import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AutoCompleteRenderDropdowns from '../../../Common/AutoCompleteRenderDropdowns';
import { useDispatch, useSelector } from 'react-redux';
import { orderCreationActions } from '@AMIEWEB/Order/Store/OrderCreation.actions';
import { selectBestBets } from '@AMIEWEB/Order/Store/OrderCreation.selectors';
import { IBestBets } from 'app/models/Orders/OrderDetails';

const mapBestBetsOptions = (bestBetsList: IBestBets[]) =>
  bestBetsList?.map(item => {
    return {
      ID: item.value,
      Description: item.name,
    };
  });

const BestBets = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const bestBetsList = useSelector(selectBestBets);
  const [bestBetsOptions, setBestBetsOptions] = useState([]);

  useEffect(() => {
    dispatch(orderCreationActions.getBestBetsOptions());
  }, []);

  useEffect(() => {
    setBestBetsOptions(mapBestBetsOptions(bestBetsList));
  }, [bestBetsList]);

  return (
    <AutoCompleteRenderDropdowns
      label={t('facility.atAGlance.bestBets')}
      options={bestBetsOptions}
      id="best-bets-dropdown"
      name="bestBets"
    />
  );
};

export default BestBets;
