import React, { useState, useRef } from 'react';
import { makeStyles } from 'tss-react/mui';
import { INotificationFeedResponse } from 'app/models/Tasks/Tasks';
import { EmailNotDeliveredPopup } from '../../Common/EmailNotDeliveredPopup';
import EmailDeliveryStatus, {
  DeliveryStatus,
  IDeliveryStatusGridData,
  IDeliveryStatusProps,
  ReciepientType,
} from 'app/components/Notification/EmailDeliverStatus/EmailDeliveryStatus';
import { missingField } from 'app/constants';
import { GetMomentOfDate } from 'utils/dates/moment';
import { useDispatch, useSelector } from 'react-redux';
import { taskDetailsActions } from '@AMIEWEB/Tasks/store/Tasks.redux';
import { getEmployeeId, getNotificationType, markNotificationAsRead } from '../utils';
import { selectUser } from 'oidc/user.selectors';
import { INotificationPopperProps } from '../../utils';
import { gridSelectionActions } from '@AMIEWEB/Common/Grid/GridSelection/GridSelection.redux';

const useStyles = makeStyles()({
  addPadding: {
    '&>div:first-child': {
      padding: '0.6rem',
    },
  },
});

interface IEmailStatusFeedProps {
  notifyData: INotificationFeedResponse;
  pageNumber?: React.MutableRefObject<number>;
  activityFeedRefactoringFlag?: boolean;
  setSelectedFeedData?: React.Dispatch<React.SetStateAction<INotificationPopperProps>>;
}

export const EmailStatusFeed = ({
  notifyData,
  pageNumber,
  activityFeedRefactoringFlag,
  setSelectedFeedData,
}: IEmailStatusFeedProps) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [openDeliveryModal, setEmailDeliveryModal] = useState(false);
  const emailDeliveryData = useRef<IDeliveryStatusProps>();
  const { classes } = useStyles();
  const { id, useType, useSubType, channel, isNotificationUnread } = notifyData?.contentProps;

  const openEmailDeliveryModal = () => {
    if (activityFeedRefactoringFlag) {
      const notificationCategory = getNotificationType({ useType, useSubType, channelType: channel });
      //! Sprint 99 Viper replace this logic wtih action panel story
      const disableGetActivityFetch = true;
      markNotificationAsRead(
        [id],
        getEmployeeId(user?.userInfo),
        dispatch,
        notificationCategory,
        pageNumber.current,
        isNotificationUnread,
        disableGetActivityFetch,
      );
    }
    const tblData: IDeliveryStatusGridData[] = [];
    let idx = 0;
    const tos = (notifyData.contentProps.message.tos as any[]).map<IDeliveryStatusGridData>(d => ({
      deliveryStatus: d.deliveryStatus ? d.deliveryStatus : DeliveryStatus.Pending,
      email: d.email,
      error: 0,
      id: `${idx++}`,
      name: d?.name?.length > 1 ? d.name : missingField,
      reciepientType: ReciepientType.Tos,
    }));
    tblData.push(...tos);
    const ccs = (notifyData.contentProps.message.ccs as any[]).map<IDeliveryStatusGridData>(d => ({
      deliveryStatus: d.deliveryStatus ? d.deliveryStatus : DeliveryStatus.Pending,
      email: d.email,
      error: 0,
      id: `${idx++}`,
      name: d?.name?.length > 1 ? d.name : missingField,
      reciepientType: ReciepientType.Ccs,
    }));
    tblData.push(...ccs);
    const bccs = (notifyData.contentProps.message.bccs as any[]).map<IDeliveryStatusGridData>(d => ({
      deliveryStatus: d.deliveryStatus ? d.deliveryStatus : DeliveryStatus.Pending,
      email: d.email,
      error: 0,
      id: `${idx++}`,
      name: d?.name?.length > 1 ? d.name : missingField,
      reciepientType: ReciepientType.Bccs,
    }));
    tblData.push(...bccs);
    const moment =
      GetMomentOfDate(notifyData.contentProps.publishedOn).format('MM/DD/YYYY') +
      ' ' +
      'at' +
      ' ' +
      GetMomentOfDate(notifyData.contentProps.publishedOn).format('LT');
    const deliveryModalProps: IDeliveryStatusProps = {
      id: notifyData.contentProps.id,
      body: notifyData.contentProps.message.body,
      open: true,
      subject: notifyData.contentProps.message.subject,
      sentDate: [{ date: moment, logid: notifyData.contentProps.id }],
      tableData: tblData,
      closeModal: () => {
        setEmailDeliveryModal(false);
        dispatch(gridSelectionActions.reset());
        if (activityFeedRefactoringFlag) {
          setSelectedFeedData({
            currentPopper: null,
            openPopper: false,
          });
        }
      },
    };
    emailDeliveryData.current = deliveryModalProps;
    dispatch(taskDetailsActions.setEmailDeliveryAssociateRecords([...notifyData.contentProps.associateRecords]));
    setEmailDeliveryModal(true);
  };
  return (
    <>
      <div
        className={!activityFeedRefactoringFlag && classes.addPadding}
        onClick={openEmailDeliveryModal}
        style={notifyData.contentProps.isRead ? {} : { backgroundColor: '#f5f5fa' }}
      >
        <EmailNotDeliveredPopup
          hasAttachments={notifyData.contentProps.hasAttachments}
          publishedOn={notifyData.contentProps.publishedOn}
          recipientAddr={notifyData.contentProps.recipientAddr}
          status={notifyData.contentProps.status}
          subject={notifyData.contentProps.message.subject}
          isUnread={isNotificationUnread}
          showActionPanel={false}
          defaultBody
        />
      </div>
      <EmailDeliveryStatus {...emailDeliveryData.current} open={openDeliveryModal} />
    </>
  );
};
