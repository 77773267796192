import CloseIcon from '@mui/icons-material/Close';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import {
  AppBar,
  Avatar,
  Button,
  ClickAwayListener,
  Grid,
  InputAdornment,
  Modal,
  TextField,
  Toolbar,
  Typography,
  useTheme,
} from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { CloseConfirmation } from 'app/components/Notification/Common/CloseDialog';
import { SaveCallLog, SaveConversationNotes } from 'app/services/NotificationServices/NotificationService';
import _ from 'lodash';
import React, { useState } from 'react';
import Draggable from 'react-draggable';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styledComponent from 'styled-components';
import { Concatenate } from 'utils/string/string';
import { candidateDetailActions } from '../../Profile/CandidateDetails.redux';
import { candidateDetailsSelection } from '../../Profile/CandidateDetails.selector';
import { COMMUNICATION_LOG_NOTES_CHARACTER_LIMIT } from '@AMIEWEB/Notification/Constants';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import { ISaveCallLogPayload } from 'app/models/Notification/Notification';

const useStyles = makeStyles()(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  notesRoot: {
    '& .MuiInputBase-input.Mui-disabled': {
      color: '#000',
    },
  },
  headerIcon: {
    fontSize: '40px',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    overflowY: 'hidden',
    overflowX: 'hidden',
    width: '25vw',
    outline: 'none',
    paddingBottom: '1%',
    boxShadow: '0px 0px 2px 6px #8888',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: '6px',
  },
  actionButton: {
    margin: '1%',
    marginTop: '4%',
    float: 'right',
    color: '#fff',
  },
  cancelButton: { backgroundColor: '#8e9195' },
  notes: {
    minHeight: '5vh',
  },
  avatar: {
    margin: '2% 3% 2% 0',
    background: '#DBE7F1',
    color: '#0F6FB9',
    height: 50,
    width: 50,
  },
  modalIcon: {
    color: '#BDBEBF',
    cursor: 'pointer',
    fontSize: '1.5rem',
    margin: '5px',
  },
  modalContent: {
    margin: '2% 5%',
    color: '#393939',
  },
  errorText: {
    fontSize: '12px',
    justifyContent: 'center',
    color: theme.palette.system.errorRed,
  },
  characterCount: {
    fontSize: '12px',
    justifyContent: 'center',
    color: '#888888',
  },
  errorInfoIcon: {
    width: '16px',
    height: '16px',
  },
}));

const ModalAppBar = styledComponent(AppBar)`
  width: 100%;
  padding-left: 20px;
  background: #f4f5f7;
  cursor: all-scroll;
  box-shadow: none;
`;
const Notes = styledComponent(TextField)`
  margin-top: 1%;
  width: 100%;
`;

const Header = styledComponent(Typography)`
  color: #0f6fb9;
  text-align: left;
  font: normal normal medium Roboto;
  letter-spacing: 0px;
  opacity: 1;
  font-size: 1rem;
  font-weight: 700;
  margin-top: 1.5%;
`;

export const NotesModal = props => {
  const { log, onClose, isPreview = false, rowId } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [notes, setNotes] = useState(log?.response?.notes || '');
  const SAVE_BUTTON_TEXT = log?.response?.notes ? 'Save Note' : 'Add Note';
  const [closeModal, setCloseModal] = useState(false);
  const { classes } = useStyles();
  const candidateDetails = useSelector(candidateDetailsSelection);
  const [previewOnly, setPreviewOnly] = useState<boolean>(isPreview);
  const theme = useTheme();

  const updateGrid = () => {
    const updatedGridLog = _.cloneDeep(candidateDetails.gridLogsData);
    updatedGridLog.results[rowId].response
      ? (updatedGridLog.results[rowId].response.notes = notes)
      : (updatedGridLog.results[rowId] = { ...updatedGridLog.results[rowId], response: { notes: notes } });
    dispatch(candidateDetailActions.updateGridLogsData(updatedGridLog));

    setPreviewOnly(false);
    onClose();
    dispatch(
      globalActions.setSnackBar({
        message: log?.response?.notes ? 'Note has been updated successfully!' : `Note has been added successfully!`,
        severity: 'success',
      }),
    );
  };

  const handleSubmit = async () => {
    if (log.channel === 'Conversation') {
      const addNotesData = {
        conversationId: log.id,
        brand: log?.brand,
        brandId: log?.associatedRecords?.find(x => x.name.toLowerCase() === 'brandid')?.value,
        sender: log?.message.sender,
        tos: log?.message.tos[0],
        notes: notes,
        publishedOn: new Date(),
      };
      setPreviewOnly(true);
      try {
        const response = await SaveConversationNotes(addNotesData);
        if (response.saved === true) updateGrid();
      } catch (error) {
        setPreviewOnly(false);
        dispatch(
          globalActions.setSnackBar({
            message: `Failed to save notes ${error}`,
            severity: 'error',
          }),
        );
      }
    }
    if (log.channel === 'Voice') {
      const addNotesData: ISaveCallLogPayload = {
        id: log.id,
        CTIActivityHistoryId: log.response?.messageId,
        manualLog: false,
        channel: log?.channel,
        direction: log?.message.direction,
        disposition: log?.response?.disposition,
        notes: notes,
        associatedRecords: log?.associatedRecords,
        sender: log?.message.sender,
        tos: log?.message.tos,
        useType: log?.useType,
        formName: log?.message.formName,
        brand: log?.brand,
        brandId: log?.brandId,
        userRole: Concatenate(log.userRoles, ','),
        initiatedOn: new Date(),
      };
      setPreviewOnly(true);
      try {
        const response = await SaveCallLog(addNotesData);
        if (response.data.saved === true) updateGrid();
      } catch (error) {
        setPreviewOnly(false);
        dispatch(
          globalActions.setSnackBar({
            message: `Failed to save notes ${error}`,
            severity: 'error',
          }),
        );
      }
    }
  };

  const handleCloseAction = () => {
    if ((log.response?.notes && log.response?.notes !== notes) || (!log.response?.notes && notes)) {
      setCloseModal(true);
    } else {
      onClose();
    }
  };

  return (
    <React.Fragment>
      <Modal open className={classes.modal} hideBackdrop>
        <ClickAwayListener onClickAway={() => handleCloseAction()}>
          <div>
            <Draggable handle="#draggableArea">
              <div className={classes.paper}>
                <ModalAppBar id="draggableArea" position="static">
                  <Toolbar disableGutters style={{ background: '#88888' }}>
                    <Avatar className={classes.avatar}>
                      <NoteAddOutlinedIcon className={classes.headerIcon} />
                    </Avatar>
                    <Grid>
                      <Grid item>
                        <Header>
                          {`${log?.message.tos?.find(Boolean)?.name} | CID ${
                            log?.message.tos.find(Boolean)?.contactId
                          }`}
                        </Header>
                      </Grid>
                    </Grid>
                    <div style={{ marginLeft: 'auto', marginRight: '2%' }}>
                      <CloseIcon onClick={() => handleCloseAction()} className={classes.modalIcon} />
                    </div>
                  </Toolbar>
                </ModalAppBar>

                <div className={classes.modalContent}>
                  <Notes
                    label={t('notification.voice.enterNotes')}
                    className={classes.notesRoot}
                    disabled={previewOnly}
                    value={notes}
                    rows={10}
                    onChange={event => !previewOnly && setNotes(event.target.value)}
                    multiline
                    inputProps={{
                      maxLength: COMMUNICATION_LOG_NOTES_CHARACTER_LIMIT,
                    }}
                    InputProps={{
                      classes: { input: classes.notes },
                      startAdornment:
                        notes.length >= COMMUNICATION_LOG_NOTES_CHARACTER_LIMIT ? (
                          <InputAdornment
                            position="start"
                            style={{ position: 'absolute', top: '0', right: '0', color: theme.palette.system.errorRed }}
                          >
                            <InfoOutlinedIcon className={classes.errorInfoIcon} />
                          </InputAdornment>
                        ) : (
                          <></>
                        ),
                    }}
                    variant="filled"
                    error={notes.length === COMMUNICATION_LOG_NOTES_CHARACTER_LIMIT}
                  />
                  <LayoutGrid
                    container
                    justifyContent="space-between"
                    style={{ width: '100%', marginLeft: '0px' }}
                    xs={12}
                  >
                    <LayoutGridItem style={{ paddingLeft: '12px' }}>
                      <label className={classes.errorText}>{`${
                        notes.length === COMMUNICATION_LOG_NOTES_CHARACTER_LIMIT
                          ? t('notification.voice.maxCharLimit')
                          : ' '
                      }`}</label>
                    </LayoutGridItem>
                    <LayoutGridItem>
                      <label
                        className={
                          notes.length === COMMUNICATION_LOG_NOTES_CHARACTER_LIMIT
                            ? classes.errorText
                            : classes.characterCount
                        }
                      >{`${notes.length}/${COMMUNICATION_LOG_NOTES_CHARACTER_LIMIT}`}</label>
                    </LayoutGridItem>
                  </LayoutGrid>
                  <Grid container spacing={0} direction="row" alignItems="center" justifyContent="center">
                    <Grid item>
                      {closeModal && (
                        // eslint-disable-next-line react/jsx-no-undef
                        <CloseConfirmation yesAction={() => onClose()} noAction={() => setCloseModal(false)} />
                      )}
                    </Grid>
                  </Grid>

                  {!previewOnly && (
                    <>
                      <Button
                        className={classes.actionButton}
                        disabled={!notes || notes === log?.response?.notes}
                        onClick={() => handleSubmit()}
                        variant="contained"
                        color="primary"
                      >
                        {SAVE_BUTTON_TEXT}
                      </Button>
                      <Button
                        className={`${classes.actionButton} ${classes.cancelButton}`}
                        onClick={() => handleCloseAction()}
                        variant="contained"
                      >
                        {t('Cancel')}
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </Draggable>
          </div>
        </ClickAwayListener>
      </Modal>
    </React.Fragment>
  );
};
