import { ChannelType } from '@AMIEWEB/Notification/Constants';
import { ITemplate } from '@AMIEWEB/Notification/MultiChannel/Email/Email';
import { Editor } from '@tinymce/tinymce-react';
import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from 'amn-ui-core';
import { trackEvent } from 'app-insights/appInsightsTracking';
import { GetAvailableCategory } from 'app/services/NotificationServices/TemplateService';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { getEmptySubstitutions } from '../Common/helper';
import {
  selectFacilityBaseTemplate,
  selectIsEdit,
  selectPacketFacilityTemplatesById,
} from 'store/redux-store/notification/notification.selector';
import { TemplateCategory, IError, initialState } from './AddTemplateModal';
import { CustomBackdrop } from '@AMIEWEB/Common/Backdrop/CustomBackdrop';
import { theme } from 'styles/global-styles';

const useStyles = makeStyles()(theme => ({
  inputLabel: {
    color: theme.palette.system.buttonDarkGray,
    '&.Mui-focused': {
      color: theme.palette.system.buttonDarkGray,
    },
    '&:focus': {
      color: `${theme.palette.system.buttonDarkGray} !important`,
    },
  },
  select: {
    background: theme.palette.system.grayChip,
    borderBottom: `1px solid ${theme.palette.system.neutralGray} !important`,
  },
  scroll: {
    width: '100%',
    overflowY: 'auto',
    overflowX: 'auto',
    margin: '1.5% 0 0 10px',
    borderBottom: '1px solid #CCC',
    '& div.tox-tinymce': {
      border: 'none',
      borderRadius: '0px',
    },
    '& div.tox-editor-header': {
      boxShadow: 'none !important',
      borderBottom: '1px solid lightgrey !important',
    },
    '& div.tox-editor-header, div.tox-toolbar': {
      backgroundColor: '#fbfbfb !important',
    },
    '& .tox .tox-tbtn svg': {
      fill: 'grey !important',
    },
    '& div.tox-toolbar__group:last-child': {
      marginLeft: 'auto',
      borderLeft: 'none',
    },
    '& .tox .tox-tbtn--select': {
      width: '120px !important',
      justifyContent: 'space-between',
      borderBottom: `1px solid ${theme.palette.system.black} !important`,
      borderRadius: '0px !important',
      cursor: 'pointer',
    },
    '& .tox .tox-tbtn__select-label': {
      color: 'grey',
    },
  },
  body: {
    '& table': {
      maxWidth: '100% !important',
      width: '100% !important',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 9,
    background: 'transparent',
  },
}));

const API_KEY = globalThis?.app?.env?.REACT_APP_TINYMCE_API_KEY;

interface IAddEmailTemplate {
  expanded: boolean;
  emailTemplates: ITemplate;
  templateError: IError;
  isPreview: boolean;
  facilityTitles: any[];
  setIsEdited: (edited: boolean) => void;
  setEmailTemplates: (template: ITemplate) => void;
  setTemplateError: (error: IError) => void;
}

export const AddEmailTemplate = (props: IAddEmailTemplate) => {
  const {
    expanded,
    setIsEdited,
    emailTemplates,
    setEmailTemplates,
    templateError,
    setTemplateError,
    isPreview,
    facilityTitles,
  } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();
  const trackTemplate = useRef(emailTemplates);
  const baseTemplate = useSelector(selectFacilityBaseTemplate);
  const facilityEmailTempaltesById = useSelector(selectPacketFacilityTemplatesById);
  const isEdit = useSelector(selectIsEdit);

  const [selectedTemplateCategory, setSelectedTemplateCategory] = useState<string>(TemplateCategory.PacketSubmission);
  const [templateCategoryList, setTemplateCategoryList] = useState<any[]>([]);
  const [editorValue, setEditorValue] = useState<string>(!isEdit ? baseTemplate : facilityEmailTempaltesById.body);
  const [loader, setLoader] = useState<boolean>(false);

  const handleInputChange = (event, type) => {
    if (type === 'title') {
      const template = { ...emailTemplates, title: event.target.value };
      trackTemplate.current = template;
      setEmailTemplates(template);
      if (event.target.value.length === 0) {
        setTemplateError({ ...templateError, templateSaveTitleError: t('Preferences.templateSaveTitleError') });
      } else if (event.target.value.length < 31) {
        setIsEdited(true);
        if (facilityTitles?.some(f => f?.title === event.target.value)) {
          setTemplateError({ ...templateError, templateSaveTitleError: t('Preferences.duplicateTitleError') });
        } else {
          setTemplateError({ ...templateError, templateSaveTitleError: '' });
        }
      } else {
        setTemplateError({
          ...templateError,
          templateSaveTitleError: t('Preferences.templateSaveMaxLengthTitleError'),
        });
      }
    }
    if (type === 'desc') {
      const template = { ...emailTemplates, description: event.target.value };
      trackTemplate.current = template;
      setEmailTemplates(template);
      if (event.target.value.length === 0) {
        setTemplateError({ ...templateError, templateSaveDescError: t('Preferences.templateSaveDescError') });
      } else if (event.target.value.length < 301) {
        setIsEdited(true);
        setTemplateError({ ...templateError, templateSaveDescError: '' });
      } else {
        setTemplateError({
          ...templateError,
          templateSaveDescError: t('Preferences.templateSaveDescMaxLengthError'),
        });
      }
    }
  };

  const handleCategoryChange = event => {
    if (event.target.value.length <= 0) {
      setTemplateError({ ...templateError, templateSaveCategoryError: t('notification.emails.categoryRequired') });
    } else {
      setSelectedTemplateCategory(event.target.value);
      setEmailTemplates({ ...emailTemplates, category: event.target.value });
      setTemplateError({ ...templateError, templateSaveCategoryError: '' });
    }
  };

  const getTemplatesCategory = async () => {
    await GetAvailableCategory(ChannelType.email).then(response => {
      setTemplateCategoryList(response?.public?.filter(item => item !== 'Pipeline Management') ?? []);
    });
  };

  useEffect(() => {
    setLoader(true);
    getTemplatesCategory();
  }, []);

  useEffect(() => {
    trackTemplate.current = emailTemplates;
  }, [emailTemplates]);

  React.useEffect(() => {
    setEmailTemplates(
      isEdit
        ? { ...facilityEmailTempaltesById, body: facilityEmailTempaltesById.body }
        : { ...initialState, body: baseTemplate },
    );
  }, [baseTemplate, facilityEmailTempaltesById]);

  const handleChange = (value: string) => {
    setEmailTemplates({ ...trackTemplate.current, body: value });
  };

  const editor = () => {
    return (
      <>
        <Editor
          value={editorValue}
          apiKey={API_KEY}
          onInit={() => {
            setLoader(false);
          }}
          init={{
            init_instance_callback: function (editor) {
              editor.on('KeyPress', function (e) {
                setIsEdited(true);
              });
              editor.on('keydown', function (e) {
                if (e.key === 'Backspace' || e.key === 'Delete') {
                  setIsEdited(true);
                }
              });
              editor.on('blur', function (e) {
                handleChange(e.target.getContent());
              });
            },
            setup: function (editor) {
              editor.ui.registry.addMenuButton('insert', {
                text: 'Insert',
                fetch: function (callback) {
                  const items: any[] = [];
                  getEmptySubstitutions()?.map(token => {
                    const item = {
                      type: 'menuitem',
                      text: token?.name,
                      onAction: function () {
                        trackEvent({ type: 'event', name: 'Insert -' + token?.input });
                        editor.insertContent(token?.input);
                        setIsEdited(true);
                      },
                      enabled: true,
                    };
                    items.push(item);
                  });
                  callback(items);
                },
              });
              editor.ui.registry.addGroupToolbarButton('grouptools', {
                icon: 'image-options',
                tooltip: 'additional options',
                items: 'removeformat | help | undo redo',
              });
            },
            plugins: [
              'advlist',
              'lists',
              'charmap',
              'link',
              'autolink',
              'print',
              'preview',
              'anchor',
              'searchreplace',
              'code',
              'fullscreen',
              'insertdatetime',
              'media',
              'table',
              'code',
              'help',
              'und',
            ],
            toolbar: [
              'bold italic underline backcolor | link | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | grouptools | insert',
            ],
            toolbar_mode: 'floating',
            branding: false,
            statusbar: false,
            elementpath: false,
            height: '100%',
            block_formats: 'Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3',
            menubar: false,
            forced_root_block: '',
            force_br_newlines: true,
            force_p_newlines: false,
            paste_data_images: false,
            relative_urls: false,
            remove_script_host: false,
            content_style:
              'body { background-color: rgb(251, 251, 251); border:none;} table { width: 100% !important; max-width: 100% !important; border: 1px solid white !important }',
          }}
          onEditorChange={(newValue, editor) => {
            setEditorValue(newValue);
          }}
        />
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return (
    <Grid container spacing={2}>
      <CustomBackdrop className={classes.backdrop} open={loader} />
      {!isPreview ? (
        <>
          <Grid item xs={12}>
            <TextField
              id="templateTitle"
              fullWidth
              required
              value={emailTemplates.title}
              style={{ background: theme.palette.framework.system.platinum }}
              variant="filled"
              label={t('Template Title')}
              helperText={templateError.templateSaveTitleError}
              error={templateError.templateSaveTitleError.length > 0}
              onChange={event => handleInputChange(event, 'title')}
            />
          </Grid>
          <Grid item xs={9}>
            <TextField
              required
              fullWidth
              value={emailTemplates.description}
              id="templateDescription"
              style={{ background: theme.palette.framework.system.platinum }}
              variant="filled"
              label={t('Description')}
              helperText={templateError.templateSaveDescError}
              error={templateError.templateSaveDescError.length > 0}
              onChange={event => handleInputChange(event, 'desc')}
            />
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth variant="filled">
              <InputLabel className={classes.inputLabel}>{t('Template Category')}</InputLabel>
              <Select
                variant="filled"
                id="templateCategory"
                required
                classes={{ select: classes.select }}
                labelId="device-label-id"
                value={selectedTemplateCategory}
                onChange={handleCategoryChange}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                }}
                disableUnderline
                disabled
              >
                {templateCategoryList.map((listItem, index) => (
                  <MenuItem key={index} value={listItem}>
                    {listItem}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{templateError.templateSaveCategoryError}</FormHelperText>
            </FormControl>
          </Grid>
          <div className={classes.scroll} style={{ position: 'relative', height: expanded ? '550px' : '370px' }}>
            {editor()}
          </div>
        </>
      ) : (
        <div className={classes.body} dangerouslySetInnerHTML={{ __html: editorValue }}></div>
      )}
    </Grid>
  );
};
