import { GridColumns } from '@mui/x-data-grid-pro';
import { tooltipType } from 'app/components/Common/Tooltips';
import { XGridContactHoverView } from 'app/components/Common/XGrid/cells/HoverViewRenderers/XGridContactHoverView';
import { XGridBlueCell } from 'app/components/Common/XGrid/cells/XGridBlueCell';
import { XGridDefaultColumn } from 'app/components/Common/XGrid/cells/XGridDefaults';
import { XGridLinkCell } from 'app/components/Common/XGrid/cells/XGridLinkCell';
import { XGridSideBarViewCell } from 'app/components/Common/XGrid/cells/XGridSideBarViewCell';
import { XGridOverflowCell } from 'app/components/Common/XGrid/cells/XGridOverflowCell';
import { ICustomHeader } from 'app/models/Grid';

interface IGridColumns extends Omit<ICustomHeader, 'title'> {
  title:
    | 'facilityId'
    | 'facility'
    | 'facilityStatus'
    | 'orders'
    | 'positionsAvailable'
    | 'filesPending'
    | 'filesOut'
    | 'accountManager'
    | 'city'
    | 'state'
    | 'division'
    | 'type'
    | 'staffingVendor'
    | 'techVendor'
    | 'healthSystem'
    | 'contractAffiliate'
    | 'credentialingAnalyst'
    | 'clinicalManager'
    | 'contractAffiliateId'
    | 'regionalDirector'
    | 'clientContractAdmin'
    | 'clientContractSpecialist'
    | 'hospitalSetting'
    | 'facilityRequirementType'
    | 'requirementType'
    | 'facilityType'
    | 'facilityDescription'
    | 'healthSystemName';
}

const FacilityDefaultCell = {
  ...XGridDefaultColumn,
  flex: 0,
};

export const facilityViewInfo = (t, exclude = [] as string[]): GridColumns => [
  {
    ...FacilityDefaultCell,
    field: 'facilityId',
    headerName: t('search.globalSearch.facility.gridTags.facilityId'),
    width: 150,
    renderCell: XGridOverflowCell(XGridSideBarViewCell('/facility/{0}', ['facilityId'], true))(
      XGridSideBarViewCell('/facility/{0}', ['facilityId']),
    ),
  },
  {
    ...FacilityDefaultCell,
    field: 'facility',
    headerName: t('search.globalSearch.facility.gridTags.facility'),
    width: 280,
    renderCell: XGridOverflowCell(XGridLinkCell('/facility/{0}', ['facilityId'], true))(
      XGridLinkCell('/facility/{0}', ['facilityId']),
    ),
  },
  {
    ...FacilityDefaultCell,
    field: 'facilityStatus',
    headerName: t('search.globalSearch.facility.gridTags.facilityStatus'),
    width: 130,
  },
  {
    ...FacilityDefaultCell,
    field: 'orders',
    headerName: t('search.globalSearch.facility.gridTags.orders'),
    width: 100,
  },
  {
    ...FacilityDefaultCell,
    field: 'positionsAvailable',
    headerName: t('search.globalSearch.facility.gridTags.positionsAvailable'),
    width: 100,
  },
  {
    ...FacilityDefaultCell,
    field: 'filesPending',
    headerName: t('search.globalSearch.facility.gridTags.filesPending'),
    width: 120,
    description: 'Status includes WFC and RFO',
  },
  {
    ...FacilityDefaultCell,
    field: 'filesOut',
    headerName: t('search.globalSearch.facility.gridTags.filesOut'),
    width: 100,
    description: 'Status includes Sent-Offer',
  },
  {
    ...FacilityDefaultCell,
    field: 'accountManager',
    headerName: t('search.globalSearch.facility.gridTags.accountManager'),
    width: 145,
    renderCell: XGridOverflowCell(XGridContactHoverView('accountManagerAttributes'), true)(XGridBlueCell),
  },
  {
    ...FacilityDefaultCell,
    field: 'city',
    headerName: t('search.globalSearch.facility.gridTags.city'),
    width: 130,
  },
  {
    ...FacilityDefaultCell,
    field: 'stateName',
    headerName: t('search.globalSearch.facility.gridTags.state'),
    width: 90,
  },
  {
    ...FacilityDefaultCell,
    field: 'division',
    headerName: t('search.globalSearch.facility.gridTags.division'),
    width: 90,
  },
  {
    ...FacilityDefaultCell,
    field: 'staffingVendor',
    headerName: t('search.globalSearch.facility.gridTags.staffingVendor'),
    width: 145,
  },
  {
    ...FacilityDefaultCell,
    field: 'techVendor',
    headerName: t('search.globalSearch.facility.gridTags.techVendor'),
    width: 145,
  },
  {
    ...FacilityDefaultCell,
    field: 'healthSystem',
    headerName: t('search.globalSearch.facility.gridTags.healthSystem'),
    width: 145,
  },
  {
    ...FacilityDefaultCell,
    field: 'contractAffiliate',
    headerName: 'Contract Affiliate',
    width: 170,
  },
  {
    ...FacilityDefaultCell,
    field: 'credAnalyst',
    headerName: t('search.globalSearch.facility.gridTags.credentialingAnalyst'),
    width: 145,
    renderCell: XGridOverflowCell(XGridContactHoverView('credentialingAnalystAttributes'), true)(XGridBlueCell),
  },
  {
    ...FacilityDefaultCell,
    field: 'clinicalManager',
    headerName: t('search.globalSearch.facility.gridTags.clinicalManager'),
    width: 145,
    renderCell: XGridOverflowCell(XGridContactHoverView('clinicalManagerAttributes'), true)(XGridBlueCell),
  },
  {
    ...FacilityDefaultCell,
    field: 'contractAffiliateId',
    headerName: t('search.globalSearch.facility.gridTags.contractAffiliateId'),
    width: 145,
  },
  {
    ...FacilityDefaultCell,
    field: 'regionalDirector',
    headerName: t('search.globalSearch.facility.gridTags.regionalDirector'),
    width: 145,
    renderCell: XGridOverflowCell(XGridContactHoverView('regionalDirectorAttributes'), true)(XGridBlueCell),
  },
  {
    ...FacilityDefaultCell,
    field: 'clientContractAdmin',
    headerName: t('search.globalSearch.facility.gridTags.clientContractAdmin'),
    width: 145,
    renderCell: XGridOverflowCell(XGridContactHoverView('clientContractAdminAttributes'), true)(XGridBlueCell),
  },
  {
    ...FacilityDefaultCell,
    field: 'clientContractSpecialist',
    headerName: t('search.globalSearch.facility.gridTags.clientContractSpecialist'),
    width: 145,
    renderCell: XGridOverflowCell(XGridContactHoverView('clientContractSpecialistAttributes'), true)(XGridBlueCell),
  },
  {
    ...FacilityDefaultCell,
    field: 'hospitalSetting',
    headerName: t('search.globalSearch.facility.gridTags.hospitalSetting'),
    width: 145,
  },
  {
    ...FacilityDefaultCell,
    field: 'requirementTypes',
    headerName: t('search.globalSearch.facility.gridTags.facilityRequirementType'),
    width: 145,
  },
  {
    ...FacilityDefaultCell,
    field: 'requirementTypesUnits',
    headerName: t('search.globalSearch.facility.gridTags.requirementType'),
    width: 145,
  },
  {
    ...FacilityDefaultCell,
    field: 'facilityType',
    headerName: t('search.globalSearch.facility.gridTags.facilityType'),
    width: 145,
  },
  {
    ...FacilityDefaultCell,
    field: 'facilityDescription',
    headerName: t('search.globalSearch.facility.gridTags.description'),
    width: 145,
  },
  {
    ...FacilityDefaultCell,
    field: 'healthSystemId',
    headerName: t('search.globalSearch.facility.gridTags.healthSystemID'),
    width: 145,
  },
]; //.filter(item => !exclude.some(field => field === item.field));

export const facilityColumnVisibilityModel = {
  facilityId: true,
  facility: true,
  facilityStatus: true,
  orders: true,
  positionsAvailable: true,
  filesPending: true,
  filesOut: true,
  accountManager: true,
  city: true,
  state: true,
  division: true,
  type: true,
  staffingVendor: true,
  techVendor: true,
  healthSystem: true,
  contractAffiliate: true,
  region: false,
};

export const facilityViewInfo_Old = t =>
  [
    {
      title: 'facilityId',
      value: 'Facility ID',
      clickable: true,
      sortable: true,
      sortByField: 'orderSortable',
    },
    {
      title: 'facility',
      value: 'Facility Name',
      clickable: true,
      sortable: true,
      navigationIds: ['navFacilityId'],
      navigationExp: '/facility/{0}',
      tooltip: tooltipType.OverflowTooltip,
    },
    { title: 'facilityStatus', value: 'Facility Status', sortable: true },
    { title: 'orders', value: 'Orders', sortable: true },
    { title: 'positionsAvailable', value: 'Positions', sortable: true },
    {
      title: 'filesPending',
      value: 'Files Pending',
      sortable: true,
      isNumber: true,
      columnHeaderHoverView: true,
      columnHeaderHoverText: 'Status includes WFC and RFO',
    },
    {
      title: 'filesOut',
      value: 'Files Out',
      sortable: true,
      columnHeaderHoverView: true,
      columnHeaderHoverText: 'Status includes Sent-Offer',
    },
    { title: 'accountManager', value: 'Account Manager', sortable: true },
    {
      title: 'city',
      value: 'City',
      sortable: true,
      sortByField: 'citySortField',
      tooltip: tooltipType.CustomTooltip,
      tooltipAttributes: 'cityAttributes',
    },
    { title: 'state', value: 'State', sortable: true },
    { title: 'division', value: 'Division', sortable: true },
    { title: 'type', value: 'Type', sortable: true },
    {
      title: 'staffingVendor',
      value: 'Staffing Vendor',
      sortable: true,
      tooltip: tooltipType.CustomTooltip,
      tooltipAttributes: 'staffingVendorAttributes',
    },
    {
      title: 'techVendor',
      value: 'Tech Vendor',
      sortable: true,
      tooltip: tooltipType.CustomTooltip,
      tooltipAttributes: 'technologyVendorAttributes',
    },
    { title: 'healthSystem', value: 'Health System', sortable: true },
    { title: 'contractAffiliate', value: 'Contract Affiliate', sortable: true },
  ] as IGridColumns[];

/*export const facilityViewInfo: IGridColumns[] = [
  {
    title: 'facilityId',
    value: 'Facility ID',
    clickable: true,
    sortable: true,
    sortByField: 'orderSortable',
  },
  {
    title: 'facility',
    value: 'Facility Name',
    clickable: true,
    sortable: true,
    navigationIds: ['navFacilityId'],
    navigationExp: '/facility/{0}',
    tooltip: tooltipType.OverflowTooltip,
  },
  { title: 'facilityStatus', value: 'Facility Status', sortable: true },
  { title: 'orders', value: 'Orders', sortable: true },
  { title: 'positionsAvailable', value: 'Positions', sortable: true },
  {
    title: 'filesPending',
    value: 'Files Pending',
    sortable: true,
    isNumber: true,
    columnHeaderHoverView: true,
    columnHeaderHoverText: 'Status includes WFC and RFO',
  },
  {
    title: 'filesOut',
    value: 'Files Out',
    sortable: true,
    columnHeaderHoverView: true,
    columnHeaderHoverText: 'Status includes Sent-Offer',
  },
  { title: 'accountManager', value: 'Account Manager', sortable: true },
  {
    title: 'city',
    value: 'City',
    sortable: true,
    sortByField: 'citySortField',
    tooltip: tooltipType.CustomTooltip,
    tooltipAttributes: 'cityAttributes',
  },
  { title: 'state', value: 'State', sortable: true },
  { title: 'division', value: 'Division', sortable: true },
  { title: 'type', value: 'Type', sortable: true },
  {
    title: 'staffingVendor',
    value: 'Staffing Vendor',
    sortable: true,
    tooltip: tooltipType.CustomTooltip,
    tooltipAttributes: 'staffingVendorAttributes',
  },
  {
    title: 'techVendor',
    value: 'Tech Vendor',
    sortable: true,
    tooltip: tooltipType.CustomTooltip,
    tooltipAttributes: 'technologyVendorAttributes',
  },
  { title: 'healthSystem', value: 'Health System', sortable: true },
  { title: 'contractAffiliate', value: 'Contract Affiliate', sortable: true },
];*/

/* enum values are named w.r.t api service call*/
export enum facilitySearchFilterName {
  facilityStatus = 'facilityStatusIds',
  division = 'divisionIds',
  state = 'states',
  facility = 'facilityIds',
  city = 'city',
  accountManager = 'amUserIds',
  positions = 'positions',
  credentialingAnalyst = 'credAnalystUserIds',
  clinicalManager = 'clinicalManagerUserIds',
  contractAffiliateId = 'affiliateIds',
  contractAffiliateName = 'affiliateNameIds',
  regionalDirector = 'regionalDirectorUserIds',
  clientContractAdmin = 'clientContractAdminUserIds',
  clientContractSpecialist = 'ClientContractSpecialistUserIds',
  hospitalSetting = 'hospitalSettingIds',
  requirementType = 'requirementTypeIds',
  facilityNested = 'facilityType',
  facilityType = 'facilityTypeId',
  description = 'facilityDescriptionId',
  staffingVendor = 'staffingVendorIds',
  healthSystemID = 'healthSystemIds',
  healthSystem = 'healthSystemNameIds',
  techVendor = 'technologyVendorIds',
  includeRequirementTypeUnits = 'includeRequirementTypeUnits',
  region = 'regionIds',
}

/*export const defaultFilterValues = {
  [facilitySearchFilterName.facilityStatus]: null,
  [facilitySearchFilterName.division]: null,
  [facilitySearchFilterName.state]: null,
  [facilitySearchFilterName.facility]: null,
  [facilitySearchFilterName.city]: null,
  [facilitySearchFilterName.accountManager]: null,
  [facilitySearchFilterName.positions]: null,
};

// GET Grid Title For Facility

export const gridTitleFacility = positionCount => {
  return positionCount ? ` / Positions (${positionCount})` : undefined;
};*/
