import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmissionPreferences } from './Submission';
import { BookingPreferences } from './Booking';
import { useInjectReducer } from 'utils/redux-injectors';
import {
  facilityPreferenceActions,
  facilityPreferenceReducer,
  facilityPreferenceSliceKey,
} from 'store/redux-store/facility-preferences/slice';
import { useInjectSaga } from 'redux-injectors';
import { facilityPreferenceSaga } from 'store/redux-store/facility-preferences/saga';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { selectFacility, selectFacilityContacts } from '@AMIEWEB/Facility/FacilityStore/Facility.selector';
import { AMNDivisionType } from 'app/models/enums/AMNDivisionType';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import { ActionButtonWrapper } from '@AMIEWEB/Common/SubmissionPreference/ActionButtonWrapper/ActionButtonWrapper';
import { notificationDataActions, notificationDataKey } from 'store/redux-store/notification/notification.redux';
import { notificationSaga } from 'store/redux-store/notification/notification.saga';
import { FormProvider, useForm } from 'react-hook-form';
import {
  selectBookingFacilityDirty,
  selectConfirmationDialog,
  selectExistingUnitsForFacility,
  selectFacilityPreferences,
  selectFacilitytemplate,
  selectMiscellaneousDate,
  selectPreferenceChanged,
  selectPreferenceSelection,
  selectTabValue,
  selectUpdatePreferenceDialog,
} from 'store/redux-store/facility-preferences/selectors';
import { selectUser } from 'oidc/user.selectors';
import { usePromiseTracker } from 'react-promise-tracker';
import {
  initialSendPacketState,
  placementStage,
} from '@AMIEWEB/Common/SubmissionPreference/SubmissionLimit/SendPacketAutomation';
import { ConfirmationDialog } from '@AMIEWEB/Settings/AutoTaskManagement/common/ConfirmationDialog';
import { yupResolver } from '@hookform/resolvers/yup';
import { selectPacketCategoryTitles } from 'store/redux-store/notification/notification.selector';
import {
  ISubmissionEmail,
  ISubmissionPacket,
  getFormattedSubmissionEmail,
  getFormattedSubmissionPacket,
} from './Submission/SubmissionHelper';
import { useDecision } from '@optimizely/react-sdk';
import { ff_facility_ui_newpreference_rfo_to_sent } from 'app/constants/FeatureFlags/Facility/Keys';
import { initialPacketState } from '@AMIEWEB/Common/SubmissionPreference/SubmissionPacket/SubmissionPacketSelector';
import {
  TemplateUserTypes,
  deriveAttentionToOpts,
  deriveSendToOpts,
} from '@AMIEWEB/Common/SubmissionPreference/Common/helper';
import { Authorized } from 'oidc/userHelper';
import { userRoles } from 'oidc/userRoles';
import { UpdatePreferencesModal } from '@AMIEWEB/Common/SubmissionPreference/UpdatePreferenes/UpdatePreferencesModal';
import {
  extractRows,
  getExistngUnitColumns,
} from '@AMIEWEB/Common/SubmissionPreference/UpdatePreferenes/FacilityUtils';
import { isValidJSONString } from 'utils/string/string';
import { trackEvent } from 'app-insights/appInsightsTracking';
import { facilityActions } from '@AMIEWEB/Facility/FacilityStore/Facility.redux';
import { FacilityUnitActions, FacilityUnitKey, FacilityUnitReducer } from '../UnitTab/Store/FacilityUnit.redux';
import { facilityUnitsSaga } from '../UnitTab/Store/FacilityUnit.saga';
import { validationSchema } from '@AMIEWEB/Common/SubmissionPreference/Common/facilityValidation';
import { gridSelectionActions } from '@AMIEWEB/Common/Grid/GridSelection/GridSelection.redux';
import { initialState } from 'store/redux-store/facility-preferences/initialState';
import { INavigation, navigationStickActions } from '@AMIEWEB/GlobalNavigationMenu/NavigationStick.redux';
import { Expandable } from '@AMIEWEB/Common';
import { Title } from '@AMIEWEB/Order/OrderDetails/OrderPreferences/customComponents/OrderPreferencesDesign';
import { TemplateCategory } from '@AMIEWEB/Common/SubmissionPreference/SubmissionEmail/AddTemplateModal';

export const AddOnContext = createContext<{ facilityId: number }>({ facilityId: 0 });

export const facilityMenu: INavigation = {
  key: 'facility',
  icon: 1,
  name: 'navStick.facility',
  tag: 'facility',
  path: '/search/facility',
  roles: [userRoles.all],
};

export const FacilityPreferences = props => {
  useInjectReducer({ key: facilityPreferenceSliceKey, reducer: facilityPreferenceReducer });
  useInjectSaga({ key: facilityPreferenceSliceKey, saga: facilityPreferenceSaga });
  useInjectSaga({ key: notificationDataKey, saga: notificationSaga });
  useInjectSaga({ key: FacilityUnitKey, saga: facilityUnitsSaga });
  useInjectReducer({ key: FacilityUnitKey, reducer: FacilityUnitReducer });

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const facilityId = parseInt(id);

  const facilityBookingtemplate = useSelector(selectFacilitytemplate);
  const existingUnits = useSelector(selectExistingUnitsForFacility);
  const dialogOpen = useSelector(selectConfirmationDialog);
  const {
    wfcToRfo,
    candidateTasks,
    confirmationTemplates,
    facilityTemplate,
    rfoToSent,
    sendPacket: apiSendPacket,
    savePacket,
    submissionEmailSetup,
    submissionPacketSetup,
  } = useSelector(selectFacilityPreferences);
  const { userInfo } = useSelector(selectUser);
  const preferenceChanged = useSelector(selectPreferenceChanged);
  const tabValue = useSelector(selectTabValue);
  const isBookingFacilityDirty = useSelector(selectBookingFacilityDirty);
  const clientContacts = useSelector(selectFacilityContacts);
  const categoryTitles = useSelector(selectPacketCategoryTitles);
  const selectedData = useSelector(selectPreferenceSelection);
  const updateDialogOpen = useSelector(selectUpdatePreferenceDialog);
  const [openClearChangesConfirmation, setOpenClearChangesConfirmation] = useState(false);
  const { disableEmailContainer } = useSelector(selectMiscellaneousDate);
  const facilityData = useSelector(selectFacility);
  const facilityName = facilityData?.facilityResponse?.facilityName;
  const amnDivisionTypeId = facilityData?.facilityResponse?.amnDivisionTypeId;
  const [rfoFlag] = useDecision(ff_facility_ui_newpreference_rfo_to_sent);
  const history = useHistory();
  const [triggerExit, setTriggerExit] = useState({
    isExit: false,
    path: '',
  });
  const [saveDialogOpen, SetSaveDialogOpen] = useState<boolean>(false);
  const [rows, setRows] = useState<any[]>([...extractRows(existingUnits)]);

  const navToUnitDetails = (row: any) => {
    const id = row['unitId'];
    trackEvent({
      type: 'click',
      name: 'Facility unit is clicked',
      properties: { unitId: id },
    });
    dispatch(
      facilityActions.getSelectedUnitDetail({
        unitId: id,
      }),
    );
  };
  const [columns, setColumns] = useState<any>(getExistngUnitColumns(navToUnitDetails, t));

  const defaultFormValues = {
    maxFilesSent: false,
    reason: null,
    submissionLimit: { noLimit: false, limit: null },
    wfcToRfo: false,
    candidateTasks: false,
    rfoToSent: false,
    sendPacket: { save: false, saveAndSend: false, doNotSave: false },
    packetTemplate: initialPacketState,
    emailSettings: '',
    emailTemplate: null,
    attentionTo: [],
    sendTo: [],
    noteToFacility: '',
  };

  const formMethods = useForm({
    defaultValues: defaultFormValues,
    resolver: !disableEmailContainer ? yupResolver(validationSchema(t)) : null,
    mode: 'onChange',
  });

  const {
    formState: { dirtyFields },
    reset,
    handleSubmit,
    getValues,
    errors,
  } = formMethods;

  const isDirty = Object.keys(dirtyFields).length !== 0;

  const handleDiscardAction = () => {
    resetForm();
    SetSaveDialogOpen(false);
    handleUpdateDialog(true);
  };
  const handleSaveAction = () => {
    onSubmit(getValues(), true);
    SetSaveDialogOpen(false);
  };

  const handleResetColumns = () => {
    setColumns(getExistngUnitColumns(navToUnitDetails, t));
  };

  const handleGridRefresh = () => {
    dispatch(facilityPreferenceActions.getExistingUnitPreferences({ facilityId }));
  };

  const handleUpdateDialog = (isOpen: boolean) => {
    dispatch(facilityPreferenceActions.setPreferenceUpdateDialog(isOpen));
    dispatch(
      gridSelectionActions.setGridSelections({
        selectedData: [],
      }),
    );
    dispatch(facilityPreferenceActions.setPreferenceSelection([]));
  };

  const saveDialogActions = [
    {
      text: t(`autoTaskManagement.button.cancel`),
      onClick: () => SetSaveDialogOpen(false),
    },
    {
      text: t(`autoTaskManagement.button.discard`),
      onClick: handleDiscardAction,
      color: 'tertiary',
      variant: 'contained',
    },
    {
      text: t(`autoTaskManagement.button.save`),
      variant: 'contained',
      color: 'primary',
      onClick: handleSaveAction,
    },
  ];

  const defaultFacilityTemplate = useMemo(() => {
    if (facilityTemplate && confirmationTemplates) {
      return confirmationTemplates.find(template => template.value === String(facilityTemplate.confirmationTemplateId));
    }
  }, [facilityTemplate, confirmationTemplates]);

  const { promiseInProgress: isSaving } = usePromiseTracker({
    area: `update-facility-preferences-${placementStage.submission}`,
    delay: 0,
  });

  //resetting form based on selector values
  const resetForm = React.useCallback(() => {
    const packetSetup: ISubmissionPacket = isValidJSONString(submissionPacketSetup)
      ? JSON.parse(submissionPacketSetup)
      : null;

    const emailSetup: ISubmissionEmail = isValidJSONString(submissionEmailSetup)
      ? JSON.parse(submissionEmailSetup)
      : null;

    const _sendPacket = rfoToSent
      ? {
          save: savePacket && !apiSendPacket,
          saveAndSend: apiSendPacket && savePacket,
          doNotSave: !savePacket,
        }
      : initialSendPacketState;

    const disablePacketContainer = !((_sendPacket.saveAndSend || _sendPacket.save) && rfoToSent && rfoFlag.enabled);
    dispatch(
      facilityPreferenceActions.setMiscellaneousData({
        key: 'disablePacketContainer',
        value: disablePacketContainer,
      }),
    );

    const disableEmailContainer = !(_sendPacket.saveAndSend && rfoToSent && rfoFlag.enabled);
    dispatch(
      facilityPreferenceActions.setMiscellaneousData({
        key: 'disableEmailContainer',
        value: disableEmailContainer,
      }),
    );

    const _attentionTo = deriveAttentionToOpts(clientContacts, packetSetup?.attentionTo, disablePacketContainer);

    const _sendTo = deriveSendToOpts(clientContacts, emailSetup?.sendTo, disableEmailContainer);

    reset({
      wfcToRfo,
      candidateTasks,
      rfoToSent,
      sendPacket: _sendPacket,
      packetTemplate: disablePacketContainer ? initialPacketState : packetSetup?.packetTemplate,
      emailSettings: disableEmailContainer ? null : emailSetup?.bulkEmailSettings,
      emailTemplate: emailSetup?.emailTemplateId ?? categoryTitles?.[0]?.id ?? null,
      attentionTo: _attentionTo,
      sendTo: _sendTo,
      noteToFacility: disablePacketContainer ? '' : packetSetup?.noteToFacility,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    apiSendPacket,
    candidateTasks,
    categoryTitles,
    clientContacts,
    rfoToSent,
    savePacket,
    submissionEmailSetup,
    submissionPacketSetup,
    wfcToRfo,
    rfoFlag,
  ]);

  const submissionPreferenceSubmit = (data: any, isCopyingPreferences = false) => {
    dispatch(facilityPreferenceActions.updateFacilityPreferences({ data, isCopyingPreferences }));
  };

  const bookingContentSubmit = () => {
    dispatch(
      facilityPreferenceActions.updateFacilityTemplate({
        facilityId: facilityId,
        confirmationTemplateId: Number(
          !!facilityBookingtemplate ? facilityBookingtemplate.value : defaultFacilityTemplate.value,
        ),
        updatedBy: userInfo.employeeId,
        confirmationTemplateName: !!facilityBookingtemplate
          ? facilityBookingtemplate.name
          : defaultFacilityTemplate.name,
        facilityForm: true,
      }),
    );
    dispatch(facilityPreferenceActions.setIBookingFacilityDirty(false));
    dispatch(facilityPreferenceActions.setBookingFacilityReset(false));
  };

  const onSubmit = (data, isCopyingPreferences = false) => {
    if (isDirty) {
      const payload = {
        facilityId,
        rfoToSent: data?.rfoToSent,
        sendPacket: data.sendPacket.saveAndSend,
        savePacket: data.sendPacket.save || data.sendPacket.saveAndSend,
        submissionEmailSetup: data.sendPacket.saveAndSend ? getFormattedSubmissionEmail(data) : null,
        submissionPacketSetup:
          data.sendPacket.save || data.sendPacket.saveAndSend ? getFormattedSubmissionPacket(data) : null,
        wfcToRfo: data?.wfcToRfo,
        candidateTasks: data?.candidateTasks,
        updatedBy: userInfo.employeeId,
      };
      if (!payload.sendPacket || (payload.sendPacket && data?.sendTo?.length > 0)) {
        submissionPreferenceSubmit(payload, isCopyingPreferences);
      }
    }
    if (isBookingFacilityDirty) {
      bookingContentSubmit();
    }
  };

  useEffect(() => {
    if (isDirty !== preferenceChanged) {
      dispatch(facilityPreferenceActions.setIsPreferenceChaged(isDirty));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  const handleKeepChanges = () => {
    dispatch(facilityPreferenceActions.setConfirmationDialog(false));
    setTriggerExit(obj => ({
      ...obj,
      path: location.pathname,
      isExit: false,
    }));
    dispatch(navigationStickActions.setSelectedMenu(facilityMenu));
  };

  const discardChanges = () => {
    dispatch(facilityPreferenceActions.setConfirmationDialog(false));
    dispatch(facilityPreferenceActions.setIsPreferenceChaged(false));
    setTriggerExit(obj => ({
      ...obj,
      isExit: true,
    }));
  };

  const handleClearChangesConfirmClose = (clearValues: boolean) => {
    setOpenClearChangesConfirmation(false);
    if (clearValues) {
      resetForm();
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(
    () => resetForm(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      wfcToRfo,
      candidateTasks,
      rfoToSent,
      savePacket,
      apiSendPacket,
      submissionPacketSetup,
      submissionEmailSetup,
      rfoFlag,
      clientContacts,
    ],
  );

  const handleGoToIntendedLocation = useCallback(
    location => {
      history.push({ pathname: location, search: tabValue !== 4 ? `tab=${tabValue}` : null });
    },
    [history, tabValue],
  );

  useEffect(() => {
    if (triggerExit.isExit) {
      handleGoToIntendedLocation(triggerExit.path);
    }
    //@ts-ignore
    const unblock = history.block(loc => {
      // eslint-disable-next-line no-restricted-globals
      if (loc.pathname !== location.pathname && isDirty) {
        dispatch(facilityPreferenceActions.setConfirmationDialog(true));
      }
      setTriggerExit(obj => ({ ...obj, path: loc.pathname }));
      if (triggerExit.isExit || !isDirty) {
        return true;
      }
      return false;
    });

    return () => {
      unblock();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleGoToIntendedLocation, history, triggerExit.isExit, triggerExit.path, isDirty]);

  useEffect(() => {
    dispatch(
      facilityPreferenceActions.getExistingUnitPreferences({ facilityId: facilityData.facilityResponse?.facilityId }),
    );
    dispatch(facilityPreferenceActions.getFacilityPreferences({ facilityId, stage: null }));
    dispatch(facilityPreferenceActions.getConfirmationTemplates());
    dispatch(facilityPreferenceActions.getFacilityTemplate({ facilityId }));
    dispatch(notificationDataActions.getTemplatesByCategory({ category: TemplateCategory.PacketSubmission }));
    dispatch(
      notificationDataActions.getFacilityTemplatesByCategory({
        category: TemplateCategory.PacketSubmission,
        userType: TemplateUserTypes.Facility,
        userId: facilityId,
      }),
    );
    dispatch(notificationDataActions.getFacilityBaseTemplate({ userType: TemplateUserTypes.Facility }));
    dispatch(facilityPreferenceActions.getActivePlacementCounts({ facilityId }));
    dispatch(FacilityUnitActions.requestFacilityUnitsList({ id: facilityId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityData.facilityResponse?.facilityId]);

  useEffect(() => {
    return () => {
      dispatch(facilityPreferenceActions.setConfirmationDialog(false));
      dispatch(facilityPreferenceActions.setTabValue(0));
      dispatch(facilityPreferenceActions.setIsPreferenceChaged(false));
      dispatch(facilityPreferenceActions.setPreferences({ ...initialState }));
    };
  }, [dispatch, facilityId]);

  useEffect(() => setRows(extractRows(existingUnits)), [existingUnits]);

  const savePreferencesToFacility = () => {
    const payload = {
      unitIds: selectedData,
      facilityId,
      updatedBy: userInfo.employeeId,
    };
    dispatch(
      facilityPreferenceActions.updatePrefrencesToFacility({
        data: payload,
        facilityId: facilityId,
      }),
    );
    handleUpdateDialog(false);
    dispatch(
      gridSelectionActions.setGridSelections({
        selectedData: [],
      }),
    );
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(data => onSubmit(data, false))}>
        <AddOnContext.Provider value={{ facilityId }}>
          <React.Fragment>
            <Expandable expanded titleComponent={<Title>{t('Submission')}</Title>} unmountOnExit={false}>
              <LayoutGrid direction="column" spacing={2} data-testid="preference-layout">
                <LayoutGridItem>
                  <SubmissionPreferences
                    isStrike={amnDivisionTypeId === AMNDivisionType.hsg}
                    resetForm={resetForm}
                    isDirty={isDirty}
                  />
                </LayoutGridItem>
                {amnDivisionTypeId !== AMNDivisionType.hsg && (
                  <LayoutGridItem>
                    <BookingPreferences />
                  </LayoutGridItem>
                )}
                <LayoutGridItem>
                  <ActionButtonWrapper
                    disableApplyButton={isDirty || errors?.hasOwnProperty('sendTo')}
                    disableSubmit={(!isBookingFacilityDirty && !isDirty) || errors?.hasOwnProperty('sendTo')}
                    disableCancel={!isBookingFacilityDirty && !isDirty}
                    isSaving={isSaving}
                    openClearChangesConfirmation={openClearChangesConfirmation}
                    handleClearChanges={() => setOpenClearChangesConfirmation(true)}
                    handleClearChangesConfirmClose={handleClearChangesConfirmClose}
                    handleApplyButtonClick={() => (!isDirty ? handleUpdateDialog(true) : SetSaveDialogOpen(true))}
                    showApplyButton={Authorized(
                      [
                        userRoles.accountManagement,
                        userRoles.accountManagement_Leadership,
                        userRoles.accountManagement_TeamMember,
                      ],
                      userInfo,
                    )}
                    ApplyButtonTitle={t('order.orderDetails.buttonApplyToUnits')}
                  />
                  <ConfirmationDialog
                    dialogActions={saveDialogOpen ? saveDialogActions : undefined}
                    dialogeOpen={dialogOpen || saveDialogOpen}
                    keepChanges={handleKeepChanges}
                    handleDiscard={discardChanges}
                    dialogTitle={saveDialogOpen && t(`autoTaskManagement.dialogModal.saveChanges`)}
                    dialogeText={saveDialogOpen && t(`autoTaskManagement.dialogModal.saveAndExit`)}
                  />
                  {updateDialogOpen && (
                    <UpdatePreferencesModal
                      open={updateDialogOpen}
                      isExistingItems={existingUnits.length > 0}
                      dialogTitleText={t(`Apply to Units: ${facilityName} (FID ${facilityId})`)}
                      onCancel={() => handleUpdateDialog(false)}
                      onConfirm={savePreferencesToFacility}
                      isSubmittingAction={!selectedData.length}
                      tableTitle={'Units'}
                      tableData={rows}
                      columns={columns}
                      onResetColumns={handleResetColumns}
                      onGridRefresh={handleGridRefresh}
                      selectedData={selectedData}
                    />
                  )}
                </LayoutGridItem>
              </LayoutGrid>
            </Expandable>
            <ConfirmationDialog
              dialogeOpen={dialogOpen}
              keepChanges={handleKeepChanges}
              handleDiscard={discardChanges}
            />
          </React.Fragment>
        </AddOnContext.Provider>
      </form>
    </FormProvider>
  );
};
