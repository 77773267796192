import { TextField, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import { LayoutGridItem } from 'app/layout/LayoutGrid';
import React from 'react';

const useStyles = makeStyles()(theme=>({
  saveAsDialog: {
    width: '650px',
    maxWidth: '650px',
  },
  subtitle: {
    fontWeight: 'bold',
    color: '#333333',
    marginBottom: '10px',
    marginTop: '25px',
  },
  filterName: {
    marginTop: '10px',
    marginBottom: '20px',
    height: '56px',
    width: '600px',
    fontSize: '12px',
    backgroundColor: theme.palette.components.textfield.blankField.backgroundColor, 
    '& .MuiFormHelperText-root.Mui-error': {
      margin: '12px !important',
    },
    '& .MuiFilledInput-input': {
      padding: '18px',
      fontSize: '14px',
      '&:hover': {
        backgroundColor: '#F5F5F5',
      },
    },
  },
  input: {
    color: 'red',
  },
  chips: {
    marginTop: '18px',
  },
  chipsLayout: {
    '& .MuiCollapse-root': {
      minHeight: '82px !important',
    },
    '& .css-1dg6z5a-MuiGrid-root-collapse': {
      maxHeight: '82px !important',
      overflowY: 'scroll'
    },
    '& .css-1u9i8he-MuiGrid-root-root': {
      maxHeight: '82px !important',
      overflowY: 'scroll'
    },
    '& .css-8xdhdq-collapse': {
      maxHeight: '82px !important',
      overflowY: 'scroll'
    }
},
}))

export const SaveAsModal = ({
  isSaveAsModalOpen,
  setSaveAsModalOpen,
  disableSaveButton,
  handleFilterNameChange,
  addNewFilter,
  errorText,
  chips,
}) => {
  const { classes } = useStyles();
  return (
    <GenericDialog
      classes={{ paper: classes.saveAsDialog }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      open={isSaveAsModalOpen}
      onClose={() => setSaveAsModalOpen(false)}
      variant="blue"
      dialogTitleProps={{
        id: 'alert-dialog-title',
        text: 'Save Filters As',
        closeButton: true,
      }}
      dialogActions={[
        {
          text: 'Cancel',
          variant: 'text',
          color: 'tertiary',
          useDialogOnCloseEvent: true,
        },
        {
          disabled: disableSaveButton,
          text: 'Save',
          variant: 'contained',
          color: 'primary',
          onClick: () => addNewFilter(),
        },
      ]}
    >
      <Typography className={classes.subtitle}>{`Enter a Filter Set name to Save Filters`}</Typography>
      <TextField
        variant="filled"
        error={errorText.length > 0}
        InputProps={{
          className: errorText.length > 0 ? classes.input : '',
        }}
        helperText={errorText}
        className={classes.filterName}
        onChange={e => handleFilterNameChange(e.target.value.trim())}
        placeholder={`Add Filter Name`}
      />
      <div className={errorText.length > 0 ? classes.chips : ''}>
        {chips && <LayoutGridItem id="details-page-chips" className={classes.chipsLayout}>{chips}</LayoutGridItem>}
      </div>
    </GenericDialog>
  );
};
