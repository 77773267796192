/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Grid, ThemeProvider, StyledEngineProvider,useTheme } from 'amn-ui-core';
import { reqPref } from 'app/enums/Unit';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReqPrefSection } from '../Common/ReqPrefSection';
import { UnitCardsTitle, UnitDetailsBody } from '../UnitDesign';
import { SimpleCheckbox } from '../Common/SimpleCheckbox';
import { NewNonStandardComboSection } from './NewNonStandardComboSection';

interface Props {
  disableEdit?: boolean;
  hasRadio?: boolean;
}

export const NewNonStandardRequirements = (props: Props) => {
  const { disableEdit } = props;
  const { t } = useTranslation();
  const { control, watch, setValue } = useFormContext();
  const watchValue = watch('nonStandardRequirement');
  const theme = useTheme();
  const [showPanel, setShowPanel] = React.useState(
    watchValue !== undefined && watchValue !== null && Array.isArray(watchValue) && watchValue.length > 0,
  );
  const [yesNoOptionSelected, setYesNoOptionSelected] = React.useState<boolean>(
    watchValue !== undefined && watchValue !== null && Array.isArray(watchValue) && watchValue.length > 0,
  );
  const [isRequired, setIsRequired] = React.useState<boolean>(true);

  const handleCheckBoxChangeX = (event: React.ChangeEvent<HTMLInputElement>) => {
    //const existingValues = getValues();
    if (event.target.checked) {
      setValue('nonStandardRequirement', []);
      /*reset({
        ...existingValues,
        nonStandardRequirement: [],
      });*/
    } else {
      setValue('nonStandardRequirement', null);
      /*reset({
        ...existingValues,
        nonStandardRequirement: null,
      });*/
    }
    setYesNoOptionSelected(event.target.checked);
    setShowPanel(event.target.checked);
  };

  const handleReqPrefCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsRequired(event.target.value === reqPref.req);
  };

  const onNSUnitPropChange = (property: string, value: any) => {
    //const existingValues = getValues();
    setValue('nonStandardRequirement', value);
    /*reset({
      ...existingValues,
      nonStandardRequirement: value,
    });*/
  };

  return (
    <>
      {/* TODO: Remove this theme wrapper since it uses the global theme */}
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <UnitDetailsBody variant="outlined">
            <Grid container direction="column" spacing={1}>
              <Grid item xs={12}>
                <Grid container direction="row" spacing={1}>
                  <Grid item>
                    <UnitCardsTitle>{t('order.createUnit.nonStandardRequirement.title')}</UnitCardsTitle>
                  </Grid>
                  <Controller
                    control={control}
                    name={'nonStandardRequirement'}
                    render={({ ref, onChange, ...rest }) => (
                      <SimpleCheckbox
                        id={'createUnitNonStandardRequirementEnableCheck'}
                        isChecked={yesNoOptionSelected ? true : false}
                        isdisabled={disableEdit}
                        hasRadio={false}
                        handleChecked={handleCheckBoxChangeX}
                        checkboxLabel={`Yes`}
                        checkboxName={'no-cbx'}
                      />
                    )}
                  />
                </Grid>
                {showPanel && (
                  <>
                    <ReqPrefSection
                      isRequired={isRequired}
                      handleReqPref={handleReqPrefCheck}
                      isdisabled={disableEdit}
                      id={'createUnitNonStandardRequirementReqPrefRadio'}
                    />

                    <Grid container direction="row">
                      <NewNonStandardComboSection
                        isRequired={isRequired}
                        setIsRequired={setIsRequired}
                        onSkillAddition={onNSUnitPropChange}
                        nonStandardData={watchValue}
                        isdisabled={disableEdit}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </UnitDetailsBody>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
};
