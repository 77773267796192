import { XGridDefaultColumn } from '@AMIEWEB/Common/XGrid/cells/XGridDefaults';
import { GridColumns } from '@mui/x-data-grid-pro';
import React from 'react';
import { CertificationSettingRenderCell } from './CertificationSetting';
import { SubCertificationRenderCell } from './SubCertificationDropdown';

const defaultTaskWordDeskColumn = {
  ...XGridDefaultColumn,
  flex: 0,
};

export const orderCertificationColumns = (isDisabled, t): GridColumns => [
  {
    ...defaultTaskWordDeskColumn,
    field: 'certificationName',
    headerName: t('order.certification.certificationsTitle'),
    width: 210,
    hide: false,
    sortable: false,
  },
  {
    ...defaultTaskWordDeskColumn,
    headerName: t('order.certification.subCertificate'),
    field: 'state',
    width: 210,
    hide: false,
    sortable: false,
    renderCell: params => <SubCertificationRenderCell param={params} isDisabled={isDisabled} />,
  },
  {
    ...defaultTaskWordDeskColumn,
    headerName: t('order.license.setting'),
    field: 'setting',
    width: 240,
    hide: false,
    sortable: false,
    renderCell: params => <CertificationSettingRenderCell param={params} isDisabled={isDisabled} />,
  },
];
