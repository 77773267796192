import { tooltipType } from 'app/components/Common/Tooltips';
import { formatToAmericanNotaion } from 'app/helpers/numberHelper';
import { GridColumns } from '@mui/x-data-grid-pro';
import { XGridContactHoverView } from 'app/components/Common/XGrid/cells/HoverViewRenderers/XGridContactHoverView';
import { XGridBlueCell } from 'app/components/Common/XGrid/cells/XGridBlueCell';
import { XGridDefaultColumn } from 'app/components/Common/XGrid/cells/XGridDefaults';
import { XGridLinkCell } from 'app/components/Common/XGrid/cells/XGridLinkCell';
import { XGridSideBarViewCell } from 'app/components/Common/XGrid/cells/XGridSideBarViewCell';
import { XGridOverflowCell } from 'app/components/Common/XGrid/cells/XGridOverflowCell';
import { ICustomHeader } from 'app/models/Grid';

interface IGridColumns extends Omit<ICustomHeader, 'title'> {
  title:
    | 'orderId'
    | 'vmsReqNo'
    | 'positionsAvailable'
    | 'filesPending'
    | 'filesOut'
    | 'mspClient'
    | 'affiliate'
    | 'facility'
    | 'unit'
    | 'skillsets'
    | 'shifts'
    | 'paysGww'
    | 'guaranteedHours'
    | 'expectedHours'
    | 'reason'
    | 'orderBillRate'
    | 'startdate'
    | 'orderEndDate'
    | 'weeklyGrossPay'
    | 'accountManager'
    | 'city'
    | 'stateName'
    | 'zipCode'
    | 'division'
    | 'orderType'
    | 'facilityStatus'
    | 'orderAge'
    | 'dateVerified'
    | 'mfs'
    | 'businessRelationship'
    | 'orderPriorities'
    | 'covidVaccinationRequired'
    | 'staffingVendor'
    | 'technologyVendor'
    | 'region';
}

const OrderDefaultCell = {
  ...XGridDefaultColumn,
  flex: 0,
};

export const orderViewInfo = (t, exclude = [] as string[]): GridColumns => [
  {
    ...OrderDefaultCell,
    field: 'orderId',
    headerName: t('search.globalSearch.order.gridTags.orderId'),
    width: 150,
    renderCell: XGridOverflowCell(XGridSideBarViewCell('/order/{0}', ['orderId'], true))(
      XGridSideBarViewCell('/order/{0}', ['orderId']),
    ),
  },
  {
    ...OrderDefaultCell,
    field: 'vmsReqNo',
    headerName: t('search.globalSearch.order.gridTags.vmsReqNo'),
    width: 150,
  },
  {
    ...OrderDefaultCell,
    field: 'positionsAvailable',
    headerName: t('search.globalSearch.order.gridTags.positionsAvailable'),
    width: 120,
  },
  {
    ...OrderDefaultCell,
    field: 'maxFilesSent',
    headerName: t('search.globalSearch.order.gridTags.maxFilesSent'),
    width: 100,
  },
  {
    ...OrderDefaultCell,
    field: 'filesPending',
    headerName: t('search.globalSearch.order.gridTags.filesPending'),
    width: 120,
    description: 'Status includes WFC and RFO',
  },
  {
    ...OrderDefaultCell,
    field: 'filesOut',
    headerName: t('search.globalSearch.order.gridTags.filesOut'),
    width: 100,
    description: 'Status includes Sent-Offer',
  },
  {
    ...OrderDefaultCell,
    field: 'mspClient',
    headerName: t('search.globalSearch.order.gridTags.mspClient'),
    width: 150,
  },
  {
    ...OrderDefaultCell,
    field: 'affiliate',
    headerName: t('search.globalSearch.order.gridTags.affiliate'),
    width: 150,
  },
  {
    ...OrderDefaultCell,
    field: 'facility',
    headerName: t('search.globalSearch.order.gridTags.facility'),
    width: 280,
    renderCell: XGridOverflowCell(XGridLinkCell('/facility/{0}', ['facilityId'], true))(
      XGridLinkCell('/facility/{0}', ['facilityId']),
    ),
  },
  {
    ...OrderDefaultCell,
    field: 'unit',
    headerName: t('search.globalSearch.order.gridTags.unit'),
    width: 150,
  },
  {
    ...OrderDefaultCell,
    field: 'skillsets',
    headerName: t('search.globalSearch.order.gridTags.skillSet'),
    width: 150,
  },
  {
    ...OrderDefaultCell,
    field: 'shifts',
    headerName: t('search.globalSearch.order.gridTags.shifts'),
    width: 150,
  },
  {
    ...OrderDefaultCell,
    field: 'paysGww',
    headerName: t('search.globalSearch.order.gridTags.paysGww'),
    width: 150,
  },
  {
    ...OrderDefaultCell,
    field: 'guaranteedHours',
    headerName: t('search.globalSearch.order.gridTags.guaranteedHours'),
    width: 150,
  },
  {
    ...OrderDefaultCell,
    field: 'expectedHours',
    headerName: t('search.globalSearch.order.gridTags.expectedHours'),
    width: 150,
  },
  {
    ...OrderDefaultCell,
    field: 'reason',
    headerName: t('search.globalSearch.order.gridTags.reason'),
    width: 150,
  },
  {
    ...OrderDefaultCell,
    field: 'orderBillRate',
    headerName: t('search.globalSearch.order.gridTags.orderBillRate'),
    width: 150,
  },
  {
    ...OrderDefaultCell,
    field: 'startdate',
    headerName: t('search.globalSearch.order.gridTags.startdate'),
    width: 100,
  },
  {
    ...OrderDefaultCell,
    field: 'endDate',
    headerName: t('search.globalSearch.order.gridTags.orderEndDate'),
    width: 100,
  },
  {
    ...OrderDefaultCell,
    field: 'orderPriority',
    headerName: t('search.globalSearch.order.gridTags.orderPriority'),
    width: 280,
  },
  {
    ...OrderDefaultCell,
    field: 'weeklyGrossPay',
    headerName: t('search.globalSearch.order.gridTags.weeklyGrossPay'),
    width: 150,
  },
  {
    ...OrderDefaultCell,
    field: 'accountManager',
    headerName: t('search.globalSearch.order.gridTags.accountManager'),
    width: 145,
    renderCell: XGridOverflowCell(XGridContactHoverView('accountManagerAttributes'), true)(XGridBlueCell),
  },
  {
    ...OrderDefaultCell,
    field: 'city',
    headerName: t('search.globalSearch.order.gridTags.city'),
    width: 130,
  },
  {
    ...OrderDefaultCell,
    field: 'stateName',
    headerName: t('search.globalSearch.order.gridTags.state'),
    width: 90,
  },
  {
    ...OrderDefaultCell,
    field: 'zipCode',
    headerName: t('search.globalSearch.order.gridTags.zip'),
    width: 120,
  },
  {
    ...OrderDefaultCell,
    field: 'division',
    headerName: t('search.globalSearch.order.gridTags.division'),
    width: 90,
  },
  {
    ...OrderDefaultCell,
    field: 'orderType',
    headerName: t('search.globalSearch.order.gridTags.type'),
    width: 90,
  },
  {
    ...OrderDefaultCell,
    field: 'businessRelationship',
    headerName: t('search.globalSearch.order.gridTags.businessRelationship'),
    width: 180,
  },
  {
    ...OrderDefaultCell,
    field: 'facilityStatus',
    headerName: t('search.globalSearch.order.gridTags.facilityStatus'),
    width: 130,
  },
  {
    ...OrderDefaultCell,
    field: 'dateEntered',
    headerName: t('search.globalSearch.order.gridTags.ordersAge'),
    width: 100,
  },
  {
    ...OrderDefaultCell,
    field: 'dateVerified',
    headerName: t('search.globalSearch.order.gridTags.dateVerified'),
    width: 100,
  },
  {
    ...OrderDefaultCell,
    field: 'covidVaccinationRequired',
    headerName: t('search.globalSearch.order.gridTags.covidVaccinationRequired'),
    width: 90,
    description: 'COVID Vaccination Required',
  },
  {
    ...OrderDefaultCell,
    field: 'staffingVendor',
    headerName: t('search.globalSearch.order.gridTags.staffingVendor'),
    width: 145,
  },
  {
    ...OrderDefaultCell,
    field: 'techVendor',
    headerName: t('search.globalSearch.order.gridTags.techVendor'),
    width: 145,
  },
  {
    ...OrderDefaultCell,
    field: 'region',
    headerName: t('search.globalSearch.order.gridTags.region'),
    width: 145,
  },
];

export const orderColumnVisibilityModel = {
  orderId: true,
  vmsReqNo: true,
  positionsAvailable: true,
  filesPending: true,
  filesOut: true,
  mspClient: true,
  affiliate: true,
  facility: true,
  unit: true,
  skillsets: true,
  shifts: true,
  paysGww: true,
  guaranteedHours: true,
  expectedHours: true,
  reason: true,
  orderBillRate: true,
  startdate: true,
  endDate: true,
  orderPriority: true,
  weeklyGrossPay: true,
  accountManager: true,
  city: true,
  state: true,
  zipCode: true,
  division: true,
  orderType: true,
  businessRelationship: true,
  facilityStatus: true,
  dateEntered: true,
  dateVerified: true,
  covidVaccinationRequired: true,
  staffingVendor: true,
  techVendor: true,
  region: false,
};

export const orderViewInfo_Old = t =>
  [
    {
      title: 'orderId',
      value: 'Order ID',
      clickable: true,
      sortable: true,
      defaultSort: true,
      sortByField: 'orderSortable',
    },
    {
      title: 'vmsReqNo',
      value: 'VMS Req #',
      sortable: true,
      tooltip: tooltipType.CustomTooltip,
      tooltipAttributes: 'vmsReqNoAttributes',
    },
    { title: 'positionsAvailable', value: 'Positions', sortable: true },
    { title: 'mfs', value: 'MFS', sortable: true },
    {
      title: 'filesPending',
      value: 'Files Pending',
      sortable: true,
      isNumber: true,
      columnHeaderHoverView: true,
      columnHeaderHoverText: 'Status includes WFC and RFO',
    },
    {
      title: 'filesOut',
      value: 'Files Out',
      sortable: true,
      columnHeaderHoverView: true,
      columnHeaderHoverText: 'Status includes Sent-Offer',
    },
    {
      title: 'facility',
      value: 'Facility',
      clickable: true,
      sortable: true,
      defaultSort: true,
      navigationIds: ['facilityId'],
      navigationExp: '/facility/{0}',
      tooltip: tooltipType.OverflowTooltip,
      tooltipAttributes: 'facilityAttributes',
    },
    {
      title: 'unit',
      value: 'Unit',
      sortable: true,
      defaultSort: true,
      tooltip: tooltipType.CustomTooltip,
      tooltipAttributes: 'unitAttributes',
    },
    {
      title: 'skillsets',
      value: 'Skillset',
      sortable: true,
      tooltip: tooltipType.CustomTooltip,
      tooltipAttributes: 'skillsetsAttributes',
    },
    {
      title: 'shifts',
      value: 'Shifts',
      sortable: true,
      tooltip: tooltipType.CustomTooltip,
      tooltipAttributes: 'shiftsAttributes',
    },
    { title: 'startdate', value: 'Start Date', sortable: true, sortByField: 'startDateSortable' },
    {
      title: 'orderPriorities',
      value: 'Order Priorities',
      sortable: true,
      tooltip: tooltipType.CustomTooltip,
      tooltipAttributes: 'orderPrioritiesAttributes',
    },
    { title: 'weeklyGrossPay', value: 'Weekly Gross Pay', sortable: true, isNumber: true },
    {
      title: 'accountManager',
      value: 'Account Manager',
      sortable: true,
    },
    { title: 'city', value: 'City', sortable: true },
    { title: 'stateName', value: 'State', sortable: true },
    { title: 'zipCode', value: 'ZIP Code', sortable: true },
    { title: 'division', value: 'Division', sortable: true, defaultSort: true },
    { title: 'orderType', value: 'Type', sortable: true },
    { title: 'businessRelationship', value: 'Business Relationship', sortable: true },
    { title: 'facilityStatus', value: 'Facility Status', sortable: true },
    { title: 'orderAge', value: 'Order Age', sortable: true },
    { title: 'dateVerified', value: 'Last Verified', sortable: true },
    {
      title: 'covidVaccinationRequired',
      value: 'Vaccinated',
      sortable: true,
      columnHeaderHoverView: true,
      columnHeaderHoverText: 'Covid Vaccination Required',
    },
    {
      title: 'staffingVendor',
      value: 'Staffing Vendor',
      sortable: true,
      tooltip: tooltipType.CustomTooltip,
      tooltipAttributes: 'staffingVendorAttributes',
    },
    {
      title: 'technologyVendor',
      value: 'Tech Vendor',
      sortable: true,
      tooltip: tooltipType.CustomTooltip,
      tooltipAttributes: 'technologyVendorAttributes',
    },
    { title: 'region', value: 'Region', sortable: true },
  ] as IGridColumns[];

/* enum values are named w.r.t api service call*/
export enum orderSearchFilterName {
  division = 'divisionIds',
  businessRelationship = 'businessRelationshipIds',
  facilityStatus = 'facilityStatusIds',
  orderType = 'orderTypeIds',
  skillsetFilter = 'skillsetFilter',
  discipline = 'disciplineIds',
  specialty = 'specialtyIds',
  subSpecialty = 'subSpecialtyIds',
  state = 'states',
  compactState = 'compactStates',
  city = 'city',
  zip = 'zipCode',
  radius = 'radius',
  facility = 'facilityIds',
  mspClient = 'mspClientIds',
  contractAffiliates = 'affiliateIds',
  orderAgeDateRange = 'dateEnteredRange',
  orderEndDateRange = 'endDateRange',
  orderBillRate = 'orderBillRate',
  paysGww = 'paysGww',
  guaranteedHours = 'guaranteedHours',
  expectedHours = 'expectedHours',
  reason = 'reasonids',
  education = 'education',
  accountManager = 'amUserIds',
  orderPriority = 'orderPriorityIds',
  shift = 'shiftIds',
  startDateRange = 'orderStartDateRange',
  mfs = 'maxFilesSentId',
  positions = 'positions',
  vaccinated = 'vaccinatedIds',
  weeklyGrossPay = 'weeklyGrossPay',
  orderIds = 'orderIds',
  region = 'regionIds',
}

export const defaultFilterValues = {
  [orderSearchFilterName.division]: null,
  [orderSearchFilterName.businessRelationship]: null,
  [orderSearchFilterName.facilityStatus]: null,
  [orderSearchFilterName.orderType]: null,
  [orderSearchFilterName.skillsetFilter]: null,
  [orderSearchFilterName.discipline]: null,
  [orderSearchFilterName.specialty]: null,
  [orderSearchFilterName.subSpecialty]: null,
  [orderSearchFilterName.compactState]: null,
  [orderSearchFilterName.state]: null,
  [orderSearchFilterName.facility]: null,
  [orderSearchFilterName.mspClient]: null,
  [orderSearchFilterName.contractAffiliates]: null,
  [orderSearchFilterName.orderAgeDateRange]: null,
  [orderSearchFilterName.orderEndDateRange]: null,
  [orderSearchFilterName.orderBillRate]: null,
  [orderSearchFilterName.paysGww]: null,
  [orderSearchFilterName.guaranteedHours]: null,
  [orderSearchFilterName.expectedHours]: null,
  [orderSearchFilterName.reason]: null,
  [orderSearchFilterName.education]: null,
  [orderSearchFilterName.accountManager]: null,
  [orderSearchFilterName.orderPriority]: null,
  [orderSearchFilterName.shift]: null,
  [orderSearchFilterName.startDateRange]: null,
  [orderSearchFilterName.mfs]: null,
  [orderSearchFilterName.positions]: null,
  [orderSearchFilterName.vaccinated]: null,
  [orderSearchFilterName.weeklyGrossPay]: null,
  [orderSearchFilterName.orderIds]: null,
  [orderSearchFilterName.region]: null,
};

// GET Grid Title For Order

export const gridTitleOrder = positionCount =>
  positionCount ? ` / Positions (${formatToAmericanNotaion(positionCount)})` : undefined;
