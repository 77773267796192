import React from 'react';
import { missingField } from 'app/constants';
import { MoreTextBlock } from '../../CustomComponents/MoreTextBlock';

export const OrderInfoRequiredQualifications = ({ ...props }) => (
  <div id="placement-order-info-required-qualifications" data-testid="order-info-required-qualifications-item">
    <MoreTextBlock
      title={''}
      text={
        props.orderInformation?.requiredQualifications
          ? props.orderInformation?.requiredQualifications
          : missingField
      }
      charLimit={170}
      id={'placementDetailsTabsRequiredQualificationsDetails'}
    />
  </div>
);
