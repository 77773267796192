import { getPlacementOrderTypeValue } from 'app/models/Placement/PlacementDetails';
import { isRFO, isTA } from '@AMIEWEB/Placement/NextPlacementStatus/StatusDefaults';
import { ICueNoteEmployees, ICueNoteTransformed, ISelectedCueNoteStatus } from './model';
import { missingField } from 'app/constants';
import moment from 'moment';
import { TimeOffMode } from '@AMIEWEB/Common/TimeOffRequest';
import { IPlacementCandidate, IPlacementOrder } from 'store/redux-store/new-placement/types';
import { ActiveStatusType, AMNDivisionType } from 'app/models/enums/AMNDivisionType';

const formatCueNoteEmployeeList = (cueNoteEmployees: ICueNoteEmployees[]): ICueNoteTransformed[] => {
  const transformedCueNotes: ICueNoteTransformed[] = cueNoteEmployees?.length
    ? cueNoteEmployees.map(cueNoteItem => ({
        name: `${cueNoteItem.firstName} ${cueNoteItem.lastName} - ${cueNoteItem.roleDescription}`,
        value: cueNoteItem.employeeId,
        role: cueNoteItem.roleDescription.includes('Account Manager') ? 'Account Manager' : 'Recruiter',
      }))
    : [];
  transformedCueNotes.push({ name: 'No Cue', value: undefined, role: 'No Cue' });
  return transformedCueNotes;
};

const isChecked = (selectedStatus: ISelectedCueNoteStatus) => {
  const selectedPlacementOrderType = getPlacementOrderTypeValue(selectedStatus.placementOrderType);
  if (selectedPlacementOrderType || selectedStatus.activityStatusId === ActiveStatusType.WFC) {
    return false;
  } else {
    return true;
  }
};

const generateDefaultCuteNoteText = (
  selectedStatus: ISelectedCueNoteStatus,
  formState,
  candidateData: IPlacementCandidate | null,
  order: IPlacementOrder | null,
) => {
  const isWFC = selectedStatus.activityStatusId === ActiveStatusType.WFC;
  const isTAStrike = selectedStatus.isTA && selectedStatus.placementOrderType;
  const isRfoNonStrikeByDivisionAMNNurse =
    selectedStatus.isRfo && !selectedStatus.placementOrderType && order.divisionTypeId === AMNDivisionType.amnNurse;
  const isRfoNonStrikeByDivisionNonAMNNurse =
    selectedStatus.isRfo && !selectedStatus.placementOrderType && order.divisionTypeId !== AMNDivisionType.amnNurse;
  if (isWFC) {
    return `Placement (New), Status: Waiting For Clearance`;
  } else if (isTAStrike) {
    return `Placement (New), Status: Traveler Accepts`;
  } else if (isRfoNonStrikeByDivisionAMNNurse) {
    return generateTextForRfo(formState, order);
  } else if (isRfoNonStrikeByDivisionNonAMNNurse) {
    return generateTextForRfoNotAmnNurse(formState, candidateData, order);
  }
};

const setupCueNoteData = (selectedStatus: ISelectedCueNoteStatus) => {
  return {
    ...selectedStatus,
    isRfo: isRFO(selectedStatus),
    isTA: isTA(selectedStatus),
    placementOrderType: getPlacementOrderTypeValue(selectedStatus.placementOrderType),
  };
};

/**
 *  Method to generate cue note text for rfo AMN Nurse
 * @param formState
 * @returns
 */
const generateTextForRfo = (formState, order: IPlacementOrder | null) => {
  let dynamicText = '';
  const maxChar = 4000;
  dynamicText += `Placement (New), Status: Request File Out\n`;
  dynamicText += `Available Start Date: ${getAvailableStartDate(formState?.availabilityDate)}\n`;
  dynamicText += `Contract Length: ${getPlacementStartEndDate(order, true)}\n`;
  dynamicText += `Requested Shift: ${formState?.requestedShift ? formState.requestedShift : missingField}\n`;
  dynamicText += `Travels with Partner: ${getTravelWithPartner(formState?.travel)}\n`;
  dynamicText += `Requested Time Off: ${getFormattedRequestedTimeOff(formState?.requestedTimeOff)}\n`;
  dynamicText += `${excludeTimeOffWithNoValue(formState?.requestedTimeOff)}`;
  dynamicText += `${excludeTimeOffNotesWithNoValue(formState?.requestedTimeOff?.requestedTimeOffNote)}`;
  dynamicText += `Additional Notes:`;
  return dynamicText?.length > maxChar ? dynamicText.substring(0, maxChar) : dynamicText;
};

/**
 * Method to generate cue note text for rfo not AMN Nurse
 * @param formState
 * @returns
 */
const generateTextForRfoNotAmnNurse = (
  formState,
  candidateData: IPlacementCandidate | null,
  order: IPlacementOrder | null,
) => {
  let dynamicText = '';
  const maxChar = 4000;
  dynamicText += `Placement (New), Status: Request File Out\n`;
  dynamicText += `Placement Date: ${getPlacementStartEndDate(order)}\n`;
  dynamicText += `Available Start Date: ${getAvailableStartDate(formState?.availabilityDate)}\n`;
  dynamicText += `Requested Shift:  ${formState?.requestedShift ? formState?.requestedShift : missingField}\n`;
  dynamicText += `Best Time to Call: ${formState?.bestTimeToCall ? formState?.bestTimeToCall : missingField}\n`;
  dynamicText += `Phone: ${candidateData.phone ? candidateData.phone : missingField}\n`;
  dynamicText += `Email: ${candidateData.email ? candidateData.email : missingField}\n`;
  dynamicText += `DOB:  ${candidateData?.dob ? moment(candidateData?.dob).format('MM/DD/YYYY') : missingField}\n`;
  dynamicText += `SSN: ${candidateData?.ssn ? candidateData?.ssn : missingField}\n`;
  dynamicText += `Travels with Partner: ${getTravelWithPartner(formState?.travel)}\n`;
  dynamicText += `Requested Time Off: ${getFormattedRequestedTimeOff(formState?.requestedTimeOff)}\n`;
  dynamicText += `${excludeTimeOffWithNoValue(formState?.requestedTimeOff)}`;
  dynamicText += `${excludeTimeOffNotesWithNoValue(formState?.requestedTimeOff?.requestedTimeOffNote)}`;
  dynamicText += `Selling Points: ${formState?.sellingPoints ?? missingField}\n`;
  return dynamicText?.length > maxChar ? dynamicText.substring(0, maxChar) : dynamicText;
};

/**
 *
 * @param order
 * @param isRfoAMNNurse
 * @returns
 */
const getPlacementStartEndDate = (order: IPlacementOrder | null, isRfoAMNNurse = false) => {
  if (isRfoAMNNurse) {
    const defaultContractDuration = '13 Weeks';
    return order?.startDate && order?.endDate ? getContractLength(order?.placementLength) : defaultContractDuration;
  } else {
    return `${setPlacementDate(order)} , Duration: ${getContractLength(order?.placementLength)}`;
  }
};

const setPlacementDate = (order: IPlacementOrder | null) => {
  if (order?.startDate && order?.endDate) {
    return `${moment(order?.startDate).format('MM/DD/YYYY')} - ${moment(order?.endDate).format('MM/DD/YYYY')}`;
  } else {
    return missingField;
  }
};

const getContractLength = (contractLength: string) => {
  return contractLength ? `${contractLength} Weeks` : missingField;
};

const excludeTimeOffNotesWithNoValue = requestedTimeOffNote => {
  return requestedTimeOffNote ? `Time Off Notes: ${requestedTimeOffNote ?? missingField}\n` : '';
};

const excludeTimeOffWithNoValue = requestedTimeOffData => {
  if (
    requestedTimeOffData.requestedTimeOffMode === TimeOffMode.ToBeDetermined ||
    requestedTimeOffData.requestedTimeOffMode === TimeOffMode.NotRequired
  ) {
    return ``;
  }
  return `Make Up for Time Off: ${getMakeUpForTimeOff(requestedTimeOffData)}\n`;
};

const getFormattedRequestedTimeOff = requestedTimeOffData => {
  if (requestedTimeOffData.requestedTimeOffMode === TimeOffMode.AddTimeOff) {
    return requestedTimeOffData?.requestedTimeOff
      ?.map(item => {
        if (item?.startDate && item?.endDate) {
          return `${moment(item.startDate).format('MM/DD/YYYY')} - ${moment(item.endDate).format('MM/DD/YYYY')}`;
        } else {
          return `${moment(item.startDate).format('MM/DD/YYYY')}`;
        }
      })
      .join(', ');
  } else if (requestedTimeOffData.requestedTimeOffMode === TimeOffMode.ToBeDetermined) {
    return `To Be Determined`;
  } else if (requestedTimeOffData.requestedTimeOffMode === TimeOffMode.NotRequired) {
    return `Not Required`;
  }
};

const getTravelWithPartner = travel => {
  return travel?.travelsWithPartner ? `Yes, ${travel?.travelPartner ?? ''}` : missingField;
};

const getAvailableStartDate = availabilityDate => {
  return availabilityDate ? moment(availabilityDate).format('MM/DD/YYYY') : missingField;
};

const getMakeUpForTimeOff = requestedTimeOffData => {
  if (requestedTimeOffData.requestedTimeOffMode === TimeOffMode.AddTimeOff) {
    if (requestedTimeOffData?.isRequestedTimeOffMakeUp) {
      return `Yes`;
    } else {
      return `No`;
    }
  } else {
    return missingField;
  }
};

const getCueNotEmployeeRequestPayload = formState => {
  return {
    placementId: 0,
    orderId: formState?.order?.object?.orderId,
    candidateId: formState?.candidate?.object?.candidateId,
    brandId: formState?.candidate?.object?.brandId,
    recruiterId: formState?.recruiter?.object?.id,
  };
};

export {
  formatCueNoteEmployeeList,
  isChecked,
  generateDefaultCuteNoteText,
  setupCueNoteData,
  getCueNotEmployeeRequestPayload,
};
