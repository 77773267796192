import { takeEvery, takeLatest } from 'redux-saga/effects';
import {
  requestPlacementProgress,
  requestRequiredPlacementData,
  requestHeaderDetails,
  requestPlacementQuickGlance,
  requestPlacementContacts,
  requestPlacementCandidatePlacementCounts,
  requestPlacementCandidateSellingPoints,
  requestStrikeStatus,
  requestCandidateTimeOffRequestsData,
  requestCandidateVerifiedSkillsetsData,
  getPlacementCandidatePlacementDetails,
  requestPlacementChangeHistory,
  requestPlacementStatusDetails,
  requestCandidateSSN,
  requestCandidateDOB,
  requestLandsGtgDetails,
  checkPlacementTimestamps,
  requestPassportChecklistItems,
  requestPlacementExtensionStatusDetails,
  requestExtTimeOffPayerOpts,
  requestExtDirectHireOpts,
  requestCredOneDetails,
  requestPlacementSupervisor,
  requestPlacementSupervisee,
  requestPlacementDetails,
  linkPlacementId,
  deletesupervisor,
  requestPlacementTags,
  placementTagsUpdate,
  requestComplianceStatusProgressNotes,
  searchComplianceStatusNotes,
} from './request.saga';
import { placementDetailsAction } from './slice';
import { placementStatusAction } from '../placement-status/slice';
import {
  updateKeyContact,
  updatePlacementRegion,
  saveSubmissionPacketsToCosmos,
  updateCandidateDOB,
  updateCandidateSSN,
  updateExtensionStatus,
  updateExtensionStatusDetails,
  updateFinalizedByQs,
  updatePlacementAll,
  updatePlacementStatus,
  createClassicRequestContract,
  updateEfoDocToOkToSend,
  saveComplianceStatusNote,
  saveComplianceStatusDocDeadline,
  updateComplianceStatusNotes,
  updateDirectHire,
  updateComplianceStatusComments,
  updateOrderIdForPlacement,
} from './update.saga';
import { onInit } from './business.saga';

export function* placementDetailsSaga() {
  yield takeLatest(placementDetailsAction.onInitAction.type, onInit);
  yield takeLatest(placementDetailsAction.getPlacementProgressAction, requestPlacementProgress);
  yield takeLatest(placementDetailsAction.getRequiredPlacementData, requestRequiredPlacementData);
  yield takeLatest(placementDetailsAction.getHeaderDetailsAction.type, requestHeaderDetails);
  yield takeLatest(placementDetailsAction.getQuickGlanceDetailsAction.type, requestPlacementQuickGlance);
  yield takeLatest(placementDetailsAction.getPlacementContactsAction.type, requestPlacementContacts);
  yield takeLatest(
    placementDetailsAction.getCandidateRecordsRightAction.type,
    requestPlacementCandidatePlacementCounts,
  );
  yield takeLatest(placementDetailsAction.getCandidateRecordsRightAction.type, requestPlacementCandidateSellingPoints);
  yield takeLatest(placementDetailsAction.getCandidateRecordsRightAction.type, requestStrikeStatus);
  yield takeLatest(placementDetailsAction.getCandidateRecordsRightAction.type, requestCandidateTimeOffRequestsData);
  yield takeLatest(placementDetailsAction.getCandidateRecordsRightAction.type, requestCandidateVerifiedSkillsetsData);
  yield takeLatest(placementDetailsAction.getModalCategoryData.type, getPlacementCandidatePlacementDetails);
  yield takeLatest(placementDetailsAction.getChangeHistory.type, requestPlacementChangeHistory);
  yield takeLatest(placementDetailsAction.getCandidateSSN.type, requestCandidateSSN);
  yield takeLatest(placementDetailsAction.updateCandidateSSN.type, updateCandidateSSN);
  yield takeLatest(placementDetailsAction.getCandidateDOB.type, requestCandidateDOB);
  yield takeLatest(placementDetailsAction.updateCandidateDOB.type, updateCandidateDOB);
  yield takeLatest(placementDetailsAction.getPlacementLandsGtgAction, requestLandsGtgDetails);
  yield takeLatest(placementDetailsAction.getCredOneAction, requestCredOneDetails);
  yield takeLatest(placementDetailsAction.validatePlacementTimestamp.type, checkPlacementTimestamps);
  yield takeLatest(placementDetailsAction.updatePlacementAllAction.type, updatePlacementAll);
  yield takeLatest(placementDetailsAction.getPassportChecklist.type, requestPassportChecklistItems);
  yield takeLatest(placementDetailsAction.updateFinalizedByQs.type, updateFinalizedByQs);
  yield takeLatest(placementDetailsAction.getExtTimeOffPayerOpts.type, requestExtTimeOffPayerOpts);
  yield takeLatest(placementDetailsAction.getExtDirectHireOpts.type, requestExtDirectHireOpts);
  yield takeEvery(placementDetailsAction.updateKeyContact.type, updateKeyContact);
  yield takeEvery(placementDetailsAction.updatePlacementRegion.type, updatePlacementRegion);
  yield takeLatest(placementDetailsAction.getPlacementSupervisorAction, requestPlacementSupervisor);
  yield takeLatest(placementDetailsAction.getPlacementSuperviseeAction, requestPlacementSupervisee);
  yield takeLatest(placementDetailsAction.getPlacementSupervisorDetailsAction, requestPlacementDetails);
  yield takeLatest(placementDetailsAction.getPlacementSupervisorLinkAction, linkPlacementId);
  yield takeLatest(placementDetailsAction.deletePlacementSupervision, deletesupervisor);
  yield takeLatest(placementDetailsAction.getPlacementTagsAction.type, requestPlacementTags);
  yield takeLatest(placementDetailsAction.updatePlacementTags, placementTagsUpdate);
  yield takeLatest(placementDetailsAction.getComplianceStatusProgress, requestComplianceStatusProgressNotes);
  yield takeLatest(placementDetailsAction.saveComplianceStatusNote.type, saveComplianceStatusNote);
  yield takeLatest(placementDetailsAction.saveComplianceStatusDocDeadline.type, saveComplianceStatusDocDeadline);
  yield takeLatest(placementDetailsAction.searchComplianceStatusNotes.type, searchComplianceStatusNotes);
  yield takeLatest(placementDetailsAction.updateComplianceStatusNotes.type, updateComplianceStatusNotes);
  yield takeLatest(placementDetailsAction.updateComplianceStatusComments.type, updateComplianceStatusComments);
  yield takeLatest(placementDetailsAction.updateDirectHire.type, updateDirectHire);
  yield takeLatest(placementDetailsAction.updateOrderIdForPlacement.type, updateOrderIdForPlacement);
  /** placementStatusAction - START */
  yield takeLatest(placementStatusAction.getPlacementStatusDetails.type, requestPlacementStatusDetails);
  yield takeLatest(placementStatusAction.updatePlacementStatusAction.type, updatePlacementStatus);
  yield takeLatest(placementStatusAction.createClassicRequestContract.type, createClassicRequestContract);
  yield takeLatest(placementStatusAction.getExtentionStatusDetails.type, requestPlacementExtensionStatusDetails);
  yield takeLatest(placementStatusAction.updateExtentionStatusDetails.type, updateExtensionStatusDetails);
  yield takeLatest(placementStatusAction.updateExtentionStatus.type, updateExtensionStatus);
  yield takeLatest(placementStatusAction.saveSubmissionPacketsToCosmos.type, saveSubmissionPacketsToCosmos);
  yield takeLatest(placementStatusAction.updateEfoDocToOkToSend.type, updateEfoDocToOkToSend);
  /** placementStatusAction - END */
}
