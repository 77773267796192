/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { TextField, Chip, Avatar, Box, debounce, Typography, useTheme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectEditUnit,
  selectOrderDetails,
  selectOrderRequirementTypes,
  selectStage,
} from '@AMIEWEB/Order/Store/OrderCreation.selectors';
import { useFormContext } from 'react-hook-form';
import { selectOrderCertification, selectOrderSubCertification } from '@AMIEWEB/Order/Store/Order.selectors';
import { IOrderCertification, orderDataActions } from '@AMIEWEB/Order/Store/Order.redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { orderCreationReducer, orderCreationSliceKey } from '@AMIEWEB/Order/Store/OrderCreation.redux';
import { orderCreationSaga } from '@AMIEWEB/Order/Store/OrderCreation.saga';
import { createOrderOcbrReducer, createOrderOcbrSliceKey } from 'store/redux-store/create-order-ocbr/slice';
import { createOrderOcbrSaga } from 'store/redux-store/create-order-ocbr/saga';
import { CustomTooltip } from '@AMIEWEB/Common/Tooltips/CustomTooltip';
import { CertificationDropdownGrid } from './CertificationDropdownGrid';
import { formatCertifications, transformCertificationData } from './helper';
import { Certifications } from 'app/models/Orders/OrderDetails';

const useStyles = makeStyles()(theme => ({
  searchField: {
    width: '100%',
    borderBottom: `1px solid ${theme.palette.system.lightGrey}`,
  },
  chip: {
    margin: '0 1px 1px 0',
  },

  wrapper: {
    backgroundColor: `${theme.palette.components.box.container.backgroundColor} !important`,
    border: `1px solid ${theme.palette.system.lightGrey}`,
    borderRadius: '5px',
    minHeight: '300px',
  },
  noBorder: {
    border: 'hidden',
  },
  chipWrapper: {
    paddingTop: '4px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  chipIcon: {
    fontSize: 'large',
    marginLeft: '6px',
  },
}));

interface Props {
  isDisabled?: boolean;
}

export const CertificationDropdownWrapper = (props: Props) => {
  /* eslint-disable i18next/no-literal-string */
  const { isDisabled } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();
  const theme = useTheme();
  useInjectReducer({ key: orderCreationSliceKey, reducer: orderCreationReducer });
  useInjectSaga({ key: orderCreationSliceKey, saga: orderCreationSaga });
  useInjectReducer({ key: createOrderOcbrSliceKey, reducer: createOrderOcbrReducer });
  useInjectSaga({ key: createOrderOcbrSliceKey, saga: createOrderOcbrSaga });
  const dispatch = useDispatch();
  const { certifications } = useSelector(selectOrderRequirementTypes);
  const orderCertifications = useSelector(selectOrderCertification);
  const subCertificationList = useSelector(selectOrderSubCertification);

  const { setValue } = useFormContext();
  const [cerificationRequirements, setCerificationRequirements] = useState<any[]>([]);
  const [isError, setIsError] = useState<boolean>(false);
  const [transformedLicenses, setTransformedLicenses] = useState<any[]>(null);
  const unitCertifications = useSelector(selectOrderDetails)?.unit?.qualification?.certifications;
  const editableUnitCertifications = useSelector(selectEditUnit)?.qualification?.certifications;
  const currentStage = useSelector(selectStage);
  const [certificationOptions, setCertificationOptions] = useState<any[]>([]);

  const getSelectionInfo = (searchQuery, options) => {
    if (!Array.isArray(options)) return [];
    const searchResult =
      searchQuery && searchQuery.length >= 2
        ? options?.filter(item =>
            `${item.abbreviation} - ${item.credentialName}`
              .toLowerCase()
              .replace(/\s|\.|\(|\)/g, '')
              .includes(searchQuery.toLowerCase().replace(/\s|\.|\(|\)/g, '')),
          )
        : options;
    if (searchQuery?.toUpperCase() === 'CERT') {
      const indexToMove = searchResult?.findIndex(obj => obj?.requirementTypeID === Certifications.CERT_CERTIFICATE);

      if (indexToMove !== -1) {
        const objectToMove = searchResult?.splice(indexToMove, 1)[0];

        searchResult?.unshift(objectToMove);
      }
    }
    return searchResult;
  };

  const handleSearch = event => {
    (async () => {
      if (Array.isArray(certificationOptions) && certificationOptions.length > 0) {
        setCerificationRequirements(getSelectionInfo(event.target.value, certificationOptions));
      }
    })();
  };

  const parseCertifications = () => {
    const certs = [];
    const subCerts = [];
    certifications?.map(rt => {
      rt.category ? certs.push(rt) : subCerts.push(rt);
    });
    setCertificationOptions(certs);
    dispatch(orderDataActions.setsubCertificationList(subCerts));
    if (Array.isArray(certs) && certs.length > 0) {
      setCerificationRequirements(getSelectionInfo('', certs));
    }
  };

  const debouncedSearchHandler = debounce(handleSearch, 300);

  const handleDelete = (certToDelete, data: IOrderCertification[]) => {
    if (Array.isArray(data) && data?.length > 0) {
      const certsAfterDeletion = data.filter(cert => cert.requirementTypeId !== certToDelete.requirementTypeId);
      dispatch(orderDataActions.setOrderCertificationDetails(certsAfterDeletion));
    }
  };

  const loadSavedCertifications = (savedCertificationList: any[]) => {
    const savedCertifications: IOrderCertification[] = [];
    if (certificationOptions?.length > 0 && subCertificationList?.length > 0) {
      savedCertificationList?.map(cert => {
        const isValidCert = certificationOptions?.some(
          parentCert => parentCert?.requirementTypeID === cert?.requirementTypeId,
        );
        const isValidSubCert = subCertificationList?.some(
          subCert => subCert?.requirementTypeID === cert?.requirementTypeId,
        );
        if (isValidSubCert || isValidCert) {
          const newCert: IOrderCertification = {
            abbreviation: cert?.abbreviation,
            documentAttachmentRequired: cert?.documentAttachmentRequired,
            isError: false,
            isRequired: cert?.isRequired,
            isSubCert: isValidSubCert,
            requirementTypeId: cert?.requirementTypeId,
          };
          savedCertifications.push(newCert);
        }
      });
      const isSubCertExists = savedCertifications?.some(
        item => item?.isSubCert === true && item?.requirementTypeId !== 122,
      );
      const parentCert = certificationOptions?.find(data => data?.requirementTypeID === 122);
      if (isSubCertExists) {
        const newCert: IOrderCertification = {
          abbreviation: parentCert?.abbreviation,
          documentAttachmentRequired: false,
          isError: false,
          isRequired: true,
          isSubCert: false,
          requirementTypeId: parentCert?.requirementTypeID,
        };
        savedCertifications.push(newCert);
      }
    }
    dispatch(orderDataActions.setOrderCertificationDetails(savedCertifications));
  };

  useEffect(() => {
    if (certifications?.length > 0) {
      parseCertifications();
    }
  }, [certifications]);

  useEffect(() => {
    if (Array.isArray(orderCertifications)) {
      const formattedCertifications = formatCertifications(orderCertifications);
      setValue('qualification.certifications', formattedCertifications);
      const isSubCertErrorExists = orderCertifications?.some(item => item?.isError === true);
      if (isSubCertErrorExists) {
        setIsError(true);
      } else {
        setIsError(false);
      }
      setTransformedLicenses(transformCertificationData(orderCertifications));
    }
  }, [orderCertifications]);

  useEffect(() => {
    if (currentStage !== 'createUnitDetails') {
      if (unitCertifications?.length && certificationOptions?.length) {
        loadSavedCertifications(unitCertifications);
      } else if (editableUnitCertifications?.length && certificationOptions?.length) {
        loadSavedCertifications(editableUnitCertifications);
      }
    }
  }, [unitCertifications, editableUnitCertifications, certificationOptions, subCertificationList]);

  return (
    <>
      <Box className={classes.wrapper}>
        <TextField
          variant="outlined"
          color="primary"
          placeholder={t('order.createUnit.qualifications.placeHolder')}
          id="txtCertification"
          autoComplete="on"
          onChange={debouncedSearchHandler}
          disabled={isDisabled}
          fullWidth
          classes={{ root: classes.searchField }}
          InputProps={{
            startAdornment: <SearchIcon style={{ opacity: 0.6 }} />,
            classes: { notchedOutline: classes.noBorder },
          }}
        />
        <CertificationDropdownGrid isDisabled={isDisabled} certificationList={cerificationRequirements} />
      </Box>
      {isError && (
        <Typography style={{ color: theme.palette.system.guardsmanRed, margin: '5px' }}>
          {t('order.requiredMessage')}
        </Typography>
      )}
      <div className={classes.chipWrapper} id={'createUnitQualificationsLicenseAddedItemContainer'}>
        {orderCertifications &&
          transformedLicenses?.map((item, idx) => {
            const isRequiredText = item?.isRequired ? t('order.createUnit.required') : t('order.createUnit.preferred');
            const tootltipContent = item.isSubCert ? `CERT - ${item.abbreviation}` : item.abbreviation;
            return (
              <div key={idx} style={{ paddingRight: '6px' }}>
                <CustomTooltip
                  tooltipContent={`(${isRequiredText}) ${tootltipContent}`}
                  placement="bottom"
                  isStandardContent={true}
                  contentStyle={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}
                >
                  <Chip
                    variant="outlined"
                    avatar={
                      <Avatar variant={item.isRequired ? 'primary' : 'green'}>{item.isRequired ? 'R' : 'P'}</Avatar>
                    }
                    disabled={isDisabled}
                    key={item?.requirementTypeId}
                    label={
                      <React.Fragment>
                        {item.isSubCert ? `CERT - ${item.abbreviation}` : item.abbreviation}
                        {item.documentAttachmentRequired && (
                          <DescriptionOutlinedIcon
                            style={{ fill: theme.palette.framework.system.skyBlue }}
                            className={classes.chipIcon}
                          />
                        )}
                      </React.Fragment>
                    }
                    className={classes.chip}
                    onDelete={() => handleDelete(item, orderCertifications)}
                  />
                </CustomTooltip>
              </div>
            );
          })}
      </div>
    </>
  );
};
