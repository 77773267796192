/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, useEffect, useMemo, useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import EMRList from 'app/assets/jsons/EMR.json';
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  TextField,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Checkbox,
  Chip,
  Avatar,
  Box,
  FormControl,
  Tooltip,
  debounce,
  Skeleton,
} from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { SimpleDropdown } from '../Common/SimpleDropdown';
import { yearsOfExperienceList } from 'app/constants';
import { formatYOEDropdown } from '../helper';

import { SkillCheckList, SkillCheckListChip, SkillcheckList } from 'app/models/Unit/CreateUnit';
import { selectSkillCheckListRequrements } from '@AMIEWEB/Order/Store/OrderCreation.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { requestOrderCreationFormatedSkillCheckListDetailsAction } from '@AMIEWEB/Order/Store/OrderCreation.actions';
import { Controller, useFormContext } from 'react-hook-form';
import { setLoadingSkillCheckListRequrementsStatus } from '@AMIEWEB/Order/Store/OrderCreation.redux';

const useStyles = makeStyles()(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    height: '15%',
    maxHeight: 300,
    overflow: 'auto',
    paddingTop: 0,
    paddingBottom: 0,
    '& .MuiList-padding': {
      paddingTop: 0,
    },
  },
  searchField: {
    height: '1%',
    width: '100%',
    borderBottom: '1px solid #CCCCCC',
  },
  chip: {
    margin: '0 1% 1% 0',
    maxWidth: '50ch',
  },

  wrapper: {
    backgroundColor: '#FFFFFF !important',
    border: '1px solid #CCCCCC',
    borderRadius: 5,
    minHeight: 300,
  },
  item: {
    borderBottom: '1px solid #EFEFEF',
    borderColor: '#f4f5f738',
  },
  noBorder: {
    border: 'hidden',
  },
  chipWrapper: {
    paddingTop: '0.8%',
  },
  dropDownStyle: {
    paddingLeft: '0px !important',
  },
  listItemContent: {
    display: '-webkit-box',
    maxWidth: '40ch',
    fontSize: 14,
    lineHeight: 1.4,
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  tooltip: {
    backgroundColor: '#ffffff',
    color: '#333333',
    opacity: 1,
    textAlign: 'left',
    letterSpacing: '0px',
    boxShadow: '0px 3px 6px #00000029',
    border: '1px solid #8C8C8C',
  },
  arrow: {
    color: '#ffffff',
    opacity: 1,
    '&::before': {
      boxShadow: '0px 3px 6px #00000029',
      border: '1px solid #8C8C8C',
    },
  },
  placementBottom: {
    marginBottom: 0,
  },
  icon: {
    top: 7,
    right: 5,
  },
  selectMenu: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  chipBlock: {
    display: 'flex',
  },
  chipText: {
    maxWidth: '20ch',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

interface Props {
  isdisabled?: boolean;
}

export const NewUnitSkillCheckList = (props: Props) => {
  /* eslint-disable i18next/no-literal-string */
  const { classes } = useStyles();
  const { isdisabled } = props;

  const dispatch = useDispatch();
  const { control, watch, setValue } = useFormContext();
  const unitSkillCheckList = watch('qualification.skillChecklists');
  const [searchResult, setSearchResult] = useState<SkillCheckList[] | null>();
  const [chips, setChips] = useState<SkillCheckListChip[] | []>();
  const [loaded, setLoaded] = useState<boolean>(false);

  const skillCheckListRequrements: SkillCheckList[] | null = useSelector(selectSkillCheckListRequrements);
  /**
   * This function is used to filter out results where the result's text
   * does not exist in the EMRList.
   *
   * @param {Array} results - An array of results to filter.
   * @param {Array} unitSkillCheckList - Not used in current function scope.
   * This parameter can be removed if not needed for future purposes.
   *
   * @returns {Array} An array of results that are not present in EMRList.
   *
   * The function improves performance by creating a Set from the EMRList names,
   * and using `has` function, which has a faster lookup time (O(1)) compared to an array (O(n)).
   * This optimization can have a significant impact if EMRList is large.
   */
  const getSelectionInfo = (results, unitSkillCheckList) => {
    const emrNamesSet = new Set(EMRList.map(emr => emr.name));
    return results.filter(search => !emrNamesSet.has(search.text));
  };

  /**
   * This function is used to map unitSkillCheckList to selectionInfo. It merges the
   * item from unitSkillCheckList into selectionInfo if an item with the same questionId exists.
   * It also removes any existing item in selectionInfo with the same questionId before adding
   * the new one, ensuring each questionId is unique in the returned selectionInfo array.
   *
   * @param {Array} unitSkillCheckList - Array of items that contains skill checklist data.
   * Each item in unitSkillCheckList is an object with properties like text, questionId,
   * isRequired, yearsOfExperience, partitionKey, id etc.
   *
   * @param {Array} selectionInfo - Array of SkillCheckList objects. Each SkillCheckList
   * object has properties like shortDesc, description, text, questionId, isRequired,
   * yearsOfExperience, isSelected, partitionKey, id etc.
   *
   * @returns {Array} An updated version of selectionInfo array. The returned array contains
   * the items from unitSkillCheckList merged into it based on questionId. Each item in
   * the returned array will have a unique questionId.
   *
   * Note: It is important that both unitSkillCheckList and selectionInfo have the property
   * questionId. The function uses questionId to find matching items in both arrays.
   */
  const mapData = (unitSkillCheckList: any, selectionInfo: SkillCheckList[]) => {
    unitSkillCheckList?.map(item => {
      const newItem = selectionInfo.find(d => d.questionId === item.questionId);
      selectionInfo = selectionInfo.filter(d => d.questionId !== item.questionId);
      selectionInfo.push({
        shortDesc: newItem ? newItem?.shortDesc : '',
        description: newItem ? newItem?.description : '',
        text: item?.text,
        questionId: item?.questionId,
        isRequired: item?.isRequired,
        yearsOfExperience: item?.yearsOfExperience === '' ? '0' : item?.yearsOfExperience,
        isSelected: true,
        partitionKey: item?.partitionKey,
        id: item?.id,
      });
    });
    return selectionInfo;
  };

  /**
   * This function is used to update the skill checklist with a new skill checklist array.
   * It first retrieves the existing values, sets the chips based on the new skill checklist,
   * and finally resets the form values with the new skill checklist.
   *
   * @param {Array} newSkillCheckList - New array of skill checklist items. Each item in the
   * newSkillCheckList is an object with properties like questionId, text, isRequired,
   * and yearsOfExperience.
   *
   * getValues() - This is an external function that retrieves the current form values.
   *
   * setChips() - This is an external function that updates the chips (visual elements
   * that represent the skill checklist items on the UI) based on the provided array.
   *
   * reset() - This is an external function that resets the form values with the provided object.
   *
   * Note: The external functions getValues, setChips, and reset are not defined in this code.
   * Make sure they are correctly defined and imported in the context of this function.
   */
  const updateSkillCheckList = newSkillCheckList => {
    //const existingValues = getValues();
    setChips(
      newSkillCheckList?.map((item, indx) => {
        return {
          id: item.questionId,
          text: item.text,
          isRequired: item.isRequired,
          yearsOfExperience: item.yearsOfExperience,
        };
      }) || [],
    );
    setValue('qualification.skillChecklists', newSkillCheckList);
    /*reset({
      ...existingValues,
      qualification: {
        ...existingValues.qualification,
        skillChecklists: newSkillCheckList,
      },
    });*/
  };

  /**
   * This function is responsible for selecting or deselecting a skill checklist item based on the requirementTypeID.
   * If the checklist item with the specified requirementTypeID exists in skillCheckListRequirements,
   * it will toggle the selection status of the checklist item in unitSkillCheckList.
   * If the checklist item does not exist in skillCheckListRequirements,
   * it will remove the checklist item from unitSkillCheckList if it exists.
   *
   * @param {number} requirementTypeID - The ID of the requirement type to be selected or deselected.
   *
   * skillCheckListRequrements - An external array that holds the original skill checklist items.
   * unitSkillCheckList - An external array that holds the currently selected skill checklist items.
   * updateSkillCheckList() - An external function that updates unitSkillCheckList.
   * setLoaded() - An external function that updates the loading status of the component.
   *
   * Note: The external arrays and functions are not defined in this code.
   * Make sure they are correctly defined and imported in the context of this function.
   */
  const selectItem = (requirementTypeID: number, data: SkillcheckList[] | null) => {
    if (!skillCheckListRequrements) return;

    const skillCheckListItem = skillCheckListRequrements.find(x => x.questionId === requirementTypeID);

    if (!!skillCheckListItem) {
      const newSkillCheckListItem = {
        questionId: skillCheckListItem.questionId,
        text: skillCheckListItem.text,
        isRequired: true,
        yearsOfExperience: skillCheckListItem.yearsOfExperience ?? 0,
      };
      if (!!data) {
        const newSkillCheckList =
          !!data && data?.some(x => x.questionId === requirementTypeID)
            ? data.filter(x => x.questionId !== requirementTypeID)
            : [...data, newSkillCheckListItem];
        updateSkillCheckList(newSkillCheckList);
      } else {
        updateSkillCheckList([newSkillCheckListItem]);
      }
    } else {
      if (!!data) {
        const newSkillCheckList = data.filter(x => x.questionId !== requirementTypeID);
        updateSkillCheckList(newSkillCheckList);
        const selectionInfo: SkillCheckList[] = getSelectionInfo(skillCheckListRequrements, data);
        setSearchResult(mapData(newSkillCheckList, selectionInfo));
      } else {
        updateSkillCheckList([]);
        const selectionInfo: SkillCheckList[] = getSelectionInfo(skillCheckListRequrements, []);
        setSearchResult(mapData([], selectionInfo));
      }
    }
  };

  /**
   * This function updates the 'isRequired' property of a specific skill checklist item in unitSkillCheckList.
   * The specific skill checklist item is identified by its 'questionId' property, which should match 'requirementTypeID'.
   * 'isRequired' is set based on the value of the radio button selected in the user interface.
   * If the radio button value is 'req', 'isRequired' is set to true, otherwise, it's set to false.
   *
   * @param {object} event - The event object generated by the radio button onChange event.
   * @param {object} skillCheckList - The skill checklist item object that contains properties like 'questionId', etc.
   *
   * unitSkillCheckList - An external array that holds the currently selected skill checklist items.
   * updateSkillCheckList() - An external function that updates unitSkillCheckList.
   *
   * Note: The external array and function are not defined in this code.
   * Make sure they are correctly defined and imported in the context of this function.
   */
  const handleChange = (event, skillCheckList, data: SkillcheckList[] | null) => {
    const requirementTypeID = skillCheckList.questionId;
    const isRequiredVal = event.target.value === 'req';

    if (Array.isArray(data) && data.length > 0) {
      const newSkillCheckList = data.map(item => {
        if (item.questionId === requirementTypeID) {
          return {
            ...item,
            isRequired: isRequiredVal,
          };
        }
        return item;
      });

      // update
      updateSkillCheckList(newSkillCheckList);
    }
  };

  /**
   * This function updates the 'yearsOfExperience' property of a specific skill checklist item in unitSkillCheckList.
   * The specific skill checklist item is identified by its 'questionId' property, which should match 'requirementTypeID'.
   * 'yearsOfExperience' is set based on the value selected from a dropdown in the user interface.
   *
   * @param {object} event - The event object generated by the dropdown onChange event.
   * @param {number} id - The 'questionId' of the skill checklist item to update.
   *
   * unitSkillCheckList - An external array that holds the currently selected skill checklist items.
   * updateSkillCheckList() - An external function that updates unitSkillCheckList.
   *
   * Note: The external array and function are not defined in this code.
   * Make sure they are correctly defined and imported in the context of this function.
   */
  const handleSelectYear = (event, id, data: SkillcheckList[] | null) => {
    const requirementTypeID = id;
    const numberOfYears = event.target.value;
    if (Array.isArray(data) && data.length > 0) {
      const newSkillCheckList = data.map(item => {
        if (item.questionId === requirementTypeID) {
          return {
            ...item,
            yearsOfExperience: numberOfYears,
          };
        }
        return item;
      });
      //update
      updateSkillCheckList(newSkillCheckList);
    }
  };

  const handleSearch = async event => {
    (async () => {
      setLoaded(false);
      dispatch(requestOrderCreationFormatedSkillCheckListDetailsAction({ searchQuery: event.target.value }));
      setLoaded(true);
    })();
  };

  const debouncedSearchHandler = debounce(handleSearch, 300);

  const handleDelete = (id, data: SkillcheckList[] | null) => {
    selectItem(id, data);
  };

  const handleToggle = (requirementTypeID: number, data: SkillcheckList[] | null) => () => {
    selectItem(requirementTypeID, data);
  };

  useEffect(() => {
    dispatch(setLoadingSkillCheckListRequrementsStatus(true));
    const selectionInfo: SkillCheckList[] = getSelectionInfo(skillCheckListRequrements, unitSkillCheckList);
    setSearchResult(mapData(unitSkillCheckList, selectionInfo));

    setChips(
      unitSkillCheckList?.map((item, indx) => {
        return {
          id: item.questionId,
          text: item.text,
          isRequired: item.isRequired,
          yearsOfExperience: item.yearsOfExperience,
        };
      }) || [],
    );

    setLoaded(true);
    dispatch(setLoadingSkillCheckListRequrementsStatus(false));
  }, []);

  useMemo(() => {
    if (loaded === true) {
      const selectionInfo: SkillCheckList[] = getSelectionInfo(skillCheckListRequrements, unitSkillCheckList);
      setSearchResult(mapData(unitSkillCheckList, selectionInfo));
    }
  }, [skillCheckListRequrements, loaded]);

  const sortResults = (a, b) => (a.text > b.text ? 1 : a.text === b.text ? (a.questionId > b.questionId ? 1 : -1) : -1);

  /*
    Calculate SkillCheckList to be displayed
  */
  const SearchResultItem = ({
    value,
    unitSkillCheckList,
    isdisabled,
    handleToggle,
    handleChange,
    handleSelectYear,
    labelId,
    data,
  }) => {
    const skillCheckLists = unitSkillCheckList?.filter(x => x.questionId === value.questionId);
    const skillCheckList = skillCheckLists && skillCheckLists.length > 0 ? skillCheckLists[0] : null;

    return (
      <ListItem
        key={value.questionId}
        role={undefined}
        dense
        button
        disabled={isdisabled}
        onClick={handleToggle(value.questionId, data)}
        className={classes.item}
      >
        <ListItemIcon>
          <Checkbox
            edge="start"
            disableFocusRipple
            disableRipple
            disableTouchRipple
            disabled={isdisabled}
            checked={!!skillCheckList}
            tabIndex={-1}
            inputProps={{ 'aria-labelledby': labelId }}
            color="primary"
          />
        </ListItemIcon>
        <Tooltip
          title={`${value.text}`}
          placement="bottom"
          arrow
          classes={{
            tooltip: classes.tooltip,
            arrow: classes.arrow,
            tooltipPlacementBottom: classes.placementBottom,
          }}
        >
          <ListItemText
            classes={{
              primary: classes.listItemContent,
            }}
            id={labelId}
            primary={`${value.text}`}
          />
        </Tooltip>
        <ListItemSecondaryAction>
          {!!skillCheckList && (
            <Grid container>
              <Grid item>
                <RadioGroup aria-label="Required" name="required" onChange={e => handleChange(e, value, data)} row>
                  <FormControlLabel
                    disabled={isdisabled}
                    value="req"
                    control={<Radio color="primary" checked={!!skillCheckList && skillCheckList.isRequired === true} />}
                    label="Req"
                  />
                  <FormControlLabel
                    disabled={isdisabled}
                    value="pref"
                    control={
                      <Radio color="primary" checked={!!skillCheckList && skillCheckList.isRequired === false} />
                    }
                    label="Pref"
                  />
                </RadioGroup>
              </Grid>
              <Grid item>
                {
                  <FormControl variant="outlined">
                    <SimpleDropdown
                      onChange={event => {
                        handleSelectYear(event, value.questionId, data);
                      }}
                      defaultInputList={yearsOfExperienceList}
                      defaultInputValue={(!!skillCheckList && skillCheckList.yearsOfExperience) || 0}
                      formattingFunc={formatYOEDropdown}
                      disabled={isdisabled}
                      inputName="experienceYearsOfExperience"
                      iconStyle={classes.icon}
                      dropDownStyle={classes.dropDownStyle}
                      inputPaddingLeft={'0px'}
                      bodyMarginTop={'3%'}
                      selectMenuStyle={classes.selectMenu}
                      value={!!skillCheckList && skillCheckList.yearsOfExperience}
                      size="small"
                    />
                  </FormControl>
                }
              </Grid>
            </Grid>
          )}
        </ListItemSecondaryAction>
      </ListItem>
    );
  };

  const sortedSearchResults = useMemo(() => searchResult?.sort(sortResults), [searchResult]);

  return (
    <>
      <Box className={classes.wrapper}>
        <TextField
          variant="outlined"
          color="primary"
          placeholder="Search"
          id="txtSkillCheckList"
          autoComplete="on"
          onChange={debouncedSearchHandler}
          fullWidth
          disabled={isdisabled}
          classes={{ root: classes.searchField }}
          InputProps={{
            startAdornment: <SearchIcon style={{ opacity: 0.6 }} />,
            classes: { notchedOutline: classes.noBorder },
          }}
        />
        <List className={classes.root} id={'createUnitQualificationsskillChecklistSearchResultListContainer'}>
          <Controller
            control={control}
            name={'qualification.skillChecklists'}
            render={({ ref, onChange, ...rest }) => {
              return (
                <Suspense fallback={<Skeleton variant="rectangular" />}>
                  {sortedSearchResults &&
                    sortedSearchResults.map((value, index) => {
                      const labelId = `checkbox-list-label-${value}`;
                      return (
                        <SearchResultItem
                          key={value.questionId}
                          value={value}
                          unitSkillCheckList={rest.value}
                          isdisabled={isdisabled}
                          handleToggle={handleToggle}
                          handleChange={handleChange}
                          handleSelectYear={handleSelectYear}
                          labelId={labelId}
                          data={rest.value}
                        />
                      );
                    })}
                </Suspense>
              );
            }}
          />
        </List>
      </Box>
      <div className={classes.chipWrapper} id={'createUnitQualificationsskillChecklistAddedItemContainer'}>
        {chips &&
          chips.map(item => {
            return (
              <Chip
                disabled={isdisabled}
                variant="outlined"
                avatar={<Avatar variant={item.isRequired ? 'primary' : 'green'}>{item.isRequired ? 'R' : 'P'}</Avatar>}
                key={item.id}
                label={
                  <React.Fragment>
                    <div className={classes.chipBlock}>
                      <div className={classes.chipText}>{item.text}</div>
                      {item?.yearsOfExperience?.toString() === '0' || item?.yearsOfExperience?.toString() === '' ? (
                        ''
                      ) : (
                        <span>{` , ${item.yearsOfExperience}y`}</span>
                      )}
                    </div>
                  </React.Fragment>
                }
                className={classes.chip}
                onDelete={() => handleDelete(item.id, unitSkillCheckList)}
              />
            );
          })}
      </div>
    </>
  );
};
