import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IWorkExperienceView } from './types';
import { businessActions } from './business-actions';
import { serviceActions } from './async-actions';

export const initialState: IWorkExperienceView = {
  workExperienceData: [],
  editWorkExperienceAPIStatusCode: null,
  referenceListByWorkExperience: [],
  selectedWorkExperience: null,
};

const slice = createSlice({
  name: 'candidateWorkExperienceView',
  initialState,
  reducers: {
    setWorkExperience(state, action) {
      return { ...state, workExperienceData: action.payload };
    },
    setEditWorkExperienceAPIStatusCode(state, action: PayloadAction<any>) {
      return { ...state, editWorkExperienceAPIStatusCode: action.payload };
    },
    setSelectedWorkExperience(state, action) {
      return { ...state, selectedWorkExperience: action.payload };
    },
  },
});

export const { name: workExperienceSliceKey, reducer: workExperienceReducer } = slice;

export const workExperienceActions = { ...slice.actions, ...businessActions, ...serviceActions };
