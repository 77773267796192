import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { facilityActions } from '@AMIEWEB/Facility/FacilityStore/Facility.redux';
import { selectFacility, selectFacilityInstructions } from '@AMIEWEB/Facility/FacilityStore/Facility.selector';
import { useTranslation } from 'react-i18next';
import { EditableFacilityTextCard } from './EditableFacilityTextCard';
import { selectUser } from 'oidc/user.selectors';
import { Typography } from 'amn-ui-core';
import { GenericDialog } from '@AMIEWEB/Alerts/GenericDialog';
import { postInstructionsDataPromiseKey } from '@AMIEWEB/Facility/FacilityStore/Facility.saga';
import { usePromiseTracker } from 'react-promise-tracker';
import { IUpdateFacilityInstructionsCommand } from '@AMIEWEB/Facility/FacilityStore/Facility.model';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { isAccountManagement } from 'oidc/userRoles';
import { GenericTabs, Orientations } from '@AMIEWEB/Common/Tabs/GenericTabs';
import { isPositiveNumber } from 'app/helpers/componentHelpers';
import { isEmpty } from 'lodash';

const InstructionsTab = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [modifiedText, setModifiedText] = useState<string>('');
  const [shouldShowDialog, setShouldShowDialog] = useState<boolean>(false);
  const [updatingUnits, setUpdatingUnits] = useState<boolean>(false);
  const facilityInstructions = useSelector(selectFacilityInstructions);
  const facilityData = useSelector(selectFacility);
  const userData = useSelector(selectUser);
  const { promiseInProgress: isBusyUpdating } = usePromiseTracker({ area: postInstructionsDataPromiseKey, delay: 0 });
  const { promiseInProgress: isBusyLoading } = usePromiseTracker({ area: 'get-facility-instructions', delay: 0 });

  useEffect(() => {
    if (isPositiveNumber(facilityData?.facilityResponse?.facilityId) && isEmpty(facilityInstructions))
      dispatch(facilityActions.getFacilityInstructions(facilityData.facilityResponse.facilityId));
  }, [facilityData?.facilityResponse?.facilityId]);

  useEffect(() => {
    if (shouldShowDialog && !isBusyUpdating) {
      setUpdatingUnits(false);
      setShouldShowDialog(false);
    }
  }, [isBusyUpdating]);

  const handleSaveClick = (text: string) => {
    setModifiedText(text);
    setShouldShowDialog(true);
  };

  const handleSave = (option: boolean) => {
    const payload: IUpdateFacilityInstructionsCommand = {
      firstDayInstructions: modifiedText,
      facilityId: facilityData.facilityResponse.facilityId,
      currentEmployeeId: userData.userInfo.employeeId,
      isUnitUpdateRequired: option,
      t: t,
    };
    setUpdatingUnits(option);
    dispatch(facilityActions.updateFacilityInstructions(payload));
    setModifiedText('');
  };

  const tabs = [
    {
      label: t('facility.instructionsTab.firstDayInstructions'),
      component: (
        <EditableFacilityTextCard
          title={t('facility.instructionsTab.firstDayInstructions')}
          showRightIcon={!isBusyLoading}
          leftIcon={<DescriptionOutlinedIcon />}
          text={facilityInstructions?.firstDayInstructions}
          onSave={handleSaveClick}
          isAuthorized={isAccountManagement(userData.userInfo.roles)}
        />
      ),
    },
  ];

  return (
    <div>
      <GenericTabs
        dense
        orientation={Orientations.vertical}
        tabs={tabs}
        title={t('facility.instructionsTab.instructions')}
      />
      <GenericDialog
        open={shouldShowDialog}
        dialogActions={[
          {
            text: t('global.button.cancel'),
            variant: 'text',
            color: 'tertiary',
            useDialogOnCloseEvent: true,
            disabled: isBusyUpdating,
          },
          {
            text: t('global.No'),
            variant: 'contained',
            color: 'tertiary',
            onClick: () => handleSave(false),
            loading: isBusyUpdating && !updatingUnits,
            disabled: isBusyUpdating,
          },
          {
            text: t('global.Yes'),
            variant: 'contained',
            color: 'primary',
            onClick: () => handleSave(true),
            loading: isBusyUpdating && updatingUnits,
            disabled: isBusyUpdating,
          },
        ]}
        dialogTitleProps={{ text: t('facility.instructionsTab.modalSaveTitle') }}
        draggable
        onClose={() => setShouldShowDialog(false)}
      >
        <Typography> {t('facility.instructionsTab.modalSaveBody')} </Typography>
      </GenericDialog>
    </div>
  );
};

export default InstructionsTab;
