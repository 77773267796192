/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Paper, Grid, Divider, Button } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { LayoutGrid } from 'app/layout/LayoutGrid';
import { SimpleCheckbox } from 'app/components/Unit/Common/SimpleCheckbox';
import { FormInputMultiRadioButton } from './Controls/FormInputMultiRadioButton';
import { GetMomentOfDate } from 'utils/dates/moment';
import { dates, getAddType, IsDateValid } from './PersonalInfoHelper';
import countries from 'app/assets/jsons/Country.json';
import states from 'app/assets/jsons/State.json';
import { FormInputDatePicker } from './Controls/FormInputDatePicker';
import { FormInputDropdown } from './Controls/FormInputDropdown';
import { FormInputText } from './Controls/FormInputText';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { candidateDetailsSelection } from '../../../Profile/CandidateDetails.selector';
import moment from 'moment';
import { candidateDetailActions } from '../../../Profile/CandidateDetails.redux';
import { useTranslation } from 'react-i18next';
import { FormInputDatePickerOptional } from './Controls/FormInputDatePickerOptional';
import { userRoles } from 'oidc/userRoles';
import { selectUser } from 'oidc/user.selectors';
import treeData from 'app/assets/jsons/placementStatusFilter.json';
import { Authorized } from 'oidc/userHelper';
import { AddRoleTypeSectionV2 } from './AddRoleTypeSectionsV2';
import { GenericDialog } from '@AMIEWEB/Alerts/GenericDialog';
import { globalActions } from 'app/ApplicationRoot/Global.redux';

const useStyles = makeStyles()(theme => ({
  root: {
    padding: '0px 10px 10px',
  },
  container: {
    width: '100%',
    boxShadow: 'none',
    border: '1px solid #e5e5e5',
    borderRadius: '4px',
  },
  divider: {
    margin: '10px 0px 0px 0px',
  },
  child: {
    overflow: 'hidden',
    fontSize: '14px',
  },
  addressButton: {
    backgroundColor: '#F7F7F7',
    borderRadius: '30px',
    border: '1px solid #f5f5f5',
    fontSize: '14px',
    height: '32px',
    color: '#000',
    '&:hover': {
      backgroundColor: '#00000014',
    },
  },
  allainment: {
    paddingTop: '10px',
    paddingLeft: '10px',
  },
  submitButton: {
    color: '#006FB9',
    textTransform: 'none',
  },
  innerdivider: {
    margin: '10px 20px 10px 20px',
  },
  addType: {
    margin: '0px 0px 0px 20px',
  },
  action: {
    display: 'flex',
    flexDirection: 'row',
  },
  actionBtn: {
    fontSize: 12,
    padding: 0,
    minWidth: 30,
  },
  actionNoBtn: {
    color: '#888888',
  },
  alignButton: {
    padding: 0,
    alignSelf: 'center',
  },
}));

export const AddAddressForm = props => {
  const { classes } = useStyles();
  const { errors, getValues, setValue, setError, clearErrors, trigger } = useFormContext();
  const { t } = useTranslation();

  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [isCheckBoxEnabled, setIsCheckBoxEnabled] = useState<boolean>(false);
  const [cityLabel, setCityLabel] = useState('City');
  const [zipLabel, setZipLabel] = useState('Zip/Postal Code');
  const [addType, setAddType] = useState('Other');
  const [addAddress1, setAddAddress1] = useState('');
  const [addMoveIn, setAddMoveIn] = useState('');
  const [opt, setOpt] = useState(true);
  const [alertOpen, setAlertOpen] = useState(false);
  const [dateAlertOpen, setDateAlertOpen] = useState(false);
  const [stateList, setStateList] = useState(states.states.filter(e => e.CountryID === 1));

  const candidateDetails = useSelector(candidateDetailsSelection);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  const booked = treeData.filter(item => item.name == 'Booked');
  const inSumbite = treeData
    .filter(item => item.name == 'In Submission')[0]
    .children.filter(x => x.value === '3' || x.value === '2');
  const inSumbitePrior = treeData
    .filter(item => item.name == 'In Submission')[0]
    .children.filter(x => x.value !== '3' && x.value !== '2');
  const Offer = treeData.filter(item => item.name == 'Offer');
  const allowedStatuses: any[] = [];
  const allowedPriorStatuses: any[] = [];

  booked[0].children.map(item => {
    allowedStatuses.push(item.name);
  });

  Offer[0].children.map(item => {
    allowedPriorStatuses.push(item.name);
  });

  inSumbite.map(item => {
    if (item.value === '3' || item.value === '2') allowedStatuses.push(item.name);
  });

  inSumbitePrior.map(item => {
    allowedPriorStatuses.push(item.name);
  });

  useEffect(() => {
    const addressRoleType = getValues().roleType;
    if (addressRoleType?.length == 0) clearErrors('Start1');
  }, []);

  //Events
  const handleCheck = e => {
    const currentTHAddress = candidateDetails.addressData?.filter(x => x.addressTypeId === 4);
    //If we have only one Tax Home.
    if (e?.target?.checked && isCheckBoxEnabled && currentTHAddress && currentTHAddress.length >= 1)
      bindAddress(currentTHAddress[0]);
    else initialValues();

    setIsChecked(e?.target?.checked);
  };

  const bindAddress = async data => {
    setValue(`MoveIn`, data?.dateMoveIn, { shouldDirty: true });
    setValue(`MoveOut`, data?.dateMoveOut, { shouldDirty: true });
    setValue(`MoveOutOptional`, data?.dateMoveOut, { shouldDirty: true });
    setValue(`Country`, data?.country, { shouldDirty: true });
    setValue(`Address1`, data?.address1, { shouldDirty: true });
    setValue(`Address2`, data?.address2, { shouldDirty: true });
    setValue(`City`, data?.city, { shouldDirty: true });
    setValue(`State`, data?.state, { shouldDirty: true });
    setValue(`Zip`, data?.zip, { shouldDirty: true });
    await trigger('Address1');
  };

  const handleMoveInDate = async event => {
    const MOId = opt ? `MoveOutOptional` : `MoveOut`;
    if (event && event !== '') {
      const MI = GetMomentOfDate(event).format('MM/DD/YYYY');
      const MO = GetMomentOfDate(getValues(MOId) ?? '').format('MM/DD/YYYY');

      const gt = dates.gt(MI, MO);
      const eq = dates.eq(MI, MO);

      setValue(`MoveIn`, MI);

      if (gt || eq) setError(`MoveIn`, { type: 'manual', message: 'Date has to be less than Move-Out date' });
      else clearErrors(`MoveIn`);
    }
  };

  const handleMoveOutDate = async event => {
    const MOId = opt ? `MoveOutOptional` : `MoveOut`;
    if (event && event !== '') {
      const MO = GetMomentOfDate(event).format('MM/DD/YYYY');
      const MI = GetMomentOfDate(getValues(`MoveIn`) ?? '').format('MM/DD/YYYY');

      const lt = dates.lt(MO, MI);
      const eq = dates.eq(MO, MI);

      setValue(MOId, MO);

      if (lt || eq) setError(MOId, { type: 'manual', message: 'Date has to be greater than Move-In date' });
      else clearErrors(MOId);
    } else {
      setValue(MOId, event);
    }
  };

  const isFormValid = data => {
    if (!opt && isEmpty(data?.MoveOut)) return false;
    if (isEmpty(data?.Address1) || isEmpty(data?.MoveIn) || isEmpty(data?.City) || isEmpty(data?.Zip)) return false;
    var isRoleTypesValid = true;
    const addressRoleType = getValues().roleType;
    if (addressRoleType?.length > 0) {
      [...Array(addressRoleType?.length)].map((e, i) => {
        if (isEmpty(getValues(`roleType[${i}].Start`))) isRoleTypesValid = false;

        if (isEmpty(getValues(`roleType[${i}].End`)) && !(getAddType(addType) == 2 || getAddType(addType) == 5))
          isRoleTypesValid = false;
      });
    }
    if (
      isEmpty(data?.Address1) ||
      isEmpty(data?.MoveIn) ||
      isEmpty(data?.City) ||
      isEmpty(data?.Zip) ||
      !isRoleTypesValid
    )
      return false;
    else return true;
  };

  const fireValidation = async data => {
    if (isEmpty(data?.MoveIn)) IsDateValid(setError, `MoveIn`, data?.MoveIn);
    if (!opt) {
      if (isEmpty(data?.MoveOut)) {
        IsDateValid(setError, `MoveOut`, data?.MoveOut);
        await trigger('MoveOut');
      }
    }

    if (isEmpty(data?.Address1)) await trigger(`Address1`);
    if (isEmpty(data?.City)) await trigger(`City`);
    if (isEmpty(data?.Zip)) await trigger(`Zip`);
    var addressRoleTypes = getValues().roleType;
    if (addressRoleTypes?.length > 0) {
      [...Array(addressRoleTypes?.length)].map(async (e, i) => {
        if (isEmpty(getValues(`roleType[${i}].Start`)))
          setError(`roleType[${i}].Start`, {
            type: 'required',
            message: 'Required',
          });

        if (isEmpty(getValues(`roleType[${i}].End`)) && (getAddType(addType) == 2 || getAddType(addType) == 5))
          setError(`roleType[${i}].End`, {
            type: 'required',
            message: 'Required',
          });
        if (
          isEmpty(getValues(`roleType[${i}].W2`)) &&
          isEmpty(getValues(`roleType[${i}].Paycheck`)) &&
          isEmpty(getValues(`roleType[${i}].Mailers`)) &&
          isEmpty(getValues(`roleType[${i}].Subsidy`)) &&
          isEmpty(getValues(`roleType[${i}].Cobra`)) &&
          isEmpty(getValues(`roleType[${i}].DependentCobra`))
        )
          setError(`roleType[${i}].CheckboxError`, {
            type: 'required',
            message: 'Role type selection cannot be empty',
          });
      });
    }
  };

  const isEmpty = (str: string | any) => {
    if (str === null || str === '' || str === undefined) return true;
    return false;
  };

  const getAddressRoleType = (roleTypes: any[], index) => {
    const roleType = roleTypes[index];
    var addressRoles: any = [];
    addressRoles.push({
      description: 'Cobra',
      addressRoleId: 5,
      selected: roleType.Cobra ? 1 : 0,
    });
    addressRoles.push({
      description: 'Dependent Cobra',
      addressRoleId: 6,
      selected: roleType.DependentCobra ? 1 : 0,
    });
    addressRoles.push({
      description: 'Mailers',
      addressRoleId: 4,
      selected: roleType.Mailers ? 1 : 0,
    });
    addressRoles.push({
      description: 'Paycheck',
      addressRoleId: 2,
      selected: roleType.Paycheck ? 1 : 0,
    });
    addressRoles.push({
      description: 'Subsidy',
      addressRoleId: 1,
      selected: roleType.Subsidy ? 1 : 0,
    });
    addressRoles.push({
      description: 'W2',
      addressRoleId: 3,
      selected: roleType.W2 ? 1 : 0,
    });
    return addressRoles;
  };

  const CommonMethodForAddressPush = pushType => {
    let isDuplicate = false;
    const data = getValues([
      `MoveIn`,
      `MoveOut`,
      `MoveOutOptional`,
      `Country`,
      `Address1`,
      `Address2`,
      `City`,
      `State`,
      `Zip`,
    ]);
    const currentAddress = _.cloneDeep(candidateDetails?.addressData);
    if (pushType === 'Yes') {
      currentAddress?.forEach(x => {
        if (x.addressType === addType && x.dateMoveOut === null) {
          x.dateMoveOut = moment(data?.MoveIn).format('yyyy-MM-DD');
        }
      });
    }
    var tempAddressDates: any = [];
    var addedRoleType = getValues().roleType;
    if (addedRoleType?.length > 0) {
      [...Array(addedRoleType.length)].map((e, i) => {
        var addressDates = {
          travelerAddressId: 0,
          addressDateRangeID: 0,
          fromDate: moment(addedRoleType[i].Start).format('yyyy-MM-DD'),
          toDate: addedRoleType[i].End !== undefined ? moment(addedRoleType[i].End).format('yyyy-MM-DD') : null,
          insertDate: moment(new Date()).format('yyyy-MM-DD'),
          lastUpdateUserID: 8410,
          addressRoles: getAddressRoleType(addedRoleType, i),
          timestamp: null,
        };
        tempAddressDates.map(item => {
          if (_.isEqual(item, addressDates)) isDuplicate = true;
        });
        if (isDuplicate) {
          setError(`roleType[${i}].CheckboxError`, {
            type: 'required',
            message: 'New address role type should not have same details as an existing address role type',
          });
          return;
        } else {
          tempAddressDates = [...tempAddressDates, addressDates];
          clearErrors(`roleType[${i}].CheckboxError`);
        }
      });
    }
    if (!isDuplicate) {
      currentAddress?.push({
        travelerAddressId: 0,
        addressTypeId: getAddType(addType),
        addressType: addType,
        address1: data?.Address1 || '',
        address2: data?.Address2 || '',
        city: data?.City || '',
        stateProvinceID: states.states.find(state => state.Description === (data?.State || 'Alberta'))?.ID || '',
        state: data?.State || 'Alberta',
        zip: data?.Zip || '',
        country: data?.Country || 'USA',
        latitude: null,
        longitude: null,
        housingProperty: null,
        housingPropertyPhone: null,
        dateMoveIn: data?.MoveIn ? moment(data?.MoveIn).format('yyyy-MM-DD') : null,
        dateMoveOut: opt
          ? data?.MoveOutOptional
            ? moment(data?.MoveOutOptional).format('yyyy-MM-DD')
            : null
          : data?.MoveOut
            ? moment(data?.MoveOut).format('yyyy-MM-DD')
            : null,
        lastUpdate: null,
        addressDates: tempAddressDates,
        timeStamp: '',
      });
      dispatch(
        candidateDetailActions.setCandidateDetails({
          ...candidateDetails,
          addressData: currentAddress,
        }),
      );
      props.toggleAddAddress();
      setAlertOpen(false);
    } else return;
  };

  const handleAddDate = () => {
    CommonMethodForAddressPush('Yes');
  };

  const handleClose = () => {
    setDateAlertOpen(false);
  };

  const handleAddAddress = () => {
    const roleTypes = getValues().roleType;
    const data = getValues([
      `MoveIn`,
      `MoveOut`,
      `MoveOutOptional`,
      `Country`,
      `Address1`,
      `Address2`,
      `City`,
      `State`,
      `Zip`,
    ]);
    const isVal = isFormValid(data);
    fireValidation(data);
    if (!_.isEmpty(errors)) return;
    setAddMoveIn(data?.MoveIn);
    if (isVal) {
      var res = candidateDetails?.addressData?.filter(x => x.addressType === addType && x.dateMoveOut === null);
      if (res?.length) {
        setAddAddress1(res[0].address1 ? res[0].address1 : '');
        var dateCheck = res.filter(
          t =>
            t.dateMoveIn !== '' &&
            t.dateMoveIn !== null &&
            (dates.gt(moment(t.dateMoveIn).format('MM/DD/YYYY'), data.MoveIn) ||
              dates.eq(moment(t.dateMoveIn).format('MM/DD/YYYY'), data.MoveIn)),
        );
        if (dateCheck.length) {
          setDateAlertOpen(true);
        } else {
          setAlertOpen(true);
        }
      } else {
        CommonMethodForAddressPush('No');
      }
    } else fireValidation(data);
  };

  const updateStateOptions = (countryDescription: string) => {
    const selectedCountry = countries.countries.find(e => e.Description === countryDescription);
    const updatedStateOptoons = states.states.filter(e => e.CountryID === selectedCountry.ID);
    setStateList(updatedStateOptoons);
  };

  const handleChange = event => {
    switch (event?.target?.id) {
      case 'txtZip':
        if (event.target.value.length <= 6) {
          setValue('Zip', event.target.value);
        }
        break;
      case 'dropState':
        setValue('State', event.target.value);
        break;
      case 'txtState':
        setValue('State', event.target.value);
        break;
      case 'dropCountry':
        setValue('Country', event.target.value);
        updateStateOptions(event.target.value);
        updateLabels(event.target.value);
        break;
      case 'txtAddress2':
        setValue('Address2', event.target.value);
        break;
      case 'txtAddress1':
        setValue('Address1', event.target.value);
        break;
      case 'txtCity':
        setValue('City', event.target.value);
        break;
    }
  };

  const handleCancel = () => {
    initialValues();
    props.toggleAddAddress();
    const currentAddress = _.cloneDeep(candidateDetails.addressData);
    if (currentAddress && currentAddress?.length > 0) {
      dispatch(
        candidateDetailActions.setCandidateDetails({
          ...candidateDetails,
          addressData: currentAddress,
        }),
      );
    }
  };

  const initialValues = () => {
    setValue(`MoveIn`, '', { shouldDirty: true });
    setValue(`MoveOut`, '', { shouldDirty: true });
    setValue(`MoveOutOptional`, '', { shouldDirty: true });
    setValue(`Country`, 'USA', { shouldDirty: true });
    setValue(`Address1`, '', { shouldDirty: true });
    setValue(`Address2`, '', { shouldDirty: true });
    setValue(`City`, '', { shouldDirty: true });
    setValue(`State`, 'Alberta', { shouldDirty: true });
    setValue(`Zip`, '', { shouldDirty: true });
  };

  const updateLabels = val => {
    if (val.toLowerCase() === 'usa') {
      setCityLabel('City');
      setZipLabel('Zip/Postal Code');
    } else {
      setCityLabel('Province');
      setZipLabel('Postal Code');
    }
  };

  const handleChangeRadio = value => {
    setAddType(value);
    const val = getAddType(value) === 2 || getAddType(value) === 5 ? false : true;
    setOpt(val);
    setValue(`MoveOut`, '', { shouldDirty: true });
    setValue(`MoveOutOptional`, '', { shouldDirty: true });
  };

  useEffect(() => {
    initialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Calculate count
  useEffect(() => {
    if (candidateDetails) {
      setIsCheckBoxEnabled(
        candidateDetails.addressData
          ? candidateDetails.addressData?.filter(x => x.addressTypeId === 4).length > 0
          : false,
      );
    }
  }, [candidateDetails]);

  useEffect(() => {
    if (props.selectableAddresses) {
      const selectableAddress = Object.keys(props.selectableAddresses).filter(key => props.selectableAddresses[key]);
      if (selectableAddress.length === 1) {
        switch (selectableAddress[0]) {
          case 'taxHome': setAddType('Tax Home');
            break;
          case 'permanent': setAddType('Permanent');
            break;
          case 'other': setAddType('Other');
            break;
          case 'companyAssignment': setAddType('Company Assignment');
            break;
          case 'companyAssignmentHotel': setAddType('Company Assignment - Hotel');
            break;
        }
      }
    }
  }, [props.selectableAddresses])

  const handleFeedback = toggle => {
    if (toggle) handleCancel();
  };

  const getAddRoleTypeAccess = () => {
    if (
      (allowedPriorStatuses.includes(candidateDetails.placementStatus) &&
        Authorized(
          [
            userRoles.recruitment,
            userRoles.recruitment_Leadership,
            userRoles.recruitment_TeamMember,
            userRoles.clinical_Manager,
            userRoles.clinical_ReviewAnalyst,
            userRoles.clinical_QualificationsSpecialist,
          ],
          user.userInfo,
        )) ||
      Authorized(
        [
          userRoles.customerSupport_Leadership,
          userRoles.customerSupport_TeamMember,
          userRoles.payrollSpecialist,
          userRoles.benefitsCoordinator,
        ],
        user.userInfo,
      )
    ) {
      return {
        startDate: true,
        endDate: true,
        cobra: true,
        dependentCobra: true,
        mailers: true,
        paycheck: true,
        subsidy: true,
        w2: true,
      };
    } else if (allowedStatuses.includes(candidateDetails.placementStatus)) {
      if (
        Authorized(
          [
            userRoles.recruitment,
            userRoles.recruitment_Leadership,
            userRoles.recruitment_TeamMember,
            userRoles.clinical_Manager,
            userRoles.clinical_ReviewAnalyst,
            userRoles.clinical_QualificationsSpecialist,
          ],
          user.userInfo,
        )
      ) {
        return {
          startDate: true,
          endDate: true,
          cobra: true,
          dependentCobra: true,
          mailers: true,
          paycheck: false,
          subsidy: true,
          w2: false,
        };
      } else if (Authorized([userRoles.housingAccountExecutive, userRoles.workersCompCoordinator], user.userInfo)) {
        return {
          startDate: true,
          endDate: true,
          cobra: true,
          dependentCobra: true,
          mailers: true,
          paycheck: true,
          subsidy: true,
          w2: true,
        };
      }
    } else {
      return {
        startDate: false,
        endDate: false,
        cobra: false,
        dependentCobra: false,
        mailers: false,
        paycheck: false,
        subsidy: false,
        w2: false,
      };
    }
  };

  const addRoleDisabled = moveOut => {
    let pastMoveOutDate = false;
    if (moveOut) {
      const currentDate = GetMomentOfDate(new Date()).format('MM/DD/YYYY');
      const moveOutDate = GetMomentOfDate(moveOut).format('MM/DD/YYYY');
      pastMoveOutDate = dates.gt(currentDate, moveOutDate);
    }
    return (
      pastMoveOutDate ||
      !Authorized(
        [
          userRoles.recruitment,
          userRoles.recruitment_Leadership,
          userRoles.recruitment_TeamMember,
          userRoles.clinical_Manager,
          userRoles.clinical_ReviewAnalyst,
          userRoles.clinical_QualificationsSpecialist,
          userRoles.customerSupport_Leadership,
          userRoles.customerSupport_TeamMember,
        ],
        user.userInfo,
      )
    );
  };

  return (
    <>
      <LayoutGrid container direction="column" className={classes.root}>
        <Paper className={classes.container}>
          <Grid container spacing={2} direction="column" style={{ flexWrap: 'initial' }}>
            <Grid item>
              <Grid container spacing={2} direction="row" xs={12} className={classes.allainment}>
                <Grid item xs={10}>
                  <SimpleCheckbox
                    id={'TaxtEnableCheck'}
                    isChecked={isChecked ? true : false}
                    hasRadio={false}
                    handleChecked={handleCheck}
                    checkboxLabel={`Same as Tax Home Address`}
                    checkboxName={'no-cbx'}
                    isdisabled={!isCheckBoxEnabled}
                  />
                </Grid>
                <Grid item xs={2} style={{ textAlign: 'end' }}>
                  <Button
                    onClick={() => { }}
                    disableRipple
                    disableFocusRipple
                    className={classes.addressButton}
                    variant="text"
                    data-testid="secondary-button-id"
                  >
                    {'NEW'}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Divider className={classes.innerdivider}></Divider>
            </Grid>
            <Grid item>
              <Divider className={classes.innerdivider}></Divider>
            </Grid>
            <Grid item className={classes.addType}>
              <FormInputMultiRadioButton
                name="addType"
                handleChange={handleChangeRadio}
                inputValue={addType}
                selectableAddresses={props.selectableAddresses}
              />
            </Grid>
            <Grid item>
              <Grid container direction="column" spacing={2} style={{ padding: '0px 20px' }}>
                <Grid item>
                  <Grid container direction="column" spacing={2} style={{ width: '60%' }}>
                    <Grid item>
                      <Grid container direction="row" spacing={4}>
                        <Grid item xs={4}>
                          <FormInputDatePicker
                            name={'MoveIn'}
                            label="Move In *"
                            handleChange={handleMoveInDate}
                            require={true}
                            inputValue={getValues('MoveIn')}
                          ></FormInputDatePicker>
                        </Grid>
                        {opt ? (
                          <Grid item xs={4}>
                            <FormInputDatePickerOptional
                              name={'MoveOutOptional'}
                              label={'Move Out (Optional)'}
                              handleChange={handleMoveOutDate}
                              require={false}
                              inputValue={getValues('MoveOutOptional')}
                            ></FormInputDatePickerOptional>
                          </Grid>
                        ) : (
                          <Grid item xs={4}>
                            <FormInputDatePicker
                              name={'MoveOut'}
                              label={'Move Out *'}
                              handleChange={handleMoveOutDate}
                              require={true}
                              inputValue={getValues('MoveOut')}
                            ></FormInputDatePicker>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <FormInputDropdown
                        name={'Country'}
                        label={`Country`}
                        require={false}
                        options={countries.countries}
                        handleChange={handleChange}
                        inputValue={getValues('Country')}
                      ></FormInputDropdown>
                    </Grid>
                    <Grid item className={classes.child}>
                      <FormInputText
                        name={'Address1'}
                        label={'Address 1 *'}
                        handleChange={handleChange}
                        require={true}
                        inputValue={getValues('Address1')}
                        isCheckBox={isChecked}
                      ></FormInputText>
                    </Grid>
                    <Grid item className={classes.child}>
                      <FormInputText
                        name={'Address2'}
                        label={'Address 2 (Optional)'}
                        handleChange={handleChange}
                        require={false}
                        inputValue={getValues('Address2')}
                        isCheckBox={isChecked}
                      ></FormInputText>
                    </Grid>
                    <Grid item>
                      <Grid container direction="row" justifyContent="space-between" spacing={4}>
                        <Grid item className={classes.child} xs={4}>
                          <FormInputText
                            name={'City'}
                            label={`${cityLabel} *`}
                            handleChange={handleChange}
                            require={true}
                            inputValue={getValues('City')}
                            isCheckBox={isChecked}
                          ></FormInputText>
                        </Grid>
                        <Grid item className={classes.child} xs={4}>
                          {stateList?.length > 0 ? (
                            <FormInputDropdown
                              name={'State'}
                              label={`State`}
                              require={false}
                              options={stateList ?? []}
                              handleChange={handleChange}
                              inputValue={getValues('State')}
                            ></FormInputDropdown>
                          ) : (
                            <FormInputText name="State" label={'State'} handleChange={handleChange} require={false} />
                          )}
                        </Grid>
                        <Grid item className={classes.child} xs={4}>
                          <FormInputText
                            type={'number'}
                            name={'Zip'}
                            label={`${zipLabel} *`}
                            handleChange={handleChange}
                            require={true}
                            inputValue={getValues('Zip')}
                            isCheckBox={isChecked}
                          ></FormInputText>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Divider className={classes.divider}></Divider>
                </Grid>

                <Grid item>
                  <AddRoleTypeSectionV2
                    title={getValues().roleType?.length == 1 ? `Role Types` : `Role Type I`}
                    require={getAddType(addType) == 2 || getAddType(addType) == 5}
                    roleTypeAccess={getAddRoleTypeAccess()}
                    moveInDate={getValues(`MoveIn`)}
                    moveOutDate={getValues(`MoveOut`)}
                  ></AddRoleTypeSectionV2>
                </Grid>
                {/* <Grid item>
                  <Divider className={classes.divider}></Divider>
                </Grid> */}
                {/* <Grid item>
                  <Grid container alignItems={'center'}>
                    <Grid item style={{ padding: 0 }}>
                      <IconButton
                        onClick={handleAddRoleType}
                        disabled={addRoleDisabled(moveOutValue)}
                        className={classes.addRoleButton}
                        size="large"
                        disableFocusRipple
                        disableTouchRipple
                      >
                        <AddBoxIcon className={classes.addRoleIcon} />
                      </IconButton>
                    </Grid>
                    <Grid item style={{ padding: 0 }}>
                      <Typography className={classes.addRolesText}>Add Role Type</Typography>
                    </Grid>
                  </Grid>
                </Grid> */}
                <Divider className={classes.divider}></Divider>

                <Grid item style={{ textAlign: 'end' }}>
                  <Grid container justifyContent={'end'} spacing={2} style={{ paddingBottom: '12px' }}>
                    <Grid item>
                      <Button
                        onClick={() => {
                          dispatch(
                            globalActions.setSnackBar({
                              message: `Are you sure you want to close?`,
                              severity: null,
                              disableIcon: true,
                              actions: [
                                {
                                  action: () => handleFeedback(true),
                                  closeSnackbar: true,
                                  text: t('Yes'),
                                },
                                {
                                  closeSnackbar: true,
                                  text: t('No'),
                                },
                              ],
                            }),
                          );
                        }}
                        disableRipple
                        disableFocusRipple
                        variant="text"
                        sx={{ color: 'gray' }}
                        className={classes.alignButton}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        onClick={() => {
                          handleAddAddress();
                        }}
                        disableRipple
                        disableFocusRipple
                        className={`${classes.submitButton} ${classes.alignButton}`}
                        variant="text"
                      >
                        UPDATE
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </LayoutGrid>
      <GenericDialog
        open={alertOpen}
        disableEscapeKeyDown
        maxWidth="sm"
        fullWidth
        dialogTitleProps={{
          text: t(`Previous ${addType} Move Out Date Updated`),
        }}
        dialogActions={[
          {
            text: t('Got It'),
            variant: 'contained',
            color: 'primary',
            onClick: handleAddDate,
          },
        ]}
      >
        <span>
          {t(`Please note, previous Address move out date for ${addAddress1} has been updated to ${addMoveIn}`)}
        </span>
      </GenericDialog>
      <GenericDialog
        open={dateAlertOpen}
        disableEscapeKeyDown
        maxWidth="sm"
        fullWidth
        dialogTitleProps={{
          text: t(`Previous ${addType} Move Out Date Updated`),
        }}
        dialogActions={[
          {
            text: t('Got It'),
            variant: 'contained',
            color: 'primary',
            onClick: handleClose,
          },
        ]}
      >
        <span>
          {t(
            `New ${addType} address move in date should be greater than the move in date of previous ${addType} address`,
          )}
        </span>
      </GenericDialog>
    </>
  );
};
