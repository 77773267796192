import { missingField } from 'app/constants';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePromiseTracker } from 'react-promise-tracker';
import { useSelector, useDispatch } from 'react-redux';
import { AddOnContext } from '../../../PlacementSummaryTab';
import { placementSummaryActions } from '../../../../../../../../../store/redux-store/placement-summary/slice';
import { RequirementsRow } from '../../TableComponents/TableComponents';
import { selectServiceFailures } from '../../../../../../../../../store/redux-store/placement-summary/selectors';
import { getRequirementCheck } from '../../../helper';
import { TableSection } from '../../../customComponents/TableSection';
import { GetMomentOfDate } from 'utils/dates/moment';
import { Avatar } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { useFilterIterator, useRequirementFilter } from '../../../Helpers/requirementFilter';
import { Concatenate } from 'utils/string/string';
import { OverflowTooltip, WhiteTooltip } from '@AMIEWEB/Common';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { colors } from 'styles/styleVariables';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import HearingIcon from 'app/assets/images/IconImages/HearingIcon.svg';
import { VerticalContentPopover } from '@AMIEWEB/Common/Tooltips';
import { IOperation } from 'app/models/Placement/PlacementDetails';

const useStyles = makeStyles()(theme => ({
  blue: {
    color: `${theme.palette.common.white} !important`,
    backgroundColor: theme.palette.system.primary,
    width: '22px',
    height: '22px',
    fontSize: '14px',
    display: 'inline-flex',
    margin: '0 0.4rem 0 0.4rem',
  },
  green: {
    color: `${theme.palette.common.white} !important`,
    backgroundColor: theme.palette.framework.system.green,
    width: '22px',
    height: '22px',
    fontSize: '14px',
    display: 'inline-flex',
    margin: '0 0.4rem 0 0.4rem',
  },
  overflow: {
    maxWidth: 200,
    fontSize: 14,
    fontWeight: 450,
    paddingRight: '3px',
  },
  documentIcon: {
    fill: `${theme.palette.system.lightBlue} !important`,
    marginTop: 1,
    marginLeft: 8,
  },
  documentIconGrey: {
    fill: `${theme.palette.system.neutralGray} !important`,
    marginTop: 1,
    marginLeft: 8,
  },
  secondaryText: {
    color: colors.darkText,
    fontSize: 14,
  },
  primary: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
  keyValue: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    gap: 6,
  },
  expiredDate: {
    color: '#AB0012',
  },
  licenseContainer: {
    width: '220px',
  },
}));

export const LicenseRow = ({ filterKey, requirementFilter, req: { order: orderReq, candidate: candidateReq } }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { promiseInProgress } = usePromiseTracker({
    area: 'placement-submission-grouped-skillset-requirement',
    delay: 0,
  });
  const { groupedSkillsetRequirement: serviceFailed } = useSelector(selectServiceFailures);
  const { placementId } = useContext(AddOnContext);
  const [forceTruncation, setForceTruncation] = React.useState(true);
  const [display, setDisplay] = useState(true);

  const dispatch = useDispatch();

  const { filter } = useRequirementFilter(filterKey, requirementFilter);

  useEffect(() => {
    const state =
      /**True if filter is not applied */
      !filter.length
        ? true
        : /** pass / fail / risk is satisfied by candidate certificates in orderRequirement column and applied in filter */
          (filter || []).some(
            item =>
              (orderReq.data.operation === '|'
                ? item === orderReq.type
                : orderReq.data.orderLicensesDetails.some(lice => lice.type === item)) ||
              candidateReq.data.some(lice => lice.type === item),
          );

    setDisplay(state);
  }, [candidateReq, filter, orderReq]);

  return display ? (
    <RequirementsRow
      id="placement-submission-order-license"
      title={t('placement.profile.summary.submission.order.license.title2')}
      requirementCell={
        (orderReq?.data?.orderLicensesDetails ?? []).length > 0 ? (
          <div className={classes.licenseContainer}>
            <OperationRequirment requirement={orderReq?.type} operation={orderReq?.data?.operation} t={t} />
            <VerticalContentPopover
              maxHeight={300}
              forceTruncation={forceTruncation}
              setForceTruncation={setForceTruncation}
              value={
                <RowKey
                  licenseData={orderReq}
                  requirementFilter={filter}
                  classes={classes}
                  t={t}
                  filterKey={filterKey}
                />
              }
            />
          </div>
        ) : (
          missingField
        )
      }
      candidateCell={
        (candidateReq?.data ?? []).length > 0 ? (
          <div className={classes.licenseContainer}>
            <VerticalContentPopover
              maxHeight={300}
              forceTruncation={forceTruncation}
              setForceTruncation={setForceTruncation}
              value={
                <RowValue
                  licenseData={candidateReq}
                  requirementFilter={filter}
                  classes={classes}
                  t={t}
                  filterKey={filterKey}
                />
              }
            />
          </div>
        ) : (
          missingField
        )
      }
      loading={promiseInProgress}
      hasError={serviceFailed}
      onTryAgain={() => dispatch(placementSummaryActions.getGroupedSkillSetRequirements({ placementId }))}
    />
  ) : null;
};
const RowKey = ({ licenseData, requirementFilter, classes, t, filterKey }) => {
  const {
    data: { orderLicensesDetails: licenses , operation },   
  } = licenseData;

  const items = React.useMemo(
    () =>
      licenses.reduce(
        (
          results,
          {
            type,
            requirementTypeId,
            stateAbbreviation,
            state,
            licenseAbbreviation,
            licenseDescription,
            isRequired,
            isMultiState,
            isCompactLicenseAccepted,
            documentAttachmentRequired,
          },
          index,
        ) => {
          if (!!requirementFilter.length && !requirementFilter.includes(type) && operation === '&') return results;
          const _stateAbbr = stateAbbreviation || missingField;
          const _license = Concatenate([licenseAbbreviation, licenseDescription], ' - ') || missingField;

          const SecondaryContent = () => (
            <div className={classes.secondaryText}>
              {isMultiState ? (
                <div>
                  {isCompactLicenseAccepted ? (
                    <div>{t('placement.profile.summary.submission.order.license.compactLicenseAccepted')}</div>
                  ) : null}
                  <div>
                    {Concatenate(
                      [t('placement.profile.summary.submission.order.license.facilityStateTag'), _stateAbbr],
                      ' ',
                    )}
                  </div>
                </div>
              ) : (
                Concatenate([t('placement.profile.summary.submission.order.license.stateTag'), _stateAbbr], ' ')
              )}
            </div>
          );

          const PrimaryContent = () => (
            <div className={classes.primary}>
              <OverflowTooltip value={_license} contentClass={classes.overflow} />
              <WhiteTooltip title={isRequired ? t('placement.common.required') : t('placement.common.preferred')}>
                <Avatar className={isRequired ? classes.blue : classes.green}>{isRequired ? 'R' : 'P'}</Avatar>
              </WhiteTooltip>
              {documentAttachmentRequired ? (
                <WhiteTooltip title={t('placement.common.documentPresent')}>
                  <DescriptionOutlinedIcon fontSize="small" className={classes.documentIcon} />
                </WhiteTooltip>
              ) : null}
            </div>
          );
          const _icon = getRequirementCheck(type);
          results.push({
            // Code: Null coalescing op used to maintain alignment, if icon is null
            icon: _icon ?? <div style={{ visibility: 'hidden', padding: 14 }} />,
            primaryContent: <PrimaryContent />,
            secondaryContent: <SecondaryContent />,
          });
          return results;
        },
        [],
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [licenses, requirementFilter, operation],
  );
  return <TableSection items={items} avatarMargin={3} />;
};

const RowValue = ({ licenseData, requirementFilter, classes, t, filterKey }) => {
  const { data: licenses = [] } = licenseData;
  // const displayIterator = useFilterIterator({ requirementFilter, iterator: licenses, filterKey });
  const items = React.useMemo(
    () =>
      licenses.length > 0
        ? licenses.reduce(
            (
              results,
              {
                licenseAbbreviation,
                licenseDescription,
                type,
                singleOrCompact,
                state,
                licenseType,
                expirationDate,
                licenseNumber,
                isHearsay,
                isExpired,
                isRisk,
                placementDisciplineId,
                disciplines,
                documentExists,
              },
              index,
            ) => {
              if (type !== null && !!requirementFilter.length && !requirementFilter.includes(type)) return results;
              const _license = Concatenate([licenseAbbreviation, licenseDescription], ' - ') || missingField;
              const _stateAbbr = Concatenate([state || missingField, singleOrCompact || missingField], ' - ');
              const _expMoment = GetMomentOfDate(expirationDate);

              const SecondaryContent = () => (
                <div className={classes.secondaryText}>
                  <div className={classes.keyValue}>
                    <span>{t('placement.profile.summary.submission.order.license.stateTag')}</span>
                    <span>{_stateAbbr}</span>
                  </div>
                  <div className={classes.keyValue}>
                    <span>{t('placement.profile.summary.submission.order.license.licenseType')}</span>
                    <span>{licenseType ? licenseType : missingField}</span>
                  </div>
                  <div className={classes.keyValue}>
                    <span>{t('placement.profile.summary.submission.order.license.expDate')}</span>
                    <span className={isExpired ? classes.expiredDate : undefined}>
                      {_expMoment.isValid() ? _expMoment.format('MM/DD/YYYY') : missingField}
                    </span>
                  </div>
                  <div className={classes.keyValue}>
                    <span>{t('placement.profile.summary.submission.order.license.licenseNo')}</span>
                    <span>{licenseNumber || missingField}</span>
                  </div>
                </div>
              );

              const PrimaryContent = () => (
                <div className={classes.primary}>
                  <OverflowTooltip value={_license} contentClass={classes.overflow} />
                  {isHearsay ? (
                    <img src={HearingIcon} alt="HearingIcon" style={{ marginLeft: 3 }} />
                  ) : (
                    <DoneAllIcon fontSize="small" sx={{ color: '#888888' }} />
                  )}
                  {documentExists ? (
                    <WhiteTooltip title={t('placement.common.documentPresent')}>
                      <DescriptionOutlinedIcon fontSize="small" className={classes.documentIcon} />
                    </WhiteTooltip>
                  ) : (
                    <WhiteTooltip title={t('placement.common.documentAbsent')}>
                      <DescriptionOutlinedIcon fontSize="small" className={classes.documentIconGrey} />
                    </WhiteTooltip>
                  )}
                </div>
              );

              results.push({
                icon: getRequirementCheck(type),
                primaryContent: <PrimaryContent />,
                secondaryContent: <SecondaryContent />,
              });

              return results;
            },
            [],
          )
        : [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [licenses, requirementFilter],
  );

  return <TableSection items={items} />;
};
const OperationRequirment = ({ requirement, operation, t }) => (
  <TableSection
    items={[
      {
        icon: operation === IOperation.OR && !!requirement ? getRequirementCheck(requirement) : null,
        primaryContent: getRequirmentText(operation, t),
      },
    ]}
  />
);

function getRequirmentText(requirement, t) {
  switch (requirement) {
    case IOperation.AND:
      return (
        <>
          <span style={{ fontWeight: 500 }}>{t(`placement.profile.summary.submission.order.commons.all`)}</span>
          <span>{t(`placement.profile.summary.submission.order.license.message`)}</span>
        </>
      );
    case IOperation.OR:
      return (
        <>
          <span style={{ fontWeight: 500 }}>{t(`placement.profile.summary.submission.order.commons.atleast`)}</span>
          <span>{t(`placement.profile.summary.submission.order.license.message`)}</span>
        </>
      );
    default:
      return '';
  }
}
