import { candidateDetailsSelection } from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import { CallPurpose, ChannelType, FormName, SendType, UseType } from '@AMIEWEB/Notification/Constants';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { SmsType } from 'app/enums/Sms';
import { TwilioExtensionCode, unformatPhoneNumber } from 'app/helpers/phoneHelpers';
import { ICandidateDetails } from 'app/models/Candidate/CandidateProfile';
import { EmailDefaultValues } from 'app/models/Email/IEmail';
import { ContactProps, IEmployee, IChatHistory, IToken } from 'app/models/Notification/Notification';
import { GetUserLogs } from 'app/services/NotificationServices/NotificationService';
import { selectUser } from 'oidc/user.selectors';
import { useDispatch, useSelector } from 'react-redux'
import { notificationDataActions } from 'store/redux-store/notification/notification.redux';
import { notificationSelection } from 'store/redux-store/notification/notification.selector';
import { selectPlacementHeaderData } from 'store/redux-store/placement-details/selectors';
import { Concatenate } from 'utils/string/string';


interface InitiateCommunicationProps {
    channelType: ChannelType,
    phoneNumber?: string,
    manualLog?: boolean,
    phoneType?: string,
    placementsCall?: boolean,
    email?: string;
    candidateDetails?: ICandidateDetails;
}
const useCommunication = () => {
    const dispatch = useDispatch();
    const candidateData = useSelector(candidateDetailsSelection);
    const { userInfo } = useSelector(selectUser);
    const notificationData = useSelector(notificationSelection);
    const placementDetails = useSelector(selectPlacementHeaderData);
    const sender: IEmployee = {
        name: userInfo?.firstName + ' ' + userInfo?.lastName,
        email: userInfo?.email,
        senderId: `${userInfo?.employeeId}`,
        userId: `${userInfo?.employeeId}`,
        NtUserName: userInfo?.ntUserName,
        deviceName: '',
        lineNumber: '',
        phoneNumber: userInfo.phoneNumber,
    };
    const initiateCommunication = async (props: InitiateCommunicationProps) => {
        const { channelType, phoneNumber, manualLog, phoneType, placementsCall, email } = props;
        const candidateDetails = props?.candidateDetails || candidateData;
        const candidate: ContactProps = {
            brand: candidateDetails?.brand || placementDetails.brandName,
            name: Concatenate([candidateDetails?.name?.first || placementDetails.candidate.firstName,
            candidateDetails?.name?.last || placementDetails.candidate.lastName],
                ' '),
            email: email || candidateDetails?.primaryEmail || placementDetails.candidatePrimaryEmail,
            phoneNumber: channelType === ChannelType.voice ? phoneNumber : channelType === ChannelType.email ? `${candidateDetails.phoneNumbers?.[0]?.phoneNumber}` || '' : TwilioExtensionCode + unformatPhoneNumber(phoneNumber),
            brandId: `${candidateDetails?.brandId || placementDetails.brandId}`,
            contactId: `${candidateDetails?.travelerId || placementDetails.candidate.id}`,
            contactType: 'candidate',
        };
        const associatedRecord = [
            { name: 'travelerId', value: `${candidateDetails?.travelerId}` },
            { name: 'recruiterId', value: `${candidateDetails?.recruiter?.recruiterId}` },
            {
                name: 'recruiter',
                value: Concatenate([candidateDetails?.recruiter?.first, candidateDetails?.recruiter?.last], ' '),
            },
            { name: 'brandId', value: `${candidateDetails?.brandId}` },
        ];
        switch (channelType) {
            case ChannelType.sms:
                const smsLogsPayload = {
                    contactId: `${candidateDetails?.travelerId}`,
                    pageSize: 20,
                    pageNumber: 1,
                    channel: ['SMS'],
                    initiatedBy: userInfo.phoneNumber,
                    contactPhone: TwilioExtensionCode + unformatPhoneNumber(phoneNumber),
                };
                const chatHistoryPayload: IChatHistory = { chats: [], candidateDetails };
                await GetUserLogs(smsLogsPayload)
                    .then(response => {
                        chatHistoryPayload.chats = response;
                    })
                    .catch(error => {
                        dispatch(
                            globalActions.setSnackBar({
                                message: `Failed to fetch user logs`,
                                severity: 'error',
                            }),
                        );
                        return;
                    });

                const data = {
                    manualLog: manualLog,
                    channel: channelType,
                    associatedRecords: associatedRecord,
                    sender: sender,
                    sendType: SendType.one_to_one,
                    tos: [candidate],
                    useType: UseType.Candidate,
                    brand: candidateDetails?.brand || placementDetails.brandName,
                    brandId: candidateDetails?.brandId || placementDetails.brandId,
                    userRole: Concatenate(userInfo?.roles || [], ','),
                    formName: FormName[FormName.Traveler],
                    reason: CallPurpose[CallPurpose.Prospecting],
                };

                const sms = notificationData.sms;
                data['body'] = sms.data?.body ?? '';
                if (
                    (sms.open || sms.minimized) &&
                    (data['body'].length > 0 || sms.newNotes?.length > 0 || sms.attachments !== undefined)
                ) {
                    dispatch(
                        notificationDataActions.setSmsInteraction({
                            open: true,
                            minimized: false,
                            readonly: false,
                        }),
                    );
                    if (notificationData.sms.data?.tos.find(Boolean)?.contactId !== candidate.contactId) {
                        dispatch(notificationDataActions.setSnackBarData({ channel: 'sms', manual: false, changeWarning: true }));
                    }
                } else {
                    dispatch(
                        notificationDataActions.setNotificationData({
                            ...notificationData,
                            sms: {
                                ...notificationData.sms,
                                chatHistory: chatHistoryPayload,
                                data: data,
                                open: true,
                                minimized: false,
                                readonly: false,
                                sendType: SmsType.one_to_one,
                            },
                            snackbarData: null,
                        }),
                    );
                }
                break;
            case ChannelType.voice:
                const placementAssociatedRecords = [
                    { name: 'placementId', value: `${placementDetails?.placementId}` },
                    { name: 'travelerId', value: `${placementDetails?.candidate?.id}` },
                    { name: 'brandId', value: `${placementDetails?.brandId}` },
                    { name: 'facilityId', value: `${placementDetails?.facility?.id}` },
                    { name: 'recruiter', value: Concatenate([placementDetails?.recruiterFirstName, placementDetails?.recruiterLastName], ' '),}
                ];
                const VoiceData = {
                    manualLog: manualLog,
                    channel: channelType,
                    associatedRecords: placementsCall ? placementAssociatedRecords : associatedRecord,
                    sender: sender,
                    sendType: SendType.one_to_one,
                    tos: [candidate],
                    useType: placementsCall ? UseType.Placement : UseType.Candidate,
                    brand: candidateDetails?.brand || placementDetails.brandName,
                    brandId: candidateDetails?.brandId || placementDetails.brandId,
                    userRole: Concatenate(userInfo?.roles || [], ','),
                    formName: placementsCall ? FormName.Placement : FormName[FormName.Traveler],
                    reason: CallPurpose[CallPurpose.Prospecting],
                };
                if (!manualLog) {
                    if (!notificationData.voice.open && notificationData.voice.minimized) {
                        dispatch(
                            notificationDataActions.setVoiceInteraction({
                                open: true,
                                minimized: false,
                                showDisposition: true,
                            }),
                        );
                        if (notificationData.voice.data.tos.find(Boolean).contactId !== candidate.contactId) {
                            dispatch(notificationDataActions.setSnackBarData({ channel: 'voice', manual: false, changeWarning: true }));
                        }
                    } else {
                        dispatch(
                            notificationDataActions.setVoiceData({
                                data: VoiceData,
                                open: true,
                                minimized: false,
                            }),
                        );
                    }
                }

                if (manualLog) {
                    if (!notificationData.log.open && notificationData.log.minimized) {
                        dispatch(
                            notificationDataActions.setLogInteraction({
                                open: true,
                                minimized: false,
                                showDisposition: true,
                            }),
                        );
                        if (notificationData.log.data.tos.find(Boolean).contactId !== candidate.contactId) {
                            dispatch(notificationDataActions.setSnackBarData({ channel: 'voice', manual: true, changeWarning: true }));
                        }
                    } else {
                        dispatch(
                            notificationDataActions.setLogdata({
                                ...notificationData,
                                data: VoiceData,
                                log: {
                                    channel: ChannelType.voice,
                                    data: VoiceData,
                                    open: true,
                                    minimized: false,
                                },
                                snackbarData: null,
                            }),
                        );
                    }
                }
                break;
            case ChannelType.email:
                let verifiedSkills: string = '';
                candidateDetails?.verifiedSkills.map(skill => {
                    verifiedSkills = Concatenate(
                        [verifiedSkills, Concatenate([skill.disciplineAbbr, skill.specialtyAbbr], ', ')],
                        ' | ',
                    );
                });
                const substitutions: Array<IToken> = [
                    {
                        name: 'Signature',
                        input: '{{signature}}',
                    },
                    {
                        name: 'First Name',
                        input: '{{first_name}}',
                        value: candidateDetails?.name?.first,
                    },
                    {
                        name: 'Last Name',
                        input: '{{last_name}}',
                        value: candidateDetails?.name?.last,
                    },
                    {
                        name: 'Brand',
                        input: '{{brand}}',
                        value: candidateDetails?.brand,
                    },
                    {
                        name: 'Email Address',
                        input: '{{email_address}}',
                        value: email,
                    },
                    {
                        name: 'Phone Number',
                        input: '{{phone_number}}',
                        value: candidateDetails?.primaryPhoneNumber,
                    },
                    {
                        name: 'Skillset',
                        input: '{{skillset}}',
                        value: verifiedSkills,
                    }
                ];

                const emailData = {
                    ...EmailDefaultValues(),
                    channel: ChannelType.email,
                    useType: UseType.Candidate,
                    brand: candidateDetails.brand,
                    brandId: candidateDetails.brandId,
                    userRole: Concatenate(userInfo.roles || [], ','),
                    body: '<br/><br/> {{signature}}',
                    sendType: SendType.one_to_one,
                    substitutions: substitutions,
                    emailType: SendType.one_to_one,
                    tos: [candidate],
                    ccs: [],
                    bccs: [sender],
                    associatedRecord,
                    sender
                }

                if (!notificationData.email.open && notificationData.email.minimized) {
                    dispatch(
                        notificationDataActions.setEmailInteraction({
                            open: true,
                            minimized: false,
                        }),
                    );
                    dispatch(notificationDataActions.setSnackBarData({ channel: 'email', manual: false, changeWarning: true }));
                } else {
                    dispatch(
                        notificationDataActions.setNotificationData({
                            ...notificationData,
                            email: {
                                data: emailData,
                                open: true,
                                minimized: false,
                                sendWithoutConsent: true,
                            },
                            snackbarData: null,
                        }),
                    );
                }
        }

    }

    return { initiateCommunication }
}

export default useCommunication;
