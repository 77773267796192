import { trackException } from 'app-insights/appInsightsTracking';
import {
  ICandidateHeaderDetails,
  ICandidateAddressDetails,
} from 'app/components/Candidate/CandidateProfile/CandidateHeader/CandidateHeader';
import { ExceptionType } from 'app/enums/Common';
import { SkillSet } from 'app/models/Candidate/CandidateJobInterest';
import {
  ICandidateDateOfBirth,
  ICandidateNUID,
  ICandidateSSN,
  IDeleteHearsayCertification,
  IDeleteHearsayLicense,
  IDeleteSkillSet,
  IHearsayCertificationRequestData,
  IHearsayLicense,
  IPersonalInformation,
  IPostEmrRequest,
  IPostSupervisorData,
  IReferenceData,
  IAuditPostData,
  IDeleteSupervisorSkillsets,
  IDeleteTravelerPayroll,
  IDeleteTravelerTaxInfo,
  IPostTaxLetterInfo,
  ITaxData,
  ICandidatePreferences,
  IUpdateSkillsetTreeData,
  IDeleteAudit,
} from 'app/models/Candidate/CandidateProfile';
import axios from 'axios';
import baseAxiosInstance from 'utils/BaseApi';
import { IPostTags, IReferenceRequest } from 'app/models/Candidate/Tags';
import { IPostRequestTimeOff } from '@AMIEWEB/Candidate/CandidateProfile/CandidateTabPanel/ProfileTab/Cards/RequestTimeOff/IRequest';
import { IDeleteWorkExperience } from 'app/models/Candidate/WorkExperience';
import {  encodeString } from '../serviceHelpers';
import uuid from 'react-uuid';
import { ICandidateProfileSummaryDetails } from '@AMIEWEB/Candidate/CandidateProfile/CandidateTabPanel/SummaryTab/Cards/helper';

const candidateBase = globalThis?.app?.env?.REACT_APP_CANDIDATE_SERVICE_BASE_URL;

export const SearchCandidates = async (keyword: string) => {
  try {
    const url = `${candidateBase}/Candidates`;
    const response = await baseAxiosInstance.post(url, { keyword });
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'SearchCandidates',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
  }
};

export async function getCandidateById(travelerId: number, brandId: number) {
  try {
    const url = `${candidateBase}/candidate/${travelerId}/brand/${brandId}`;
    const response = await baseAxiosInstance.get(url);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getCandidateById',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
  }
}

export async function getCandidateJobPreference(candidateId: number, recruiterId: number | null) {
  try {
    const url = `${candidateBase}/candidate/job-preferences/${candidateId}?recruiterId=${recruiterId}`;
    const response = await baseAxiosInstance.get(url);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getCandidateJobPreference',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
  }
}

export async function SaveCandidateJobPreference(body) {
  try {
    const url = `${candidateBase}/candidate/job-preferences/save`;
    const response = await baseAxiosInstance.post(url, body);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'SaveCandidateJobPreference',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
  }
}

export async function getCandidateJobMatchOrderBase(
  travelerId: number,
  brandId: number,
  limit: number,
  offset: number,
  matchFilter?: string,
) {
  try {
    const url = `${candidateBase}/candidate/job-matches?travelerId=${travelerId}&brandId=${brandId}&limit=${limit}&offset=${offset}${
      matchFilter ? `&matchFilter=${matchFilter}` : ''
    }`;
    const response = await baseAxiosInstance.get(url);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getCandidateJobMatchOrderBase',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
  }
}

export async function getCandidateJobInterestOrderBase(
  travelerId: number,
  brandId: number,
  limit: number,
  offset: number,
) {
  try {
    const url = `${candidateBase}/candidate/job-interests?travelerId=${travelerId}&brandId=${brandId}&limit=${limit}&offset=${offset}`;
    const response = await baseAxiosInstance.get(url);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getCandidateJobInterestOrderBase',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
  }
}

export async function getCandidateJobInterestFilterOrderBase(body: {
  travelerId: number;
  brandId: number;
  limit: number;
  offset: number;
  filterEnable: boolean;
  orderStatus?: string;
  fromDate?: string;
  toDate?: string;
  states?: string[];
  skillSets?: SkillSet[];
}) {
  try {
    const url = `${candidateBase}/candidate/job-interests-filters`;
    const response = await baseAxiosInstance.post(url, body);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getCandidateJobInterestFilterOrderBase',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
  }
}

export async function getCandidateJobMatchCount(travelerId: number) {
  try {
    const limit = 0;
    const url = `${candidateBase}/candidate/job-match-count/${travelerId}?limit=${limit}`;
    const response = await baseAxiosInstance.get(url);
    return { status: response?.status, count: response.data.count };
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getCandidateJobMatchCount',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
    return { status: error.response?.status };
  }
}

export async function getCandidateJobInterestCount(travelerId: number) {
  try {
    const url = `${candidateBase}/candidate/job-interests-count/${travelerId}`;
    const response = await baseAxiosInstance.get(url);
    return { status: response?.status, count: response.data.total };
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getCandidateJobInterestCount',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
    return { status: error.response?.status };
  }
}

export async function getActivePlacementsByRecruiter(recruiterId: number) {
  try {
    const url = `${candidateBase}/candidate/placements/recruiter/${recruiterId}`;
    const response = await baseAxiosInstance.get(url);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getActivePlacementsByRecruiter',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
    return { status: error.response?.status };
  }
}

export async function getActiveRecruitersByLoggedInUserId(userId: number, role: String) {
  try {
    return await baseAxiosInstance.get(`${candidateBase}/recruiter/${userId}/${role}`);
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getActiveRecruitersByLoggedInUserId',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
    return { status: error.response?.status };
  }
}

export async function getActiveUsersByLoggedInUserId(userId: number, role: String) {
  try {
    return await baseAxiosInstance.get(`${candidateBase}/users?role=HAM`);
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getActiveUsersByLoggedInUserId',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
    return { status: error.response?.status };
  }
}

export async function getRecruiterStaticCount(recruiterId: number) {
  try {
    const url = `${candidateBase}/static-counts/recruiter/${recruiterId}`;
    const response = await baseAxiosInstance.get(url);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getRecruiterStaticCount',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
    return { status: error.response?.status };
  }
}

export async function getCandidateOptions(candidateQuery: string) {
  try {
    const url = `${candidateBase}/candidate/lookups/${candidateQuery}`;
    const response = await baseAxiosInstance.get(url);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getCandidateOptions',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
    return { status: error.response?.status };
  }
}

export async function getCandidateForPlacementCreation(candidateId: number, brandId: number) {
  try {
    const url = `${candidateBase}/candidate/placement-modal/${candidateId}/${brandId}`;
    const response = await baseAxiosInstance.get(url);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getCandidateForPlacementCreation',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
  }
}

export async function updateHeaderDetails(updatedHeaderDetails: ICandidateHeaderDetails) {
  try {
    const url = `${candidateBase}/candidate/updateheader`;
    const notes = updatedHeaderDetails.notes;
    updatedHeaderDetails.notes = encodeString(updatedHeaderDetails.notes);
    const response = await baseAxiosInstance.post(url, updatedHeaderDetails, {
      headers: { 'x-encoded-notes': 'true' },
    });
    updatedHeaderDetails.notes = notes;
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'updateHeaderDetails',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
  }
}

export async function updateCandidatePersonalInfo(updatedHeaderDetails: ICandidateHeaderDetails) {
  try {
    const url = `${candidateBase}/candidate/personalinfo`;
    const response = await baseAxiosInstance.post(url, updatedHeaderDetails);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'updateCandidatePersonalInfo',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
  }
}

export async function updateCandidateAddress(updatedAddressDetails: ICandidateAddressDetails) {
  try {
    const url = `${candidateBase}/candidate/address`;
    const response = await baseAxiosInstance.post(url, updatedAddressDetails);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'updateCandidateAddress',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
    throw error;
  }
}

export async function updateProfileSummaryDetails(updatedDetails: ICandidateProfileSummaryDetails) {
  try {
    const url = `${candidateBase}/candidate/ssn`;
    const response = await baseAxiosInstance.post(url, updatedDetails);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'updateProfileSummaryDetails',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
  }
}

export async function updateCandidateSSN(payload: ICandidateSSN) {
  try {
    const url = `${candidateBase}/candidate/ssn/new`;
    const response = await baseAxiosInstance.post(url, payload);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'updateCandidateSSN',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
    throw error;
  }
}

export async function updateCandidateDOB(payload: ICandidateDateOfBirth) {
  try {
    const url = `${candidateBase}/candidate/dob`;
    const response = await baseAxiosInstance.post(url, payload);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'updateCandidateDOB',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
    throw error;
  }
}

export async function updateCandidateNUID(payload: ICandidateNUID) {
  try {
    const url = `${candidateBase}/candidate/nuid`;
    const response = await baseAxiosInstance.post(url, payload);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'updateCandidateNUID',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
    throw error;
  }
}

export async function getSSNValueByTravelerId(id: any) {
  try {
    const url = `${candidateBase}/candidate/${id}/ssn`;
    const response = await baseAxiosInstance.get(url);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getSSNValueByTravelerId',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
  }
}

export async function getCandidateInterivew(id: any) {
  try {
    const url = `${candidateBase}/candidate/${id}/interviews`;
    const response = await baseAxiosInstance.get(url);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getCandidateInterivew',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
  }
}

export async function getCandidateAddress(id: any, brand: any) {
  try {
    const url = `${candidateBase}/candidate/${id}/brand/${brand}/addresses`;
    const response = await baseAxiosInstance.get(url);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getCandidateAddress',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
  }
}

export async function postCandidatePersonalInfo(data: IPersonalInformation) {
  try {
    const url = `${candidateBase}/candidate/personalinfo`;
    const response = await baseAxiosInstance.post(url, data);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'postCandidatePersonalInfo',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
    return error.response;
  }
}

export async function getCandidateSkillsetTree(id: any) {
  try {
    const url = `${candidateBase}/candidate/${id}/skillsettree`;
    const response = await baseAxiosInstance.get(url);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getCandidateSkillsetTree',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
  }
}

export async function deleteCandidateSkillSet(data: IDeleteSkillSet) {
  try {
    const url = `${candidateBase}/candidate/unverifiedskillset`;
    const response = await baseAxiosInstance.delete(url, { data });
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'deleteCandidateSkillSet',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
    return error.response;
  }
}

export async function updateCandidateUnverifiedSkillSets(data: any) {
  try {
    const url = `${candidateBase}/candidate/unverifiedskillset`;
    const response = await baseAxiosInstance.post(url, data);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'updateCandidateUnverifiedSkillSets',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
    return error.response;
  }
}

export async function getCandidateSkillsChecklist(id: any) {
  try {
    const url = `${candidateBase}/candidate/${id}/skillschecklist`;
    const response = await baseAxiosInstance.get(url);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getCandidateSkillsChecklist',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
  }
}

export async function postCandidateWorkExperience(data: any) {
  try {
    const url = `${candidateBase}/candidate/workhistory`;
    const response = await baseAxiosInstance.post(url, data);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'postCandidateWorkExperience',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
    return error.response;
  }
}

export async function postCandidateEducation(data: any) {
  try {
    const url = `${candidateBase}/candidate/education`;
    const response = await baseAxiosInstance.post(url, data);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'postCandidateEducation',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
    return error.response;
  }
}

export async function getCandidateAuditDetails(candidateId: any) {
  try {
    const url = `${candidateBase}/candidate/${candidateId}/audit`;
    const res = await baseAxiosInstance.get(url);
    return res;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getCandidateAuditDetails',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
  }
}

export async function getCandidateReference(candidateId: any, filter: number) {
  try {
    const url = `${candidateBase}/candidate/${candidateId}/reference?filter=${filter}`;
    const res = await baseAxiosInstance.get(url);
    return res;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getCandidateReference',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
  }
}

export async function postSellingNotes(data: any) {
  try {
    const url = `${candidateBase}/candidate/sellingnotes`;
    const response = await baseAxiosInstance.post(url, data);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'postSellingNotes',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
    return error.response;
  }
}

export async function postInterviewInvite(candidateId: any, data: any) {
  try {
    const url = `${candidateBase}/candidate/interviewrequest`;
    const response = await baseAxiosInstance.post(url, data);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'postInterviewInvite',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
    return error.response;
  }
}

export async function getEMRList(candidateId: number, brandId: number) {
  try {
    const url = `${candidateBase}/candidate/${candidateId}/${brandId}/emr`;
    return await baseAxiosInstance.get(url);
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getEMRList',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
    return error.response;
  }
}

export async function postEMRData(requestBody: IPostEmrRequest) {
  try {
    const url = `${candidateBase}/candidate/emr`;
    return await baseAxiosInstance.post(url, requestBody);
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'postEMRData',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
    return error.response;
  }
}

export async function getCandidateDocumentsPassport(travelerId: string) {
  try {
    const url = `${candidateBase}/candidate/submitted-documents/${travelerId}`;
    return await baseAxiosInstance.get(url);
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getCandidateDocumentsPassport',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
    return error.response;
  }
}

export async function fetchPdfFileFromBlobStorage(customURl) {
  const customAxiosInstance = axios.create({
    baseURL: customURl,
  });
  return customAxiosInstance({
    method: 'GET',
    responseType: 'blob',
  });
}

export async function getPassportPreferences(candidateId: number) {
  try {
    const url = `${globalThis?.app?.env?.REACT_APP_PASSPORT_URL}/api/amie-web/userpreferences/${candidateId}`;
    const response = await baseAxiosInstance.get(url, {
      headers: { 'user-id': candidateId, 'x-correlationId': uuid() },
    });
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getPassportPreferences',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
    return error.response;
  }
}

export async function getCandidateJobPreferences(travelerId: number, brandId: number) {
  try {
    const url = `${candidateBase}/candidate/get-job-preferences/${travelerId}/${brandId}`;
    return await baseAxiosInstance.get(url);
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getCandidateJobPreferences',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
    return error.response;
  }
}

export async function deleteCandidateJobPreferences(requestBody: { candidateId: any; brandId: any; ids: any[] }) {
  try {
    const url = `${candidateBase}/candidate/delete-job-preferences`;
    return await baseAxiosInstance.post(url, requestBody);
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'deleteCandidateJobPreferences',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
    return error.response;
  }
}

export async function addCandidateJobPreferences(requestBody) {
  try {
    const url = `${candidateBase}/candidate/add-job-preferences`;
    return await baseAxiosInstance.post(url, requestBody);
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'addCandidateJobPreferences',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
    return error.response;
  }
}

export async function editCandidateJobPreferences(requestBody) {
  try {
    const url = `${candidateBase}/candidate/edit-job-preferences`;
    return await baseAxiosInstance.post(url, requestBody);
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'editCandidateJobPreferences',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
    return error.response;
  }
}

export async function toggleCandidateJobPreferenceNotification(requestBody) {
  try {
    const url = `${candidateBase}/candidate/toggle-job-preference`;
    return await baseAxiosInstance.post(url, requestBody);
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'toggleCandidateJobPreferenceNotification',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
    return error.response;
  }
}

/**
 * Method to get skill checklist data for a specific skill checklist id
 * @param candidateId
 * @param skillsChecklistId
 * @returns
 */
export async function getSkillChecklistById(candidateId: string, skillsChecklistId: string) {
  try {
    const url = `${candidateBase}/candidate/skillsChecklistDetailDocument/${candidateId}/${skillsChecklistId}`;
    return await baseAxiosInstance.get(url);
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'toggleCandidateJobPreferenceNotification',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
    return error.response;
  }
}

export async function deleteCandidateEducation(data: any) {
  if(data.graduationMonth == 0)
  {
     data.graduationMonth = null;
  }
  try {
    const url = `${candidateBase}/candidate/delete-candidate-education`;
    const response = await baseAxiosInstance.delete(url, { data });
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'deleteCandidateEducation',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
    return error.response;
  }
}

/**
 * Method to get tags list by candidate id and brand id
 * @param candidateId
 * @param brandId
 */
export async function getTagsListByCandidateId(candidateId: number, brandId: number) {
  try {
    const url = `${candidateBase}/candidate/tags/${candidateId}/brand/${brandId}`;
    return await baseAxiosInstance.get(url);
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getTagsListByCandidateId',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
    return error.response;
  }
}

/**
 * Method to get post tags data
 * @param reqBody
 */
export async function postTagsList(reqBody: IPostTags) {
  try {
    const url = `${candidateBase}/candidate/updatecandidatetags`;
    const response = await baseAxiosInstance.post(url, reqBody);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'postTagsList',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
    return error.response;
  }
}
export async function postReferenceRequest(reqBody: IReferenceRequest) {
  try {
    const url = `${candidateBase}/candidate/reference-request`;
    const response = await baseAxiosInstance.post(url, reqBody);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'postReferenceRequest',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
    return error.response;
  }
}
export async function postCandidateReference(data: IReferenceData) {
  try {
    const url = `${candidateBase}/candidate/unverified-reference`;
    const response = await baseAxiosInstance.post(url, data);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'postCandidatePersonalInfo',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
    return error.response;
  }
}

/**
 * Method to get time off request
 * @param travelerId
 */
export async function getTimeOffRequest(travelerId: number, brandId: number) {
  try {
    const url = `${candidateBase}/candidate/timeoff/${travelerId}/brand/${brandId}`;
    const response = await baseAxiosInstance.get(url);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getTimeOffRequest',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
    return error.response;
  }
}

/**
 * Method to post time off request
 * @param reqBody
 */
export async function postTimeOffRequest(reqBody: IPostRequestTimeOff) {
  try {
    const url = `${candidateBase}/candidate/time-off`;
    const response = await baseAxiosInstance.post(url, reqBody);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'postTimeOffRequest',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
    return error.response;
  }
}

/**
 * Method to delete work experience record
 * @param data
 */
export async function deleteCandidateWorkExperienceRecord(data: IDeleteWorkExperience) {
  try {
    const url = `${candidateBase}/candidate/delete-work-history`;
    const response = await baseAxiosInstance.delete(url, { data });
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'deleteCandidateWorkExperienceRecord',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
    return error.response;
  }
}

export async function fetchAllSkillChecklistData(candidateId: number) {
  try {
    const url = `${candidateBase}/candidate/skillsChecklistDocument/${candidateId}`;
    return await baseAxiosInstance.get(url);
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'fetchAllSkillChecklistData',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
    return error.response;
  }
}

export async function getCandidateRequestReference(candidateId: number, brandId: number) {
  try {
    const url = `${candidateBase}/candidate/${candidateId}/brand/${brandId}/reference-request`;
    return await baseAxiosInstance.get(url);
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getCandidateRequestReference',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
    return error.response;
  }
}

export async function getPermanentTaxLetterData(travelerId: string) {
  try {
    const url = `${candidateBase}/candidate/${travelerId}/tax-letter-info`;
    return await baseAxiosInstance.get(url);
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getPermanentTaxLetterData',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
    return error.response;
  }
}

export async function getSupervisorData(travelerId: number) {
  try {
    const url = `${candidateBase}/candidate/${travelerId}/supervisor`;
    return await baseAxiosInstance.get(url);
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getSupervisorData',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
    return error.response;
  }
}

export async function postSupervisorData(data: IPostSupervisorData) {
  try {
    const url = `${candidateBase}/candidate/traveler-supervisor`;
    const response = await baseAxiosInstance.post(url, data);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'postSupervisorData',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
    return error.response;
  }
}

export async function deleteSupervisorSkillSet(data: IDeleteSupervisorSkillsets) {
  try {
    const url = `${candidateBase}/candidate/delete-traveler-supervisor-skillsets`;
    const response = await baseAxiosInstance.post(url, data);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'deleteSupervisorSkillSet',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
    return error.response;
  }
}

export async function postUnverifiedHearsayCertification(
  candidateId: number,
  brandId: number,
  reqBody: IHearsayCertificationRequestData[],
) {
  try {
    const url = `${candidateBase}/candidate/${candidateId}/brand/${brandId}/unverified-hearsaycertification`;
    return await baseAxiosInstance.post(url, reqBody);
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'postUnverifiedHearsayCertification',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
    return error.response;
  }
}

export async function deleteUnverifiedHearsayCertification(data: IDeleteHearsayCertification) {
  try {
    const url = `${candidateBase}/candidate/unverified-hearsay-certification`;
    const response = await baseAxiosInstance.delete(url, { data });
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'deleteUnverifiedHearsayCertification',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
    return error.response;
  }
}

export async function postUnverifiedHearsayLicense(reqBody: IHearsayLicense) {
  try {
    const url = `${candidateBase}/candidate/unverified-hearsay-licenses`;
    return await baseAxiosInstance.post(url, reqBody);
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'postUnverifiedHearsayLicense',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
    return error.response;
  }
}

export async function deleteUnverifiedHearsayLicense(data: IDeleteHearsayLicense) {
  try {
    const url = `${candidateBase}/candidate/unverified-hearsay-licenses`;
    const response = await baseAxiosInstance.delete(url, { data });
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'deleteUnverifiedHearsayLicense',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
    return error.response;
  }
}

export async function updateOrderMatchReadStatus(data: any) {
  try {
    const url = `${candidateBase}/candidate/job-preferences/update-order-match-read-status`;
    return await baseAxiosInstance.post(url, data);
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'updateOrderMatchReadStatus',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
    return error.response;
  }
}

export async function postCandidateAudit(reqBody: IAuditPostData) {
  try {
    const url = `${candidateBase}/candidate/audit`;
    const response = await baseAxiosInstance.post(url, reqBody);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'postCandidateAudit',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
    return error.response;
  }
}

export async function deleteCandidateAudit(data: IDeleteAudit) {
  try {
    const url = `${candidateBase}/candidate/audit`;
    const response = await baseAxiosInstance.delete(url, { data });
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'deleteCandidateAudit',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
    return error.response;
  }
}

export async function getTaxData(travelerId: string) {
  try {
    const url = `${candidateBase}/candidate/${travelerId}/tax-data`;
    return await baseAxiosInstance.get(url);
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getTaxData',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
    return error.response;
  }
}

export async function postTaxLetterInfo(candidateId: number, taxLetterInfo: IPostTaxLetterInfo) {
  try {
    const url = `${candidateBase}/candidate/${candidateId}/tax-letter-info`;
    return await baseAxiosInstance.post(url, taxLetterInfo);
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'postTaxLetterInfo',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
    return error.response;
  }
}

export async function getPreferencesData(travelerId: string) {
  try {
    const url = `${candidateBase}/candidate/${travelerId}/preferences`;
    return await baseAxiosInstance.get(url);
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getPreferencesData',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
    return error.response;
  }
}

export async function deleteTravelerPayroll(data: IDeleteTravelerPayroll) {
  try {
    const url = `${candidateBase}/candidate/delete-tax-data`;
    const response = await baseAxiosInstance.post(url, data);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'deleteTravelerPayroll',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
    return error.response;
  }
}

export async function deleteTravelerTaxInfo(data: IDeleteTravelerTaxInfo) {
  try {
    const url = `${candidateBase}/candidate/delete-tax-letter-info`;
    const response = await baseAxiosInstance.post(url, data);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'deleteTravelerTaxInfo',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
    return error.response;
  }
}

export async function postTaxData(data: ITaxData) {
  try {
    const url = `${candidateBase}/candidate/${data.candidateId}/tax-data`;
    const response = await baseAxiosInstance.post(url, data);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'postTaxData',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
    return error.response;
  }
}

export async function postPreferencesData(preferences: ICandidatePreferences) {
  try {
    const url = `${candidateBase}/candidate/preferences`;
    return await baseAxiosInstance.post(url, preferences);
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'postPreferencesData',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
    return error.response;
  }
}

export async function updateCandidateSkillsetTree(data: IUpdateSkillsetTreeData) {
  try {
    const url = `${candidateBase}/candidate/${data.candidateId}/skillsettree`;
    const response = await baseAxiosInstance.post(url, data);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'updateCandidateSkillsetTree',
        area: 'src/app/services/CandidateServices/CandidateServices.ts',
      },
    });
    return error.response;
  }
}
