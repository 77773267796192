import React from 'react';
import { Grid, ThemeProvider, StyledEngineProvider,useTheme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { UnitCardsTitle, UnitDetailsBody } from '../UnitDesign';
import { ReqPrefSection } from '../Common/ReqPrefSection';
import { reqPref } from 'app/enums/Unit';
import { SingleLineInput } from '../Common/SingleLineInput';
import { useTranslation } from 'react-i18next';
import { SimpleCheckbox } from '../Common/SimpleCheckbox';
import { Controller, useFormContext } from 'react-hook-form';

interface Props {
  disableEdit?: boolean;
}

const useStyles = makeStyles()(theme => ({
  wrapper: {
    paddingTop: theme.spacing(2),
  },
  titleWrapper: {
    paddingBottom: '1%',
  },
}));

export const NewRefernces = (props: Props) => {
  const { disableEdit } = props;
  const { classes } = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const { control } = useFormContext();

  const getNumericValue = (value: string) => {
    const numericValue = parseInt(value);
    return numericValue >= 0 ? numericValue : '';
  };

  return (
    <div>
      {/* TODO: Remove this theme wrapper since it uses the global theme */}
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <UnitDetailsBody variant="outlined">
            <Grid container direction="column" spacing={1}>
              <Grid item xs={12}>
                <Grid container direction="row" spacing={1}>
                  <Grid item className={classes.titleWrapper}>
                    <UnitCardsTitle>{t('order.createUnit.references.title')}</UnitCardsTitle>
                  </Grid>
                </Grid>
                <span className={classes.wrapper}>
                  <Controller
                    control={control}
                    name={'reference.isRequired'}
                    render={({ ref, onChange, ...rest }) => (
                      <ReqPrefSection
                        isRequired={rest.value ?? false}
                        handleReqPref={(event: React.ChangeEvent<HTMLInputElement>) => {
                          onChange(event.target.value === reqPref.req);
                        }}
                        isdisabled={disableEdit}
                        id={'createUnitReferencesReqPrefRadio'}
                      />
                    )}
                  />
                </span>
                <Grid item container direction="row" spacing={2} className={classes.wrapper}>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name={'reference.numberOfReferenceRequired'}
                      render={({ ref, onChange, ...rest }) => (
                        <SingleLineInput
                          id={'createUnitReferencesNumberOfReferencesRequiredTxt'}
                          inputName={'numberOfReferenceRequired'}
                          isdisabled={disableEdit}
                          inputLabel={t('order.createUnit.references.numberOfReferencesRequired')}
                          inputDefaultValue={''}
                          inputValue={rest.value ? parseInt(rest.value) : 0}
                          handleChange={event => {
                            onChange(getNumericValue(event.target.value));
                          }}
                          inputType={'number'}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name={'reference.numberOfReferenceToRFO'}
                      render={({ ref, onChange, ...rest }) => (
                        <SingleLineInput
                          id={'createUnitReferencesVumberOfReferencesToRFOTxt'}
                          inputName={'numberOfReferenceToRFO'}
                          inputLabel={t('order.createUnit.references.numberOfReferencesToRFO')}
                          inputValue={rest.value ? parseInt(rest.value) : 0}
                          isdisabled={disableEdit}
                          inputDefaultValue={''}
                          handleChange={event => {
                            onChange(getNumericValue(event.target.value));
                          }}
                          inputType={'number'}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid item container direction="row" spacing={2} className={classes.wrapper}>
                  <Grid item>
                    <Controller
                      control={control}
                      name={'reference.withinMonths'}
                      render={({ ref, onChange, ...rest }) => (
                        <SimpleCheckbox
                          id={'createUnitReferencesWithinMonthsCheck'}
                          isChecked={rest.value}
                          handleChecked={(event: React.ChangeEvent<HTMLInputElement>) => {
                            onChange(event.target.checked);
                          }}
                          isdisabled={disableEdit}
                          checkboxLabel={t('order.createUnit.references.withinMonths')}
                          checkboxName={'withinMonths'}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item>
                    <Controller
                      control={control}
                      name={'reference.referenceFromSupervisor'}
                      render={({ ref, onChange, ...rest }) => (
                        <SimpleCheckbox
                          id={'createUnitReferencesReferencefromSupervisorCheck'}
                          isChecked={rest.value}
                          handleChecked={(event: React.ChangeEvent<HTMLInputElement>) => {
                            onChange(event.target.checked);
                          }}
                          isdisabled={disableEdit}
                          checkboxLabel={t('order.createUnit.references.referencefromSupervisor')}
                          checkboxName={'referenceFromSupervisor'}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </UnitDetailsBody>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
};
