import { deriveVerifiedSkillsetChip2 } from '@AMIEWEB/Common/SkillsetTree/SkillsetChips';
import { Grid, Typography } from 'amn-ui-core';
import { missingField } from 'app/constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  chip: {
    fontSize: '12px',
    margin: 0,
  },
});

export const Skillset = ({ skills = [] }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const skillChips = React.useMemo(() => {
    // @ts-ignore
    const { verifiedChips, unverifiedChips } = deriveVerifiedSkillsetChip2(skills, {
      size: 'small',
      classes: { root: classes.chip },
    });

    return [...verifiedChips, ...unverifiedChips];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skills]);

  return (
    <Grid container flexDirection="column" spacing={1}>
      <Grid item>
        <Typography variant="body1" color="charcoal" fontWeight={500}>
          {t('Skill Set')}
        </Typography>
      </Grid>
      <Grid item>
        <Grid container flexDirection="row" spacing={1}>
          {!!skillChips.length ? (
            skillChips.map((chip, indx) => (
              <Grid item key={indx.toString()}>
                {chip}
              </Grid>
            ))
          ) : (
            <Grid item>{missingField}</Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
