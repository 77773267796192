/* eslint-disable @typescript-eslint/no-explicit-any */
import { takeLatest, put, call, getContext, select, delay, all } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  getUnitsForFacility,
  createUnit,
  getClientContact,
  getFacilityDetails,
  searchSkillsets,
  searchFacilities,
  getFacilityContract,
  getAdditionalContractDetail,
  getFacilityContractDiscipline,
  getDisciplineSpecialityRates,
  getRateElements,
  postOffcontractDetails,
  getOffContractRateElements,
  postRateElements,
  getOffContractApprovalDocument,
  saveOCBRAttachments,
  postUpdateFaciliytRegion,
  getInstructionsData,
  postInstructionsData,
  getFacilityContactAffiliate,
  getFacilitiesForAffiliate,
  updateFacilityBestBets,
} from 'app/services/FacilityServices/FacilityServices';
import { manuallyDecrementPromiseCounter, manuallyIncrementPromiseCounter, trackPromise } from 'react-promise-tracker';
import { NewUnit } from 'app/models/Unit/CreateUnit';
import { facilityActions } from './Facility.redux';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { sort } from '../FacilityDetails/FacilityTabs/RateCardTab/FormatData';
import { getSearchLookups } from 'app/services/SharedServices/SharedServices';
import { httpSuccess } from 'app/services/serviceHelpers';
import { SharedService } from 'app/services/SharedServices/shared-service';
import {
  formatTypeAheadDisciplineOptions,
  formatTypeAheadSpecialtyOptions,
  formatTypeAheadSubSpecialtyOptions,
} from 'app/helpers/typeAheadHelper';
import { FacilityService } from 'app/services/FacilityServices/facility-service';
import { OrderService } from 'app/services/OrderServices/order-service';
import {
  IFacilityDetails,
  IGetRateElement,
  IOffContract,
  IRequestApprovalDocument,
  ISaveRateElementCommand,
  ISaveOCBRAttachments,
  IUpdateInternalContactCommand,
  IDisplayInternalContact,
  IFacilityRegionUpdateCommand,
  IUpdateFacilityInstructionsCommand,
  IUpdateDrugScreenPolicyCommand,
  IUpdateReferencesCommand,
} from './Facility.model';
import {
  setEditInternalContactsValue,
  transformInternalContacts,
} from '../FacilityDetails/FacilityTabs/GeneralTab/FacilityRoles/helper';
import { IEditFacilityRoles } from 'app/models/Facility/FacilityRoles';
import { selectUser } from 'oidc/user.selectors';
import { selectEditFacilityRegion, selectFacility, selectFacilityInternalContacts } from './Facility.selector';
import { selectUnitsData } from '../FacilityDetails/FacilityTabs/UnitTab/Store/FacilityUnit.selector';
import { PromiseTrackerKeys } from 'app/constants/PromiseTrackerKeys';
import { missingField } from 'app/constants';
import {
  BestBetsType,
  IBestBets,
} from '../FacilityDetails/FacilityTabs/GeneralTab/AtAGlance/ManageBestBetsForFacility';
import { FacilityUnitActions } from '../FacilityDetails/FacilityTabs/UnitTab/Store/FacilityUnit.redux';
import { GetDisciplineSpecialtyAndSubSpecialtyOptions } from '@AMIEWEB/Order/OrderCreationForm/OrderDetails/FormatData';
import { ITypeAheadOptions, orderDataActions } from '@AMIEWEB/Order/Store/Order.redux';

const TrackWrapper = (fn, ...args) => trackPromise(fn(...args), 'facility');
const TrackContractDiscipline = (fn, ...args) => trackPromise(fn(...args), 'get-facility-discipline');
const TrackAdditionalContracts = (fn, ...args) => trackPromise(fn(...args), 'get-additional-contract-details');
const TrackSpecialtyRates = (fn, ...args) => trackPromise(fn(...args), 'get-discipline-specialty-rates');
const TrackFacilityContract = (fn, ...args) => trackPromise(fn(...args), 'get-facility-contract');
const TrackSaveOffContract = (fn, ...args) => trackPromise(fn(...args), 'save-facility-off-contract');
const TrackUpdateInternalContacts = (fn, ...args) => trackPromise(fn(...args), 'update-facility-internal-contact');
const TrackUpdateFacilityRegion = (fn, ...args) => trackPromise(fn(...args), 'update-facility-region-area');
const TrackApprovalDocument = (fn, ...args) =>
  trackPromise(fn(...args), `get-approval-document-${args[0].rateElementId}`);
const TrackUpdateRateElements = (fn, ...args) => trackPromise(fn(...args), 'update-rate-elements');
const TrackOffContractRateElements = (fn, ...args) => trackPromise(fn(...args), 'off-contract-rate-elements');
const TrackSaveOCBRAttachments = (fn, ...args) => trackPromise(fn(...args), 'save-ocbr-attachments');
const TrackGetFacilityInstructions = (fn, ...args) => trackPromise(fn(...args), 'get-facility-instructions');
const TrackGetFacilityContractAffiliate = (fn, ...args) => trackPromise(fn(...args), 'get-facility-contract-affiliate');
const TrackGetFacilitiesForAffiliate = (fn, ...args) =>
  trackPromise(fn(...args), PromiseTrackerKeys.facility.facilitiesForAffiliate);
export const updateFacilityReferencePromiseKey = 'update-Facility-Reference-Promise-Key';

//
// ─── GETFACILITYDETAILS ─────────────────────────────────────────────────────────
//
export function* requestGetFacilityDetails(action: PayloadAction<{ id: number }>) {
  try {
    yield call(getFacilityDetails, action.payload.id);
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestGetFacilityDetails',
        area: 'src/app/components/Facility/FacilityStore/Facility.saga.ts',
      },
    });
  }
}

//
// ─── GETFACILITYDETAILSWITHOPENORDERCOUNT ───────────────────────────────────────
//
export function* requestGetFacilityDetailsWithOpenOrderCount(action: PayloadAction<{ id: number }>) {
  try {
    const user = yield select(selectUser);
    yield put(facilityActions.setFacilityPageLoading(true));
    const facilityService: FacilityService = yield getContext('facilityService');
    const orderService: OrderService = yield getContext('orderService');

    let [facilityResponse, openOrdersCount, facilityInstructions] = yield all([
      call(TrackWrapper, facilityService.getFacilityDetails, action.payload.id),
      call(TrackWrapper, orderService.getOpenOrdersCountForFacility, action.payload.id),
      call(getInstructionsData, action.payload.id),
    ]);

    if (httpSuccess(facilityResponse?.status)) {
      facilityResponse = facilityResponse.data;
      openOrdersCount = httpSuccess(openOrdersCount?.status) ? openOrdersCount.data : 0;
      const response = { facilityResponse, openOrdersCount };
      yield put(facilityActions.setFacilityData(response as IFacilityDetails));
      const transformedInternalContacts: IEditFacilityRoles = transformInternalContacts(
        response.facilityResponse.employeeRoles,
        response.facilityResponse.payrollRegion,
        response.facilityResponse.payrollRegionID,
        user.userInfo,
      );
      yield put(facilityActions.setInternalContacts(transformedInternalContacts));

      const clientContacts = facilityResponse?.facilityContacts?.filter(item =>
        item?.actions?.some(action => action?.description === 'Send file to'),
      );
      yield put(facilityActions.setFacilityClientContacts(clientContacts));

      yield put(
        facilityActions.editInternalContact(
          setEditInternalContactsValue(
            'accountCoordinator',
            transformedInternalContacts.accountCoordinator.title,
            transformedInternalContacts.accountCoordinator.id,
            null,
          ),
        ),
      );
      yield put(
        facilityActions.editInternalContact(
          setEditInternalContactsValue(
            'accountManager',
            transformedInternalContacts.accountManager.title,
            transformedInternalContacts.accountManager.id,
            null,
          ),
        ),
      );
      yield put(
        facilityActions.editInternalContact(
          setEditInternalContactsValue(
            'clientContractSpecialist',
            transformedInternalContacts.clientContractSpecialist.title,
            transformedInternalContacts.clientContractSpecialist.id,
            null,
          ),
        ),
      );
      yield put(
        facilityActions.editInternalContact(
          setEditInternalContactsValue(
            'clinicalDirector',
            transformedInternalContacts.clinicalDirector.title,
            transformedInternalContacts.clinicalDirector.id,
            null,
          ),
        ),
      );
      yield put(
        facilityActions.editInternalContact(
          setEditInternalContactsValue(
            'credentialingAnalyst',
            transformedInternalContacts.credentialingAnalyst.title,
            transformedInternalContacts.credentialingAnalyst.id,
            null,
          ),
        ),
      );
      yield put(
        facilityActions.editInternalContact(
          setEditInternalContactsValue(
            'credentialingSpecialist',
            transformedInternalContacts.credentialingSpecialist.title,
            transformedInternalContacts.credentialingSpecialist.id,
            null,
          ),
        ),
      );
      yield put(
        facilityActions.editInternalContact(
          setEditInternalContactsValue(
            'customerSupportSpecialist',
            transformedInternalContacts.customerSupportSpecialist.title,
            transformedInternalContacts.customerSupportSpecialist.id,
            null,
          ),
        ),
      );
      yield put(
        facilityActions.editInternalContact(
          setEditInternalContactsValue(
            'housingAccountExecutive',
            transformedInternalContacts.housingAccountExecutive.title,
            transformedInternalContacts.housingAccountExecutive.id,
            null,
          ),
        ),
      );

      yield put(
        facilityActions.editInternalContact(
          setEditInternalContactsValue(
            'payrollRegion',
            transformedInternalContacts.payrollRegion.title,
            transformedInternalContacts.payrollRegion.id,
            null,
          ),
        ),
      );

      yield put(
        facilityActions.editInternalContact(
          setEditInternalContactsValue(
            'payrollSpecialist',
            transformedInternalContacts.payrollSpecialist.title,
            transformedInternalContacts.payrollSpecialist.id,
            null,
          ),
        ),
      );

      yield put(
        facilityActions.editInternalContact(
          setEditInternalContactsValue(
            'regionalDirector',
            transformedInternalContacts.regionalDirector.title,
            transformedInternalContacts.regionalDirector.id,
            null,
          ),
        ),
      );

      yield put(
        facilityActions.editInternalContact(
          setEditInternalContactsValue(
            'timeProcessingSpecialist',
            transformedInternalContacts.timeProcessingSpecialist.title,
            transformedInternalContacts.timeProcessingSpecialist.id,
            null,
          ),
        ),
      );
      yield put(facilityActions.setFacilityPageLoading(true));
    } else if (httpSuccess(openOrdersCount?.status) && !httpSuccess(facilityResponse?.status)) {
      facilityResponse = null;
      openOrdersCount = openOrdersCount.data;
      const response = { facilityResponse, openOrdersCount };
      yield put(facilityActions.setFacilityData(response as IFacilityDetails));
    }
    if (httpSuccess(facilityInstructions.status)) {
      const { data } = facilityInstructions;
      yield put(facilityActions.setInstructionsData(data));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestGetFacilityDetailsWithOpenOrderCount',
        area: 'src/app/components/Facility/FacilityStore/Facility.saga.ts',
      },
    });
  }
  yield put(facilityActions.setFacilityPageLoading(false));
}

//
// ─── GETUNITSFORFACILITY ────────────────────────────────────────────────────────
//
export function* requestGetUnitsForFacility(action: PayloadAction<{ facilityId: number }>) {
  try {
    yield call(TrackWrapper, getUnitsForFacility, action.payload.facilityId);
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestGetUnitsForFacility',
        area: 'src/app/components/Facility/FacilityStore/Facility.saga.ts',
      },
    });
  }
}

//
// ─── CREATEUNIT ─────────────────────────────────────────────────────────────────
//
export function* requestCreateUnit(action: PayloadAction<{ newUnit: NewUnit }>) {
  try {
    yield call(TrackWrapper, createUnit, action.payload.newUnit);
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestCreateUnit',
        area: 'src/app/components/Facility/FacilityStore/Facility.saga.ts',
      },
    });
  }
}

//
// ─── GETFACILITYREGIONS ────────────────────────────────────────────────────────
//
export function* requestGetFacilityRegions(action: PayloadAction) {
  try {
    const response = yield call(() => getSearchLookups('facilityregion', '')); //getSearchLookups is returning Data, not a full HTTP response
    if (!!response) {
      yield put(facilityActions.setFacilityRegions(response));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestGetFacilityRegions',
        area: 'src/app/components/Facility/FacilityStore/Facility.saga.ts',
      },
    });
  }
}

//
// ─── GETCLIENTCONTACT ───────────────────────────────────────────────────────────
//
export function* requestGetClientContact(action: PayloadAction<{ searchString: string; pageNumber?: number }>) {
  try {
    yield call(TrackWrapper, getClientContact, {
      searchString: action.payload.searchString,
      pageNumber: action.payload.pageNumber,
    });
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestGetClientContact',
        area: 'src/app/components/Facility/FacilityStore/Facility.saga.ts',
      },
    });
  }
}

//
// ─── GETFACILITYCONTRACT ───────────────────────────────────────────────────────────
//
export function* requestGetFacilityContract(action: PayloadAction<number>) {
  try {
    const response = yield call(TrackFacilityContract, getFacilityContract, action.payload);
    if (httpSuccess(response.status)) {
      yield put(facilityActions.setContractDetails(response.data));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestGetFacilityContract',
        area: 'src/app/components/Facility/FacilityStore/Facility.saga.ts',
      },
    });
  }
}

//
// ─── GETFACILITYADDITIONALCONTRACT ───────────────────────────────────────────────────────────
//
export function* requestGetFacilityAdditionalContract(action: PayloadAction<number>) {
  try {
    const response = yield call(TrackAdditionalContracts, getAdditionalContractDetail, action.payload);

    if (httpSuccess(response.status) && response?.data) {
      const { data } = response;
      const formattedResponse = {
        billRateAgreement: data?.billRateAgreement ? data?.billRateAgreement : missingField,
        contractId: data?.contractId,
        directHireDescription: data?.directHireDescription ? data?.directHireDescription : missingField,
        isDirectHire: data?.isDirectHire ? 'Yes' : 'No',
        localRadiusRuleNotes: data?.localRadiusRuleNotes ? data?.localRadiusRuleNotes : missingField,
        notes: data?.notes ? data?.notes : missingField,
        permAddressWithin: data?.permAddressWithin ? data?.permAddressWithin : missingField,
        radiusRule: data?.radiusRule ? data?.radiusRule : missingField,
      };
      yield put(facilityActions.setAdditionalContractDetails(formattedResponse));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestGetFacilityAdditionalContract',
        area: 'src/app/components/Facility/FacilityStore/Facility.saga.ts',
      },
    });
  }
}

//
// ─── GETDICIPLINESPECIALITYRATES ───────────────────────────────────────────────────────────
//
export function* requestGetDisciplineSpecialityRates(action: PayloadAction<any>) {
  try {
    const response = yield call(TrackSpecialtyRates, getDisciplineSpecialityRates, action.payload);
    yield put(facilityActions.setDisciplineSpecialityRates(response));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestGetDisciplineSpecialityRates',
        area: 'src/app/components/Facility/FacilityStore/Facility.saga.ts',
      },
    });
  }
}

//
// ─── SEARCHFACILITIES ───────────────────────────────────────────────────────────
//
export function* requestSearchFacilities(action: PayloadAction<{ searchString: string; pageNumber?: number }>) {
  try {
    yield call(TrackWrapper, searchFacilities, {
      searchString: action.payload.searchString,
      pageNumber: action.payload.pageNumber,
    });
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestSearchFacilities',
        area: 'src/app/components/Facility/FacilityStore/Facility.saga.ts',
      },
    });
  }
}

//
// ─── SEARCHSKILLSETS ────────────────────────────────────────────────────────────
//
export function* requestSearchSkillsets(action: PayloadAction<{ keyword: string }>) {
  try {
    yield call(TrackWrapper, searchSkillsets, {
      keyword: action.payload.keyword,
    });
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestSearchSkillsets',
        area: 'src/app/components/Facility/FacilityStore/Facility.saga.ts',
      },
    });
  }
}

//
//─── DISCIPLINEFORFACILITY ────────────────────────────────────────────────────────────
//
export function* requestFacilityContractDiscipline(action: PayloadAction<number>) {
  try {
    const response = yield call(TrackContractDiscipline, getFacilityContractDiscipline, action.payload);
    const sortedResponse = [...response].sort((a, b) => (a.discipline > b.discipline ? 1 : -1));
    yield put(facilityActions.setDisciplineList(sortedResponse));
    yield put(facilityActions.setSelectedDiscipline(sortedResponse[0]));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestFacilityContractDiscipline',
        area: 'src/app/components/Facility/FacilityStore/Facility.saga.ts',
      },
    });
  }
}
//
//─── RATEELEMENTS ────────────────────────────────────────────────────────────
//
export function* requestRateElements(action: PayloadAction<IGetRateElement>) {
  try {
    const response = yield call(getRateElements, action.payload);
    yield put(facilityActions.setRateElements(response));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestRateElements',
        area: 'src/app/components/Facility/FacilityStore/Facility.saga.ts',
      },
    });
  }
}

//
// ─── POST OFF CONTRACT ─────────────────────────────────────────────────────────────────
//
export function* requestSaveOffContract(action: PayloadAction<{ offContract: any }>) {
  try {
    const response = yield call(TrackSaveOffContract, postOffcontractDetails, action.payload.offContract);
    if (response.offContractId.length) {
      yield put(
        globalActions.setSnackBar({
          message: 'New Off Contract Rate Elements created successfully',
          severity: 'success',
        }),
      );
      yield put(facilityActions.setCreatedOffContractId(response.offContractId));
    } else {
      yield put(
        globalActions.setSnackBar({
          message: 'Failed to create Off Contract Rate',
          severity: 'error',
        }),
      );
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestSaveOffContract',
        area: 'src/app/components/Facility/FacilityStore/Facility.saga.ts',
      },
    });
  }
}
//
//─── OFFCONTRACT ────────────────────────────────────────────────────────────
//
export function* requestOffContractRateElements(action: PayloadAction<IOffContract>) {
  try {
    const response = yield call(TrackOffContractRateElements, getOffContractRateElements, action.payload);
    if (response?.length > 2) {
      const formattedData = sort(response?.rateElements);
      yield put(facilityActions.setOffContract({ rateElements: formattedData, totalCount: response.totalCount }));
    } else {
      yield put(facilityActions.setOffContract(response));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestOffContractRateElements',
        area: 'src/app/components/Facility/FacilityStore/Facility.saga.ts',
      },
    });
  }
}

//
//─── OFFCONTRACTAPPROVALDOCUMENT ────────────────────────────────────────────────────────────
//
export function* requestOffContractApprovalDocuments(action: PayloadAction<IRequestApprovalDocument>) {
  try {
    const response = yield call(TrackApprovalDocument, getOffContractApprovalDocument, action.payload);
    if (httpSuccess(response.status)) {
      const formattedAttachments = response.data.attachments.map(item => ({
        ...item,
        hash: item.documentId ?? item.id,
        size: item.attachmentSize,
      }));
      const data = { ...response.data, attachments: formattedAttachments, rateElementId: action.payload.rateElementId };
      yield put(facilityActions.setApprovalDocument(data));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestOffContractRateElements',
        area: 'src/app/components/Facility/FacilityStore/Facility.saga.ts',
      },
    });
  }
}

//
// ─── POST OFF CONTRACT ─────────────────────────────────────────────────────────────────
//
export function* updateRateElements(
  action: PayloadAction<{ saveRateElementCommand: ISaveRateElementCommand; message?: string }>,
) {
  try {
    const response = yield call(TrackUpdateRateElements, postRateElements, action.payload.saveRateElementCommand);
    if (httpSuccess(response.status)) {
      yield put(facilityActions.setUpdatedOffContract(true));
      yield put(
        globalActions.setSnackBar({
          message: action.payload.message ? action.payload.message : 'Rate Elements updated successfully',
          severity: 'success',
        }),
      );
    } else {
      yield put(
        globalActions.setSnackBar({
          message: 'Failed to update the Rate Elements',
          severity: 'error',
        }),
      );
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'updateRateElements',
        area: 'src/app/components/Facility/FacilityStore/Facility.saga.ts',
      },
    });
  }
}

//
// ─── SAVE OCBR ATTACHMENTS ─────────────────────────────────────────────────────────────────
//
export function* requestSaveOCBRAttachments(action: PayloadAction<{ saveAttachments: ISaveOCBRAttachments }>) {
  try {
    const request = new FormData();
    const attachments = action.payload.saveAttachments.attachments;
    request.append('command', JSON.stringify(action.payload.saveAttachments.command));
    attachments && attachments.map(attachment => request.append('attachments', attachment ?? null));
    const response = yield call(TrackSaveOCBRAttachments, saveOCBRAttachments, request);
    if (httpSuccess(response.status)) {
      yield put(facilityActions.setUploadedDocuments(response.data));
    } else {
      yield put(
        globalActions.setSnackBar({
          message: 'Failed to upload the OCBR attachments',
          severity: 'error',
        }),
      );
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'updateRateElements',
        area: 'src/app/components/Facility/FacilityStore/Facility.saga.ts',
      },
    });
  }
}

//
// ─── POST INTERNAL CONTACTS─────────────────────────────────────────────────────────────────
//
export function* requestUpdateInternalContact(
  action: PayloadAction<{
    updateInternalContact: IUpdateInternalContactCommand;
    setInternalContact: IDisplayInternalContact;
  }>,
) {
  const serviceTracker = `save-contact-${action.payload.setInternalContact.employeeRole}`;
  try {
    manuallyIncrementPromiseCounter(serviceTracker);
    const { updateInternalContact, setInternalContact } = action.payload;
    const internalContacts = { ...updateInternalContact, ...setInternalContact };

    const facilityService: FacilityService = yield getContext('facilityService');
    const response = yield call(
      TrackUpdateInternalContacts,
      facilityService.postUpdateInternalContacts,
      updateInternalContact,
    );

    yield put(facilityActions.setSaveModalState({ open: false, data: undefined }));
    if (httpSuccess(response.status)) {
      const currentFacilityRoles = yield select(selectFacility);
      const userName = updateInternalContact.userRoleName.split(' ');
      const firstName = userName[0];
      const lastName = `${userName[1] ?? ''} ${userName[2] ?? ''} ${userName[3] ?? ''}`;
      const existingData = yield select(selectFacilityInternalContacts);
      const currentUser = yield select(selectUser);

      const newData = {
        ...existingData,
        [internalContacts.employeeRole]: internalContacts,
      };

      if (updateInternalContact.isParentUpdateRequired) {
        yield put(
          globalActions.setSnackBar({
            message: `${updateInternalContact.key} has been updated at the Facility, Order and Placement levels.`,
            severity: 'success',
          }),
        );
      } else if (updateInternalContact.key === 'Account Manager' || updateInternalContact.key === 'Regional Director') {
        yield put(
          globalActions.setSnackBar({
            message: `${updateInternalContact.key} has been updated at the Facility level.`,
            severity: 'success',
          }),
        );
      }
      if (internalContacts.key === 'Payroll Region') {
        yield put(
          facilityActions.setFacilityData({
            ...currentFacilityRoles,
            facilityResponse: {
              ...currentFacilityRoles?.facilityResponse,
              payrollRegionID: updateInternalContact?.userID,
              payrollRegion: firstName != '' ? `${firstName} ${lastName}` : null,
            },
          }),
        );
      } else {
        yield put(
          facilityActions.setFacilityData({
            ...currentFacilityRoles,
            facilityResponse: {
              ...currentFacilityRoles?.facilityResponse,
              employeeRoles: {
                ...currentFacilityRoles?.facilityResponse?.employeeRoles,
                [internalContacts.employeeRole]: {
                  employeeId: updateInternalContact?.userID,
                  firstName: firstName,
                  lastName: lastName,
                },
              },
              lastUpdatedBy: {
                ...currentFacilityRoles?.facilityResponse?.lastUpdatedBy,
                email: currentUser?.userInfo?.email,
                employeeId: currentUser?.userInfo?.employeeId,
                firstName: currentUser?.userInfo?.firstName,
                lastName: currentUser?.userInfo?.lastName,
              },
              lastUpdated: response?.data?.lastUpdated,
            },
          }),
        );
      }
      manuallyDecrementPromiseCounter(serviceTracker);
      yield put(facilityActions.setInternalContacts(newData));
      yield put(
        facilityActions.editInternalContact(
          setEditInternalContactsValue(
            internalContacts?.employeeRole,
            internalContacts?.userRoleName,
            internalContacts?.userID,
            'Saved',
          ),
        ),
      );
      yield delay(2000);
      yield put(facilityActions.removeInternalContactHelperText(internalContacts?.employeeRole));
    } else {
      manuallyDecrementPromiseCounter(serviceTracker);
      yield put(
        facilityActions.setInternalContactUpdateError({
          key: internalContacts.employeeRole,
          error: { message: 'Cannot be saved.' },
        }),
      );
      if (updateInternalContact.isParentUpdateRequired) {
        yield put(
          globalActions.setSnackBar({
            message: `${updateInternalContact.key} could not be updated at the Facility, Order and Placement levels. Please try again`,
            severity: 'error',
          }),
        );
      } else {
        yield put(
          globalActions.setSnackBar({
            message: `${updateInternalContact.key} could not be updated at the Facility level. Please try again`,
            severity: 'error',
          }),
        );
      }
    }
  } catch (error) {
    manuallyDecrementPromiseCounter(serviceTracker);
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestUpdateInternalContact',
        area: 'src/app/components/Facility/FacilityStore/Facility.saga.ts',
      },
    });
  }
}

//
// ─── FACILITY MISCELLANEOUS DATA ────────────────────────────────────────────────────────────────
//
export function* requestFaciltiyMiscellaneousData() {
  try {
    const sharedService: SharedService = yield getContext('sharedService');

    const response = yield call(sharedService.getDisciplineSpecialtyData);
    if (httpSuccess(response.status)) {
      const disciplineOpts = formatTypeAheadDisciplineOptions(response.data.disciplines);
      const specialtyOpts = formatTypeAheadSpecialtyOptions(response.data.disciplineSpecialties);
      const subSpecialtyOpts = formatTypeAheadSubSpecialtyOptions(response.data.subSpecialties);
      const divisionOpts = response.data.divisionDisciplines;

      yield put(
        facilityActions.setMiscellaneousData([
          { key: 'disciplineOpts', data: disciplineOpts },
          { key: 'specialtyOpts', data: specialtyOpts },
          { key: 'subSpecialtyOpts', data: subSpecialtyOpts },
          { key: 'divisionDisciplineOpts', data: divisionOpts },
        ]),
      );
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'getCreateOrderMiscellaneousData',
        area: 'store.redux-store.create-order-ocbr.saga.ts',
      },
    });
  }
}

//
// ─── UPDATE FACILITY REGION ─────────────────────────────────────────────────────────────────
//
export function* requestUpdateFacilityRegion(action: PayloadAction<IFacilityRegionUpdateCommand>) {
  const serviceTracker = 'update-facility-region';
  const currentUser = yield select(selectUser);
  const currentFacility = yield select(selectFacility);
  try {
    manuallyIncrementPromiseCounter(serviceTracker);
    const response = yield call(TrackUpdateFacilityRegion, postUpdateFaciliytRegion, action.payload);
    const editedRegion = yield select(selectEditFacilityRegion);
    if (httpSuccess(response.status)) {
      yield put(facilityActions.setFacilityRegion(editedRegion.name));
      yield put(
        facilityActions.setFacilityData({
          ...currentFacility,
          facilityResponse: {
            ...currentFacility.facilityResponse,
            lastUpdatedBy: {
              ...currentFacility.facilityResponse.lastUpdatedBy,
              email: currentUser.userInfo.email,
              employeeId: currentUser.userInfo.employeeId,
              firstName: currentUser.userInfo.firstName,
              lastName: currentUser.userInfo.lastName,
            },
            lastUpdated: response.data.lastUpdated,
            location: {
              ...currentFacility.facilityResponse.location,
              region: editedRegion.name,
              regionId: editedRegion.id,
            },
          },
        }),
      );
      yield put(facilityActions.editFacilityRegion({ ...editedRegion, helperText: 'Saved' }));
      yield put(facilityActions.setFacilityRegionModalState({ open: false, data: undefined }));
      if (!action.payload.IsPlacementOrderUpdateRequired) {
        yield put(
          globalActions.setSnackBar({
            message: 'Region has been updated at the Facility level.',
            severity: 'success',
          }),
        );
      } else {
        yield put(
          globalActions.setSnackBar({
            message: 'Region has been updated at the Facility, Order and Placement levels.',
            severity: 'success',
          }),
        );
      }
      manuallyDecrementPromiseCounter(serviceTracker);
      yield delay(2000);
      yield put(facilityActions.editFacilityRegion(undefined));
    } else {
      yield put(facilityActions.editFacilityRegion({ ...editedRegion, error: { message: 'Failed' } }));
      yield put(facilityActions.setFacilityRegionModalState({ open: false, data: undefined }));
      if (!action.payload.IsPlacementOrderUpdateRequired) {
        yield put(
          globalActions.setSnackBar({
            message: 'Region could not be updated at the Facility level. Please try again.',
            severity: 'error',
          }),
        );
      } else {
        yield put(
          globalActions.setSnackBar({
            message: 'Region could not be updated at the Facility, Order and Placement levels. Please try again.',
            severity: 'error',
          }),
        );
      }
      manuallyDecrementPromiseCounter(serviceTracker);
      yield delay(2000);
      yield put(facilityActions.editFacilityRegion(undefined));
    }
  } catch (error) {
    yield put(facilityActions.setFacilityRegionModalState({ open: false, data: undefined }));
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'UpdateFacilityLocationRegion',
        area: 'src/app/components/Facility/FacilityStore/Facility.saga.ts',
      },
    });
    manuallyDecrementPromiseCounter(serviceTracker);
  }
}

export function* fetchFacilityInstructionData(action: PayloadAction<number>) {
  try {
    const response = yield call(TrackGetFacilityInstructions, getInstructionsData, action.payload);
    if (httpSuccess(response.status)) {
      const { data } = response;
      yield put(facilityActions.setInstructionsData(data));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'fetchFacilityInstructionData',
        area: 'src/app/components/Facility/FacilityStore/Facility.saga.ts',
      },
    });
  }
}

export function* fetchFacilityContractAffiliate(action: PayloadAction<number>) {
  try {
    const response = yield call(TrackGetFacilityContractAffiliate, getFacilityContactAffiliate, action.payload);
    if (httpSuccess(response.status)) {
      const { data } = response;
      yield put(facilityActions.setContractAffiliateData(data));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'fetchFacilityContractAffiliate',
        area: 'src/app/components/Facility/FacilityStore/Facility.saga.ts',
      },
    });
  }
}

export function* fetchFacilitiesForAffiliate(action: PayloadAction<{ affilitationId: number }>) {
  try {
    const response = yield call(TrackGetFacilitiesForAffiliate, getFacilitiesForAffiliate, action.payload);
    if (httpSuccess(response.status)) {
      const { data } = response;
      const gridDataWithIds = data.map((item, index) => {
        return { ...item, id: index };
      });
      yield put(facilityActions.setFaciliteisForAssociate(gridDataWithIds));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'fetchFacilitiesForAffiliate',
        area: 'src/app/components/Facility/FacilityStore/Facility.saga.ts',
      },
    });
  }
}

export const postInstructionsDataPromiseKey = 'post-instructions-data';
export function* fetchFacilityInstructionUpdateData(action: PayloadAction<IUpdateFacilityInstructionsCommand>) {
  try {
    yield put(facilityActions.setFacilityInstructionsSuccess(false));

    manuallyIncrementPromiseCounter(postInstructionsDataPromiseKey);
    const currentFacility = yield select(selectFacility);
    const currentUser = yield select(selectUser);
    const response = yield call(postInstructionsData, action.payload);

    if (httpSuccess(response.status)) {
      yield put(facilityActions.setFacilityInstructionsSuccess(true));
      yield put(
        facilityActions.setFacilityData({
          ...currentFacility,
          instructions: { ...currentFacility.instructions, firstDayInstructions: action.payload.firstDayInstructions },
          facilityResponse: {
            ...currentFacility.facilityResponse,
            lastUpdatedBy: {
              ...currentFacility.facilityResponse.lastUpdatedBy,
              email: currentUser.userInfo.email,
              employeeId: currentUser.userInfo.employeeId,
              firstName: currentUser.userInfo.firstName,
              lastName: currentUser.userInfo.lastName,
            },
            lastUpdated: response.data.lastUpdated,
          },
        }),
      );
      yield put(
        globalActions.setSnackBar({
          message: action.payload.isUnitUpdateRequired
            ? action.payload.t('facility.instructionsTab.sanckbarSaveSuccessWithUnits')
            : action.payload.t('facility.instructionsTab.snackbarSaveSuccess'),
          severity: 'success',
        }),
      );
    } else {
      yield put(
        globalActions.setSnackBar({
          message: action.payload.isUnitUpdateRequired
            ? action.payload.t('facility.instructionsTab.snackbarSaveErrorWithUnits')
            : action.payload.t('facility.instructionsTab.snackbarSaveError'),
          severity: 'error',
        }),
      );
    }
  } catch (error) {
    yield put(
      globalActions.setSnackBar({
        message: action.payload.isUnitUpdateRequired
          ? action.payload.t('facility.instructionsTab.snackbarSaveErrorWithUnits')
          : action.payload.t('facility.instructionsTab.snackbarSaveError'),
        severity: 'error',
      }),
    );
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'fetchFacilityInstructionData',
        area: 'src/app/components/Facility/FacilityStore/Facility.saga.ts',
      },
    });
  } finally {
    manuallyDecrementPromiseCounter(postInstructionsDataPromiseKey);
  }
}

export function* setSelectedUnitDetail(action: PayloadAction<{ unitId: number }>) {
  try {
    const unitsData = yield select(selectUnitsData);
    const selectedUnit: NewUnit | undefined = unitsData.find(unit => unit.unitId == action.payload.unitId);
    if (selectedUnit) {
      const isStandardizedRequirements =
        selectedUnit?.standardizedRequirements === true || selectedUnit?.createdByVersion === '2.0';
      yield put(
        facilityActions.setUnitNavigationDetails({
          facilityId: selectedUnit?.facilityId,
          unitId: selectedUnit?.unitId,
          mode: isStandardizedRequirements ? 'standardized' : 'notnow',
          standardizedRequirements: selectedUnit?.standardizedRequirements,
        }),
      );
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonUIError,
        functionName: 'setSelectedUnitDetail',
        area: 'src/app/components/Facility/FacilityStore/Facility.saga.ts',
      },
    });
  }
}

//
// ─── UPDATE DRUG SCREEN POLICY ─────────────────────────────────────────────────────────────────
//
export function* requestUpdateFacilityDrugScreenPolicy(
  action: PayloadAction<{ updateDrugScreenPolicy: IUpdateDrugScreenPolicyCommand }>,
) {
  const currentUser = yield select(selectUser);
  const currentFacility = yield select(selectFacility);
  try {
    const facilityService: FacilityService = yield getContext('facilityService');
    const response = yield call(facilityService.postUpdateDrugScreenPolicy, action.payload.updateDrugScreenPolicy);

    if (httpSuccess(response.status)) {
      yield put(
        facilityActions.setFacilityData({
          ...currentFacility,
          facilityResponse: {
            ...currentFacility.facilityResponse,
            lastUpdatedBy: {
              ...currentFacility.facilityResponse?.lastUpdatedBy,
              email: currentUser.userInfo.email,
              employeeId: currentUser.userInfo.employeeId,
              firstName: currentUser.userInfo.firstName,
              lastName: currentUser.userInfo.lastName,
            },
            lastUpdated: response.data.lastUpdated,
          },
          instructions: {
            ...currentFacility.instructions,
            marijuanaPolicyName: action.payload.updateDrugScreenPolicy.drugScreenPolicyName,
            marijuanaPolicyID: action.payload.updateDrugScreenPolicy.drugScreenPolicyID,
            isSendDrugScreen: action.payload.updateDrugScreenPolicy.isSendDrugScreen,
          },
        }),
      );
      yield put(
        globalActions.setSnackBar({
          message: 'Drug Screen Policy has successfully been updated',
          severity: 'success',
        }),
      );
    } else {
      yield put(
        globalActions.setSnackBar({
          message: 'Drug Screen Policy could not be updated. Please try again.',
          severity: 'error',
        }),
      );
    }
  } catch (error) {
    yield put(facilityActions.setFacilityRegionModalState({ open: false, data: undefined }));
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestUpdateFacilityDrugScreenPolicy',
        area: 'src/app/components/Facility/FacilityStore/Facility.saga.ts',
      },
    });
  }
}

//
// ─── GET DRUG SCREEN POLICIES ────────────────────────────────────────────────────────
//
export function* requestGetFacilityDrugScreenPolicies() {
  try {
    const sharedService: SharedService = yield getContext('sharedService');
    const data = yield call(sharedService.getFacilityDrugScreenPolicyOptions);
    if (!!data) {
      const mapPolicies = data?.map(x => {
        return {
          id: x.value,
          label: x.name,
        };
      });
      yield put(facilityActions.setFacilityDrugScreenPolicies(mapPolicies));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestGetDrugScreenPolicies',
        area: 'src/app/components/Facility/FacilityStore/Facility.saga.ts',
      },
    });
  }
}

//
// ─── UPDATE FACILITY REFERENCES ─────────────────────────────────────────────────────────────────
//
export function* requestUpdateFacilityReferences(action: PayloadAction<IUpdateReferencesCommand>) {
  try {
    manuallyIncrementPromiseCounter(updateFacilityReferencePromiseKey);
    const currentFacility = yield select(selectFacility);
    const currentUser = yield select(selectUser);
    const facilityService: FacilityService = yield getContext('facilityService');
    const response = yield call(facilityService.updateFacilityReferences, action.payload);
    if (httpSuccess(response.status)) {
      yield put(
        facilityActions.setFacilityData({
          ...currentFacility,
          facilityResponse: {
            ...currentFacility.facilityResponse,
            lastUpdatedBy: {
              ...currentFacility.facilityResponse?.lastUpdatedBy,
              email: currentUser.userInfo.email,
              employeeId: currentUser.userInfo.employeeId,
              firstName: currentUser.userInfo.firstName,
              lastName: currentUser.userInfo.lastName,
            },
            lastUpdated: response.data.lastUpdated,
            numberOfReferenceRequired: action.payload.numberOfReferenceRequired,
            numberOfReferenceToRFO: action.payload.numberOfReferencesToRFO,
            submissionRequiredReference: action.payload.submissionRequiredReference,
            supervisorAndAboveReference: action.payload.supervisorAndAboveReference,
            withinLastMonthsReferenceChanged: action.payload.withinLastMonthsReferenceChanged,
            entireWorkHistory: action.payload.entireWorkHistory,
            facilityReferenceChangedBy: {
              email: currentUser.userInfo.email,
              employeeId: currentUser.userInfo.employeeId,
              firstName: currentUser.userInfo.firstName,
              lastName: currentUser.userInfo.lastName,
            },
            facilityReferenceChangedDate: response.data.lastUpdated,
          },
        }),
      );
      yield put(
        globalActions.setSnackBar({
          message: 'References has been successfully updated',
          severity: 'success',
        }),
      );
    } else {
      yield put(
        globalActions.setSnackBar({
          message: 'References could not be updated. Please try again.',
          severity: 'error',
        }),
      );
    }
  } catch (err) {
    yield put(
      globalActions.setSnackBar({
        message: 'References could not be updated. Please try again.',
        severity: 'error',
      }),
    );
  } finally {
    manuallyDecrementPromiseCounter(updateFacilityReferencePromiseKey);
  }
}

export function* requestUpdateForBestBets(action: PayloadAction<IBestBets>) {
  try {
    const currentUser = yield select(selectUser);
    const currentFacility = yield select(selectFacility);
    const payload = {
      id: action.payload.id, // Id can be facilityId/unitId
      bestBetsLabel: action.payload.bestBetsLabel,
      bestBetsId: action.payload.bestBetsId,
      isComingFromFacility: action.payload.isComingFromFacility,
      currentEmployeeId: currentUser.userInfo.employeeId,
      currentEmployeeName: `${currentUser.userInfo.firstName} ${currentUser.userInfo.lastName}`,
    };
    const response = yield call(updateFacilityBestBets, payload);
    if (httpSuccess(response.status)) {
      if (!action.payload.isComingFromFacility) {
        const unitsSourceData = yield select(selectUnitsData);
        const updatedItems = unitsSourceData.map(unit =>
          unit.unitId === Number(action.payload.id) &&
          unit.facilityId === Number(currentFacility?.facilityResponse?.facilityId)
            ? {
                ...unit,
                bestBets: action.payload.bestBetsLabel,
                bestBetsId: action.payload.bestBetsId,
              }
            : unit,
        );
        yield put(FacilityUnitActions.setUnitsSourceData(updatedItems));
      } else {
        yield put(
          facilityActions.setFacilityData({
            ...currentFacility,
            facilityResponse: {
              ...currentFacility.facilityResponse,
              lastUpdatedBy: {
                ...currentFacility.facilityResponse.lastUpdatedBy,
                email: currentUser.userInfo.email,
                employeeId: currentUser.userInfo.employeeId,
                firstName: currentUser.userInfo.firstName,
                lastName: currentUser.userInfo.lastName,
              },
              lastUpdated: response.data.lastUpdated,
              bestBets: action.payload.bestBetsLabel,
              bestBetsId: action.payload.bestBetsId,
            },
          }),
        );
        yield put(
          facilityActions.setHttpStatus({
            statusCode: response?.status,
            pageName: BestBetsType.FACILITY_BEST_BETS,
          }),
        );
      }
    }
    yield put(
      globalActions.setSnackBar({
        message: 'Best Bets has been successfully updated',
        severity: 'success',
      }),
    );
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestUpdateForBestBets',
        area: 'src/app/components/Facility/FacilityStore/Facility.saga.ts',
      },
    });
    yield put(
      globalActions.setSnackBar({
        message: 'Best Bets could not be updated. Please try again.',
        severity: 'error',
      }),
    );
    yield put(
      facilityActions.setHttpStatus({
        statusCode: error,
        pageName: BestBetsType.FACILITY_BEST_BETS,
      }),
    );
  }
}

export function* facilitySaga() {
  yield takeLatest(
    facilityActions.getFacilityDetailsWithOpenOrderCountAction.type,
    requestGetFacilityDetailsWithOpenOrderCount,
  );
  yield takeLatest(facilityActions.getFacilityDetailsAction.type, requestGetFacilityDetails);
  yield takeLatest(facilityActions.getUnitsForFacilityAction.type, requestGetUnitsForFacility);
  yield takeLatest(facilityActions.createUnitAction.type, requestCreateUnit);
  yield takeLatest(facilityActions.getFacilityRegions.type, requestGetFacilityRegions);
  yield takeLatest(facilityActions.getClientContactAction.type, requestGetClientContact);
  yield takeLatest(facilityActions.getFacilityContractAction.type, requestGetFacilityContract);
  yield takeLatest(facilityActions.getAdditionalContractAction.type, requestGetFacilityAdditionalContract);
  yield takeLatest(facilityActions.searchSkillsetsAction.type, requestSearchSkillsets);
  yield takeLatest(facilityActions.searchFacilitiesAction.type, requestSearchFacilities);
  yield takeLatest(facilityActions.getFacilityDisciplineAction.type, requestFacilityContractDiscipline);
  yield takeLatest(facilityActions.getDisciplineSpecialityRatesAction.type, requestGetDisciplineSpecialityRates);
  yield takeLatest(facilityActions.getRateElementsAction.type, requestRateElements);
  yield takeLatest(facilityActions.saveOffContractAction.type, requestSaveOffContract);
  yield takeLatest(facilityActions.getOffContractRateElements.type, requestOffContractRateElements);
  yield takeLatest(facilityActions.getApprovalDocuments.type, requestOffContractApprovalDocuments);
  yield takeLatest(facilityActions.updateRateElements.type, updateRateElements);
  yield takeLatest(facilityActions.requestUpdateInternalContact.type, requestUpdateInternalContact);
  yield takeLatest(facilityActions.saveOCBRAttachmentsAction.type, requestSaveOCBRAttachments);
  yield takeLatest(facilityActions.getMiscellaneousData.type, requestFaciltiyMiscellaneousData);
  yield takeLatest(facilityActions.updateFacilityRegionAction.type, requestUpdateFacilityRegion);
  yield takeLatest(facilityActions.getFacilityInstructions.type, fetchFacilityInstructionData);
  yield takeLatest(facilityActions.updateFacilityInstructions.type, fetchFacilityInstructionUpdateData);
  yield takeLatest(facilityActions.getSelectedUnitDetail.type, setSelectedUnitDetail);
  yield takeLatest(facilityActions.getContractAffiliateData.type, fetchFacilityContractAffiliate);
  yield takeLatest(facilityActions.requestUpdateDrugScreenPolicy.type, requestUpdateFacilityDrugScreenPolicy);
  yield takeLatest(facilityActions.getFacilityDrugScreenPolicies.type, requestGetFacilityDrugScreenPolicies);
  yield takeLatest(facilityActions.updateReferencesAction.type, requestUpdateFacilityReferences);
  yield takeLatest(facilityActions.getFacilitiesForAffiliate.type, fetchFacilitiesForAffiliate);
  yield takeLatest(facilityActions.updateBestBetsForFacility.type, requestUpdateForBestBets);
}
