/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import { Divider, Grid, ThemeProvider, StyledEngineProvider, CircularProgress,useTheme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { UnitCardsSubTitle, UnitCardsTitle, UnitDetailsBody } from '../UnitDesign';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useDecision } from '@optimizely/react-sdk';
import { ff_order_ui_skillssection } from 'app/constants/FeatureFlags/Order/Keys';
import {
  selectLoadingCertificationRequrements,
  selectLoadingSkillCheckListRequrements,
} from '@AMIEWEB/Order/Store/OrderCreation.selectors';
import { NewEMR } from '../EMR/NewEMR';
import { NewUnitSkillCheckList } from './NewUnitSkillCheckList';
import { LicenseDropdownWrapper } from '@AMIEWEB/Order/OrderCreationForm/Components/OrderLicense/LicenseDropdownWrapper';
import { ff_order_ui_addlicensesection } from 'app/constants/FeatureFlags/Order/Keys';
import { orderDataActions } from '@AMIEWEB/Order/Store/Order.redux';
import { CertificationDropdownWrapper } from './NewCertificationDropdown/CertificationDropdownWrapper';

interface Props {
  //onUnitPropChange: (property: string, value: any) => void;
  disableEdit?: boolean;
  //qualificationData?: QualificationType | null;
}

const useStyles = makeStyles()(theme => ({
  titleRightPadding: {
    paddingRight: 23,
  },
  wrapper: {
    paddingTop: theme.spacing(2),
  },
  divider: {
    marginTop: '1%',
  },
}));

export const NewUnitQualification = (props: Props) => {
  const { disableEdit } = props;
  const { classes } = useStyles();
 const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  /*const newOrderObj = useSelector(selectNewUnit);
  const qualificationInitialData = newOrderObj.qualification;

  const qualification = qualificationInitialData
    ? qualificationInitialData
    : {
        activeLicense: false,
        compactLicense: false,
        emr: {
          isRequired: true,
          values: [],
        },
        certifications: [],
        skillChecklists: [],
      };*/

  const [skillsSectionDecision] = useDecision(ff_order_ui_skillssection);
  const certificationsRef = useRef();
  const loadingCertificationRequirements = useSelector(selectLoadingCertificationRequrements);
  const loadingSkillCheckListRequirements = useSelector(selectLoadingSkillCheckListRequrements);
  const [licenseSectionFeatureFlag] = useDecision(ff_order_ui_addlicensesection);

  /*const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedData = { ...qualification, [event.target.name]: event.target.checked } as QualificationType;
    dispatch(requestOrderCreationUpdateQualificationDetailsAction({qualificationData: updatedData}));
  };*/

  useEffect(() => {
    return () => {
      dispatch(orderDataActions.setOrderLicenseDetails([]));
      dispatch(orderDataActions.setOrderCertificationDetails([]));
    };
  }, []);

  /*const onQualificationChange = (property, value) => {
    const updatedData = { ...qualification, [property]: value } as QualificationType;
    //dispatch(requestOrderCreationUpdateQualificationDetailsAction({qualificationData: updatedData}));
  };*/

  return (
    <div>
      {/* TODO: Remove this theme wrapper since it uses the global theme */}
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <UnitDetailsBody variant="outlined">
            <Grid spacing={1}>
              <Grid item xs={12}>
                <Grid container direction="row" spacing={1}>
                  <Grid item>
                    <UnitCardsTitle className={classes.titleRightPadding}>
                      {t('order.createUnit.qualifications.title')}
                    </UnitCardsTitle>
                  </Grid>
                </Grid>
                {licenseSectionFeatureFlag.enabled && (
                  <>
                    <Grid container direction="row" spacing={1} className={classes.wrapper}>
                      <Grid item>
                        <UnitCardsSubTitle className={classes.titleRightPadding}>{t('Licenses')}</UnitCardsSubTitle>
                      </Grid>
                    </Grid>
                    <Grid container direction="row" spacing={1} className={classes.wrapper}>
                      <Grid item xs={12}>
                        <LicenseDropdownWrapper isDisabled={disableEdit} />
                      </Grid>
                    </Grid>
                    <Divider className={classes.divider} />
                  </>
                )}
                <Grid container direction="row" spacing={1} className={classes.wrapper}>
                  <Grid item>
                    <UnitCardsSubTitle className={classes.titleRightPadding}>
                      {t('order.createUnit.qualifications.certifications')}
                    </UnitCardsSubTitle>
                  </Grid>
                  {loadingCertificationRequirements === true ? (
                    <CircularProgress style={{ width: '12px', height: '12px', position: 'relative', top: '12px' }} />
                  ) : null}
                </Grid>
                <Grid container direction="row" spacing={1} className={classes.wrapper}>
                  <Grid item xs={12} ref={certificationsRef}>
                    <CertificationDropdownWrapper isDisabled={disableEdit} />
                  </Grid>
                </Grid>
                <Divider className={classes.divider} />
                <>
                  <Grid container direction="row" spacing={1} className={classes.wrapper}>
                    <Grid item>
                      <UnitCardsSubTitle className={classes.titleRightPadding}>
                        {t('order.createUnit.qualifications.skills')}
                        {loadingSkillCheckListRequirements === true ? (
                          <CircularProgress
                            style={{ width: '12px', height: '12px', position: 'relative', top: '1px', left: '6px' }}
                          />
                        ) : null}
                      </UnitCardsSubTitle>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={1} className={classes.wrapper}>
                    <Grid item xs={12}>
                      <NewUnitSkillCheckList isdisabled={disableEdit || !skillsSectionDecision.enabled} />
                    </Grid>
                  </Grid>
                  <Divider className={classes.divider} />
                </>
              </Grid>
              <Grid item xs={12} className={classes.wrapper}>
                <NewEMR isdisabled={disableEdit} hasRadio={true} />
              </Grid>
            </Grid>
          </UnitDetailsBody>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
};
