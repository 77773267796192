export const ff_order_ui_skillssection = 'ff_order_ui_skillssection';
export const ff_order_ui_job_match = 'ff_order_ui_job_match';
export const ff_order_ui_qualificationlist = 'ff_order_ui_qualificationlist';
export const ff_order_ui_bulk_update_dates_hsg = 'ff_order_ui_bulk_update_dates_hsg';
export const ff_order_ui_addlicensesection = 'ff_order_ui_addlicensesection';
export const ff_order_ui_internalnote_char_count = 'ff_order_ui_internalnote_char_count';
export const ff_order_ui_newpreference_rfo_to_sent = 'ff_order_ui_newpreference_rfo_to_sent';
export const ff_order_new_page_hide_skills_checklist_checkbox = 'ff_order_new_page_hide_skills_checklist_checkbox';
export const ff_order_ui_inheritfromunitbutton = 'order_ui_inheritfromunitbutton';
export const ff_order_api_cdc_inherit_unit = 'ff_order_api_cdc_inherit_unit';
