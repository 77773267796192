import { Typography } from 'amn-ui-core';
import React from 'react';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  AccordianBgColor: {
    backgroundColor: `${theme.palette.system.skyBlueWithOpacity} !important`,
  },
  TransparentBgColor: {
    backgroundColor: 'transparent',
  },
  Title: {
    color: theme.palette.system.black,
    fontSize: '16px !important',
    fontWeight: 'normal',
    marginLeft: '5px',
  },
}));

export const AccordianComponent = ({
  addIcon,
  title,
  children,
  titleChildren,
  isTransparent,
  isExpanded,
  setIsExpanded,
}: {
  title: string;
  children?;
  addIcon?;
  titleChildren?;
  isTransparent?: boolean;
  isExpanded?: boolean;
  setIsExpanded?: () => void;
}) => {
  const [expanded, setExpanded] = React.useState<string[]>(isExpanded ? ['panel'] : []);
  const { classes } = useStyles();

  const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
    ({ theme }) => ({
      border: isTransparent ? '0px' : `1px solid ${theme.palette.divider}`,
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&::before': {
        display: 'none',
      },
    }),
  );

  const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
  ))(({ theme }) => ({
    minHeight: '38px !important',
    fontSize: '16px',
    fontWeight: 'bold',
    flexDirection: 'row-reverse',
    padding: isTransparent ? '0px' : '0px 12px',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: isTransparent ? 'rotate(270deg)' : 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: isTransparent ? theme.spacing(0.5) : theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: isTransparent ? theme.spacing(0.5) : theme.spacing(2),
    borderTop: isTransparent ? '0px' : '1px solid rgba(0, 0, 0, .125)',
  }));

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(prevExpanded => (newExpanded ? [...prevExpanded, panel] : prevExpanded.filter(item => item !== panel)));
    setIsExpanded();
  };

  return (
    <Accordion expanded={expanded.includes('panel')} onChange={handleChange('panel')}>
      <AccordionSummary
        aria-controls="panel-content"
        id="panel-header"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        className={isTransparent ? classes.TransparentBgColor : classes.AccordianBgColor}
      >
        <Typography className={isTransparent ? classes.Title : ''}>{title}</Typography>
        {titleChildren}
        {addIcon}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};
