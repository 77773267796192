/* eslint-disable tss-unused-classes/unused-classes */
import { Chip, Grid } from 'amn-ui-core';
import { useTagStyles } from './TagsWrapperstyles';
import React, { useEffect, useMemo, useState } from 'react';
import { CustomTooltip } from 'app/components/Common/Tooltips';
import { ISelection } from 'app/ComponentLibrary/Filter/TypeAheadDropdown/CustomTypeAhead';
import CloseIcon from '@mui/icons-material/Close';
import { AddTagsIcon } from './AddTagsIcon';
import lookupTags from 'app/assets/jsons/TagsV2.json';
import { useDispatch, useSelector } from 'react-redux';
import { candidateDetailsSelection, selectTags } from '../../Profile/CandidateDetails.selector';
import { Authorized } from 'oidc/userHelper';
import { userRoles } from 'oidc/userRoles';
import { selectUser } from 'oidc/user.selectors';
import { candidateDetailActions } from '../../Profile/CandidateDetails.redux';
import { createLabelValue, shortenText, getWindowTagCount } from './TagUtils';

export enum TagValues {
  SUPERVISOR = 'Supervisor',
  PRECHECK = 'Pre-Check',
}

export const TagsWrapper = props => {
  const { buttonId, candidateDetails } = props;
  const anchorAccessorId = buttonId || 'tags-anchor-accessor';
  const { classes: tagClasses } = useTagStyles();
  const user = useSelector(selectUser);
  const tags = useSelector(selectTags);
  const [tagsList, setTagsList] = useState<ISelection[]>([]);
  const [options, setOptions] = useState([]);
  const [tagsOptions, setTagsOptions] = useState([]);
  const [isRecruiter, setIsRecruiter] = useState<boolean>(false);
  const candidateInfo = useSelector(candidateDetailsSelection);
  const dispatch = useDispatch();
  const [tagDisplayCount, setTagDisplayCount] = useState(0);

  useEffect(() => {
    setIsRecruiter(
      Authorized(
        [userRoles.recruitment, userRoles.recruitment_TeamMember, userRoles.recruitment_Leadership],
        user?.userInfo,
      ),
    );
  }, [user?.userInfo]);

  useEffect(() => {
    const handleWindowResize = () => {
      setTagDisplayCount(getWindowTagCount());
    };
    window.addEventListener('resize', handleWindowResize);
    setTagDisplayCount(getWindowTagCount());
    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);
  const handleDeleteTag = filteredTags => {
    const postReqBody = {
      candidateId: candidateDetails?.travelerId || candidateInfo?.travelerId,
      brandId: candidateDetails?.brandId || candidateInfo?.brandId,
      currentEmployeeId: user.userInfo.employeeId,
      currentEmployeeName: user.userInfo.firstName + user.userInfo.lastName,
      candidateTags: filteredTags,
    };

    dispatch(candidateDetailActions.postTags(postReqBody));
  };

  const setupOptions = useMemo(() => {
    var updatedOptions = lookupTags.map(e => {
      return { ...e, name: e.label, key: e.id, disabled: false };
    });
    setOptions(updatedOptions);
    setTagsOptions(updatedOptions.filter(e => !e.isHidden));
  }, [lookupTags]);

  useEffect(() => {
    if (tags) {
      // call setup actions
      setupOptions;

      const filterTags = options
        .filter(lookupTagItem => tags.find(tag => tag.value === lookupTagItem.value))
        .map(e => {
          return { ...e, name: e.label };
        });
      setTagsList(filterTags);
    }
  }, [tags, tagDisplayCount]);

  /**
   * Method to delete tag item
   * @param tagItem
   */
  const handleDelete = (tagItem: ISelection) => {
    const filteredTags = tagsList.filter(currentItem => currentItem.id !== tagItem?.id);
    handleDeleteTag(filteredTags);
    setTagsList(filteredTags);
  };

  const canDelete = (label: string) => {
    return label !== TagValues.SUPERVISOR && label !== TagValues.PRECHECK;
  };

  const Chips = useMemo(() => {
    const chips = [];
    tagsList?.slice(0, tagDisplayCount).map((item: ISelection, index: number) => {
      const label = createLabelValue(item);
      if (item?.value?.length > 10) {
        chips.push(
          <Grid item key={index} className={tagClasses.gridItemStyle}>
            <CustomTooltip
              contentStyle={{ fontWeight: 400 }}
              tooltipContent={item?.name}
              placement="bottom-start"
              isInlineContent={true}
              key={index}
            >
              <Chip
                id={`tags-candidate-header-${index}`}
                key={index}
                className={tagClasses.chip}
                label={
                  <>
                    <div id={'fullText'}>{label}</div>
                    <div id={'shortenedText'}>{shortenText(label)}</div>
                  </>
                }
                onDelete={canDelete(label) ? () => handleDelete(item) : undefined}
                deleteIcon={
                  canDelete(label) ? <CloseIcon style={{ width: '15px', height: '15px', color: 'white' }} /> : undefined
                }
              />
            </CustomTooltip>
          </Grid>,
        );
      } else {
        chips.push(
          <Grid item key={index} className={tagClasses.gridItemStyle}>
            <Chip
              id={`tags-candidate-header-${index}`}
              key={index}
              className={tagClasses.chip}
              label={
                <>
                  <div id={'fullText'}>{label}</div>
                  <div id={'shortenedText'}>{shortenText(label)}</div>
                </>
              }
              onDelete={canDelete(label) ? () => handleDelete(item) : undefined}
              deleteIcon={
                canDelete(label) ? <CloseIcon style={{ width: '15px', height: '15px', color: 'white' }} /> : undefined
              }
            />
          </Grid>,
        );
      }
    });
    if (tagsList?.length > tagDisplayCount) {
      const remainingChips = tagsList
        .slice(tagDisplayCount)
        .map((item, index) => (
          <Chip
            id={`tags-candidate-header-${index}`}
            key={index}
            className={tagClasses.chip}
            label={<div id={'fullText'}>{item.name}</div>}
            onDelete={canDelete(item.name) ? () => handleDelete(item) : undefined}
            deleteIcon={
              canDelete(item.name) ? <CloseIcon style={{ width: '15px', height: '15px', color: 'white' }} /> : undefined
            }
          />
        ));

      chips.push(
        <Grid item className={tagClasses.gridItemStyle}>
          <CustomTooltip
            tooltipContent={
              <Grid container direction="row">
                {remainingChips.map((item, index) => {
                  return (
                    <Grid item key={index} style={{ padding: '5px 0px' }}>
                      {item}
                    </Grid>
                  );
                })}
              </Grid>
            }
            interactive
            arrow
            placement="bottom-start"
          >
            <Chip variant="additionalchip" label={`+${remainingChips.length}`} />
          </CustomTooltip>
        </Grid>,
      );
      return chips;
    } else {
      return chips;
    }
  }, [tagsList]);

  return (
    <Grid container direction="row" className={tagClasses.container}>
      {Chips}
      <Grid item className={`${tagClasses.gridItemStyle} ${tagClasses.addIconStyle}`}>
        <AddTagsIcon
          anchorAccessorId={anchorAccessorId}
          tagsOptions={tagsOptions}
          tagsList={tagsList}
          setTagsList={setTagsList}
          isRecruiter={isRecruiter}
          candidateDetails={candidateDetails}
        />
      </Grid>
    </Grid>
  );
};
