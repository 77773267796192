import {
  ITaskAttachmentList,
  IInternalTaskModalDetails,
  TaskStatus,
  StatusUpdateModalVersion,
  TaskStatusReason,
} from 'app/models/Tasks/Tasks';
import { Chip, Grid, IconButton, Link, Typography } from 'amn-ui-core';
import { selectUser } from 'oidc/user.selectors';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _isArray from 'lodash/isArray';
import moment from 'moment-timezone';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { getAttachmentIcon } from 'app/components/Notification/Common/AttachmentFileTypes';
import { getFileName } from '../CreateTask/FormatData';
import { TaskComments } from './TaskComments';
import Comments from './Comments/Comments';
import {
  formatCoveredByUsers,
  generateReplyRecipients,
  getTaskAssigneeCoveredEmployee,
  getCreatedByCoveredEmployee,
  getRecipientsForAvatars,
  getStatusUpdateReason,
  getFormattedTaskComments,
} from './helper';
import {
  FetchStatusReason,
  checkCreatedByUserStatusEligibility,
  checkIfActiveUserRecipient,
  checkOtherUserStatus,
  fetchCoverageRecipientUserDetails,
  isActiveUserCoveringCreatedBy,
  isActiveUserCoveringRecipients,
  isAssigneeStatusCompletedCancelled,
  isRecipientHsg,
  isUserOnlyRecipientNotCovering,
} from './newTaskStatusHelper';
import ReplyIcon from '@mui/icons-material/Reply';
import { CustomTooltip } from '@AMIEWEB/Common';
import { SubTaskOnContext } from './TaskDetails';
import { RecipientStatusIcon } from './StatusIcon';
import { TaskRecipientAvatars } from '../TaskManagement/RecipientAvatars';
import { missingField } from 'app/constants';
import { selectActiveCoverage } from 'store/redux-store/user-coverage/selectors';
import { useTheme } from 'amn-ui-core';
import { TaskDetailsDateRenderer } from './TaskDueDateRenderer';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { taskDetailsActions } from '../store/Tasks.redux';
import { TaskPriorityIcon } from './TaskPriorityIcon';
import { getEmployeeId } from '@AMIEWEB/Notification/Tasks/Common_v2/utils';

export const TaskDetailsInternalRightPanel = ({
  classes,
  taskDetails,
  isExternal = false,
  setEditExternalOpen,
  setCreateModalOpen,
  attachments,
  setDeleteModalOpen,
  setAttachmentIndex,
  setSubtaskReplyRecipients,
  setSubtaskModalOpen,
  setComment,
  statusChanged,
  setIsPopperOpen,
  setSubTaskName,
  setEnableTaskName,
  setTask,
}: {
  classes: any;
  taskDetails: IInternalTaskModalDetails;
  isExternal?: boolean;
  setEditExternalOpen: (e) => void;
  setCreateModalOpen: (e) => void;
  attachments: ITaskAttachmentList[];
  setDeleteModalOpen: (e) => void;
  setAttachmentIndex: (e) => void;
  setSubtaskReplyRecipients: (e) => void;
  setSubtaskModalOpen: (e) => void;
  setComment: (e) => void;
  statusChanged?: boolean;
  setIsPopperOpen: (e) => void;
  setSubTaskName?: (e) => void;
  setEnableTaskName?: (e) => void;
  setTask?: (e) => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(true);
  const [isEligableToUpdate, setEligableToUpdate] = useState(false);
  const [userStatusId, setUserStatusId] = useState<number>(0);
  const [showMore, setShowMore] = useState<boolean>(false);
  const [statusDetails, setStatusDetails] = useState<any>();
  const [coverageUser, setCoverageUser] = useState<any>(null);
  const [textHeight, setTextHeight] = useState<any>('40px');
  const userInfo = useSelector(selectUser);
  const user = userInfo?.userInfo;
  const { expanded } = useContext(SubTaskOnContext);
  const activeCoverage = useSelector(selectActiveCoverage);
  const hsgId = 6000;
  const theme = useTheme();

  const deleteAttachmentUser = task => {
    const isValidUser =
      task?.createdBy?.id === parseInt(getEmployeeId(userInfo?.userInfo)) ||
      (!_isArray(task?.assignedTo) && parseInt(getEmployeeId(userInfo?.userInfo)) === task?.assignedTo?.id) ||
      (_isArray(task?.assignedTo) && (task?.assignedTo || []).find(assigned => assigned.id === parseInt(getEmployeeId(userInfo?.userInfo))));
    return isValidUser;
  };

  const handleReplyClick = () => {
    const { createdBy, assignedTo, cc } = taskDetails;
    const recipients = generateReplyRecipients(
      createdBy,
      assignedTo,
      cc,
      user,
      taskDetails?.taskCoverageActions?.length > 0 ? formatCoveredByUsers(taskDetails.taskCoverageActions) : null,
    );
    setSubtaskReplyRecipients(recipients);
    setSubtaskModalOpen(true);
    dispatch(taskDetailsActions.setTaskNameFromSubtask(false));
  };

  const updateStatusId = (activeUserId, reversible) => {
    let statusId: number = 2;
    taskDetails?.assignedTo?.forEach(assigned => {
      if (assigned?.id === activeUserId) {
        statusId = assigned?.statusId;
      }
    });
    taskDetails?.cc?.forEach(ccUser => {
      if (ccUser?.id === activeUserId) {
        statusId = ccUser?.statusId;
      }
    });
    if (taskDetails?.createdBy.id === activeUserId) {
      statusId = 2;
    }
    if (statusId > 0) {
      setEligableToUpdate(true);
      setDisabled(false);
    } else {
      const isCreatedInError = taskDetails?.taskStatusDetails?.some(
        data => data?.statusUpdateReasonId === TaskStatusReason.ERROR,
      );
      if (reversible && !isCreatedInError) {
        setEligableToUpdate(true);
        setDisabled(false);
      } else {
        setEligableToUpdate(false);
        setDisabled(true);
      }
    }
    if (activeUserId === hsgId && activeCoverage?.length > 0) {
      setEligableToUpdate(false);
      setDisabled(true);
    }
    setUserStatusId(activeUserId === hsgId && statusId === TaskStatus.New ? TaskStatus.Pending : statusId);
  };

  const defaultStatusUpdation = statusData => {
    const isCreatedByError = taskDetails?.taskStatusDetails?.find(
      data => data?.statusUpdateReasonId === TaskStatusReason.ERROR,
    );
    setUserStatusId(isCreatedByError ? TaskStatus.Cancelled : TaskStatus.Pending);
    setStatusDetails(
      isCreatedByError
        ? {
            statusId: TaskStatus.Cancelled,
            reason: `${isCreatedByError?.statusUpdateReason} - ${taskDetails?.taskCategory}`,
          }
        : statusData,
    );
  };

  const checkCreatorStatusUpdate = statusData => {
    const checkCreatorStatus = checkCreatedByUserStatusEligibility(taskDetails, activeCoverage, parseInt(getEmployeeId(userInfo?.userInfo)));
    if (checkCreatorStatus) {
      setStatusDetails(checkCreatorStatus);
      setUserStatusId(checkCreatorStatus?.statusId);
    } else {
      defaultStatusUpdation(statusData);
    }
    if (checkCreatorStatus && checkCreatorStatus?.statusId < 3) {
      setEligableToUpdate(true);
      setDisabled(false);
    } else {
      setEligableToUpdate(false);
      setDisabled(true);
    }
    if (isActiveUserCoveringCreatedBy(activeCoverage, taskDetails?.createdBy?.id)) {
      setCoverageUser({
        firstName: user?.firstName,
        id: user?.employeeId,
        lastName: user?.lastName,
        statusId: checkCreatorStatus && checkCreatorStatus?.statusId > 3 ? checkCreatorStatus?.statusId : 2,
      });
    }
  };

  useEffect(() => {
    const statusData = {
      statusId: TaskStatus.Pending,
      reason: taskDetails?.taskCategory,
    };
    if (isUserOnlyRecipientNotCovering(taskDetails, activeCoverage, parseInt(getEmployeeId(userInfo?.userInfo)))) {
      const currentTaskStatus = getStatusUpdateReason(taskDetails, StatusUpdateModalVersion.PARENT_TASK, user);
      setStatusDetails(currentTaskStatus);
      const isActiveUserRecipient = checkIfActiveUserRecipient(taskDetails, parseInt(getEmployeeId(userInfo?.userInfo)));
      if (isActiveUserRecipient) {
        updateStatusId(parseInt(getEmployeeId(userInfo?.userInfo)), true);
      } else {
        setUserStatusId(TaskStatus.Pending);
        setEligableToUpdate(false);
        setDisabled(true);
      }
    } else if (
      activeCoverage?.length > 0 &&
      isActiveUserCoveringRecipients(taskDetails, activeCoverage, user?.employeeId)
    ) {
      const coveredUser = fetchCoverageRecipientUserDetails(taskDetails, activeCoverage, user?.employeeId);
      const coveredUserStatus = FetchStatusReason(taskDetails, activeCoverage, user?.employeeId);
      if (coveredUser) {
        setCoverageUser(coveredUser);
        setStatusDetails(coveredUserStatus);
        updateStatusId(coveredUser?.id, true);
      } else {
        setUserStatusId(TaskStatus.Pending);
        setStatusDetails(statusData);
      }
    } else if (
      taskDetails?.createdBy?.id === parseInt(getEmployeeId(userInfo?.userInfo)) ||
      isActiveUserCoveringCreatedBy(activeCoverage, taskDetails?.createdBy?.id)
    ) {
      checkCreatorStatusUpdate(statusData);
    } else if (isRecipientHsg(taskDetails, hsgId)) {
      updateStatusId(hsgId, true);
      const hsgTaskStatus = getStatusUpdateReason(taskDetails, StatusUpdateModalVersion.PARENT_TASK, {
        employeeId: hsgId,
      });
      if (hsgTaskStatus?.statusId === TaskStatus.New) {
        const hsgStatus = { ...hsgTaskStatus, statusId: TaskStatus.Pending };
        setStatusDetails(hsgStatus);
      } else {
        setStatusDetails(hsgTaskStatus);
      }
    } else {
      const otherUserStatusValue = checkOtherUserStatus(taskDetails);
      setStatusDetails(otherUserStatusValue);
      setUserStatusId(otherUserStatusValue?.statusId);
      setEligableToUpdate(false);
      setDisabled(true);
    }

    if (isAssigneeStatusCompletedCancelled(taskDetails, parseInt(getEmployeeId(userInfo?.userInfo)))) {
      setDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskDetails]);

  return (
    <Grid data-testid="task-details-right-content" style={{ width: 'inherit', border: '1px solid #D9D9D9' }}>
      <div className={classes.gridRightContainer}>
        <div data-testid="task-details-status-container" className={classes.detailsRightColumn}>
          <div style={{ padding: '24px 24px 24px 12px' }}>
            <div className={classes.flexContainer}>
              <RecipientStatusIcon
                classes={classes}
                details={taskDetails}
                isExternal={isExternal}
                isEligableToUpdate={isEligableToUpdate}
                isSubtask={false}
                statusDetails={statusDetails}
                coverageUser={coverageUser}
              />

              <div style={{ marginLeft: '9px', marginRight: 'auto', display: 'inline-grid' }}>
                <div
                  data-testid="task-details-title"
                  className={classes.taskTitle}
                  style={
                    statusDetails?.statusId === TaskStatus.Completed || statusDetails?.statusId === TaskStatus.Cancelled
                      ? {
                          textDecorationLine: 'line-through',
                          color: theme.palette.system.neutralGray,
                          opacity: 0.5,
                        }
                      : statusDetails?.statusId === TaskStatus.New
                      ? {
                          color: theme.palette.system.primary,
                        }
                      : { color: theme.palette.framework.system.charcoal }
                  }
                >
                  {taskDetails?.task}
                </div>
              </div>
              <div className={classes.headerRight}>
                <div className={classes.replyBtnContainer}>
                  <TaskPriorityIcon taskPriorityId={taskDetails.taskPriorityId} />
                </div>
                <div className={classes.replyBtnContainer}>
                  <CustomTooltip standardMargin arrow tooltipContent={t('notification.taskDetails.replyBtnText')}>
                    <IconButton
                      color="secondary"
                      size="medium"
                      disableRipple
                      disabled={false}
                      onClick={handleReplyClick}
                    >
                      <ReplyIcon className={classes.replyBtnIcon} />
                    </IconButton>
                  </CustomTooltip>
                </div>
                <div className={classes.replyBtnContainer}>
                  <CustomTooltip standardMargin arrow tooltipContent={t('search.grid.editMenu.edit')}>
                    <IconButton
                      color="secondary"
                      size="medium"
                      disabled={disabled}
                      disableRipple
                      onClick={() => (isExternal ? setEditExternalOpen(true) : setCreateModalOpen(true))}
                    >
                      <EditOutlinedIcon className={classes.replyBtnIcon} />
                    </IconButton>
                  </CustomTooltip>
                </div>
                <div style={{ paddingLeft: '12px' }}>
                  <TaskDetailsDateRenderer
                    details={taskDetails}
                    isSubtask={false}
                    userStatusId={userStatusId}
                    setIsPopperOpen={setIsPopperOpen}
                    coverageUser={coverageUser}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                fontSize: '14px',
                color:
                  statusDetails?.statusId === TaskStatus.New
                    ? theme.palette.system.primary
                    : theme.palette.system.neutralGray,
                lineHeight: '20px',
                marginLeft: '36px',
              }}
              data-testid="task-details-task-category"
            >
              {!isExternal && statusDetails?.reason}
            </div>

            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Typography style={{ fontSize: '14px !important' }} className={classes.descriptionTitle}>
                  {t('notification.taskDetails.description')}
                </Typography>
              </div>
              <div
                style={
                  statusDetails?.statusId === TaskStatus.Cancelled
                    ? {
                        opacity: 0.5,
                        fontSize: '14px',
                        lineHeight: '20px',
                        fontFamily: 'Roboto',
                        marginBottom: '6px',
                        marginLeft: '36px',
                        whiteSpace: 'pre-line',
                      }
                    : {
                        fontSize: '14px',
                        lineHeight: '20px',
                        fontFamily: 'Roboto',
                        marginBottom: '6px',
                        marginLeft: '36px',
                        whiteSpace: 'pre-line',
                      }
                }
                data-testid="task-details-description"
              >
                {taskDetails?.description ? (
                  <>
                    <Typography
                      id="typography"
                      variant="body1"
                      style={{
                        lineHeight: '20px',
                        fontSize: '14px',
                        height: taskDetails?.description?.length < 230 ? 'auto' : textHeight,
                        overflow: 'hidden',
                      }}
                    >
                      {taskDetails?.description}
                    </Typography>
                    <Link
                      data-testid="task-details-show-btn"
                      component="button"
                      onClick={e => {
                        e.preventDefault();
                        setShowMore(s => !s);
                        if (!showMore) {
                          setTextHeight('auto');
                        } else {
                          setTextHeight('40px');
                        }
                      }}
                      hidden={taskDetails?.description?.length <= 230}
                    >
                      {showMore ? t('notification.subTask.viewLess') : t('notification.subTask.viewMore')}
                    </Link>
                  </>
                ) : (
                  missingField
                )}
              </div>
            </>

            {taskDetails?.attachments && (
              <div style={{ marginBottom: '9px', marginLeft: '33px' }}>
                {taskDetails?.attachments &&
                  attachments?.map((attachment: ITaskAttachmentList, index) => (
                    <Chip
                      classes={{ icon: classes.fileIcon, label: classes.chipLabel }}
                      key={index}
                      className={classes.attachmentChip}
                      icon={
                        <div>
                          <img
                            src={getAttachmentIcon(
                              attachment?.attachmentType && attachment?.attachmentType?.toLowerCase(),
                            )}
                            alt={t('FileIcon')}
                          />
                        </div>
                      }
                      label={getFileName(attachment.saasAttachmentUrl)}
                      onClick={() => {
                        window.open(attachment?.saasAttachmentUrl, '_blank');
                      }}
                      deleteIcon={deleteAttachmentUser(taskDetails) && <CloseIcon />}
                      onDelete={
                        deleteAttachmentUser(taskDetails)
                          ? () => {
                              setDeleteModalOpen(true);
                              setAttachmentIndex(index);
                            }
                          : undefined
                      }
                    />
                  ))}
              </div>
            )}
            <div
              style={{
                display: 'flex',
                width: '100%',
                flexWrap: 'nowrap',
                margin: '6px auto 0px 36px',
                overflow: expanded ? 'auto hidden' : null,
                maxWidth: expanded ? '70%' : null,
              }}
            >
              {TaskRecipientAvatars(
                null,
                true,
                getRecipientsForAvatars(taskDetails),
                taskDetails,
                5,
                taskDetails?.createdBy,
                getCreatedByCoveredEmployee(taskDetails),
                getTaskAssigneeCoveredEmployee(taskDetails),
                moment(taskDetails?.createdDate).format('L hh:mm A'),
              )}
            </div>
            <Comments taskComments={getFormattedTaskComments(taskDetails?.comments)} />
          </div>
          <TaskComments
            rootTaskId={taskDetails?.rootTaskId}
            classes={classes}
            setReplyRecipients={setSubtaskReplyRecipients}
            setSubtaskModalOpen={setSubtaskModalOpen}
            setComment={setComment}
            statusChanged={statusChanged}
            setSubTaskName={setSubTaskName}
            setEnableTaskName={setEnableTaskName}
            setTask={setTask}
          />
        </div>
      </div>
    </Grid>
  );
};
