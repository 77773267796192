import React, { useEffect, useState } from 'react';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import { useTranslation } from 'react-i18next';
import { UpdatePreferences } from './UpdatePreferences';
import { makeStyles } from 'tss-react/mui';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { gridSelectionActions } from '@AMIEWEB/Common/Grid/GridSelection/GridSelection.redux';

const useStyles = makeStyles()({
  title: {
    fontSize: 20,
  },
});

interface IUpdatePreferenceModal {
  open: boolean;
  isSubmittingAction: boolean;
  tableTitle: string;
  tableData: any[];
  columns: any[];
  onResetColumns: () => void;
  onGridRefresh: () => void;
  onCancel: () => void;
  onConfirm: () => void;
  dialogTitleText: string;
  isExistingItems: boolean;
  selectedData: number[];
}

export const UpdatePreferencesModal = ({
  open,
  onCancel,
  onConfirm,
  isSubmittingAction,
  tableTitle,
  tableData,
  columns,
  onResetColumns,
  onGridRefresh,
  dialogTitleText,
  isExistingItems,
  selectedData,
}: IUpdatePreferenceModal) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const handleExpandChange = (expanded: boolean) => setIsExpanded(expanded);

  useEffect(() => {
    return () => {
      dispatch(
        gridSelectionActions.setGridSelections({
          selectedData: [],
        }),
      );
    };
  }, []);

  return (
    <GenericDialog
      open={open}
      draggable
      disablePortal
      disableEscapeKeyDown
      dialogTitleProps={{
        text: dialogTitleText,
        expandable: isExistingItems,
        classes: { root: classes.title },
        closeButton: true,
        undoButton: true,
        ellipseText: true,
        onExpandChange: handleExpandChange,
      }}
      dialogActions={[
        {
          text: t('Cancel'),
          variant: 'contained',
          color: 'tertiary',
          onClick: onCancel,
        },
        {
          text: t('Save'),
          variant: 'contained',
          disabled: isSubmittingAction || isEmpty(selectedData),
          onClick: onConfirm,
        },
      ]}
      fullWidth={isExistingItems}
      maxWidth={'md'}
      variant={'blue'}
      onClose={onCancel}
    >
      <UpdatePreferences
        tableTitle={tableTitle}
        columns={columns}
        tableData={tableData}
        onResetColumns={onResetColumns}
        onGridRefresh={onGridRefresh}
        isExpanded={isExpanded}
        selectedGridData={selectedData}
      />
    </GenericDialog>
  );
};
