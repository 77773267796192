import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { missingField } from 'app/constants';
import {
  DirectHireDetails,
  IPlacementSubmissionPacketDetail,
  IPlacementUpdate,
  PlacementOrderType,
} from 'app/models/Placement/PlacementDetails';
import {
  ISubmissionAmnRequirementKey,
  IPlacementSummary,
  IServiceFailureKey,
  ISubmissionOrderRequirementKey,
  ITransportation,
  IPickListsKey,
  IBookingCredentialing,
  IOfferConfirmation,
  IBookingConfirmation,
  IUpdateStrikePlacementProps,
  IRequirementCounts,
  IAuditDetailsInfo,
  IRequirementTreeFilter,
  IPlacementEditableRequirements,
  IPeopleSoft,
  ITravelDetails,
  IStrikeTravelDetails,
  IExtensionInfo,
  ICandidateVirtualInterviewData,
  IOrderVirtualInterviewData,
  requirementType,
  IGroupedSkillsetRequirement,
} from 'app/models/Placement/PlacementSummary';
import _ from 'lodash';
import { serviceActions } from './async-actions';
import { extractTransportationOpts, extractTypeAheadPicklistOpts } from './sagaHelper';
import { IVICardView } from '@AMIEWEB/Placement/PlacementDetails/PlacementTabPanel/PlacementSummaryTab/Statuses/InterviewStatus/VirtualInterview/InterviewSpecs';
import { deriveInitFilter } from '@AMIEWEB/Placement/PlacementDetails/PlacementTabPanel/PlacementSummaryTab/GroupedRequirements';

const reqCounts = { aggregate: 0, passed: 0, failed: 0, risks: 0 };

const bookingSelections: IRequirementTreeFilter = {
  key: 'booking',
  filter: [],
  children: [
    { key: 'booking-confirmation', filter: [] },
    { key: 'booking-credentialing', filter: [] },
  ],
};

const offerSelections: IRequirementTreeFilter = {
  key: 'offer',
  filter: [],
  children: [
    { key: 'offer-confirmation', filter: [] },
    { key: 'offer-transportation', filter: [] },
  ],
};

const submissionSelections: IRequirementTreeFilter = {
  key: 'submission',
  filter: [],
  children: [
    { key: 'submission-amn', filter: [] },
    { key: 'submission-order', filter: [] },
  ],
};

const interViewSelections: IRequirementTreeFilter = {
  key: 'interview',
  filter: [],
  children: [{ key: 'interview-virtual', filter: [] }],
};

const nullString = null as unknown as string;

const initialTravelDetails: ITravelDetails = {
  statusOfTravel: nullString,
  airline: nullString,
  flightNumber: nullString,
  departureAirport: nullString,
  arrivalDate: nullString,
  arrivalTime: nullString,
  arrivalAirport: nullString,
  itineraryLink: nullString,
};

export const initialState: IPlacementSummary = {
  orderInformation: {
    startDate: null,
    contractBillRate: null,
    contractGWW: null,
    endDate: null,
    expectedHours: null,
    flexibleSchedule: null,
    float: null,
    internalNotes: null,
    numOfWeeks: null,
    orderBillRate: null,
    orderGWW: null,
    orderPriorities: null,
    orderShifts: null,
    orderType: null,
    preferredQualifications: null,
    requiredQualifications: null,
    vmsBillRate: null,
    weeklyGrossPay: null,
    division: null,
    numberOfWeeks: null,
    positionsAvailable: null,
  },
  submissionInfo: {
    requirementFilter: submissionSelections,
    amnReq: {
      reqCounts,
      amn: {
        skillset: { type: -1, data: { verifiedByCmType: -1, strikeMatchType: -1 } },
        status: null,
        audit: null,
        education: null,
        experience: null,
        workHistory: null,
        references: missingField,
        skillsCheckList: null,
        application: null,
      },
      candidate: {
        skillset: null,
        status: null,
        audit: null,
        education: null,
        experience: null,
        workHistory: null,
        references: null,
        skillsCheckList: null,
        application: null,
        distance: null,
      },
    },
    orderReq: {
      reqCounts,
      order: {
        skillset: null,
        status: null,
        facilityStatus: null,
        dates: null,
        schedule: null,
        experience: null,
        workHistory: null,
        references: null,
        skillsCheckList: null,
        licenses: null,
        certifications: null,
        milesRadius: null,
        miceDetails: null,
        interview: null,
        covid: null,
        dob: null,
        ssn: null,
        documents: null,
        orderDob: null,
        orderSsn: null,
        virtualInterview: null,
      },
      candidate: {
        skillset: null,
        status: null,
        dates: null,
        schedule: null,
        experience: null,
        workHistory: null,
        references: null,
        skillsCheckList: null,
        licenses: null,
        certifications: null,
        milesRadius: null,
        miceDetails: null,
        interview: null,
        covid: null,
        dob: null,
        ssn: null,
        documents: null,
      },
    },
    skillReq: {
      containers: null,
      containerRegistry: {},
      reqCounts,
    },
  },
  interviewInfo: {
    requirementFilter: interViewSelections,
    interview: {
      reqCounts,
    },
    virtualInterview: {
      reqCounts,
      order: {
        type: -1,
        data: null,
        view: {
          rating: missingField,
          vaAutoOffer: missingField,
          skillset: missingField,
          matchingSkillset: missingField,
        },
      },
      candidate: [],
    },
    interviewScheduled: {
      reqCounts,
    },
  },
  offerInfo: {
    requirementFilter: offerSelections,
    confirmation: {
      reqCounts,
      datesConfirmed: null,
      dnsCleared: null,
      travelConfirmed: {
        type: -1,
        data: {
          checked: false,
          lastUpdatedAt: null,
          lastUpdatedBy: null,
          lastUpdatedById: null,
        },
      },
    },
    transportation: {
      reqCounts,
      gender: { type: -1, data: null },
      transportation: {
        type: -1,
        data: {
          transportation: null,
          transportationId: null,
        },
      },
      travelTimePreference: {
        type: -1,
        data: {
          travelTimePreference: null,
          travelTimePreferenceId: null,
        },
      },
      dob: { type: -1 },
      ssn: { type: -1 },
      departureAirport: { type: -1, data: null },
      departureItineraryLink: { type: -1, data: null },
      arrivalDate: { type: -1, data: null },
      arrivalTime: { type: -1, data: null },
      picklists: {
        airportCodes: [],
        transportationOpts: [],
        travelTimePreferenceOpts: [],
      },
    },
    strikeTravelDetails: {
      travelFromStrike: { ...initialTravelDetails, departureDate: nullString, departureTime: nullString },
      travelToStrike: { ...initialTravelDetails, arrivalDate: nullString, arrivalTime: nullString },
    },
  },
  bookingInfo: {
    requirementFilter: bookingSelections,
    credentialing: {
      reqCounts,
      gtgComplete: null,
      finalizedByQs: null,
    },
    confirmation: {
      reqCounts,
      arrived: null,
      directDeposit: null,
      wisely: null,
    },
    /** Placement holder for hidden requirements */
  },
  assignmentInformation: {
    placementId: null,
    placementStatusId: null,
    placementStatus: null,
    startDate: null,
    endDate: null,
    endDateTravelerOptions: null,
    directHireTypeId: ''
  },
  extensionsInfo: [],
  serviceFailures: {},
  auditDetailsInfo: [],
  referenceAndEmploymentVerificationRecords: [],
  editableRequirementTypes: {
    availabilityDate: { requirement: -1, requiredDependency: false },
    placementDate: { requirement: -1, requiredDependency: false },
    ssn: { requirement: -1, requiredDependency: false },
    dob: { requirement: -1, requiredDependency: false },
    datesConfirmed: { requirement: -1, requiredDependency: false },
    dnsCleared: { requirement: -1, requiredDependency: false },
    travelConfirmed: { requirement: -1, requiredDependency: false },
    transportation: { requirement: -1, requiredDependency: false },
    travelTimePreference: { requirement: -1, requiredDependency: false },
    gender: { requirement: -1, requiredDependency: false },
    departureAirport: { requirement: -1, requiredDependency: false },
    arrivalDate: { requirement: -1, requiredDependency: false },
    arrivalTime: { requirement: -1, requiredDependency: false },
    orderDob: { requirement: -1, requiredDependency: false },
    orderSsn: { requirement: -1, requiredDependency: false },
    virtualInterview: { requirement: -1, requiredDependency: false },
    extensions: [],
  },
  peopleSoft: {
    lastSync: missingField,
    syncStatus: -1,
    syncStatusLabel: missingField,
    description: missingField,
    awaitingResultsCount: 0,
  },
  classicPacketInOkToSendExists: false,
  submissionPacketDetail: undefined,
};

export interface IPlacementSummaryRequest {
  placementId: number;
  placementOrderType?: PlacementOrderType | null;
  isRetryingService?: boolean;
  requirementCheckFlagEnabled?: boolean;
  isOrderGeneralRequirement?: boolean;
  arbitrationAgreementFlagEnabled?: boolean;
  isInitLoad?: boolean | false ;
}

export interface IAuditRequest extends Omit<IPlacementSummaryRequest, 'placementId'> {
  candidateId: number;
}

const placementSummarySlice = createSlice({
  name: 'placementSummary',
  initialState,
  reducers: {
    setSummaryDetails(state, action) {
      // TODO: replace with return {...action.payload } once all data are configured
      return { ...state, ...action.payload };
    },
    setRequirementCounts(
      state,
      action: PayloadAction<{
        submissionAMNReqCounts?: IRequirementCounts;
        submissionOrderReqCounts?: IRequirementCounts;
        offerConfirmationReqCounts?: IRequirementCounts;
        offerTransportationReqCounts?: IRequirementCounts;
        bookingConfirmationReqCounts?: IRequirementCounts;
        bookingCredentialingReqCounts?: IRequirementCounts;
        extensionTimeOffReqCounts?: IRequirementCounts;
        virtualInterviewReqCounts?: IRequirementCounts;
      }>,
    ) {
      return {
        ...state,
        submissionInfo: {
          ...state.submissionInfo,
          amnReq: {
            ...state.submissionInfo.amnReq,
            reqCounts: {
              ...state.submissionInfo.amnReq.reqCounts,
              ...(action.payload.submissionAMNReqCounts || {}),
            },
          },
          orderReq: {
            ...state.submissionInfo.orderReq,
            reqCounts: {
              ...state.submissionInfo.orderReq.reqCounts,
              ...(action.payload.submissionOrderReqCounts || {}),
            },
          },
        },
        interviewInfo: {
          ...state.interviewInfo,
          virtualInterview: {
            ...state.interviewInfo.virtualInterview,
            reqCounts: {
              ...state.interviewInfo.virtualInterview.reqCounts,
              ...(action.payload.virtualInterviewReqCounts || {}),
            },
          },
        },
        offerInfo: {
          ...state.offerInfo,
          confirmation: {
            ...state.offerInfo.confirmation,
            reqCounts: {
              ...state.offerInfo.confirmation.reqCounts,
              ...(action.payload.offerConfirmationReqCounts || {}),
            },
          },
          transportation: {
            ...state.offerInfo.transportation,
            reqCounts: {
              ...state.offerInfo.transportation.reqCounts,
              ...(action.payload.offerTransportationReqCounts || {}),
            },
          },
        },
        bookingInfo: {
          ...state.bookingInfo,
          confirmation: {
            ...state.bookingInfo.confirmation,
            reqCounts: {
              ...state.bookingInfo.confirmation.reqCounts,
              ...(action.payload.bookingConfirmationReqCounts || {}),
            },
          },
          credentialing: {
            ...state.bookingInfo.credentialing,
            reqCounts: {
              ...state.bookingInfo.credentialing.reqCounts,
              ...(action.payload.bookingCredentialingReqCounts || {}),
            },
          },
        },
      };
    },
    setOrderDivision(state, action: PayloadAction<string | null>) {
      return {
        ...state,
        orderInformation: {
          ...state.orderInformation,
          division: action.payload,
        },
      };
    },
    setSubmissionInfoByKey(
      state,
      action: PayloadAction<{
        key: ISubmissionAmnRequirementKey | ISubmissionOrderRequirementKey;
        amnRequirement: any;
        orderRequirement: any;
        candidateRequirement: any;
        /**
         * Specify for OrderRequirement service call as {isOrderReq: true}
         * use of orderRequirement directly for null checks is
         * not suitable as service can fail or due to similar exceptions
         */
        isOrderReq?: boolean;
      }>,
    ) {
      const { amnRequirement, orderRequirement, candidateRequirement, isOrderReq = false, key } = action.payload;
      return {
        ...state,
        submissionInfo: isOrderReq
          ? {
              ...state.submissionInfo,
              orderReq: {
                ...state.submissionInfo.orderReq,
                order: { ...state.submissionInfo.orderReq.order, [key]: orderRequirement },
                candidate: { ...state.submissionInfo.orderReq.candidate, [key]: candidateRequirement },
              },
            }
          : {
              ...state.submissionInfo,
              amnReq: {
                ...state.submissionInfo.amnReq,
                amn: { ...state.submissionInfo.amnReq.amn, [key]: amnRequirement },
                candidate: { ...state.submissionInfo.amnReq.candidate, [key]: candidateRequirement },
              },
            },
      };
    },
    setSubmissionSkillsetRequirements(state, action: PayloadAction<IGroupedSkillsetRequirement>) {
      const requirementFilter = deriveInitFilter(state.submissionInfo.requirementFilter, action.payload.containers);
      return {
        ...state,
        submissionInfo: {
          ...state.submissionInfo,
          skillReq: { ...action.payload, reqCounts: action.payload.containers.counts },
          requirementFilter,
        },
      };
    },
    setVirtualInterviewRequirement(
      state,
      action: PayloadAction<{
        type: requirementType;
        order: {
          data: IOrderVirtualInterviewData;
          view: {
            rating: string;
            vaAutoOffer: string;
            skillset: string;
            matchingSkillset: string;
          };
        };
        candidate: (ICandidateVirtualInterviewData & { cardView: IVICardView })[];
      }>,
    ) {
      return {
        ...state,
        interviewInfo: {
          ...state.interviewInfo,
          virtualInterview: {
            ...state.interviewInfo.virtualInterview,
            candidate: action.payload.candidate,
            order: {
              ...action.payload.order,
              type: action.payload.type,
            },
          },
        },
      };
    },
    setOfferConfirmationInfo(state, action: PayloadAction<IOfferConfirmation>) {
      return {
        ...state,
        offerInfo: {
          ...state.offerInfo,
          confirmation: {
            ...state.offerInfo.confirmation,
            ...action.payload,
          },
        },
      };
    },
    setOfferTransportationInfo(state, action: PayloadAction<ITransportation>) {
      return {
        ...state,
        offerInfo: { ...state.offerInfo, transportation: { ...state.offerInfo.transportation, ...action.payload } },
      };
    },
    setOfferTravelDetailsInfo(state, action: PayloadAction<IStrikeTravelDetails>) {
      return {
        ...state,
        offerInfo: {
          ...state.offerInfo,
          strikeTravelDetails: { ...state.offerInfo.strikeTravelDetails, ...action.payload },
        },
      };
    },
    setOfferTransportationPickListByKey(
      state,
      action: PayloadAction<{ key: IPickListsKey; data: { key: any; value: any }[] }>,
    ) {
      const opts =
        action.payload.key === 'transportationOpts'
          ? extractTransportationOpts(action.payload.data || [])
          : extractTypeAheadPicklistOpts(action.payload.data || [], item =>
              action.payload.key === 'airportCodes'
                ? item.value
                : action.payload.key === 'travelTimePreferenceOpts'
                ? { travelTimePreferenceId: parseInt(item.key), travelTimePreference: item.value }
                : item,
            );

      return {
        ...state,
        offerInfo: {
          ...state.offerInfo,
          transportation: {
            ...state.offerInfo.transportation,
            picklists: {
              ...state.offerInfo.transportation.picklists,
              [action.payload.key]: [...opts],
            },
          },
        },
      };
    },
    setBookingConfirmationInfo(state, action: PayloadAction<IBookingConfirmation>) {
      return {
        ...state,
        bookingInfo: {
          ...state.bookingInfo,
          confirmation: {
            ...state.bookingInfo.confirmation,
            ...action.payload,
          },
        },
      };
    },
    setBookingCredentialingInfo(state, action: PayloadAction<IBookingCredentialing>) {
      return {
        ...state,
        bookingInfo: {
          ...state.bookingInfo,
          credentialing: {
            ...state.bookingInfo.credentialing,
            ...action.payload,
          },
        },
      };
    },
    setExtensionsInfo(state, action: PayloadAction<IExtensionInfo[]>) {
      return {
        ...state,
        extensionsInfo: [...action.payload],
      };
    },
    setStrikeUpdatedData(state, action: PayloadAction<IUpdateStrikePlacementProps>) {
      return {
        ...state,
        offerInfo: {
          ...state.offerInfo,
          transportation: {
            ...state.offerInfo.transportation,
            gender: {
              ...state.offerInfo.transportation.gender,
              data: action.payload.gender ?? null,
            },
            transportation: {
              ...state.offerInfo.transportation.transportation,
              data: {
                transportation:
                  action.payload.transportation ?? state.offerInfo.transportation.transportation.data.transportation,
                transportationId:
                  action.payload.transportationId ??
                  state.offerInfo.transportation.transportation.data.transportationId,
              },
            },
            travelTimePreference: {
              ...state.offerInfo.transportation.travelTimePreference,
              data: {
                travelTimePreference:
                  action.payload.travelTimePreference ??
                  state.offerInfo.transportation.travelTimePreference.data.travelTimePreference,
                travelTimePreferenceId:
                  action.payload.travelTimePreferenceId ??
                  state.offerInfo.transportation.travelTimePreference.data.travelTimePreferenceId,
              },
            },
            departureAirport: {
              ...state.offerInfo.transportation.departureAirport,
              data: action.payload.departureAirport ?? state.offerInfo.transportation.departureAirport.data,
            },
            arrivalDate: {
              ...state.offerInfo.transportation.arrivalDate,
              data: action.payload.arrivalDate ?? state.offerInfo.transportation.arrivalDate.data,
            },
            arrivalTime: {
              ...state.offerInfo.transportation.arrivalTime,
              data: action.payload.arrivalTime ?? state.offerInfo.transportation.arrivalTime.data,
            },
          },
          confirmation: {
            ...state.offerInfo.confirmation,
            datesConfirmed: {
              ...state.offerInfo.confirmation.datesConfirmed,
              type: state.offerInfo.confirmation.datesConfirmed?.type ?? -1,
              data: {
                checked:
                  action.payload.datesConfirmed?.checked ??
                  state.offerInfo.confirmation.datesConfirmed?.data.checked ??
                  false,
                lastUpdatedAt:
                  action.payload.datesConfirmed?.lastUpdatedAt ??
                  state.offerInfo.confirmation.datesConfirmed?.data.lastUpdatedAt ??
                  null,
                lastUpdatedBy:
                  action.payload.datesConfirmed?.lastUpdatedBy ??
                  state.offerInfo.confirmation.datesConfirmed?.data.lastUpdatedBy ??
                  null,
                lastUpdatedById:
                  action.payload.datesConfirmed?.lastUpdatedById ??
                  state.offerInfo.confirmation.datesConfirmed?.data.lastUpdatedById ??
                  null,
              },
            },
            dnsCleared: {
              ...state.offerInfo.confirmation.dnsCleared,
              type: state.offerInfo.confirmation.dnsCleared?.type ?? -1,
              data: {
                checked:
                  action.payload.dnsCleared?.checked ?? state.offerInfo.confirmation.dnsCleared?.data.checked ?? false,
                lastUpdatedAt:
                  action.payload.dnsCleared?.lastUpdatedAt ??
                  state.offerInfo.confirmation.dnsCleared?.data.lastUpdatedAt ??
                  null,
                lastUpdatedBy:
                  action.payload.dnsCleared?.lastUpdatedBy ??
                  state.offerInfo.confirmation.dnsCleared?.data.lastUpdatedBy ??
                  null,
                lastUpdatedById:
                  action.payload.dnsCleared?.lastUpdatedById ??
                  state.offerInfo.confirmation.dnsCleared?.data.lastUpdatedById ??
                  null,
              },
            },
            travelConfirmed: {
              ...state.offerInfo.confirmation.travelConfirmed,
              type: state.offerInfo.confirmation.travelConfirmed?.type ?? -1,
              data: {
                checked:
                  action.payload.travelConfirmed?.checked ??
                  state.offerInfo.confirmation.travelConfirmed?.data.checked ??
                  false,
                lastUpdatedAt:
                  action.payload.travelConfirmed?.lastUpdatedAt ??
                  state.offerInfo.confirmation.travelConfirmed?.data.lastUpdatedAt ??
                  null,
                lastUpdatedBy:
                  action.payload.travelConfirmed?.lastUpdatedBy ??
                  state.offerInfo.confirmation.travelConfirmed?.data.lastUpdatedBy ??
                  null,
                lastUpdatedById:
                  action.payload.travelConfirmed?.lastUpdatedById ??
                  state.offerInfo.confirmation.travelConfirmed?.data.lastUpdatedById ??
                  null,
              },
            },
          },
        },
      };
    },
    /** @RIJASH - Bug #173782: Revise implementaion and sync into one setter - Due: 25h Nov 2022*/
    setUpdatedPlacementFieldsInSummary(
      state,
      action: PayloadAction<{
        data: IPlacementUpdate;
        dirtyFields: any;
        status?: { success?: boolean; fail?: boolean; message?: string };
      }>,
    ) {
      const updates = {
        requestedShift: action.payload.data.requestedShift,
      };

      return {
        ...state,
        submissionInfo: {
          ...state.submissionInfo,
          orderReq: {
            ...state.submissionInfo.orderReq,
            candidate: {
              ...state.submissionInfo.orderReq.candidate,
              schedule: {
                ...state.submissionInfo.orderReq.candidate.schedule,
                data: {
                  ...state.submissionInfo.orderReq.candidate.schedule.data,
                  requestedShift: updates.requestedShift,
                },
              },
            },
          },
        },
      };
    },
    setServiceFailureByKey(state, action: PayloadAction<{ key: IServiceFailureKey; failureStatus?: boolean }>) {
      return {
        ...state,
        serviceFailures: { ...state.serviceFailures, [action.payload.key]: action.payload.failureStatus },
      };
    },
    setOrderInformation(state, action: PayloadAction<any>) {
      return {
        ...state,
        orderInformation: {
          ...state.orderInformation,
          ...action.payload,
        },
      };
    },
    setAssignmentInformation(state, action: PayloadAction<any>) {
      return {
        ...state,
        assignmentInformation: {
          ...state.assignmentInformation,
          ...action.payload,
        },
      };
    },
    setAuditDetails(state, action: PayloadAction<IAuditDetailsInfo[]>) {
      return {
        ...state,
        auditDetailsInfo: [...action.payload],
      };
    },
    setReferenceAndEmploymentVerificationRecords(state, action: PayloadAction<any[]>) {
      return {
        ...state,
        referenceAndEmploymentVerificationRecords: [...action.payload],
      };
    },
    setFilterTreeByKey(
      state,
      action: PayloadAction<{
        filterTree: IRequirementTreeFilter;
        key: 'submissionInfo' | 'offerInfo' | 'bookingInfo' | 'interviewInfo';
      }>,
    ) {
      return {
        ...state,
        [action.payload.key]: { ...state[action.payload.key], requirementFilter: { ...action.payload.filterTree } },
      };
    },
    setEditableRequirementTypes(state, action: PayloadAction<IPlacementEditableRequirements>) {
      return {
        ...state,
        editableRequirementTypes: {
          ...state.editableRequirementTypes,
          ...action.payload,
        },
      };
    },
    setExtensionEditableRequirementTypes(
      state,
      action: PayloadAction<{ extensionId: number; requirement: IPlacementEditableRequirements }>,
    ) {
      const extensions = _.cloneDeep(state.editableRequirementTypes?.extensions);
      const newObj = { [action.payload.extensionId]: { extensionTimeOff: action.payload.requirement?.extensions } };
      extensions[action.payload.extensionId] = newObj;

      return {
        ...state,
        editableRequirementTypes: {
          ...state.editableRequirementTypes,
          extensions,
        },
      };
    },
    setPeopleSoft(state, action: PayloadAction<IPeopleSoft>) {
      return {
        ...state,
        peopleSoft: action.payload,
      };
    },
    setPeopleSoftAwaitingResultsCount(state, action: PayloadAction<number>) {
      return {
        ...state,
        peopleSoft: {
          ...state.peopleSoft,
          awaitingResultsCount: action.payload,
        },
      };
    },
    setSubmissionPacketDetail(state, action: PayloadAction<IPlacementSubmissionPacketDetail>) {
      return {
        ...state,
        submissionPacketDetail: { ...action.payload },
      };
    },
    setClassicSubmissionPacketInOkToSendExists(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        classicPacketInOkToSendExists: action.payload,
      };
    },
    reset() {
      return { ...initialState };
    },
  },
});

export const placementSummaryActions = { ...placementSummarySlice.actions, ...serviceActions };

export const { name: placementSummarySliceKey, reducer: placementSummaryReducer } = placementSummarySlice;
