import React, { useCallback, useEffect, useRef, useState } from 'react';
import { TextField, Chip, Grid, Theme, Avatar, Autocomplete } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { DisableAppScroll, EnableAppScroll } from 'app/layout/Layout';
import { useTranslation } from 'react-i18next';

export interface IMultiTypeAheadOption {
  object: any;
  label: string;
  isReadOnly: boolean;
}

const useStyles: any = makeStyles()((theme: Theme) => ({
  chip: {
    margin: '5px 16px 5px 0',
    background: '#F0F0F0 0% 0% no-repeat padding-box',
    '& .MuiChip-deleteIcon': {
      marginLeft: 'auto',
    },
  },
  chipDisplay: {
    '& .MuiChip-deleteIcon': {
      display: 'none',
    },
    margin: '5px 16px 0 0',
    background: '#F0F0F0 0% 0% no-repeat padding-box',
  },
  avatar: {
    margin: '0px 12px 0 0',
    width: '32px',
    height: '32px',
    fontSize: '0.75rem !important',
    textAalign: 'center',
    font: 'normal normal bold 14px/20px Roboto',
    color: '#FFFFFF !important',
    background: '#708090',
  },
  label: {
    textAlign: 'left',
    font: 'normal normal normal 14px/19px Roboto',
    color: '#333333',
    letterSpacing: '0px',
    opacity: '1',
  },
  title: {
    height: '16px',
    textAlign: 'left',
    font: 'normal normal normal 12px/20px Roboto',
    letterSpacing: '0px',
    opacity: '1',
  },
  chipStyle: {
    width: '180px',
    margin: '3px auto 3px 0px !important',
    justifyContent: 'unset',
    '& .MuiChip-label': {
      paddingRight: '6px',
    },
  },
  assignedToField: {
    '.MuiFilledInput-root': {
      padding: '27px 0px 0px 12px !important',
    },
  },
  chipAdornment: {
    width: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    maxHeight: '96px',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
}));

export const MultiSelectTypeAheadInlineChips = props => {
  const {
    taskVar,
    label,
    options,
    error,
    helperText,
    setKeyword,
    isLoading,
    isDisabled,
    value,
    isOpenOption,
    inputValue,
    formMethods,
    required,
  } = props;
  const { classes } = useStyles();
  const [chips, setChips] = useState<JSX.Element[]>([]);
  const [open, setOpen] = useState(false);
  const inputRef = useRef() as any;
  const { t } = useTranslation();
  const getInitials = fullname => {
    const fullName = fullname?.split(' ');
    if (fullName?.length > 1) {
      const name = fullName?.shift()?.charAt(0) + fullName?.pop()?.charAt(0);
      return name;
    } else {
      const initial = fullName?.shift()?.charAt(0);
      return initial;
    }
  };
  const { setError } = formMethods;
  const [shrink, setShrink] = useState<boolean>(false);

  const sortAlphabetically = elems => {
    elems?.sort((a, b) => {
      if (a.props?.label < b.props?.label) {
        return -1;
      }
      if (a.props?.label > b.props?.label) {
        return 1;
      }
      return 0;
    });
    return elems;
  };

  const onFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setShrink(true);
      if (props.onFocus) {
        props.onFocus(event);
      }
    },
    [props],
  );

  const onBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (props.value?.length === 0) {
        setShrink(false);
      }
      if (props.onBlur) {
        props.onBlur(event);
      }
      if (isOpenOption) {
        setOpen(false);
      }
    },
    [isOpenOption, props],
  );

  useEffect(() => {
    if (options?.length > 0 && inputValue) {
      setOpen(true);
    } else if (inputValue?.length > 3) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [inputValue, options, value]);

  useEffect(() => {
    if (value?.length > 0) {
      setShrink(true);
    }
  }, [value]);

  useEffect(() => {
    const chipElements =
      props.value &&
      props.value.map((option, index) => {
        if (option?.isReadOnly) return <Chip key={index} label={option.label} className={classes.chip} />;
        return (
          <Chip
            key={index}
            label={option?.object?.label}
            className={`${classes.chipStyle} ${isDisabled ? classes.chipDisplay : classes.chip}`}
            avatar={<Avatar className={classes.avatar}>{getInitials(option?.object?.label)}</Avatar>}
            onDelete={
              isDisabled
                ? undefined
                : () => {
                    const data = props.value.filter(entry => entry?.object?.empId !== option?.object?.empId);
                    props.onChange(data);
                    if (data.length === 0) {
                      // set short time out to get focus
                      if (label === 'Assigned To') {
                        setError(taskVar.assignedTo, { type: 'required' });
                      }
                      const timeout = setTimeout(() => {
                        inputRef?.current?.focus();
                      }, 100);

                      return () => {
                        clearTimeout(timeout);
                      };
                    }
                  }
            }
          />
        );
      });
    const sortedChips = sortAlphabetically(chipElements);
    setChips(sortedChips);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value, isDisabled]);

  const renderAuto = () => {
    return (
      <Autocomplete
        multiple
        filterSelectedOptions
        disableClearable
        value={props.value}
        options={options}
        getOptionLabel={option => option.label}
        popupIcon={null}
        loading={isLoading}
        noOptionsText={t('notification.createTask.noResultLabel') || undefined}
        filterOptions={option => option}
        open={open}
        disabled={isDisabled}
        renderOption={(props, option, state) => {
          return (
            <li {...props}>
              <Avatar className={classes.avatar}>{getInitials(option?.object?.label)}</Avatar>
              <div className={classes.main}>
                <div className={classes.label}>{option?.object?.label}</div>
                <div className={classes.title}>
                  <span>{option?.object?.role}</span>
                </div>
              </div>
            </li>
          );
        }}
        renderInput={params => (
          <TextField
            required={required}
            inputRef={inputRef}
            error={error}
            onFocus={onFocus}
            onBlur={onBlur}
            helperText={helperText}
            label={label}
            {...params}
            InputLabelProps={{ shrink: shrink , className: classes.label }}
            variant="filled"
            className={classes.assignedToField}
            InputProps={{
              ...params.InputProps,
              classes: {},
              startAdornment: <div className={classes.chipAdornment}>{chips}</div>,
            }}
          />
        )}
        onChange={(e, data) => {
          props.onChange(data);
        }}
        onInputChange={(e, data) => {
          if (!shrink) {
            setShrink(true);
          }
          setKeyword(data);
          if (!data || isOpenOption) {
            setOpen(false);
          }
        }}
        // className={classes.selectGrid}
        onOpen={() => {
          if (inputValue || isOpenOption) {
            setOpen(true);
          }
          options.length && DisableAppScroll();
        }}
        onClose={EnableAppScroll}
      />
    );
  };

  return (
    <Grid container direction="column">
      <Grid item xs={12}>
        {renderAuto()}
      </Grid>
    </Grid>
  );
};
