/* eslint-disable react/jsx-key */
import {
  GridSortModel,
  GridCallbackDetails,
  GridColumns as XGridColumns,
  GridApi,
  GridColumnVisibilityModel,
} from '@mui/x-data-grid-pro';
import { CircularProgress } from 'amn-ui-core';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import {
  selectColumnVisibilityModel,
  selectFiterAttributes,
  selectGridFiltersExpanded,
  selectGridState,
} from 'app/components/Common/Grid/GridStateManagement/GridState.selectors';
import { formatToAmericanNotaion } from 'app/helpers/numberHelper';
import { getDetailsPageSupportComponentHeight } from 'app/layout/pages/DetailsPage';
import { GridColumns, SearchType } from 'app/models/GlobalSearch/GlobalSearch';
import { ContactProps, ILimitsContact, IToken } from 'app/models/Notification/Notification';
import { ApplyDataToTemplate, GetTemplatesByCategory } from 'app/services/NotificationServices/TemplateService';
import _ from 'lodash';
import { selectUser } from 'oidc/user.selectors';
import { userDevicePreferenceActions } from 'oidc/UserDevicePreference/userPreference.redux';
import { selectUserPreference } from 'oidc/UserDevicePreference/userPreference.selectors';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Concatenate } from 'utils/string/string';
import { selectGridSelection } from '../Common/Grid/GridSelection/GridSelection.selector';
import { gridStateActions, IGridState, SortColumn } from '../Common/Grid/GridStateManagement/GridState.redux';
import { XGrid } from '../Common/XGrid/XGrid';
import { candidateViewInfo, candidateColumnVisibilityModel } from '../GlobalSearch/Candidate/SearchUtils';
import { ChannelType, FormName, SendType, UseSubType, UseType } from '../Notification/Constants';
import { notificationDataActions } from 'store/redux-store/notification/notification.redux';
import { notificationSelection } from 'store/redux-store/notification/notification.selector';
import { OrderData } from '../Order/OrderDetails/OrderDetailsDesign';
import { facilityColumnVisibilityModel, facilityViewInfo } from './Facility/SearchUtils';
import {
  formatMultiSortColumns,
  formatMultiSortXGridColumns,
  searchNotificationData,
  signatureSubstitutionToken,
  sortOrderData,
} from './helper';
import { orderColumnVisibilityModel, orderViewInfo } from './Order/SearchUtils';
import { ArchivePlacementDialog } from './Placement/ArchivePlacementDialog';
import { placementColumnVisibilityModel, placementViewInfo } from './Placement/SearchUtils';
import { formatPhoneNumber } from '../Notification/MultiChannel/BulkSms/MessageHelpers';
import { EmailDefaultValues } from 'app/models/Email/IEmail';
import {
  useEnableBulkEmail,
  useEnableBulkSms,
  useEnableEmailCommunication,
  useEnableSmsCommunication,
} from 'oidc/CommunicationEnabler';
import { globalSearchActions, totalCountErrorThreshold } from 'store/redux-store/global-search/slice';
import { missingField } from 'app/constants';
import {
  selectArchivePlacements,
  selectBulkVerifyOrdersFlag,
  selectSearchResults,
} from 'store/redux-store/global-search/selectors';
import { GlobalSearchSort, IGlobalSearchFetch } from './SearchResultsPage';
import { RootState } from 'types';
import {
  getSessionValue,
  SessionKey,
  setSessionValue,
  StorageType,
} from 'utils/customHooks/sessionStorage/sessionHelpers';
import { useDecision } from '@optimizely/react-sdk';
import {
  ff_candidate_ui_search_job_interest,
  ff_candidate_ui_search_job_match,
  ff_candidate_api_candidate_search_index,
} from 'app/constants/FeatureFlags/Candidate/Keys';
import { trackEvent, trackException } from 'app-insights/appInsightsTracking';
import moment from 'moment';
import { isNullOrUndefined } from 'app/helpers/objectHelpers';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import { userRoles } from 'oidc/userRoles';
import { convertToFormat } from 'app/helpers/dateHelper';
import { usePromiseTracker } from 'react-promise-tracker';
import { Authorized } from 'oidc/userHelper';
import UpdateOrderDatesModal from './UpdateOrderDatesModal';
import EditCalendarIcon from 'app/assets/images/Calendar-Edit.svg';
import EditCalendarIconDisabled from 'app/assets/images/Calendar-Edit-Disabled.svg';
import { ff_order_ui_bulk_update_dates_hsg } from 'app/constants/FeatureFlags/Order/Keys';
import { SearchGrid } from 'app/constants/FeatureFlags/smsKeys';
import { selectGlobalBanner } from 'app/ApplicationRoot/Global.selectors';
import { ff_placement_ui_arbitration_agreement_grids } from 'app/constants/FeatureFlags/Global/keys';
import { CommunicationLimitsDialog } from './CommunicationLimitsDialog';
import {
  ff_notifications_blueshift_email_communication_limits,
  ff_notifications_blueshift_sms_communication_limits,
  ff_candidate_communication_grid,
} from 'app/constants/FeatureFlags/Tasks/Notifications/Keys';
import { ff_placement_region_fields } from 'app/constants/FeatureFlags/Placement/Keys';

const MAX_ALLOWED_EMAIL_SELECTION = 50;
const MAX_ALLOWED_ORDER_SELECTION = 200;
const CREATE_MESSAGE_WARNING = `Maximum of ${MAX_ALLOWED_EMAIL_SELECTION} recipients permitted. Please reduce your selections and try again.`;
const CREATE_ORDERS_MESSAGE_WARNING = `Maximum of ${MAX_ALLOWED_ORDER_SELECTION} orders permitted. Please reduce your selections and try again.`;

interface IOrderTemplate {
  facility: string | undefined;
  unit: string | undefined;
  city: string | undefined;
  state: string | undefined;
  skillset: string | undefined;
  shifts: any | undefined;
  weekly_gross: any | undefined;
  business_relationship: string | undefined;
  positions: string | undefined;
  notes: any | undefined;
}

export const SearchResultsGrid = ({
  searchCategory,
  searchString,
  gridTag,
  gridTitle,
  gridType,
  loading,
  getSearchResults,
}: {
  searchCategory: SearchType;
  searchString: string;
  gridTag: string;
  gridTitle: string;
  gridType: string;
  loading: boolean;
  getSearchResults: ({ pageNumber, pageSize, sortModel, multiSortedColumns }: IGlobalSearchFetch) => void;
}) => {
  const { t } = useTranslation();
  const { promiseInProgress: bulkVerifyOrdersPromise } = usePromiseTracker({
    area: 'bulk-verify-orders-call',
    delay: 0,
  });
  const user = useSelector(selectUser);
  const notificationData = useSelector(notificationSelection);
  const { selectedData } = useSelector(selectGridSelection);
  const { success: archivePlacementStatus } = useSelector(selectArchivePlacements);
  const bulkVerifyOrdersFlag = useSelector(selectBulkVerifyOrdersFlag);
  const apiRef = React.useRef<GridApi>();
  const gridState = useSelector(selectGridState);
  const columnVisibilityModel = useSelector(selectColumnVisibilityModel);
  const globalBanner = useSelector(selectGlobalBanner);

  /** To be refactored with useGridPreference hook - start */
  const gridStateRef = useRef<IGridState>(gridState);
  gridStateRef.current = gridState;
  /** To be refactored with useGridPreference hook - end */

  const {
    items,
    count,
    miscellaneous: { jobInterestHealth, jobMatchHealth, positionCount },
  } = useSelector(selectSearchResults);

  const [notificationUser] = useState(searchNotificationData(user));
  const [secondaryTitle, setSecondaryTitle] = useState<string | null>(null);
  const dispatch = useDispatch();
  const userPreference = useSelector(selectUserPreference);
  const [jobInterestFlag] = useDecision(ff_candidate_ui_search_job_interest);
  const [jobMatchFlag] = useDecision(ff_candidate_ui_search_job_match);
  const filtersExpanded = useSelector(selectGridFiltersExpanded);
  const [newSearchIndex] = useDecision(ff_candidate_api_candidate_search_index);
  const [arbitrationAgreementFlag] = useDecision(ff_placement_ui_arbitration_agreement_grids);
  const [communicationLimitsFlag] = useDecision(ff_notifications_blueshift_email_communication_limits);
  const [communicationLimitsSmsFlag] = useDecision(ff_notifications_blueshift_sms_communication_limits);
  const [candidateCommunicationConsentFlag] = useDecision(ff_candidate_communication_grid);
  const [regionFlag] = useDecision(ff_placement_region_fields);

  ///////////////////////////////////////////////////////////
  /** To be refactored with useGridPreference hook - start */
  const [columns, setColumns] = useState<any>([]);
  const [defaultColumns, setDefaultColumns] = useState<any>([]);
  const [xGridSortModel, setXGridSortModel] = useState<any>([]);
  const [defaultSortModel, setDefaultSortModel] = useState<any>([]);
  /** To be refactored with useGridPreference hook - end */
  const filters = useSelector(selectFiterAttributes);
  const enableEmailCommunication = useEnableEmailCommunication();
  const enableSmsCommunication = useEnableSmsCommunication();
  const enableBulkSms = useEnableBulkSms(SearchGrid.candidateSearchGrid);
  const enableBulkEmail = useEnableBulkEmail(SearchGrid.candidateSearchGrid);
  const activate = user.userInfo?.phoneNumber !== undefined ? true : false;
  const [disableArchivePlacements, setDisableArchivePlacements] = React.useState<boolean>(true);
  const [disableVerifyOrders, setDisableVerifyOrders] = React.useState<boolean>(true);
  const [showMapView, setShowMapView] = React.useState<boolean>(false);
  const [disableMapView, setDisableMapView] = React.useState<boolean>(true);
  const resultMapView = 'globalOrdersMapview';
  const gridPreferences = useSelector((state: RootState) => {
    return state.userPreferenceData.userGridPreferences.find(item => item.id === gridTag);
  });
  const [initialState, setInitialState] = React.useState<{ [x: string]: any } | undefined>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const [refreshKey, setRefreshKey] = React.useState<boolean>(false);
  const enableUpdateHsgOrders = useDecision(ff_order_ui_bulk_update_dates_hsg);
  const [openUpdateDatesModal, setOpenUpdateDatesModal] = React.useState<boolean>(false);
  const [UpdateOrderDatesModalProps, setUpdateDatesModalProps] = React.useState<{
    orderStartDate: any;
    orderEndDate: any;
  }>({
    orderStartDate: null,
    orderEndDate: null,
  });

  React.useEffect(() => {
    if (columns.length > 0) {
      const sessionColumnVisibilityModel = getSessionValue(SessionKey.tempColumnOrder, StorageType.sessionStorage);
      const selectedFilterIndex = (gridPreferences?.value?.filterAttributes?.filterSet || [])?.findIndex(
        item => item.name === gridPreferences?.value?.selectedFilter?.name,
      );
      const newVisibilityModel =
        selectedFilterIndex !== -1
          ? gridPreferences?.value?.filterAttributes?.filterSet[selectedFilterIndex]?.columnVisibilityModel
          : gridPreferences?.value?.columnVisibilityModel
          ? gridPreferences?.value?.columnVisibilityModel
          : sessionColumnVisibilityModel?.columnVisibilityModel
          ? sessionColumnVisibilityModel?.columnVisibilityModel
          : defaultColumnVisibilityModel();

      /** Code: newVisibilityModel is determined from multiple factors
       * Incurring high chances of becoming undefined
       */
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const _visibilityModel = newVisibilityModel || defaultColumnVisibilityModel();
      if (
        newVisibilityModel &&
        !Object.keys(defaultColumnVisibilityModel()).every(key => Object.keys(newVisibilityModel).includes(key))
      ) {
        const cols = defaultColumnVisibilityModel();
        _.merge(cols, newVisibilityModel);
        dispatch(gridStateActions.setColumnVisibilityModel(cols));
        determineGridColumns();
      } else {
        dispatch(gridStateActions.setColumnVisibilityModel(_visibilityModel));
      }

      setInitialState({ columns: { columnVisibilityModel: _visibilityModel } });
      if (globalThis.amieLogLevel === 1) {
        console.info(
          'column visibility set model on column change: ',
          _visibilityModel,
          gridPreferences,
          selectedFilterIndex,
        );
      }
      // Commented out to fix column visibility menu closing upon selection.
      // Leaving code in case grid refresh is necessary for preference modifications.
      // setRefreshKey(p => !p);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(columns ?? []), gridPreferences?.value?.selectedFilter?.name]);

  React.useEffect(() => {
    if (globalThis.amieLogLevel === 1) {
      console.info('column visibility model redux object changed', columnVisibilityModel);
    }
  }, [columnVisibilityModel]);

  React.useEffect(() => {
    if (bulkVerifyOrdersFlag) {
      handleFetchData(gridState.pageSize, gridState.pageNumber, gridState.sortedColumn);
      dispatch(globalSearchActions.setBulkOrders({ bulkVerifyOrdersFlag: false }));
    }
  }, [bulkVerifyOrdersFlag]);

  ///////////////////////////////////////////////////////////////
  /** To be refactored with useGridPreference hook - start */
  const savePreferences = React.useCallback(
    ({
      field,
      columns: gridColumns,
      visibilityModel,
    }: {
      field?: string;
      columns: XGridColumns;
      visibilityModel: GridColumnVisibilityModel;
    }) => {
      if (!columns || columns.length === 0 || !userPreference.loaded || defaultColumns?.length === 0) return;
      const currentGridColumns = new GridColumns(gridColumns).columns;
      if (globalThis.amieLogLevel === 1) {
        console.info('column preferences saving: ', visibilityModel, columns, gridPreferences);
      }

      const i = (gridPreferences?.value?.filterAttributes?.filterSet || [])?.findIndex(
        item => item.name === gridPreferences?.value?.selectedFilter?.name,
      );

      if (currentGridColumns && currentGridColumns.length > 0 && currentGridColumns[0].field === '__check__')
        currentGridColumns.shift();

      const comparableColumns = new GridColumns(
        i !== -1 ? gridPreferences?.value?.filterAttributes?.filterSet[i]?.columns : gridPreferences?.value?.columns,
        i !== -1
          ? gridPreferences?.value?.filterAttributes?.filterSet[i]?.columnVisibilityModel
          : gridPreferences?.value?.columnVisibilityModel,
      );
      const columnsEqual = comparableColumns.isEqual(currentGridColumns);
      const visibilityEqual = comparableColumns.isVisibilityModelEqual(visibilityModel);

      if (!columnsEqual || !visibilityEqual) {
        let newFilterSet: any = undefined;
        if (i !== -1) {
          newFilterSet = _.cloneDeep(gridPreferences?.value?.filterAttributes?.filterSet);
          newFilterSet[i].columns = currentGridColumns;
          newFilterSet[i].columnVisibilityModel = visibilityModel;
        }
        setSessionValue(
          SessionKey.tempColumnOrder,
          {
            ...getSessionValue(SessionKey.tempColumnOrder, StorageType.sessionStorage),
            columns: currentGridColumns,
            columnVisibilityModel: visibilityModel,
          },
          StorageType.sessionStorage,
        );
        const preferenceData = {
          id: gridTag,
          value: {
            ...(gridPreferences?.value || {}),
            filterAttributes: {
              ...gridPreferences?.value?.filterAttributes,
              filterSet: newFilterSet ?? gridPreferences?.value?.filterAttributes?.filterSet,
            },
            columns: currentGridColumns,
            columnVisibilityModel: visibilityModel,
          },
        };
        if (globalThis.amieLogLevel === 1) {
          console.info('column preferences saved', columnsEqual, visibilityEqual, preferenceData);
        }
        dispatch(userDevicePreferenceActions.saveUserGridPreferenceAction(preferenceData));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columns, userPreference.loaded, defaultColumns?.length, gridPreferences?.value, gridTag, dispatch],
  );

  useEffect(() => {
    const defaultViewValue = userPreference.userGridPreferences?.find(x => x?.id === gridTag)?.value;
    if (defaultViewValue && gridState) {
      dispatch(
        gridStateActions.setSaveAsDefaultView(_.isEqual(gridState.filterAttributes, defaultViewValue.filterAttributes)),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPreference.userGridPreferences]);
  /** To be refactored with useGridPreference hook - end */

  /////////////////////////////////////////
  /** To be refactored with useGridPreference hook - start */
  const getColumnPreferences = (cols: any[]) => {
    try {
      const columnPreferences = gridPreferences?.value;
      const filterSetColumns = gridPreferences?.value?.filterAttributes?.filterSet?.find(
        item => item.name === gridPreferences?.value?.selectedFilter?.name,
      )?.columns;
      if (columnPreferences && (columnPreferences.columns || filterSetColumns)) {
        const newColumns = cols.map(column => {
          const foundIndex = (filterSetColumns ?? columnPreferences.columns).findIndex(
            item => item?.field === column?.field,
          );
          if (foundIndex !== -1) {
            return {
              ...column,
              ...(filterSetColumns ?? columnPreferences.columns)[foundIndex],
            };
          } else {
            return column;
          }
        });
        return newColumns.sort((a, b) => {
          const filterSetColumnsIndexA = (filterSetColumns ?? columnPreferences.columns).findIndex(
            i => i.field === a.field,
          );
          const filterSetColumnsIndexB = (filterSetColumns ?? columnPreferences.columns).findIndex(
            i => i.field === b.field,
          );

          const indexA = filterSetColumnsIndexA === -1 ? Number.MAX_SAFE_INTEGER : filterSetColumnsIndexA;
          const indexB = filterSetColumnsIndexB === -1 ? Number.MAX_SAFE_INTEGER : filterSetColumnsIndexB;

          return indexA - indexB;
        });
      }
      return cols;
    } catch (err) {
      trackException({
        exception: err,
        properties: { columns: cols, grid: gridTag, category: searchCategory, searchString: searchString },
      });
      return cols;
    }
  };

  const determineColumns = (isDefault, currentColumns) => {
    setDefaultColumns(currentColumns);
    const newCols = isDefault ? currentColumns : getColumnPreferences(currentColumns);
    if (!_.isEqual(newCols, columns)) setColumns(newCols);
  };

  /** To be refactored with useGridPreference hook - end */
  const deternineSortModal = (isDefault = false) => {
    const preference = userPreference.userGridPreferences.find(x => x?.id === gridTag)?.value;
    const filterSetSort = gridPreferences?.value?.filterAttributes?.filterSet?.find(
      item => item.name === gridPreferences?.value?.selectedFilter?.name,
    );
    const sortModal = new GlobalSearchSort(searchCategory);
    setDefaultSortModel(sortModal.toGrid());
    if (
      !isDefault &&
      ((preference?.sortedColumn?.column && preference?.sortedColumn?.direction) ||
        (filterSetSort?.sortedColumn?.column && filterSetSort?.sortedColumn?.direction) ||
        preference?.multiSortedColumns?.length > 0 ||
        filterSetSort?.multiSortedColumns?.length > 0)
    ) {
      const multiSortedColumns =
        filterSetSort?.multiSortedColumns?.length > 0
          ? formatMultiSortXGridColumns(filterSetSort?.multiSortedColumns)
          : undefined;

      setXGridSortModel(
        preference?.multiSortedColumns
          ? multiSortedColumns
          : [
              filterSetSort
                ? { field: filterSetSort?.sortedColumn?.column, sort: filterSetSort?.sortedColumn?.direction }
                : { field: preference?.sortedColumn?.column, sort: preference?.sortedColumn?.direction },
            ],
      );
    } else {
      setXGridSortModel(sortModal.toGrid());
    }
  };

  const determineGridColumns = (isDefault = false) => {
    switch (searchCategory) {
      case SearchType.order:
        determineColumns(isDefault, orderViewInfo(t));
        break;
      case SearchType.candidate:
        determineColumns(
          isDefault,
          candidateViewInfo(
            t,
            jobInterestHealth,
            jobMatchHealth,
            jobInterestFlag.enabled,
            jobMatchFlag.enabled,
            newSearchIndex.enabled,
            candidateCommunicationConsentFlag.enabled,
          ),
        );
        break;
      case SearchType.placement:
        determineColumns(
          isDefault,
          placementViewInfo(
            t,
            arbitrationAgreementFlag.enabled
              ? ['brand']
              : ['brand', 'arbitrationSentDate', 'arbitrationSignedDate', 'arbitrationStatus'],
            candidateCommunicationConsentFlag.enabled,
          ).filter(column => !(regionFlag.enabled ? [] : ['region']).some(notValue => notValue === column.field)),
        );
        break;
      case SearchType.facility:
        determineColumns(isDefault, facilityViewInfo(t));
        break;
    }
  };

  const defaultColumnVisibilityModel = React.useCallback(() => {
    switch (searchCategory) {
      case SearchType.order:
        return orderColumnVisibilityModel;
      case SearchType.candidate:
        return candidateColumnVisibilityModel;
      case SearchType.placement:
        return placementColumnVisibilityModel;
      case SearchType.facility:
        return facilityColumnVisibilityModel;
    }
  }, [searchCategory]);

  React.useEffect(() => {
    if (window.location.href.includes((gridTag ?? 'none-none').split('-')[1]) && userPreference?.loaded) {
      determineGridColumns();
      deternineSortModal(!isNullOrUndefined(searchString));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    jobInterestHealth,
    jobMatchHealth,
    t,
    gridTag,
    userPreference?.loaded,
    gridPreferences?.value?.selectedFilter?.name,
  ]);

  const getValidEmailRowsWithEmail = React.useCallback(() => {
    const validRows = items.filter(row => selectedData.includes(row.id));
    const validEmailRows: ContactProps[] = validRows.reduce((storage: ContactProps[], item) => {
      if (item.email !== missingField) {
        return [
          ...storage,
          {
            name: item.candidate,
            email: item.email,
            contactId: item.candidateId,
            phoneNumbers: item.phones,
            contactType: searchCategory,
            brand: item.brand,
            brandId: item.brandId,
            recruiterId: item?.recruiterId !== '--' ? item?.recruiterId : undefined,
          },
        ];
      } else {
        return storage;
      }
    }, []);
    return validEmailRows;
  }, [items, searchCategory, selectedData]);

  const getOrderData = () => {
    const validRows = items.filter(row => selectedData.includes(row.id));
    const validEmailRows: IOrderTemplate[] = validRows.reduce((storage: IOrderTemplate[], item) => {
      if (item.id !== missingField) {
        return [
          ...storage,
          {
            facility: item.facility,
            unit: item.unit,
            city: item.city,
            state: item?.stateName,
            skillset: item.skillsets,
            shifts: item.shifts,
            weekly_gross: `${item.weeklyGrossPay}`,
            business_relationship: item.businessRelationship,
            positions: `${item.positionsAvailable}`,
            notes: item?.notes,
          },
        ];
      } else {
        return storage;
      }
    }, []);
    return validEmailRows;
  };

  const handleOrderEmailCreate = React.useCallback(async () => {
    const orderData = getOrderData();
    const sortedData = sortOrderData(orderData);
    const sender = {
      name: Concatenate([user.userInfo?.firstName || '', user.userInfo?.lastName || ''], ' '),
      email: user.userInfo?.email,
      userId: `${user.userInfo?.employeeId}`,
      NtUserName: user.userInfo?.ntUserName,
      senderId: undefined,
    };
    const templates = await GetTemplatesByCategory('Job Match', 'email');
    const template = templates.filter(data => data.title === 'Default Jobs List');
    var body = String(template[0]?.body);
    const partialData = sortedData;
    const tableData = {
      jobs: {
        partialTemplateName: 'job',
        partialTemplate:
          '<tr style="padding: 5px; border:1px solid black; border-collapse: collapse;">\r\n\t<td style="padding: 5px; border:1px solid black; border-collapse: collapse;">{{job.facility}}</td>\r\n\t<td style="padding: 5px; border:1px solid black; border-collapse: collapse;">{{job.unit}}</td>\r\n\t<td style="padding: 5px; border:1px solid black; border-collapse: collapse;">{{job.city}}</td>\r\n\t<td style="padding: 5px; border:1px solid black; border-collapse: collapse;">{{job.state}}</td>\r\n\t<td style="padding: 5px; border:1px solid black; border-collapse: collapse;">{{job.skillset}}</td>\r\n\t<td style="padding: 5px; border:1px solid black; border-collapse: collapse;">{{job.shifts}}</td>\r\n\t<td style="padding: 5px; border:1px solid black; border-collapse: collapse;">{{job.weekly_gross}}</td>\r\n\t<td style="padding: 5px; border:1px solid black; border-collapse: collapse;">{{job.business_relationship}}</td>\r\n\t<td style="padding: 5px; border:1px solid black; border-collapse: collapse;">{{job.positions}}</td>\r\n\t<td style="padding: 5px; border:1px solid black; border-collapse: collapse;">{{job.notes}}</td>\r\n</tr>',
        partialData: partialData,
      },
    };

    const previewRequestData = {
      body: body,
      brand: user.userInfo?.companyBrandName,
      signatureSubstitutions: signatureSubstitutionToken(user, userPreference),
      substitutions: {},
      tableData: tableData,
      id: null,
      skipSignatureSubstitution: true,
    };
    await ApplyDataToTemplate(previewRequestData).then(response => {
      body = response;
    });
    const substitutions: IToken[] = [];
    substitutions.push({
      name: 'Signature',
      input: '{{signature}}',
    });
    const data = {
      useType: UseType.Candidate,
      channel: ChannelType.email,
      emailType: SendType.one_to_one,
      selectedTemplate:
        template.length > 0
          ? template[0]
          : {
              title: '',
              id: '',
              body: '',
              description: '',
              category: '',
              isPrivate: false,
              substitutions: [],
            },
      publicTemplates: templates?.filter(tmpl => tmpl?.isPrivate !== true),
      sendType: SendType.one_to_one,
      body: body,
      sender: sender,
      substitutions: substitutions,
      tos: [],
      ccs: [],
      bccs: [sender],
      userRole: Concatenate(user?.userInfo?.roles || [], ','),
      formName: FormName.Traveler,
      signatureSubstitutions: previewRequestData.signatureSubstitutions,
      tableData: tableData,
      subject: '',
      packetSubmission: false,
      associatedRecords: [],
      selectedPublicCategory: template.length > 0 ? template[0].category : '',
      personalTemplates: [],
      selectedPersonalCategory: '',
    };

    if (!notificationData.email.open && notificationData.email.minimized) {
      dispatch(
        notificationDataActions.setEmailInteraction({
          open: true,
          minimized: false,
        }),
      );
      dispatch(notificationDataActions.setSnackBarData({ channel: 'email', manual: false, changeWarning: true }));
    } else {
      dispatch(
        notificationDataActions.setNotificationData({
          ...notificationData,
          email: {
            data: data,
            open: true,
            minimized: false,
          },
          snackbarData: null,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, notificationData, selectedData, user, OrderData]);

  const handleEmailCreate = React.useCallback(
    () => {
      const recipient: ContactProps[] = getValidEmailRowsWithEmail();
      const contacts: ILimitsContact[] = (recipient || []).map(rec => ({
        candidateId: rec?.contactId?.toString(),
        brandId: rec?.brandId?.toString(),
      }));
      //CompanyId : AMN Nurse - 1 and AMN Allied - 10
      communicationLimitsFlag.enabled &&
        [1, 10].includes(user.userInfo?.companyId) &&
        Authorized(
          [userRoles.recruitment, userRoles.recruitment_TeamMember, userRoles.recruitment_Leadership],
          user.userInfo,
        ) &&
        dispatch(notificationDataActions.getCommunicationLimitsData({ contacts, channel: ChannelType.email }));
      const substitutions: IToken[] = [];
      substitutions.push({
        name: 'Signature',
        input: '{{signature}}',
      });
      if (!notificationData.email.open && notificationData.email.minimized) {
        dispatch(
          notificationDataActions.setEmailInteraction({
            open: true,
            minimized: false,
          }),
        );
        if (
          notificationData.email.data?.sendType === SendType.one_to_one ||
          notificationData.email.data?.useType === UseType.ClientContacts ||
          notificationData.email.data?.packetSubmission ||
          !_.isEqual(notificationData.email.data!.tos, recipient)
        ) {
          dispatch(notificationDataActions.setSnackBarData({ channel: 'email', manual: false, changeWarning: true }));
        }
      } else {
        dispatch(
          notificationDataActions.setEmailData({
            data: {
              ...EmailDefaultValues(),
              ...notificationUser,
              substitutions: substitutions,
              useType: UseType.Bulk,
              useSubType: UseSubType.CandidateSearch,
              emailType: SendType.bulk,
              tos: recipient,
            },
            open: true,
            minimized: false,
          }),
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dispatch,
      items,
      notificationData.email.data,
      notificationData.email.minimized,
      notificationData.email.open,
      notificationUser,
      searchCategory,
      selectedData,
      user.userInfo,
    ],
  );

  const getBulkOrderData = () => {
    const validRows = items.filter(row => selectedData.includes(row.id));
    let ordersData = [];
    ordersData = validRows.map(row => row?.orderId);
    return ordersData;
  };

  const handleBulkVerifyOrders = () => {
    const ordersData = getBulkOrderData();
    const verifiedDateTime = convertToFormat(new Date(), '', true);

    dispatch(
      globalSearchActions.bulkVerifyOrders({
        orderIds: [...ordersData],
        DateVerified: verifiedDateTime ? verifiedDateTime : '',
        VerifiedByUserId: user.userInfo?.employeeId ? user.userInfo?.employeeId : 0,
      }),
    );
  };

  // const handleBulkSmsData = () => {
  //   const validRows = items.filter(row => selectedData.includes(row.id));
  //   const validEmailRows = validRows.reduce((storage: ContactProps[], item) => {
  //     if (item?.phones?.length > 0) {
  //       return [
  //         ...storage,
  //         {
  //           name: item.candidate,
  //           email: item.email,
  //           contactId: `${item.candidateId}`,
  //           phoneNumber:
  //             item?.phones?.length > 0
  //               ? item?.phones[0]?.phoneNumer !== missingField
  //                 ? formatPhoneNumber(item.phones[0]?.phoneNumber)
  //                 : undefined
  //               : undefined,
  //           contactType: searchCategory,
  //           brand: item.brand,
  //           brandId: `${item.brandId}`,
  //           recruiter: item?.recruiter,
  //           recruiterId: `${item?.recruiterId}`,
  //         },
  //       ];
  //     } else {
  //       return storage;
  //     }
  //   }, []);
  //   return validEmailRows;
  // };

  const EditCalendarIconSVG = () => {
    const disableUpdateOrderDates = selectedData.length < 1 || !enableUpdateHsgOrders;
    return disableUpdateOrderDates ? (
      <img src={EditCalendarIconDisabled} alt="Edit Calendar" />
    ) : (
      <img src={EditCalendarIcon} alt="Edit Calendar" />
    );
  };

  const handleUpdateOrderDates = () => {
    const selections = items.filter(row => selectedData.includes(row.id));
    const hasEqualStartDates = selections.every(
      ele => new Date(ele.startdate)?.getTime() === new Date(selections?.[0]?.startdate)?.getTime(),
    );
    const hasEqualEndDates = selections.every(
      ele => new Date(ele.endDate)?.getTime() === new Date(selections?.[0]?.endDate)?.getTime(),
    );
    const orderStartDate = hasEqualStartDates ? selections[0]?.startdate : null;
    const orderEndDate = hasEqualEndDates ? selections[0]?.endDate : null;
    setUpdateDatesModalProps({ orderStartDate, orderEndDate });
    setOpenUpdateDatesModal(true);
  };

  const handleBulkSmsDataFilter = React.useCallback(() => {
    const validRows = items.filter(row => selectedData.includes(row.id));
    const validMappedRows: ContactProps[] = validRows.map(item => {
      const newItem = {
        name: item?.candidate,
        email: item?.email,
        contactId: `${item.candidateId}`,
        phoneNumber:
          item?.phones?.length > 0
            ? item?.phones[0]?.phoneNumer !== missingField
              ? formatPhoneNumber(item.phones[0]?.phoneNumber)
              : undefined
            : undefined,
        contactType: searchCategory,
        brand: item?.brand,
        brandId: `${item.brandId}`,
        recruiter: item?.recruiter,
        recruiterId: `${item?.recruiterId}`,
      };
      return newItem;
    });
    return validMappedRows;
  }, [items, searchCategory, selectedData]);

  const handleBulkSms = React.useCallback(() => {
    const candidateData = handleBulkSmsDataFilter();
    const contactIds = candidateData.map(item => item.contactId) as string[];

    const contacts: ILimitsContact[] = (candidateData || []).map(rec => ({
      candidateId: rec?.contactId?.toString(),
      brandId: rec?.brandId?.toString(),
    }));

    //CompanyId : AMN Nurse - 1 and AMN Allied - 10
    communicationLimitsSmsFlag.enabled &&
    [1, 10].includes(user.userInfo?.companyId) &&
    Authorized(
      [userRoles.recruitment, userRoles.recruitment_TeamMember, userRoles.recruitment_Leadership],
      user.userInfo,
    )
      ? dispatch(
          notificationDataActions.getCommunicationLimitsData({
            contacts,
            channel: ChannelType.sms,
            bulkSmsData: { contactIds, candidateData },
          }),
        )
      : dispatch(notificationDataActions.bulkSmsCandidateConsent({ contactIds, candidateData }));
  }, [dispatch, handleBulkSmsDataFilter]);

  const leftActions = React.useMemo(() => {
    if (items.length > 0) {
      switch (searchCategory) {
        case SearchType.candidate:
          return [
            {
              title: 'global.xgrid.toolbar.email',
              Icon: EmailOutlinedIcon,
              disabled:
                !enableEmailCommunication ||
                !enableBulkEmail ||
                getValidEmailRowsWithEmail().length < 1 ||
                getValidEmailRowsWithEmail().length > MAX_ALLOWED_EMAIL_SELECTION,
              onClick: handleEmailCreate,
              tooltipProps: {
                tooltipContent: CREATE_MESSAGE_WARNING,
                disabled: selectedData.length <= MAX_ALLOWED_EMAIL_SELECTION,
              },
            },
            {
              title: 'global.xgrid.toolbar.sms',
              Icon: ChatOutlinedIcon,
              disabled:
                !activate ||
                !enableSmsCommunication ||
                !enableBulkSms ||
                handleBulkSmsDataFilter().length < 1 ||
                handleBulkSmsDataFilter().length > MAX_ALLOWED_EMAIL_SELECTION,
              onClick: handleBulkSms,
              tooltipProps: {
                tooltipContent: CREATE_MESSAGE_WARNING,
                disabled: selectedData.length <= MAX_ALLOWED_EMAIL_SELECTION,
              },
            },
          ];
        case SearchType.order:
          return [
            {
              id: 'search-order-message-button',
              title: 'global.xgrid.toolbar.email',
              Icon: EmailOutlinedIcon,
              disabled:
                !enableEmailCommunication ||
                getValidEmailRowsWithEmail().length < 1 ||
                getValidEmailRowsWithEmail().length > MAX_ALLOWED_ORDER_SELECTION,
              onClick: handleOrderEmailCreate,
              tooltipProps: {
                tooltipContent: CREATE_ORDERS_MESSAGE_WARNING,
                disabled: selectedData.length <= MAX_ALLOWED_ORDER_SELECTION,
              },
            },
          ];
        case SearchType.placement:
          return [
            {
              title: 'global.xgrid.toolbar.archive',
              Icon: ArchiveOutlinedIcon,
              disabled: disableArchivePlacements,
              onClick: () => {
                dispatch(globalSearchActions.setBulkArchivePlacements({ dialogOpen: true }));
              },
              enableReadOnly: true,
            },
          ];
        default:
          return undefined;
      }
    }
  }, [
    activate,
    disableArchivePlacements,
    dispatch,
    enableBulkEmail,
    enableBulkSms,
    enableEmailCommunication,
    enableSmsCommunication,
    getValidEmailRowsWithEmail,
    handleBulkSms,
    handleBulkSmsDataFilter,
    handleEmailCreate,
    handleOrderEmailCreate,
    items.length,
    searchCategory,
    selectedData.length,
  ]);

  const rightActions = React.useMemo(() => {
    const disableUpdateOrderDates = selectedData.length < 1 || !enableUpdateHsgOrders;
    if (items.length > 0) {
      switch (searchCategory) {
        case SearchType.order:
          return [
            {
              id: 'search-map-view-button',
              Icon: MapOutlinedIcon,
              disabled: disableMapView,
              onClick: () => {
                window.open(`/mapresults/${resultMapView}`, '_blank');
              },
              tooltipProps: {
                tooltipContent: disableMapView ? t('global.xgrid.toolbar.mapTooltip') : 'Map',
              },
            },
            {
              id: 'verify-order-button',
              Icon: PublishedWithChangesIcon,
              disabled: disableVerifyOrders || bulkVerifyOrdersPromise,
              onClick: handleBulkVerifyOrders,
              tooltipProps: {
                tooltipContent: t('global.xgrid.toolbar.verifyOrders'),
              },
            },
            {
              id: 'update-order-dates-button',
              Icon: EditCalendarIconSVG,
              disabled: disableUpdateOrderDates,
              onClick: handleUpdateOrderDates,
              tooltipProps: {
                tooltipContent: 'Update Start and End Dates',
              },
            },
          ].filter(
            item =>
              !(
                (item.id === 'search-map-view-button' && !showMapView) ||
                (item.id === 'verify-order-button' &&
                  !Authorized(
                    [
                      userRoles.accountManagement,
                      userRoles.accountManagement_Leadership,
                      userRoles.accountManagement_TeamMember,
                      userRoles.supportAdmin,
                    ],
                    user.userInfo,
                  )) ||
                (item.id === 'update-order-dates-button' &&
                  !(
                    Authorized(
                      [
                        userRoles.accountManagement,
                        userRoles.accountManagement_Leadership,
                        userRoles.accountManagement_TeamMember,
                        userRoles.recruitment_Leadership,
                      ],
                      user.userInfo,
                    ) && user?.userInfo?.companyId === 14
                  ))
              ),
          );
        default:
          return undefined;
      }
    }
  }, [
    disableMapView,
    disableVerifyOrders,
    items.length,
    searchCategory,
    showMapView,
    t,
    selectedData.length,
    bulkVerifyOrdersPromise,
    enableUpdateHsgOrders,
  ]);

  const handleFetchData = React.useCallback(
    (
      offset: number,
      page: number,
      sortedColumn?: SortColumn | undefined | null,
      multiSortedColumns?: SortColumn[] | undefined | null,
    ) => {
      getSearchResults?.({
        pageNumber: page,
        pageSize: offset,
        sortModel: sortedColumn || gridState.sortedColumn,
        multiSortedColumns: multiSortedColumns || gridState.multiSortedColumns,
      });
    },
    [getSearchResults, gridState.sortedColumn, gridState.multiSortedColumns],
  );

  /** Enable or disable Archive Placements action button */
  React.useEffect(() => {
    if (searchCategory === SearchType.placement && selectedData?.length > 0) {
      dispatch(
        globalSearchActions.getArchivablePlacements({
          result: rows => {
            setDisableArchivePlacements(rows?.length === 0);
          },
        }),
      );
    } else {
      setDisableArchivePlacements(true);
    }

    if (searchCategory === SearchType.order && selectedData?.length > 0) {
      setDisableVerifyOrders(false);
    } else {
      setDisableVerifyOrders(true);
    }
  }, [dispatch, searchCategory, selectedData]);

  /** Show or Hide / Enable or disable Map View action button */
  React.useEffect(() => {
    if (searchCategory === SearchType.order) {
      setShowMapView(true);
      if (count == 0 || count > 500) {
        setDisableMapView(true);
        localStorage.removeItem(resultMapView);
      } else {
        setDisableMapView(false);
        const resultMapViewLocations: any = [];
        items?.forEach(item => {
          resultMapViewLocations.push({
            infoHtml: '<b>' + item?.facility + '</b> <br/>' + item?.city + ',' + item?.stateName,
            latitude: item?.facilityCoordinates?.latitude,
            longitude: item?.facilityCoordinates?.longitude,
          });
        });
        localStorage.removeItem(resultMapView);
        localStorage.setItem(resultMapView, JSON.stringify(resultMapViewLocations));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCategory, count]);

  /** Sub title calcuation */
  React.useEffect(() => {
    if (searchCategory === 'order') {
      let selectedCountData = positionCount;
      if (selectedData && selectedData.length > 0) {
        let selectedPositions = 0;
        for (let index = 0; index < selectedData.length; index++) {
          const selectedItem = selectedData[index];
          const selectedRowItems = items.filter(x => x.id === selectedItem);
          if (selectedRowItems && selectedRowItems.length > 0) {
            const position = selectedRowItems.map(x => x.positionsAvailable)[0];
            if (!isNaN(parseFloat(position))) {
              selectedPositions = selectedPositions + position;
            }
          }
        }
        selectedCountData = selectedPositions;
      }
      setSecondaryTitle(` / Positions (${selectedCountData ? formatToAmericanNotaion(selectedCountData) : 0})`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCategory, items, selectedData]);

  /** X-Grid Implementation */
  const [gridHeight, setGridHeight] = React.useState<number | undefined>(window.innerHeight / 1.9);

  const resizeGrid = React.useCallback(event => {
    updateGridHeight();
  }, []);

  React.useEffect(() => {
    updateGridHeight();
    window.addEventListener('resize', resizeGrid, true);

    return () => {
      window.removeEventListener('resize', resizeGrid);
    };
  }, [items, resizeGrid, filtersExpanded]);

  const updateGridHeight = () => {
    const date = moment();
    const timer = setInterval(() => {
      // Updates the height every 100 milliseconds, for 1.5s while any animation is occurring
      const c = document.getElementById('details-page-paper')?.offsetHeight;
      const d = getDetailsPageSupportComponentHeight();
      if (moment().subtract(1500, 'milliseconds').isBefore(date)) {
        if (c && d) {
          // -12 to account for grid padding
          setGridHeight(c - d - (document.getElementById('xgrid-title-container')?.offsetHeight ?? 0) - 12);
        }
      } else {
        // -12 to account for grid padding
        setGridHeight(c - d - (document.getElementById('xgrid-title-container')?.offsetHeight ?? 0) - 12);
        clearInterval(timer);
      }
    }, 100);
  };

  const saveSortModel = React.useCallback(
    sortModel => {
      const sortedColumn =
        sortModel?.length > 0
          ? {
              column: sortModel[0].field,
              direction: sortModel[0].sort,
            }
          : undefined;

      const multiSortedColumns = sortModel?.length > 0 ? formatMultiSortColumns(sortModel) : undefined;

      let newFilterSet: any = undefined;
      const i = (gridPreferences?.value?.filterAttributes?.filterSet || [])?.findIndex(
        item => item.name === gridPreferences?.value?.selectedFilter?.name,
      );

      if (
        !_.isEqual(
          sortedColumn,
          i !== -1 ? gridPreferences?.value?.filterAttributes?.filterSet[i]?.sortedColumn : gridState?.sortedColumn,
        ) ||
        !_.isEqual(
          multiSortedColumns,
          i !== -1
            ? gridPreferences?.value?.filterAttributes?.filterSet[i]?.multiSortedColumns
            : gridState?.multiSortedColumns,
        )
      ) {
        if (i !== -1) {
          newFilterSet = _.cloneDeep(gridPreferences?.value?.filterAttributes?.filterSet);
          newFilterSet[i].sortedColumn = sortedColumn;
          newFilterSet[i].multiSortedColumns = multiSortedColumns;
        }

        setSessionValue(
          SessionKey.tempColumnOrder,
          {
            ...getSessionValue(SessionKey.tempColumnOrder, StorageType.sessionStorage),
            sortedColumn: sortedColumn,
            columnVisibilityModel: columnVisibilityModel ?? defaultColumnVisibilityModel(),
            multiSortedColumns: multiSortedColumns,
          },
          StorageType.sessionStorage,
        );

        const preferenceData = {
          id: gridTag,
          value: {
            ...(gridPreferences?.value || {}),
            filterAttributes: {
              ...gridPreferences?.value?.filterAttributes,
              filterSet: newFilterSet ?? gridPreferences?.value?.filterAttributes?.filterSet,
            },
            // columns: currentGridColumns,
            sortedColumn: sortedColumn,
            multiSortedColumns: multiSortedColumns,
          },
        };
        dispatch(userDevicePreferenceActions.saveUserGridPreferenceAction(preferenceData));
      }
    },
    [
      columnVisibilityModel,
      defaultColumnVisibilityModel,
      dispatch,
      gridPreferences?.value,
      gridState?.sortedColumn,
      gridTag,
      gridState.multiSortedColumns,
    ],
  );

  const onSortModelChanged = React.useCallback(
    (model: GridSortModel, details: GridCallbackDetails) => {
      const sortedColumn =
        model?.length > 0
          ? {
              column: model[0].field,
              direction: model[0].sort,
            }
          : undefined;

      const multiSortedColumns = model?.length > 0 ? formatMultiSortColumns(model) : undefined;

      saveSortModel(model);
      setXGridSortModel(model);
      handleFetchData(gridState.pageSize, gridState.pageNumber, sortedColumn, multiSortedColumns);
    },
    [gridState.pageNumber, gridState.pageSize, handleFetchData, saveSortModel],
  );

  const onPageChange = React.useCallback(
    (page: number, details: GridCallbackDetails) => {
      handleFetchData(gridState.pageSize, page + 1, gridState.sortedColumn, xGridSortModel);
    },
    [gridState.sortedColumn, gridState.pageSize, handleFetchData],
  );

  const onPageSizeChange = React.useCallback(
    (pageSize: number, details: GridCallbackDetails) => {
      if (gridState.pageSize !== pageSize)
        handleFetchData(pageSize, gridState.pageNumber, gridState.sortedColumn, xGridSortModel);
    },
    [gridState.pageSize, gridState.pageNumber, gridState.sortedColumn, handleFetchData, xGridSortModel],
  );

  React.useEffect(() => {
    // Reset grid selection regardless of archive filter
    if (
      archivePlacementStatus === true &&
      (filters?.filters as any[])?.find(
        item => item[0] === 'archiveStatus' && (item[1]?.name === 'No' || item[1]?.name === 'Yes'),
      )
    ) {
      // Get new data when archive filter is applied
      apiRef?.current?.setSelectionModel([]);
      handleFetchData(gridState.pageSize, gridState.pageNumber, gridState.sortedColumn, xGridSortModel);
      dispatch(globalSearchActions.setBulkArchivePlacements({ dialogOpen: false, success: null, loading: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [archivePlacementStatus, dispatch, handleFetchData, xGridSortModel]);

  /** End X-Grid */
  const SearchXGrid = React.useMemo(() => {
    return (
      <div key={`grid-key-${refreshKey}`}>
        {initialState && (
          <XGrid
            initialState={initialState}
            columnVisibilityModel={columnVisibilityModel}
            apiRef={apiRef}
            loading={loading}
            gridTag={gridTag}
            rows={items ?? []}
            columns={columns}
            defaultColumns={defaultColumns}
            defaultSortModel={defaultSortModel}
            defaultColumnVisibilityModel={defaultColumnVisibilityModel()}
            disableMultipleColumnsSorting={false}
            autoHeight={false}
            height={gridHeight}
            rowsPerPageOptions={[250, 300, 400, 500]}
            defaultRowsPerPage={gridState.pageSize || 250}
            paginationMode="server"
            checkboxSelection
            sortingMode="server"
            rowCount={count > totalCountErrorThreshold ? totalCountErrorThreshold : count}
            rowCountDisplay={count}
            onPageChange={onPageChange}
            onSortModelChange={onSortModelChanged}
            onPageSizeChange={onPageSizeChange}
            title={gridTitle}
            secondaryTitle={secondaryTitle}
            sortModel={xGridSortModel}
            bannerIsVisible={globalBanner.open}
            // actionItems={actionItems}
            onColumnResizeStop={_columns => {
              if (globalThis.amieLogLevel === 1) {
                console.info('column resize visibility model: ', columnVisibilityModel);
              }
              savePreferences({
                field: undefined,
                columns: _columns,
                visibilityModel: _.cloneDeep(columnVisibilityModel ?? defaultColumnVisibilityModel()),
              });
            }}
            onColumnOrderChange={(params, event, details, _columns) => {
              if (globalThis.amieLogLevel === 1) {
                console.info('column order change visibility model: ', columnVisibilityModel);
              }
              savePreferences({
                field: params.field,
                columns: _columns,
                visibilityModel: _.cloneDeep(columnVisibilityModel ?? defaultColumnVisibilityModel()),
              });
            }}
            onColumnVisibilityModelChange={(model, details) => {
              trackEvent({
                type: 'event',
                name: 'Search Grid Column Visibility Change',
                properties: {
                  model,
                  currentModel: columnVisibilityModel,
                },
              });
              if (globalThis.amieLogLevel === 1) {
                console.info('column visibility model: ', model);
              }
              dispatch(gridStateActions.setColumnVisibilityModel(model));
              savePreferences({
                field: undefined,
                columns: _.cloneDeep(apiRef?.current?.getAllColumns()),
                visibilityModel: _.cloneDeep(model),
              });
              // BUG: There's a bug with DataGrid where if you make a column visible (previously hidden) then adjust the width of that same column,
              // This column visibility handler will be called. It only happens if you adjust the width of the same column that was just made visible.
              // This hack will maintain the visibility, but the width will be lost and have to be adjusted a second time, since this handler is called before
              // the onColumnResizeStop handler
              // setColumns(
              //   _.cloneDeep(columns).map(column => ({
              //     ...column,
              //     // hide: !model[column.field],
              //   })),
              // );
            }}
            toolbarProps={{
              refreshButton: true,
              filterButton: true,
              leftActions,
              rightActions,
              refreshGrid: () =>
                handleFetchData(gridState.pageSize, gridState.pageNumber, gridState.sortedColumn, xGridSortModel),
              resetColumns: () => {
                saveSortModel(new GlobalSearchSort(searchCategory).toGrid());
                savePreferences({
                  field: undefined,
                  columns: defaultColumns,
                  visibilityModel: defaultColumnVisibilityModel(),
                });
                dispatch(gridStateActions.setColumnVisibilityModel(defaultColumnVisibilityModel()));
                determineGridColumns(true);
                deternineSortModal(true);
                handleFetchData(
                  gridState.pageSize,
                  gridState.pageNumber,
                  new GlobalSearchSort(searchCategory).toAPI(),
                  xGridSortModel,
                );
                dispatch(
                  globalActions.setSnackBar({
                    message: 'Columns have been reset.',
                    severity: 'success',
                  }),
                );
              },
            }}
          />
        )}
        {!initialState && (
          <div className="center" style={{ width: '100%', height: '300px' }}>
            <CircularProgress />
          </div>
        )}
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    loading,
    items,
    columns,
    count,
    onPageChange,
    onSortModelChanged,
    onPageSizeChange,
    gridTitle,
    secondaryTitle,
    xGridSortModel,
    gridHeight,
    leftActions,
    defaultSortModel,
    rightActions,
  ]);

  return (
    <React.Fragment>
      {SearchXGrid}
      {searchCategory === SearchType.placement && <ArchivePlacementDialog />}
      {searchCategory === SearchType.order && (
        <UpdateOrderDatesModal
          open={openUpdateDatesModal}
          onClose={() => setOpenUpdateDatesModal(false)}
          handleFetch={(pageSize, pageNumber, sortedColumn) =>
            handleFetchData(pageSize, pageNumber, sortedColumn, xGridSortModel)
          }
          {...UpdateOrderDatesModalProps}
        />
      )}
      {searchCategory === SearchType.candidate && (
        <CommunicationLimitsDialog handleEmailRetry={handleEmailCreate} handleSMSRetry={handleBulkSms} />
      )}
    </React.Fragment>
  );
};
