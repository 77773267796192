import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import treeData from 'app/assets/jsons/activityFeedFilter.json';
import { Paper, Grid, Button, Popper, Typography } from 'amn-ui-core';
import Checkbox from '@mui/material/Checkbox';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { taskDetailsActions } from 'app/components/Tasks/store/Tasks.redux';
import { TaskTreeView } from 'app/components/Tasks/CustomComponents/TaskTreeView';
import { userDevicePreferenceActions } from 'oidc/UserDevicePreference/userPreference.redux';
import { RootState } from 'types';
import {
  INotificationPopperProps,
  defaultLogsPayload,
  filterLogic,
  mergePreferenceFilters,
  payloadWithFilters,
  updateUnreadFilter,
  updateUnreadFilterOption,
} from '../utils';
import { selectUser } from 'oidc/user.selectors';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { NotificationCategory } from 'app/models/Tasks/Tasks';

const useStyles = makeStyles()(theme => ({
  actionContainer: {
    backgroundColor: theme.palette.framework.system.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.framework.system.white,
    },
    // position: 'sticky',
    bottom: -8,
    width: '100%',
    borderTop: `1px solid ${theme.palette.disabled.main}`,
    padding: 5,
    zIndex: 10,
  },
  divider: {
    borderTop: `1px solid ${theme.palette.disabled.main}`,
  },
  button: {
    fontSize: 14,
    textTransform: 'none',
    '&:hover, &:focus': {
      background: 'none',
    },
  },
  clearAllButton: {
    color: theme.palette.components.button.secondary.backgroundColor,
    paddingRight: '15px',
  },
  cancelButton: {
    color: theme.palette.framework.system.tertiaryGrey,
  },
  countTag: {
    color: theme.palette.framework.system.doveGray,
    fontSize: 14,
    fontWeight: 600,
    paddingLeft: 10,
  },
  paper: {
    width: '230px',
    marginTop: '55px',
    marginLeft: '78px',
    overflow: 'hidden',
    borderRadius: '4px',
    boxShadow: '0px 2px 8px #0000004D',
  },
  childWrapper: {
    maxHeight: '300px',
    overflowY: 'auto',
    padding: '5px 12px',
  },
  filterPopup: {
    zIndex: '10'
  },
  filterPopupFixed: {
    position: 'fixed',
    right: '20px !important',
    left: 'auto !important',
    top: '40px !important',
    zIndex: '10'

  },
  checked: {
    color: `${theme.palette.framework.system.skyBlue} !important`,
  },
}));

export interface ITreeDataChild {
  name: string;
  value: string;
}

export interface ITreeDataParent {
  name: string;
  value: string;
  children: ITreeDataChild[];
}

export const ActivityFeedFilterV2 = ({
  openFeedFilter,
  setFeedFilter,
  selectedFeedData,
  activityFeedRefactoring,
}: {
  openFeedFilter: boolean;
  setFeedFilter: (e) => void;
  selectedFeedData: INotificationPopperProps;
  activityFeedRefactoring: boolean;
}) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const boxRef = React.useRef<any>();
  const checkedUnreadOption = useRef<boolean>(true);
  const [existingFilter, setExistingFilters] = useState<any[]>([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [notificationFilters, setnotificationFilters] = useState<ITreeDataParent[]>([]);
  const [filterCount, setFilterCount] = useState<string[]>([]);
  const filterPreferencesId = 'Activity-Feed-Filter';
  const user = useSelector(selectUser);
  const filterPreference = useSelector((state: RootState) => {
    return state.userPreferenceData.userGridPreferences.find(item => item.id === filterPreferencesId);
  });
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const clearAll = () => {
    checkedUnreadOption.current = false;
    const clearedFilteredData = [
      {
        children: [],
        name: NotificationCategory.Unread,
        value: false,
        id: `${selectedFeedData?.currentPopper}-id`,
      },
    ];
    setSelectedFilters(clearedFilteredData);
    dispatch(taskDetailsActions.setIsFilterApplied(false));
  };

  const onChange = val => {
    setSelectedFilters(val);
  };

  const onSelectionChange = () => {
    checkedUnreadOption.current = !checkedUnreadOption.current;
    let result = selectedFilters ?? [];
    const isUnreadFilterExists = selectedFilters?.find(
      x => x?.name === NotificationCategory.Unread && x?.id === `${selectedFeedData?.currentPopper}-id`,
    );
    if (!isUnreadFilterExists) {
      result.push({
        children: [],
        name: NotificationCategory.Unread,
        value: checkedUnreadOption.current,
        id: `${selectedFeedData?.currentPopper}-id`,
      });
    } else {
      result = selectedFilters?.map(filter => {
        if (filter?.name === NotificationCategory.Unread && filter?.id === `${selectedFeedData?.currentPopper}-id`) {
          return { ...filter, value: checkedUnreadOption.current };
        } else {
          return filter;
        }
      });
    }

    setSelectedFilters(result);
  };

  const cancelChanges = () => {
    setFeedFilter(false);
    setSelectedFilters(existingFilter);
  };

  const setFilterData = () => {
    const currentFilterOptions = treeData.filter(tree => {
      return filterLogic(tree, selectedFeedData?.currentPopper, true);
    });

    setnotificationFilters(activityFeedRefactoring ? currentFilterOptions : treeData);
    const appliedFilters = filterPreference?.value.filtersApplied;
    let hasNonEmptyObjects = appliedFilters?.some(obj => Object.keys(obj).length > 0);
    dispatch(taskDetailsActions.setIsFilterApplied(hasNonEmptyObjects));
    if (hasNonEmptyObjects) {
      const appliedFiltersPerFeed = appliedFilters.filter(filter => {
        return filterLogic(filter, selectedFeedData?.currentPopper, false);
      });
      setFeedFilter(false);
      const unreadDataOption = updateUnreadFilter(
        appliedFilters,
        appliedFiltersPerFeed,
        selectedFeedData?.currentPopper,
      );
      checkedUnreadOption.current = unreadDataOption;
      setExistingFilters(activityFeedRefactoring ? appliedFiltersPerFeed : appliedFilters);
      setSelectedFilters(activityFeedRefactoring ? appliedFiltersPerFeed : appliedFilters);
    } else {
      if (activityFeedRefactoring) {
        const appliedFiltersPerFeed = [];
        updateUnreadFilterOption(appliedFiltersPerFeed, selectedFeedData?.currentPopper);
        checkedUnreadOption.current = true;
        setExistingFilters(appliedFiltersPerFeed);
        setSelectedFilters(appliedFiltersPerFeed);
      }
    }
  };

  useEffect(() => {
    setFilterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFeedData?.currentPopper]);

  useEffect(() => {
    let isFilterDataApplied = true;
    const count: string[] = [];
    selectedFilters?.forEach(a => {
      if (a?.children?.length > 0) {
        count.push(...a.children?.map(z => z.value));
      }
    });
    if (count?.length > 0) {
      isFilterDataApplied = true;
    } else {
      isFilterDataApplied = false;
    }
    if (
      activityFeedRefactoring &&
      selectedFilters &&
      selectedFilters?.find(
        alert =>
          alert?.name === NotificationCategory.Unread &&
          alert?.id === `${selectedFeedData?.currentPopper}-id` &&
          alert?.value,
      )
    ) {
      isFilterDataApplied = true;
      count.push(`${selectedFeedData?.currentPopper}-id`);
    }
    setFilterCount(count);
    dispatch(taskDetailsActions.setIsFilterApplied(isFilterDataApplied));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters]);

  const SaveFiltersPreference = selectedFiltersArg => {
    const appliedFilters = filterPreference?.value.filtersApplied;
    const notificationFilterPreference = activityFeedRefactoring
      ? mergePreferenceFilters(appliedFilters, selectedFiltersArg, selectedFeedData?.currentPopper)
      : selectedFiltersArg;

    const preferenceData = {
      id: filterPreferencesId,
      value: {
        filtersApplied: notificationFilterPreference,
      },
    };
    selectedFiltersArg && dispatch(userDevicePreferenceActions.saveUserGridPreferenceAction(preferenceData));
  };
  const getActivityFeedLog = selectedFilterData => {
    dispatch(
      taskDetailsActions.getNotificationLogData(
        selectedFilterData?.length > 0
          ? payloadWithFilters(selectedFilterData, selectedFeedData?.currentPopper, activityFeedRefactoring, user, 1)
          : defaultLogsPayload(
            { type: [], subTypes: [] },
            selectedFeedData?.currentPopper,
            user,
            activityFeedRefactoring,
            1,
          ),
      ),
    );
  };

  const OnApplyFilter = () => {
    dispatch(taskDetailsActions.setActivityFeedFilter(selectedFilters));
    setFeedFilter(false);
    setExistingFilters(selectedFilters);
    SaveFiltersPreference(selectedFilters);
    getActivityFeedLog(selectedFilters);
  };

  return (
    <div>
      <React.Fragment>
        <Popper
          anchorEl={!activityFeedRefactoring ? boxRef.current : null}
          role={undefined}
          transition
          disablePortal
          open={openFeedFilter}
          className={!activityFeedRefactoring ? classes.filterPopup : classes.filterPopupFixed}
          modifiers={[
            {
              options: {
                offset: {
                  enabled: true,
                },
              },
            },
          ]}
        >
          <Paper elevation={0} className={classes.paper}>
            <Grid
              item
              container
              justifyContent={'space-between'}
              alignItems="center"
              style={{ padding: 6, minWidth: 240 }}
            >
              <div className={classes.countTag}>{`${filterCount && filterCount.length > 0 ? `${filterCount?.length}` : 0
                } ${t('search.filter.Selected')}`}</div>
              <Button
                className="clear-all-button"
                aria-label="clear-all-button"
                variant="text"
                disableRipple
                disableFocusRipple
                disableTouchRipple
                onClick={clearAll}
                classes={{ text: ` ${classes.button} ${classes.clearAllButton}` }}
              >
                {t('search.filter.clearAll')}
              </Button>
            </Grid>
            <div>
              <div className={classes.childWrapper}>
                {activityFeedRefactoring && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <Checkbox
                      id={`${selectedFeedData?.currentPopper}-id`}
                      size="small"
                      icon={icon}
                      checkedIcon={checkedIcon}
                      classes={{ checked: classes.checked }}
                      onChange={() => onSelectionChange()}
                      checked={
                        !!selectedFilters?.find(
                          x =>
                            x?.name === NotificationCategory.Unread &&
                            x?.id === `${selectedFeedData?.currentPopper}-id` &&
                            x?.value,
                        ) && checkedUnreadOption.current
                      }
                    />
                    <Typography style={{ fontSize: '14px' }}>{t(NotificationCategory.Unread)}</Typography>
                  </div>
                )}
              </div>
              <div className={notificationFilters?.length > 0 && classes.divider}></div>
              <div className={classes.childWrapper}>
                <TaskTreeView
                  options={notificationFilters}
                  onChange={onChange}
                  value={selectedFilters}
                  size={'large'}
                  defaultExpanded={['1-Task', '2-Cue Note', '3-Email Status', '4-Order Match']}
                  isAlternateDisabled={false}
                />
              </div>
            </div>
            <div className={classes.actionContainer}>
              <Grid item container justifyContent="flex-end" alignItems="center">
                <Button
                  className="clear-all-button"
                  aria-label="apply-button"
                  variant="text"
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                  onClick={() => OnApplyFilter()}
                  classes={{ text: ` ${classes.button} ${classes.clearAllButton}` }}
                >
                  {t('search.filter.apply')}
                </Button>
                <Button
                  className="clear-all-button"
                  aria-label="cancel-button"
                  variant="text"
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                  onClick={() => cancelChanges()}
                  classes={{ text: ` ${classes.button} ${classes.cancelButton}` }}
                >
                  {t('search.filter.cancel')}
                </Button>
              </Grid>
            </div>
          </Paper>
        </Popper>
      </React.Fragment>
    </div>
  );
};
