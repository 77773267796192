/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { gridStateActions } from 'app/components/Common/Grid/GridStateManagement/GridState.redux';
import React from 'react';
import { createRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import OrderMatchChips from './OrderMatchChips';
import { OrderMatches } from './OrderMatches';
import OrderMatchesFilters from './OrderMatchesFilters';
import { selectCandidateOrderMatchId, selectPayload } from '../store/candidateOrderMatch.selector';
import { cloneDeep } from 'lodash';
import { getCandidateOrderMatchActions, initialFilterChips } from '../store/candidateOrderMatch.redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import SearchGridWrapper from 'app/layout/pages/SearchGridWrapper';
import moment from 'moment';
import { getFilterRequest, revalidateChipSelection } from '@AMIEWEB/GlobalSearch/helper';
import { selectJobPreferencesData, selectPreferenceId } from '../../JobPreferencesTab/store/JobPreferences.selectors';
import { selectFilters, selectGridState } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.selectors';
import { userDevicePreferenceActions } from 'oidc/UserDevicePreference/userPreference.redux';
import { RootState } from 'types';
import { useParams } from 'react-router-dom';
import { OrderMatchFlterName } from './OrderMatchFilterUtils';
import {
  ICandidateSkillSet,
  getSelectedSkillSetFilter,
  getSkillSetFilter,
} from 'app/ComponentLibrary/Filter/CandidateSkillset';
import { ORDER_PREFERENCE_SET } from '@AMIEWEB/Notification/Tasks/Common_v2/utils';
import { jobPreferncesActions } from '../../JobPreferencesTab/store/JobPreferences.redux';

interface Item {
  value?: any;
}

export function getFilterValues(items: Item[] | undefined): any[] {
  const appliedEntries = items?.map(item => item?.value) ?? [];
  return appliedEntries?.filter(item => item !== 'All');
}
export function getStateFilterValues(items: Item[] | undefined): any[] {
  const values = items?.map(item => item.value) ?? [];
  const stateValues = values.filter(value => typeof value === 'string');
  return stateValues.filter(value => value !== 'All');
}
export const OrderMatchesWrapper = props => {
  const formMethods = useForm();
  const { handleSubmit, setValue } = formMethods;
  const hiddenSubmitButton = createRef<HTMLButtonElement>();
  const dispatch = useDispatch();
  const payload = useSelector(selectPayload);
  const { t } = useTranslation();
  const { jobPreferencesData } = useSelector(selectJobPreferencesData);
  let prefId = useSelector(selectCandidateOrderMatchId);
  const orderPreferenceId = useSelector(selectPreferenceId);
  const appliedFilterValues = useSelector(selectFilters);
  const { travelerId: candidateId } = useParams<{ travelerId: string; brandId: string }>();
  const [filterKey] = React.useState<any>(`candidate-${candidateId}`);
  const filterPreference = useSelector((state: RootState) => {
    return state.userPreferenceData.userGridPreferences.find(item => item.id === filterKey);
  });
  const [preferencesLoaded, setPreferencesLoaded] = React.useState<boolean>(false);
  const [rendered, setRendered] = React.useState<boolean>(false);
  const gridState = useSelector(selectGridState);
  const orderPreferenceSet = JSON.parse(localStorage.getItem(ORDER_PREFERENCE_SET));

  React.useEffect(() => {
    if (orderPreferenceSet) {
      dispatch(getCandidateOrderMatchActions.setId(undefined));
      dispatch(jobPreferncesActions.setPreferenceId(orderPreferenceSet?.PreferenceId));
      dispatch(
        jobPreferncesActions.getJobPreferencesAction({
          travelerId: orderPreferenceSet.CandidateId,
          brandId: orderPreferenceSet.BrandId,
        }),
      );
      localStorage.removeItem(ORDER_PREFERENCE_SET);
    }
  }, [orderPreferenceSet]);

  React.useEffect(() => {
    dispatch(gridStateActions.setGrid('CandidateOrderMatch'));
    setRendered(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      dispatch(gridStateActions.reset());
      dispatch(gridStateActions.setFilterAttributes({ filterObject: null, selectedChipFilters: null }));
    };
  }, []);

  const getBillRateRange = (defaultBillRate, appliedBillRate) => {
    let billRate = {
      min: undefined,
      max: undefined,
    };
    if (appliedBillRate?.min > defaultBillRate?.max || appliedBillRate?.max < defaultBillRate?.min) {
      billRate = { ...appliedBillRate };
    } else {
      if (appliedBillRate?.min > defaultBillRate?.min) {
        if (appliedBillRate?.min < defaultBillRate?.max) {
          billRate.min = appliedBillRate?.min;
        } else {
          billRate.min = defaultBillRate?.min;
        }
      } else {
        billRate.min = defaultBillRate?.min;
      }

      if (appliedBillRate?.max < defaultBillRate?.max) {
        if (!(appliedBillRate?.max < defaultBillRate?.min)) {
          billRate.max = appliedBillRate?.max;
        } else {
          billRate.max = defaultBillRate?.max;
        }
      } else {
        billRate.max = defaultBillRate?.max;
      }
    }

    if (!appliedBillRate?.min) {
      billRate.min = defaultBillRate?.min;
    }
    if (!appliedBillRate?.max) {
      billRate.max = defaultBillRate?.max;
    }
    return billRate;
  };

  const getStartDateRange = (preferenceDateRange, appliedFilterDateRange) => {
    let orderStartDateRange = {
      startDate: appliedFilterDateRange.startDate,
      endDate: appliedFilterDateRange.endDate,
    };

    if (moment(appliedFilterDateRange.startDate).format() < moment(preferenceDateRange.startDate).format())
      orderStartDateRange.startDate = preferenceDateRange.startDate;

    if (moment(appliedFilterDateRange.endDate).format() > moment(preferenceDateRange.endDate).format())
      orderStartDateRange.endDate = preferenceDateRange.endDate;

    return orderStartDateRange;
  };

  type MinMaxRange = {
    min: number | undefined;
    max: number | undefined;
  };

  const getMinMaxRange = (defaultMinMaxRange: MinMaxRange, appliedMinMaxRange: MinMaxRange): MinMaxRange => {
    if (
      !(
        appliedMinMaxRange.min === undefined ||
        appliedMinMaxRange.max === undefined ||
        defaultMinMaxRange.min === undefined ||
        defaultMinMaxRange.max === undefined
      )
    ) {
      const min = Math.max(appliedMinMaxRange.min, defaultMinMaxRange.min);
      const max = Math.min(appliedMinMaxRange.max, defaultMinMaxRange.max);

      if (min > max) {
        return appliedMinMaxRange;
      }
      return { min, max };
    }
  };
  //EVENTS
  const onApplyFilter = async (values, newFilterChips?) => {
    let requestPayload;
    let orderMatchId;

    if (orderPreferenceId) {
      orderMatchId = orderPreferenceId;
    } else {
      orderMatchId = prefId;
    }

    if (payload) {
      requestPayload = cloneDeep(payload);
    }

    // const selectedChipFilters =
    //   newFilterChips || revalidateChipSelection(values, gridState.filterAttributes?.selectedChipFilters || []);

    dispatch(
      gridStateActions.setFilterAttributes({
        filterObject: Object.entries(values),
        selectedChipFilters: newFilterChips,
      }),
    );
    dispatch(gridStateActions.setPageAttributes({ pageSize: 250, pageNumber: 1 }));
    const guaranteedHours = { min: values.guaranteedHours?.value || 0, max: values.guaranteedHours?.value || 0 };
    const expectedHours = { min: values.expectedHours?.value || 0, max: values.expectedHours?.value || 0 };
    const weeklyGrossPay = { min: values.weeklyGrossPay?.value || 0, max: values.weeklyGrossPay?.value || 0 };

    const billrate = {
      min: values.billrate?.min || 0,
      max: values.billrate?.max || 0,
    };

    const orderStartDateRange = {
      startDate: values.orderStartDateRange?.startDate,
      endDate: values.orderStartDateRange?.endDate,
    };

    let appliedFilter = getFilterRequest(values, newFilterChips);

    const selectedChipFilters =
      newFilterChips || revalidateChipSelection(values, gridState.filterAttributes?.selectedChipFilters || []);
    const orderkillSetFilter = values[OrderMatchFlterName.skillsetFilter];
    const selectedSkillSetChips = selectedChipFilters?.[OrderMatchFlterName.skillsetFilter];

    let skillsets: ICandidateSkillSet[] = [];
    if (selectedSkillSetChips && selectedSkillSetChips?.length > 0) {
      skillsets = getSelectedSkillSetFilter(
        orderkillSetFilter?.skillsets,
        selectedSkillSetChips,
        OrderMatchFlterName.skillsetFilter,
      );
    } else {
      skillsets = getSkillSetFilter(orderkillSetFilter?.skillsets, OrderMatchFlterName.skillsetFilter);
    }

    appliedFilter = {
      ...appliedFilter,

      orderBillRate:
        requestPayload?.orderBillRate && values.billrate
          ? getBillRateRange(requestPayload?.orderBillRate || { min: 0, max: 0 }, billrate)
          : requestPayload?.orderBillRate && !values.billrate
          ? requestPayload?.orderBillRate
          : values.billrate,

      guaranteedHours:
        requestPayload?.guaranteedHours && values.guaranteedHours
          ? getMinMaxRange(requestPayload?.guaranteedHours || { min: 0, max: 0 }, guaranteedHours)
          : requestPayload?.guaranteedHours && !values.guaranteedHours
          ? requestPayload?.guaranteedHours
          : values.guaranteedHours,

      expectedHours:
        requestPayload?.expectedHours && values.expectedHours
          ? getMinMaxRange(requestPayload?.expectedHours || { min: 0, max: 0 }, expectedHours)
          : requestPayload?.expectedHours && !values.expectedHours
          ? requestPayload?.expectedHours
          : values.expectedHours,

      weeklyGrossPay:
        requestPayload?.weeklyGrossPay && values.weeklyGrossPay
          ? getMinMaxRange(requestPayload?.weeklyGrossPay || { min: 0, max: 0 }, weeklyGrossPay)
          : requestPayload?.weeklyGrossPay && !values.weeklyGrossPay
          ? requestPayload?.weeklyGrossPay
          : values.weeklyGrossPay,

      orderStartDateRange:
        requestPayload?.orderStartDateRange && values.orderStartDateRange
          ? getStartDateRange(requestPayload?.orderStartDateRange, orderStartDateRange)
          : requestPayload?.orderStartDateRange && !values.orderStartDateRange
          ? requestPayload?.orderStartDateRange
          : values.orderStartDateRange,

      orderSkillsetFilter: {
        allowableMismatch: orderkillSetFilter?.allowableMismatch || false,
        isVerifiedOnly: undefined,
        operation: orderkillSetFilter?.operation || 'Or',
        skillsets: skillsets && skillsets.length === 0 ? null : skillsets,
        isExactMatch: orderkillSetFilter?.isExactMatch || false,
      },
    };

    if (_.isEqual(values, initialFilterChips)) {
      dispatch(getCandidateOrderMatchActions.setSelectedFilters(null));
    } else {
      dispatch(getCandidateOrderMatchActions.setSelectedFilters(appliedFilter));
    }
    if (
      requestPayload?.orderBillRate &&
      appliedFilter.orderBillRate &&
      (requestPayload?.orderBillRate?.max < appliedFilter.orderBillRate?.min ||
        requestPayload?.orderBillRate?.min > appliedFilter.orderBillRate.max)
    ) {
      dispatch(
        getCandidateOrderMatchActions.setData({
          items: [],
          count: 0,
        }),
      );
    } else {
      requestPayload = {
        ...requestPayload,
        ...appliedFilter,
      };
      const data = jobPreferencesData?.filter(item => item?.id === orderMatchId)?.[0];
      dispatch(
        getCandidateOrderMatchActions.getCandidateOrderMatchAction({
          ...requestPayload,
          pageNumber: 1,
          pageSize: 250,
          locations: data?.locations,
          translation: t,
          keyword: '',
        }),
      );
    }
    if (rendered && !prefId) {
      saveFilterPreferences(values, newFilterChips);
    }
  };

  const saveFilterPreferences = (values: any, newChipSelections?: any) => {
    const filters = values || appliedFilterValues?.filters;

    const preferenceData = {
      id: filterKey,
      value: {
        filtersApplied: filters,
        selectedChipFilters: newChipSelections,
      },
    };
    filters && dispatch(userDevicePreferenceActions.saveUserGridPreferenceAction(preferenceData));
  };
  React.useEffect(() => {
    const appliedFilters = filterPreference?.value?.filtersApplied;
    const selectedChips = filterPreference?.value?.selectedChipFilters;
    if (appliedFilters) {
      if (!preferencesLoaded) {
        setPreferencesLoaded(true);
        if (!!appliedFilters) {
          Object.keys(appliedFilters).forEach(key => {
            setValue(key, appliedFilters[key]);
          });
          onApplyFilter(appliedFilters, undefined);
          dispatch(
            gridStateActions.setFilterAttributes({
              filterObject: Object.entries(appliedFilters),
              selectedChipFilters: selectedChips,
            }),
          );
        }
      }
    }
  }, [filterPreference]);

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(filter => onApplyFilter(filter))}>
          <SearchGridWrapper
            filters={<OrderMatchesFilters onApplyFilter={onApplyFilter} divisions={props.divisions} />}
            table={<OrderMatches candidateId={candidateId} />}
            chips={<OrderMatchChips onApplyFilter={onApplyFilter} saveFilterPreferences={saveFilterPreferences} />}
            loading={false}
            tableMinHeight={538}
          />
          <button ref={hiddenSubmitButton} style={{ display: 'none' }} type="submit" />
        </form>
      </FormProvider>
    </>
  );
};
