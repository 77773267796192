import React from 'react';
import { ClickAwayListener, MenuItem, MenuList, Paper, Popper } from 'amn-ui-core';
import { ORDER_PREFERENCE_SET, getEmployeeId, getNotificationType, markNotificationAsRead } from './utils';
import { useDispatch } from 'react-redux';
import { taskDetailsActions } from '@AMIEWEB/Tasks/store/Tasks.redux';

export const RenderOptionsPopper = props => {
  const {
    args: {
      anchorEl2,
      handleClose,
      notifyData,
      classes,
      setAnchorEl2,
      record,
      closeFeed,
      t,
      history,
      pageNumber,
      userInfo,
      activityFeedRefactoringFlag,
      isUnread,
      pushNotification = false,
    },
  } = props;
  const { id, useType, useSubType, channel } = notifyData?.contentProps;
  const dispatch = useDispatch();

  const handleMarkAsRead = () => {
    if (activityFeedRefactoringFlag) {
      const notificationCategory = getNotificationType({
        useType,
        useSubType,
        channelType: channel,
      });
      //Diasables the  service to update new data, only updates count
      const disableGetActivityFetch = true;
      markNotificationAsRead(
        [id],
        parseInt(getEmployeeId(userInfo)),
        dispatch,
        notificationCategory,
        pageNumber,
        isUnread,
        disableGetActivityFetch,
      );
      pushNotification && dispatch(taskDetailsActions.setDismissNotificationId(id));
    }
  };
  return (
    <Popper open={Boolean(anchorEl2)} anchorEl={anchorEl2} role={undefined} disablePortal className={classes.menu}>
      <ClickAwayListener onClickAway={handleClose}>
        <Paper className={classes.paperMenu}>
          <MenuList
            onClick={e => {
              setAnchorEl2(null);
              e.stopPropagation();
            }}
          >
            {activityFeedRefactoringFlag && parseInt(record?.CandidateId) > 0 && record?.PreferenceSetName && (
              <MenuItem
                className={classes.menuItem}
                onClick={() => {
                  handleMarkAsRead();
                  localStorage.setItem(ORDER_PREFERENCE_SET, JSON.stringify(record));
                  const path = history.createHref({
                    pathname: `/candidate/${record?.CandidateId}/${record?.BrandId}?tab=6`,
                  });
                  window.open(path, '_blank');
                  closeFeed();
                }}
              >
                {t('notification.taskNotification.PreferenceSet')}
              </MenuItem>
            )}
            {parseInt(record?.PlacementId) > 0 && (
              <MenuItem
                className={classes.menuItem}
                onClick={() => {
                  if (!activityFeedRefactoringFlag) {
                    history.push(`/placement/${record?.PlacementId}`);
                  } else {
                    handleMarkAsRead();
                    (() => {
                      const path = history.createHref({ pathname: `/placement/${record?.PlacementId}` });
                      window.open(path, '_blank');
                    })();
                    closeFeed();
                  }
                }}
              >
                {activityFeedRefactoringFlag
                  ? t('notification.taskNotification.placementOption')
                  : t('notification.taskNotification.placement')}
              </MenuItem>
            )}

            {parseInt(record?.CandidateId) > 0 && (
              <MenuItem
                className={classes.menuItem}
                onClick={() => {
                  if (!activityFeedRefactoringFlag) {
                    history.push(`/candidate/${record?.CandidateId}/${record?.BrandId || '1'}`);
                  } else {
                    handleMarkAsRead();
                    (() => {
                      const path = history.createHref({
                        pathname: `/candidate/${record?.CandidateId}/${record?.BrandId || '1'}`,
                      });
                      window.open(path, '_blank');
                    })();
                  }
                  closeFeed();
                }}
              >
                {activityFeedRefactoringFlag
                  ? t('notification.taskNotification.CandidateOption')
                  : t('notification.taskNotification.candidate')}
              </MenuItem>
            )}

            {parseInt(record?.OrderId) > 0 && (
              <MenuItem
                className={classes.menuItem}
                onClick={() => {
                  if (!activityFeedRefactoringFlag) {
                    history.push(`/order/${record?.OrderId}`);
                  } else {
                    handleMarkAsRead();
                    (() => {
                      const path = history.createHref({ pathname: `/order/${record?.OrderId}` });
                      window.open(path, '_blank');
                    })();
                  }
                  closeFeed();
                }}
              >
                {activityFeedRefactoringFlag
                  ? t('notification.taskNotification.OrderOption')
                  : t('notification.taskNotification.order')}
              </MenuItem>
            )}

            {(parseInt(record?.FacilityId) > 0 || parseInt(record?.FaciltyId) > 0) && (
              <MenuItem
                className={classes.menuItem}
                onClick={() => {
                  if (!activityFeedRefactoringFlag) {
                    history.push(`/facility/${record?.FacilityId ?? record?.FaciltyId}`);
                  } else {
                    handleMarkAsRead();
                    (() => {
                      const path = history.createHref({
                        pathname: `/facility/${record?.FacilityId ?? record?.FaciltyId}`,
                      });
                      window.open(path, '_blank');
                    })();
                  }
                  closeFeed();
                }}
              >
                {activityFeedRefactoringFlag
                  ? t('notification.taskNotification.facilityOption')
                  : t('notification.taskNotification.facility')}
              </MenuItem>
            )}
            {activityFeedRefactoringFlag &&
              (parseInt(record?.FacilityId) > 0 || parseInt(record?.FaciltyId) > 0) &&
              parseInt(record?.UnitId) > 0 && (
                <MenuItem
                  className={classes.menuItem}
                  onClick={() => {
                    handleMarkAsRead();
                    const path = history.createHref({
                      pathname: `/facility/${record?.FacilityId ?? record?.FaciltyId}/unit/${record?.UnitId}`,
                    });
                    window.open(path, '_blank');
                    closeFeed();
                  }}
                >
                  {t('notification.taskNotification.UnitOption')}
                </MenuItem>
              )}
          </MenuList>
        </Paper>
      </ClickAwayListener>
    </Popper>
  );
};
