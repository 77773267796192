/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { SingleLineInput } from '../Common/SingleLineInput';
import { SimpleDropdown } from '../Common/SimpleDropdown';
import { ThemeProvider, StyledEngineProvider, Grid, InputAdornment, FormControl, InputLabel,useTheme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { UnitCardsTitle, UnitDetailsBody } from '../UnitDesign';
import { useTranslation } from 'react-i18next';
import { getNurse, getPatient } from 'app/components/Order/OrderCreationForm/DefineUnit/Helper';
import { Controller, useFormContext } from 'react-hook-form';

interface Props {
  //unit: NewUIOrder;
  //onUnitPropChange: (property: string, value: any) => void;
  disableEdit?: boolean;
}

const useStyles = makeStyles()((theme) => ({
  titleRightPadding: {
    paddingRight: 23,
  },
  adornedRoot: {
    backgroundColor: '#fff',
    border: 'thin #e7e2e2 solid',
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&.Mui-focused': {
      backgroundColor: '#fff',
    },
  },
  textareastyle: {
    backgroundColor: '#fff !important',
    // border: 'thin #e7e2e2 solid !important',
    '& label + div': {
      backgroundColor: '#fff !important',
    },
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&:focus': {
      backgroundColor: '#fff',
    },
    '&.Mui-focused': {
      backgroundColor: '#fff',
    },
  },
}));

export const NewNurseToPatientRatio = (props: Props) => {
  const { disableEdit } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const theme= useTheme();

  const { control, watch } = useFormContext();
  const watchValue = watch('nurseRatio');

  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  /*if (event.target.name === 'fyreIntegration')
      return onUnitPropChange(event.target.name, event.target.value === 'Yes');
    onUnitPropChange(event.target.name, event.target.value);*/
  // };

  return (
    <Grid item>
      {/* TODO: Remove this theme wrapper since it uses the global theme */}
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <UnitDetailsBody variant="outlined">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <UnitCardsTitle className={classes.titleRightPadding}>
                  {t('order.createUnit.nursePatientRatio.title')}
                </UnitCardsTitle>
              </Grid>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name={'nurse'}
                  render={({ ref, onChange, ...rest }) => (
                    <SingleLineInput
                      id={'createUnitNursePatientRatioNurseTxt'}
                      inputValue={rest.value ? parseInt(rest.value) : watchValue ? parseInt(getNurse(watchValue)) : ''}
                      inputName={'nurse'}
                      inputDefaultValue={''}
                      isdisabled={disableEdit}
                      inputLabel={t('order.createUnit.nursePatientRatio.nurse')}
                      handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        onChange(event.target.value);
                      }}
                      inputType={'number'}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment style={{ backgroundColor: 'red !important' }} position="start">
                            {'%'}
                          </InputAdornment>
                        ),
                      }}
                    ></SingleLineInput>
                  )}
                />
              </Grid>
              <Grid item xs={6} className={disableEdit ? '' : classes.textareastyle}>
                <Controller
                  control={control}
                  name={'patient'}
                  render={({ ref, onChange, ...rest }) => (
                    <SingleLineInput
                      id={'createUnitNursePatientRatioPatientTxt'}
                      inputValue={
                        rest.value ? parseInt(rest.value) : watchValue ? parseInt(getPatient(watchValue)) : ''
                      }
                      inputName={'patient'}
                      inputDefaultValue={''}
                      isdisabled={disableEdit}
                      inputLabel={t('order.createUnit.nursePatientRatio.patient')}
                      handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        onChange(event.target.value);
                      }}
                      inputType={'number'}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment className={classes.adornedRoot} position="start">
                            {'%'}
                          </InputAdornment>
                        ),
                      }}
                    ></SingleLineInput>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth variant="filled">
                  <InputLabel>{`FYRE integration`}</InputLabel>
                  <Controller
                    control={control}
                    name={'fyreIntegration'}
                    render={({ ref, onChange, ...rest }) => (
                      <SimpleDropdown
                        id={'createUnitNursePatientRatioFyreIntegrationDropDown'}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          onChange(event.target.value === 'Yes');
                        }}
                        defaultInputList={['Yes', 'No']}
                        disabled={disableEdit}
                        defaultInputValue={rest.value === true ? 'Yes' : 'No'}
                        inputName={'fyreIntegration'}
                        inputPaddingLeft="2%"
                        marginLeft={'0px'}
                        minWidth={'330px !important'}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth variant="filled">
                  <InputLabel>{t('order.createUnit.nursePatientRatio.status')}</InputLabel>
                  <Controller
                    control={control}
                    name={'Status'}
                    render={({ ref, onChange, ...rest }) => (
                      <SimpleDropdown
                        id={'createUnitNursePatientRatioActiveStatusDropDown'}
                        onChange={() => {}}
                        defaultInputList={['Active']}
                        defaultInputValue={'Active'}
                        inputName={'Status'}
                        inputPaddingLeft="2%"
                        marginLeft={'0px'}
                        disabled={disableEdit}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </UnitDetailsBody>
        </ThemeProvider>
      </StyledEngineProvider>
    </Grid>
  );
};
