import { Divider, Grid, IconButton, Typography, Paper, Tab, Tabs, Avatar, useTheme, Button, Box } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ClearIcon from '@mui/icons-material/Clear';
import { DrawerFitted } from 'app/components/Common/Drawer/Drawer';
import { LayoutGridItem } from 'app/layout/LayoutGrid';
import React, { useState, useEffect, useRef, useMemo } from 'react';
import PassportDisabled from 'app/assets/images/CandidateHeader/Passport-disabled.svg';
import PassportEnabled from 'app/assets/images/CandidateHeader/Passport-enabled.svg';
import { Concatenate } from 'utils/string/string';
import { CustomTooltip } from 'app/components/Common/Tooltips';
import BusinessIcon from '@mui/icons-material/Business';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import ScheduleIcon from '@mui/icons-material/Schedule';
import EditIcon from 'app/assets/images/Credentialing/PersonalInfo/editIcon.svg';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  useEnableEmailCommunication,
  useEnableSmsCommunication,
  useEnableVoiceCommunication,
} from 'oidc/CommunicationEnabler';
import { useDecision } from '@optimizely/react-sdk';
import { ff_candidate_tags } from 'app/constants/FeatureFlags/Candidate/Keys';
import { missingField } from 'app/constants';
import { selectUser } from 'oidc/user.selectors';
import { useDispatch, useSelector } from 'react-redux';
import {
  candidateDetailsSelection,
  selectChannelValues,
  selectDefaultChannels,
} from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import {
  candidateDetailActions,
  loadUserLogs,
} from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.redux';
import { notificationSelection } from 'store/redux-store/notification/notification.selector';
import { useTranslation } from 'react-i18next';
import { isStrikOrder } from 'utils/common/Placements/Automation';

const useStyles = makeStyles()(theme => ({
  showMore: {
    fontWeight: 500,
    fontSize: '14px',
    color: '#006fb9',
    cursor: 'pointer',
  },
  kpi: {
    color: '#333',
    fontSize: '1rem',
    textAlign: 'center',
    padding: '0.4rem',
    borderRadius: '0.2rem',
    backgroundColor: 'white',
    boxShadow: '2px 2px white',
    border: '1px solid #E2E2E2',
    minWidth: '90px',
    margin: '5px',
  },
  tag: {
    padding: '3px 1.5rem',
  },
  recruiterAvtr: {
    height: '1.5rem',
    width: '1.5rem',
    fontSize: '10px',
    backgroundColor: '#ACACAC',
  },
  divider: {
    height: 2,
  },
  tabContainer: {
    padding: '12px 0 0 0 !important',
    width: 'auto',
  },
  drawerRoot: {
    flexGrow: 1,
    width: 'inherit',
    maxHeight: '1000px',
    backgroundColor: theme.palette.system.lightGray,
  },
  tabRoot: {
    [theme.breakpoints.up(380)]: {
      maxWidth: '115px !important',
    },
    color: '#555555 !important',
    fontSize: '16px !important',
  },
  tabSelected: {
    color: '#52429A !important',
  },
  drawerArrow: {
    position: 'relative',
    padding: '0',
    margin: '0',
    'font-size': '1.2rem',
    'margin-left': '1rem',
    top: '10px',
    cursor: 'pointer',
    color: '#888',
    marginBottom: '20px',
  },
  topHeader: {
    padding: '0 0.63rem',
    height: '47px',
    alignItems: 'center',
  },
  panelHeaderItem: {
    width: 'fit-content',
    alignSelf: 'center',
  },
  profileSegment: {
    display: 'flex',
    paddingLeft: '0.6rem',
    paddingBottom: '0.1rem',
  },
  avatarProfile: {
    height: 72,
    width: 72,
    marginRight: 10,
    fontSize: 27,
    backgroundColor: '#888888',
    fontWeight: 500,
    textTransform: 'uppercase',
    position: 'inherit',
  },
  avatarProfileNavigate: {
    height: 72,
    width: 72,
    marginRight: 10,
    fontSize: 30,
    backgroundColor: '#888888',
    fontWeight: 500,
    textTransform: 'uppercase',
    position: 'inherit',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  candidateStatus: {
    height: '26px',
    padding: '3px 8px',
    fontSize: '0.8rem',
    borderRadius: '5px',
    letterSpacing: '0',
    fontWeight: 500,
  },
  tooltip: {
    display: 'grid',
  },
  topHeaderTitle: {
    color: '#006FB9',
    fontSize: '12px',
    fontWeight: 500,
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  indicator: {
    background: '#52429A !important',
    marginTop: '0px',
    paddingTop: '0px',
  },
  passportImage: {
    transform: 'translate(55px, -24px)',
  },
  descHeaderText: {
    fontSize: '22px',
    color: '#006FB9',
    fontWeight: 500,
  },
  descHeaderTextHover: {
    fontSize: '22px',
    color: '#006FB9',
    fontWeight: 500,
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  hoverText: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  operationIcon: {
    height: '38px',
    width: '46px',
    marginTop: '3px',
    position: 'relative',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#E0EDF7',
      border: '0px solid #E0EDF7',
      borderRadius: '20px',
    },
  },
  details: {
    wordBreak: 'keep-all',
    fontSize: '14px',
    padding: '4px 0px 4px 0px',
    display: 'flex',
    marginBottom: '0px',
  },
  button: {
    marginTop: '0.3rem',
    padding: '0.3rem 1rem',
    color: '#FFFFFF',
  },
  operationIconDisabled: {
    filter: 'opacity(0.5) contrast(0)',
    height: '38px',
    width: '46px',
    marginTop: '3px',
    position: 'relative',
  },
  consentIcons: {
    height: '38px',
    width: '46px',
    marginTop: '3px',
    position: 'relative',
  },
  kpiContainer: {
    display: 'flex',
    padding: '0.5rem',
    height: '85px',
    maxWidth: '450px',
    overflowX: 'hidden',
    '&:active': {
      overflowX: 'auto',
    },
    '&:hover': {
      overflowX: 'auto',
    },
    '&:focus': {
      overflowX: 'auto',
    },
  },
}));

export enum PanelControls {
  Close,
  Up,
  Down,
  Maximize,
}

export enum OperationType {
  Mail,
  Phone,
  Message,
}
export interface ITopHeader {
  title: string;
  panelControls: Map<PanelControls, Function>;
}
export interface IDescriptionHeader {
  imageSrc: any | null;
  name: string;
  relationSets: string[];
  status: {
    title: string;
    color: string;
    background: string;
  };
  passport?: boolean;
  operations: {
    value: any;
    icon: string;
    type: OperationType;
    disabled: boolean;
    handler: () => void;
  }[];
}

export interface IDetailsSection {
  info: string;
  value: string;
  component?: JSX.Element;
}

export interface IInfoSection {
  brand: string;
  address: string;
  recruiter: string;
  time: string;
}
export interface INotifiedDetails {
  callBack: string;
  lastContacted: string;
  availableToStart: string;
  lastAssignmentEnded: string;
  nextPlacementStart: string;
  lastApplicationDate: string;
}

export interface IAdditionalSection {
  header: string;
  editable: boolean;
  open: boolean;
  comments: string;
  summary: string;
  component: JSX.Element;
  onClickEdit?: (value: boolean) => void;
}

export interface ITab {
  name: string;
  kpiTiles?: string[];
  tags?: JSX.Element;
  info?: IInfoSection;
  detailsSection?: IDetailsSection[];
  additionalSection?: IAdditionalSection[];
  summary: string;
  component?: JSX.Element;
}

export interface ICandidateDrawerConsent {
  hasCallConsents?: boolean;
  hasEmailConsents?: boolean;
  hasTextConsents?: boolean;
}

interface ISideDrawer {
  closeProfileDrawer: () => void;
  openProfileDrawer: boolean;
  topHeader: ITopHeader;
  descHeader: IDescriptionHeader;
  tabs: ITab[];
  isDrawer?: boolean;
  navigate?: () => void;
  isCandidateHeader?: boolean;
  consentsData?: ICandidateDrawerConsent;
  setOpenCreatePlacementForm?: (boolean) => void;
  division?: string;
  orderType?: string;
  positionsAvailable?: any;
}

export const SideDrawer = (props: ISideDrawer) => {
  const userInfo = useSelector(selectUser);
  const {
    closeProfileDrawer,
    topHeader,
    descHeader,
    tabs,
    openProfileDrawer,
    isDrawer = true,
    navigate,
    isCandidateHeader = false,
    consentsData = {
      hasCallConsents: true,
      hasEmailConsents: true,
      hasTextConsents: true,
    },
    setOpenCreatePlacementForm,
    division,
    orderType,
    positionsAvailable,
  } = props;
  const { classes } = useStyles();

  const enableEmailCommunication = useEnableEmailCommunication();
  const enableSmsCommunication = useEnableSmsCommunication() && !!userInfo?.userInfo?.phoneNumber;
  const enableVoiceCommunication = useEnableVoiceCommunication();
  const { t } = useTranslation();
  const [showTagsSection] = useDecision(ff_candidate_tags);
  const [tabSelectedValue, setTabSelectedValue] = useState<number>(0);
  const [showFullSummary, setShowFullSummary] = React.useState(false);
  const additionalSection = useRef<HTMLDivElement | null>(null);
  let candidateDetails = useSelector(candidateDetailsSelection);
  const dispatch = useDispatch();
  const notificationData = useSelector(notificationSelection);
  const filters = notificationData.logFilters;
  const desiredChannels = ['Email', 'Call', 'SMS'];
  const defaultChannels = useSelector(selectDefaultChannels);
  const channelValues = useSelector(selectChannelValues);
  const theme = useTheme();

  const uniqueChannels = useMemo(() => {
    const channels = [];
    candidateDetails?.communicationLog?.results?.forEach(result => {
      const { channel } = result;
      if (channel === 'Conversation' && !channels.includes('SMS')) {
        channels.push('SMS');
      } else if (channel === 'Voice' && !channels.includes('Call')) {
        channels.push('Call');
      } else if (desiredChannels.includes(channel) && !channels.includes(channel)) {
        channels.push(channel);
      }
    });
    return channels;
  }, [candidateDetails.communicationLog?.results]);
  const { strikeOrder, createScreenEnabled } = React.useMemo(() => {
    const isStrike = isStrikOrder({
      division,
      orderType,
    });
    const isCreateScreenEnabled = Number(positionsAvailable) === 0;
    return {
      strikeOrder: isStrike,
      createScreenEnabled: isCreateScreenEnabled,
    };
  }, [positionsAvailable, division, orderType]);
  useEffect(() => {
    if (filters?.channel.length === 0) {
      dispatch(candidateDetailActions.setDefaultChannelValues(uniqueChannels));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidateDetails.communicationLog?.results, channelValues]);

  const handleProfileDrawerTabChange = (event, newValue) => {
    const payload = {
      brandId: candidateDetails?.brandId?.toString(),
      contactId: candidateDetails?.travelerId.toString(),
      channel: ['Conversation', 'Email', 'Voice'],
    };

    if (newValue !== tabSelectedValue) {
      setTabSelectedValue(newValue);
    }
    if (newValue === 1 && isCandidateHeader) {
      dispatch(loadUserLogs({ payload }));
    }
  };

  useEffect(() => {
    const summaryTab = tabs?.find(tab => tab.name === 'Summary');
    const open = summaryTab?.additionalSection?.map(section => {
      if (section.open) return 1;
      return 0;
    });
    if (open?.includes(1)) {
      additionalSection?.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [tabs]);

  const pC: JSX.Element[] = [];
  if (topHeader.panelControls.has(PanelControls.Up)) {
    pC.push(
      <IconButton
        color="primary"
        key={'up'}
        className={classes.drawerArrow}
        onClick={() => topHeader.panelControls.get(PanelControls.Up)?.(false)}
        onKeyDown={() => {}}
        size="small"
      >
        <KeyboardArrowUpIcon />
      </IconButton>,
    );
  }
  const operations = useMemo<JSX.Element[]>(
    () =>
      descHeader.operations.map(operation => (
        <CustomTooltip
          tooltipPlacementBottom
          disableMaxWidthX
          key={operation.type}
          tooltipContent={
            <span className={classes.tooltip}>
              <span>{operation.value}</span>{' '}
            </span>
          }
        >
          {operation.type === OperationType.Mail && !operation.disabled && enableEmailCommunication ? (
            <img
              src={operation.icon}
              className={!consentsData.hasEmailConsents ? classes.consentIcons : classes.operationIcon}
              onClick={operation.handler}
              alt="icon"
              id={'SidebarOperationEmailCommunication'}
            />
          ) : operation.type === OperationType.Message && !operation.disabled && enableSmsCommunication ? (
            <img
              src={operation.icon}
              className={!consentsData.hasTextConsents ? classes.consentIcons : classes.operationIcon}
              onClick={operation.handler}
              alt="icon"
              id={'SidebarOperationSmsCommunication'}
            />
          ) : operation.type === OperationType.Phone && !operation.disabled && enableVoiceCommunication ? (
            <img
              src={operation.icon}
              className={!consentsData.hasCallConsents ? classes.consentIcons : classes.operationIcon}
              onClick={operation.handler}
              alt="icon"
              id={'SidebarOperationPhoneCommunication'}
            />
          ) : (
            <img src={operation.icon} className={classes.operationIconDisabled} alt="icon" />
          )}
        </CustomTooltip>
      )),
    [descHeader],
  );

  if (topHeader.panelControls.has(PanelControls.Down)) {
    pC.push(
      <IconButton
        color="primary"
        key={'down'}
        className={classes.drawerArrow}
        onClick={() => topHeader.panelControls.get(PanelControls.Down)?.(true)}
        onKeyDown={() => {}}
        size="small"
      >
        <KeyboardArrowDownIcon />
      </IconButton>,
    );
  }

  if (topHeader.panelControls.has(PanelControls.Maximize)) {
    pC.push(
      <IconButton
        color="primary"
        key={'maximize'}
        className={classes.drawerArrow}
        onClick={() => {}}
        onKeyDown={() => {}}
        size="small"
      >
        <FullscreenIcon />
      </IconButton>,
    );
  }

  if (topHeader.panelControls.has(PanelControls.Close)) {
    pC.push(
      <IconButton
        color="primary"
        key={'close'}
        className={classes.drawerArrow}
        onMouseDown={() => topHeader.panelControls.get(PanelControls.Close)?.()}
        onKeyDown={() => {}}
        size="small"
      >
        <ClearIcon />
      </IconButton>,
    );
  }

  const descHdr: JSX.Element = (
    <Grid item xs={12} className={classes.profileSegment}>
      <Grid item xs={2}>
        <Avatar
          className={navigate ? classes.avatarProfileNavigate : classes.avatarProfile}
          onClick={() => navigate?.()}
          id={'SidebarAvatar'}
        >
          {descHeader.name !== missingField
            ? Concatenate([descHeader.name?.charAt(0), descHeader.name?.split(' ')[1]?.charAt(0)], '')
            : missingField}
        </Avatar>
        {descHeader.passport ? (
          <div className={classes.passportImage}>
            <img src={PassportEnabled} alt="Passport enabled" id={'SidebarPassportIcon'} />
          </div>
        ) : (
          <div className={classes.passportImage}>
            <img src={PassportDisabled} alt="Passport disabled" id={'SidebarPassportIcon'} />
          </div>
        )}
      </Grid>
      <Grid item xs={10} style={{ paddingLeft: '1.5rem', paddingRight: '0.9rem' }}>
        <Grid container justifyContent={'space-between'} alignItems={'center'}>
          <Grid item>
            <span
              className={navigate ? classes.descHeaderTextHover : classes.descHeaderText}
              onClick={() => navigate?.()}
              id={'SidebarName'}
            >
              {descHeader.name}
            </span>
          </Grid>
          <Grid
            item
            className={classes.candidateStatus}
            style={{ color: descHeader.status.color, background: descHeader.status.background }}
          >
            <span style={{ paddingTop: '6px' }} id={'SidebarStatus'}>
              {descHeader.status.title}
            </span>
          </Grid>
          {/* HIDDEN */}
          {/* <div style={{ color: '#888', padding: '0 5px' }}>
            <MoreVertIcon />
          </div> */}
        </Grid>
        <div style={{ lineHeight: '20px', padding: '3px 0px' }}>
          <span style={{ fontSize: '0.9rem' }} id={'SidebarRelation'}>
            {descHeader.relationSets.join(', ')}
          </span>
        </div>
        <div style={{ display: 'flex', paddingTop: '5px' }} id={'SidebarOperations'}>
          {operations.map((operation, index) => (
            <div style={{ paddingRight: '0.2rem' }} key={index}>
              {operation}
            </div>
          ))}
        </div>
        <Grid item xs={12} className={`${classes.details}`}>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            size="medium"
            onClick={() => {
              setOpenCreatePlacementForm(true);
            }}
          >
            {!strikeOrder && createScreenEnabled ? t('placement.create.createScreen') : t('placement.create.create')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );

  const tabsSection = (
    <Tabs
      value={tabSelectedValue}
      classes={{ indicator: `${'tab-indicator profileTab'} ${classes.indicator}` }}
      onChange={handleProfileDrawerTabChange}
      variant="fullWidth"
    >
      {tabs.map(tab => (
        <Tab
          key={tab.name}
          className={tabSelectedValue === 0 ? 'active-tab-profile' : 'default-tab profileTab'}
          label={tab.name}
          classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
        />
      ))}
    </Tabs>
  );

  const KPI = (candidateDetails: { header: string; value: string }) => {
    return (
      <div className={classes.kpi}>
        <Typography style={{ color: '#797979', fontSize: '11px' }}>{candidateDetails.header}</Typography>
        <Typography style={{ color: '#333333', fontSize: '18px', fontWeight: 500 }}>
          {candidateDetails.value}
        </Typography>
      </div>
    );
  };

  const kpiTiles = tabs[tabSelectedValue].kpiTiles
    ? tabs[tabSelectedValue].kpiTiles!.map(kpi => KPI({ header: kpi.split('=')[0], value: kpi.split('=')[1] }))
    : null;

  // const tagsTiles = tabs[tabSelectedValue].tags ? Tag(tabs[tabSelectedValue].tags) : null;

  const addrSection = tabs[tabSelectedValue].info ? (
    <table style={{ width: '100%', borderCollapse: 'separate', borderSpacing: '10px' }}>
      <tr>
        <td>
          <Grid container wrap={'nowrap'} spacing={2} alignItems={'center'}>
            <Grid item>
              <BusinessIcon color={'disabled'} style={{ height: '20px', width: '20px' }} />
            </Grid>
            <Grid item>
              <span style={{ fontSize: '14px', color: '#333333' }} id="SidebarAddressBrand">
                {tabs[tabSelectedValue].info!.brand}
              </span>
            </Grid>
          </Grid>
        </td>
        <td>
          <Grid container wrap={'nowrap'} spacing={2} alignItems={'center'}>
            <Grid item>
              <LocationOnOutlinedIcon color={'disabled'} style={{ height: '20px', width: '20px' }} />
            </Grid>
            <Grid item>
              <span style={{ fontSize: '14px', color: '#333333' }} id="SidebarAddressLine">
                {tabs[tabSelectedValue].info!.address}
              </span>
            </Grid>
          </Grid>
        </td>
      </tr>
      <tr>
        <td style={{ display: 'flex' }}>
          <Grid container wrap={'nowrap'} spacing={2} alignItems={'center'}>
            <Grid item>
              <Avatar className={classes.recruiterAvtr} style={{ height: '20px', width: '20px' }}>
                {Concatenate(
                  [
                    tabs[tabSelectedValue].info!.recruiter?.charAt(0),
                    tabs[tabSelectedValue].info!.recruiter.split(' ')[1]?.charAt(0),
                  ],
                  '',
                )}
              </Avatar>
            </Grid>
            <Grid item>
              <span style={{ fontSize: '14px', color: '#333333' }} id="SidebarAddressRecruiter">
                {tabs[tabSelectedValue].info!.recruiter}
              </span>
            </Grid>
          </Grid>
        </td>
        <td>
          <Grid container wrap={'nowrap'} spacing={2} alignItems={'center'}>
            <Grid item>
              <ScheduleIcon color={'disabled'} style={{ height: '20px', width: '20px' }} />
            </Grid>
            <Grid item>
              <span style={{ fontSize: '14px', color: '#333333' }} id="SidebarAddressTime">
                {tabs[tabSelectedValue].info!.time}
              </span>
            </Grid>
          </Grid>
        </td>
      </tr>
    </table>
  ) : null;

  const detailedSection = (detailSection: IDetailsSection[]) => (
    <table style={{ paddingLeft: '0.5rem', width: '100%', borderCollapse: 'separate', borderSpacing: '8px' }}>
      {detailSection.map(detailSec => (
        <tr key={detailSec.info}>
          <td style={{ color: '#333333', fontSize: '12px', fontWeight: 500, width: '50%' }}>{detailSec.info}</td>
          <td style={{ color: '#333333', fontSize: '14px', alignContent: 'left', width: '50%' }}>
            {detailSec.component ? detailSec.component : detailSec.value}
          </td>
        </tr>
      ))}
    </table>
  );

  const DrawerContent = () => {
    return (
      <>
        {
          <div className={classes.drawerRoot}>
            <LayoutGridItem container xs={12} direction="row" className={classes.topHeader}>
              <LayoutGridItem
                className={`${classes.panelHeaderItem} ${classes.topHeaderTitle}`}
                onClick={() => navigate?.()}
                style={{ alignItems: 'center' }}
                container
              >
                <span id="SidebarHeaderTitle" className={navigate && classes.hoverText}>
                  {topHeader.title}
                </span>
              </LayoutGridItem>
              <LayoutGridItem className={classes.panelHeaderItem} style={{ marginLeft: 'auto' }} container>
                {pC.map(control => control)}
              </LayoutGridItem>
            </LayoutGridItem>
            <Divider orientation="horizontal" classes={{ root: classes.divider }} />
            <Paper elevation={0} className={`paper ${classes.tabContainer} `} style={{ border: 'none' }}>
              {descHdr}
              {tabsSection}
            </Paper>
            <Grid item xs={12} style={{ maxHeight: '1000px' }}>
              {tabs[tabSelectedValue].kpiTiles ? (
                <section className={classes.kpiContainer} id="SidebarKpis">
                  {kpiTiles}
                </section>
              ) : null}
              {tabs[tabSelectedValue].tags && showTagsSection.enabled ? (
                <section
                  style={{ display: 'flex', justifyContent: 'flex-start', padding: '6px 20px' }}
                  id="SidebarTags"
                >
                  {tabs[tabSelectedValue].tags}
                </section>
              ) : null}
              {tabs[tabSelectedValue].info ? (
                <section style={{ padding: '0.6rem 1rem' }} id="SidebarAddress">
                  {addrSection}
                </section>
              ) : null}
              <Divider orientation="horizontal" classes={{ root: classes.divider }} />
              {tabs[tabSelectedValue].detailsSection ? (
                <>
                  <section style={{ padding: '1rem' }} id="SidebarDetailsSection">
                    {detailedSection(tabs[tabSelectedValue].detailsSection)}
                  </section>
                  <Divider orientation="horizontal" classes={{ root: classes.divider }} />
                </>
              ) : null}

              {tabs[tabSelectedValue].additionalSection
                ? tabs[tabSelectedValue].additionalSection!.map(additionalSec => (
                    <>
                      <section style={{ padding: '0 1rem' }} id="SidebarAdditionalSection">
                        <>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              padding: '1rem',
                            }}
                          >
                            <span style={{ fontSize: '16px', fontWeight: 500 }}>{additionalSec.header}</span>
                            {additionalSec.editable && !additionalSec.open && (
                              <span onClick={() => additionalSec.onClickEdit?.(true)}>
                                <IconButton color="secondary" size="medium">
                                  <EditOutlinedIcon />
                                </IconButton>
                              </span>
                            )}
                          </div>
                          {additionalSec.open ? (
                            <div ref={additionalSection}>{additionalSec.component}</div>
                          ) : (
                            <>
                              {additionalSec.summary &&
                              (additionalSec.summary.length < 150 ||
                                additionalSec.summary === missingField ||
                                additionalSec.summary === '') ? (
                                <div style={{ padding: '0 1rem 1rem 1rem' }}>
                                  <span style={{ fontSize: '0.9rem' }}>{additionalSec.comments}</span>
                                  <span style={{ fontSize: '14px', whiteSpace: 'pre-line' }}>
                                    {additionalSec.summary}
                                  </span>
                                </div>
                              ) : (
                                <>
                                  {additionalSec?.summary?.length > 100 ? (
                                    !showFullSummary ? (
                                      <div style={{ padding: '0 1rem 1rem 1rem' }}>
                                        <span style={{ fontSize: '0.9rem' }}>{additionalSec.comments}</span>
                                        <span style={{ fontSize: '14px', whiteSpace: 'pre-line' }}>
                                          {additionalSec?.summary?.substring(0, 100)}
                                        </span>
                                        <div
                                          role="button"
                                          tabIndex={-1}
                                          className={classes.showMore}
                                          color="primary"
                                          onClick={() => setShowFullSummary(true)}
                                          onKeyDown={() => setShowFullSummary(true)}
                                        >{`More...`}</div>
                                      </div>
                                    ) : (
                                      <div style={{ padding: '0 1rem 1rem 1rem' }}>
                                        <span style={{ fontSize: '0.9rem' }}>{additionalSec.comments}</span>
                                        <span style={{ fontSize: '14px', whiteSpace: 'pre-line' }}>
                                          {additionalSec.summary}
                                        </span>
                                        <div
                                          role="button"
                                          tabIndex={-1}
                                          className={classes.showMore}
                                          color="primary"
                                          onClick={() => setShowFullSummary(false)}
                                          onKeyDown={() => setShowFullSummary(false)}
                                        >{`Less...`}</div>
                                      </div>
                                    )
                                  ) : (
                                    <Box sx={{ padding: '0 1rem 1rem 1rem' }}>
                                      <Typography variant="body1">{additionalSec.comments}</Typography>
                                      <Typography variant="body1">{additionalSec.summary}</Typography>
                                    </Box>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      </section>
                      <Divider orientation="horizontal" classes={{ root: classes.divider }} />
                    </>
                  ))
                : null}
              {tabs[tabSelectedValue].component && <>{tabs[tabSelectedValue].component}</>}
            </Grid>
          </div>
        }
      </>
    );
  };

  return isDrawer ? (
    <DrawerFitted
      open={openProfileDrawer}
      onDrawerClose={closeProfileDrawer}
      width={450}
      top={0}
      backgroundColor={theme.palette.framework.system.whisper}
      shadow
    >
      <DrawerContent />
    </DrawerFitted>
  ) : (
    <DrawerContent />
  );
};
