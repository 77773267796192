import React, { useEffect, useState } from 'react';
import AutoCompleteRenderChips from '@AMIEWEB/Order/OrderCreationForm/Common/AutoCompleteRenderChips';
import { useTranslation } from 'react-i18next';
import { virtualRatingList, virtualRatingListWithVoiceAdvantage } from 'app/constants';
import { ITypeAheadOption } from 'app/models/Orders/OrderDetails';
import { Grid } from 'amn-ui-core';
import { Controller, useFormContext } from 'react-hook-form';
import { SimpleCheckbox } from '@AMIEWEB/Unit/Common/SimpleCheckbox';
import { makeStyles } from 'tss-react/mui';
import { commonVIPriority } from '@AMIEWEB/Unit/helper';
import { useSelector } from 'react-redux';
import { selectOrderPriorities } from '@AMIEWEB/Order/Store/OrderCreation.selectors';
import { getOPObjectbyLabel } from './Helper';

export const transformDataForVirtualRatings = virtualRatings => {
  const result = virtualRatings?.map(item => {
    const option: ITypeAheadOption = {
      object: { value: 0, name: item },
      label: item,
    };
    return option;
  });
  return result;
};

const useStyles = makeStyles()(theme => ({
  checkbox: {
    maxWidth: '120px',
    minWidth: '120px',
    '& label': {
      paddingLeft: '0px',
      margin: '0px',
      '& .MuiButtonBase-root.MuiCheckbox-root.Mui-checked': {
        color: `${theme.palette.system.main} !important`,
      },
      '&:hover .MuiButtonBase-root.MuiCheckbox-root': {
        color: `${theme.palette.system.main} !important`,
      },
    },
    '& .MuiTypography-root': {
      fontSize: '14px !important',
    },
    '&:hover .MuiButtonBase-root.MuiCheckbox-root.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.26) !important',
    },
  },
}));

const VirtualRatings = () => {
  const { t } = useTranslation();
  const [virtualRatingOptions, setVirtualRatingOptions] = useState([]);
  const { control, watch, setValue } = useFormContext();
  const { classes } = useStyles();
  const orderPriorities = useSelector(selectOrderPriorities);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const watchValue = !!watch('virtualRating') ? watch('virtualRating') : [];
  const watchOrderPrioritiesValue = !!watch('orderPriorities') ? watch('orderPriorities') : [];

  useEffect(() => {
    if (watchOrderPrioritiesValue?.length > 0) {
      let orderPrioritiesToAdd = watchOrderPrioritiesValue
        .map(orderPriorities => orderPriorities?.object?.name)
        .filter(viFromAtoI => virtualRatingListWithVoiceAdvantage.includes(viFromAtoI))
        .filter(item => !watchValue.map(virtualRatings => virtualRatings.label).includes(item));
      orderPrioritiesToAdd = virtualRatingOptions.filter(item => orderPrioritiesToAdd.includes(item?.object?.name));
      const orderPrioritiesToRemove = virtualRatingListWithVoiceAdvantage.filter(        
        item => !watchOrderPrioritiesValue.map(virtualRatings => virtualRatings?.object?.name).includes(item),
      );
      if (!!orderPrioritiesToAdd && orderPrioritiesToAdd.length > 0) {
        setValue('virtualRating', [...watchValue, ...orderPrioritiesToAdd]);
      } else if (!!orderPrioritiesToRemove && orderPrioritiesToRemove.length > 0) {
        let filteredValues = watchValue.filter(item => !orderPrioritiesToRemove.includes(item.label));
        const commonVIPresent = filteredValues.map(item => item.label).includes(commonVIPriority);
        const hasVirtualRatingList = filteredValues.some(item => virtualRatingList.includes(item.label));

        if(!hasVirtualRatingList && commonVIPresent) {
          filteredValues = filteredValues.filter(item => item.label !== commonVIPriority);          
        }
        setValue(
          'virtualRating',
          [...filteredValues]
        );
      }
      const hasVirtualRatingList = watchValue.some(item => virtualRatingList.includes(item.label));
      const commonVIPresent = watchValue.map(item => item.label).includes(commonVIPriority);
      if (hasVirtualRatingList && !commonVIPresent) {
        setValue('virtualRating', [
          ...watchValue,
          {
            object: { value: 0, name: commonVIPriority },
            label: commonVIPriority,
          },
        ]);
      } else if (!hasVirtualRatingList && commonVIPresent) {
        setValue(
          'virtualRating',
          watchValue.filter(item => item.label !== commonVIPriority),
        );
      }
    }
  }, [watchOrderPrioritiesValue?.length]);

  useEffect(() => {
    if (watchValue?.length) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
      setValue('submissionRequirement', false);
    }
  }, [watchValue]);

  useEffect(() => {
    setVirtualRatingOptions(
      virtualRatingListWithVoiceAdvantage.map(item => ({
        object: getOPObjectbyLabel(item, orderPriorities),
        label: item,
      })),
    );
  }, [orderPriorities]);

  const virtualRatingDisabledOption = [commonVIPriority];

  return (
    <Grid container xs={12}>
      <Grid item xs={8.75} marginRight={2}>
        <AutoCompleteRenderChips
          label={`${t('facility.units.unitDetails.additionalInformation.virtualInterviewRating')}${
            watch('submissionRequirement') ? ' *' : ''
          }`}
          options={virtualRatingOptions}
          id="virtual-rating-dropdown"
          name="virtualRating"
          disabledValue={virtualRatingDisabledOption}
          getOptionDisabled={option => !!virtualRatingDisabledOption?.includes(option.label)}
        />
      </Grid>
      <Grid item className={classes.checkbox}>
        <Controller
          name={'submissionRequirement'}
          control={control}
          render={({ onChange, ...rest }) => (
            <SimpleCheckbox
              isChecked={rest.value || false}
              handleChecked={event => {
                onChange(event.target.checked);
              }}
              checkboxLabel={t('facility.units.unitDetails.additionalInformation.submissionRequirement')}
              checkboxName={'submissionRequirement'}
              id={'submissionRequirement'}
              isdisabled={isDisabled}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default VirtualRatings;
