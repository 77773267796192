import { XGridDefaultColumn } from '@AMIEWEB/Common/XGrid/cells/XGridDefaults';
import { GridColumns } from '@mui/x-data-grid-pro';
import { CompactStatusRenderCell } from './Components/OrderLicense/CompactStatusRenderCell';
import { LicenseStateRenderCell } from './Components/OrderLicense/StateRenderCell';
import { LicenseSettingRenderCell } from './Components/OrderLicense/LicenseSettingRenderCell';
import React from 'react';

const defaultTaskWordDeskColumn = {
  ...XGridDefaultColumn,
  flex: 0,
};

export const orderLicenseColumns = (isDisabled, t): GridColumns => [
  {
    ...defaultTaskWordDeskColumn,
    field: 'credentialName',
    headerName: t('order.license.licenseName'),
    width: 400,
    hide: false,
    sortable: false,
  },
  {
    ...defaultTaskWordDeskColumn,
    field: 'compactStatus',
    headerName: t('order.license.compactStatus'),
    width: 180,
    hide: false,
    sortable: false,
    renderCell: params => <CompactStatusRenderCell param={params} isDisabled={isDisabled} />,
  },
  {
    ...defaultTaskWordDeskColumn,
    headerName: t('order.license.state'),
    field: 'state',
    width: 150,
    hide: false,
    sortable: false,
    renderCell: params => <LicenseStateRenderCell param={params} isDisabled={isDisabled} />,
  },
  {
    ...defaultTaskWordDeskColumn,
    headerName: t('order.license.requirements'),
    field: 'setting',
    width: 370,
    hide: false,
    sortable: false,
    renderCell: params => <LicenseSettingRenderCell param={params} isDisabled={isDisabled} />,
  },
];
