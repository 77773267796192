import { IOrderCertification } from '@AMIEWEB/Order/Store/Order.redux';
import { Certification } from 'app/models/Unit/CreateUnit';

export const transformCertificationData = (certifications: IOrderCertification[]) => {
  const transformedCertifications: any[] = [];

  certifications?.forEach(cert => {
    if (cert?.requirementTypeId !== 122) {
      transformedCertifications.push(cert);
    }
  });
  const sortedCertificationList = transformedCertifications.sort(function (a, b) {
    return a?.abbreviation?.toLowerCase() === b?.abbreviation?.toLowerCase()
      ? 0
      : a?.abbreviation?.toLowerCase() > b?.abbreviation?.toLowerCase()
      ? 1
      : -1;
  });
  return sortedCertificationList;
};

export const formatCertifications = (certifications: IOrderCertification[]) => {
  const filteredCertifications = certifications?.filter(cert => cert?.requirementTypeId !== 122);
  const updatedCertifications = filteredCertifications?.map(cert => {
    const newCert: Certification = {
      requirementTypeId: cert?.requirementTypeId,
      abbreviation: cert?.abbreviation,
      isRequired: cert?.isRequired,
      documentAttachmentRequired: cert?.documentAttachmentRequired,
    };
    return newCert;
  });
  return updatedCertifications;
};
