/* eslint-disable i18next/no-literal-string */
import React from 'react';
import { Expandable } from 'app/components/Common/Expandable/Expandable';
import ScreenIcon from 'app/assets/images/IconImages/ScreenIcon.svg';
import { IconTitle } from './Titles/IconTitle';
import { CandidatePlacements } from 'app/components/Placement/PlacementDetails/PlacementTabPanel/PlacementSummaryTab/CandidatePlacements';

export const Placements = ({ ...props }) => {
  return (
    <Expandable
      id="placement-summary-placements"
      expanded={false}
      titleComponent={<IconTitle title={'Placements'} icon={<img src={ScreenIcon} alt="Screen" />} />}
    >
      <CandidatePlacements />
    </Expandable>
  );
};
