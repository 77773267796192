import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { FormInputProps } from './FormInputProps';
import MaskedInput from 'react-text-mask';
import { RestrictedSSNTextField } from 'app/ComponentLibrary/SSNTextField/RestrictedSSNTextField';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';

export const FormInputText = ({
  name,
  label,
  require,
  message,
  handleChange,
  inputValue,
  readOnly,
  customClass,
  customInputClass,
  type,
  isCheckBox,
  disableInputRef,
  inputComponent,
  maxLength,
  maxLengthError,
  multiline,
  inputLength,
  charCount,
  spellCheck,
  maxRows,
  placeholder,
  labelClass,
  inputRef,
  maskedInputValue
}: FormInputProps) => {
  const { control, register, errors } = useFormContext();
  const [labelShrink, setLabelShrink] = useState(false);
  useEffect(() => {
    setLabelShrink(isCheckBox ? true : inputValue ? true : false);
  }, [inputValue, isCheckBox]);
  // function TextMaskCustom(props) {
  //   const { inputRef, ...other } = props;
  //   return (
  //     <MaskedInput
  //       {...other}
  //       // ref={ref => {
  //       //   inputRef(ref ? ref.inputElement : null);
  //       // }}
  //       mask={[/\d*/, /\d*/, /\d*/, ' ', /\d*/, /\d*/, ' ', /\d*/, /\d*/, /\d*/, /\d*/]}
  //     />
  //   );
  // }

  const TextBox = useMemo(() => {
    return (
      <>
        <Controller
          name={name}
          control={control}
          rules={{
            required: require,
            maxLength: maxLengthError,
          }}
          render={({ ref, value, onChange, ...rest }) =>
            inputComponent ? (
              <RestrictedSSNTextField
                id="candidate-personal-info-ssn"
                overrideEdit={!readOnly}
                ssn={value || inputValue}
                variant="filled"
                size="medium"
                error={!!errors[name]}
                helperText={errors[name] ? (errors[name]?.type === 'required' ? 'Required' : message) : undefined}
                label="SSN"
                hiddenLabel={false}
                fullWidth
                onEndEdit={ssn => {
                  onChange(ssn);
                }}
                keepTextFieldOnReadOnly
              />
            ) : (
              <TextField
                autoComplete="off"
                multiline={multiline}
                maxRows={maxRows}
                id={`txt${name}`}
                variant="filled"
                type={type || 'text'}
                label={label}
                value={maskedInputValue}
                defaultValue={inputValue}
                onChange={e => {
                  handleChange?.(e);
                  if (e.target.value === '') setLabelShrink(false);
                  else setLabelShrink(true);
                }}
                InputLabelProps={{ shrink: labelShrink || placeholder?.length > 0, className: labelClass }}
                className={customClass ?? ''}
                inputRef={!disableInputRef ? register : inputRef}
                fullWidth
                placeholder={placeholder}
                {...rest}
                InputProps={{
                  className: customInputClass ?? '',
                  readOnly: readOnly,
                }}
                error={!!errors[name]}
                disabled={readOnly}
                inputProps={{
                  maxLength: maxLength,
                  'aria-label': name,
                  spellCheck: spellCheck,
                }}
                helperText={
                  <LayoutGrid container direction="row" justifyContent="space-between" spacing={2} xs={12}>
                    {errors[name] ? (
                      errors[name]?.type === 'required' ? (
                        <LayoutGridItem item>Required</LayoutGridItem>
                      ) : errors[name]?.type === 'maxLength' ? (
                        <LayoutGridItem item mt="0">
                          {"Maximum Characters Reached"}
                        </LayoutGridItem>
                      ) : errors[name]?.type === 'custom' && <LayoutGridItem item>{message}</LayoutGridItem>
                    ) : (
                      <LayoutGridItem item>{message}</LayoutGridItem>
                    )}
                    {charCount != undefined ? (
                      <LayoutGridItem item mr="-28px">{`${charCount}/${inputLength}`}</LayoutGridItem>
                    ) : (
                      <LayoutGridItem item></LayoutGridItem>
                    )}
                  </LayoutGrid>
                }
              />
            )
          }
        />
      </>
    );
  }, [
    control,
    customClass,
    disableInputRef,
    errors,
    handleChange,
    inputComponent,
    inputValue,
    label,
    labelShrink,
    message,
    name,
    readOnly,
    register,
    require,
    type,
  ]);

  return <>{TextBox}</>;
};
