/* eslint-disable @typescript-eslint/naming-convention */
export const ff_placementStatusChangeKey = 'ff_placements_ui_placement_status_change';
export const ff_submitPacketMarkAsSent = 'ff_placement_ui_submit_packet_mark_as_sent';
export const ff_disableStrikePlacementAutomation = 'ff_order_ui_disable_strike_automation_toggle';
export const ff_credentialPassportCred = 'ff_placement_ui_credential_passportcred';
export const ff_placementSubmissionAuditsModal = 'ff_placement_ui_submission_audits_modal';
export const ff_SubmissionAmnReqReferences = 'ff_placement_ui_submission_amn_req_references';
export const ff_placement_ui_document_test_ids = 'ff_placement_ui_document_test_ids';
export const ff_placement_ui_packet_generation = 'ff_placement_ui_packet_generation';
export const ff_placement_ui_peoplesoft_sync = 'ff_placement_ui_peoplesoft_sync';
export const ff_placement_ui_documents = 'ff_placement_ui_documents';
export const ff_placement_ui_assignment_icons = 'ff_placement_ui_assignment_icons';
export const ff_placement_ui_download_packet = 'ff_placement_ui_download_packet';
export const ff_placement_ui_modstab = 'ff_placement_ui_modstab';
export const ff_placement_ui_add_mod = 'ff_placement_ui_add_mod';
export const ff_placement_ui_edit_mods = 'ff_placement_ui_edit_mods';
export const ff_placement_ui_client_confirmation = 'ff_placement_ui_client_confirmation';
export const ff_placement_ui_documents_tab_contract_button = 'ff_placement_ui_documents_tab_contract_button';
export const ff_placement_ui_charge_amount_mod = 'ff_placement_ui_charge_amount_mod';
//TODO:Remove api ff with ui flag once it is created
export const ff_placement_ui_requirement_check = 'ff_placement_api_requirement_check';
export const ff_placement_pending_mods_approve_deny_buttons = 'ff_placement_pending_mods_approve_deny_buttons';
export const ff_placement_ui_and_or_containers = 'ff_placement_ui_and_or_containers';
export const ff_placement_check_for_classic_packets_in_ok_to_send_status =
  'ff_placement_check_for_classic_packets_in_ok_to_send_status';
export const ff_placement_new_client_confirmation_ui_flow = 'ff_placement_new_client_confirmation_ui_flow';
export const ff_credentialing_csp_classic = 'ff_credentialing_csp_classic';
export const ff_credentialing_csp_web = 'ff_credentialing_csp_web';
export const ff_placement_ui_disable_arbitration_link = 'ff_placement_ui_disable_arbitration_link';
export const ff_placement_ui_refresh_driving_distance = 'ff_placement_ui_refresh_driving_distance';
export const ff_placement_region_fields = 'ff_placement_region_fields';
export const ff_new_create_placement_modal_layout = 'ff_new_create_placement_modal_layout';
export const ff_peformance_enhanced_placement_profile_page = 'ff_peformance_enhanced_placement_profile_page';
export const ff_placement_cue_note_form_fields = 'ff_placement_cue_note_form_fields';
export const ff_placement_ui_change_order_on_placement = 'ff_placement_ui_change_order_on_placement';
