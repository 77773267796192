import { missingField } from 'app/constants';

export function Concatenate(words: string[], separator?: string) {
  const notNullWords = words?.filter(word => word !== null && word !== undefined && word);
  if (!notNullWords.length) return '';
  else {
    if (separator !== null && separator !== undefined) return notNullWords.join(separator);
    return notNullWords.join();
  }
}

export function ConcatenateX(words: string[], separator?: string) {
  if (!words) {
    return '';
  }
  const notNullWords = words?.filter(word => word !== null && word !== undefined && word);
  if (!notNullWords.length) return '';
  else {
    if (separator !== null && separator !== undefined) return notNullWords.join(separator);
    return notNullWords.join();
  }
}

export function isNumeric(str: string) {
  if (typeof str != 'string') return false;
  return !isNaN(parseFloat(str));
}

export function isEmpty(str: string | any) {
  if (str === null || str === '' || str === undefined) return '--';
  return str;
}

export function isStrNull(str: string | any) {
  if (str === null || str === '' || str === undefined) return '';
  return str;
}

export function isNum(value) {
  return /^-?\d+$/.test(value);
}

export const CapitalizeFirstLetter = str => {
  return str && str.length ? str.charAt(0).toUpperCase() + str.slice(1) : str;
};

export const CapitalizeEveryFirstLetter = str => {
  if (str && str.length) {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
  }
  return str;
};

export const stripRtf = rtfString => {
  var basicRtfPattern = /\{\*?\\[^{}]+;}|[{}]|\\[A-Za-z]+\n?(?:-?\d+)?[ ]?/g;
  var newLineSlashesPattern = /\\\n/g;
  var ctrlCharPattern = /\n\\f[0-9]\s/g;

  //Remove RTF Formatting, replace RTF new lines with real line breaks, and remove whitespace
  return rtfString
    .replace(ctrlCharPattern, '')
    .replace(basicRtfPattern, '')
    .replace(newLineSlashesPattern, '\n')
    .trim();
};

export function truncateString(string: string, count: number) {
  if (string?.length > count) {
    return string.substring(0, count) + '... ';
  } else {
    return string + ' ';
  }
}

export const validEmail = (value: string) => {
  return (
    /^[A-Z0-9._%+-]+@[a-zA-Z0-9_]+?\.[a-zA-Z]{2,3}$/i.test(value) ||
    /^[A-Z0-9._%+-]+@[a-zA-Z0-9_]+?\.[a-zA-Z]{2,3}\.[a-zA-Z]{1,3}$/i.test(value)
  );
};

export function areAllWhiteSpaces(value) {
  return /^\s*$/.test(value);
}

export function stringMatches(str1: string, str2: string) {
  return str1?.toLowerCase() === str2?.toLowerCase();
}

export function isNonEmptyString(str: string): boolean {
  return typeof str === 'string' && str !== '';
}

export function isValidJSONString(str: string) {
  if (str === '' || str === null) return false;
  else {
    if (
      /^[\],:{}\s]*$/.test(
        str
          .replace(/\\["\\\/bfnrtu]/g, '@')
          .replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']')
          .replace(/(?:^|:|,)(?:\s*\[)+/g, ''),
      )
    ) {
      return true;
    } else {
      return false;
    }
  }
}

export function checkRatioString(str: string) {
  if (!str) {
    return missingField;
  }
  const [antecedent, consequent] = str?.split(':');
  const isInvalid = part => part === 'null' || part === '0';
  return isInvalid(antecedent) || isInvalid(consequent) ? missingField : str;
}
