import { TFunction } from 'i18next';
import { Moment } from 'moment';
import { ICustomHeader } from '../Grid';
import { ITypeAheadOption } from '../Orders/OrderDetails';
import { IRequestCoverage } from '../UserCoverage/UserCoverage';
import { ITaskComment } from './Comments';
import { IEmployeeSharedProfile } from 'oidc/user.redux';

export enum TaskFilterType {
  PLACEMENTID = 1,
  CANDIDATEID,
  FACILITYID,
  ORDERID,
  FACILITY,
  CANDIDATE,
  ASSIGNEDTO,
  CREATEDBY,
  TASKCATEGORY,
  TASKNAME,
}

export enum TaskEntity {
  ALL,
  PLACEMENTS,
  CANDIDATES,
  FACILITIES,
  ORDERS,
}

export enum SubTypeId {
  License = 1,
  Certification = 2,
  State = 3,
  IssuingBody = 4,
}

export enum TaskContainer {
  ALL = 'all',
  ORDER = 'order',
  FACILITY = 'facility',
  PLACEMENT = 'placement',
  CANDIDATE = 'candidate',
}

export enum gridTags {
  TASKGRIDTAG = 'GlobalearchGrid-tasks',
  ENTITYGRIDTAG = 'TaskManagement-entityNavigation',
}

export enum TaskGridColumns {
  ASSIGNEDTO = 'assignedTo',
  TASK = 'task',
  TASKCATEGORY = 'taskCategory',
  STATUS = 'status',
  DUEDATE = 'dueDate',
  CREATEDBY = 'createdBy',
  TYPE = 'type',
  CREATEDDATE = 'sentDate',
  DETAILS = 'description',
  RECIPIENTSTATUS = 'recipientStatus',
}

export enum TaskCoverageActionType {
  CREATE_TASK = 1,
  EDIT_TASK,
  UPDATE_TASK_STATUS,
}

export const CC_CALL_TRAVELER_ID = '3-25';
export const QS_COORDINATOR = 'QS Coordinator';

export enum TaskCoverageDescription {
  CREATE_TASK = 'Create Task',
  EDIT_TASK = 'Edit Task',
  UPDATE_TASK_STATUS = 'Update Task Status',
}

export enum TaskCategory {
  OTHERS,
  MISSINGRFOREQS,
  SUBMISSIONREQS = 2,
  CREDENTIALING = 3,
  BOOKINGREQS = 6,
}

export enum SnackActionStatus {
  failure,
  success,
}

export enum TaskStatus {
  New = 1,
  Pending,
  Completed,
  Cancelled,
}

export enum TaskDescription {
  CreateTask = "Create Task",
  EditTask = "Edit Task",
  StatusUpdate = "Update Task Status",
  CancelTask = "Cancel Task",
}

export enum ActionType {
  CreateTask = 1,
  EditTask = 2,
  StatusUpdate = 3,
  CancelTask = 4,
}

export enum TaskName {
  CertificationUpdate = 1,
  LicenseUpdate = 5,
}

export enum TaskStatusReason {
  OTHER,
  NORESPONSE_CANDIDATE,
  NORESPONSE_CLIENT,
  NORESPONSE_TEAM,
  DECLINED,
  ERROR,
}

export enum NotificationCategory {
  Preferences = 'Preferences',
  Task = 'Task',
  Alert = 'Alert',
  Unread = 'Unread',
  Email = 'Email',
  CueNote = 'CueNote',
}
export interface ITask {
  id?: number;
  taskId?: number;
  sentBy: number;
  sentDate: string | Date | Moment;
  statusId: number;
  statusChangeDate: string | Date | Moment;
  facilityId?: number;
  candidateId?: number;
  placementId?: number;
  orderId?: number | null;
  contractAffiliateId?: number;
  facilityContactId?: number;
  unitId?: number;
  affiliateContactId?: number;
  taskCategory?: number;
  taskCategoryText?: string;
  task?: string;
  taskNameId?: number;
  description: string;
  assignedTo: any[] | any;
  cc?: any[];
  dueDate?: string | Date | Moment;
  reason?: string;
  attachments?: any;
  companyId?: number;
  sentByUser?: IUser;
  taskCoverageAction?: ICoverageActionDetails;
  taskPriorityId: number;
  sharedDeskAssociatedUser?: ISharedDeskUserAction;
}

export interface ISharedDeskUserAction {
  employeeId: string;
  firstName: string;
  lastName: string;
  actionById: number;
  actionByFirstName: string;
  actionByLastName: string;
  actionDate: string;
  actionType: number;
  actionTypeDescription: string;
}

export interface IInternalTask {
  id?: number;
  taskId?: number;
  sentBy: number;
  sentDate: string | Date | Moment;
  statusId: number;
  statusChangeDate: string | Date | Moment;
  facilityId?: number;
  candidateId?: number;
  placementId?: number;
  orderId?: number | null;
  contractAffiliateId?: number;
  facilityContactId?: number;
  unitId?: number;
  affiliateContactId?: number;
  taskCategory?: number;
  taskCategoryText?: string;
  task?: string;
  taskNameId?: number;
  description: string;
  assignedToIds: any[];
  ccIds?: any[];
  dueDate?: string | Date | Moment;
  reason?: string;
  attachments?: any;
  companyId?: number;
  sentByUser?: IUser;
  taskCoverageAction?: ICoverageActionDetails[];
  taskPriorityId: string;
  sharedDeskAssociatedUser?: ISharedDeskUserAction;
}

export interface IExternalTask {
  sentById: number;
  sentByFirstName?: string;
  sentByLastName?: string;
  sentDate: string | Date | Moment;
  facilityId?: number;
  candidateId: number;
  placementId: number;
  orderId?: number;
  contractAffiliateId?: number;
  facilityContactId?: number;
  unitId?: number;
  affiliateContactId?: number;
  taskCategoryId?: number;
  taskNameId: number;
  description?: string;
  assignedToCandidateFirstName?: string;
  assignedToCandidateLastName?: string;
  dueDate: string | Date | Moment;
  companyId?: number;
  cc?: IExternalCC[];
  createdLocation?: number;
  isAMIEWebRequest?: boolean;
  subCategory1: ISubCategoryDependentData;
  subCategory2: ISubCategoryDependentData;
}

export interface IExternalCC {
  ccFirstName?: string;
  ccLastName?: string;
  ccId?: number;
}

export interface IFormatedInternalTaskData {
  rootTaskId?: number;
  placementId?: number;
  assignedToId?: number;
  taskId?: number;
  statusId?: number;
  rootTaskCategoryId?: number;
  userRoleId?: number;
  assignedToFirstName?: string;
  assignedToLastName?: string;
  task?: string;
  taskName?: string;
  description?: string;
  taskCategoryId?: number;
  taskCategory?: string;
  userRole?: string;
  dueDate?: Moment;
  senderFirstName?: string;
  senderLastName?: string;
  ccValues: IUser[];
  assignedTo: IUser[] | ITypeAheadOption[];
  createdBy: IUser;
  reason?: string;
  statusUpdateReason?: string;
  statusUpdatedByName?: string;
  statusUpdatedById?: number;
  statusUpdatedDate?: Moment | String | Date;
  companyId?: number;
  candidateId?: number;
  taskNameId?: number;
  isAMIEWebRequest: boolean;
  categorySubType1?: ISubCategoryDependentData;
  categorySubType2?: ISubCategoryDependentData;
  subTaskCount?: number;
  taskPriorityId: number;
}

export interface IFormatedExternalTaskData {
  rootTaskId?: number;
  placementId?: number;
  assignedToId?: number;
  taskId?: number;
  statusId?: number;
  rootTaskCategoryId?: number;
  userRoleId?: number;
  assignedToFirstName?: string;
  assignedToLastName?: string;
  task?: string;
  taskName?: string;
  description?: string;
  taskCategoryId?: number;
  taskCategory?: string;
  userRole?: string;
  dueDate?: Moment;
  senderFirstName?: string;
  senderLastName?: string;
  ccValues: IUser[];
  assignedTo: IUser;
  createdBy: IUser;
  reason?: string;
  statusUpdateReason?: string;
  statusUpdatedByName?: string;
  statusUpdatedById?: number;
  statusUpdatedDate?: Moment | String | Date;
  companyId?: number;
  candidateId?: number;
  taskNameId?: number;
  isAMIEWebRequest: boolean;
  categorySubType1?: ISubCategoryDependentData;
  categorySubType2?: ISubCategoryDependentData;
  subTaskCount?: number;
}

export interface IStatusChip {
  statusId: number;
  statusCount: number;
  users: IUser[];
}

export interface IResponseData {
  id: number;
  name: string;
}

export interface IDueDateView {
  isFirstView: boolean;
  taskId: number;
}

export interface ITaskStatusCheck {
  rootTaskId?: number;
  taskId?: number;
  statusId?: number;
  recipientId?: number;
  statusUpdateReason?: string;
  statusUpdateReasonId?: number;
  statusUpdatedByName?: string;
  statusUpdatedById?: number;
  statusUpdatedDate?: string | Date | Moment;
  categorySubType1?: ISubCategoryDependentData;
  categorySubType2?: ISubCategoryDependentData;
}

export interface ICurrentUserStatus {
  isReverting?: boolean;
  statusId?: TaskStatus;
}

export enum StatusUpdateModalVersion {
  PARENT_TASK = 1,
  SUBTASK,
}

export enum StatusUpdateModalOptions {
  ALL_OPTIONS = 1,
  LIMITED_OPTIONS,
}

export interface IStatusModal {
  isModalOpen: boolean;
  container: string;
  version?: StatusUpdateModalVersion;
  options?: StatusUpdateModalOptions;
  isSubtaskCreatedUser?: boolean;
  isRecipientUser?: boolean;
  subTaskDetails?: any;
}

export interface ITaskDetails {
  newTask?: ITask;
  internalTaskFlagChanged?: IFormatedInternalTaskData;
  externalTaskFlagChanged?: IFormatedExternalTaskData;
  snackAction?: SnackActionStatus;
  newId?: number;
  internalTaskDetailsData?: IInternalTaskModalDetails;
  externalTaskDetailsData?: IExternalTaskModalDetails;
  commentList?: ITaskComments[];
  newComment?: ITaskComments;
  taskStatusUpdateModalId?: ITaskStatusCheck;
  taskStatusUpdateFlag?: string;
  showTaskStatusModal?: IStatusModal;
  currentContainer?: number;
  internalTaskListData?: IFormatedInternalTaskData[];
  externalTaskListData?: IFormatedExternalTaskData[];
  groupedList?: IGroupedList;
  errors?: any;
  updatedId?: number;
  updatedData?: ITask;
  taskGridData?: {
    paging?: IGridPaging;
    items?: IGridData[];
  };
  categoryList?: any[];
  taskEntity: number;
  createdTaskData?: ITask;
  taskStatusData?: any;
  notificationContainer?: any;
  statusContainer?: any;
  notificationPrimaryContext?: string;
  unreadNotificationLog?: any;
  placementBrandId?: number;
  newTaskAttachments?: any;
  existingAttachments?: any;
  createdComment?: boolean;
  isAttachmentDeleted?: any;
  companyId?: number;
  externalTaskId?: string;
  externalTaskCreatedData?: IExternalTask;
  externalCategoryList?: any[];
  externalTaskList?: any;
  isExternal?: boolean;
  currentExternalTask?: any;
  cueNotesList: ICueNoteResponse[];
  taskStickChosen: ITaskStickChosen;
  sidePanelChoosen: boolean;
  cueRecipientList: ICueRecipient[];
  cueNoteId?: number;
  createdCueNoteData?: ICueNote;
  lastRecipientDetails?: ICueRecipient;
  checkPanelChange: boolean;
  isAMIEWebRequest: boolean;
  isExternalTaskUpdated: boolean;
  cueNoteReplyParent?: ICueNoteParent;
  isCueNoteCreated?: boolean;
  cueCreatedData?: ICueNoteContent;
  notificationLogData: any;
  isUserCueTo: boolean;
  currentUserCued: boolean;
  entityId?: IEntityId;
  activityFeedFilterList: any[];
  isFilterApplied?: boolean;
  cueListforPlacementOptions: ICueListForPlacement;
  subCategory1: IExternalTaskSubType[];
  subCategory2: IExternalTaskSubType[];
  internalTaskdata: IFormatedInternalTaskData;
  externalTaskdata: IFormatedExternalTaskData;
  isDetailsModelOpen: boolean;
  emailDeliveryAssociateRecors?: IEmailDeliveryAssociateRecords[];
  versionUpdated?: StatusUpdateModalVersion | null;
  isStatusAppliedAll: boolean;
  dueDateFirstView?: IDueDateView[];
  isUpdatingTask?: boolean;
  subTaskName?: boolean;
  notifications: INotifications | null;
  dismissNotificationId?: string;
  requestedAlertCategory?: string;
}
export interface INotifications {
  unreadTaskNotifications?: number;
  unreadOrderMatchNotifications?: number;
  unreadAlertNotifications?: number;
}

export interface ITaskStickChosen {
  entity: TaskEntity;
  stick: number | null;
}

export interface IEntityId {
  placementId: number;
  candidateId: number;
  orderId?: string;
  facilityId?: number;
  companyId?: number;
}

export interface IGridData {
  taskId: number;
  taskName: string;
  taskCategory: string;
  taskStatusId: number;
  rootTaskId: number;
  createdByFirstName: string;
  createdByLastName: string;
  dueDate: Moment | Date | string;
  placementId: number;
  orderId: number;
  candidateFirstName: string;
  candidateLastName: string;
  candidateId: number;
  facilityName: string;
  facilityId: number;
  companyId: number;
  companyBrandName: string;
  assignedToList: any[];
  comments: Array<ITaskComment>;
}

export interface IGridPaging {
  pageSize: number;
  pageNumber: number;
  total: number;
}

export interface IAllEmployees {
  employeeFirstName: string;
  employeeId: string;
  employeeLastName: string;
  employeeRoleId: string;
  employeeRoleName: string;
}

export interface IPlacementEmployees {
  employeeId: string;
  firstName: string;
  lastName: string;
  roleDescription: string;
}

export interface ITaskComments {
  taskId?: number;
  subject?: string;
  comment?: string;
  createdBy?: IUser;
  sentDate?: string | Date | Moment;
  dueDate?: string | Date | Moment;
  webTask?: boolean;
  attachments?: ITaskAttachmentList[];
  tos?: any[];
  ccs?: any[];
  taskStatusDetails?: any;
  statusArray?: IStatusChip[];
  originalTaskId?: number;
  rootTaskId?: number;
  companyId?: number;
  taskCategory?: number;
  taskCategoryText?: string;
  taskNameId?: number;
  currentUserStatusDetails?: any;
  taskCoverageAction?: ICoverageActionDetails[];
  taskPriorityId: number;
  sharedDeskAssociatedUser?: ISharedDeskUserAction;
}

export interface ITaskAttachmentList {
  attachmentUrl: string;
  attachmentSize?: string;
  attachmentType?: string;
  isCommentAttachment?: boolean;
  saasAttachmentUrl: string;
  taskId?: number;
}

export class IUser {
  id?: number;
  firstName?: string;
  lastName?: string;
  statusId?: number;
  constructor(_firstName: string, _lastName: string, _id?: number, _statusId?: number) {
    this.firstName = _firstName;
    this.lastName = _lastName;
    if (_id) this.id = _id;
    if (_statusId) this.statusId = _statusId;
  }
}

export enum CoverageAction {
  UPDATE_STATUS_COMPLETE = 1,
  UPDATE_STATUS_CANCEL,
  REVERT_STATUS,
  EDIT_TASK,
}

export interface ICoverageData {
  openDialog: boolean;
  decision?: boolean;
  action?: CoverageAction;
  coveredUser?: IUser;
}

export interface IInternalTaskModalDetails {
  rootTaskId?: number;
  placementId?: number;
  taskId?: number;
  assignedTo?: IUser[];
  createdBy?: IUser;
  candidate?: IUser;
  task?: string;
  taskName?: string;
  companyId?: number;
  companyBrandName?: string;
  orderId?: number;
  facilityId?: number;
  facilityName?: string;
  description?: string;
  dueDate?: string | Date | Moment;
  taskCategory?: any;
  statusId?: number;
  createdDate?: string | Date | Moment;
  cc?: IUser[];
  reason?: string;
  statusUpdateReason?: string;
  statusUpdatedByName?: string;
  statusUpdatedDate?: string | Date | Moment;
  statusUpdatedById?: number;
  statusUpdateReasonId?: number;
  role?: string;
  placementStatus?: string;
  placementStatusId?: number;
  taskSource?: number;
  attachments?: ITaskAttachmentList[];
  candidateId?: number;
  taskNameId?: number;
  isAMIEWebRequest?: boolean;
  subCategory1: ISubCategoryData;
  subCategory2: ISubCategoryData;
  taskStatusDetails?: any;
  statusArray?: IStatusChip[];
  taskCoverageActions?: ICoverageActionDetails[];
  comments?: ITaskComment[];
  taskPriorityId: number;
  sharedDeskAssociatedUsers?: ISharedDeskUserAction[];
}

export interface IExternalTaskModalDetails {
  rootTaskId?: number;
  placementId?: number;
  taskId?: number;
  assignedTo?: IUser;
  createdBy?: IUser;
  candidate?: IUser;
  task?: string;
  taskName?: string;
  companyId?: number;
  companyBrandName?: string;
  orderId?: number;
  facilityId?: number;
  facilityName?: string;
  description?: string;
  dueDate?: string | Date | Moment;
  taskCategory?: any;
  statusId?: number;
  createdDate?: string | Date | Moment;
  cc?: IUser[];
  reason?: string;
  statusUpdateReason?: string;
  statusUpdatedByName?: string;
  statusUpdatedDate?: string | Date | Moment;
  statusUpdatedById?: number;
  statusUpdateReasonId?: number;
  role?: string;
  placementStatus?: string;
  placementStatusId?: number;
  taskSource?: number;
  attachments?: ITaskAttachmentList[];
  candidateId?: number;
  taskNameId?: number;
  isAMIEWebRequest?: boolean;
  subCategory1: ISubCategoryData;
  subCategory2: ISubCategoryData;
  taskCategoryId?: number;
}

export interface ISubCategoryData {
  subTypeId: SubTypeId;
  subTypeText: String;
  subTypeDataId: number;
  subTypeDataText: string;
}
export interface ITaskFilterBody {
  Id: number | undefined;
  statusIds: any[] | undefined;
  assignedToId: number | null | undefined;
}

export interface ICategoryTask {
  taskId: number;
  taskName: string;
}
export interface ITaskCategorizedList {
  id: string | number;
  title: string;
  groupBy: string;
  categoryId: number;
  category: string;
  taskId: number;
  taskName: string;
}

export interface IGroupedListItem {
  name: string;
  taskCount: number;
  id: number;
  entityId?: number;
  placementStatus?: string;
  placementStartDate?: string | Date | Moment;
}

export interface IGroupedList {
  paging?: IGridPaging;
  items?: IGroupedListItem[];
}
export interface ITaskGridOptions {
  AssignedTo: string;
  Task: string;
  Status: any;
  DueDate: string;
  CandidateId: number;
  CandidateName: string;
  PlacementId: number;
  OrderId: number;
  FacilityId: number;
  FacilityName: string;
  CreatedBy: string;
}

export interface ITaskGridBody {
  entity: number;
  pageNumber: number;
  pageSize: number;
  candidateId: number[];
  placementId: number[];
  facilityId: number[];
  orderId: number[];
  taskNameId: number[];
  taskCategory: number[];
  dueDateStart: Moment | Date | String | null;
  dueDateEnd: Moment | Date | String | null;
  assignedTo: number[];
  createdBy: number[];
  taskStatusId: number[];
  sortedColumn: { column: string; direction: any } | undefined | null;
  translation?: TFunction;
}
export interface IGridColumns extends Omit<ICustomHeader, 'title'> {
  title:
    | 'assignedTo'
    | 'task'
    | 'status'
    | 'dueDate'
    | 'candidateId'
    | 'candidateName'
    | 'placementId'
    | 'orderId'
    | 'facilityId'
    | 'facilityName'
    | 'createdBy'
    | 'taskCategory'
    | 'statusId'
    | 'rootTaskId'
    | 'assignedToIds'
    | 'assignedToId'
    | 'assignedToAttributes';
}

export interface ITaskFilter {
  taskName?: string[];
  taskCategories?: string[];
  placementIds?: string[];
  candidateName?: string[];
  candidateIds?: string[];
  facility?: string[];
  facilityIds?: string[];
  orderIds?: string[];
  taskStatusIds?: string[];
  dueDateStart?: string;
  dueDateEnd?: string;
  assignedToIds?: string[];
  createdBy?: string[];
}

export interface ICueNoteResponse {
  rootTaskId?: number;
  originalTaskId?: number;
  taskId?: number;
  sentDate?: Moment | Date | string;
  senderId?: number;
  sentByFirstName?: string;
  sentByLastName?: string;
  text?: string;
  placementId?: number;
  statusId?: TaskStatus;
  recipientId?: number;
  recipientFirstName?: string;
  recipientLastName?: string;
  lastRecipientId?: number;
  parentCueNote?: ICueNoteParent;
  coverageDetail?: ICoverageDetails;
  sharedDeskAssociatedUser?: IEmployeeSharedProfile;
}

export interface ICueListForPlacement {
  placementId: number;
  currentUserId?: number;
  createdStartDate?: Date | Moment;
  createdEndDate?: Date | Moment;
  createdBy?: number[];
  cuedTo?: number[] | undefined;
  repliedToId?: number[];
  alertUser?: boolean;
}
export interface ICueNoteData {
  id: Number;
  createdBy: IUser;
  content: String;
  assignedTo: IUser;
  period: String;
  timestamp: String;
  dateCreated: Date;
  parentCueNote?: ICueNoteParent;
}

export interface ITaskNotificationPopupProps {
  id: string;
  name: string;
  taskId: string;
  body: string;
  attachments: string[] | null;
  associateRecords?: any[] | null;
  useSubType: string;
}

export interface INotificationFeedResponse {
  contentProps?: any;
  multiLine: boolean;
  channel: string;
}

export enum TaskNotificationType {
  TASKCREATED = 1,
  NEWCOMMENT,
  COMPLETED,
  CANCELLED,
  TASKEDITED,
  CUENOTE,
  CUENOTEREPLY,
  ORDERMATCH,
  SUBTASKCREATED,
  SUBTASKCOMPLETED,
  SUBTASKCANCELLED,
}

export enum TaskUserType {
  assignedTo,
  createdBy,
  cc,
}

export const NotificationSender = {
  name: 'AMIEWeb',
  email: '',
  phoneNumber: '',
  userId: '14583',
  senderType: '',
};

export interface ICueNote {
  senderId: number;
  placementId: number;
  text: string;
  recipientId?: number;
  parentCueNote?: ICueNoteParent;
  statusId: number;
  coverageDetail?: ICoverageDetails;
  sharedDeskAssociatedUser?: IEmployeeSharedProfile
};

export interface ICoverageDetails {
  coveredByEmployeeId: number;
  coveredByEmployeeFirstName: string;
  coveredByEmployeeLastName: string;
}

export interface ICueNoteContent {
  senderId: number;
  placementId: number;
  text: string;
  recipient?: IUser;
  statusId: number;
}

export interface ICueNoteParent {
  cueNoteLegacyTaskId?: number;
  sentById?: number;
  sentByFirstName?: string;
  sentByLastName?: string;
  recipientId: number;
  recipientFirstName: string;
  recipientLastName: string;
  sentDate?: Moment | Date | string;
  text?: string;
  coverageDetail: ICoverageDetails;
  sharedDeskAssociatedUser?: IEmployeeSharedProfile
}

export const Task = 'Task';

export const NotificationBrand = '';

export const CuedTo = 'Cued To';
export const RepliedTo = 'Replied To';

export const defaultFilterValues = [
  {
    id: '1',
    value: '1',
    name: 'New',
  },
  {
    id: '2',
    value: '2',
    name: 'Pending',
  },
];
export const defaultFilterApiValues = [TaskStatus.New, TaskStatus.Pending];

export interface ICueRecipient {
  placementId: number;
  employeeId: number;
  userName: string;
  firstName: string;
  lastName: string;
  coverageDetails: IRequestCoverage[];
}

export interface IOptionsList {
  id: string;
  value: string;
  name: string;
}

export interface IExternalTaskSubType {
  id: number;
  label: string;
  subTypeId: number;
}

export interface ISubCategoryDependentData {
  subTypeId: number;
  subTypeText: string;
  subTypeDataId: number;
  subTypeDataText: string;
}

export interface IEmailDeliveryAssociateRecords {
  name?: string;
  value?: string;
}

export interface ICoverageActionDetails {
  coveredByEmployeeId: number;
  coveredByEmployeeFirstName: string;
  coveredByEmployeeLastName: string;
  coveredForEmployeeId: number;
  coveredForEmployeeFirstName?: string;
  coveredForEmployeeLastName?: string;
  actionById: number;
  actionDate?: string | Date | Moment;
  actionType: TaskCoverageActionType;
  actionTypeDescription?: string;
}

export interface IUpdateNotificationReadStatusAction {
  notificationCategory?: NotificationCategory;
  idArr: string[];
  employeeId: number;
  currentPage?: number;
  disableGetActivityFetch?: boolean;
}

export interface IHandleBulkMarkReadAction {
  idArr: string[];
  notificationCategory: string;
}

export interface IEditSubTask {
  updatedSubTask: ITaskComments;
  initialSubTask: ITaskComments;
}

export enum TaskPriority {
  Low = 1,
  Normal = 2,
  High = 3,
}
export const TaskPriorityList = [
  { name: 'High Priority', value: TaskPriority.High.toString() },
  { name: 'Normal Priority', value: TaskPriority.Normal.toString() },
  { name: 'Low Priority', value: TaskPriority.Low.toString() },
];
export const TaskPriorityIdList = Object.values(TaskPriority) as number[];
