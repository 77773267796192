/* eslint-disable tss-unused-classes/unused-classes */
import { makeStyles } from 'tss-react/mui';

export const useWorkExperienceStyles = makeStyles()(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  boxInlineHeadLabelTxt: {
    'font-weight': '600',
    'margin-top': '0.3rem',
  },
  container: {
    border: '1px solid #ECECEC',
    borderRadius: '4px 4px 4px 4px',
    background: '#fff',
    display: 'flex',
    flexDirection: 'column',
  },
  grid: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleBar: {
    display: 'flex',
    borderBottom: '1px solid #ECECEC',
    background: '#FCFCFD',
    color: '#333333',
    fontSize: 16,
    padding: '6px',
    fontWeight: 500,
  },
  children: {
    padding: 12,
  },
  icon: {
    paddingLeft: 6,
  },
  profileInlineLink: {
    color: '#006FB9',
    'font-weight': '800',
    'padding-left': '0.3rem',
    'text-decoration': 'underline',
    cursor: 'pointer',
  },
  inlineLink: {
    color: '#006FB9',
    fontSize: '14px',
    cursor: 'pointer',
  },
  profileInlineLinkDisabled: {
    color: '#006FB9',
    'font-weight': '800',
    'padding-left': '0.3rem',
    'text-decoration': 'underline',
    pointerEvents: 'none',
    opacity: 0.4,
  },
  normalText: {
    'font-weight': 'normal',
    width: 'max-content',
  },
  rowData: {
    textAlign: 'left',
    fontWeight: 400,
  },
  sectionDivider: {
    height: '12px',
  },
  inlineBoxEditItemPadding: {
    padding: '0 0.4rem',
  },
  inlineBoxEditMainCheckItem: {
    'padding-left': '0',
    'padding-right': '0.2rem',
    '& svg': {
      width: '25px',
      height: '25px',
    },
  },
  inlineBoxEditInputItem: {
    'border-top': '0',
    'border-left': '0',
    'border-right': '0',
    'border-bottom-color': '#bababa',
    '&:focus-visible': {
      outline: 'none',
      background: '#f2f2f2',
    },
  },
  inlineBoxEditMainLblItem: {
    'padding-right': '0.8rem',
    paddingLeft: '3px',
  },
  inlineBoxEditMainAcceptItem: {
    'padding-right': '0.2rem',
    '& svg': {
      color: '#006FB9',
      width: '20px',
      height: '20px',
      cursor: 'pointer',
    },
  },
  inlineBoxEditMainRejectItem: {
    'padding-left': '0.2rem',
    '& svg': {
      color: '#8f9a8e',
      width: '20px',
      height: '20px',
      cursor: 'pointer',
    },
  },
  title: {
    color: '#0a090a !important',
    fontSize: '16px !important',
    fontWeight: 500,
    paddingLeft: '12px',
  },
  titleIcon: {
    paddingRight: '12px',
  },
  progreesBar: {
    backgroundColor: '#d0d0d0',
  },
  ProgreesBarS: {
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: 'Green !important',
    },
  },
  tooltipPlacementBottom: {
    backgroundColor: '#003C69',
    margin: 0,
    padding: '5px',
    color: '#fff',
    fontWeight: 100,
    fontSize: '8px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  tooltipBottom: {
    backgroundColor: '#fff',
    margin: 0,
    padding: '5px',
    color: '#050505',
    fontWeight: 100,
    fontSize: '8px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  tooltipAlign: {
    margin: '0px 0px 0px -10px',
  },
  alignContainer: {
    padding: '3px',
  },
  alignExperienceContainer: {
    padding: '3px 3px 3px 0px',
  },
  button: {
    cursor: 'pointer',
    color: '#006FB9',
    justifyContent: 'flex-start',
    marginLeft: '3px',
    fontWeight: 450,
    padding: '0px',
    textDecoration: 'underline',
    margin: '0px !important',
    '&:hover': {
      background: 'none',
      textDecoration: 'underline',
    },
  },
  countButton: {
    cursor: 'default',
    color: '#006FB9',
    justifyContent: 'flex-start',
    marginLeft: '3px',
    fontWeight: 450,
    padding: '0px',
    textDecoration: 'underline',
    margin: '0px !important',
    '&:hover': {
      background: 'none',
      textDecoration: 'underline',
    },
  },
  buttonNoCount: {
    cursor: 'context-menu',
    color: '#534c4c',
    justifyContent: 'flex-start',
    marginLeft: '3px',
    fontWeight: 450,
    padding: '0px',
    margin: '0px !important',
    '&:hover': {
      background: 'none',
    },
  },
  content: {
    paddingTop: '5px',
  },
  customScroll: {
    overflowX: 'hidden',
    overflowY: 'scroll',
    height: '180px',
  },
  customScrollWorkExpGap: {
    overflowX: 'hidden',
    overflowY: 'scroll',
  },
}));
