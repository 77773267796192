import { SelectPlacementStatus } from '@AMIEWEB/Placement/CreatePlacement/Status/SelectPlacementStatus';
import { Grid } from 'amn-ui-core';
import React from 'react';
import { WhatsNext } from '@AMIEWEB/Placement/CreatePlacement/Status/WhatsNext';
import { Risks } from '@AMIEWEB/Placement/PlacementDetails/PlacementTabPanel/PlacementSummaryTab/Risks';
import { CreateActions } from './CreateActions';
import { makeStyles } from 'tss-react/mui';
import { useDispatch, useSelector } from 'react-redux';
import { DefaultCurrentPlacementStatus, isNegativeStatus } from '@AMIEWEB/Placement/NextPlacementStatus/StatusDefaults';
import { selectPlacementStatus } from 'store/redux-store/placement-status/selectors';
import { usePromiseTracker } from 'react-promise-tracker';
import { selectCreatePlacementOrderType, selectNewPlacementChoice } from 'store/redux-store/new-placement/selectors';
import { IPlacementStatus, PlacementOrderType } from 'app/models/Placement/PlacementDetails';
import { StatusChangeConfirmDialog } from '@AMIEWEB/Placement/NextPlacementStatus/StatusChangeConfirmDialog';
import { newPlacementActions } from 'store/redux-store/new-placement/slice';
import { getSaveRequest, getUpdateRequest } from '@AMIEWEB/Placement/CreatePlacement/helper';
import { useFormContext } from 'react-hook-form';
import { selectUser } from 'oidc/user.selectors';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { useTranslation } from 'react-i18next';
import CueNotesDialog from '../CuteNotesConfirmation/CueNotesDialog';
import { useDecision } from '@optimizely/react-sdk';
import { ff_placement_cue_note_form_fields } from 'app/constants/FeatureFlags/Placement/Keys';
import { formatFormDataBeforeSave } from '../helper';

const useStyles = makeStyles()(() => ({
  root: {
    position: 'relative',
    height: '100%',
  },
  actions: {
    position: 'absolute',
    backgroundColor: '#F4F5F7',
    bottom: '-18px',
    width: '100%',
  },
}));

export const CreatePlacementStatus = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const {
    currentPlacementStatus = DefaultCurrentPlacementStatus,
    availablePlacementStatus,
    failedRequirements,
    updatePlacementStatusResponse,
  } = useSelector(selectPlacementStatus);
  const { userInfo } = useSelector(selectUser);
  const { candidate, order, newPlacementId } = useSelector(selectNewPlacementChoice);
  const placementOrderType = useSelector(selectCreatePlacementOrderType);
  const [showCueNotesConfirmation] = useDecision(ff_placement_cue_note_form_fields);
  const [selectedStatus, setSelectedStatus] = React.useState<IPlacementStatus | undefined>();

  const { promiseInProgress: gettingOrder } = usePromiseTracker({ area: 'new-placement-get-order', delay: 0 });
  const { promiseInProgress: isSubmitting } = usePromiseTracker({ area: 'new-placement-submit', delay: 0 });
  const { promiseInProgress: isValidating } = usePromiseTracker({ area: 'new-placement-get-validations', delay: 0 });
  const { promiseInProgress: gettingCandidate } = usePromiseTracker({
    area: 'new-placement-get-candidate',
    delay: 0,
  });

  const dispatch = useDispatch();

  const isFetchingRequirements = React.useMemo(
    () => gettingCandidate || gettingOrder || isSubmitting || isValidating,
    [gettingCandidate, gettingOrder, isSubmitting, isValidating],
  );

  const availableStatuses = React.useMemo(
    () => (candidate && order ? availablePlacementStatus : []),
    [candidate, order, availablePlacementStatus],
  );

  const {
    getValues,
    formState: { dirtyFields, isDirty },
  } = useFormContext();

  const onStatusSelected = (status: IPlacementStatus) => {
    setSelectedStatus(status);
  };

  const submitStatusChange = (status: IPlacementStatus) => {
    const formData = formatFormDataBeforeSave(getValues());
    if (isNegativeStatus(status)) {
      // Update status to declined. Save any fields that were modified
      if ((newPlacementId ?? 0) > 0)
        dispatch(
          newPlacementActions.declinePlacement({
            placementId: newPlacementId ?? -1,
            placementStatus: status,
            placementOrderType: placementOrderType || PlacementOrderType.Default,
            candidateId: candidate?.candidateId ?? -1,
            placementUpdate: isDirty
              ? {
                  updates: getUpdateRequest(
                    formData as any,
                    Object.keys(dirtyFields),
                    userInfo?.employeeId,
                    newPlacementId,
                  ),
                }
              : undefined,
          }),
        );
    } else {
      dispatch(
        newPlacementActions.savePlacementAction(
          getSaveRequest({
            ...(formData as any),
            startDate: order?.startDate,
            endDate: order?.endDate,
            userId: userInfo?.employeeId,
            status,
            placementOrderType: placementOrderType ?? PlacementOrderType.Default,
          }),
        ),
      );
    }
  };

  /** TODO: Push code to saga */
  React.useEffect(() => {
    if (updatePlacementStatusResponse?.success) {
      // successfully created/updated placement
      setSelectedStatus(undefined);
      if (updatePlacementStatusResponse.props?.placementCreated && newPlacementId !== -1) {
        // new placement created
        dispatch(
          globalActions.setSnackBar({
            message: t('placement.create.snackbar.success', { placementId: newPlacementId }),
            severity: 'success',
            portalRefId: 'create-placement-dialog',
          }),
        );
      } else {
        // placement updated
        dispatch(
          globalActions.setSnackBar({
            message: t('placement.profile.placementStatusUpdate.successSnackbar'),
            severity: 'success',
            portalRefId: 'update-placement-dialog',
          }),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatePlacementStatusResponse, newPlacementId]);

  return (
    <Grid container flexDirection="column" flexWrap="nowrap" spacing={2} classes={{ root: classes.root }}>
      <Grid item sx={{ paddingBottom: '8px' }}>
        <SelectPlacementStatus
          loading={isFetchingRequirements}
          currentPlacementStatus={currentPlacementStatus}
          availablePlacementStatus={availableStatuses}
          failedRequirements={failedRequirements}
          onStatusSelected={onStatusSelected}
          inlineDense
        />
      </Grid>
      <Grid item>
        <Grid
          container
          flexDirection="column"
          flexWrap="nowrap"
          spacing={2}
          sx={{ maxHeight: 500, overflow: 'auto', paddingBottom: '24px' }}
        >
          <Grid item>
            <Risks expanded={false} inlineDense loading={isValidating} />
          </Grid>
          <Grid item>
            <WhatsNext
              loading={isFetchingRequirements}
              currentPlacementStatus={currentPlacementStatus}
              availablePlacementStatus={availableStatuses}
              failedRequirements={failedRequirements}
              onStatusSelected={onStatusSelected}
              inlineDense
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} classes={{ root: classes.actions }}>
        <CreateActions />
      </Grid>
      {selectedStatus && (
        showCueNotesConfirmation?.enabled ? (
        <CueNotesDialog
          open={!!selectedStatus}
          status={selectedStatus}
          selectedStatus={{ ...selectedStatus, placementOrderType: placementOrderType ?? PlacementOrderType.Default }}
          handleConfirmation={submitStatusChange}
          onClose={() => setSelectedStatus(undefined)}
          submitting={isSubmitting}
        />
        ) : (
          <StatusChangeConfirmDialog
            open={!!selectedStatus}
            status={selectedStatus}
            submitting={isSubmitting}
            error={updatePlacementStatusResponse && updatePlacementStatusResponse.success === false}
            type={updatePlacementStatusResponse?.successType}
            onSubmit={submitStatusChange}
            onClose={() => setSelectedStatus(undefined)}
            closeButton={false}
          />
        )
      )}
    </Grid>
  );
};
