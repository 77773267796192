import React from 'react';
import { missingField } from 'app/constants';
import { MoreTextBlock } from '../../CustomComponents/MoreTextBlock';

export const OrderInfoInternalNotes = ({ ...props }) => {
  return (
    <div id="placement-order-info-internal-notes" data-testid="order-info-internal-note-item">
      <MoreTextBlock
        title={''}
        text={props.orderInformation?.internalNotes ? props.orderInformation?.internalNotes : `${missingField}`}
        charLimit={170}
        id={'placementSummaryDetailsTabsInternalNotesDetailsContainer'}
      />
    </div>
  );
};
