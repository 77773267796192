import { ISelection } from 'app/ComponentLibrary/Filter/CustomSelect';
import { FilterCompTypes } from 'app/ComponentLibrary/Filter/utils';
import { dropDownFilterOptions, getFacilitySearchLookups, getOrderIdSearchLookups } from '../helper';
import { getSearchLookups } from 'app/services/SharedServices/SharedServices';
import { orderSearchFilterName } from './SearchUtils';
import {
  getFilteredCities,
  getFilteredOptions,
  getFilteredZips,
  removeDuplicateZips,
  upgradeBasedOnSearchOption,
} from '../Candidate/FilterUtils';
import i18next from 'i18next';
import { AutocompleteChangeReason } from 'amn-ui-core';
import { candidateSearchFilterName } from '../Candidate/SearchUtils';
import { totalCountNodes } from '@AMIEWEB/Common/TreeView/TreeViewHelpers';

export const EducationOpts = [
  { id: 0, value: 'clinicalFellowshipYear', name: 'Clinical Fellow', labelPrefix: 'Education' },
  { id: 1, value: 'newGrad', name: 'New Grad', labelPrefix: 'Education' },
];

export const MFSOpts = [
  { id: 0, value: 1, name: 'Yes', labelPrefix: 'MFS' },
  { id: 1, value: 2, name: 'No', labelPrefix: 'MFS' },
];

export const PaysGwwOpts = [
  { id: 0, value: true, name: 'Yes', labelPrefix: 'GWW' },
  { id: 1, value: false, name: 'No', labelPrefix: 'GWW' },
];

export const VaccinatedOpts = [
  { id: 0, value: 1, name: 'Yes', labelPrefix: 'Vaccinated' },
  { id: 1, value: 2, name: 'No', labelPrefix: 'Vaccinated' },
];

export const PositionOpts = [
  {
    id: 0,
    value: { min: 0, max: 0 },
    name: '0',
    labelPrefix: i18next.t('search.globalSearch.order.filterNames.positions'),
  },
  {
    id: 1,
    value: { min: 1, max: 999 },
    name: '>0',
    labelPrefix: i18next.t('search.globalSearch.order.filterNames.positions'),
  },
  {
    id: 2,
    value: { min: 1, max: 10 },
    name: '1-10',
    labelPrefix: i18next.t('search.globalSearch.order.filterNames.positions'),
  },
  {
    id: 3,
    value: { min: 10, max: 50 },
    name: '10-50',
    labelPrefix: i18next.t('search.globalSearch.order.filterNames.positions'),
  },
  {
    id: 4,
    value: { min: 50, max: 99 },
    name: '50-99',
    labelPrefix: i18next.t('search.globalSearch.order.filterNames.positions'),
  },
  {
    id: 5,
    value: { min: 100, max: 999 },
    name: '>99',
    labelPrefix: i18next.t('search.globalSearch.order.filterNames.positions'),
  },
] as unknown as ISelection[];

export async function getOrderPriorities(options, keyword) {
  let result = [];
  if (keyword) {
    const filteredData = options.filter(
      opt =>
        opt.value.toLowerCase() === keyword.toLowerCase() ||
        opt.name.toLowerCase().includes(keyword.toLowerCase()) ||
        opt.id === 0,
    );
    if (filteredData.length > 1) {
      result = filteredData;
    }
  } else {
    result = options;
  }
  return result?.map(opt => ({ ...opt, labelPrefix: `${opt.value}` }));
}

export class OrderFilter {
  constructor(public filterSpecs: any, public styleClasses: any, public gridStateFilters: any) {}

  getOrderFilterSpecs = () => {
    return [
      {
        filterName: i18next.t('home.pricingWorkDesk.filterHeading.OID'),
        name: orderSearchFilterName.orderIds,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        fetchOptions: getOrderIdSearchLookups,
        isMultiSelect: true,
        applyOnClickAway: true,
        applyOnEnter: true,
        searchOnCharacterCount: 1,
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.division'),
        name: orderSearchFilterName.division,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        isMultiSelect: true,
        // Code: Options are prefetched on page load and manipulated via getFilteredOptions - for search query operation with select all feature
        fetchOptions: async key => await getFilteredOptions(this.filterSpecs.divisionOpts, key),
        applyOnClickAway: true,
        applyOnEnter: true,
        isSelectAll: true,
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.businessRelationship'),
        name: orderSearchFilterName.businessRelationship,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        isMultiSelect: true,
        // Code: Options are prefetched on page load and manipulated via getFilteredOptions - for search query operation with select all feature
        fetchOptions: async key => await getFilteredOptions(this.filterSpecs.businessRelationshipsOpts, key),
        applyOnClickAway: true,
        applyOnEnter: true,
        isSelectAll: true,
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.facilityStatus'),
        name: orderSearchFilterName.facilityStatus,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        isMultiSelect: true,
        // Code: Options are prefetched on page load and manipulated via getFilteredOptions - for search query operation with select all feature
        fetchOptions: async key => await getFilteredOptions(this.filterSpecs.facilityStatusesOpts, key),
        applyOnClickAway: true,
        applyOnEnter: true,
        isSelectAll: true,
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.type'),
        name: orderSearchFilterName.orderType,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        isMultiSelect: true,
        // Code: Options are prefetched on page load and manipulated via getFilteredOptions - for search query operation with select all feature
        fetchOptions: async key => await getFilteredOptions(this.filterSpecs.orderTypesOpts, key),
        applyOnClickAway: true,
        applyOnEnter: true,
        isSelectAll: true,
      },
      {
        filterName: i18next.t('search.globalSearch.candidate.filterNames.skillSet'),
        name: candidateSearchFilterName.candidateSkillSet,
        type: FilterCompTypes.CANDIDATESKILLSET,
        isMultiSelect: true,
        options: [],
        placeholder: 'Select',
        selectAllValue: 'All',
        version2: true,
        returnsObjectAsValue: true,
        size: 'large',
        isExact: true,
        customSelectedCount: value => totalCountNodes(value),
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.state'),
        name: orderSearchFilterName.state,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        isMultiSelect: true,
        options: this.filterSpecs.statesOpts,
        // Code: Options are prefetched on page load and manipulated via getFilteredOptions - for search query operation with select all feature
        fetchOptions: async key => await getFilteredOptions(this.filterSpecs.statesOpts, key),
        isSelectAll: true,
        compactValues: this.filterSpecs.compactStates,
        handleChangeCallback: (newValue = [], reason: AutocompleteChangeReason, { option }, onChange) => {
          //click on selectAllValue
          if (option.value === 'All') {
            onChange(reason === 'selectOption' ? this.filterSpecs.statesOpts : []);
            return;
          }

          const compactValuesSelected = newValue.reduce(
            (results, item) => (item.isCompactOpt ? [...results, ...item.value] : results),
            [],
          );
          let newSelections = this.filterSpecs.statesOpts.reduce((results, item) => {
            let isItemSelected =
              newValue.some(x => x.value === item.value) || compactValuesSelected.some(x => x.value === item.value);

            return isItemSelected ? [...results, item] : results;
          }, []);

          // Remove all child opts if parent is de-selected
          if (reason === 'removeOption' && option.isCompactOpt) {
            newSelections = newSelections.reduce(
              (results, item) => (option.value.some(opt => opt.name === item.name) ? results : [...results, item]),
              [],
            );
            const compactValuesPresent = newSelections.filter(item => item?.isCompactOpt === true);
            compactValuesPresent.forEach(opt => {
              newSelections = [...newSelections, ...opt.value];
            });
            const uniqueValues = newSelections.filter((val, index, self) => {
              return self.findIndex(p => p.value === val.value) === index;
            });
            newSelections = uniqueValues;
          }

          // Splice compact parents and child elements if child gets removed
          else if (reason === 'removeOption' && !option.isCompactOpt) {
            const parentIndex = newSelections.findIndex(
              item => item.isCompactOpt && item.value.some(x => x.value === option.value),
            );
            parentIndex !== -1 && newSelections.splice(parentIndex, 1);

            const childIndex = newSelections.findIndex(item => item.value === option.value);
            childIndex !== -1 && newSelections.splice(childIndex, 1);
          }

          onChange(newSelections);
        },
        filterOptions: (options, { inputValue }) => {
          return dropDownFilterOptions(options, inputValue);
        },
        applyOnClickAway: true,
        applyOnEnter: true,
      },
      {
        filterName: i18next.t('search.globalSearch.candidate.filterNames.city'),
        name: orderSearchFilterName.city,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        fetchOptions: async (key: string) => {
          if (!key) return getFilteredCities(this.filterSpecs.cityOptions, key);
          const cityOptions = await upgradeBasedOnSearchOption(key, 'c', this.gridStateFilters);
          return cityOptions;
        },
        isMultiSelect: true,
        isSelectAll: true,
        applyOnClickAway: true,
        applyOnEnter: true,
      },
      {
        filterName: i18next.t('search.globalSearch.candidate.filterNames.zip'),
        name: orderSearchFilterName.zip,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        fetchOptions: async (key: string) => {
          if (!key) return getFilteredZips(this.filterSpecs.zipOptions, key);
          const zipOptions = await upgradeBasedOnSearchOption(key, 'z', this.gridStateFilters);
          const uniqueZips = removeDuplicateZips(zipOptions); 
          return uniqueZips;
        },
        isMultiSelect: false,
        hideClearAll: true,
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.radius'),
        name: orderSearchFilterName.radius,
        type: FilterCompTypes.TEXTFIELDNUMBER,
        placeholder: 'Radius',
        disabled: this.gridStateFilters?.filters?.zipCode ? false : true,
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.facility'),
        name: orderSearchFilterName.facility,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        fetchOptions: getFacilitySearchLookups,
        isMultiSelect: true,
        isSelectAll: true,
        applyOnClickAway: true,
        applyOnEnter: true,
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.mspClient'),
        name: orderSearchFilterName.mspClient,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        // Code: Options are prefetched on page load and manipulated via getFilteredOptions - for search query operation with select all feature
        fetchOptions: async key => await getFilteredOptions(this.filterSpecs.mspClientOpts, key),
        isSelectAll: true,
        isMultiSelect: true,
        applyOnClickAway: true,
        applyOnEnter: true,
      },

      {
        filterName: i18next.t('search.globalSearch.order.filterNames.contractAffiliates'),
        name: orderSearchFilterName.contractAffiliates,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        fetchOptions: async key => await getSearchLookups('contractaffiliates', key),
        isMultiSelect: true,
        isSelectAll: true,
        applyOnClickAway: true,
        applyOnEnter: true,
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.orderAgeDateRange'),
        name: orderSearchFilterName.orderAgeDateRange,
        placeholder: i18next.t('search.globalSearch.order.filterNames.orderAgeDateRange'),
        version3: true,
        type: FilterCompTypes.DATERANGEV2,
        variant: 'outlined',
        secondaryLabel: i18next.t('search.globalSearch.order.filterNames.orderAgeDateRange'),
        useMaxWidth: true,
        maxWidth: 210,
        clickAway: true,
        disablePortal: true,
        quickSelect: false,
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.orderEndDateRange'),
        name: orderSearchFilterName.orderEndDateRange,
        placeholder: i18next.t('search.globalSearch.order.filterNames.orderEndDateRange'),
        version3: true,
        type: FilterCompTypes.DATERANGEV2,
        variant: 'outlined',
        secondaryLabel: i18next.t('search.globalSearch.order.filterNames.orderEndDateRange'),
        useMaxWidth: true,
        maxWidth: 210,
        clickAway: true,
        disablePortal: true,
        quickSelect: false,
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.orderBillRate'),
        name: orderSearchFilterName.orderBillRate,
        type: FilterCompTypes.NUMBERRANGE,
        placeholder: i18next.t('search.globalSearch.order.filterNames.orderBillRate'),
        numberRangeType: 'currency',
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.paysGww'),
        name: orderSearchFilterName.paysGww,
        placeholder: i18next.t('search.globalSearch.order.filterNames.paysGww'),
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        options: PaysGwwOpts,
        hideClearAll: true,
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.guaranteedHours'),
        name: orderSearchFilterName.guaranteedHours,
        type: FilterCompTypes.NUMBERRANGE,
        placeholder: i18next.t('search.globalSearch.order.filterNames.guaranteedHours'),
        numberRangeType: 'hours',
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.expectedHours'),
        name: orderSearchFilterName.expectedHours,
        type: FilterCompTypes.NUMBERRANGE,
        placeholder: i18next.t('search.globalSearch.order.filterNames.expectedHours'),
        numberRangeType: 'hours',
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.reason'),
        name: orderSearchFilterName.reason,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        // Code: Options are prefetched on page load and manipulated via getFilteredOptions - for search query operation with select all feature
        fetchOptions: async key => await getFilteredOptions(this.filterSpecs.orderReasonOpts, key),
        isSelectAll: true,
        isMultiSelect: true,
        applyOnClickAway: true,
        applyOnEnter: true,
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.education'),
        name: orderSearchFilterName.education,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        // Code: Options are prefetched on page load and manipulated via getFilteredOptions - for search query operation with select all feature
        fetchOptions: async key => await getFilteredOptions(EducationOpts, key),
        isMultiSelect: true,
        isSelectAll: true,
        applyOnClickAway: true,
        applyOnEnter: true,
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.accountManager'),
        name: orderSearchFilterName.accountManager,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        isMultiSelect: true,
        // Code: Options are prefetched on page load and manipulated via getFilteredOptions - for search query operation with select all feature
        fetchOptions: async key => await getFilteredOptions(this.filterSpecs.accountManagerOpts, key),
        isSelectAll: true,
        applyOnClickAway: true,
        applyOnEnter: true,
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.orderPriority'),
        name: orderSearchFilterName.orderPriority,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        isMultiSelect: true,
        // Code: Options are prefetched on page load and manipulated via getFilteredOptions - for search query operation with select all feature
        fetchOptions: async key => await getOrderPriorities(this.filterSpecs.orderPrioritiesOpts, key),
        isSelectAll: true,
        searchOnCharacterCount: 1,
        applyOnClickAway: true,
        applyOnEnter: true,
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.shift'),
        name: orderSearchFilterName.shift,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        isMultiSelect: true,
        // Code: Options are prefetched on page load and manipulated via getFilteredOptions - for search query operation with select all feature
        fetchOptions: async key => await getFilteredOptions(this.filterSpecs.shiftOpts, key),
        isSelectAll: true,
        applyOnClickAway: true,
        applyOnEnter: true,
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.weeklyGrossPay'),
        name: orderSearchFilterName.weeklyGrossPay,
        type: FilterCompTypes.NUMBERRANGE,
        placeholder: 'Weekly Gross',
        numberRangeType: 'currency',
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.startDateRange'),
        name: orderSearchFilterName.startDateRange,
        placeholder: i18next.t('search.globalSearch.order.filterNames.startDateRange'),
        version3: true,
        type: FilterCompTypes.DATERANGEV2,
        variant: 'outlined',
        secondaryLabel: i18next.t('search.globalSearch.order.filterNames.startDateRange'),
        useMaxWidth: true,
        maxWidth: 210,
        clickAway: true,
        disablePortal: true,
        quickSelect: false,
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.mfs'),
        name: orderSearchFilterName.mfs,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        options: MFSOpts,
        hideClearAll: true,
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.positions'),
        name: orderSearchFilterName.positions,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        options: PositionOpts,
        hideClearAll: true,
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.vaccinated'),
        name: orderSearchFilterName.vaccinated,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        options: VaccinatedOpts,
        hideClearAll: true,
      },
      {
        filterName: i18next.t('search.globalSearch.order.filterNames.region'),
        name: orderSearchFilterName.region,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        isMultiSelect: true,
        fetchOptions: async key => await getFilteredOptions(this.filterSpecs.regionOpts, key),
        applyOnClickAway: true,
        applyOnEnter: true,
        isSelectAll: true,
        searchOnCharacterCount: 1,
        fallbackPlacements: ['top', 'right'],
      },
    ];
  };
}

export const convertToTreeStructure = data => {
  const tree = [];

  const disciplineMap = new Map();

  data?.forEach(item => {
    const { disciplineId, disciplineAbbr, specialtyId, specialtyAbbr, subSpecialtyId, subSpecialtyAbbr } = item;

    // Find or create the discipline node
    if (!disciplineMap?.has(disciplineId)) {
      disciplineMap?.set(disciplineId, {
        name: disciplineAbbr,
        value: disciplineId,
        children: [],
        userChecked: true,
      });
      tree.push(disciplineMap?.get(disciplineId));
    }

    const disciplineNode = disciplineMap?.get(disciplineId);

    // Find or create the specialty node
    let specialtyNode = disciplineNode?.children?.find(child => child?.value === `${disciplineId}-${specialtyId}`);
    if (!specialtyNode) {
      specialtyNode = {
        name: specialtyAbbr,
        value: `${disciplineId}-${specialtyId}`,
        children: [],
        userChecked: true,
      };
      disciplineNode?.children?.push(specialtyNode);
    }

    // Add the subspecialty node if it exists
    if (subSpecialtyId !== null && subSpecialtyAbbr !== null) {
      specialtyNode?.children?.push({
        name: subSpecialtyAbbr,
        value: `${disciplineId}-${specialtyId}-${subSpecialtyId}`,
        userChecked: true,
      });
    }
  });

  return tree;
};

