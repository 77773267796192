import { ClickAwayListener, MenuList, Paper, Typography, Theme, Skeleton, Grid } from 'amn-ui-core';
import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { InboxToolbar } from '../../MessageInbox/MessageInbox.styles';
import ActivityIcon from 'app/assets/images/BellIcon.svg';
import CommunicationTab from 'app/assets/images/Notification/CommunicationTab.svg';
import { selectUser } from 'oidc/user.selectors';
import { useTranslation } from 'react-i18next';
import NoActivityIcon from 'app/assets/images/img-impressive-no-tasks-left-for-you.svg';
import { INotificationFeedResponse } from 'app/models/Tasks/Tasks';
import { useDispatch, useSelector } from 'react-redux';
import FilterIconClosed from 'app/assets/images/Tasks/FilterIconDeactivated.svg';
import FilterIconOpen from 'app/assets/images/Tasks/FilterIconActivated.svg';
import FilterIconGrayedOut from 'app/assets/images/Tasks/FilterIconGrayedout.svg';
import { ActivityFeedFilterV2 } from './ActivityFeedFilter';
import { selectisFilterApplied, unReadNotificationCount } from 'app/components/Tasks/store/Tasks.selectors';
import { usePromiseTracker } from 'react-promise-tracker';
import { CONSTANTS_NOTIFICATIONS, getNotificationData, updateReadNotification } from '../Common/FormatData';
import { AlertFeed } from './Feeds/AlertFeed';
import { EmailStatusFeed } from './Feeds/EmailStatusFeed';
import { useRef } from 'react';
import {
  INotificationPopperProps,
  defaultLogsPayload,
  getSelectedFiltersBasedOnTab,
  payloadWithFilters,
} from '../utils';
import OrderMatch from 'app/assets/images/OrderMatch.svg';
import TaskAltSvg from 'app/assets/images/TaskAltSvg.svg';
import { formatNotificationResponseWithUnreadStatus, getEmployeeId } from './utils';
import { taskDetailsActions } from '@AMIEWEB/Tasks/store/Tasks.redux';
import { NavLink } from 'react-router-dom';
import { NotificationHeaderOptions } from '@AMIEWEB/Notification/Constants';
import { MarkAsReadButton } from './MarkAsReadButton';
import { NotificationService } from 'app/services/NotificationServices/notification-service';

const useStyles = makeStyles()((theme: Theme) => ({
  title: {
    color: '#333333',
    paddingLeft: '5px',
    paddingRight: '5px',
    fontSize: '14px',
    fontWeight: 500,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontVariant: 'normal',
    lineHeight: '19px',
  },
  notificationViewer: {
    width: '320px',
    margin: 0,
    padding: '0px',
  },
  NewnotificationViewer: {
    width: '350px',
    margin: 0,
    padding: '0px',
  },
  paper: {
    padding: 0,
    margin: 0,
    width: '320px',
    float: 'right',
    marginRight: '30px',
    overflow: 'hidden',
    borderRadius: '10px 10px 10px 10px',
    boxShadow: '2px 5px 10px #0000004D',
  },
  NewNotificationpaper: {
    padding: 0,
    margin: '10px 0px 0px',
    width: '350px',
    float: 'right',
    marginRight: '30px',
    overflowX: 'hidden',
    overflowY: 'auto',
    borderRadius: '10px 10px 10px 10px',
    boxShadow: '2px 5px 10px #0000004D',
  },
  notificationPopper: {
    padding: 0,
    margin: '10px 0px 0px',
    width: '350px',
    float: 'right',
    marginRight: '30px',
    overflowX: 'hidden',
    overflowY: 'auto',
    borderRadius: '10px 10px 10px 10px',
    boxShadow: '2px 5px 10px #0000004D',
    position: 'fixed',
    top: '42px !important',
    right: '-24px',
  },
  validationLoader: {
    left: 'calc(50% - 20px)',
    top: '50%',
  },
  filterIcon: {
    justifyContent: 'flex-end',
  },
  filterIconGrayedOut: {
    paddingLeft: '5px',
    marginRight: '7px',
    justifyContent: 'flex-end',
  },
  filterIconActive: {
    paddingLeft: '5px',
    marginRight: '7px',
    justifyContent: 'flex-end',
  },
  filterPanel: {
    left: 'calc(50% - 20px)',
    top: '50%',
  },
  scroll: {
    color: '#555555',
    fontSize: '1rem',
    maxHeight: '490px',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  scrollFullsize: {
    color: '#555555',
    fontSize: '1rem',
    maxHeight: '85vh',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  noActivity: {
    justifyContent: 'center',
    display: 'grid',
    marginTop: '10em',
    marginBottom: '10em',
    userSelect: 'none',
  },
  noTasksIcon: {
    margin: 'auto',
    display: 'block',
  },
  noTasksText: {
    paddingTop: '1rem',
    margin: 'auto',
    fontStyle: 'italic',
    fontSize: '.9rem',
  },
  notificationIcon: {
    backgroundColor: '#F4F2FF',
    borderRadius: '40px',
    padding: '7px',
  },
  skeleton: { margin: '5px', padding: '10px', width: '340px', height: '60px' },
  navLink: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      color: '#009AD9',
    },
  },
}));

interface IActivityFeedProps {
  notificationPopupData: INotificationFeedResponse[];
  setNotificationDataArray: React.Dispatch<React.SetStateAction<INotificationFeedResponse[] | undefined>>;
  totalRecords: number;
  setDetailsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setTaskId: React.Dispatch<React.SetStateAction<number | undefined>>;
  setRecordDetails: React.Dispatch<React.SetStateAction<any>>;
  appliedFilters: any;
  activityFeedRefactoring?: boolean;
  selectedFeedData?: INotificationPopperProps;
  category?: string;
  setSelectedFeedData: (e) => void;
}

export const ActivityFeedV2 = ({
  notificationPopupData,
  setNotificationDataArray,
  totalRecords,
  setDetailsModalOpen,
  setTaskId,
  setRecordDetails,
  appliedFilters,
  activityFeedRefactoring,
  selectedFeedData,
  category,
  setSelectedFeedData,
}: IActivityFeedProps) => {
  const { classes } = useStyles();
  const notificationService = NotificationService.getInstance();

  const dispatch = useDispatch();
  const userInfo = useSelector(selectUser);
  const pageNumber = useRef(1);
  const { t } = useTranslation();
  const anchorRef = useRef<HTMLButtonElement>(null);
  const active = useRef(false);
  const [openFeedFilter, setFeedFilter] = useState(false);
  const isFilterApplied = useSelector(selectisFilterApplied);
  const { promiseInProgress: isCallingAPI } = usePromiseTracker({ area: 'get-activity-feed', delay: 0 });
  const { promiseInProgress: isCallingAPIBulkMarkAsRead } = usePromiseTracker({
    area: 'notification-bulk-read',
    delay: 0,
  });
  const [selectedCards, setselectedCards] = useState<string[]>([]);
  const [iconHover, setIconHover] = useState(false);
  const [lastSelectedCard, setLastSelectedCard] = useState(null);

  const { unreadTaskNotificationsCount, unreadOrderMatchNotificationsCount, unreadAlertNotificationsCount } =
    useSelector(unReadNotificationCount);

  useEffect(() => {
    pageNumber.current = 1;
  }, [appliedFilters]);

  useEffect(() => {
    setselectedCards([]);
    setLastSelectedCard(null);
  }, [category]);

  const handleClose = async event => {
    if (
      (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) ||
      event?.target?.id === CONSTANTS_NOTIFICATIONS.ALERT.IMAGEID ||
      event?.target?.id === CONSTANTS_NOTIFICATIONS.TASK.IMAGEID ||
      event?.target?.id === CONSTANTS_NOTIFICATIONS.PREFERENCES.IMAGEID ||
      event?.target?.id === NotificationHeaderOptions.Preferences ||
      event?.target?.id === NotificationHeaderOptions.Alert ||
      event?.target?.id === NotificationHeaderOptions.Task
    ) {
      return;
    }
    if (!activityFeedRefactoring) {
      const emailIds = notificationPopupData
        ?.filter(item => item?.channel === 'Email' && item?.contentProps?.isRead !== true)
        .map(ele => ele?.contentProps?.id);
      const payload = updateReadNotification(emailIds || [], getEmployeeId(userInfo?.userInfo));
      emailIds && emailIds?.length > 0 && (await notificationService.UpdateNotificationReadStatus(payload));
    }
    setSelectedFeedData({
      currentPopper: null,
      openPopper: false,
    });
  };

  const getLogsDataWhileScroll = async () => {
    try {
      const availableFilters = getSelectedFiltersBasedOnTab(
        selectedFeedData?.currentPopper,
        appliedFilters ?? [],
        activityFeedRefactoring,
      );
      const response = await notificationService.GetActivityFeed(
        availableFilters?.length > 0
          ? payloadWithFilters(
              availableFilters,
              selectedFeedData?.currentPopper,
              activityFeedRefactoring,
              userInfo,
              pageNumber.current,
            )
          : defaultLogsPayload(
              selectedFeedData?.selectedTypes,
              selectedFeedData?.currentPopper,
              userInfo,
              activityFeedRefactoring,
              pageNumber.current,
            ),
      );
      if (activityFeedRefactoring) {
        const formattedResponse = formatNotificationResponseWithUnreadStatus(response.data, userInfo?.userInfo);
        dispatch(taskDetailsActions.appendNotificationLogData(formattedResponse));
      } else {
        const notificationData = response?.data?.results?.map(log => getNotificationData(log));
        setNotificationDataArray(prevdata => [...prevdata!, ...notificationData]);
      }
      active.current = false;
    } catch {
      active.current = false;
    }
  };

  const handleScroll = e => {
    if (openFeedFilter) return;
    const element = e.target;
    if (
      element.scrollTop + element.clientHeight >= element.scrollHeight - 8 &&
      !active.current &&
      totalRecords / 20 + 1 >= pageNumber.current
    ) {
      // eslint-disable-next-line no-plusplus
      pageNumber.current++;
      active.current = true;
      getLogsDataWhileScroll();
    }
  };

  const FilterSelectedCards = selectedCards => {
    const filteredCards = [];
    notificationPopupData?.forEach(notifications => {
      if (
        notifications?.contentProps?.isNotificationUnread &&
        !!selectedCards.includes(notifications?.contentProps?.id)
      ) {
        filteredCards.push(notifications?.contentProps?.id);
      }
    });
    return filteredCards;
  };

  const DisplayFeed = (notifyData: INotificationFeedResponse, index: number) => {
    let jsxFeed: JSX.Element | null;
    if (activityFeedRefactoring) {
      return (
        <AlertFeed
          key={index}
          index={index}
          notifyData={notifyData}
          setDetailsModalOpen={setDetailsModalOpen}
          setTaskId={setTaskId}
          setRecordDetails={setRecordDetails}
          setSelectedFeedData={setSelectedFeedData}
          setselectedCards={setselectedCards}
          selectedCards={selectedCards}
          setLastSelectedCard={setLastSelectedCard}
          lastSelectedCard={lastSelectedCard}
          activityFeedRefactoringFlag={activityFeedRefactoring}
          selectedFeedData={selectedFeedData}
          pageNumber={pageNumber}
          notificationPopupData={notificationPopupData}
          pushNotification={false}
        />
      );
    }
    switch (notifyData.channel) {
      case 'Alert':
        jsxFeed = (
          <AlertFeed
            key={index}
            index={index}
            notifyData={notifyData}
            setDetailsModalOpen={setDetailsModalOpen}
            setTaskId={setTaskId}
            setRecordDetails={setRecordDetails}
            setSelectedFeedData={setSelectedFeedData}
            setselectedCards={setselectedCards}
            selectedCards={selectedCards}
            activityFeedRefactoringFlag={activityFeedRefactoring}
            selectedFeedData={selectedFeedData}
            pageNumber={pageNumber}
            pushNotification={false}
          />
        );
        break;
      case 'Email':
        //TODO:Upcoming :sprint 99 Viper Add selection to the email feed component
        jsxFeed = (
          <EmailStatusFeed
            key={index}
            notifyData={notifyData}
            pageNumber={pageNumber}
            activityFeedRefactoringFlag={activityFeedRefactoring}
            setSelectedFeedData={setSelectedFeedData}
          />
        );
        break;
      default:
        jsxFeed = <div></div>;
        break;
    }
    return jsxFeed;
  };
  const GetActivityIcon = () => {
    let response = [];
    switch (category) {
      case NotificationHeaderOptions.Preferences:
        response = [OrderMatch, 'Preference Matches', '', unreadOrderMatchNotificationsCount];
        break;
      case NotificationHeaderOptions.Task:
        response = [TaskAltSvg, 'Tasks', '/tasks', unreadTaskNotificationsCount];
        break;
      default:
        response = [CommunicationTab, 'Notifications', '', unreadAlertNotificationsCount];
    }
    return response;
  };
  return (
    <>
      <Paper
        className={activityFeedRefactoring ? classes.notificationPopper : classes.NewNotificationpaper}
        elevation={5}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <MenuList className={classes.NewnotificationViewer}>
            <InboxToolbar style={{ padding: '0px 12px', display: 'flex' }}>
              {activityFeedRefactoring ? (
                <>
                  {GetActivityIcon()[1] === 'Tasks' ? (
                    <NavLink data-testid="activity-feed-image" to={GetActivityIcon()[2]}>
                      <img src={GetActivityIcon()[0]} className={classes.notificationIcon} alt={GetActivityIcon()[1]} />
                    </NavLink>
                  ) : (
                    <img src={GetActivityIcon()[0]} className={classes.notificationIcon} alt={GetActivityIcon()[1]} />
                  )}
                </>
              ) : (
                <img src={ActivityIcon} alt="All Notifications" />
              )}
              {activityFeedRefactoring && GetActivityIcon()[2].length ? (
                <NavLink
                  data-testid="activity-feed-link"
                  className={`${classes.title} ${classes.navLink}`}
                  to={GetActivityIcon()[2]}
                >
                  {GetActivityIcon()[1] + ' (' + GetActivityIcon()[3] + ')'}
                </NavLink>
              ) : (
                <Typography className={classes.title}>
                  {activityFeedRefactoring ? (
                    <>{GetActivityIcon()[1] + ' (' + GetActivityIcon()[3] + ')'}</>
                  ) : (
                    <>{t('notification.taskNotification.activityFeed')}</>
                  )}
                </Typography>
              )}
              {activityFeedRefactoring && (
                <div style={{ marginLeft: 'auto', marginRight: '12px' }}>
                  <MarkAsReadButton
                    selectedCards={selectedCards}
                    selectedCardsFiltered={FilterSelectedCards(selectedCards)}
                    unReadCount={notificationPopupData?.some(item => item?.contentProps?.isNotificationUnread)}
                    notificationCategory={category}
                  />
                </div>
              )}
              {(notificationPopupData?.length > 0 || !!isFilterApplied) && (
                <div style={{ marginLeft: !activityFeedRefactoring && 'auto', width: '30px', height: '30px' }}>
                  {!isFilterApplied ? (
                    <img
                      src={iconHover ? FilterIconClosed : FilterIconGrayedOut}
                      className={iconHover ? classes.filterIcon : classes.filterIconGrayedOut}
                      onClick={() => setFeedFilter(true)}
                      onMouseEnter={() => setIconHover(true)}
                      onMouseLeave={() => setIconHover(false)}
                      alt="FilterClosed"
                    />
                  ) : (
                    <img
                      src={FilterIconOpen}
                      className={classes.filterIconActive}
                      onClick={() => setFeedFilter(true)}
                      alt="FilterOpen"
                    />
                  )}
                </div>
              )}
            </InboxToolbar>
            <div className={classes.filterPanel}>
              <ActivityFeedFilterV2
                openFeedFilter={openFeedFilter}
                setFeedFilter={setFeedFilter}
                selectedFeedData={selectedFeedData}
                activityFeedRefactoring={activityFeedRefactoring}
              />
            </div>
            <div className={activityFeedRefactoring ? classes.scrollFullsize : classes.scroll} onScroll={handleScroll}>
              {(isCallingAPI && pageNumber.current === 1) || isCallingAPIBulkMarkAsRead ? (
                <Grid item xs={12}>
                  <Skeleton variant="rounded" className={classes.skeleton} />
                  <Skeleton variant="rounded" className={classes.skeleton} />
                  <Skeleton variant="rounded" className={classes.skeleton} />
                  <Skeleton variant="rounded" className={classes.skeleton} />
                </Grid>
              ) : (
                <div style={{ boxShadow: '0px 2px 8px #372C6733', backgroundColor: 'transparent' }}>
                  {notificationPopupData?.map((notifyData, index) => DisplayFeed(notifyData, index))}
                </div>
              )}
            </div>
            {!isCallingAPI && (!notificationPopupData || notificationPopupData?.length === 0) && (
              <div className={classes.noActivity}>
                <div className={classes.noTasksIcon}>
                  <img src={NoActivityIcon} alt="No Activity" />
                </div>
                <div className={classes.noTasksText}>{`No activity found`}</div>
              </div>
            )}
          </MenuList>
        </ClickAwayListener>
      </Paper>
    </>
  );
};
