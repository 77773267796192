import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import baseAxiosInstance from 'utils/BaseApi';

export class PlacementModsService {
  private placementModsBase: string;

  private sharedBase: string;
  private taskbase: string;

  private cancellationToken = axios.CancelToken.source();

  private static currentInstance: PlacementModsService;

  config?: AxiosRequestConfig = {};

  private constructor() {
    this.placementModsBase = globalThis?.app?.env?.REACT_APP_PLACEMENTS_SERVICE_BASE_URL;
    this.sharedBase = globalThis?.app?.env?.REACT_APP_TRACK_CHANGE_SERVICE_BASE_URL;
    this.taskbase = globalThis?.app?.env?.REACT_APP_TASKS_SERVICE_BASE_URL;
  }

  static createInstance(): PlacementModsService {
    const activeInstance = new PlacementModsService();
    return activeInstance;
  }

  static getInstance(): PlacementModsService {
    if (!this.currentInstance) this.currentInstance = this.createInstance();
    return this.currentInstance;
  }

  static cancelActiveRequests() {
    this.currentInstance.cancellationToken.cancel('RequestCancellation');
    // this.currentInstance = null;
  }

  cancelRequests() {
    this.cancellationToken.cancel('RequestCancellation');
    return PlacementModsService.getInstance();
  }

  cancelAndRevokeInstance() {
    this.cancellationToken.cancel('RequestCancellation');
    this.cancellationToken = axios.CancelToken.source();
    if (this.config) this.config.cancelToken = this.cancellationToken.token;
  }

  getPlacementModsList = (placementId: number) =>
    baseAxiosInstance
      .get(`${this.placementModsBase}/placement/${placementId}/modifications/add-list`)
      .catch((error: AxiosError) => error.response);

  getRefreshPlacementModList = (placementId: number) =>
    baseAxiosInstance
      .get(`${this.placementModsBase}/placement/${placementId}/modifications/refresh-list`)
      .catch((error: AxiosError) => error.response);

  getStartDateModDetails = (placementId: string, travelerOptionId: number) =>
    baseAxiosInstance
      .get(`${this.placementModsBase}/placement/${placementId}/modifications/${travelerOptionId}/start-date`)
      .catch((error: AxiosError) => error.response);

  getStartDateModDetailsNew = (placementId: string) =>
    baseAxiosInstance
      .get(`${this.placementModsBase}/placement/${placementId}/modifications/start-date/new`)
      .catch((error: AxiosError) => error.response);

  getInsuranceModDetailsNew = (placementId: string) =>
    baseAxiosInstance
      .get(`${this.placementModsBase}/placement/${placementId}/modifications/insurance/new`)
      .catch((error: AxiosError) => error.response);

  getStartDateReasonCode = () =>
    baseAxiosInstance
      .get(`${this.placementModsBase}/placement/pick-list/start-date-reason-codes`)
      .catch((error: AxiosError) => error.response);

  getMinReqHoursModDetails = (placementId: string, travelerOptionId: number) =>
    baseAxiosInstance
      .get(`${this.placementModsBase}/placement/${placementId}/modifications/${travelerOptionId}/min-req-hours`)
      .catch((error: AxiosError) => error.response);

  getEndDateModDetails = (placementId: string, travelerOptionId: number) =>
    baseAxiosInstance
      .get(`${this.placementModsBase}/placement/${placementId}/modifications/${travelerOptionId}/end-date`)
      .catch((error: AxiosError) => error.response);

  getEndDateModDetailsNew = (placementId: string) =>
    baseAxiosInstance
      .get(`${this.placementModsBase}/placement/${placementId}/modifications/end-date/new`)
      .catch((error: AxiosError) => error.response);

  updateEndDateModDetails = body => {
    return baseAxiosInstance
      .post(`${this.placementModsBase}/placement/modifications/end-date/update`, body)
      .catch((error: AxiosError) => error.response);
  };

  addInsuranceModDetails = body => {
    return baseAxiosInstance
      .post(`${this.placementModsBase}/placement/modifications/insurance/add`, body)
      .catch((error: AxiosError) => error.response);
  };

  addEndDateModDetails = body => {
    return baseAxiosInstance
      .post(`${this.placementModsBase}/placement/modifications/end-date/add`, body)
      .catch((error: AxiosError) => error.response);
  };

  getPayRateModDetails = (placementId: number, travelerOptionId: number) =>
    baseAxiosInstance
      .get(`${this.placementModsBase}/placement/${placementId}/modifications/${travelerOptionId}/pay-rate`)
      .catch((error: AxiosError) => error.response);

  refreshMod = body =>
    baseAxiosInstance
      .post(`${this.placementModsBase}/placement/modifications/refresh`, body)
      .catch((error: AxiosError) => error.response);

  getPayRateModDetailsNew = (placementId: number) =>
    baseAxiosInstance
      .get(`${this.placementModsBase}/placement/${placementId}/modifications/pay-rate/new`)
      .catch((error: AxiosError) => error.response);

  addPayRateModDetails = body =>
    baseAxiosInstance
      .post(`${this.placementModsBase}/placement/modifications/pay-rate/add`, body)
      .catch((error: AxiosError) => error.response);

  updatePayRateModDetails = body =>
    baseAxiosInstance
      .post(`${this.placementModsBase}/placement/modifications/pay-rate/update`, body)
      .catch((error: AxiosError) => error.response);

  getArrivingTravelModDetail = (placementId: number, travelerOptionId: number, modEnum: number) =>
    baseAxiosInstance
      .get(
        `${this.placementModsBase}/placement/${placementId}/modifications/${travelerOptionId}/type/${modEnum}/travel-allowance`,
      )
      .catch((error: AxiosError) => error.response);

  getAdditionalPremiumPayRate = () =>
    baseAxiosInstance.get(`${this.placementModsBase}/placement/pick-list/additional-premium-pay-rates`);

  getLodgingModDetails = (placementId: string, travelerOptionId: number) =>
    baseAxiosInstance
      .get(`${this.placementModsBase}/placement/${placementId}/modifications/${travelerOptionId}/lodging`)
      .catch((error: AxiosError) => error.response);

  getInsuranceModDetails = (placementId: string, travelerOptionId: number) =>
    baseAxiosInstance
      .get(`${this.placementModsBase}/placement/${placementId}/modifications/${travelerOptionId}/insurance`)
      .catch((error: AxiosError) => error.response);

  getEffectiveDateTypes = () =>
    baseAxiosInstance
      .get(`${this.placementModsBase}/placement/pick-list/effective-date-types`)
      .catch((error: AxiosError) => error.response);

  getInsuranceTypes = () =>
    baseAxiosInstance
      .get(`${this.placementModsBase}/placement/pick-list/insurance-types`)
      .catch((error: AxiosError) => error.response);

  getBillRateModDetails = (placementId: number, travelerOptionId: number) =>
    baseAxiosInstance
      .get(`${this.placementModsBase}/placement/${placementId}/modifications/${travelerOptionId}/bill-rate`)
      .catch((error: AxiosError) => error.response);

  getBillRateModDetailsNew = (placementId: string, travelerOptionId: number) =>
    baseAxiosInstance
      .get(`${this.placementModsBase}/placement/${placementId}/modifications/bill-rate/new`)
      .catch((error: AxiosError) => error.response);

  getPricingReason = () =>
    baseAxiosInstance.get(`${this.placementModsBase}/placement/pick-list/premium-pricing-reasons`);

  getRateShiftList = () =>
    baseAxiosInstance.get(`${this.sharedBase}/pick-list/rate-shift-list`).catch((error: AxiosError) => error.response);

  getCancellationHoursFrequencyList = () =>
    baseAxiosInstance
      .get(`${this.sharedBase}/pick-list/cancellation-hours-frequency`)
      .catch((error: AxiosError) => error.response);

  getBonusModDetail = (placementId: number, travelerOptionId: number) =>
    baseAxiosInstance.get(`${this.placementModsBase}/placement/${placementId}/modifications/${travelerOptionId}/bonus`);

  getReimbursement = (placementId: number, travelerOptionId: number) =>
    baseAxiosInstance
      .get(`${this.placementModsBase}/placement/${placementId}/modifications/${travelerOptionId}/reimbursement`)
      .catch((error: AxiosError) => error.response);

  getReimbursementNew = (placementId: string, travelerOptionId: number) =>
    baseAxiosInstance
      .get(`${this.placementModsBase}/placement/${placementId}/modifications/reimbursement/new`)
      .catch((error: AxiosError) => error.response);

  updateReimbursementModDetails = body => {
    return baseAxiosInstance
      .post(`${this.placementModsBase}/placement/modifications/reimbursement/update`, body)
      .catch((error: AxiosError) => error.response);
  };

  updateBillRateModDetails = body => {
    return baseAxiosInstance
      .post(`${this.placementModsBase}/placement/modifications/bill-rate/update`, body)
      .catch((error: AxiosError) => error.response);
  };

  addReimbursementModDetails = body => {
    return baseAxiosInstance
      .post(`${this.placementModsBase}/placement/modifications/reimbursement/add`, body)
      .catch((error: AxiosError) => error.response);
  };

  addBillRateModDetails = body => {
    return baseAxiosInstance
      .post(`${this.placementModsBase}/placement/modifications/bill-rate/add`, body)
      .catch((error: AxiosError) => error.response);
  };

  getReimbursementCategoryAndFrequency = (includeActive: boolean, onlyActive: boolean) =>
    baseAxiosInstance
      .get(
        !includeActive
          ? `${this.placementModsBase}/placement/pick-list/reimbursement-categories`
          : `${this.placementModsBase}/placement/pick-list/reimbursement-categories?onlyActive=${onlyActive}`,
      )
      .catch((error: AxiosError) => error.response);

  getPayeeList = () =>
    baseAxiosInstance
      .get(`${this.placementModsBase}/placement/pick-list/payees`)
      .catch((error: AxiosError) => error.response);

  getTimeOffMod = (placementId: number, travelerOptionId: number) =>
    baseAxiosInstance
      .get(`${this.placementModsBase}/placement/${placementId}/modifications/${travelerOptionId}/time-off `)
      .catch((error: AxiosError) => error.response);

  getFurnitureMod = (placementId: number, travelerOptionId: number) =>
    baseAxiosInstance
      .get(`${this.placementModsBase}/placement/${placementId}/modifications/${travelerOptionId}/furniture `)
      .catch((error: AxiosError) => error.response);

  getMinReqHoursModDetailsNew = (placementId: string) =>
    baseAxiosInstance
      .get(`${this.placementModsBase}/placement/${placementId}/modifications/min-req-hours/new`)
      .catch((error: AxiosError) => error.response);

  addMinReqHoursModDetails = body =>
    baseAxiosInstance
      .post(`${this.placementModsBase}/placement/modifications/min-req-hours/add`, body)
      .catch((error: AxiosError) => error.response);

  updateMinReqHoursModDetails = body =>
    baseAxiosInstance
      .post(`${this.placementModsBase}/placement/modifications/min-req-hours/update`, body)
      .catch((error: AxiosError) => error.response);

  getEmiLmoModDetail = (placementId: number, travelerOptionId: number) =>
    baseAxiosInstance.get(
      `${this.placementModsBase}/placement/${placementId}/modifications/${travelerOptionId}/emi-lmo`,
    );

  updateEmiLmoModDetails = body => {
    return baseAxiosInstance
      .post(`${this.placementModsBase}/placement/modifications/emi-lmo/update`, body)
      .catch((error: AxiosError) => error.response);
  };

  updateBonusModDetails = body => {
    return baseAxiosInstance
      .post(`${this.placementModsBase}/placement/modifications/bonus/update`, body)
      .catch((error: AxiosError) => error.response);
  };

  updatePreferenceModDetails = body => {
    return baseAxiosInstance
      .post(`${this.placementModsBase}/placement/modifications/preference/update`, body)
      .catch((error: AxiosError) => error.response);
  };

  getPtoModDetail = (placementId: number, travelerOptionId: number) =>
    baseAxiosInstance
      .get(`${this.placementModsBase}/placement/${placementId}/modifications/${travelerOptionId}/pto`)
      .catch((error: AxiosError) => error.response);

  getPtoModDetailNew = (placementId: number) =>
    baseAxiosInstance
      .get(`${this.placementModsBase}/placement/${placementId}/modifications/pto/new`)
      .catch((error: AxiosError) => error.response);

  updatePTOModDetails = body => {
    return baseAxiosInstance
      .post(`${this.placementModsBase}/placement/modifications/pto/update`, body)
      .catch((error: AxiosError) => error.response);
  };

  addPTOModDetails = body => {
    return baseAxiosInstance
      .post(`${this.placementModsBase}/placement/modifications/pto/add`, body)
      .catch((error: AxiosError) => error.response);
  };

  getEmiLmoModDetailNew = (placementId: number) =>
    baseAxiosInstance.get(`${this.placementModsBase}/placement/${placementId}/modifications/emi-lmo/new`);

  addEmiLmoModDetails = body => {
    return baseAxiosInstance
      .post(`${this.placementModsBase}/placement/modifications/emi-lmo/add`, body)
      .catch((error: AxiosError) => error.response);
  };

  addPreferenceModDetails = body => {
    return baseAxiosInstance
      .post(`${this.placementModsBase}/placement/modifications/preference/add`, body)
      .catch((error: AxiosError) => error.response);
  };

  getLodgingModDetailsNew = (placementId: string) =>
    baseAxiosInstance
      .get(`${this.placementModsBase}/placement/${placementId}/modifications/lodging/new`)
      .catch((error: AxiosError) => error.response);

  addLodgingModDetails = body =>
    baseAxiosInstance
      .post(`${this.placementModsBase}/placement/modifications/lodging/add`, body)
      .catch((error: AxiosError) => error.response);

  updateLodgingModDetails = body => {
    return baseAxiosInstance
      .post(`${this.placementModsBase}/placement/modifications/lodging/update`, body)
      .catch((error: AxiosError) => error.response);
  };

  getShiftModDetail = (placementId: number, travelerOptionId: number) =>
    baseAxiosInstance
      .get(`${this.placementModsBase}/placement/${placementId}/modifications/${travelerOptionId}/shift`)
      .catch((error: AxiosError) => error.response);

  geShiftModDetailsNew = (placementId: number) =>
    baseAxiosInstance
      .get(`${this.placementModsBase}/placement/${placementId}/modifications/shift/new`)
      .catch((error: AxiosError) => error.response);

  updateShiftModDetails = body => {
    return baseAxiosInstance
      .post(`${this.placementModsBase}/placement/modifications/shift/update`, body)
      .catch((error: AxiosError) => error.response);
  };

  addShiftModDetails = body => {
    return baseAxiosInstance
      .post(`${this.placementModsBase}/placement/modifications/shift/add`, body)
      .catch((error: AxiosError) => error.response);
  };

  addStartDateModDetails = body => {
    return baseAxiosInstance.post(`${this.placementModsBase}/placement/modifications/start-date/add`, body);
  };

  updateStartDateModDetails = body => {
    return baseAxiosInstance.post(`${this.placementModsBase}/placement/modifications/start-date/update`, body);
  };

  getHousingModDetail = (placementId: number, travelerOptionId: number) =>
    baseAxiosInstance.get(
      `${this.placementModsBase}/placement/${placementId}/modifications/${travelerOptionId}/housing`,
    );

  getInitiators = (placementId: number) =>
    baseAxiosInstance.get(`${this.taskbase}/task/placement-employees/${placementId}`);

  getPreferencesModDetail = (placementId: number, travelerOptionId: number) =>
    baseAxiosInstance
      .get(`${this.placementModsBase}/placement/${placementId}/modifications/${travelerOptionId}/preference`)
      .catch((error: AxiosError) => error.response);

  getPreferencesModDetailNew = (placementId: number) =>
    baseAxiosInstance
      .get(`${this.placementModsBase}/placement/${placementId}/modifications/preference/new`)
      .catch((error: AxiosError) => error.response);

  deleteMods = (placementId: number, travelerOptionId: number, travelerOptionTimestamp: string) =>
    baseAxiosInstance
      .delete(
        `${this.placementModsBase}/placement/modifications/delete?placementId=${placementId}&travelerOptionId=${travelerOptionId}&travelerOptionTimestamp=${travelerOptionTimestamp}`,
      )
      .catch((error: AxiosError) => error.response);

  voidMods = (body: { placementId: number; travelerOptionId: number; travelerOptionTimestamp: string }) =>
    baseAxiosInstance.post(`${this.placementModsBase}/placement/modifications/void`, body);

  getTimeOffModDetailsNew = (placementId: string) =>
    baseAxiosInstance
      .get(`${this.placementModsBase}/placement/${placementId}/modifications/time-off/new`)
      .catch((error: AxiosError) => error.response);

  addTimeOffModDetails = body =>
    baseAxiosInstance
      .post(`${this.placementModsBase}/placement/modifications/time-off/add`, body)
      .catch((error: AxiosError) => error.response);

  updateTimeOffModDetails = body => {
    return baseAxiosInstance
      .post(`${this.placementModsBase}/placement/modifications/time-off/update`, body)
      .catch((error: AxiosError) => error.response);
  };

  getFurnitureModDetailsNew = (placementId: string) =>
    baseAxiosInstance
      .get(`${this.placementModsBase}/placement/${placementId}/modifications/furniture/new`)
      .catch((error: AxiosError) => error.response);

  addFurnitureModDetails = body =>
    baseAxiosInstance
      .post(`${this.placementModsBase}/placement/modifications/furniture/add`, body)
      .catch((error: AxiosError) => error.response);

  updateFurnitureModDetails = body =>
    baseAxiosInstance
      .post(`${this.placementModsBase}/placement/modifications/furniture/update`, body)
      .catch((error: AxiosError) => error.response);

  // travel allowance services start
  getTravelAllowanceModDetail = (placementId: number, travelerOptionId: number, modEnum: number) => {
    return baseAxiosInstance
      .get(
        `${this.placementModsBase}/placement/${placementId}/modifications/${travelerOptionId}/type/${modEnum}/travel-allowance`,
      )
      .catch((error: AxiosError) => error.response);
  };

  getNewEmptyTravelAllowanceModDetail = (placementId: number, travelerOptionId: number, modEnum: number) => {
    return baseAxiosInstance
      .get(`${this.placementModsBase}/placement/${placementId}/modifications/type/${modEnum}/travel-allowance/new`)
      .catch((error: AxiosError) => error.response);
  };

  updateTravelAllowanceModDetails = body => {
    return baseAxiosInstance
      .post(`${this.placementModsBase}/placement/modifications/travel-allowance/update`, body)
      .catch((error: AxiosError) => error.response);
  };

  addTravelAllowanceModDetails = body => {
    return baseAxiosInstance
      .post(`${this.placementModsBase}/placement/modifications/travel-allowance/add`, body)
      .catch((error: AxiosError) => error.response);
  };
  // travel allowance services end

  updateInsuranceModDetails = body => {
    return baseAxiosInstance.post(`${this.placementModsBase}/placement/modifications/insurance/update`, body);
  };

  getPayrollShiftDifferentialModDetail = (placementId: number, travelerOptionId: number) =>
    baseAxiosInstance
      .get(
        `${this.placementModsBase}/placement/${placementId}/modifications/${travelerOptionId}/payroll-shift-differential`,
      )
      .catch((error: AxiosError) => error.response);

  getPayrollShiftDifferentialModDetailNew = (placementId: number) =>
    baseAxiosInstance
      .get(`${this.placementModsBase}/placement/${placementId}/modifications/payroll-shift-differential/new`)
      .catch((error: AxiosError) => error.response);

  updatePayrollShiftDifferentialModDetails = body => {
    return baseAxiosInstance
      .post(`${this.placementModsBase}/placement/modifications/payroll-shift-differential/update`, body)
      .catch((error: AxiosError) => error.response);
  };

  addPayrollShiftDifferentialModDetails = body => {
    return baseAxiosInstance
      .post(`${this.placementModsBase}/placement/modifications/payroll-shift-differential/add`, body)
      .catch((error: AxiosError) => error.response);
  };

  getOgpInternationalLoanModDetail = (placementId: number, travelerOptionId: number) =>
    baseAxiosInstance
      .get(`${this.placementModsBase}/placement/${placementId}/modifications/${travelerOptionId}/ogp`)
      .catch((error: AxiosError) => error.response);

  getOgpInternationalLoanModDetailNew = (placementId: number, travelerOptionId: number) =>
    baseAxiosInstance
      .get(`${this.placementModsBase}/placement/${placementId}/modifications/ogp/new`)
      .catch((error: AxiosError) => error.response);

  updateOGPModDetails = body => {
    return baseAxiosInstance
      .post(`${this.placementModsBase}/placement/modifications/ogp/update`, body)
      .catch((error: AxiosError) => error.response);
  };

  addOGPModDetails = body => {
    return baseAxiosInstance
      .post(`${this.placementModsBase}/placement/modifications/ogp/add`, body)
      .catch((error: AxiosError) => error.response);
  };

  getShiftDifferentialTypes = () =>
    baseAxiosInstance
      .get(`${this.placementModsBase}/placement/pick-list/shift-differential-types`)
      .catch((error: AxiosError) => error.response);

  getShiftDifferentialDescriptionTypes = () =>
    baseAxiosInstance
      .get(`${this.placementModsBase}/placement/pick-list/shift-differential-description-types`)
      .catch((error: AxiosError) => error.response);

  getShiftDifferentialTemplateTypes = () =>
    baseAxiosInstance
      .get(`${this.placementModsBase}/placement/pick-list/shift-differential-template-types`)
      .catch((error: AxiosError) => error.response);

  updateLinkModTask = body => {
    return baseAxiosInstance
      .post(`${this.placementModsBase}/placement/modifications/update/task-link`, body)
      .catch((error: AxiosError) => error.response);
  };

  getBillingShiftDifferentialModDetail = (placementId: number, travelerOptionId: number) =>
    baseAxiosInstance
      .get(
        `${this.placementModsBase}/placement/${placementId}/modifications/${travelerOptionId}/billing-shift-differential`,
      )
      .catch((error: AxiosError) => error.response);

  getBillingShiftDifferentialModDetailNew = (placementId: number) =>
    baseAxiosInstance
      .get(`${this.placementModsBase}/placement/${placementId}/modifications/billing-shift-differential/new`)
      .catch((error: AxiosError) => error.response);

  getShiftDifferentialTemplateItems = shiftDifferentialTemplateTypeId =>
    baseAxiosInstance
      .get(`${this.placementModsBase}/placement/shift-differential-template-item/${shiftDifferentialTemplateTypeId}`)
      .catch((error: AxiosError) => error.response);

  addBonuseModDetails = body => {
    return baseAxiosInstance
      .post(`${this.placementModsBase}/placement/modifications/bonus/add`, body)
      .catch((error: AxiosError) => error.response);
  };

  getBonusModDetailsNew = (placementId: string) => {
    return baseAxiosInstance
      .get(`${this.placementModsBase}/placement/${placementId}/modifications/bonus/new`)
      .catch((error: AxiosError) => error.response);
  };

  updateBillingShiftDifferentialModDetails = body => {
    return baseAxiosInstance
      .post(`${this.placementModsBase}/placement/modifications/billing-shift-differential/update`, body)
      .catch((error: AxiosError) => error.response);
  };

  addBillingShiftDifferentialModDetails = body => {
    return baseAxiosInstance
      .post(`${this.placementModsBase}/placement/modifications/billing-shift-differential/add`, body)
      .catch((error: AxiosError) => error.response);
  };

  updateHousingModDetails = body => {
    return baseAxiosInstance
      .post(`${this.placementModsBase}/placement/modifications/housing/update`, body)
      .catch((error: AxiosError) => error.response);
  };

  getHousingModDetailsNew = (placementId: string) => {
    return baseAxiosInstance
      .get(`${this.placementModsBase}/placement/${placementId}/modifications/housing/new`)
      .catch((error: AxiosError) => error.response);
  };

  addHousingModDetails = body => {
    return baseAxiosInstance
      .post(`${this.placementModsBase}/placement/modifications/housing/add`, body)
      .catch((error: AxiosError) => error.response);
  };

  getPlacementModifications = body =>
    baseAxiosInstance
      .post(`${this.placementModsBase}/placement/modifications`, body)
      .catch((error: AxiosError) => error.response);

  getBookingApproveCheckboxStatus = (placementId: number) =>
    baseAxiosInstance
      .get(`${this.placementModsBase}/placement/${placementId}/modifications/booking-approved/status`)
      .catch((error: AxiosError) => error.response);
}
