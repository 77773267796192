import React from 'react';
import GreenCheck from 'app/assets/images/IconImages/GreenCheckCircleIcon.svg';
import OrangeWarning from 'app/assets/images/IconImages/OrangeWarningIcon.svg';
import RedWarning from 'app/assets/images/IconImages/RedWarningIcon.svg';
import { customSwitch } from 'utils/customSwitchCase/customSwitch';
import { nestedChildren } from 'app/helpers/arrayHelpers';
import { convertNumberToBoolean } from 'app/helpers/converters';
import { missingDate, missingField } from 'app/constants';
import { GetMomentOfDate } from 'utils/dates/moment';
import {
  getCredentialName,
  getDropDownDescription,
} from 'app/components/Candidate/CandidateProfile/CandidateTabPanel/CredentialsTab/TableData/tableHelpers';
import { CredentialsAPICategories } from 'app/components/Candidate/CandidateProfile/CandidateTabPanel/CredentialsTab/Search/searchHelpers';
import { convertToFormat } from 'app/helpers/dateHelper';
import { groupedOtherCertifications } from 'app/components/Candidate/CandidateProfile/CandidateTabPanel/CredentialsTab/TableData/Certifications/OtherCertifications';
import { groupedLifeCertifications } from 'app/components/Candidate/CandidateProfile/CandidateTabPanel/CredentialsTab/TableData/Certifications/LifeSupportCertifications';
import { groupedCertifications } from 'app/components/Candidate/CandidateProfile/CandidateTabPanel/CredentialsTab/TableData/Certifications/Certfications';
import { ICredentials } from 'app/models/Credentialing/Credentials';
import { IToken } from 'app/models/Notification/Notification';
import moment from 'moment';
import { Concatenate } from 'utils/string/string';

export const getPlacementListIcon = customSwitch({
  1: <img src={GreenCheck} alt="GreenCheck" />,
  2: <img src={OrangeWarning} alt="OrangeWarning" />,
})(<img src={RedWarning} alt="RedWarning" />);

export const formatCredentialCertificates = (data, dataLookups) => {
  const d: ICredentials[] = data.map(item => {
    const dateVerified = convertToFormat(item.dateVerified, 'MM/DD/yyyy');
    const issuedDate = convertToFormat(item.issuedDate, 'MM/DD/yyyy');
    const expirationDate = convertToFormat(item.expirationDate, 'MM/DD/yyyy');
    const fieldExists = field => (field ? field : missingField);

    const certificateInfo = {
      category: item.category,
      subCategory: item.subCategory,
      credentialName: item.credentialName,
      credentialNameGrid: getCredentialName(item.requirementType, item.credentialName),
      credentialNameSort: `${item.requirementType} - ${item.credentialName}`,
      dateVerified:
        item.subCategory === CredentialsAPICategories.certificationsOtherCertifications
          ? 'N/A'
          : dateVerified
          ? dateVerified
          : missingField,
      dateVerifiedNoFormat:
        item.subCategory === CredentialsAPICategories.certificationsOtherCertifications || !dateVerified
          ? missingDate
          : item.dateVerified,
      isExpired: item.isExpired,
      expirationDate: expirationDate,
      expirationDateDisplay: expirationDate ? expirationDate : missingField,
      expirationDateNoFormat: expirationDate ? item.expirationDate : missingDate,
      issuedByOrType:
        item.subCategory === CredentialsAPICategories.certifications
          ? item.issuedBy
            ? getDropDownDescription(
                dataLookups?.filter(
                  i => i['subCategory'] === item.subCategory && i.requirementTypeID === item.requirementTypeId,
                ) || [],
                'IssuedBy',
                item.issuedBy ? parseInt(item.issuedBy) : -1,
              )
            : missingField
          : fieldExists(item.type),
      issuedBy: item.issuedBy,
      issuedDate: issuedDate,
      issuedDateDisplay: fieldExists(issuedDate),
      issuedDateNoFormat: issuedDate ? item.issuedDate : missingDate,
      requirementID: item.requirementId,
      requirementTypeID: item.requirementTypeId,
      requirementType: item.requirementType,
      typeID: item.typeId,
      type: item.type,
      verifiedID:
        item.subCategory === CredentialsAPICategories.certificationsOtherCertifications
          ? 'N/A'
          : convertNumberToBoolean(item.verifiedId)
          ? 'Yes'
          : 'No',
    };
    return certificateInfo;
  });
  const certs = nestedChildren(
    groupedCertifications(d.filter(item => item.subCategory === CredentialsAPICategories.certifications)),
  );
  const lifeSupport = nestedChildren(
    groupedLifeCertifications(
      d.filter(item => item.subCategory === CredentialsAPICategories.certificationsLifeSupportCertifications),
    ),
  );
  const other = nestedChildren(
    groupedOtherCertifications(
      d.filter(item => item.subCategory === CredentialsAPICategories.certificationsOtherCertifications),
    ),
  );
  return [...certs, ...lifeSupport, ...other].sort((a, b) =>
    a.subCategory > b.subCategory ? 1 : a.subCategory === b.subCategory ? 0 : -1,
  );
};

export const getSubstitutions = values => {
  const substitutions: IToken[] = [];

  const certificationsList: string[] = [];
  values.certifications?.map(item => {
    if (moment(item.expirationDate).isSameOrAfter(moment(), 'day')) {
      return certificationsList.push(`${item.type !== null ? item.type : ''} (exp ${item.expirationDate})`);
    }
  });

  const activeLicenses: string[] = [];
  values?.licenses?.map(item => {
    const expirationDate =
      GetMomentOfDate(item.expirationDate).format('MM/DD/YYYY') !== 'Invalid date'
        ? GetMomentOfDate(item.expirationDate).format('MM/DD/YYYY')
        : undefined;
    if (moment(expirationDate).isSameOrAfter(moment(), 'day')) {
      return activeLicenses.push(
        `${item.type !== null ? item.type : ''} ${item?.requirementType || ''} ${item.state} #${
          item.licenseNumber || 'Not Available'
        } (exp ${expirationDate})`,
      );
    }
  });

  const candidateDiscipline = Concatenate(
    values.candidateDiscipline.map(skill => Concatenate([skill.disciplineAbbr], ', ')),
    '',
  );

  const candidateSpecialty = [];
  values.candidateDiscipline.map(item => {
    const arr = item?.specialties?.map(ele => ele?.specialtyAbbr);
    candidateSpecialty.push(...arr);
  });
  candidateSpecialty.reduce((arr, item) => {
    if (!arr?.includes(item)) {
      arr.push(item);
    }
    return arr;
  }, []);

  const disciplineFullText = values.candidateDiscipline.map(skill => {
    return values.candidateDisciplineText.filter(item => item.abbreviation === skill.disciplineAbbr)[0]?.description;
  });

  const requestedTimeOff: string[] = [];

  if (values.requestedTimeOff?.requestedTimeOff?.length > 0) {
    values.requestedTimeOff?.requestedTimeOff?.map(item => {
      requestedTimeOff.push(
        `${GetMomentOfDate(item.startDate).format('MM/DD/YYYY') ?? ''} ${
          item.endDate ? `- ${GetMomentOfDate(item.endDate).format('MM/DD/YYYY')}` : ''
        }`,
      );
    });
  }

  const dateMoment = GetMomentOfDate(values.availableStartDate as unknown as string);

  substitutions.push({
    name: 'Signature',
    input: '{{signature}}',
  });

  substitutions.push({
    name: 'Candidate First Name',
    input: '{{candidate_first_name}}',
    value: values.candidateFirstName,
  });

  substitutions.push({
    name: 'Candidate Last Name',
    input: '{{candidate_last_name}}',
    value: values.candidateLastName,
  });

  substitutions.push({
    name: 'Candidate Goes By',
    input: '{{candidate_goes_by}}',
    value: values.candidateGoesBy,
  });

  substitutions.push({
    name: 'Candidate Phone Number',
    input: '{{candidate_phone_number}}',
    value: values.candidatePhoneNumber || '',
  });

  substitutions.push({
    name: 'Candidate Email',
    input: '{{candidate_email_address}}',
    value:
      values.candidateEmail && values.candidateEmail !== ''
        ? `<a href=mailto:${values.candidateEmail}>${values.candidateEmail}</a>`
        : '',
  });

  substitutions.push({
    name: 'Placement Selling Notes',
    input: '{{selling_notes}}',
    value: values.placementSellingPoints,
  });

  substitutions.push({
    name: 'Requested Time Off',
    input: '{{time_off}}',
    value: requestedTimeOff.join(', '),
  });

  substitutions.push({
    name: 'Active Licenses',
    input: '{{active_licences}}',
    value: values.activeLicences,
  });

  substitutions.push({
    name: 'Active Certifications',
    input: '{{active_certifications}}',
    value: values.activeCertifications,
  });
  substitutions.push({
    name: 'Candidate Discipline',
    input: '{{candidate_discipline}}',
    value: candidateDiscipline,
  });

  substitutions.push({
    name: 'Candidate Discipline Full Text',
    input: '{{candidate_discipline_full_text}}',
    value: disciplineFullText?.join(', '),
  });

  substitutions.push({
    name: 'Candidate Speciality',
    input: '{{candidate_speciality}}',
    value: candidateSpecialty?.join(', '),
  });

  substitutions.push({
    name: 'Candidate Speciality Full Text',
    input: '{{candidate_speciality_full_text}}',
    value: candidateSpecialty?.join(', '),
  });

  substitutions.push({
    name: 'Order Skill Set',
    input: '{{order_skillsets}}',
    value: values.orderSkillSets?.join(', '),
  });

  substitutions.push({
    name: 'Available Start Date',
    input: '{{available_start_date}}',
    value: dateMoment.isValid() ? dateMoment.format('MM/DD/YYYY') : '-',
  });

  substitutions.push({
    name: 'Facility Unit',
    input: '{{facility_unit}}',
    value: values.facilityUnit,
  });

  substitutions.push({
    name: 'COVID Vaccine Status',
    input: '{{candidate_covid_vaccine_status}}',
    value: values.COVIDVaccineStatus,
  });

  substitutions.push({
    name: 'Flu Vaccine Status',
    input: '{{candidate_flu_vaccine_status}}',
    value: values.fluVaccinationStatus,
  });

  substitutions.push({
    name: 'Account Manager First Name',
    input: '{{account_manager_first_name}}',
    value: values.accountManagerFirstName,
  });

  substitutions.push({
    name: 'Account Manager Last Name',
    input: '{{account_manager_last_name}}',
    value: values.accountManagerLastName,
  });

  substitutions.push({
    name: 'Account Manager Email Address',
    input: '{{account_manager_email_address}}',
    value: values.accountManagerEmailAddress,
  });

  substitutions.push({
    name: 'Account Manager Phone Number',
    input: '{{account_manager_phone_number}}',
    value: values.accountManagerPhoneNumber,
  });

  substitutions.push({
    name: 'Total Years of Experience',
    input: '{{total_years_of_experience}}',
    value: `${
      values.experience?.data.totalYearsOfExp ? values.experience?.data?.totalYearsOfExp?.toString() + 'Y' : ''
    } ${values.experience?.data.totalMonthsOfExp ? values.experience?.data?.totalMonthsOfExp?.toString() + 'M' : ''}`,
  });

  substitutions.push({
    name: 'Current AMN Traveler',
    input: '{{current_amn_traveler}}',
    value: values.currentAMNTraveler ? 'Yes' : 'No',
  });

  substitutions.push({
    name: 'VMS Req Number',
    input: '{{vms_request_number}}',
    value: values.vmsReqNumber,
  });

  substitutions.push({
    name: 'Order Shift',
    input: '{{order_shift}}',
    value: values.orderShift,
  });

  substitutions.push({
    name: 'Duration of Assignment',
    input: '{{duration_of_assignment}}',
    value: values.assignmentDuration,
  });

  substitutions.push({
    name: 'GWW (Hours)',
    input: '{{order_gww}}',
    value: values.orderGww,
  });

  substitutions.push({
    name: 'EMR',
    input: '{{candidate_emr}}',
    value: values.candidateEmr?.join(', '),
  });

  substitutions.push({
    name: 'Placement Start Date',
    input: '{{placement_start_date}}',
    value: GetMomentOfDate(values?.placementStartDate).format('MM/DD/YYYY') ?? '',
  });

  substitutions.push({
    name: 'Placement End Date',
    input: '{{placement_end_date}}',
    value: GetMomentOfDate(values?.placementEndDate).format('MM/DD/YYYY') ?? '',
  });

  substitutions.push({
    name: 'Candidate Id',
    input: '{{candidate_id}}',
    value: values.candidateId,
  });

  substitutions.push({
    name: 'Placement Id',
    input: '{{placement_id}}',
    value: values.placementId,
  });

  substitutions.push({
    name: 'License Number',
    input: '{{license_number}}',
    value: values.licenseNumber,
  });

  return substitutions;
};
