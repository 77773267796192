import React, { useEffect, useState } from 'react';
import { Grid } from 'amn-ui-core';
import { CertificationsTile } from './CertificationsTile';
import { SkillSetSection } from './SkillSetSection';
import { UnitGridLicensesWrapper } from '../UnitGridLicensesWrapper';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { AndOrPopUp, AndOrSwitchButton } from './AndOrButtonComponents';
import { getDefaultSkillSet } from '../../UnitDetailDefaultValues';
import { CollectionType, AndOrOperator } from 'app/enums/Common';
import { SkillHeader } from './SkillHeader';
import { TravelTile } from './TravelTile';
import { useDispatch } from 'react-redux';
import { FacilityUnitActions } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/UnitTab/Store/FacilityUnit.redux';
import { useSkillSetStyles } from './Experience.styles';
import { useDecision } from '@optimizely/react-sdk';
import { ff_unit_ui_duplicate_skill_set } from 'app/constants/FeatureFlags/Facility/Keys';

export const SkillSet = ({ groupIndex, setExperienceCount }) => {
  const { classes } = useSkillSetStyles();
  const {
    control,
    watch,
    trigger,
    formState: { isSubmitted },
  } = useFormContext();
  const operationValue = watch(`skillSetGroup.groups[${groupIndex}].operation`);
  const [duplicateCetificateData, setDuplicateCetificateData] = useState(null);
  const [numberOfFilteredSpeciality, setNumberOfFilteredSpeciality] = useState(0);
  const [canAddCertificate, setCanAddCertificate] = useState(false);
  const [enableDuplicateSkillSet] = useDecision(ff_unit_ui_duplicate_skill_set);  
  const dispatch = useDispatch();
  const {
    fields: skillSetFields,
    append: appendSkillSet,
    remove: removeSkillSet,
  } = useFieldArray({
    control,
    name: `skillSetGroup.groups[${groupIndex}].skillsets`,
  });

  useEffect(() => {
    setExperienceCount(skillSetFields?.length || 0);
    const selectedSubCerts = [];
    skillSetFields?.forEach((skill, index) => {
      const subCerts = skill?.collections[1]?.requirements
        ?.map(each => each?.subCertificate?.[0]?.subCertificate?.id)
        ?.filter(Boolean);
      selectedSubCerts.push({ skillSetIndex: index, subCerts: subCerts });
    });
    dispatch(FacilityUnitActions.setSelectedSubCert(selectedSubCerts));
  }, [skillSetFields]);

  const handleAdd = () => {
    appendSkillSet(getDefaultSkillSet(skillSetFields.length));
    setCanAddCertificate(true);
    setExperienceCount(++skillSetFields.length);
  };

  const handleOnDeleteSkillSet = async (skillSetId: number) => {
    await removeSkillSet(skillSetId);
    if (isSubmitted) {
      trigger();
    }
    setExperienceCount(--skillSetFields.length);
  };

  const handleDuplicate = (skillSetId, isAnd) => {
    if (enableDuplicateSkillSet.enabled) {
      const lastSkillSetValue = watch(`skillSetGroup.groups[${groupIndex}].skillsets`)[skillSetId];
      const updatedValue = {
        ...lastSkillSetValue,
        id: skillSetFields.length,
        isDuplicate: true,
        skillSet: isAnd
          ? { ...lastSkillSetValue.skillSet, specialty: null, subSpecialty: null, isRequired: false }
          : { ...lastSkillSetValue.skillSet },
      };
      setDuplicateCetificateData(
        lastSkillSetValue?.collections[CollectionType.CertificationsIndex]?.requirements
          ? lastSkillSetValue?.collections[CollectionType.CertificationsIndex]?.requirements
          : [],
      );
      appendSkillSet(updatedValue);
    } else {
      const lastSkillSetValue = watch(`skillSetGroup.groups[${groupIndex}].skillsets`)[skillSetFields.length - 1];
      const updatedValue = {
        ...lastSkillSetValue,
        id: skillSetFields.length,
        isDuplicate: true,
        skillSet: { ...lastSkillSetValue.skillSet, specialty: null, subSpecialty: null, isRequired: false },
      };
      setDuplicateCetificateData(
        lastSkillSetValue?.collections[CollectionType.CertificationsIndex]?.requirements
          ? lastSkillSetValue?.collections[CollectionType.CertificationsIndex]?.requirements
          : [],
      );
      appendSkillSet(updatedValue);
    }
    
    setCanAddCertificate(true);
    setExperienceCount(++skillSetFields.length);
  };

  return (
    <>
      {skillSetFields?.map((skillSetField, skillSetIndex) => {
        return (
          <Grid key={skillSetField.id}>
            <Grid container className={classes.container}>
              <Grid container padding={2} gap={1}>
                <SkillHeader
                  groupIndex={groupIndex}
                  skillSetIndex={skillSetIndex}
                  onDeleteSkillSet={() => handleOnDeleteSkillSet(skillSetIndex)}
                />
                <SkillSetSection
                  skillSetIndex={skillSetIndex}
                  groupIndex={groupIndex}
                  skillSetField={skillSetField}
                  numberOfSkillSets={skillSetFields.length}
                  setNumberOfFilteredSpeciality={setNumberOfFilteredSpeciality}
                />
              </Grid>
              <TravelTile skillSetIndex={skillSetIndex} groupIndex={groupIndex} skillSetField={skillSetField} />
              <UnitGridLicensesWrapper skillSetIndex={skillSetIndex} groupIndex={groupIndex} value={skillSetField} />
              <CertificationsTile
                skillSetIndex={skillSetIndex}
                groupIndex={groupIndex}
                skillSetField={skillSetField}
                duplicateCetificateData={duplicateCetificateData}
                canAddCertificate={canAddCertificate}
                setCanAddCertificate={setCanAddCertificate}
                numberOfSkillSets={skillSetFields.length}
                setDuplicateCetificateData={setDuplicateCetificateData}
              />
            </Grid>
            <Grid className={classes.buttonContainer}>
              {skillSetFields.length - 1 === skillSetIndex ? (
                <AndOrPopUp
                  handleAdd={handleAdd}
                  handleDuplicate={handleDuplicate}
                  value={skillSetFields?.length > 1 ? operationValue : null}
                  groupIndex={groupIndex}
                  skillSetIndex={skillSetIndex}
                  numberOfFilteredSpeciality={numberOfFilteredSpeciality}
                />
              ) : (
                <Controller
                  name={`skillSetGroup.groups[${groupIndex}].operation`}
                  control={control}
                  defaultValue={operationValue}
                  render={({ ref, onChange, ...rest }) => (
                    <AndOrSwitchButton value={operationValue} onChange={onChange} />
                  )}
                />
              )}
            </Grid>
          </Grid>
        );
      })}
    </>
  );
};
