import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Theme, Grid, CircularProgress } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { Cancel } from 'app/components/Common/CancelModal/Cancel';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { selectUser } from 'oidc/user.selectors';
import moment from 'moment';
import { useReadOnly } from 'oidc/userRoles';
import { brandsSelector, disciplineDataSelector, specialtiesSelector } from 'store/redux-store/lookup/lookup.selectors';
import { trackException } from 'app-insights/appInsightsTracking';
import { postCandidateWorkExperience } from 'app/services/CandidateServices/CandidateServices';
import countriesData from 'app/assets/jsons/Country.json';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import { useDecision } from '@optimizely/react-sdk';
import { ff_WorkExperience_Enhancement } from 'app/constants/FeatureFlags/Candidate/Keys';
import {
  workExperienceActions,
  workExperienceReducer,
  workExperienceSliceKey,
} from 'store/redux-store/candidate-work-experience/slice';
import {
  JobTypeIDs,
  getJobTypeValue,
  getWorkExpLastUpdatedText,
} from 'store/redux-store/candidate-work-experience/helper';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { workExperienceSaga } from 'store/redux-store/candidate-work-experience/saga';
import { candidateDetailsSelection } from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import { reasonForLeavingDropDownOptions } from '../EditWorkExperience/Constants';
import { candidateDetailActions } from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.redux';
import { AddWorkExperienceForm } from './AddWorkExperienceForm';
import { WorkExperienceView } from '../viewComponents/WorkExperienceView';
import { ITypeAheadOption } from '../../../PreferencesTab/CustomComponents/ControlledTypeAhead';

const useStyles = makeStyles()((theme: Theme) => ({
  dialogContainer: {
    minWidth: '890px',
  },
  enhancedDialogContainer: {
    minWidth: '1280px',
  },
  dialogContent: {
    paddingTop: 0,
    overflow: 'hidden',
    'overflow-y': 'auto',
  },
  MuiCircularProgressRoot: {
    left: '50%',
    position: 'absolute',
    top: '50vh',
  },
}));

enum ExcludedCountries {
  VIRGIN_ISLANDS = 213,
  PUERTO_RICO = 17,
}

const getCountryOpts = () =>
  countriesData.countries
    .filter(x => x.ID !== ExcludedCountries.PUERTO_RICO && x.ID !== ExcludedCountries.VIRGIN_ISLANDS)
    .map(e => ({
      label: e.Description,
      value: e.CountryDialingCode,
      id: e.ID,
    }));

export const AddWorkExperienceWrapper = props => {
  const { open, handleClose } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  useInjectReducer({ key: workExperienceSliceKey, reducer: workExperienceReducer });
  useInjectSaga({ key: workExperienceSliceKey, saga: workExperienceSaga });

  const candidateDetails = useSelector(candidateDetailsSelection);
  const travelCompanies = useSelector(brandsSelector);
  const disciplines = useSelector(disciplineDataSelector);
  const specialtiesLookup = useSelector(specialtiesSelector);

  const [showWorkExperienceView, setShowWorkExperienceView] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [cancel, setCancel] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [travelCompanyData, setTravelCompanyData] = useState<any[]>([]);
  const [saveDisabled, setSaveDisabled] = useState<boolean>(true);
  const [typeAheadOptions, setTypeAheadOptions] = useState<{
    reasonForLeaving: ITypeAheadOption[];
    disciplines: ITypeAheadOption[];
    specialties: ITypeAheadOption[];
  }>({ disciplines: [], specialties: [], reasonForLeaving: reasonForLeavingDropDownOptions });
  const user = useSelector(selectUser);
  const { readOnly } = useReadOnly();
  const [countryOptions] = useState(getCountryOpts());
  const formMethods = useForm({ mode: 'all', defaultValues: { pCountry: { label: 'USA', value: '1', id: 1 } } });

  const [enhancedWorkExperienceModal] = useDecision(ff_WorkExperience_Enhancement);
  const {
    handleSubmit,
    setError,
    formState: { dirtyFields, errors, touched },
  } = formMethods;

  useEffect(() => {
    if (travelCompanyData.length === 0) {
      fetchWork();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchWork = async () => {
    setIsLoading(true);
    var mapResult = travelCompanies?.map(x => {
      return {
        id: x.value,
        label: x.name,
      };
    });
    const sortedDisciplines = disciplines ? [...disciplines].sort((a, b) => a.label.localeCompare(b.label)) : [];
    setTravelCompanyData(mapResult);
    setTypeAheadOptions({
      disciplines: sortedDisciplines || [],
      specialties: specialtiesLookup || [],
      reasonForLeaving: reasonForLeavingDropDownOptions,
    });
    setIsLoading(false);
  };

  const validateWorkForm = data => {
    var reasonForLeaving = enhancedWorkExperienceModal.enabled ? data.pReason?.label : data.pReason;
    var isWorkFormValid = true;
    const endFromDate = new Date(data.pEnd);
    const startDate = new Date(data.pStart);
    if (!data.currentlyWorking && !data.pOngoing && endFromDate?.getTime() < startDate?.getTime()) {
      setError('pEnd', {
        type: 'validate',
        message: 'End date must be greater than start date',
      });
      isWorkFormValid = false;
    }

    const StartFromDate = new Date(data.pStart);
    const endDate = data.pEnd ? new Date(data.pEnd) : null;
    if (!data.pOngoing && StartFromDate?.getTime() > endDate?.getTime()) {
      setError('pStart', {
        type: 'validate',
        message: 'Start date must be less than end date',
      });
      isWorkFormValid = false;
    }
    if (data.pAddWorkExperience) {
      if (data.pPosition && data.pPosition?.length > 50) {
        setError('pPosition', {
          type: 'maxLength',
          message: 'max character limit: 50',
        });
        isWorkFormValid = false;
      }
      if (data.pUnitFloorDept && data.pUnitFloorDept?.length > 50) {
        setError('pUnitFloorDept', {
          type: 'maxLength',
          message: 'max character limit: 50',
        });
        isWorkFormValid = false;
      }
      if (!data.currentlyWorking && reasonForLeaving?.length > 100) {
        setError('pReason', {
          type: 'maxLength',
          message: 'max character limit: 100',
        });
        isWorkFormValid = false;
      }
      if (data.piCity?.length > 100) {
        setError('piCity', {
          type: 'maxLength',
          message: 'max character limit: 100',
        });
        isWorkFormValid = false;
      }
      if (data.pFacilityEmployer?.length > 100) {
        setError('pFacilityEmployer', {
          type: 'validate',
          message: 'character limit: 100',
        });
        isWorkFormValid = false;
      }
      if (data?.pnotes?.length > 1000) {
        setError('pnotes', {
          type: 'maxLength',
          message: 'Maximum Characters Reached',
        });
        isWorkFormValid = false;
      }

      if (data.pBeds && data.pBeds > 9999) {
        setError('pBeds', {
          type: 'maxLength',
          message: '# of Beds must be less than 10,000',
        });
        isWorkFormValid = false;
      } else if (data.pBeds && data.pBeds < 0) {
        setError('pBeds', {
          type: 'minLength',
          message: '# of Beds must be greater than 0',
        });
        isWorkFormValid = false;
      }
      var countryIDFilter = data.pCountry?.id;
      if (countryIDFilter === 1 || countryIDFilter === 2) {
        if (parseInt(data.piZip) > 99999) {
          setError('piZip', {
            type: 'validate',
            message: 'Zip must be less than 5 digits',
          });
          isWorkFormValid = false;
        }
      } else if (data.piZip?.length > 20) {
        setError('piZip', {
          type: 'validate',
          message: 'Zip must be less than 20 digits',
        });
        isWorkFormValid = false;
      }
    }
    return isWorkFormValid;
  };

  const onSubmit = async (data, e) => {
    if (!validateWorkForm(data)) return;
    var reasonForLeaving = enhancedWorkExperienceModal.enabled ? data.pReason?.label : data.pReason;
    var specialtyLabel = enhancedWorkExperienceModal.enabled ? data.piSpecialty?.label : data.piSpecialty;
    var exYears = new Date(new Date(data.pEnd).valueOf() - new Date(data.pStart).valueOf()).getFullYear() - 1970;
    var exMonths =
      new Date(data.pEnd).getMonth() -
      new Date(data.pStart).getMonth() +
      12 * (new Date(data.pEnd).getFullYear() - new Date(data.pStart).getFullYear());
    const bodyPayload = {
      candidateId: candidateDetails?.travelerId,
      brandId: candidateDetails?.brandId,
      workHistoryId: 0,
      startedOn: moment(data.pStart).format('yyyy-MM-DDT00:00:00'),
      endedOn: data.pEnd ? moment(data.pEnd).format('yyyy-MM-DDT00:00:00') : null,
      yearsOfExperience: exYears || 0,
      monthsOfExperience: exMonths || 0,
      discipline: data.piDiscipline?.label || '',
      disciplineAbbr: data.piDiscipline?.object?.Abbreviation || '',
      specialty: specialtyLabel || '',
      specialtyAbbr: specialtyLabel || '',
      position: data.pPosition || null,
      approvedReferenceCount: 0,
      facilityName: data.pAddTimeOff ? 'Personal Time Off' : data.pFacilityEmployer || '',
      location: {
        address1: null,
        address2: null,
        city: typeof data.piCity === 'string' ? data.piCity : data.piCity?.label,
        stateProvinceID: typeof data.pState === 'string' ? data.pState : data.pState?.id,
        state: typeof data.pState === 'string' ? data.pState : data.pState?.label,
        country: data.pCountry ? data.pCountry?.label : null,
        zip: data.piZip || null,
        countryId: data.pCountry ? data.pCountry.id : null,
        latitude: null,
        longitude: null,
      },
      unit: data.pUnitFloorDept || null,
      travelCompany: data.piTravelerCompany?.label || null,
      staffingAgency: data.pStaffing ? 'Y' : 'N',
      isTeachingHospital: data.pHospital ? 'Y' : 'N',
      numberOfBedsInFacility: data.pBeds ? parseInt(data.pBeds) : null,
      currentlyEmployed: data.currentlyWorking ? true : !data.pEnd ? true : false,
      reasonForLeaving: data.pAddTimeOff ? data.pPaidTimeOffReason : reasonForLeaving,
      ref: null,
      travelCompanyId: data.piTravelerCompany?.id || null,
      specialtyId: typeAheadOptions?.specialties?.find(x => x.label === specialtyLabel)?.object?.SpecialtyID || null,
      disciplineId: data.piDiscipline?.object?.ID || null,
      otherDiscipline: null,
      otherSpecialty: null,
      equipmentProcedures: data.pnotes?.replace(/\r?\n/g, '\r\n') || null,
      isSupervisor: data.pSupervisor || null,
      isTeletherapy: data.pTeletherapy || null,
      currentEmployeeId: user.userInfo?.employeeId || 8410,
      currentEmployeeName: user.userInfo?.firstName + ' ' + user.userInfo?.lastName,
      jobTypeID: data?.pAddTimeOff ? JobTypeIDs.TimeOff : JobTypeIDs.Travel, //To-Do: Modify this when we have more Job Types availale in UI
    };
    setIsLoading(true);
    const response = await postCandidateWorkExperience(bodyPayload);
    if (response.status == 200 && response.data.timeStamp) {
      dispatch(
        globalActions.setSnackBar({
          message: t('candidate.workExperience.snackbar.addWorkExperience'),
          severity: 'success',
        }),
      );
      setIsLoading(false);
      const temp: any[] = [];
      candidateDetails.experience?.employment.map(x => {
        temp.push(x);
      });
      const newData = {
        ...response.data,
        workHistoryId: response.data.workHistoryId,
        startedOn: data.pStart,
        endedOn: response.data.endedOn,
        yearsOfExperience: response.data.yearsOfExperience,
        monthsOfExperience: response.data.monthsOfExperience,
        discipline: data.piDiscipline?.label || '',
        disciplineAbbr: data.piDiscipline?.object?.Abbreviation || '',
        specialty: specialtyLabel || '',
        specialtyAbbr: specialtyLabel || '',
        position: data.pPosition,
        approvedReferenceCount: 0,
        inProgressReferenceCount: 0,
        unapprovedReferenceCount: 0,
        verifiedReferenceCount: 0,
        hasEMPVReference: false,
        facilityName: data.pAddTimeOff ? 'Personal Time Off' : data.pFacilityEmployer,
        location: {
          address1: '',
          city: typeof data.piCity === 'string' ? data.piCity : data.piCity?.label,
          stateProvinceID: response.data.location?.stateProvinceID,
          state: typeof data.pState === 'string' ? data.pState : data.pState?.id,
          country: data.pCountry?.label,
          zip: data.piZip,
        },
        unit: data.pUnitFloorDept,
        isSupervisor: data.pSupervisor,
        isTeletherapy: data.pTeletherapy,
        travelCompany: response?.data?.travelCompany,
        travelCompanyId: response?.data?.travelCompanyId,
        staffingAgency: data.pStaffing ? 'Y' : 'N',
        isTeachingHospital: data.pHospital ? 'Y' : 'N',
        numberOfBedsInFacility: data.pBeds ? parseInt(data.pBeds) : null,
        currentlyEmployed: response?.data?.currentlyEmployed,
        placementID: null,
        reasonForLeaving: data.pAddTimeOff ? data.pPaidTimeOffReason : reasonForLeaving,
        timeStamp: response?.data?.timeStamp,
        equipmentProcedures: data.pnotes?.replace(/\r?\n/g, '\r\n'),
        disciplineId: response.data?.disciplineId,
        specialtyId: response?.data?.specialtyId,
        jobType: getJobTypeValue(response.data),
        lastUpdatedText: getWorkExpLastUpdatedText(response.data),
        referenceData: [],
      };
      temp.push(newData);

      dispatch(candidateDetailActions.setEmployment(temp));
      dispatch(
        workExperienceActions.onInitAction({
          candidateId: candidateDetails?.travelerId,
          brandId: candidateDetails?.brandId,
        }),
      );
      dispatch(workExperienceActions.setSelectedWorkExperience(newData));

      enhancedWorkExperienceModal.enabled && setShowWorkExperienceView(true);
    } else {
      dispatch(
        globalActions.setSnackBar({
          message: t('common.failedLoad'),
          severity: 'error',
        }),
      );
      setIsLoading(false);
      enhancedWorkExperienceModal.enabled && handleClose();
    }
    !enhancedWorkExperienceModal.enabled && handleClose();
  };

  const onError = (errors, e) => {
    dispatch(globalActions.setSnackBar({ message: 'An error has occurred. Please try again', severity: 'error' }));
    trackException({ exception: new Error('Error editing work history'), properties: { errors } });
  };

  const handleCancel = () => {
    if (!_.isEmpty(errors) || !_.isEmpty(dirtyFields) || !_.isEmpty(touched)) setCancel(true);
    else yesAction();
  };

  const yesAction = () => {
    handleClose();
    setCancel(false);
  };

  const noAction = () => {
    setCancel(false);
  };

  return (
    <>
      {!showWorkExperienceView ? (
        isLoading ? (
          <CircularProgress className={classes.MuiCircularProgressRoot} />
        ) : (
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
              <GenericDialog
                variant={'blue'}
                classes={{ paper: classes.enhancedDialogContainer }}
                open={open}
                disablePortal
                disableEscapeKeyDown
                dialogTitleProps={{
                  text: `${t('candidate.dialogActions.AddWorkExperienceTitle')} - ${candidateDetails.name.first} ${
                    candidateDetails.name.last
                  } (${candidateDetails.travelerId})`,
                  closeButton: true,
                  expandable: true,
                }}
                dialogContentProps={{
                  classes: { root: classes.dialogContent },
                }}
                onClose={handleCancel}
              >
                <Grid spacing={3}>
                  <Grid item xs={12}>
                    {
                      <AddWorkExperienceForm
                        travelCompanyData={travelCompanyData}
                        typeAheadOptions={typeAheadOptions}
                        setSaveDisabled={setSaveDisabled}
                        countriesData={countryOptions}
                        dialogActions={[
                          {
                            onClick: handleCancel,
                            text: 'CANCEL',
                            variant: 'contained',
                            color: 'tertiary',
                          },
                          {
                            text: `${t('candidate.dialogActions.SaveWorkExperience')}`,
                            variant: 'contained',
                            type: 'submit',
                            disabled: saveDisabled || readOnly,
                            tooltipProps: readOnly
                              ? {
                                  tooltipContent: t('global.readOnlyTooltip'),
                                }
                              : undefined,
                          },
                        ]}
                      />
                    }
                  </Grid>
                </Grid>
              </GenericDialog>
              {cancel && <Cancel openDialog={cancel} handleConfirmAction={yesAction} handleCancelAction={noAction} />}
            </form>
          </FormProvider>
        )
      ) : (
        <WorkExperienceView opened={showWorkExperienceView} handleClosed={() => handleClose()} />
      )}
    </>
  );
};
