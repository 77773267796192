import { taskDetailsActions, taskDetailsReducer, taskDetailsSliceKey } from 'app/components/Tasks/store/Tasks.redux';
import { taskDetailsSaga } from 'app/components/Tasks/store/Tasks.saga';
import {
  selectInternalTaskDetailsData,
  selectIsExternal,
  selectNotificationContainer,
  selectNotificationPrimaryContext,
  selectTaskStatusData,
  selectVersionUpdated,
} from 'app/components/Tasks/store/Tasks.selectors';
import { useEffect } from 'react';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import _isArray from 'lodash/isArray';
import {
  NotificationBrand,
  NotificationSender,
  StatusUpdateModalVersion,
  Task,
  TaskEntity,
} from 'app/models/Tasks/Tasks';
import { useTranslation } from 'react-i18next';
import { getTaskEntityValue } from './Common/FormatData';
import { selectUser } from 'oidc/user.selectors';
import { getPlacementHeader } from 'app/services/PlacementServices/PlacementServices';
import { Concatenate } from 'utils/string/string';
import { getFacilityDetails } from 'app/services/FacilityServices/FacilityServices';
import { getOrderDetailsById } from 'app/services/OrderServices/OrderServices';
import { getCandidateById } from 'app/services/CandidateServices/CandidateServices';
import { isPositiveNumber } from 'app/helpers/componentHelpers';

export const useStatusUpdateNotification = () => {
  const taskDetails = useSelector(selectTaskStatusData);
  const userInfo = useSelector(selectUser);
  const isExternal = useSelector(selectIsExternal);
  const parentTaskDetails = useSelector(selectInternalTaskDetailsData);
  const notificationContainer = useSelector(selectNotificationContainer);
  const notificationContext = useSelector(selectNotificationPrimaryContext);
  const versionUpdated = useSelector(selectVersionUpdated);
  const { t } = useTranslation();
  useInjectSaga({ key: taskDetailsSliceKey, saga: taskDetailsSaga });
  useInjectReducer({ key: taskDetailsSliceKey, reducer: taskDetailsReducer });
  const dispatch = useDispatch();

  const addCreatedBy = () => {
    const createdByUser = {
      name: taskDetails?.createdByFirstName + ' ' + taskDetails?.createdByLastName,
      email: `${taskDetails?.createdByFirstName}.${taskDetails?.createdByLastName}@amnhealthcare.com`,
      phoneNumber: '',
      contactId: taskDetails?.createdById?.toString(),
    };
    return createdByUser;
  };

  const getAssignedToList = () => {
    const assignedToArray: any[] = [];
    let isUserExists: boolean = false;
    if (_isArray(taskDetails?.assignedTo)) {
      taskDetails?.assignedTo?.map(assigned => {
        if (taskDetails?.createdById === assigned?.assignedToId) {
          isUserExists = true;
        }
      });
    } else {
      if (taskDetails?.createdById === taskDetails?.assignedToId) {
        isUserExists = true;
      }
    }

    if (!isUserExists && taskDetails?.createdById !== userInfo?.userInfo?.employeeId) {
      assignedToArray.push(addCreatedBy());
    }
    if (_isArray(taskDetails?.assignedTo)) {
      taskDetails?.assignedTo?.map(assinged => {
        if (assinged?.assignedToId !== userInfo?.userInfo?.employeeId) {
          const assignedValue = {
            name: assinged?.assignedToFirstName + ' ' + assinged?.assignedToLastName,
            email: `${assinged?.assignedToFirstName}.${assinged?.assignedToLastName}@amnhealthcare.com`,
            phoneNumber: '',
            contactId: assinged?.assignedToId?.toString(),
          };
          assignedToArray.push(assignedValue);
        }
      });
    } else {
      if (taskDetails?.assignedToId !== userInfo?.userInfo?.employeeId) {
        const assignedValue = {
          name: taskDetails?.assignedToFirstName + ' ' + taskDetails?.assignedToLastName,
          email: `${taskDetails?.assignedToFirstName}.${taskDetails?.assignedToLastName}@amnhealthcare.com`,
          phoneNumber: '',
          contactId: taskDetails?.assignedToId?.toString(),
        };
        assignedToArray.push(assignedValue);
      }
    }
    return assignedToArray;
  };

  const getTaskStatusNotifications = async notificationContain => {
    let entityId: string = '0';
    let container: TaskEntity;
    let primaryText: string = '';

    if (notificationContainer) {
      container = notificationContainer;
    } else {
      if (notificationContain) {
        container = notificationContain;
      }
    }

    if (container === TaskEntity.PLACEMENTS) {
      entityId = taskDetails?.candidateId?.toString();
      if (notificationContext && container === notificationContainer) {
        primaryText = notificationContext;
      } else {
        if (taskDetails?.placementId) {
          await getPlacementHeader(taskDetails?.placementId).then(response => {
            primaryText = Concatenate(
              [
                response?.candidate?.firstName,
                ' ',
                response?.candidate?.middleName || '',
                ' ',
                response?.candidate?.lastName,
              ],
              '',
            );
          });
        }
      }
    } else if (container === TaskEntity.FACILITIES) {
      entityId = taskDetails?.facilityId?.toString();
      if (notificationContext && container === notificationContainer) {
        primaryText = notificationContext;
      } else {
        if (taskDetails?.facilityId) {
          await getFacilityDetails(taskDetails?.facilityId).then(response => {
            primaryText = response?.facilityName;
          });
        }
      }
    } else if (container === TaskEntity.ORDERS) {
      entityId = taskDetails?.facilityId?.toString();
      if (notificationContext && container === notificationContainer) {
        primaryText = notificationContext;
      } else {
        if (taskDetails?.orderId) {
          await getOrderDetailsById(taskDetails?.orderId).then(response => {
            primaryText = response?.facility?.name;
          });
        }
      }
    } else if (container === TaskEntity.CANDIDATES) {
      entityId = taskDetails?.candidateId?.toString();
      if (notificationContext && container === notificationContainer) {
        primaryText = notificationContext;
      } else {
        const taskDetailsCompanyId = taskDetails?.companyId?.toString() || '1';
        if (isPositiveNumber(taskDetails?.candidateId) && isPositiveNumber(taskDetailsCompanyId)) {
          await getCandidateById(taskDetails?.candidateId?.toString(), taskDetails?.CompanyId).then(response => {
            primaryText = Concatenate(
              [response?.name?.first, ' ', response?.name?.middle || '', ' ', response?.name?.last],
              '',
            );
          });
        }
      }
    } else {
      entityId = '0';
      primaryText = '';
    }

    const statusText = taskDetails?.statusId === 3 ? 'has completed' : 'has cancelled';
    if (primaryText) {
      const taskNotificationPayload = {
        brand: NotificationBrand,
        useType: Task,
        useSubType: taskDetails?.statusId?.toString(),
        sendType: 'Bulk',
        sender: NotificationSender,
        ttlValue: 2,
        ttlPeriod: 'w',
        associatedRecords: [
          {
            name: 'TaskId',
            value:
              versionUpdated === StatusUpdateModalVersion.PARENT_TASK
                ? taskDetails?.taskId?.toString()
                : parentTaskDetails?.taskId?.toString(),
          },
          {
            name: 'TaskName',
            value:
              versionUpdated === StatusUpdateModalVersion.PARENT_TASK
                ? taskDetails?.task
                : taskDetails?.task?.split('-')[1] ?? taskDetails?.task,
          },
          {
            name: 'UpdatedUser',
            value: taskDetails?.statusUpdatedByName,
          },
          {
            name: 'CurrentContainer',
            value: getTaskEntityValue(container, t),
          },
          {
            name: 'PrimaryContext',
            value: primaryText,
          },
          {
            name: 'PlacementId',
            value: taskDetails?.placementId ? taskDetails?.placementId?.toString() : '',
          },
          {
            name: 'FacilityId',
            value: taskDetails?.facilityId ? taskDetails?.facilityId?.toString() : '',
          },
          {
            name: 'UnitId',
            value: taskDetails?.unitId ? taskDetails?.unitId?.toString() : '',
          },
          {
            name: 'CandidateId',
            value: taskDetails?.candidateId ? taskDetails?.candidateId?.toString() : '',
          },
          {
            name: 'OrderId',
            value: taskDetails?.orderId ? taskDetails?.orderId?.toString() : '',
          },
          {
            name: 'Description',
            value: ` ${
              versionUpdated === StatusUpdateModalVersion.PARENT_TASK
                ? taskDetails?.statusUpdatedByName + ' ' + statusText
                : taskDetails?.createdByFirstName +
                  ' ' +
                  taskDetails?.createdByLastName +
                  ' ' +
                  ':' +
                  ' ' +
                  taskDetails?.description
            }`,
          },
          {
            name: 'TaskCategory',
            value: taskDetails?.taskCategory ? taskDetails?.taskCategory : '',
          },
          {
            name: 'DueDate',
            value: taskDetails?.dueDate ? taskDetails?.dueDate : '',
          },
          {
            name: 'EntityId',
            value: entityId,
          },
          {
            name: 'SentDate',
            value: moment.tz(new Date(), 'America/Los_Angeles').format(),
          },
          {
            name: 'Version',
            value: versionUpdated.toString(),
          },
        ],
        body: '',
        level: 1,
        templateId: '',
        tos: getAssignedToList(),
        ccs: [],
        groupName: '',
      };
      if (
        taskNotificationPayload?.tos?.length > 0 &&
        (taskNotificationPayload?.useSubType === '3' || taskNotificationPayload?.useSubType === '4')
      ) {
        if (taskDetails?.companyId > 0) {
          taskNotificationPayload?.associatedRecords?.push({
            name: 'BrandId',
            value: taskDetails?.companyId?.toString(),
          });
        }
        dispatch(taskDetailsActions.sendTaskUpdatedNotification(taskNotificationPayload));
        dispatch(taskDetailsActions.setVersionUpdated(null));
        dispatch(taskDetailsActions.setTaskStatusData(undefined));
      }
    }
  };
  useEffect(() => {
    let notificationEntity;
    if (taskDetails?.placementId) {
      notificationEntity = TaskEntity.PLACEMENTS;
    } else if (taskDetails?.facilityId) {
      notificationEntity = TaskEntity.FACILITIES;
    } else if (taskDetails?.orderId) {
      notificationEntity = TaskEntity.ORDERS;
    } else if (taskDetails?.candidateId) {
      notificationEntity = TaskEntity.CANDIDATES;
    }
    if (taskDetails) {
      if (
        (versionUpdated === StatusUpdateModalVersion.PARENT_TASK ||
          versionUpdated === StatusUpdateModalVersion.SUBTASK) &&
        !isExternal
      ) {
        getTaskStatusNotifications(notificationEntity);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskDetails, versionUpdated]);
};
