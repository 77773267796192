export const validDecimal = (value: string, restrictions: { numberLength: number; decimalLength: number }): boolean => {
  const regex = new RegExp(
    `^([0-9]{0,${restrictions.numberLength}}[.]{1}[0-9]{0,${restrictions.decimalLength}}|[0-9]{0,${restrictions.numberLength}})$`,
  );
  const validate = value.match(regex);
  // Code: Uncomment to restrict overall length regardless of decimal place
  // const length = value.replace(/[^0-9]/g, '').length <= restrictions.numberLength;
  return validate !== null; // && length;
};

export const validInteger = (value: string, restrictions: { numberLength: number }): boolean => {
  const regex = new RegExp(
    `^([0-9]{0,${restrictions.numberLength}})$`,
  );
  const validate = value.match(regex);
  // Code: Uncomment to restrict overall length regardless of decimal place
  // const length = value.replace(/[^0-9]/g, '').length <= restrictions.numberLength;
  return validate !== null; // && length;
};

export const formatToAmericanNotaion = (num: number) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export function kFormatter(num: number, digits: number) {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
  ];
  const regexExp = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });

  return item ? (num / item.value).toFixed(digits).replace(regexExp, '$1') + item.symbol : '0';
}
