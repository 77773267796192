/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import MailBoxIcon from 'app/assets/images/MailBox.svg';
import PhoneIcon from 'app/assets/images/Phone.svg';
import { IDrawerContactPartner } from 'app/components/Common/Drawer/HeaderContactDrawer';
import React, { useState } from 'react';
import { container } from 'styles/styleVariables';
import EmailDisabled from 'app/assets/images/CandidateHeader/email-Disabled.svg';
import PhoneDisabled from 'app/assets/images/CandidateHeader/PhoneDisabled.svg';
import { useEnableEmailCommunication, useEnableVoiceCommunication } from 'oidc/CommunicationEnabler';
import { InternalContacts } from '../../Facility/FacilityDetails/SidePanel/InternalContacts';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectFacility } from '@AMIEWEB/Facility/FacilityStore/Facility.selector';
import { useDecision } from '@optimizely/react-sdk';
import { ff_facility_ui_edit_internal_contacts } from 'app/constants/FeatureFlags/Facility/Keys';
import { ChannelType, FormName, SendType, UseSubType, UseType } from '@AMIEWEB/Notification/Constants';
import { selectUser } from 'oidc/user.selectors';
import { IEmployee, IToken } from 'app/models/Notification/Notification';
import { Concatenate } from 'utils/string/string';
import { notificationSelection } from 'store/redux-store/notification/notification.selector';
import { notificationDataActions } from 'store/redux-store/notification/notification.redux';
import { EmailDefaultValues } from 'app/models/Email/IEmail';
import { searchNotificationData } from '@AMIEWEB/GlobalSearch/helper';
import _ from 'lodash';

const useStyles = makeStyles()(theme => ({
  tabContainer: {
    borderBottom: 'none !important',
    padding: '0px !important',
    width: '100%',
    borderRadius: `${container.borderRadius} ${container.borderRadius} 0px 0px !important`,
    '& .active-tab-profile': {
      width: 'auto',
    },
  },
  profileTab: {
    position: 'relative',
    marginTop: '-2%',
    '& .MuiTabScrollButton-root.Mui-disabled': {
      opacity: '0.6',
    },
  },
  profileTabMaster: {
    marginTop: '0.5rem',
  },
  tabRoot: {
    [theme.breakpoints.up(380)]: {
      minWidth: '125px !important',
    },
  },
  tooltip: {
    display: 'grid',
  },
  tooltipArrow: {
    color: '#fff',
    '&::before': {
      border: '1px solid #555555',
    },
  },
  tooltipBackground: {
    backgroundColor: '#fff',
    color: '#555555',
    border: '1px solid #555555',
    fontSize: '12px',
  },
  root: {
    padding: 12,
  },
  customProfileList: {
    'padding-right': '2rem',
    '&.customProfileListKeyClient': {
      'padding-right': '1.2rem',
      'padding-left': '2rem',
    },
    '& .MuiListItemAvatar-alignItemsFlexStart': {
      'min-width': 'auto',
    },
    '& .MuiDivider-root': {
      'background-color': 'rgb(0 0 0 / 6%)',
    },
    '& .MuiListItem-secondaryAction': {
      'padding-right': '60px',
    },
  },
  customProfileUserName: {
    color: '#003e68',
    'font-size': '16px',
    'font-weight': '500',
  },
  secondaryTextWithOutAvatar: {
    maxWidth: 225,
    position: 'relative',
    top: '-2px',
  },
  designation: {
    color: '#333333',
    fontSize: 12,
    fontWeight: 600,
  },
  description: {
    color: '#333333',
    fontSize: 12,
    'font-weight': '400',
    'line-height': '1.2',
  },
  actionContainer: {
    right: 0,
  },
  fullWidth: {
    width: '100% !important',
  },
}));

export enum IFacilitySidePanelTabs {
  KEY_CLIENT_CONTACTS,
  INTERNAL_CONTACTS,
}

export interface IDetailsDrawerCommonComponent {
  keyInternalPartners?: IDrawerContactPartner[];
  keyClientContacts?: IDrawerContactPartner[];
  defaultSelectedTab?: number;
  children?: React.ReactNode;
  handleNotificationData?: (ChannelType, ContactType, manualLog?) => void;
}

export const HeaderContactDrawerContent = (props: IDetailsDrawerCommonComponent) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { keyClientContacts } = props;
  const dispatch = useDispatch();

  const enableEmailCommunication = useEnableEmailCommunication();
  const enableVoiceCommunication = useEnableVoiceCommunication();
  const notificationData = useSelector(notificationSelection);
  const { userInfo } = useSelector(selectUser);
  const facilityData = useSelector(selectFacility);
  const facilityDrawerState = facilityData?.facilityDrawerState;
  const [tabSelectedValue, setTabSelectedValue] = useState<number>(
    facilityDrawerState?.tabId ? facilityDrawerState?.tabId : IFacilitySidePanelTabs.KEY_CLIENT_CONTACTS,
  );
  const [showEditInternalContacts] = useDecision(ff_facility_ui_edit_internal_contacts);

  const TabPanel = ({ children, value, index, classes, ...other }) => {
    return (
      <div
        className="tab"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box className={`paper ${classes.tabContainer}`}>{children}</Box>}
      </div>
    );
  };

  const handleProfileDrawerTabChange = (event, newValue) => {
    if (newValue !== tabSelectedValue) {
      setTabSelectedValue(newValue);
    }
  };

  const handleCommunication = (props: { channelType: ChannelType; contactData: any }) => {
    const { contactData } = props;
    const associatedRecords = [
      {
        name: 'clientContactId',
        value: `${contactData?.userId}`,
      },
      { name: 'facilityId', value: contactData?.facilityId?.toString() },
      { name: 'facilityName', value: contactData?.facilityName?.toString() },
    ];
    const sender: IEmployee = {
      name: userInfo?.firstName + ' ' + userInfo?.lastName,
      email: userInfo?.email,
      senderId: `${userInfo?.employeeId}`,
      userId: `${userInfo?.employeeId}`,
      NtUserName: userInfo?.ntUserName,
      deviceName: '',
      lineNumber: '',
      phoneNumber: userInfo?.phoneNumber,
    };
    const tos = {
      contactId: parseInt(contactData?.userId),
      designation: contactData?.userRole,
      email: contactData?.email,
      phoneNumber: contactData?.phone?.replace(/^\+\d+\s\(/, '('),
      name: `${contactData?.firstName} ${contactData?.lastName}`,
    };
    if (props.channelType === ChannelType.voice) {
      const VoiceData = {
        manualLog: false,
        channel: props.channelType,
        associatedRecords: associatedRecords,
        sender: sender,
        sendType: SendType.one_to_one,
        tos: [tos],
        useType: UseType.ClientContacts,
        userRole: Concatenate(userInfo?.roles || [], ','),
        formName: FormName[FormName.Contact],
        UseSubType: UseSubType.ClientContactGrid,
      };
      if (!notificationData?.voice?.open && notificationData?.voice?.minimized) {
        dispatch(
          notificationDataActions.setVoiceInteraction({
            open: true,
            minimized: false,
            showDisposition: true,
          }),
        );
        if (notificationData?.voice?.data?.tos.find(Boolean).contactId !== contactData?.contactId) {
          dispatch(notificationDataActions.setSnackBarData({ channel: 'voice', manual: false, changeWarning: true }));
        }
      } else {
        dispatch(
          notificationDataActions.setVoiceData({
            data: VoiceData,
            open: true,
            minimized: false,
          }),
        );
      }
    } else {
      const notificationUser = searchNotificationData({ userInfo });
      const body = `<p><br><br><br>{{signature}}</p>`;
      const substitutions: Array<IToken> = [
        {
          name: 'Signature',
          input: '{{signature}}',
        },
      ];
      const emailData = {
        substitutions: substitutions,
        useType: UseType.ClientContacts,
        emailType: SendType.one_to_one,
        body: body,
        associatedRecords: associatedRecords,
        subject: '',
        tos: [tos],
        sender: sender,
        userRole: Concatenate(userInfo?.roles || [], ','),
        useSubType: UseSubType.ClientContactGrid,
        sendType: SendType.one_to_one,
        disableGroupEmailCheckbox: true,
      };
      if (!notificationData?.email?.open && notificationData?.email?.minimized) {
        dispatch(
          notificationDataActions.setEmailInteraction({
            open: true,
            minimized: false,
          }),
        );
        if (!_.isEqual(notificationData?.email?.data?.tos, [])) {
          dispatch(notificationDataActions.setSnackBarData({ channel: 'email', manual: false, changeWarning: true }));
        }
      } else {
        dispatch(
          notificationDataActions.setEmailData({
            data: {
              ...EmailDefaultValues(),
              ...notificationUser,
              ...emailData,
            },
            open: true,
            minimized: false,
          }),
        );
      }
    }
  };

  return (
    <div className={`${classes.profileTab} ${classes.profileTabMaster}  ${classes.fullWidth}`}>
      <Paper elevation={0} className={`paper ${classes.tabContainer} `}>
        <Tabs
          value={tabSelectedValue}
          classes={{ indicator: 'tab-indicator profileTab' }}
          onChange={handleProfileDrawerTabChange}
          aria-label="full width tabs"
        >
          <Tab
            className={
              tabSelectedValue === IFacilitySidePanelTabs.KEY_CLIENT_CONTACTS
                ? 'active-tab-profile'
                : 'default-tab profileTab'
            }
            label={'Key Client Contacts'}
            classes={{ root: classes.tabRoot }}
          />
          {showEditInternalContacts?.enabled && (
            <Tab
              className={
                tabSelectedValue === IFacilitySidePanelTabs.INTERNAL_CONTACTS
                  ? 'active-tab-profile'
                  : 'default-tab profileTab'
              }
              label={t('facility.internalContacts.title')}
              classes={{ root: classes.tabRoot }}
            />
          )}
        </Tabs>
      </Paper>
      <TabPanel componet={'div'} value={tabSelectedValue} index={0} classes={classes}>
        <List className={`${classes.root} ${classes.customProfileList} customProfileListKeyClient`}>
          {keyClientContacts?.map((contact, index) =>
            contact?.firstName && contact?.lastName ? (
              <>
                <ListItem alignItems="flex-start" disableGutters>
                  <ListItemText
                    primary={`${contact.firstName} ${contact.lastName}`}
                    classes={{
                      primary: classes.customProfileUserName,
                      secondary: classes.secondaryTextWithOutAvatar,
                    }}
                    secondary={
                      <React.Fragment>
                        <Typography variant="body2" classes={{ root: classes.designation }} data-testid="designation">
                          {contact.userRole}
                        </Typography>
                        <Typography classes={{ root: classes.description }} data-testid="description">
                          {contact.description}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                  <ListItemSecondaryAction classes={{ root: classes.actionContainer }}>
                    {enableEmailCommunication && !!contact?.email && contact?.email !== '' ? (
                      <Tooltip
                        title={
                          <span className={classes.tooltip}>
                            <span>{contact.email}</span>{' '}
                          </span>
                        }
                        disableHoverListener={!contact.email}
                        arrow
                        placement="bottom"
                        classes={{ tooltip: classes.tooltipBackground, arrow: classes.tooltipArrow }}
                      >
                        <img
                          src={MailBoxIcon}
                          alt="MailBox"
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            handleCommunication({ channelType: ChannelType.email, contactData: { ...contact } })
                          }
                        />
                      </Tooltip>
                    ) : (
                      <img src={EmailDisabled} alt="MailBox"/>
                    )}
                    {enableVoiceCommunication &&
                    contact?.phone &&
                    contact?.phone?.length > 0 &&
                    contact?.doNotCall === 0 ? (
                      <Tooltip
                        title={
                          <span className={classes.tooltip}>
                            <span>{contact?.phone}</span>{' '}
                          </span>
                        }
                        disableHoverListener={false}
                        arrow
                        placement="bottom"
                        classes={{ tooltip: classes.tooltipBackground, arrow: classes.tooltipArrow }}
                      >
                        <img
                          src={PhoneIcon}
                          alt="Phone"
                          style={{ marginLeft: 6 }}
                          onClick={() =>
                            handleCommunication({ channelType: ChannelType.voice, contactData: { ...contact } })
                          }
                        />
                      </Tooltip>
                    ) : (
                      <img src={PhoneDisabled} style={{ marginLeft: 6 }} />
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
                {index !== keyClientContacts.length - 1 && <Divider variant="fullWidth" component="li" />}
              </>
            ) : (
              <></>
            ),
          )}
        </List>
      </TabPanel>
      {showEditInternalContacts?.enabled && (
        <TabPanel componet={'div'} value={tabSelectedValue} index={1} classes={classes}>
          <InternalContacts />
        </TabPanel>
      )}
    </div>
  );
};
