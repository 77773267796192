/* eslint-disable i18next/no-literal-string */
import { Button, Grid, LinearProgress, Skeleton, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { Expandable } from 'app/components/Common/Expandable/Expandable';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconTitle } from './Titles/IconTitle';
import SyncIcon from '@mui/icons-material/Sync';
import { getRequirementCheck } from './helper';
import styledComponent from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { selectPlacementPeopleSoft } from '../../../../../../store/redux-store/placement-summary/selectors';
import { placementSummaryActions } from '../../../../../../store/redux-store/placement-summary/slice';
import { useParams } from 'react-router';
import { PromiseTrackerKeys } from 'app/constants/PromiseTrackerKeys';
import { usePromiseTracker } from 'react-promise-tracker';
import RedWarning from 'app/assets/images/IconImages/RedWarningIcon.svg';
import GreenCheck from 'app/assets/images/IconImages/GreenCheckCircleIcon.svg';
import { useDecision } from '@optimizely/react-sdk';
import { ff_placement_ui_peoplesoft_sync } from 'app/constants/FeatureFlags/Placement/Keys';

const useStyles: any = makeStyles()({
  button: {
    display: 'flex',
    justifyItems: 'flex-end',
  },
  rightGrid: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  skeleton: {
    width: '100%',
  },
});

interface PeopleSoftProps {}

export const PeopleSoft = ({ ...props }: PeopleSoftProps) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const params = useParams<{ placementId: string }>();
  const placementId = parseInt(params.placementId);
  const [enablePeoplesoft] = useDecision(ff_placement_ui_peoplesoft_sync);

  const { lastSync, syncStatus, syncStatusLabel, description, awaitingResultsCount } = useSelector(
    selectPlacementPeopleSoft,
  );
  const { promiseInProgress: loading } = usePromiseTracker({
    area: PromiseTrackerKeys.placements.peopleSoftGetDetails,
    delay: 0,
  });
  const { promiseInProgress: syncing } = usePromiseTracker({
    area: PromiseTrackerKeys.placements.peopleSoftSync,
    delay: 0,
  });
  const { promiseInProgress: awaitingResults } = usePromiseTracker({
    area: PromiseTrackerKeys.placements.peopleSoftAwaitResults,
    delay: 0,
  });

  const onSync = () => {
    if (enablePeoplesoft?.enabled) {
      dispatch(placementSummaryActions.syncWithPeopleSoftAction({ placementId }));
    }
  };

  const Title = () => {
    return (
      <IconTitle
        title="PeopleSoft"
        titleSupportComponent={
          !loading &&
          !syncing &&
          !awaitingResults &&
          (syncStatus === 1 ? (
            <img src={GreenCheck} alt="Pass" style={{ width: '16px', height: '16px' }} />
          ) : syncStatus === 2 ? (
            <img src={RedWarning} alt="Fail" style={{ width: '16px', height: '16px' }} />
          ) : undefined)
        }
        icon={<SyncIcon style={{ marginRight: '-5px' }} />}
      />
    );
  };

  return (
    <Expandable titleComponent={<Title />}>
      <Grid container spacing={2}>
        {awaitingResults && (
          <Grid item xs={12}>
            <LinearProgress variant="determinate" value={awaitingResultsCount} />
          </Grid>
        )}
        <Grid item xs={5}>
          <Label>{t('Last synced')}</Label>
        </Grid>
        <Grid item xs={7} className={classes.rightGrid}>
          {loading || syncing || awaitingResults ? (
            <Skeleton className={classes.skeleton} />
          ) : (
            <Typography>{lastSync}</Typography>
          )}
        </Grid>
        <Grid item xs={6}>
          <Label>{t('Sync Status')}</Label>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={2} justifyContent="flex-end" alignItems="center" className={classes.rightGrid}>
            {!loading && !syncing && !awaitingResults && (
              <Grid item>{(syncStatus === 1 || syncStatus === 2) && getRequirementCheck(syncStatus)}</Grid>
            )}
            <Grid item xs={loading || syncing || awaitingResults ? 12 : undefined}>
              {loading || syncing || awaitingResults ? (
                <Skeleton className={classes.skeleton} />
              ) : (
                <Typography>{syncStatusLabel}</Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <Label>{t('Description')}</Label>
            </Grid>
            <Grid item xs={12}>
              {loading || syncing || awaitingResults ? (
                <>
                  <Skeleton className={classes.skeleton} />
                </>
              ) : (
                <Typography>{description}</Typography>
              )}
            </Grid>
            <Grid item xs={12} justifyContent="flex-end" className={classes.button}>
              {(!loading || (loading && awaitingResults)) && (
                <Button
                  color="primary"
                  disabled={!enablePeoplesoft?.enabled || syncing || awaitingResults}
                  variant="contained"
                  size="small"
                  disableElevation
                  onClick={onSync}
                >
                  {t('Sync')}
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Expandable>
  );
};

const Label = styledComponent(Typography)`
  font-weight: 500;
`;
