import React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { Grid } from 'amn-ui-core';
import { SingleLineInput } from '@AMIEWEB/Unit/Common/SingleLineInput';
import { SimpleCheckbox } from '@AMIEWEB/Unit/Common/SimpleCheckbox';
import { getPositiveIntegerValue, modifyValues, validateInputValue } from '@AMIEWEB/NewOrder/CreateOrder/helper';
import { useClinicianToPatientStyles } from './Styles';

export interface IClinicianToPatientRatioProps {
  notifyCandidate: { name: string; value: boolean };
  patientsSeen: { name: string; value: string | null };
  numberOfVisits: { name: string; value: string | null };
  numberOfClinicians: { name: string; value: number | null };
  numberOfPatients: { name: string; value: string | null };
  productivityPercentage?: { name: string; value: number | null };
}

const ClinicianToPatientRatio: React.FunctionComponent<{
  nameValuePairs: IClinicianToPatientRatioProps | null;
}> = ({ nameValuePairs }) => {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext();
  const { classes } = useClinicianToPatientStyles();
  const CONSTANT_MINUES_KEY = '-';
  const CONSTANT_DECIMAL_KEY = '.';
  const handleNullValue = (value: string) => {
    return value === 'null' ? '' : value;
  };

  return (
    <Grid container columnSpacing={2} marginTop={nameValuePairs ? 0 : 1}>
      <Grid item xs={4}>
        <Controller
          name={nameValuePairs ? nameValuePairs.numberOfClinicians.name : 'nurse'}
          control={control}
          render={({ onChange, ...rest }) => (
            <SingleLineInput
              id={nameValuePairs ? nameValuePairs.numberOfClinicians.name : 'nurse'}
              inputDefaultValue={nameValuePairs ? nameValuePairs.numberOfClinicians.value : null}
              inputLabel={t('facility.units.clinicianToPatient.numberOfClinicians')}
              inputName={nameValuePairs ? nameValuePairs.numberOfClinicians.name : 'nurse'}
              inputValue={rest.value}
              handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                onChange(event.target.value === '' ? null : getPositiveIntegerValue(event.target.value));
              }}
              inputType={'number'}
              fieldStyle={classes.fieldStyle}
            />
          )}
        />
      </Grid>
      <Grid item xs={4}>
        <Controller
          name={nameValuePairs ? nameValuePairs.numberOfPatients.name : 'patient'}
          control={control}
          render={({ onChange, ...rest }) => (
            <SingleLineInput
              id={nameValuePairs ? nameValuePairs.numberOfPatients.name : 'patient'}
              inputDefaultValue={nameValuePairs ? nameValuePairs.numberOfPatients.value : ''}
              inputLabel={t('facility.units.clinicianToPatient.numberOfPatient')}
              inputName={nameValuePairs ? nameValuePairs.numberOfPatients.name : 'patient'}
              inputValue={handleNullValue(rest.value)}
              handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                onChange(validateInputValue(event.target.value));
              }}
              handleBlur={event => {
                setValue(
                  nameValuePairs ? nameValuePairs.numberOfPatients.name : 'patient',
                  modifyValues(event.target.value),
                );
              }}
              inputType={'string'}
              fieldStyle={classes.fieldStyle}
            />
          )}
        />
      </Grid>
      <Grid item xs={4}>
        <Grid item className={classes.checkbox} sx={nameValuePairs ? { paddingTop: '16px', paddingLeft: '12px' } : {}}>
          <Controller
            name={nameValuePairs ? nameValuePairs.notifyCandidate.name : 'notifyCandidate'}
            control={control}
            render={({ onChange, ...rest }) => (
              <SimpleCheckbox
                defaultValue={nameValuePairs ? nameValuePairs.notifyCandidate.value : false}
                isChecked={rest.value || false}
                handleChecked={event => {
                  onChange(event.target.checked);
                }}
                checkboxLabel={t('facility.units.clinicianToPatient.notifyCanditate')}
                checkboxName={nameValuePairs ? nameValuePairs.notifyCandidate.name : 'notifyCandidate'}
                id={nameValuePairs ? nameValuePairs.notifyCandidate.name : 'notifyCandidate'}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid item xs={4} mt={2}>
        <Controller
          name={nameValuePairs ? nameValuePairs.patientsSeen.name : 'patientsSeen'}
          control={control}
          render={({ onChange, ...rest }) => (
            <SingleLineInput
              id={nameValuePairs ? nameValuePairs.patientsSeen.name : 'patientsSeen'}
              inputDefaultValue={nameValuePairs ? nameValuePairs.patientsSeen.value : null}
              inputLabel={t('facility.units.clinicianToPatient.patientsSeen')}
              inputName={nameValuePairs ? nameValuePairs.patientsSeen.name : 'patientsSeen'}
              inputValue={rest.value}
              handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                onChange(validateInputValue(event.target.value));
              }}
              handleBlur={event =>
                setValue(
                  nameValuePairs ? nameValuePairs.patientsSeen.name : 'patientsSeen',
                  modifyValues(event.target.value),
                )
              }
              inputType={'string'}
              fieldStyle={classes.fieldStyle}
            />
          )}
        />
      </Grid>
      <Grid item xs={4} mt={2}>
        <Controller
          name={nameValuePairs ? nameValuePairs.numberOfVisits.name : 'numberOfVisits'}
          control={control}
          render={({ onChange, ...rest }) => (
            <SingleLineInput
              id={nameValuePairs ? nameValuePairs.numberOfVisits.name : 'numberOfVisits'}
              inputDefaultValue={nameValuePairs ? nameValuePairs.numberOfVisits.value : null}
              inputLabel={t('facility.units.clinicianToPatient.numberOfVisits')}
              inputName={nameValuePairs ? nameValuePairs.numberOfVisits.name : 'numberOfVisits'}
              inputValue={rest.value}
              handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                onChange(validateInputValue(event.target.value));
              }}
              handleBlur={event =>
                setValue(
                  nameValuePairs ? nameValuePairs.numberOfVisits.name : 'numberOfVisits',
                  modifyValues(event.target.value),
                )
              }
              inputType={'string'}
              fieldStyle={classes.fieldStyle}
            />
          )}
        />
      </Grid>
      <Grid item xs={4} mt={2}>
        <Controller
          name={nameValuePairs ? nameValuePairs.productivityPercentage.name : 'productivityPercentage'}
          control={control}
          render={({ onChange, ...rest }) => (
            <SingleLineInput
              id={nameValuePairs ? nameValuePairs.productivityPercentage.name : 'productivityPercentage'}
              inputDefaultValue={nameValuePairs ? nameValuePairs.productivityPercentage.value : null}
              inputLabel={t('facility.units.clinicianToPatient.productivityPercentage')}
              inputName={nameValuePairs ? nameValuePairs.productivityPercentage.name : 'productivityPercentage'}
              inputValue={rest.value}
              handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                onChange(event.target.value === '' ? null : getPositiveIntegerValue(event.target.value));
              }}
              showEndAdornment={rest.value !== null}
              endAdornmentValue={'%'}
              inputType={'number'}
              fieldStyle={classes.fieldStyle}
              onKeyPress={e => {
                if (e.key === CONSTANT_MINUES_KEY || e.key === CONSTANT_DECIMAL_KEY) {
                  e.preventDefault();
                }
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default ClinicianToPatientRatio;
