import React from 'react';
import { Grid, ThemeProvider, StyledEngineProvider,useTheme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { CustomMultiLineTextField } from 'app/components/Order/OrderCreationForm/Common/CustomMultiLineTextField';
import { useTranslation } from 'react-i18next';
import { UnitCardsTitle, UnitDetailsBody } from '../UnitDesign';
import { CustomTooltip } from 'app/components/Common/Tooltips';
import { Controller, useFormContext } from 'react-hook-form';

interface Props {
  onUnitPropChange: (property: string, value: any) => void;
  disableEdit?: boolean;
}
const useStyles = makeStyles()((theme) => ({
  body: {
    paddingBottom: 12,
  },
  tooltipPlacementBottom: {
    margin: 0,
    marginTop: 22,
  },
}));
export const NewClassicRequired = (props: Props) => {
  const { disableEdit } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const theme= useTheme();

  const { control } = useFormContext();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //onUnitPropChange(event.target.name, event.target.value);
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <UnitDetailsBody variant="outlined">
          <Grid container direction="row" spacing={1} className={classes.body}>
            <Grid item>
              <UnitCardsTitle>{t('order.createUnit.classicRequirement.title')}</UnitCardsTitle>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  {!disableEdit ? (
                    <Controller
                      control={control}
                      name={'requiredQualifications'}
                      render={({ ref, onChange, ...rest }) => (
                        <CustomMultiLineTextField
                          id={'createUnitclassicRequirementrequiredLableTxt'}
                          value={rest.value}
                          isdisabled={disableEdit}
                          name="requiredQualifications"
                          label={t('order.createUnit.classicRequirement.requiredLable')}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            onChange(event.target.value);
                          }}
                          placeholder={t('order.createUnit.externalJobInternalNotes.placeHolder')}
                        />
                      )}
                    />
                  ) : (
                    <CustomTooltip
                      tooltipContent={t('order.createUnit.classicRequirement.noValueText')}
                      tooltipPlacementBottom={classes.tooltipPlacementBottom}
                    >
                      <Controller
                      control={control}
                      name={'requiredQualifications'}
                      render={({ ref, onChange, ...rest }) => (
                          <CustomMultiLineTextField
                            id={'createUnitclassicRequirementrequiredLableTxt'}
                            value={rest.value}
                            isdisabled={disableEdit}
                            name="requiredQualifications"
                            label={t('order.createUnit.classicRequirement.requiredLable')}
                            placeholder={t('order.createUnit.externalJobInternalNotes.placeHolder')}
                          />
                        )}
                      />
                    </CustomTooltip>
                  )}
                </Grid>
                <Grid item xs={6}>
                  {!disableEdit ? (
                    <Controller
                      control={control}
                      name={'preferredQualifications'}
                      render={({ ref, onChange, ...rest }) => (
                        <CustomMultiLineTextField
                          id={'createUnitclassicRequirementprefLableTxt'}
                          value={rest.value}
                          isdisabled={disableEdit}
                          name="preferredQualifications"
                          label={t('order.createUnit.classicRequirement.PreferedLable')}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            onChange(event.target.value);
                          }}
                          placeholder={t('order.createUnit.externalJobInternalNotes.placeHolder')}
                        />
                      )}
                    />
                  ) : (
                    <CustomTooltip
                      tooltipContent={t('order.createUnit.classicRequirement.noValueText')}
                      tooltipPlacementBottom={classes.tooltipPlacementBottom}
                    >
                      <Controller
                      control={control}
                      name={'preferredQualifications'}
                      render={({ ref, onChange, ...rest }) => (
                        <CustomMultiLineTextField
                            id={'createUnitclassicRequirementprefLableTxt'}
                            value={rest.value}
                            isdisabled={disableEdit}
                            name="preferredQualifications"
                            label={t('order.createUnit.classicRequirement.PreferedLable')}
                            onChange={handleChange}
                            placeholder={t('order.createUnit.externalJobInternalNotes.placeHolder')}
                          />
                        )}
                      />
                    </CustomTooltip>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </UnitDetailsBody>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
