import React from 'react';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import { useTranslation } from 'react-i18next';
import { Chip } from 'amn-ui-core/dist/components/Chip/Chip';
export const TimeOffView = ({ selectedWorkExperience, timeOffStartDate, timeOffEndDate, classes }) => {
  const { t } = useTranslation();
  return (
    <LayoutGrid container spacing={2} style={{ minWidth: 1224 }}>
      <LayoutGridItem xs={4}>
        <div className="column">
          <Chip label={t('candidate.workExperienceView.personalTimeOff')} variant="outlined" />
        </div>
      </LayoutGridItem>

      <LayoutGridItem xs={4}>
        <div className="column">
          <div className={`row ${classes.columnRow}`}>
            {'Date:  '}
            <strong>
              {timeOffStartDate} - {timeOffEndDate}
            </strong>
          </div>
        </div>
      </LayoutGridItem>

      <LayoutGridItem xs={4}>
        <div className="column">
          <div className={`row ${classes.columnRow}`}>
            {'Ongoing:  '}
            {selectedWorkExperience?.currentlyEmployed ? (
              <strong>Yes</strong>
            ) : selectedWorkExperience?.currentlyEmployed === false ? (
              <strong>No</strong>
            ) : (
              ''
            )}
          </div>
        </div>
      </LayoutGridItem>
    </LayoutGrid>
  );
};
