import { convertToPST } from 'app/helpers/dateHelper';
import { ISmsPayload } from 'app/models/Notification/Notification';
import { ISmsResult } from 'app/models/Sms/ISmsResult';
import _ from 'lodash';
import moment from 'moment';

export const groupMessages = (logs: ISmsResult[]) => {
  logs = logs && logs?.length > 0
    ? _.cloneDeep(logs)?.sort(function (firstDate, secondDate) {
      return (new Date(firstDate.publishedOn!) as any) - (new Date(secondDate.publishedOn!) as any);
    })
    : [];

  const groupedData = _.chain(logs)
    .groupBy(datum => determineDateRange(moment(convertToPST(datum.publishedOn!)).startOf('day')))
    .value();
  const keys = Object.keys(groupedData);
  const values = Object.values(groupedData);
  const timeStampedMessages = keys.map((period, index) => ({ period: period, chats: values[index] }));
  return timeStampedMessages;
};

export const getElapsedTime = value => {
  return duration(value, new Date());
};

export function duration(newDate, currentDate) {
  const years = moment(currentDate).diff(newDate, 'years');
  const months = moment(currentDate).diff(newDate, 'months');
  const weeks = moment(currentDate).diff(newDate, 'weeks');
  const days = moment(currentDate).diff(newDate, 'days');
  const hours = moment(currentDate).diff(newDate, 'hours');
  const minutes = moment(currentDate).diff(newDate, 'minutes');
  const seconds = moment(currentDate).diff(newDate, 'seconds');

  if (years > 0) {
    return years + 'y';
  }
  if (months > 0) {
    return months + 'mo';
  }
  if (weeks > 0) {
    return weeks + 'w';
  }
  if (days > 0) {
    return days + 'd';
  }
  if (hours > 0) {
    return hours + 'h';
  }
  if (minutes > 0) {
    return minutes + 'm';
  }
  if (seconds > 0) {
    return seconds + 's';
  }
  return '0s';
}

const determineDateRange = value => {
  const now = moment(convertToPST(new Date())).startOf('day');

  if (value.isSame(now, 'day')) {
    return 'Today';
  }
  if (value.isSame(now.clone().subtract(1, 'days').startOf('day'))) {
    return 'Yesterday';
  }
  return value.format('MM/DD/YYYY');
};

export function smsPayloadToFormData(publishSmsPayload: ISmsPayload, attachments: File): FormData {
  const messageForm = new FormData();
  messageForm.append('publishSms', JSON.stringify(publishSmsPayload));
  messageForm.append('attachments', (attachments as Blob) ?? '');
  return messageForm;
};