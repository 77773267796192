import { PayloadAction } from '@reduxjs/toolkit';
import { takeLatest } from 'redux-saga/effects';
import { workExperienceActions } from './slice';
import {
  onInit,
  deleteWorkExperienceBatch,
  postReferenceFromWorkExpModal,
  getReferenceListByWorkExperience,
  postReferenceRequestFromWorkExp,
} from './business.saga';
import { updateCandidateWorkExperience, updateCandidateWorkExperienceBulk } from './update-saga';

export function* workExperienceSaga(action: PayloadAction) {
  yield takeLatest(workExperienceActions.onInitAction.type, onInit);
  yield takeLatest(workExperienceActions.deleteWorkExperienceBatchAction.type, deleteWorkExperienceBatch);
  yield takeLatest(workExperienceActions.updateWorkExperience.type, updateCandidateWorkExperience);
  yield takeLatest(workExperienceActions.updateWorkExperienceBulk.type, updateCandidateWorkExperienceBulk);
  yield takeLatest(workExperienceActions.getReferenceByWorkExperienceAction.type, getReferenceListByWorkExperience);
  yield takeLatest(workExperienceActions.postReferenceFromWorkExpModalAction.type, postReferenceFromWorkExpModal);
  yield takeLatest(workExperienceActions.postReferenceRequestFromWorkExpAction.type, postReferenceRequestFromWorkExp);
}
