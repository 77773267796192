import React, { ReactNode, useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import { Accordion, AccordionDetails, AccordionSummary, Grid, IconButton, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { CustomTooltip } from '@AMIEWEB/Common';

const useStyles = makeStyles<{ isDisabled: boolean }>()((theme, { isDisabled }) => ({
  summary: {
    flexDirection: 'row-reverse',
    backgroundColor: isDisabled
      ? theme.palette.components.accordion.secondary.backgroundColor
      : theme.palette.system.skyBlue,
    cursor: 'default !important',
    minHeight: '48px !important',
    maxHeight: '48px !important',
    borderBottom: `1px solid ${theme.palette.system.lightGrey}`,
    borderTop: `1px solid ${theme.palette.system.lightGrey}`,
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  addButton: {
    color: `${theme.palette.system.neutralGray}`,
    '&:hover': {
      color: `${theme.palette.system.main}`,
    },
  },
  disableButton: {
    color: `${theme.palette.grey[400]}`
  }
}));

const UnitCollapsible = ({
  title,
  isDisabled = false,
  children,
  showIcon = true,
  onClickHandler,
  iconToolTipContent,
  fieldCount = null,
  isCollapsible = true,
  updateIsExpanded
}: {
  title: string;
  isDisabled?: boolean;
  children: ReactNode;
  showIcon?: boolean;
  fieldCount?: number | null;
  onClickHandler?: () => void;
  iconToolTipContent?: string;
  isCollapsible?: boolean;
  updateIsExpanded?: ((isExpanded: boolean) => void) | undefined;
}) => {
  const { classes } = useStyles({ isDisabled: isDisabled });
  const [isExpanded, setIsExpanded] = useState<boolean>(isCollapsible);

  const handleOnIconClick = event => {
    event.stopPropagation();
    if (!isExpanded) {
      setIsExpanded(true);
    }
    onClickHandler();
  };
  const onExpandClick = () => {
    setIsExpanded(prev => !prev);
    if (updateIsExpanded) {
      updateIsExpanded(!isExpanded);
    };
    if (fieldCount === 0) {
      onClickHandler();
    }
  };
  useEffect(() => {
    if (fieldCount === 0) {
      setIsExpanded(false);
    } else {
      setIsExpanded(true);
    }
  }, [fieldCount]);

  return (
    <Accordion expanded={isExpanded}>
      <AccordionSummary
        expandIcon={
          <IconButton color="primary" size="small" onClick={onExpandClick}>
            <ExpandMoreIcon />
          </IconButton>
        }
        aria-controls="panel1a-content"
        id="unit-collapsible"
        className={classes.summary}
      >
        <Grid container item className={classes.headerContainer}>
          <Grid item pl={'12px'}>
            <Typography
              component="span"
              sx={{ fontSize: '16px !important', color: theme => theme.palette.system.black, fontWeight: '500' }}
            >
              {title}
            </Typography>
          </Grid>
          <Grid item sx={{ position: 'absolute', right: '16px' }}>
            {showIcon && (
              <CustomTooltip standardMargin tooltipContent={iconToolTipContent}>
                <IconButton color="secondary" size="medium" disabled={isDisabled} onClick={handleOnIconClick}>
                  <AddIcon id="add-icon-tags" className={!isDisabled ? classes.addButton : classes.disableButton} />
                </IconButton>
              </CustomTooltip>
            )}
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default UnitCollapsible;
