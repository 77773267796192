import { XGrid } from '@AMIEWEB/Common/XGrid/XGrid';
import { Grid } from 'amn-ui-core';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { GridApi, GridPinnedRowsProp, GridSelectionModel } from '@mui/x-data-grid-pro';
import { CompactStatus, IOrderLicense } from '@AMIEWEB/Order/Store/Order.redux';
import { Controller, useFormContext } from 'react-hook-form';
import { useDecision } from '@optimizely/react-sdk';
import { ff_facility_ui_view_new_unit_pages } from 'app/constants/FeatureFlags/Facility/Keys';
import { formatOrderLicenseList } from '@AMIEWEB/Order/OrderCreationForm/helper';
import { unitLicenseGridColumnsAndOr } from './UnitGridLicenseHelper';
import { CollectionType } from 'app/enums/Common';
import { useSelector } from 'react-redux';
import { selectOrderRequirementTypes } from '@AMIEWEB/Order/Store/OrderCreation.selectors';
import { selectFacility } from '@AMIEWEB/Facility/FacilityStore/Facility.selector';
import { useUnitLicenseGridStyles } from './Experience/Experience.styles';

export const UnitLicensesGrid = ({
  filteredLicenses,
  skillSetIndex,
  groupIndex,
}: {
  filteredLicenses: any[];
  skillSetIndex: number;
  groupIndex: number;
}) => {
  const { classes } = useUnitLicenseGridStyles();
  const { t } = useTranslation();
  const { control, setValue, getValues } = useFormContext();
  const gridApi = React.useRef<GridApi>();
  const [licenseColumns, setLicenseColumns] = useState([]);
  const [dataList, setDataList] = useState<any[]>([]);
  const [orderSelectionModel, setOrderSelectionModel] = useState<any[]>([]);
  const [isNewEditUnitDetails] = useDecision(ff_facility_ui_view_new_unit_pages);
  const facilityData = useSelector(selectFacility);
  const { states } = useSelector(selectOrderRequirementTypes);

  const skillsetLicenseData = getValues(
    `skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].collections[${CollectionType.LicensesIndex}].requirements`,
  );

  const selectedRowDetails = useRef<{ [id: string]: boolean }>();
  const setSelectedRowDetails = data => {
    selectedRowDetails.current = data;
  };

  const getPinnedRows = () => {
    const pinnedRowIds = selectedRowDetails.current
      ? Object.keys(selectedRowDetails.current)
          .filter(key => selectedRowDetails.current[key] === true)
          .map(Number)
      : [];

    const pinnedRows: GridPinnedRowsProp = {
      top: dataList.filter(obj => pinnedRowIds.includes(obj.id)),
    };
    return pinnedRows;
  };

  useEffect(() => {
    setLicenseColumns([
      ...unitLicenseGridColumnsAndOr(
        groupIndex,
        skillSetIndex,
        t,
        selectedRowDetails.current,
        setSelectedRowDetails,
        true,
      ),
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skillSetIndex, selectedRowDetails.current]);

  useEffect(() => {
    if (filteredLicenses?.length && skillsetLicenseData) {
      const selectedLicenseIds = skillsetLicenseData?.map(x => x.licenseId);
      setOrderSelectionModel(selectedLicenseIds);

      const formattedGridData = formatOrderLicenseList(skillsetLicenseData, filteredLicenses);
      setDataList(formattedGridData);
      const prepinnedRows = skillsetLicenseData.reduce((acc, curr) => {
        if (curr.licenseId) {
          acc[curr.licenseId] = true;
        }
        return acc;
      }, {});
      setSelectedRowDetails(prepinnedRows);
    }
  }, [filteredLicenses, skillsetLicenseData]);

  const onSelectionModelChange = useCallback(
    (selectionModel: GridSelectionModel) => {
      setOrderSelectionModel([...selectionModel]);
      if (selectionModel && selectionModel?.length > 0 && filteredLicenses?.length > 0) {
        const orderLicensesDetails: IOrderLicense[] = [...skillsetLicenseData];
        const facilityState = states?.find(
          (state: any) =>
            state?.value === facilityData?.facilityResponse?.location?.state ||
            state?.value === facilityData?.response?.location?.state,
        );
        selectionModel.forEach(selectedOption => {
          const licenseDetailsIndex = orderLicensesDetails?.findIndex(data => data.licenseId === selectedOption);

          if (licenseDetailsIndex === -1) {
            const selectedLicenseOptionDetails = filteredLicenses?.find(
              data => data?.requirementTypeId === selectedOption || data?.requirementTypeID === selectedOption,
            );
            if (selectedLicenseOptionDetails) {
              const licenseStateData = [
                {
                  state: facilityState,
                  isRequired: true, // "isRequired" always true
                  documentAttachmentRequired: false,
                },
              ];
              const selectedLicenseDetail: IOrderLicense = {
                licenseDescription: selectedLicenseOptionDetails.credentialName,
                licenseAbbreviation: selectedLicenseOptionDetails.abbreviation,
                licenseId:
                  selectedLicenseOptionDetails.requirementTypeId || selectedLicenseOptionDetails.requirementTypeID,
                isError: { compactStatus: false, state: false },
                isSelected: true,
                licenseStateDetails: selectedLicenseOptionDetails.compact
                  ? [{ isRequired: true, documentAttachmentRequired: false }] // "isRequired" always true
                  : licenseStateData,
                compactStatus: selectedLicenseOptionDetails.compact ? CompactStatus.compact : CompactStatus.single,
              };
              orderLicensesDetails.push(selectedLicenseDetail);
            }
          }
        });

        const filteredData = orderLicensesDetails?.filter(licenseDetails =>
          selectionModel.includes(licenseDetails.licenseId),
        );

        setValue(
          `skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].collections[${CollectionType.LicensesIndex}].requirements`,
          filteredData,
        );
      } else {
        setValue(
          `skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].collections[${CollectionType.LicensesIndex}].requirements`,
          [],
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filteredLicenses, skillsetLicenseData],
  );

  return (
    <div data-testid="license-dropdown" className={classes.licenseGrid}>
      <Controller
        control={control}
        name={`skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].collections[${CollectionType.LicensesIndex}].requirements`}
        render={({ ref, onChange, ...rest }) => (
          <Grid className={classes.root}>
            <XGrid
              apiRef={gridApi}
              hideFooterSelectedRowCount
              checkboxSelection
              disableSelectionOnClick
              gridTag={'Licenses'}
              height={500}
              rowHeight={58}
              columns={licenseColumns}
              onSelectionModelChange={onSelectionModelChange}
              selectionModel={orderSelectionModel}
              keepNonExistentRowsSelected={true}
              rows={dataList}
              toolbar={isNewEditUnitDetails?.enabled}
              title={'License'}
              autoHeight={false}
              pagination={false}
              hideFooterPagination
              hideFooter={true}
              disablePaggination
              displayCustomCount={orderSelectionModel?.length}
              paginationProps={{
                hideResultsPerPageSelection: true,
                hidePageCount: true,
              }}
              disableVirtualization={true}
              components={{
                NoRowsOverlay: () => <div className={classes.overlay}>{t('order.license.noRecordsFound')}</div>,
              }}
              toolbarProps={{
                refreshButton: true,
                resetButton: true,
                refreshGrid: () => {},
                resetColumns: () =>
                  setLicenseColumns([
                    ...unitLicenseGridColumnsAndOr(
                      groupIndex,
                      skillSetIndex,
                      t,
                      selectedRowDetails?.current,
                      setSelectedRowDetails,
                      true,
                    ),
                  ]),
              }}
              pinnedRows={getPinnedRows()}
              experimentalFeatures={{ rowPinning: true }}
            />
          </Grid>
        )}
      />
    </div>
  );
};
