import React from 'react';
import { Chip, Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { UnitCardsTitle, UnitDetailsBody } from '../UnitDesign';
import { useTranslation } from 'react-i18next';
import { formatChipLabel } from '../helper';
import { ControlledTypeAheadWithCheckBox } from 'app/components/Order/OrderCreationForm/OrderDetails/ControlledTypeAheadWithCheckBox';
import { useFormContext } from 'react-hook-form';
import { GetVirtualRating } from 'app/components/Order/OrderCreationForm/OrderDetails/FormatData';
import { formatTypeAheadVirtualRatingOptions } from 'app/helpers/typeAheadHelper';

interface Props {
  disableEdit?: boolean;
}

const useStyles = makeStyles()(() => ({
  chip: {
    maxWidth: '50ch',
    margin: '0 5px 10px 0',
  }
}));

export const NewVirtualRating = (props: Props) => {
  const { disableEdit } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();
  const virtualRatingOptions = GetVirtualRating();
  const { control, register,  watch, setValue } = useFormContext();
  const watchValue = watch('virtualRating');

  const handleDelete = vr => {
    //const existingValues = getValues();
    const selectedValues = watchValue.filter(virtualRatingChip => virtualRatingChip != vr);
    /*reset({
      ...existingValues,
      virtualRating: selectedValues,
    });*/

    setValue('virtualRating', selectedValues);
  };

  return (
    <div>
      <UnitDetailsBody variant="outlined">
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <UnitCardsTitle>{t('order.createUnit.virtualInterviewRating.title')}</UnitCardsTitle>
          </Grid>
          <Grid item>
                <ControlledTypeAheadWithCheckBox
                        id={'virtualRating'}
                        register={register}
                        name="virtualRating"
                        label={t('order.createUnit.virtualInterviewRating.rating')}
                        control={control}
                        isdisabled={disableEdit}
                        options={virtualRatingOptions ? virtualRatingOptions : []}
                        onChange={values => {
                          //const existingValues = getValues();
                          setValue('virtualRating', values);
                          /*reset({
                            ...existingValues,
                            virtualRating: values,
                          });*/
                        }}
                        defaultValue={watchValue && 
                            Array.isArray(watchValue) ? formatTypeAheadVirtualRatingOptions(watchValue) : []}
                        selectedvalue={watchValue && 
                            Array.isArray(watchValue) ? formatTypeAheadVirtualRatingOptions(watchValue) : []}
                      />
          </Grid>
          <Grid item>
            <div id={'createUnitVirtualInterviewRatingSelectedItemContainer'}>
              {watchValue &&
               Array.isArray(watchValue) &&
                watchValue.map(vrChip => {
                  return (
                    <Chip
                      disabled={disableEdit}
                      variant="outlined"
                      label={
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                          <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{formatChipLabel(vrChip)}</div>
                        </div>
                      }
                      className={classes.chip}
                      onDelete={() => handleDelete(vrChip)}
                    />
                  );
                })}
            </div>
          </Grid>
        </Grid>
      </UnitDetailsBody>
    </div>
  );
};
