import {
  candidateDetailActions,
  credentialsInitialState,
} from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.redux';
import {
  CredentialingLookups,
  CredentialsSelection,
  RequirementTypes,
  candidateDetailsSelection,
} from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import { calculateExperienceOverlap } from '@AMIEWEB/Candidate/CandidateProfile/Profile/helper';
import { PayloadAction } from '@reduxjs/toolkit';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import { CandidateService } from 'app/services/CandidateServices/candidate-service';
import { CredentialingService } from 'app/services/CredentialingServices/credentialing-service';
import { httpSuccess } from 'app/services/serviceHelpers';
import { trackPromise } from 'react-promise-tracker';
import { call, fork, getContext, put, select } from 'redux-saga/effects';
import { SessionKey, appendSessionValue } from 'utils/customHooks/sessionStorage/sessionHelpers';
import { Concatenate } from 'utils/string/string';
import { formatRequestedTimeOffList, transformEMRData } from './helper';

// #region Track Promises
const TrackGetCandidateDetails = (fn, ...args) => trackPromise(fn(...args), 'get-candidate-details');
const TrackGetCandidateSummaryTabDetails = (fn, ...args) =>
  trackPromise(fn(...args), 'get-candidate-summary-tab-details');
const TrackGetCandidateProfessionalProfileTabDetails = (fn, ...args) =>
  trackPromise(fn(...args), 'get-candidate-professional-profile-tab-details');
const TrackGetCandidateCredentialsTabDetails = (fn, ...args) =>
  trackPromise(fn(...args), 'get-candidate-credentials-tab-details');
const TrackGetCandidatePersonalInfoTabDetails = (fn, ...args) =>
  trackPromise(fn(...args), 'get-candidate-personal-info-tab-details');
const TrackGetCredentialLookUpDetails = (fn, ...args) =>
  trackPromise(fn(...args), 'get-candidate-credentials-lookup-details');
// #endregion

// #region Sub-Worker Functions
function* getCandidateByBrandId(candidateId: number, brandId: number) {
  try {
    const candidateService: CandidateService = yield getContext('candidateService');
    const response = yield call(TrackGetCandidateDetails, candidateService.getCandidateDetails, candidateId, brandId);
    const ssnDetails = yield call(getCandidateSSNDetails, candidateId);

    if (response && httpSuccess(response?.status)) {
      const candidateRes = response.data;
      appendSessionValue(
        SessionKey.recentCandidates,
        {
          candidateId: candidateRes.travelerId,
          name: Concatenate([candidateRes.name.first, candidateRes.name.middle, candidateRes.name.last], ' '),
          brandId: candidateRes.brandId,
          brand: candidateRes.brand,
          recruiter: Concatenate([candidateRes.recruiter.first, candidateRes.recruiter.last], ' '),
          recruiterId: candidateRes.recruiter.recruiterId,
          applicationDate: candidateRes.applicationDate,
          disciplines: candidateRes.groupedSkillsSets?.reduce((storage, item) => {
            if (item.disciplineAbbr !== null) storage.push(item.disciplineAbbr);
            return storage;
          }, []),
        },
        true,
        5,
      );

      if (ssnDetails) {
        candidateRes.socialSecurityNumber = ssnDetails?.ssn;
        candidateRes.ssnTimestamp = ssnDetails?.timestamp;
      }

      const res = calculateExperienceOverlap(candidateRes?.experience?.employment, candidateRes);
      const credData = yield select(CredentialsSelection);
      res.credentials = credData ?? credentialsInitialState;

      yield put(candidateDetailActions.setCandidateDetails(res));
      yield fork(getCredentialingLookups, TrackGetCredentialLookUpDetails);
      yield fork(getRequirementTypes, TrackGetCredentialLookUpDetails);
      yield fork(getCandidateSkillsetTree, candidateId);
      yield fork(getCandidateSkillsChecklist, candidateId);
      yield fork(getCandidateAuditList, candidateId);
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'getCandidateByBrandId',
        area: '/src/store/redux-store/candidate-details/business.saga.ts',
      },
    });
  }
}

function* getCandidateSkillsetTree(candidateId: number) {
  try {
    const candidateService: CandidateService = yield getContext('candidateService');
    const response = yield call(TrackGetCandidateDetails, candidateService.getCandidateSkillsetTree, candidateId);

    if (response && httpSuccess(response?.status)) {
      yield put(candidateDetailActions.setSkillSetTree(response.data));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'getCandidateSkillsetTree',
        area: '/src/store/redux-store/candidate-details/business.saga.ts',
      },
    });
  }
}

function* getCandidateSkillsChecklist(candidateId: number) {
  try {
    const candidateService: CandidateService = yield getContext('candidateService');
    const response = yield call(TrackGetCandidateDetails, candidateService.getCandidateSkillsChecklist, candidateId);

    if (response && httpSuccess(response?.status)) {
      yield put(candidateDetailActions.setSkillsChecklist(response.data));
    }
  } catch (error) {
    yield put(candidateDetailActions.setSkillsChecklist([]));
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'getCandidateSkillsetTree',
        area: '/src/store/redux-store/candidate-details/business.saga.ts',
      },
    });
  }
}

function* getCandidateAuditList(candidateId: number) {
  try {
    const candidateService: CandidateService = yield getContext('candidateService');
    const response = yield call(TrackGetCandidateDetails, candidateService.getCandidateAuditDetails, candidateId);

    if (response && httpSuccess(response?.status)) {
      yield put(candidateDetailActions.setAuditData(response.data));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'getCandidateAuditDetails',
        area: '/src/store/redux-store/candidate-details/business.saga.ts',
      },
    });
  }
}

function* getCandidateSSNDetails(candidateId: number) {
  try {
    const candidateService: CandidateService = yield getContext('candidateService');
    const response = yield call(TrackGetCandidateDetails, candidateService.getCandidateSsn, candidateId);

    if (response && httpSuccess(response?.status)) {
      return response.data;
    }
    return null;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'getCandidateSSNDetails',
        area: '/src/store/redux-store/candidate-details/business.saga.ts',
      },
    });
  }
}

function* getCandidateCredentials(candidateId: number, tracker: any) {
  try {
    const credentialingService: CredentialingService = yield getContext('credentialingService');
    const response = yield call(tracker, credentialingService.getCandidateCredentials, candidateId);
    if (response && httpSuccess(response?.status)) {
      const credentialData = response.data ?? credentialsInitialState;

      const lookupData = yield select(CredentialingLookups);
      const reqTypesData = yield select(RequirementTypes);

      const finalData = {
        ...credentialData,
        credentialingLookups: lookupData ?? [],
        requirementTypes: reqTypesData ?? [],
      };
      yield put(candidateDetailActions.setCredentials(finalData));
    }
  } catch (error) {
    yield put(candidateDetailActions.setCredentials(credentialsInitialState));
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'getCandidateCredentials',
        area: '/src/store/redux-store/candidate-details/business.saga.ts',
      },
    });
  }
}

function* getCandidateReferences(candidateId: number, filter: number) {
  try {
    const candidateService: CandidateService = yield getContext('candidateService');
    const response = yield call(
      TrackGetCandidateSummaryTabDetails,
      candidateService.getCandidateReferences,
      candidateId,
      filter,
    );

    if (response && httpSuccess(response?.status)) {
      if (filter === 0) {
        yield put(candidateDetailActions.setReferences(response.data));
      } else {
        yield put(candidateDetailActions.setReferenceData(response.data));
      }
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'getCandidateReferences',
        area: '/src/store/redux-store/candidate-details/business.saga.ts',
      },
    });
  }
}

function* getCandidateSupervisorData(candidateId: number, brandId: number) {
  try {
    const candidateService: CandidateService = yield getContext('candidateService');
    const response = yield call(
      TrackGetCandidateProfessionalProfileTabDetails,
      candidateService.getSupervisorDetails,
      candidateId,
    );

    if (response && httpSuccess(response?.status)) {
      yield put(
        candidateDetailActions.setSupervisor({
          ...response?.data,
          caseLoad: response?.data.maxNumber - response?.data.available,
        }),
      );
    } else {
      yield put(
        candidateDetailActions.setSupervisor({
          availabilityDate: '',
          interested: false,
          maxNumber: 0,
          available: 0,
          skillsets: [],
          caseLoad: 0,
        }),
      );
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'getCandidateSupervisorData',
        area: '/src/store/redux-store/candidate-details/business.saga.ts',
      },
    });
  }
}

function* getCandidateRequestTimeOff(candidateId: number, brandId: number) {
  try {
    const candidateService: CandidateService = yield getContext('candidateService');
    const response = yield call(
      TrackGetCandidateProfessionalProfileTabDetails,
      candidateService.getCandidateRequestTimeOff,
      candidateId,
      brandId,
    );

    if (response && httpSuccess(response?.status)) {
      const formattedRequestTimeOff = formatRequestedTimeOffList(response?.data.length ? response?.data : []);
      yield put(candidateDetailActions.setTimeOffRequest(formattedRequestTimeOff));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'getCandidateRequestTimeOff',
        area: '/src/store/redux-store/candidate-details/business.saga.ts',
      },
    });
  }
}

function* getCandidateEMRData(candidateId: number, brandId: number) {
  try {
    const candidateService: CandidateService = yield getContext('candidateService');
    const response = yield call(
      TrackGetCandidateProfessionalProfileTabDetails,
      candidateService.getCandidateEMRData,
      candidateId,
      brandId,
    );

    if (response && httpSuccess(response?.status)) {
      const transFormedEmrList = transformEMRData(response.data);
      const savedEmr = transFormedEmrList.filter(emrItem => emrItem.candidateId && emrItem.brandId);
      yield put(candidateDetailActions.savedEquipmentsProcedure(savedEmr?.length ? savedEmr : []));
      yield put(candidateDetailActions.setEMRList(transFormedEmrList));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'getCandidateEMRData',
        area: '/src/store/redux-store/candidate-details/business.saga.ts',
      },
    });
  }
}

function* getCandidatePreferencesData(candidateId: number) {
  try {
    const candidateService: CandidateService = yield getContext('candidateService');
    const response = yield call(
      TrackGetCandidateProfessionalProfileTabDetails,
      candidateService.getCandidatePreferences,
      candidateId,
    );

    if (response && httpSuccess(response?.status)) {
      yield put(candidateDetailActions.setPreferences(response.data));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'getCandidatePreferencesData',
        area: '/src/store/redux-store/candidate-details/business.saga.ts',
      },
    });
  }
}

function* getCredentialingLookups(tracker: any) {
  try {
    const credentialingService: CredentialingService = yield getContext('credentialingService');
    const response = yield call(tracker, credentialingService.getCredentialLookupTypes, 'all');

    const lookupData =
      response?.data && response?.data?.length > 0 ? response.data : response?.length > 0 ? response : [];
    yield put(candidateDetailActions.setCredentialingLookups(lookupData));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'getCredentialingLookups',
        area: '/src/store/redux-store/candidate-details/business.saga.ts',
      },
    });
  }
}

function* getRequirementTypes(tracker: any) {
  try {
    const credentialingService: CredentialingService = yield getContext('credentialingService');
    const response = yield call(tracker, credentialingService.getRequirementTypes);

    const reqTypesData =
      response?.data && response?.data?.length > 0 ? response.data : response?.length > 0 ? response : [];
    yield put(candidateDetailActions.setRequirementTypes(reqTypesData));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'getRequirementTypes',
        area: '/src/store/redux-store/candidate-details/business.saga.ts',
      },
    });
  }
}

function* getCandidateAddressData(candidateId: number, brandId: number) {
  try {
    const candidateService: CandidateService = yield getContext('candidateService');
    const response = yield call(
      TrackGetCandidatePersonalInfoTabDetails,
      candidateService.getCandidateAddress,
      candidateId,
      brandId,
    );

    if (response && httpSuccess(response?.status)) {
      yield put(candidateDetailActions.setAddressData(response.data));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'getCandidateAddressData',
        area: '/src/store/redux-store/candidate-details/business.saga.ts',
      },
    });
  }
}

function* getCandidateTaxData(candidateId: number) {
  try {
    const candidateService: CandidateService = yield getContext('candidateService');
    const response = yield call(
      TrackGetCandidatePersonalInfoTabDetails,
      candidateService.getCandidateTaxData,
      candidateId,
    );

    if (response && httpSuccess(response?.status)) {
      yield put(candidateDetailActions.setTaxData(response.data));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'getCandidateTaxData',
        area: '/src/store/redux-store/candidate-details/business.saga.ts',
      },
    });
  }
}

function* getPermanentTaxLetterData(candidateId: number) {
  try {
    const candidateService: CandidateService = yield getContext('candidateService');
    const response = yield call(
      TrackGetCandidatePersonalInfoTabDetails,
      candidateService.getCandidatePermanentTaxLetterData,
      candidateId,
    );

    if (response && httpSuccess(response?.status)) {
      yield put(candidateDetailActions.setTaxLetterInfo(response.data));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'getPermanentTaxLetterData',
        area: '/src/store/redux-store/candidate-details/business.saga.ts',
      },
    });
  }
}
// #endregion

// #region Worker Functions
export function* getCandidatePersonalInfoData(action: PayloadAction<{ candidateId: number; brandId: number }>) {
  yield fork(getCandidateTaxData, action.payload.candidateId);
  yield fork(getPermanentTaxLetterData, action.payload.candidateId);
  yield fork(getCandidateAddressData, action.payload.candidateId, action.payload.brandId);
}

export function* getCredentialsTabData(action: PayloadAction<{ candidateId: number; brandId: number }>) {
  yield fork(getCandidateCredentials, action.payload.candidateId, TrackGetCandidateCredentialsTabDetails);
}

export function* getProfessionalProfileTabData(action: PayloadAction<{ candidateId: number; brandId: number }>) {
  yield fork(getCandidateSupervisorData, action.payload.candidateId, action.payload.brandId);
  yield fork(getCandidateEMRData, action.payload.candidateId, action.payload.brandId);
  yield fork(getCandidateRequestTimeOff, action.payload.candidateId, action.payload.brandId);
  yield fork(getCandidatePreferencesData, action.payload.candidateId);
}

export function* getSummaryTabData(action: PayloadAction<{ candidateId: number }>) {
  yield fork(getCandidateCredentials, action.payload.candidateId, TrackGetCandidateSummaryTabDetails);
  yield fork(getCandidateReferences, action.payload.candidateId, 1);
  yield fork(getCandidateReferences, action.payload.candidateId, 0);
}

export function* getCandidateDetailsData(action: PayloadAction<{ candidateId: number; brandId: number }>) {
  yield fork(getCandidateByBrandId, action.payload.candidateId, action.payload.brandId);
}
// #endregion
