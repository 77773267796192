import React, { useCallback, useState } from 'react';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import { MenuItem, TextField } from 'amn-ui-core';
import { useSelector } from 'react-redux';
import { CompactStatus, IOrderLicense } from '@AMIEWEB/Order/Store/Order.redux';
import { selectFacility } from '@AMIEWEB/Facility/FacilityStore/Facility.selector';
import { selectOrderRequirementTypes } from '@AMIEWEB/Order/Store/OrderCreation.selectors';
import { CollectionType } from 'app/enums/Common';
import { useFormContext } from 'react-hook-form';
import { selectCompactStatus } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/UnitTab/Store/FacilityUnit.selector';
import { CustomTooltip } from '@AMIEWEB/Common';
import { useCompactStatusStyles } from './Experience.styles';

interface GridCellProps {
  [key: string]: any;
}

export const CompactStatusOptions = [
  {
    id: 1,
    name: 'Single State',
    value: 'Single State',
  },
  {
    id: 2,
    name: 'Multi State',
    value: 'Multi State',
  },
];

const CompactStatusRenderer = React.memo(function CompactStatusRenderer(cellParams: GridCellProps) {
  const { classes } = useCompactStatusStyles();
  const { t } = useTranslation();
  const [isFieldHidden, setFieldHidden] = useState<boolean>(true);
  const [selectedValues, setSelectedValues] = useState<any>(null);
  const [isError, setIsError] = useState<boolean>(false);
  const facilityData = useSelector(selectFacility);
  const { states } = useSelector(selectOrderRequirementTypes);
  const { setValue } = useFormContext();
  const compactStatusOptions = useSelector(selectCompactStatus);

  React.useEffect(() => {
    if (cellParams?.param?.id && cellParams?.selectedLicense) {
      setFieldHidden(false);
      cellParams?.skillsetLicenseData?.forEach(data => {
        if (data?.licenseId === cellParams?.param?.id) {
          setIsError(data?.isError?.compactStatus);
          if (data?.compactStatus) {
            setSelectedValues(data?.compactStatus);
          }
        }
      });
    }
  }, [cellParams?.selectedLicense]);

  const onSelectFieldChange = useCallback(
    (data: any) => {
      const facilityState = states?.find(
        (state: any) =>
          state?.value === facilityData?.facilityResponse?.location?.state ||
          state?.value === facilityData?.response?.location?.state,
      );
      setSelectedValues(data?.target?.value);
      const orderLicensesDetails: IOrderLicense[] = [...cellParams?.skillsetLicenseData];
      if (cellParams?.skillsetLicenseData?.length > 0 && data) {
        const licenseDetailsIndex = orderLicensesDetails?.findIndex(data => data?.licenseId === cellParams?.param?.id);

        const licenseStateData = [
          {
            state: facilityState,
            isRequired: true, // "isRequired" always true
            documentAttachmentRequired: false,
          },
        ];
        if (licenseDetailsIndex !== -1 && cellParams?.selectedLicense) {
          const selectedLicenseDetail: IOrderLicense = {
            ...cellParams?.selectedLicense,
            compactStatus: data?.target?.value,
            licenseStateDetails:
              data?.target?.value === CompactStatus.single
                ? licenseStateData
                : [{ isRequired: true, documentAttachmentRequired: false }], // "isRequired" always true
            isError: { compactStatus: false, state: false },
          };
          orderLicensesDetails[licenseDetailsIndex] = { ...selectedLicenseDetail };
        }
      }
      setValue(
        `skillSetGroup.groups[${cellParams?.groupIndex}].skillsets[${cellParams?.skillSetIndex}].collections[${CollectionType.LicensesIndex}].requirements`,
        orderLicensesDetails,
      );
    },
    [cellParams?.skillsetLicenseData, cellParams?.param?.id],
  );

  return (
    <>
      {!isFieldHidden && (
        <CustomTooltip
          standardMargin
          tooltipContent={
            cellParams?.isUnitLicense && !cellParams?.param?.row?.compact
              ? t('facility.units.unitDetails.unitRequirements.disabledCompactStatusLabel')
              : ''
          }
        >
          <FormControl
            variant="outlined"
            className={`${classes.formControl} ${
              cellParams?.isUnitLicense && !cellParams?.param?.row?.compact ? classes.disableField : ''
            }`}
          >
            <TextField
              required={true}
              select
              variant="outlined"
              size={'small'}
              style={{ width: '165px' }}
              error={isError}
              value={selectedValues}
              onChange={value => onSelectFieldChange(value)}
              label={!selectedValues ? t('order.license.compactStatus') : ''}
              InputLabelProps={{ shrink: false }}
            >
              {cellParams?.isUnitLicense
                ? compactStatusOptions?.map(option => {
                    return <MenuItem value={option.name}>{option.name}</MenuItem>;
                  })
                : CompactStatusOptions?.map(option => {
                    return <MenuItem value={option.value}>{option.name}</MenuItem>;
                  })}
            </TextField>
          </FormControl>
        </CustomTooltip>
      )}
    </>
  );
});

export const UnitCompactStatusRenderCell = (props: {
  param: GridCellParams;
  groupIndex: number;
  skillSetIndex: number;
  isUnitLicense: boolean; // remove in User Story: 357906
}) => {
  const { getValues } = useFormContext();

  const skillsetLicenseData = getValues(
    `skillSetGroup.groups[${props.groupIndex}].skillsets[${props.skillSetIndex}].collections[${CollectionType.LicensesIndex}].requirements`,
  );

  const selectedLicense = skillsetLicenseData?.find(data => data?.licenseId === props.param?.id);

  return props.param?.id && skillsetLicenseData?.length && selectedLicense ? (
    <CompactStatusRenderer
      {...props}
      skillsetLicenseData={skillsetLicenseData}
      selectedLicense={selectedLicense}
      isUnitLicense={props.isUnitLicense}
    />
  ) : (
    <></>
  );
};
