import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { useSelector } from 'react-redux';
import { Grid, Skeleton } from 'amn-ui-core';

import {
  selectFacilityDetails,
  selectMandatoryFieldsStatus,
  selectNewUnit,
  selectIsUnitNameError,
  selectIncompleteReqFields,
} from '@AMIEWEB/Order/Store/OrderCreation.selectors';
import { UnitBody } from 'app/components/Unit/UnitDesign';
import { NewUnitDetails } from 'app/components/Unit/UnitDetails/NewUnitDetails';
import { NewNurseToPatientRatio } from '@AMIEWEB/Unit/NurseToPatientRatio/NewNurseToPatientRatio';
import { NewTravelExperience } from '@AMIEWEB/Unit/TravelExperience/NewTravelExperience';
import { NewExperience } from '@AMIEWEB/Unit/Experience/NewExperience';
import { Controller, useFormContext } from 'react-hook-form';
import { NewVirtualRating } from '@AMIEWEB/Unit/VirtualRating/NewVirtualRating';
import { NewUnitOrderPriorities } from '@AMIEWEB/Unit/UnitOrderPriorities/NewUnitOrderPriorities';
import { NewUnitBestBets } from '@AMIEWEB/Unit/UnitBestBets/NewUnitBestBets';
import { NewRefernces } from '@AMIEWEB/Unit/References/NewRefernces';
import { NewFloat } from '@AMIEWEB/Unit/Float/NewFloat';
import { NewFlexibleSchedule } from '@AMIEWEB/Unit/FlexibleSchedule/NewFlexibleSchedule';
import { NewNonStandardRequirements } from '@AMIEWEB/Unit/NonStandardRequirements/NewNonStandardRequirements';
import { NewUnitQualification } from '@AMIEWEB/Unit/Qualification/NewUnitQualification';
import { NewUnitClientContactWrapper } from '@AMIEWEB/Unit/ClientContact/NewUnitClientContactWrapper';
import { NewUnitExternalJobInternalNotes } from '@AMIEWEB/Unit/ExternalJobInternalNotes/NewUnitExternalJobInternalNotes';
import { NewFirstDayInstructionNotes } from '@AMIEWEB/Unit/NewFirstDayInstructionNotes';

const useStyles: any = makeStyles()(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}));

export const UnitCreationEditOrderDetails = () => {
  const { classes } = useStyles();
  const { control } = useFormContext();
  const facilityDetails = useSelector(selectFacilityDetails);
  const newUnitObj = useSelector(selectNewUnit);
  const showMandetryStyle = useSelector(selectMandatoryFieldsStatus);
  const isUnitNameError = useSelector(selectIsUnitNameError);
  const incompleteReqFields = useSelector(selectIncompleteReqFields);
  /*Event Handlers*/

  const handleUnitPropChange = (property: string, value: any) => {
    console.log('property changed', property, value);
    if (property === 'bestBetsId') {
      //dispatch(requestOrderCreationUpdateBestBetsIdDetailsAction({data: value}));
    } else if (property === 'virtualRating') {
      //dispatch(requestOrderCreationUpdateVirtualRatingDetailsAction({data: value}));
    } else {
      //dispatch(requestOrderCreationUpdatePropertyDetailsAction({propertyName : property, data: value}));
    }
  };

  return (
    <>
      <Grid container spacing={3} className={classes.root}>
        {/* UnitForm */}
        <Grid item xs={12}>
          <Grid container direction="column">
            <UnitBody container spacing={3}>
              <Grid item xs={12}>
                {newUnitObj ? (
                  <>
                    <NewUnitDetails
                      onUnitPropChange={handleUnitPropChange}
                      setUnitNameError={isUnitNameError}
                      disableEdit={false}
                      showMandetryStyle={showMandetryStyle}
                      incompleteReqFields={incompleteReqFields}
                    />
                  </>
                ) : (
                  <Skeleton variant="rectangular" />
                )}
              </Grid>
              <Grid item xs={6}>
                <Grid container direction="row" spacing={3}>
                  <Grid item xs={12}>
                    {newUnitObj ? (
                      <>
                        <NewExperience
                          division={facilityDetails.facilityData.division}
                          isEdit={true}
                          showMandetryStyle={showMandetryStyle}
                          incompleteReqFields={incompleteReqFields}
                        />
                      </>
                    ) : (
                      <Skeleton variant="rectangular" />
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {newUnitObj && <NewTravelExperience disableEdit={false} hasRadio={true} />}
                  </Grid>
                  <Grid item xs={12}>
                    {newUnitObj && <NewUnitQualification disableEdit={false} />}
                  </Grid>
                  <Grid item xs={12}>
                    {newUnitObj && (
                      <NewNurseToPatientRatio
                        //onUnitPropChange={handleUnitPropChange}
                        //unit={newOrderObj}
                        disableEdit={false}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container direction="row" spacing={3}>
                  <Grid item xs={12}>
                    {newUnitObj && <NewUnitExternalJobInternalNotes showMandetryStyle={true} />}
                  </Grid>
                  <Grid item xs={6}>
                    {newUnitObj && <NewVirtualRating disableEdit={false} />}
                  </Grid>
                  <Grid item xs={6}>
                    {newUnitObj && <NewUnitOrderPriorities />}
                  </Grid>
                  <Grid item xs={12}>
                    {newUnitObj && <NewUnitBestBets disableEdit={false} />}
                  </Grid>
                  <Grid item xs={12}>
                    {newUnitObj && <NewRefernces disableEdit={false} />}
                  </Grid>
                  <Grid item xs={12}>
                    {newUnitObj && <NewFloat disableEdit={false} hasRadio={true} />}
                  </Grid>
                  <Grid item xs={12}>
                    {newUnitObj && <NewFlexibleSchedule disableEdit={false} hasRadio={true} />}
                  </Grid>
                  <Grid item xs={12}>
                    {newUnitObj && <NewNonStandardRequirements disableEdit={false} hasRadio={true} />}
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name={'unitContacts'}
                      render={({ ref, onChange, ...rest }) => (
                        <NewUnitClientContactWrapper
                          unitContacts={rest.value}
                          onCheckedRows={(selectedRows: any) => {
                            const clientContacts = [];
                            selectedRows.map(contact => {
                              const filtercontact = rest.value?.find(x => x.clientContactId === contact['contactId']);
                              clientContacts.push({
                                clientContactId: contact['contactId'],
                                isPrimary: filtercontact ? filtercontact.isPrimary : contact['isPrimary'],
                              });
                            });
                            onChange(clientContacts);
                          }}
                          onStarIconClick={starredRows => {
                            const selectedRows = starredRows
                              ?.filter(item => item.selected)
                              .map(item => ({
                                clientContactId: item.contactId,
                                isPrimary: item.isPrimary ?? false,
                              }));
                            onChange(selectedRows);
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {newUnitObj && <NewFirstDayInstructionNotes showMandatoryStyle={true} />}
                  </Grid>
                </Grid>
              </Grid>
            </UnitBody>
          </Grid>
        </Grid>
        {/* End UnitForm */}
      </Grid>
    </>
  );
};
