/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import moment from 'moment';
import { useState, useCallback, useEffect } from 'react';
import { appInsights } from './AppInsights';
import { IPageViewTelemetry } from '@microsoft/applicationinsights-web';
import {
  SessionKey,
  StorageType,
  getSessionValue,
  setSessionValue,
} from 'utils/customHooks/sessionStorage/sessionHelpers';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useSelector } from 'react-redux';
import { selectUser } from 'oidc/user.selectors';

const sessionStorageAnalyticsKey = 'amie-analytics';
const employeeNameUnavailable = 'unavailable';

interface ITrackEventDefault {
  type: 'event' | 'click' | 'duration' | 'loadTime' | 'pageLoadTime' | 'navigation' | 'error';
  name: string;
  properties?: { [key: string]: any };
}
interface ITrackEventTimed extends ITrackEventDefault {
  timerKey?: string;
}

const timeDifference = endTimestamp => {
  return moment(moment()).diff(moment(endTimestamp));
};

/**
 * Start a timed event for App Insights
 * @param key Unique key for the event type. E.g., `Order Created`
 */
export const startTimedEvent = (key: string, track?: ITrackEventDefault) => {
  const analyticsStorage = JSON.parse(sessionStorage.getItem(sessionStorageAnalyticsKey) || '{}');
  const item = moment().format('yyyy-MM-DDTHH:mm:ss.SSS');
  sessionStorage.setItem(sessionStorageAnalyticsKey, JSON.stringify({ ...analyticsStorage, [key]: item }));
  if (track) trackEvent(track);
};

/**
 * Removed a timed event for app insights prematurely
 * @param key Unique key for the event type
 */
export const removeTimedEvent = (key: string) => {
  const analyticsStorage = JSON.parse(sessionStorage.getItem(sessionStorageAnalyticsKey) || '{}');
  if (analyticsStorage.hasOwnProperty(key)) {
    delete analyticsStorage[key];
  }
  sessionStorage.setItem(sessionStorageAnalyticsKey, JSON.stringify(analyticsStorage));
};

const getCustomEndTimestamp = (key: string) => {
  const timer = JSON.parse(sessionStorage.getItem(sessionStorageAnalyticsKey) || '{}');
  return timer[key];
};

/**
 * Track event in app insights
 *
 * If timerKey is provided, it will retrieve the timestamp used from `startTimedEvent` and set it as the `duration` property
 * as a time difference in milliseconds
 * fullname and username are a constant keys within properties that tracks the employee name and username
 * @param param0
 */
export const trackEvent = ({ type, name, properties, timerKey }: ITrackEventTimed) => {
  let modifiedProperties: { [x: string]: any } = {
    ...(properties || {}),
    ...staticProperties(),
  };
  if (timerKey) {
    const foundTimer = getCustomEndTimestamp(timerKey);
    if (foundTimer) {
      const duration = timeDifference(foundTimer);
      modifiedProperties = {
        ...properties,
        duration: duration,
      };
    }
    removeTimedEvent(timerKey);
  }
  if (appInsights !== undefined) {
    appInsights.trackEvent({
      name: `amieweb:${type}:${name}`,
      properties: modifiedProperties,
    });
  }
};

/**
 * Log an Error to app insights
 * @param exception Exception or error that was thrown
 * @param properties Any additional properties
 */
export const trackException = ({ exception, properties }: { exception: any; properties?: { [key: string]: any } }) => {
  if (appInsights !== undefined) {
    appInsights.trackException(
      {
        exception: exception,
      },
      {
        ...(properties || {}),
        ...staticProperties(),
      },
    );
  }
};

export const trackPageView = ({ name, ...props }: IPageViewTelemetry) => {
  if (appInsights !== undefined) {
    appInsights.trackPageView({
      name: name,
      ...props,
      properties: {
        ...(props.properties || {}),
        ...staticProperties(),
      },
    });
  }
};

export interface IPageLoadTracker {
  initialTime: moment.Moment;
  data: { [x: string]: boolean };
  pageDidLoad: (item?) => void;
  resetPageDidLoad: () => void;
  dataLoaded: (item?) => void;
}

/**
 * custom hook for tracking page visit times
 */
export const usePageVisitTimeTracker = (key: string) => {
  useEffect(() => {
    return () => {
      const endTime = timeDifference(getCustomEndTimestamp(key));
      if (endTime >= 200) {
        // offset to account for initial render before data is loadad.
        trackEvent({ type: 'duration', name: key, properties: { duration: endTime } });
      }
    };
  }, [key]);
};

interface IPageLoad {
  name?: IPage;
  apis?: IPageLoadAPIs;
  // date formatted as yyyy-MM-DDTHH:mm:ss.SSS
  start: string;
}

interface IPageLoadAPIs {
  [x: string]: boolean;
}

enum IPage {
  placement,
  candidate,
  facility,
  order,
  unit,
}

export class PageLoadTracker {
  static currentPage?: IPage = undefined;
  /**
   * Begin page load tracker both for app insights and a custom event
   * @param param0 The page name and list of APIs you're tracking for the current page
   */
  static startPageLoad = ({ name, apis }: Omit<IPageLoad, 'start'> = {}) => {
    const pageName = PageLoadTracker.getPageName(name);
    PageLoadTracker.currentPage = pageName;
    appInsights.startTrackPage();
    const pageLoadTime = getSessionValue(SessionKey.pageLoadTime, StorageType.sessionStorage) ?? {};
    setSessionValue(
      SessionKey.pageLoadTime,
      {
        ...pageLoadTime,
        [IPage[pageName]]: {
          name: IPage[pageName],
          start: moment().format('yyyy-MM-DDTHH:mm:ss.SSS'),
          apis: apis ?? PageLoadTracker.pageRequests(pageName),
        },
      },
      StorageType.sessionStorage,
    );
  };

  /**
   * When an API finishes loading, call this method. This will set that API to true and check if all APIs have loaded.
   * If all APIs have been loaded, then the `stopTrackPage` method will be called and a custom even logged
   * @param name The name of the page you're tracking
   * @param item The name of the API you're tracking
   */
  static dataLoaded = async ({ name, item }: { name?: IPage; item?: string }) => {
    const pageLoadTimes = getSessionValue(SessionKey.pageLoadTime, StorageType.sessionStorage) ?? {};
    const pageName = PageLoadTracker.getPageName(name);
    const currentPage: IPageLoad = pageLoadTimes[IPage[pageName]];
    if (currentPage?.apis) {
      Object.keys(currentPage.apis).some(pattern => {
        const regex = new RegExp(`^${pattern}.*$`);
        if (regex.test(item)) {
          currentPage.apis[pattern] = true;
        }
      });

      const allItemsCompleted = Object.values(currentPage.apis ?? {}).indexOf(false) === -1;
      if (allItemsCompleted) {
        appInsights.stopTrackPage();
        delete pageLoadTimes[IPage[pageName]];
        // custom event for page load time
        const timeApi = moment(moment()).diff(moment(currentPage.start));
        trackEvent({ type: 'pageLoadTime', name: IPage[pageName], properties: { duration: timeApi } });
      }
      setSessionValue(SessionKey.pageLoadTime, pageLoadTimes, StorageType.sessionStorage);
      return Promise.resolve(allItemsCompleted);
    } else {
      return Promise.resolve(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  /**
   * Manually stop tracking the page load.
   * This will remove the page from sessionStorage and log a custom event
   * @param name The name of the page you're tracking
   */
  static stopPageLoad = (name?: IPage) => {
    appInsights.stopTrackPage();
    const pageLoadTimes = getSessionValue(SessionKey.pageLoadTime, StorageType.sessionStorage) ?? {};
    const pageName = PageLoadTracker.getPageName(name);
    const currentPage: IPageLoad = pageLoadTimes[IPage[pageName]];
    delete pageLoadTimes[IPage[pageName]];
    // custom event for page load time
    const timeApi = moment(moment()).diff(moment(currentPage.start));
    trackEvent({ type: 'pageLoadTime', name: IPage[pageName], properties: { duration: timeApi } });
    setSessionValue(SessionKey.pageLoadTime, pageLoadTimes, StorageType.sessionStorage);
  };

  static getPageName = (name?: IPage): IPage | null => {
    if (name) {
      return name;
    } else {
      if (window.location.pathname.match(/^\/placement\/\d+.*$/) !== null) {
        return IPage.placement;
      } else if (window.location.pathname.match(/^\/candidate\/\d+\/\d+.*$/) !== null) {
        return IPage.candidate;
      } else if (window.location.pathname.match(/^\/facility\/\d+.*$/) !== null) {
        return IPage.facility;
      } else if (window.location.pathname.match(/^\/facility\/\d+\/unit\/\d+.*$/) !== null) {
        return IPage.unit;
      } else if (window.location.pathname.match(/^\/order\/\d+.*$/) !== null) {
        return IPage.order;
      } else {
        return null;
      }
    }
  };

  static pageRequests = (name: IPage) => {
    switch (name) {
      case IPage.placement:
        return {
          '/placement/v1/placement/profile/details': false,
          '/order/v1/order/\\d+': false,
          '/candidate/v1/candidate/\\d+/dob': false,
          '/candidate/v1/candidate/\\d+/ssn': false,
          '/placement/v1/placement/\\d+/modifications/refresh-list': false,
          '/credentialing/v1/credentials/credone/\\d+/placement/\\d+': false,
          '/shared/v1/booking/details-report': false,
          '/credentialing/v1/credentials/lookups/all': false,
          // '/order/v1/order/\\d+/placement-automation': false,
          // '/placement/v1/placement/\\d+/detail/amn-references': false,
          // '/placement/v1/placement/\\d+/detail/order-certifications': false,
          // '/placement/v1/placement/\\d+/detail/order-mile-radius': false,
          // '/placement/v1/placement/\\d+/detail/order-schedule': false,
          // '/placement/v1/placement/\\d+/detail/skillset-groups': false,
          // '/placement/v1/placement/\\d+/detail/strike-status': false,
          // '/placement/v1/placement/\\d+/disabled-fields': false,
          // '/placement/v1/placement/\\d+/modifications/add-list': false,
          // '/placement/v1/placement/extension/direct-hire-types': false,
          // '/placement/v1/placement/pick-list/start-date-reason-codes': false,
          // '/shared/v1/placement/\\d+/peoplesoft': false,
        };
      case IPage.candidate:
        return {
          '/credentialing/v1/credentials/bycandidateid/\\d+': false,
          '/candidate/v1/candidate/\\d+/reference': false,
          '/candidate/v1/candidate/\\d+/brand/\\d+': false,
          '/candidate/v1/candidate/\\d+/ssn': false,
          '/candidate/v1/candidate/\\d+/skillsettree': false,
          '/candidate/v1/candidate/\\d+/skillschecklist': false,
          '/candidate/v1/candidate/\\d+/audit': false,
          '/candidate/v1/candidate/tags/\\d+/brand/\\d+': false,
        }
      case IPage.facility:
        return {
          '/facility/v1/Facilities/Facility/\\d+': false,
          '/facility/v1/facilities/facility//qs-instructions/\\d+': false,
          '/order/v1/order/facility/\\d+/open-order-count': false,
        }
      case IPage.unit:
        return {
          '/facility/v1/units/unit/\\d+': false,
          '/facility/v1/units/facility/\\d+': false,
          '/facility/v1/Facilities/Facility/\\d+': false,
          '/facility/v1/facilities/facility//qs-instructions/\\d+': false,
          '/facility/v1/units/unit/skillset/\\d+': false,
        }
      case IPage.order:
        return {
          '/facility/v1/Units/unit/\\d+/\\d+/client-contacts': false,
          '/facility/v1/units/details/unit/\\d+/facility/\\d+': false,
          '/order/v1/order/\\d+': false,
          '/order/v1/order/\\d+/grouped-rate-element': false,
          '/order/v1/order/\\d+/report': false,
          '/order/v1/order/candidates/preferences/order/\\d+': false,
          '/shared/v1/search/issuing-bodies': false,
        }
      default:
        return {};
    }
  };
}

/**
 * Soley used for page load times
 */
export const usePageLoadingTracker = (
  {
    name = '',
    itemsToLoad = {},
  }: {
    name: string;
    itemsToLoad?: { [x: string]: boolean };
  } = { name: '', itemsToLoad: {} },
): IPageLoadTracker => {
  const [initialTime, setInitialTime] = useState<any>(moment());
  const [data, setData] = useState<{ [x: string]: boolean }>(itemsToLoad);

  React.useEffect(() => {
    PageLoadTracker.startPageLoad();
    return () => {
      // Fallback stop page load when the component unmounts
      PageLoadTracker.stopPageLoad();
    };
  }, []);

  const resetPageDidLoad = useCallback(() => {
    setInitialTime(moment());
  }, []);

  const dataLoaded = useCallback((item?: string) => {
    const newData = { ...data };
    if (item) newData[item] = true;
    setData(newData);
    return Object.values(newData).indexOf(false) === -1;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pageDidLoad = useCallback(
    (item?: string) => {
      const loaded = dataLoaded(item);
      if (loaded) {
        const timeApi = moment(moment()).diff(moment(initialTime));
        trackEvent({ type: 'pageLoadTime', name: name, properties: { duration: timeApi } });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialTime],
  );

  return { initialTime, data, pageDidLoad, resetPageDidLoad, dataLoaded };
};

const staticProperties = () => {
  const user = getSessionValue(SessionKey.userDetails, StorageType.sessionStorage);
  return {
    fullname: user?.name || employeeNameUnavailable,
    username: user?.username || employeeNameUnavailable,
    employeeId: user?.employeeId || employeeNameUnavailable,
  };
};

export const useEmployeeNameTracking = () => {
  const { userInfo } = useSelector(selectUser);
  const isAuthenticated = useIsAuthenticated();
  const { accounts } = useMsal();

  React.useEffect(() => {
    if (isAuthenticated && accounts?.length > 0) {
      setSessionValue(
        SessionKey.userDetails,
        { name: accounts[0].name, username: accounts[0].username, employeeId: userInfo.employeeId },
        StorageType.sessionStorage,
      );
    }
  }, [isAuthenticated, accounts, userInfo]);
};
