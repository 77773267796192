import { VerticalContentPopover } from 'app/components/Common/Tooltips';
import { selectPlacementOrderType } from 'store/redux-store/placement-details/selectors';
import { missingField } from 'app/constants';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { usePromiseTracker } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { CustomListSection } from '../../../customComponents/ListSection';
import { useRequirementFilter } from '../../../Helpers/requirementFilter';
import { AddOnContext } from '../../../PlacementSummaryTab';
import { placementSummaryActions } from '../../../../../../../../../store/redux-store/placement-summary/slice';
import {
  selectServiceFailures,
  selectSubmissionInfo,
} from '../../../../../../../../../store/redux-store/placement-summary/selectors';
import { RequirementsRow } from '../../TableComponents/TableComponents';

export const SkillsChecklist = () => {
  const { t } = useTranslation();
  const {
    orderReq: {
      candidate: { skillsCheckList: candidateReqItem },
    },
    requirementFilter,
  } = useSelector(selectSubmissionInfo);
  const { skillsChecklistRequirement: serviceFailed } = useSelector(selectServiceFailures);
  const { placementId } = useContext(AddOnContext);
  const placementOrderType = useSelector(selectPlacementOrderType);

  const { promiseInProgress } = usePromiseTracker({
    area: 'placement-submission-skills-checklist-requirement',
    delay: 0,
  });

  const dispatch = useDispatch();

  const { display } = useRequirementFilter('submission-order', requirementFilter);

  return display ? (
    <RequirementsRow
      id="placement-submission-order-skills-checklist"
      title={t('placement.profile.summary.submission.order.skillsChecklist.title')}
      requirementCell={missingField}
      candidateCell={<RowValue skillsCheckList={candidateReqItem} />}
      loading={promiseInProgress}
      hasError={serviceFailed}
      onTryAgain={() =>
        dispatch(placementSummaryActions.getSkillSChecklistRequirement({ placementId, placementOrderType }))
      }
    />
  ) : null;
};

const RowValue = ({ skillsCheckList }) =>
  (skillsCheckList?.data || []).length ? (
    <VerticalContentPopover
      value={<CustomListSection listType={'skillsChecklist'} items={skillsCheckList?.data || []} />}
      maxHeight={180}
    />
  ) : (
    <>{missingField}</>
  );
