import { SkillsetTree } from 'app/components/Common/SkillsetTree/SkillsetTree';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { usePromiseTracker } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { TableSection } from '../../../customComponents/TableSection';
import { useRequirementFilter } from '../../../Helpers/requirementFilter';
import { AddOnContext } from '../../../PlacementSummaryTab';
import { placementSummaryActions } from '../../../../../../../../../store/redux-store/placement-summary/slice';
import {
  selectServiceFailures,
  selectSubmissionInfo,
} from '../../../../../../../../../store/redux-store/placement-summary/selectors';
import { RequirementsRow } from '../../TableComponents/TableComponents';

export const Skillset = () => {
  const { t } = useTranslation();
  const {
    orderReq: {
      order: { skillset: orderReq },
      candidate: { skillset: candidateReq },
    },
    requirementFilter,
  } = useSelector(selectSubmissionInfo);
  const { promiseInProgress } = usePromiseTracker({
    area: 'placement-submission-skillset-requirement',
    delay: 0,
  });
  const { skillsetOrderRequirement: serviceFailed } = useSelector(selectServiceFailures);
  const { placementId } = useContext(AddOnContext);
  const dispatch = useDispatch();
  const { display } = useRequirementFilter('submission-order', requirementFilter, orderReq?.type);

  return display ? (
    <RequirementsRow
      id="placement-submission-order-skillset"
      title={t('placement.profile.summary.submission.order.skillset.title')}
      requirementCell={<RowKey skillset={orderReq} t={t} />}
      candidateCell={<RowValue skillset={candidateReq} />}
      loading={promiseInProgress}
      hasError={serviceFailed}
      onTryAgain={() =>
        dispatch(placementSummaryActions.getSkillsetRequirement({ placementId, isRetryingService: true }))
      }
    />
  ) : null;
};

const RowKey = ({ skillset, t }) => (
  <TableSection
    items={[
      {
        primaryContent: <SkillsetTree skillset={skillset ? skillset.data : []} />,
      },
    ]}
  />
);

const RowValue = ({ skillset }) => <SkillsetTree skillset={skillset ? skillset.data : []} />;
