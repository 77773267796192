import { missingField } from 'app/constants';
import { IEditPersonalInfoCategory } from 'app/enums/PersonalInfo';
import {
  ICandidateDetails,
  IPersonalInfoNonFormFields,
  IPersonalInformation,
} from 'app/models/Candidate/CandidateProfile';
import _ from 'lodash';
import moment from 'moment';
import { IUserInfo } from 'oidc/user.redux';
import { Authorized } from 'oidc/userHelper';
import { userRoles } from 'oidc/userRoles';
import { customSwitch } from 'utils/customSwitchCase/customSwitch';
import { validEmail } from 'utils/string/string';
import { ICandidateAddressDetails } from '@AMIEWEB/Candidate/CandidateProfile/CandidateHeader/CandidateHeader';

export const contactTypes = [
  { ID: 6, Description: 'Cell phone' },
  { ID: 1, Description: 'Current' },
  { ID: 7, Description: 'Work' },
  { ID: 2, Description: 'Permanent' },
  { ID: 8, Description: 'International' },
];

export enum YesNoValues {
  YES = 'Yes',
  NO = 'No',
  MISSING = missingField,
}

export const YesNoFieldOptions = [
  {
    ID: 1,
    Description: YesNoValues.MISSING,
  },
  {
    ID: 2,
    Description: YesNoValues.YES,
  },
  {
    ID: 3,
    Description: YesNoValues.NO,
  },
];

//This Helps on Setting Default Values
export const setValues = (setValue, section, candidateData: ICandidateDetails) => {
  switch (section) {
    case IEditPersonalInfoCategory.PersonalInfo:
      setValuesPersonalInfo(setValue, candidateData);
      break;
    case IEditPersonalInfoCategory.ContactInfo:
      setValuesContactInfo(setValue, candidateData);
      break;
    case IEditPersonalInfoCategory.EmergencyContact:
      setValuesEmergencySection(setValue, candidateData);
      break;
    case IEditPersonalInfoCategory.ApplicationQuestions:
      setValuesApplicationSection(setValue, candidateData);
      break;
  }
};

//Set Default Values For Personal Info
const setValuesPersonalInfo = (setValue, formData) => {
  setValue('piFirstName', formData?.name?.first, { isDirty: true });
  setValue('piMidName', formData?.name?.middle, { isDirty: true });
  setValue('piLastName', formData?.name?.last, { isDirty: true });
  setValue('piGoesBy', formData?.goesBy, { isDirty: true });
  setValue('piDOB', formData?.dateOfBirth, { isDirty: true });
  setValue('piSSN', formData?.socialSecurityNumber, { isDirty: true });
  setValue('piGender', formData?.gender, { isDirty: true });
  setValue('raceEthnicity', formData?.ethnicityId, { isDirty: true });
  setValue('piNUID', formData?.nuid, { isDirty: true });
  // formData?.addressData?.map(item=>{
  // setValue('Address1', "kjxjd", { isDirty: true });
  // })
};

//Extract data from candidate response
const extractData = (data: ICandidateDetails, userInfo) => {
  const personalInformation: IPersonalInformation = {
    candidateId: data?.travelerId,
    brandId: data?.brandId,
    firstName: data?.name?.first,
    lastName: data?.name?.last,
    middleName: data?.name?.middle,
    goesBy: data?.goesBy,
    ssn: data?.socialSecurityNumber,
    dob: data?.dateOfBirth,
    primaryEmail: data?.primaryEmail,
    secondaryEmail: data?.secondaryEmail,
    addresses: data?.addressData,
    phoneNumbers: data.phoneNumbers !== undefined ? data?.phoneNumbers : [],
    currentEmployeeId: userInfo?.employeeId,
    currentEmployeeName: userInfo?.firstName + ' ' + userInfo?.lastName,
    timeStamp: data?.amieTravelerTimestamp,
    //ethnicityId: data?.ethnicityId,
    gender: data?.gender,
  };

  return personalInformation;
};

const extractAddressData = (data: IPersonalInformation) => {
  const addressInfo: ICandidateAddressDetails = {
    candidateId: data?.candidateId,
    brandId: data?.brandId,
    currentEmployeeId: data?.currentEmployeeId,
    currentEmployeeName: data?.currentEmployeeName,
    addresses: data?.addresses,
  };

  return addressInfo;
};

export const processDataUpdate = (
  data: ICandidateDetails,
  formData,
  userInfo,
  addExistingFieldValues: IPersonalInfoNonFormFields,
) => {
  const formatData = extractData(data, userInfo) as IPersonalInformation;
  //update for Personal Information
  formatData.firstName = formData['piFirstName'] ?? formatData.firstName;
  formatData.middleName = formData['piMidName'] ?? formatData.middleName;
  formatData.lastName = formData['piLastName'] ?? formatData.lastName;
  formatData.goesBy = formData['piGoesBy'] ?? formatData.goesBy;
  formatData.dob = formData['piDOB'] ?? formatData.dob;
  formatData.ssn = formData['piSSN'] ?? formatData.ssn;
  //formatData.ethnicityId = formData.raceEthnicity?.object?.ID;
  formatData.gender = formData['piGender'] ?? formatData.gender;
  //formatData.ethnicityId = formData['raceEthnicity'] ?? data.ethnicityId;
  // data from api is sending string ID
  // eslint-disable-next-line eqeqeq
  const raceEthnicityDescription = addExistingFieldValues.raceEthnicityOptions?.find(
    e => e.object.ID == formData.raceEthnicity,
  );
  formatData.ethnicityDescription = raceEthnicityDescription?.object.Description;
  formatData.primaryEmail = formData['ciPrimaryEmail'] ?? formatData.primaryEmail;
  formatData.secondaryEmail = formData['ciSecondaryEmail'] ?? formatData.secondaryEmail;
  formatData.emergencyContactInfo = {
    name: formData?.emergencyContactName,
    countryDialingCode: addExistingFieldValues.emergencyContactCountryCode,
    phone: addExistingFieldValues.emergencyPhoneNumber,
    extensionNotes: formData?.extNote,
    relationship: formData?.relationship,
  };
  formatData.applicationQuestions = {
    licenseInvestigationSuspension: replaceYesOrNo(formData?.piInvestigationSuspension),
    investigationSuspensionExplanation: formData?.piExplanation || '',
    usRightToWorkVerification: replaceYesOrNo(formData?.piRightToWork),
    visaType: formData?.piVisaType || '',
  };

  //Address
  return formatData;
};

export const processAddressData = (data: IPersonalInformation) => {
  const formatData = extractAddressData(data) as ICandidateAddressDetails;
  return formatData;
};

const replaceYesOrNo = (value: string) => {
  switch (value) {
    case 'Yes':
      return 'Y';
    case 'No':
      return 'N';
    default:
      return '';
  }
};

export const processSsnAndDOB = (data: ICandidateDetails, formData, userInfo) => {
  const updatedSSN =
    formData['piSSN'] && !formData['piSSN'].includes('*')
      ? formData['piSSN'].length < 10
        ? `${formData['piSSN'].slice(0, 3)}-${formData['piSSN'].slice(3, 5)}-${formData['piSSN'].slice(5, 9)}`
        : formData['piSSN']?.replace(/ /g, '-')
      : data.socialSecurityNumber;
  return {
    candidateId: data?.travelerId,
    brandId: data?.brandId,
    currentEmployeeId: userInfo?.employeeId || 0,
    currentEmployeeName: userInfo?.firstName + ' ' + userInfo?.lastName,
    ssn: updatedSSN,
    dob: formData['piDOB'] ? moment(formData['piDOB']).format('yyyy-MM-DD') : data.dateOfBirth,
    nuid: formData['piNUID'] ? formData['piNUID'] : data.nuid,
    timestamp: data?.amieTravelerTimestamp,
  };
};

const setValuesContactInfo = (setValue, formData) => {
  if (formData.travelerId > 0) {
    setValue('ciPrimaryEmail', formData?.primaryEmail, { isDirty: true });
    setValue('ciSecondaryEmail', formData?.secondaryEmail, { isDirty: true });
  }
};

/**
 *  Method to update emergency section values on page load
 * @param setValue
 * @param candidateData
 */
const setValuesEmergencySection = (setValue, candidateData: ICandidateDetails) => {
  const emergencyContact = candidateData?.emergencyContact;
  const phoneNumber = emergencyContact.countryDialingCode?.concat(emergencyContact.phone);
  setValue('emergencyContactName', emergencyContact.name, { isDirty: true });
  setValue('relationship', emergencyContact?.relationship, { isDirty: true });
  setValue('emergencyPhoneNumber', phoneNumber, { isDirty: true });
  setValue('extNote', emergencyContact?.extensionNotes, { isDirty: true });
};

const setValuesApplicationSection = (setValue, candidateData: ICandidateDetails) => {
  setValue(
    'piInvestigationSuspension',
    convertInvestigationAndRightToWork(
      candidateData?.investigationSuspension,
      candidateData?.investigationSuspensionExplanation,
    ),
    {
      isDirty: true,
    },
  );
  setValue('piExplanation', candidateData?.investigationSuspensionExplanation, { isDirty: true });
  setValue(
    'piRightToWork',
    convertInvestigationAndRightToWork(candidateData?.rightToWorkVerification, candidateData?.visaExplaination),
    {
      isDirty: true,
    },
  );
  setValue('piVisaType', candidateData?.visaExplaination, { isDirty: true });
};

export const roleCheck = (currentUserRoles: IUserInfo | undefined, section: string) => {
  switch (section) {
    case 'personalInfo':
      return Authorized(
        [
          userRoles.recruitment_TeamMember,
          userRoles.recruitment_Leadership,
          userRoles.credentialing_TeamMember,
          userRoles.credentialing_Leadership,
          userRoles.clinical_QualificationsSpecialist,
        ],
        currentUserRoles,
      );
    default:
      return false;
  }
};

export const convertInvestigationAndRightToWork = (value: string | boolean | null, explanation?: string) => {
  switch (value) {
    case 'Y':
      return YesNoValues.YES;
    case 'N':
      return YesNoValues.NO;
    case true:
      return YesNoValues.YES;
    case false:
      return YesNoValues.NO;
    default:
      return YesNoValues.MISSING;
  }
};

export const toPascalCase = str => {
  return _.startCase(_.toLower(str));
};

//Email Validations
export const errorEmailMessage = (e, name, setError) => {
  if (e?.target?.value !== '') {
    if (!validEmail(e?.target?.value)) {
      setErrorMessage(name, setError, 'Invalid format');
    }
  }
};

export const emailCheck = email => {
  return email === '' || validEmail(email);
};

//Extra Layer Of Validation
export const beforeSubmitValidate = (data: any, setError) => {
  if (data?.ciPrimaryEmail) {
    if (!emailCheck(data?.ciPrimaryEmail)) {
      setErrorMessage('ciPrimaryEmail', setError, 'Invalid format');
      return false;
    }
  }

  if (data?.ciSecondaryEmail) {
    if (!emailCheck(data?.ciSecondaryEmail)) {
      setErrorMessage('ciSecondaryEmail', setError, 'Invalid format');
      return false;
    }
  }

  return true;
};

const setErrorMessage = (name, setError, message) => {
  setError(name, {
    type: 'required',
    message: message,
  });
};

export const IsDateValid = (setError, Id, value) => {
  if (!value) {
    setErrorMessage(Id, setError, 'Required');
    return false;
  }
  return true;
};

export const dates = {
  convert: function (d) {
    return d ? new Date(d) : NaN;
  },
  gt: function (a: any, b: any) {
    return this.convert(a) > this.convert(b);
  },
  lt: function (a: any, b: any) {
    return this.convert(a) < this.convert(b);
  },
  eq: function (a: string, b: string) {
    return a === b;
  },
};

export const getAddType = customSwitch({
  Permanent: 1,
  'Company Assignment': 2,
  Other: 3,
  'Tax Home': 4,
  'Company Assignment - Hotel': 5,
})(undefined);

export const validateAddressAddAccess = (roles: IUserInfo | undefined, status) => {
  var selectableAddresses = {
    taxHome: true,
    permanent: true,
    other: true,
    companyAssignment: true,
    companyAssignmentHotel: true,
  };
  //Note: Only Payroll Specialist and Support Admin can add/select tax home address for both status types
  if (status == 1) {
    if (
      Authorized(
        [
          userRoles.recruitment,
          userRoles.recruitment_Leadership,
          userRoles.recruitment_TeamMember,
          userRoles.clinical_Manager,
          userRoles.clinical_ReviewAnalyst,
          userRoles.clinical_QualificationsSpecialist,
          userRoles.customerSupport_Leadership,
          userRoles.customerSupport_TeamMember,
          userRoles.benefitsCoordinator,
          userRoles.accountManagement,
          userRoles.accountManagement_Leadership,
          userRoles.accountManagement_TeamMember,
        ],
        roles,
      )
    ) {
      selectableAddresses.taxHome = false;
      return selectableAddresses;
    }
    if (
      Authorized(
        [
          userRoles.payrollSpecialist,
          userRoles.supportAdmin
        ],
        roles,
      )
    ) {
      return selectableAddresses;
    }
    if (Authorized(
      [
        userRoles.accountsPayableSpecialist,
        userRoles.billingSpecialist,
        userRoles.candidate_Contracts,
        userRoles.candidate_Contracts_Leadership,
        userRoles.candidate_Contracts_TeamMember,
        userRoles.clientAccountingRepresentative,
        userRoles.clientContract_Admin,
        userRoles.clientContract_Admin_Leadership,
        userRoles.clientContract_Admin_TeamMember,
        userRoles.clientSupportSpecialist,
        userRoles.clinical_ClientContractSpecialist,
        userRoles.clinical_Director,
        userRoles.complianceAuditSpecialist,
        userRoles.credentialing,
        userRoles.credentialing_Leadership,
        userRoles.credentialing_TeamMember,
        userRoles.housingAccountExecutive,
        userRoles.pricingAnalyst,
        userRoles.regionalDirector,
        userRoles.riskManagement,
        userRoles.timeProcessingSpecialist,
        userRoles.workersCompCoordinator
      ],
      roles,
    )) {
      selectableAddresses.companyAssignment = false;
      selectableAddresses.companyAssignmentHotel = false;
      selectableAddresses.taxHome = false;
      selectableAddresses.other = false;
      return selectableAddresses;
    }
  }
  if (status == 2) {
    if (
      Authorized(
        [
          userRoles.recruitment,
          userRoles.recruitment_Leadership,
          userRoles.recruitment_TeamMember,
          userRoles.clinical_Manager,
          userRoles.clinical_ReviewAnalyst,
          userRoles.clinical_QualificationsSpecialist,
        ],
        roles,
      )
    ) {
      selectableAddresses.taxHome = false;
      return selectableAddresses;
    }
    if (
      Authorized(
        [
          userRoles.benefitsCoordinator,
          userRoles.housingAccountExecutive,
          userRoles.workersCompCoordinator,
          userRoles.customerSupport_Leadership,
          userRoles.customerSupport_TeamMember,
        ],
        roles,
      )
    ) {
      selectableAddresses.taxHome = false;
      return selectableAddresses;
    }
    if (
      Authorized(
        [
          userRoles.payrollSpecialist,
          userRoles.supportAdmin
        ],
        roles,
      )
    ) {
      return selectableAddresses;
    }
    if (
      Authorized(
        [
          userRoles.accountManagement,
          userRoles.accountManagement_Leadership,
          userRoles.accountManagement_TeamMember
        ],
        roles,
      )
    ) {
      selectableAddresses.companyAssignment = false;
      selectableAddresses.companyAssignmentHotel = false;
      selectableAddresses.taxHome = false;
      return selectableAddresses;
    }
    if (Authorized(
      [
        userRoles.accountsPayableSpecialist,
        userRoles.billingSpecialist,
        userRoles.candidate_Contracts,
        userRoles.candidate_Contracts_Leadership,
        userRoles.candidate_Contracts_TeamMember,
        userRoles.clientAccountingRepresentative,
        userRoles.clientContract_Admin,
        userRoles.clientContract_Admin_Leadership,
        userRoles.clientContract_Admin_TeamMember,
        userRoles.clientSupportSpecialist,
        userRoles.clinical_ClientContractSpecialist,
        userRoles.clinical_Director,
        userRoles.complianceAuditSpecialist,
        userRoles.credentialing,
        userRoles.credentialing_Leadership,
        userRoles.credentialing_TeamMember,
        userRoles.pricingAnalyst,
        userRoles.regionalDirector,
        userRoles.riskManagement,
        userRoles.supportAdmin,
        userRoles.timeProcessingSpecialist,
      ],
      roles,
    )) {
      selectableAddresses.companyAssignment = false;
      selectableAddresses.companyAssignmentHotel = false;
      selectableAddresses.taxHome = false;
      selectableAddresses.other = false;
      return selectableAddresses;
    }
  }
  if (status == 3) {
    if (
      Authorized(
        [
          userRoles.recruitment,
          userRoles.recruitment_Leadership,
          userRoles.recruitment_TeamMember,
          userRoles.clinical_Manager,
          userRoles.clinical_ReviewAnalyst,
          userRoles.clinical_QualificationsSpecialist,
          userRoles.benefitsCoordinator,
          userRoles.housingAccountExecutive,
          userRoles.workersCompCoordinator,
          userRoles.customerSupport_Leadership,
          userRoles.customerSupport_TeamMember,
        ],
        roles,
      )
    ) {
      selectableAddresses.taxHome = false;
      return selectableAddresses;
    }
    if (
      Authorized(
        [
          userRoles.payrollSpecialist,
          userRoles.supportAdmin
        ],
        roles,
      )
    ) {
      selectableAddresses.permanent = false;
      return selectableAddresses;
    }
    if (
      Authorized(
        [
          userRoles.accountManagement,
          userRoles.accountManagement_Leadership,
          userRoles.accountManagement_TeamMember
        ],
        roles,
      )
    ) {
      selectableAddresses.companyAssignment = false;
      selectableAddresses.companyAssignmentHotel = false;
      selectableAddresses.taxHome = false;
      return selectableAddresses;
    }
    if (
      Authorized(
        [
          userRoles.accountsPayableSpecialist,
          userRoles.billingSpecialist,
          userRoles.candidate_Contracts,
          userRoles.candidate_Contracts_Leadership,
          userRoles.candidate_Contracts_TeamMember,
          userRoles.clientAccountingRepresentative,
          userRoles.clientContract_Admin,
          userRoles.clientContract_Admin_Leadership,
          userRoles.clientContract_Admin_TeamMember,
          userRoles.clientSupportSpecialist,
          userRoles.clinical_ClientContractSpecialist,
          userRoles.clinical_Director,
          userRoles.complianceAuditSpecialist,
          userRoles.credentialing,
          userRoles.credentialing_Leadership,
          userRoles.credentialing_TeamMember,
          userRoles.pricingAnalyst,
          userRoles.regionalDirector,
          userRoles.riskManagement,
          userRoles.timeProcessingSpecialist
        ],
        roles,
      )
    ) {
      selectableAddresses.companyAssignment = false;
      selectableAddresses.companyAssignmentHotel = false;
      selectableAddresses.taxHome = false;
      selectableAddresses.other = false;
      return selectableAddresses;
    }
  }
  if (status == 4) {
    if (
      Authorized(
        [
          userRoles.recruitment,
          userRoles.recruitment_Leadership,
          userRoles.recruitment_TeamMember,
          userRoles.clinical_Manager,
          userRoles.clinical_ReviewAnalyst,
          userRoles.clinical_QualificationsSpecialist,
          userRoles.accountManagement,
          userRoles.accountManagement_Leadership,
          userRoles.accountManagement_TeamMember,
          userRoles.accountsPayableSpecialist,
          userRoles.benefitsCoordinator,
          userRoles.billingSpecialist,
          userRoles.candidate_Contracts,
          userRoles.candidate_Contracts_Leadership,
          userRoles.candidate_Contracts_TeamMember,
          userRoles.clientAccountingRepresentative,
          userRoles.clientContract_Admin,
          userRoles.clientContract_Admin_Leadership,
          userRoles.clientContract_Admin_TeamMember,
          userRoles.clientSupportSpecialist,
          userRoles.clinical_ClientContractSpecialist,
          userRoles.clinical_Director,
          userRoles.complianceAuditSpecialist,
          userRoles.credentialing,
          userRoles.credentialing_Leadership,
          userRoles.credentialing_TeamMember,
          userRoles.customerSupport_Leadership,
          userRoles.customerSupport_TeamMember,
          userRoles.housingAccountExecutive,
          userRoles.payrollSpecialist,
          userRoles.pricingAnalyst,
          userRoles.regionalDirector,
          userRoles.riskManagement,
          userRoles.supportAdmin,
          userRoles.timeProcessingSpecialist,
          userRoles.workersCompCoordinator
        ],
        roles,
      )
    ) {
      selectableAddresses.companyAssignment = false;
      selectableAddresses.companyAssignmentHotel = false;
      selectableAddresses.taxHome = false;
      selectableAddresses.other = false;
      return selectableAddresses;
    }
  }
};

export const enableRulesForAddress = (moveIn, moveOut) => {
  var res = {
    Current: false,
    Feature: false,
    Previous: false,
  };

  const todayDate = new Date().toLocaleDateString();

  //Previous Address
  res.Previous = dates.lt(moveOut, todayDate);

  //Current Address
  res.Current = dates.lt(moveIn, todayDate) && dates.eq(moveIn, todayDate);

  //Feature Address
  res.Feature = dates.gt(moveOut, todayDate);

  return res;
};

export const checkDuplicateRoles = (addressId, val, getValues) => {
  var addressRoles: any = [];
  addressRoles.push({
    description: 'Cobra',
    addressRoleID: 5,
    selected: getValues(`Cobra${addressId}${val + 1}`) ? 1 : 0,
  });
  addressRoles.push({
    description: 'Dependent Cobra',
    addressRoleID: 6,
    selected: getValues(`DependentCobra${addressId}${val + 1}`) ? 1 : 0,
  });
  addressRoles.push({
    description: 'Mailers',
    addressRoleID: 4,
    selected: getValues(`Mailers${addressId}${val + 1}`) ? 1 : 0,
  });
  addressRoles.push({
    description: 'Paycheck',
    addressRoleID: 2,
    selected: getValues(`Paycheck${addressId}${val + 1}`) ? 1 : 0,
  });
  addressRoles.push({
    description: 'Subsidy',
    addressRoleID: 1,
    selected: getValues(`Subsidy${addressId}${val + 1}`) ? 1 : 0,
  });
  addressRoles.push({
    description: 'W2',
    addressRoleID: 3,
    selected: getValues(`W2${addressId}${val + 1}`) ? 1 : 0,
  });
  return addressRoles;
};
