import { getValueForNumberField, getValueForWithinLastYear } from '@AMIEWEB/NewOrder/CreateOrder/helper';
import { SingleLineInput } from '@AMIEWEB/Unit/Common/SingleLineInput';
import { Checkbox, Grid, Typography } from 'amn-ui-core';
import { isNull } from 'lodash';
import React, { useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  fieldStyle: {
    '& .MuiFilledInput-root': {
      backgroundColor: `${theme.palette.system.grayChip} !important`,
    },
    '&:hover .MuiFilledInput-root': {
      backgroundColor: `${theme.palette.system.silver} !important`,
    },
    '&:hover .MuiInputLabel-root': {
      color: `${theme.palette.system.primary} !important`,
    },
  },
}));

const isWholeNumber = value => Number(value) >= 0 && !isNull(value);

const WorkHistoryGap = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { control, watch, setValue } = useFormContext();
  const [isWithinTheLastFocused, setIsWithinTheLastFocused] = useState(false);
  const [isWithinTheLastYearsFocused, setIsWithinTheLastYearsFocused] = useState(false);
  const [withingLastYearError, setWithingLastYearError] = useState<boolean>(false);

  const setError = (value: string) => {
    const number = parseInt(value, 10);
    const responseValue = getValueForWithinLastYear(number);
    number <= 0 ? setWithingLastYearError(true) : setWithingLastYearError(false);
    return responseValue;
  };

  const isEntireWorkHistory = watch('workHistoryGap.entireWorkHistory');
  const withinTheLastDays = useWatch({
    control,
    name: 'workHistoryGap.withinTheLastDays',
  });
  const withinTheLastYears = useWatch({
    control,
    name: 'workHistoryGap.withinTheLastYears',
  });

  const handleCheckBoxChange = (onChange, isChecked: boolean) => {
    onChange(isChecked);
    setValue('workHistoryGap.withinTheLastYears', null);
  };
  return (
    <Grid container rowSpacing={2}>
      <Grid container item>
        <Grid item xs={6}>
          <Controller
            name={'workHistoryGap.withinTheLastDays'}
            control={control}
            render={({ onChange, ...rest }) => (
              <SingleLineInput
                id={'withinTheLast'}
                inputDefaultValue={null}
                inputLabel={t('facility.units.unitDetails.additionalInformation.workHistoryGap.noGapsGreaterThan')}
                inputName={'withinTheLastWorkHistoryGap'}
                inputValue={rest.value}
                handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  onChange(getValueForNumberField(event.target.value));
                }}
                endAdornmentValue={'days'}
                inputType={'number'}
                fieldStyle={classes.fieldStyle}
                showEndAdornment={isWithinTheLastFocused || isWholeNumber(withinTheLastDays)}
                shrinkLabel={isWithinTheLastFocused || isWholeNumber(withinTheLastDays)}
                handleWarning={() => setIsWithinTheLastFocused(true)}
                handleBlur={() => {
                  setIsWithinTheLastFocused(false);
                  rest.onBlur();
                }}
              />
            )}
          />
        </Grid>
      </Grid>

      <Grid container item rowSpacing={2}>
        <Grid item xs={6}>
          <Controller
            name={'workHistoryGap.withinTheLastYears'}
            control={control}
            render={({ onChange, ...rest }) => (
              <SingleLineInput
                id="withinTheLastYears"
                inputDefaultValue={null}
                inputLabel={t('facility.units.unitDetails.additionalInformation.workHistoryGap.withinTheLast')}
                inputName="withinTheLast"
                minInputValue={1}
                inputValue={isEntireWorkHistory ? '' : rest.value}
                handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  onChange(setError(event.target.value));
                }}
                endAdornmentValue="years"
                inputType="number"
                inputError={withingLastYearError}
                helperText={
                  withingLastYearError
                    ? t('facility.units.unitDetails.additionalInformation.workHistoryGap.validation')
                    : ''
                }
                fieldStyle={isEntireWorkHistory ? '' : classes.fieldStyle}
                isdisabled={isEntireWorkHistory}
                showEndAdornment={
                  isWithinTheLastYearsFocused || isWholeNumber(withinTheLastYears) || withingLastYearError
                }
                shrinkLabel={isWithinTheLastYearsFocused || isWholeNumber(withinTheLastYears) || withingLastYearError}
                handleWarning={() => setIsWithinTheLastYearsFocused(true)}
                handleBlur={() => {
                  setIsWithinTheLastYearsFocused(false);
                  rest.onBlur();
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name={'workHistoryGap.entireWorkHistory'}
            control={control}
            render={({ onChange, ...rest }) => (
              <Checkbox
                checked={rest.value}
                onChange={(e, data) => handleCheckBoxChange(onChange, data)}
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            )}
          />
          <Typography variant="body1" component="span">
            {t('facility.units.unitDetails.additionalInformation.workHistoryGap.entireWorkHistory')}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default WorkHistoryGap;
