import { missingField } from 'app/constants';
import { IExperience } from 'app/models/Placement/PlacementDetails';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Concatenate } from 'utils/string/string';
import { RequirementsRow } from '../../TableComponents/TableComponents';
import { ModalTableHeader } from 'app/models/Orders/OrderDetails';
import { AddOnContext } from '../../../PlacementSummaryTab';
import { placementSummaryActions } from '../../../../../../../../../store/redux-store/placement-summary/slice';
import styledComponent from 'styled-components';
import { TableSection } from '../../../customComponents/TableSection';
import { Avatar, Button, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { useRequirementFilter } from '../../../Helpers/requirementFilter';
import { selectServiceFailures } from 'store/redux-store/placement-summary/selectors';
import { usePromiseTracker } from 'react-promise-tracker';
import { getRequirementCheck } from '../../../helper';
import { UnOrderedList } from '@AMIEWEB/Common/SkillsetTree/SkillsetTree';
import { WhiteTooltip } from '@AMIEWEB/Common';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { CalculationType } from '@AMIEWEB/Placement/PlacementDetails/utils';

const SkillsHeader: ModalTableHeader[] = [
  { title: 'skillset', value: 'Skill Set' },
  { title: 'lastThreeYearsYearsOfExperience', value: 'Last 3 Years' },
  { title: 'totalYearsOfExperience', value: 'Total Experience' },
];

export function getExperienceData(years: number | undefined, months: number | undefined) {
  if ((years == null && months == null) || (years === 0 && months === 0)) return '';
  else if (years == null || years === 0) return `${months} mo`;
  else if (months == null || months === 0) return `${years} yr`;
  else return `${years} yr, ${months} mo`;
}

export function getExperienceDataWithFormating(years: number | undefined, months: number | undefined) {
  if ((years == null && months == null) || (years === 0 && months === 0)) return missingField;
  else if (years == null || years === 0) return `${months} mo.`;
  else if (months == null || months === 0) return `${years} yr`;
  else return `${years} yr, ${months} mo.`;
}

const useStyles = makeStyles()(theme => ({
  yoeCell: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: 2,
  },
  text: {
    fontSize: '14px',
  },
  titleText: { fontWeight: 500, fontSize: '14px' },
  button: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
    fontWeight: 450,
    textTransform: 'inherit',
    fontSize: 14,
    padding: 0,
    margin: 0,
    paddingLeft: '2px',
    'min-width': 'inherit',
    '&:hover': {
      background: 'none',
      textDecoration: 'underline',
    },
    whiteSpace: 'nowrap'
  },
  experienceModalText: {
    color: theme.palette.system.lightSkyBlue,
    fontWeight: 'bold',
  },
  blue: {
    color: `${theme.palette.common.white} !important`,
    backgroundColor: theme.palette.system.primary,
    width: '22px',
    height: '22px',
    fontSize: '14px',
    display: 'inline-flex',
    margin: '0 0.4rem 0 0.4rem',
  },
  green: {
    color: `${theme.palette.common.white} !important`,
    backgroundColor: theme.palette.framework.system.green,
    width: '22px',
    height: '22px',
    fontSize: '14px',
    display: 'inline-flex',
    margin: '0 0.4rem 0 0.4rem',
  },
  inlineDiv: {
    marginBottom: '1rem',
  },
}));

export const ExperienceRow = ({ filterKey, requirementFilter, req }) => {
  const { t } = useTranslation();

  const { order: orderReqItem, candidate: candidateReqItem } = req;
  const { groupedSkillsetRequirement: serviceFailed } = useSelector(selectServiceFailures);
  const { placementId } = useContext(AddOnContext);

  const { promiseInProgress } = usePromiseTracker({
    area: 'placement-submission-grouped-skillset-requirement',
    delay: 0,
  });

  const dispatch = useDispatch();
  const { display } = useRequirementFilter(filterKey, requirementFilter, orderReqItem?.type);

  return display ? (
    <RequirementsRow
      id="placement-submission-order-experience"
      title={t('placement.profile.summary.submission.order.experience.title')}
      requirementCell={orderReqItem ? <RowKey experience={orderReqItem} t={t} /> : missingField}
      candidateCell={<RowValue experience={candidateReqItem} t={t} />}
      loading={promiseInProgress}
      hasError={serviceFailed}
      onTryAgain={() => dispatch(placementSummaryActions.getGroupedSkillSetRequirements({ placementId }))}
    />
  ) : null;
};

const RowKey = ({ experience, t }) => {
  const { classes } = useStyles();
  const allowableMismatchesDetails = experience?.data?.allowableMismatches
    ? experience?.data?.allowableMismatches
      ?.map(data => Concatenate([data?.disciplineAbbr, data?.specialtyAbbr], ` - `))
      ?.join(', ')
    : missingField;

  const calculationTypeTooltipValue = experience?.data?.calculationTypeId ? (
    <span>
      {experience?.data?.calculationTypeId === CalculationType.IndividualExperience
        ? t('placement.profile.summary.submission.amn.experince.tooltip1')
        : t('placement.profile.summary.submission.amn.experince.tooltip2')}
    </span>
  ) : null;

  const experienceDataExists = experience?.data &&
    ((Number.isFinite(experience?.data.yearsOfExperience) && experience?.data.yearsOfExperience > 0) ||
      (Number.isFinite(experience?.data.monthsOfExperience) && experience?.data.monthsOfExperience > 0))

  return (
    <div style={{ width: "220px" }}>
      <TableSection
        items={[
          {
            icon: experience?.type ? getRequirementCheck(experience?.type) : null,
            primaryContent: (
              <>
                {experienceDataExists ? (
                  <div>
                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                      <div
                        style={{
                          display: 'inline-block',
                          paddingBottom: '6px',
                          maxWidth: ' 155px',
                          whiteSpace: 'pre-wrap',
                        }}
                      >
                        <span className={classes.text}>
                          {t('placement.profile.summary.submission.amn.experince.orderContentLine1')}
                        </span>
                        <span className={classes.titleText}>
                          {getExperienceDataWithFormating(
                            experience?.data?.yearsOfExperience,
                            experience?.data?.monthsOfExperience,
                          )}
                        </span>
                        <span className={classes.text}>
                          {t('placement.profile.summary.submission.amn.experince.orderContentLine2')}
                        </span>
                        <span className={classes.titleText}>
                          {t('placement.profile.summary.submission.amn.experince.orderContentLine3')}
                        </span>
                        <span className={classes.text}>
                          {t('placement.profile.summary.submission.amn.experince.orderContentLine4')}
                        </span>
                      </div>
                      <div>
                        <WhiteTooltip
                          title={
                            experience?.data?.isRequired
                              ? t('placement.common.required')
                              : t('placement.common.preferred')
                          }
                        >
                          <Avatar
                            variant={experience?.data?.isRequired ? 'primary' : 'green'}
                            className={`${experience?.data?.isRequired ? classes.blue : classes.green}`}
                          >
                            {experience?.data?.isRequired ? 'R' : 'P'}
                          </Avatar>
                        </WhiteTooltip>
                      </div>
                    </div>
                    <UnOrderedList>
                      <li>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <span className={classes.titleText}>
                            {t('placement.profile.summary.submission.amn.experince.skillsetTitle')}
                          </span>
                          <span className={classes.text}>
                            {Concatenate([experience?.data?.disciplineAbbr, experience?.data?.specialtyAbbr], ` - `)}
                          </span>
                        </div>
                      </li>
                      <li>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <span className={classes.titleText}>{`${t(
                            'placement.profile.summary.submission.amn.experince.allowableMismatchesTitle',
                          )}`}</span>
                          <span style={{ whiteSpace: 'break-spaces', fontSize: '14px' }}>
                            {allowableMismatchesDetails}
                          </span>
                        </div>
                      </li>
                    </UnOrderedList>
                  </div>
                ) : (
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {t('placement.profile.summary.submission.amn.experince.experienceTitle')}
                    <span style={{ paddingLeft: '3px' }}>{missingField}</span>
                    <div style={{ paddingLeft: '3px' }}>
                      <WhiteTooltip
                        title={
                          experience?.data?.isRequired ? t('placement.common.required') : t('placement.common.preferred')
                        }
                      >
                        <Avatar
                          variant={experience?.data?.isRequired ? 'primary' : 'green'}
                          className={`${experience?.data?.isRequired ? classes.blue : classes.green}`}
                        >
                          {experience?.data?.isRequired ? 'R' : 'P'}
                        </Avatar>
                      </WhiteTooltip>
                    </div>
                  </div>
                )}
                {experience?.data?.isNewGrad && (
                  <div style={{ display: 'flex', flexDirection: 'row', paddingTop: '5px' }}>
                    <span className={classes.text}>
                      {t('placement.profile.summary.submission.amn.experince.newGradTitle')}
                    </span>
                    <span className={classes.text}>
                      {t('placement.profile.summary.submission.amn.experince.accepted')}
                    </span>
                  </div>
                )}
                {experience?.data?.isClinicalFellowship && (
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <span className={classes.text}>
                      {t('placement.profile.summary.submission.amn.experince.clinicalFellowshipTitle')}
                    </span>
                    <span className={classes.text}>
                      {t('placement.profile.summary.submission.amn.experince.accepted')}
                    </span>
                  </div>
                )}

                {experience?.data?.calculationType && experienceDataExists && (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span className={classes.text}>
                      {t('placement.profile.summary.submission.amn.experince.calculationTypeTitle')}
                    </span>
                    <span className={classes.text}>
                      {experience?.data?.calculationType}
                      <WhiteTooltip arrow title={calculationTypeTooltipValue}>
                        <InfoOutlinedIcon style={{ color: '#005BBB', fontSize: '16px', paddingBottom: '2px' }} />
                      </WhiteTooltip>
                    </span>
                  </div>
                )}
              </>
            ),
          },
        ]}
      />
    </div>
  );
};

const RowValue = ({ experience, t }) => {
  const { classes } = useStyles();
  const { setModalProps } = useContext(AddOnContext);
  const {
    candidateYearsOfExpBySkillsetInfo: experiences = [],
    totalYearsOfExp: yoe = 0,
    totalMonthsOfExp: moe,
  } = experience?.data?.placementCandTotalExpBySkillset || {};

  const flattenSkillsetData = (skillsetInfo: IExperience[]) =>
    skillsetInfo.reduce(
      (resultArray, info) => [
        ...resultArray,
        {
          skillset: Concatenate([info.disciplineAbbr, info.specialtyAbbr], ', '),
          lastThreeYearsYearsOfExperience: (
            <>
              {getExperienceDataWithFormating(
                info.lastThreeYearsYearsOfExperience,
                info.lastThreeYearsMonthsOfExperience,
              )}
            </>
          ),
          totalYearsOfExperience: (
            <>{getExperienceDataWithFormating(info.totalYearsOfExperience, info.totalMonthsOfExperience)}</>
          ),
        },
      ],
      [] as any[],
    );

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div className={classes.yoeCell}>
        <Key variant="body1">
          <span>
            <b>{t('placement.profile.summary.submission.amn.experince.yearsOfExperienceTitle')}</b>
          </span>
        </Key>
        <Button
          type="button"
          variant="text"
          disableRipple
          disableTouchRipple
          disableFocusRipple
          onClick={() =>
            setModalProps?.({
              onChange: e => setModalProps?.(null),
              title: `${t('placement.profile.summary.submission.amn.experince.title')}`,
              subTitle: (
                <div>
                  <span className={classes.text}>
                    {t('placement.profile.summary.submission.amn.experince.yearsOfExperienceTitle')}
                  </span>

                  <span style={{ fontSize: '12px', fontWeight: 500 }}>{getExperienceData(yoe, moe)}</span>
                </div>
              ),
              columns: SkillsHeader,
              rows: flattenSkillsetData(experiences || []),
            })
          }
          disabled={!(yoe || moe)}
          classes={{ root: classes.button }}
        >
          {Number.isFinite(yoe) ? (moe ? getExperienceData(yoe, moe) : `${yoe} yr`) : missingField}
        </Button>
      </div>
      <UnOrderedList>
        {experience?.data?.isNewGrad && (
          <li>
            <span className={classes.text}>{t('placement.profile.summary.submission.amn.experince.newGrad')}</span>
          </li>
        )}
        {experience?.data?.isClinicalFellowship && (
          <li>
            <span className={classes.text}>
              {t('placement.profile.summary.submission.amn.experince.clinicalFellowship')}
            </span>
          </li>
        )}
      </UnOrderedList>
    </div>
  );
};

const Key = styledComponent(Typography)`
  padding-top: 1px;
`;
