import React from 'react';
import { Grid, ThemeProvider, StyledEngineProvider,useTheme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { CustomMultiLineTextField } from 'app/components/Order/OrderCreationForm/Common/CustomMultiLineTextField';
import { OrderConstants } from 'app/constants/OrderCreationForm';
import { useTranslation } from 'react-i18next';
import { InstructionText, UnitCardsTitle, UnitDetailsBody } from '../UnitDesign';
import { useDispatch, useSelector } from 'react-redux';
import { selectNewOrder } from '@AMIEWEB/Order/Store/OrderCreation.selectors';
import {
  orderCreationActions,
  requestOrderCreationUpdateOrderDetailsAction,
} from '@AMIEWEB/Order/Store/OrderCreation.actions';
import { Controller, useFormContext } from 'react-hook-form';
import { validateInternalNotes } from '@AMIEWEB/Order/OrderCreationForm/helper';
import { ff_order_ui_internalnote_char_count } from 'app/constants/FeatureFlags/Order/Keys';
import { useDecision } from '@optimizely/react-sdk';

interface Props {
  showMandetryStyle?: boolean;
}
const useStyles = makeStyles()((theme) => ({
  body: {
    paddingBottom: 12,
  },
  instructionalText: {
    paddingBottom: 16,
  },
}));
export const NewUnitExternalJobInternalNotes = (props: Props) => {
  const { showMandetryStyle } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme= useTheme();
  const { control } = useFormContext();
  const [internalNoteCharCountFlag] = useDecision(ff_order_ui_internalnote_char_count);
  const charCountFlagEnabled = internalNoteCharCountFlag?.enabled;

  const newOrderObj = useSelector(selectNewOrder);

  //@Team: @Monin  Remove these functions if they are not used
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedValue = { ...newOrderObj, [event.target.name]: event.target.value };
    dispatch(requestOrderCreationUpdateOrderDetailsAction({ data: updatedValue }));
  };

  //@Team: @Monin  Remove these functions if they are not used
  const handleChangeForm = (key, value) => {
    const updatedValue = { ...newOrderObj, [key]: value };
    dispatch(requestOrderCreationUpdateOrderDetailsAction({ data: updatedValue }));
  };

  React.useEffect(() => {
    dispatch(orderCreationActions.setInternalNotesFlag(charCountFlagEnabled));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [charCountFlagEnabled]);

  return (
    <div>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <UnitDetailsBody variant="outlined">
            <Grid container direction="column" spacing={1} className={classes.body}>
              <Grid item>
                <UnitCardsTitle>{t('order.createUnit.externalJobInternalNotes.title')}</UnitCardsTitle>
              </Grid>
              <Grid item>
                <Controller
                  control={control}
                  name={'externalJobDescription'}
                  render={({ ref, onChange, ...rest }) => (
                    <CustomMultiLineTextField
                      id={'createUnitExternalJobDescriptionTxt'}
                      value={rest.value}
                      name="externalJobDescription"
                      req={
                        showMandetryStyle !== true &&
                        (rest.value === undefined || rest.value === null || rest.value === '')
                          ? true
                          : false
                      }
                      label={
                        showMandetryStyle !== true &&
                        (rest.value === undefined || rest.value === null || rest.value === '')
                          ? `${t('order.createUnit.externalJobInternalNotes.externalJobDescription')} *`
                          : t('order.createUnit.externalJobInternalNotes.externalJobDescription')
                      }
                      onChange={newValue => {
                        onChange(newValue);
                        //handleChangeForm("internalNotes", newValue);
                      }}
                      placeholder={t('order.createUnit.externalJobInternalNotes.placeHolder')}
                    />
                  )}
                />
                <div className={classes.instructionalText}>
                  <InstructionText>{OrderConstants.UNIT_CREATION_EXTERNAL_JOB_HELPER}</InstructionText>
                </div>
              </Grid>
              <Grid item>
                <Controller
                  control={control}
                  name={'internalNotes'}
                  render={({ ref, onChange, ...rest }) => (
                    <CustomMultiLineTextField
                      id={'createUnitInternalNotesTxt'}
                      value={rest.value}
                      name="internalNotes"
                      label={t('order.createUnit.externalJobInternalNotes.internalNotes')}
                      onChange={e => {
                        onChange(e?.target?.value);
                        //handleChangeForm("internalNotes", newValue);
                      }}
                      placeholder={t('order.createUnit.externalJobInternalNotes.placeHolder')}
                      error={validateInternalNotes(charCountFlagEnabled, rest.value ?? '')}
                      helperText={
                        validateInternalNotes(charCountFlagEnabled, rest.value ?? '') ? t('order.maxCharLimit') : ''
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
          </UnitDetailsBody>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
};
