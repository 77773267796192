import { Employment } from 'app/models/Candidate/CandidateProfile';

export const getExperienceText = (employment: Employment) => {
  if (employment.yearsOfExperience == null && employment.monthsOfExperience == null)
    return 'Years of experience not added';
  const monthText = 'M';
  const yearText = 'Y';
  if (employment.yearsOfExperience === 0 && employment.monthsOfExperience !== 0)
    return ` ${employment.monthsOfExperience}${monthText}`;
  else if (employment.monthsOfExperience === 0 && employment.yearsOfExperience !== 0)
    return ` ${employment.yearsOfExperience}${yearText}`;
  else return ` ${employment.yearsOfExperience}${yearText} and ${employment.monthsOfExperience}${monthText}`;
};
