import { AppBar, Tab, Tabs } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { SmsType } from 'app/enums/Sms';
import { getCandidateById } from 'app/services/CandidateServices/CandidateServices';
import { GetUserLogs } from 'app/services/NotificationServices/NotificationService';
import { selectUser } from 'oidc/user.selectors';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { notificationDataActions } from 'store/redux-store/notification/notification.redux';
import { notificationSelection } from 'store/redux-store/notification/notification.selector';
import { CapitalizeEveryFirstLetter, Concatenate } from 'utils/string/string';
import { ChannelType, SendType, UseType, UserRoles } from '../Constants';
import { MessageCard } from './MessageCard';
import { NoResults } from './NoResuls';
import { selectCoveredInbox } from 'store/redux-store/user-coverage/selectors';
import { MessageDeliveryStatus } from './Types/enums';
import { IInboxReadStatusCommand } from 'app/models/Notification/Notification';

enum SearchTabTypes {
  contacts = 'c',
  messages = 'm',
}

enum SearchTabValues {
  contacts = 0,
  messages = 1,
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
      style={{ width: '380px' }}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles()(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
  tabs: {
    padding: '0 17px',
  },
}));

export const InboxSearchResults = props => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const {
    contactsResults,
    messagesResults,
    searchText,
    setShowNotificationBackdrop,
    setDisplaySearchResults,
    setSearchValue,
    searchInbox,
    OpenSmsModal,
    searchTypeTab,
  } = props;

  const [value, setValue] = useState<number>(
    searchTypeTab === SearchTabTypes.contacts ? SearchTabValues.contacts : SearchTabValues.messages,
  );

  const globalData = useSelector(notificationSelection);
  const { activeInbox } = useSelector(selectCoveredInbox);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const updateReadStatus = inboxMessage => {
    const readStatusPayload: IInboxReadStatusCommand = {
      sender: {
        phoneNumber: inboxMessage?.message?.sender?.phoneNumber,
      },
      contactId: inboxMessage?.message?.tos?.find(Boolean)?.contactId?.toString() || '',
      isRead: true,
      channel: ChannelType.sms,
      readOn: new Date(),
      phoneNumber: inboxMessage?.message?.tos?.find(Boolean)?.phoneNumber || '',
    };
    dispatch(notificationDataActions.updateMessageReadStatus(readStatusPayload));
  };
  const OpenSms = async inboxMessage => {
    if (!globalData.sms.open) {
      setShowNotificationBackdrop(true);
    }
    const smsLogsPayload = {
      contactId: inboxMessage?.message?.tos?.find(Boolean)?.contactId
        ? `${inboxMessage?.message?.tos?.find(Boolean)?.contactId}`
        : inboxMessage?.log?.message?.tos?.find(Boolean)?.contactId
        ? `${inboxMessage?.log?.message?.tos?.find(Boolean)?.contactId}`
        : '',
      pageSize: 20,
      pageNumber: 1,
      channel: ['SMS'],
      initiatedBy: activeInbox?.phoneNumber,
      contactPhone: inboxMessage?.message?.tos?.find(Boolean)?.phoneNumber
        ? `${inboxMessage?.message?.tos?.find(Boolean)?.phoneNumber}`
        : `${inboxMessage?.log?.message?.tos?.find(Boolean)?.phoneNumber}`,
    };
    let candidateDetails: any = {};
    if (inboxMessage?.message?.tos?.find(Boolean)?.contactId) {
      const brandDetails = inboxMessage.associatedRecords.find(x => x.name.toLowerCase() === 'brandid');
      const response = await getCandidateById(
        inboxMessage?.message?.tos?.find(Boolean)?.contactId,
        !brandDetails?.value || brandDetails?.value == 'undefined' ? '1' : brandDetails.value,
      );
      if (!response)
        dispatch(
          globalActions.setSnackBar({
            message: t('candidate.credentials.networkError'),
            severity: 'error',
          }),
        );
      candidateDetails = response;
    }
    const associatedRecord = [
      { name: 'travelerId', value: `${candidateDetails?.travelerId}` },
      { name: 'recruiterId', value: `${candidateDetails?.recruiter?.recruiterId}` },
      {
        name: 'recruiter',
        value: Concatenate([candidateDetails?.recruiter?.first, candidateDetails?.recruiter?.last], ' '),
      },
      { name: 'brandId', value: `${candidateDetails?.brandId}` },
    ];
    const smsData = {
      associatedRecords: associatedRecord,
      channel: ChannelType.sms,
      body: '',
      sender: inboxMessage.message.sender,
      sendType: SendType.one_to_one,
      tos: inboxMessage?.message?.tos,
      useType: UseType.Candidate,
      brand: user?.userInfo?.companyBrandName,
      brandId: user?.userInfo?.companyId?.toString(),
      userRole: UserRoles.recruitment,
      scrollToMessage: inboxMessage.id,
    };
    const chatHistoryPayload = Object.create({});
    chatHistoryPayload.candidateDetails = candidateDetails;
    const response = await GetUserLogs(smsLogsPayload);
    chatHistoryPayload.chats = response;
    const sms = globalData.sms;
    if (
      (sms.open || sms.minimized) &&
      (sms.data?.body?.length > 0 || (sms?.newNotes || '')?.length > 0 || sms.attachments !== undefined)
    ) {
      dispatch(notificationDataActions.setSmsInteraction({ open: true, minimized: false, readonly: false }));
      globalData.sms.data?.tos[0]?.contactId !== inboxMessage.travelerId
        ? dispatch(notificationDataActions.setSnackBarData({ channel: 'sms', manual: false, changeWarning: true }))
        : updateReadStatus(inboxMessage);
    } else {
      updateReadStatus(inboxMessage);
      dispatch(
        notificationDataActions.setNotificationData({
          ...globalData,

          sms: {
            ...globalData.sms,
            chatHistory: chatHistoryPayload,
            data: smsData,
            open: true,
            minimized: false,
            readonly: false,
            isBrandSelectionSkipped: undefined,
            sendType: SmsType.one_to_one,
          },
          snackbarData: null,
        }),
      );
    }
    setDisplaySearchResults(false);
    setSearchValue('');
  };

  const search = searchType => {
    searchInbox(searchText, true, searchType);
  };
  const openSMS = item => {
    OpenSmsModal(item);
    setDisplaySearchResults(false);
    setSearchValue('');
  };

  const handleChange = (event, newValue) => {
    const searchType = newValue === SearchTabValues.contacts ? SearchTabTypes.contacts : SearchTabTypes.messages;
    search(searchType);
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="full width tabs example"
          className={classes.tabs}
        >
          <Tab label={t(`Contacts`)} style={{ textTransform: 'capitalize', minWidth: 0 }} {...a11yProps(0)} />
          <Tab label={t(`Messages`)} style={{ textTransform: 'capitalize', minWidth: 0 }} {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        {contactsResults?.length > 0 ? (
          contactsResults?.map((item, index) => {
            const brandId =
              (item?.log && item?.log?.message?.tos?.[0]?.brandId) ||
              (item?.log?.associatedRecords &&
                item?.log?.associatedRecords?.find(data => data.name.toLowerCase() === 'brandid')?.value);
            return (
              <div key={index}>
                <MessageCard
                  onClick={() => openSMS(item)}
                  direction={item.log?.message.direction}
                  candidateID={item?.travelerId}
                  closeInboxPanel={() => null}
                  brandID={brandId}
                  phoneNumber={item?.log?.message?.tos.find(Boolean)?.phoneNumber}
                  candidateName={item?.name ? CapitalizeEveryFirstLetter(item?.name?.toLowerCase()) : ''}
                  subheader={`${item?.log?.message?.body || ''}`}
                  publishedOn={item?.log?.publishedOn}
                  unreadCount={item?.unreadLogs}
                  isLast={index === contactsResults.length - 1}
                  attachments={item?.log?.message?.attachmentUrls}
                  pinned={null}
                  handlePin={null}
                  pinning={false}
                  error={
                    item?.log?.response?.errors?.length > 0 || item?.log?.deliveryStatus === MessageDeliveryStatus.error
                  }
                />
              </div>
            );
          })
        ) : (
          <NoResults text={searchText} />
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {messagesResults?.length > 0 ? (
          messagesResults?.map((item, index) => {
            const brandId =
              (item?.log && item?.log?.message?.tos?.[0]?.brandId) ||
              (item?.log?.associatedRecords &&
                item?.log?.associatedRecords?.find(data => data.name.toLowerCase() === 'brandid')?.value);
            return (
              <div key={index}>
                <MessageCard
                  onClick={() => OpenSms(item)}
                  direction={item?.message.direction}
                  candidateID={item?.message?.tos[0]?.contactId || ''}
                  closeInboxPanel={() => null}
                  brandID={brandId}
                  candidateName={item?.message?.tos[0]?.name || item?.message?.tos[0]?.phoneNumber}
                  phoneNumber={item?.message?.tos[0]?.phoneNumber || undefined}
                  subheader={`${item?.message?.body || ''}`}
                  publishedOn={item?.publishedOn}
                  unreadCount={0}
                  isLast={index === messagesResults.length - 1}
                  attachments={item?.message?.attachmentUrls}
                  pinned={null}
                  handlePin={null}
                  pinning={false}
                  error={item?.response?.errors?.length > 0}
                />
              </div>
            );
          })
        ) : (
          <NoResults text={searchText} />
        )}
      </TabPanel>
    </div>
  );
};
