import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import baseAxiosInstance from 'utils/BaseApi';

export class MarginToolService {
  private placementBase: string;

  private cancellationToken = axios.CancelToken.source();

  private static currentInstance: MarginToolService;

  config?: AxiosRequestConfig = {};

  private constructor() {
    this.placementBase = globalThis?.app?.env?.REACT_APP_PLACEMENTS_SERVICE_BASE_URL;
  }

  static createInstance(): MarginToolService {
    const activeInstance = new MarginToolService();
    // activeInstance.cancellationToken = axios.CancelToken.source();
    // if (activeInstance.config) activeInstance.config.cancelToken = activeInstance.cancellationToken.token;
    return activeInstance;
  }

  static getInstance(): MarginToolService {
    if (!this.currentInstance) this.currentInstance = this.createInstance();
    return this.currentInstance;
  }

  static cancelActiveRequests() {
    this.currentInstance.cancellationToken.cancel('RequestCancellation');
    // this.currentInstance = null;
  }

  cancelRequests() {
    this.cancellationToken.cancel('RequestCancellation');
    return MarginToolService.getInstance();
  }

  cancelAndRevokeInstance() {
    this.cancellationToken.cancel('RequestCancellation');
    this.cancellationToken = axios.CancelToken.source();
    if (this.config) this.config.cancelToken = this.cancellationToken.token;
  }

  getMarginToolData = () =>
    baseAxiosInstance.get(`${this.placementBase}/placement/marginTool`).catch((error: AxiosError) => error.response);
}
