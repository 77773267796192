/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createRef, useEffect } from 'react';
import { PlacementsGrid } from './PlacementsGrid';
import { useDispatch, useSelector } from 'react-redux';
import {
  associatedPlacementActions,
  IRootFilter,
} from 'app/components/Placement/AssociatedPlacements/store/associatedPlacements.redux';
import { useTranslation } from 'react-i18next';
import { GridColumns } from '@mui/x-data-grid-pro';
import { selectAssociatedPlacements } from './store/associatedPlacements.selectors';
import { HomePage } from 'app/layout/pages/HomePage';
import { FormProvider, useForm } from 'react-hook-form';
import { gridStateActions } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.redux';
import { PlacementsFilters } from './PlacementsFilters';
import { FilterSearchChips } from '@AMIEWEB/GlobalSearch/Common/FilterSearchChips';
import { selectFilters, selectGridState } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.selectors';
import { getFilterRequest, revalidateChipSelection } from '@AMIEWEB/GlobalSearch/helper';
import { defaultPlacementFilters } from './FilterUtils';
import { userDevicePreferenceActions } from 'oidc/UserDevicePreference/userPreference.redux';
import { RootState } from 'types';

export const AssociatedPlacements = ({
  columns,
  setColumns,
  rootFilter,
  gridTag,
  ...props
}: {
  columns: GridColumns;
  setColumns: any;
  rootFilter: { [key in keyof IRootFilter]?: string[] };
  gridTag: string;
  [key: string]: any;
}) => {
  const { t } = useTranslation();
  const { items: rows, count } = useSelector(selectAssociatedPlacements);
  const dispatch = useDispatch();
  const formMethods = useForm({ defaultValues: defaultPlacementFilters, shouldUnregister: false });
  const { handleSubmit, getValues, setValue } = formMethods;
  const hiddenSubmitButton = createRef<HTMLButtonElement>();
  const { filterAttributes } = useSelector(selectGridState);
  const appliedFilterValues = useSelector(selectFilters);
  const [preferencesLoaded, setPreferencesLoaded] = React.useState<boolean>(false);
  const filterPreference = useSelector((state: RootState) => {
    return state.userPreferenceData.userGridPreferences.find(item => item.id === gridTag);
  });
  useEffect(() => {
    const selectedChipFilters = revalidateChipSelection(getValues(), filterAttributes?.selectedChipFilters || []);
    const filters = getFilterRequest(getValues(), selectedChipFilters);

    dispatch(
      gridStateActions.setFilterAttributes({
        filterObject: Object.entries(defaultPlacementFilters),
      }),
    );

    return () => {
      dispatch(associatedPlacementActions.reset());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveFilterPreferences = (values: any, newChipSelections?: any) => {
    const filters = values || appliedFilterValues?.filters;

    const preferenceData = {
      id: gridTag,
      value: {
        filtersApplied: filters,
        selectedChipFilters: newChipSelections,
      },
    };
    filters && dispatch(userDevicePreferenceActions.saveUserGridPreferenceAction(preferenceData));
  };

  const onApplyFilter = (values, selectedChips?: any) => {
    const selectedChipFilters =
      selectedChips || revalidateChipSelection(values, filterAttributes?.selectedChipFilters || []);
    dispatch(gridStateActions.setPageAttributes({ pageSize: 250, pageNumber: 1 }));
    dispatch(
      gridStateActions.setFilterAttributes({
        filterObject: Object.entries(values),
        selectedChipFilters,
      }),
    );
    setTimeout(() => {
      dispatch(associatedPlacementActions.setApplyFilter(true));
    }, 300);
    if (preferencesLoaded) {
      saveFilterPreferences(values, selectedChipFilters);
    }
  };

  useEffect(() => {
    const appliedFilters = filterPreference?.value?.filtersApplied;
    const selectedChips = filterPreference?.value?.selectedChipFilters;
    if (appliedFilters) {
      if (!preferencesLoaded) {
        setPreferencesLoaded(true);
        if (!!appliedFilters) {
          Object.keys(appliedFilters).forEach(key => {
            setValue(key, appliedFilters[key]);
          });
          onApplyFilter(appliedFilters, undefined);
          dispatch(
            gridStateActions.setFilterAttributes({
              filterObject: Object.entries(appliedFilters),
              selectedChipFilters: selectedChips,
            }),
          );
        }
      }
    } else {
      if (!preferencesLoaded) {
        setPreferencesLoaded(true);
      }
    }
  }, [filterPreference]);

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(filter => onApplyFilter(filter))}>
          <HomePage
            title=""
            filters={
              <PlacementsFilters
                defaultFilterValues={defaultPlacementFilters}
                saveFilterPreferences={saveFilterPreferences}
              />
            }
            table={
              <PlacementsGrid
                columns={columns}
                setColumns={setColumns}
                count={count}
                rows={rows}
                rootFilter={rootFilter}
                placementGridTag={
                  gridTag === 'FacilityPlacements' ? 'facilityPlacementMatch' : 'candidatePlacementMatch'
                }
              />
            }
            chips={<FilterSearchChips onApplyFilter={onApplyFilter} isPrefixLabelAvailable={true} />}
            showSidePanel={false}
            shrink={true}
          />
          <button ref={hiddenSubmitButton} style={{ display: 'none' }} type="submit" />
        </form>
      </FormProvider>
    </>
  );
};
