/* eslint-disable tss-unused-classes/unused-classes */
import { makeStyles } from 'tss-react/mui';
import { defaultFilterName, SearchType } from 'app/models/GlobalSearch/GlobalSearch';
import { getSearchFilterOptions } from 'app/services/SharedServices/SharedServices';
import { userDevicePreferenceActions } from 'oidc/UserDevicePreference/userPreference.redux';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { trackPromise } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { SessionKey, removeSessionValue, StorageType } from 'utils/customHooks/sessionStorage/sessionHelpers';
import { CustomFilter } from '../../../ComponentLibrary/Filter';
import { getMultiSelectOptions } from '../helper';
import { FacilitiesFilter, PositionOpts } from './FilterUtils';
import _ from 'lodash';
import { RootState } from 'types';
import { isNullOrUndefined } from 'app/helpers/objectHelpers';
import { Collapse, Grid } from 'amn-ui-core';
import { SearchPageIcons } from 'app/ComponentLibrary/Filter/SearchPageIcons';
import { selectFilters, selectGridFiltersExpanded } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.selectors';
import { gridStateActions } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.redux';

const useStyles = makeStyles()({
  businessRelationship: {
    width: 170,
  },
  facilityStatus: {
    width: 145,
  },
  accountManager: {
    width: 165,
  },
  fillterCollapse: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '3px',
  },
  collapse: {
    paddingTop: '4px',
  },
});

export const FacilitySearchFilters = ({ defaultFilterValues }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { reset } = useFormContext();
  const gridStateFilters = useSelector(selectFilters);
  const filterPreferences: any = useSelector((state: RootState) => {
    return state.userPreferenceData.userGridPreferences.find(item => item.id === 'GlobalSearchGrid-facility');
  });
  const gridSpecs = useRef(
    new FacilitiesFilter(
      {
        facilityStatusesOpts: [],
        divisionOpts: [],
        statesOpts: [],
        facilityOpts: [],
        accountManagerOpts: [],
        positionOpts: [...PositionOpts(t)],
      },
      t,
      classes,
      gridStateFilters,
    ),
  );

  const [fields, setFields] = useState(gridSpecs.current.getFacilityFilterSpecs());

  const fetchFilterSpecs = async () => {
    // To-do : need to move to saga
    getSearchFilterOptions('facilitystatuses', true).then(res => {
      gridSpecs.current.filterSpecs.facilityStatusesOpts = getMultiSelectOptions(res || [], { withoutAllOption: true });
      const fieldsSchema = gridSpecs.current.getFacilityFilterSpecs();
      setFields(fieldsSchema);
    });
    getSearchFilterOptions('divisions', true).then(res => {
      gridSpecs.current.filterSpecs.divisionOpts = getMultiSelectOptions(res || [], { withoutAllOption: true });
      const fieldsSchema = gridSpecs.current.getFacilityFilterSpecs();
      setFields(fieldsSchema);
    });
    getSearchFilterOptions('states', true).then(res => {
      gridSpecs.current.filterSpecs.statesOpts = getMultiSelectOptions(res || [], { withoutAllOption: true });
      const fieldsSchema = gridSpecs.current.getFacilityFilterSpecs();
      setFields(fieldsSchema);
    });
    getSearchFilterOptions('accountmanagers', true).then(res => {
      gridSpecs.current.filterSpecs.accountManagerOpts = getMultiSelectOptions(res || [], { withoutAllOption: true });
      const fieldsSchema = gridSpecs.current.getFacilityFilterSpecs();
      setFields(fieldsSchema);
    });
    getSearchFilterOptions('facilities', true).then(res => {
      gridSpecs.current.filterSpecs.facilityOpts = getMultiSelectOptions(res || []);
      const fieldsSchema = gridSpecs.current.getFacilityFilterSpecs();
      setFields(fieldsSchema);
    });
    getSearchFilterOptions('ethnicity', true).then(res => {
      gridSpecs.current.filterSpecs.ethnicityOpts = getMultiSelectOptions(res || []);
      const fieldsSchema = gridSpecs.current.getFacilityFilterSpecs();
      setFields(fieldsSchema);
    });
    getSearchFilterOptions('hospitalSettings', true).then(res => {
      gridSpecs.current.filterSpecs.hospitalSettingOpts = getMultiSelectOptions(res || [], { withoutAllOption: true });
      const fieldsSchema = gridSpecs.current.getFacilityFilterSpecs();
      setFields(fieldsSchema);
    });
    getSearchFilterOptions('requirementType', true).then(res => {
      gridSpecs.current.filterSpecs.requirementTypeOpts = getMultiSelectOptions(res || [], { withoutAllOption: true });
      const fieldsSchema = gridSpecs.current.getFacilityFilterSpecs();
      setFields(fieldsSchema);
    });
    getSearchFilterOptions('facilityType', true).then(res => {
      gridSpecs.current.filterSpecs.facilityTypeOpts = getMultiSelectOptions(res || []);
      const fieldsSchema = gridSpecs.current.getFacilityFilterSpecs();
      setFields(fieldsSchema);
    });
    getSearchFilterOptions('staffingVendor', true).then(res => {
      gridSpecs.current.filterSpecs.staffingVendorOpts = getMultiSelectOptions(res || [], { withoutAllOption: true });
      const fieldsSchema = gridSpecs.current.getFacilityFilterSpecs();
      setFields(fieldsSchema);
    });
    getSearchFilterOptions('technologyVendor', true).then(res => {
      gridSpecs.current.filterSpecs.techVendorOpts = getMultiSelectOptions(res || [], { withoutAllOption: true });
      const fieldsSchema = gridSpecs.current.getFacilityFilterSpecs();
      setFields(fieldsSchema);
    });
  };

  useEffect(() => {
    updateGridState(gridStateFilters);
  }, [gridStateFilters]);

  const updateGridState = useCallback(async (gridStateFilters: any) => {
    gridSpecs.current.gridStateFilters = gridStateFilters;
    const fieldsSchema = gridSpecs.current.getFacilityFilterSpecs();
    setFields(fieldsSchema);
  }, []);

  const resetFilter = () => {
    const foundDefaultIndex = (filterPreferences.value?.filterAttributes?.filterSet || [])?.find(
      item => item.name === defaultFilterName || item.isDefault,
    );
    const filterPrefClone = _.cloneDeep(filterPreferences);

    if (!foundDefaultIndex) {
      filterPrefClone.value.selectedFilter = null;
    } else {
      // Default filter found, select it
      filterPrefClone.value.selectedFilter = foundDefaultIndex;
    }
    removeSessionValue(SessionKey['GlobalSearchGrid-facility'], StorageType.sessionStorage);
    dispatch(userDevicePreferenceActions.saveUserGridPreferenceAction(filterPrefClone));
    reset(!isNullOrUndefined(foundDefaultIndex) ? Object.fromEntries(foundDefaultIndex?.value) : null);
  };

  const filtersExpanded = useSelector(selectGridFiltersExpanded);
  const handleChange = () => {
    dispatch(gridStateActions.setFiltersExpanded(!filtersExpanded));
  };

  useEffect(() => {
    trackPromise(fetchFilterSpecs(), 'global-search-call');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container wrap="nowrap" display="flex">
      <Grid item flex="1 1 auto">
        <Collapse timeout={200} in={filtersExpanded} collapsedSize={45} className={classes.collapse}>
          <CustomFilter
            fields={fields}
            justifyContent={'flex-start'}
            version2
            resetBtn
            analytics={{
              caller: t('search.globalSearch.globalSearchFilter'),
              onApply: true,
              properties: {
                category: SearchType.facility,
              },
            }}
          />
        </Collapse>
      </Grid>
      <Grid item flex="0 0 auto" className={classes.fillterCollapse}>
        <SearchPageIcons
          resetlbutton
          funnelButton
          checked={filtersExpanded}
          onResetForm={() => resetFilter()}
          onAdvancedFilterClick={() => handleChange()}
        />
      </Grid>
    </Grid>
  );
};
