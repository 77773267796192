import { Grid, Typography } from 'amn-ui-core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { theme } from 'styles/global-styles';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { Controller, useFormContext } from 'react-hook-form';
import { useSkillHeaderStyles } from './Experience.styles';

export const SkillHeader = ({
  groupIndex,
  skillSetIndex,
  onDeleteSkillSet,
}: {
  skillSetIndex: number;
  groupIndex: number;
  onDeleteSkillSet: () => void;
}) => {
  const { t } = useTranslation();
  const { classes } = useSkillHeaderStyles();
  const [hover, setHover] = React.useState(false);
  const { control, watch, getValues, setValue } = useFormContext();

  const onMouseOver = () => {
    setHover(!hover);
  };

  const isPrimary = getValues(`skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].isPrimary`);
  const changeValue = () => {
    setValue(`skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].isPrimary`, !isPrimary);
    if (!isPrimary) {
      const skillsets = watch(`skillSetGroup.groups[${groupIndex}].skillsets`);
      skillsets.forEach((value, index) => {
        if (index != skillSetIndex) {
          setValue(`skillSetGroup.groups[${groupIndex}].skillsets[${index}].isPrimary`, false);
        }
      });
    }
  };

  const renderSection = () => {
    return (
      <div onClick={e => changeValue()}>
        {isPrimary || hover ? (
          <StarIcon
            sx={{
              color: theme.palette.secondary.main,
              cursor: 'pointer',
            }}
          />
        ) : (
          <StarBorderIcon
            sx={{
              color: theme.palette.framework.system.tertiaryGrey,
              cursor: 'pointer',
            }}
          />
        )}
        {
          <Typography
            component={'span'}
            className={`${classes.font} ${isPrimary || hover ? classes.primarySkillSetText : ''}`}
          >
            {isPrimary
              ? t('facility.units.unitDetails.unitRequirements.primarySkillSetCaps')
              : t('facility.units.unitDetails.unitRequirements.setPrimarySkillSet')}
          </Typography>
        }
      </div>
    );
  };

  return (
    <Grid container sx={{ justifyContent: 'space-between' }}>
      <Grid item sx={{ fontWeight: '500', fontSize: '16px !important' }}>
        {t('facility.units.unitDetails.unitRequirements.skillSet', { skillCount: skillSetIndex + 1 })}
      </Grid>
      <Grid item display="flex" alignItems="center">
        <Controller
          name={`skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].isPrimary`}
          control={control}
          defaultValue={isPrimary}
          render={({ onChange, ...rest }) => (
            <Grid item onMouseEnter={onMouseOver} onMouseLeave={onMouseOver} display="flex" className={classes.font}>
              {renderSection()}
            </Grid>
          )}
        />
        {!isPrimary && skillSetIndex > 0 && (
          <Grid item className={classes.deleteSection} onClick={onDeleteSkillSet}>
            <div>
              <DeleteOutlinedIcon />
              <Typography component={'span'} className={classes.fontParams} variant="body2">
                {t('facility.units.unitDetails.unitRequirements.deleteSkillSet')}
              </Typography>
            </div>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
