import React, { useRef, useState } from 'react';
import { trackEvent } from 'app-insights/appInsightsTracking';
import { SignalREventType } from 'app/enums/Common';
import { IEmailNotDeliveredNotification } from 'app/models/Email/IEmail';
import { selectUser } from 'oidc/user.selectors';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { EmailNotDeliveredPopup } from './EmailNotDeliveredPopup';
import EmailDeliveryStatus, {
  DeliveryStatus,
  IDeliveryStatusGridData,
  IDeliveryStatusProps,
  ReciepientType,
} from '@AMIEWEB/Notification/EmailDeliverStatus/EmailDeliveryStatus';
import { GetMomentOfDate } from 'utils/dates/moment';
import { missingField } from 'app/constants';
import { HubEventType } from '@AMIEWEB/Notification/Constants';
import { GetUserLogs } from 'app/services/NotificationServices/NotificationService';
import { StyledToastContainer } from '../utils';
import ClearIcon from '@mui/icons-material/Clear';
import { makeStyles } from 'tss-react/mui';
import { useDecision } from '@optimizely/react-sdk';
import { ff_notifications_ui_activity_feed_split } from 'app/constants/FeatureFlags/Tasks/Notifications/Keys';
import { taskDetailsActions } from '@AMIEWEB/Tasks/store/Tasks.redux';
import { getNotificationType } from '../Common_v2/utils';
import { NotificationCategory } from 'app/models/Tasks/Tasks';
import { gridSelectionActions } from '@AMIEWEB/Common/Grid/GridSelection/GridSelection.redux';
import { AlertFeed } from '../Common_v2/Feeds/AlertFeed';
import { getNotificationData } from './FormatData';
import { selectDismissNotificationId } from '@AMIEWEB/Tasks/store/Tasks.selectors';

const useStyles = makeStyles()(theme => ({
  closeIcon: {
    display: 'block',
    height: '27px',
    width: '27px',
    background: theme.palette.framework.system.white,
    border: '1px solid #E2DFDF',
    borderRadius: '15px',
    padding: '3px',
    boxShadow: `0px 0px 6px ${theme.palette.system.lightBlack}`,
    cursor: 'pointer',
    transition: '0.3s ease',
    position: 'absolute',
    left: '-12px',
    top: '-12px',
  },
  fixedPos: {
    position: 'fixed',
  },
}));

const isEmailNotified = (event: string) => {
  let ev = event.toLowerCase();
  return (
    ev === HubEventType.EmailBlocked ||
    ev === HubEventType.EmailBounced ||
    ev === HubEventType.EmailDelivered ||
    ev === HubEventType.EmailDropped ||
    ev === HubEventType.EmailProcessed ||
    ev === HubEventType.EmailUnsubscribed ||
    ev === HubEventType.EmailSpam
  );
};

export const EmailDeliveryNotifications = () => {
  const user = useSelector(selectUser);
  const dismissedNotificationId = useSelector(selectDismissNotificationId);
  const [activityFeedRefactoring] = useDecision(ff_notifications_ui_activity_feed_split);
  const [openEmailDeliveryModal, setEmailDeliveryModal] = useState(false);
  const emailDeliveryData = useRef<IDeliveryStatusProps>();
  const { classes } = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    if (dismissedNotificationId) {
      toast.dismiss(dismissedNotificationId);
      dispatch(taskDetailsActions.setDismissNotificationId(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dismissedNotificationId]);

  const closeNotification = (e, closeToast) => {
    closeToast();
    e.stopPropagation();
  };

  const CloseButton = ({ closeToast }) => {
    return <ClearIcon className={classes.closeIcon} onClick={e => closeNotification(e, closeToast)} />;
  };

  useEffect(
    () => {
      const hubConnection = user?.userInfo?.hubConnection;
      if (hubConnection) {
        hubConnection.on('Broadcast', (receivedUser, eventName, log) => {
          trackEvent({
            type: 'event',
            name: `SignalR Hub`,
            properties: {
              NotificationType: SignalREventType.EmailDelivery,
              UserId: receivedUser,
              CorrelationId: log?.correlationId,
              Log: log,
              Description: `Received email delivery message notification for user: ${receivedUser}`,
            },
          });

          if (isEmailNotified(eventName)) {
            if (activityFeedRefactoring?.enabled) {
              //TODO: change the log type to get channel and use type
              dispatch(
                taskDetailsActions.incrementNotificationCount({
                  notificationType: getNotificationType({
                    useType: log?.useType,
                    useSubType: log?.useSubType,
                    channelType: NotificationCategory.Email,
                  }),
                }),
              );
            }
            const emailStatusLog = log as IEmailNotDeliveredNotification;
            const formattedNotification = getNotificationData({
              ...log.emailMessage,
              isNotificationUnread: true,
            });
            toast(
              activityFeedRefactoring?.enabled ? (
                <AlertFeed
                  key={0}
                  index={0}
                  notifyData={formattedNotification}
                  activityFeedRefactoringFlag={activityFeedRefactoring?.enabled}
                  pushNotification={true}
                />
              ) : (
                <EmailNotDeliveredPopup
                  hasAttachments={false}
                  publishedOn={emailStatusLog.publishedOn}
                  recipientAddr={emailStatusLog.recipient}
                  status={emailStatusLog.event}
                  subject={emailStatusLog.subject}
                  showActionPanel={false}
                  isUnread
                />
              ),
              {
                toastId: log.emailMessage.id,
                containerId: 'EmailDeliveryNotification',
                position: 'top-right',
                closeButton: true,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoClose: 5000,
                onClick: () => {
                  if (activityFeedRefactoring?.enabled) return;
                  GetUserLogs({ messageId: emailStatusLog.messageId }).then(res => {
                    const tblData: IDeliveryStatusGridData[] = [];
                    let idx = 0;
                    const tos = (res.results[0].message.tos as any[]).map<IDeliveryStatusGridData>(d => ({
                      deliveryStatus: d.deliveryStatus ? d.deliveryStatus : DeliveryStatus.Pending,
                      email: d.email,
                      error: 0,
                      id: `${idx++}`,
                      name: d?.name?.length > 1 ? d.name : missingField,
                      reciepientType: ReciepientType.Tos,
                    }));
                    tblData.push(...tos);
                    const ccs = (res.results[0].message.ccs as any[]).map<IDeliveryStatusGridData>(d => ({
                      deliveryStatus: d.deliveryStatus ? d.deliveryStatus : DeliveryStatus.Pending,
                      email: d.email,
                      error: 0,
                      id: `${idx++}`,
                      name: d?.name?.length > 1 ? d.name : missingField,
                      reciepientType: ReciepientType.Ccs,
                    }));
                    tblData.push(...ccs);
                    const bccs = (res.results[0].message.bccs as any[]).map<IDeliveryStatusGridData>(d => ({
                      deliveryStatus: d.deliveryStatus ? d.deliveryStatus : DeliveryStatus.Pending,
                      email: d.email,
                      error: 0,
                      id: `${idx++}`,
                      name: d.name?.length > 1 ? d.name : missingField,
                      reciepientType: ReciepientType.Bccs,
                    }));
                    tblData.push(...bccs);
                    const moment =
                      GetMomentOfDate(res.results[0].publishedOn).format('MM/DD/YYYY') +
                      ' ' +
                      'at' +
                      ' ' +
                      GetMomentOfDate(res.results[0].publishedOn).format('LT');
                    const deliveryModalProps: IDeliveryStatusProps = {
                      id: res.results[0].id,
                      body: res.results[0].message.body,
                      open: true,
                      subject: res.results[0].message.subject,
                      sentDate: [{ date: moment, logid: emailStatusLog.messageId }],
                      tableData: tblData,
                      closeModal: () => {
                        setEmailDeliveryModal(false);
                        dispatch(gridSelectionActions.reset());
                      },
                    };
                    emailDeliveryData.current = deliveryModalProps;
                    setEmailDeliveryModal(true);
                  });
                },
              },
            );

            return;
          }
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return (
    <>
      <div className={classes.fixedPos}>
        <StyledToastContainer
          enableMultiContainer
          containerId={'EmailDeliveryNotification'}
          limit={3}
          closeButton={CloseButton}
        />
      </div>
      <EmailDeliveryStatus {...emailDeliveryData.current} open={openEmailDeliveryModal} />
    </>
  );
};
