import { ClickAwayListener, IconButton, MenuItem, Popper } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React, { useState, useEffect } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTranslation } from 'react-i18next';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { missingField } from 'app/constants';
import { globalSearchActions } from 'store/redux-store/global-search/slice';
import { useDispatch, useSelector } from 'react-redux';
import { taskDetailsActions } from 'app/components/Tasks/store/Tasks.redux';
import { selectCueNotesList } from 'app/components/Tasks/store/Tasks.selectors';
import { WhiteTooltip } from 'app/components/Common/Tooltips/WhiteTooltip';
import { placementDetailReducer, placementDetailsAction, placementDetailSliceKey } from 'store/redux-store/placement-details/slice';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { placementDetailsSaga } from 'store/redux-store/placement-details/saga';

const useStyles = makeStyles()({
  truncation: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  moreIcon: {
    padding: '0px !important',
  },
  menu: {
    width: '114px',
    height: '80px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 2px 2px #0000004D',
    borderRadius: '4px',
    opacity: '1',
    position: 'relative',
    marginRight: '3rem',
    marginTop: '-1rem',
    zIndex: 999,
  },
  menuItem: {
    width: '114px',
    height: '40px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    opacity: '1',
    borderRadius: '4px',
  },
});

interface GridCellProps {
  [key: string]: any;
}

const PlacementSearchCueTextRenderer = React.memo(function PlacementSearchCueTextRenderer(cellParams: GridCellProps) {
  const { t } = useTranslation();
  useInjectReducer({ key: placementDetailSliceKey, reducer: placementDetailReducer });
  useInjectSaga({ key: placementDetailSliceKey, saga: placementDetailsSaga });
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const cueNotesList = useSelector(selectCueNotesList);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [show, setShow] = useState<Boolean>(false);
  const [displayMenu, setDisplayMenu] = useState<boolean>(false);
  const [isReadOnly, setIsReadOnly] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [cueData, setCueData] = useState<string>('');

  const handleMenuClose = (event: React.MouseEvent<EventTarget>) => {
    setDisplayMenu(false);
  };

  const handleClick = event => {
    setAnchorEl(event?.currentTarget);
    setDisplayMenu(true);
  };

  const fetchPlacementHeaderDetails = (placementId) => {
    dispatch(placementDetailsAction.getHeaderDetailsAction({ placementId }));
  };

  const setDrawerData = (rowData, readOnly: boolean) => {
    fetchPlacementHeaderDetails(rowData?.placementId);
    let drawerData = Object.assign({}, rowData);
    drawerData.isReadOnly = readOnly ?? false;
    setIsReadOnly(readOnly);
    dispatch(globalSearchActions.setDrawerData({ open: true, data: drawerData }));
  };

  const setCueNoteReplyParent = mostRecentCueNote => {
    if (mostRecentCueNote) {
      const parent = {
        cueNoteLegacyTaskId: mostRecentCueNote?.originalTaskId,
        sentById: mostRecentCueNote?.senderId,
        sentByFirstName: mostRecentCueNote?.sentByFirstName,
        sentByLastName: mostRecentCueNote?.sentByLastName,
        recipientId: mostRecentCueNote?.recipientId || 0,
        recipientFirstName: mostRecentCueNote?.recipientFirstName || '',
        recipientLastName: mostRecentCueNote?.recipientLastName || '',
        sentDate: mostRecentCueNote?.sentDate,
        text: mostRecentCueNote?.text,
      };
      dispatch(taskDetailsActions.setCueNoteReplyParent(parent));
    }
  };

  useEffect(() => {
    if (cueNotesList && !isReadOnly) {
      setCueNoteReplyParent(cueNotesList[cueNotesList.length - 1]);
    } else {
      setCueNoteReplyParent(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cueNotesList, isReadOnly]);

  useEffect(() => {
    if (cellParams?.row?.cueNote !== missingField) {
      if (cellParams?.row?.cueNote?.length > 25) {
        setHasMore(true);
        if (cellParams?.row?.cueNote?.length > 100) {
          setCueData(`${cellParams?.row?.cueNote?.substring(0, 100)} ...`);
        } else {
          setCueData(`${cellParams?.row?.cueNote}`);
        }
      } else {
        setHasMore(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cellParams?.row]);

  return (
    <React.Fragment>
      {cellParams?.row?.cueNote !== missingField ? (
        <div
          style={{ display: 'flex', width: '100%', justifyContent: 'space-between', overflow: 'hidden' }}
          onMouseEnter={async () => {
            cellParams?.row?.cueNote && setShow(true);
          }}
          onMouseLeave={async () => {
            cellParams?.row?.cueNote && setShow(false);
          }}
        >
          {hasMore ? (
            <WhiteTooltip arrow title={cueData}>
              <div className={classes.truncation}>{cellParams?.row?.cueNote}</div>
            </WhiteTooltip>
          ) : (
            <div className={classes.truncation}>{cellParams?.row?.cueNote}</div>
          )}
          <div>
            {show && (
              <>
                <IconButton color="primary" className={classes.moreIcon} onClick={handleClick} size="small">
                  <MoreVertIcon fontSize="small" />
                </IconButton>
                <Popper className={classes.menu} open={displayMenu} anchorEl={anchorEl} role={undefined} placement={'bottom-end'}>
                  <ClickAwayListener onClickAway={() => handleMenuClose}>
                    <>
                      <MenuItem
                        className={classes.menuItem}
                        onClick={e => {
                          setDrawerData(cellParams?.row, true);
                          handleMenuClose(e);
                        }}
                      >
                        {t('notification.taskSidePanel.cueNotes.view')}
                      </MenuItem>
                      <MenuItem
                        className={classes.menuItem}
                        onClick={e => {
                          setDrawerData(cellParams?.row, false);
                          handleMenuClose(e);
                        }}
                      >
                        {t('notification.taskSidePanel.cueNotes.replyCueBtn')}
                      </MenuItem>
                    </>
                  </ClickAwayListener>
                </Popper>
              </>
            )}
          </div>
        </div>
      ) : (
        missingField
      )}
    </React.Fragment>
  );
});

export const PlacementSearchCueText = (params: GridCellParams) => {
  return <PlacementSearchCueTextRenderer {...params} />;
};
