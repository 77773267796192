/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Grid, ThemeProvider, StyledEngineProvider,useTheme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { reqPref } from 'app/enums/Unit';
import { ReqPrefSection } from '../Common/ReqPrefSection';
import { CustomDivider, UnitCardsTitle, UnitDetailsBody } from '../UnitDesign';
import { ComboSection } from './ComboSection';
import { useTranslation } from 'react-i18next';
import { FlexibleScheduleType } from 'app/models/Unit/CreateUnit';
import { SimpleCheckbox } from '../Common/SimpleCheckbox';
import { Controller, useFormContext } from 'react-hook-form';

interface Props {
  disableEdit?: boolean;
  hasRadio?: boolean;
}

const useStyles = makeStyles()((theme) => ({
  comboSection: {
    paddingTop: 17,
  },
  holidayFieldWidth: {
    width: '110%',
  },
  comboSectionDivider: {
    margin: '0px 12px 0px 12px',
    opacity: 1,
    border: '1px solid rgb(209,209,209)',
  },
}));
export const NewFlexibleSchedule = (props: Props) => {
  const { disableEdit } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const theme= useTheme();

  const { control, getValues, watch, setValue, reset } = useFormContext();
  const watchValue = watch('flexibleSchedule');
  const showPanel = watchValue !== undefined && watchValue !== null;
  const [showTextField, setShowTextfield] = React.useState<{
    isWorkDuringWeekend: boolean;
    isWorkDuringHoliday: boolean;
  }>({
    isWorkDuringHoliday: watchValue?.workDuringWeekend,
    isWorkDuringWeekend: watchValue?.workDuringHoliday,
  });

  const handleCheckBoxChangeX = (event: React.ChangeEvent<HTMLInputElement>) => {
    //const existingValues = getValues();
    if (event.target.checked) {
      const updatedValue = {
        isRequired: false,
        workDuringWeekend: null,
        workDuringHoliday: null,
      } as FlexibleScheduleType;
      setValue('flexibleSchedule', updatedValue);
      /*reset({
        ...existingValues,
        flexibleSchedule: updatedValue,
      });*/
    } else {
      const existingValues = getValues();
      setValue('flexibleSchedule', null);
      reset({
        ...existingValues,
        flexibleSchedule: null,
      });
    }
  };

  const validateControl = (value: number) => {
    return value <= 99;
  };

  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const existingValues = getValues();
    if (!event.target.checked) {
      if (event.target.name === 'isWorkDuringWeekend') {
        const updatedValue = {
          isRequired: existingValues.flexibleSchedule.isRequired,
          workDuringWeekend: null,
          workDuringHoliday: existingValues.flexibleSchedule.workDuringHoliday,
        } as FlexibleScheduleType;
        setValue('flexibleSchedule', updatedValue);
        /*reset({
          ...existingValues,
          flexibleSchedule: updatedValue,
        });*/
      } else if (event.target.name === 'isWorkDuringHoliday') {
        const updatedValue = {
          isRequired: existingValues.flexibleSchedule.isRequired,
          workDuringWeekend: existingValues.flexibleSchedule.workDuringWeekend,
          workDuringHoliday: null,
        } as FlexibleScheduleType;
        setValue('flexibleSchedule', updatedValue);
        /*reset({
          ...existingValues,
          flexibleSchedule: updatedValue,
        });*/
      }
    }
    setShowTextfield({
      ...showTextField,
      [event.target.name]: event.target.checked,
    });
  };

  const handleChange = event => {
    const value = event.target.value;
    const existingValues = getValues();
    if (event.target.name === 'workDuringWeekend') {
      if (isNaN(value) || value == '') {
        const updatedValue = {
          isRequired: existingValues.flexibleSchedule.isRequired,
          workDuringWeekend: null,
          workDuringHoliday: existingValues.flexibleSchedule.workDuringHoliday,
        } as FlexibleScheduleType;
        setValue('flexibleSchedule', updatedValue);
        /*reset({
          ...existingValues,
          flexibleSchedule: updatedValue,
        });*/
      } else if (validateControl(parseInt(value))) {
        const valueNum = parseInt(value);
        const updatedValue = {
          isRequired: existingValues.flexibleSchedule.isRequired,
          workDuringWeekend: valueNum >= 0 ? valueNum : 0,
          workDuringHoliday: existingValues.flexibleSchedule.workDuringHoliday,
        } as FlexibleScheduleType;
        setValue('flexibleSchedule', updatedValue);
        /*reset({
          ...existingValues,
          flexibleSchedule: updatedValue,
        });*/
      }
    } else if (event.target.name === 'workDuringHoliday') {
      if (isNaN(value) || value == '') {
        const updatedValue = {
          isRequired: existingValues.flexibleSchedule.isRequired,
          workDuringWeekend: existingValues.flexibleSchedule.workDuringWeekend,
          workDuringHoliday: null,
        } as FlexibleScheduleType;
        setValue('flexibleSchedule', updatedValue);
        /*reset({
          ...existingValues,
          flexibleSchedule: updatedValue,
        });*/
      } else if (validateControl(parseInt(value))) {
        const valueNum = parseInt(value);
        const updatedValue = {
          isRequired: existingValues.flexibleSchedule.isRequired,
          workDuringWeekend: existingValues.flexibleSchedule.workDuringWeekend,
          workDuringHoliday: valueNum >= 0 ? valueNum : 0,
        } as FlexibleScheduleType;
        setValue('flexibleSchedule', updatedValue);
        /*reset({
          ...existingValues,
          flexibleSchedule: updatedValue,
        });*/
      }
    }
  };

  return (
    <div>
      {/* TODO: Remove this theme wrapper since it uses the global theme */}
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <UnitDetailsBody variant="outlined">
            <Grid container direction="column" spacing={1}>
              <Grid item xs={12}>
                <Grid container direction="row" spacing={1}>
                  <Grid item>
                    <UnitCardsTitle>{t('order.createUnit.flexibleSchedule.title')}</UnitCardsTitle>
                  </Grid>
                  <SimpleCheckbox
                    id={'createUnitFlexibleScheduleEnableCheck'}
                    isChecked={watchValue !== undefined && watchValue !== null ? true : false}
                    isdisabled={disableEdit}
                    hasRadio={false}
                    handleChecked={handleCheckBoxChangeX}
                    checkboxLabel={`Yes`}
                    checkboxName={'no-cbx'}
                  />
                </Grid>
                {showPanel && (
                  <>
                    <Controller
                      control={control}
                      name={'flexibleSchedule.isRequired'}
                      render={({ ref, onChange, ...rest }) => (
                        <ReqPrefSection
                          isRequired={rest.value ?? false}
                          handleReqPref={(event: React.ChangeEvent<HTMLInputElement>) => {
                            onChange(event.target.value === reqPref.req);
                          }}
                          isdisabled={disableEdit}
                          id={'createUnitFlexibleScheduleReqPrefRadio'}
                        />
                      )}
                    />

                    <Grid container direction="row" className={classes.comboSection}>
                      <Controller
                        control={control}
                        name={'flexibleSchedule.workDuringWeekend'}
                        render={({ ref, onChange, ...rest }) => (
                          <ComboSection
                            id={'createUnitFlexibleScheduleWorkDuringWeekendCheck'}
                            isComboChecked={showTextField.isWorkDuringWeekend}
                            handleComboChecked={handleCheckBoxChange}
                            comboCheckedLabel={t('order.createUnit.flexibleSchedule.workDuringWeekend')}
                            comboCheckedName={'isWorkDuringWeekend'}
                            comboInputName={'workDuringWeekend'}
                            comboDefaultValue={watchValue.workDuringWeekend}
                            comboInputLabel={t('order.createUnit.flexibleSchedule.workDuringWeekend')}
                            comboInputValue={watchValue.workDuringWeekend}
                            handleComboChange={handleChange}
                            isdisabled={disableEdit}
                          />
                        )}
                      />
                      <CustomDivider
                        className={classes.comboSectionDivider}
                        orientation="vertical"
                        flexItem
                        variant="middle"
                      />
                      <Controller
                        control={control}
                        name={'flexibleSchedule.workDuringHoliday'}
                        render={({ ref, onChange, ...rest }) => (
                          <ComboSection
                            id={'createUnitFlexibleScheduleWorkDuringHolidayCheck'}
                            isComboChecked={showTextField.isWorkDuringHoliday}
                            handleComboChecked={handleCheckBoxChange}
                            comboCheckedLabel={t('order.createUnit.flexibleSchedule.workDuringHoliday')}
                            comboCheckedName={'isWorkDuringHoliday'}
                            comboInputName={'workDuringHoliday'}
                            comboDefaultValue={watchValue.workDuringHoliday}
                            comboInputLabel={t('order.createUnit.flexibleSchedule.workDuringHoliday')}
                            comboInputValue={watchValue.workDuringHoliday}
                            handleComboChange={handleChange}
                            gridValue={5}
                            fieldStyle={classes.holidayFieldWidth}
                            isdisabled={disableEdit}
                          />
                        )}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </UnitDetailsBody>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
};
