import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormInputProps } from './FormInputProps';
import { DateRangePicker } from 'app/ComponentLibrary/DateRangeNew';
import { addDays } from 'date-fns';
import moment from 'moment';
import { ErrorMessage } from '@hookform/error-message';

export const FormInputDatePickerOptional = ({
  name,
  label,
  require,
  inputValue,
  message,
  readOnly,
  handleChange,
  customClass,
}: FormInputProps) => {
  const { control, errors } = useFormContext();
  const date = new Date();
  const bindValue = inputValue => {
    return {
      startDate: new Date(inputValue),
      endDate: new Date(inputValue),
      label: moment(new Date(inputValue)).format('MM/DD/YYYY'),
      useLabelAsValue: inputValue || false,
    };
  };
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: require,
      }}
      render={({ ref, value, onChange, ...rest }) => (
        <>
          <DateRangePicker
            onChange={event => {
              handleChange(event?.startDate?.toString() || '');
            }}
            onCancel={() => {}}
            actionBar={false}
            open={false}
            single
            variant="filled"
            placeholder={label}
            clickAway
            isDisabled={readOnly}
            maxWidth={300}
            fullWidth
            showIcon={true}
            className={customClass ?? ''}
            isDateIconReq={true}
            initialDateRange={inputValue ? bindValue(inputValue) : value ? bindValue(value) : undefined}
            minDate={new Date('01-01-1940')}
            definedRanges={[
              {
                label: '1 Day',
                startDate: addDays(date, -1),
                endDate: addDays(date, -1),
                useLabelAsValue: false,
              },
              {
                label: '2 Days',
                startDate: addDays(date, -2),
                endDate: addDays(date, -2),
                useLabelAsValue: false,
              },
              {
                label: '7 Days',
                startDate: addDays(date, -7),
                endDate: addDays(date, -7),
                useLabelAsValue: false,
              },
              {
                label: '20 Days',
                startDate: addDays(date, -20),
                endDate: addDays(date, -20),
                useLabelAsValue: false,
              },
              {
                label: '30 Days',
                startDate: addDays(date, -30),
                endDate: addDays(date, -30),
                useLabelAsValue: false,
              },
              {
                label: '60 Days',
                startDate: addDays(date, -60),
                endDate: addDays(date, -60),
                useLabelAsValue: false,
              },
            ]}
            {...rest}
          ></DateRangePicker>

          <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => (
              <span style={{ color: '#f44336', fontSize: '0.75rem', marginLeft: '10px' }}>{message}</span>
            )}
          />
        </>
      )}
    />
  );
};
