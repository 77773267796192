import { FormControlLabel, Grid, Radio } from 'amn-ui-core';
import { reqPref } from 'app/enums/Unit';
import React from 'react';
import { ReqPrefRadio } from '../UnitDesign';

interface Props {
  isRequired: boolean;
  handleReqPref: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isdisabled?: boolean;
  id?: string;
}

export const ReqPrefSection = (props: Props) => {
  const { classes: reqPrefStyleClasses } = ReqPrefRadio();

  const handleReqPrefCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.handleReqPref(event);
  };
  return (
    <>
      <Grid item className={reqPrefStyleClasses.wrapper}>
        <FormControlLabel
          value={reqPref.req}
          id={props.id}
          control={
            <Radio
              id={`${props.id}req`}
              data-testid={'req-radio'}
              checked={props.isRequired}
              onChange={handleReqPrefCheck}
              disableFocusRipple
              disableRipple
              disabled={props.isdisabled}
              disableTouchRipple
              name="req"
              className={reqPrefStyleClasses.checkboxPadding}
              color="primary"
            />
          }
          label={reqPref.req}
          className={reqPrefStyleClasses.checkboxLabel}
        />
        <FormControlLabel
          value={reqPref.pref}
          control={
            <Radio
              id={`${props.id}pref`}
              data-testid={'pref-radio'}
              checked={!props.isRequired}
              onChange={handleReqPrefCheck}
              disableFocusRipple
              disableRipple
              disableTouchRipple
              disabled={props.isdisabled}
              name="pref"
              className={reqPrefStyleClasses.checkboxPadding}
              color="primary"
            />
          }
          label={reqPref.pref}
          className={reqPrefStyleClasses.checkboxLabel}
        />
      </Grid>
    </>
  );
};
