/* eslint-disable i18next/no-literal-string */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { LayoutGrid } from 'app/layout/LayoutGrid';
import { GetMomentOfDate } from 'utils/dates/moment';
import { useSelector } from 'react-redux';
import { candidateDetailsSelection } from '../../../Profile/CandidateDetails.selector';
import { Concatenate } from 'utils/string/string';
import { selectUser } from 'oidc/user.selectors';
import { userRoles } from 'oidc/userRoles';
import { Authorized } from 'oidc/userHelper';
import { useDecision } from '@optimizely/react-sdk';
import { ff_candidate_container_ui_v2 } from 'app/constants/FeatureFlags/Candidate/Keys';
import {
  Info,
  UserRolePermission,
  allowedStatuses,
  calenderViewableUserRoles,
  formatSsn,
  itemCounter,
  rolePermission,
  ssnViewableUserRoles,
} from './helper';
import ProfileSummaryUI from './Card Design/ProfileSummaryUI';
import ProfileSummaryUIV2 from './Card Design/ProfileSummaryUIV2';
import { useStyles } from './Card Design/utils';

const defaultValue: Info = {
  dateOfBirth: null,
  socialSecurityNumber: '',
  gender: '',
  phone: '',
  email: '',
  emergencyContact: '',
  address: '',
};

export const ProfileSummaryCard = ({ navigate }) => {
  const userInfo = useSelector(selectUser);
  const candidateDetails = useSelector(candidateDetailsSelection);
  const [info, setInfo] = useState<Info>(defaultValue);
  const { classes } = useStyles();
  const [progresscount, setprogresscount] = useState<number>(0);
  const totalItems = 7;
  const [filledItemscount, setFilledItemscount] = useState<number>(0);
  const [isSSNViewableByUser, setIsSSNViewableByUser] = useState<boolean>(false);
  const [isSSNEditableByUser, setIsSSNEditableByUser] = useState<boolean>(false);
  const [isCalendarViewableByUser, setIsCalendarViewableByUser] = useState<boolean>(false);
  const [isCalendarEditableByUser, setCalendarIsEditableByUser] = useState<boolean>(false);
  const [candidateContainerUIV2] = useDecision(ff_candidate_container_ui_v2);

  useEffect(() => {
    //Check for View Access
    setIsSSNViewableByUser(Authorized(ssnViewableUserRoles, userInfo.userInfo));
    setIsCalendarViewableByUser(Authorized(calenderViewableUserRoles, userInfo.userInfo));
    //Check For Edit Access
    if (
      (Authorized([userRoles.recruitment_Leadership, userRoles.recruitment_TeamMember], userInfo.userInfo) &&
        allowedStatuses.includes(candidateDetails.placementStatus)) ||
      Authorized(rolePermission, userInfo.userInfo)
    ) {
      setIsSSNEditableByUser(true);
    }
    if (
      (Authorized([userRoles.recruitment_Leadership, userRoles.recruitment_TeamMember], userInfo.userInfo) &&
        allowedStatuses.includes(candidateDetails.placementStatus)) ||
      Authorized(UserRolePermission, userInfo.userInfo)
    ) {
      setCalendarIsEditableByUser(true);
    }
  }, [userInfo.userInfo]);

  useEffect(() => {
    if (candidateDetails) {
      const address = Concatenate(
        [
          candidateDetails.address?.address1 || '',
          candidateDetails.address?.city || '',
          candidateDetails?.address?.stateProvinceId || '',
          candidateDetails?.address?.zip || '',
          candidateDetails?.address?.country || '',
        ],
        ', ',
      );
      const momentOfDOB = GetMomentOfDate(candidateDetails.dateOfBirth);
      const processedData: Info = {
        dateOfBirth: momentOfDOB.isValid() ? momentOfDOB.format('MM/DD/YYYY') : null,
        socialSecurityNumber: candidateDetails.socialSecurityNumber
          ? formatSsn(candidateDetails.socialSecurityNumber)
          : '',
        gender: candidateDetails.gender || '',
        phone: candidateDetails.primaryPhoneNumber || candidateDetails.mobilePhoneNumber || '',
        email: candidateDetails.primaryEmail || candidateDetails.secondaryEmail || '',
        emergencyContact: candidateDetails.emergencyContact.phone || '',
        address: address ? address : '',
      };
      setInfo(processedData);
    } else {
      setInfo(defaultValue);
    }
  }, [candidateDetails]);

  useEffect(() => {
    const eachItemP = 100 / totalItems;
    const count = itemCounter(info);
    setprogresscount(count * eachItemP);
    setFilledItemscount(count);
  }, [info]);

  return (
    <LayoutGrid className={classes.grid}>
      {candidateContainerUIV2?.enabled ? (
        <ProfileSummaryUIV2
          progresscount={progresscount}
          filledItemscount={filledItemscount}
          totalItems={totalItems}
          navigate={navigate}
          info={info}
          setInfo={setInfo}
          isCalendarEditableByUser={isCalendarEditableByUser}
          isCalendarViewableByUser={isCalendarViewableByUser}
          isSSNEditableByUser={isSSNEditableByUser}
          isSSNViewableByUser={isSSNViewableByUser}
        />
      ) : (
        <ProfileSummaryUI
          progresscount={progresscount}
          filledItemscount={filledItemscount}
          totalItems={totalItems}
          navigate={navigate}
          info={info}
          setInfo={setInfo}
          isCalendarEditableByUser={isCalendarEditableByUser}
          isCalendarViewableByUser={isCalendarViewableByUser}
          isSSNEditableByUser={isSSNEditableByUser}
          isSSNViewableByUser={isSSNViewableByUser}
        />
      )}
    </LayoutGrid>
  );
};
