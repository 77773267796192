import { Typography } from 'amn-ui-core';
import React from 'react';
import { CapitalizeEveryFirstLetter } from 'utils/string/string';
import { MessageCard } from './MessageCard';
import { ArrowRight } from '@mui/icons-material';
import { MessageDeliveryStatus } from './Types/enums';
import { NoFilterResult } from './NoFilterResult';
import { selectUserPreference } from 'oidc/UserDevicePreference/userPreference.selectors';
import { useSelector } from 'react-redux';
import { selectPinnedMessages } from 'store/redux-store/notification/notification.selector';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ popOverOpen: boolean }>()((theme, { popOverOpen }) => ({
  hidden: {
    display: 'none',
  },
  flex: {
    marginTop: '10px',
    width: '100%',
    display: 'flex',
  },
  buttonTransform: {
    margin: 0,
    marginLeft: '5px',
    marginBottom: '5px',
    padding: 0,
    border: 'none',
    background: 'none',
    transform: 'rotate(90deg)',
  },
  dropdownButton: {
    margin: 0,
    marginLeft: '5px',
    marginBottom: '5px',
    padding: 0,
    border: 'none',
    background: 'none',
  },
  Inboxheader: {
    fontWeight: 500,
  },
  InboxSubHeader: {
    fontWeight: 100,
    fontStyle: 'italic',
  },
  recentsInbox: {
    margin: 0,
    padding: 0,
    pointerEvents: popOverOpen ? 'none' : 'inherit',
  },
  unread: {
    fontSize: 12,
    height: 18,
    fontWeight: 600,
  },
  buttonRotate: {
    transform: 'rotate(90deg)',
  },
}));

interface IInboxProps {
  popOverOpen: boolean;
  handleScroll: any;
  inboxMessages: any;
  handleShow: any;
  pinnedInbox: any;
  recentMessages: any;
  recentInbox: any;
  handlePinAndUnpin: any;
  OpenSmsModal: any;
  setOpen: any;
  isFilterApplied?: boolean;
}
export const Inbox = (props: IInboxProps) => {
  const {
    popOverOpen,
    inboxMessages,
    handleShow,
    pinnedInbox,
    recentMessages,
    recentInbox,
    handlePinAndUnpin,
    OpenSmsModal,
    setOpen,
    isFilterApplied = false,
  } = props;
  const [unread, setUnread] = React.useState<boolean>(true);
  const pinnedMessages = useSelector(selectPinnedMessages);
  const { classes } = useStyles({ popOverOpen });

  return (
    <div>
      {!isFilterApplied ? (
        <>
          <div className={pinnedMessages?.length > 0 ? '' : classes.hidden}>
            <div className={pinnedMessages?.length > 0 ? classes.flex : classes.hidden}>
              <button
                onClick={() => handleShow('pinned')}
                className={pinnedInbox ? classes.buttonTransform : classes.dropdownButton}
              >
                <ArrowRight />
              </button>
              <Typography className={classes.Inboxheader}>
                {`Pinned`}
                <span className={classes.InboxSubHeader}>{`(Max limit 5)`}</span>
              </Typography>
            </div>
            <div className={pinnedInbox ? classes.recentsInbox : classes.hidden}>
              {pinnedMessages?.map((item, index) => {
                const brandId =
                  (item?.log && item?.log?.message?.tos?.[0]?.brandId) ||
                  (item?.log?.associatedRecords &&
                    item?.log?.associatedRecords?.find(data => data.name.toLowerCase() === 'brandid')?.value);
                return (
                  <div key={item?.log?.id}>
                    {item?.log && (
                      <MessageCard
                        onClick={() => OpenSmsModal(item)}
                        direction={item.log?.message.direction}
                        candidateID={item?.travelerId}
                        closeInboxPanel={() => setOpen(false)}
                        brandID={brandId}
                        candidateName={item?.name ? CapitalizeEveryFirstLetter(item?.name?.toLowerCase()) : ''}
                        phoneNumber={item?.log?.message?.tos[0]?.phoneNumber}
                        subheader={`${item?.log?.message?.body || ''}`}
                        publishedOn={item?.log?.publishedOn}
                        unreadCount={item?.unreadLogs}
                        isLast={index === inboxMessages?.results?.length - 1}
                        attachments={item?.log?.message?.attachmentUrls}
                        pinned={true}
                        id={item?.log?.id}
                        handlePin={handlePinAndUnpin}
                        pinning={true}
                        error={
                          item?.log?.response?.errors?.length > 0 ||
                          item?.log?.deliveryStatus == MessageDeliveryStatus.error
                        }
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <div className={pinnedMessages?.length > 0 ? classes.flex : classes.hidden}>
            <button
              onClick={() => handleShow('recents')}
              className={recentInbox ? classes.buttonTransform : classes.dropdownButton}
            >
              <ArrowRight />
            </button>
            <Typography className={classes.Inboxheader}>{`Recents`}</Typography>
          </div>
          <div className={recentInbox ? classes.recentsInbox : classes.hidden}>
            {recentMessages?.map((item, index) => {
              const brandId =
                (item?.log && item?.log?.message?.tos?.[0]?.brandId) ||
                (item?.log?.associatedRecords &&
                  item?.log?.associatedRecords?.find(data => data.name.toLowerCase() === 'brandid')?.value);
              return (
                <div key={item?.log?.id}>
                  {item.log && (
                    <MessageCard
                      onClick={() => OpenSmsModal(item)}
                      direction={item.log?.message.direction}
                      candidateID={item.travelerId}
                      closeInboxPanel={() => setOpen(false)}
                      brandID={brandId}
                      candidateName={item.name ? CapitalizeEveryFirstLetter(item.name.toLowerCase()) : ''}
                      phoneNumber={item?.log?.message?.tos[0]?.phoneNumber}
                      subheader={`${item?.log?.message?.body || ''}`}
                      publishedOn={item?.log?.publishedOn}
                      unreadCount={item?.unreadLogs}
                      isLast={index === inboxMessages?.results?.length - 1}
                      attachments={item?.log?.message?.attachmentUrls}
                      pinned={false}
                      id={item?.log?.id}
                      handlePin={handlePinAndUnpin}
                      pinning={true}
                      error={
                        item?.log?.response?.errors?.length > 0 ||
                        item?.log?.deliveryStatus == MessageDeliveryStatus.error
                      }
                    />
                  )}
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <div className={classes.recentsInbox}>
          <div
            className={inboxMessages?.results?.length > 0 ? classes.unread : classes.hidden}
            onClick={() => setUnread(prev => !prev)}
          >
            <ArrowRight className={unread ? classes.buttonRotate : ''} /> Unread
          </div>
          {inboxMessages?.results?.length > 0 ? (
            <div className={unread ? classes.recentsInbox : classes.hidden}>
              {inboxMessages?.results?.map((item, index) => {
                const brandId =
                  (item?.log && item?.log?.message?.tos?.[0]?.brandId) ||
                  (item?.log?.associatedRecords &&
                    item?.log?.associatedRecords?.find(data => data.name.toLowerCase() === 'brandid')?.value);
                return (
                  <div key={item?.log?.id}>
                    {item.log && (
                      <MessageCard
                        onClick={() => OpenSmsModal(item)}
                        direction={item.log?.message.direction}
                        candidateID={item.travelerId}
                        closeInboxPanel={() => setOpen(false)}
                        brandID={brandId}
                        candidateName={item.name ? CapitalizeEveryFirstLetter(item.name.toLowerCase()) : ''}
                        phoneNumber={item?.log?.message?.tos[0]?.phoneNumber}
                        subheader={`${item?.log?.message?.body || ''}`}
                        publishedOn={item?.log?.publishedOn}
                        unreadCount={item?.unreadLogs}
                        isLast={index === inboxMessages?.results?.length - 1}
                        attachments={item?.log?.message?.attachmentUrls}
                        pinned={false}
                        id={item?.log?.id}
                        handlePin={handlePinAndUnpin}
                        pinning={false}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          ) : (
            <NoFilterResult />
          )}
        </div>
      )}
    </div>
  );
};
