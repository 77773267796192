/* eslint-disable i18next/no-literal-string */
/**
 * THIS IS THE NEW STANDARD COMPONENT FOR A FILTER OPTION WRAPPER
 */

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Button,
  Checkbox,
  ClickAwayListener,
  FormControlLabel,
  Grid,
  Paper,
  Popper,
  Radio,
  RadioGroup,
  StyledEngineProvider,
  TextField,
  Theme,
  ThemeProvider,
  Typography,
  createTheme,
} from 'amn-ui-core';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from 'styles/global-styles';
import { makeStyles } from 'tss-react/mui';

import SearchIcon from '@mui/icons-material/Search';
import { fetchDisciplineAndSpecialty } from 'app/services/SharedServices/SharedServices';
import _ from 'lodash';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { filtersStyle } from '../filters.style';
import { UserCheckedChips } from './UserCheckedChips';
import {
  checkedIsobjectHasValue,
  pruneTree,
  selectNodeOnCondition,
  skillSetSubFilterCount,
  updateTreeNodes,
} from '@AMIEWEB/Common/TreeView/TreeViewHelpers';
import { parseSpecialitySubSpeciality } from './helper';

export const SKILLSET_FILTER_TRACKER = 'skilset-filter-search';
export const FilterDropDown = () => {
  return <div />;
};

const smallTextFieldTheme = createTheme(theme, {
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          height: '37.625px',
          width: '100%',
          paddingLeft: '16px',
          paddingRight: '16px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: { height: '37.625px' },
      },
    },
  },
});

const useStyles = makeStyles()((theme: Theme) => ({
  textField: {
    fontSize: 12,
    width: '100%',
    '&::placeholder': {
      opacity: 1,
      fontSize: 12,
      color: '#C0C0C0',
    },
  },
  filterCheckbox: {
    display: 'flex',
    paddingLeft: '16px',
    paddingTop: '8px',
  },
  actionContainer: {
    backgroundColor: '#ffffff',
    '&:hover, &:focus': {
      backgroundColor: '#ffffff',
    },
    position: 'sticky',
    bottom: -8,
    width: '100%',
    borderTop: '1px solid #cccccc',
    padding: 5,
    zIndex: 10,
  },
  button: {
    fontSize: '12px',
    fontWeight: 'bold',
    textTransform: 'none',
    '&:hover, &:focus': {
      background: 'none',
    },
  },

  cancelButton: {
    color: '#888888',
  },
  countTag: {
    color: '#555555',
    fontSize: 14,
    fontWeight: 600,
    paddingLeft: 10,
  },
  popper: {
    zIndex: 90,
    boxShadow: '0px 3px 8px 0px rgba(148,148,148,0.75)',
    '-webkit-box-shadow': '0px 3px 8px 0px rgba(148,148,148,0.75)',
    '-moz-box-shadow': '0px 3px 8px 0px rgba(148,148,148,0.75)',
    marginTop: '6px',
  },
  paper: {
    border: `1px solid lightgray`,
    minWidth: '840px',
    maxWidth: '840px',
  },

  childWrapper1: {
    maxHeight: '36vh',
    overflowY: 'auto',
    padding: '5px 12px 0px 12px',
  },
  loadingLabel: {
    color: '#888888',
    fontSize: '14px',
    padding: '8px 0px 8px 8px',
  },

  topActionContainer: {
    boxShadow: '0px 12px 10px -17px rgba(0, 0, 0, 0.75)',
    zIndex: 3,
    position: 'relative',
    paddingLeft: '4px',
    paddingBottom: '4px',
  },
}));

export interface ICandidateSkillSet {
  disciplineId: number;
  specialtyId: number;
  subSpecialtyId: number;
  filterName: string | null;
}

export interface ICandidateSkillSetFilter {
  operation: string;
  isVerifiedOnly: boolean;
  allowableMismatch: boolean;
  isExactMatch: boolean;
}

export interface ICandidateSkillSetSearch extends ICandidateSkillSetFilter {
  skillsets: ICandidateSkillSet[];
}

export const initialCandidateSkillSetFilter: ICandidateSkillSetSearch = {
  allowableMismatch: false,
  isVerifiedOnly: false,
  operation: null,
  skillsets: [],
  isExactMatch: false,
};

export function instanceOfCandidateSkillSetFilter(object: any): object is ICandidateSkillSetSearch {
  return (
    object &&
    typeof object === 'object' &&
    'skillsets' in object &&
    ('operation' in object || 'isVerifiedOnly' in object || 'allowableMismatch' in object)
  );
}

export const getSkillSetFilter = (skillSetFilter, filtername?) => {
  let skillSets: ICandidateSkillSet[] = [];
  skillSetFilter?.forEach(discipline => {
    const disciplineId = discipline?.value;
    if (discipline?.children && discipline?.children?.length > 0) {
      discipline?.children?.forEach(specialty => {
        const specialtyId = specialty?.value.toString().includes('-')
          ? specialty?.value?.split('-')[1]
          : specialty?.value;
        if (specialty?.children && specialty?.children?.length) {
          specialty?.children?.forEach(subSpecialty => {
            skillSets.push({
              disciplineId: disciplineId,
              specialtyId: specialtyId,
              subSpecialtyId: subSpecialty?.value.toString().includes('-')
                ? subSpecialty?.value?.split('-')[2]
                : subSpecialty?.value,
              filterName: filtername,
            });
          });
        } else {
          skillSets.push({
            disciplineId: disciplineId,
            specialtyId: specialtyId,
            subSpecialtyId: null,
            filterName: filtername,
          });
        }
      });
    } else {
      skillSets.push({
        disciplineId: disciplineId,
        specialtyId: null,
        subSpecialtyId: null,
        filterName: filtername,
      });
    }
  });

  skillSets = skillSets?.map(item => ({
    ...item,
    specialtyId: parseSpecialitySubSpeciality(item?.specialtyId),
    subSpecialtyId: parseSpecialitySubSpeciality(item?.subSpecialtyId),
  }));

  return skillSets;
};

export const getSelectedSkillSetFilter = (skillSetFilter, selectedChips, filterName) => {
  let skillSets = [];
  if (selectedChips && selectedChips?.length > 0) {
    if (Array.isArray(selectedChips[0])) {
      const disciplineId = selectedChips[0][0]?.children[0]?.value;
      skillSets?.push({
        disciplineId: disciplineId,
        specialtyId: null,
        subSpecialtyId: null,
        filterName: filterName,
      });
    } else {
      if (selectedChips[0]?.children[0]?.children) {
        const disciplineId = selectedChips[0]?.value;
        const specialtyId = selectedChips[0]?.children[0]?.value;
        skillSets?.push({
          disciplineId: disciplineId,
          specialtyId: specialtyId,
          subSpecialtyId: null,
          filterName: filterName,
        });
      } else {
        skillSetFilter?.every(element => {
          let disciplineId = null;
          let specialtyId = null;
          let subSpecialtyId = null;
          let childNodeFound = false;
          if (selectedChips[0]?.value === element?.value) {
            disciplineId = element?.value;
            element?.children?.every(specialty => {
              specialty?.children?.every(subspecialty => {
                if (selectedChips[0]?.children[0]?.value === subspecialty?.value) {
                  subSpecialtyId = subspecialty?.value;
                  childNodeFound = true;
                  return false;
                }
                return true;
              });
              if (childNodeFound) {
                specialtyId = specialty?.value;
                return false;
              }
              return true;
            });
          }
          if (childNodeFound) {
            skillSets?.push({
              disciplineId: disciplineId,
              specialtyId: specialtyId,
              subSpecialtyId: subSpecialtyId,
              filterName: filterName,
            });
            return false;
          }
          return true;
        });
      }
    }
  }

  skillSets = skillSets?.map(item => ({
    ...item,
    specialtyId: parseSpecialitySubSpeciality(item?.specialtyId),
    subSpecialtyId: parseSpecialitySubSpeciality(item?.subSpecialtyId),
  }));

  return skillSets;
};

export const CandidateSkillsetFilter: React.FC<{
  isMultiSelect: boolean;
  onApplyEvent: any;
  onChange: (skillsets: ICandidateSkillSetSearch) => void;
  filterName: string;
  customSelectedCount: any;
  value: ICandidateSkillSetSearch;
  Component: any;
  [key: string]: any;
}> = props => {
  const {
    value = initialCandidateSkillSetFilter,
    isMultiSelect,
    filterName,
    customSelectedCount,
    onChange,
    onApplyEvent,
    Component,
    isExact = false,
  } = props;

  const { classes, cx } = useStyles();
  const { classes: commonCss } = filtersStyle();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState<boolean>(false);
  const boxRef = React.useRef<any>();
  const [originalVal, setOriginalVal] = React.useState(initialCandidateSkillSetFilter);
  const [options, setOptions] = React.useState<any>({ ...props });
  const prevAbortControllerRef = React.useRef<AbortController>(null);
  const [chipsObject, setChipsObject] = useState<any>([]);
  const [{ trackerArea, id }] = React.useState({
    trackerArea: `${SKILLSET_FILTER_TRACKER}-${props.name}`,
    id: `skillset-filter-${props.name}`,
  });
  const [expandedItems, setExpandedItems] = React.useState<string[]>([]);
  const { promiseInProgress: loadingOpts } = usePromiseTracker({ area: trackerArea, delay: 0 });
  const [verifiedOnly, setVerifiedOnly] = useState<boolean>(false);
  const [exactMatch, setExactMatch] = useState<boolean>(false);
  const [operation, setOperation] = useState<string | null>(null);
  const [allowableMismatch, setAllowableMismatch] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const handleExpandedItemsChange = (isExpnad: boolean) => {
    return isExpnad ? setExpandedItems(options?.itemsToExapand) : setExpandedItems([]);
  };

  const fetchDiscipline = React.useCallback(async filters => {
    setOptions([]);
    prevAbortControllerRef.current?.abort('CandidateSkillsetFilter - throttling');

    const abortController = new AbortController();
    prevAbortControllerRef.current = abortController;
    const disciline = await fetchDisciplineAndSpecialty(filters, abortController.signal);

    if (filters) {
      setExpandedItems(disciline?.nodesWithChildren);
    }
    setOptions({ ...props, options: disciline?.treeStructure, itemsToExapand: disciline?.nodesWithChildren });
  }, []);

  const throttleService = useMemo(
    () => _.throttle(request => trackPromise(fetchDiscipline(request), trackerArea), 2500),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleSearch = React.useCallback(
    searchText => {
      setSearch(searchText);
      throttleService(searchText);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [throttleService],
  );

  const toggleDropDown = event => {
    if (!open) {
      setOpen(true);
      throttleService(search);
    }
  };

  const resetSubfilters = () => {
    setVerifiedOnly(false);
    setAllowableMismatch(false);
    setOperation(null);
    setSearch('');
  };

  const clearAll = () => {
    onChange(initialCandidateSkillSetFilter);
    setChipsObject([]);
    setExpandedItems([]);
    resetSubfilters();
    setExactMatch(false);
    throttleService('');
  };

  useEffect(() => {
    setChipsObject(value?.skillsets);
    setVerifiedOnly(value?.isVerifiedOnly);
    setOperation(value?.operation);
    setExactMatch(value?.isExactMatch);
    setAllowableMismatch(value?.allowableMismatch);
  }, [
    value?.isVerifiedOnly,
    value?.operation,
    value?.allowableMismatch,
    value?.skillsets?.length,
    value?.isExactMatch,
    value?.skillsets,
  ]);

  const cancelChanges = () => {
    onChange({ ...originalVal });
    setVerifiedOnly(originalVal?.isVerifiedOnly);
    setOperation(originalVal?.operation);
    setExactMatch(originalVal?.isExactMatch);
    setAllowableMismatch(originalVal?.allowableMismatch);
    setChipsObject([...originalVal?.skillsets]);
    setSearch('');
    setOpen(false);
    setExpandedItems([]);
  };

  const apply = value => {
    const oldValues = value;

    // No skillset selected
    if (value?.skillsets?.length === 0) {
      value = {
        ...oldValues,
        operation: '',
        isVerifiedOnly: false,
        allowableMismatch: false,
      };
      resetSubfilters();
    }
    // One skillset selected
    if (value?.skillsets?.length === 1) {
      const getSelectedSkillsetCount = customSelectedCount ? customSelectedCount(value) : 0;
      value = {
        ...oldValues,
        operation: getSelectedSkillsetCount > 1 ? value?.operation || 'Or' : null,
      };
      setOperation(value.operation);
    }

    // More than 1 skillset selected
    if (value?.skillsets?.length > 1 && !value?.operation) {
      value = {
        ...oldValues,
        operation: 'Or',
      };
      setOperation('Or');
    }

    setChipsObject(value?.skillsets);
    setOriginalVal(value);
    onChange(value);
    onApplyEvent();
    setOpen(false);
  };

  const getSkillsetsWithFilterName = skillsets => {
    return skillsets && skillsets?.length > 0
      ? skillsets.map(skillset => ({ ...skillset, filterName: props?.name }))
      : [];
  };

  const handleSelection = args => {
    const removeDuplicate = updateTreeNodes(pruneTree(args));
    const filterValue = {
      isVerifiedOnly: verifiedOnly,
      operation: operation,
      skillsets: getSkillsetsWithFilterName(removeDuplicate),
      allowableMismatch: allowableMismatch,
      isExactMatch: exactMatch,
    };
    setChipsObject(removeDuplicate);
    onChange(filterValue);
  };

  React.useEffect(() => {
    if (open) {
      const count = customSelectedCount(value);
      if (!count) {
        setSearch('');
      }
      setOriginalVal(value);
      setChipsObject(value?.skillsets);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const onClickAway = e => {
    if (e.target.type !== 'submit') {
      setOpen(false);
      apply(value);
    }
  };

  const handleUpdate = updatedData => {
    // Handle the updated JSON data here
    const removeDuplicate = updateTreeNodes(pruneTree(updatedData));
    const filterValue = {
      isVerifiedOnly: verifiedOnly,
      operation: operation,
      skillsets: getSkillsetsWithFilterName(removeDuplicate),
      allowableMismatch: allowableMismatch,
      isExactMatch: exactMatch,
    };

    setChipsObject(removeDuplicate);
    onChange(filterValue);
  };

  const handleSelectAll = () => {
    let updatedOptions = [];
    if (search === '') {
      updatedOptions = selectNodeOnCondition(options?.options, 'First');
    } else {
      updatedOptions = selectNodeOnCondition(options?.options, 'All');
    }

    const removeDuplicate = updateTreeNodes(updatedOptions);

    const filterValue = {
      isVerifiedOnly: verifiedOnly,
      operation: operation,
      skillsets: getSkillsetsWithFilterName(removeDuplicate),
      allowableMismatch: allowableMismatch,
      isExactMatch: exactMatch,
    };
    onChange(filterValue);
    setChipsObject(removeDuplicate);
  };

  const userCheckedChips = useMemo(() => {
    let chipsObjectLc =
      chipsObject?.map(item => {
        // Filter children where `name` is not null
        const filteredChildren = item.children?.filter(child => child.name !== null) || [];

        // If there are no valid children, set userChecked to true
        return {
          ...item,
          children: filteredChildren,
          userChecked: filteredChildren.length === 0 ? true : item.userChecked,
          filterName: 'skillsetFilter',
        };
      }) || null;

    return <UserCheckedChips data={chipsObjectLc} onDataChange={handleUpdate} />;
  }, [chipsObject, handleUpdate]);

  const handleKeyDown = React.useCallback(
    event => {
      if (open) {
        /** Code: Apply on click on enter key */
        if (event.key === 'Enter') {
          apply(value);
        } else if (event.key === 'Escape') {
          /** Code: Cancel on click on Esc key */
          cancelChanges();
        } else if (event.altKey && event.key.toLowerCase() === 'c') {
          /** Code: Apply on click on aly + "c" key */
          cancelChanges();
        } else if (event.key === ' ' || event.key === 'Spacebar') {
          event.preventDefault();
          const activeElement = document.activeElement as HTMLInputElement;
          const elementType = activeElement?.getAttribute('type');
          if (elementType === 'checkbox') {
            activeElement.click();
          }
        }
      }
      /** Add future key down features below */
    },
    [cancelChanges, apply],
  );

  React.useEffect(() => {
    document.body.addEventListener('keydown', handleKeyDown);

    return () => {
      document.body.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const isMultipleSkillsetSelected = () => {
    let isMultiple: boolean = false;
    if (chipsObject) {
      if (chipsObject.length > 1) {
        isMultiple = true;
      } else if (chipsObject.length === 1) {
        if (chipsObject[0].children) {
          if (chipsObject[0].children.length > 1) {
            isMultiple = true;
          } else if (chipsObject[0].children.length === 1) {
            if (chipsObject[0].children[0].children) {
              if (chipsObject[0].children[0].children.length > 1) {
                isMultiple = true;
              }
            }
          }
        }
      }
    }
    if (isMultiple && operation === null) {
      setOperation('Or');
      onChange({
        isVerifiedOnly: verifiedOnly,
        operation: 'Or',
        skillsets: getSkillsetsWithFilterName(chipsObject),
        allowableMismatch: allowableMismatch,
        isExactMatch: exactMatch,
      });
    }
    if (isMultiple === false && operation !== null) {
      setOperation(null);
      onChange({
        isVerifiedOnly: verifiedOnly,
        operation: null,
        skillsets: getSkillsetsWithFilterName(chipsObject),
        allowableMismatch: allowableMismatch,
        isExactMatch: exactMatch,
      });
    }

    return isMultiple;
  };

  const selectedSkillsetCount = isMultipleSkillsetSelected();

  const selectedSkillsets = customSelectedCount(value) || 0;

  React.useEffect(() => {
    if (selectedSkillsets < 1) {
      setOperation('Or');
    }
  }, [selectedSkillsets]);

  return (
    <React.Fragment>
      <div ref={boxRef}>
        <Box
          onClick={toggleDropDown}
          className={cx(commonCss.sudoTextBox, {
            focused: open,
            empty: !(
              value &&
              ((customSelectedCount && customSelectedCount(value?.skillsets) > 0) || value?.skillsets?.length > 0)
            ),
          })}
        >
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Typography>{`${
                value && checkedIsobjectHasValue(value)
                  ? `(${customSelectedCount(value) + skillSetSubFilterCount(value)})`
                  : ''
              }  ${filterName}`}</Typography>
            </Grid>
            <Grid item>
              {!open ? (
                <ExpandMoreIcon className={commonCss.collapsIcon} />
              ) : (
                <ExpandLessIcon className={commonCss.expandIcon} />
              )}
            </Grid>
          </Grid>
        </Box>
      </div>
      {open && (
        <ClickAwayListener onClickAway={onClickAway}>
          <Popper
            className={classes.popper}
            open={open}
            anchorEl={boxRef.current}
            placement="bottom-start"
            modifiers={[
              {
                name: 'skillsetfiter',
                enabled: true,
                options: {
                  fallbackPlacements: ['top', 'bottom'],
                },
              },
            ]}
          >
            <Paper elevation={0} className={classes.paper}>
              <Grid
                item
                container
                justifyContent={isMultiSelect ? 'space-between' : 'flex-end'}
                alignItems="center"
                style={{ padding: 6, minWidth: 250 }}
              >
                {isMultiSelect ? (
                  <div className={classes.countTag}>{`${
                    value && value?.skillsets && value?.skillsets.length > 0 ? customSelectedCount(value) : ''
                  }  ${t('search.filter.Selected')}`}</div>
                ) : null}
                <Button
                  className="clear-all-button"
                  aria-label="clear-all-button"
                  variant="text"
                  color="primary"
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                  onClick={clearAll}
                  disabled={!isMultiSelect}
                  classes={{ text: ` ${classes.button} ` }}
                >
                  {t('search.filter.clearAll')}
                </Button>
              </Grid>
              <Grid container columns={20}>
                <Grid item xs={12}>
                  <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={smallTextFieldTheme}>
                      <TextField
                        onChange={event => {
                          handleSearch(event.target.value.trim());
                        }}
                        value={search ?? ''}
                        autoFocus
                        placeholder={t('search.globalSearch.candidate.filterNames.skillsetFilterSearch')}
                        variant="outlined"
                        size="small"
                        inputProps={{ maxLength: 255 }}
                        InputProps={{
                          classes: { input: classes.textField },
                          startAdornment: <SearchIcon style={{ opacity: 0.6 }} />,
                          'aria-label': 'filter-type-ahead',
                        }}
                      />
                    </ThemeProvider>
                  </StyledEngineProvider>
                  <div style={{ display: 'flex' }} className={classes.filterCheckbox}>
                    <FormControlLabel
                      control={<Checkbox />}
                      label={t('search.globalSearch.candidate.filterNames.allowableMismatch')}
                      onChange={() => {
                        const allowableMismatchLocal = allowableMismatch;
                        setAllowableMismatch(!allowableMismatchLocal);
                        const filterValue = {
                          isVerifiedOnly: verifiedOnly,
                          operation: operation,
                          skillsets: getSkillsetsWithFilterName(chipsObject),
                          allowableMismatch: !allowableMismatchLocal,
                          isExactMatch: exactMatch,
                        };
                        onChange(filterValue);
                      }}
                      checked={allowableMismatch}
                    />
                    {!isExact ? (
                      <FormControlLabel
                        onChange={() => {
                          const verifiedOnlyLocal = verifiedOnly;
                          setVerifiedOnly(!verifiedOnlyLocal);
                          const filterValue = {
                            isVerifiedOnly: !verifiedOnlyLocal,
                            operation: operation,
                            skillsets: getSkillsetsWithFilterName(chipsObject),
                            allowableMismatch: allowableMismatch,
                            isExactMatch: exactMatch,
                          };
                          onChange(filterValue);
                        }}
                        checked={verifiedOnly}
                        control={<Checkbox />}
                        label={t('search.globalSearch.candidate.filterNames.verifiedOnly')}
                      />
                    ) : (
                      <FormControlLabel
                        onChange={() => {
                          const isExactMatchLocal = exactMatch;
                          setExactMatch(!isExactMatchLocal);
                          const filterValue = {
                            isVerifiedOnly: verifiedOnly,
                            operation: operation,
                            skillsets: getSkillsetsWithFilterName(chipsObject),
                            allowableMismatch: allowableMismatch,
                            isExactMatch: !isExactMatchLocal,
                          };
                          onChange(filterValue);
                        }}
                        checked={exactMatch}
                        control={<Checkbox />}
                        label={t('search.globalSearch.candidate.filterNames.exactMatch')}
                      />
                    )}
                    {selectedSkillsetCount && (
                      <RadioGroup
                        row
                        aria-labelledby="radio-buttons-group-label"
                        name="radio-buttons-group"
                        value={operation || 'Or'}
                        onChange={e => {
                          setOperation(e.target.value);
                          const filterValue = {
                            isVerifiedOnly: verifiedOnly,
                            operation: e.target.value,
                            skillsets: getSkillsetsWithFilterName(chipsObject),
                            allowableMismatch: allowableMismatch,
                            isExactMatch: exactMatch,
                          };
                          onChange(filterValue);
                        }}
                      >
                        <FormControlLabel value={'Or'} control={<Radio />} label="Or" />
                        <FormControlLabel value={'And'} control={<Radio />} label="And" />
                      </RadioGroup>
                    )}
                  </div>
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    style={{ paddingLeft: '8px' }}
                    className={classes.topActionContainer}
                  >
                    <Button
                      variant="text"
                      color="primary"
                      disableRipple
                      disableFocusRipple
                      disableTouchRipple
                      classes={{ text: ` ${classes.button} ` }}
                      onClick={() => handleExpandedItemsChange(true)}
                    >
                      {t('search.globalSearch.candidate.filterNames.expandAll')}
                    </Button>
                    <Button
                      variant="text"
                      color="primary"
                      disableRipple
                      disableFocusRipple
                      disableTouchRipple
                      classes={{ text: ` ${classes.button} ` }}
                      onClick={() => handleExpandedItemsChange(false)}
                    >
                      {t('search.globalSearch.candidate.filterNames.collapseAll')}
                    </Button>
                    <Button
                      variant="text"
                      color="primary"
                      disableRipple
                      disableFocusRipple
                      disableTouchRipple
                      classes={{ text: ` ${classes.button} ` }}
                      onClick={() => {
                        handleSelectAll();
                      }}
                    >
                      {t('search.globalSearch.candidate.filterNames.selectAll')}
                    </Button>
                  </Grid>
                  <div className={classes.childWrapper1}>
                    {loadingOpts ? (
                      <div className={classes.loadingLabel}>{t('Loading...')}</div>
                    ) : options?.options?.length === 0 ? (
                      <div className={classes.loadingLabel}>{t('No Options')}</div>
                    ) : (
                      <Component
                        {...options}
                        value={value}
                        onChange={handleSelection}
                        expandedItems={expandedItems}
                        setExpandedItems={setExpandedItems}
                        excludeChildren={true}
                      />
                    )}
                  </div>
                </Grid>
                <Grid item xs={8}>
                  <div
                    style={{
                      width: '100%',
                      background: '#FFFFFF',
                      borderTop: '1px solid #cccccc',
                      borderLeft: '1px solid #cccccc',
                      maxHeight: '54vh', // Adjust this value as needed
                      height: '54vh', // Adjust this value as needed
                      overflowY: 'auto',
                      padding: '5px 12px',
                    }}
                  >
                    {userCheckedChips}
                  </div>
                </Grid>
              </Grid>
              <div className={classes.actionContainer}>
                <Grid item container justifyContent="flex-end" alignItems="center">
                  <Button
                    className="clear-all-button"
                    aria-label="cancel-button"
                    variant="text"
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                    onClick={() => cancelChanges()}
                    classes={{ text: ` ${classes.button} ${classes.cancelButton}` }}
                  >
                    {t('search.filter.cancel')}
                  </Button>
                  <Button
                    className="clear-all-button"
                    aria-label="apply-button"
                    variant="text"
                    color="primary"
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                    onClick={() => {
                      apply(value);
                    }}
                    classes={{ text: ` ${classes.button} ` }}
                  >
                    {t('search.filter.apply')}
                  </Button>
                </Grid>
              </div>
            </Paper>
          </Popper>
        </ClickAwayListener>
      )}
    </React.Fragment>
  );
};
