import { ICustomHeader, gridControlType } from 'app/models/Grid';
import React, { useCallback, useEffect, useState } from 'react';
import { CustomGrid } from '../../../../Common/Grid/Grid';
import { useTranslation } from 'react-i18next';
import { Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { CredentialingLookup } from 'app/models/Credentialing/CredentialingLookups';
import moment from 'moment';
import { Credentials, ICredentials } from 'app/models/Credentialing/Credentials';
import _ from 'lodash';
import {
  onDeleteCredential,
  findCredentialByRequirementID,
  onEditSave,
  findMyComparingCredentialDate,
  getColumnsAndRows,
  getDataSet,
  useNewGrid,
} from './CredentialsHelpers';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { selectUser } from 'oidc/user.selectors';
import { AttachmentAction } from './Attachments/AttachmentHoverView';
import { AttachmentPreviewDialog } from './Attachments/AttachmentPreview/AttachmentPreviewDialog';
import { startTimedEvent, trackEvent } from 'app-insights/appInsightsTracking';
import { isCredentialing, isRecruitment, useReadOnly } from 'oidc/userRoles';
import { gridTypeToDisplayString } from './Search/searchHelpers';
import {
  CredentialsReloadData,
  candidateDetailsSelection,
  selectAllPassportSkillChecklist,
} from '../../Profile/CandidateDetails.selector';
import { gridStateActions, GridTag } from '../../../../Common/Grid/GridStateManagement/GridState.redux';
import { selectGridState } from '../../../../Common/Grid/GridStateManagement/GridState.selectors';
import { candidateDetailActions } from '../../Profile/CandidateDetails.redux';
import { useDecision } from '@optimizely/react-sdk';
import { ff_editUnverifiedLicense } from 'app/constants/FeatureFlags/Candidate/Keys';
import { ff_ui_viewing_skill_checklist } from 'app/constants/FeatureFlags/Candidate/Keys';
import { isPositiveNumber } from 'app/helpers/componentHelpers';
import { getSkillChecklistById } from 'app/services/CandidateServices/CandidateServices';
import { globalActions } from 'app/ApplicationRoot/Global.redux';

export enum CredentialsGridType {
  licensure,
  clinicalQualificationsParent,
  clinicalQualifications,
  clinicalQualificationsSkillsChecklists,
  clinicalQualificationsAssessments,
  certificationsParent,
  certifications,
  certificationsOtherCertifications,
  certificationsLifeSupportCertifications,
  humanResourcesParent,
  humanResourcesBackgroundChecks,
  humanResourcesDrugScreens,
  humanResourcesIdentifications,
  humanResourcesForms,
  educationAndOnlineForms,
  employeeHealthParent,
  employeeHealthLabResults,
  employeeHealthMedicalRecords,
  employeeHealthImmunizations,
  miscellaneous,
  miscellaneousFacilityTrackingSheet,
  miscellaneousClientSpecificContractEnclosures,
  miscellaneousManagerReviewSensitiveAccount,
  miscellaneousPendingMoleculeDevelopment,
}

const useStyles = makeStyles()({
  deleteCredentialContent: {
    fontWeight: 'bold',
  },
});

interface CredentialsGridProps {
  credentials: Credentials | null;
  credentialsGridType: CredentialsGridType;
  credentialingLookups: CredentialingLookup[];
  searchResult?: any;
  onSearchComplete?: () => void;
  reloadGrid?: boolean;
  addingCredential?: boolean;
  selectedOption?: any;
  addPopUpEditIcon?: boolean; //={true}
  addPopupClose?: Function; //={handlePopupClose}
  initialEditingIndex?: number | null; //={editingIndex}
  /** show/hide in-line cancel & save buttons */
  showEditControl?: boolean;
  duplicateRecordsFound?: Function;
  willAddNewCredential?: Function;
  onSaveNew?: Function;
  backButtonLabel?: string;
  numberOfItemsPerPage?: number;
  typeNotSelected?: boolean;
  menuToRender?: any;
  hasSubcategories?: boolean;
  isAddLicense?: boolean;
}

const DEFAULT_PAGE_SIZE = 20;
const DEFAULT_PAGE_NUMBER = 1;

export const CredentialsGrid: React.FC<CredentialsGridProps> = props => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const { classes } = useStyles();
  const {
    credentialsGridType,
    credentialingLookups,
    searchResult,
    onSearchComplete,
    reloadGrid,
    credentials,
    addingCredential,
    selectedOption,
    addPopupClose = null,
    addPopUpEditIcon = false,
    initialEditingIndex = null,
    showEditControl = true,
    duplicateRecordsFound = null,
    willAddNewCredential = null,
    onSaveNew = null,
    hasSubcategories,
    menuToRender,
    isAddLicense,
  } = props;
  const [columns, setColumns] = useState<ICustomHeader[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [title, setTitle] = useState<string>('');
  // hide grid while it's being updated on add
  const [showGrid, setShowGrid] = useState<boolean>(true);
  const [editSearchResult, setEditSearchResult] = useState<any>(null);
  const [enableEditUnverifiedLicenseDecision] = useDecision(ff_editUnverifiedLicense);
  const [enableNewSkillsChecklist] = useDecision(ff_ui_viewing_skill_checklist);
  const user = useSelector(selectUser);
  const hasAccess = !isCredentialing(user?.userInfo?.roles || []);
  const reloadData = useSelector(CredentialsReloadData);
  const gridState = useSelector(selectGridState);
  const candidateDetails = useSelector(candidateDetailsSelection);
  const expandedGrid = useNewGrid(credentialsGridType, props);
  const [sortCol, setSortCol] = useState<any>();
  const [sortDir, setSortDir] = useState<any>();
  const [clearChildMessages, setClearChildMessages] = useState<boolean>(false);
  const { readOnly } = useReadOnly();
  const passportSkillChecklists = useSelector(selectAllPassportSkillChecklist);
  const [showAttachmentPreview, setShowAttachmentPreview] = useState<{
    open: boolean;
    action: AttachmentAction;
    requirementID: number;
    requirementTypeID: number;
    travelerId: number;
    brandId: number;
  }>({
    open: false,
    action: AttachmentAction.view,
    requirementID: 0,
    requirementTypeID: 0,
    travelerId: 0,
    brandId: 0,
  });

  useEffect(() => {
    if ((credentials && credentialingLookups) || credentialsGridType) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption, reloadGrid, credentialsGridType, reloadData, editSearchResult]);

  useEffect(() => {
    dispatch(
      gridStateActions.setGridState({
        gridTag: GridTag.CredentialsGrid,
        pageSize: DEFAULT_PAGE_SIZE,
        pageNumber: DEFAULT_PAGE_NUMBER,
        previewItemId: -1,
        showPreviewDialog: false,
        sortedColumn: {
          column: gridState.sortedColumn?.column || sortCol,
          direction: gridState.sortedColumn?.direction || sortDir || 'asc',
        },
        filterAttributes: undefined,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadData]);

  const filterRequirementTypeID = item => item.requirementTypeID === selectedOption.requirementTypeID;
  const filterType = item =>
    (item.requirementTypeID === 222 ? item.reqTypeID : item.typeID) === selectedOption.type.value;

  /**
   * Method to find skill checklist id from passport skill checklist by requirement id and open attachments section
   * @param requirementID
   */
  const getSkillChecklistAndAttachments = async (
    requirementID: number,
    travelerId,
    action,
    requirementTypeID,
    brandId,
  ) => {
    const currentSkillChecklist = passportSkillChecklists?.skillsChecklists.find(
      item => item.requirementId === requirementID,
    );
    if (isPositiveNumber(candidateDetails?.travelerId)) {
      if (currentSkillChecklist?.id) {
        await getSkillChecklistById(travelerId, currentSkillChecklist?.id).then(response => {
          if (response?.data)
            dispatch(
              candidateDetailActions.setCandidateSkillChecklistFromPassport(response?.data?.skillsChecklists[0]),
            );
        });
      }
      setClearChildMessages(true);
      dispatch(candidateDetailActions.setIsPreview(false));
      setShowAttachmentPreview({
        open: true,
        action: action,
        requirementID: requirementID,
        requirementTypeID: requirementTypeID,
        travelerId: travelerId,
        brandId: brandId,
      });
    }
  };

  const onAttachmentAction = async (
    action: AttachmentAction,
    requirementID: number,
    requirementTypeID: number,
    travelerId: number,
    brandId: number,
  ) => {
    dispatch(candidateDetailActions.setCandidateSkillChecklistFromPassport(undefined));
    getSkillChecklistAndAttachments(requirementID, travelerId, action, requirementTypeID, brandId);
  };

  const onAttachmentDialogClose = () => {
    setShowAttachmentPreview({ ...showAttachmentPreview, open: false });
  };

  const getData = useCallback(() => {
    const { columns, rows } = getColumnsAndRows(
      credentials,
      credentialsGridType,
      credentialingLookups,
      selectedOption
        ? !!selectedOption.type && selectedOption?.type?.value !== 'N/A'
          ? [filterRequirementTypeID, filterType]
          : [filterRequirementTypeID]
        : [],
      !!selectedOption,
      onAttachmentAction,
    );

    setShowGrid(true);
    if (selectedOption) {
      if (rows.length > 0) {
        duplicateRecordsFound?.(rows.length);
      } else {
        setShowGrid(false);
        willAddNewCredential?.();
        return;
      }
    }
    setColumns(columns);
    setRows(rows);
    setTitle(t(gridTypeToDisplayString(credentialsGridType)));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials, credentialsGridType, credentialingLookups, t, selectedOption]);

  /**
   * Update the field values when another field changes
   * @param booleanValue The value of the checkbox in question
   * @param columns The column info for that dataset
   * @param originalValue the value of the column
   */
  const setLinkedField = (booleanValue, columns, originalValue) => {
    let value: any = null;
    switch (columns.control.type) {
      case gridControlType.name:
        value = booleanValue ? `${user.userInfo?.firstName} ${user.userInfo?.lastName}` : null;
        break;
      case gridControlType.nameID:
        value = null;
        break;
      case gridControlType.dateLabel:
      case gridControlType.date:
        value = booleanValue ? moment().format('MM/DD/yyyy') : null;
        break;
      default:
        break;
    }
    return value;
  };

  /**
   * Finds the data to modify if a checkbox is changed, or a field that should change a checkbox and related fields
   * @param booleanKey The key of the boolean value in question
   * @param keys The keys of the data to modify
   * @param overallData The total data for the row
   * @param booleanValue The value of checkbox in question
   */
  const indexFinder = (
    booleanKey: keyof ICredentials | null,
    keys: (keyof ICredentials)[],
    overallData,
    booleanValue: boolean,
  ) => {
    keys?.forEach(key => {
      if (overallData.hasOwnProperty(key) && overallData[key] !== undefined) {
        if (key === booleanKey) {
          overallData[booleanKey] = false;
        } else {
          const c = columns.find(column => column.title === `${key}`);
          overallData[key] = setLinkedField(booleanValue, c, overallData[key]);
        }
      } else {
        overallData.detailRows?.forEach(rows => {
          rows.forEach(rowsColumns => {
            rowsColumns.rows.forEach(row => {
              if (row.hasOwnProperty(key) && row[key] !== undefined) {
                if (key === booleanKey) {
                  row[booleanKey] = false;
                } else {
                  const c: ICustomHeader = rowsColumns.columns.find(column => column.title === `${key}`);
                  row[key] = setLinkedField(booleanValue, c, row[key]);
                }
              }
            });
          });
        });
      }
    });

    return overallData;
  };

  /** formats the data if certain field of credential category are changed */
  const onValueChanged = (key: keyof ICredentials, value: any, overallData: any, showCustomAlert: Function) => {
    overallData['removeRecord'] = false;
    //check if expiration date is less than today

    if (key === 'expirationDate' && value !== null && value !== undefined && value !== '') {
      if (
        moment(value).isBefore(moment(`${moment().format('yyyy-MM-DD')}${moment().format('THH:mm:ss')}`)) &&
        moment(moment(value).format('yyyy-MM-DD')).isBefore(findMyComparingCredentialDate(overallData, 'dateCompleted'))
      ) {
        overallData['removeRecord'] = true;
        showCustomAlert(
          t('candidate.credentials.removeRecordExpirationLesserDateCompletedWarningTitle'),
          t('candidate.credentials.removeRecordExpirationLesserDateCompletedWarningContent'),
        );
      } else if (moment(value).isBefore(moment(`${moment().format('yyyy-MM-DD')}${moment().format('THH:mm:ss')}`))) {
        overallData['removeRecord'] = true;

        showCustomAlert(
          t('candidate.credentials.removeRecordExpirationTitle'),
          t('candidate.credentials.removeRecordExpirationContent'),
        );
      } else if (
        moment(moment(value).format('yyyy-MM-DD')).isBefore(findMyComparingCredentialDate(overallData, 'dateCompleted'))
      ) {
        showCustomAlert(
          t('candidate.credentials.expirationLesserDateCompletedWarningTitle'),
          t('candidate.credentials.expirationLesserDateCompletedWarningContent'),
        );
      }
    }
    if (
      key === 'dateCompleted' &&
      value !== null &&
      value !== undefined &&
      value !== '' &&
      moment(moment(value).format('yyyy-MM-DD')).isAfter(findMyComparingCredentialDate(overallData, 'expirationDate'))
    ) {
      showCustomAlert(
        t('candidate.credentials.dateCompletedWarningTitle'),
        t('candidate.credentials.dateCompletedWarningContent'),
      );
    }
    const CredentialTrackEventName = 'Edit Credential:Credential Verified';
    switch (credentialsGridType) {
      case CredentialsGridType.licensure:
        switch (key) {
          case 'typeID':
          case 'issuedBy':
          case 'dateCompleted':
          case 'expirationDate':
          case 'stateID':
          case 'licenseNumber':
            // set the credential to unverified if any of these fields are changed
            if (addingCredential) return overallData;
            return indexFinder(
              'verifiedID',
              ['dateVerified', 'verifiedByName', 'verifiedID', 'verifiedByID'],
              overallData,
              false,
            );
          case 'verifiedID':
            if (value) trackEvent({ type: 'click', name: CredentialTrackEventName });
            // clear or the verified data if the checkbox is changed
            return indexFinder(null, ['verifiedByName', 'dateVerified', 'verifiedByID'], overallData, value);
          case 'paperlessID':
            // clear or the paperless data if the checkbox is changed
            return indexFinder(null, ['paperlessByName', 'paperlessDate', 'paperlessBy'], overallData, value);
          case 'onFileID':
            // clear or the on-file data if the checkbox is changed
            return indexFinder(null, ['onFileBy', 'onFileDate', 'onFileByID'], overallData, value);
          default:
            break;
        }
        break;
      case CredentialsGridType.certificationsOtherCertifications:
        switch (key) {
          case 'certificationRegistrationNum':
          case 'issuedBy':
          case 'expirationDate':
          case 'dateCompleted':
            // set the credential to unverified if any of these fields are changed
            if (addingCredential) return overallData;
            return indexFinder(
              'verifiedID',
              ['verifiedID', 'dateVerified', 'verifiedByName', 'verifiedByID'],
              overallData,
              false,
            );
          case 'verifiedID':
            if (value) trackEvent({ type: 'click', name: CredentialTrackEventName });
            // clear or the verified data if the checkbox is changed
            return indexFinder(null, ['verifiedByName', 'dateVerified', 'verifiedByID'], overallData, value);

          default:
            break;
        }
        break;
      case CredentialsGridType.certifications:
        switch (key) {
          case 'certificationRegistrationNum':
          case 'issuedBy':
          case 'expirationDate':
          case 'dateCompleted':
            // set the credential to unverified if any of these fields are changed
            if (addingCredential) return overallData;
            return indexFinder(
              'verifiedID',
              ['verifiedID', 'dateVerified', 'verifiedByName', 'verifiedByID'],
              overallData,
              false,
            );
          case 'verifiedID':
            if (value) trackEvent({ type: 'click', name: CredentialTrackEventName });
            // clear or the verified data if the checkbox is changed
            return indexFinder(null, ['verifiedByName', 'dateVerified', 'verifiedByID'], overallData, value);
          case 'onFileID':
            // clear or the on-file data if the checkbox is changed
            return indexFinder(null, ['onFileBy', 'onFileDate', 'onFileByID'], overallData, value);
          default:
            break;
        }
        break;
      case CredentialsGridType.certificationsLifeSupportCertifications:
        switch (key) {
          case 'backOfCardID':
          case 'typeID':
          case 'dateCompleted':
            // set the credential to unverified if any of these fields are changed
            if (addingCredential) return overallData;
            return indexFinder(
              'verifiedID',
              ['verifiedID', 'dateVerified', 'verifiedByName', 'verifiedByID'],
              overallData,
              false,
            );
          case 'verifiedID':
            if (value) trackEvent({ type: 'click', name: CredentialTrackEventName });
            // clear or the verified data if the checkbox is changed
            return indexFinder(null, ['verifiedByName', 'dateVerified', 'verifiedByID'], overallData, value);
          default:
            break;
        }
        break;
      case CredentialsGridType.humanResourcesBackgroundChecks:
      case CredentialsGridType.humanResourcesDrugScreens:
      case CredentialsGridType.humanResourcesIdentifications:
      case CredentialsGridType.humanResourcesForms:
        switch (key) {
          case 'verifiedID':
            if (value) trackEvent({ type: 'click', name: CredentialTrackEventName });
            // clear or the verified data if the checkbox is changed
            return indexFinder(null, ['verifiedByName', 'dateVerified', 'verifiedByID'], overallData, value);
          case 'onFileID':
            // clear or the on-file data if the checkbox is changed
            return indexFinder(null, ['onFileBy', 'onFileDate', 'onFileByID'], overallData, value);
          default:
            break;
        }
        break;
      default:
        break;
    }
    return null;
  };

  /**
   * Formats the updated data into a form the profile context and API can accept
   * @param updatedRow The updated data for the credential
   */
  const _onEditSave = async (updatedRow): Promise<any> => {
    return onEditSave(
      updatedRow,
      _.cloneDeep(credentials),
      credentialsGridType,
      credentialingLookups,
      dispatch,
      {
        first: user.userInfo?.firstName,
        last: user.userInfo?.lastName,
      },
      onAttachmentAction,
      user?.userInfo?.employeeId || 0,
    );
  };

  const onEditSaveComplete = rowID => {
    const s = {
      key: 'rowID',
      value: rowID,
    };
    setEditSearchResult(s);
  };

  const canEditRow = (row): boolean => {
    if (readOnly) return false;

    startTimedEvent('editCredential', {
      type: 'event',
      name: 'Credential Edit Started',
    });
    switch (credentialsGridType) {
      case CredentialsGridType.humanResourcesIdentifications:
        const dataSet = getDataSet(credentials, credentialsGridType);
        const foundData = (dataSet as any[]).find(item => item.requirementID === row.rowID);
        if (foundData && foundData['requirementTypeID'] === 118) {
          if (row['verifiedID'] === true) {
            return false;
          } else {
            return (
              row['detailRows']?.find(
                rows =>
                  rows?.find(rowsAndColumns => rowsAndColumns.rows?.find(item => item['verifiedID'] === true)) !==
                  undefined,
              ) === undefined
            );
          }
        }
        return true;
      default:
        return true;
    }
  };

  const willDelete = (requirementID): any => {
    const credential = findCredentialByRequirementID(requirementID, credentials);
    let type: string | null = null;
    if (
      credential.gridType === CredentialsGridType.miscellaneousPendingMoleculeDevelopment ||
      credential.gridType === CredentialsGridType.certificationsOtherCertifications ||
      credential.gridType === CredentialsGridType.educationAndOnlineForms
    ) {
      const foundType =
        credential?.credential?.requirementTypeID === 222
          ? credential?.credential?.reqTypeID
          : credential?.credential?.typeID;
      const lookups = credentialingLookups?.find(
        item =>
          item.requirementTypeID === credential?.credential?.requirementTypeID &&
          item.lookUpField === (credential?.credential?.requirementTypeID === 222 ? 'ReqTypeID' : 'TypeID'),
      );
      if (lookups !== undefined) {
        const foundLookup = lookups.lookups?.filter(item => item.value === foundType);
        if (foundLookup !== undefined && foundLookup?.length > 0) {
          type = foundLookup[0].description;
        }
      }
    }
    const typeString = `${type !== null ? ` of ${credential?.credential?.requirementTypeID === 222 ? 'Requirement Type' : 'Type'} ` : ''
      }`;

    return {
      title:
        !readOnly && credential?.credential?.canDelete === true
          ? t('search.grid.delete.dialogTitle')
          : t('search.grid.delete.disallowDeleteDialogTitle'),
      content: (
        <React.Fragment>
          <div>
            <span>
              {!readOnly && credential?.credential?.canDelete === true
                ? t('search.grid.delete.dialogSpecificContentOne')
                : t('search.grid.delete.disallowDialogSpecificContentOne')}
            </span>
            <span className={classes.deleteCredentialContent}>
              {` ${credential?.credential?.requirementType} - ${credential?.credential?.credentialName}`}
            </span>
            {type && <span>{typeString}</span>}
            {type && (
              <>
                {' '}
                <span className={classes.deleteCredentialContent}>{type}</span> <span>{'?'}</span>{' '}
              </>
            )}
          </div>
          <br />
          <div>
            <span>
              {!readOnly && credential?.credential?.canDelete === true
                ? t('search.grid.delete.dialogSpecificContentTwo')
                : ''}
            </span>
          </div>
        </React.Fragment>
      ),
      canDeleteRecord: !readOnly && credential?.credential?.canDelete,
    };
  };

  const onDelete = (requirementID): Promise<boolean> => {
    //this is added because after each record delete state is set back to initial state
    if (gridState.sortedColumn?.column && gridState.sortedColumn?.direction) {
      setSortCol(gridState.sortedColumn?.column || '');
      setSortDir(gridState.sortedColumn?.direction || '');
    }

    return onDeleteCredential(requirementID, _.cloneDeep(credentials), dispatch);
  };

  return (
    <React.Fragment>
      {credentials && credentialingLookups && (
        <Grid container direction="column" justifyContent="flex-end">
          {hasSubcategories && <Grid item>{menuToRender}</Grid>}
          <Grid item sx={{ width: '100%' }}>
            {columns && rows && showGrid &&
              (enableNewSkillsChecklist?.enabled && expandedGrid || credentialsGridType === CredentialsGridType.clinicalQualifications) ? (
              <React.Fragment>
                {expandedGrid.component}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <CustomGrid
                  columns={columns}
                  rows={rows}
                  searchTerm=""
                  component={''}
                  showCount={!selectedOption && !hasSubcategories}
                  disableGutters
                  noPadding={true}
                  fullWidth
                  showPagination={!addingCredential}
                  showViewOptions={false}
                  currentPageNumber={gridState.pageNumber || DEFAULT_PAGE_NUMBER}
                  fixedItemsPerPage={gridState.pageSize || DEFAULT_PAGE_SIZE}
                  gridName={GridTag.CredentialsGrid}
                  resultsTitle={title}
                  defaultSortDirection={gridState.sortedColumn?.direction || (!!selectedOption ? 'asc' : 'desc')}
                  defaultSortedColumn={gridState.sortedColumn?.column}
                  disablePaper
                  centerHoverView
                  hasEditMenu={credentialsGridType !== CredentialsGridType.clinicalQualificationsSkillsChecklists}
                  rowSearch={editSearchResult || searchResult}
                  onSearchComplete={onSearchComplete}
                  onEditSave={_onEditSave}
                  onEditSaveComplete={onEditSaveComplete}
                  onValueChanged={onValueChanged}
                  canEdit={canEditRow}
                  hasSingleEditIcon={addPopUpEditIcon}
                  onSingleEditIconClick={addPopupClose}
                  initialEditingIndex={initialEditingIndex}
                  showEditControl={showEditControl}
                  onSave={onSaveNew}
                  disableRowColors={addingCredential}
                  stickyTable={true}
                  isInDialog={!!selectedOption}
                  addingCredential={addingCredential}
                  deleteActions={{
                    willDelete: willDelete,
                    onDelete: onDelete,
                  }}
                  hasPermisions={{
                    canEdit: enableEditUnverifiedLicenseDecision.enabled
                      ? (hasAccess && !isRecruitment(user?.userInfo?.roles || [])) || isAddLicense
                      : hasAccess || isAddLicense,
                    canDelete: (hasAccess && isRecruitment(user?.userInfo?.roles || [])) || isAddLicense,
                    canAdd: hasAccess,
                  }}
                  analytics={{
                    tableName: 'Credentials Grid',
                  }}
                  enableStateManagement
                />
                <AttachmentPreviewDialog
                  data={showAttachmentPreview}
                  onClose={onAttachmentDialogClose}
                  clearChildMessages={clearChildMessages}
                  setClearChildMessages={setClearChildMessages}
                />
              </React.Fragment>
            )}
          </Grid>
        </Grid>
      )}
      {/* {(!credentials || !credentialingLookups) && <Box className={classes.centerBox}>{t('Error loading data')}</Box>} */}
    </React.Fragment>
  );
};
