import { NewUnit, OrderPriorityType } from 'app/models/Unit/CreateUnit';
import { ClinicianToPatientRatio, INewOrderInitialState, IPage2, OrderWorkHistory } from '../types';
import { getNurse, getPatient } from '@AMIEWEB/Order/OrderCreationForm/DefineUnit/Helper';
import {
  DisableOrderPriorityList,
  STRING_VALIDATION_MESSAGE,
  UPDATE_MESSAGE_TEMPLATE,
  VirtualInterviewAssociatedList,
  VirtualInterviewRatingsId,
  VoiceAdvantage,
} from '../constants';
import { IAutoCompleteOption } from '../CreateOrder/Common/AutoCompleteRenderChips';
import {
  formatDate,
  getIfElementOfArrayPresentInAnotherArray,
  getSkillSetReorderPrimarySkill,
  transformSkillSetGroupsDataWithoutLicense,
  updateQualificationStrings,
} from '../CreateOrder/helper';
import {
  IOrderDetails,
  IOrderFreeText,
  OrderPrioritiesData,
  SubmitOrder,
  editOrder,
} from 'app/models/Orders/OrderDetails';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { ISkillSet } from '@AMIEWEB/Facility/FacilityStore/Facility.model';
import { FacilityResponse } from 'app/models/Facility/FacilityDetails';
import { generatePayload } from '../CreateOrder/PreviewAndSubmit/Preferences/PreferencesWrapper';
import { IUser } from 'oidc/user.redux';

const getOrderPriorities = (orderPriorities: OrderPriorityType[], virtualRatings: string[]) => {
  const rating = virtualRatings?.map(item => {
    return {
      name: item,
      value: item === VoiceAdvantage ? VirtualInterviewRatingsId.VoiceAdvantage : VirtualInterviewRatingsId[`${item}`],
      hideDeleteIcon: false,
    } as IAutoCompleteOption;
  });
  //TODO can remove this code after setting Voice Advantage from Unit side
  const priority = (orderPriorities || []).reduce((resultsArray, item) => {
    const idValue = item.id.toString();
    // include only the order priorities whose values are greater than 0
    // and those which are not present in virtual ratings
    if (resultsArray.some(x => x.value === idValue) || parseInt(idValue) === 0) return resultsArray;
    resultsArray.push({
      name: item.description,
      value: idValue,
      hideDeleteIcon: DisableOrderPriorityList.includes(idValue),
    } as IAutoCompleteOption);
    return resultsArray;
  }, rating);
  //TODO can remove this code after setting Voice Advantage from Unit side
  if (
    getIfElementOfArrayPresentInAnotherArray(priority, 'value', VirtualInterviewAssociatedList) &&
    priority.findIndex(pr => pr?.value === VirtualInterviewRatingsId.VoiceAdvantage) === -1
  ) {
    priority.push({
      name: VoiceAdvantage,
      value: VirtualInterviewRatingsId.VoiceAdvantage,
      hideDeleteIcon: true,
    });
  }
  return priority;
};
export const convertToString = value => (value === null || value === undefined ? null : value.toString());
export function validateNumberRange(input): string | null {
  if (!input) {
    return null;
  }
  const numberRangePattern = /^\d+(-\d+)?$/;
  return numberRangePattern.test(input) ? input : null;
}

export const getDataFromUnit = (selectedUnit: NewUnit) => {
  const patientRatio = getPatient(selectedUnit?.nurseRatio);
  const nurseRatio = getNurse(selectedUnit?.nurseRatio);
  const clinicianPatientRatio = {
    numberOfClinicians: nurseRatio && !isNaN(nurseRatio) ? parseInt(nurseRatio) : null,
    numberOfPatients: validateNumberRange(patientRatio),
    numberOfVisits: validateNumberRange(selectedUnit.rangeOfVisits),
    notifyCandidate: selectedUnit.notifyCandidate ?? false,
    patientsSeen: validateNumberRange(selectedUnit.rangeOfPatientsSeen),
    productivityPercentage: selectedUnit.productivityPercentage,
  } as ClinicianToPatientRatio;
  const orderWorkHistory = {
    entireWorkHistory: selectedUnit?.entireWorkHistory ?? false,
    workHistoryGapInDays: selectedUnit?.withinTheLastDays ?? null,
    workHistoryGapInYears: selectedUnit?.withinTheLastYears ?? null,
  } as OrderWorkHistory;

  return [clinicianPatientRatio, orderWorkHistory];
};

export const getPage3FreeTextDetails = (selectedUnit: NewUnit) => {
  const orderFreeTextDetails: IOrderFreeText = {
    additionalRequiredQualifications: selectedUnit?.additionalRequiredQualifications ?? '',
    internalNotes: selectedUnit?.internalNotes ?? '',
    jobDescription: selectedUnit?.externalJobDescription ?? '',
    preferredQualifications: selectedUnit?.preferredQualifications ?? '',
    requiredQualifications: selectedUnit?.requiredQualifications ?? '',
  };
  return orderFreeTextDetails;
};

export const getPage2DefaultData = (pageData: IPage2, selectedUnit: NewUnit) => {
  const [clinicianPatientRatio, orderWorkHistory] = getDataFromUnit(selectedUnit);

  let page2Data = { ...pageData };
  page2Data.bestBets = selectedUnit.bestBets;
  page2Data.bestBetsId = selectedUnit.bestBetsId;
  page2Data.orderClinicianToPatientRatio = clinicianPatientRatio as ClinicianToPatientRatio;
  page2Data.orderWorkHistory = orderWorkHistory as OrderWorkHistory;
  page2Data.orderPriorities =
    selectedUnit?.orderPriorities?.length > 0
      ? getOrderPriorities(selectedUnit.orderPriorities, selectedUnit.virtualRating)
      : [];
  page2Data.virtualRatingSubmissionRequirement = selectedUnit.submissionRequirement ?? false;
  page2Data.reference = { ...selectedUnit?.reference };
  return page2Data;
};

export const getPagewiseValues: {
  [key: string]: (orderData: IOrderDetails, initialState: INewOrderInitialState, issuedBy?) => any;
} = {
  page1: (orderData, initialState, issuedBy) => {
    const updatedFormData = {
      ...orderData,
      skillSetGroup: getSkillSetReorderPrimarySkill(orderData?.skillSetGroup, issuedBy),
    };
    const page1data = {
      dobRequired: orderData?.dobRequired,
      ssnRequired: orderData?.ssnRequired,
      orderType: orderData?.orderType,
      region: orderData?.location.region,
      orderReason: orderData?.orderReason,
      orderReasonId: orderData?.orderReasonId,
      positionsAvailable: orderData?.positionsAvailable,
      maxFilesSent: orderData?.maxFilesSent,
      reasonForMaxFilesSent: orderData?.reasonForMaxFilesSent,
      hideFacilityDescription: orderData?.hideFacilityDescription,
      suppressExternalPosting: orderData?.suppressExternalPosting,
      orderTypeId: orderData?.orderTypeId,
      regionId: orderData?.location?.regionId,
      skillSetGroup: updatedFormData?.skillSetGroup,
    };
    return { ...page1data };
  },
  page2: (orderData, initialState) => {
    const page2data = {
      orderClinicianToPatientRatio: {
        ...orderData?.orderClinicianToPatientRatio,
        patientsSeen: validateNumberRange(orderData?.orderClinicianToPatientRatio?.rangeOfPatientsSeen),
        numberOfVisits: validateNumberRange(orderData?.orderClinicianToPatientRatio?.rangeOfVisits),
        numberOfPatients: validateNumberRange(orderData?.orderClinicianToPatientRatio?.rangeOfPatients),
      },
      orderWorkHistory: orderData?.orderWorkHistory,
      virtualRatingSubmissionRequirement: orderData?.virtualRatingSubmissionRequirement,
      orderPriorities: orderData?.orderPriorities?.map(x => ({
        value: x?.orderPriorityTypeId?.toString(),
        name: x?.description,
        hideDeleteIcon: DisableOrderPriorityList.includes(x?.orderPriorityTypeId?.toString()),
      })),
      bestBets: orderData?.bestBets,
      bestBetsId: orderData?.bestBetsId,
      reference: orderData?.reference,
      orderStrike: orderData?.orderStrike,
    };
    return { ...page2data };
  },
  page3: (orderData, initialState) => {
    const page3data = {
      areDefaultValueSet: true,
      placementLength: orderData?.numOfWeeks,
      additionalInformation: orderData?.additionalInformation,
      shifts: orderData?.shifts?.map(x => ({ object: { ID: x.id }, label: x.description })),
      ASAP: orderData?.asap,
      flexibleDates: orderData?.flexibleDates,
      startDate: orderData?.startDate,
      endDate: orderData?.endDate,
      VMSOrderNumber: orderData?.vmsOrderNumber,
      webLink: orderData?.webLink,
      expectedHours: orderData?.expectedHours,
      guaranteedHours: orderData?.guaranteedHours,
      orderBillRate: orderData?.orderBillRate,
      VMSBillRate: orderData?.vmsBillRate,
    };
    return { ...page3data };
  },
};
export const transformDataForEditOrder = (orderData: IOrderDetails, initState: INewOrderInitialState, issuedBy) => {
  const initialState = { ...initState };
  initialState.page1 = getPagewiseValues.page1(orderData, initialState, issuedBy);
  initialState.page2 = getPagewiseValues.page2(orderData, initialState);
  initialState.page3 = getPagewiseValues.page3(orderData, initialState);
  return { ...initialState };
};

export const generateUpdatedInternalNotes = (editOrderData, userState) => {
  const lastUpdatedBy = `${userState?.userInfo?.firstName} ${userState?.userInfo?.lastName}`;
  const updateMessage = UPDATE_MESSAGE_TEMPLATE.replace('[lastupdatedby]', lastUpdatedBy).replace(
    '[lastupdateddatetime]',
    formatDate(new Date()).toLocaleString(),
  );

  if (editOrderData && editOrderData?.internalNotes) {
    if (!editOrderData?.internalNotes.includes(STRING_VALIDATION_MESSAGE)) {
      return `${editOrderData?.internalNotes}\n${updateMessage}`;
    } else {
      return editOrderData?.internalNotes;
    }
  } else {
    return updateMessage;
  }
};

export const transformPayloadForEditOrder = (
  initialState: INewOrderInitialState,
  editOrderData: editOrder,
  userState: IUser,
  selectedSkillSetDataUnit,
) => {
  let editPayload = JSON.parse(JSON.stringify(editOrderData));
  for (const k of Object.keys(initialState.page1)) {
    if (k in editPayload) {
      editPayload[k] = initialState.page1[k];
    }
  }
  for (const k of Object.keys(initialState.page2)) {
    if (k in editPayload) {
      editPayload[k] = initialState.page2[k];
    }
  }
  for (const k of Object.keys(initialState.page3)) {
    if (k in editPayload) {
      editPayload[k] = initialState.page3[k];
    }
  }
  editPayload.VMSBillRate = initialState.page3.VMSBillRate;
  editPayload.bestBetsId = initialState.page2.bestBetsId;
  editPayload.bestBets = initialState.page2.bestBets;
  editPayload.shifts = initialState.page3.shifts?.map(x => ({ id: x.object.ID, description: x.label })) ?? [];
  editPayload.dates = [{ startDate: initialState.page3?.startDate, endDate: initialState.page3?.endDate }];
  editPayload.asap = initialState.page3.ASAP;
  editPayload.vmsOrderNumber = initialState.page3.VMSOrderNumber;
  editPayload.flexibleDates = initialState.page3.flexibleDates;
  editPayload.numOfWeeks = initialState.page3.placementLength;
  editPayload.orderPriorities =
    initialState.page2.orderPriorities?.map(x => ({
      orderPriorityTypeId: x?.value?.toString(),
      description: x?.name,
    })) ?? [];
  editPayload.location.regionId = initialState.page1.regionId;
  editPayload.location.region = initialState.page1.region;
  editPayload.reference = { ...editPayload.reference, withinMonths: updateOrderReference(cloneDeep(initialState)) };

  let ssg = cloneDeep(transformSkillSetGroupsDataWithoutLicense(initialState.page1.skillSetGroup));
  ssg.groups[0]?.skillsets?.forEach(a =>
    a.collections[0]?.requirements?.forEach(b =>
      Object.keys(b)?.forEach(key => (b[key] === undefined || b[key] === null) && delete b[key]),
    ),
  );
  editPayload.skillSetGroup = ssg;
  updateQualificationStrings(
    editPayload,
    initialState?.page1?.skillSetGroup,
    editPayload?.additionalRequiredQualifications,
    selectedSkillSetDataUnit,
  );
  if (editOrderData?.vmsOrder && editOrderData?.showInheritUnitButton) {
    editPayload.internalNotes = generateUpdatedInternalNotes(editOrderData, userState);
  }
  editPayload.orderClinicianToPatientRatio = {
    ...editPayload.orderClinicianToPatientRatio,
    numberOfPatients: null,
    numberOfVisits: null,
    patientsSeen: null,
    rangeOfPatients: convertToString(editPayload.orderClinicianToPatientRatio.numberOfPatients),
    rangeOfVisits: convertToString(editPayload.orderClinicianToPatientRatio.numberOfVisits),
    rangeOfPatientsSeen: convertToString(editPayload.orderClinicianToPatientRatio.patientsSeen),
  };

  return editPayload;
};

export const getSkillSetsForAPI = (skillsets: ISkillSet[]) => {
  let i = 0;
  for (let v of skillsets) {
    if (typeof v.skillSet.discipline !== 'string') {
      skillsets[i].skillSet.disciplineId = v.skillSet.discipline.object?.ID;
      skillsets[i].skillSet.disciplineAbbr = v.skillSet.discipline.object?.Abbreviation;
      skillsets[i].skillSet.discipline = v.skillSet.discipline.label;
    }
    if (typeof v.skillSet.specialty !== 'string') {
      skillsets[i].skillSet.specialtyId = v.skillSet.specialty.object?.SpecialtyID;
      skillsets[i].skillSet.specialty = v.skillSet.specialty.label;
    }
    if (typeof v.skillSet.subSpecialty !== 'string') {
      skillsets[i].skillSet.subSpecialtyId = v.skillSet?.subSpecialty?.object?.SubSpecialtyID;
      skillsets[i].skillSet.subSpecialty = v.skillSet?.subSpecialty?.label;
    }
    // eslint-disable-next-line no-plusplus
    i++;
  }
  return skillsets;
};

export const transformPayloadForSubmitOrder = (
  initialState: INewOrderInitialState,
  facilityData: FacilityResponse,
  unitInherited?: boolean,
) => {
  const preference = generatePayload(initialState?.preferences);

  let submitPayload = {
    additionalInformation: null,
    ASAP: null,
    bestBets: '',
    bestBetsId: 0,
    currentUserId: 0,
    dateCreated: moment().format(),
    dates: null,
    startDate: null,
    endDate: null,
    dobRequired: null,
    flexibleDates: null,
    hideFacilityDescription: null,
    maxFilesSent: null,
    newGrad: null,
    orderClinicianToPatientRatio: null,
    orderPriorities: null,
    orderReason: null,
    orderReasonId: null,
    orderStrike: null,
    orderTypeId: null,
    orderWorkHistory: null,
    placementLength: null,
    positionsAvailable: null,
    reasonForMaxFilesSent: null,
    reference: null,
    shifts: null,
    skillSetGroup: null,
    virtualRatingSubmissionRequirement: false,
    VMSOrderNumber: null,
    webLink: null,
    yearsOfExperience: null,
    ssnRequired: null,
    facility: facilityData as unknown,
    suppressExternalPosting: false,
    isUpdatedFeeText: true,
    expectedHours: 0,
    guaranteedHours: 0,
    orderBillRate: 0.0,
    contacts: {
      recruiter: {
        userId: facilityData?.employeeContacts?.recruiter?.employeeId ?? null,
        firstName: '',
        lastName: '',
        email: '',
      },
      accountManager: {
        userId: facilityData?.employeeContacts?.accountManager?.employeeId ?? null,
        firstName: '',
        lastName: '',
        email: '',
      },
      regionalDirector: {
        userId: facilityData?.employeeContacts?.regionalDirector?.employeeId ?? null,
        firstName: '',
        lastName: '',
        email: '',
      },
      credentialingAnalyst: null,
      clinicalManager: null,
      verifiedBy: null,
    },
    unit: {
      ...initialState?.selectedUnitItem,
      reference: {
        ...initialState?.selectedUnitItem?.reference,
        withinMonths: initialState?.selectedUnitItem?.reference?.withinMonths ?? false,
        withinNumberOfMonths: initialState?.selectedUnitItem?.reference?.withinNumberOfMonths ?? 0,
      },
    },
    location: {
      regionId: initialState.page1.regionId,
      region: initialState.page1.region,
      address1: '',
      address2: '',
      city: '',
      country: '',
      phoneNumber: '',
      state: '',
      zipCode: '',
    },
    VMSBillRate: null,
    preferences: null,
    manualCreation: true,
  } as SubmitOrder;
  for (const k of Object.keys(initialState.page1)) {
    if (k in submitPayload) {
      submitPayload[k] = initialState.page1[k];
    }
  }
  for (const k of Object.keys(initialState.page2)) {
    if (k in submitPayload) {
      submitPayload[k] = initialState.page2[k];
    }
  }
  for (const k of Object.keys(initialState.page3)) {
    if (k in submitPayload) {
      submitPayload[k] = initialState.page3[k];
    }
  }

  let ssg = cloneDeep(transformSkillSetGroupsDataWithoutLicense(initialState.page1.skillSetGroup));
  submitPayload.orderPriorities = initialState.page2.orderPriorities
    ?.filter(item => item.value)
    ?.map(item => {
      return { orderPriorityTypeId: +item.value, description: item.name } as OrderPrioritiesData;
    });
  submitPayload.shifts = initialState.page3?.shifts
    ?.filter(s => s?.object?.ID)
    ?.map(shift => {
      return { Id: shift?.object?.ID, Description: shift?.label };
    });
  submitPayload.VMSBillRate = parseFloat(initialState.page3.VMSBillRate?.toString());
  submitPayload.dates = [{ startDate: initialState.page3?.startDate, endDate: initialState.page3?.endDate }];
  ssg.groups[0]?.skillsets?.forEach(a =>
    a.collections[0]?.requirements?.forEach(b =>
      Object.keys(b)?.forEach(key => (b[key] === undefined || b[key] === null) && delete b[key]),
    ),
  );
  submitPayload.skillSetGroup = ssg;
  submitPayload.preferences =
    unitInherited || !!preference?.rfoToSent || !!preference?.autoRFO || !!preference?.candidateTasks
      ? generatePayload(initialState?.preferences)
      : {};
  submitPayload.manualCreation = true;
  submitPayload.reference = { ...submitPayload.reference, withinMonths: updateOrderReference(initialState) };
  submitPayload.orderClinicianToPatientRatio = {
    ...submitPayload.orderClinicianToPatientRatio,
    numberOfPatients: null,
    numberOfVisits: null,
    patientsSeen: null,
    rangeOfPatients: convertToString(submitPayload.orderClinicianToPatientRatio.numberOfPatients),
    rangeOfVisits: convertToString(submitPayload.orderClinicianToPatientRatio.numberOfVisits),
    rangeOfPatientsSeen: convertToString(submitPayload.orderClinicianToPatientRatio.patientsSeen),
  };
  submitPayload.jobDescription = initialState.jobDescription ?? '';
  submitPayload.internalNotes = initialState.internalNotes ?? '';
  submitPayload.requiredQualifications = initialState.requiredQualifications ?? '';
  submitPayload.preferredQualifications = initialState.preferredQualifications ?? '';
  submitPayload.additionalRequiredQualifications = initialState.additionalRequiredQualifications ?? '';
  return submitPayload;
};

export const updateOrderReference = initialState => {
  //*NOTE : The References : Entire Work History -> US#372358 COA 5 */
  const reference = initialState?.page2?.reference;
  const hasReferences = reference?.numberOfReferenceToRFO >= 1;
  return (hasReferences && !reference?.withinNumberOfMonths) || reference?.withinMonths;
};

export const transformPayloadForEditOrderUnitInheritance = (
  unit: NewUnit,
  editOrderData: editOrder,
  userState: IUser,
  unitSkillSet,
) => {
  const [clinicianPatientRatio, orderWorkHistory] = getDataFromUnit(unit);
  let editPayload = JSON.parse(JSON.stringify(editOrderData));
  editPayload.ssnRequired = unit.ssn;
  editPayload.dobRequired = unit.dob;
  editPayload.bestBetsId = unit.bestBetsId;
  editPayload.bestBets = unit.bestBets;
  editPayload.skillSetGroup = unit.skillSetGroup;
  editPayload.orderPriorities =
    unit?.orderPriorities?.length > 0
      ? getOrderPriorities(unit.orderPriorities, unit.virtualRating)?.map(x => ({
          orderPriorityTypeId: x?.value?.toString(),
          description: x?.name,
        }))
      : [];
  editPayload.orderClinicianToPatientRatio = clinicianPatientRatio;
  editPayload.orderWorkHistory = orderWorkHistory;
  editPayload.virtualRatingSubmissionRequirement = unit.submissionRequirement ?? false;
  editPayload.reference = { ...unit?.reference };
  editPayload.reference.withinNumberOfMonths = unit?.reference?.withinNumberOfMonths ?? 0;
  editPayload.showInheritUnitButton = false;

  updateQualificationStrings(editPayload, unitSkillSet, editPayload?.additionalRequiredQualifications, unitSkillSet);

  if (editOrderData.showInheritUnitButton) {
    editPayload.internalNotes = generateUpdatedInternalNotes(editOrderData, userState);
  } else {
    editPayload.internalNotes = editOrderData?.internalNotes;
  }

  return editPayload;
};

export const isPostionChangedToZero = ({ originalPositions, currPositions }) => {
  return originalPositions !== currPositions && currPositions === 0;
};
