import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Badge, Tabs, Tab, Popper } from 'amn-ui-core';
import { toast } from 'react-toastify';
import { selectUser } from 'oidc/user.selectors';
import { trackEvent } from 'app-insights/appInsightsTracking';
import { HubEventType, NotificationHeaderOptions } from '../../Constants';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { taskDetailsActions, taskDetailsReducer, taskDetailsSliceKey } from 'app/components/Tasks/store/Tasks.redux';
import ClearIcon from '@mui/icons-material/Clear';
import {
  INotificationFeedResponse,
  ITaskNotificationPopupProps,
  NotificationCategory,
  TaskEntity,
} from 'app/models/Tasks/Tasks';
import { TaskDetails } from 'app/components/Tasks/TaskDetails/TaskDetails';
import { pageAttributesActions } from 'app/components/Placement/PlacementDetails/Edit/store/placementPageAttributes.redux';
import { taskDetailsSaga } from 'app/components/Tasks/store/Tasks.saga';
import Alert from 'app/assets/images/Header/Notification.svg';
import CommunicationTab from 'app/assets/images/Notification/CommunicationTab.svg';
import {
  CONSTANTS_NOTIFICATIONS,
  getNotificationData,
  updateReadNotification,
} from 'app/components/Notification/Tasks/Common/FormatData';
import {
  selectDismissNotificationId,
  selectNotificationLog,
  selectUnreadLogCount,
  unReadNotificationCount,
} from 'app/components/Tasks/store/Tasks.selectors';
import {
  ff_clientcontactpage,
  ff_notifications_ui_activity_feed_split,
} from 'app/constants/FeatureFlags/Tasks/Notifications/Keys';
import { useDecision } from '@optimizely/react-sdk';
import { navigationStickActions } from 'app/components/GlobalNavigationMenu/NavigationStick.redux';
import { INotificationLog } from 'app/models/ActivityFeed/IActivityLogs';
import { IGetActivityFeedQuery } from 'app/models/ActivityFeed/IGetActivityFeed';
import { ActivityFeedV2 } from '../Common_v2/ActivityFeed';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { RootState } from 'types';
import {
  candidateJobPreferencesSliceKey,
  jobPreferenceReducer,
} from '@AMIEWEB/Candidate/CandidateProfile/CandidateTabPanel/JobPreferencesTab/store/JobPreferences.redux';
import { jobPreferencesSaga } from '@AMIEWEB/Candidate/CandidateProfile/CandidateTabPanel/JobPreferencesTab/store/JobPreferences.saga';
import { SignalREventType } from 'app/enums/Common';
import {
  INotificationPopperProps,
  StyledToastContainer,
  defaultLogsPayload,
  filterPreferencesId,
  getSelectedFiltersBasedOnTab,
  payloadWithFilters,
  useStyles,
} from '../utils';
import OrderMatch from 'app/assets/images/OrderMatch.svg';
import TaskAltSvg from 'app/assets/images/TaskAltSvg.svg';
import VisibilityContext from '@AMIEWEB/VisblityChange/VisiblityContext';
import { CreatePlacementWrapper } from '@AMIEWEB/Placement/CreatePlacement';
import { getEmployeeId, getNotificationType } from '../Common_v2/utils';
import { SessionKey } from 'utils/customHooks/sessionStorage/sessionHelpers';
import { AlertFeed } from '../Common_v2/Feeds/AlertFeed';

export const TaskNotifications = props => {
  const { userInfo } = props;
  const { classes } = useStyles();
  const { isVisible } = useContext(VisibilityContext);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const showToast = React.useRef<boolean>(isVisible);
  const [taskId, setTaskId] = useState<number>();
  const [recordDetails, setRecordDetails] = useState<any>();
  const [taskContainer, setTaskContainer] = useState<number>();
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [statusChanged, setStatusChanged] = useState<boolean | null>(null);
  const [notificationDataArray, setNotificationDataArray] = useState<INotificationFeedResponse[]>();
  const [multiLine, setMultiLine] = useState<boolean>(false);
  const [category, setCategory] = useState<string>();
  const [selectedFeedData, setSelectedFeedData] = useState<INotificationPopperProps>({
    id: null,
    currentPopper: '',
    openPopper: false,
    currentRef: null,
    selectedTypes: null,
  });

  const dispatch = useDispatch();

  const [clientContactFlag] = useDecision(ff_clientcontactpage, { autoUpdate: true });
  const [activityFeedRefactoring] = useDecision(ff_notifications_ui_activity_feed_split);

  useInjectReducer({ key: taskDetailsSliceKey, reducer: taskDetailsReducer });
  useInjectSaga({ key: taskDetailsSliceKey, saga: taskDetailsSaga });
  useInjectSaga({ key: candidateJobPreferencesSliceKey, saga: jobPreferencesSaga });
  useInjectReducer({ key: candidateJobPreferencesSliceKey, reducer: jobPreferenceReducer });

  const filterPreference = useSelector((state: RootState) => {
    return state.userPreferenceData.userGridPreferences.find(item => item.id === filterPreferencesId);
  });
  const appliedFilters = filterPreference?.value.filtersApplied;

  const user = useSelector(selectUser);
  const unreadLogCount = useSelector(selectUnreadLogCount);
  const dismissedNotificationId = useSelector(selectDismissNotificationId);
  const { unreadTaskNotificationsCount, unreadOrderMatchNotificationsCount, unreadAlertNotificationsCount } =
    useSelector(unReadNotificationCount);
  const NotificationLogData = useSelector<RootState, INotificationLog>(selectNotificationLog);

  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const taskAnchorRef = React.useRef<HTMLDivElement>(null);
  const prefAnchorRef = React.useRef<HTMLDivElement>(null);
  const cueAnchorRef = React.useRef<HTMLDivElement>(null);

  const iconList = [
    {
      id: 0,
      img: OrderMatch,
      unreadLogCount: unreadOrderMatchNotificationsCount,
      name: NotificationCategory.Preferences,
      ref: prefAnchorRef,
      eleId: CONSTANTS_NOTIFICATIONS.PREFERENCES.IMAGEID,
    },
    {
      id: 1,
      img: TaskAltSvg,
      unreadLogCount: unreadTaskNotificationsCount,
      name: NotificationCategory.Task,
      ref: cueAnchorRef,
      eleId: CONSTANTS_NOTIFICATIONS.TASK.IMAGEID,
    },
    {
      id: 2,
      img: CommunicationTab,
      name: NotificationCategory.Alert,
      unreadLogCount: unreadAlertNotificationsCount,
      ref: taskAnchorRef,
      eleId: CONSTANTS_NOTIFICATIONS.ALERT.IMAGEID,
    },
  ];

  const closeNotification = (e, closeToast) => {
    closeToast();
    e.stopPropagation();
  };

  const CloseButton = ({ closeToast }) => {
    return <ClearIcon className={classes.closeIcon} onClick={e => closeNotification(e, closeToast)} />;
  };

  const updateNotificationReadStatus = (messageId?: string) => {
    const updateReadStatusBody = updateReadNotification([], userInfo?.userInfo?.employeeId, messageId);
    dispatch(taskDetailsActions.sendNotificationReadStatus(updateReadStatusBody));
  };

  const sendNotificationforLogId = id => {
    const updateReadStatusBody = updateReadNotification([id], userInfo?.userInfo?.employeeId);
    if (id) {
      dispatch(taskDetailsActions.sendNotificationReadStatus(updateReadStatusBody));
    }
  };

  const getActivityFeedLog = (types = {}, currentTab = '') => {
    const availableFilters = getSelectedFiltersBasedOnTab(
      currentTab,
      appliedFilters ?? [],
      activityFeedRefactoring?.enabled,
    );
    dispatch(
      taskDetailsActions.getNotificationLogData(
        availableFilters?.length > 0
          ? payloadWithFilters(availableFilters, currentTab, activityFeedRefactoring?.enabled, userInfo, 1)
          : defaultLogsPayload(types, currentTab, userInfo, activityFeedRefactoring?.enabled, 1),
      ),
    );
  };

  const getUnreadCounts = () => {
    const UnreadPayload: IGetActivityFeedQuery = {
      ReadStatus: false,
      Activities: {
        ContactId: getEmployeeId(userInfo?.userInfo),
        UseSubTypes: [],
        UseTypes: [],
      },
      EmailDeliveries: {
        UserId: `${userInfo?.userInfo?.employeeId}`,
        DeliveryStatus: ['dropped', 'blocked', 'bounce', 'spamreport', 'unsubscribe'],
      },
    };
    dispatch(taskDetailsActions.getUnReadNotificationsCounts(UnreadPayload));
  };

  const onIconClick = icon => {
    dispatch(taskDetailsActions.setRequestedAlertCategory(icon.name));
    let typeArray = null;
    if (!activityFeedRefactoring?.enabled) {
      typeArray = {};
    } else {
      setCategory(icon.name);
      if (selectedFeedData.openPopper && selectedFeedData.currentPopper === icon.name) {
        setSelectedFeedData({ openPopper: false });
      } else {
        switch (icon.name) {
          case NotificationHeaderOptions.Preferences:
            typeArray = {
              type: ['order match'],
              subTypes: [],
            };
            break;
          case NotificationHeaderOptions.Task:
            typeArray = {
              type: ['Task'],
              subTypes: ['1', '2', '3', '4', '5'],
            };
            break;
          case NotificationHeaderOptions.Alert:
            typeArray = {
              type: ['Cue Note'],
              subTypes: ['6', '7'],
            };
            break;
          default:
            return [];
        }
      }
      setSelectedFeedData({
        id: icon.id,
        currentPopper: icon.name,
        openPopper: true,
        currentRef: icon.ref,
        selectedTypes: { type: typeArray?.type, subTypes: typeArray?.subTypes },
      });
    }

    getActivityFeedLog(typeArray, icon.name);
  };

  useEffect(() => {
    if (!clientContactFlag?.enabled) {
      dispatch(navigationStickActions.removeMenuByKey({ key: 'client' }));
    } else {
      dispatch(navigationStickActions.removeMenuByKey(undefined));
    }
    if (!activityFeedRefactoring?.enabled) {
      getUnreadCounts();
    }
    if (activityFeedRefactoring?.enabled) {
      dispatch(taskDetailsActions.businessInitNotifications());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dismissedNotificationId) {
      toast.dismiss(dismissedNotificationId);
      dispatch(taskDetailsActions.setDismissNotificationId(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dismissedNotificationId]);

  useEffect(() => {
    const notificationData = NotificationLogData?.results?.map(log => getNotificationData(log));
    setNotificationDataArray(notificationData);
    setTotalRecords(NotificationLogData?.totalRecords);
  }, [NotificationLogData]);

  React.useLayoutEffect(() => {
    // to trigger effect as soon as the dom is loaded
    showToast.current = isVisible;
  }, [isVisible]);

  useEffect(
    () => {
      const hubConnection = user?.userInfo?.hubConnection;
      if (hubConnection) {
        hubConnection.on('BroadcastTask', (connectedUser, eventName, log) => {
          trackEvent({
            type: 'event',
            name: `SignalR Hub`,
            properties: {
              NotificationType: SignalREventType.Task,
              UserId: connectedUser,
              CorrelationId: log?.correlationId,
              Log: log,
              Message: `Received task notification for user: ${connectedUser}`,
            },
          });
          const contentProps: ITaskNotificationPopupProps = {
            id: log?.id,
            name: log?.message?.tos.find(Boolean).name,
            taskId: log?.message?.tos.find(Boolean).contactId,
            body: log?.message?.body,
            associateRecords: log?.associatedRecords,
            attachments: log?.message?.attachmentUrls,
            useSubType: log?.useSubType,
          };
          if (connectedUser === getEmployeeId(user?.userInfo)) {
            if (activityFeedRefactoring?.enabled) {
              dispatch(
                taskDetailsActions.incrementNotificationCount({
                  notificationType: getNotificationType({
                    useType: log?.useType,
                    useSubType: log?.useSubType,
                    channelType: log?.channel,
                  }),
                }),
              );
            } else {
              sendNotificationforLogId(log?.id);
            }
          }
          contentProps?.associateRecords?.forEach(record => {
            if (record?.name === 'Description') {
              if (record?.value?.length > 50) {
                setMultiLine(true);
              } else {
                setMultiLine(false);
              }
            } else if (detailsModalOpen === false && record?.name === 'TaskId') {
              setTaskId(record?.value);
            }
            if (record?.name === 'PrimaryContext') {
              dispatch(taskDetailsActions.setNotificationPrimaryContext(record?.value));
            }
            if (record?.name === 'CurrentContainer') {
              switch (record?.value) {
                case 'Placement':
                  setTaskContainer(1);
                  dispatch(taskDetailsActions.setTaskNotificationContainer(TaskEntity.PLACEMENTS));
                  break;
                case 'Candidate':
                  setTaskContainer(2);
                  dispatch(taskDetailsActions.setTaskNotificationContainer(TaskEntity.CANDIDATES));
                  break;
                case 'Facility':
                  setTaskContainer(3);
                  dispatch(taskDetailsActions.setTaskNotificationContainer(TaskEntity.FACILITIES));
                  break;
                case 'Order':
                  setTaskContainer(4);
                  dispatch(taskDetailsActions.setTaskNotificationContainer(TaskEntity.ORDERS));
                  break;
                default:
                  setTaskContainer(1);
                  break;
              }
            }
          });
          const notification: INotificationFeedResponse = {
            contentProps: { ...contentProps, useType: SignalREventType.Task, isNotificationUnread: true },
            channel: NotificationCategory.Alert,
            multiLine: false,
          };
          eventName === HubEventType.TaskNotification &&
            showToast.current &&
            toast(
              <AlertFeed
                key={0}
                index={0}
                notifyData={notification}
                setTaskId={setTaskId}
                setRecordDetails={setRecordDetails}
                activityFeedRefactoringFlag={activityFeedRefactoring?.enabled}
                pushNotification={true}
                setDetailsModalOpen={setDetailsModalOpen}
              />,
              {
                toastId: log.id,
                containerId: 'task',
                position: 'top-right',
                closeButton: true,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoClose: 5000,
                pauseOnFocusLoss: false,
                onClick: () => {
                  contentProps?.associateRecords?.forEach(record => {
                    if (record?.name === 'TaskId') {
                      setTaskId(record?.value);
                      setDetailsModalOpen(true);
                    }
                  });
                },
              },
            );
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      <Tabs
        TabIndicatorProps={{ style: { background: '#52429A' } }}
        ref={activityFeedRefactoring?.enabled ? null : anchorRef}
        value={selectedFeedData?.currentPopper ? selectedFeedData?.currentPopper : false}
        aria-label="Alert Tab"
      >
        {activityFeedRefactoring?.enabled ? (
          iconList.map((icon, index) => (
            <Tab
              classes={{ root: classes.tab }}
              key={icon.id}
              ref={icon.ref}
              id={icon.eleId}
              value={icon.name}
              onClick={() => onIconClick(icon)}
              icon={
                <Badge
                  badgeContent={icon.unreadLogCount > 0 && icon.unreadLogCount > 99 ? '99+' : icon.unreadLogCount}
                  invisible={
                    !activityFeedRefactoring?.enabled &&
                    selectedFeedData.currentPopper === icon.name &&
                    selectedFeedData.openPopper
                  }
                  color={'error'}
                  className={unreadLogCount > 99 ? classes.countBadge : classes.badge}
                >
                  <img src={icon.img} alt="Alert" id={icon.name} />
                </Badge>
              }
            />
          ))
        ) : (
          <>
            <Tab
              classes={{ root: classes.tab }}
              value={true}
              onClick={() => {
                if (!selectedFeedData?.openPopper) {
                  setSelectedFeedData({
                    currentPopper: 'Task',
                    openPopper: true,
                  });
                  getActivityFeedLog();
                  updateNotificationReadStatus();
                } else
                  setSelectedFeedData({
                    currentPopper: null,
                    openPopper: false,
                  });
              }}
              icon={
                <Badge
                  badgeContent={unreadLogCount > 0 && unreadLogCount > 99 ? '99+' : unreadLogCount}
                  invisible={selectedFeedData.openPopper || unreadLogCount === 0}
                  color={'error'}
                  className={unreadLogCount > 99 ? classes.countBadge : classes.badge}
                >
                  <img src={Alert} alt="Alert" />
                </Badge>
              }
            />
          </>
        )}
      </Tabs>

      <Popper
        open={selectedFeedData.openPopper}
        id={selectedFeedData.currentPopper}
        anchorEl={!activityFeedRefactoring?.enabled && anchorRef?.current}
        role={undefined}
        style={{ zIndex: '1300' }}
      >
        <ActivityFeedV2
          notificationPopupData={notificationDataArray!}
          setNotificationDataArray={setNotificationDataArray}
          totalRecords={totalRecords}
          setDetailsModalOpen={setDetailsModalOpen}
          setTaskId={setTaskId}
          setRecordDetails={setRecordDetails}
          appliedFilters={appliedFilters}
          activityFeedRefactoring={activityFeedRefactoring?.enabled}
          selectedFeedData={selectedFeedData}
          category={category}
          setSelectedFeedData={setSelectedFeedData}
        />
      </Popper>

      <Grid item className={classes.centerItem}></Grid>

      <div style={{ position: 'fixed' }}>
        <StyledToastContainer enableMultiContainer containerId={'task'} limit={3} closeButton={CloseButton} />
      </div>
      {detailsModalOpen && (
        <TaskDetails
          taskID={taskId}
          open={detailsModalOpen}
          container={taskContainer}
          setDetailsModalOpen={setDetailsModalOpen}
          handleClose={() => {
            dispatch(pageAttributesActions.setPopperOpen(false));
            setDetailsModalOpen(false);
          }}
          setStatusChanged={setStatusChanged}
          statusChanged={statusChanged}
        />
      )}
      {activityFeedRefactoring?.enabled &&
        recordDetails?.openCreatePlacementForm &&
        recordDetails?.CandidateId &&
        recordDetails?.OrderId &&
        recordDetails?.BrandId && (
          <CreatePlacementWrapper
            isDefaultCandidate
            isDefaultOrder
            open={activityFeedRefactoring?.enabled && recordDetails?.openCreatePlacementForm}
            sessionKey={SessionKey.createPlacementNotifications}
            handleClose={() => setRecordDetails(prev => ({ ...prev, openCreatePlacementForm: false }))}
            initialArgs={{
              candidateId: parseInt(recordDetails.CandidateId),
              brandId: parseInt(recordDetails.BrandId),
              orderId: parseInt(recordDetails.OrderId),
            }}
          />
        )}
    </>
  );
};
