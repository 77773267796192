import React from 'react';
import { Status } from '../Status';
import { useSelector } from 'react-redux';
import {
  Application,
  Audit,
  CandidateStatus,
  Education as AmnEducationReq,
  Experience as AmnExperienceReq,
  WorkHistory as AmnWorkHistory,
  References as AmnReferenceReq,
  SkillsChecklist as AmnSkillsChecklistReq,
  AmnSkillsetRequirement,
} from './amnRequirementRows';
import { Concatenate } from 'utils/string/string';
import { selectPlacementHeaderData } from 'store/redux-store/placement-details/selectors';
import {
  Certifications,
  Covid,
  DateOfBirth,
  Dates,
  Documents,
  License,
  MilesRadius,
  VirtualInterview,
  Experience as OrderExperienceReq,
  WorkHistory as OrderWorkHistory,
  References as OrderReferenceReq,
  Skillset as OrderSkillsetReq,
  Status as OrderStatusReq,
  Schedule,
  SkillsChecklist as OrderSkillsChecklistReq,
  SocialSecurityNum,
} from './orderRequirementRows';
import { useTranslation } from 'react-i18next';
import { selectSubmissionInfo } from '../../../../../../../../store/redux-store/placement-summary/selectors';
import { useSubmissionRequirementCounts } from '../RequirementCounts/SubmissionRequirmentCounts';
import { useDecision } from '@optimizely/react-sdk';
import {
  ff_SubmissionAmnReqReferences,
  ff_placement_ui_and_or_containers,
} from 'app/constants/FeatureFlags/Placement/Keys';

export const Submission = ({ ...props }) => {
  const { t } = useTranslation();
  const {
    amnReq: { reqCounts: amnReqCounts },
    orderReq: { reqCounts: orderReqCounts },
    requirementFilter,
    skillReq: { reqCounts: skillReqCounts, containers: skillContainers },
  } = useSelector(selectSubmissionInfo);

  const { candidate } = useSelector(selectPlacementHeaderData);
  useSubmissionRequirementCounts();
  const [ShowAmnReferenceReq] = useDecision(ff_SubmissionAmnReqReferences);
  const [orderGeneralRequirement] = useDecision(ff_placement_ui_and_or_containers);

  return (
    <Status
      id="placement-submission-container"
      expanded={false}
      title={t('Submission')}
      candidateName={Concatenate([candidate?.firstName, candidate?.middleName || '', candidate?.lastName], ' ')}
      amnCounts={amnReqCounts}
      orderCounts={orderReqCounts}
      skillReqCounts={skillReqCounts}
      skillContainers={skillContainers}
      requirementFilter={requirementFilter}
      amnTableRows={
        <React.Fragment>
          <AmnSkillsetRequirement />
          <CandidateStatus />
          <Audit />
          <AmnEducationReq />
          <AmnExperienceReq />
          <AmnWorkHistory />
          {ShowAmnReferenceReq?.enabled ? <AmnReferenceReq /> : <></>}
          <AmnSkillsChecklistReq />
          <Application />
        </React.Fragment>
      }
      orderTableRows={
        <React.Fragment>
          {!orderGeneralRequirement?.enabled && <OrderSkillsetReq />}
          <OrderStatusReq />
          <Dates />
          <Schedule />
          <VirtualInterview />
          {!orderGeneralRequirement?.enabled && <OrderExperienceReq />}
          <OrderWorkHistory />
          <OrderReferenceReq />
          {!orderGeneralRequirement?.enabled && <OrderSkillsChecklistReq />}
          {!orderGeneralRequirement?.enabled && <License />}
          {!orderGeneralRequirement?.enabled && <Certifications />}
          <MilesRadius />
          {/* Temporarily removing row - Interview 
          <Interview /> */}
          <Covid />
          <DateOfBirth />
          <SocialSecurityNum />
          <Documents />
        </React.Fragment>
      }
    />
  );
};
