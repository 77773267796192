import { RequirementType } from 'app/enums/Facility';
import { AndOrOperator } from 'app/enums/Common';

export const validateDuplicateCertification = (requirementIndex, requirements, t) => {
  const certificationToCheck = requirements[requirementIndex];
  if (
    requirementIndex === 0 ||
    !certificationToCheck?.certificates ||
    certificationToCheck?.certificates?.object?.requirementTypeID === RequirementType.cert
  ) {
    return undefined;
  }
  const previousValidCertifications = requirements?.filter(
    (value, idx) => idx !== requirementIndex && value.certificates !== null,
  );

  if (!certificationToCheck.subCertificate?.length && !certificationToCheck.issuedBy) {
    if (
      previousValidCertifications.find(
        value =>
          !value.subCertificate?.length &&
          !value.issuedBy &&
          value.certificates.object.requirementTypeID === certificationToCheck.certificates.object.requirementTypeID,
      )
    ) {
      return `${t('facility.units.unitDetails.unitRequirements.duplicated')}`;
    } else {
      return undefined;
    }
  } else if (!certificationToCheck.issuedBy) {
    if (
      previousValidCertifications.find(
        value =>
          !value.issuedBy &&
          value.certificates.object.requirementTypeID === certificationToCheck.certificates.object.requirementTypeID &&
          value.subCertificate?.length === certificationToCheck.subCertificate?.length &&
          value.subCertificate?.every(obj1 =>
            certificationToCheck.subCertificate?.some(obj2 => obj1?.subCertificate?.id === obj2?.subCertificate?.id),
          ),
      )
    ) {
      return `${t('facility.units.unitDetails.unitRequirements.duplicated')}`;
    } else {
      return undefined;
    }
  } else if (!certificationToCheck.subCertificate?.length) {
    if (
      previousValidCertifications.find(
        value =>
          !value.subCertificate?.length &&
          value.certificates.object.requirementTypeID === certificationToCheck.certificates.object.requirementTypeID &&
          value.issuedBy?.object?.id === certificationToCheck.issuedBy?.object?.id,
      )
    ) {
      return `${t('facility.units.unitDetails.unitRequirements.duplicated')}`;
    } else {
      return undefined;
    }
  } else {
    if (
      previousValidCertifications.find(
        value =>
          value.certificates.object.requirementTypeID === certificationToCheck.certificates.object.requirementTypeID &&
          (value.issuedBy?.object?.id === certificationToCheck.issuedBy?.object?.id ||
            (value.subCertificate?.length === certificationToCheck.subCertificate?.length &&
              value.subCertificate?.every(obj1 =>
                certificationToCheck.subCertificate?.some(
                  obj2 => obj1?.subCertificate?.id === obj2?.subCertificate?.id,
                ),
              ))),
      )
    ) {
      return `${t('facility.units.unitDetails.unitRequirements.duplicated')}`;
    } else {
      return undefined;
    }
  }
};

export const validateDuplicateSkillset = (skillSetCollection, skillSetIndex, operation, enableDuplicateSkillSet, t) => {
  const skillSetToCheck = skillSetCollection?.[skillSetIndex]?.skillSet;
  const isAnd = `${operation}` === AndOrOperator.And;  

  if (skillSetIndex === 0 || !skillSetToCheck?.discipline || !skillSetToCheck?.specialty) {
    return undefined;
  }
  const previousValidSkillSets = skillSetCollection.slice(0, skillSetIndex).map(skillSet => skillSet?.skillSet);
  if (!skillSetToCheck?.subSpecialty) {
    if (isAnd && enableDuplicateSkillSet &&
      previousValidSkillSets.find(
        value =>
          !value.subSpecialty &&
          value?.discipline?.object?.ID === skillSetToCheck?.discipline?.object?.ID &&
          value?.specialty?.object?.SpecialtyID === skillSetToCheck?.specialty?.object?.SpecialtyID,
      )
    ) {
      return `${t('facility.units.unitDetails.unitRequirements.duplicated')}`;
    } 
    else  if (!enableDuplicateSkillSet &&
      previousValidSkillSets.find(
        value =>
          !value.subSpecialty &&
          value?.discipline?.object?.ID === skillSetToCheck?.discipline?.object?.ID &&
          value?.specialty?.object?.SpecialtyID === skillSetToCheck?.specialty?.object?.SpecialtyID,
      )
    ) {
      return `${t('facility.units.unitDetails.unitRequirements.duplicated')}`;
    } else {
      return undefined;
    }
  } else {
    if (isAnd && enableDuplicateSkillSet && 
      previousValidSkillSets.find(
        value =>
          value?.discipline?.object?.ID === skillSetToCheck?.discipline?.object?.ID &&
          value?.specialty?.object?.SpecialtyID === skillSetToCheck?.specialty?.object?.SpecialtyID &&
          value?.subSpecialty &&
          value?.subSpecialty?.object?.SubSpecialtyID === skillSetToCheck?.subSpecialty?.object?.SubSpecialtyID,
      )
    ) {
      return `${t('facility.units.unitDetails.unitRequirements.duplicated')}`;
    } 
    else if (!enableDuplicateSkillSet && 
      previousValidSkillSets.find(
        value =>
          value?.discipline?.object?.ID === skillSetToCheck?.discipline?.object?.ID &&
          value?.specialty?.object?.SpecialtyID === skillSetToCheck?.specialty?.object?.SpecialtyID &&
          value?.subSpecialty &&
          value?.subSpecialty?.object?.SubSpecialtyID === skillSetToCheck?.subSpecialty?.object?.SubSpecialtyID,
      )
    ) {
      return `${t('facility.units.unitDetails.unitRequirements.duplicated')}`;
    } else {
      return undefined;
    }
  } 
};
export const validateOrderDuplicateSkillset = (skillSetCollection, skillSetIndex, t) => {
  const skillSetToCheck = skillSetCollection?.[skillSetIndex]?.skillSet;

  if (skillSetIndex === 0 || !skillSetToCheck?.discipline || !skillSetToCheck?.specialty) {
    return undefined;
  }
  const previousValidSkillSets = skillSetCollection.slice(0, skillSetIndex).map(skillSet => skillSet?.skillSet);
  if (!skillSetToCheck?.subSpecialty) {
    if (
      previousValidSkillSets.find(
        value =>
          !value.subSpecialty &&
          value?.discipline?.object?.ID === skillSetToCheck?.discipline?.object?.ID &&
          value?.specialty?.object?.SpecialtyID === skillSetToCheck?.specialty?.object?.SpecialtyID,
      )
    ) {
      return `${t('facility.units.unitDetails.unitRequirements.duplicated')}`;
    } else {
      return undefined;
    }
  } else {
    if (
      previousValidSkillSets.find(
        value =>
          value?.discipline?.object?.ID === skillSetToCheck?.discipline?.object?.ID &&
          value?.specialty?.object?.SpecialtyID === skillSetToCheck?.specialty?.object?.SpecialtyID &&
          value?.subSpecialty &&
          value?.subSpecialty?.object?.SubSpecialtyID === skillSetToCheck?.subSpecialty?.object?.SubSpecialtyID,
      )
    ) {
      return `${t('facility.units.unitDetails.unitRequirements.duplicated')}`;
    } else {
      return undefined;
    }
  }
};
