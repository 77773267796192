/* eslint-disable react-hooks/exhaustive-deps */
import {
  GridApi,
  GridSortModel,
  GridColumns as XGridColumns,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridCallbackDetails,
  GridDensity,
  GridPinnedColumns,
} from '@mui/x-data-grid-pro';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { CustomBreadcrums } from 'app/components/Common/BreadcrumbNavigation/CustomBreadcrums';
import { DrawerFitted } from 'app/components/Common/Drawer/Drawer';
import { selectGridSelection } from 'app/components/Common/Grid/GridSelection/GridSelection.selector';
import { selectGridState } from 'app/components/Common/Grid/GridStateManagement/GridState.selectors';
import { XGrid } from 'app/components/Common/XGrid/XGrid';
import { OrderPreviewDrawerViewer } from 'app/components/GlobalSearch/Order/OrderPreviewDrawerViewer';
import { GridColumns } from 'app/models/GlobalSearch/GlobalSearch';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { usePromiseTracker } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectSearchResults } from 'store/redux-store/global-search/selectors';
import { globalSearchActions, totalCountErrorThreshold } from 'store/redux-store/global-search/slice';
import { candidateDetailsSelection } from '../../../Profile/CandidateDetails.selector';
import { RouteParams } from '../../../Profile/CandidateProfile';
import { selectJobPreferencesData, selectPreferenceId } from '../../JobPreferencesTab/store/JobPreferences.selectors';
import { getCandidateOrderMatchActions } from '../store/candidateOrderMatch.redux';
import {
  selectAppliedFilters,
  selectCandidateOrderMatch,
  selectCandidateOrderMatchId,
  selectModalProps,
} from '../store/candidateOrderMatch.selector';
import { orderMatchesColumnVisibilityModel, OrderMatchesViewInfo } from './OrderMatchesGridUtils';
import { ChannelType, FormName, SendType, UseType } from 'app/components/Notification/Constants';
import { selectOrderMatchAlert, selectUnreadOrderIds } from '../AlertStore/OrderMatchAlert.selector';
import { selectUser } from 'oidc/user.selectors';
import { getOrderMatchAlertActions } from '../AlertStore/OrderMatchAlert.redux';
import OrderComparisionModal from '../OrderComparisionModal/OrderComparisionModal';
import { getOrderDetailsWithPlacements } from 'app/services/OrderServices/OrderServices';
import { missingField } from 'app/constants';
import { getOrderComparisionTableRows } from './OrderComparisionTableUtils';
import moment from 'moment';
import { selectUserPreference } from 'oidc/UserDevicePreference/userPreference.selectors';
import { RootState } from 'types';
import { gridStateActions, IGridState, SortColumn } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.redux';
import { userDevicePreferenceActions } from 'oidc/UserDevicePreference/userPreference.redux';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import { getDistance } from 'app/helpers/distanceHelpers';
import { getSearchFilterOptions } from 'app/services/SharedServices/SharedServices';
import { gridSelectionActions } from '@AMIEWEB/Common/Grid/GridSelection/GridSelection.redux';
import { useDecision } from '@optimizely/react-sdk';
import { ff_candidate_ui_preferences, ff_WorkExperience_Enhancement } from 'app/constants/FeatureFlags/Candidate/Keys';
import {
  formatMultiSortColumns,
  formatMultiSortXGridColumns,
  signatureSubstitutionToken,
  sortOrderData,
} from '@AMIEWEB/GlobalSearch/helper';
import { Concatenate } from 'utils/string/string';
import { IToken } from 'app/models/Notification/Notification';
import { GetTemplatesByCategory, ApplyDataToTemplate } from 'app/services/NotificationServices/TemplateService';
import { notificationDataActions } from 'store/redux-store/notification/notification.redux';
import { notificationSelection } from 'store/redux-store/notification/notification.selector';
import { Authorized } from 'oidc/userHelper';
import { userRoles } from 'oidc/userRoles';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { CustomBackdrop } from '@AMIEWEB/Common';
import { useEnableEmailCommunication } from 'oidc/CommunicationEnabler';
import { updateOrderMatchReadStatus } from 'app/services/CandidateServices/CandidateServices';
import { mappedPayload } from './OrderMatches';
import { jobPreferncesActions } from '../../JobPreferencesTab/store/JobPreferences.redux';
import { materialUiXGrid, useTheme } from 'amn-ui-core';
import { getEmployeeId } from '@AMIEWEB/Notification/Tasks/Common_v2/utils';

const OrderMatchesGrid = props => {
  const gridTag = `CandidateOrderMatch`;
  const gridFiltersTag = `CandidateOrderMatchFilters-${props.candidateId}`;
  const resultMapView = 'candidateOrdersMatchMapview';
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const apiRef = materialUiXGrid.useGridApiRef();
  const isPageSizeChangeRef = React.useRef(false);
  const routeParams = useParams<RouteParams>();
  const { promiseInProgress } = usePromiseTracker({ area: 'candidate-order-match-call', delay: 0 });
  const [enableJobPreferences] = useDecision(ff_candidate_ui_preferences);
  const [hasWorkExperienceTab] = useDecision(ff_WorkExperience_Enhancement);

  const theme = useTheme();

  //useSelector
  const userPreference = useSelector(selectUserPreference);
  const gridPreferences = useSelector((state: RootState) => {
    return state.userPreferenceData.userGridPreferences?.find(item => item.id === gridTag);
  });
  const gridFilterPreferences = useSelector((state: RootState) => {
    return state.userPreferenceData.userGridPreferences?.find(item => item.id === gridFiltersTag);
  });
  const gridState = useSelector(selectGridState);
  const { userInfo } = useSelector(selectUser);
  let candidateDetails = useSelector(candidateDetailsSelection);
  const { drawerData } = useSelector(selectSearchResults);
  const { items: rows, count } = useSelector(selectCandidateOrderMatch);
  const orderLogResultData = useSelector(selectOrderMatchAlert);
  const { selectedData } = useSelector(selectGridSelection);
  let prefId = useSelector(selectCandidateOrderMatchId);
  const { jobPreferencesData, jobPreferencesGridData } = useSelector(selectJobPreferencesData);
  const appliedFilters = useSelector(selectAppliedFilters);
  const newUnradOrders = useSelector(selectUnreadOrderIds);
  const orderPreferenceId = useSelector(selectPreferenceId);
  let orderPrefId = prefId ?? orderPreferenceId;
  //useState
  const [showMapView, setShowMapView] = React.useState<boolean>(false);
  const [fetchData, setFetchData] = React.useState<boolean>(false);
  const [disableMapView, setDisableMapView] = React.useState<boolean>(true);
  const [pageBreadCrumbs, setBreadCrumbs] = React.useState([]);

  const gridStateRef = React.useRef<IGridState>(gridState);
  gridStateRef.current = gridState;
  const defaultSort = { column: 'orderId', direction: 'desc' };
  const orderLogResult = orderLogResultData.items;
  const jobData = jobPreferencesData?.filter(item => item?.id === orderPrefId)?.[0];
  const unreadCount = jobData?.alertsSent - jobData?.alertsSeen;
  const candidateId = routeParams.travelerId;
  const brandId = routeParams.brandId;
  const data = jobPreferencesData?.filter(item => item?.id === orderPreferenceId)?.[0];
  const assignedRecruiter = candidateDetails.recruiter.recruiterId;
  const checkAssignedRecruiter = parseInt(getEmployeeId(userInfo)) === assignedRecruiter;
  const user = useSelector(selectUser);

  //xgrid useState
  const [defaultColumns, setDefaultColumns] = React.useState<any[]>([...OrderMatchesViewInfo(t)]);
  const [defaultColumnVisibilityModel, setDefaultColumnVisibilityModel] = React.useState<any>(
    _.cloneDeep(orderMatchesColumnVisibilityModel),
  );
  const [defaultSortModel, setDefaultSortModel] = React.useState<any[]>([{ field: 'orderId', sort: 'desc' }]);

  const [currentColumns, setCurrentColumns] = React.useState<any[]>([...OrderMatchesViewInfo(t)]);
  const [currentColumnVisibilityModel, setCurrentColumnVisibilityModel] = React.useState<any>(
    _.cloneDeep(orderMatchesColumnVisibilityModel),
  );
  const [currentSortModel, setCurrentSortModel] = React.useState<any[]>([{ field: 'orderId', sort: 'desc' }]);
  const tableRows = getOrderComparisionTableRows();
  const preferenceId = useSelector(selectCandidateOrderMatchId);
  const notificationData = useSelector(notificationSelection);
  const [openBackDrop, setOpenBackDrop] = React.useState<boolean>(false);
  const enableEmailCommunication = useEnableEmailCommunication();
  const [filterModel, setFilterModel] = React.useState<GridFilterModel>({ items: [] });
  const [pinnedColumns, setPinnedColumns] = React.useState<GridPinnedColumns>({});
  const [density, setDensity] = React.useState<GridDensity>('compact');
  const { open: comparisonModalOpen } = useSelector(selectModalProps);

  const getLatestOrderFromPreference = () => {
    const orders: any[] = [];
    if (jobData) {
      jobData.orderMatches
        .filter(o => o.isRead === false)
        .map(orderMatch => {
          if (orderMatch.orderId) {
            const duplicateCheck = orders.find(x => x === parseInt(orderMatch.orderId));
            if (duplicateCheck > 0) {
              console.log(orderMatch.orderId, 'already exist');
            } else {
              orders.push(parseInt(orderMatch.orderId));
            }
          }
          return orderMatch;
        });
    }
    if (unreadCount > 0) {
      dispatch(getOrderMatchAlertActions.setUnreadOrderIds(orders));
    }
  };

  const getBreadCrumbs = preferenceId => {
    const redirectTabNumber =
      enableJobPreferences?.enabled && hasWorkExperienceTab?.enabled
        ? 6
        : !enableJobPreferences?.enabled && !hasWorkExperienceTab?.enabled
        ? 4
        : 5;
    const data = jobPreferencesData?.filter(item => item?.id === preferenceId)?.[0];
    if (data) {
      setBreadCrumbs([
        {
          title: 'Order Preference',
          path: `/candidate/${candidateId}/${brandId}?tab=${redirectTabNumber}`,
          clickable: true,
        },
        { title: `${data?.preferenceSetName ? data?.preferenceSetName : ''}` },
        { title: `Orders (${selectedData?.length > 0 ? selectedData?.length : count})` },
      ]);
    }
  };

  //TODO: - This needs to be removed after we exclude all logic from Alerts container in back-end
  const updateReadStatus = () => {
    const readStatusPayload = {
      jobPreferenceId: preferenceId ? `${preferenceId}` : `${orderPreferenceId}`,
    };

    updateOrderMatchReadStatus(readStatusPayload)
      .then(response => {
        dispatch(
          jobPreferncesActions.getJobPreferencesAction({
            travelerId: candidateDetails.travelerId,
            brandId: candidateDetails.brandId,
          }),
        );
      })
      .catch(err =>
        dispatch(
          globalActions.setSnackBar({
            message: t('Failed to update order read status'),
            severity: 'error',
          }),
        ),
      );
  };

  const handleFetchData = React.useCallback(
    (pageSize: number, page: number, sortedColumn?: any, multiSortedColumns?: SortColumn[]) => {
      let requestPayload;
      let orderMatchId;
      if (orderPreferenceId) {
        orderMatchId = orderPreferenceId;
      } else {
        orderMatchId = prefId;
      }
      const data = jobPreferencesData?.filter(item => item?.id === orderMatchId)?.[0];
      if (orderMatchId) {
        let rowData = mappedPayload(data);
        requestPayload = _.cloneDeep(rowData);
      }
      requestPayload = {
        ...requestPayload,
        sortedColumn: sortedColumn || gridState.sortedColumn,
        pageSize: pageSize,
        pageNumber: page,
        multiSortedColumns: multiSortedColumns || gridState.multiSortedColumns,
      };
      const payload = _.cloneDeep(requestPayload);
      dispatch(getCandidateOrderMatchActions.setPayload(requestPayload));
      const orderBillRate = requestPayload?.orderBillRate;
      if (appliedFilters) {
        requestPayload = {
          ...requestPayload,
          ...appliedFilters,
        };
      }
      if (appliedFilters?.orderBillRate === null) {
        requestPayload.orderBillRate = orderBillRate;
      }

      if (payload?.orderBillRate && appliedFilters?.orderBillRate) {
        if (
          payload?.orderBillRate?.max < appliedFilters?.orderBillRate?.min ||
          payload?.orderBillRate?.min > appliedFilters?.orderBillRate?.max
        ) {
          dispatch(
            getCandidateOrderMatchActions.setData({
              items: [],
              count: 0,
            }),
          );
        } else {
          dispatch(
            getCandidateOrderMatchActions.getCandidateOrderMatchAction({
              translation: t,
              keyword: '',
              ...requestPayload,
            }),
          );
        }
      } else {
        dispatch(
          getCandidateOrderMatchActions.getCandidateOrderMatchAction({
            translation: t,
            keyword: '',
            ...requestPayload,
          }),
        );
      }
      dispatch(gridSelectionActions.reset());
    },
    // eslint-disable-next-line max-len, react-hooks/exhaustive-deps
    [
      gridState.sortedColumn,
      gridState.pageNumber,
      gridState.pageSize,
      candidateDetails,
      gridState.multiSortedColumns,
      appliedFilters,
    ],
  );

  const saveSortModel = React.useCallback(
    sortModel => {
      const sortedColumn =
        sortModel?.length > 0
          ? {
              column: sortModel[0].field,
              direction: sortModel[0].sort,
            }
          : defaultSort;

      const multiSortedColumns = sortModel?.length > 0 ? formatMultiSortColumns(sortModel) : [defaultSort];

      if (
        !_.isEqual(sortedColumn, gridState?.sortedColumn) ||
        !_.isEqual(multiSortedColumns, gridState?.multiSortedColumns)
      ) {
        const preferenceData = {
          id: gridTag,
          value: {
            ...(gridPreferences?.value || {}),
            sortedColumn: sortedColumn,
            multiSortedColumns: multiSortedColumns,
          },
        };

        dispatch(userDevicePreferenceActions.saveUserGridPreferenceAction(preferenceData));
      }
    },
    [
      currentColumnVisibilityModel,
      dispatch,
      gridPreferences?.value,
      gridState?.sortedColumn,
      gridState?.multiSortedColumns,
    ],
  );

  const savePreferences = React.useCallback(
    ({
      field,
      columns: gridColumns,
      visibilityModel,
    }: {
      field?: string;
      columns: XGridColumns;
      visibilityModel: GridColumnVisibilityModel;
    }) => {
      if (!currentColumns || currentColumns.length === 0 || !userPreference.loaded) return;
      const currentGridColumns = new GridColumns(gridColumns).columns;

      if (currentGridColumns && currentGridColumns.length > 0 && currentGridColumns[0].field === '__check__')
        currentGridColumns.shift();

      const comparableColumns = new GridColumns(
        gridPreferences?.value?.columns,
        gridPreferences?.value?.columnVisibilityModel,
      );
      const columnsEqual = comparableColumns.isEqual(currentGridColumns);
      const visibilityEqual = comparableColumns.isVisibilityModelEqual(visibilityModel);

      if (!columnsEqual || !visibilityEqual) {
        const preferenceData = {
          id: gridTag,
          value: {
            ...(gridPreferences?.value || {}),
            columns: currentGridColumns,
            columnVisibilityModel: visibilityModel,
          },
        };
        dispatch(userDevicePreferenceActions.saveUserGridPreferenceAction(preferenceData));
      }
    },
    [currentColumns, userPreference.loaded, defaultColumns?.length, gridPreferences?.value, dispatch],
  );

  const getColumnPreferences = (cols: any[]) => {
    try {
      const columnPreferences = gridPreferences?.value;
      if (columnPreferences && columnPreferences.columns) {
        const newColumns = cols.map(column => {
          const foundIndex = columnPreferences.columns.findIndex(item => item?.field === column?.field);
          if (foundIndex !== -1) {
            return {
              ...column,
              ...columnPreferences.columns[foundIndex],
            };
          } else {
            return column;
          }
        });
        return newColumns.sort((a, b) => {
          return (
            columnPreferences.columns.findIndex(i => i.field === a.field) -
            columnPreferences.columns.findIndex(i => i.field === b.field)
          );
        });
      }
      return cols;
    } catch (err) {
      return cols;
    }
  };

  const getVisibilityPreferences = (cols: any) => {
    const columnPreferences = gridPreferences?.value;
    if (columnPreferences && columnPreferences?.columnVisibilityModel) {
      return columnPreferences?.columnVisibilityModel;
    }
    return cols;
  };

  const getSortModelPreferences = (sortModel: any[]) => {
    const columnPreferences = gridPreferences?.value;
    if (
      columnPreferences &&
      columnPreferences?.multiSortedColumns &&
      Array.isArray(columnPreferences?.multiSortedColumns)
    ) {
      return formatMultiSortXGridColumns(columnPreferences?.multiSortedColumns);
    }
    return sortModel;
  };

  const determineGridColumns = () => {
    const currentGridColumns = getColumnPreferences(defaultColumns);
    setCurrentColumns(currentGridColumns);
  };

  const determineVisibilityModel = () => {
    const currentVisibilityModel = getVisibilityPreferences(defaultColumnVisibilityModel);
    setCurrentColumnVisibilityModel(currentVisibilityModel);
  };

  const determineSortModel = () => {
    const currentGridSortModel = getSortModelPreferences(defaultSortModel);
    setCurrentSortModel(currentGridSortModel);
    if (
      !gridState?.sortedColumn ||
      (gridState?.sortedColumn &&
        (gridState.sortedColumn.column !== currentGridSortModel[0].field ||
          gridState.sortedColumn.direction !== currentGridSortModel[0].sort))
    )
      dispatch(
        gridStateActions.setSortedColumn({
          column: currentGridSortModel[0].field,
          direction: currentGridSortModel[0].sort,
        }),
      );
    dispatch(gridStateActions.setMultiSortedColumn(gridPreferences?.value.multiSortedColumns));
  };

  const determinePinnedColumns = () => {
    if (gridPreferences?.value?.pinnedColumns) {
      setPinnedColumns(gridPreferences?.value?.pinnedColumns);
    }
  };

  const determineDensity = () => {
    if (gridPreferences?.value?.gridDensity) {
      setDensity(gridPreferences?.value?.gridDensity);
    }
  };

  React.useEffect(() => {
    if (userPreference?.loaded) {
      dispatch(gridStateActions.setGrid(gridTag));
      determineGridColumns();
      determineVisibilityModel();
      determineSortModel();
      determineFilters();
      determinePinnedColumns();
      determineDensity();
    }
  }, [userPreference?.loaded, gridPreferences]);

  React.useEffect(() => {
    handleFetchData(
      250,
      1,
      gridState?.sortedColumn ?? { column: 'orderId', direction: 'desc' },
      gridState?.multiSortedColumns,
    );
  }, [prefId, preferenceId, appliedFilters]);

  React.useEffect(() => {
    if (orderPreferenceId) {
      getBreadCrumbs(orderPreferenceId);
    } else if (prefId) {
      getBreadCrumbs(prefId);
    }
    getLatestOrderFromPreference();
  }, [prefId, jobPreferencesData, orderPreferenceId, count, selectedData]);

  React.useEffect(() => {
    if (orderLogResult?.length > 0) {
    }
  }, [orderLogResult, rows]);

  React.useEffect(() => {
    if (checkAssignedRecruiter) {
      return () => {
        updateReadStatus();
        dispatch(getOrderMatchAlertActions.setUnreadOrderIds([]));
      };
    }
  }, [orderLogResult, orderPreferenceId]);

  React.useEffect(() => {
    return () => {
      dispatch(getCandidateOrderMatchActions.setId(undefined));
      dispatch(jobPreferncesActions.setPreferenceId(undefined));
    };
  }, []);

  /** Show or Hide / Enable or disable Map View action button */
  React.useEffect(() => {
    setShowMapView(true);
    if (count == 0 || count > 500) {
      setDisableMapView(true);
      localStorage.removeItem(resultMapView);
    } else {
      setDisableMapView(false);
      const resultMapViewLocations: any = [];
      rows?.forEach((item: any) => {
        resultMapViewLocations.push({
          infoHtml: '<b>' + item.facility + '</b> <br/>' + item.city + ',' + item.stateName,
          latitude: item.facilityCoordinates.latitude,
          longitude: item.facilityCoordinates.longitude,
        });
      });
      localStorage.removeItem(resultMapView);
      localStorage.setItem(resultMapView, JSON.stringify(resultMapViewLocations));
    }
  }, [rows, count]);

  const onSortModelChanged = React.useCallback(
    (model: GridSortModel) => {
      const pagination = apiRef?.current?.state?.pagination;
      const multiSortedColumns = model?.length > 0 ? formatMultiSortColumns(model) : [defaultSort];
      if (pagination) {
        setFetchData(true);
        handleFetchData(
          pagination.pageSize,
          pagination.page + 1,
          model.length > 0
            ? {
                column: model[0].field,
                direction: model[0].sort,
              }
            : defaultSort,
          multiSortedColumns,
        );
      }
      saveSortModel(model);
      setCurrentSortModel(model);
    },
    [
      handleFetchData,
      gridState?.sortedColumn,
      gridState?.pageNumber,
      gridState?.pageSize,
      gridState?.multiSortedColumns,
    ],
  );

  const onPageChange = (page: number) => {
    if (isPageSizeChangeRef.current) {
      isPageSizeChangeRef.current = false;
      return;
    }
    const pagination = apiRef?.current?.state?.pagination;
    if (pagination) {
      handleFetchData(pagination.pageSize, page + 1);
    }
  };

  const onPageSizeChange = (pageSize: number) => {
    if (gridState.pageSize !== pageSize) isPageSizeChangeRef.current = true;
    handleFetchData(pageSize, 1);
  };

  const handleRefreshGrid = () => {
    setFetchData(true);
    handleFetchData(gridState.pageSize, gridState.pageNumber);
  };

  const handlePinnedColumnsChange = React.useCallback(
    (updatedPinnedColumns: GridPinnedColumns) => {
      setPinnedColumns(updatedPinnedColumns);
      const preferenceData = {
        id: gridTag,
        value: {
          ...(gridPreferences?.value || {}),
          pinnedColumns: updatedPinnedColumns,
        },
      };
      dispatch(userDevicePreferenceActions.saveUserGridPreferenceAction(preferenceData));
    },
    [pinnedColumns, userPreference.loaded, gridPreferences?.value, dispatch],
  );

  const handleGridChanges = state => {
    if (state.density.value !== density) {
      setDensity(state.density.value);
      const preferenceData = {
        id: gridTag,
        value: {
          ...(gridPreferences?.value || {}),
          gridDensity: state.density.value,
        },
      };
      dispatch(userDevicePreferenceActions.saveUserGridPreferenceAction(preferenceData));
    }

    const current = apiRef.current;
    const currentGridColumns: any = _.cloneDeep(current.getAllColumns());
    if (currentGridColumns && currentGridColumns.length > 0 && currentGridColumns[0].field === '__check__')
      currentGridColumns.shift();
    const fields = _.cloneDeep(current.state.columns.all);
    if (fields.length > 0 && fields[0] === '__check__') fields.shift();
    const columnsEqual = new GridColumns(currentColumns).isEqual(currentGridColumns);
  };

  const OrderMatchBreadCrumbs = React.useMemo(() => {
    return (
      <React.Fragment>
        {pageBreadCrumbs?.length > 0 && (prefId || orderPreferenceId) && (
          <div style={{ margin: '5px' }}>
            <CustomBreadcrums items={pageBreadCrumbs} />
          </div>
        )}
      </React.Fragment>
    );
  }, [pageBreadCrumbs, prefId]);

  const getShifts = shift => {
    let shifts = '';
    if (shift && shift !== missingField) {
      if (shift?.includes('N')) {
        shifts = shifts + 'Night' + ', ';
      }
      if (shift?.includes('D')) {
        shifts = shifts + 'Day' + ', ';
      }
      if (shift?.includes('E')) {
        shifts = shifts + 'Evening' + ', ';
      }
    } else {
      return missingField;
    }
    shifts = shifts.slice(0, -2);
    return shifts;
  };

  const getprefLocations = (arr, field) => {
    let result = '';
    arr?.map(item => {
      if (item?.[field]) {
        result = result + item?.[field] + ', ';
      }
    });
    if (result !== '') {
      result = result.slice(0, -2);
      return result;
    } else {
      return missingField;
    }
  };

  const getOrderSkillsets = arr => {
    let skillSets = '';
    arr?.forEach(element => {
      if (element?.disciplineAbbr || element?.specialtyAbbr) {
        if (element?.disciplineAbbr) {
          skillSets = skillSets + element?.disciplineAbbr;
        }
        if (element?.specialtyAbbr) {
          skillSets = skillSets + '-' + element?.specialtyAbbr;
        }
      }
    });
    if (skillSets == '' || skillSets == undefined) {
      return missingField;
    }
    skillSets = skillSets.slice(0, -2);
    return skillSets;
  };

  const distanceByCoOrdinates = async (lat1, lon1, lat2, lon2) => {
    if (lat1 && lon1 && lat2 && lon2) {
      const payload = {
        source: {
          latitude: lat2,
          longitude: lon2,
        },
        destination: {
          latitude: lat1,
          longitude: lon1,
        },
      };
      const distance = await getDistance(payload);
      return `${distance?.toFixed(1)} mi`;
    } else return missingField;
  };

  const getComparisionData = async () => {
    setOpenBackDrop(true);
    const statesLookups = await getSearchFilterOptions('states');
    const orders = rows.filter(row => selectedData.includes(row.id));
    const orderData = await Promise.all(
      orders.map(async (item, index) => {
        const orderDetails = await getOrderDetailsWithPlacements(item?.orderId);
        const shift = item?.shifts?.substr(item?.shifts?.indexOf(' ') + 1);
        const skills = orderDetails?.orderDetails?.skillsets?.map(element => {
          if (element?.disciplineAbbr || element?.specialtyAbbr) {
            if (element?.disciplineAbbr && element?.specialtyAbbr) {
              return `${element?.disciplineAbbr} - ${element?.specialtyAbbr}`;
            }
            if (element?.disciplineAbbr) {
              return element?.disciplineAbbr;
            }
            if (element?.specialtyAbbr) {
              return element?.specialtyAbbr;
            }
          }
        });
        const skillsets = skills?.filter(item => item != undefined && item != '');
        return {
          id: item?.orderId,
          skillsets: {
            preferenceSkills: skillsets,
          },
          billRate: item?.orderBillRate !== missingField ? `$${item?.orderBillRate}` : missingField,
          shifts: getShifts(shift),
          city: item?.city,
          state:
            statesLookups?.find(ele => ele.name?.toLowerCase() === item?.stateName?.toLowerCase())?.value ||
            missingField,
          distance: await distanceByCoOrdinates(
            candidateDetails.address?.latitude,
            candidateDetails.address?.longitude,
            item?.facilityCoordinates?.latitude,
            item?.facilityCoordinates?.longitude,
          ),
          notes: orderDetails?.orderDetails?.internalNotes || missingField,
          accountManager: `${orderDetails?.orderDetails?.contacts?.accountManager?.firstName || missingField} ${
            orderDetails?.orderDetails?.contacts?.accountManager?.lastName || missingField
          }`,
          facility: item?.facility,
          startdate: item?.startdate || missingField,
          positions: item?.positionsAvailable,
          maxFilesSent: orderDetails?.orderDetails?.maxFilesSent === true ? 'Yes' : 'No',
        };
      }),
    );
    const data = jobPreferencesData?.filter(item => item?.id === preferenceId)?.[0];
    const gridData = jobPreferencesGridData?.filter(item => item?.id === preferenceId)?.[0];
    const date =
      data?.orderStartDateRange?.startDate == null && data?.orderStartDateRange?.endDate == null
        ? missingField
        : `${moment(data?.orderStartDateRange?.startDate).format('MM/DD/YYYY')} -
          ${moment(data?.orderStartDateRange?.endDate).format('MM/DD/YYYY')}`;
    const uniqueVerifiedSkills = candidateDetails?.verifiedSkills?.reduce((acc, curr) => {
      const duplicate = acc.find(
        item => item.disciplineId === curr.disciplineId && item.specialtyId === curr.specialtyId,
      );
      if (!duplicate) {
        acc.push(curr);
      }
      return acc;
    }, []);
    const uniqueUnVerifiedSkills = candidateDetails?.unVerifiedSkills?.reduce((acc, curr) => {
      const duplicate = acc.find(
        item => item.disciplineId === curr.disciplineId && item.specialtyId === curr.specialtyId,
      );
      if (!duplicate) {
        acc.push(curr);
      }
      return acc;
    }, []);
    const candidateReference = preferenceId
      ? {
          id: `${candidateDetails.name.first} ${candidateDetails.name.last} (${candidateDetails?.travelerId})`,
          skillsets: {
            preferenceSkills: data?.skillSetsArray?.map(item => `${item.discipline} - ${item.speciality}`),
          },
          billRate: missingField,
          shifts:
            gridData?.shift !== '' && gridData?.shift != null ? gridData?.shift.replaceAll(',', ', ') : missingField,
          city: getprefLocations(data?.locations, 'city'),
          state: getprefLocations(data?.locations, 'state'),
          distance: missingField,
          notes: data.notes || missingField,
          accountManager: missingField,
          facility: missingField,
          startdate: date,
          positions: missingField,
          maxFilesSent: missingField,
        }
      : {
          id: `${candidateDetails.name.first} ${candidateDetails.name.last} (${candidateDetails?.travelerId})`,
          skillsets: {
            verifiedSkills: uniqueVerifiedSkills?.map(item => `${item.disciplineAbbr} - ${item.specialtyAbbr}`) || [],
            unVerifiedSkills:
              uniqueUnVerifiedSkills?.map(item => `${item.disciplineAbbr} - ${item.specialtyAbbr}`) || [],
          },
          billRate: missingField,
          shifts: missingField,
          city: candidateDetails?.address?.city || missingField,
          state: candidateDetails?.address?.stateProvinceId || missingField,
          distance: missingField,
          notes: candidateDetails?.candidateSummary || missingField,
          accountManager: missingField,
          facility: missingField,
          startdate: candidateDetails?.availableOn
            ? moment(candidateDetails?.availableOn).format('MM/DD/YYYY')
            : missingField,
          positions: missingField,
          maxFilesSent: missingField,
        };
    dispatch(
      getCandidateOrderMatchActions.setModalProps({
        tableData: orderData,
        tableRows: tableRows,
        referenceData: candidateReference,
      }),
    );
    dispatch(getCandidateOrderMatchActions.setModalOpen(true));
    setOpenBackDrop(false);
  };

  const sendOrdersEmail = React.useCallback(async () => {
    setOpenBackDrop(true);
    const orders = rows.filter(row => selectedData.includes(row.id));
    const tos = {
      brand: candidateDetails.brand,
      brandId: `${candidateDetails.brandId}`,
      contactId: `${candidateDetails.travelerId}`,
      email: candidateDetails.primaryEmail,
      name: `${candidateDetails.name.first || ''} ${candidateDetails.name.last}`,
      recruiter: `${candidateDetails.recruiter.first} ${candidateDetails.recruiter.last}`,
      recruiterId: candidateDetails.recruiter.recruiterId,
    };
    const orderData = orders.reduce((storage, item) => {
      if (item.id !== missingField) {
        return [
          ...storage,
          {
            facility: item.facility,
            unit: item.unit,
            city: item.city,
            state: item?.stateName,
            skillset: item.skillsets,
            shifts: item.shifts,
            weekly_gross: `${item.weeklyGrossPay}`,
            business_relationship: item.businessRelationship,
            positions: `${item.positionsAvailable}`,
            notes: item?.notes,
          },
        ];
      } else {
        return storage;
      }
    }, []);
    const sortedData = sortOrderData(orderData);
    const sender = {
      name: Concatenate([userInfo?.firstName || '', userInfo?.lastName || ''], ' '),
      email: userInfo?.email,
      userId: `${userInfo?.employeeId}`,
      NtUserName: userInfo?.ntUserName,
      senderId: undefined,
    };
    const templates = await GetTemplatesByCategory('Job Match', 'email');
    const template = templates.filter(data => data.title === 'Default Jobs List');
    var body = String(template[0]?.body);
    const partialData = sortedData;
    const tableData = {
      jobs: {
        partialTemplateName: 'job',
        partialTemplate:
          '<tr style="padding: 5px; border:1px solid black; border-collapse: collapse;">\r\n\t<td style="padding: 5px; border:1px solid black; border-collapse: collapse;">{{job.facility}}</td>\r\n\t<td style="padding: 5px; border:1px solid black; border-collapse: collapse;">{{job.unit}}</td>\r\n\t<td style="padding: 5px; border:1px solid black; border-collapse: collapse;">{{job.city}}</td>\r\n\t<td style="padding: 5px; border:1px solid black; border-collapse: collapse;">{{job.state}}</td>\r\n\t<td style="padding: 5px; border:1px solid black; border-collapse: collapse;">{{job.skillset}}</td>\r\n\t<td style="padding: 5px; border:1px solid black; border-collapse: collapse;">{{job.shifts}}</td>\r\n\t<td style="padding: 5px; border:1px solid black; border-collapse: collapse;">{{job.weekly_gross}}</td>\r\n\t<td style="padding: 5px; border:1px solid black; border-collapse: collapse;">{{job.business_relationship}}</td>\r\n\t<td style="padding: 5px; border:1px solid black; border-collapse: collapse;">{{job.positions}}</td>\r\n\t<td style="padding: 5px; border:1px solid black; border-collapse: collapse;">{{job.notes}}</td>\r\n</tr>',
        partialData: partialData,
      },
    };

    const previewRequestData = {
      body: body,
      brand: user.userInfo?.companyBrandName,
      signatureSubstitutions: signatureSubstitutionToken(user, userPreference),
      substitutions: {},
      tableData: tableData,
      id: null,
      skipSignatureSubstitution: true,
    };
    await ApplyDataToTemplate(previewRequestData).then(response => {
      body = response;
    });
    const substitutions: IToken[] = [];
    substitutions.push({
      name: 'Signature',
      input: '{{signature}}',
    });

    const data = {
      useType: UseType.Candidate,
      channel: ChannelType.email,
      emailType: SendType.one_to_one,
      selectedTemplate:
        template.length > 0
          ? template[0]
          : {
              title: '',
              id: '',
              body: '',
              description: '',
              category: '',
              isPrivate: false,
              substitutions: [],
            },
      publicTemplates: templates?.filter(tmpl => tmpl?.isPrivate !== true),
      sendType: SendType.one_to_one,
      body: body,
      sender: sender,
      substitutions: substitutions,
      tos: [tos],
      ccs: [],
      bccs: [sender],
      userRole: Concatenate(user?.userInfo?.roles || [], ','),
      formName: FormName.Traveler,
      signatureSubstitutions: previewRequestData.signatureSubstitutions,
      tableData: tableData,
      subject: '',
      packetSubmission: false,
      associatedRecords: [],
      selectedPublicCategory: template.length > 0 ? template[0].category : '',
      personalTemplates: [],
      selectedPersonalCategory: '',
    };

    if (!notificationData.email.open && notificationData.email.minimized) {
      dispatch(
        notificationDataActions.setEmailInteraction({
          open: true,
          minimized: false,
        }),
      );
      dispatch(notificationDataActions.setSnackBarData({ channel: 'email', manual: false, changeWarning: true }));
    } else {
      dispatch(
        notificationDataActions.setNotificationData({
          ...notificationData,
          email: {
            data: data,
            open: true,
            minimized: false,
          },
          snackbarData: null,
        }),
      );
    }
    setOpenBackDrop(false);
  }, [notificationData, rows, selectedData, user, candidateDetails]);

  const getLeftActions = React.useMemo(() => {
    const leftActions: any[] = [
      {
        title: 'Compare',
        Icon: CompareArrowsIcon,
        onClick: getComparisionData,
        disabled: selectedData.length <= 0 || selectedData.length > 5,
      },
    ];
    if (
      Authorized(
        [userRoles.recruitment, userRoles.recruitment_TeamMember, userRoles.recruitment_Leadership],
        user.userInfo,
      ) &&
      enableEmailCommunication
    ) {
      leftActions.push({
        title: 'Email',
        Icon: MailOutlineIcon,
        onClick: sendOrdersEmail,
        disabled: selectedData.length <= 0 || selectedData.length > 200,
        tooltipProps: {
          tooltipContent: 'Maximum of 200 Orders permitted. Please reduce your selections and try again.',
          disabled: selectedData.length <= 201,
        },
      });
    }
    return leftActions;
  }, [userInfo, selectedData, Authorized]);

  const saveFilterPreference = React.useCallback(
    (updatedFilter: GridFilterModel, details: GridCallbackDetails) => {
      setFilterModel(updatedFilter);
      const preferenceData = {
        id: gridFiltersTag,
        value: {
          updatedFilter: updatedFilter,
        },
      };
      dispatch(userDevicePreferenceActions.saveUserGridPreferenceAction(preferenceData));
    },
    [filterModel, userPreference.loaded, gridPreferences?.value, dispatch, gridFilterPreferences?.value],
  );

  const determineFilters = () => {
    if (!orderPrefId) {
      if (gridFilterPreferences?.value?.updatedFilter) {
        setFilterModel(gridFilterPreferences?.value?.updatedFilter);
      }
    } else {
      setFilterModel({ items: [] });
    }
  };

  const GridComponent = React.useMemo(() => {
    return (
      <XGrid
        sx={{
          '& .super-app-theme': {
            fontWeight: 'bold',
          },
        }}
        apiRef={apiRef}
        title={orderPreferenceId || prefId ? '' : 'Orders'}
        titleBreadCrumbs={OrderMatchBreadCrumbs}
        loading={promiseInProgress}
        defaultColumns={defaultColumns}
        defaultColumnVisibilityModel={defaultColumnVisibilityModel}
        defaultSortModel={defaultSortModel}
        columns={currentColumns}
        columnVisibilityModel={currentColumnVisibilityModel}
        disableMultipleColumnsSorting={false}
        sortModel={currentSortModel}
        rows={rows || []}
        rowCount={count > totalCountErrorThreshold ? totalCountErrorThreshold : count}
        height={490}
        rowsPerPageOptions={[250, 300, 400, 500]}
        defaultRowsPerPage={250}
        checkboxSelection
        onStateChange={handleGridChanges}
        gridTag={gridTag}
        onSortModelChange={onSortModelChanged}
        paginationMode="server"
        sortingMode="server"
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        getRowClassName={params => {
          if (newUnradOrders.includes(params.row.orderId) && checkAssignedRecruiter) return `super-app-theme`;
        }}
        onColumnResizeStop={_columns => {
          savePreferences({
            field: undefined,
            columns: _columns,
            visibilityModel: _.cloneDeep(currentColumnVisibilityModel ?? defaultColumnVisibilityModel),
          });
        }}
        onColumnOrderChange={(params, event, details, _columns) => {
          savePreferences({
            field: params.field,
            columns: _columns,
            visibilityModel: _.cloneDeep(currentColumnVisibilityModel ?? defaultColumnVisibilityModel),
          });
        }}
        onColumnVisibilityModelChange={(model, details) => {
          dispatch(gridStateActions.setColumnVisibilityModel(model));
          setCurrentColumnVisibilityModel(_.cloneDeep(model));
          savePreferences({
            field: undefined,
            columns: _.cloneDeep(apiRef?.current?.getAllColumns()),
            visibilityModel: _.cloneDeep(model),
          });
        }}
        toolbarProps={{
          filterButton: true,
          refreshButton: true,
          refreshGrid: () => {
            handleRefreshGrid();
          },
          leftActions: getLeftActions,
          rightActions: [
            {
              id: 'search-map-view-button',
              Icon: MapOutlinedIcon,
              disabled: disableMapView,
              onClick: () => {
                window.open(`/mapresults/${resultMapView}`, '_blank');
              },
              tooltipProps: {
                tooltipContent: disableMapView ? t('global.xgrid.toolbar.mapTooltip') : 'Map',
              },
            },
          ].filter(item => !(item.id === 'search-map-view-button' && !showMapView)),
          resetColumns: () => {
            setPinnedColumns({});
            setDensity('compact');
            setFilterModel({ items: [] });
            setCurrentColumns(OrderMatchesViewInfo(t));
            setCurrentColumnVisibilityModel(orderMatchesColumnVisibilityModel);
            setFetchData(false);
            dispatch(gridStateActions.setSortedColumn({ column: 'orderId', direction: 'desc' }));
            dispatch(gridStateActions.setMultiSortedColumn([{ column: 'orderId', direction: 'desc' }]));
            setCurrentSortModel(defaultSortModel);
            const preferenceData = {
              id: gridTag,
              value: {
                ...(gridPreferences?.value || {}),
                columns: defaultColumns,
                columnVisibilityModel: defaultColumnVisibilityModel,
                sortedColumn: { column: 'orderId', direction: 'desc' },
                multiSortedColumns: [{ column: 'orderId', direction: 'desc' }],
                pinnedColumns: {},
                gridDensity: 'compact',
              },
            };
            dispatch(userDevicePreferenceActions.saveUserGridPreferenceAction(preferenceData));
            setTimeout(() => {
              const filterPreferenceData = {
                id: gridFiltersTag,
                value: {
                  updatedFilter: null,
                },
              };
              dispatch(userDevicePreferenceActions.saveUserGridPreferenceAction(filterPreferenceData));
            }, 300);
            dispatch(
              globalActions.setSnackBar({
                message: 'Columns have been reset.',
                severity: 'success',
              }),
            );
          },
        }}
        filterModel={filterModel}
        onFilterModelChange={saveFilterPreference}
        density={density}
        pinnedColumns={pinnedColumns}
        onPinnedColumnsChange={handlePinnedColumnsChange}
      />
    );
  }, [
    density,
    filterModel,
    pinnedColumns,
    rows,
    currentColumns,
    currentSortModel,
    promiseInProgress,
    getLeftActions,
    currentColumnVisibilityModel,
    userPreference?.loaded,
    pageBreadCrumbs,
    OrderMatchBreadCrumbs,
    selectedData,
    count,
  ]);

  return (
    <div>
      <CustomBackdrop open={openBackDrop} />
      {GridComponent}
      {drawerData?.open && (
        <DrawerFitted
          onDrawerClose={() => {
            dispatch(globalSearchActions.setDrawerData({ open: false, data: undefined }));
          }}
          width={450}
          top={0}
          backgroundColor={theme.palette.framework.system.whisper}
          open={drawerData?.open || false}
        >
          {drawerData?.data ? <OrderPreviewDrawerViewer /> : null}
        </DrawerFitted>
      )}
      {comparisonModalOpen && (
        <OrderComparisionModal onClose={() => dispatch(getCandidateOrderMatchActions.setModalOpen(false))} />
      )}
    </div>
  );
};

export default OrderMatchesGrid;
