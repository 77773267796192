import { IGroupedSkillset } from 'app/components/Common/SkillsetTree/SkillsetTree';
import { candidatePreferenceMatchResponse } from '../Candidate/CandidatePreferenceMatch';
import {
  EMRType,
  FlexibleScheduleType,
  FloatType,
  IDisplayUnit,
  NewUnit,
  NonStandardRequirementType,
  QualificationType,
  Reference,
  SkillSetType,
  TravelExperienceType,
  UpdateOrderPriorityType,
} from '../Unit/CreateUnit';
import { INonContractedBillRate } from './CreateOrder';
import { IOffContractRateElements } from 'store/redux-store/create-order-ocbr/types';
import { ISkillSetGroup } from '@AMIEWEB/Facility/FacilityStore/Facility.model';
import { AdditionalInformation, OrderStrike } from '@AMIEWEB/NewOrder/types';
import { DateElement } from '@AMIEWEB/Order/Store/helpers/rateCard/types';

export interface IOrderDetails {
  id?: string;
  orderId: string;
  facility: Facility;
  unit: IDisplayUnit;
  location: Location;
  numOfJobMatches: number;
  positionsAvailable: number;
  numOfFilesWfcRfo: number;
  numOfFilesSent: number;
  weeklyGrossPay: number | null;
  orderBillRate: number;
  startDate: Date | null;
  endDate: Date | null;
  orderRateEffectiveStartDate?: Date | null;
  orderRateEffectiveEndDate?: Date | null;
  isFlatRate?: boolean | null;
  numOfWeeks: number;
  dateCreated: string;
  maxFilesSent: boolean;
  division: string;
  orderType: string;
  orderTypeId: number | null;
  staffingVendor?: string;
  technologyVendor: string;
  activeBonus: boolean | null;
  payCycle: number;
  fees: number;
  radiusRules: number;
  gww: boolean | null;
  contacts: Contacts;
  dateVerified: string;
  jobDescription: string;
  yearsOfExperience: number;
  shifts: ShiftResponse[];
  submissionRequirements: string;
  preferredQualifications: string;
  requiredQualifications?: string | null;
  internalNotes: string;
  skillsets: SkillSet[];
  groupedSkillsets: IGroupedSkillset[];
  clinicalManager: userContact;
  flexibleDates: boolean;
  asap: boolean;
  reasonForMaxFilesSent: string;
  hideFacilityDescription: boolean;
  suppressExternalPosting: boolean;
  clinicalFellowship: boolean | null;
  verifiedByUserId?: number | null;
  autoDecrementDate?: string | null;
  allowScreenId?: number | null;
  shiftNotNegotiable?: boolean | null;
  newGrad: boolean | null;
  expectedHours: number | null;
  guaranteedHours: number | null;
  orderReasonId: number | null;
  orderReason: string | null;
  vmsOrderNumber: string;
  webLink: string | null;
  vmsBillRate: number | null;
  orderPriorities: OrderPrioritiesData[];
  brandinfo?: Brand[];
  status?: string;
  lastUpdatedMaxFilesSent: string;
  lastUpdatedByUserIdMaxFilesSent?: number | null;
  conditional?: boolean | null;
  orderScore: number;
  orderCurrentPlacements: OrderCurrentPlacements;
  orderDecrementReasonID: number | null;
  declineFiles: boolean;
  amieOrdersTimestamp: string;
  placementOrderType?: string;
  rateElements?: IOffContractRateElements[];
  qualification?: QualificationType | null;
  region?: string;
  ssnRequired?: boolean;
  dobRequired?: boolean;
  orderWorkHistory?: IOrderWorkHistory;
  orderClinicianToPatientRatio?: IOrderClinicianToPatientRatio;
  additionalInformation?: AdditionalInformation;
  bestBets?: string;
  bestBetsId?: number;
  reference?: Reference;
  skillSetGroup?: ISkillSetGroup;
  virtualRatingSubmissionRequirement: boolean;
  orderStrike: OrderStrike;
  dates: { startDate?: string; endDate?: string }[];
  additionalRequiredQualifications: string;
  divisionId: number;
  standardizedRequirementsLastUpdated: Date | string;
  standardizedRequirementsLastUpdatedByUserId: number;
  showInheritUnitButton: boolean;
  standardizedRequirements?: boolean;
  isUpdatedFeeText: boolean;
  vmsOrder?: boolean;
}
export interface IOrderFreeText {
  orderId?: string;
  isUpdatedFeeText?: boolean;
  jobDescription: string;
  preferredQualifications: string;
  requiredQualifications?: string | null;
  internalNotes: string;
  additionalRequiredQualifications: string;
}

export interface IOrderClinicianToPatientRatio {
  notifyCandidate: string;
  patientsSeen: number;
  numberOfVisits: number;
  numberOfClinicians: number;
  numberOfPatients: number;
  productivityPercentage: number;
  rangeOfPatients?: string;
  rangeOfVisits?: string;
  rangeOfPatientsSeen?: string;
}

export interface IOrderWorkHistory {
  workHistoryGapInDays: number;
  workHistoryGapInYears: number;
  entireWorkHistory: boolean;
}

export interface License {
  requirementTypeId: number;
  isMultiState: boolean;
  stateAbbreviation: string;
  state: string;
  isRequired: boolean;
  documentAttachmentRequired: boolean | null;
  isError?: boolean;
}

export interface IValidPlacementDate {
  startDate: boolean;
  endDate: boolean;
}

export interface OrderCurrentPlacements {
  totalPlacements: number;
  booked: number;
  interviewing: number;
  offered: number;
  onAssignment: number;
  inSubmission: number;
  lastBookedDate: Date | string | null;
  placementStartDate: Date | string | null;
}

export interface ShiftResponse {
  id: number;
  description: string;
  isPrimary?: boolean;
}
export interface Brand {
  brandId: number;
  brandName: string;
}

export interface Facility {
  facilityId: number;
  facilityBestBetsId: number;
  name: string;
  creditStatusId: number;
  businessRelationship?: string;
  amieFacilityTimeStamp?: string | null;
}

export interface Contacts {
  accountManager: userContact;
  regionalDirector: userContact;
  recruiter: userContact | null;
  credentialingAnalyst: userContact | null;
  clinicalManager: userContact | null;
  verifiedBy: userContact | null;
  createdBy: userContact | null;
}

export interface userContact {
  userId?: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string | null;
  contactId?: number;
}

export interface Unit {
  unitId: number;
  unitBestBetsId: number;
  name: string;
}

export interface OrderHeaderSection {
  orderID: string;
  facilityId: number;
  facilityName: string;
  unit: string;
  location: {
    addressLine1: string | null;
    addressLine2: string;
    phoneNumber: string | null;
  };
  skillSet: SkillSet[];
  lastVerified: string;
  verifiedBy: string;
  accountManager: string;
  division: string;
  divisionId: number;
  type: string;
  regionalDirector: string;
  brandName: string;
  contacts: any[];
  status: string;
}

export interface IOrderMidPage {
  jobDescription: string;
  yearsOfExperience: number;
  submissionRequirements: string;
  preferredQualifications: string;
  internalNotes: string;
  shifts: ShiftResponse[];
  orderPriorities: OrderPrioritiesData[];
}

export interface ILastVerifiedValues {
  lastVerifiedDate: string;
  lastVerifiedByUserId: number;
}

export interface updateLastVerifiedData {
  orderId: number;
  DateVerified: string;
  VerifiedByUserId: number;
}
export interface IOrderDetailsDataView {
  orderMidPageDetails: IOrderMidPage;
  atAGlanceDetails: IAtAGlance;
  handleUpdates: (property: string, value: any) => void;
  updateLastVerified(lastVerified: ILastVerifiedValues): void;
  lastVerifiedUpdateStatus: boolean;
  editedOrder: IOrderDetails;
  disableEdit: boolean;
}
export interface JobDescription {
  jobDescription: string;
  handleOrderUpdate: (property: string, value: any) => void;
  disableEdit: boolean;
}

export interface YearsOfExperience {
  yearsOfExperience: number;
  handleOrderUpdate: (property: string, value: any) => void;
  disableEdit: boolean;
}

export interface Shifts {
  shifts: ShiftResponse[];
  handleOrderUpdate: (property: string, value: any) => void;
  disableEdit: boolean;
}

export interface IOrderPriorities {
  orderPriorities: OrderPrioritiesData[];
  handleOrderUpdate: (property: string, value: any) => void;
  disableEdit: boolean;
}

export interface SubmissionRequirements {
  submissionRequirements: string;
  handleOrderUpdate: (property: string, value: any) => void;
  disableEdit: boolean;
}

export interface PreferredQualifications {
  preferredQualifications: string;
  handleOrderUpdate: (property: string, value: any) => void;
  disableEdit: boolean;
}
export interface InternalNotes {
  internalNotes: string;
  handleOrderUpdate: (property: string, value: any) => void;
  disableEdit: boolean;
}

export interface IAtAGlance {
  dateVerified: string;
  verifiedBy: string;
  positionsAvailable: number;
  startDate: string;
  endDate: string;
  placementLength: string;
  flexibleDates: boolean;
  asap: boolean;
  dateCreated: string;
  maxFilesSent: boolean;
  reasonForMaxFilesSent: string;
  maxFilesSentdate: string;
  orderScore: string;
  businessRelationship: string;
  region: string;
  payCycle: string;
  fees: string;
  radiusRules: number;
  hideFacilityDescription: boolean;
  suppressExternalPosting: boolean;
  clinicalFellowship: boolean | null;
  expectedHours: number | null;
  guaranteedHours: number | null;
  orderReason: number | null;
  nurseToPatientRatio: string;
  unitSize: string;
  costCenter: string;
  orderDecrementReasonID: number | null;
  declineFiles: boolean;
}

export interface QuickGlance {
  positionsAvailable: string;
  startDate: string;
  placementLength: string;
  maxFilesSent: string;
  guaranteedWorkWeek: string;
  activeBonus: string;
  weeklyGrossPay: string;
  billRate: string;
  jobMatches: string;
}

export enum GlanceDataStatus {
  yes = 'Yes',
  no = 'No',
  neutral = 'Neutral',
}

export interface ModalTableHeader {
  title: string;
  value: string;
}

export interface PlacementFileSent {
  placementId: string;
  candidateName: string;
  sentDate: string;
}

export interface FilesAwaitingSubmission {
  placementId: string;
  candidateName: string;
  placementStatus: string;
  changedToStatus: string;
  availabilityDate: string;
  predictiveInterviewSuccessScore: string;
  predictiveInterestScore: string;
}

export interface CandidateInfo {
  firstName: string;
  middleName: string;
  lastName: string;
}

export interface OrderResponse {
  orderDetails: IOrderDetails;
  candidatePreferenceMatch: candidatePreferenceMatchResponse[];
}

export interface OrderPriorities {
  bedSize: boolean;
  teaching: boolean;
  magnetStatus: boolean;
  traumaCenter: boolean;
}

export interface ITypeAheadOption {
  object: any;
  label: string;
  label2?: string;
  groupBy?: ITypeAheadOptionGroup;
}

export interface ITypeAheadOptionGroup {
  label: string;
  id: number;
  parentId?: number;
  parentLabel?: string;
}

export interface NewOrder {
  facility: Facility;
  unit: Unit;
  type: string;
  orderTypeId: number;
  region: string;
  skillsets: SkillSetType[];
  orderPriorities: string[];
  orderBillRate: string | null;
  onCallRate: number | null;
  callBackMinimum: string;
  completionBonus: string;
  hideFacilityDescription: boolean;
  suppressExternalPosting: boolean;
  CF: boolean;
  newGrad: boolean;
  maxFilesSent: boolean;
  reasonForMaxFilesSent: string;
  VMSOrderNumber: string;
  weblink: string;
  yearsOfExperience: number;
  positionsAvailable: number | null;
  requiredQualifications: string | null;
  preferredQualifications: string | null;
  jobDescription: string | null;
  internalNotes: string | null;
  shifts: string[];
  placementLength: number;
  dates: string[];
  startDate: Date | null;
  endDate: Date | null;
  ASAP: boolean;
  flexibleDates: boolean;
  expectedHours: number | null;
  guaranteedHours: number | null;
  orderReason: string | null;
  orderReasonId: number | null;
  virtualRating?: string[] | null;
  addBillRateRange: boolean;
  negotiableRate: string;
  notes: string | null;
  nonContractedBillRates: INonContractedBillRate[];
  // contact:Contacts
}

export interface NewUIOrder extends NewOrder {
  id?: string | null;
  orderId?: string | null;
  unitId: number;
  name: string;
  bestBets: string | null;
  regionId: number | null;
  bestBetsId: number;
  reference: Reference;
  float: FloatType | null;
  floatSelected: boolean | false;
  flexibleSchedule: FlexibleScheduleType | null;
  nonStandardRequirement: NonStandardRequirementType[] | null;
  nurseRatio: string | null;
  unitSize: number | null;
  status: string | null;
  fyreIntegration: boolean | null;
  costCenter: string;
  floor: string;
  travelExperience: TravelExperienceType | null;
  qualification: QualificationType | null;
  emr: EMRType | null;
  nurse?: string | null;
  patient?: string | null;
}

export interface SubmitOrder {
  facility: Facility;
  unit: Unit | NewUnit;
  currentUserId: number;
  type: string;
  orderTypeId: number;
  division: string;
  bestBets: string;
  bestBetsId: number;
  location: Location;
  skillsets: SkillSet[];
  orderPriorities: OrderPrioritiesData[];
  orderBillRate: number | null;
  onCallRate: number | null;
  callBackMinimum: string;
  completionBonus: string;
  hideFacilityDescription: boolean;
  suppressExternalPosting: boolean;
  CF: boolean;
  newGrad: boolean;
  maxFilesSent: boolean;
  reasonForMaxFilesSent: string;
  VMSOrderNumber: string;
  weblink: string;
  yearsOfExperience: number;
  positionsAvailable: number | null;
  requiredQualifications: string | null;
  preferredQualifications: string | null;
  jobDescription: string | null;
  additionalRequiredQualifications: string | null;
  internalNotes: string | null;
  shifts: ShiftData[];
  placementLength: number;
  dates: string[] | DateElement[];
  startDate: Date | null;
  endDate: Date | null;
  ASAP: boolean;
  flexibleDates: boolean;
  expectedHours: number | null;
  guaranteedHours: number | null;
  orderReason: string | null;
  orderReasonId: number | null;
  contacts: Contacts;
  addBillRateRange: boolean;
  negotiableRate: string;
  notes: string | null;
  nonContractedBillRates: INonContractedBillRate[];
  VMSBillRate: number | null;
  standardizedRequirements: boolean;
  dateCreated: string;
  dateVerified: string;
  ClinicalFellowship: boolean | null;
  VerifiedByUserId: number | null;
  AutoDecrementDate: Date | null;
  AllowScreenId: number | null;
  ShiftNotNegotiable: boolean;
  LastUpdatedMaxFilesSent: string;
  Conditional: boolean;
  LastUpdatedByUserIdMaxFilesSent: number | null;
  orderDecrementReasonID: number | null;
  declineFiles: boolean;
  amieOrdersTimestamp: string;
  ssnRequired: boolean;
  dobRequired: boolean;
  orderClinicianToPatientRatio: IOrderClinicianToPatientRatio;
  orderWorkHistory: IOrderWorkHistory;
  virtualRatingSubmissionRequirement: boolean;
  skillSetGroup: ISkillSetGroup;
  additionalInformation: AdditionalInformation;
  reference: Reference;
  orderStrike: OrderStrike;
  preferences: any;
  webLink: string;
  manualCreation: boolean;
  isUpdatedFeeText: boolean;
}

export interface Location {
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  regionId: number;
  region: string;
  phoneNumber: string;
}

export interface SkillSet {
  disciplineId: number;
  discipline: string;
  specialtyId: number;
  specialty: string;
  subSpecialtyId?: number;
  subSpecialty?: string;
  disciplineAbbr?: string;
  isRequired?: boolean;
  isPrimary?: boolean;
  yearsOfExperience?: number;
  specialtyAbbr?: string;
}
export interface CreateShifts {
  id: number;
  shiftName: string;
  isPrimary?: boolean;
}

export interface ShiftData {
  Id: number;
  Description: string;
}

export interface OrderPrioritiesData {
  orderPriorityTypeId: number;
  description: string;
}

export interface IBestBets {
  value: string;
  name: string;
}

export interface Unit {
  unitId: number;
  name: string;
}

export interface editOrder {
  orderId: number;
  orderTypeId: number;
  // type: string;
  location: Location;
  // region: string;
  regionId: number;
  // division: string;
  orderPriorities: UpdateOrderPriorityType[] | undefined;
  hideFacilityDescription: boolean;
  suppressExternalPosting: boolean;
  // CF: boolean | null;
  newGrad: boolean | null;
  maxFilesSent: boolean;
  reasonForMaxFilesSent: string;
  maxFilesSentDate: string;
  // orderScore: number;
  vmsOrderNumber: string;
  webLink: string | null;
  yearsOfExperience: number;
  positionsAvailable: number | null;
  requiredQualifications: string | null;
  preferredQualifications: string | null;
  jobDescription: string | null;
  internalNotes: string | null;
  shifts: ShiftResponse[];
  placementLength: number;
  NumOfWeeks: number;
  startDate: Date | string | null;
  endDate: Date | string | null;
  ASAP: boolean;
  flexibleDates: boolean;
  expectedHours: number | null;
  guaranteedHours: number | null;
  orderReasonId: number | null;
  currentUserId: number;
  vmsBillRate: number | null;
  orderBillRate: number | null;
  standardizedRequirements: boolean;
  dateCreated: string;
  dateVerified: string;
  ClinicalFellowship: boolean | null;
  VerifiedByUserId: number | null;
  AutoDecrementDate: Date | null;
  AllowScreenId: number | null;
  ShiftNotNegotiable: boolean;
  LastUpdatedMaxFilesSent: string;
  Conditional: boolean;
  LastUpdatedByUserIdMaxFilesSent: number | null;
  contacts: Contacts;
  skillsets: SkillSet[] | undefined;
  facility: Facility;
  unit: IDisplayUnit;
  orderDecrementReasonID: number | null;
  declineFiles: boolean;
  amieOrdersTimestamp: string;
  ssnRequired: boolean;
  dobRequired: boolean;
  orderClinicianToPatientRatio: IOrderClinicianToPatientRatio;
  orderWorkHistory: IOrderWorkHistory;
  virtualRatingSubmissionRequirement: boolean;
  skillSetGroup: ISkillSetGroup;
  additionalInformation: AdditionalInformation;
  reference: Reference;
  orderStrike: OrderStrike;
  dates: DateElement[];
  showInheritUnitButton: boolean;
  vmsOrder?: boolean;
}

export interface IOrderReportDetails {
  orderId: string;
  orderSkillset: string;
  numberOfPositions: string;
  verifiedDate: string;
  notNegotiable: string;
  yearsOfExperience: string;
  numberOfWeeks: string;
  orderShifts: string;
  referencing: string;
  limitation: string;
  benefitResult: string;
  permAddressWithIn: string;
  facilityId: string;
  affiliate: string;
  facilityName: string;
  amnDivision: string;
  facilityCity: string;
  facilitySettingsId: string;
  website: string;
  doNotCallFacility: string;
  facilitySettings: string;
  facilityType: string;
  facilityDesc: string;
  isTeachingHospital: string;
  stateProvinceId: string;
  numberOfBeds: string;
  facilityPreferenceNotes: string;
  currentAssignments: string;
  previousAssignments: string;
  numberOfFilesPending: string;
  numberOfFilesWFCRFO: string;
  housingType: string;
  numberOfRequiredReferences: string;
  housingDescription: string;
  floatingRequirements: string;
  firstDayInstructions: string;
  floatingRequirementId: string;
  qbRequirement: string;
  unitName: string;
  unitSize: string;
  unitId: string;
  nurseToPatientRatio: string;
  typicalDiagnoses: string;
  specialSkills: string;
  commonMedications: string;
  useOfAssistivePersonnel: string;
  computerSkills: string;
  sellingPoints: string;
  other: string;
  hamName: string;
  hamUserName: string;
  payrollBy: string;
  requiredQualifications: string;
  preferredQualifications: string;
  jobDescription: string;
  internalNotes: string;
  notes: string;
  maxFilesSent: string;
  orderPriorities: string;
  payRateNotes: string;
  billRateNotes: string;
  general: string;
  demographics: string;
  facilityHighlights: string;
  onTheJobMileage: string;
  timecardTypeId: string;
  timeSubmittedBy: string;
  descTimeSubmittedBY: string;
  descTimecardType: string;
  descJobMileagePayer: string;
  mealsAndIncidentals: string;
  mealsAndIncidentalsFrequency: string;
  carRental: string;
  businessRelationship: string;
  restrictionNotes: string;
  billRateAgreement: string;
  radiusRule: string;
  earlyArrivalDay: string;
  earlyArrivalNotes: string;
  amnDivisionTypeId: string;
  postponementPenaltyAmt: string;
  postPonementPenaltyFreq: string;
  cancellationPenaltyAmt: string;
  cancellationPenaltyFreq: string;
  orderType: string;
  facilityPPGWWExceptions: string;
  facilityRFORequirements: IFacilityRFORequirements;
  rfoRequirements: string;
  facilityBillRates: IFacilityBillrate[];
  orderPayRates: IOrderPayRate[];
  facilityPpPayCyle: IFacilityPPPayCyle[];
  facilityPPShiftDifferentials: IFacilityPPShiftDifferentials[];
  guaranteedWorkWeeks: IGuaranteedWorkWeek[];
  orderBonus: IOrderBonus[];
  orderHousings: IOrderHousings[];
  travelAllowances: ITravelAllowances[];
  facilityBRShiftDifferentials: IfacilityBRShiftDifferential[];
}

export interface IFacilityRFORequirements {
  facilityId: string;
  rfoRequirements: string;
}
export interface IOrderHousings {
  housingDescriptionId: string;
  amount: string;
  descHousing: string;
  housingProviderId: string;
  descHousingProvider: string;
  utilityCharge: string;
}
export interface IOrderPayRate {
  skillset: string;
  facilityId: string;
  facilityPPId: string;
  payRateId: string;
  payRateLineId: string;
  shiftId: string;
  regularRate: string;
  overtimeFactor: string;
  doubletimeFactor: string;
  holidayFactor: string;
  onCallRate: string;
  callBackFactor: string;
  chargePremium: string;
  timestamp: string;
  holidayRate: string;
  callbackRate: string;
  payroll: string;
  paysGWW: string;
  descShift: string;
  compositeRate: string;
  takeHomeRate: string;
  additionalPremiumPay: string;
}
export interface IFacilityBillrate {
  skillset: string;
  billRateId: string;
  contractId: string;
  descShift: string;
  regularRate: string;
  overtimeFactor: string;
  overtimeRate: string;
  doubletimeFactor: string;
  doubleTimeRate: string;
  holidayRate: string;
  onCallRate: string;
  callBackFactor: string;
  callBackRate: string;
  chargePremium: string;
  orientationRate: string;
  orientationFactor: string;
  descHousingProvider: string;
  notes: string;
  overtimeTotal: string;
  doubletimeTotal: string;
  holidayTotal: string;
  callbackTotal: string;
}
export interface IFacilityPPPayCyle {
  facilityId: string;
  facilityPPId: string;
  payCycleId: string;
  shiftTypeId: string;
  payCycleTypeId: string;
  startDay: string;
  endDay: string;
  startTime: string;
  endTime: string;
  descShiftType: string;
  descPayCycleType: string;
  descStartDay: string;
  descEndDay: string;
}
export interface IFacilityPPShiftDifferentials {
  skillset: string;
  facilityId: string;
  facilityPPId: string;
  payRateId: string;
  startTime: string;
  endTime: string;
  amount: string;
  shiftDifferentialTypeId: string;
  descShiftDifferentialType: string;
  addedByName: string;
  descShiftDifferentialDescriptionType: string;
  templateTypeId: string;
}

export interface IfacilityBRShiftDifferential {
  skillset: string;
  contractID: string;
  billRateID: string;
  startTime: string;
  endTime: string;
  amount: string;
  shiftDifferentialTypeID: string;
  shiftDifferentialType: string;
  addedByName: string;
  shiftDifferentialDescType: string;
  templateTypeID: string;
}
export interface IGuaranteedWorkWeek {
  contractID: string;
  gwwid: string;
  shiftID: string;
  hoursPerPayCycle: string;
  timestamp: string;
  cancellationHoursAllowed: string;
  cancellationHoursFrequencyID: string;
  frequency: string;
  gwwException: string;
  gwwExceptionNotes: string;
}
export interface IOrderBonus {
  orderID: string;
  bonusID: string;
  bonusAmount: string;
  shifts: string;
  billingAmount: string;
  approvalDate: string;
  startdate: string;
  endDate: string;
  minimumWeeksWorked: string;
  descSortAssignment: string;
  descBonusType: string;
  descPayer: string;
  descBillingType: string;
  descPaymentType: string;
  descApprovalType: string;
  descShortHoursType: string;
  notes: string;
}
export interface ITravelAllowances {
  amountPerMile: string;
  allowanceCap: string;
  descAllowance: string;
  descPayer: string;
}

export interface IReportSectionProps {
  orderskillset: string;
  orderShifts: string;
  numberOfWeeks: string;
  numberOfRequiredReferences: string;
  numberOfPositions: string;
  yearsOfExperience: string;
  maxFilesSent: string;
  numberOfFilesWFCRFO: string;
  numberOfFilesPending: string;
  verifiedDate: string;
}

export interface IRfoSectionProps {
  earlyArrivalDay: string;
  jobDescription: string;
  notes: string;
  requiredQualifications: string;
  preferredQualifications: string;
}

export interface ILTRestrictionSectionProps {
  radiusRule: string;
  permAddressWithIn: string;
  billRateAgreement: string;
  benefitResult: string;
  restrictionNotes: string;
  facilityBillrate: IFacilityBillrate[];
  amnDivisionTypeId: string;
  facilityBRShiftDifferentials: IfacilityBRShiftDifferential[];
}

export interface IPayBonusSectionProps {
  payRates: IOrderPayRate[];
  pPShiftDifferentials: IFacilityPPShiftDifferentials[];
  facilityPpPayCyle: IFacilityPPPayCyle[];
  guaranteedWorkWeeks: IGuaranteedWorkWeek[];
  orderBonus: IOrderBonus[];
  descPayCycleType: string;
  descShiftType: string;
  descStartDayTime: string;
  descEndDayTime: string;
  descTimecardType: string;
  descTimeSubmittedBY: string;
  carRental: string;
  notes: string;
  amnDivisionTypeId: string;
  facilityPPGWWExceptions: string;
}

export interface IMealSectionProps {
  mealsAndIncidentals: string;
}

export interface IHousingSectionProps {
  housingType: string;
  limitation: string;
  housingDescription: string;
  orderHousings: IOrderHousings[];
}

export interface IFacilitySectionProps {
  doNotCallFacility: string;
  postponementPenaltyAmt: string;
  facilityType: string;
  postPonementPenaltyFreq: string;
  facilityCity: string;
  cancellationPenaltyAmt: string;
  website: string;
  cancellationPenaltyFreq: string;
  currentAssignments: string;
  numberOfBeds: string;
  facilityPreferenceNotes: string;
  facilityName: string;
}

export interface IUnitSectionProps {
  unitName: string;
  unitSize: string;
  nurseToPatientRatio: string;
  typicalDiagnoses: string;
  commonMedications: string;
  specialSkills: string;
  useOfAssistivePersonnel: string;
  computerSkills: string;
  sellingPoints: string;
  other: string;
}

export interface ITravelSectionProps {
  travelAllowances: ITravelAllowances[];
  onTheJobMileage: string;
  descJobMileagePayer: string;
}
export interface IPointOfIntrestSectionProps {
  demographics: string;
  facilityHighlights: string;
}

export enum Certifications {
  CERT_CERTIFICATE = 122,
}
