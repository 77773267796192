import { makeStyles } from 'tss-react/mui';
import { colors } from 'styles/styleVariables';
import { Theme } from 'amn-ui-core';

export const useFacilityHeaderStyles = makeStyles()((theme: Theme, props) => ({
  container: {
    flexWrap: 'nowrap',
    width: '100%',
  },
  leftLings: {
    maxWidth: 158,
  },
  leftStrip: {
    width: 15,
    height: 50,
    background: '#05528B',
    borderRadius: '4px 0 0 4px',
  },
  rightStrip: {
    minWidth: 130,
    height: 50,
    fontSize: 20,
    fontWeight: 450,
    background: '#003C69',
    textTransform: 'uppercase',
    borderRadius: '0 4px 4px 0',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '0 6px',
  },
  pidContainer: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  pidSpan: {
    paddingLeft: 6,
    '&:hover': {
      cursor: 'default',
    },
  },
  alignCenterDiv: {
    display: 'flex',
    alignItems: 'center',
  },
  contactBtn: {
    backgroundColor: '#F8F8F8',
    justifyContent: 'start',
    border: '1px solid #006fb9',
    borderRadius: 4,
    fontSize: 12,
    width: '95%',
    '&:hover': {
      backgroundColor: '#006FB9',
      color: '#fff',
      border: '1px solid #006fb9',
    },
  },
  divider: {
    margin: '0 12px',
  },
  tailStrip: {
    background: 'rgba(102, 153, 204, 0.33)',
    borderRadius: 3,
    padding: 6,
  },
  rowHeadDefaultColor: {
    color: '#333333 !important',
  },
  rowContentDefaultColor: {
    color: '#555555 !important',
  },
  rowHead: {
    fontSize: 12,
    fontWeight: 400,
    whiteSpace: 'nowrap',
  },
  tailStripHead: {
    fontSize: 14,
    color: '#333333',
    fontWeight: 500,
  },
  brandName: {
    color: '#E89120',
    fontSize: 14,
  },
  headerData: {
    fontSize: 14,
  },
  placementDate: {
    fontSize: 14,
    fontWeight: 500,
    color: '#555555',
    paddingLeft: 12,
  },
  headerLinkContent: {
    fontSize: 16,
    color: '#006FB9',
    'font-weight': '600',
  },
  unitTag: {
    fontSize: 14,
    color: '#555555',
  },
  fontSize12: {
    fontSize: 12,
  },
  fontSize14: {
    fontSize: 14,
  },
  skeleton: {
    borderRadius: 4,
  },
  closeIconPad: {
    color: '#fff',
  },
  tableGridContainer: {
    marginTop: -18,
    '@media (max-width: 1024px)': {
      paddingLeft: 4,
    },
    '@media (max-width: 980px)': {
      paddingLeft: 12,
    },
  },
  tableGrid: {
    width: 'fit-content',
  },
  link: {
    color: colors.amnBlue,
    textDecoration: 'underline',
    fontWeight: 450,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  contactIcons: {
    display: 'flex',
    flexDirection: 'row',
  },
  facilityStatusGreen: {
    backgroundColor: theme.palette.system.paleGreen,
    color: theme.palette.system.lightGreen,
  },
  facilityStatusOrange: {
    backgroundColor: theme.palette.system.paleOrange,
    color: theme.palette.system.orange,
  },
  facilityStatusRed: {
    backgroundColor: theme.palette.system.paleRed,
    color: theme.palette.system.darkRed,
  },
  containerAvatar: {
    display: 'flex',
    justifyContent: 'start',
  },
  myAvatarAlignment: {
    display: 'flex',
    flexDirection: 'row',
  },
  contactDrawer: {
    marginTop: 10,
  },
  detailsContainerWrap: {
    flexWrap: 'nowrap',
    width: '100%',
    paddingLeft: '1rem',
  },
  statusContainer: {
    width: 'min-content',
  },
  candidateStatus: {
    backgroundColor: '#34B233',

    fontSize: '14px',
    width: 'max-content',
    padding: '8px',
    height: '27px',
    justifyContent: 'space-between',
    minWidth: '153px',
    maxWidth: '165px',
    [theme.breakpoints.up('lg')]: {
      width: 'inherit !important',
    },
  },
  candidateStatusText: {
    textOverflow: 'ellipsis',
    display: 'block',
    overflow: 'hidden',
  },
  inlineHeaderKeyLabel: {
    'font-weight': '500',
  },
  inlineHeaderValLabel: {
    'font-size': '14px',
    'font-weight': '500',
  },
  actionContainer: {
    backgroundColor: '#6699CC70',
    width: 'inherit',
    display: 'flex',
    padding: '7px',
    minHeight: 38,
    justifyContent: 'space-between',
    'margin-top': '0.6rem',
    'margin-bottom': '0.6rem',
    'border-radius': '0.2rem',
  },
  rowHeadCustom: {
    color: '#003E68',
    fontWeight: 600,
    fontSize: 14,
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px !important',
    },
  },
  rowContentCustom: {
    'font-weight': '600',
    'margin-top': '0',
    'padding-top': '0.12rem',
    fontSize: 14,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px !important',
    },
  },
  address: {
    color: '#333333',
    fontWeight: 500,
    paddingTop: 3,
  },
  leftMostHeaderColumns: {
    position: 'relative',
    top: '1px',
  },
  headerRowColor: {
    color: '#333333',
  },
  headerInfo: {
    display: 'flex',
    margin: `${theme.spacing(2)} 0px 0px 0px`,
  },
  fieldContainer: {
    color: theme.palette.system.black,
    display: 'flex',
    alignItems: 'flex-end',
    flexWrap: 'nowrap',
    marginBottom: theme.spacing(1),
  },
  fieldSection: {
    display: 'flex',
    whiteSpace: 'nowrap',
    width: '100%',
  },
  fieldHead: {
    fontSize: 12,
    fontWeight: 600,
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.down('lg')]: {
      maxWidth: '72px !important',
      minWidth: '48px',
    },
  },
  fieldContent: {
    color: theme.palette.system.black,
    fontWeight: 400,
    fontSize: 14,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  fieldContentContainer: {
    marginLeft: theme.spacing(),
    overflow: 'hidden',
  },
  headerPhoneNumber: {
    fontSize: 14,
    color: '#006FB9',
    fontWeight: '600',
    overflow: 'hidden',
  },
  facilityStatus: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'fit-content',
    height: '28px',
    padding: '6px 12px',
    whiteSpace: 'pre',
    fontWeight: 700,
    fontSize: 12,
    borderRadius: 7,
    opacity: 1,
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px !important',
    },
  },
  facilityStatusActive: {
    backgroundColor: theme.palette.components.avatar.green.backgroundColor,
  },
  facilityStatusProspect: {
    backgroundColor: theme.palette.system.orangeWarning,
  },
  facilityStatusFailed: {
    backgroundColor: theme.palette.system.darkRed,
  },
  previousFacilityStatusDesign: {
    height: '20px',
    fontSize: '14px',
    width: 'max-content',
    padding: '8px',
    minWidth: 102,
    justifyContent: 'space-between',
  },
  oldStatusText: {
    color: theme.palette.system.white,
    textOverflow: 'ellipsis',
    display: 'block',
    overflow: 'hidden',
  },
  contentMaxWidth: {
    [theme.breakpoints.up(1366)]: {
      maxWidth: 'none',
    },
  },
}));
