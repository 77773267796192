import {
  ChipStyle,
  getChipStyle,
} from '@AMIEWEB/Placement/PlacementDetails/PlacementTabPanel/PlacementSummaryTab/Statuses/CustomComponents/StatusChip';
import { formatCredentialCertificates } from '@AMIEWEB/Placement/PlacementDetails/helper';
import { missingField } from 'app/constants';
import { convertToFormat } from 'app/helpers/dateHelper';
import { ITypeAheadOption } from 'app/models/Orders/OrderDetails';
import { IOperation } from 'app/models/Placement/PlacementDetails';
import { Requirements } from 'app/models/Placement/PlacementRequirements';
import {
  ICandidateVirtualInterviewData,
  IOrderVirtualInterviewData,
  requirementType,
} from 'app/models/Placement/PlacementSummary';
import { customSwitch } from 'utils/customSwitchCase/customSwitch';
import { GetMomentOfDate } from 'utils/dates/moment';
import { Concatenate } from 'utils/string/string';

export const submissionInfoAmnReq = 'submissionInfo.amnReq.reqCounts';
export const submissionInfoOrderReq = 'submissionInfo.orderReq.reqCounts';
export const offerInfoConfirmationReq = 'offerInfo.confirmation.reqCounts';
export const offerInfoTransportationReq = 'offerInfo.transportation.reqCounts';

/**
 * Default value is 'key'.aggregate since
 * this must be counted even when service fails
 */

export const getCountKey = keyStartValue =>
  customSwitch({
    [requirementType.pass]: `${keyStartValue}.passed`,
    [requirementType.fail]: `${keyStartValue}.failed`,
    [requirementType.risk]: `${keyStartValue}.risks`,
  })(`${keyStartValue}.aggregate`);

export const calculateDate = dateStr => {
  const dateObj = GetMomentOfDate(dateStr).format('MM/DD/YYYY');
  if (dateStr && GetMomentOfDate(dateStr).isValid()) {
    return dateObj;
  } else {
    return missingField;
  }
};

export const expiringinMonthCount = certificationList => {
  if (certificationList && certificationList.length > 0) {
    return certificationList.filter(x => x && x.isExpired === false).length;
  } else {
    return 0;
  }
};

export const checkIfRequirmenetTypeIsValid = () => { };

export const extractTypeAheadPicklistOpts = (picklist: { key: any; value: any }[], extractObject): ITypeAheadOption[] =>
  picklist.map(item => ({ object: extractObject(item), label: item.value }));

export const extractTransportationOpts = (
  picklist: { key: any; value: any }[],
): { transportationId: number; transportation: string }[] =>
  picklist
    .map(item => ({ transportationId: parseInt(item.key), transportation: item.value }))
    .sort((itemA, itemB) =>
      itemA.transportation === itemB.transportation ? 0 : itemA.transportation < itemB.transportation ? 1 : -1,
    );

export const getGenderLabel = customSwitch({
  // eslint-disable-next-line prettier/prettier
  F: 'Female',
  // eslint-disable-next-line prettier/prettier
  M: 'Male',
})(null);

export const ServiceConstants = {
  bookingConfirmation: ['booking', 'confirmations'],
  bookingCredentialing: ['booking', 'credentialing'],
  offerConfirmation: ['offer', 'confirmations'],
  offerTransportation: ['offer', 'transportation'],
  offerTravelDetails: ['offer', 'travelDetails'],
};

export const extractReferenceAndEmploymentVerifications = data =>
  data.map((item, index) => {
    const recordDateMoment = GetMomentOfDate(item.referenceDate);
    const startDateMoment = GetMomentOfDate(item.startDate);
    const endDateMoment = GetMomentOfDate(item.endDate);

    return {
      id: index,
      ...item,
      referenceType: item.referenceType ?? missingField,
      referenceDate: recordDateMoment.isValid() ? recordDateMoment.format('MM/DD/YYYY') : missingField,
      status: item.status ?? missingField,
      facility: item.facility ?? missingField,
      city: item.city ?? missingField,
      state: item.state ?? missingField,
      startDate: startDateMoment.isValid() ? startDateMoment.format('MM/DD/YYYY') : missingField,
      endDate: endDateMoment.isValid() ? endDateMoment.format('MM/DD/YYYY') : missingField,
      discipline: item.discipline ?? missingField,
      specialty: item.specialty ?? missingField,
      supervisor: item.supervisor ?? missingField,
      title: item.title ?? missingField,
      amnTitle: item.amnTitle ?? missingField,
      travelAssignment: item.travelAssignment ?? missingField,
      comments: item.comments ?? missingField,
      contactInformationComplete: item.contactInformationComplete ?? missingField,
      phoneType1: item.phoneType1 ?? missingField,
      phoneNumber1: item.phoneNumber1 ?? missingField,
      phoneType2: item.phoneType2 ?? missingField,
      phoneNumber2: item.phoneNumber2 ?? missingField,
      callPreference: item.callPreference ?? missingField,
      bestTime: item.bestTime ?? missingField,
      email: item.email ?? missingField,
      eligibleForRehire: item.eligibleForRehire ?? missingField,
      explanation: item.explanation ?? missingField,
      unit: item.unit ?? missingField,
      unitSpecialty: item.unitSpecialty ?? missingField,
      positionsHeld: item.positionsHeld ?? missingField,
      beds: item.beds ?? missingField,
      averageCaseload: item.averageCaseload ?? missingField,
      unitDescription: item.unitDescription ?? missingField,
      shiftWorked: item.shiftWorked ?? missingField,
      supervisorExperience: item.supervisorExperience ?? missingField,
      shareable: item.shareable ?? missingField,
      shared: item.shared ?? missingField,
    };
  });

export const extractVirtualInterviewOrderDisplay = (order: IOrderVirtualInterviewData) => ({
  rating: !order?.rating?.length
    ? missingField
    : Concatenate(
      order.rating.map(x => x.orderPriorityType),
      ', ',
    ),
  vaAutoOffer: !order ? missingField : order.isVaAutoOffer ? 'Yes' : 'No',
  skillset: !order?.placementSkillset?.length ? missingField : deriveSkillsetString(order.placementSkillset),
  matchingSkillset: !order?.placementAllowableMismatch?.length
    ? missingField
    : deriveSkillsetString(order.placementAllowableMismatch),
});

export const extractVirtualInterviewCandidateDisplay = (candidate: ICandidateVirtualInterviewData[]) =>
  candidate.map(({ candidateInterviewItem: item, candidateInterviewHistory: history }) => {
    let skillset = Concatenate([item.disciplineAbbr, item.specialtyAbbr], ' - ');
    if (item.isAllowableMatch && !!skillset) skillset += ' *';
    return {
      candidateInterviewItem: item,
      candidateInterviewHistory: history,
      cardView: {
        skillset: skillset || missingField,
        reason: item.interviewReason || missingField,
        rating: item.interviewRating || missingField,
        status: item.interviewStatus || missingField,
        interviewDateValue: !item.interviewDate ? missingField : convertToFormat(item.interviewDate, 'MM/DD/yyyy'),
        sentDateValue: !item.invitationDate ? missingField : convertToFormat(item.invitationDate, 'MM/DD/yyyy'),
        lastUpdatedValue: !item.changedDate ? missingField : convertToFormat(item.changedDate, 'MM/DD/yyyy'),
        inviteId: item.inviteId ?? missingField,
        chipStyle: getStatusChip(item.interviewStatus),
        history: history.map((i, index) => ({
          ...i,
          fieldChanged: Concatenate([!i.fieldChangedId ? 'N/A' : i.fieldChangedId.toString(), i.fieldChanged], ' - '),
          changedDate: !i.changedDate ? missingField : convertToFormat(i.changedDate, 'MM/DD/yyyy'),
          id: index + 1,
        })),
      },
    };
  });

const getStatusChip = status => {
  switch (status) {
    case 'Complete':
      return getChipStyle(ChipStyle.Green);
    case 'Rejected':
      return getChipStyle(ChipStyle.Red);
    case 'Sent':
    case '1st Reminder':
    case '2nd Reminder':
      return getChipStyle(ChipStyle.Yellow);
    case 'Not Available':
    case 'Withdrawn':
      return getChipStyle(ChipStyle.Grey);
    default:
      return null;
  }
};
const deriveSkillsetString = (skillsets: { disciplineAbbr: string; specialtyAbbr: string }[] = []) =>
  Concatenate(
    skillsets.map(({ disciplineAbbr, specialtyAbbr }) => Concatenate([disciplineAbbr, specialtyAbbr], ' - ')),
    ', ',
  );

export const generateStringKey = propertiesObject => Object.values(propertiesObject).join('_');

export const getSubmissionSkillsetReqCounts = ({ currSkillset, skillsetOperation, isPrimary }) => {
  const counts = new Requirements();
  /**Licenses Start */
  const orderRequirement = currSkillset?.licenses?.orderRequirement;
  const licenseData = orderRequirement?.data;
  if (skillsetOperation === IOperation.AND) {
    if (licenseData?.operation === IOperation.AND) {
      (licenseData?.orderLicensesDetails || []).forEach(license => {
        counts.add(license?.type);
      });
    } else if (licenseData?.operation === IOperation.OR) {
      counts.add(orderRequirement?.type);
    }
    //Add risk count
    counts.add(requirementType.risk, licenseData?.riskRequirementCount);
  } else if (skillsetOperation === IOperation.OR) {
    //Check if skillset primary
    if (isPrimary) {
      if (licenseData?.operation === IOperation.AND) {
        (licenseData?.orderLicensesDetails || []).forEach(license => {
          counts.add(license?.type);
        });
      } else if (licenseData?.operation === IOperation.OR) {
        counts.add(orderRequirement?.type);
      }
      //Add risk count
      counts.add(requirementType.risk, licenseData?.riskRequirementCount);
    }
  }
  /**Licenses End */
  /**Certifications Start */
  const orderRequirementCert = currSkillset?.certifications?.orderRequirement;
  const certData = orderRequirementCert?.data;
  if (skillsetOperation === IOperation.AND) {
    if (certData?.operation === IOperation.AND) {
      (certData?.certifications || []).forEach(cert => {
        counts.add(cert?.type);
      });
    } else if (certData?.operation === IOperation.OR) {
      counts.add(orderRequirementCert?.type);
    }
    //Add risk count
    counts.add(requirementType.risk, certData?.riskRequirementCount);
  } else if (skillsetOperation === IOperation.OR) {
    //Check if skillset primary
    if (isPrimary) {
      if (certData?.operation === IOperation.AND) {
        (certData?.certifications || []).forEach(cert => {
          counts.add(cert?.type);
        });
      } else if (certData?.operation === IOperation.OR) {
        counts.add(orderRequirementCert?.type);
      }
      //Add risk count
      counts.add(requirementType.risk, certData?.riskRequirementCount);
    }
  }
  /**Certifications End */
  /**Experience Start */
  if (skillsetOperation === IOperation.AND) {
    counts.add(currSkillset?.experience?.orderRequirement?.type);
  } else if (skillsetOperation === IOperation.OR) {
    //Check if skillset primary
    if (isPrimary) {
      counts.add(currSkillset?.experience?.orderRequirement?.type);
    }
  }
  /**Experience End */

  return counts;
};

/**Format Certifications */
export const formatCertificates = ({ certifications, lookupCert }) => {
  if (certifications.candidateRequirement.data.candidateCredCertListResponse && lookupCert) {
    const formatedCredCerti = formatCredentialCertificates(
      certifications.candidateRequirement.data.candidateCredCertListResponse,
      lookupCert,
    );
    certifications.candidateRequirement.data.candidateCredCertListResponse = formatedCredCerti;
  }

  /** Logic to determine risk icons  */
  const isCandidateCertsExpiring = (certifications.candidateRequirement.data.candidateCertResponse || []).some(
    item => item.isExpired === false,
  );
  const isCredentialCertsExpiring = (certifications.candidateRequirement.data.candidateCredCertListResponse || []).some(
    item => item.isExpired === false,
  );

  const countOfCredentialsWithRisk = (
    certifications.candidateRequirement.data.candidateCredCertListResponse || []
  ).filter(item => item.isExpired === false).count;

  return {
    ...certifications,
    candidateRequirement: {
      ...certifications.candidateRequirement,
      data: {
        ...certifications.candidateRequirement.data,
        isCandidateCertsExpiring,
        isCredentialCertsExpiring,
        countOfCredentialsWithRisk,
      },
    },
  };
};

export const formatData = (skillset, lookupCert) => {
  const formatedSkillset = skillset.map(item => {
    const formatedCertifications = formatCertificates({ certifications: item.certifications, lookupCert });
    return {
      ...item,
      certifications: {
        ...item.certifications,
        ...formatedCertifications,
      },
    };
  });
  return formatedSkillset;
};
