import React from 'react';
import { Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormInputText } from '../Controls/FormInputText';
import { CountryPhoneNumber } from './CountryPhoneNumber';
import { useDispatch, useSelector } from 'react-redux';
import { candidateDetailActions } from 'app/components/Candidate/CandidateProfile/Profile/CandidateDetails.redux';
import { candidateDetailsSelection } from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.selector';

const useEmergencyContactStyles = makeStyles()(() => ({
  root: {
    margin: '0px !important',
    width: '100% !important',
    paddingBottom: '10px',
  },
  textColor: {
    color: '#0D6FB9',
    textAlign: 'left',
    font: 'normal normal medium 14px / 26px Roboto',
    fontWeight: 500,
    paddingLeft: 10,
    paddingTop: 8,
  },
}));

/**
 * Component to render emergency contact section in edit personal info modal
 */
export const EmergencyContact = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { classes } = useEmergencyContactStyles();
  const { control, setValue } = useFormContext();
  const candidateDetailsSel = useSelector(candidateDetailsSelection);

  const patchFieldValues = (value: string | null, countryCode: string, phone: string) => {
    dispatch(candidateDetailActions.updateEmergencyContactCountryCode({ countryCode: countryCode, phone: phone }));
    setValue('emergencyPhoneNumber', value);
  };

  /**
   * Method to split phone number and country code
   */
  const handleChange = (value: string, country, e, formattedValue) => {
    if (value !== '') {
      const getPhoneNumberCountryCode = formattedValue.split(' ');
      const setPhoneNumber = getPhoneNumberCountryCode.slice(1);
      const result = setPhoneNumber.join('');
      patchFieldValues(value, country.dialCode, result);
    } else {
      patchFieldValues(null, '', '');
    }
  };

  return (
    <LayoutGrid container direction="column" className={classes.root} spacing={4}>
      <Typography className={classes.textColor} variant="subtitle1" gutterBottom component="div">
        {t('candidate.profileSummaryCard.emergencyContact')}
      </Typography>
      <LayoutGridItem item xs={5} sx={{ width: '80%' }} paddingLeft="12px !important">
        <FormInputText
          name="emergencyContactName"
          label={t('candidate.emergencyContactSection.name')}
          require={false}
          isCheckBox={candidateDetailsSel.emergencyContact.name ? true : false}
          maxLength={255}
        />
      </LayoutGridItem>
      <LayoutGridItem item xs={5} sx={{ width: '80%' }} paddingLeft="12px !important">
        <FormInputText
          name="relationship"
          label={t('candidate.emergencyContactSection.relationship')}
          require={false}
          isCheckBox={candidateDetailsSel.emergencyContact.relationship ? true : false}
          maxLength={255}
        />
      </LayoutGridItem>
      <LayoutGridItem container direction="row" paddingLeft="12px !important">
        <LayoutGridItem xs={5}>
          <Controller
            control={control}
            name="emergencyPhoneNumber"
            render={({ value }) => <CountryPhoneNumber onChange={handleChange} value={value} />}
          />
        </LayoutGridItem>
        <LayoutGridItem xs={3} style={{ marginLeft: '24px' }}>
          <FormInputText
            name="extNote"
            label={t('candidate.emergencyContactSection.extNote')}
            require={false}
            isCheckBox={candidateDetailsSel.emergencyContact.extensionNotes ? true : false}
            maxLength={260}
          />
        </LayoutGridItem>
      </LayoutGridItem>
    </LayoutGrid>
  );
};
