import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGroupedSkillset } from 'app/components/Common/SkillsetTree/SkillsetTree';
import { ICredentials } from 'app/models/Credentialing/Credentials';
import { IPlacementTimestampResponse } from 'app/models/Placement';
import { IPlacementChangeHistory } from 'app/models/Placement/PlacementChangeHistory';
import {
  IPlacementDetails,
  IPlacementHeader,
  IQuickGlance,
  IPlacementKeyContacts,
  IPlacementCandidateRecords,
  CandidateSellingPointCounts,
  CandidatePlacementCounts,
  ILicense,
  IExperience,
  IPlacementUpdate,
  IPlacementProgress,
  PlacementOrderType,
  ILandsGtgDetails,
  IPassportCredential,
  StrikeStatus,
  IContactEditor,
  IPlacementTag,
  IPlacementRegionEdit,
  ComplianceStatusProgressNotesDetails,
  ComplianceStateProgressNotesHeader,
  DirectHireOption,
  DirectHireDetailsPayload,
  IKeyInternalContacts,
} from 'app/models/Placement/PlacementDetails';
import {
  initialHeaderState,
  initialContactsState,
  initialCandidateRecords,
  initialQuickGlance,
  initialProgress,
  intialLandsGtgdetails,
} from '../../../app/components/Placement/PlacementDetails/utils';
import { serviceActions } from './async-actions';
import { ICredOneDetails } from 'app/models/Placement/CredOneCompliance';
import { getDirectHireSelectedOption } from './helper';
import moment from 'moment';

export const initialState: IPlacementDetails = {
  placementOrderType: PlacementOrderType.Default,
  timestamp: undefined,
  progress: initialProgress,
  header: initialHeaderState,
  quickGlance: initialQuickGlance,
  contacts: { ...initialContactsState, flattenedContacts: null },
  candidateRecords: initialCandidateRecords,
  changeHistory: [],
  modalData: {},
  updateStatus: null,
  placementStatus: {
    currentPlacementStatus: null,
    availablePlacementStatus: [],
    updatePlacementStatusResponse: null,
  },
  requiredDataLoadStatus: undefined,
  landsGtgDetails: intialLandsGtgdetails,
  avoidPlacementParentForm: false,
  isLandsCancel: false,
  passportCredentials: null,
  isLicenseSelect: null,
  isLicenseSelectRedio: false,
  landsGtgCompletionStatus: { landsStatus: false, gtgStatus: false },
  resetForm: false,
  disciplineData: [],
  passportChecklist: [],
  credOneDetails: null,
  drivingDistanceMapLink: null,
  editor: {
    keyInternalContacts: {
      recruiter: {
        value: null,
        error: null,
        isDirty: false,
        checked: false,
      },
      accountManager: {
        value: null,
        error: null,
        isDirty: false,
        checked: false,
      },
      regionalDirector: {
        value: null,
        error: null,
        isDirty: false,
        checked: false,
      },
      credentialingAnalyst: {
        value: null,
        error: null,
        isDirty: false,
        checked: false,
      },
      clientContractSpecialist: {
        value: null,
        error: null,
        isDirty: false,
        checked: false,
      },
      clinicalManager: {
        value: null,
        error: null,
        isDirty: false,
        checked: false,
      },
    },
  },
  placementModificationTabRendered: false,
  placementLinkedSupervision: [],
  placementLinkedSupervisee: [],
  placementLinkModalOpen: false,
  placementLinkDetails: [],
  placementLink: [],
  placementTags: [],
  editPlacementRegion: undefined,
  complianceStatusProgress: {
    notes: '',
    notesList: [],
    originalNotesList: [],
    openDrawer: false,
    fullScreen: false,
    expanded: false,
    cancelled: false,
    isEditNotes: false,
    editableNoteDetails: {
      lastUpdatedDate: '',
      notes: '',
      userName: '',
    },
    isClearForm: false,
    complianceStateProgressNotesHeader: {
      license: [],
      certificates: [],
      docDealine: '',
      comments: '',
      saveStatus: null,
    },
  },
  extDirectHireOpts: [],
  selectedDirectHireDetail: {
    directHireOption: {
      id: '',
      description: '',
    },
    directHireLastUpdated: '',
    directHireLastUpdatedBy: '',
  },
  isUpdatedOrderId: false,
  focusedHeight: 0,
  isContactsExpanded: { isInternal: true, isClient: true },
};

const PlacementDetailSlice = createSlice({
  name: 'placementDetails',
  initialState,
  reducers: {
    setPlacementDetails(state, action) {
      return { ...state, ...action.payload };
    },
    setTimestamps(state, action: PayloadAction<IPlacementTimestampResponse | undefined>) {
      return {
        ...state,
        timestamp: {
          ...state.timestamp,
          placementTimestamp: action.payload?.placementTimestamp ?? state.timestamp?.placementTimestamp,
          candidateTimestamp: action.payload?.candidateTimestamp ?? state.timestamp?.candidateTimestamp,
          travelerOptionTimestamp: action.payload?.travelerOptionTimestamp ?? state.timestamp?.travelerOptionTimestamp,
        },
      };
    },
    resetForm(state, action: PayloadAction<undefined>) {
      return {
        ...state,
        resetForm: !state.resetForm,
      };
    },
    setRequiredDataLoadStatus(state, action: PayloadAction<boolean | undefined>) {
      return {
        ...state,
        requiredDataLoadStatus: action.payload,
      };
    },
    setPlacementOrderType(state, action: PayloadAction<PlacementOrderType>) {
      return { ...state, placementOrderType: action.payload };
    },
    setPlacementProgress(state, action: PayloadAction<IPlacementProgress>) {
      state.progress = action.payload;
    },
    setLandsGtgDetails(state, action: PayloadAction<ILandsGtgDetails>) {
      state.landsGtgDetails = action.payload;
    },
    setPlacementHeaderDetails(state, action: PayloadAction<IPlacementHeader>) {
      state.header = action.payload;
    },
    setCandidateAddress(state, action) {
      state.header.candidate.address = { ...state.header.candidate.address, ...action.payload };
    },
    setSSN(state, action: PayloadAction<{ ssn: string; timestamp: string }>) {
      return {
        ...state,
        header: {
          ...state.header,
          candidate: {
            ...state.header.candidate,
            ssn: action.payload,
          },
        },
      };
    },
    setDOB(
      state,
      action: PayloadAction<{
        dob: string | null;
        timestamp: string;
      }>,
    ) {
      return {
        ...state,
        header: {
          ...state.header,
          candidate: {
            ...state.header.candidate,
            dob: action.payload,
          },
        },
      };
    },
    setQuickGlanceDetails(state, action: PayloadAction<IQuickGlance>) {
      state.quickGlance = action.payload;
    },
    setPlacementContacts(state, action: PayloadAction<IPlacementKeyContacts & { flattenedContacts }>) {
      state.contacts = action.payload;
    },
    setCandidateRecords(state, action: PayloadAction<IPlacementCandidateRecords>) {
      state.candidateRecords = action.payload;
    },
    setCanidateSellingPoints(state, action: PayloadAction<CandidateSellingPointCounts>) {
      state.candidateRecords.candidateSellingPointCounts = action.payload;
    },
    setStrikeStatus(state, action: PayloadAction<StrikeStatus>) {
      state.candidateRecords.strikeStatus = action.payload;
    },
    setCandidatePlacements(state, action: PayloadAction<CandidatePlacementCounts>) {
      state.candidateRecords.candidatePlacementCounts = action.payload;
    },
    setModalData(state, action: PayloadAction<{ categoryId: any; data: any }>) {
      return {
        ...state,
        modalData: {
          ...state.modalData,
          [action.payload.categoryId]: action.payload.data,
        },
      };
    },
    setCandidateLicenses(state, action: PayloadAction<ILicense[]>) {
      state.candidateRecords.comparisons.candidate.licenses = action.payload;
    },
    setCandidateShifts(
      state,
      action: PayloadAction<{
        candidateId: number;
        requestedShifts: string;
        availabilityDate: string;
        referencesFulfilled: number;
      }>,
    ) {
      state.candidateRecords.comparisons.candidate.candidateId = action.payload.candidateId;
      state.candidateRecords.comparisons.candidate.requestedShifts = action.payload.requestedShifts;
      state.candidateRecords.comparisons.candidate.availabilityDate = action.payload.availabilityDate;
    },
    setCandidateTimeOffRequests(state, action: PayloadAction<any>) {
      return {
        ...state,
        candidateRecords: {
          ...state.candidateRecords,
          comparisons: {
            ...state.candidateRecords.comparisons,
            candidate: {
              ...state.candidateRecords.comparisons.candidate,
              requestedTimeOff: action.payload,
            },
          },
        },
      };
    },
    setCandidateVerifiedSkillsets(state, action: PayloadAction<IGroupedSkillset[]>) {
      state.candidateRecords.comparisons.candidate.skillsets = action.payload;
    },
    setCandidateExperience(
      state,
      action: PayloadAction<{
        experiences: IExperience[];
        totalYearsOfExp: number;
        totalMonthsOfExp: number;
      }>,
    ) {
      state.candidateRecords.comparisons.candidate.totalYearsOfExp = action.payload.totalYearsOfExp;
      state.candidateRecords.comparisons.candidate.totalMonthsOfExp = action.payload.totalMonthsOfExp;
    },
    reset() {
      return { ...initialState };
    },
    setCredentialCertifications(state, action: PayloadAction<ICredentials[]>) {
      return {
        ...state,
        candidateRecords: {
          ...state.candidateRecords,
          credentialingCertifications: action.payload,
        },
      };
    },
    setChangeHistory(state, action: PayloadAction<IPlacementChangeHistory[]>) {
      return {
        ...state,
        changeHistory: action.payload,
      };
    },
    setUpdatedPlacementFields(
      state,
      action: PayloadAction<{
        data: IPlacementUpdate;
        dirtyFields: any;
        status?: { success?: boolean; fail?: boolean; message?: string };
      }>,
    ) {
      const updates = {
        placementDate: action.payload.data.placementDate,
        availabilityDate: action.payload.data.availabilityDate,
        candidateBestTimeToCall: action.payload.data.bestTimeToCall,
        requestedShift: action.payload.data.requestedShift,
        travelsWithPartner: action.payload.data.travelsWithPartner,
        travelsWithPartnerName: action.payload.data.travelPartner,
        placementSellingPoints: action.payload.data.sellingPoints,
        lastUpdatedAt: action.payload.data.lastUpdatedAt,
        lastUpdatedBy: action.payload.data.lastUpdatedBy,
        requestedTimeOff: action.payload.data.summaryRequestedTimeOff,
        vmsReqDetails: {
          vmsReqNo: action.payload.data.vmsReqNo,
          lastUpdated: action.payload.data.vmsLastUpdated,
          lastUpdatedBy: action.payload.data.vmsLastUpdatedBy,
        },
        contractHours: action.payload.data.contractHours,
        numberOfWeeks: action.payload.data.numberOfWeeks,
        PlacementStartDate: action.payload.data.placementDate?.startDate ?? null,
        PlacementEndDate: action.payload.data.placementDate?.endDate ?? null,
      };

      return {
        ...state,
        updateStatus: action.payload.status || state.updateStatus,
        header: {
          ...state.header,
          placementDate: {
            startDate: updates?.PlacementStartDate,
            endDate: updates?.PlacementEndDate,
          },
          availabilityDate: updates.availabilityDate,
          candidateBestTimeToCall: updates.candidateBestTimeToCall,
          lastUpdatedAt: updates.lastUpdatedAt,
          lastUpdatedBy: updates.lastUpdatedBy,
          vmsReqDetails: {
            ...state.header.vmsReqDetails,
            ...updates.vmsReqDetails,
          },
          contractHours: updates.contractHours,
          numberOfWeeks: updates?.numberOfWeeks,
          placementStartDate: updates?.PlacementStartDate,
          placementEndDate: updates?.PlacementEndDate,
        },
        candidateRecords: {
          ...state.candidateRecords,
          comparisons: {
            ...state.candidateRecords.comparisons,
            candidate: {
              ...state.candidateRecords.comparisons.candidate,
              requestedShifts: updates.requestedShift,
              requestedTimeOff: updates.requestedTimeOff,
            },
          },
          candidateSellingPointCounts: {
            ...state.candidateRecords.candidateSellingPointCounts,
            travelsWithPartner: updates.travelsWithPartner,
            travelsWithPartnerName: updates.travelsWithPartnerName,
            placementSellingPoints: updates.placementSellingPoints,
          },
        },
      };
    },
    setAvoidPlacementParentForm(state, action: PayloadAction<boolean>) {
      state.avoidPlacementParentForm = action.payload;
    },
    setIsLandsCancel(state, action: PayloadAction<boolean>) {
      state.isLandsCancel = action.payload;
    },
    setPassportCredentials(state, action: PayloadAction<IPassportCredential[]>) {
      state.passportCredentials = action.payload;
    },
    setIslicenseSelect(state, action: PayloadAction<number | null>) {
      state.isLicenseSelect = action.payload;
    },
    setIslicenseSelectRedio(state, action: PayloadAction<boolean>) {
      state.isLicenseSelectRedio = action.payload;
    },
    setLandsGtgCompletionStatus(
      state,
      action: PayloadAction<{
        landsStatus: boolean;
        gtgStatus: boolean;
      }>,
    ) {
      state.landsGtgCompletionStatus = action.payload;
    },
    setCredOneDetails(state, action: PayloadAction<ICredOneDetails>) {
      state.credOneDetails = action.payload;
    },
    setDisciplineData(state, action: PayloadAction<any>) {
      state.disciplineData = action.payload;
    },
    setSpecialitiesData(state, action: PayloadAction<any>) {
      state.specialitiesData = action.payload;
    },
    setSubSpecialitiesData(state, action: PayloadAction<any>) {
      state.subSpecialitiesData = action.payload;
    },
    setIsFinalisedByQs(state, action: PayloadAction<boolean>) {
      state.header.isFinalizedByQS = action.payload;
    },
    setIsArchived(state, action: PayloadAction<boolean>) {
      state.header.isArchived = action.payload;
    },
    setLicensesList(state, action: PayloadAction<any[]>) {
      state.licensesList = action.payload;
    },
    setPassportChecklist(state, action: PayloadAction<any>) {
      state.passportChecklist = action.payload;
    },
    setDrivingDistanceMapLink(state, action: PayloadAction<string>) {
      return {
        ...state,
        drivingDistanceMapLink: action.payload,
      };
    },
    setExtTimeOffPayerOpts(state, action: PayloadAction<any>) {
      return {
        ...state,
        extTimeOffPayerOpts: action.payload,
      };
    },
    setExtDirectHireOpts(state, action: PayloadAction<DirectHireOption[]>) {
      return {
        ...state,
        extDirectHireOpts: action.payload,
      };
    },
    selectedDirectHireDetail(state, action: PayloadAction<DirectHireDetailsPayload>) {
      state.selectedDirectHireDetail = {
        ...state.selectedDirectHireDetail,
        directHireOption: getDirectHireSelectedOption(
          state.extDirectHireOpts,
          action.payload.directHireOptionId,
          action.payload.directHireTypeDescription,
        ),
        directHireLastUpdated: action.payload.directHireLastUpdated
          ? moment(action.payload.directHireLastUpdated).format('MM/DD/YYYY')
          : '',
        directHireLastUpdatedBy: action.payload.directHireLastUpdatedBy,
      };
    },
    editKeyInternalContact(
      state,
      action: PayloadAction<{
        key:
          | 'recruiter'
          | 'accountManager'
          | 'credentialingAnalyst'
          | 'regionalDirector'
          | 'clientContractSpecialist'
          | 'clinicalManager';
        data: IContactEditor;
      }>,
    ) {
      return {
        ...state,
        editor: {
          ...state.editor,
          keyInternalContacts: {
            ...state.editor.keyInternalContacts,
            [action.payload.key]: action.payload.data,
          },
        },
      };
    },
    setKeyInternalContactUpdateError(
      state,
      action: PayloadAction<{
        key: 'recruiter' | 'accountManager' | 'credentialingAnalyst' | 'regionalDirector' | 'clientContractSpecialist';
        error: { message: string } | null;
      }>,
    ) {
      return {
        ...state,
        editor: {
          ...state.editor,
          keyInternalContacts: {
            ...state.editor.keyInternalContacts,
            [action.payload.key]: {
              ...state.editor.keyInternalContacts[action.payload.key],
              error: action.payload.error,
            },
          },
        },
      };
    },
    removeKeyInternalContactHelperText(
      state,
      action: PayloadAction<
        'recruiter' | 'accountManager' | 'credentialingAnalyst' | 'regionalDirector' | 'clientContractSpecialist'
      >,
    ) {
      return {
        ...state,
        editor: {
          ...state.editor,
          keyInternalContacts: {
            ...state.editor.keyInternalContacts,
            [action.payload]: {
              ...state.editor.keyInternalContacts[action.payload],
              helperText: undefined,
            },
          },
        },
      };
    },
    setModificationTabRendered(state, action: PayloadAction<Boolean>) {
      state.placementModificationTabRendered = action.payload;
    },
    setPlacementSupervision(state, action: PayloadAction<any[]>) {
      state.placementLinkedSupervision = action.payload;
    },
    setPlacementSupervisee(state, action: PayloadAction<any[]>) {
      state.placementLinkedSupervisee = action.payload;
    },
    setPlacementLinkModalOpen(state, action: PayloadAction<boolean>) {
      state.placementLinkModalOpen = action.payload;
    },
    setPlacementLinkDetails(state, action: PayloadAction<any[]>) {
      state.placementLinkDetails = action.payload;
    },
    setPlacementLinkId(state, action: PayloadAction<any>) {
      state.placementLink = action.payload;
    },
    setPlacementTags(state, action: PayloadAction<IPlacementTag[]>) {
      state.placementTags = action.payload;
    },
    editPlacementRegion(state, action: PayloadAction<IPlacementRegionEdit | undefined>) {
      return {
        ...state,
        editPlacementRegion: action.payload,
      };
    },
    setEditPlacementRegionError(state, action: PayloadAction<{ message: string }>) {
      return {
        ...state,
        editPlacementRegion: {
          ...state?.editPlacementRegion,
          error: action.payload,
        },
      };
    },
    setComplianceStatusNotes(
      state,
      action: PayloadAction<{
        notes: string;
        notesList: Array<ComplianceStatusProgressNotesDetails>;
      }>,
    ) {
      state.complianceStatusProgress.notes = action.payload.notes;
      state.complianceStatusProgress.notesList = action.payload.notesList ?? [];
      state.complianceStatusProgress.originalNotesList = action.payload.notesList ?? [];
    },
    toggleComplianceStatusNotesDrawerState(state, action: PayloadAction<boolean>) {
      state.complianceStatusProgress.openDrawer = action.payload;
    },
    toggleComplianceStatusNotesDrawerFullScreenState(state, action: PayloadAction<boolean>) {
      state.complianceStatusProgress.fullScreen = action.payload;
    },
    toggleComplianceStatusNotesDrawerNoteTextboxExpanded(state, action: PayloadAction<boolean>) {
      state.complianceStatusProgress.expanded = action.payload;
    },
    toggleComplianceStatusNotesDrawerNoteCancel(state, action: PayloadAction<boolean>) {
      state.complianceStatusProgress.cancelled = action.payload;
    },
    toggleComplianceStatusNotesDrawerClearForm(state, action: PayloadAction<boolean>) {
      state.complianceStatusProgress.isClearForm = action.payload;
    },
    editComplianceStatusNotes(state, action: PayloadAction<ComplianceStatusProgressNotesDetails>) {
      state.complianceStatusProgress.editableNoteDetails = action.payload;
    },
    setEditComplianceStatusNotes(state, action: PayloadAction<boolean>) {
      state.complianceStatusProgress.isEditNotes = action.payload;
    },
    setComplianceStatusHeader(state, action: PayloadAction<Partial<ComplianceStateProgressNotesHeader>>) {
      state.complianceStatusProgress.complianceStateProgressNotesHeader = {
        ...state.complianceStatusProgress.complianceStateProgressNotesHeader,
        ...action.payload,
      };
    },
    setFilteredComplianceStatusNotes(state, action: PayloadAction<Array<ComplianceStatusProgressNotesDetails>>) {
      state.complianceStatusProgress.notesList = action.payload;
    },
    setInternalKeyContacts(state, action: PayloadAction<IKeyInternalContacts>) {
      return {
        ...state,
        editor: {
          ...state.editor,
          keyInternalContacts: {
            ...action.payload,
          },
        },
      };
    },
    setClientContactsCheckbox(state, action: PayloadAction<number>) {
      return {
        ...state,
        contacts: {
          ...state.contacts,
          flattenedContacts: {
            ...state.contacts?.flattenedContacts,
            facilityContacts: state.contacts?.flattenedContacts?.facilityContacts?.map(item => {
              if (item?.id === action.payload) {
                return { ...item, checked: !item?.checked };
              }
              return item;
            }),
          },
        },
      };
    },
    selectAllClientContacts(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        contacts: {
          ...state.contacts,
          flattenedContacts: {
            ...state.contacts?.flattenedContacts,
            facilityContacts: state.contacts?.flattenedContacts?.facilityContacts?.map(item => {
              return { ...item, checked: item?.email ? action.payload : false };
            }),
          },
        },
      };
    },

    setIsUpdatedOrderId(state, action: PayloadAction<boolean>) {
      state.isUpdatedOrderId = action.payload;
    },
    setFocusedHeight(state, action: PayloadAction<number>) {
      state.focusedHeight = action.payload;
    },
    setContactsExpanded(state, action: PayloadAction<{ type: 'isInternal' | 'isClient'; value: boolean }>) {
      state.isContactsExpanded = { ...state.isContactsExpanded, [action.payload.type]: action.payload.value };
    },
  },
});

const { actions } = PlacementDetailSlice;
export const { reducer: placementDetailReducer, name: placementDetailSliceKey } = PlacementDetailSlice;
export const placementDetailsAction = { ...actions, ...serviceActions };
