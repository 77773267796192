/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Grid, ThemeProvider, StyledEngineProvider,useTheme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { reqPref } from 'app/enums/Unit';
import { Controller, useFormContext } from 'react-hook-form';
import { ReqPrefSection } from '../Common/ReqPrefSection';
import { SimpleCheckbox } from '../Common/SimpleCheckbox';
import { UnitCardsTitle, UnitDetailsBody } from '../UnitDesign';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  selectIsEditUnit,
  selectLevel,
} from 'app/components/Order/Store/Order.selectors';
import { getElement } from 'app/components/Order/OrderCreationForm/helper';
import { selectHasNotNow, selectOrderDetails } from '@AMIEWEB/Order/Store/OrderCreation.selectors';
import { NewExperienceSkillSet } from './NewExperienceSkillSet';
import { ReqOrderFieldNames } from '@AMIEWEB/Order/Store/types';
import { AMNDivisionType } from 'app/models/enums/AMNDivisionType';

interface Props {
  division: number | undefined;
  isEdit?: boolean;
  showMandetryStyle?: boolean;
  incompleteReqFields?: ReqOrderFieldNames[];
}

const useStyles = makeStyles()((theme) => ({
  addDisciplineSpecialty: {
    fontSize: 14,
    color: '#333333',
    fontWeight: 500,
    paddingRight: 23,
  },
}));

export const NewExperience = (props: Props) => {
  const { division, isEdit, showMandetryStyle, incompleteReqFields } = props;
  const { t } = useTranslation();
  const theme= useTheme();
  const { classes } = useStyles();
  const experienceHeader = 'Experience';
  const addDisciplineSpecialty = 'Add Discipline & Specialty';
  const { control } = useFormContext();
  //const watchValue = watch('skillsets');

  const [isRequired, setIsRequired] = React.useState<boolean>(true);
  const hasNotNow = useSelector(selectHasNotNow);
  const orderDetails = useSelector(selectOrderDetails);

  const isEditUnit = useSelector(selectIsEditUnit);
  const level = useSelector(selectLevel);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsRequired(event.target.value === reqPref.req);
  };

  const getNewGradEnabledStatus = () => {
    return !hasNotNow
  };

  return (
    <div>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <UnitDetailsBody variant="outlined">
              <Grid container direction="column" spacing={2}>
                <Grid item xs={12}>
                  <Grid container direction="row" spacing={2}>
                    <Grid item>
                      <UnitCardsTitle>{experienceHeader}</UnitCardsTitle>
                    </Grid>
                    <Grid item>
                      <Controller
                        control={control}
                        name={"newGrad"}
                        render={({ ref, onChange, ...rest }) => (
                          <SimpleCheckbox
                            isChecked={rest.value || false}
                            handleChecked={event => {
                              onChange(event.target.checked);
                            }}
                            checkboxLabel={t('order.createUnit.experience.newGrad')}
                            checkboxName={'newGrad'}
                            isdisabled={!getNewGradEnabledStatus()}
                            id={'createUnitNewGradCheck'}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container direction="row" spacing={2}>
                    <Grid item>
                      <div className={`${classes.addDisciplineSpecialty}`}>
                        {addDisciplineSpecialty}
                        <span style={{ color: '#e90707' }}>{t('*')}</span>
                      </div>
                    </Grid>
                    <ReqPrefSection
                      isRequired={isRequired}
                      handleReqPref={handleChange}
                      id={'createUnitDisciplineSpecialtyReqPrefRadio'}
                    />
                    <Grid item xs={12}>
                    <Controller
                        control={control}
                        name={"skillsets"}
                        render={({ ref, onChange, ...rest }) => (
                          <NewExperienceSkillSet
                            onNewSkillAddition={newValue => {
                              onChange(newValue);
                            }}
                            isRequired={isRequired}
                            hasMultipleChips={division === AMNDivisionType.allied || division === AMNDivisionType.hsg}
                            hasEdit={isEdit}
                            showMandetryStyle={showMandetryStyle !== true}
                            incompleteReqFields={incompleteReqFields}
                            expSkillSetData={rest.value ?? []}
                            orderDetails={orderDetails}
                            isDisabled={isEditUnit ? getElement(level, 'newGrad')?.disabled : false}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </UnitDetailsBody>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
};
