import React, { useState } from 'react';
import { GridCellParams } from '@mui/x-data-grid-pro';
import Grid from '@mui/material/Grid/Grid';
import { FormControlLabel, IconButton, Radio, RadioGroup } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { selectOrderCertification } from '@AMIEWEB/Order/Store/Order.selectors';
import { IOrderCertification, orderDataActions } from '@AMIEWEB/Order/Store/Order.redux';
import { useTheme } from 'amn-ui-core';
import { useTranslation } from 'react-i18next';
import { CustomTooltip } from 'app/components/Common/Tooltips';

interface GridCellProps {
  [key: string]: any;
}

const useStyles = makeStyles()(theme => ({
  icon: {
    borderRadius: 3,
    padding: 2,
    border: '1px solid #cccccc',
    marginTop: 3,
    height: 36,
  },
  tooltip: {
    backgroundColor: theme.palette.components.tooltip.backgroundColor,
    color: '#333333',
    opacity: 1,
    textAlign: 'left',
    letterSpacing: '0px',
    boxShadow: '0px 3px 6px #00000029',
    border: '1px solid #8C8C8C',
  },

  iconSize: {
    fontSize: 'larger',
    padding: 4,
  },

}));

const CertificationSettingRenderer = React.memo(function CertificationSettingRenderer(cellParams: GridCellProps) {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const orderCertifications = useSelector(selectOrderCertification);
  const [isRequiredSelection, setRequiredSelection] = useState<boolean>(true);
  const [renderOptions, setRenderOptions] = useState<boolean>(false);
  const [documentAttachmentRequired, setDocumentAttachmentRequired] = useState<boolean>(false);
  const theme = useTheme();

  const handleChange = (e, value) => {
    if (value === 'req') {
      setRequiredSelection(true);
    } else {
      setRequiredSelection(false);
    }
  };

  const handleDocumentClick = () => {
    if (documentAttachmentRequired) {
      setDocumentAttachmentRequired(false);
    } else {
      setDocumentAttachmentRequired(true);
    }
  };

  React.useEffect(() => {
    const orderCertificationDetails: IOrderCertification[] = [...orderCertifications];
    if (orderCertifications && orderCertifications?.length > 0) {
      const licenseDetailsIndex = orderCertificationDetails?.findIndex(
        data => data?.requirementTypeId === cellParams.id,
      );
      const selectedCertificationOptionDetails = orderCertifications?.find(
        data => data?.requirementTypeId === cellParams.id,
      );
      if (licenseDetailsIndex !== -1) {
        const selectedCertificationDetail: IOrderCertification = {
          ...selectedCertificationOptionDetails,
          isRequired: isRequiredSelection,
          documentAttachmentRequired: documentAttachmentRequired,
        };
        orderCertificationDetails[licenseDetailsIndex] = { ...selectedCertificationDetail };
      } else {
        if (selectedCertificationOptionDetails) {
          const selectedCertificationDetail: IOrderCertification = {
            ...selectedCertificationOptionDetails,
            isRequired: isRequiredSelection,
            documentAttachmentRequired: documentAttachmentRequired,
          };
          orderCertificationDetails.push(selectedCertificationDetail);
        }
      }
    }
    dispatch(orderDataActions.setOrderCertificationDetails(orderCertificationDetails));
  }, [isRequiredSelection, documentAttachmentRequired]);

  React.useEffect(() => {
    const existingCertification = orderCertifications?.find(data => data?.requirementTypeId === cellParams.id);
    if (cellParams?.id && existingCertification && existingCertification?.requirementTypeId !== 122) {
      setRenderOptions(true);
      setDocumentAttachmentRequired(existingCertification?.documentAttachmentRequired);
      setRequiredSelection(existingCertification?.isRequired);
    } else {
      setRenderOptions(false);
      setDocumentAttachmentRequired(false);
      setRequiredSelection(true);
    }
  }, [orderCertifications]);

  return (
    <React.Fragment>
      {renderOptions && (
        <Grid container style={{ pointerEvents: cellParams?.isDisabled ? 'none' : 'auto' }}>
          <Grid item>
            <RadioGroup aria-label="Required" name={'required'} onChange={(e, value) => handleChange(e, value)} row>
              <FormControlLabel
                value="req"
                control={
                  <Radio
                    color="primary"
                    disableRipple
                    disableTouchRipple
                    disableFocusRipple
                    checked={isRequiredSelection}
                  />
                }
                label="Req"
              />
              <FormControlLabel
                value="pref"
                control={
                  <Radio
                    color="primary"
                    disableRipple
                    disableTouchRipple
                    disableFocusRipple
                    checked={!isRequiredSelection}
                  />
                }
                label="Pref"
              />
            </RadioGroup>
          </Grid>
          <Grid item>
            <CustomTooltip
              tooltipContent={t('order.certification.docAttachedTooltip')}
              placement="bottom"
              arrow
              classes={{
                tooltip: classes.tooltip,
              }}
            >
              <IconButton
                color="primary"
                edge="end"
                aria-label="document"
                disableFocusRipple
                disableTouchRipple
                disableRipple
                className={`${classes.icon}`}
                onClick={() => handleDocumentClick()}
                size="small"
              >
                <DescriptionOutlinedIcon
                  style={{
                    fill:
                      documentAttachmentRequired === true
                        ? theme.palette.components.icons.primary.color
                        : theme.palette.system.mediumGrey,
                  }}
                  className={classes.iconSize}
                />
              </IconButton>
            </CustomTooltip>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
});

export const CertificationSettingRenderCell = (props: { param: GridCellParams; isDisabled: boolean }) => {
  return <CertificationSettingRenderer {...props?.param} isDisabled={props?.isDisabled} />;
};
