import { PayloadAction } from '@reduxjs/toolkit';
import { actionChannel, all, call, delay, put, race, select, take } from 'redux-saga/effects';
import { placementDetailsAction } from './slice';
import {
  requestDisciplineLookup,
  requestHeaderDetails,
  requestRequiredPlacementData,
} from './request.saga';
import { placementSummaryActions } from '../placement-summary/slice';
import { PlacementOrderType } from 'app/models/Placement/PlacementDetails';
import { placementStatusAction } from '../placement-status/slice';
import { pageAttributesActions } from '@AMIEWEB/Placement/PlacementDetails/Edit/store/placementPageAttributes.redux';
import { placementModsAction } from '../placement-mods/slice';
import { orderPreferenceActions } from '@AMIEWEB/Order/OrderDetails/OrderPreferences/store/OrderPreference.redux';
import { lookupActions } from '../lookup/lookup.slice';
import { ExceptionType } from 'app/enums/Common';
import { trackException } from 'app-insights/appInsightsTracking';
import { reportsViewActions } from '../report-details/slice';
import moment from 'moment';
import { consolidatedServiceActions } from '../placement-summary/consolidatedStoreActions';
import { selectBulkDetailsLoadState, selectErrorStatus, selectInitialDetailsLoadState } from '../placement-summary/consolidatedStoreSelectors';
import { setIsLoadingCompletedStatus, setPlacementInitialBulkDetails, setPlacementInitialDetails } from '../placement-summary/consolidatedStoreSlice';
import { buffers } from 'redux-saga';
import { getCueNoteRecipientPayload } from './helper';
import { taskDetailsActions } from '@AMIEWEB/Tasks/store/Tasks.redux';

function* waitForSpecificState(selector, expectedValue, actionTypes) {
  const state = yield select(selector);
  if (state === expectedValue) {
    return;
  }
  yield race(actionTypes.map(type => take(type)));
}

function* monitorActions(channel, actionType) {
  while (true) {
    const action = yield take(channel);
    if (action.type === actionType) {
      break;
    }
  }
}

export function* onInit(
  action: PayloadAction<{
    placementId: number;
    peoplesoftEnabled: boolean;
    requirementCheckFlagEnabled: boolean;
    isOrderGeneralRequirement?: boolean;
    checkClassicPacketInOkToSendEnabled: boolean;
    arbitrationAgreementFlagEnabled?: boolean;
    isPerformanceOptimized?: boolean;
  }>,
) {

  const isPerformanceOptimized = action.payload?.isPerformanceOptimized;
  //isPerformanceOptimized = false;
  
  yield put(setIsLoadingCompletedStatus({status: false}));

  yield put(taskDetailsActions.getCueNoteRecipient(getCueNoteRecipientPayload(action.payload.placementId)));

  if (isPerformanceOptimized) {
    yield put(consolidatedServiceActions.getPlacemnetConsolidatedInitialDetails({ 
      placementId: action.payload.placementId 
    }));
    yield put(consolidatedServiceActions.getPlacemnetConsolidatedInitialBulkDetails({ 
      placementId: action.payload.placementId 
    }));
    yield waitForSpecificState(selectInitialDetailsLoadState, true, [
      setPlacementInitialDetails.type,
    ]);
  }
  const errorStatus = yield select(selectErrorStatus);
  if(errorStatus){
    return;
  }

  try {
    const {
      placementId,
      requirementCheckFlagEnabled,
      arbitrationAgreementFlagEnabled,
      checkClassicPacketInOkToSendEnabled,
    } = action.payload;
    const [placementOrderType, { candidateId, orderId }] = yield all([
      /** Tag - Updated within init */
      call(requestRequiredPlacementData, {
        payload: { placementId, isInitLoad: isPerformanceOptimized },
        type: placementDetailsAction.getRequiredPlacementData.type,
      }),
      /** Tag - Updated within init */
      call(requestHeaderDetails, {
        payload: { placementId, isInitLoad: isPerformanceOptimized },
        type: placementDetailsAction.getRequiredPlacementData.type,
      }),
      call(requestDisciplineLookup),
    ]);

    if (!!placementOrderType) {
      /** Tag - Updated within init */
      yield put(placementSummaryActions.getMilesRadiusOrderRequirement({ 
        placementId,
      }));
    }

    if (isPerformanceOptimized) {
      yield waitForSpecificState(selectBulkDetailsLoadState, true, [
        setPlacementInitialBulkDetails.type,
      ]);
    }

    const actionChannelBuffer = yield actionChannel('*', buffers.expanding());
    const putActions = [];

    if (checkClassicPacketInOkToSendEnabled 
      && !!placementOrderType && placementOrderType !== PlacementOrderType.StrikeOrderInHsg) {
      /** Tag - Updated within init */
      putActions.push(placementSummaryActions.checkForClassicSubmissionPacketsInOkToSend({ 
        placementId, 
        isInitLoad: isPerformanceOptimized }));
    }
    if (!!placementOrderType) {

      //Submission AMN Requirements - Strike Placement
      /**Check for Strke placement */
      if (placementOrderType === PlacementOrderType.StrikeOrderInHsg) {
        /** Tag - Updated within init */
        putActions.push(placementSummaryActions.getTransportationData({ 
          placementId, 
          isInitLoad: isPerformanceOptimized 
        }));
        /** Tag - Updated within init */
        putActions.push(placementSummaryActions.getOfferConfirmationRequirement({ 
          placementId, 
          isInitLoad: isPerformanceOptimized 
        }));
        /** Tag - Updated within init */
        putActions.push(placementSummaryActions.getBookingConfirmationRequirement({ 
          placementId, 
          isInitLoad: isPerformanceOptimized 
        }));
        /** Tag - Updated within init */
        putActions.push(placementSummaryActions.getBookingCredentialingRequirement({ 
          placementId, 
          isInitLoad: isPerformanceOptimized 
        }));
        /** Tag - Updated within init */
        putActions.push(placementSummaryActions.getOfferTravelDetailsRequirement({ 
          placementId, 
          isInitLoad: isPerformanceOptimized 
        }));
        /** Tag - Updated within init */
        putActions.push(placementSummaryActions.getTransportationPicklists());
      } else if (placementOrderType === PlacementOrderType.Default) {
        // Interview
        /** Tag - Updated within init */
        putActions.push(placementSummaryActions.getVirtualInterviewRequirement({
          placementId, 
          isInitLoad: isPerformanceOptimized 
        }));
        //Extension details
        /** Tag - Updated within init */
        putActions.push(placementStatusAction.getExtentionStatusDetails({ 
          placementId, 
          isInitLoad: isPerformanceOptimized 
        }));
        /** Tag - Updated within init */
        putActions.push(placementSummaryActions.getBookingRequirement({ 
          placementId,
           placementOrderType, 
           isInitLoad: isPerformanceOptimized 
          }));
        /** Tag - Updated within init */
        putActions.push(placementSummaryActions.getBookingApprovedRequirements({ 
          placementId, 
          placementOrderType, 
          isInitLoad: isPerformanceOptimized  
        }));
        /** Tag - Updated within init */
        putActions.push(placementSummaryActions.getWaitingGoodToGoRequirements({ 
          placementId, 
          placementOrderType, 
          isInitLoad: isPerformanceOptimized 
        }));
      }

      putActions.push(placementSummaryActions.getAssignmentInformationRequirement({ 
        placementId, 
        isInitLoad: isPerformanceOptimized 
      }));
      
      /** Tag - Updated within init */
      putActions.push(
        placementSummaryActions.getExperienceRequirement({
          placementId,
          placementOrderType,
          isOrderGeneralRequirement: action.payload.isOrderGeneralRequirement,
          isInitLoad: isPerformanceOptimized
        }),
      );
      /** Tag - Updated within init */
      putActions.push(
        placementSummaryActions.getEducationAmnRequirement({
          placementId,
          placementOrderType,
          isInitLoad: isPerformanceOptimized
        }),
      );
      /** Tag - Updated within init */
      putActions.push(
        placementSummaryActions.getAuditAmnRequirement({
          placementId,
          placementOrderType,
          isInitLoad: isPerformanceOptimized
        }),
      );
    }
    if (action.payload.peoplesoftEnabled) {
      putActions.push(placementSummaryActions.getPeopleSoftDetails({ placementId }));
    }
    putActions.push(placementDetailsAction.getExtDirectHireOpts());
    /** Tag - Updated within init */
    putActions.push(placementDetailsAction.getPlacementProgressAction({ 
      placementId, 
      isInitLoad: isPerformanceOptimized 
    }));
    /** Tag - To be invoked on accessing Credential data */
    putActions.push(placementDetailsAction.getPlacementLandsGtgAction({ 
      placementId,
       isInitLoad: isPerformanceOptimized 
      }));
    /** Tag - Updated within init */
    putActions.push(placementStatusAction.getPlacementStatusDetails({ 
      placementId, 
      isInitLoad: isPerformanceOptimized 
    }));
    /** Tag - To be invoked on accessing contacts drawer */
    putActions.push(placementDetailsAction.getPlacementContactsAction({ 
      placementId, 
      isInitLoad: isPerformanceOptimized 
     }));
    /** Tag - Updated within init */
    putActions.push(placementDetailsAction.getCandidateRecordsRightAction({ 
      placementId, 
      isInitLoad: isPerformanceOptimized  
    }));
    /** Tag: Not in use ??????????? */
    putActions.push(placementDetailsAction.getCredentialCertifications({ placementId }));
    /** Tag - Updated within init */
    putActions.push(pageAttributesActions.getEditableStatusesAction({ 
      placementId, 
      isInitLoad: isPerformanceOptimized 
    }));

    //Submission AMN Requirements
    /** Tag - Updated within init */
    putActions.push(
      placementSummaryActions.getSkillsetRequirement({
        placementId,
        isOrderGeneralRequirement: action.payload.isOrderGeneralRequirement,
        isInitLoad: isPerformanceOptimized,
      }),
    );
    /** Tag - Updated within init */
    putActions.push(placementSummaryActions.getStatusAmnRequirement({
       placementId, 
       isInitLoad: isPerformanceOptimized 
      }));
    /** Tag - Updated within init */
    putActions.push(
      placementSummaryActions.workHistorySubmissionRequirement({
        placementId,
        requirementCheckFlagEnabled: requirementCheckFlagEnabled,
        isInitLoad: isPerformanceOptimized,
      }),
    );
    /** Tag - Updated within init */
    putActions.push(placementSummaryActions.getReferenceAmnRequirement({ placementId }));
    /** Tag - Updated within init */
    putActions.push(
      placementSummaryActions.getSkillSChecklistRequirement({
        placementId,
        isOrderGeneralRequirement: action.payload.isOrderGeneralRequirement,
        isInitLoad: isPerformanceOptimized,
      }),
    );
    /** Tag - Updated within init */
    putActions.push(placementSummaryActions.getApplicationAmnRequirement({ 
      placementId, 
      isInitLoad: isPerformanceOptimized 
    }));

    //Submission Order Requirements
    /** Tag - Updated within init */
    putActions.push(placementSummaryActions.getStatusOrderRequirement({ 
      placementId, 
      isInitLoad: isPerformanceOptimized  
    }));
    /** Tag - Updated within init */
    putActions.push(placementSummaryActions.getFacilityStatusOrderRequirement({ 
      placementId, 
      isInitLoad: isPerformanceOptimized 
    }));
    if (requirementCheckFlagEnabled) {
      /** Tag - Updated within init */
      putActions.push(placementSummaryActions.getDOBStatusOrderRequirement({ 
        placementId, 
        isInitLoad: isPerformanceOptimized 
      }));
      /** Tag - Updated within init */
      putActions.push(placementSummaryActions.getSsnStatusOrderRequirement({
         placementId, 
         isInitLoad: isPerformanceOptimized 
        }));
    }
    /** Tag - Updated within init */
    putActions.push(placementSummaryActions.getDatesOrderRequirement({ 
      placementId, 
      isInitLoad: isPerformanceOptimized 
    }));
    /** Tag - Updated within init */
    putActions.push(placementSummaryActions.getScheduleOrderRequirement({ 
      placementId, 
    }));//do not load as part of bulk
    /** Tag: Not in use ??????????? */
    putActions.push(placementSummaryActions.getWorkHistoryOrderRequirement({ placementId }));
    /** Tag - Updated within init */
    putActions.push(
      placementSummaryActions.getReferenceOrderRequirement({
        placementId,
        requirementCheckFlagEnabled: requirementCheckFlagEnabled,
        isInitLoad: isPerformanceOptimized,
      }),
    );
    if (action.payload.isOrderGeneralRequirement) {
      putActions.push(placementSummaryActions.getGroupedSkillSetRequirements({ placementId }));
    }
    /** Tag - Updated within init */
    putActions.push(
      placementSummaryActions.getLicenseOrderRequirement({
        placementId,
        isOrderGeneralRequirement: action.payload.isOrderGeneralRequirement,
        isInitLoad: isPerformanceOptimized,
      }),
    );
    /** Tag - Updated within init */
    putActions.push(
      placementSummaryActions.getCertificationOrderRequirement({
        placementId,
        isOrderGeneralRequirement: action.payload.isOrderGeneralRequirement,
      }),
    );

    /** Tag - Updated within init */
    putActions.push(placementSummaryActions.getMiscDetailsOrderRequirement({ 
      placementId, 
      isInitLoad: isPerformanceOptimized 
    }));
    /** Tag - Updated within init */
    putActions.push(placementSummaryActions.getCovidOrderRequirement({ 
      placementId, 
      isInitLoad: isPerformanceOptimized 
    }));
    /** Tag: Not in use ??????????? */
    putActions.push(placementSummaryActions.getDocumentsOrderRequirement({ placementId }));
    //Order Information
    /** Tag - Updated within init */
    putActions.push(placementSummaryActions.getOrderInformationRequirement({ 
      placementId, 
      isInitLoad: isPerformanceOptimized 
    }));

    putActions.push(placementModsAction.getPlacementModsList({ placementId }));
    putActions.push(placementModsAction.getRefreshPlacementModsList({ placementId }));
    putActions.push(placementModsAction.getStartDateReasonCodes());
    putActions.push(placementModsAction.getInitiators({ placementId: placementId.toString() }));

    /** Tag - Updated within init */
    putActions.push(placementDetailsAction.getPlacementSupervisorAction({ 
      placementId, 
      isInitLoad: isPerformanceOptimized 
    }));
    /** Tag - Updated within init */
    putActions.push(placementDetailsAction.getPlacementSuperviseeAction({ 
      placementId, 
      isInitLoad: isPerformanceOptimized 
    }));

    /** Tag - Updated within init */
    putActions.push(placementDetailsAction.getPlacementTagsAction({ 
      placementId, 
      isInitLoad: isPerformanceOptimized 
    }));

    /** Tag - Updated within init */
    putActions.push(orderPreferenceActions.getAutomationStatusByStage({ orderId }));

    /** Tag: Not in use ??????????? */
    putActions.push(placementDetailsAction.getCandidatePreferenceAction({ candidateId }));
    /** Tag - Updated within init */
    putActions.push(placementSummaryActions.getReferenceAndEmploymentVerificationRecords({ 
      placementId, 
      isInitLoad: isPerformanceOptimized 
    }));
    /** Tag - To be invoked on accessing Credential data */
    putActions.push(
      placementDetailsAction.getCredOneAction({
        candidateId,
        placementId,
      }),
    );
    /** Tag - Updated within init */
    putActions.push(placementSummaryActions.getSubmissionPacketDetails({ 
      placementId, placementOrderType, 
      isInitLoad: isPerformanceOptimized 
    }));
    /** Tag - Updated within init */
    putActions.push(
      placementSummaryActions.getArbitrationAgreementRequirements({
        placementId,
        arbitrationAgreementFlagEnabled: arbitrationAgreementFlagEnabled,
        isInitLoad: isPerformanceOptimized,
      }),
    );
    putActions.push(lookupActions.getAllLookupOptions());
    putActions.push(reportsViewActions.getR448ReportDetails({ orderId: orderId, startDate: moment().format('YYYY-MM-DD') }));

    for (const actionLc of putActions) {
      yield put(actionLc);
      yield call(monitorActions, actionChannelBuffer, actionLc.type);
    }

    yield put(setIsLoadingCompletedStatus({status: true}));

  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'onInit',
        area: 'src/store/redux-store/placement-details/business.saga.ts',
      },
    });
  }
}

