import { AutoTaskManagementPage } from '@AMIEWEB/Settings/AutoTaskManagement';
import { TemplateEditor } from '@AMIEWEB/Settings/Templates';
import { UserManagement } from '@AMIEWEB/Settings/UserManagement';
import { EditUnitWrapper } from '@AMIEWEB/Unit/EditUnitWrapper';
import { NewUnitWrapper } from '@AMIEWEB/Unit/NewUnitWrapper';
import { UnitDetailsLandingPage } from '@AMIEWEB/Unit/UnitDetails/UnitLandingPageWrapper';
import { PageNotFound } from 'app/components/Common/PageNotFound';
import { Layout } from 'app/layout';
import { Callback } from 'oidc/Callback';
import { SignoutCallback } from 'oidc/SignoutCallback';
import { SilentRenew } from 'oidc/SilentRenew';
import { UnauthorizedLoadable } from 'oidc/Unauthorized.loadable';
import { userRoles } from 'oidc/userRoles';
import CandidatePage from 'pages/CandidatePage';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PrivateRoute } from './PrivateRoute';
import { OptimizelyFlagsLoadable } from './components/Admin/OptimizelyFlags/OptimizelyFlags.loadable';
import { AppHomeLoadable } from './components/AppHome/AppHome.loadable';
import { ClientContactTaskLoadable } from './components/ClientContact/ClientContactTask.lodable';
import { NetworkErrorLoadable } from './components/Common/NetworkError/NetworkError.loadable';
import CredentialingAttachmentsViewer from './components/Credentialing/CredentialingAttachmentsViewer/CredentialingAttachmentsViewer';
import { FacilityDetails } from './components/Facility/FacilityDetails/FacilityDetails';
import { SearchResultsWrapperLoadable } from './components/GlobalSearch/SearchResultWrapperLoadable';
import { OrderMapResultsLoadable } from './components/Order/OrderDetails/OrderMapResultsWrapper.loadable';
import { ReportPageLoadable } from './components/PerformanceReport/PerdformanceReport.loadable';
import { PlacementDetailsWrapperLoadable } from './components/Placement/PlacementDetails/PlacementDetailsWrapper.loadable';
import { PlacementDocViewerClassic } from './components/Placement/PlacementDetails/PlacementTabPanel/DocumentsTab/PlacementDocViewerClassic';
import { PlacementDocViewerWeb } from './components/Placement/PlacementDetails/PlacementTabPanel/DocumentsTab/PlacementDocViewerWeb';
import { TaskManagementLoadable } from './components/Tasks/TaskManagement/TaskManagement.loadable';
import { SearchType } from './models/GlobalSearch/GlobalSearch';
import { OrderDetailsWrapperLoadable } from '@AMIEWEB/Order/OrderDetails/OrderDetailsWrapper.loadable';
import { authorizedRolesForAddEditUnit } from '@AMIEWEB/Unit/UnitDetails/Containers/unitHelper';
import { SalesReportLoadable } from '@AMIEWEB/ReportPages/R225/SalesReport.loadable';
import { NewOrderLoadable } from '@AMIEWEB/NewOrder/CreateOrder/NewOrderLoadable';
import { MarginToolLoadable } from '@AMIEWEB/MarginTool/MarginToolWrapper.loadable';

export const Routes: React.FC = () => {
  return (
    <Switch>
      <PrivateRoute exact path="/" Component={AppHomeLoadable} />
      <Route exact path="/callback" component={Callback} />
      <Route path="/health">
        <h3>{`The App is Healthy`}</h3>
      </Route>
      <Route exact path="/silent_renew" component={() => <SilentRenew />} />
      <Layout exact path="/signout" Component={() => <SignoutCallback />} />
      <PrivateRoute exact path="/candidate/:travelerId/:brandId" Component={CandidatePage} />
      <PrivateRoute exact path="/placement/:placementId" Component={PlacementDetailsWrapperLoadable} />
      <PrivateRoute
        exact
        path="/placement/document/:documentType/:documentId"
        Component={PlacementDocViewerClassic}
        removeLayout
      />
      <PrivateRoute exact path="/placement/document/:documentId" Component={PlacementDocViewerWeb} removeLayout />
      <PrivateRoute
        exact
        path="/credentialing/:travelerId/:brandId/attachements/:reqId([0-9]+)/:reqTypeId([0-9]+)?*"
        Component={CredentialingAttachmentsViewer}
      />
      <PrivateRoute
        exact
        path={`/search/:category(${Object.values(SearchType).join('|')})/:searchString?`}
        Component={SearchResultsWrapperLoadable}
      />
      <PrivateRoute exact path="/tasks" Component={TaskManagementLoadable} />
      <PrivateRoute exact path="/clientContact" Component={ClientContactTaskLoadable} />
      <PrivateRoute exact path="/reports/communicationAndTasks" Component={ReportPageLoadable} />
      <PrivateRoute exact path="/reports/recruiterSales" Component={SalesReportLoadable} />
      <PrivateRoute exact path="/flags" Component={OptimizelyFlagsLoadable} roles={[userRoles.all]} />
      <PrivateRoute exact path="/settings/user-management" Component={UserManagement} />
      <PrivateRoute exact path="/settings/template-editor/:editor" Component={TemplateEditor} />
      <PrivateRoute exact path="/settings/auto-task-management" Component={AutoTaskManagementPage} />
      <PrivateRoute exact path="/order/:id([0-9]+)" Component={OrderDetailsWrapperLoadable} />
      <PrivateRoute exact path="/order/:id([0-9]+)/edit" Component={NewOrderLoadable} />
      <PrivateRoute exact path="/facility/:id([0-9]+)/order/new" Component={NewOrderLoadable} />
      <PrivateRoute
        exact
        path="/facility/:id([0-9]+)/newUnit"
        Component={NewUnitWrapper}
        roles={authorizedRolesForAddEditUnit}
      />
      <PrivateRoute exact path="/facility/:id([0-9]+)/unit/:unitId([0-9]+)" Component={UnitDetailsLandingPage} />
      <PrivateRoute
        exact
        path="/facility/:id([0-9]+)/unit/:unitId([0-9]+)/edit"
        Component={EditUnitWrapper}
        roles={authorizedRolesForAddEditUnit}
      />
      <PrivateRoute exact path="/facility/:id([0-9]+)?*" Component={FacilityDetails} />
      <Route exact path="/networkerror" component={NetworkErrorLoadable} />
      <Route exact path="/unauthorized" component={UnauthorizedLoadable} />
      <Route exact path="/mapresults/:view" component={OrderMapResultsLoadable} />
      <PrivateRoute exact path="/:placementId/marginTool" Component={MarginToolLoadable} />
      <Layout component={PageNotFound} />
    </Switch>
  );
};
