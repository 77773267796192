import { SkillsetTree } from 'app/components/Common/SkillsetTree/SkillsetTree';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { TableSection } from '../../../customComponents/TableSection';
import { useRequirementFilter } from '../../../Helpers/requirementFilter';
import { AddOnContext } from '../../../PlacementSummaryTab';
import { placementSummaryActions } from '../../../../../../../../../store/redux-store/placement-summary/slice';
import { selectServiceFailures } from '../../../../../../../../../store/redux-store/placement-summary/selectors';
import { RequirementsRow } from '../../TableComponents/TableComponents';
import { usePromiseTracker } from 'react-promise-tracker';

export const SkillsetRow = ({ filterKey, requirementFilter, req }) => {
  const { t } = useTranslation();

  const { order, candidate } = req;

  const { promiseInProgress } = usePromiseTracker({
    area: 'placement-submission-grouped-skillset-requirement',
    delay: 0,
  });
  const { groupedSkillsetRequirement: serviceFailed } = useSelector(selectServiceFailures);
  const { placementId } = useContext(AddOnContext);
  const dispatch = useDispatch();
  const { display } = useRequirementFilter(filterKey, requirementFilter, order?.type);

  return display ? (
    <RequirementsRow
      id={`placement-submission-order-skillset-${filterKey}`}
      title={t('placement.profile.summary.submission.order.skillset.title')}
      requirementCell={<RowKey skillset={order} t={t} />}
      candidateCell={<RowValue skillset={candidate} />}
      loading={promiseInProgress}
      hasError={serviceFailed}
      onTryAgain={() => dispatch(placementSummaryActions.getGroupedSkillSetRequirements({ placementId }))}
    />
  ) : null;
};

const RowKey = ({ skillset, t }) => (
  <TableSection
    items={[
      {
        primaryContent: <SkillsetTree skillset={skillset ? [skillset.data] : []} />,
      },
    ]}
  />
);

const RowValue = ({ skillset }) => <SkillsetTree skillset={skillset ? skillset.data : []} />;
