import { IconButton, Theme } from 'amn-ui-core';
import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { selectUser } from 'oidc/user.selectors';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  INotificationFeedResponse,
  NotificationCategory,
  TaskEntity,
  TaskNotificationType,
} from 'app/models/Tasks/Tasks';
import { useDispatch, useSelector } from 'react-redux';
import { taskDetailsActions } from 'app/components/Tasks/store/Tasks.redux';
import { CueNotesNotificationPopup } from '../../CueNotesNotification/CueNotesNotificationPopup';
import { TaskNotificationPopup } from '../../Common/TaskNotificationPopup';
import { OrderMatchNotificationPopup } from 'app/components/Order/OrderMatch/OrderMatchNotificationPopup';
import { jobPreferncesActions } from 'app/components/Candidate/CandidateProfile/CandidateTabPanel/JobPreferencesTab/store/JobPreferences.redux';
import { getCandidateById } from 'app/services/CandidateServices/CandidateServices';
import { useDecision } from '@optimizely/react-sdk';
import { ff_candidate_ui_preferences, ff_WorkExperience_Enhancement } from 'app/constants/FeatureFlags/Candidate/Keys';
import { isPositiveNumber } from 'app/helpers/componentHelpers';
import { INotificationPopperProps } from '../../utils';
import { pageAttributesActions } from '@AMIEWEB/Placement/PlacementDetails/Edit/store/placementPageAttributes.redux';
import { getEmployeeId, handleCardSelection, isCardSelected, openEmailDeliveryModal } from '../utils';
import { RenderOptionsPopper } from '../RenderOptionsPopper';
import { RenderActionPanel } from '../RenderActionPanel';
import { EmailNotDeliveredPopup } from '../../Common/EmailNotDeliveredPopup';
import EmailDeliveryStatus, {
  IDeliveryStatusProps,
} from '@AMIEWEB/Notification/EmailDeliverStatus/EmailDeliveryStatus';
import { SessionKey, setSessionValue, StorageType } from 'utils/customHooks/sessionStorage/sessionHelpers';

const useStyles = makeStyles<{ showActionPanel?: boolean }>()((theme: Theme, { showActionPanel }) => ({
  notificationtile: {
    margin: '6px',
  },
  notificationtileSplited: {
    flex: `${showActionPanel ? 'none' : '1'}`,
    width: showActionPanel ? '250px' : '350px',
    transition: 'flex 0.3s',
    margin: '0px',
  },

  paperMenu: {
    width: '140px',
    marginRight: theme.spacing(2),
  },

  flexContainer: {
    width: '350px',
    display: 'flex',
    alignItems: 'flex-start',
  },
  ActionPanel: {
    width: '100px',
    display: 'flex',
    alignItems: 'flex-start',
  },

  optionsContainer: {
    position: 'sticky',
    transition: '0.3s ease',
    zIndex: 1,
    width: '60px',
    right: '10px',
    marginTop: '8.5px',
  },

  optionsContainerUnread: {
    position: 'sticky',
    transition: '0.3s ease',
    zIndex: 1,
    width: '60px',
    right: '10px',
    marginTop: '8.5px',
  },

  moreIcon: {
    padding: '0px !important',
    paddingTop: '3px !important',
  },

  menu: {
    width: '140px !important',
    height: '40px !important',
    right: '0px !important',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 2px 8px #0000004D',
    borderRadius: '4px',
    opacity: '10 !important',
    zIndex: 100,
  },

  menuItem: {
    width: '140px !important',
    height: '40px !important',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    opacity: '10 !important',
    zIndex: 100,
  },

  isRead: {
    display: 'flex',
    alignItems: 'flex-start',
    background: '#f5f5fa',
    opacity: '1',
  },
  tileUnreadContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    background: '#f5f5fa',
    opacity: '1',
  },
  tileContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    opacity: '1',
  },
  selected: {
    display: 'flex',
    alignItems: 'flex-start',
    background: theme.palette.system.lightViolet,
  },
  actionPanelContainer: {
    margin: '0px',
    paddingRight: '10px',
    width: `${showActionPanel ? '100px' : '0px'}`,
    height: '119px',
    display: 'flex',
    transition: 'width 0.3s',
  },
}));

interface IAlertFeedProps {
  notifyData: INotificationFeedResponse;
  index: number;
  selectedCards?: string[];
  selectedFeedData?: INotificationPopperProps;
  lastSelectedCard?: string;
  setTaskId?: React.Dispatch<React.SetStateAction<number | undefined>>;
  setRecordDetails?: React.Dispatch<React.SetStateAction<any>>;
  setDetailsModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedFeedData?: React.Dispatch<React.SetStateAction<INotificationPopperProps>>;
  setselectedCards?: React.Dispatch<React.SetStateAction<string[]>>;
  setLastSelectedCard?: React.Dispatch<React.SetStateAction<string>>;
  pageNumber?: React.MutableRefObject<number>;
  activityFeedRefactoringFlag: boolean;
  pushNotification: boolean;
  notificationPopupData?: INotificationFeedResponse[];
}

export const AlertFeed = ({
  notifyData,
  setTaskId = () => {},
  setRecordDetails = () => {},
  index,
  selectedCards = [],
  setDetailsModalOpen = () => {},
  setSelectedFeedData = () => {},
  setselectedCards = () => {},
  activityFeedRefactoringFlag,
  selectedFeedData,
  pageNumber,
  notificationPopupData = [],
  lastSelectedCard,
  setLastSelectedCard = () => {},
  pushNotification,
}: IAlertFeedProps) => {
  const userInfo = useSelector(selectUser);
  const { t } = useTranslation();
  const history = useHistory();
  const [showOptions, setShowOptions] = useState<boolean>();
  const [record, setRecords] = useState<any>();
  const [openDeliveryModal, setEmailDeliveryModal] = useState(false);
  const [indexValue, setIndexValue] = useState<number>();
  const [anchorEl2, setAnchorEl2] = React.useState<HTMLElement | null>(null);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const emailDeliveryData = useRef<IDeliveryStatusProps>();
  const dispatch = useDispatch();
  const [preferenceId, setPreferenceId] = useState<string>(null);
  const [candidateId, setCandidateId] = useState<string>(null);
  const [brandId, setBrandId] = useState<string>(null);
  const [enableJobPreferences] = useDecision(ff_candidate_ui_preferences);
  const [hasWorkExperienceTab] = useDecision(ff_WorkExperience_Enhancement);
  const showActionPanel = showOptions && indexValue === index;
  const { classes } = useStyles({ showActionPanel });
  const closeFeed = () => {
    setSelectedFeedData({
      currentPopper: null,
      openPopper: false,
    });
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setAnchorEl2(null);
    closeFeed();
  };

  const handleNotificationClick = notifyData => {
    if (
      record &&
      (parseInt(notifyData?.useSubType) === TaskNotificationType.CUENOTE ||
        parseInt(notifyData?.useSubType) === TaskNotificationType.CUENOTEREPLY)
    ) {
      closeFeed();
      setSessionValue(
        SessionKey.taskSidePanel,
        {
          stickValue: 1,
          container: TaskEntity.PLACEMENTS,
        },
        StorageType.sessionStorage,
      );
      dispatch(pageAttributesActions.setPopperOpen(false));
      if (activityFeedRefactoringFlag) {
        const path = history.createHref({ pathname: `/placement/${record?.PlacementId}` });
        window.open(path, '_blank');
      } else {
        history.push(`/placement/${record?.PlacementId}`);
      }
      dispatch(
        taskDetailsActions.setTaskStickChosen({
          entity: TaskEntity.PLACEMENTS,
          stick: 1,
        }),
      );

      dispatch(taskDetailsActions.setSidePanelChosen(true));
    }
    if (notifyData?.useType === 'Order Match' || notifyData?.useType === 'order match') {
      notifyData?.associateRecords?.map(record => {
        if (record?.name === 'CandidateId') {
          setCandidateId(record?.value);
        }
        if (record?.name === 'BrandId') {
          setBrandId(record?.value);
        }
        if (record?.name === 'PreferenceId') {
          setPreferenceId(record?.value);
        }
      });
    }
    if (
      parseInt(notifyData?.useSubType) === TaskNotificationType.TASKCREATED ||
      parseInt(notifyData?.useSubType) === TaskNotificationType.TASKEDITED ||
      parseInt(notifyData?.useSubType) === TaskNotificationType.COMPLETED ||
      parseInt(notifyData?.useSubType) === TaskNotificationType.CANCELLED ||
      parseInt(notifyData?.useSubType) === TaskNotificationType.NEWCOMMENT
    ) {
      notifyData?.associateRecords?.map(record => {
        if (record?.name === 'TaskId') {
          setTaskId(parseInt(record?.value));
          setDetailsModalOpen(true);
        }
      });
    }
    if (activityFeedRefactoringFlag && notifyData?.channel === NotificationCategory.Email) {
      openEmailDeliveryModal({
        notifyData,
        setEmailDeliveryModal,
        activityFeedRefactoringFlag,
        setSelectedFeedData,
        emailDeliveryData,
        dispatch,
      });
    }
  };

  const navigateToOrderMatchGrid = (candidateId, brandId, preferenceId) => {
    if (isPositiveNumber(candidateId) && isPositiveNumber(brandId)) {
      getCandidateById(candidateId, brandId).then(response => {
        dispatch(
          jobPreferncesActions.getJobPreferencesAction({
            travelerId: parseInt(candidateId),
            brandId: parseInt(brandId),
          }),
        );
      });
    }

    let redirectTabNumber = 5;
    if (enableJobPreferences?.enabled && hasWorkExperienceTab?.enabled) {
      redirectTabNumber = 7;
    } else if (
      (enableJobPreferences?.enabled && !hasWorkExperienceTab?.enabled) ||
      (!enableJobPreferences?.enabled && hasWorkExperienceTab?.enabled)
    ) {
      redirectTabNumber = 6;
    } else if (!enableJobPreferences?.enabled && !hasWorkExperienceTab?.enabled) {
      redirectTabNumber = 5;
    }

    dispatch(jobPreferncesActions.setPreferenceId(preferenceId));
    setAnchorEl2(null);
    closeFeed();
    if (!window.location.href.includes(`/candidate/${candidateId}/${brandId}?tab=${redirectTabNumber}`)) {
      history.push(`/candidate/${candidateId}/${brandId}?tab=${redirectTabNumber}`);
    }
  };

  const getAssociatedRecords = records => {
    const obj: any = {};
    records?.forEach(el => {
      obj[el.name] = el.value;
    });
    setRecords(obj);
  };

  const getContainerClass = notifyData => {
    let value = '';
    if (pushNotification) {
      value = !activityFeedRefactoringFlag ? classes.flexContainer : classes.tileContainer;
    } else {
      notifyData?.tos?.forEach(el => {
        if (el.contactId === getEmployeeId(userInfo?.userInfo)) {
          if (!el.readByRecipient) {
            value = !activityFeedRefactoringFlag ? classes.isRead : classes.tileContainer;
          } else {
            value = !activityFeedRefactoringFlag ? classes.flexContainer : classes.tileContainer;
          }
        }
      });
    }
    return value;
  };

  const getMenuClass = notifyData => {
    let value = '';
    if (pushNotification) {
      value = classes.optionsContainer;
    } else {
      notifyData?.tos?.forEach(el => {
        if (el.contactId === getEmployeeId(userInfo?.userInfo)) {
          if (!el.readByRecipient) {
            value = classes.optionsContainerUnread;
          } else {
            value = classes.optionsContainer;
          }
        }
      });
    }
    return value;
  };

  const getNotificationTileType = () => {
    if (
      parseInt(notifyData?.contentProps?.useSubType) === TaskNotificationType.CUENOTE ||
      parseInt(notifyData?.contentProps?.useSubType) === TaskNotificationType.CUENOTEREPLY
    ) {
      return (
        <CueNotesNotificationPopup
          NotificationBody={notifyData?.contentProps}
          activityFeed={!pushNotification}
          showActionPanel={activityFeedRefactoringFlag ? showActionPanel : false}
          isUnread={isUnread && activityFeedRefactoringFlag}
          activityFeedRefactoringFlag={activityFeedRefactoringFlag}
        />
      );
    } else if (
      notifyData?.contentProps?.useType === 'Order Match' ||
      notifyData?.contentProps?.useType === 'order match'
    ) {
      return (
        <OrderMatchNotificationPopup
          NotificationBody={notifyData?.contentProps}
          multiLine={true}
          activityFeed={!pushNotification}
          showActionPanel={activityFeedRefactoringFlag ? showActionPanel : false}
          isUnread={isUnread && activityFeedRefactoringFlag}
        />
      );
    } else if (activityFeedRefactoringFlag && notifyData.channel === NotificationCategory.Email) {
      return (
        <EmailNotDeliveredPopup
          hasAttachments={notifyData.contentProps.hasAttachments}
          publishedOn={notifyData.contentProps.publishedOn}
          recipientAddr={notifyData.contentProps.recipientAddr}
          status={notifyData.contentProps.status}
          subject={notifyData.contentProps.message.subject}
          isUnread={isUnread}
          showActionPanel={activityFeedRefactoringFlag ? showActionPanel : false}
          defaultBody
        />
      );
    } else {
      return (
        <TaskNotificationPopup
          NotificationBody={notifyData?.contentProps}
          multiLine={notifyData?.multiLine}
          activityFeed={!pushNotification}
          showActionPanel={activityFeedRefactoringFlag ? showActionPanel : false}
          isUnread={isUnread && activityFeedRefactoringFlag}
        />
      );
    }
  };

  useEffect(() => {
    if (candidateId !== null && brandId !== null && preferenceId !== null) {
      navigateToOrderMatchGrid(candidateId, brandId, preferenceId);
    }
  }, [candidateId, brandId, preferenceId]);

  const getSelectionClass = (cardDetails, selectedCards) => {
    if (isCardSelected(cardDetails?.id, selectedCards)) return classes.selected;
    else return getContainerClass(cardDetails);
  };
  const isUnread = !!notifyData?.contentProps?.isNotificationUnread;

  return (
    <>
      <div
        style={{ display: 'flex', width: '350px' }}
        className={
          activityFeedRefactoringFlag && !pushNotification
            ? getSelectionClass(notifyData?.contentProps, selectedCards)
            : getContainerClass(notifyData?.contentProps)
        }
        onMouseEnter={async () => {
          setShowOptions(true);
          setIndexValue(index);
          getAssociatedRecords(notifyData?.contentProps?.associateRecords);
        }}
        onMouseLeave={async () => {
          setShowOptions(true);
          setIndexValue(undefined);
          setAnchorEl2(null);
        }}
      >
        <div
          className={activityFeedRefactoringFlag ? classes.notificationtileSplited : classes.notificationtile}
          style={{ pointerEvents: pushNotification ? 'none' : 'auto' }}
          onClick={e => {
            if (!pushNotification) {
              if (activityFeedRefactoringFlag) {
                handleCardSelection({
                  id: notifyData?.contentProps?.id,
                  index,
                  setselectedCards,
                  lastSelectedCard,
                  setLastSelectedCard,
                  event: e,
                  notificationPopupData,
                });
              } else {
                handleNotificationClick(notifyData?.contentProps);
              }
            }
          }}
          onMouseDown={e => {
            if (activityFeedRefactoringFlag) {
              e.preventDefault();
            }
          }}
          aria-hidden="true"
        >
          {getNotificationTileType()}
        </div>
        {!activityFeedRefactoringFlag &&
          showOptions &&
          indexValue === index &&
          parseInt(notifyData?.contentProps?.useSubType) !== TaskNotificationType.CUENOTE &&
          parseInt(notifyData?.contentProps?.useSubType) !== TaskNotificationType.CUENOTEREPLY && (
            <div className={getMenuClass(notifyData?.contentProps)}>
              <div className={classes.flexContainer}>
                <div style={{ marginLeft: '15px', paddingTop: '3px' }}>
                  <ArrowForwardIosIcon fontSize="small" />
                </div>
                <div>
                  <IconButton
                    color="primary"
                    className={classes.moreIcon}
                    onClick={e => {
                      setAnchorEl2(e.currentTarget);
                      e.stopPropagation();
                    }}
                    size="small"
                  >
                    <MoreVertIcon fontSize="inherit" />
                  </IconButton>
                  {(record?.PlacementId || record?.FacilityId || record?.OrderId || record?.CandidateId) && (
                    <RenderOptionsPopper
                      args={{
                        anchorEl2,
                        handleClose,
                        notifyData,
                        classes,
                        setAnchorEl2,
                        record,
                        closeFeed,
                        t,
                        history,
                        pageNumber,
                        userInfo: userInfo?.userInfo,
                        activityFeedRefactoringFlag,
                        isUnread,
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          )}

        {activityFeedRefactoringFlag && (
          <RenderActionPanel
            args={{
              showActionPanel,
              classes,
              getMenuClass,
              notifyData,
              setAnchorEl2,
              isUnread,
              handleNotificationClick,
              userInfo: userInfo?.userInfo,
              pageNumber,
              setRecordDetails,
              record,
              pushNotification
            }}
          />
        )}
        {activityFeedRefactoringFlag &&
          showActionPanel &&
          (record?.PlacementId || record?.FaciltyId || record?.OrderId || record?.CandidateId) && (
            <RenderOptionsPopper
              args={{
                anchorEl2,
                handleClose,
                notifyData,
                classes,
                getMenuClass,
                setAnchorEl2,
                record,
                closeFeed,
                t,
                history,
                pageNumber,
                userInfo: userInfo?.userInfo,
                activityFeedRefactoringFlag,
                isUnread,
                pushNotification
              }}
            />
          )}
      </div>
      <EmailDeliveryStatus {...emailDeliveryData.current} open={openDeliveryModal} />
    </>
  );
};
