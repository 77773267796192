export const ff_notificationnavigationaction = 'ff_task_ui_tasknotification_notificationnavigationaction';
export const ff_unreadnotificationcount = 'ff_task_ui_tasknotification_unreadnotificationcount';
export const ff_accessactivityfeed = 'ff_task_ui_tasknotification_accessactivityfeed';
export const ff_createexteraltask = 'ff_task_ui_createexternaltask';
export const ff_externaltasklist = 'ff_task_ui_externaltask_listbyplacementid';
export const ff_displayexternaltaskdetails = 'ff_task_ui_displayexternaltaskdetails';
export const ff_taskAndCueSidePanel = 'ff_task_ui_displaycuenotes';
export const ff_updateexternaltask = 'ff_task_ui_updateexternaltask';
export const ff_clientcontactpage = 'ff_task_ui_clientcontactpage';
export const ff_Notifications_Voice = 'ff_notifications_voice';
export const ff_Notifications_Email = 'ff_notifications_email';
export const ff_Notifications_Sms = 'ff_notifications_sms';
export const ff_task_ui_createclientcontact = 'ff_task_ui_clientcontactpage';
export const ff_order_ui_rate_card = 'ff_order_ui_rate_card';
export const ff_notifications_ui_recruitment_workdesk_bulk_sms = 'ff_notifications_ui_recruitment_workdesk_bulk_sms';
export const ff_notifications_ui_account_management_workdesk_bulk_sms =
  'ff_notifications_ui_account_management_workdesk_bulk_sms';
export const ff_notifications_ui_credentialing_workdesk_bulk_sms =
  'ff_notifications_ui_credentialing_workdesk_bulk_sms';
export const ff_notifications_ui_candidate_search_bulk_sms = 'ff_notifications_ui_candidate_search_bulk_sms';
export const ff_notifications_ui_candidate_search_bulk_email = 'ff_notifications_ui_candidate_search_bulk_email';
export const ff_notifications_ui_credentialing_workdesk_bulk_email =
  'ff_notifications_ui_credentialing_workdesk_bulk_email';
export const ff_notifications_ui_recruitment_workdesk_bulk_email =
  'ff_notifications_ui_recruitment_workdesk_bulk_email';
export const ff_notifications_ui_account_management_workdesk_bulk_email =
  'ff_notifications_ui_account_management_workdesk_bulk_email';
export const ff_notifications_onetrust_consents = 'ff_notifications_onetrust_consents';
export const ff_notifications_notificationhub_from_aks = 'ff_notifications_notificationhub_from_aks';
export const ff_notifications_blueshift_email_communication_limits =
  'ff_notifications_blueshift_email_communication_limits';
export const ff_notifications_blueshift_sms_communication_limits =
  'ff_notifications_blueshift_sms_communication_limits';
export const ff_candidate_communication_grid = 'ff_candidate_communication_grid';
export const ff_notifications_ui_activity_feed_split = 'ff_notifications_ui_activity_feed_split';
export const ff_notifications_enable_shareddesk_communication = 'ff_notifications_enable_shareddesk_communication';
