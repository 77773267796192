/* eslint-disable @typescript-eslint/naming-convention */
import { MessageDisabledTooltip } from '@AMIEWEB/Common';
import ResponsiveChipCell from '@AMIEWEB/Common/XGrid/cells/ResponsiveGridChips/ResponsiveChipCell';
import { ChannelType } from '@AMIEWEB/Notification/Constants';
import CommunicationStack from '@AMIEWEB/Notification/MultiChannel/CommunicationTooltip/CommunicationStack';
import EmailStack from '@AMIEWEB/Notification/MultiChannel/CommunicationTooltip/EmailStack';
import { GridColumns } from '@mui/x-data-grid-pro';
import ErrorIcon from 'app/assets/images/glance-tile-error-icon.svg';
import { CustomTooltip, tooltipType } from 'app/components/Common/Tooltips';
import { XGridHeaderWithIconCell } from 'app/components/Common/XGrid/cells/HeaderRenderers/XGridHeaderWithIconCell';
import { XGridContactHoverView } from 'app/components/Common/XGrid/cells/HoverViewRenderers/XGridContactHoverView';
import { XGridBlueCell } from 'app/components/Common/XGrid/cells/XGridBlueCell';
import { XGridDefaultColumn } from 'app/components/Common/XGrid/cells/XGridDefaults';
import { XGridLinkCell } from 'app/components/Common/XGrid/cells/XGridLinkCell';
import { XGridOverflowCell } from 'app/components/Common/XGrid/cells/XGridOverflowCell';
import { XGridSideBarViewCell } from 'app/components/Common/XGrid/cells/XGridSideBarViewCell';
import { ICustomHeader } from 'app/models/Grid';
import React from 'react';
import smsWithdrawYellow from 'app/assets/images/SMS-withdraw-yellow.svg';
import emailWithdrawYellow from 'app/assets/images/Email-withdraw-yellow.svg';
import phoneWithdrawYellow from 'app/assets/images/phone-withdraw-yellow.svg';
import MessageDisabled from 'app/assets/images/CandidateHeader/Text-Disabled.svg';
import { selectUser } from 'oidc/user.selectors';
import { useSelector } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import EmailOutlined from '@mui/icons-material/EmailOutlined';
import CallOutlined from '@mui/icons-material/CallOutlined';
import ChatOutlined from '@mui/icons-material/ChatOutlined';
import { initialCandidateAggregateSearch } from 'app/ComponentLibrary/Filter/CandidateSearch';
import { initialCandidateSkillSetFilter } from 'app/ComponentLibrary/Filter/CandidateSkillset';
interface IGridColumns extends Omit<ICustomHeader, 'title'> {
  title:
    | 'candidateId'
    | 'candidate'
    | 'firstName'
    | 'lastName'
    | 'goesBy'
    | 'otherName'
    | 'phones'
    | 'emails'
    | 'placementBeenOnAssignment'
    | 'supervisor'
    | 'supervisorStatus'
    | 'supervisorReason'
    | 'skillsets'
    | 'unverifiedSkillsets'
    | 'brand'
    | 'candidateStatus'
    | 'recruiter'
    | 'availableToStartDate'
    | 'lastContactedOnDate'
    | 'callBackDate'
    | 'applicationDate'
    | 'city'
    | 'candidateTags'
    | 'state'
    | 'jobMatchCount'
    | 'jobInterestCount';
}

const CandidateDefaultCell = {
  ...XGridDefaultColumn,
  flex: 0,
};

const CellErrorIcon = <img src={ErrorIcon} alt="Error Icon" style={{ width: 22, height: 22, marginTop: -4 }} />;

export const candidateViewInfo = (
  t,
  jobInterestHealth,
  jobMatchHealth,
  jobInterestEnabled,
  jobMatchEnabled,
  newSearchIndexEnabled,
  candidateCommunicationConsentFlag,
): GridColumns =>
  [
    {
      ...CandidateDefaultCell,
      field: 'candidateId',
      headerName: t('search.globalSearch.candidate.gridTags.cid'),
      width: 100,
      renderCell: XGridOverflowCell(XGridSideBarViewCell('/candidate/{0}/{1}', ['candidateId', 'brandId'], true))(
        XGridSideBarViewCell('/candidate/{0}/{1}', ['candidateId', 'brandId']),
      ),
    },
    {
      ...CandidateDefaultCell,
      field: 'candidate',
      headerName: t('search.globalSearch.candidate.gridTags.candidate'),
      width: 150,
      renderCell: XGridOverflowCell(XGridLinkCell('/candidate/{0}/{1}', ['candidateId', 'brandId'], true))(
        XGridLinkCell('/candidate/{0}/{1}', ['candidateId', 'brandId']),
      ),
    },
    {
      ...CandidateDefaultCell,
      field: 'firstName',
      headerName: t('search.globalSearch.candidate.gridTags.firstName'),
      width: 150,
      renderCell: XGridOverflowCell(XGridLinkCell('/candidate/{0}/{1}', ['candidateId', 'brandId'], true))(
        XGridLinkCell('/candidate/{0}/{1}', ['candidateId', 'brandId']),
      ),
    },
    {
      ...CandidateDefaultCell,
      field: 'lastName',
      headerName: t('search.globalSearch.candidate.gridTags.lastName'),
      width: 150,
      renderCell: XGridOverflowCell(XGridLinkCell('/candidate/{0}/{1}', ['candidateId', 'brandId'], true))(
        XGridLinkCell('/candidate/{0}/{1}', ['candidateId', 'brandId']),
      ),
    },
    {
      ...CandidateDefaultCell,
      field: 'goesBy',
      headerName: t('search.globalSearch.candidate.gridTags.goesBy'),
      width: 160,
    },
    {
      ...CandidateDefaultCell,
      field: 'otherName',
      headerName: t('search.globalSearch.candidate.gridTags.otherNames'),
      width: 160,
    },
    {
      ...CandidateDefaultCell,
      field: 'phones',
      headerName: t('search.globalSearch.candidate.gridTags.phone'),
      sortable: false,
      width: 100,
      valueFormatter: param =>
        param.value.length === 0
          ? '--'
          : param.value
              .filter(x => x.phoneNumber !== null && x.phoneNumber !== undefined && x.phoneNumber.length > 0)
              .map(obj => `${obj.phoneTypeDescription}: ${obj.phoneNumber}`)
              .join(', '),
      renderCell: params => (
        <div style={{ display: 'flex', gap: '8px' }}>
          <CandidateGridCustomTooltip
            candidate={params.row}
            value={params.value}
            type={ChannelType.voice}
            communicationConsentFlag={candidateCommunicationConsentFlag}
          />
          <CandidateGridCustomTooltip
            candidate={params.row}
            value={params.value}
            type={ChannelType.sms}
            communicationConsentFlag={candidateCommunicationConsentFlag}
          />
        </div>
      ),
    },
    {
      ...CandidateDefaultCell,
      field: 'emails',
      headerName: t('search.globalSearch.candidate.gridTags.email'),
      sortable: false,
      width: 100,
      valueFormatter: param =>
        param.value.primaryEmail || param.value.secondaryEmail
          ? Object.values(param.value)
              .filter(value => value)
              .join(', ')
          : '--',
      renderCell: params => (
        <CandidateGridCustomTooltip
          value={params.value}
          candidate={params.row}
          type={ChannelType.email}
          communicationConsentFlag={candidateCommunicationConsentFlag}
        />
      ),
    },
    ...(newSearchIndexEnabled
      ? [
          {
            ...CandidateDefaultCell,
            field: 'placementBeenOnAssignment',
            headerName: t('search.globalSearch.candidate.gridTags.placementBeenOnAssignment'),
            width: 160,
          },
          {
            ...CandidateDefaultCell,
            field: 'supervisor',
            headerName: t('search.globalSearch.candidate.gridTags.supervisor'),
            width: 160,
          },
        ]
      : []),

    {
      ...CandidateDefaultCell,
      field: 'skillsets',
      headerName: t('search.globalSearch.candidate.gridTags.skillSet'),
      width: 160,
    },
    {
      ...CandidateDefaultCell,
      field: 'unverifiedSkillsets',
      headerName: t('search.globalSearch.candidate.gridTags.unVerifiedSkillset'),
      width: 160,
    },
    {
      ...CandidateDefaultCell,
      field: 'brand',
      headerName: t('search.globalSearch.candidate.gridTags.brand'),
      width: 150,
    },
    {
      ...CandidateDefaultCell,
      field: 'candidateStatus',
      headerName: t('search.globalSearch.candidate.gridTags.candidateStatus'),
      width: 150,
    },
    {
      ...CandidateDefaultCell,
      field: 'recruiter',
      headerName: t('search.globalSearch.candidate.gridTags.recruiter'),
      width: 150,
      renderCell: XGridOverflowCell(XGridContactHoverView('recruiterAttributes'), true)(XGridBlueCell),
    },
    {
      ...CandidateDefaultCell,
      field: 'availableToStartDate',
      headerName: t('search.globalSearch.candidate.gridTags.availableToStartDate'),
      width: 150,
    },
    {
      ...CandidateDefaultCell,
      field: 'lastContactedOnDate',
      headerName: t('search.globalSearch.candidate.gridTags.lastContactedOnDate'),
      width: 150,
    },
    {
      ...CandidateDefaultCell,
      field: 'candidateTags',
      headerName: t('search.globalSearch.candidate.gridTags.tags'),
      width: 300,
      renderCell: ResponsiveChipCell,
      valueFormatter: params => params.value?.map(x => x.title).join(', '),
    },
    {
      ...CandidateDefaultCell,
      field: 'callBackDate',
      headerName: t('search.globalSearch.candidate.gridTags.callBackDate'),
      width: 150,
    },
    {
      ...CandidateDefaultCell,
      field: 'applicationDate',
      headerName: t('search.globalSearch.candidate.gridTags.applicationDate'),
      width: 170,
    },
    {
      ...CandidateDefaultCell,
      field: 'city',
      headerName: t('search.globalSearch.candidate.gridTags.city'),
      width: 150,
    },
    {
      ...CandidateDefaultCell,
      field: 'state',
      headerName: t('search.globalSearch.candidate.gridTags.state'),
      width: 150,
    },
    {
      ...CandidateDefaultCell,
      field: 'jobMatchCount',
      headerName: t('search.globalSearch.candidate.gridTags.jobMatchCount'),
      width: jobMatchHealth ? 130 : 150,
      sortable: false,
      renderHeader: XGridHeaderWithIconCell(
        !jobMatchHealth ? CellErrorIcon : undefined,
        jobMatchHealth,
        !jobMatchHealth
          ? t('search.candidateSearchColumns.serviceFailureMessage')
          : t('search.globalSearch.candidate.gridTags.jobMatchCount'),
      ),
    },
    {
      ...CandidateDefaultCell,
      field: 'jobInterestCount',
      headerName: t('search.globalSearch.candidate.gridTags.jobInterestCount'),
      sortable: false,
      width: jobInterestHealth ? 135 : 165,
      renderHeader: XGridHeaderWithIconCell(
        !jobInterestHealth ? CellErrorIcon : undefined,
        jobInterestHealth,
        !jobInterestHealth
          ? t('search.candidateSearchColumns.serviceFailureMessage')
          : t('search.globalSearch.candidate.gridTags.jobInterestCount'),
      ),
    },
    {
      ...CandidateDefaultCell,
      field: 'lastPlacementEndDate',
      headerName: t('search.globalSearch.candidate.gridTags.lastPlacementEndDate'),
      width: 185,
    },
    {
      ...CandidateDefaultCell,
      field: 'zipCode',
      headerName: t('search.globalSearch.candidate.gridTags.zip'),
      width: 150,
    },
    {
      ...CandidateDefaultCell,
      field: 'distance',
      headerName: t('search.globalSearch.candidate.gridTags.radius'),
      width: 150,
    },
    {
      ...CandidateDefaultCell,
      field: 'virtualInterview',
      headerName: t('search.globalSearch.candidate.gridTags.virtualInterview'),
      width: 150,
    },
    {
      ...CandidateDefaultCell,
      field: 'covidVaxStatus',
      headerName: t('search.globalSearch.candidate.gridTags.covidVaxStatus'),
      width: 190,
    },
  ].filter(
    item =>
      !(
        (!jobInterestEnabled && item.field === 'jobInterestCount') ||
        (!jobMatchEnabled && item.field === 'jobMatchCount')
      ),
  );

export const candidateColumnVisibilityModel = {
  candidateId: true,
  candidate: true,
  firstName: false,
  lastName: false,
  goesBy: false,
  otherName: false,
  phones: false,
  emails: false,
  placementBeenOnAssignment: true,
  supervisor: true,
  supervisorStatus: true,
  supervisorReason: true,
  skillsets: true,
  unverifiedSkillsets: false,
  brand: true,
  candidateStatus: true,
  recruiter: true,
  availableToStartDate: true,
  lastContactedOnDate: true,
  callBackDate: true,
  applicationDate: true,
  city: true,
  state: true,
  jobMatchCount: true,
  jobInterestCount: true,
  candidateTags: true,
  lastPlacementEndDate: false,
  zipCode: false,
  radius: false,
  virtualInterview: false,
  covidVaxStatus: false,
  region: false,
};

export const candidateViewInfo_Old = t =>
  [
    {
      value: t('search.candidateSearchColumns.candidateId'),
      title: 'candidateId',
      clickable: true,
      sortable: true,
      navigationIds: ['candidateId', 'brandId'],
      navigationExp: '/candidate/{0}/{1}',
      isNumber: true,
    },
    {
      value: t('search.candidateSearchColumns.candidate'),
      title: 'candidate',
      clickable: true,
      sortable: true,
      navigationIds: ['candidateId', 'brandId'],
      navigationExp: '/candidate/{0}/{1}',
    },
    { value: t('search.candidateSearchColumns.skillSet'), title: 'skillsets', sortable: true, multiLine: true },
    { value: t('search.candidateSearchColumns.brand'), title: 'brand', sortable: true },
    { value: t('search.candidateSearchColumns.candidateStatus'), title: 'candidateStatus', sortable: true },
    {
      value: t('search.candidateSearchColumns.recruiter'),
      title: 'recruiter',
      sortable: true,
      tooltip: tooltipType.IterableKeyTooltip,
      tooltipAttributes: 'recruiterAttributes',
    },
    {
      value: t('search.candidateSearchColumns.availableToStart'),
      title: 'availableToStartDate',
      sortable: true,
      sortByField: 'availableToStartDateNoFormat',
    },
    {
      value: t('search.candidateSearchColumns.lastContacted'),
      title: 'lastContactedOnDate',
      sortable: true,
      sortByField: 'lastContactedDateNoFormat',
    },
    {
      value: t('search.candidateSearchColumns.callBackDate'),
      title: 'callBackDate',
      sortable: true,
      sortByField: 'callBackDateDateNoFormat',
    },
    {
      value: t('search.candidateSearchColumns.applicationDate'),
      title: 'applicationDate',
      sortable: true,
      sortByField: 'applicationDateDateNoFormat',
    },
    { value: t('search.candidateSearchColumns.city'), title: 'city', sortable: true },
    { value: t('search.candidateSearchColumns.state'), title: 'state', sortable: true },
    {
      value: t('search.candidateSearchColumns.jobMatch'),
      title: 'jobMatchCount',
      //headerIcon: { redAlert: !jobMatchHealth },
      columnHeaderHoverText: t('search.candidateSearchColumns.serviceFailureMessage'),
    },
    {
      value: t('search.candidateSearchColumns.jobInterest'),
      title: 'jobInterestCount',
      //headerIcon: { redAlert: !jobInterestHealth },
      columnHeaderHoverText: t('search.candidateSearchColumns.serviceFailureMessage'),
    },
  ] as IGridColumns[];

/* enum values are named w.r.t api service call*/
export enum candidateSearchFilterName {
  recruiter = 'recruiterIds',
  brand = 'brandIds',
  candidateStatus = 'candidateStatusIds',
  discipline = 'disciplineIds',
  specialty = 'specialtyIds',
  candidate = 'candidateIds',
  candidateAgggreggate = 'candidateFilter',
  candidateSkillSet = 'skillsetFilter',
  cid = 'cIds',
  availableToStart = 'availableStartDateRange',
  lastApplicationDate = 'lastApplicationDateRange',
  lastPlacementEndDate = 'LastPlacementEndDateRange',
  nonContacted = 'nonContactedDate',
  callBackDate = 'callBackDateRange',
  state = 'states',
  city = 'city',
  zip = 'zipCode',
  radius = 'radius',
  virtualInterview = 'virtualInterview',
  covidVaxStatus = 'covidVaxStatus',
  licenseType = 'licenseType',
  licenseState = 'state',
  licenseCompactStatus = 'compactStatus',
  licenseExp = 'expirationDate',
  license = 'license',
  certification = 'certification',
  certificationType = 'certificationType',
  certificationIssuedBy = 'issuedBy',
  certificationExpDate = 'expirationDate',
  workHistory = 'workHistory',
  workYearOfExp = 'yearsOfExperience',
  workHistoryState = 'state',
  workHistoryFacility = 'facilityId',
  workHistoryDiscipline = 'disciplineId',
  workHistoryUnitSpecialty = 'unitSpecialtyId',
  workHistoryTravelExp = 'travelExperience',
  activePlacements = 'activePlacements',
  candidateTags = 'candidateTags',
  placementBeenOnAssignment = 'placementBeenOnAssignment',
  supervisor = 'supervisor',
  supervisorSkillsetStatus = 'supervisorSkillsetStatus',
  supervisorSkillsetRejectionReason = 'supervisorSkillsetRejectionReason',
  recruitmentLeaderIds = 'LeaderRecruiterIds',
  region = 'regionIds',
}

export enum covidVaxStatus {
  V = 'Vaccinated',
  U = 'Unvaccinated',
  D = 'Declined Vaccination',
}

export const defaultFilterValues = {
  [candidateSearchFilterName.recruiter]: null,
  [candidateSearchFilterName.brand]: null,
  [candidateSearchFilterName.candidateStatus]: null,
  [candidateSearchFilterName.candidateAgggreggate]: initialCandidateAggregateSearch,
  [candidateSearchFilterName.candidateSkillSet]: initialCandidateSkillSetFilter,
  [candidateSearchFilterName.discipline]: [],
  [candidateSearchFilterName.specialty]: [],
  [candidateSearchFilterName.availableToStart]: null,
  [candidateSearchFilterName.nonContacted]: null,
  [candidateSearchFilterName.callBackDate]: null,
  [candidateSearchFilterName.state]: null,
  [candidateSearchFilterName.virtualInterview]: null,
  [candidateSearchFilterName.activePlacements]: null,
  [candidateSearchFilterName.candidateTags]: null,
  [candidateSearchFilterName.placementBeenOnAssignment]: null,
  [candidateSearchFilterName.supervisor]: null,
  [candidateSearchFilterName.supervisorSkillsetStatus]: null,
  [candidateSearchFilterName.supervisorSkillsetRejectionReason]: null,
};

export const CandidateGridCustomTooltip = props => {
  const [openEmailTooltip, setOpenEmailTooltip] = React.useState<boolean>(false);
  const [hoverPhone, setHoverPhone] = React.useState<boolean>(false);
  const [hoverSms, setHoverSms] = React.useState<boolean>(false);
  const candidateEmails = [props.value.primaryEmail, props.value.secondaryEmail].filter(Boolean);
  const user = useSelector(selectUser);
  let candidateDetail = null;
  let hasCallConsents = true;
  let hasEmailConsents = true;
  let hasTextConsents = true;
  const communicationConsentFlag = props?.communicationConsentFlag;

  if (props?.candidate) {
    const candidate = props?.candidate;
    const recruiterName = candidate?.recruiter.split(' ');
    const updateCandidate = {
      verifiedSkills: [],
      name: { first: candidate?.firstName, last: candidate?.lastName },
      primaryEmail: candidate?.email,
      phoneNumbers: candidate?.phones,
      primaryPhoneNumber: candidate?.phones[0]?.phoneNumber,
      recruiter: {
        recruiterId: candidate?.recruiterId,
        first: recruiterName[0],
        last: recruiterName[1],
      },
      travelerId: candidate?.candidateId,
    };
    candidateDetail = { ...candidate, ...updateCandidate };
    hasCallConsents = candidate?.activePlacements ? true : candidate?.consents?.hasCallConsents;
    hasEmailConsents = candidate?.activePlacements ? true : candidate?.consents?.hasEmailConsents;
    hasTextConsents = candidate?.activePlacements ? true : candidate?.consents?.hasTextConcents;
  }

  const EmailTooltipEnabled = (
    <CustomTooltip
      tooltipPlacementBottom
      disableMaxWidth={true}
      open={openEmailTooltip}
      tooltipContent={
        <EmailStack
          emails={candidateEmails}
          showWarningMessage={!hasEmailConsents}
          onClose={() => setOpenEmailTooltip(false)}
          candidateDetails={candidateDetail}
          communicationConsentFlag={communicationConsentFlag}
        />
      }
    >
      {!hasEmailConsents ? (
        <img
          src={emailWithdrawYellow}
          alt="MailBox"
          style={{
            cursor: 'pointer',
            marginLeft: '-1px',
            height: '25px',
            width: '30px',
          }}
        />
      ) : (
        <IconButton size="medium" color="secondary">
          <EmailOutlined style={{ fontSize: '22px' }} />
        </IconButton>
      )}
    </CustomTooltip>
  );

  const EmailTooltipDisabled = (
    <CustomTooltip
      tooltipPlacementBottom
      disableMaxWidth={true}
      tooltipContent={<span style={{ fontSize: '14px', color: '#888888' }}>{'No email address on file'}</span>}
    >
      <IconButton size="medium" color="secondary" disabled>
        <EmailOutlined style={{ fontSize: '22px' }} />
      </IconButton>
    </CustomTooltip>
  );

  const PhoneTooltipEnabled = ({ data }) => (
    <CustomTooltip
      tooltipPlacementBottom
      disableMaxWidth={true}
      tooltipContent={
        <div>
          <table style={{ borderCollapse: 'separate', borderSpacing: '8px' }}>
            {data && (
              <CommunicationStack
                phoneNumbers={data}
                channelType={ChannelType.voice}
                backDrop={flag => flag}
                showWarningMessage={!hasCallConsents}
                candidateDetails={candidateDetail}
                communicationConsentFlag={communicationConsentFlag}
              />
            )}
          </table>
        </div>
      }
    >
      {!hasCallConsents ? (
        <img
          src={phoneWithdrawYellow}
          alt="Phone"
          style={{
            cursor: 'pointer',
            marginLeft: '-1px',
            height: '25px',
            width: '30px',
          }}
        />
      ) : (
        <IconButton size="medium" color="secondary">
          <CallOutlined style={{ fontSize: '22px' }} />
        </IconButton>
      )}
    </CustomTooltip>
  );

  const PhoneTooltipDisabled = (
    <CustomTooltip
      tooltipPlacementBottom
      disableMaxWidth={true}
      tooltipContent={<span style={{ fontSize: '14px', color: '#888888' }}>{'No phone number on file'}</span>}
    >
      <IconButton size="medium" color="secondary" disabled>
        <CallOutlined style={{ fontSize: '22px' }} />
      </IconButton>
    </CustomTooltip>
  );

  const SmsTooltipEnabled = ({ data }) => (
    <CustomTooltip
      tooltipPlacementBottom
      disableMaxWidth={true}
      tooltipContent={
        <div>
          <table style={{ borderCollapse: 'separate', borderSpacing: '8px' }}>
            {data && (
              <CommunicationStack
                phoneNumbers={data}
                channelType={ChannelType.sms}
                backDrop={flag => flag}
                showWarningMessage={!hasTextConsents}
                candidateDetails={candidateDetail}
                communicationConsentFlag={communicationConsentFlag}
              />
            )}
          </table>
        </div>
      }
    >
      {!hasTextConsents ? (
        <img
          src={smsWithdrawYellow}
          alt="Message"
          style={{
            cursor: 'pointer',
            marginLeft: '-1px',
            height: '25px',
            width: '30px',
          }}
        />
      ) : (
        <IconButton size="medium" color="secondary">
          <ChatOutlined style={{ fontSize: '22px' }} />
        </IconButton>
      )}
    </CustomTooltip>
  );

  const SmsTooltipDisabled = (
    <CustomTooltip
      tooltipPlacementBottom
      disableMaxWidth={true}
      tooltipContent={<span style={{ fontSize: '14px', color: '#888888' }}>{'No text messaging number on file'}</span>}
    >
      <IconButton size="medium" color="secondary" disabled>
        <ChatOutlined style={{ fontSize: '22px' }} />
      </IconButton>
    </CustomTooltip>
  );

  if (props.type === ChannelType.email) {
    return props.value.primaryEmail || props.value.secondaryEmail ? (
      <div onMouseEnter={() => setOpenEmailTooltip(true)} onMouseLeave={() => setOpenEmailTooltip(false)}>
        {EmailTooltipEnabled}
      </div>
    ) : (
      EmailTooltipDisabled
    );
  }
  if (props.type === ChannelType.voice) {
    return props.value && props.value.length > 0 ? (
      <div onMouseEnter={() => setHoverPhone(true)} onMouseLeave={() => setHoverPhone(false)}>
        <PhoneTooltipEnabled data={props.value} />
      </div>
    ) : (
      PhoneTooltipDisabled
    );
  }

  if (props.type === ChannelType.sms) {
    if (!user.userInfo?.phoneNumber) {
      return (
        <MessageDisabledTooltip>
          <img src={MessageDisabled} alt="Message disabeld" />
        </MessageDisabledTooltip>
      );
    } else if (props.value && props.value.length > 0) {
      return (
        <div onMouseEnter={() => setHoverSms(true)} onMouseLeave={() => setHoverSms(false)}>
          <SmsTooltipEnabled data={props.value} />
        </div>
      );
    } else {
      return SmsTooltipDisabled;
    }
  }

  return <></>;
};
