import React, { useState } from 'react';
import { GridCellParams } from '@mui/x-data-grid-pro';
import Grid from '@mui/material/Grid/Grid';
import { Checkbox, FormControlLabel, FormGroup } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { useDispatch, useSelector } from 'react-redux';
import { selectOrderLicenses } from '@AMIEWEB/Order/Store/Order.selectors';
import { CompactStatus, IOrderLicense, orderDataActions } from '@AMIEWEB/Order/Store/Order.redux';

interface GridCellProps {
  [key: string]: any;
}

const useStyles = makeStyles()(theme => ({
  item: {
    borderBottom: '1px solid #EFEFEF',
    borderColor: '#f4f5f738',
  },
  icon: {
    borderRadius: 3,
    padding: 2,
    border: '1px solid #cccccc',
    marginTop: 3,
    height: 36,
  },
  tooltip: {
    backgroundColor: theme.palette.system.white,
    color: '#333333',
    opacity: 1,
    textAlign: 'left',
    letterSpacing: '0px',
    boxShadow: '0px 3px 6px #00000029',
    border: '1px solid #8C8C8C',
  },
  arrow: {
    color: theme.palette.system.white,
    opacity: 1,
    '&::before': {
      boxShadow: '0px 3px 6px #00000029',
      border: '1px solid #8C8C8C',
    },
  },
  placementBottom: {
    marginBottom: 0,
  },
  iconSize: {
    fontSize: 'larger',
    padding: 4,
  },
  defaultBackgroundColor: {
    backgroundColor: 'transparent',
  },
  selectBackgroundColor: {
    backgroundColor: theme.palette.system.lightBlue,
    border: `1px solid ${theme.palette.system.white}`,
    ' svg': {
      fill: `${theme.palette.system.white} !important`,
    },
  },
}));

const LicenseRequirementRenderer = React.memo(function LicenseRequirementRenderer(cellParams: GridCellProps) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const orderLicenses = useSelector(selectOrderLicenses);
  const [isRequiredSelection, setRequiredSelection] = useState<boolean>(true);
  const [renderOptions, setRenderOptions] = useState<boolean>(false);
  const [documentAttachmentRequired, setDocumentAttachmentRequired] = useState<boolean>(false);

  const handleRequiredChange = value => {
    setRequiredSelection(value);
  };

  const handleAttachDocumentChange = value => {
    setDocumentAttachmentRequired(value);
  };

  React.useEffect(() => {
    const orderLicensesDetails: IOrderLicense[] = [...orderLicenses];
    if (orderLicenses && orderLicenses?.length > 0) {
      const licenseDetailsIndex = orderLicensesDetails?.findIndex(data => data?.licenseId === cellParams.id);
      const selectedLicenseOptionDetails = orderLicenses?.find(data => data?.licenseId === cellParams.id);
      if (licenseDetailsIndex !== -1) {
        const selectedLicenseDetail: IOrderLicense = {
          ...selectedLicenseOptionDetails,
          licenseStateDetails: [
            {
              isRequired: isRequiredSelection,
              documentAttachmentRequired: documentAttachmentRequired,
            },
          ],
        };
        orderLicensesDetails[licenseDetailsIndex] = { ...selectedLicenseDetail };
      } else {
        if (selectedLicenseOptionDetails) {
          const selectedLicenseDetail: IOrderLicense = {
            ...selectedLicenseOptionDetails,
            licenseStateDetails: [
              {
                isRequired: isRequiredSelection,
                documentAttachmentRequired: documentAttachmentRequired,
              },
            ],
          };
          orderLicensesDetails.push(selectedLicenseDetail);
        }
      }
    }
    dispatch(orderDataActions.setOrderLicenseDetails(orderLicensesDetails));
  }, [isRequiredSelection, documentAttachmentRequired]);

  React.useEffect(() => {
    const existingLicense = orderLicenses?.find(data => data?.licenseId === cellParams.id);
    if (cellParams?.id && existingLicense) {
      if (existingLicense?.compactStatus === CompactStatus.multiple) {
        setRenderOptions(true);
        setDocumentAttachmentRequired(existingLicense?.licenseStateDetails[0]?.documentAttachmentRequired);
        setRequiredSelection(existingLicense?.licenseStateDetails[0]?.isRequired);
      } else {
        setRenderOptions(false);
        setDocumentAttachmentRequired(false);
        setRequiredSelection(true);
      }
    } else {
      setRenderOptions(false);
      setDocumentAttachmentRequired(false);
      setRequiredSelection(true);
    }
  }, [orderLicenses]);

  return (
    <React.Fragment>
      {renderOptions && (
        <Grid container style={{ pointerEvents: cellParams?.isDisabled ? 'none' : 'auto' }}>
          <Grid item>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={isRequiredSelection}
                    onChange={e => handleRequiredChange(e.target.checked)}
                  />
                }
                label="Required"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={documentAttachmentRequired}
                    onChange={e => handleAttachDocumentChange(e.target.checked)}
                  />
                }
                label="Attach Document"
              />
            </FormGroup>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
});

export const LicenseRequirementRenderCell = (props: { param: GridCellParams; isDisabled: boolean }) => {
  return <LicenseRequirementRenderer {...props?.param} isDisabled={props?.isDisabled} />;
};
