import React, { useEffect, useState } from 'react';
import { Grid, ThemeProvider, StyledEngineProvider,useTheme } from 'amn-ui-core';
import { UnitDetailsBody, UnitCardsTitle, ValidationErrorMessage } from '../UnitDesign';
import { SingleLineInput } from '../Common/SingleLineInput';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { ReqOrderFieldNames } from '@AMIEWEB/Order/Store/types';

interface Props {
  onUnitPropChange: (property: string, value: any) => void;
  setUnitNameError: boolean;
  disableEdit?: boolean;
  showMandetryStyle?: boolean;
  incompleteReqFields?: ReqOrderFieldNames[];
}

export const NewUnitDetails = (props: Props) => {
  const { t } = useTranslation();
  const { setUnitNameError, disableEdit, showMandetryStyle, incompleteReqFields } = props;
  const [isValid, setIsValid] = useState<boolean>(true);
  const { control } = useFormContext();
  const theme = useTheme();

  useEffect(() => {
    if (incompleteReqFields?.includes(ReqOrderFieldNames.UNIT_NAME)) setIsValid(false);
  }, [incompleteReqFields]);

  return (
    <div>
      {/* TODO: Remove this theme wrapper since it uses the global theme */}
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <UnitDetailsBody variant="outlined">
            <Grid container direction="row" spacing={1}>
              <Grid item xs={12}>
                <UnitCardsTitle>{t('order.createUnit.unitDetails.title')}</UnitCardsTitle>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name={'name'}
                      render={({ ref, onChange, ...rest }) => (
                        <SingleLineInput
                          inputName={'name'}
                          inputLabel={t('order.createUnit.unitDetails.unitName')}
                          inputDefaultValue={''}
                          inputValue={rest.value}
                          req={showMandetryStyle !== true ? rest.value === null || rest.value === '' : false}
                          handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            onChange(event.target.value);
                          }}
                          inputError={!isValid && (rest.value === null || rest.value === '')}
                          isdisabled={disableEdit}
                          id={'createUnitName'}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Controller
                      control={control}
                      name={'costCenter'}
                      render={({ ref, onChange, ...rest }) => (
                        <SingleLineInput
                          inputName={'costCenter'}
                          inputLabel={t('order.createUnit.unitDetails.costCenter')}
                          inputDefaultValue={rest.value}
                          inputValue={rest.value}
                          handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            onChange(event.target.value);
                          }}
                          isdisabled={disableEdit}
                          id={'createUnitCostCenter'}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Controller
                      control={control}
                      name={'floor'}
                      render={({ ref, onChange, ...rest }) => (
                        <SingleLineInput
                          inputName={'floor'}
                          inputLabel={t('order.createUnit.unitDetails.floor')}
                          inputValue={rest.value}
                          inputDefaultValue={rest.value}
                          handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            onChange(event.target.value);
                          }}
                          isdisabled={disableEdit}
                          id={'createUnitFloor'}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {setUnitNameError && (
                <Grid item xs={12}>
                  <Grid item xs={6}>
                    <ValidationErrorMessage>{t('order.createUnit.unitDetails.unitNameError')}</ValidationErrorMessage>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </UnitDetailsBody>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
};
