import { XGrid } from '@AMIEWEB/Common/XGrid/XGrid';
import { Grid } from 'amn-ui-core';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { orderLicenseColumns } from '../../LicenseGridhelper';
import { useDispatch, useSelector } from 'react-redux';
import { selectMandatoryFieldsStatus, selectOrderRequirementTypes } from '@AMIEWEB/Order/Store/OrderCreation.selectors';
import { GridApi, GridCallbackDetails, GridSelectionModel } from '@mui/x-data-grid-pro';
import { selectOrderLicenses } from '@AMIEWEB/Order/Store/Order.selectors';
import { IOrderLicense, orderDataActions } from '@AMIEWEB/Order/Store/Order.redux';
import { makeStyles } from 'tss-react/mui';
import { Controller, useFormContext } from 'react-hook-form';
import { orderCreationActions } from '@AMIEWEB/Order/Store/OrderCreation.actions';
import { formatOrderLicenseList } from '../../helper';

const useStyles = makeStyles<{ isDisabled: boolean }>()((theme, props) => ({
  root: {
    '& .MuiGrid-root': {
      margin: 0,
      width: 'auto',
    },
    '& .MuiGrid-root > .MuiGrid-item': {
      padding: 0,
    },
    '& .MuiDataGrid-root': {
      border: 'none',
      borderRadius: 0,
    },
    '& .MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
      border: 'none !important',
      outline: 'none !important',
      backgroundColor: 'transparent !important',
    },
  },
  licenseGrid: {
    opacity: props.isDisabled ? 0.3 : 1,
  },
  overlay: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    whiteSpace: 'nowrap',
  },
}));

export const LicenseDropdownGrid = ({
  filteredLicenses,
  updateLicenses,
  isDisabled = false,
}: {
  filteredLicenses: any[];
  updateLicenses: (e) => void;
  isDisabled?: boolean;
}) => {
  const { classes } = useStyles({ isDisabled: isDisabled });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { licenses } = useSelector(selectOrderRequirementTypes);
  const orderLicenses = useSelector(selectOrderLicenses);
  const mandatoryFieldsStatus = useSelector(selectMandatoryFieldsStatus);
  const filteredLicenseOptions = (filteredLicenses ?? licenses)?.map(e => ({
    ...e,
    id: e.requirementTypeID ?? e.requirementTypeId,
  }));
  const formattedLicenseList =
    licenses?.length > 0
      ? licenses?.map(e => ({
          ...e,
          id: e.requirementTypeID ?? e.requirementTypeId,
        }))
      : [];
  const gridApi = React.useRef<GridApi>();
  const licenseColumns = [...orderLicenseColumns(isDisabled, t)];
  const [dataList, setDataList] = useState<any[]>([...filteredLicenseOptions]);
  const [orderSelectionModel, setOrderSelectionModel] = useState<any[]>([]);
  const { control } = useFormContext();

  useEffect(() => {
    if (filteredLicenses) {
      setDataList(filteredLicenseOptions);
    }
  }, [filteredLicenses]);

  useEffect(() => {
    const selectedLicenseIds = orderLicenses?.map(x => x.licenseId);
    setOrderSelectionModel([...selectedLicenseIds]);
    const formattedGridData = formatOrderLicenseList(orderLicenses, dataList);
    setDataList(formattedGridData);
    const formattedSelectedOrderLicense = formatOrderLicenseList(orderLicenses, formattedLicenseList);
    const selectedLicenses = formattedSelectedOrderLicense?.filter(x => x.isSelected);
    updateLicenses(selectedLicenses);
  }, [orderLicenses]);

  useEffect(() => {
    if (!mandatoryFieldsStatus) {
      const orderLicensesDetails: IOrderLicense[] = [...orderLicenses];
      orderLicensesDetails?.map((data, index) => {
        if (!data?.compactStatus) {
          const orderDetails: IOrderLicense = {
            ...data,
            isError: { ...data?.isError, compactStatus: true },
          };
          orderLicensesDetails[index] = { ...orderDetails };
        }
        if (data?.compactStatus === 'Single State' && data?.licenseStateDetails?.length === 0) {
          const orderDetails: IOrderLicense = {
            ...data,
            isError: { ...data?.isError, state: true },
          };
          orderLicensesDetails[index] = { ...orderDetails };
        }
      });
      dispatch(orderDataActions.setOrderLicenseDetails(orderLicensesDetails));
    }
  }, [mandatoryFieldsStatus]);

  const onSelectionModelChange = (selectionModel: GridSelectionModel, details: GridCallbackDetails<any>) => {
    if (!isDisabled) {
      setOrderSelectionModel([...selectionModel]);
      if (selectionModel && selectionModel?.length > 0 && licenses?.length > 0) {
        const orderLicensesDetails: IOrderLicense[] = [...orderLicenses];
        selectionModel?.map(selectedOption => {
          const licenseDetailsIndex = orderLicenses?.findIndex(data => data?.licenseId === selectedOption);
          if (licenseDetailsIndex === -1) {
            const selectedLicenseOptionDetails = licenses?.find(data => data?.requirementTypeID === selectedOption);
            const selectedLicenseDetail: IOrderLicense = {
              licenseDescription: selectedLicenseOptionDetails?.credentialName,
              licenseAbbreviation: selectedLicenseOptionDetails?.abbreviation,
              licenseId: selectedLicenseOptionDetails.requirementTypeID,
              isError: { compactStatus: false, state: false },
              isSelected: true,
              licenseStateDetails: [{ isRequired: true, documentAttachmentRequired: false }],
            };
            orderLicensesDetails.push(selectedLicenseDetail);
          }
        });
        orderLicensesDetails?.map((licenseDetails, index) => {
          const isRowSelected = selectionModel?.find(selected => selected === licenseDetails.licenseId);
          if (!isRowSelected) {
            orderLicensesDetails.splice(index, 1);
          }
        });
        dispatch(orderDataActions.setOrderLicenseDetails(orderLicensesDetails));
      } else {
        dispatch(orderDataActions.setOrderLicenseDetails([]));
      }
      if (!mandatoryFieldsStatus) {
        dispatch(orderCreationActions.setEditOrderRequrementMandatoryFieldsStatus(true));
      }
    }
  };

  return (
    <div data-testid="license-dropdown" className={classes.licenseGrid}>
      <Controller
        control={control}
        name={'qualification.licenses'}
        render={({ ref, onChange, ...rest }) => (
          <Grid className={classes.root}>
            <XGrid
              apiRef={gridApi}
              hideFooterSelectedRowCount
              checkboxSelection
              disableSelectionOnClick
              gridTag={'Order-License'}
              height={300}
              rowHeight={60}
              columns={licenseColumns}
              onSelectionModelChange={onSelectionModelChange}
              selectionModel={orderSelectionModel}
              keepNonExistentRowsSelected={true}
              rows={dataList}
              toolbar={false}
              autoHeight={false}
              pagination={false}
              hideFooterPagination
              hideFooter={true}
              disablePaggination
              paginationProps={{
                hideResultsPerPageSelection: true,
                hidePageCount: true,
              }}
              disableVirtualization={true}
              components={{
                NoRowsOverlay: () => <div className={classes.overlay}>{t('No licenses match your search')}</div>,
              }}
            />
          </Grid>
        )}
      />
    </div>
  );
};
