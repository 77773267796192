import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete, Button, Checkbox, FormControlLabel, FormGroup, Grid, TextField, Theme, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import { FormInputText } from '@AMIEWEB/Candidate/CandidateProfile/CandidateTabPanel/PersonalInfoTab/Form/Controls/FormInputText';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { CountryPhoneNumber } from '@AMIEWEB/Candidate/CandidateProfile/CandidateTabPanel/PersonalInfoTab/Form/EmergencyContact/CountryPhoneNumber';
import {
  selectCompanyOptions,
  selectDisableUserFieldEdit,
  selectEmpCardInfo,
  selectUpdateUserInfoErrorFields,
  selectUserDetailsFields,
  selectedTabValue,
} from '../store/userManagement.selector';
import { selectUser } from 'oidc/user.selectors';
import { Authorized } from 'oidc/userHelper';
import { userRoles } from 'oidc/userRoles';
import { IDetailsOptionsPayload, IUserDetailPayload, getUserManagementActions } from '../store/userManagement.redux';
import { FormInputDatePicker } from '@AMIEWEB/Candidate/CandidateProfile/CandidateTabPanel/PersonalInfoTab/Form/Controls/FormInputDatePicker';
import { formatPhoneNumber } from 'app/helpers/phoneHelpers';
import { Cancel, CustomTooltip } from '@AMIEWEB/Common';
import { separateCountryCodeAndNumber } from 'app/helpers/objectHelpers';
import { StatusOptions, processDataUpdate, statusOpts } from './Helper/DetailsHelper';
import { initialUserDetailFields } from '../Helper';
import { DiscardChangesDialog } from '../DiscardChangesDialog/DiscardChangesDialog';
import { selectAllData } from './Roles';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    padding: '0px 5px 0px 10px',
  },
  textColor: {
    color: theme.palette.components.typography.color,
    textAlign: 'left',
    font: 'normal normal medium 14px / 26px Roboto',
    fontWeight: 500,
    paddingLeft: 20,
    paddingTop: 16,
    fontSize: 14,
  },
  email: {
    width: '100%',
  },
  checkbox: {
    height: '20px',
    color: theme.palette.framework.system.charcoal,
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 10,
  },
  actionButton: {
    '&:hover': {
      backgroundColor: theme.palette.system.lightGrey,
    },
    color: theme.palette.components.button.primary.color,
    padding: '4px 9px',
    fontSize: '0.775rem',
    backgroundColor: theme.palette.system.lightGrey,
    pointerEvents: 'none',
  },
  cancelButton: {
    '&:hover': {
      backgroundColor: theme.palette.system.darkGray,
    },
    color: theme.palette.components.button.primary.color,
    padding: '4px 9px',
    fontSize: '0.775rem',
    backgroundColor: theme.palette.system.buttonGray,
  },
  saveButton: {
    color: theme.palette.components.button.primary.color,
    padding: '4px 9px',
    fontSize: '0.775rem',
    marginRight: '8px',
  },
  button: {
    padding: '10px 10px 0px 10px',
  },
  saveDisableButton: {
    '&:hover': {
      backgroundColor: theme.palette.system.lightGrey,
    },
    color: theme.palette.components.button.primary.color,
    padding: '4px 9px',
    fontSize: '0.775rem',
    backgroundColor: theme.palette.system.lightGrey,
    marginRight: '8px',
    pointerEvents: 'none',
  },
  detailsContainer: {
    width: '100%',
    display: 'flex',
    height: 'calc(100vh - 475px)',
    '@media (max-width: 980px)': {
      height: 'calc(100vh - 515px)',
    },
    overflowY: 'auto',
  },
  fieldHeight: {
    height: '60px',
    fontWeight: 'normal',
    '&&&:before': {
      borderBottomStyle: 'solid',
    },
    backgroundColor: 'rgba(0,0,0, 0.04) !important',
    '& .MuiInputBase-input.Mui-disabled': {
      WebkitTextFillColor: theme.palette.framework.system.charcoal,
    },
  },
  emailField: {
    '& .Mui-disabled': {
      backgroundColor: 'rgba(0,0,0, 0.02) !important',
    },
  },
  emailLabel: {
    '&.Mui-disabled': {
      WebkitTextFillColor: theme.palette.framework.system.charcoal,
    },
  },
  elipseText: {
    '& .MuiInputBase-input': {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  },
  dobAdjust: {
    paddingBottom: '0px !important',
    paddingTop: '8px !important',
    height: 60,
    '& .MuiInputBase-input.Mui-disabled': {
      WebkitTextFillColor: theme.palette.framework.system.charcoal,
    },
    '&&&:before': {
      borderBottomStyle: 'solid',
    },
  },
  dobFieldLabel: {
    paddingTop: 4,
  },
  phonelabel: {
    '&.react-tel-input .special-label': {
      display: 'inline-flex',
      position: 'absolute',
      top: '5px',
      left: '70px',
      background: 'transparent',
      fontSize: '11px',
      color: theme.palette.system.darkGrey,
    },
  },
  greyLabel: {
    fontSize: '14px !important',
    color: '#00000099 !important',
  },
}));

export const UserDetails = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { userInfo } = useSelector(selectUser);
  const disableEdit = useSelector(selectDisableUserFieldEdit);
  const userDetails = useSelector(selectUserDetailsFields);
  const companyOpts = useSelector(selectCompanyOptions);
  const { empInfo, dialogeOpen } = useSelector(selectAllData);
  const empCardInfo = useSelector(selectEmpCardInfo);
  const errorFields = useSelector(selectUpdateUserInfoErrorFields);
  const tabValue = useSelector(selectedTabValue);
  const authorized = Authorized(
    [
      userRoles.credentialing_Leadership,
      userRoles.accountManagement_Leadership,
      userRoles.recruitment_Leadership,
      userRoles.supportAdmin
    ], userInfo);
  const [cancelModalOpen, setCancelModalOpen] = React.useState<boolean>(false);
  const [disableSubmitOnError, setDisableSubmitOnError] = React.useState<boolean>(false);
  const [isOverflown, setIsOverflown] = useState({ userName: false, firstName: false, lastName: false });
  const formMethods = useForm({
    mode: 'onSubmit',
  });
  const defaultCountryCode = '1';
  const inputRef = useRef({});


  const {
    control,
    setValue,
    reset,
    getValues,
    watch,
    handleSubmit,
    setError,
    formState: { isDirty, errors, dirtyFields },
  } = formMethods;


  useEffect(() => {
    const companyPayload: IDetailsOptionsPayload = {
      keyword: 'companies',
    };
    dispatch(getUserManagementActions.getDetailsOptions(companyPayload));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (empInfo?.employeeId) {
      const payload: IUserDetailPayload = {
        employeeIds: [Number(empInfo.employeeId)],
      };
      dispatch(getUserManagementActions.getUserDetail(payload));
      reset(initialUserDetailFields);
      dispatch(getUserManagementActions.setDisableUserFieldEdit(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empInfo?.employeeId]);

  useEffect(() => {
    reset(userDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  React.useEffect(() => {
    if (errorFields.length > 0) {
      errorFields.forEach(errorField => {
        setError(errorField.fieldName, { type: "custom", message: errorField.message })
      });
      setDisableSubmitOnError(!disableSubmitOnError)
    }
  }, [errorFields])

  const handleSave = formData => {
    const updateUser = processDataUpdate(userDetails, formData)
    dispatch(getUserManagementActions.updateAmieUserInfo(updateUser))
  };

  const handleCancel = () => {
    if (Object.keys(dirtyFields).length > 0) {
      setCancelModalOpen(true);
    } else {
      setCancelModalOpen(false);
    }
  };

  const handleChange = (formId, value) => {
    setValue(formId, value);
    disableSubmitOnError && setDisableSubmitOnError(!disableSubmitOnError);
  };


  const handleDeptHireDateChange = event => {
    setValue('departmentHireDate', event, { shouldDirty: true });
  };

  const compareSize = () => {
    if (inputRef.current) {
      setTimeout(() => {
        setIsOverflown({
          ...isOverflown,
          firstName: isLayoutOverflown('firstName'),
          lastName: isLayoutOverflown('lastName'),
          userName: isLayoutOverflown('userName'),
        });
      }, 1000);
    }
  };

  const isLayoutOverflown = (field: string) => {
    return inputRef.current[field]?.scrollWidth > inputRef.current[field]?.clientWidth;
  };

  const getFormattedValue = (contactNumber) => {
    const separatedPhone = separateCountryCodeAndNumber(contactNumber)
    if (separatedPhone !== null) {
      return separatedPhone.countryCode + separatedPhone.number;
    } else {
      return defaultCountryCode + contactNumber;
    }
  };

  const filterOptions = (companyOpts, state) => {
    const newOptions: any = [];
    companyOpts.forEach(companyOpt => {
      if (companyOpt.name.toLowerCase().includes(state.inputValue?.toLowerCase())) newOptions.push(companyOpt);
    });
    return newOptions;
  };

  const handleDiscardChanges = () => {
    dispatch(getUserManagementActions.setIsDataChanged(false));
    dispatch(getUserManagementActions.setCustomTabValue(tabValue));
    dispatch(getUserManagementActions.setDialoge(false));
    dispatch(getUserManagementActions.setUserDetailsFields(initialUserDetailFields));
    dispatch(getUserManagementActions.setSelectedCardId(empCardInfo?.employeeId));
    dispatch(getUserManagementActions.setEmpInfo(empCardInfo));
    const payload = { employeeId: empCardInfo?.employeeId };
    dispatch(getUserManagementActions.getEmpRoles(payload));
    dispatch(getUserManagementActions.employeeAssociations(payload));
  };

  const keepChanges = () => {
    dispatch(getUserManagementActions.setDialoge(false));
  };


  useEffect(() => {
    compareSize();
    window.addEventListener('resize', compareSize);

    return () => {
      window.removeEventListener('resize', compareSize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empInfo?.employeeId]);

  const dirtyFieldsString = JSON.stringify(dirtyFields);

  useEffect(() => {
    const isDataChanged = Object.keys(dirtyFields).length > 0;
    dispatch(getUserManagementActions.setIsDataChanged(isDataChanged));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dirtyFieldsString]);

  return (
    <>
      <FormProvider {...formMethods}>
        <Grid container className={classes.detailsContainer} data-testId="userDetails-container">
          <LayoutGrid container direction="column" className={classes.root}>
            <Typography className={classes.textColor} variant="subtitle1" gutterBottom component="div">
              {t('userManagement.userDetails.details')}
            </Typography>
            <LayoutGridItem item>
              <LayoutGrid
                container
                classes={{ root: classes.root }}
                direction="row"
                data-testid="personalDetails-container"
                justifyContent="flex-start"
                columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                rowSpacing={3}
              >
                <LayoutGridItem item xs={6}>
                  <FormInputText
                    name="employeeId"
                    label={t('userManagement.profileInformation.employeeId')}
                    require={false}
                    isCheckBox={true}
                    message={errors.employeeId?.message}
                    readOnly
                    customInputClass={classes.fieldHeight}
                    labelClass={classes.greyLabel}
                  />
                </LayoutGridItem>
                <LayoutGridItem item xs={6}>
                  <CustomTooltip
                    standardMargin
                    tooltipContent={watch('userName')}
                    hideToolTip={!isOverflown.userName}
                  >
                    <FormInputText
                      name="userName"
                      label={t('userManagement.profileInformation.userName')}
                      inputRef={element => (inputRef.current['userName'] = element)}
                      require={true}
                      isCheckBox={true}
                      maxLengthError={255}
                      handleChange={e => { handleChange('userName', e.target?.value) }}
                      maskedInputValue={getValues('userName')}
                      readOnly={!authorized || disableEdit}
                      message={errors?.userName?.message}
                      customInputClass={`${classes.fieldHeight} ${classes.elipseText}`}
                      labelClass={classes.greyLabel}
                      disableInputRef={!authorized || disableEdit}
                    />
                  </CustomTooltip>
                </LayoutGridItem>
                <LayoutGridItem item xs={6}>
                  <CustomTooltip
                    standardMargin
                    tooltipContent={watch('firstName')}
                    hideToolTip={!isOverflown.firstName}
                  >
                    <FormInputText
                      name="firstName"
                      label={t('userManagement.profileInformation.firstName')}
                      require={true}
                      maxLengthError={255}
                      inputRef={element => (inputRef.current['firstName'] = element)}
                      handleChange={e => { handleChange('firstName', e.target?.value) }}
                      isCheckBox={true}
                      maskedInputValue={getValues('firstName')}
                      message={errors.firstName?.message}
                      readOnly={!authorized || disableEdit}
                      customInputClass={`${classes.fieldHeight} ${classes.elipseText}`}
                      labelClass={classes.greyLabel}
                      disableInputRef={!authorized || disableEdit}
                    />
                  </CustomTooltip>
                </LayoutGridItem>
                <LayoutGridItem item xs={6}>
                  <CustomTooltip
                    standardMargin
                    tooltipContent={watch('lastName')}
                    hideToolTip={!isOverflown.lastName}
                  >
                    <FormInputText
                      name="lastName"
                      label={t('userManagement.profileInformation.lastName')}
                      require={true}
                      inputRef={element => (inputRef.current['lastName'] = element)}
                      isCheckBox={true}
                      handleChange={e => { handleChange('lastName', e.target?.value) }}
                      maskedInputValue={getValues('lastName')}
                      message={errors.lastName?.message}
                      readOnly={!authorized || disableEdit}
                      customInputClass={`${classes.fieldHeight} ${classes.elipseText}`}
                      labelClass={classes.greyLabel}
                      disableInputRef={!authorized || disableEdit}
                    />
                  </CustomTooltip>
                </LayoutGridItem>
              </LayoutGrid>
            </LayoutGridItem>
            <Typography className={classes.textColor} variant="subtitle1" gutterBottom component="div">
              {t('userManagement.userDetails.contacts')}
            </Typography>
            <LayoutGridItem item>
              <LayoutGrid
                container
                classes={{ root: classes.root }}
                direction="row"
                data-testid="contactDetails-container"
                justifyContent="flex-start"
                columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                rowSpacing={3}
              >
                <LayoutGridItem item xs={6}>
                  <Controller
                    control={control}
                    name={"phoneNumber"}
                    render={({ ref, onChange, value, ...rest }) => (
                      <CountryPhoneNumber
                        className={classes.phonelabel}
                        name="phoneNumber"
                        isDisabled={!authorized || disableEdit}
                        value={value ? `${getFormattedValue(value)}` : defaultCountryCode}
                        countryCodeEditable={true}
                        label={t('userManagement.profileInformation.phoneNum')}
                        onChange={(value, data, event, formattedValue) => {
                          if (separateCountryCodeAndNumber(formattedValue) !== null) {
                            onChange(formattedValue);
                          } else {
                            onChange("");
                          }
                        }}
                      />
                    )}
                  />
                </LayoutGridItem>
                <LayoutGridItem item xs={6}>
                  <Controller
                    control={control}
                    name={"faxNumber"}
                    render={({ ref, onChange, value, ...rest }) => (
                      <CountryPhoneNumber
                        className={classes.phonelabel}
                        name="faxNumber"
                        isDisabled={!authorized || disableEdit}
                        countryCodeEditable={true}
                        value={value ? `${getFormattedValue(value)}` : defaultCountryCode}
                        label={t('userManagement.profileInformation.faxNum')}
                        onChange={(value, data, event, formattedValue) => {
                          if (separateCountryCodeAndNumber(formattedValue) !== null) {
                            onChange(formattedValue);
                          } else {
                            onChange("");
                          }
                        }}
                      />
                    )}
                  />
                </LayoutGridItem>
                <LayoutGridItem item xs={6}>
                  <CountryPhoneNumber
                    className={classes.phonelabel}
                    name="smsNumber"
                    isDisabled={true}
                    countryCodeEditable={true}
                    value={
                      getValues('smsNumber')
                        ? `${defaultCountryCode}${formatPhoneNumber(getValues('smsNumber'))}`
                        : defaultCountryCode
                    }
                    label={t('userManagement.profileInformation.smsNum')}
                    onChange={() => console.log()}
                  />
                </LayoutGridItem>
                <LayoutGridItem item className={classes.email}>
                  <Controller
                    name="emailId"
                    control={control}
                    rules={{
                      required: false,
                      maxLength: { value: 255, message: t('notification.createTask.maxLimitLabel') },
                    }}
                    render={({ ref, value, onChange, onBlur, ...rest }) => (
                      <TextField
                        variant="filled"
                        name="emailId"
                        type="email"
                        label={t('userManagement.profileInformation.emailId')}
                        className={classes.emailField}
                        value={value || null}
                        onChange={e => {
                          onChange(e);
                        }}
                        disabled={!authorized || disableEdit}
                        InputLabelProps={{ shrink: true, className: classes.greyLabel }}
                        inputProps={{ style: { height: 30 }, className: classes.emailLabel }}
                        fullWidth
                        error={(isDirty && errors?.emailId) ? true : false}
                        helperText={(isDirty && errors?.emailId?.message)}
                      />
                    )}
                  />
                </LayoutGridItem>
              </LayoutGrid>
            </LayoutGridItem>
            <Typography className={classes.textColor} variant="subtitle1" gutterBottom component="div">
              {t('userManagement.userDetails.statusAndCompany')}
            </Typography>
            <LayoutGridItem item>
              <LayoutGrid
                container
                classes={{ root: classes.root }}
                direction="row"
                data-testid="CompanyDetails-container"
                justifyContent="flex-start"
                columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                rowSpacing={3}
              >
                <LayoutGridItem item xs={6}>
                  <Controller
                    control={control}
                    name={"statusId"}
                    rules={{
                      required: true
                    }}
                    render={({ ref, onChange, value, ...rest }) => (
                      <Autocomplete
                        options={statusOpts}
                        disabled={!authorized || disableEdit}
                        getOptionLabel={(option: StatusOptions) => option.name}
                        noOptionsText={t('notification.createTask.noResultLabel')}
                        value={statusOpts.find((option) => option.id === value) || null}
                        onChange={(event, newValue: any) => {
                          onChange(newValue?.id);
                          disableSubmitOnError && setDisableSubmitOnError(!disableSubmitOnError)
                        }}
                        classes={{ inputRoot: classes.fieldHeight }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label={t('userManagement.profileInformation.status')}
                            variant="filled"
                            error={Object.keys(errors).includes("statusId")}
                            helperText={
                              (Object.keys(errors).includes("statusId") && t('required'))
                            }
                            InputLabelProps={{
                              classes: {
                                root: classes.greyLabel,
                              },
                              shrink: true
                            }}

                          />
                        )}
                      />
                    )}
                  />
                </LayoutGridItem>
                <LayoutGridItem item xs={6}>
                  <Controller
                    control={control}
                    name={"companyId"}
                    rules={{
                      required: true
                    }}
                    render={({ ref, onChange, value, ...rest }) => (
                      <Autocomplete
                        options={companyOpts}
                        filterOptions={filterOptions}
                        disabled={!authorized || disableEdit}
                        getOptionLabel={(option) => option?.name}
                        noOptionsText={t('notification.createTask.noResultLabel')}
                        value={companyOpts.find((option) => parseInt(option.value) === value) || null}
                        onChange={(event, newValue: any) => {
                          onChange(parseInt(newValue.value));
                        }}
                        classes={{ inputRoot: classes.fieldHeight }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label={t('userManagement.profileInformation.brand')}
                            variant="filled"
                            className={classes.fieldHeight}
                            error={Object.keys(errors).includes("companyId")}
                            helperText={
                              (Object.keys(errors).includes("companyId") && t('required'))
                            }
                            InputLabelProps={{
                              classes: {
                                root: classes.greyLabel,
                              },
                              shrink: true
                            }}
                          />
                        )}
                      />
                    )}
                  />
                </LayoutGridItem>
                <LayoutGridItem item xs={6}>
                  <Controller
                    control={control}
                    name={'workDayEmployeeId'}
                    rules={{
                      maxLength: { value: 6, message: t('notification.createTask.maxLimitLabel') }
                    }}
                    render={({ ref, onChange, value, ...rest }) => (
                      <TextField
                        variant="filled"
                        fullWidth
                        color="primary"
                        id="workDayEmployeeId"
                        value={value || null}
                        disabled={!authorized || disableEdit}
                        label={t('userManagement.profileInformation.workId')}
                        error={(isDirty && errors?.workDayEmployeeId) ? true : false}
                        onChange={e => {
                          onChange(e);
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        InputProps={{
                          classes: { root: classes.fieldHeight }
                        }}
                        helperText={(isDirty && errors?.workDayEmployeeId && t('notification.createTask.maxLimitLabel'))}
                      />
                    )}
                  />
                </LayoutGridItem>
                <LayoutGridItem item xs={6}>
                  <FormInputDatePicker
                    name="departmentHireDate"
                    label={t('userManagement.profileInformation.deptHDate')}
                    require={false}
                    readOnly={!authorized || disableEdit}
                    inputValue={watch('departmentHireDate')}
                    customClass={classes.dobAdjust}
                    labelClass={classes.dobFieldLabel}
                    shrink={true}
                    maxWidth={'100%'}
                    handleChange={handleDeptHireDateChange}
                  ></FormInputDatePicker>
                </LayoutGridItem>
              </LayoutGrid>
              <FormGroup className={classes.checkbox} data-testid="checkbox-container">
                <FormControlLabel
                  control={
                    <Controller
                      name="excludeFromTask"
                      render={({ ref, value, onChange, ...rest }) => {
                        return (
                          <Checkbox
                            name="excludeFromTask"
                            checked={getValues("excludeFromTask") ? true : false}
                            onChange={e => onChange(e.target.checked)}
                            defaultChecked
                            disabled={!authorized || disableEdit}
                          />
                        );
                      }}
                      control={control}
                    />
                  }
                  label={t('userManagement.profileInformation.excFromTask')}
                />
                <FormControlLabel
                  control={
                    <Controller
                      name="crossCompanyUser"
                      render={({ ref, value, onChange, ...rest }) => {
                        return (
                          <Checkbox
                            name="crossCompanyUser"
                            checked={getValues("crossCompanyUser") ? true : false}
                            onChange={e => onChange(e.target.checked)}
                            defaultChecked
                            disabled={!authorized || disableEdit}
                          />
                        );
                      }}
                      control={control}
                    />
                  }
                  label={t('userManagement.profileInformation.crossCUser')}
                />
              </FormGroup>
            </LayoutGridItem>
          </LayoutGrid>
        </Grid>
        <LayoutGrid container spacing={2} justifyContent="flex-end" className={classes.button} data-testid="button">
          <LayoutGridItem item>
            <Button
              className={`${!disableEdit ? classes.cancelButton : classes.actionButton}`}
              variant="contained"
              onClick={handleCancel}
              disabled={disableEdit || Object.keys(dirtyFields).length === 0}
            >
              {t('global.button.cancel')}
            </Button>
          </LayoutGridItem>
          <LayoutGridItem item>
            <Button
              className={`${empInfo?.employeeId && Object.keys(dirtyFields).length > 0 && authorized ? classes.saveButton : classes.saveDisableButton
                }`}
              variant="contained"
              onClick={handleSubmit(handleSave)}
              disabled={disableEdit || Object.keys(dirtyFields).length === 0 || disableSubmitOnError}
            >
              {t('global.button.save')}
            </Button>
          </LayoutGridItem>
        </LayoutGrid>
        <Cancel
          openDialog={cancelModalOpen}
          handleConfirmAction={() => {
            setCancelModalOpen(false);
          }}
          handleCancelAction={() => {
            setCancelModalOpen(false);
          }}
        />
      </FormProvider >
      <DiscardChangesDialog dialogeOpen={dialogeOpen} handleChanges={handleDiscardChanges} keepChanges={keepChanges} />
    </>
  );
};
