import React, { useEffect } from 'react';
import LogRocket from 'logrocket';
import { useSelector } from 'react-redux';
import { useDecision } from '@optimizely/react-sdk';
import { RootState } from 'types';
import { ff_global_ui_logrocket } from 'app/constants/FeatureFlags/Global/keys';

interface ILogRocketLogging {
	children?: any;
}

export const LogRocketLogging: React.FC<ILogRocketLogging> = ({ children })  => {
    const [logrocketFeature] = useDecision(ff_global_ui_logrocket);
	const user = useSelector((state: RootState) => state.user);

    //Init LogRocket
	useEffect(() => {
        if (logrocketFeature.enabled && globalThis?.app?.env?.REACT_APP_LOGROCKET_KEY) {
            LogRocket.init(globalThis?.app?.env?.REACT_APP_LOGROCKET_KEY as string, {
                dom: {
                    textSanitizer: true,
                    inputSanitizer: true
                },
                network: {
                    requestSanitizer: request => {
                        //throw out all headers, but keep AppInsights headers for correlation
                        const headerWhitelist: string[] = ['traceparent', 'Request-Id'];
                        Object.keys(request.headers).forEach((header) => {
                            if (!headerWhitelist.includes(header)) {
                                request.headers[header] = null;
                            }
                        });
                        
                        //throw out request Body
                        request.body = null;

                        return request;
                    },
                    responseSanitizer: response => {
                        //throw out response, saves timing info only
                        return null;
                    },
                },
            });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logrocketFeature.enabled]);

	// Send identity to LogRocket
    useEffect(() => {
        if (logrocketFeature.enabled && globalThis?.app?.env?.REACT_APP_LOGROCKET_KEY) {
			LogRocket.identify(user?.userInfo?.email as string, {
				name: `${user?.userInfo?.firstName} ${user?.userInfo?.lastName}`  as string,
				email: user?.userInfo?.email as string,
				environment: process.env.NODE_ENV as string,
				domain: globalThis?.app?.env?.REACT_APP_BASE_URL as string,
			});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user?.userInfo?.email]);

    return <>{ children } </>;
};