import {
  Button,
  Theme,
  Typography,
  Divider,
  FormControlLabel,
  debounce,
  ToggleButtonGroup,
  ToggleButton,
  Grid,
} from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import DialogActions, { DialogActionsProps } from '@mui/material/DialogActions';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormInputDropdown } from '../../../PersonalInfoTab/Form/Controls/FormInputDropdown';
import { FormInputText } from '../../../PersonalInfoTab/Form/Controls/FormInputText';
import { isWithinInterval, isAfter, isBefore } from 'date-fns';
import { FormInputDatePicker } from '../../../PersonalInfoTab/Form/Controls/FormInputDatePicker';
import { ControlledTypeAhead, ITypeAheadOption } from '../../../PreferencesTab/CustomComponents/ControlledTypeAhead';
import { getCitiesAndZipsByStates } from 'app/services/SharedServices/SharedServices';
import { FormInputCheckbox } from '../../../PersonalInfoTab/Form/Controls/FormInputCheckbox';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { candidateDetailsSelection } from '../../../../Profile/CandidateDetails.selector';
import { useSelector } from 'react-redux';
import { ff_ui_candidate_manage_EquipmentProcedure } from 'app/constants/FeatureFlags/Candidate/Keys';
import { useDecision } from '@optimizely/react-sdk';
import { CustomTooltip, TypeAhead } from '@AMIEWEB/Common';
import statesData from 'app/assets/jsons/State.json';
import { IOption } from '../../../ProfileTab/Education/FormInputDropdown';
import { FormInputRadio } from '../../../../WorkExperience/Edit/components/FormInputRadio';
import FormInputTextField from '../../../../WorkExperience/Edit/components/FormInputTextField';
import { theme } from 'styles/global-styles';
import { GenericDialogButton } from '@AMIEWEB/Alerts/GenericDialog';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    padding: '0px 24px 24px 24px',
  },
  action: {
    display: 'flex',
    flexDirection: 'row',
  },
  actionBtn: {
    fontSize: 12,
    padding: 0,
    minWidth: 30,
  },
  actionNoBtn: {
    color: '#888888',
  },

  alingmentField: {
    paddingRight: '25px !important',
  },
  currentlyWorking: {
    marginBottom: '-15px',
  },
  datePickerInput: {
    height: '55px',
  },

  contentContainer: {
    padding: '0px',
    border: `1px solid ${theme.palette.system.paleGray}`,
    borderRadius: '4px 4px 4px 4px',
    margin: '24px',
  },
  checkBoxContainer: {
    marginBottom: '-30px',
  },
  checkBoxInput: {
    paddingTop: '24px !important',
  },
  heading: {
    border: `1px solid ${theme.palette.system.lightGrey}`,
    borderRadius: '4px 4px 4px 4px',
    background: '#EBF4FA',
    display: 'flex',
    flexDirection: 'column',
    padding: '6px !important',
  },
  buttonStyle: {
    color: '#611A16',
    fontSize: '14px',
    fontWeight: 500,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  infoIcon: {
    color: '#34AADC',
    height: '20px',
    width: '20px',
  },
  infoIconAlignment: {
    paddingTop: '30px !important',
  },
  label: {
    fontSize: '14px !important',
    color: 'rgba(0, 0, 0, 0.37)',
    '&.Mui-focused': {
      color: 'rgba(0, 0, 0, 0.37)',
    },
    '&[class*="MuiInputLabel-outlined"]': {
      marginTop: '1px',
    },
  },
  textBox: {
    backgroundColor: '#FFFFFF',
    marginTop: '1%',
    marginBottom: '8px',
    '& .MuiInputBase-root': {
      height: '135px',
      display: 'inline-flex',
      flexDirection: 'column',
      margin: '2px',
    },
    '& .MuiOutlinedInput-root': {
      margin: '2px',
      position: 'initial',
    },
    '& label': {
      '&.Mui-focused': {
        color: '#C0C0C0',
      },
    },
  },
  actionButton: {
    backgroundColor: '#00000010',
    boxShadow: '0px -1px 3px rgba(0, 0, 0, 0.29)',
    borderRadius: '0px 0px 4px 4px',
    opacity: 0.95,
    backdropFilter: 'blur(30px)',
    WebkitBackdropFilter: 'blur(30px)',
    height: theme.spacing(11),
    paddingBottom: theme.spacing(2),
  },
}));
const useGenericDialogStyles = makeStyles<{ variant?: 'blue' | 'white' }>()((theme: Theme, props) => ({}));
enum CountryCodes {
  USA = 1,
  CANADA = 2,
}

export const AddWorkExperienceForm = props => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const candidateDetails = useSelector(candidateDetailsSelection);
  const [filteredSpecialty, setFilteredSpecialty] = React.useState<ITypeAheadOption[]>([]);
  const { cx } = useGenericDialogStyles({});
  const [candidateManageEquipmentProcedure] = useDecision(ff_ui_candidate_manage_EquipmentProcedure);
  const [pSupervisor, setPSupervisor] = useState<boolean>(false);
  const [pTeletherapy, setPTeletherapy] = useState<boolean>(false);
  const [pHospital, setPHospital] = useState<boolean>(false);
  const [pStaffing, setPStaffing] = useState<boolean>(false);
  const [currentEmployer, setCurrentEmployer] = useState<boolean>(true);
  const [pOngoing, setPOngoing] = useState<boolean>(false);
  const [addWorkExperience, setAddWorkExperience] = useState<boolean>(true);
  const [errorMessageOpen, setErrorMessageOpen] = useState<boolean>(false);
  const [startValue, setStartValue] = useState<Date>(null);
  const [endValue, setEndValue] = useState<Date>(null);
  const [addTimeOff, setAddTimeOff] = useState<boolean>(false);
  const { errors, getValues, setValue, clearErrors, control, register, setError, formState, watch } = useFormContext();
  const [charCount, setCharCount] = useState<number>(0);
  const [cityOptions, setCityOptions] = useState<IOption[]>([]);
  const [statesOptions, setStatesOptions] = useState<IOption[]>([]);

  const facilityEmployer = watch('pFacilityEmployer');
  const discipline = watch('piDiscipline');
  const speciality = watch('piSpecialty');
  const selectedState = watch('pState');
  const selectedCity = watch('piCity');
  const onGoing = watch('pOngoing');
  const selectedCountry = watch('pCountry');

  useEffect(() => {
    if (!getValues('pAddWorkExperience') && addWorkExperience) {
      setValue('pAddTimeOff', false);
      setValue('pAddWorkExperience', addWorkExperience);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStartDateChange = event => {
    setValue('pStart', event, { shouldDirty: true });
    setStartValue(new Date(event));
    clearErrors('pStart');
  };

  useEffect(() => {
    const endFromDate = endValue ? new Date(endValue) : null;
    const startDate = startValue ? new Date(startValue) : null;

    if (hasDateOverlapping()) {
      setErrorMessageOpen(true);
    } else {
      setErrorMessageOpen(false);
    }

    const currentlyWorking = getValues('currentlyWorking');
    if (!currentlyWorking && !pOngoing && endFromDate?.getTime() < startDate?.getTime()) {
      setError('pEnd', {
        type: 'validate',
        message: 'End date must be greater than start date',
      });
    } else {
      clearErrors('pEnd');
    }
    if (startDate?.getTime() > endFromDate?.getTime()) {
      setError('pStart', {
        type: 'validate',
        message: 'Start date must be less than end date',
      });
    } else {
      clearErrors('pStart');
    }
  }, [startValue, endValue]);

  const handleEndDateChange = event => {
    setValue('pEnd', event, { shouldDirty: true });
    setEndValue(new Date(event));
    clearErrors('pEnd');
  };

  const handlePNotes = event => {
    setValue('pnotes', event.target.value, { shouldDirty: true });
    setCharCount(event.target.value?.length);
  };

  const hasDateOverlapping = () => {
    const endFromDate = endValue ? new Date(endValue) : null;
    const startDate = startValue ? new Date(startValue) : null;

    if (addTimeOff) {
      if (isOverlappingDates(startDate, endFromDate)) {
        return true;
      } else {
        return false;
      }
    }
  };

  useEffect(() => {
    if (discipline) {
      const filteredData = props.typeAheadOptions?.specialties.filter(data => {
        return data.object.DisciplineID === discipline.object.ID;
      });
      filteredData.sort((a, b) => a.label?.localeCompare(b.label));
      setFilteredSpecialty(filteredData);
      filteredData.length && setValue('piSpecialty', filteredData[0], { shouldDirty: true });
    }
  }, [discipline]);

  const isOverlappingDates = (startDate: Date, endDate: Date) => {
    let isOverlapping = false;
    candidateDetails?.experience?.employment.map(item => {
      if (
        item.endedOn &&
        (isWithinInterval(startDate, {
          start: new Date(item.startedOn),
          end: item.endedOn ? new Date(item.endedOn) : null,
        }) ||
          isWithinInterval(endDate, {
            start: new Date(item.startedOn),
            end: item.endedOn ? new Date(item.endedOn) : null,
          }))
      ) {
        isOverlapping = true;
      } else if (
        !item.endedOn &&
        (isAfter(startDate, new Date(item.startedOn)) || isAfter(endDate, new Date(item.startedOn)))
      ) {
        isOverlapping = true;
      } else if (
        item.endedOn &&
        isBefore(startDate, new Date(item.startedOn)) &&
        isAfter(endDate, new Date(item.endedOn))
      ) {
        isOverlapping = true;
      }
    });
    return isOverlapping;
  };

  const handleCurrentlyWorkingChange = event => {
    const isChecked = event;

    if (isChecked) {
      errors['pReason'] = '';
    }

    setValue('currentlyWorking', isChecked, { shouldDirty: true });
    setCurrentEmployer(isChecked);
  };

  const handleWorkEndDateChange = event => {
    if (event && event !== '') {
      const currentDate = new Date().getTime();
      const endDate = new Date(event).getTime();
      const isCurrentlyWorking = currentDate < endDate;

      if (isCurrentlyWorking) {
        errors['pReason'] = '';
      }
      setValue('currentlyWorking', isCurrentlyWorking, { shouldDirty: true });
      setCurrentEmployer(isCurrentlyWorking);
    }
  };

  useEffect(() => {
    const country = getValues('pCountry');
    if (country?.id === CountryCodes.USA || country?.id === CountryCodes.CANADA) {
      const states = statesData.states
        .filter(e => e.CountryID === country?.id)
        .map(e => ({ id: e.ID, label: e.Description, value: e.StateNumber }));

      setStatesOptions(states);
      setValue('pState', states[0]);
    } else if (formState['dirtyFields']['pCountry'] || formState['touched']['pCountry']) {
      setStatesOptions([]);

      // DO NOT REMOVE SetTimeout otherwise state wont reset once you change state from USA to non-USA.
      setTimeout(() => {
        setValue('pState', '');
      }, 0);
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedCountry?.id === CountryCodes.USA && selectedState) {
      fetchCities();
    } else {
      setCityOptions([]);
    }

    setTimeout(() => {
      setValue('piCity', '');
    }, 0);
  }, [selectedState]);

  const fetchCities = async () => {
    const selectedStates = typeof selectedState ? [selectedState?.label] : [];
    const result = getCitiesAndZipsByStates({
      states: selectedStates,
      cities: [],
    });

    let filteredCities = [];
    result.then(res => {
      filteredCities = res[0]?.cities?.map(item => {
        return { id: item.id, label: item.name };
      });

      setCityOptions(filteredCities);
    });
  };

  const onTextSearch = async (key: string, searchMode: 'c' | 'z') => {
    const selectedStates = selectedState ? [selectedState?.label] : [];
    if (searchMode === 'c') {
      const result = getCitiesAndZipsByStates({
        states: selectedStates,
        cities: [],
        searchMode: searchMode,
        searchValue: key,
      });

      let filteredCities = [];
      result.then(res => {
        filteredCities = res[0]?.cities?.map(item => {
          return { id: item.id, label: item.name };
        });

        setCityOptions(filteredCities);
      });
    }
  };

  const yesAction = () => {
    setErrorMessageOpen(false);
    props.setSaveDisabled(false);
  };

  const enableSaveOnChanges = () => {
    const values = getValues();
    const { pFacilityEmployer, piDiscipline, piSpecialty, pState, piCity, pStart, pCountry } = values;

    if (addWorkExperience) {
      if (!pFacilityEmployer) {
        props.setSaveDisabled(true);
        return;
      } else if (!piDiscipline) {
        props.setSaveDisabled(true);
        return;
      } else if (!piSpecialty) {
        props.setSaveDisabled(true);
        return;
      } else if (!pState && (pCountry?.id === CountryCodes.USA || pCountry?.id === CountryCodes.CANADA)) {
        props.setSaveDisabled(true);
        return;
      } else if (!piCity && pCountry?.id === CountryCodes.USA) {
        props.setSaveDisabled(true);
        return;
      } else if (!pStart) {
        props.setSaveDisabled(true);
        return;
      } else {
        props.setSaveDisabled(false);
        return;
      }
    } else {
      if (!pStart) {
        props.setSaveDisabled(true);
        return;
      } else if (errorMessageOpen) {
        props.setSaveDisabled(true);
        return;
      } else {
        if (hasDateOverlapping()) {
          props.setSaveDisabled(true);
        } else {
          props.setSaveDisabled(false);
        }
        return;
      }
    }
  };

  useEffect(() => {
    enableSaveOnChanges();
  }, [facilityEmployer, discipline, speciality, selectedState, selectedCity, startValue, endValue, onGoing]);

  return (
    <>
      <div className={classes.contentContainer}>
        <Grid
          id="generic-dialog-content-heading"
          className={classes.heading}
          style={{ marginBottom: '20px', borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px' }}
        >
          <span>
            <span style={{ fontWeight: 'bold', fontSize: '16px' }}>{'Work Experience '}</span>
            <span style={{ fontSize: '14px' }}>{'*Required Fields'}</span>
          </span>
        </Grid>

        <LayoutGrid container direction="column" className={classes.root} spacing={4}>
          <LayoutGridItem container display="flex" flexDirection="row">
            <LayoutGridItem xs={8.35}>
              <Controller
                name="pAddWorkExperience"
                key={'Work Experience'}
                control={control}
                render={({ ref, value, onChange, ...rest }) => (
                  <ToggleButtonGroup
                    color="secondary"
                    value={addWorkExperience ? 'workExperience' : ''}
                    exclusive
                    onChange={(e, newValue) => {
                      if (newValue !== null) {
                        setAddWorkExperience(newValue === 'workExperience');
                        setAddTimeOff(false);
                        setValue('pAddTimeOff', false);
                        setValue('pAddWorkExperience', newValue === 'workExperience');
                      }
                    }}
                  >
                    <ToggleButton
                      style={{
                        width: theme.spacing(14),
                        borderTopRightRadius: '0px',
                        borderBottomRightRadius: '0px',
                        textTransform: 'none',
                      }}
                      value="workExperience"
                    >{`Travel`}</ToggleButton>
                  </ToggleButtonGroup>
                )}
              />

              <Controller
                key={'Time Off'}
                name="pAddTimeOff"
                control={control}
                render={({ ref, value, onChange, ...rest }) => (
                  <ToggleButtonGroup
                    color="secondary"
                    value={addTimeOff ? 'timeOff' : ''}
                    exclusive
                    onChange={(e, newValue) => {
                      if (newValue !== null) {
                        setAddTimeOff(newValue === 'timeOff');
                        setAddWorkExperience(false);
                        setValue('pAddTimeOff', newValue === 'timeOff');
                        setValue('pAddWorkExperience', false);
                      }
                    }}
                  >
                    <ToggleButton
                      style={{
                        width: theme.spacing(16),
                        borderTopLeftRadius: '0px',
                        borderBottomLeftRadius: '0px',
                        textTransform: 'none',
                      }}
                      value="timeOff"
                    >
                      Time Off
                    </ToggleButton>
                  </ToggleButtonGroup>
                )}
              />
            </LayoutGridItem>
            {addWorkExperience && (
              <LayoutGridItem>
                <FormInputCheckbox
                  name={`currentlyWorking`}
                  handleChecked={e => handleCurrentlyWorkingChange(e)}
                  isChecked={getValues('currentlyWorking') || currentEmployer}
                  checkboxLabel={'Current Employer'}
                ></FormInputCheckbox>
              </LayoutGridItem>
            )}
          </LayoutGridItem>

          {addWorkExperience ? (
            <>
              <LayoutGridItem item>
                <LayoutGrid
                  container
                  direction="row"
                  data-testid="container"
                  justifyContent="flex-start"
                  spacing={6}
                  sx={theme => ({ marginBottom: theme.spacing(9) })}
                >
                  <LayoutGridItem item xs={5}>
                    <LayoutGridItem container direction="column" spacing={4}>
                      <LayoutGridItem container>
                        <LayoutGridItem item xs={9}>
                          <Controller
                            name="piTravelerCompany"
                            defaultValue={null}
                            control={control}
                            render={({ ref, ...rest }) => (
                              <TypeAhead
                                options={props.travelCompanyData || []}
                                label="Staffing Agency"
                                {...rest}
                                isError={errors['piTravelerCompany']}
                              />
                            )}
                          />
                        </LayoutGridItem>
                      </LayoutGridItem>
                      <LayoutGridItem container>
                        <LayoutGridItem item xs={12}>
                          <FormInputText
                            name="pFacilityEmployer"
                            label={'Facility/Employer *'}
                            message={errors['pFacilityEmployer']?.message || ''}
                          />
                        </LayoutGridItem>
                      </LayoutGridItem>
                      <LayoutGridItem container>
                        <LayoutGridItem item xs={9}>
                          <Controller
                            name="pCountry"
                            defaultValue={null}
                            control={control}
                            render={({ ref, ...rest }) => (
                              <TypeAhead
                                options={props.countriesData}
                                label="Country"
                                {...rest}
                                isError={errors['pCountry']}
                              />
                            )}
                          />
                        </LayoutGridItem>
                      </LayoutGridItem>
                      <LayoutGridItem container display="flex" direction="row" spacing={2}>
                        <LayoutGridItem item xs={5}>
                          {watch('pCountry')?.id === CountryCodes.USA ||
                          watch('pCountry')?.id === CountryCodes.CANADA ? (
                            <Controller
                              name="pState"
                              defaultValue={statesOptions[0] || null}
                              control={control}
                              render={({ ref, ...rest }) => (
                                <TypeAhead
                                  options={statesOptions || []}
                                  label="State *"
                                  {...rest}
                                  isError={errors['pState']}
                                />
                              )}
                            />
                          ) : (
                            <FormInputTextField name="pState" label={'State'} />
                          )}
                        </LayoutGridItem>
                        <LayoutGridItem item xs={4}>
                          {selectedCountry?.id === CountryCodes.USA ? (
                            <Controller
                              name="piCity"
                              defaultValue={null}
                              control={control}
                              render={({ ref, ...rest }) => (
                                <TypeAhead
                                  options={cityOptions || []}
                                  label="City *"
                                  onInputChange={debounce(text => onTextSearch(text, 'c'), 1500)}
                                  {...rest}
                                />
                              )}
                            />
                          ) : (
                            <FormInputTextField name="piCity" label={'City'} required={false} />
                          )}
                        </LayoutGridItem>
                        <LayoutGridItem item xs={3}>
                          <FormInputText name="piZip" label={'Zip Code'} message={errors['piZip']?.message || ''} />
                        </LayoutGridItem>
                      </LayoutGridItem>
                      <LayoutGridItem container display="flex" direction="row" spacing={2}>
                        <LayoutGridItem item xs={5}>
                          <FormInputCheckbox
                            name={`pSupervisor`}
                            isChecked={pSupervisor}
                            handleChecked={() => {
                              setPSupervisor(!pSupervisor);
                            }}
                            checkboxLabel={`Supervisor/Charge`}
                            isDisabled={false}
                            checkboxErrorName={`CheckboxError pSupervisor`}
                          ></FormInputCheckbox>
                        </LayoutGridItem>
                        <LayoutGridItem item>
                          <FormInputCheckbox
                            name={`pTeletherapy`}
                            isChecked={pTeletherapy}
                            handleChecked={() => {
                              setPTeletherapy(!pTeletherapy);
                            }}
                            checkboxLabel={`Telehealth/Teletherapy`}
                            isDisabled={false}
                            checkboxErrorName={`CheckboxError pTeletherapy`}
                          ></FormInputCheckbox>
                        </LayoutGridItem>
                      </LayoutGridItem>
                      <LayoutGridItem container>
                        <LayoutGridItem item xs={5} pt={'10px'}>
                          <FormInputCheckbox
                            name={`pHospital`}
                            isChecked={pHospital}
                            handleChecked={() => {
                              setPHospital(!pHospital);
                            }}
                            checkboxLabel={`Teaching Hospital`}
                            isDisabled={false}
                            checkboxErrorName={`CheckboxError pHospital`}
                          ></FormInputCheckbox>
                        </LayoutGridItem>
                      </LayoutGridItem>
                    </LayoutGridItem>
                  </LayoutGridItem>
                  <LayoutGridItem item xs={3}>
                    <LayoutGridItem container direction="column" spacing={4}>
                      <LayoutGridItem container>
                        <LayoutGridItem item xs={12}>
                          <Controller
                            name="piDiscipline"
                            control={control}
                            render={({ ref, ...rest }) => (
                              <TypeAhead
                                options={props.typeAheadOptions?.disciplines || []}
                                label="Discipline *"
                                {...rest}
                                isError={errors.piDiscipline ? true : false}
                              />
                            )}
                          />
                        </LayoutGridItem>
                      </LayoutGridItem>
                      <LayoutGridItem container>
                        <LayoutGridItem item xs={12}>
                          <Controller
                            name="piSpecialty"
                            control={control}
                            defaultValue={getValues('piSpecialty') || {}}
                            render={({ ref, ...rest }) => (
                              <TypeAhead
                                options={filteredSpecialty.length ? filteredSpecialty : []}
                                label="Specialty *"
                                {...rest}
                                isError={errors['piSpecialty']}
                              />
                            )}
                          />
                        </LayoutGridItem>
                      </LayoutGridItem>
                      <LayoutGridItem container>
                        <LayoutGridItem item xs={9}>
                          <FormInputText
                            name="pBeds"
                            label={'# of Beds in Facility'}
                            require={false}
                            type={'number'}
                            message={errors['pBeds']?.message || ''}
                          />
                        </LayoutGridItem>
                      </LayoutGridItem>
                      <LayoutGridItem container>
                        <LayoutGridItem item xs={12}>
                          <FormInputText
                            name="pUnitFloorDept"
                            label={'Unit/Floor/Dept'}
                            require={false}
                            message={errors['pUnitFloorDept']?.message || ''}
                          />
                        </LayoutGridItem>
                      </LayoutGridItem>
                      <LayoutGridItem container>
                        <LayoutGridItem item xs={12}>
                          <FormInputText
                            name="pPosition"
                            label={'Position Held'}
                            require={false}
                            message={errors['pPosition']?.message || ''}
                          />
                        </LayoutGridItem>
                      </LayoutGridItem>
                    </LayoutGridItem>
                  </LayoutGridItem>
                  <LayoutGridItem item xs={4}>
                    <LayoutGridItem container direction="column" spacing={4}>
                      <LayoutGridItem container display="flex" direction="row" xs={4} spacing={2}>
                        <LayoutGridItem item xs={5}>
                          <FormInputDatePicker
                            name={'pStart'}
                            label={'Start Date *'}
                            handleChange={handleStartDateChange}
                            customClass={classes.datePickerInput}
                            labelClass={classes.label}
                            trailingIconVisible={true}
                            inputValue={getValues('pStart')}
                          ></FormInputDatePicker>
                        </LayoutGridItem>
                        <LayoutGridItem item xs={5}>
                          <FormInputDatePicker
                            name={'pEnd'}
                            label={'End Date'}
                            handleChange={handleWorkEndDateChange}
                            readOnly={false}
                            customClass={classes.datePickerInput}
                            labelClass={classes.label}
                            trailingIconVisible={true}
                            inputValue={watch('pEnd')}
                          ></FormInputDatePicker>
                        </LayoutGridItem>
                      </LayoutGridItem>
                      <LayoutGridItem container>
                        <LayoutGridItem item xs={12}>
                          <Controller
                            name="pReason"
                            defaultValue={null}
                            control={control}
                            render={({ ref, ...rest }) => (
                              <TypeAhead
                                options={props.typeAheadOptions?.reasonForLeaving || []}
                                label="Reason for Leaving"
                                {...rest}
                                isError={errors['pReason']?.message || ''}
                              />
                            )}
                          />
                        </LayoutGridItem>
                      </LayoutGridItem>

                      {candidateManageEquipmentProcedure.enabled && (
                        <>
                          <LayoutGridItem item xs={12}>
                            <FormInputText
                              name="pnotes"
                              label={t('candidate.referenceGrid.equipmentProcedures')}
                              handleChange={handlePNotes}
                              inputValue={getValues('pnotes')}
                              message={errors['pnotes']?.message || ''}
                              multiline
                              spellCheck
                              inputLength={1000}
                              charCount={charCount}
                              customClass={classes.textBox}
                            />
                          </LayoutGridItem>
                        </>
                      )}
                    </LayoutGridItem>
                  </LayoutGridItem>
                </LayoutGrid>
              </LayoutGridItem>
            </>
          ) : (
            <>
              {errorMessageOpen && (
                <LayoutGridItem style={{ height: theme.spacing(17) }}>
                  <LayoutGrid
                    container
                    direction="row"
                    className={classes.root}
                    spacing={2}
                    style={{ backgroundColor: '#FDEDE9', padding: '10px', paddingBottom: '15px' }}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    xs={12}
                  >
                    <LayoutGridItem>
                      <LayoutGrid container direction="row" spacing={2}>
                        <LayoutGridItem>
                          <ErrorOutlineIcon style={{ color: '#F4574A' }} />
                        </LayoutGridItem>
                        <LayoutGridItem>
                          <LayoutGrid container direction="column" spacing={0}>
                            <LayoutGridItem>
                              <span style={{ color: '#611A16', fontSize: '14px', fontWeight: 400 }}>
                                {
                                  'Dates are overlapping with existing work experience. This will cause a delay in submission,'
                                }
                              </span>
                            </LayoutGridItem>
                            <LayoutGridItem>
                              <span style={{ color: '#611A16', fontSize: '14px', fontWeight: 400 }}>
                                {'do you wish to proceed?'}
                              </span>
                            </LayoutGridItem>
                          </LayoutGrid>
                        </LayoutGridItem>
                      </LayoutGrid>
                    </LayoutGridItem>
                    <LayoutGridItem>
                      <LayoutGrid
                        container
                        direction="row"
                        className={classes.root}
                        spacing={2}
                        style={{ paddingRight: '15px' }}
                      >
                        <LayoutGridItem>
                          <span className={classes.buttonStyle} onClick={() => setErrorMessageOpen(false)}>
                            {'No'}
                          </span>
                        </LayoutGridItem>
                        <LayoutGridItem>
                          <span className={classes.buttonStyle} onClick={() => yesAction()}>
                            {'Yes'}
                          </span>
                        </LayoutGridItem>
                      </LayoutGrid>
                    </LayoutGridItem>
                  </LayoutGrid>
                </LayoutGridItem>
              )}
              <LayoutGridItem item>
                <LayoutGrid
                  container
                  direction="row"
                  data-testid="container"
                  justifyContent="flex-start"
                  alignItems={'center'}
                  spacing={'12px'}
                  xs={10}
                  sx={{ marginBottom: theme.spacing(5) }}
                >
                  <LayoutGridItem item xs={4}>
                    <FormInputDatePicker
                      name={'pStart'}
                      label={'Start Date *'}
                      handleChange={handleStartDateChange}
                      customClass={classes.datePickerInput}
                      trailingIconVisible={true}
                      inputValue={getValues('pStart')}
                    ></FormInputDatePicker>
                  </LayoutGridItem>
                  <LayoutGridItem item xs={4}>
                    <FormInputDatePicker
                      name={'pEnd'}
                      label={'End Date'}
                      handleChange={handleEndDateChange}
                      customClass={classes.datePickerInput}
                      trailingIconVisible={true}
                      inputValue={getValues('pEnd')}
                    ></FormInputDatePicker>
                  </LayoutGridItem>
                  <LayoutGridItem item xs={4}>
                    <FormInputCheckbox
                      name={`pOngoing`}
                      isChecked={pOngoing}
                      handleChecked={() => {
                        setPOngoing(!pOngoing);
                        if (!pOngoing == true) {
                          setValue('pEnd', null);
                          setEndValue(null);
                        }
                      }}
                      checkboxLabel={`Ongoing`}
                      isDisabled={false}
                      checkboxErrorName={`CheckboxError pOngoing`}
                    ></FormInputCheckbox>
                  </LayoutGridItem>
                </LayoutGrid>
              </LayoutGridItem>
            </>
          )}
        </LayoutGrid>

        <DialogActions
          {...props.dialogActionsProps}
          color={props.variant === 'blue' ? 'primary' : 'secondary'}
          classes={{
            ...props.dialogActionsProps?.classes,
            root: classes.actionButton,
          }}
        >
          {props.dialogActions.map((button, index) => (
            <div key={`generic-dialog-button-${index}`}>
              {!button.hidden &&
                (button.renderer ? (
                  button.renderer(button)
                ) : (
                  <GenericDialogButton {...button} onClick={button.useDialogOnCloseEvent ? onclose : button.onClick} />
                ))}
            </div>
          ))}
        </DialogActions>
      </div>
    </>
  );
};
