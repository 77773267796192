import React from 'react';
import { IFundamentalContainer, IOperationContainer, Operation, instanceOfIOperationContainer } from './types';
import { RequirementsContainer } from '../Statuses/RequirementsContainer';
import { Box } from 'amn-ui-core';
import { IRequirementTreeFilter, requirementType } from 'app/models/Placement/PlacementSummary';
import { useSelector } from 'react-redux';
import { selectSubmissionInfo } from 'store/redux-store/placement-summary/selectors';
import { CertificationsRow } from '../Statuses/Submission/groupedSkillsetRows/CertificationsRow';
import { ExperienceRow } from '../Statuses/Submission/groupedSkillsetRows/ExperieneRow';
import { LicenseRow } from '../Statuses/Submission/groupedSkillsetRows/LicenseRow';
import { SkillsetRow } from '../Statuses/Submission/groupedSkillsetRows/SkillsetRow';
import { useAccordionState } from '../Helpers/requirementFilter';

const getOperationString = (op: Operation) => (op === Operation.AND ? 'AND' : 'OR');

export const deriveNodeContainer = (state: IOperationContainer, filter: IRequirementTreeFilter, handleFilterChange) => (
  <>
    {state.nodes.map((_, index) => (
      <Box sx={{ display: 'flex', gap: '12px', padding: '6px' }} key={_.key}>
        <div style={{ fontWeight: 'bold', whiteSpace: 'pre', alignContent: 'flex-start', paddingTop: '12px' }}>
          {getOperationString(state.operation)}
        </div>
        {instanceOfIOperationContainer(_) ? (
          deriveNodeContainer(
            _,
            filter.children.find(x => x.key === _.key),
            handleFilterChange,
          )
        ) : (
          <RenderContainer 
            {..._} 
            filterKey={_.key} 
            handleFilterChange={handleFilterChange} 
            parentNodeFilter={filter} 
            operation={state.operation} 
          />
        )}
      </Box>
    ))}
  </>
);

const RenderContainer: React.FC<
  IFundamentalContainer & { 
    handleFilterChange; parentNodeFilter: IRequirementTreeFilter; filterKey: string; operation: Operation; 
  }
> = ({
  label,
  counts,
  parentNodeFilter,
  isPrimary,
  excludeFromFilter,
  containerRegistryKey,
  filterKey,
  handleFilterChange,
  operation,
}) => {
  const {
    skillReq: { containerRegistry },
  } = useSelector(selectSubmissionInfo);

  const { skillset, experience, licenses, certifications, appliedContainerTypes } = React.useMemo(() => {
    const {
      skillset: _skillset,
      experience: _experience,
      licenses: _licenses,
      certifications: _certifications,
    } = containerRegistry[containerRegistryKey];

    const licensesPassFailTypes =
      _licenses.orderRequirement.data.operation === '&'
        ? _licenses.orderRequirement.data.orderLicensesDetails.map(x => x.type)
        : [_licenses.orderRequirement.type];
    const licensesRiskTypes = _licenses.candidateRequirement.data.map(x => x.type);
    const certificationsPassFailTypes =
      _certifications.orderRequirement.data.operation === '&'
        ? _certifications.orderRequirement.data.certifications.map(x => x.type)
        : [_certifications.orderRequirement.type];
    const certificationRiskType =
      _certifications.candidateRequirement?.data.isCandidateCertsExpiring ||
      _certifications.candidateRequirement?.data.isCredentialCertsExpiring
        ? [requirementType.risk]
        : [];
    const _appliedContainerTypes = [
      _experience.orderRequirement.type,
      ...licensesPassFailTypes,
      ...licensesRiskTypes,
      ...certificationsPassFailTypes,
      ...certificationRiskType,
    ];

    return {
      skillset: {
        order: _skillset.orderRequirement,
        candidate: _skillset.candidateRequirement,
        amn: _skillset.amnRequirement,
      },
      experience: {
        order: _experience.orderRequirement,
        candidate: _experience.candidateRequirement,
        amn: _experience.amnRequirement,
      },
      licenses: {
        order: _licenses.orderRequirement,
        candidate: _licenses.candidateRequirement,
        amn: _licenses.amnRequirement,
      },
      certifications: {
        order: _certifications.orderRequirement,
        candidate: _certifications.candidateRequirement,
        amn: _certifications.amnRequirement,
      },
      appliedContainerTypes: _appliedContainerTypes,
    };
  }, [containerRegistry, containerRegistryKey]);

  const appliedFilter = React.useMemo(
    () => (!excludeFromFilter ? parentNodeFilter.children.find(x => x.key === filterKey)?.filter || [] : []),
    [filterKey, excludeFromFilter, parentNodeFilter.children],
  );

  const { expand: expandContainer } = useAccordionState(appliedFilter, appliedContainerTypes);

  return (
    <RequirementsContainer
      title={label}
      counts={counts}
      filter={appliedFilter}
      handleFilterChange={!excludeFromFilter ? newFilter => handleFilterChange(filterKey, newFilter) : undefined}
      isPrimary={isPrimary}
      expanded={expandContainer}
      excludeFromFilter={excludeFromFilter}
      highlight={operation === Operation.AND || isPrimary}
    >
      <SkillsetRow filterKey={filterKey} requirementFilter={parentNodeFilter} req={skillset} />
      <ExperienceRow filterKey={filterKey} requirementFilter={parentNodeFilter} req={experience} />
      {/* <SkillsChecklistRow filterKey={filterKey} requirementFilter={undefined} req={skillsChecklist} /> */}
      <LicenseRow filterKey={filterKey} requirementFilter={parentNodeFilter} req={licenses} />
      <CertificationsRow filterKey={filterKey} requirementFilter={parentNodeFilter} req={certifications} />
    </RequirementsContainer>
  );
};
