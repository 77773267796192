import { ITypeAheadOptions, orderDataActions } from '@AMIEWEB/Order/Store/Order.redux';
import { PayloadAction } from '@reduxjs/toolkit';
import { trackException } from 'app-insights/appInsightsTracking';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { ExceptionType } from 'app/enums/Common';
import {
  formatTypeAheadDisciplineOptions,
  formatTypeAheadSpecialtyOptions,
  formatTypeAheadSubSpecialtyOptions,
} from 'app/helpers/typeAheadHelper';
import { createOrder, updateOrder, updateOrderFreeText } from 'app/services/OrderServices/OrderServices';
import { getDisciplineSpecialtyData, getSearchFilterOptions } from 'app/services/SharedServices/SharedServices';
import { httpClientError, httpServerError, httpSuccess } from 'app/services/serviceHelpers';
import i18next from 'i18next';
import { call, getContext, put, select, takeLatest } from 'redux-saga/effects';
import { TooltipOrderPriorityList } from '../constants';
import {
  selectOrderCreationState,
  selectOrderPriorities,
  selectPage2,
  selectedOrderTypeDetails,
  selectedUnit,
  selectedUnitSkillSets,
} from './Order.selectors';
import { orderCreationNewActions } from './OrderCreation.actions';
import {
  getPage2DefaultData,
  getPage3FreeTextDetails,
  isPostionChangedToZero,
  transformDataForEditOrder,
  transformPayloadForEditOrder,
  transformPayloadForEditOrderUnitInheritance,
  transformPayloadForSubmitOrder,
} from './helper';
import { getSkillSetsForUnitByUnitId } from 'app/services/FacilityServices/FacilityServices';
import {
  selectOrderDetailsOrderType,
  selectOrderDetailsUIView,
  selectOrderRegions,
} from '@AMIEWEB/Order/Store/Order.selectors';
import { trackPromise } from 'react-promise-tracker';
import { selectUser } from 'oidc/user.selectors';
import { selectFacility } from '@AMIEWEB/Facility/FacilityStore/Facility.selector';
import { IOrderFreeText } from 'app/models/Orders/OrderDetails';
import { selectTransformedIssuedBy } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/UnitTab/Store/FacilityUnit.selector';
import { calculateOrderTypeDefaultValue, getDefaultSkillSetForEdit } from '../CreateOrder/helper';
import { FacilityService } from 'app/services/FacilityServices/facility-service';
import { selectUnitInherited } from 'store/redux-store/create-order-preferences/selectors';
import { initialState } from './OrderCreation.redux';
import { createOrderPreferenceActions } from 'store/redux-store/create-order-preferences/slice';
import { taskDetailsActions } from '@AMIEWEB/Tasks/store/Tasks.redux';
import { IEditOrderActionPayload } from '../types';
import { requestOrderTypes, requestRegions } from '@AMIEWEB/Order/Store/Order.saga';

const TrackUpdateOrder = (fn, ...args) => trackPromise(fn(...args), 'update-order');
const TrackSubmitOrder = (fn, ...args) => trackPromise(fn(...args), 'submit-order');
const TrackUnitSkillSetDetails = (fn, ...args) => trackPromise(fn(...args), 'unit-skillsetdetails');
const TrackOrderPriority = (fn, ...args) => trackPromise(fn(...args), 'order-priority');

export function* getOptionsForPage2(action: PayloadAction) {
  try {
    const orderPriorities = yield select(selectOrderPriorities);
    if (!orderPriorities || orderPriorities?.length === 0) {
      const result = yield call(() => TrackOrderPriority(() => getSearchFilterOptions('activeOrderPriorityTypes')));
      if (result?.length) {
        const results = result.map(item =>
          TooltipOrderPriorityList.includes(item.value)
            ? { ...item, tooltip: i18next.t('order.newOrderCreation.additionalRequirements.orderPriorityTooltip') }
            : item,
        );
        yield put(orderCreationNewActions.setOrderPriorities(results));
      }
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'getOptionsForPage2',
        area: 'src/app/components/NewOrder/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* updateDefaultvaluePage2(action: PayloadAction) {
  try {
    let page2 = yield select(selectPage2);
    const selectUnit = yield select(selectedUnit);
    const page2Data = getPage2DefaultData(page2, selectUnit);
    yield put(orderCreationNewActions.setPage2FormData(page2Data));
    yield put(orderCreationNewActions.setPage2DefaultValues(true));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'updateDefaultvaluePage2',
        area: 'src/app/components/NewOrder/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* updateDefaultvaluePage3(action: PayloadAction) {
  try {
    const selectUnit = yield select(selectedUnit);
    const freeTextData = getPage3FreeTextDetails(selectUnit);
    yield put(orderCreationNewActions.setOrderQualificationFreeText(freeTextData));
    yield put(orderCreationNewActions.setPage3DefaultValues(true));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'updateDefaultvaluePage3',
        area: 'src/app/components/NewOrder/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* getSkillSetsForSelectedUnit(action: PayloadAction<{ unitId: number }>) {
  try {
    const orderTypes = yield select(selectOrderDetailsOrderType);
    const facilityDetails = yield select(selectFacility);
    const defaultOrderType = calculateOrderTypeDefaultValue(orderTypes, facilityDetails);

    yield put(orderCreationNewActions.setOrderType(defaultOrderType));
    const response = yield call(TrackUnitSkillSetDetails, getSkillSetsForUnitByUnitId, action.payload.unitId);
    if (httpSuccess(response.status)) {
      const issuedBy = yield select(selectTransformedIssuedBy);
      const editSkillSetTransFormdata = getDefaultSkillSetForEdit(response?.data, issuedBy);

      yield put(orderCreationNewActions.setSkillSetsForSelectedUnit(editSkillSetTransFormdata));
    } else if (!(httpServerError(response.status) || httpClientError(response.status))) {
      //To handle Data refresh issues if Status Code is 204 (No-Content)
      yield put(orderCreationNewActions.setSkillSetsForSelectedUnit(initialState.page1.skillSetGroup));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getSkillSetsForSelectedUnit',
        area: 'src/app/components/NewOrder/Store/OrderCreation.saga.ts',
      },
    });
  }
}
export function* initOrderFromUnitPage(
  action: PayloadAction<{ unitId: any; updatedUnit: any; changeRoute: () => {} }>,
) {
  try {
    yield put(orderCreationNewActions.setNewOrderCreatedFromUnit(true));
    yield put(orderCreationNewActions.setTypeAheadOptions());
    const orderDetailsOrderType = yield select(selectOrderDetailsOrderType);
    const orderRegions = yield select(selectOrderRegions);
    if (!orderDetailsOrderType?.length) {
      yield call(requestOrderTypes);
    }
    if (!orderRegions?.length) {
      yield call(requestRegions);
    }
    yield call(getSkillSetsForSelectedUnit, {
      type: orderCreationNewActions.getSkillsetForSelectedUnitAction.type,
      payload: action.payload,
    });
    const page1 = yield select(selectedOrderTypeDetails);
    const newUnit = { ...page1, ...action.payload.updatedUnit };
    yield put(orderCreationNewActions.setSelectedUnitItem(newUnit));
    yield put(createOrderPreferenceActions.getUnitPreferences(action.payload.unitId));
    yield put(orderCreationNewActions.setSelectedUnitId(parseInt(action.payload.unitId)));

    action.payload.changeRoute();
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getSkillSetsForSelectedUnit',
        area: 'src/app/components/NewOrder/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* requestOrderTypeAheadDropDownOptions() {
  try {
    const response = yield call(getDisciplineSpecialtyData);
    if (httpSuccess(response.status)) {
      const disciplineOpts = formatTypeAheadDisciplineOptions(response.data.disciplines);
      const specialtyOpts = formatTypeAheadSpecialtyOptions(response.data.disciplineSpecialties);
      const subSpecialtyOpts = formatTypeAheadSubSpecialtyOptions(response.data.subSpecialties);
      const divisionDisciplineOpts = response.data.divisionDisciplines;

      yield put(
        orderCreationNewActions.setTypeAheadDropdownOptions({
          disciplines: disciplineOpts || [],
          specialties: specialtyOpts || [],
          subSpecialties: subSpecialtyOpts || [],
          divisionDisciplines: divisionDisciplineOpts || [],
        } as ITypeAheadOptions),
      );
    }
  } catch (error) {
    console.log('error requestOrderTypeAheadDropDownOptions', error);
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestOrderTypeAheadDropDownOptions',
        area: 'src/app/components/NewOrder/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* requestEditOrder(action: PayloadAction<{ orderId: string }>) {
  try {
    yield put(orderCreationNewActions.setOrderId(action.payload.orderId));
    const result = yield call(() => getSearchFilterOptions('activeOrderPriorityTypes'));
    if (result?.length) {
      const results = result.map(item =>
        TooltipOrderPriorityList.includes(item.value)
          ? { ...item, tooltip: i18next.t('order.newOrderCreation.additionalRequirements.orderPriorityTooltip') }
          : item,
      );
      yield put(orderCreationNewActions.setOrderPriorities(results));
    }

    // yield put(orderCreationNewActions.setActiveStep(action.payload.activeStep));
    const orderDetails = yield select(selectOrderDetailsUIView);
    const initialValues = yield select(selectOrderCreationState);
    const issuedBy = yield select(selectTransformedIssuedBy);
    const stateValue = transformDataForEditOrder(orderDetails?.orderDetails, initialValues, issuedBy);
    yield put(
      orderCreationNewActions.setAllPageData({
        page1: stateValue.page1,
        page2: stateValue.page2,
        page3: stateValue.page3,
      }),
    );
    const freeTextData: IOrderFreeText = {
      additionalRequiredQualifications: orderDetails?.orderDetails?.additionalRequiredQualifications ?? '',
      preferredQualifications: orderDetails?.orderDetails?.preferredQualifications ?? '',
      requiredQualifications: orderDetails?.orderDetails?.requiredQualifications ?? '',
      internalNotes: orderDetails?.orderDetails?.internalNotes ?? '',
      jobDescription: orderDetails?.orderDetails?.jobDescription ?? '',
    };
    yield put(orderCreationNewActions.setOrderQualificationFreeText(freeTextData));
  } catch (error) {
    console.log('error requestEditOrder', error);
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestEditOrder',
        area: 'src/app/components/NewOrder/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* editOrder(action: PayloadAction<IEditOrderActionPayload | undefined>) {
  const { activityFeedRefactoring = false } = action.payload;
  try {
    yield put(orderCreationNewActions.setIsEditLoader(true));
    const orderDetails = yield select(selectOrderDetailsUIView);
    const initialValues = yield select(selectOrderCreationState);
    const user = yield select(selectUser);
    const selectedSkillSetDataUnit = yield select(selectOrderDetailsUIView);

    //getting unit skillset data
    const response = yield call(getSkillSetsForUnitByUnitId, selectedSkillSetDataUnit?.orderDetails?.unit?.unitId);

    const issuedBy = yield select(selectTransformedIssuedBy);
    const editSkillSetTransFormdata = getDefaultSkillSetForEdit(response?.data, issuedBy);

    yield put(orderCreationNewActions.setSkillSetsForSelectedUnit(editSkillSetTransFormdata));
    const unitSkillSet = yield select(selectedUnitSkillSets);
    const editOrderPayload = transformPayloadForEditOrder(
      initialValues,
      orderDetails?.orderDetails,
      user,
      unitSkillSet,
    );
    editOrderPayload.currentUserId =
      editOrderPayload.currentUserId === 0 ? user.userInfo?.employeeId : editOrderPayload.currentUserId;
    const payLoadToPass = { ...orderDetails.orderDetails, ...editOrderPayload };
    const res = yield call(TrackUpdateOrder, async () => {
      const result = await updateOrder(payLoadToPass, orderDetails?.orderDetails.orderId);
      return result;
    });
    const originalPositions = +orderDetails?.orderDetailsOrginal?.positionsAvailable || 0;
    const currPositions = +payLoadToPass?.positionsAvailable || 0;
    const isPositionChangedToZero = isPostionChangedToZero({ originalPositions, currPositions });

    if (httpSuccess(res.status) && res?.data) {
      yield put(orderCreationNewActions.setIsEditSuccess(true));
      yield put(
        globalActions.setSnackBar({
          message: i18next.t('order.newOrderCreation.orderSaved'),
          severity: 'success',
        }),
      );
      //--Updates  notification count in header--
      if (activityFeedRefactoring && res.data?.positionsAvailable === 0 && isPositionChangedToZero) {
        yield put(taskDetailsActions.businessInitNotifications(10000));
      }
    } else {
      yield put(orderCreationNewActions.setIsEditLoader(false));
      yield put(
        globalActions.setBanner({
          message: i18next.t('order.newOrderCreation.orderFailed'),
          severity: 'error',
        }),
      );
    }
  } catch (error) {
    yield put(orderCreationNewActions.setIsEditLoader(false));
    yield put(
      globalActions.setBanner({
        message: i18next.t('order.newOrderCreation.orderFailed'),
        severity: 'error',
      }),
    );
    console.log('error editOrder', error);
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'editOrder',
        area: 'src/app/components/NewOrder/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* submitOrder(action: PayloadAction<any>) {
  try {
    const initialValues = yield select(selectOrderCreationState);
    const user = yield select(selectUser);
    const unitInherited = yield select(selectUnitInherited);
    const facilityData = yield select(selectFacility);
    const submitOrderPayload = transformPayloadForSubmitOrder(
      initialValues,
      facilityData?.facilityResponse,
      unitInherited,
    );
    submitOrderPayload.currentUserId = user.userInfo?.employeeId;
    const res = yield call(TrackSubmitOrder, createOrder, submitOrderPayload);

    if (httpSuccess(res.status) && res?.data) {
      yield put(createOrderPreferenceActions.setIsUnitInherited(false));
      action.payload.push(`/order/${res?.data}`);
      yield put(
        globalActions.setSnackBar({
          message: i18next.t('order.newOrderCreation.orderPublishedSuccessfully'),
          severity: 'success',
        }),
      );
    } else {
      yield put(
        globalActions.setBanner({
          message: i18next.t('order.newOrderCreation.orderCreationFailed'),
          severity: 'error',
        }),
      );
    }
  } catch (error) {
    yield put(
      globalActions.setBanner({
        message: i18next.t('order.newOrderCreation.orderCreationFailed'),
        severity: 'error',
      }),
    );
    console.log('error editOrder', error);
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'submitOrder',
        area: 'src/app/components/NewOrder/Store/OrderCreation.saga.ts',
      },
    });
  }
}
export function* updateQualifications(action: PayloadAction<IOrderFreeText>) {
  try {
    const res = yield call(TrackUpdateOrder, updateOrderFreeText, action.payload);
    const selectOrderUiDetails = yield select(selectOrderDetailsUIView);
    if (httpSuccess(res.status) && res?.data) {
      yield put(orderCreationNewActions.setIsEditSuccess(true));
      yield put(
        orderDataActions.setOrderDetailsView({
          ...selectOrderUiDetails,
          orderDetails: { ...selectOrderUiDetails.orderDetails, ...action.payload },
        }),
      );
      yield put(
        globalActions.setSnackBar({
          message: i18next.t('order.newOrderCreation.orderSaved'),
          severity: 'success',
        }),
      );
    } else {
      yield put(
        globalActions.setBanner({
          message: i18next.t('order.newOrderCreation.orderFailed'),
          severity: 'error',
        }),
      );
    }
  } catch (error) {
    yield put(
      globalActions.setBanner({
        message: i18next.t('order.newOrderCreation.orderFailed'),
        severity: 'error',
      }),
    );
    console.log('error updateQualifications', error);
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'updateQualifications',
        area: 'src/app/components/NewOrder/Store/OrderCreation.saga.ts',
      },
    });
  }
}

function* requestClientContacts(action: PayloadAction<{ facilityId: number; unitId: number }>) {
  try {
    const facilityService: FacilityService = yield getContext('facilityService');
    const response = yield call(
      facilityService.getFacilityUnitContacts,
      action.payload.facilityId,
      action.payload.unitId,
    );
    if (httpSuccess(response.status)) {
      const facilityUnitContacts = response?.data?.filter(item =>
        item?.actions?.some((ac: { description: string }) => ac.description === 'Send file to'),
      );
      yield put(orderCreationNewActions.setClientContacts([...facilityUnitContacts]));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'requestClientContacts',
        area: 'src/app/components/NewOrder/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* editOrderInheritUnit() {
  try {
    const orderDetails = yield select(selectOrderDetailsUIView);
    const user = yield select(selectUser);
    const selectedSkillSetDataUnit = yield select(selectOrderDetailsUIView);

    //getting unit skillset data
    const response = yield call(getSkillSetsForUnitByUnitId, selectedSkillSetDataUnit?.orderDetails?.unit?.unitId);
    const issuedBy = yield select(selectTransformedIssuedBy);
    const editSkillSetTransFormdata = getDefaultSkillSetForEdit(response?.data, issuedBy);

    yield put(orderCreationNewActions.setSkillSetsForSelectedUnit(editSkillSetTransFormdata));
    const unitSkillSet = yield select(selectedUnitSkillSets);
    const editOrderPayload = transformPayloadForEditOrderUnitInheritance(
      orderDetails?.selectedNewUnit,
      orderDetails?.orderDetails,
      user,
      unitSkillSet,
    );
    editOrderPayload.currentUserId =
      editOrderPayload.currentUserId === 0 ? user.userInfo?.employeeId : editOrderPayload.currentUserId;
    const payLoadToPass = { ...orderDetails.orderDetails, ...editOrderPayload };

    const res = yield call(TrackUpdateOrder, async () => {
      const result = await updateOrder(payLoadToPass, orderDetails?.orderDetails.orderId);
      return result;
    });

    if (httpSuccess(res.status) && res?.data) {
      yield put(orderDataActions.setOrderDetailsView({ ...orderDetails, orderDetails: payLoadToPass }));
      yield put(
        globalActions.setSnackBar({
          message: i18next.t('order.newOrderCreation.orderSaved'),
          severity: 'success',
        }),
      );
    } else {
      yield put(
        globalActions.setBanner({
          message: i18next.t('order.newOrderCreation.orderFailed'),
          severity: 'error',
        }),
      );
    }
  } catch (error) {
    yield put(
      globalActions.setBanner({
        message: i18next.t('order.newOrderCreation.orderFailed'),
        severity: 'error',
      }),
    );
    console.log('error editOrder', error);
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'editOrder',
        area: 'src/app/components/NewOrder/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* updateQualificationFreeText(action: PayloadAction<IOrderFreeText>) {
  try {
    const res = yield call(TrackUpdateOrder, updateOrderFreeText, action.payload);
    if (!httpSuccess(res.status)) {
      yield put(
        globalActions.setBanner({
          message: i18next.t('common.failedLoad'),
          severity: 'error',
        }),
      );
    }
  } catch (error) {
    yield put(
      globalActions.setBanner({
        message: i18next.t('common.failedLoad'),
        severity: 'error',
      }),
    );
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'updateQualificationFreeText',
        area: 'src/app/components/NewOrder/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* orderCreationNewSaga() {
  yield takeLatest(orderCreationNewActions.getOptionsForPage2.type, getOptionsForPage2);
  yield takeLatest(orderCreationNewActions.setDefaultValuesPage2.type, updateDefaultvaluePage2);
  yield takeLatest(orderCreationNewActions.setDefaultFreeTextValuesPage3.type, updateDefaultvaluePage3);
  yield takeLatest(orderCreationNewActions.setTypeAheadOptions.type, requestOrderTypeAheadDropDownOptions);
  yield takeLatest(orderCreationNewActions.getSkillsetForSelectedUnitAction.type, getSkillSetsForSelectedUnit);
  yield takeLatest(orderCreationNewActions.onRequestEditOrder.type, requestEditOrder);
  yield takeLatest(orderCreationNewActions.onEditOrder.type, editOrder);
  yield takeLatest(orderCreationNewActions.onSubmitOrder.type, submitOrder);
  yield takeLatest(orderCreationNewActions.onEditQualifications.type, updateQualifications);
  yield takeLatest(orderCreationNewActions.onUpdateQualificationFreeText.type, updateQualificationFreeText);
  yield takeLatest(orderCreationNewActions.getClientContacts.type, requestClientContacts);
  yield takeLatest(orderCreationNewActions.onEditOrderInheritUnit.type, editOrderInheritUnit);
  yield takeLatest(orderCreationNewActions.initOrderFromUnitPage.type, initOrderFromUnitPage);
}
