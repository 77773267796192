/* eslint-disable tss-unused-classes/unused-classes */
import { makeStyles } from 'tss-react/mui';
import { Divider, Theme, Toolbar } from 'amn-ui-core';
import styledComponent from 'styled-components';

export const useMessageInboxStyles = makeStyles()((theme: Theme, props) => ({
  tab: {
    minWidth: 52,
    width: 52,
    height: 10,
    opacity: 1,
    '&.Mui-selected': {
      borderColor: theme.palette.system.red,
    },
    '& .MuiTab-root': {
      color: '#1D4659',
      opacity: 1,
    },
  },
  titleIcon: { fill: theme.palette.system.violet },
  titleNumber: {
    fontWeight: 400,
    fontSize: '16px',
  },
  title: {
    color: '#333',
    paddingLeft: '10px',
    paddingRight: '5px',
    fontWeight: 500,
    fontSize: '16px',
  },
  notificationViewer: {
    width: '380px',
    margin: 0,
    padding: '0px',
  },
  paper: {
    padding: 0,
    margin: 0,
    overflow: 'auto',
    borderRadius: '10px',
  },
  searchBox: {
    marginTop: '4%',
    marginBottom: '4%',
    height: '32px',
    width: '80%',
    marginLeft: 14,
  },
  closeIcon: {
    display: 'block',
    height: '27px',
    width: '27px',
    background: theme.palette.framework.system.white,
    border: '1px solid #E2DFDF',
    borderRadius: '15px',
    padding: '3px',
    boxShadow: '0px 0px 6px #00000040',
    cursor: 'pointer',
    transition: '0.3s ease',
    position: 'absolute',
    right: '-10px',
    top: '-10px',
  },
  badge: {
    '& .MuiBadge-colorError': {
      color: '#ffff',
      backgroundColor: theme.palette.system.darkRed,
    },
    '& .MuiBadge-badge ': {
      minWidth: '24px',
      fontSize: '0.60rem',
      height: '17px',
    },
  },
  countBadge: {
    '& .MuiBadge-colorError': {
      color: '#ffff',
      backgroundColor: theme.palette.system.darkRed,
    },
    '& .MuiBadge-badge ': {
      height: '17px',
      fontSize: '0.6rem',
      minWidth: '30px',
    },
  },
  scroll: {
    color: theme.palette.framework.system.doveGray,
    fontSize: '1rem',
    overflowX: 'hidden',
    overflowY: 'auto',
    maxHeight: '75vh',
  },
  filterIcon: {
    marginLeft: 'auto',
    padding: '0px',
    border: '0px',
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    gap: 6,
  },
  smsIcon: {
    padding: '0px',
    border: `1px solid ${theme.palette.system.lightGrey}`,
    color: theme.palette.framework.system.charcoal,
    marginTop: 14,
    maxHeight: 38,
    minWidth: '38px !important',
    '&:hover': {
      background: theme.palette.system.lightblueBackground,
      border: `1px solid ${theme.palette.framework.system.skyBlue}`,
      color: theme.palette.framework.system.skyBlue,
    },
  },
  smsContainer: {
    display: 'flex',
    marginRight: 15,
  },
  fixedPos: {
    position: 'fixed',
  },
  skeleton: { margin: '5px', padding: '10px', width: '370px', height: '70px' },
}));

export const useSelectInboxStyles = makeStyles()((theme: Theme, props) => ({
  avatar: {
    fontSize: 14,
    marginLeft: 12,
    marginRight: 0,
    textWrap: 'nowrap',
    '&:hover': {
      '& #avatarText': {
        display: 'block',
      },
      '& #avatarInitial': {
        display: 'none',
      },
    },
  },
  avatarText: {
    width: 'fit-content',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'none',
  },
  avatarInitial: {
    width: 'fit-content',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    overflow: 'hidden',
  },
  innerWrapper: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  button: {
    flex: '0 auto',
  },
  count: {
    marginLeft: 5,
    borderRadius: '50%',
    fontSize: 11,
    width: 16,
    height: 16,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.framework.system.white,
    textAlign: 'center',
  },
  checkIcon: {
    position: 'absolute',
    right: 20,
    fontSize: 18,
    color: theme.palette.primary.main,
  },
  popper: {
    zIndex: 1300,
    left: 16,
    overflow: 'hidden',
  },
  listAvatar: {
    fontSize: 14,
    marginLeft: 20,
    marginRight: 10,
    textWrap: 'nowrap',
    '&:hover': {
      '& #avatarText': {
        display: 'block',
      },
      '& #avatarInitial': {
        display: 'none',
      },
    },
  },
  listPhone: {
    fontSize: 14,
    marginLeft: '60px',
  },
  menuItem: {
    maxHeight: 45,
  },
  phone: {
    textWrap: 'nowrap',
  },
}));

export const InboxToolbar = styledComponent(Toolbar)`
  border: 1px solid #ccc;
  width: 100%;
  margin-top: 0;
  padding-top: 0;
  border-radius: 10px 10px 0 0;
  background: #f4f5f7;
  border-bottom: 2.5px solid #ccc;
  height: 52px;
`;
export const MessageDivider = styledComponent(Divider)`
  text-align: center;
  background: #ccc;
  width: 100%;
  height: 2px;
`;
