import { placementStage } from '@AMIEWEB/Order/OrderDetails/OrderPreferences/store/OrderPreference.redux';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import baseAxiosInstance from 'utils/BaseApi';

export class FacilityService {
  private facilityBase: string;

  private cancellationToken = axios.CancelToken.source();

  private static currentInstance: FacilityService;

  config?: AxiosRequestConfig = {};

  private constructor() {
    this.facilityBase = globalThis?.app?.env?.REACT_APP_FACILITIES_SERVICE_BASE_URL;
  }

  static createInstance(): FacilityService {
    const activeInstance = new FacilityService();
    // activeInstance.cancellationToken = axios.CancelToken.source();
    // if (activeInstance.config) activeInstance.config.cancelToken = activeInstance.cancellationToken.token;
    return activeInstance;
  }

  static getInstance(): FacilityService {
    if (!this.currentInstance) this.currentInstance = this.createInstance();
    return this.currentInstance;
  }

  static cancelActiveRequests() {
    this.currentInstance.cancellationToken.cancel('RequestCancellation');
    // this.currentInstance = null;
  }

  cancelRequests() {
    this.cancellationToken.cancel('RequestCancellation');
    return FacilityService.getInstance();
  }

  cancelAndRevokeInstance() {
    this.cancellationToken.cancel('RequestCancellation');
    this.cancellationToken = axios.CancelToken.source();
    if (this.config) this.config.cancelToken = this.cancellationToken.token;
  }

  getFacilityPreferences = (facilityId: number, stage: placementStage | null) =>
    baseAxiosInstance
      .get(`${this.facilityBase}/facility/placement-automation/${facilityId}/automation-status`)
      .catch((error: AxiosError) => error.response);

  updateFacilityPreferences = body =>
    baseAxiosInstance
      .post(`${this.facilityBase}/facility/placement-automation/update/external-systems`, body)
      .catch((error: AxiosError) => error.response);

  updateAutomationStatus = body =>
    baseAxiosInstance
      .post(`${this.facilityBase}/facility/placement-automation/set-automation-status`, body)
      .catch((error: AxiosError) => error.response);

  getOffContractRateElements = body =>
    baseAxiosInstance
      .post(`${this.facilityBase}/offcontract/rateelements/search`, body)
      .catch((error: AxiosError) => error.response);

  /**
   * Saves only off contract details to COSMOS DB
   * @param request
   * @returns Off contract details
   */
  saveOffContractDetails = body =>
    baseAxiosInstance
      .post(`${this.facilityBase}/offcontract/offcontract/save`, body)
      .catch((error: AxiosError) => error.response);

  /**
   * Saves only facility off contract rate elemnts along with document IDs to COSMOS DB
   * @param request
   * @returns
   */
  saveFacilityOffContractRateElementDocuments = body =>
    baseAxiosInstance
      .post(`${this.facilityBase}/offcontract/rateelement/update`, body)
      .catch((error: AxiosError) => error.response);

  //   /**
  //  * Saves documents to Blob storgae
  //  * @param request
  //  * @returns
  //  */
  saveDocuments = body =>
    baseAxiosInstance
      .post(`${this.facilityBase}/offcontract/attachment/save`, body)
      .catch((error: AxiosError) => error.response);

  getFacilityDetails = (id: number) =>
    baseAxiosInstance
      .get(`${this.facilityBase}/Facilities/Facility/${id}`)
      .catch((error: AxiosError) => error.response);

  getFacilityTemplate = (id: number) =>
    baseAxiosInstance
      .get(`${this.facilityBase}/facility/${id}/document-template`)
      .catch((error: AxiosError) => error.response);

  updateFacilityTemplate = body =>
    baseAxiosInstance
      .post(`${this.facilityBase}/document-template/update`, body)
      .catch((error: AxiosError) => error.response);

  postUpdateDrugScreenPolicy = body =>
    baseAxiosInstance
      .post(`${this.facilityBase}/facilities/facility/update/drug-screen-policy`, body)
      .catch((error: AxiosError) => error.response);

  postUpdateInternalContacts = body =>
    baseAxiosInstance
      .post(`${this.facilityBase}/facilities/facility/update/internal-contact`, body)
      .catch((error: AxiosError) => error.response);

  getActivePlacementCounts = (facilityId: number) =>
    baseAxiosInstance
      .get(`${this.facilityBase}/Facilities/facility/${facilityId}/active-placement-counts`)
      .catch((error: AxiosError) => error.response);

  getFacilityUnitContacts = (facilityId: number, unitId: number) =>
    baseAxiosInstance.get(`${this.facilityBase}/Units/unit/${facilityId}/${unitId}/client-contacts`);

  updateFacilityReferences = body =>
    baseAxiosInstance
      .post(`${this.facilityBase}/facilities/facility/update/references`, body)
      .catch((error: AxiosError) => error.response);

  saveUnitDetails = body =>
    baseAxiosInstance
      .post(`${this.facilityBase}/units/unit/save-new`, body)
      .catch((error: AxiosError) => error.response);

  updateUnitViewPageFields = body =>
    baseAxiosInstance
      .post(`${this.facilityBase}/units/unit/update/view-page-fields`, body)
      .catch((error: AxiosError) => error.response);

  getUnitActivePlacementCounts = (unitId: number) =>
    baseAxiosInstance
      .get(`${this.facilityBase}/Units/unit/${unitId}/active-placement-counts`)
      .catch((error: AxiosError) => error.response);

  updateUnitPreferences = body =>
    baseAxiosInstance
      .post(`${this.facilityBase}/unit/placement-automation/update/external-systems`, body)
      .catch((error: AxiosError) => error.response);

  getUnitPreferences = (unitId: number) =>
    baseAxiosInstance
      .get(`${this.facilityBase}/unit/placement-automation/${unitId}/automation-status`)
      .catch((error: AxiosError) => error.response);

  getFacilityIdUnit = (facilityId: number) =>
    baseAxiosInstance
      .get(`${this.facilityBase}/facility/placement-automation/${facilityId}/unit-preferences`)
      .catch((error: AxiosError) => error.response);

  updateFacilityPreferencesToUnits = body =>
    baseAxiosInstance
      .post(`${this.facilityBase}/facility/placement-automation/copy-facility-preferences`, body)
      .catch((error: AxiosError) => error.response);
}
