import { PayloadAction } from '@reduxjs/toolkit';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType, TravelExperienceSectionIndex, TravelExperienceSections } from 'app/enums/Common';
import {
  createNewUnit,
  getFacilityDetails,
  getUnitsForFacility,
  searchSkillsets,
  updateStarFacilityClientContacts,
} from 'app/services/FacilityServices/FacilityServices';
import { trackPromise } from 'react-promise-tracker';
import { call, fork, getContext, put, select, takeLatest } from 'redux-saga/effects';
import {
  getEmptyUIOrderObj,
  getInternalNotes,
  getInvalidCertification,
  getInvalidLicenses,
  getListsFromReqTypesAndLookups,
  getValidCertifications,
  validateInternalNotes,
} from '../OrderCreationForm/helper';
import { orderCreationActions } from './OrderCreation.actions';
import {
  selectCreatedUnitId,
  selectCurrentStep,
  selectDisciplineOpts,
  selectEditUnit,
  selectFacilityDetails,
  selectFilterCertificationsDecision,
  selectInternalNotesFlag,
  selectNewOrder,
  selectOrderDetails,
  selectOrderRequirementEditOrderRequrementMode,
  selectOrderRequirementTypes,
  selectProceedToUnitCreation,
  selectSkillCheckListRequrementsMaster,
  selectSpecialtyOpts,
  selectStage,
  selectStep,
  selectSubSpecialtyOpts,
  selectValidPlacementDate,
} from './OrderCreation.selectors';
import {
  ICreateOrder,
  ICreateUnit,
  IEditOrderRequrement,
  IEditUnit,
  IOrderActionButton,
  IOrderCreationStep,
  ISelectUnit,
  IUpdateOrder,
  LastUpdatedBy,
  NonStandardRequirement,
  OrderPriority,
  Shift,
} from './helpers/rateCard/types';

import EMRList from 'app/assets/jsons/EMR.json';
import bestBetsValue from 'app/assets/jsons/OrderCreation/BestBets.json';
import prioritiesData from 'app/assets/jsons/OrderCreation/OrderPriorities.json';
import shiftData from 'app/assets/jsons/OrderCreation/OrderShifts.json';
import defaultSkillCheckLists from 'app/assets/jsons/defaultSkillCheckList.json';

import { createOrderContacts } from '@AMIEWEB/Candidate/CandidateProfile/CandidateHeader/contactTabHelper';
import { FacilityUnitActions } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/UnitTab/Store/FacilityUnit.redux';
import { IFacilityDetails } from '@AMIEWEB/Facility/FacilityStore/Facility.model';
import { facilityActions } from '@AMIEWEB/Facility/FacilityStore/Facility.redux';
import { validNewUnitResponse } from '@AMIEWEB/Unit/UnitUtils';
import { commonVIPriority, getOrderPriorityObject } from '@AMIEWEB/Unit/helper';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { OrderConstants } from 'app/constants/OrderCreationForm';
import { getDynamicSortedData } from 'app/helpers/arrayHelpers';
import { convertToFormat } from 'app/helpers/dateHelper';
import {
  formatTypeAheadDisciplineOptions,
  formatTypeAheadSpecialtyOptions,
  formatTypeAheadSubSpecialtyOptions,
} from 'app/helpers/typeAheadHelper';
import { OrderFacilityDetails } from 'app/models/Facility/FacilityDetails';
import { UnitResponse } from 'app/models/Facility/Unit';
import {
  IAtAGlance,
  IOrderDetails,
  ITypeAheadOption,
  IValidPlacementDate,
  NewOrder,
  NewUIOrder,
  OrderHeaderSection,
  OrderResponse,
  QuickGlance,
  ShiftData,
  ShiftResponse,
  editOrder,
} from 'app/models/Orders/OrderDetails';
import {
  EMRType,
  NewUnit,
  OrderPriorityType,
  QualificationType,
  SkillCheckList,
  SkillSetType,
  TravelExperienceType,
} from 'app/models/Unit/CreateUnit';
import { AMNDivisionType } from 'app/models/enums/AMNDivisionType';
import { getCredentialLookups, getRequirementTypes } from 'app/services/CredentialingServices/CredentialingServices';
import {
  createOrder,
  getOrderDetailsWithPlacements,
  updateOrderDetails,
} from 'app/services/OrderServices/OrderServices';
import { OrderService } from 'app/services/OrderServices/order-service';
import {
  getDisciplineSpecialtyData,
  getSearchFilterOptions,
  getStatesList,
} from 'app/services/SharedServices/SharedServices';
import { httpSuccess } from 'app/services/serviceHelpers';
import { selectUser } from 'oidc/user.selectors';
import uuid from 'react-uuid';
import {
  saveFacilityRateElementDocumentDetails,
  saveOcbrDocs,
  saveOffcontractDetails,
} from 'store/redux-store/create-order-ocbr/saga';
import { selectOcbrDetails } from 'store/redux-store/create-order-ocbr/selector';
import { createOrderOcbrActions } from 'store/redux-store/create-order-ocbr/slice';
import { transformDataForOrderPriorities } from '../OrderCreationForm/Components/UnitDetail/AdditionalInformation/Helper';
import { transformDataForVirtualRatings } from '../OrderCreationForm/Components/UnitDetail/AdditionalInformation/VirtualRatings';
import {
  formatDataForAutoComplete,
  formatDataForTravelExperiences,
} from '../OrderCreationForm/OrderDetails/FormatData';
import { getUniqueShiftIds } from '../OrderCreationForm/RateCard/helper';
import {
  getAllRateElements,
  getFacilityRateElementDocumentsDataToSave,
  getOffContractDataToSave,
} from '../OrderCreationForm/RateCard/saveHelper';
import {
  formatAddress,
  formatDate,
  getDefaultValueIfNull,
  getDistinctCandidate,
  getEmptyFacility,
  getEmptyOrderDetails,
  getName,
  getOrderReasonId,
  getValue,
  removeTimestamp,
  toFixed,
} from '../OrderDetails/helper';
import { IOrderDetailsView, ITypeAheadOptions, orderDataActions } from './Order.redux';
import {
  selectOrderCertification,
  selectOrderDetailsOrderType,
  selectOrderDetailsUIView,
  selectOrderRegions,
} from './Order.selectors';
import { formatOrderRequirements } from './helpers/sagaHelper';
import { ReqOrderFieldNames } from './types';
import i18next from 'i18next';

const FacilityDetailsTrackWrapper = (fn, ...args) => trackPromise(fn(...args), 'CreateOrderFacilityDetails');
const OrderDetailsTrackWrapper = (fn, ...args) => trackPromise(fn(...args), 'EditOrderDetails');
const FacilityUnitsListDetailsTrackWrapper = (fn, ...args) =>
  trackPromise(fn(...args), 'CreateOrderFacilityUnitsDetails');
const RequrementTypesDetailsTrackWrapper = (fn, ...args) =>
  trackPromise(fn(...args), 'CreateOrderRequrementTypesDetails');
const RequrementLookupTypesDetailsTrackWrapper = (fn, ...args) =>
  trackPromise(fn(...args), 'CreateOrderRequrementLookupTypesDetails');
const MiscellaneousApiDetailsTrackWrapper = (fn, ...args) =>
  trackPromise(fn(...args), 'CreateOrderRequrementMiscellaneousApiDetails');
const SkillCheckListRequrementsSearchDetailsTrackWrapper = (fn, ...args) =>
  trackPromise(fn(...args), 'SkillCheckListRequrementsSearchDetails');
const SubmitCreateOrderDetailsTrackWrapper = (fn, ...args) => trackPromise(fn(...args), 'SubmitCreateOrderDetails');
const GetStatesTrackWrapper = (fn, ...args) => trackPromise(fn(...args), 'requestOrderCreationRequirementTypesDetails');

const getEditOrderDetail = (orderDetails: IOrderDetails, unit: NewUnit, facilityData: OrderFacilityDetails) => {
  const updatedOrder: NewUIOrder = getEmptyUIOrderObj(facilityData);
  updatedOrder.id = orderDetails.id;
  updatedOrder.orderId = orderDetails.orderId;
  updatedOrder.unit.unitId = orderDetails.unit.unitId;
  updatedOrder.unit.name = orderDetails.unit.name;
  updatedOrder.name = orderDetails.unit.name;
  updatedOrder.costCenter = unit?.costCenter;
  updatedOrder.floor = unit?.floor;
  updatedOrder.type = orderDetails.orderType;
  updatedOrder.orderTypeId = orderDetails.orderTypeId;
  updatedOrder.bestBetsId = orderDetails.facility.facilityBestBetsId;
  updatedOrder.bestBets = updatedOrder.bestBetsId ? getBestBets(parseInt(`${updatedOrder.bestBetsId}`)) : null;
  if (orderDetails.orderType !== undefined && orderDetails.orderType !== null) {
    if (facilityData.amnDivisionTypeId === AMNDivisionType.localStaffing) {
      updatedOrder.type = facilityData.division;
    }
  }
  updatedOrder.region = orderDetails.location.region;
  updatedOrder.regionId = orderDetails.location.regionId;
  if (updatedOrder.region !== undefined && updatedOrder.region !== null) {
    if (facilityData.amnDivisionTypeId === AMNDivisionType.hsg) {
      updatedOrder.region = 'Unassigned';
    } else if (facilityData.region !== 'AMN Allied') {
      updatedOrder.region = facilityData.region;
    } else {
      updatedOrder.region = '';
    }
  }

  updatedOrder.yearsOfExperience =
    orderDetails.yearsOfExperience ??
    orderDetails.unit.experience?.skillSets.find(s => s.isPrimary)?.yearsOfExperience ??
    0;
  updatedOrder.preferredQualifications = orderDetails.preferredQualifications ?? '';
  updatedOrder.requiredQualifications =
    orderDetails.requiredQualifications ?? orderDetails.submissionRequirements ?? '';
  updatedOrder.internalNotes = orderDetails.internalNotes;
  updatedOrder.jobDescription = orderDetails.jobDescription;
  updatedOrder.newGrad = orderDetails.newGrad || false;
  updatedOrder.skillsets = !!orderDetails.skillsets
    ? orderDetails.skillsets.map(item => {
        return {
          discipline: item.discipline,
          disciplineId: item.disciplineId,
          isRequired: item.isRequired,
          specialty: item.specialty,
          specialtyId: item.specialtyId,
          isPrimary: item.isPrimary,
          subSpecialty: item.subSpecialty,
          subSpecialtyId: item.subSpecialtyId,
          yearsOfExperience: item.yearsOfExperience,
        } as SkillSetType;
      })
    : [];
  updatedOrder.orderPriorities = !!orderDetails.orderPriorities
    ? orderDetails.orderPriorities.map(item => item.description)
    : [];
  updatedOrder.virtualRating = orderDetails.unit.virtualRating;
  updatedOrder.costCenter = orderDetails.unit.costCenter;
  updatedOrder.floor = orderDetails.unit.floor;

  updatedOrder.nurseRatio = orderDetails.unit.nurseRatio;
  updatedOrder.unitSize = orderDetails.unit.unitSize;
  updatedOrder.status = orderDetails.unit.status;
  updatedOrder.fyreIntegration = orderDetails.unit.fyre ?? false;
  updatedOrder.travelExperience = orderDetails.unit.travelExperience;
  updatedOrder.qualification = orderDetails.unit.qualification;

  if (!updatedOrder.qualification) {
    updatedOrder.qualification = {
      activeLicense: false,
      compactLicense: false,
      licenses: [],
      certifications: [],
      skillChecklists: [],
      emr: null,
    };
  } else {
    updatedOrder.qualification = {
      ...orderDetails.unit.qualification,
      activeLicense: orderDetails.unit.qualification.activeLicense ?? false,
      compactLicense: orderDetails.unit.qualification.compactLicense ?? false,
    };
  }
  updatedOrder.emr = updatedOrder.qualification.emr;

  updatedOrder.nurse = orderDetails.unit.nurse;
  updatedOrder.patient = orderDetails.unit.patient;
  updatedOrder.reference = orderDetails.unit.reference;
  updatedOrder.float = orderDetails.unit.float;
  updatedOrder.floatSelected = orderDetails.unit.float !== undefined && orderDetails.unit.float !== null;
  updatedOrder.flexibleSchedule = orderDetails.unit.flexibleSchedule;
  updatedOrder.nonStandardRequirement = orderDetails.unit.nonStandardRequirement;

  return updatedOrder;
};

const getNewOrderDetail = (unit: NewUnit, facilityData: OrderFacilityDetails) => {
  const updatedOrder = getEmptyUIOrderObj(facilityData);

  const defaultOrderPriorities = getOrderPriorities({
    bedSize: facilityData.bedSize,
    teaching: facilityData.isTeaching,
    magnetStatus: facilityData.isMagnetStatus,
    traumaCenter: facilityData.isTraumaCenter,
  });

  updatedOrder.unit.unitId = unit.unitId;
  updatedOrder.unit.name = unit.name;
  if (facilityData.amnDivisionTypeId === AMNDivisionType.localStaffing) {
    updatedOrder.type = facilityData.division;
  }

  if (facilityData.amnDivisionTypeId === AMNDivisionType.hsg) {
    updatedOrder.region = 'Unassigned';
  } else if (facilityData.region !== 'AMN Allied') {
    updatedOrder.region = facilityData.region;
  } else {
    updatedOrder.region = '';
  }

  updatedOrder.yearsOfExperience =
    unit.travelExperience?.yearsOfExperience ??
    unit.experience?.skillSets.find(s => s.isPrimary)?.yearsOfExperience ??
    0;
  updatedOrder.preferredQualifications = unit.preferredQualifications ?? '';
  updatedOrder.requiredQualifications = unit.requiredQualifications ?? '';
  updatedOrder.internalNotes = unit.internalNotes;
  updatedOrder.jobDescription = unit.externalJobDescription;
  updatedOrder.newGrad = (unit.experience && unit.experience.isNewGrad) || false;
  updatedOrder.skillsets = (unit.experience && unit.experience.skillSets) || [];
  updatedOrder.orderPriorities =
    unit.createdByVersion === '2.0' ? unit.orderPriorities.map(x => x.description) : defaultOrderPriorities;
  updatedOrder.virtualRating = unit.virtualRating;
  updatedOrder.costCenter = unit.costCenter;
  updatedOrder.floor = unit.floor;

  updatedOrder.nurseRatio = unit.nurseRatio;
  updatedOrder.unitSize = unit.unitSize;
  updatedOrder.status = unit.status;
  updatedOrder.fyreIntegration = unit.fyreIntegration;
  updatedOrder.travelExperience = unit.travelExperience;
  updatedOrder.qualification = unit.qualification;

  if (!updatedOrder.qualification) {
    updatedOrder.qualification = {
      activeLicense: false,
      compactLicense: false,
      licenses: [],
      certifications: [],
      skillChecklists: [],
      emr: null,
    };
  } else {
    updatedOrder.qualification = {
      ...updatedOrder.qualification,
      activeLicense: updatedOrder.qualification.activeLicense ?? false,
      compactLicense: updatedOrder.qualification.compactLicense ?? false,
    };
  }
  updatedOrder.emr = updatedOrder.qualification.emr;

  updatedOrder.nurse = unit.nurse;
  updatedOrder.patient = unit.patient;
  updatedOrder.bestBetsId = unit.bestBetsId;
  updatedOrder.bestBets = unit.bestBets;
  updatedOrder.reference = {
    isRequired: unit?.reference.isRequired,
    numberOfReferenceRequired: unit?.reference?.numberOfReferenceRequired,
    numberOfReferenceToRFO: unit?.reference?.numberOfReferenceToRFO,
    withinMonths: unit?.reference?.withinMonths ? unit?.reference?.withinMonths : false,
    referenceFromSupervisor: unit?.reference?.referenceFromSupervisor
      ? unit?.reference?.referenceFromSupervisor
      : false,
  };
  updatedOrder.float = unit.float;
  updatedOrder.floatSelected = unit.float !== undefined && unit.float !== null;
  updatedOrder.flexibleSchedule = unit.flexibleSchedule;
  updatedOrder.nonStandardRequirement = unit.nonStandardRequirement;

  return updatedOrder;
};

const getNewUnitDetail = (facilityData: OrderFacilityDetails) => {
  return {
    facilityId: facilityData.facilityId,
    facilityState: facilityData.location.state,
    firstDayInstructions: facilityData.firstDayInstructions,
    bestBets: 'DIR- Vac Not Required',
    bestBetsId: 33,
    costCenter: null,
    costCenterId: null,
    createdByVersion: '2.0',
    currentUserId: null,
    experience: {
      isNewGrad: false,
      skillSets: [],
    },
    externalJobDescription: null,
    flexibleSchedule: null,
    float: null,
    floor: null,
    fyreIntegration: false,
    internalNotes: null,
    lastUpdated: null,
    lastUpdatedBy: null,
    name: null,
    nonStandardRequirement: [],
    nurseRatio: null,
    nurse: null,
    patient: null,
    orders: 0,
    orderPriorities: [],
    positionsAvailable: 0,
    preferredQualifications: null,
    emr: null,
    qualification: {
      activeLicense: false,
      compactLicense: false,
      certifications: [
        {
          abbreviation: 'BLS',
          documentAttachmentRequired: false,
          isRequired: true,
          requirementTypeId: 47,
        },
      ],
      skillChecklists: [],
      emr: null,
    },
    reference: {
      isRequired: false,
      numberOfReferenceRequired: 0,
      numberOfReferenceToRFO: 0,
      withinMonths: false,
      referenceFromSupervisor: false,
    },
    requiredQualifications: null,
    standardizedRequirements: true,
    status: 'Active',
    statusId: 0,
    travelExperience: null,
    unitId: null,
    unitSize: null,
    virtualRating: [],
    unitContacts: [],
  } as ICreateUnit;
};

const getEditUnitDetail = (
  editUnit: NewUnit,
  facilityData: OrderFacilityDetails,
  editMode,
  remberMode,
  isNewUnitPage: boolean,
) => {
  const nonStandardRequirement: NonStandardRequirement[] = [];
  editUnit.nonStandardRequirement?.map(x => {
    nonStandardRequirement.push({
      category: x.category,
      isRequired: x.isRequired,
      documentAttachmentRequired: x.isRequired,
      value: x.value,
      yearsOfExperience: x.yearsOfExperience,
    });
  });

  /*const orderPriorityTypeTemp: OrderPriority[] = [];
  const prioritiesUpdatedX = getSubmitOrderPriorities(editUnit.orderPriorities.map(x =>x.description) ?? []);
  prioritiesUpdatedX.map(item => {
    orderPriorityTypeTemp.push({
      orderPriorityTypeId: item.ID,
      description: item.Description,
    });
  });*/

  return {
    facilityId: facilityData.facilityId,
    facilityState: facilityData.location.state,
    bestBets: !!editUnit.bestBetsId ? editUnit.bestBets : 'DIR- Vac Not Required',
    bestBetsId: !!editUnit.bestBetsId ? editUnit.bestBetsId : 33,
    costCenter: editUnit.costCenter,
    costCenterId: editUnit.costCenterId,
    createdByVersion: editMode === 'standardized' || remberMode === true ? '2.0' : editUnit.createdByVersion,
    currentUserId: editUnit.currentUserId,
    experience: !!editUnit.experience
      ? {
          isNewGrad: editUnit.experience.isNewGrad,
          skillSets: editUnit.experience.skillSets,
        }
      : {
          isNewGrad: false,
          skillSets: [],
        },
    newGrad: !!editUnit.experience ? editUnit.experience.isNewGrad : false,
    skillsets: !!editUnit.experience ? editUnit.experience.skillSets : [],
    yearsOfExperience: editUnit.yearsOfExperience,
    externalJobDescription: editUnit.externalJobDescription,
    flexibleSchedule: editUnit.flexibleSchedule,
    float: editUnit.float,
    floor: editUnit.floor,
    fyreIntegration: editUnit.fyreIntegration ?? false,
    internalNotes: editUnit.internalNotes,
    lastUpdated: editUnit.lastUpdated,
    lastUpdatedBy: {
      email: editUnit.lastUpdatedBy.email,
      employeeId:
        !!editUnit.lastUpdatedBy.id && Number.isInteger(editUnit.lastUpdatedBy.id)
          ? Number(editUnit.lastUpdatedBy.id)
          : 0,
      firstName: editUnit.lastUpdatedBy.firstName,
      lastName: editUnit.lastUpdatedBy.lastName,
      phoneNumber: editUnit.lastUpdatedBy.phoneNumber,
    },
    name: editUnit.name,
    nonStandardRequirement: nonStandardRequirement,
    nurseRatio: editUnit.nurseRatio,
    nurse: !!editUnit.nurseRatio && editUnit.nurseRatio.includes(':') ? editUnit.nurseRatio.split(':')[0] : 0,
    patient: !!editUnit.nurseRatio && editUnit.nurseRatio.includes(':') ? editUnit.nurseRatio.split(':')[1] : 0,
    orders: editUnit.orders,
    orderPriorities: isNewUnitPage
      ? transformDataForOrderPriorities(editUnit.orderPriorities)
      : editUnit.orderPriorities?.map(x => x.description) ?? [],
    positionsAvailable: editUnit.positionsAvailable,
    preferredQualifications: editUnit.preferredQualifications,
    qualification: editUnit.qualification,
    emr: !!editUnit.qualification ? editUnit.qualification.emr : null,
    reference: editUnit.reference,
    requiredQualifications: editUnit.requiredQualifications,
    standardizedRequirements: editMode === 'standardized' ? true : editUnit.standardizedRequirements,
    ssn: editUnit.ssn,
    dob: editUnit.dob,
    status: editUnit.status,
    statusId: editUnit.statusId,
    travelExperience: editUnit.travelExperience,
    unitId: editUnit.unitId,
    unitSize: editUnit.unitSize,
    virtualRating: isNewUnitPage ? transformDataForVirtualRatings(editUnit.virtualRating) : editUnit.virtualRating,
    submissionRequirement: editUnit.submissionRequirement,
    unitContacts: editUnit.unitContacts,
    firstDayInstructions: editUnit.firstDayInstructions,
    patientsSeen: editUnit.rangeOfPatientsSeen,
    numberOfVisits: editUnit.rangeOfVisits,
    workHistoryGap: {
      withinTheLastDays: editUnit?.withinTheLastDays,
      withinTheLastYears: editUnit?.withinTheLastYears,
      entireWorkHistory: editUnit?.entireWorkHistory,
    },
  };
};

function hasProperty<T>(obj: T, property: keyof T): boolean {
  return typeof obj === 'object' && obj.hasOwnProperty(property);
}

const getNewOrderEditRequrementProcessBaseDetail = (orderDetails: NewUIOrder, data: any) => {
  const updatedOrder = { ...orderDetails };
  //
  updatedOrder.requiredQualifications = data.requiredQualifications;
  updatedOrder.preferredQualifications = data.preferredQualifications;
  updatedOrder.newGrad = data.newGrad;
  updatedOrder.skillsets = data.skillsets;
  updatedOrder.travelExperience = data.travelExperience;
  updatedOrder.qualification = data.qualification;

  if (!updatedOrder.qualification) {
    updatedOrder.qualification = {
      activeLicense: false,
      compactLicense: false,
      certifications: [],
      skillChecklists: [],
      licenses: [],
      emr: null,
    };
  } else {
    updatedOrder.qualification = {
      ...updatedOrder.qualification,
      activeLicense: updatedOrder.qualification.activeLicense ?? false,
      compactLicense: updatedOrder.qualification.compactLicense ?? false,
    };
  }
  updatedOrder.qualification.emr = data.emr;
  updatedOrder.emr = data.emr;

  updatedOrder.jobDescription = data.jobDescription;
  updatedOrder.internalNotes = data.internalNotes;
  updatedOrder.virtualRating = data.virtualRating;
  updatedOrder.orderPriorities = data.orderPriorities;
  updatedOrder.bestBetsId = data.bestBetsId;
  updatedOrder.unitSize = data.unitSize;
  updatedOrder.reference = data.reference;
  updatedOrder.float = hasProperty(data, 'float') ? data.float : null;
  updatedOrder.flexibleSchedule = hasProperty(data, 'flexibleSchedule') ? data.flexibleSchedule : null;
  updatedOrder.nonStandardRequirement = hasProperty(data, 'nonStandardRequirement') ? data.nonStandardRequirement : [];

  return updatedOrder;
};

const getNewOrderInformationProcessBaseDetail = (
  orderDetails: NewUIOrder,
  data: any,
  orderTypes: { ID: number; Description: string }[],
) => {
  const updatedOrder = { ...orderDetails };
  //
  updatedOrder.positionsAvailable = data.positionsAvailable;
  updatedOrder.type = data.type;
  const orderType = orderTypes.filter(x => x.Description === data.type);
  if (Array.isArray(orderType) && orderType.length > 0) {
    updatedOrder.orderTypeId = orderType[0].ID;
  }
  updatedOrder.region = data.region;
  updatedOrder.shifts = data.shifts;
  updatedOrder.endDate = data.endDate;
  updatedOrder.startDate = data.startDate;
  updatedOrder.placementLength = data.placementLength;
  updatedOrder.ASAP = data.ASAP;
  updatedOrder.flexibleDates = data.flexibleDates;
  updatedOrder.expectedHours = data.expectedHours;
  updatedOrder.guaranteedHours = data.guaranteedHours;
  updatedOrder.maxFilesSent = data.maxFilesSent;
  updatedOrder.reasonForMaxFilesSent = data.maxFilesSent ? data.reasonForMaxFilesSent : null;
  updatedOrder.VMSOrderNumber = data.VMSOrderNumber;
  updatedOrder.weblink = data.weblink;
  updatedOrder.orderReason = data.orderReason;
  updatedOrder.hideFacilityDescription = data.hideFacilityDescription;
  updatedOrder.suppressExternalPosting = data.suppressExternalPosting;
  updatedOrder.CF = data.CF;
  updatedOrder.negotiableRate = data.negotiableRate;
  updatedOrder.orderBillRate = data.orderBillRate;
  updatedOrder.addBillRateRange = data.addBillRateRange;
  updatedOrder.nonContractedBillRates = hasProperty(data, 'nonContractedBillRates')
    ? data.nonContractedBillRates ?? []
    : [];

  return updatedOrder;
};

const getFacilityDetailsFromOrderDetails = (response: IOrderDetails) => {
  const emptyFacility = getEmptyFacility();
  const newFacilityData = {
    ...emptyFacility,
    facilityResponse: {
      ...emptyFacility.facilityResponse,
      ...response.facility,
      facilityName: response.facility.name ? response.facility.name : '-',
      healthSystemName: response.unit.name,
      bestBets: response.unit?.bestBets,
      bestBetsId: response.facility.facilityBestBetsId,
      employeeContacts: {
        recruiter: {
          userId: response.contacts.recruiter?.userId,
          firstName: response.contacts.recruiter?.firstName,
          lastName: response.contacts.recruiter?.lastName,
        },
        accountManager: {
          userId: response.contacts.accountManager.userId,
          firstName: response.contacts.accountManager.firstName,
          lastName: response.contacts.accountManager.lastName,
        },
        regionalDirector: {
          userId: response.contacts.regionalDirector.userId,
          firstName: response.contacts.regionalDirector.firstName,
          lastName: response.contacts.regionalDirector.lastName,
        },
        credentialingAnalyst: {
          userId: response.contacts.credentialingAnalyst?.userId,
          firstName: response.contacts.credentialingAnalyst?.firstName,
          lastName: response.contacts.credentialingAnalyst?.lastName,
        },
        clinicalManager: {
          userId: response.contacts.clinicalManager?.userId,
          firstName: response.contacts.clinicalManager?.firstName,
          lastName: response.contacts.clinicalManager?.lastName,
        },
      },
      location: response.location,
      facilityStatus: response.status,
      division: response.division,
    },
  } as IFacilityDetails;
  return newFacilityData;
};

const flattenHeaderData = (response: IOrderDetails) => {
  return {
    orderID: response.orderId !== '' ? response.orderId : 'XXXXXX',
    facilityName: response.facility.name ? response.facility.name : '-',
    facilityId: response.facility.facilityId,
    unit: response.unit.name,
    location: response.location
      ? formatAddress(response.location)
      : {
          addressLine1: null,
          addressLine2: 'N/A',
          phoneNumber: null,
        },
    skillSet: response.skillsets,
    lastVerified: response.dateVerified ? response.dateVerified : 'N/A',
    verifiedBy: response.contacts?.verifiedBy ? getName(response.contacts.verifiedBy) : 'N/A',
    accountManager: response.contacts ? getName(response.contacts.accountManager) : 'N/A',
    regionalDirector: response.contacts ? getName(response.contacts.regionalDirector) : 'N/A',
    type: response.orderType,
    division: response.division,
    divisionId: response.divisionId,
    brandName: response.brandinfo ? response.brandinfo[0].brandName : [],
    contacts: createOrderContacts(response?.contacts),
    status: response.status || 'N/A',
  } as OrderHeaderSection;
};

const flattenQuickGlanceData = (response: IOrderDetails, jobMatchres?: any) => {
  return {
    orderId: response.orderId,
    positionsAvailable: response.positionsAvailable ? response.positionsAvailable : '0',
    startDate: response.startDate ? convertToFormat(response.startDate || null, 'MM/DD/yyyy') : 'N/A',
    placementLength: response.numOfWeeks ? response.numOfWeeks + ' Weeks' : 'N/A',
    maxFilesSent: getValue(response.maxFilesSent),
    guaranteedWorkWeek: getValue(response.gww),
    activeBonus: getValue(response.activeBonus),
    weeklyGrossPay:
      response.weeklyGrossPay === null ? 'N/A' : response.weeklyGrossPay === 0 ? '0' : '$' + response.weeklyGrossPay,
    billRate: response.orderBillRate ? '$' + toFixed(response.orderBillRate, 2) : 'N/A',
    jobMatches: jobMatchres ? getDistinctCandidate(jobMatchres) : '0',
  } as QuickGlance;
};

const flattenDetails = (response: IOrderDetails) => {
  return {
    dateVerified: response.dateVerified ? formatDate(response.dateVerified, 'lastVerifiedFormat') : '-',
    verifiedBy: response.contacts.verifiedBy ? getName(response.contacts.verifiedBy) : '-',
    positionsAvailable: response.positionsAvailable,
    startDate: response.startDate ? formatDate(response.startDate, 'lastVerifiedFormat') : '-',
    endDate: response.endDate ? formatDate(response.endDate, 'lastVerifiedFormat') : '-',
    declineFiles: response.declineFiles,
    orderDecrementReasonID: response.orderDecrementReasonID,
    placementLength: response.numOfWeeks + '',
    flexibleDates: response.flexibleDates,
    asap: response.asap,
    dateCreated: response.dateCreated ? formatDate(response.dateCreated) : '-',
    maxFilesSent: response.maxFilesSent,
    reasonForMaxFilesSent: getDefaultValueIfNull(response.reasonForMaxFilesSent),
    maxFilesSentdate: response.lastUpdatedMaxFilesSent ? formatDate(response.lastUpdatedMaxFilesSent) : '-',
    orderScore: response.orderScore ? response.orderScore + '' : '-',
    businessRelationship: response.facility.businessRelationship ?? '-',
    region: response.location ? response.location.region : '-',
    payCycle: getDefaultValueIfNull(response.payCycle),
    fees: response.fees + '%',
    radiusRules: response.radiusRules,
    hideFacilityDescription: response.hideFacilityDescription,
    suppressExternalPosting: response.suppressExternalPosting,
    clinicalFellowship: response.clinicalFellowship,
    expectedHours: getDefaultValueIfNull(response.expectedHours),
    guaranteedHours: getDefaultValueIfNull(response.guaranteedHours),
    orderReason: response.orderReasonId,
    nurseToPatientRatio: response.unit.nurseRatio ?? '-',
    unitSize: response.unit.unitSize ?? '-',
    costCenter: response.unit.costCenter ?? '-',
  } as IAtAGlance;
};

const generateFlattenDetails = (orderDetailsViewTemp: IOrderDetailsView) => {
  orderDetailsViewTemp.facilityDetails = getFacilityDetailsFromOrderDetails(orderDetailsViewTemp.orderDetails);
  orderDetailsViewTemp.headerDetails = flattenHeaderData(orderDetailsViewTemp.orderDetails);
  orderDetailsViewTemp.quickGlanceDetails = flattenQuickGlanceData(
    orderDetailsViewTemp.orderDetails,
    orderDetailsViewTemp.orderResponse.candidatePreferenceMatch,
  );
  orderDetailsViewTemp.atAGlance = flattenDetails(orderDetailsViewTemp.orderDetails);
  return orderDetailsViewTemp;
};

const getUnitDetailsFromOrderDetails = (unit: NewUnit, orderDetails: IOrderDetails) => {
  const skillSetTemp: SkillSetType[] = [];
  orderDetails.skillsets?.map(item => {
    skillSetTemp.push({
      discipline: item.discipline,
      disciplineId: item.disciplineId,
      isRequired: item.isRequired,
      specialty: item.specialty,
      specialtyId: item.specialtyId,
      isPrimary: item.isPrimary,
      subSpecialty: item.subSpecialty,
      subSpecialtyId: item.subSpecialtyId,
      yearsOfExperience: item.yearsOfExperience,
    } as SkillSetType);
  });
  const unitCopy = { ...unit };
  unitCopy.experience = {
    isNewGrad: orderDetails.newGrad,
    skillSets: skillSetTemp,
  };
  unitCopy.externalJobDescription = orderDetails.jobDescription ?? '';
  unitCopy.internalNotes = orderDetails.internalNotes ?? '';
  unitCopy.reference = orderDetails.unit.reference ?? {
    isRequired: false,
    numberOfReferenceRequired: 0,
    numberOfReferenceToRFO: 0,
    withinMonths: false,
    referenceFromSupervisor: false,
  };
  unitCopy.travelExperience = orderDetails.unit.travelExperience;
  unitCopy.qualification = orderDetails.unit.qualification;
  const orderPriorityTypeTemp: OrderPriorityType[] = [];
  orderDetails.orderPriorities?.map(item => {
    orderPriorityTypeTemp.push({
      id: item.orderPriorityTypeId,
      description: item.description,
    });
  });
  unitCopy.orderPriorities = orderPriorityTypeTemp;
  unitCopy.float = orderDetails.unit.float;
  unitCopy.flexibleSchedule = orderDetails.unit.flexibleSchedule;
  unitCopy.nonStandardRequirement = orderDetails.unit.nonStandardRequirement;
  return unitCopy;
};

const getOrderPriorities = facilityData => {
  const defaultPriorities: string[] = [];
  for (var property in facilityData) {
    if (facilityData[property]) {
      defaultPriorities.push(OrderConstants.OrderPrioritiesLabel[property]);
    }
  }
  return defaultPriorities;
};

const getSubmitOrderTypeId = (type: string, orderTypes: { ID: number; Description: string }[]) => {
  const orderTypeId = orderTypes.filter(item => item.Description === type);
  return orderTypeId[0]?.ID;
};

const handleInternalNotesStringNullCondition = str => {
  if (str === null || str === '' || str === undefined) return '';
  return `${str}\n`;
};

const getSubmitOrderShifts = (shifts: string[]) => {
  const shiftUpdateData = shifts.map(shift => {
    return shiftData.filter((item: ShiftData) => {
      if (item.Description === shift) {
        return item.Id;
      }
    })[0];
  });
  return shiftUpdateData;
};

const getSubmitOrderPriorities = (priorities: string[]) => {
  const orderPriorities = [];
  priorities.map(priority => {
    const found = prioritiesData.find(item => {
      return priority === item.Description;
    });
    if (found !== undefined && found !== null) {
      orderPriorities.push(found);
    }
  });
  return orderPriorities;
};

const toOrderDetails = (
  order: NewUIOrder,
  selectedNewUnit: NewUnit,
  facilityData: OrderFacilityDetails,
  disciplineOpts: ITypeAheadOption[],
  regions: { ID: number; Description: string }[],
  orderTypes: { ID: number; Description: string }[],
) => {
  const defaultOrderPriorities = getOrderPriorities({
    bedSize: facilityData.bedSize,
    teaching: facilityData.isTeaching,
    magnetStatus: facilityData.isMagnetStatus,
    traumaCenter: facilityData.isTraumaCenter,
  });

  const selectedUnitOrderPriorities = selectedNewUnit?.orderPriorities?.map(item => {
    return {
      ID: item.id,
      Description: item.description,
    };
  });
  const newOrderPriorities =
    order?.orderPriorities && order?.orderPriorities.filter(val => !defaultOrderPriorities.includes(val));
  const prioritiesUpdatedX = getSubmitOrderPriorities(newOrderPriorities);
  const prioritiesUpdated = selectedUnitOrderPriorities?.concat(prioritiesUpdatedX);
  const regionUpdated = regions && regions.filter(item => item.Description === order.region);
  const emptyOrder = getEmptyOrderDetails();

  const newOrderDetails = {
    ...emptyOrder,
    ...order,
    orderId: 'XXXXXX',
    location: {
      ...emptyOrder.location,
      address1: facilityData?.location.address1,
      city: facilityData?.location.city,
      state: facilityData?.location.state,
      zipCode: facilityData?.location.zipCode,
      region: order.region,
      regionId: regionUpdated[0]?.ID,
    },
    skillsets: order.skillsets,
    unit: {
      ...order.unit,
      ...selectedNewUnit,
      experience: {
        isNewGrad: order?.newGrad,
        skillSets: order?.skillsets,
      },
      travelExperience: order?.travelExperience,
      nonStandardRequirement: order?.nonStandardRequirement,
      qualification: {
        ...order?.qualification,
        emr: order.emr,
      },
      fyre: selectedNewUnit?.fyreIntegration,
    },
    division: facilityData.division,
    bestBets: facilityData.bestBets,
    clinicalFellowship: order.CF,
    vmsOrderNumber: order.VMSOrderNumber,
    webLink: order.weblink,
    numOfWeeks: order.placementLength,
    shifts: order.shifts.map((item, index) => {
      return {
        id: index,
        description: item,
      } as ShiftResponse;
    }),
    orderPriorities: prioritiesUpdated?.map(priority => {
      return {
        orderPriorityTypeId: priority.ID,
        description: priority.Description,
      };
    }),
    weeklyGrossPay: null,
    orderReasonId: getOrderReasonId(order.orderReason || ''),
    orderTypeId: getSubmitOrderTypeId(order.type, orderTypes),
    orderType: order.type,
    internalNotes: `${
      selectedNewUnit
        ? handleInternalNotesStringNullCondition(selectedNewUnit.internalNotes)
        : handleInternalNotesStringNullCondition(order.internalNotes)
    }${order ? getInternalNotes(order) : ''}`,
    onCallRate: order.onCallRate ? +order.onCallRate : 0,
    orderBillRate: order.orderBillRate ? parseFloat(order.orderBillRate) : 0,
    nonContractedBillRates: order.nonContractedBillRates || [],
    placementLength: +order.placementLength,
    gww: null,
    activeBonus: null,
    contacts: {
      recruiter: null,
      accountManager: {
        userId: facilityData?.employeeContacts?.hospitalAccountManager?.userId,
        firstName: facilityData?.employeeContacts?.hospitalAccountManager?.firstName,
        lastName: facilityData?.employeeContacts?.hospitalAccountManager?.lastName,
        email: '',
      },
      regionalDirector: {
        userId: facilityData?.employeeContacts?.regionalDirector.userId,
        firstName: facilityData?.employeeContacts?.regionalDirector.firstName,
        lastName: facilityData?.employeeContacts?.regionalDirector.lastName,
        email: '',
      },
      credentialingAnalyst: null,
      clinicalManager: null,
      verifiedBy: null,
    },
    currentUserId: facilityData.currentUserId,
    submissionRequirements: order?.requiredQualifications,
    preferredQualifications: order?.preferredQualifications,
    jobDescription: order?.jobDescription,
    qualification: {
      ...order?.qualification,
      emr: order.emr,
    },
  } as IOrderDetails;

  newOrderDetails.requiredQualifications = formatOrderRequirements(newOrderDetails.unit, true);
  newOrderDetails.preferredQualifications = formatOrderRequirements(newOrderDetails.unit, false);
  return newOrderDetails;
};

const getBestBets = (bestBetsId: number) => {
  const bets = bestBetsValue.filter(x => x.ID === bestBetsId)[0];
  return bets.Description;
};

const getPrioritiesFromvirtualRating = (
  priorities: OrderPriorityType[],
  virtualRatings: string[],
): OrderPriorityType[] => {
  const viPriorities: string[] = [...virtualRatings];

  if (virtualRatings.length > 0 && !virtualRatings.includes(commonVIPriority)) viPriorities.push(commonVIPriority);

  priorities = [
    ...priorities.filter(x => x.id < 30 || (x.id > 38 && x.id !== 45)),
    ...getOrderPriorityObject(viPriorities),
  ];
  return priorities;
};

const defaultSkillCheckListsUnique = (arr, prop) => {
  const set = new Set();
  return arr.filter(o => !set.has(o[prop]) && set.add(o[prop]));
};

const getDefaultCheckLists = division => {
  if (division === AMNDivisionType.amnNurse) return defaultSkillCheckLists.filter(scl => scl.forTravel);
  if (division === AMNDivisionType.allied) return defaultSkillCheckLists.filter(scl => !scl.forTravel);
  if (division === AMNDivisionType.hsg) return defaultSkillCheckListsUnique(defaultSkillCheckLists, 'text');

  return [];
};

const getSelectionInfo = results => {
  const emrNamesSet = new Set(EMRList.map(emr => emr.name));
  return results.filter(search => !emrNamesSet.has(search.text));
};

const mapData = (unitSkillCheckList: any, selectionInfo: SkillCheckList[]) => {
  unitSkillCheckList?.map(item => {
    const newItem = selectionInfo.find(d => d.questionId === item.questionId);
    selectionInfo = selectionInfo.filter(d => d.questionId !== item.questionId) as SkillCheckList[];
    selectionInfo.push({
      shortDesc: newItem ? newItem?.shortDesc : '',
      description: newItem ? newItem?.description : '',
      text: item?.text,
      questionId: item?.questionId,
      isRequired: item?.isRequired,
      yearsOfExperience: item?.yearsOfExperience === '' ? '0' : item?.yearsOfExperience,
      isSelected: true,
      partitionKey: item?.partitionKey,
      id: item?.id,
    });
  });
  return selectionInfo;
};

export function* requestOrderCreationFacilityDetails(action: PayloadAction<{ id: number }>) {
  try {
    const orderService: OrderService = yield getContext('orderService');
    const facilityDetails = yield select(selectFacilityDetails);
    if (!!facilityDetails && !!facilityDetails.facilityId && facilityDetails.facilityId === action.payload.id) {
      return;
    }
    const response = yield call(FacilityDetailsTrackWrapper, getFacilityDetails, action.payload.id);
    yield put(
      orderCreationActions.setFacilityDetails({
        ...response,
        facilityAddress: response.location
          ? {
              line1: response.location
                ? `${response.location.address1} ${response.location.city}, ${response.location.city}, ${response.location.state}, ${response.location.zipCode}`
                : null,
              line2: response.location.address2,
            }
          : {},
      }),
    );
    yield put(orderCreationActions.setFacilityId(action.payload.id));

    const openOrdersCount = yield call(orderService.getOpenOrdersCountForFacility, action.payload.id);

    yield put(
      facilityActions.setFacilityData({
        response,
        openOrdersCount,
      } as any),
    );

    /*yield call(requestGetFacilityDetailsWithOpenOrderCount, {
      payload: { id: action.payload.id },
      type: facilityActions.getFacilityDetailsWithOpenOrderCountAction.type
    });*/
  } catch (error) {
    console.log('error requestOrderCreationFacilityDetails', error);
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestOrderCreationFacilityDetails',
        area: 'src/app/components/Order/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* requestOrderEditSummaryDetails(action: PayloadAction<{ id: number }>) {
  try {
    let orderDetailsViewTemp = {} as IOrderDetailsView;
    const response: OrderResponse = yield call(
      OrderDetailsTrackWrapper,
      getOrderDetailsWithPlacements,
      action.payload.id,
    );
    const facilityId = response.orderDetails.facility.facilityId;
    const unitIdLc = response.orderDetails.unit.unitId;
    //get facility details
    yield call(requestOrderCreationFacilityDetails, {
      payload: { id: facilityId },
      type: orderCreationActions.getOrderCreationFacilityDetailsAction.type,
    }); //call another saga method async

    //get units for facility
    yield call(requestOrderCreationFacilityUnitDetails, {
      payload: { id: facilityId },
      type: orderCreationActions.getOrderCreationFacilityUnitDetailsAction.type,
    }); //call another saga method sync

    const creationProcessSteps = (yield select(selectStep)) as IOrderCreationStep;
    const availableFacilityUnits: NewUnit[] = creationProcessSteps.selectUnit.availableFacilityUnits;
    const unitsData: UnitResponse[] = availableFacilityUnits
      ? (availableFacilityUnits.map(x => {
          return { name: x.name, unitId: x.unitId };
        }) as UnitResponse[])
      : [];
    const unitsFilterd = availableFacilityUnits?.filter(unit => unit.unitId === unitIdLc);
    const orderDetails: IOrderDetails = response.orderDetails;

    //1 credit hold || 3 cred pending
    if (response.orderDetails.facility.creditStatusId === 1) {
      orderDetailsViewTemp.creditStatus = true;
      orderDetailsViewTemp.pendingStatus = false;
    } else if (response.orderDetails.facility.creditStatusId === 3) {
      orderDetailsViewTemp.creditStatus = false;
      orderDetailsViewTemp.pendingStatus = true;
    } else {
      orderDetailsViewTemp.creditStatus = false;
      orderDetailsViewTemp.pendingStatus = false;
    }

    orderDetailsViewTemp.orderResponse = response;
    orderDetailsViewTemp.orderDetails = orderDetails;
    orderDetailsViewTemp.orderDetailsOrginal = { ...orderDetails };
    orderDetailsViewTemp.facilityId = facilityId;
    orderDetailsViewTemp = generateFlattenDetails(orderDetailsViewTemp);
    orderDetailsViewTemp.selectedNewUnitId = unitIdLc;
    orderDetailsViewTemp.currentStep = 'ViewOrderDetails';
    orderDetailsViewTemp.isEditStarted = false;
    orderDetailsViewTemp.editMode = 'inline';
    orderDetailsViewTemp.unitsData = unitsData;
    if (unitsFilterd && unitsFilterd.length > 0) {
      const unit = getUnitDetailsFromOrderDetails(unitsFilterd[0] as NewUnit, orderDetails);
      orderDetailsViewTemp.selectedNewUnit = unit;
      yield put(orderCreationActions.setOrderUnit(unit));
      yield put(orderCreationActions.setHoverdFacilityUnit(unit));
    }
    yield put(orderDataActions.setOrderDetailsView(orderDetailsViewTemp));
    yield put(orderDataActions.setNewUnit(orderDetailsViewTemp.selectedNewUnit));
    yield put(orderDataActions.setCurrentUnitId(orderDetailsViewTemp.selectedNewUnitId));

    //get Miscellaneous details
    yield call(requestOrderCreationMiscellaneousApiDetails); //call another saga method sync
  } catch (error) {
    console.log('error requestOrderEditSummaryDetails', error);
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestOrderEditSummaryDetails',
        area: 'src/app/components/Order/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* requestOrderCreationFacilityUnitDetails(action: PayloadAction<{ id: number }>) {
  try {
    const facilityUnitResponse = yield call(
      FacilityUnitsListDetailsTrackWrapper,
      getUnitsForFacility,
      action.payload.id,
    );
    facilityUnitResponse?.forEach(facility => {
      if (facility?.qualification?.certifications)
        facility.qualification.certifications = getDynamicSortedData(
          facility.qualification.certifications,
          'abbreviation',
        );
    });
    yield put(orderCreationActions.setFacilityUnits(facilityUnitResponse));
  } catch (error) {
    console.log('error requestOrderCreationFacilityUnitDetails', error);
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestOrderCreationFacilityUnitDetails',
        area: 'src/app/components/Order/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* requestOrderCreationRequirementTypesDetails() {
  try {
    const requirementTypes = yield call(RequrementTypesDetailsTrackWrapper, getRequirementTypes);
    const filterCertsFlagDecision = yield select(selectFilterCertificationsDecision);
    const dataLookups = yield getCredentialLookups('all');
    const { certifications, licenses } = getListsFromReqTypesAndLookups(
      requirementTypes,
      dataLookups,
      filterCertsFlagDecision,
    );
    const states = yield call(GetStatesTrackWrapper, getStatesList);
    const formattedStates = states?.data?.map((s, index) => ({ id: index, ...s }));
    yield put(orderCreationActions.setOrderRequrementTypes({ certifications, licenses, states: formattedStates }));
  } catch (error) {
    console.log('error requestOrderCreationRequirementTypesDetails', error);
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestOrderCreationRequirementTypesDetails',
        area: 'src/app/components/Order/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* requestOrderCreationMiscellaneousApiDetails() {
  try {
    const response = yield call(MiscellaneousApiDetailsTrackWrapper, getDisciplineSpecialtyData);
    if (httpSuccess(response.status)) {
      const disciplineOpts = formatTypeAheadDisciplineOptions(response.data.disciplines);
      const specialtyOpts = formatTypeAheadSpecialtyOptions(response.data.disciplineSpecialties);
      const subSpecialtyOpts = formatTypeAheadSubSpecialtyOptions(response.data.subSpecialties);
      const divisionDisciplineOpts = response.data.divisionDisciplines;
      yield put(
        orderCreationActions.setMiscellaneousData(
          disciplineOpts,
          specialtyOpts,
          subSpecialtyOpts,
          divisionDisciplineOpts,
        ),
      );

      /*const disciplines =  yield call(MiscellaneousApiDetailsTrackWrapper, GetDisciplineOptions);
      const specialties = yield call(MiscellaneousApiDetailsTrackWrapper, GetSpecialtyOptions);
      const subSpeciatlies = yield call(MiscellaneousApiDetailsTrackWrapper, GetSubSpecialtyOptions);
      const divisionDisciplinesOptions = yield call(MiscellaneousApiDetailsTrackWrapper, GetDivisionDisciplinesOptions);*/

      yield put(
        orderDataActions.setTypeAheadOptions({
          disciplines: disciplineOpts || [],
          specialties: specialtyOpts || [],
          subSpecialties: subSpecialtyOpts || [],
          divisionDisciplines: divisionDisciplineOpts || [],
        } as ITypeAheadOptions),
      );

      yield put(
        createOrderOcbrActions.setMiscellaneousData([
          { key: 'disciplineOpts', data: disciplineOpts },
          { key: 'specialtyOpts', data: specialtyOpts },
          { key: 'subSpecialtyOpts', data: subSpecialtyOpts },
          { key: 'divisionDisciplineOpts', data: divisionDisciplineOpts },
        ]),
      );
    }
  } catch (error) {
    console.log('error requestOrderCreationMiscellaneousApiDetails', error);
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestOrderCreationMiscellaneousApiDetails',
        area: 'src/app/components/Order/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* requestOrderCreationRequirementLookupDetails(action: PayloadAction<{ category: string }>) {
  try {
    const requirementLookupResponse = yield call(
      RequrementLookupTypesDetailsTrackWrapper,
      getCredentialLookups,
      action.payload.category,
    );
    yield put(orderCreationActions.setOrderRequrementLookUps(requirementLookupResponse));
  } catch (error) {
    console.log('error requestOrderCreationRequirementLookupDetails', error);
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestOrderCreationRequirementLookupDetails',
        area: 'src/app/components/Order/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* requestOrderCreationSearchCertificationsDetails(
  action: PayloadAction<{ searchQuery: string | null | undefined }>,
) {
  try {
    yield put(orderCreationActions.setCertificationSearchQuery(action.payload.searchQuery));
  } catch (error) {
    console.log('error requestOrderCreationSearchCertificationsDetails', error);
    yield put(orderCreationActions.setLoadingCertificationRequrementsStatus(false));
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'requestOrderCreationSearchCertificationsDetails',
        area: 'src/app/components/Order/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* requestOrderCreationSearchLicenseDetails(
  action: PayloadAction<{ searchQuery: string | null | undefined }>,
) {
  try {
    yield put(orderCreationActions.setLicenseSearchQuery(action.payload.searchQuery));
  } catch (error) {
    console.log('error requestOrderCreationSearchLicenseDetails', error);
    yield put(orderCreationActions.setLoadingLicenseRequirementsStatus(false));
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'requestOrderCreationSearchLicenseDetails',
        area: 'src/app/components/Order/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* requestOrderCreationSearchSkillCheckListDetails(
  action: PayloadAction<{ searchQuery: string | null | undefined }>,
) {
  try {
    yield put(orderCreationActions.setSkillCheckListSearchQuery(action.payload.searchQuery));
  } catch (error) {
    console.log('error requestOrderCreationSearchSkillCheckListDetails', error);
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'requestOrderCreationSearchSkillCheckListDetails',
        area: 'src/app/components/Order/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* requestOrderCreationFormatedSkillCheckListDetails(
  action: PayloadAction<{ searchQuery: string | null | undefined }>,
) {
  try {
    yield put(orderCreationActions.setLoadingSkillCheckListRequrementsStatus(true));
    const existingSkillCheckListMasterRequrements = yield select(selectSkillCheckListRequrementsMaster);
    if (existingSkillCheckListMasterRequrements !== undefined && existingSkillCheckListMasterRequrements !== null) {
      if (
        typeof action.payload.searchQuery !== undefined &&
        action.payload.searchQuery &&
        action.payload.searchQuery.length >= 3
      ) {
        const searchResult = yield call(
          SkillCheckListRequrementsSearchDetailsTrackWrapper,
          searchSkillsets,
          action.payload.searchQuery,
        );
        yield put(orderCreationActions.setSkillCheckListRequrementsSearchResults(searchResult));
      } else {
        yield put(
          orderCreationActions.setSkillCheckListRequrementsSearchResults(existingSkillCheckListMasterRequrements),
        );
      }
    }
    yield put(orderCreationActions.setSkillCheckListSearchQuery(action.payload.searchQuery));
    yield put(orderCreationActions.setLoadingSkillCheckListRequrementsStatus(false));
  } catch (error) {
    console.log('error requestOrderCreationFormatedSkillCheckListDetails', error);
    yield put(orderCreationActions.setLoadingSkillCheckListRequrementsStatus(false));
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'requestOrderCreationFormatedSkillCheckListDetails',
        area: 'src/app/components/Order/Store/OrderCreation.saga.ts',
      },
    });
  }
}

/*export function* requestOrderCreationFormatedSkillCheckListDetails(action: PayloadAction<{ searchQuery: string | null | undefined }>) {
  try {
    yield put(orderCreationActions.setLoadingSkillCheckListRequrementsStatus(true));
    const newOrderObj = yield select(selectNewOrder);
    if (newOrderObj !== null && newOrderObj !== undefined) {
      const existingSkillCheckListMasterRequrements = yield select(selectSkillCheckListRequrementsMaster);
      const unitSkillCheckList = newOrderObj?.qualification?.skillChecklists;
      if (existingSkillCheckListMasterRequrements !== undefined && existingSkillCheckListMasterRequrements !== null) {
        if (typeof action.payload.searchQuery !== undefined && action.payload.searchQuery && action.payload.searchQuery.length >= 3) {

          const searchResult = yield call(SkillCheckListRequrementsSearchDetailsTrackWrapper, searchSkillsets, action.payload.searchQuery);
          const selectionInfo = getSelectionInfo(searchResult) as SkillCheckList[];
          const formatedData = mapData(unitSkillCheckList, selectionInfo);
          yield put(orderCreationActions.setSkillCheckListRequrementsSearchResults(formatedData));
        }
        else{
          const selectionInfo = getSelectionInfo(existingSkillCheckListMasterRequrements) as SkillCheckList[];
          const formatedData = mapData(unitSkillCheckList, selectionInfo);
          yield put(orderCreationActions.setSkillCheckListRequrementsSearchResults(formatedData));
        }
      }

      yield put(orderCreationActions.setSkillCheckListSearchQuery(action.payload.searchQuery));
    }
    yield put(orderCreationActions.setLoadingSkillCheckListRequrementsStatus(false));
  }
  catch (error) {
    console.log('error requestOrderCreationFormatedSkillCheckListDetails', error);
    yield put(orderCreationActions.setLoadingSkillCheckListRequrementsStatus(false));
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'requestOrderCreationFormatedSkillCheckListDetails',
        area: 'src/app/components/Order/Store/OrderCreation.saga.ts',
      },
    });
  }
}*/

export function* requestOrderCreationUpdateEmrDetails(action: PayloadAction<{ data: EMRType | null | undefined }>) {
  try {
    const newOrderObj = yield select(selectNewOrder);
    if (newOrderObj !== null && newOrderObj !== undefined) {
      if (newOrderObj.qualification !== null && newOrderObj.qualification !== undefined) {
        const qualification: QualificationType = {
          ...newOrderObj.qualification,
          emr: action.payload.data,
        };
        //update
        yield put(orderCreationActions.setOrderQualification(qualification));
      } else {
        const qualification: QualificationType = {
          activeLicense: false,
          compactLicense: false,
          emr: action.payload.data,
          certifications: [],
          skillChecklists: [],
          licenses: [],
        };
        //update
        yield put(orderCreationActions.setOrderQualification(qualification));
      }
      yield call(requestCalculateUiComponets, {
        payload: { isInitialLoad: false },
        type: orderCreationActions.getOrderStageUiComponetsAction.type,
      });
    }
  } catch (error) {
    console.log('error requestOrderCreationUpdateCertificationsDocumentStatus', error);
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'requestOrderCreationUpdateCertificationsDocumentStatus',
        area: 'src/app/components/Order/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* requestOrderCreationUpdateTravelExperienceDetails(
  action: PayloadAction<{ data: TravelExperienceType | null | undefined }>,
) {
  try {
    const newOrderObj = yield select(selectNewOrder);
    if (newOrderObj !== null && newOrderObj !== undefined) {
      yield put(
        orderCreationActions.setOrder({
          ...newOrderObj,
          travelExperience: action.payload.data,
        }),
      );
    }
    yield call(requestCalculateUiComponets, {
      payload: { isInitialLoad: false },
      type: orderCreationActions.getOrderStageUiComponetsAction.type,
    });
  } catch (error) {
    console.log('error requestOrderCreationUpdateTravelExperienceDetails', error);
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'requestOrderCreationUpdateTravelExperienceDetails',
        area: 'src/app/components/Order/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* requestOrderCreationUpdateNewGradDetails(action: PayloadAction<{ data: boolean }>) {
  try {
    const newOrderObj = yield select(selectNewOrder);
    if (newOrderObj !== null && newOrderObj !== undefined) {
      yield put(
        orderCreationActions.setOrder({
          ...newOrderObj,
          newGrad: action.payload.data,
        }),
      );
    }
    yield call(requestCalculateUiComponets, {
      payload: { isInitialLoad: false },
      type: orderCreationActions.getOrderStageUiComponetsAction.type,
    });
  } catch (error) {
    console.log('error requestOrderCreationUpdateNewGradDetails', error);
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'requestOrderCreationUpdateNewGradDetails',
        area: 'src/app/components/Order/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* requestOrderCreationUpdateSkillSetsDetails(action: PayloadAction<{ data: SkillSetType[] }>) {
  try {
    const newOrderObj = yield select(selectNewOrder);
    if (newOrderObj !== null && newOrderObj !== undefined) {
      yield put(
        orderCreationActions.setOrder({
          ...newOrderObj,
          skillSets: action.payload.data,
        }),
      );
    }
    yield call(requestCalculateUiComponets, {
      payload: { isInitialLoad: false },
      type: orderCreationActions.getOrderStageUiComponetsAction.type,
    });
  } catch (error) {
    console.log('error requestOrderCreationUpdateSkillSetsDetails', error);
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'requestOrderCreationUpdateSkillSetsDetails',
        area: 'src/app/components/Order/Store/OrderCreation.saga.ts',
      },
    });
  }
}

/*Qualification*/
export function* requestOrderCreationUpdateQualificationDetails(
  action: PayloadAction<{ qualificationData: QualificationType }>,
) {
  try {
    const newOrderObj = yield select(selectNewOrder);
    if (newOrderObj !== null && newOrderObj !== undefined) {
      yield put(orderCreationActions.setOrderQualification(action.payload.qualificationData));
      yield call(requestCalculateUiComponets, {
        payload: { isInitialLoad: false },
        type: orderCreationActions.getOrderStageUiComponetsAction.type,
      });
    }
  } catch (error) {
    console.log('error requestOrderCreationUpdateQualificationDetails', error);
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'requestOrderCreationUpdateQualificationDetails',
        area: 'src/app/components/Order/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* requestOrderCreationUpdateBestBetsIdDetails(action: PayloadAction<{ data: any }>) {
  try {
    const newOrderObj = yield select(selectNewOrder);
    if (newOrderObj !== null && newOrderObj !== undefined) {
      const updatedValue = {
        ...newOrderObj,
        bestBetsId: action.payload.data,
        bestBets: getBestBets(action.payload.data),
      };
      yield put(orderCreationActions.setOrder(updatedValue));
      yield call(requestCalculateUiComponets, {
        payload: { isInitialLoad: false },
        type: orderCreationActions.getOrderStageUiComponetsAction.type,
      });
    }
  } catch (error) {
    console.log('error requestOrderCreationUpdateBestBetsIdDetails', error);
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'requestOrderCreationUpdateBestBetsIdDetails',
        area: 'src/app/components/Order/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* requestOrderCreationUpdateVirtualRatingDetails(action: PayloadAction<{ data: any }>) {
  try {
    const newOrderObj = yield select(selectNewOrder);
    if (newOrderObj !== null && newOrderObj !== undefined) {
      const priorities = newOrderObj ? [...newOrderObj?.orderPriorities] : [];
      const prioritiesWithVi = getPrioritiesFromvirtualRating(priorities, action.payload.data);

      const updatedValue = { ...newOrderObj, virtualRating: action.payload.data, orderPriorities: prioritiesWithVi };
      yield put(orderCreationActions.setOrder(updatedValue));
      yield call(requestCalculateUiComponets, {
        payload: { isInitialLoad: false },
        type: orderCreationActions.getOrderStageUiComponetsAction.type,
      });
    }
  } catch (error) {
    console.log('error requestOrderCreationUpdateVirtualRatingDetails', error);
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'requestOrderCreationUpdateVirtualRatingDetails',
        area: 'src/app/components/Order/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* requestOrderCreationUpdatePropertyDetails(action: PayloadAction<{ propertyName: string; data: any }>) {
  try {
    const newOrderObj = yield select(selectNewOrder);
    if (newOrderObj !== null && newOrderObj !== undefined) {
      const updatedValue = { ...newOrderObj, [action.payload.propertyName]: action.payload.data };
      yield put(orderCreationActions.setOrder(updatedValue));
      yield call(requestCalculateUiComponets, {
        payload: { isInitialLoad: false },
        type: orderCreationActions.getOrderStageUiComponetsAction.type,
      });
    }
  } catch (error) {
    console.log('error requestOrderCreationUpdatePropertyDetails', error);
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'requestOrderCreationUpdatePropertyDetails',
        area: 'src/app/components/Order/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* requestOrderCreationUpdateOrderDetails(
  action: PayloadAction<{ data: NewUIOrder | null | undefined }>,
) {
  try {
    yield put(orderCreationActions.setOrder(action.payload.data));
    yield fork(requestCalculateUiComponets, {
      payload: { isInitialLoad: false },
      type: orderCreationActions.getOrderStageUiComponetsAction.type,
    });
  } catch (error) {
    console.log('error requestOrderCreationUpdateOrderDetails', error);
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'requestOrderCreationUpdateOrderDetails',
        area: 'src/app/components/Order/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* calculateSelectUnitUiComponets() {
  try {
    const orderTypes = yield select(selectOrderDetailsOrderType);
    const facilityDetails = yield select(selectFacilityDetails);
    const creationProcessStep = (yield select(selectCurrentStep)) as ISelectUnit;
    const disciplineOpts = yield select(selectDisciplineOpts);
    yield put(orderCreationActions.setEditOrderRequrementMode(null));
    yield put(orderCreationActions.setEditModeDialogMode(false));

    yield put(orderCreationActions.setEditOrderRequrementMandatoryFieldsStatus(true));
    yield put(orderCreationActions.setEditOrderInformationMandatoryFieldsStatus(true));
    yield put(orderCreationActions.updateMessage(null));

    const proceedToUnitCreation = yield select(selectProceedToUnitCreation);
    const createdUnitId = yield select(selectCreatedUnitId);
    let selectedOrderUnit = creationProcessStep?.selectedOrderUnit;

    if (proceedToUnitCreation === true && !!createdUnitId && createdUnitId > 0) {
      //set selected unit
      if (
        Array.isArray(creationProcessStep?.availableFacilityUnits) &&
        creationProcessStep?.availableFacilityUnits?.length > 0
      ) {
        const mathcingUnits = creationProcessStep.availableFacilityUnits.filter(x => x?.unitId === createdUnitId);
        if (Array.isArray(mathcingUnits) && mathcingUnits?.length > 0) {
          yield put(orderCreationActions.setOrderUnit(mathcingUnits[0]));
          yield put(orderCreationActions.setHoverdFacilityUnit(mathcingUnits[0]));
          selectedOrderUnit = mathcingUnits[0];
        }
      }
      //reset proceedToUnitCreation to false
      yield put(orderCreationActions.setProceedToUnitCreation(false));
    }

    if (selectedOrderUnit) {
      const regions = yield select(selectOrderRegions);
      const newOrder = getNewOrderDetail(selectedOrderUnit, facilityDetails.facilityData);
      yield put(orderCreationActions.setOrder(newOrder));
      yield put(
        orderCreationActions.setOrderDetails(
          toOrderDetails(
            newOrder,
            creationProcessStep.selectedOrderUnit,
            facilityDetails.facilityData,
            disciplineOpts,
            regions,
            orderTypes,
          ),
        ),
      );
      if (
        selectedOrderUnit?.externalJobDescription &&
        selectedOrderUnit?.experience &&
        Array.isArray(selectedOrderUnit?.experience?.skillSets) &&
        selectedOrderUnit?.experience?.skillSets.length > 0
      ) {
        const actionButtons = [
          {
            buttonType: 'Cancel',
            buttonText: 'Cancel',
            isDissabled: false,
            target: 'navigateToFacility',
            toolTipMessage: null,
          },
          {
            buttonType: 'Edit Order Requirements',
            buttonText: 'Edit Order Requirements',
            isDissabled: false,
            target: 'editOrderRequirements',
            toolTipMessage: null,
          },
          {
            buttonType: 'Next',
            buttonText: 'Next',
            isDissabled: false,
            target: 'proceedToNextStep',
            toolTipMessage: null,
          },
        ] as IOrderActionButton[];
        yield put(orderCreationActions.setActionButtons(actionButtons));
      } else {
        const actionButtons = [
          {
            buttonType: 'Cancel',
            buttonText: 'Cancel',
            isDissabled: false,
            target: 'navigateToFacility',
            toolTipMessage: null,
          },
          {
            buttonType: 'Edit Order Requirements',
            buttonText: 'Edit Order Requirements',
            isDissabled: false,
            target: 'editOrderRequirements',
            toolTipMessage: null,
          },
          {
            buttonType: 'Next',
            buttonText: 'Next',
            isDissabled: true,
            target: 'proceedToNextStep',
            toolTipMessage: 'Please edit order requirements to fill out required fields.',
          },
        ] as IOrderActionButton[];
        yield put(orderCreationActions.setActionButtons(actionButtons));
      }
    } else {
      const actionButtons = [
        {
          buttonType: 'Cancel',
          buttonText: 'Cancel',
          isDissabled: false,
          target: 'navigateToFacility',
          toolTipMessage: null,
        },
        {
          buttonType: 'Edit Order Requirements',
          buttonText: 'Edit Order Requirements',
          isDissabled: true,
          target: 'editOrderRequirements',
          toolTipMessage: null,
        },
        {
          buttonType: 'Next',
          buttonText: 'Next',
          isDissabled: true,
          target: 'proceedToNextStep',
          toolTipMessage: 'Please edit order requirements to fill out required fields.',
        },
      ] as IOrderActionButton[];
      yield put(orderCreationActions.setActionButtons(actionButtons));
    }
  } catch (error) {
    console.log('error calculateSelectUnitUiComponets', error);
    //yield put(orderCreationActions.updateStatus('failed'));
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'calculateSelectUnitUiComponets',
        area: 'src/app/components/Order/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* calculateEditOrderRequrementUiComponets(action: PayloadAction<{ isInitialLoad: boolean | false }>) {
  try {
    const orderTypes = yield select(selectOrderDetailsOrderType);
    const facilityDetails = yield select(selectFacilityDetails);
    const creationProcessStep = (yield select(selectCurrentStep)) as ISelectUnit;
    const disciplineOpts = yield select(selectDisciplineOpts);
    const newOrderObj = yield select(selectNewOrder);
    const regions = yield select(selectOrderRegions);

    if (newOrderObj !== null && newOrderObj !== undefined) {
      yield put(
        orderCreationActions.setOrderDetails(
          toOrderDetails(
            newOrderObj,
            creationProcessStep.selectedOrderUnit,
            facilityDetails.facilityData,
            disciplineOpts,
            regions,
            orderTypes,
          ),
        ),
      );

      if (!action.payload.isInitialLoad) {
        /*yield fork(requestOrderCreationFormatedSkillCheckListDetails, {
          payload: { searchQuery: skillCheckListQuery },
          type: orderCreationActions.requestOrderCreationFormatedSkillCheckListDetailsAction.type
        }); //call another saga method async
        */
      }
    } else {
      yield put(orderCreationActions.setEditOrderRequrementMandatoryFieldsStatus(null));
      yield put(orderCreationActions.updateMessage(null));
    }
    //set buttons
    const actionButtons = [
      {
        buttonType: 'Cancel',
        buttonText: 'Cancel',
        isDissabled: false,
        target: 'navigateToFacility',
        toolTipMessage: null,
      },
      {
        buttonType: 'Back',
        buttonText: 'Back',
        isDissabled: false,
        target: 'selectUnit',
        toolTipMessage: null,
      },
      {
        buttonType: 'Next',
        buttonText: 'Next',
        isDissabled: false,
        target: 'proceedToNextStep',
        toolTipMessage: null,
      },
    ] as IOrderActionButton[];
    yield put(orderCreationActions.setActionButtons(actionButtons));
  } catch (error) {
    console.log('error calculateEditOrderRequrementUiComponets', error);
    // yield put(orderCreationActions.updateStatus('failed'));
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'calculateEditOrderRequrementUiComponets',
        area: 'src/app/components/Order/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* calculateEditOrderDetailsUiComponets(action: PayloadAction<{ isInitialLoad: boolean | false }>) {
  try {
    if (action.payload.isInitialLoad) {
      yield put(orderCreationActions.setMandatoryFieldsStatus(true));
      yield put(orderCreationActions.updateMessage(null));
    }
    //set buttons
    const actionButtons = [
      {
        buttonType: 'Cancel',
        buttonText: 'Cancel',
        isDissabled: false,
        target: 'navigateToFacility',
        toolTipMessage: null,
      },
      {
        buttonType: 'Save',
        buttonText: 'Save',
        isDissabled: false,
        target: '#',
        toolTipMessage: null,
      },
    ] as IOrderActionButton[];
    yield put(orderCreationActions.setActionButtons(actionButtons));
  } catch (error) {
    console.log('error calculateEditOrderDetailsUiComponets', error);
    // yield put(orderCreationActions.updateStatus('failed'));
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'calculateEditOrderDetailsUiComponets',
        area: 'src/app/components/Order/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* calculateEditUnitDetailsUiComponets(action: PayloadAction<{ isInitialLoad: boolean | false }>) {
  try {
    if (action.payload.isInitialLoad) {
      yield put(orderCreationActions.setMandatoryFieldsStatus(true));
      yield put(orderCreationActions.updateMessage(null));
    }
    //set buttons
    const actionButtons = [
      {
        buttonType: 'Cancel',
        buttonText: 'Cancel',
        isDissabled: false,
        target: 'navigateToFacility',
        toolTipMessage: null,
      },
      {
        buttonType: 'CreateUnit',
        buttonText: 'Create Unit',
        isDissabled: false,
        target: '#',
        toolTipMessage: null,
      },
    ] as IOrderActionButton[];
    yield put(orderCreationActions.setActionButtons(actionButtons));
  } catch (error) {
    console.log('error calculateEditUnitDetailsUiComponets', error);
    // yield put(orderCreationActions.updateStatus('failed'));
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'calculateEditUnitDetailsUiComponets',
        area: 'src/app/components/Order/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* calculateUpdateUnitDetailsUiComponets(action: PayloadAction<{ isInitialLoad: boolean | false }>) {
  try {
    if (action.payload.isInitialLoad) {
      yield put(orderCreationActions.setMandatoryFieldsStatus(true));
      yield put(orderCreationActions.updateMessage(null));
    }
    //set buttons
    const actionButtons = [
      {
        buttonType: 'Cancel',
        buttonText: 'Cancel',
        isDissabled: false,
        target: 'navigateToFacility',
        toolTipMessage: null,
      },
      {
        buttonType: 'Save',
        buttonText: 'Save',
        isDissabled: false,
        target: '#',
        toolTipMessage: null,
      },
    ] as IOrderActionButton[];
    yield put(orderCreationActions.setActionButtons(actionButtons));
  } catch (error) {
    console.log('error calculateUpdateUnitDetailsUiComponets', error);
    // yield put(orderCreationActions.updateStatus('failed'));
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'calculateUpdateUnitDetailsUiComponets',
        area: 'src/app/components/Order/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* calculateOrderInformationUiComponets(action: PayloadAction<{ isInitialLoad: boolean | false }>) {
  try {
    const orderTypes = yield select(selectOrderDetailsOrderType);
    const facilityDetails = yield select(selectFacilityDetails);
    const creationProcessStep = (yield select(selectCurrentStep)) as ISelectUnit;
    const disciplineOpts = yield select(selectDisciplineOpts);
    const newOrderObj = yield select(selectNewOrder);
    const regions = yield select(selectOrderRegions);
    if (newOrderObj !== null && newOrderObj !== undefined) {
      yield put(
        orderCreationActions.setOrderDetails(
          toOrderDetails(
            newOrderObj,
            creationProcessStep.selectedOrderUnit,
            facilityDetails.facilityData,
            disciplineOpts,
            regions,
            orderTypes,
          ),
        ),
      );
    } else {
      yield put(orderCreationActions.setMandatoryFieldsStatus(null));
      yield put(orderCreationActions.updateMessage(null));
    }
    //set buttons
    const actionButtons = [
      {
        buttonType: 'Cancel',
        buttonText: 'Cancel',
        isDissabled: false,
        target: 'navigateToFacility',
        toolTipMessage: null,
      },
      {
        buttonType: 'Back',
        buttonText: 'Back',
        isDissabled: false,
        target: '#',
        toolTipMessage: null,
      },
      {
        buttonType: 'Next',
        buttonText: 'Next',
        isDissabled: false,
        target: 'proceedToNextStep',
        toolTipMessage: null,
      },
    ] as IOrderActionButton[];
    yield put(orderCreationActions.setActionButtons(actionButtons));
  } catch (error) {
    console.log('error calculateOrderInformationUiComponets', error);
    // yield put(orderCreationActions.updateStatus('failed'));
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'calculateOrderInformationUiComponets',
        area: 'src/app/components/Order/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* getOrderStageBillrateUiComponets(action: PayloadAction<{ isInitialLoad: boolean | false }>) {
  try {
    //set buttons
    const actionButtons = [
      {
        buttonType: 'Cancel',
        buttonText: 'Cancel',
        isDissabled: false,
        target: 'navigateToFacility',
        toolTipMessage: null,
      },
      {
        buttonType: 'Back',
        buttonText: 'Back',
        isDissabled: false,
        target: '#',
        toolTipMessage: null,
      },
      {
        buttonType: 'Next',
        buttonText: 'Next',
        isDissabled: false,
        target: 'proceedToNextStep',
        toolTipMessage: null,
      },
    ] as IOrderActionButton[];
    yield put(orderCreationActions.setActionButtons(actionButtons));
  } catch (error) {
    console.log('error getOrderStageBillrateUiComponets', error);
    // yield put(orderCreationActions.updateStatus('failed'));
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'getOrderStageBillrateUiComponets',
        area: 'src/app/components/Order/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* calculateOrderPreviewUiComponets(action: PayloadAction<{ isInitialLoad: boolean | false }>) {
  try {
    const orderTypes = yield select(selectOrderDetailsOrderType);
    const facilityDetails = yield select(selectFacilityDetails);
    const creationProcessStep = (yield select(selectCurrentStep)) as ISelectUnit;
    const disciplineOpts = yield select(selectDisciplineOpts);
    const newOrderObj = yield select(selectNewOrder);
    const regions = yield select(selectOrderRegions);

    if (newOrderObj !== null && newOrderObj !== undefined) {
      yield put(
        orderCreationActions.setOrderDetails(
          toOrderDetails(
            newOrderObj,
            creationProcessStep.selectedOrderUnit,
            facilityDetails.facilityData,
            disciplineOpts,
            regions,
            orderTypes,
          ),
        ),
      );
      yield put(orderCreationActions.setMandatoryFieldsStatus(false));
      yield put(orderCreationActions.updateMessage('Please review your order and submit to create'));
    } else {
      yield put(orderCreationActions.setMandatoryFieldsStatus(null));
      yield put(orderCreationActions.updateMessage(null));
    }
    //set buttons
    const actionButtons = [
      {
        buttonType: 'Cancel',
        buttonText: 'Cancel',
        isDissabled: false,
        target: 'navigateToFacility',
        toolTipMessage: null,
      },
      {
        buttonType: 'Back',
        buttonText: 'Back',
        isDissabled: false,
        target: '#',
        toolTipMessage: null,
      },
      {
        buttonType: 'Submit',
        buttonText: 'Submit',
        isDissabled: false,
        target: 'proceedToSubmitStep',
        toolTipMessage: null,
      },
    ] as IOrderActionButton[];
    yield put(orderCreationActions.setActionButtons(actionButtons));
  } catch (error) {
    console.log('error calculateOrderInformationUiComponets', error);
    // yield put(orderCreationActions.updateStatus('failed'));
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'calculateOrderInformationUiComponets',
        area: 'src/app/components/Order/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* requestCalculateUiComponets(action: PayloadAction<{ isInitialLoad: boolean | false }>) {
  try {
    const creationProcessStage = yield select(selectStage);
    switch (creationProcessStage) {
      case 'selectUnit': {
        yield call(calculateSelectUnitUiComponets); //call another saga method sync
        return;
      }
      case 'editOrderDetails': {
        yield call(calculateEditOrderDetailsUiComponets, {
          payload: { isInitialLoad: action.payload.isInitialLoad },
          type: orderCreationActions.getOrderStageEditDetailsUiComponetsAction.type,
        }); //call another saga method sync
        return;
      }
      case 'createUnitDetails': {
        yield call(calculateEditUnitDetailsUiComponets, {
          payload: { isInitialLoad: action.payload.isInitialLoad },
          type: orderCreationActions.getUnitStageEditDetailsUiComponetsAction.type,
        }); //call another saga method sync
        return;
      }
      case 'editUnitDetails': {
        yield call(calculateUpdateUnitDetailsUiComponets, {
          payload: { isInitialLoad: action.payload.isInitialLoad },
          type: orderCreationActions.getUnitStageUpdateDetailsUiComponetsAction.type,
        }); //call another saga method sync
        return;
      }
      case 'editOrderRequrement': {
        yield call(calculateEditOrderRequrementUiComponets, {
          payload: { isInitialLoad: action.payload.isInitialLoad },
          type: orderCreationActions.getOrderStageEditRequremnetUiComponetsAction.type,
        }); //call another saga method sync
        return;
      }
      case 'orderInformation': {
        yield call(calculateOrderInformationUiComponets, {
          payload: { isInitialLoad: action.payload.isInitialLoad },
          type: orderCreationActions.getOrderStageInformationUiComponetsAction.type,
        }); //call another saga method sync
        return;
      }
      case 'orderBillRate': {
        yield call(getOrderStageBillrateUiComponets, {
          payload: { isInitialLoad: action.payload.isInitialLoad },
          type: orderCreationActions.getOrderStageBillrateUiComponetsAction.type,
        }); //call another saga method sync
        return;
      }
      case 'orderPreview': {
        yield call(calculateOrderPreviewUiComponets, {
          payload: { isInitialLoad: action.payload.isInitialLoad },
          type: orderCreationActions.getOrderStagePreviewUiComponetsAction.type,
        }); //call another saga method sync
        return;
      }
      default: {
        return;
      }
    }
  } catch (error) {
    console.log('error requestCalculateUiComponets', error);
    //yield put(orderCreationActions.updateStatus('failed'));
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'requestCalculateUiComponets',
        area: 'src/app/components/Order/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* requestOrderRequirementInitView() {
  try {
    //set stage to editOrderRequrement
    yield put(orderCreationActions.setCurrentStage('editOrderRequrement'));
  } catch (error) {
    console.log('error requestOrderRequirementInitView', error);
    yield put(orderCreationActions.updateStatus('failed'));
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'requestOrderRequirementInitView',
        area: 'src/app/components/Order/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* orderCreateionInitialization(action: PayloadAction<{ id: number }>) {
  try {
    yield put(orderCreationActions.updateStatus('loading'));
    yield put(orderDataActions.getRegions());
    //@Team @Derek huynen - remove unused variables
    const selectOrderDetailOrderType = yield select(selectOrderDetailsOrderType);

    //check user have chosen to create unit and after creation navigated back to create order page
    const proceedToUnitCreation = yield select(selectProceedToUnitCreation);
    const createdUnitId = yield select(selectCreatedUnitId);
    if (
      proceedToUnitCreation === false ||
      createdUnitId === undefined ||
      createdUnitId === null ||
      createdUnitId <= 0
    ) {
      //reset store
      yield put(orderCreationActions.orderCreationReset());

      //get facility details
      yield call(requestOrderCreationFacilityDetails, {
        payload: { id: action.payload.id },
        type: orderCreationActions.getOrderCreationFacilityDetailsAction.type,
      }); //call another saga method async

      //update Facility details master data
      const facilityDetails = yield select(selectFacilityDetails);
      const defaultSkills = getDefaultCheckLists(facilityDetails.facilityData.amnDivisionTypeId) as SkillCheckList[];
      yield put(orderCreationActions.setSkillCheckListMasterRequrements(defaultSkills));
      yield put(orderCreationActions.setSkillCheckListRequrementsSearchResults(defaultSkills));

      //get Requirement Types details
      yield call(requestOrderCreationRequirementTypesDetails); //call another saga method sync

      //get lookup details
      yield call(requestOrderCreationRequirementLookupDetails, {
        payload: { category: 'all' },
        type: orderCreationActions.requestOrderCreationRequirementLookupDetailsAction.type,
      }); //call another saga method async

      //get Miscellaneous details
      yield call(requestOrderCreationMiscellaneousApiDetails); //call another saga method sync
    }

    //get units for facility
    yield call(requestOrderCreationFacilityUnitDetails, {
      payload: { id: action.payload.id },
      type: orderCreationActions.getOrderCreationFacilityUnitDetailsAction.type,
    }); //call another saga method sync

    //set stage to selectUnit
    yield put(orderCreationActions.setCurrentStage('selectUnit'));

    //calculate buttons and other ui capability based on current stage
    yield call(requestCalculateUiComponets, {
      payload: { isInitialLoad: true },
      type: orderCreationActions.getOrderStageUiComponetsAction.type,
    }); //call another saga method async

    yield put(orderCreationActions.updateStatus('succeeded'));
  } catch (error) {
    console.log('error orderCreateionInitialization', error);
    yield put(orderCreationActions.updateStatus('failed'));
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'orderCreateionInitialization',
        area: 'src/app/components/Order/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* orderDetailsEditInitialization(action: PayloadAction<{ id: number }>) {
  try {
    yield put(orderCreationActions.updateStatus('loading'));
    //@Team @Derek huynen - remove unused variables
    const selectOrderDetailOrderType = yield select(selectOrderDetailsOrderType);
    yield put(orderDataActions.getRegions());

    //reset store
    yield put(orderCreationActions.orderCreationReset());

    yield put(orderCreationActions.setEditOrderRequrementMandatoryFieldsStatus(true));
    yield put(orderCreationActions.updateMessage(null));

    let facilityDetails = yield select(selectFacilityDetails);
    let selectOrderUiDetails: IOrderDetailsView | null = yield select(selectOrderDetailsUIView);
    if (
      !selectOrderUiDetails ||
      !selectOrderUiDetails.orderDetails ||
      facilityDetails.facilityData === null ||
      facilityDetails.facilityData.division === null
    ) {
      //get order details
      yield call(requestOrderEditSummaryDetails, {
        payload: { id: action.payload.id },
        type: orderCreationActions.getOrderEditSummaryDetailsAction.type,
      }); //call another saga method async
      selectOrderUiDetails = yield select(selectOrderDetailsUIView);
    }

    yield put(
      orderDataActions.setOrderDetailsView({
        ...selectOrderUiDetails,
        currentStep: 'UpdateOrderReqDetails',
      }),
    );

    facilityDetails = yield select(selectFacilityDetails);
    const defaultSkills = getDefaultCheckLists(facilityDetails.facilityData.amnDivisionTypeId) as SkillCheckList[];
    yield put(orderCreationActions.setSkillCheckListMasterRequrements(defaultSkills));
    yield put(orderCreationActions.setSkillCheckListRequrementsSearchResults(defaultSkills));

    //get Requirement Types details
    yield call(requestOrderCreationRequirementTypesDetails); //call another saga method sync

    //get lookup details
    yield call(requestOrderCreationRequirementLookupDetails, {
      payload: { category: 'all' },
      type: orderCreationActions.requestOrderCreationRequirementLookupDetailsAction.type,
    }); //call another saga method async

    const newOrder = getEditOrderDetail(
      selectOrderUiDetails.orderDetails,
      selectOrderUiDetails.selectedNewUnit,
      facilityDetails.facilityData,
    );

    yield put(orderCreationActions.setOrder(newOrder));
    yield put(orderCreationActions.setOrderDetails(selectOrderUiDetails.orderDetails));

    //set edit mode
    yield put(orderCreationActions.setEditOrderRequrementMode('standardize'));

    //set stage to editOrderDetails
    yield put(orderCreationActions.setCurrentStage('editOrderDetails'));

    //calculate buttons and other ui capability based on current stage
    yield call(requestCalculateUiComponets, {
      payload: { isInitialLoad: true },
      type: orderCreationActions.getOrderStageUiComponetsAction.type,
    }); //call another saga method async

    yield put(orderCreationActions.updateStatus('succeeded'));
  } catch (error) {
    console.log('error orderDetailsEditInitialization', error);
    yield put(orderCreationActions.updateStatus('failed'));
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'orderDetailsEditInitialization',
        area: 'src/app/components/Order/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* unitCreateionInitialization(action: PayloadAction<{ id: number }>) {
  try {
    yield put(orderCreationActions.updateStatus('loading'));
    const selectOrderDetailOrderType = yield select(selectOrderDetailsOrderType);

    //check user have chosen to create unit and after creation navigated back to create order page
    const proceedToUnitCreation = yield select(selectProceedToUnitCreation);
    if (proceedToUnitCreation === false) {
      //reset store
      yield put(orderCreationActions.orderCreationReset());

      //get facility details
      yield call(requestOrderCreationFacilityDetails, {
        payload: { id: action.payload.id },
        type: orderCreationActions.getOrderCreationFacilityDetailsAction.type,
      }); //call another saga method async

      //update Facility details master data
      const facilityDetails = yield select(selectFacilityDetails);
      const defaultSkills = getDefaultCheckLists(facilityDetails.facilityData.amnDivisionTypeId) as SkillCheckList[];
      yield put(orderCreationActions.setSkillCheckListMasterRequrements(defaultSkills));
      yield put(orderCreationActions.setSkillCheckListRequrementsSearchResults(defaultSkills));

      //get Requirement Types details
      yield call(requestOrderCreationRequirementTypesDetails); //call another saga method sync

      //get lookup details
      yield call(requestOrderCreationRequirementLookupDetails, {
        payload: { category: 'all' },
        type: orderCreationActions.requestOrderCreationRequirementLookupDetailsAction.type,
      }); //call another saga method async

      //get Miscellaneous details
      yield call(requestOrderCreationMiscellaneousApiDetails); //call another saga method sync
    }

    //get units for facility
    yield call(requestOrderCreationFacilityUnitDetails, {
      payload: { id: action.payload.id },
      type: orderCreationActions.getOrderCreationFacilityUnitDetailsAction.type,
    }); //call another saga method sync

    /*//get facility client Contacts
    yield call(requestFacilityClientContactsList, {
      payload: { id: action.payload.id },
      type: FacilityContactsActions.requestFacilityClientContactsList.type
    }); //call another saga method async
    */

    const facilityDetailsLc = yield select(selectFacilityDetails);
    const facilityData: OrderFacilityDetails = facilityDetailsLc.facilityData;

    //set create unit init
    yield put(orderCreationActions.setCreateUnitDetails(getNewUnitDetail(facilityData)));

    //reset duplicate name status
    yield put(orderCreationActions.setUnitNameErrorStatus(false));

    //set stage to selectUnit
    yield put(orderCreationActions.setCurrentStage('createUnitDetails'));

    //calculate buttons and other ui capability based on current stage
    yield call(requestCalculateUiComponets, {
      payload: { isInitialLoad: true },
      type: orderCreationActions.getOrderStageUiComponetsAction.type,
    }); //call another saga method async

    yield put(orderCreationActions.updateStatus('succeeded'));
  } catch (error) {
    console.log('error unitCreateionInitialization', error);
    yield put(orderCreationActions.updateStatus('failed'));
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'unitCreateionInitialization',
        area: 'src/app/components/Order/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* unitEditInitialization(
  action: PayloadAction<{ id: number; unitId: number; editMode: string; remberMode: boolean; isNewUnitPage: boolean }>,
) {
  try {
    //@Team @Derek huynen - remove unused variables
    const selectOrderDetailOrderType = yield select(selectOrderDetailsOrderType);
    yield put(orderCreationActions.updateStatus('loading'));
    yield put(orderCreationActions.orderCreationReset());
    yield put(orderCreationActions.setEditOrderRequrementMode(null));

    //get facility details
    yield call(requestOrderCreationFacilityDetails, {
      payload: { id: action.payload.id },
      type: orderCreationActions.getOrderCreationFacilityDetailsAction.type,
    }); //call another saga method async

    //update Facility details master data
    const facilityDetails = yield select(selectFacilityDetails);
    const defaultSkills = getDefaultCheckLists(facilityDetails.facilityData.amnDivisionTypeId) as SkillCheckList[];
    yield put(orderCreationActions.setSkillCheckListMasterRequrements(defaultSkills));
    yield put(orderCreationActions.setSkillCheckListRequrementsSearchResults(defaultSkills));

    //get Requirement Types details
    yield call(requestOrderCreationRequirementTypesDetails); //call another saga method sync

    //get lookup details
    yield call(requestOrderCreationRequirementLookupDetails, {
      payload: { category: 'all' },
      type: orderCreationActions.requestOrderCreationRequirementLookupDetailsAction.type,
    }); //call another saga method async

    //get Miscellaneous details
    yield call(requestOrderCreationMiscellaneousApiDetails); //call another saga method sync

    //get units for facility
    yield call(requestOrderCreationFacilityUnitDetails, {
      payload: { id: action.payload.id },
      type: orderCreationActions.getOrderCreationFacilityUnitDetailsAction.type,
    }); //call another saga method sync

    const facilityDetailsLc = yield select(selectFacilityDetails);
    const facilityData: OrderFacilityDetails = facilityDetailsLc.facilityData;

    const creationProcessStep = (yield select(selectCurrentStep)) as ISelectUnit;
    if (
      Array.isArray(creationProcessStep?.availableFacilityUnits) &&
      creationProcessStep?.availableFacilityUnits?.length > 0
    ) {
      const mathcingUnits = creationProcessStep.availableFacilityUnits.filter(x => x?.unitId === action.payload.unitId);
      if (Array.isArray(mathcingUnits) && mathcingUnits?.length > 0) {
        const editUnit: NewUnit = mathcingUnits[0];
        //set edit unit init
        yield put(
          orderCreationActions.setUpdateUnitDetails(
            getEditUnitDetail(
              editUnit,
              facilityData,
              action.payload.editMode,
              action.payload.remberMode,
              action.payload.isNewUnitPage,
            ),
          ),
        );

        //reset duplicate name status
        yield put(orderCreationActions.setEditUnitNameErrorStatus(false));

        yield put(
          orderCreationActions.setEditOrderRequrementMode(
            action.payload.editMode === 'standardized' ? 'standardize' : 'notNow',
          ),
        );

        //set stage to editUnitDetails
        yield put(orderCreationActions.setCurrentStage('editUnitDetails'));

        //calculate buttons and other ui capability based on current stage
        yield call(requestCalculateUiComponets, {
          payload: { isInitialLoad: true },
          type: orderCreationActions.getOrderStageUiComponetsAction.type,
        }); //call another saga method async

        yield put(orderCreationActions.updateStatus('succeeded'));
      } else {
        const isError = true;
        const error = OrderConstants.UNIT_INFO_UNAVILABLE_MESSAGE;
        yield put(orderCreationActions.updateError(isError, error));
      }
    } else {
      const isError = true;
      const error = OrderConstants.UNIT_INFO_UNAVILABLE_MESSAGE;
      yield put(orderCreationActions.updateError(isError, error));
    }
  } catch (error) {
    console.log('error unitEditInitialization', error);
    yield put(orderCreationActions.updateStatus('failed'));
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'unitEditInitialization',
        area: 'src/app/components/Order/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* orderCreationSelectUnitSetSelectedUnit(action: PayloadAction<{ id: number }>) {
  try {
    //get facility available units and add to selected unit
    const creationProcessStep = (yield select(selectCurrentStep)) as ISelectUnit;
    if (
      Array.isArray(creationProcessStep?.availableFacilityUnits) &&
      creationProcessStep?.availableFacilityUnits?.length > 0
    ) {
      const mathcingUnits = creationProcessStep.availableFacilityUnits.filter(x => x?.unitId === action.payload.id);
      if (Array.isArray(mathcingUnits) && mathcingUnits?.length > 0) {
        yield put(orderCreationActions.setOrderUnit(mathcingUnits[0]));
        yield put(orderCreationActions.setHoverdFacilityUnit(mathcingUnits[0]));

        yield call(requestCalculateUiComponets, {
          payload: { isInitialLoad: false },
          type: orderCreationActions.getOrderStageUiComponetsAction.type,
        }); //call another saga method async
      }
    }
  } catch (error) {
    console.log('error orderCreationSelectUnitSetSelectedUnit', error);
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'orderCreationSelectUnitSetSelectedUnit',
        area: 'src/app/components/Order/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* orderCreationSelectUnitSetHoverdUnit(action: PayloadAction<{ id: number }>) {
  try {
    //get facility available units and add to selected unit
    if (action.payload.id > 0) {
      const creationProcessStep = (yield select(selectCurrentStep)) as ISelectUnit;
      if (
        Array.isArray(creationProcessStep?.availableFacilityUnits) &&
        creationProcessStep?.availableFacilityUnits?.length > 0
      ) {
        const mathcingUnits = creationProcessStep.availableFacilityUnits.filter(x => x?.unitId === action.payload.id);
        if (Array.isArray(mathcingUnits) && mathcingUnits?.length > 0) {
          yield put(orderCreationActions.setHoverdFacilityUnit(mathcingUnits[0]));
        }
      }
    }
  } catch (error) {
    console.log('error orderCreationSelectUnitSetHoverdUnit', error);
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'orderCreationSelectUnitSetHoverdUnit',
        area: 'src/app/components/Order/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* orderCreationDetermineOrderRequirementEditMode() {
  try {
    //check unit is selected
    const creationProcessStep = (yield select(selectCurrentStep)) as ISelectUnit;
    yield put(orderCreationActions.setEditOrderRequrementMode(null));
    yield put(orderCreationActions.setEditModeDialogMode(false));
    if (creationProcessStep.selectedOrderUnit && creationProcessStep.selectedOrderUnit.unitId > 0) {
      //check createdByVersion === '2.0'
      if (creationProcessStep.selectedOrderUnit.createdByVersion === '2.0') {
        //proceed to edit order requrement in standardize mode
        yield put(orderCreationActions.setEditOrderRequrementMode('standardize'));
        const newOrderObj = yield select(selectNewOrder);
        if (newOrderObj !== null && newOrderObj !== undefined) {
          //proceed to edit Order Requirement edit view
          yield call(requestOrderRequirementInitView); //call another saga method sync
        }

        //calculate buttons and other ui capability based on current stage
        yield call(requestCalculateUiComponets, {
          payload: { isInitialLoad: true },
          type: orderCreationActions.getOrderStageUiComponetsAction.type,
        }); //call another saga method async
      } else {
        // set show dialog to true
        yield put(orderCreationActions.setEditModeDialogMode(true));
      }
    }
  } catch (error) {
    console.log('error orderCreationDetermineOrderRequirementEditMode', error);
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'orderCreationDetermineOrderRequirementEditMode',
        area: 'src/app/components/Order/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* orderCreationDetermineOrderRequirementEditDalogClose() {
  try {
    //close dialog
    yield put(orderCreationActions.setEditOrderRequrementMode(null));
    yield put(orderCreationActions.setEditModeDialogMode(false));
  } catch (error) {
    console.log('error orderCreationDetermineOrderRequirementEditDalogClose', error);
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'orderCreationDetermineOrderRequirementEditDalogClose',
        area: 'src/app/components/Order/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* orderCreationProceedToOrderRequirementEdit(action: PayloadAction<{ mode: string }>) {
  try {
    //set edit mode and proceed to editOrderRequrement view
    if (action.payload.mode) {
      const newOrderObj = yield select(selectNewOrder);
      const creationProcessStep = (yield select(selectCurrentStep)) as ISelectUnit;
      if (newOrderObj !== null && newOrderObj !== undefined) {
        if (
          creationProcessStep.selectedOrderUnit &&
          creationProcessStep.selectedOrderUnit.unitId > 0 &&
          (action.payload.mode === 'standardize' || action.payload.mode === 'notNow')
        ) {
          //proceed to edit Order Requirement edit view
          yield call(requestOrderRequirementInitView); //call another saga method sync

          yield put(orderCreationActions.setEditOrderRequrementMode(action.payload.mode));
          yield put(orderCreationActions.setEditModeDialogMode(false));

          //calculate buttons and other ui capability based on current stage
          yield call(requestCalculateUiComponets, {
            payload: { isInitialLoad: true },
            type: orderCreationActions.getOrderStageUiComponetsAction.type,
          }); //call another saga method async
        }
      }
    }
  } catch (error) {
    console.log('error orderCreationProceedToOrderRequirementEdit', error);
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'orderCreationProceedToOrderRequirementEdit',
        area: 'src/app/components/Order/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* orderCreationProcessOrderRequirementEdit(action: PayloadAction<{ data: any }>) {
  try {
    const orderTypes = yield select(selectOrderDetailsOrderType);
    const internalNotesFlag = yield select(selectInternalNotesFlag);
    //set edit mode and proceed to editOrderRequrement view
    const newOrderObj = yield select(selectNewOrder);
    const orderCertifications = yield select(selectOrderCertification);

    const creationProcessStep = (yield select(selectCurrentStep)) as IEditOrderRequrement;
    const orderRequirementEditOrderRequrementMode = yield select(selectOrderRequirementEditOrderRequrementMode);
    if (newOrderObj !== null && newOrderObj !== undefined) {
      if (
        creationProcessStep.selectedOrderUnit &&
        creationProcessStep.selectedOrderUnit.unitId > 0 &&
        (orderRequirementEditOrderRequrementMode === 'standardize' ||
          orderRequirementEditOrderRequrementMode === 'notNow') &&
        action.payload.data
      ) {
        const updatedNewUiOrder = getNewOrderEditRequrementProcessBaseDetail(newOrderObj, action.payload.data);
        const invalidLicenses = getInvalidLicenses(newOrderObj.qualification?.licenses);
        const invalidCertifications = getInvalidCertification(orderCertifications);
        const invalidInternalNotes = validateInternalNotes(internalNotesFlag, updatedNewUiOrder.internalNotes);

        const incompleteReqFields: ReqOrderFieldNames[] = [];
        if (invalidInternalNotes) incompleteReqFields.push(ReqOrderFieldNames.INTERNAL_NOTES);

        if (!updatedNewUiOrder.jobDescription) incompleteReqFields.push(ReqOrderFieldNames.EXT_JOB_DESCRIPTION);
        if (!updatedNewUiOrder.skillsets || updatedNewUiOrder.skillsets?.length < 1)
          incompleteReqFields.push(ReqOrderFieldNames.DISCIPLINE, ReqOrderFieldNames.SPECIALTY);
        if (invalidLicenses.length !== 0) incompleteReqFields.push(ReqOrderFieldNames.LICENSE);
        if (invalidCertifications.length !== 0) incompleteReqFields.push(ReqOrderFieldNames.CERTIFICATION);
        yield put(orderCreationActions.setIncompleteReqFields(incompleteReqFields));

        if (!incompleteReqFields?.length) {
          const facilityDetails = yield select(selectFacilityDetails);
          const disciplineOpts = yield select(selectDisciplineOpts);

          yield put(orderCreationActions.setEditOrderRequrementMandatoryFieldsStatus(true));
          yield put(orderCreationActions.updateMessage(null));
          yield put(orderCreationActions.setOrder(updatedNewUiOrder));
          const regions = yield select(selectOrderRegions);

          yield put(
            orderCreationActions.setOrderDetails(
              toOrderDetails(
                updatedNewUiOrder,
                creationProcessStep.selectedOrderUnit,
                facilityDetails.facilityData,
                disciplineOpts,
                regions,
                orderTypes,
              ),
            ),
          );
          yield put(orderCreationActions.setCurrentStage('orderInformation'));

          //calculate buttons and other ui capability based on current stage
          yield call(requestCalculateUiComponets, {
            payload: { isInitialLoad: true },
            type: orderCreationActions.getOrderStageUiComponetsAction.type,
          }); //call another saga method async
        } else {
          yield put(orderCreationActions.setEditOrderRequrementMandatoryFieldsStatus(false));
          if (invalidLicenses.length > 0) {
            yield put(orderCreationActions.updateMessage(`Please resolve incomplete License selections.`));
          } else if (invalidCertifications.length > 0) {
            yield put(orderCreationActions.updateMessage(`Please resolve incomplete Certification selections.`));
          } else {
            yield put(orderCreationActions.updateMessage('Please complete required fields.'));
          }
        }
      }
    }
  } catch (error) {
    console.log('error orderCreationProcessOrderRequirementEdit', error);
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'orderCreationProcessOrderRequirementEdit',
        area: 'src/app/components/Order/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* orderCreationProcessOrderInformationEdit(action: PayloadAction<{ data: any }>) {
  try {
    //set edit mode and proceed to editOrderRequrement view
    const orderTypes = yield select(selectOrderDetailsOrderType);
    const newOrderObj = yield select(selectNewOrder);
    const internalNotesFlag = yield select(selectInternalNotesFlag);
    const orderCertifications = yield select(selectOrderCertification);
    const isValidPlacementDate = (yield select(selectValidPlacementDate)) as IValidPlacementDate;
    const creationProcessStep = (yield select(selectCurrentStep)) as IEditOrderRequrement;
    if (newOrderObj !== null && newOrderObj !== undefined) {
      if (
        creationProcessStep.selectedOrderUnit &&
        creationProcessStep.selectedOrderUnit.unitId > 0 &&
        action.payload.data
      ) {
        const updatedNewUiOrder = getNewOrderInformationProcessBaseDetail(newOrderObj, action.payload.data, orderTypes);
        const invalidLicenses = getInvalidLicenses(newOrderObj.qualification?.licenses);
        const invalidCertifications = getInvalidCertification(orderCertifications);
        const invalidInternalNotes = validateInternalNotes(internalNotesFlag, updatedNewUiOrder.internalNotes);

        const incompleteReqFields: ReqOrderFieldNames[] = [];
        if (invalidInternalNotes) incompleteReqFields.push(ReqOrderFieldNames.INTERNAL_NOTES);

        if (!updatedNewUiOrder.positionsAvailable) incompleteReqFields.push(ReqOrderFieldNames.POSITIONS_AVAILABLE);
        if (!updatedNewUiOrder.type) incompleteReqFields.push(ReqOrderFieldNames.ORDER_TYPE);
        if (!updatedNewUiOrder.region) incompleteReqFields.push(ReqOrderFieldNames.REGION);
        if (invalidLicenses.length !== 0) incompleteReqFields.push(ReqOrderFieldNames.LICENSE);
        if (invalidCertifications.length !== 0) incompleteReqFields.push(ReqOrderFieldNames.CERTIFICATION);
        if (!isValidPlacementDate?.startDate) incompleteReqFields.push(ReqOrderFieldNames.START_DATE);
        if (!isValidPlacementDate?.endDate) incompleteReqFields.push(ReqOrderFieldNames.END_DATE);

        yield put(orderCreationActions.setIncompleteReqFields(incompleteReqFields));
        if (!incompleteReqFields?.length) {
          const facilityDetails = yield select(selectFacilityDetails);
          const disciplineOpts = yield select(selectDisciplineOpts);

          yield put(orderCreationActions.setEditOrderRequrementMandatoryFieldsStatus(true));
          yield put(orderCreationActions.setEditOrderInformationMandatoryFieldsStatus(true));
          yield put(orderCreationActions.updateMessage(null));
          const regions = yield select(selectOrderRegions);

          yield put(orderCreationActions.setOrder(updatedNewUiOrder));
          yield put(
            orderCreationActions.setOrderDetails(
              toOrderDetails(
                updatedNewUiOrder,
                creationProcessStep.selectedOrderUnit,
                facilityDetails.facilityData,
                disciplineOpts,
                regions,
                orderTypes,
              ),
            ),
          );
          yield put(
            orderCreationActions.setCurrentStage(
              action.payload.data.rateCardFlag.enabled ? 'orderBillRate' : 'orderPreview',
            ),
          );

          //calculate buttons and other ui capability based on current stage
          yield call(requestCalculateUiComponets, {
            payload: { isInitialLoad: true },
            type: orderCreationActions.getOrderStageUiComponetsAction.type,
          }); //call another saga method async
        } else {
          yield put(orderCreationActions.setEditOrderInformationMandatoryFieldsStatus(false));
          if (invalidLicenses.length > 0) {
            yield put(orderCreationActions.updateMessage(`Please resolve incomplete License selections.`));
          } else if (invalidCertifications.length > 0) {
            yield put(orderCreationActions.updateMessage(`Please resolve incomplete Certification selections.`));
          } else {
            yield put(orderCreationActions.updateMessage('Please complete required fields.'));
          }
        }
      }
    }
  } catch (error) {
    console.log('error orderCreationProcessOrderInformationEdit', error);
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'orderCreationProcessOrderInformationEdit',
        area: 'src/app/components/Order/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* orderCreationProcessOrderBillRateEdit(action: PayloadAction<{ data: any }>) {
  try {
    //set edit mode and proceed to editOrderRequrement view
    const orderTypes = yield select(selectOrderDetailsOrderType);
    const newOrderObj = yield select(selectNewOrder);
    const { effectiveDates } = yield select(selectOcbrDetails);
    const isDatesSelected: boolean = effectiveDates && effectiveDates.startDate && effectiveDates.endDate;
    const creationProcessStep = (yield select(selectCurrentStep)) as IEditOrderRequrement;
    if (newOrderObj !== null && newOrderObj !== undefined && isDatesSelected) {
      const facilityDetails = yield select(selectFacilityDetails);
      const disciplineOpts = yield select(selectDisciplineOpts);

      yield put(orderCreationActions.setEditOrderRequrementMandatoryFieldsStatus(true));
      yield put(orderCreationActions.setEditOrderInformationMandatoryFieldsStatus(true));
      yield put(orderCreationActions.updateMessage(null));

      const updatedNewUiOrder = getNewOrderInformationProcessBaseDetail(newOrderObj, action.payload.data, orderTypes);
      yield put(orderCreationActions.setOrder(updatedNewUiOrder));
      const regions = yield select(selectOrderRegions);

      yield put(
        orderCreationActions.setOrderDetails(
          toOrderDetails(
            updatedNewUiOrder,
            creationProcessStep.selectedOrderUnit,
            facilityDetails.facilityData,
            disciplineOpts,
            regions,
            orderTypes,
          ),
        ),
      );
      yield put(orderCreationActions.setCurrentStage('orderPreview'));

      //calculate buttons and other ui capability based on current stage
      yield call(requestCalculateUiComponets, {
        payload: { isInitialLoad: true },
        type: orderCreationActions.getOrderStageUiComponetsAction.type,
      }); //call another saga method async
    }
  } catch (error) {
    console.log('error orderCreationProcessOrderBillRateEdit', error);
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'orderCreationProcessOrderBillRateEdit',
        area: 'src/app/components/Order/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* orderCreationProceedToOrderInfromation(action: PayloadAction<{ data: any }>) {
  try {
    //set edit mode and proceed to editOrderRequrement view
    const newOrderObj = yield select(selectNewOrder);
    if (newOrderObj !== null && newOrderObj !== undefined) {
      if (newOrderObj.jobDescription && Array.isArray(newOrderObj.skillsets) && newOrderObj.skillsets.length > 0) {
        yield put(orderCreationActions.setCurrentStage('orderInformation'));

        yield put(orderCreationActions.setEditOrderRequrementMandatoryFieldsStatus(true));
        yield put(orderCreationActions.setEditOrderInformationMandatoryFieldsStatus(true));
        yield put(orderCreationActions.updateMessage(null));

        //calculate buttons and other ui capability based on current stage
        yield call(requestCalculateUiComponets, {
          payload: { isInitialLoad: true },
          type: orderCreationActions.getOrderStageUiComponetsAction.type,
        }); //call another saga method async
      }
    }
  } catch (error) {
    console.log('error orderCreationProceedToOrderInfromation', error);
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'orderCreationProceedToOrderInfromation',
        area: 'src/app/components/Order/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export const editOrderDetails = (order: IOrderDetails, userId: number, isEditUnit: boolean, newOrderObj) => {
  const edittedOrder: editOrder = {
    orderId: parseInt(order.orderId),
    orderTypeId: 0,
    // type: order.orderType,
    location: order.location,
    // region: '',
    regionId: 0,
    // division: order.division,
    orderPriorities: order.unit.orderPriorities?.map((item, index) => {
      return {
        orderPriorityTypeId: item.id,
        description: item.description,
      };
    }),
    hideFacilityDescription: order.hideFacilityDescription,
    suppressExternalPosting: order.suppressExternalPosting,
    // CF: order.clinicalFellowship,
    newGrad: order.newGrad ?? false,
    maxFilesSent: order.maxFilesSent,
    reasonForMaxFilesSent: order.reasonForMaxFilesSent,
    maxFilesSentDate: order.lastUpdatedMaxFilesSent,
    vmsOrderNumber: order.vmsOrderNumber,
    webLink: order.webLink,
    yearsOfExperience: order.yearsOfExperience,
    positionsAvailable: order.positionsAvailable,
    requiredQualifications: order.submissionRequirements,
    preferredQualifications: order.preferredQualifications,
    jobDescription: isEditUnit ? order.unit.externalJobDescription : order.jobDescription,
    internalNotes: isEditUnit ? order.unit.internalNotes : order.internalNotes,
    shifts: order.shifts,
    placementLength: order.numOfWeeks,
    NumOfWeeks: order.numOfWeeks,
    startDate: order.startDate,
    endDate: order.endDate,
    ASAP: order.asap,
    flexibleDates: order.flexibleDates,
    expectedHours: order.expectedHours,
    guaranteedHours: order.guaranteedHours,
    orderReasonId: order.orderReasonId,
    // orderScore: order.orderScore,
    currentUserId: userId,
    vmsBillRate: order.vmsBillRate ? parseFloat(`${order.vmsBillRate}`) : order.vmsBillRate,
    orderBillRate: order.orderBillRate,
    standardizedRequirements: false,
    dateCreated: order.dateCreated,
    dateVerified: order.dateVerified,
    ClinicalFellowship: order.clinicalFellowship,
    VerifiedByUserId: null,
    AutoDecrementDate: null,
    AllowScreenId: null,
    ShiftNotNegotiable: false,
    LastUpdatedMaxFilesSent: order.lastUpdatedMaxFilesSent,
    Conditional: false,
    LastUpdatedByUserIdMaxFilesSent: null,
    contacts: order.contacts,
    skillsets: order.unit.experience?.skillSets,
    facility: order.facility,
    unit: order.unit,
    orderDecrementReasonID: order.orderDecrementReasonID,
    declineFiles: order.declineFiles,
    amieOrdersTimestamp: order.amieOrdersTimestamp,
  };
  return edittedOrder;
};

export function* orderCreationSubmitOrderDetails(action: PayloadAction<{ data: any }>) {
  try {
    const newOrderObj = action.payload.data;
    const selectOrderUiDetails: IOrderDetailsView | null = yield select(selectOrderDetailsUIView);
    const { certifications } = yield select(selectOrderRequirementTypes);
    const orderCertifications = yield select(selectOrderCertification);
    const internalNotesFlag = yield select(selectInternalNotesFlag);
    if (!!newOrderObj && !!selectOrderUiDetails) {
      //check mandatory
      const incompleteReqFields: ReqOrderFieldNames[] = [];
      const invalidLicenses = getInvalidLicenses(newOrderObj.qualification?.licenses);
      const invalidCertifications = getInvalidCertification(orderCertifications);
      const invalidInternalNotes = validateInternalNotes(internalNotesFlag, newOrderObj.internalNotes);
      if (invalidInternalNotes) incompleteReqFields.push(ReqOrderFieldNames.INTERNAL_NOTES);
      if (!newOrderObj.jobDescription) incompleteReqFields.push(ReqOrderFieldNames.EXT_JOB_DESCRIPTION);
      if (!newOrderObj.skillsets || newOrderObj.skillsets?.length < 1)
        incompleteReqFields.push(ReqOrderFieldNames.DISCIPLINE, ReqOrderFieldNames.SPECIALTY);
      if (invalidLicenses.length !== 0) incompleteReqFields.push(ReqOrderFieldNames.LICENSE);
      if (invalidCertifications.length !== 0) incompleteReqFields.push(ReqOrderFieldNames.CERTIFICATION);
      yield put(orderCreationActions.setIncompleteReqFields(incompleteReqFields));

      if (!incompleteReqFields?.length) {
        yield put(orderCreationActions.setEditOrderRequrementMandatoryFieldsStatus(true));
        yield put(orderCreationActions.updateMessage(null));
        yield put(orderCreationActions.updateStatus('loading'));
        const facilityDetails = yield select(selectFacilityDetails);
        const disciplineOpts = yield select(selectDisciplineOpts);

        const user = yield select(selectUser);
        const orderPrioritiesMaster = newOrderObj.orderPriorities
          ? getSubmitOrderPriorities(newOrderObj.orderPriorities)
          : null;
        const orderPrioritiesList =
          !!orderPrioritiesMaster && orderPrioritiesMaster.length > 0
            ? orderPrioritiesMaster.map(item => {
                return {
                  description: item.Description,
                  orderPriorityTypeId: item.ID,
                } as OrderPriority;
              })
            : [];
        const regions = yield select(selectOrderRegions);

        const regionSelected = newOrderObj.region ?? selectOrderUiDetails.orderDetails.location.region;
        const regionUpdated = regionSelected ? regions.filter(item => item.Description === regionSelected) : null;
        const orderBaseDetails = selectOrderUiDetails.orderDetails;

        const edittedOrder: IUpdateOrder = {
          orderId: parseInt(orderBaseDetails.orderId),
          orderTypeId: orderBaseDetails.orderTypeId,
          regionId: regionUpdated ? regionUpdated[0]?.ID : 0,
          hideFacilityDescription: orderBaseDetails.hideFacilityDescription,
          suppressExternalPosting: orderBaseDetails.suppressExternalPosting,
          newGrad: newOrderObj.newGrad,
          maxFilesSent: orderBaseDetails.maxFilesSent,
          reasonForMaxFilesSent: orderBaseDetails.reasonForMaxFilesSent,
          vmsOrderNumber: orderBaseDetails.vmsOrderNumber,
          vmsBillRate: orderBaseDetails.vmsBillRate,
          yearsOfExperience: orderBaseDetails.yearsOfExperience,
          positionsAvailable: orderBaseDetails.positionsAvailable,
          requiredQualifications: orderBaseDetails?.requiredQualifications,
          preferredQualifications: orderBaseDetails?.preferredQualifications,
          jobDescription: newOrderObj.jobDescription,
          internalNotes: newOrderObj.internalNotes,
          numOfWeeks: newOrderObj.placementLength,
          startDate: orderBaseDetails.startDate,
          endDate: orderBaseDetails.endDate,
          orderRateEffectiveStartDate: orderBaseDetails?.orderRateEffectiveStartDate,
          orderRateEffectiveEndDate: orderBaseDetails.orderRateEffectiveEndDate,
          isFlatRate: orderBaseDetails.isFlatRate,
          asap: orderBaseDetails.asap,
          flexibleDates: orderBaseDetails.flexibleDates,
          webLink: orderBaseDetails.webLink,
          expectedHours: orderBaseDetails.expectedHours,
          guaranteedHours: orderBaseDetails.guaranteedHours,
          orderReasonId: orderBaseDetails.orderReasonId,
          currentUserId: user.userInfo?.employeeId ?? 0,
          orderBillRate: orderBaseDetails.orderBillRate,
          contacts: orderBaseDetails.contacts,
          standardizedRequirements: false,
          dateCreated: orderBaseDetails.dateCreated,
          dateVerified: orderBaseDetails.dateVerified,
          clinicalFellowship: orderBaseDetails.clinicalFellowship,
          verifiedByUserId: orderBaseDetails.verifiedByUserId,
          autoDecrementDate: orderBaseDetails.autoDecrementDate,
          allowScreenId: orderBaseDetails.allowScreenId,
          shiftNotNegotiable: orderBaseDetails.shiftNotNegotiable,
          lastUpdatedMaxFilesSent: orderBaseDetails.lastUpdatedMaxFilesSent,
          conditional: orderBaseDetails.conditional,
          lastUpdatedByUserIdMaxFilesSent: orderBaseDetails.lastUpdatedByUserIdMaxFilesSent,
          facility: {
            amieFacilityTimeStamp: orderBaseDetails.facility.amieFacilityTimeStamp,
            businessRelationship: orderBaseDetails.facility.businessRelationship,
            creditStatusId: orderBaseDetails.facility.creditStatusId,
            facilityBestBetsId: orderBaseDetails.facility.facilityBestBetsId,
            facilityId: orderBaseDetails.facility.facilityId,
            name: orderBaseDetails.facility.name,
          },
          unit: {
            id: null,
            costCenter: orderBaseDetails.unit.costCenter,
            timeStamp: null,
            unitId: orderBaseDetails.unit.unitId,
            name: orderBaseDetails.unit.name,
            floor: orderBaseDetails.unit.floor,
            experience: {
              isNewGrad: newOrderObj.newGrad,
              skillSets: newOrderObj.skillsets
                ? newOrderObj.skillsets.map(item => {
                    return {
                      ...item,
                      disciplineAbbr: disciplineOpts.find(x => x.object.Description === item.discipline)?.object
                        .Abbreviation,
                      specialtyAbbr: item.specialty,
                    };
                  })
                : [],
            },
            reference: {
              ...newOrderObj.reference,
              isRequired: newOrderObj.reference.isRequired,
              numberOfReferenceRequired: newOrderObj.reference.numberOfReferenceRequired ?? 0,
              numberOfReferenceToRFO: newOrderObj.reference.numberOfReferenceToRFO ?? 0,
              referenceFromSupervisor: newOrderObj.reference.referenceFromSupervisor,
              withinMonths: newOrderObj.reference.withinMonths,
            },
            nurseRatio: orderBaseDetails.unit.nurseRatio,
            declineFiles: orderBaseDetails.unit.declineFiles,
            unitSize: orderBaseDetails.unit.unitSize,
            travelExperience: newOrderObj.travelExperience,
            qualification: {
              ...newOrderObj.qualification,
              certifications: getValidCertifications(newOrderObj.qualification?.certifications, certifications),
              emr: newOrderObj.emr,
            },
            virtualRating: orderBaseDetails.unit.virtualRating ?? [],
            float: newOrderObj.float,
            flexibleSchedule: newOrderObj.flexibleSchedule,
            nonStandardRequirement: newOrderObj.nonStandardRequirement ?? [],
            createdByVersion: orderBaseDetails.unit.createdByVersion,
            standardizedRequirements: orderBaseDetails.unit.standardizedRequirements,
            fyre: orderBaseDetails.unit.fyre,
          },
          location: orderBaseDetails.location,
          skillsets: newOrderObj.skillsets
            ? newOrderObj.skillsets.map(item => {
                return {
                  ...item,
                  disciplineAbbr: disciplineOpts.find(x => x.object.Description === item.discipline)?.object
                    .Abbreviation,
                  specialtyAbbr: item.specialty,
                };
              })
            : [],
          orderPriorities: orderPrioritiesList,
          shifts: orderBaseDetails.shifts,
          orderDecrementReasonID: orderBaseDetails.orderDecrementReasonID,
          declineFiles: orderBaseDetails.declineFiles,
          amieOrdersTimestamp: orderBaseDetails.amieOrdersTimestamp,
          rateElements: orderBaseDetails.rateElements,
        };

        if (edittedOrder.orderDecrementReasonID) {
          edittedOrder.orderDecrementReasonID = Number(edittedOrder.orderDecrementReasonID);
        }
        edittedOrder.requiredQualifications = formatOrderRequirements(edittedOrder?.unit, true);
        edittedOrder.preferredQualifications = formatOrderRequirements(edittedOrder?.unit, false);

        edittedOrder.startDate = removeTimestamp(edittedOrder.startDate);
        edittedOrder.endDate = removeTimestamp(edittedOrder.endDate);

        const response = yield call(async () => {
          const r = await updateOrderDetails(edittedOrder, selectOrderUiDetails.orderDetails.orderId);
          return {
            status: r.status,
            data: r,
          };
        });
        if (httpSuccess(response.status)) {
          if (action.payload.data.rateCardEnabled) {
            const payload = {
              facilityId: facilityDetails.facilityId,
              updateOrderPayload: {
                orderId: parseInt(orderBaseDetails.orderId),
                skillsets: edittedOrder.skillsets,
                shiftIds: getUniqueShiftIds((edittedOrder.shifts ?? []).map(x => x.description)),
                currentUserId: user.userInfo?.employeeId ?? 0,
                currentUserName: !!user.userInfo?.employeeId
                  ? `${user.userInfo?.firstName} ${user.userInfo?.lastName}`
                  : '',
              },
            };
            yield put(orderDataActions.setSelectedSubCert([]));

            yield put(createOrderOcbrActions.updateOrderSkillsets(payload));
          }
          yield put(orderCreationActions.updateStatus('proceedToParent'));
          yield put(
            globalActions.setSnackBar({
              message: OrderConstants.ORDER_UPDATE_SUCCESS_MESSAGE,
              severity: 'success',
            }),
          );
        } else {
          yield put(orderCreationActions.updateStatus('failed'));
        }

        //yield put(orderCreationActions.updateStatus('failed'));
      } else {
        yield put(orderCreationActions.setEditOrderRequrementMandatoryFieldsStatus(false));
        if (invalidLicenses.length > 0) {
          yield put(orderCreationActions.updateMessage(`Please resolve incomplete License selections.`));
        } else if (invalidCertifications.length > 0) {
          yield put(orderCreationActions.updateMessage(`Please resolve incomplete Certification selections.`));
        } else {
          yield put(orderCreationActions.updateMessage('Please complete required fields.'));
        }
      }
    }
  } catch (error) {
    console.log('error orderCreationSubmitOrderDetails', error);
    yield put(
      globalActions.setSnackBar({
        message: OrderConstants.ORDER_UPDATE_FAILURE_MESSAGE,
        severity: 'error',
      }),
    );
    yield put(orderCreationActions.updateStatus('failed'));
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'orderCreationSubmitOrderDetails',
        area: 'src/app/components/Order/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* unitCreationSubmitUnitDetails(action: PayloadAction<{ data: any; isNewUnitPage: boolean }>) {
  try {
    const orderCertifications = yield select(selectOrderCertification);
    const internalNotesFlag = yield select(selectInternalNotesFlag);
    const newUnit = action.payload.data;
    const invalidLicenses = getInvalidLicenses(newUnit.qualification?.licenses);
    const invalidCertifications = getInvalidCertification(orderCertifications);
    const invalidInternalNotes = validateInternalNotes(internalNotesFlag, newUnit.internalNotes);

    const incompleteReqFields: ReqOrderFieldNames[] = [];
    if (invalidInternalNotes) incompleteReqFields.push(ReqOrderFieldNames.INTERNAL_NOTES);

    if (!newUnit.name) incompleteReqFields.push(ReqOrderFieldNames.UNIT_NAME);
    if (!newUnit.skillsets || newUnit.skillsets?.length < 1)
      incompleteReqFields.push(ReqOrderFieldNames.DISCIPLINE, ReqOrderFieldNames.SPECIALTY);
    if (invalidLicenses.length !== 0) incompleteReqFields.push(ReqOrderFieldNames.LICENSE);
    if (invalidCertifications.length !== 0) incompleteReqFields.push(ReqOrderFieldNames.CERTIFICATION);
    if (action.payload.isNewUnitPage && newUnit.submissionRequirement && newUnit.virtualRating.length === 0) {
      incompleteReqFields.push(ReqOrderFieldNames.VIRTUAL_RATING);
    }
    yield put(orderCreationActions.setIncompleteReqFields(incompleteReqFields));

    if (!incompleteReqFields?.length) {
      yield put(orderCreationActions.setEditOrderRequrementMandatoryFieldsStatus(true));
      yield put(orderCreationActions.updateMessage(null));
      yield put(orderCreationActions.updateStatus('loading'));
      yield put(orderCreationActions.setUnitNameErrorStatus(false));
      const facilityDetails = yield select(selectFacilityDetails);
      const disciplineOpts = yield select(selectDisciplineOpts);
      const user = yield select(selectUser);
      const proceedToUnitCreation = yield select(selectProceedToUnitCreation);
      let orderPrioritiesMaster = [];
      let orderPrioritiesList = [];
      if (action.payload.isNewUnitPage) {
        orderPrioritiesList = newUnit.orderPriorities;
      } else {
        orderPrioritiesMaster = newUnit.orderPriorities ? getSubmitOrderPriorities(newUnit.orderPriorities) : null;
        orderPrioritiesList =
          !!orderPrioritiesMaster && orderPrioritiesMaster.length > 0
            ? orderPrioritiesMaster.map(item => {
                return {
                  description: item.Description,
                  orderPriorityTypeId: item.ID,
                } as OrderPriority;
              })
            : [];
      }
      const edittedUnit: ICreateUnit = {
        facilityId: facilityDetails.facilityId,
        facilityState: facilityDetails.facilityData.location.state,
        bestBets: action.payload.isNewUnitPage ? newUnit.bestBets.Description : newUnit.bestBets,
        bestBetsId: action.payload.isNewUnitPage ? newUnit.bestBets.ID : newUnit.bestBetsId,
        costCenter: newUnit.costCenter,
        costCenterId: null,
        createdByVersion: '2.0',
        currentUserId: user.userInfo?.employeeId ?? 0,
        experience: {
          isNewGrad: newUnit.newGrad,
          skillSets: newUnit.skillsets
            ? newUnit.skillsets.map(item => {
                return {
                  ...item,
                  disciplineAbbr: disciplineOpts.find(x => x.object.Description === item.discipline)?.object
                    .Abbreviation,
                  specialtyAbbr: item.specialty,
                };
              })
            : [],
        },
        newGrad: newUnit.newGrad,
        skillsets: newUnit.skillsets,
        externalJobDescription: newUnit.externalJobDescription,
        flexibleSchedule: newUnit.flexibleSchedule,
        float: newUnit.float,
        floor: newUnit.floor,
        fyreIntegration: newUnit.fyreIntegration,
        internalNotes: newUnit.internalNotes,
        lastUpdated: null,
        lastUpdatedBy: {
          employeeId: user.userInfo?.employeeId ?? 0,
        } as LastUpdatedBy,
        name: newUnit.name,
        nonStandardRequirement: newUnit.nonStandardRequirement ?? [],
        orders: 0,
        emr: newUnit.emr,
        orderPriorities: orderPrioritiesList,
        positionsAvailable: 0,
        //preferredQualifications: null,
        qualification: {
          ...newUnit.qualification,
          emr: newUnit.emr,
        },
        reference: {
          ...newUnit.reference,
          isRequired: newUnit.reference.isRequired,
          numberOfReferenceRequired: newUnit.reference.numberOfReferenceRequired ?? 0,
          referenceFromSupervisor: newUnit.reference.referenceFromSupervisor,
          withinNumberOfMonths: newUnit.reference.withinNumberOfMonths ?? 0,
        },
        //requiredQualifications: null,
        standardizedRequirements: true,
        status: newUnit.status,
        statusId: newUnit.statusId,
        travelExperience: newUnit.travelExperience,
        unitId: 0,
        unitSize: newUnit.unitSize,
        virtualRating: newUnit.virtualRating,
        submissionRequirement: newUnit.submissionRequirement,
        unitContacts: newUnit.unitContacts,
        linkedToFacility: 'N',
        firstDayInstructions: newUnit.firstDayInstructions,
        ssn: newUnit.ssn,
        dob: newUnit.dob,
        notifyCandidate: newUnit.notifyCandidate,
        patientsSeen: newUnit.patientsSeen,
        numberOfVisits: newUnit.numberOfVisits,
        productivityPercentage: newUnit.productivityPercentage,
        nurseRatio: `${newUnit.nurse}:${newUnit.patient}`,
        withinTheLastDays: newUnit?.workHistoryGap?.withinTheLastDays,
        withinTheLastYears: newUnit?.workHistoryGap?.entireWorkHistory
          ? null
          : newUnit?.workHistoryGap?.withinTheLastYears,
        entireWorkHistory: newUnit?.workHistoryGap?.entireWorkHistory,
      };

      edittedUnit.yearsOfExperience = edittedUnit.experience.skillSets.find(s => s.isPrimary)?.yearsOfExperience ?? 0;

      const newUnitResponse = yield call(async () => {
        const r = await createNewUnit(edittedUnit);
        return {
          status: r.status,
          data: r,
        };
      });

      if (validNewUnitResponse(newUnitResponse.data)) {
        yield put(orderCreationActions.setUnitNameErrorStatus(false));
        if (!!edittedUnit.unitContacts && edittedUnit.unitContacts.length > 0) {
          const starResult: any[] = [];
          edittedUnit.unitContacts?.forEach(unit => {
            starResult.push({
              clientContactId: unit.clientContactId,
              isPrimary: unit.isPrimary,
            });
          });
          const updatedStarClientContacts: any = {
            facilityId: facilityDetails.facilityId,
            primaryFacilityContacts: starResult,
          };

          yield call(async () => {
            await updateStarFacilityClientContacts(updatedStarClientContacts);
          });
        }

        if (!!proceedToUnitCreation && proceedToUnitCreation === true) {
          yield put(orderCreationActions.setCreatedUnitId(newUnitResponse.data.unitId));
          yield put(orderDataActions.setCurrentUnitId(`${newUnitResponse.data.unitId}`));
        }
        yield put(orderCreationActions.updateStatus('proceedToParent'));
        yield put(orderDataActions.setOrderLicenseDetails([]));
        yield put(
          globalActions.setSnackBar({
            message: OrderConstants.UNIT_CREATION_SUCCESS_MESSAGE,
            severity: 'success',
          }),
        );
      } else if (newUnitResponse.data === 'Invalid unit name') {
        globalActions.setSnackBar({
          message: OrderConstants.UNIT_CREATION_FAILURE_MESSAGE,
          severity: 'error',
        });
        yield put(orderCreationActions.setUnitNameErrorStatus(true));
        yield put(orderCreationActions.updateStatus('failed'));
      } else {
        throw new Error(newUnitResponse);
      }
    } else {
      yield put(orderCreationActions.setEditOrderRequrementMandatoryFieldsStatus(false));
      if (invalidLicenses.length > 0) {
        yield put(orderCreationActions.updateMessage(`Please resolve incomplete License selections.`));
      } else if (invalidCertifications.length > 0) {
        yield put(orderCreationActions.updateMessage(`Please resolve incomplete Certification selections.`));
      } else {
        yield put(orderCreationActions.updateMessage('Please complete required fields.'));
      }
    }
  } catch (error) {
    console.log('error unitCreationSubmitUnitDetails', error);
    yield put(
      globalActions.setSnackBar({
        message: OrderConstants.UNIT_CREATION_FAILURE_MESSAGE,
        severity: 'error',
      }),
    );
    yield put(orderCreationActions.updateStatus('failed'));
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'unitCreationSubmitUnitDetails',
        area: 'src/app/components/Order/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* unitUpdateSubmitUnitDetails(action: PayloadAction<{ data: any; isNewUnitPage: boolean }>) {
  try {
    const orderCertifications = yield select(selectOrderCertification);
    const internalNotesFlag = yield select(selectInternalNotesFlag);
    const newUnit = action.payload.data;
    const invalidLicenses = getInvalidLicenses(newUnit.qualification?.licenses);
    const invalidCertifications = getInvalidCertification(orderCertifications);
    const invalidInternalNotes = validateInternalNotes(internalNotesFlag, newUnit.internalNotes);
    const incompleteReqFields: ReqOrderFieldNames[] = [];
    if (invalidInternalNotes) incompleteReqFields.push(ReqOrderFieldNames.INTERNAL_NOTES);
    if (!newUnit.name) incompleteReqFields.push(ReqOrderFieldNames.UNIT_NAME);
    if (!newUnit.skillsets || newUnit.skillsets?.length < 1)
      incompleteReqFields.push(ReqOrderFieldNames.DISCIPLINE, ReqOrderFieldNames.SPECIALTY);
    if (invalidLicenses.length !== 0) incompleteReqFields.push(ReqOrderFieldNames.LICENSE);
    if (invalidCertifications.length !== 0) incompleteReqFields.push(ReqOrderFieldNames.CERTIFICATION);
    if (action.payload.isNewUnitPage && newUnit.submissionRequirement && newUnit.virtualRating.length === 0) {
      incompleteReqFields.push(ReqOrderFieldNames.VIRTUAL_RATING);
    }
    yield put(orderCreationActions.setIncompleteReqFields(incompleteReqFields));
    if (incompleteReqFields.length === 0) {
      yield put(orderCreationActions.setEditOrderRequrementMandatoryFieldsStatus(true));
      yield put(orderCreationActions.updateMessage(null));
      yield put(orderCreationActions.updateStatus('loading'));
      yield put(orderCreationActions.setUnitNameErrorStatus(false));
      const facilityDetails = yield select(selectFacilityDetails);
      const disciplineOpts = yield select(selectDisciplineOpts);
      const user = yield select(selectUser);
      const proceedToUnitCreation = yield select(selectProceedToUnitCreation);
      const newUnitObj = yield select(selectEditUnit);
      let orderPrioritiesMaster = [];
      let orderPrioritiesList = [];
      if (action.payload.isNewUnitPage) {
        orderPrioritiesList = newUnit.orderPriorities;
      } else {
        orderPrioritiesMaster = newUnit.orderPriorities ? getSubmitOrderPriorities(newUnit.orderPriorities) : null;
        orderPrioritiesList =
          !!orderPrioritiesMaster && orderPrioritiesMaster.length > 0
            ? orderPrioritiesMaster.map(item => {
                return {
                  description: item.Description,
                  orderPriorityTypeId: item.ID,
                } as OrderPriority;
              })
            : [];
      }
      const edittedUnit: ICreateUnit = {
        facilityId: facilityDetails.facilityId,
        facilityState: facilityDetails.facilityData.location.state,
        bestBets: action.payload.isNewUnitPage ? newUnit.bestBets.Description : newUnit.bestBets,
        bestBetsId: action.payload.isNewUnitPage ? newUnit.bestBets.ID : newUnit.bestBetsId,
        costCenter: newUnit.costCenter,
        costCenterId: newUnitObj.costCenterId,
        createdByVersion: newUnitObj.createdByVersion,
        currentUserId: user.userInfo?.employeeId ?? 0,
        experience: {
          isNewGrad: newUnit.newGrad,
          skillSets: newUnit.skillsets
            ? newUnit.skillsets.map(item => {
                return {
                  ...item,
                  disciplineAbbr: disciplineOpts.find(x => x.object.Description === item.discipline)?.object
                    .Abbreviation,
                  specialtyAbbr: item.specialty,
                };
              })
            : [],
        },
        newGrad: newUnit.newGrad,
        skillsets: newUnit.skillsets,
        externalJobDescription: newUnit.externalJobDescription,
        flexibleSchedule: newUnit.flexibleSchedule,
        float: newUnit.float,
        floor: newUnit.floor,
        fyreIntegration: newUnit.fyreIntegration,
        internalNotes: newUnit.internalNotes,
        lastUpdated: null,
        lastUpdatedBy: {
          employeeId: user.userInfo?.employeeId ?? 0,
        } as LastUpdatedBy,
        name: newUnit.name,
        nonStandardRequirement: newUnit.nonStandardRequirement ?? [],
        orders: newUnitObj.orders,
        orderPriorities: orderPrioritiesList,
        positionsAvailable: newUnitObj.positionsAvailable,
        //preferredQualifications: null,
        qualification: {
          ...newUnit.qualification,
          activeLicense: newUnit.qualification.activeLicense ?? false,
          compactLicense: newUnit.qualification.compactLicense ?? false,
          emr: newUnit.emr,
        },
        reference: {
          ...newUnit.reference,
          isRequired: newUnit.reference.isRequired,
          numberOfReferenceRequired: newUnit.reference.numberOfReferenceRequired ?? 0,
          referenceFromSupervisor: newUnit.reference.referenceFromSupervisor,
          withinNumberOfMonths: newUnit.reference.withinNumberOfMonths ?? 0,
        },
        emr: newUnit.emr,
        standardizedRequirements: newUnitObj.standardizedRequirements,
        preferredQualifications: newUnit.preferredQualifications,
        requiredQualifications: newUnit.requiredQualifications,
        status: newUnit.status,
        statusId: newUnit.statusId,
        travelExperience: newUnit.travelExperience,
        unitId: newUnitObj.unitId,
        unitSize: newUnit.unitSize,
        virtualRating: newUnit.virtualRating,
        submissionRequirement: newUnit.submissionRequirement,
        unitContacts: newUnit.unitContacts,
        linkedToFacility: 'N',
        firstDayInstructions: newUnit.firstDayInstructions,
        dob: newUnit.dob,
        ssn: newUnit.ssn,
        notifyCandidate: newUnit.notifyCandidate,
        patientsSeen: newUnit.patientsSeen,
        numberOfVisits: newUnit.numberOfVisits,
        productivityPercentage: newUnit.productivityPercentage,
        nurseRatio: `${newUnit.nurse}:${newUnit.patient}`,
        withinTheLastDays: newUnit?.workHistoryGap?.withinTheLastDays,
        withinTheLastYears: newUnit?.workHistoryGap?.entireWorkHistory
          ? null
          : newUnit?.workHistoryGap?.withinTheLastYears,
        entireWorkHistory: newUnit?.workHistoryGap?.entireWorkHistory,
      };

      edittedUnit.yearsOfExperience = edittedUnit.experience.skillSets.find(s => s.isPrimary)?.yearsOfExperience ?? 0;

      const unit = {
        firstDayInstructions: newUnit.firstDayInstructions,
        reference: {
          numberOfReferenceRequired: newUnit.reference.numberOfReferenceRequired ?? 0,
          numberOfReferenceToRFO: newUnit.reference.numberOfReferenceToRFO ?? 0,
        },
      } as NewUnit;
      yield put(FacilityUnitActions.setSelectedUnit(unit));

      const newUnitResponse = yield call(async () => {
        const r = await createNewUnit(edittedUnit);
        return {
          status: r.status,
          data: r,
        };
      });

      if (validNewUnitResponse(newUnitResponse.data)) {
        yield put(orderCreationActions.setUnitNameErrorStatus(false));
        if (!!edittedUnit.unitContacts && edittedUnit.unitContacts.length > 0) {
          const starResult: any[] = [];
          edittedUnit.unitContacts?.forEach(unit => {
            starResult.push({
              clientContactId: unit.clientContactId,
              isPrimary: unit.isPrimary,
            });
          });
          const updatedStarClientContacts: any = {
            facilityId: facilityDetails.facilityId,
            primaryFacilityContacts: starResult,
          };

          yield call(async () => {
            await updateStarFacilityClientContacts(updatedStarClientContacts);
          });
        }

        if (!!proceedToUnitCreation && proceedToUnitCreation === true) {
          yield put(orderCreationActions.setCreatedUnitId(newUnitResponse.data.unitId));
          yield put(orderDataActions.setCurrentUnitId(`${newUnitResponse.data.unitId}`));
        }
        yield put(orderCreationActions.updateStatus('proceedToParent'));
        yield put(orderDataActions.setOrderLicenseDetails([]));
        yield put(
          globalActions.setSnackBar({
            message: i18next.t('facility.units.unitDetails.updateSuccessUnitDetails'),
            severity: 'success',
          }),
        );
      } else if (newUnitResponse.data === 'Invalid unit name') {
        globalActions.setSnackBar({
          message: i18next.t('facility.units.unitDetails.updateFailUnitDetails'),
          severity: 'error',
        });
        yield put(orderCreationActions.setUnitNameErrorStatus(true));
        yield put(orderCreationActions.updateStatus('failed'));
      } else {
        throw new Error(newUnitResponse);
      }
    } else {
      yield put(orderCreationActions.setEditOrderRequrementMandatoryFieldsStatus(false));
      if (invalidLicenses.length > 0) {
        yield put(orderCreationActions.updateMessage(`Please resolve incomplete License selections.`));
      } else if (invalidCertifications.length > 0) {
        yield put(orderCreationActions.updateMessage(`Please resolve incomplete Certification selections.`));
      } else {
        yield put(orderCreationActions.updateMessage('Please complete required fields.'));
      }
    }
  } catch (error) {
    console.log('error unitUpdateSubmitUnitDetails', error);
    yield put(
      globalActions.setSnackBar({
        message: OrderConstants.UNIT_UPDATE_FAILURE_MESSAGE,
        severity: 'error',
      }),
    );
    yield put(orderCreationActions.updateStatus('failed'));
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'unitUpdateSubmitUnitDetails',
        area: 'src/app/components/Order/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* orderCreationSubmitOrder(action: PayloadAction<{ data: any }>) {
  try {
    yield put(orderCreationActions.updateStatus('loading'));
    //set edit mode and proceed to editOrderRequrement view
    const newOrderObj: NewUIOrder | null = yield select(selectNewOrder);
    if (newOrderObj !== null && newOrderObj !== undefined) {
      const facilityDetails = yield select(selectFacilityDetails);
      const disciplineOpts = yield select(selectDisciplineOpts);
      const specialtyOpts = yield select(selectSpecialtyOpts);
      const subSpecialtyOpts = yield select(selectSubSpecialtyOpts);
      const orderDetailsObj = yield select(selectOrderDetails);
      const { certifications } = yield select(selectOrderRequirementTypes);
      const user = yield select(selectUser);
      const creationProcessSteps = (yield select(selectStep)) as IOrderCreationStep;
      const orderPrioritiesMaster = getSubmitOrderPriorities(newOrderObj.orderPriorities);
      const orderPriorities =
        !!orderPrioritiesMaster && orderPrioritiesMaster.length > 0
          ? orderPrioritiesMaster.map(item => {
              return {
                description: item.Description,
                orderPriorityTypeId: item.ID,
              } as OrderPriority;
            })
          : [];
      const shiftsMaster = getSubmitOrderShifts(newOrderObj.shifts);
      const shifts =
        !!shiftsMaster && shiftsMaster.length > 0
          ? shiftsMaster.map(item => {
              return {
                description: item.Description,
                id: item.Id,
              } as Shift;
            })
          : [];

      const offContractId = uuid();
      const regions = yield select(selectOrderRegions);
      const regionUpdated = regions.filter(item => item.Description === newOrderObj.region);

      const {
        billRates: ocbrBillRates,
        effectiveDates: ocbrEffectiveDates,
        isFlatRate,
      } = yield select(selectOcbrDetails);
      const rateElements = getAllRateElements({
        isFlatRate,
        ocbrBillRates,
        disciplineOpts,
        specialtyOpts,
        subSpecialtyOpts,
        offContractId,
      });

      const baseOrderObj = newOrderObj as NewOrder;

      const orderToBeSaved = {
        facility: {
          facilityId: facilityDetails.facilityData.facilityId,
          name: facilityDetails.facilityData.facilityName,
          facilityBestBetsId: facilityDetails.facilityData.bestBetsId,
          creditStatusId: facilityDetails.facilityData.isCreditHold === true ? 1 : 0,
        },
        unit: {
          ...orderDetailsObj.unit,
          qualification: {
            ...newOrderObj.qualification,
            certifications: getValidCertifications(newOrderObj.qualification?.certifications, certifications),
            emr: newOrderObj.emr,
          },
          costCenter: newOrderObj.costCenter,
          createdByVersion: creationProcessSteps.selectUnit.selectedOrderUnit.createdByVersion,
          experience: {
            isNewGrad: newOrderObj.newGrad,
            skillSets: newOrderObj.skillsets
              ? newOrderObj.skillsets.map(item => {
                  return {
                    ...item,
                    disciplineAbbr: disciplineOpts.find(x => x.object.Description === item.discipline)?.object
                      .Abbreviation,
                    specialtyAbbr: item.specialty,
                  };
                })
              : [],
          },
          declineFiles: false,
          flexibleSchedule: newOrderObj.flexibleSchedule,
          float: newOrderObj.float,
          floor: newOrderObj.floor,
          fyre: newOrderObj.fyreIntegration,
          virtualRating: newOrderObj.virtualRating,
          nonStandardRequirement: newOrderObj.nonStandardRequirement,
          reference: newOrderObj.reference,
          travelExperience: newOrderObj.travelExperience,
        },
        asap: newOrderObj.ASAP,
        bestBets: newOrderObj.bestBets,
        cf: newOrderObj.CF,
        contacts: {
          accountManager: {
            userId: facilityDetails.facilityData?.employeeContacts?.accountManager?.employeeId,
            firstName: '',
            lastName: '',
            email: '',
          },
          regionalDirector: {
            userId: facilityDetails.facilityData?.employeeContacts?.regionalDirector?.employeeId,
            firstName: '',
            lastName: '',
            email: '',
          },
          recruiter: {
            userId: facilityDetails.facilityData?.employeeContacts?.recruiter?.employeeId,
            firstName: '',
            lastName: '',
            email: '',
          },
          credentialingAnalyst: {
            userId: facilityDetails.facilityData?.employeeContacts?.credentialingAnalyst?.employeeId,
            firstName: '',
            lastName: '',
            email: '',
          },
          clinicalManager: {
            userId: facilityDetails.facilityData?.employeeContacts?.clinicalManager?.employeeId,
            firstName: '',
            lastName: '',
            email: '',
          },
          verifiedBy: null,
        },
        location: {
          address1: '',
          address2: '',
          city: '',
          state: '',
          zipCode: '',
          country: '',
          region: baseOrderObj.region,
          regionId: regionUpdated[0].ID,
          phoneNumber: '',
        },
        currentUserId: user?.userInfo?.employeeId,
        dates: [],
        division: facilityDetails.facilityData.division,
        endDate: newOrderObj.endDate,
        startDate: newOrderObj.startDate,
        expectedHours: newOrderObj.expectedHours,
        flexibleDates: newOrderObj.flexibleDates,
        guaranteedHours: newOrderObj.guaranteedHours,
        hideFacilityDescription: newOrderObj.hideFacilityDescription,
        internalNotes: newOrderObj.internalNotes,
        jobDescription: newOrderObj.jobDescription,
        maxFilesSent: newOrderObj.maxFilesSent,
        newGrad: newOrderObj.newGrad,
        orderId: 0,
        orderPriorities: orderPriorities,
        orderReasonId: getOrderReasonId(newOrderObj.orderReason || ''),
        orderTypeId: newOrderObj.orderTypeId,
        placementLength: newOrderObj.placementLength,
        positionsAvailable: newOrderObj.positionsAvailable,
        preferredQualifications: newOrderObj.preferredQualifications,
        orderBillRate: !!newOrderObj.orderBillRate ? parseFloat(newOrderObj.orderBillRate) : 0,
        reasonForMaxFilesSent: newOrderObj.reasonForMaxFilesSent,
        region: newOrderObj.region,
        regionId: regionUpdated[0].ID,
        regionalDirectorId: 0,
        primaryHamId: 0,
        requiredQualifications: newOrderObj.requiredQualifications,
        shifts: shifts,
        skillsets: newOrderObj.skillsets
          ? newOrderObj.skillsets.map(item => {
              return {
                ...item,
                disciplineAbbr: disciplineOpts.find(x => x.object.Description === item.discipline)?.object.Abbreviation,
                specialtyAbbr: item.specialty,
              };
            })
          : [],
        standardizedRequirements: null,
        suppressExternalPosting: newOrderObj.suppressExternalPosting,
        type: newOrderObj.type,
        vmsOrderNumber: newOrderObj.VMSOrderNumber,
        webLink: newOrderObj.weblink,
        yearsOfExperience: newOrderObj.yearsOfExperience,
        orderRateEffectiveStartDate: ocbrEffectiveDates?.startDate,
        orderRateEffectiveEndDate: ocbrEffectiveDates?.endDate,
        rateElements: rateElements,
        isFlatRate,
      } as ICreateOrder;

      const requiredQualificationsDetails = formatOrderRequirements(orderToBeSaved?.unit, true);
      const prefferedQualificationsDetails = formatOrderRequirements(orderToBeSaved?.unit, false);
      orderToBeSaved.requiredQualifications = requiredQualificationsDetails;
      orderToBeSaved.preferredQualifications = prefferedQualificationsDetails;

      orderToBeSaved.startDate = removeTimestamp(orderToBeSaved.startDate);
      orderToBeSaved.endDate = removeTimestamp(orderToBeSaved.endDate);

      const response = yield call(SubmitCreateOrderDetailsTrackWrapper, createOrder, orderToBeSaved);
      if (httpSuccess(response.status)) {
        if (action.payload.data.rateCardFlag.enabled) {
          //START - OCBR API Calls
          const offContractDetails = getOffContractDataToSave(
            response.data,
            ocbrBillRates,
            isFlatRate,
            ocbrEffectiveDates?.startDate,
            ocbrEffectiveDates?.endDate,
            facilityDetails.facilityData.facilityId,
            offContractId,
            user,
          );
          if (!!offContractDetails) {
            yield call(saveOffcontractDetails, {
              payload: offContractDetails,
              type: createOrderOcbrActions.saveOffcontractDetails.type,
            });
          }

          const facilityRateElementDetails = getFacilityRateElementDocumentsDataToSave(
            response.data,
            ocbrBillRates,
            isFlatRate,
            offContractId,
            facilityDetails.facilityData.facilityId,
            user,
          );
          if (!!facilityRateElementDetails) {
            yield call(saveFacilityRateElementDocumentDetails, {
              payload: facilityRateElementDetails,
              type: createOrderOcbrActions.saveFacilityOffContractRateElementDocuments.type,
            });
            yield call(saveOcbrDocs, {
              payload: facilityDetails.facilityData.facilityId,
              type: createOrderOcbrActions.saveDocuments.type,
            });
          }
          //END - OCBR API Calls
        }
        yield put(orderDataActions.setSelectedSubCert([]));
        yield put(orderDataActions.setOrderLicenseDetails([]));
        yield put(createOrderOcbrActions.resetOcbrDetails());
        yield put(createOrderOcbrActions.resetOcbrSavedDetails());

        yield put(orderCreationActions.updateOrderId(response.data));

        yield put(orderCreationActions.updateStatus('succeeded'));
        yield put(
          globalActions.setSnackBar({
            message: OrderConstants.SUCCESS_MESSAGE,
            severity: 'success',
          }),
        );
      } else {
        yield put(orderCreationActions.updateStatus('failed'));
      }
    }
    yield put(orderCreationActions.updateStatus('failed'));
  } catch (error) {
    console.log('error orderCreationSubmitOrder', error);
    yield put(
      globalActions.setSnackBar({
        message: OrderConstants.FAILURE_MESSAGE,
        severity: 'error',
      }),
    );
    yield put(orderCreationActions.updateStatus('failed'));
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'orderCreationSubmitOrder',
        area: 'src/app/components/Order/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* getOrderPrevStageComponets(action: PayloadAction<{ data: any }>) {
  try {
    //set edit mode and proceed to editOrderRequrement view
    const newOrderObj = yield select(selectNewOrder);
    if (newOrderObj !== null && newOrderObj !== undefined) {
      yield put(orderCreationActions.setCurrentStage(action.payload.data));
    } else {
      yield put(orderCreationActions.setCurrentStage('selectUnit'));
    }

    //reset message
    yield put(orderCreationActions.setEditOrderRequrementMandatoryFieldsStatus(true));
    yield put(orderCreationActions.setEditOrderInformationMandatoryFieldsStatus(true));
    yield put(orderCreationActions.updateMessage(null));
    yield put(createOrderOcbrActions.setRateCardValidationError(false));

    //calculate buttons and other ui capability based on current stage
    yield call(requestCalculateUiComponets, {
      payload: { isInitialLoad: true },
      type: orderCreationActions.getOrderStageUiComponetsAction.type,
    }); //call another saga method async
  } catch (error) {
    console.log('error getOrderPrevStageComponets', error);
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'getOrderPrevStageComponets',
        area: 'src/app/components/Order/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* orderPriorityTypes() {
  try {
    const result = yield call(() => getSearchFilterOptions('activeOrderPriorityTypes'));
    if (result?.length) {
      const mappedResult = formatDataForAutoComplete(result);
      yield put(orderCreationActions.setOrderPriorityTypes(mappedResult));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'orderPriorityTypes',
        area: 'src/app/components/Order/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* bestBetsOptions() {
  try {
    const result = yield call(() => getSearchFilterOptions('bestbets'));
    if (result?.length) {
      yield put(orderCreationActions.setBestBetsOptions(result));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'getBestBetsOptions',
        area: 'src/app/components/Order/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* travelExperienceOptions() {
  try {
    const options = yield call(() => getSearchFilterOptions('skillsetExperienceType'));
    if (options?.length) {
      yield put(
        orderCreationActions.setTravelExperienceOptions([
          ...formatDataForTravelExperiences(
            TravelExperienceSections.NoOfAssignments,
            options,
            TravelExperienceSectionIndex.NoOfAssignments,
          ),
          ...formatDataForTravelExperiences(
            TravelExperienceSections.YearsOfExperience,
            options,
            TravelExperienceSectionIndex.YearsOfExperience,
          ),
        ]),
      );
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'getTravelExperienceOptions',
        area: 'src/app/components/Order/Store/OrderCreation.saga.ts',
      },
    });
  }
}

export function* orderCreationSaga() {
  yield takeLatest(
    orderCreationActions.getOrderCreationFacilityDetailsAction.type,
    requestOrderCreationFacilityDetails,
  );
  yield takeLatest(orderCreationActions.getOrderEditSummaryDetailsAction.type, requestOrderEditSummaryDetails);
  yield takeLatest(
    orderCreationActions.getOrderCreationFacilityUnitDetailsAction.type,
    requestOrderCreationFacilityUnitDetails,
  );
  yield takeLatest(orderCreationActions.getOrderStageUiComponetsAction.type, requestCalculateUiComponets);
  yield takeLatest(
    orderCreationActions.getOrderStageEditRequremnetUiComponetsAction.type,
    calculateEditOrderRequrementUiComponets,
  );
  yield takeLatest(
    orderCreationActions.getOrderStageEditDetailsUiComponetsAction.type,
    calculateEditOrderDetailsUiComponets,
  );
  yield takeLatest(
    orderCreationActions.getUnitStageEditDetailsUiComponetsAction.type,
    calculateEditUnitDetailsUiComponets,
  );
  yield takeLatest(
    orderCreationActions.getUnitStageUpdateDetailsUiComponetsAction.type,
    calculateUpdateUnitDetailsUiComponets,
  );
  yield takeLatest(
    orderCreationActions.getOrderStageInformationUiComponetsAction.type,
    calculateOrderInformationUiComponets,
  );
  yield takeLatest(orderCreationActions.getOrderStageBillrateUiComponetsAction.type, getOrderStageBillrateUiComponets);
  yield takeLatest(orderCreationActions.getOrderStagePreviewUiComponetsAction.type, calculateOrderPreviewUiComponets);
  yield takeLatest(orderCreationActions.getOrderPrevStageComponetsAction.type, getOrderPrevStageComponets);
  yield takeLatest(
    orderCreationActions.orderCreationSelectUnitSetSelectedUnitAction.type,
    orderCreationSelectUnitSetSelectedUnit,
  );
  yield takeLatest(
    orderCreationActions.orderCreationSelectUnitSetHoverdUnitAction.type,
    orderCreationSelectUnitSetHoverdUnit,
  );
  yield takeLatest(
    orderCreationActions.orderCreationDetermineOrderRequirementEditModeAction.type,
    orderCreationDetermineOrderRequirementEditMode,
  );
  yield takeLatest(
    orderCreationActions.orderCreationDetermineOrderRequirementEditDalogCloseAction.type,
    orderCreationDetermineOrderRequirementEditDalogClose,
  );
  yield takeLatest(
    orderCreationActions.orderCreationProceedToOrderRequirementEditAction.type,
    orderCreationProceedToOrderRequirementEdit,
  );
  yield takeLatest(
    orderCreationActions.orderCreationProcessOrderRequirementEditAction.type,
    orderCreationProcessOrderRequirementEdit,
  );
  yield takeLatest(
    orderCreationActions.orderCreationProcessOrderInformationEditAction.type,
    orderCreationProcessOrderInformationEdit,
  );
  yield takeLatest(
    orderCreationActions.orderCreationProcessOrderBillRateEditAction.type,
    orderCreationProcessOrderBillRateEdit,
  );
  yield takeLatest(
    orderCreationActions.orderCreationProceedToOrderInfromationAction.type,
    orderCreationProceedToOrderInfromation,
  );

  yield takeLatest(orderCreationActions.orderCreationSubmitOrderAction.type, orderCreationSubmitOrder);
  yield takeLatest(orderCreationActions.orderCreationSubmitOrderDetailsAction.type, orderCreationSubmitOrderDetails);
  yield takeLatest(orderCreationActions.unitCreationSubmitUnitDetailsAction.type, unitCreationSubmitUnitDetails);
  yield takeLatest(orderCreationActions.unitUpdateSubmitUnitDetailsAction.type, unitUpdateSubmitUnitDetails);

  yield takeLatest(
    orderCreationActions.requestOrderCreationRequirementTypesDetailsAction.type,
    requestOrderCreationRequirementTypesDetails,
  );
  yield takeLatest(
    orderCreationActions.requestOrderCreationRequirementLookupDetailsAction.type,
    requestOrderCreationRequirementLookupDetails,
  );
  yield takeLatest(
    orderCreationActions.requestOrderCreationMiscellaneousApiDetailsAction.type,
    requestOrderCreationMiscellaneousApiDetails,
  );
  yield takeLatest(
    orderCreationActions.requestOrderCreationSearchCertificationsDetailsAction.type,
    requestOrderCreationSearchCertificationsDetails,
  );
  yield takeLatest(
    orderCreationActions.requestOrderCreationSearchLicenseDetailsAction.type,
    requestOrderCreationSearchLicenseDetails,
  );
  yield takeLatest(
    orderCreationActions.requestOrderCreationUpdateQualificationDetailsAction.type,
    requestOrderCreationUpdateQualificationDetails,
  );
  yield takeLatest(
    orderCreationActions.requestOrderCreationUpdateBestBetsIdDetailsAction.type,
    requestOrderCreationUpdateBestBetsIdDetails,
  );
  yield takeLatest(
    orderCreationActions.requestOrderCreationUpdateVirtualRatingDetailsAction.type,
    requestOrderCreationUpdateVirtualRatingDetails,
  );
  yield takeLatest(
    orderCreationActions.requestOrderCreationUpdatePropertyDetailsAction.type,
    requestOrderCreationUpdatePropertyDetails,
  );
  yield takeLatest(
    orderCreationActions.requestOrderCreationUpdateEmrDetailsAction.type,
    requestOrderCreationUpdateEmrDetails,
  );
  yield takeLatest(
    orderCreationActions.requestOrderCreationFormatedSkillCheckListDetailsAction.type,
    requestOrderCreationFormatedSkillCheckListDetails,
  );
  yield takeLatest(
    orderCreationActions.requestOrderCreationSearchSkillCheckListDetailsAction.type,
    requestOrderCreationSearchSkillCheckListDetails,
  );
  yield takeLatest(
    orderCreationActions.requestOrderCreationUpdateTravelExperienceDetailsAction.type,
    requestOrderCreationUpdateTravelExperienceDetails,
  );
  yield takeLatest(
    orderCreationActions.requestOrderCreationUpdateNewGradDetailsAction.type,
    requestOrderCreationUpdateNewGradDetails,
  );
  yield takeLatest(
    orderCreationActions.requestOrderCreationUpdateSkillSetsDetailsAction.type,
    requestOrderCreationUpdateSkillSetsDetails,
  );
  yield takeLatest(
    orderCreationActions.requestOrderCreationUpdateOrderDetailsAction.type,
    requestOrderCreationUpdateOrderDetails,
  );

  yield takeLatest(orderCreationActions.orderCreateionInitializationAction.type, orderCreateionInitialization);
  yield takeLatest(orderCreationActions.orderDetailsEditInitializationAction.type, orderDetailsEditInitialization);
  yield takeLatest(orderCreationActions.unitCreateionInitializationAction.type, unitCreateionInitialization);
  yield takeLatest(orderCreationActions.unitEditInitializationAction.type, unitEditInitialization);
  yield takeLatest(orderCreationActions.getTravelExperienceOptions, travelExperienceOptions);
  yield takeLatest(orderCreationActions.getOrderPriorityTypes, orderPriorityTypes);
  yield takeLatest(orderCreationActions.getBestBetsOptions, bestBetsOptions);
}
