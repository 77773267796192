/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { useTranslation } from 'react-i18next';
import { Grid, IconButton, Chip, FormControl, InputLabel, Avatar } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { Controller, useFormContext } from 'react-hook-form';
import { ControlledTypeAhead } from 'app/components/Candidate/CandidateProfile/CandidateTabPanel/PreferencesTab/CustomComponents/ControlledTypeAhead';
import { ButtonGrid, ChipsGrid } from 'app/components/Order/OrderDesign';
import { OrderConstants } from 'app/constants/OrderCreationForm';
import { yearsOfExperienceList } from 'app/constants';
import { InstructionText, InstructionTextWarning } from '../UnitDesign';
import { SimpleDropdown } from '../Common/SimpleDropdown';
import { formatYOEDropdown } from '../helper';
import { SkillSetType } from 'app/models/Unit/CreateUnit';
import { selectTypeAheadOptions } from 'app/components/Order/Store/Order.selectors';
import { useSelector } from 'react-redux';
import { IOrderDetails } from 'app/models/Orders/OrderDetails';
import { selectFacility } from '@AMIEWEB/Facility/FacilityStore/Facility.selector';
import { filterDisciplineList } from '@AMIEWEB/Order/OrderCreationForm/OrderDetails/FormatData';
import { AMNDivisionType } from 'app/models/enums/AMNDivisionType';

interface Props {
  isRequired: boolean;
  onNewSkillAddition: (value: SkillSetType[]) => void;
  hasMultipleChips: boolean;
  hasEdit?: boolean;
  expSkillSetData?: any | null;
  isDisabled?: boolean;
  showMandetryStyle?: boolean;
  orderDetails?: IOrderDetails;
}
interface ITypeAheadOption {
  object: any;
  label: string;
  label2?: string;
  groupBy?: ITypeAheadOptionGroup;
}

interface ITypeAheadOptionGroup {
  label: string;
  id: number;
  parentId?: number;
  parentLabel?: string;
}

const useStyles = makeStyles()(theme => ({
  addButtonDisabled: {
    color: '#D6D7D9',
    background: 'none !important',
  },
  addButton: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    paddingTop: theme.spacing(2),
  },
  chip: {
    margin: '0 1% 1% 0',
  },
  element: {
    height: '56px',
    // width: '330px',
  },

  chipBlock: {
    width: '230%',
    // marginTop: '20px',
  },
  instructionalText: {
    width: '100%',
    // paddingTop: '3%',
    paddingLeft: '0.5%',
  },

}));

export const ExperienceSkillSet = (props: Props) => {
  const {
    isRequired,
    onNewSkillAddition,
    hasMultipleChips,
    expSkillSetData,
    isDisabled,
    showMandetryStyle,
    orderDetails,
  } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { control, register, watch, errors, setError, clearErrors, setValue } = useFormContext();
  const [filteredDiscipline, setFilteredDiscipline] = React.useState<ITypeAheadOption[]>([]);
  const [filteredSpecialty, setFilteredSpecialty] = React.useState<ITypeAheadOption[]>([]);
  const [filteredSubSpecialty, setFilteredSubSpecialty] = React.useState<ITypeAheadOption[]>([]);
  const [chosenSkillSet, setChosenSkillSet] = React.useState<any[]>([]);
  const [hasDiscipline, setHasDiscipline] = React.useState<boolean>(false);
  const [hasSpecialty, setHasSpecialty] = React.useState<boolean>(false);
  const typeAheadOptions = useSelector(selectTypeAheadOptions);
  const [showManadtoryMultipleSkillSetMessage, setShowManadtoryMultipleSkillSetMessage] = React.useState<boolean>(false);
  const [uniqueChipChosen, setUniqueChipChosen] = React.useState(true);
  const facilityValue = useSelector(selectFacility);

  const discipline = watch('discipline');
  const specialty = watch('specialty');
  const subSpecialty = watch('subSpecialty');
  const yearsOfExperience = watch('yearsOfExperience');

  const formatChipLabel = (label1: string, label2: string, label3?: any, label4?: any) => {
    return label1 + ' - ' + label2 + (label3 ? ' - ' + label3 : '') + (label4 ? ', ' + label4 + 'y' : '');
  };

  const formatData = () => {
    const formattedData: SkillSetType = {
      disciplineId: discipline.object.ID,
      discipline: discipline.label,
      specialtyId: specialty.object.SpecialtyID,
      specialty: specialty.label,
      subSpecialtyId: subSpecialty ? subSpecialty.object.SubSpecialtyID : null,
      subSpecialty: subSpecialty ? subSpecialty.label : null,
      yearsOfExperience: yearsOfExperience === undefined ? 1 : yearsOfExperience,
      isRequired: isRequired,
    };

    if (chosenSkillSet.length === 0) formattedData.isPrimary = true;
    return formattedData;
  };
  const AddSkill = () => {
    if (!discipline) {
      setError('discipline', { message: 'Please choose a discipline' });
      return;
    }
    if (!specialty) {
      setError('specialty', { message: 'Please choose a specialty' });
      return;
    }
    const isAlreadyChosen = chosenSkillSet.some(skill => {
      return (
        discipline.label === skill.discipline &&
        specialty.label === skill.specialty &&
        ((!subSpecialty?.label && !skill.subSpecialty) || subSpecialty?.label === skill?.subSpecialty)
      );
    });

    if (isAlreadyChosen) {
      setUniqueChipChosen(false);
      return;
    }
    if (!hasMultipleChips && chosenSkillSet.length >= 1) {
      setShowManadtoryMultipleSkillSetMessage(true);
      return;
    } else {
      setShowManadtoryMultipleSkillSetMessage(false);
    }
    const updatedSkillSet = [...chosenSkillSet, formatData()];

    setChosenSkillSet(updatedSkillSet);
    onNewSkillAddition(updatedSkillSet);
    setValue('discipline', null);
    setHasDiscipline(false);
    setValue('specialty', null);
    setHasSpecialty(false);
    setValue('subSpecialty', null);
    setValue('yearsOfExperience', yearsOfExperienceList[1]);
  };

  const validateIfDisciplineSelected = () => {
    if (!discipline) {
      setHasDiscipline(false);
      setError('specialty', { message: 'Please choose a discipline' });
      return;
    }
    clearErrors('specialty');
  };

  const validateIfSpecialtySelected = () => {
    if (!specialty) {
      setHasSpecialty(false);
      setError('subSpecialty', { message: 'Please choose a specialty' });
      return;
    }
    clearErrors('subSpecialty');
  };

  const filterDiscipline = () => {
    if (facilityValue) {
      let divisionId = facilityValue?.response?.amnDivisionTypeId ?? AMNDivisionType.allied;
      const filteredList = filterDisciplineList(
        divisionId,
        typeAheadOptions?.disciplines,
        typeAheadOptions?.divisionDisciplines,
      );
      setTimeout(() => {
        setFilteredDiscipline(filteredList);
      }, 500);
    } else {
      setFilteredDiscipline(typeAheadOptions?.disciplines);
    }
  };

  const filterSpecialty = (id: number) => {
    const divisionDisciplines = typeAheadOptions?.divisionDisciplines.filter(dd => {
      return dd.amnDivisionId === facilityValue?.response?.amnDivisionTypeId && dd.disciplineId === id 
    });
    const filteredSpecialties = divisionDisciplines?.map(dd => {
       return typeAheadOptions?.specialties.find(ds => 
          ds.object.DisciplineID === dd.disciplineId && ds.object.SpecialtyID === dd.specialtyId
      );
    });
    return filteredSpecialties || [];
  };

  const filterSubspecialty = (id: number) => {
    const filteredSubSpecialty = typeAheadOptions?.subSpecialties.filter(data => {
      return data.object.SpecialtyID === id;
    });
    return filteredSubSpecialty || [];
  };

  const handleDelete = skill => {
    const data = chosenSkillSet?.filter(entry => entry !== skill);

    setChosenSkillSet(data);
    onNewSkillAddition(data);
  };

  const validateSkill = skill => {
    if (skill === 'discipline') {
      setValue('specialty', null);
      setHasSpecialty(false);
      setValue('subSpecialty', null);
    }
    if (skill === 'specialty') {
      setValue('subSpecialty', null);
    }
  };

  const onSelectionChange = (skill, choice) => {
    if (choice) {
      validateSkill(skill);
      if (skill === 'discipline') {
        if (choice.label.length < 0) {
          setHasDiscipline(false);
        } else {
          const filteredData = filterSpecialty(choice.object.ID);
          setHasDiscipline(true);
          setFilteredSpecialty(filteredData);
        }
      }
      if (skill === 'specialty') {
        if (choice.label.length < 0) setHasSpecialty(choice.label.length > 0);
        else {
          const filteredData = filterSubspecialty(choice.object.SpecialtyID);
          setHasSpecialty(true);
          setFilteredSubSpecialty(filteredData);
        }
      }
      clearErrors('discipline');
      clearErrors('specialty');
    }
  };

  useEffect(() => {
    filterDiscipline();
  }, [facilityValue]);

  useEffect(() => {
    if (expSkillSetData) {
      const prepareChips: SkillSetType[] = [];
      expSkillSetData?.map((item, indx) => {
        prepareChips.push({
          disciplineId: item.disciplineId,
          discipline: item.discipline,
          specialtyId: item.specialtyId,
          specialty: item.specialty,
          subSpecialtyId: item.subSpecialtyId,
          subSpecialty: item.subSpecialty,
          yearsOfExperience: item.yearsOfExperience,
          isRequired: item.isRequired,
        });
      });

      setChosenSkillSet(prepareChips);
    }
  }, []);

  useEffect(() => {
    setUniqueChipChosen(true);
    if (!discipline) {
      setHasDiscipline(false);
    }
    if (!specialty) {
      setHasSpecialty(false);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discipline, specialty]);

  //console.log('orderDetails', orderDetails);

  return (
    <Grid container direction="row" spacing={2}>
      <Grid xs={12} item>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={4} lg={3} className={classes.element}>
            <ControlledTypeAhead
              mandatory={showMandetryStyle ? chosenSkillSet.length === 0 : false}
              filledNormal={false}
              register={register}
              name="discipline"
              label={t('order.createUnit.experience.discipline')}
              control={control}
              options={filteredDiscipline || []}
              onChange={discipline => onSelectionChange('discipline', discipline)}
              isError={errors.discipline ? true : false}
              helperText={errors.discipline ? errors.discipline.message : ' '}
              customScroll
              disabled={isDisabled}
              id={'createUnitExperienceDiscipline'}
            />
          </Grid>
          <Grid item xs={4} lg={3} className={classes.element}>
            <ControlledTypeAhead
              mandatory={showMandetryStyle ? chosenSkillSet.length === 0 : false}
              filledNormal={false}
              register={register}
              name="specialty"
              label={t('order.createUnit.experience.specialty')}
              control={control}
              disabled={!hasDiscipline || isDisabled}
              options={discipline ? filteredSpecialty : []}
              onChange={specialty => onSelectionChange('specialty', specialty)}
              onFocus={() => validateIfDisciplineSelected()}
              isError={errors.specialty ? true : false}
              helperText={errors.specialty ? errors.specialty.message : ' '}
              customScroll
              id={'createUnitExperienceSpecialty'}
            />
          </Grid>
          <Grid item xs={4} lg={3} className={classes.element}>
            <ControlledTypeAhead
              register={register}
              name="subSpecialty"
              label={t('order.createUnit.experience.subSpecialty')}
              disabled={!hasSpecialty || !hasDiscipline || isDisabled || filteredSubSpecialty.length === 0}
              control={control}
              options={filteredSubSpecialty}
              onChange={subSpecialty => onSelectionChange('subSpecialty', subSpecialty)}
              onFocus={() => validateIfSpecialtySelected()}
              isError={errors.subSpecialty ? true : false}
              helperText={errors.subSpecialty ? errors.subSpecialty.message : ' '}
              customScroll
              id={'createUnitExperienceSubSpecialty'}
            />
          </Grid>
          <Grid item xs={4} lg={2} className={classes.element}>
            <FormControl fullWidth variant="filled" disabled={isDisabled}>
              <InputLabel>{`Years of Exp`}</InputLabel>
              <Controller
                name="yearsOfExperience"
                control={control}
                onChange={data => data}
                defaultValue={yearsOfExperienceList[1]}
                render={({ ref, onChange, ...rest }) => (
                  <SimpleDropdown
                    onChange={newValue => {
                      onChange(newValue);
                    }}
                    defaultInputList={yearsOfExperienceList}
                    defaultInputValue={yearsOfExperienceList[1]}
                    formattingFunc={formatYOEDropdown}
                    req={showMandetryStyle ? chosenSkillSet.length === 0 : false}
                    inputName="yearsOfExperience"
                    marginLeft={0}
                    minWidth={'160px !important'}
                    {...rest}
                    id={'createUnitYearsOfExperienceDropDown'}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <ButtonGrid item>
            <IconButton
              id={'createUnitSkillSetAddBtn'}
              type="button"
              classes={{ root: classes.addButton, disabled: classes.addButtonDisabled }}
              color="primary"
              disableTouchRipple
              disableFocusRipple
              disabled={!hasDiscipline || !hasSpecialty || isDisabled}
              onClick={() => AddSkill()}
              size="small"
            >
              <AddBoxIcon style={{ fontSize: 40, fill: !hasDiscipline || !hasSpecialty ? '#D6D7D9' : '#006FB9' }} />
            </IconButton>
          </ButtonGrid>
          <Grid item xs={12}>
            <div className={classes.instructionalText}>
              <InstructionText>{OrderConstants.UNIT_CREATION_SKILLSET_PRIMARY_HELPER}</InstructionText>
            </div>
            <div
              style={{ display: !hasMultipleChips && chosenSkillSet.length >= 1 ? 'block' : 'none' }}
              className={classes.instructionalText}
            >
              {showManadtoryMultipleSkillSetMessage && (
                <InstructionTextWarning>{OrderConstants.ERROR_SKILLSET_LIMITATION}</InstructionTextWarning>
              )}
            </div>
            <div style={{ display: !uniqueChipChosen ? 'block' : 'none' }} className={classes.instructionalText}>
              {!uniqueChipChosen && <InstructionText>{OrderConstants.ERROR_UNIQUE_CHIP_NOT_CHOSEN}</InstructionText>}
            </div>
            <div
              style={{
                display:
                  orderDetails?.orderCurrentPlacements && orderDetails?.orderCurrentPlacements.totalPlacements > 0
                    ? 'block'
                    : 'none',
              }}
              className={classes.instructionalText}
            >
              {orderDetails?.orderCurrentPlacements && orderDetails?.orderCurrentPlacements.totalPlacements > 0 && (
                <InstructionText>{OrderConstants.WAARRING_UNIQUE_CHIP_NOT_CHOSEN}</InstructionText>
              )}
            </div>
          </Grid>
        </Grid>
      </Grid>
      {chosenSkillSet.length > 0 && (
        <ChipsGrid item xs={12} className={classes.chipBlock} id={'createUnitSelectedSkillsSetContainer'}>
          {chosenSkillSet.map((skill, index) => (
            <div data-editingrow="true" key={index}>
              {orderDetails?.skillsets?.filter(
                t => t.disciplineId === skill.disciplineId && t.specialtyId === skill.specialtyId,
              ).length &&
              orderDetails?.orderCurrentPlacements &&
              orderDetails?.orderCurrentPlacements.totalPlacements > 0 ? (
                <Chip
                  disabled={isDisabled}
                  variant="outlined"
                  key={index}
                  avatar={
                    <Avatar  variant={skill.isRequired ?'primary':'green'}>
                      {skill.isRequired ? 'R' : 'P'}
                    </Avatar>
                  }
                  label={
                    <React.Fragment>
                      {formatChipLabel(
                        skill.discipline,
                        skill.specialty,
                        skill.subSpecialty,
                        skill.yearsOfExperience,
                      )}
                    </React.Fragment>
                  }
                  className={classes.chip}
                />
              ) : (
                <Chip
                  disabled={isDisabled}
                  variant="outlined"
                  key={index}
                  avatar={
                    <Avatar variant={skill.isRequired ?'primary':'green'}>
                      {skill.isRequired ? 'R' : 'P'}
                    </Avatar>
                  }
                  label={
                    <React.Fragment>
                      {formatChipLabel(
                        skill.discipline,
                        skill.specialty,
                        skill.subSpecialty,
                        skill.yearsOfExperience,
                      )}
                    </React.Fragment>
                  }
                  className={classes.chip}
                  onDelete={() => handleDelete(skill)}
                />
              )}
            </div>
          ))}
        </ChipsGrid>
      )}
    </Grid>
  );
};
