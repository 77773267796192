import { StatusDropDownValues } from '@AMIEWEB/Common/StatusDropDown/StatusDropDown';
import { tooltipType } from 'app/components/Common/Tooltips';
import { missingField } from 'app/constants';
import { ICustomHeader } from 'app/models/Grid';
import { setUpdatedByClientContract } from './helper';

interface IGridColumns extends Omit<ICustomHeader, 'title'> {
  title: 'firstName' | 'lastName' | 'goesBy' | 'title' | 'actions' | 'units' | 'email' | 'city' | 'state';
}

export const clientCantactViewInfo: IGridColumns[] = [
  {
    title: 'firstName',
    value: 'First Name',
    sortable: true,
    sortByField: 'firstNameSortable',
  },
  {
    title: 'lastName',
    value: 'Last Name',
    tooltip: tooltipType.CustomTooltip,
    tooltipAttributes: 'lastNameAttributes',
    sortable: true,
  },
  { title: 'goesBy', value: 'Goes by', sortable: true },
  {
    title: 'title',
    value: 'Title',
    sortable: true,
  },
  {
    title: 'actions',
    value: 'Actions',
    sortable: true,
    tooltip: tooltipType.CustomTooltip,
    tooltipAttributes: 'actionAttributes',
    sortByField: 'actionsSortField',
  },
  {
    title: 'units',
    value: 'Units',
    sortable: true,
    tooltip: tooltipType.CustomTooltip,
    tooltipAttributes: 'unitAttributes',
    sortByField: 'unitsSortField',
  },
  { title: 'email', value: 'Email', sortable: true },
  {
    title: 'city',
    value: 'City',
    sortable: true,
  },
  { title: 'state', value: 'State', sortable: true },
];

export const formatClientContacts = (response, unitContacts, employeeName?: string) => {
  const tooltipFix = { display: 'inline-block' };
  return response.map((item: any, index) => ({
    id: index,
    rowId: item.contactId,
    contactId: item.contactId,
    firstName: item?.firstName,
    firstNameSortable: item.firstName,
    lastName: `${item?.lastName || missingField}`,
    prefix: `${item?.prefix || missingField}`,
    name: item?.name,
    lastNameAttributes: {
      tooltipContent: item?.lastName,
      contentStyle: tooltipFix,
      disableTooltip: !item?.lastName || (item?.lastName.length || 0) < 9,
    },
    title: `${item?.title || missingField}`,
    email: `${item?.email || missingField}`,
    phoneNumber: `${item?.phoneNumber || missingField}`,
    goesBy: `${item?.goesBy || missingField}`,
    city: `${item?.city || missingField}`,
    state: `${item?.state || missingField}`,
    contactPreference: `${item?.contactPreference || missingField}`,
    units: item.units && item.units.length > 0 && item.units?.length ? 'Units' : missingField,
    unitAttributes: {
      tooltipContent: item.units
        ? item.units
            ?.map(item => {
              return item.name;
            })
            .join(', ')
        : missingField,
      contentStyle: tooltipFix,
      disableTooltip: item.units && item.units.length > 0 && item.units?.length ? false : true,
    },
    unitsSortField: item.units
      ? item.units
          ?.map(item => {
            return item.name;
          })
          .join(', ')
      : missingField,
    actions: item.actions && item.actions.length > 0 && item.actions?.length ? 'Actions' : missingField,
    actionAttributes: {
      tooltipContent: item.actions
        ? item.actions
            ?.map(item => {
              return item.description;
            })
            .join(', ')
        : missingField,
      contentStyle: tooltipFix,
      disableTooltip: item.actions && item.actions.length > 0 && item.actions?.length ? false : true,
    },
    actionsSortField: item.actions
      ? item.actions
          ?.map(item => {
            return item.description;
          })
          .join(', ')
      : missingField,
    actionsSort: item.actionsSort,
    selected: unitContacts ? unitContacts.findIndex(i => i.clientContactId === item.contactId) >= 0 : false,
    isPrimary:
      unitContacts?.length && unitContacts.findIndex(i => i.clientContactId === item.contactId) >= 0
        ? item.isPrimary
        : false,
    doNotCallIn: item.doNotCallIn,
    status: `${item?.status || StatusDropDownValues.ACTIVE}`,
    notes: `${item?.notes || missingField}`,
    statusLastUpdated: setUpdatedByClientContract(item?.insertDate),
  }));
};
