import { useDecision } from '@optimizely/react-sdk';
import { Grid } from 'amn-ui-core';
import { ff_facility_ui_view_new_unit_pages } from 'app/constants/FeatureFlags/Facility/Keys';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import Experiences from './Containers/Experiences';
import ExperiencesOldDesign from './Containers/ExperiencesOldDesign';
import ExternalJobQualifications from './Containers/ExternalJobQualifications';
import UnitFirstDayInstructions from './Containers/FirstDayInstructions';
import NurseToPatientRatio from './Containers/NurseToPatient';
import UnitOrderPriorities from './Containers/OrderPriorities';
import UnitQualifications from './Containers/Qualifications/UnitQualifications';
import UnitReferences from './Containers/References';
import TravelExperience from './Containers/TravelExperience';
import UnitDetails from './Containers/UnitDetails';
import ViewBestBets from './Containers/ViewBestBets';
import VirtualInterviewRating from './Containers/VirtualInterviewRating';
import WorkHistoryGap from './Containers/WorkHistoryGap';

const useStyles = makeStyles()({
  detailsBodyContainer: {
    width: 'auto',
    paddingLeft: '12px',
  },
  item: {
    paddingTop: '0px !important',
  },
});

export const UnitDetailsBody = () => {
  const { classes } = useStyles();
  const [isNewEditUnitDetails] = useDecision(ff_facility_ui_view_new_unit_pages);

  return (
    <>
      <Grid container className={classes.detailsBodyContainer} spacing={3} id="unit-details-body">
        <Grid item xs={8} className={classes.item}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {isNewEditUnitDetails?.enabled ? <Experiences /> : <ExperiencesOldDesign />}
            </Grid>
            <Grid item xs={6} className={classes.item}>
              <UnitDetails />
              {!isNewEditUnitDetails?.enabled && <UnitQualifications />}
            </Grid>
            <Grid item xs={6} className={classes.item}>
              <UnitFirstDayInstructions />
              <ExternalJobQualifications />
              {!isNewEditUnitDetails?.enabled && <TravelExperience />}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} className={classes.item}>
          <UnitReferences />
          <WorkHistoryGap />
          <UnitOrderPriorities />
          <VirtualInterviewRating />
          <NurseToPatientRatio />
          <ViewBestBets />
        </Grid>
      </Grid>
    </>
  );
};

export default UnitDetailsBody;
