import React from 'react';
import { Grid, Typography, Box } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import styledComponent from 'styled-components';
import Attachment from 'app/assets/images/Notification/Inbox/Attachment2x.png';
import MessageIcon from 'app/assets/images/messageIcon.svg';
import { CapitalizeEveryFirstLetter } from 'utils/string/string';
import { TextWithEllipses } from '../../Common/StringHelper';
import { formatPhoneNumber } from 'app/helpers/phoneHelpers';

const useStyles = makeStyles()(() => ({
  gridContainers: {
    marginTop: '-20px',
    height: 'auto',
    width: '306px',
    opacity: 1,
  },
  statusIcon: {
    marginTop: '0.5rem',
    opacity: 1,
  },
  flexContainer: {
    width: '250px',
    display: 'flex',
    alignItems: 'flex-start',
  },
  newTaskNotificationTitle: {
    height: '19px',
    width: '256px',
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontStyle: 'normal',
    fontVariant: 'normal',
    fontWeight: 500,
    lineHeight: '19px',
    letterSpacing: '0px',
    color: '#353535',
    opacity: 1,
    marginTop: '5px',
  },
  truncation: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  name: {
    color: '#353535',
    paddingRight: '5px',
    fontSize: '14px',
    fontWeight: 500,
  },
  id: {
    color: '#353535',
    fontSize: '14px',
    fontWeight: 500,
  },
  attachmentIcon: {
    width: '23px',
    height: '23px',
    transform: 'rotate(-45deg)',
  },
  messageIcon: {
    width: '36px',
    height: '36px',
    '& .images-message-a': {
      fill: '#5426df',
    },
  },
}));

export const TextContent = styledComponent.div`
  font-size: 12px;
  color: #333333;
  text-align: start;
  white-space: pre-wrap;
`;

const HeaderIcon = styledComponent.div`
  position: absolute;
  right: 20px;
  top: 20px;
  color: #cccccc;
`;

export const MessagePopup = props => {
  const { name, travelerId, body, attachments } = props;
  const messageBody = body.replaceAll(/\n+/g, ' ');
  const { classes } = useStyles();

  const MAX_NAME_LENGTH = 25;
  const MAX_MESSAGE_LENGTH = 85;

  const SubHeader = styledComponent(Typography)`
    text-align: left;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0px;
    color: #555555;
    opacity: 1;
    text-overflow: ellipsis;
    opacity: 1;
    margin-top: 3px;
  `;

  return (
    <>
      <div role="button" tabIndex={-3}>
        <Grid container direction="row" className={classes.gridContainers}>
          <Grid item xs={2}>
            <div className={classes.statusIcon}>
              <img src={MessageIcon} className={classes.messageIcon} alt="MessageIcon" />
            </div>
          </Grid>

          <Grid item xs={10} style={{ paddingLeft: '0px', marginLeft: '-8px' }}>
            <div className={classes.flexContainer}>
              <div className={classes.newTaskNotificationTitle}>
                <div className={classes.truncation}>
                  <Box display={'flex'}>
                    {props.name ? (
                      <>
                        <Typography className={classes.name}>
                          {props.name && props.name.length > 25
                            ? `${CapitalizeEveryFirstLetter(name.substring(0, MAX_NAME_LENGTH))}... `
                            : CapitalizeEveryFirstLetter(name)}{' '}
                        </Typography>
                        <Typography className={classes.id}>{`(${travelerId})`}</Typography>
                      </>
                    ) : (
                      <Typography className={classes.name}>{formatPhoneNumber(props.phoneNumber)}</Typography>
                    )}

                    {
                      <HeaderIcon>
                        {attachments && attachments.length > 0 && (
                          <img className={classes.attachmentIcon} src={Attachment} alt={`Attachment`} />
                        )}
                      </HeaderIcon>
                    }
                  </Box>
                  <SubHeader>
                    <TextContent>{TextWithEllipses(messageBody, MAX_MESSAGE_LENGTH)}</TextContent>
                  </SubHeader>
                  {messageBody.length === 0 && attachments && (
                    <SubHeader>{`Attachment${attachments.length > 1 ? `s` : ``} Received`}</SubHeader>
                  )}
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
