import { Avatar, Chip, FormControl, Grid, IconButton, InputLabel, Tooltip } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { ButtonGrid } from 'app/components/Order/OrderDesign';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { nonStandardCategories, yearsOfExperienceList } from 'app/constants';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { SimpleDropdown } from '../Common/SimpleDropdown';
import { SingleLineInput } from '../Common/SingleLineInput';
import { useTranslation } from 'react-i18next';
import { NonStandardRequirementType } from 'app/models/Unit/CreateUnit';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

interface Props {
  isRequired: boolean;
  setIsRequired: React.Dispatch<React.SetStateAction<boolean>>;
  onSkillAddition: (property: string, value: any) => void;
  nonStandardData: NonStandardRequirementType[];
  isdisabled?: boolean;
}

const useStyles = makeStyles()(theme => ({
  addButtonDisabled: {
    color: '#D6D7D9',
    background: 'none !important',
  },
  addButton: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
  },
  chip: {
    maxWidth: '50ch',
    margin: '0 1% 1% 0',
  },

  tooltip: {
    backgroundColor: '#ffffff',
    color: '#333333',
    opacity: 1,
    textAlign: 'left',
    letterSpacing: '0px',
    boxShadow: '0px 3px 6px #00000029',
    border: '1px solid #8C8C8C',
  },
  arrow: {
    color: '#ffffff',
    opacity: 1,
    '&::before': {
      boxShadow: '0px 3px 6px #00000029',
      border: '1px solid #8C8C8C',
    },
  },
  placementBottom: {
    marginBottom: 0,
  },
  icon: {
    borderRadius: 3,
    padding: 9,
    border: '1px solid #cccccc',
    marginTop: 3,
    marginRight: 2,
  },
  iconSize: {
    fontSize: 'larger',
  },

  chipIcon: {
    fontSize: 'large',
    marginLeft: 6,
  },
}));

//@Team Please remove this non standard id since it is hardcoded on UI
let nonStandardID = 9000;
export const NewNonStandardComboSection = (props: Props) => {
  const { onSkillAddition, nonStandardData, isdisabled } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [skill, setSkill] = React.useState<string | null>(null);
  const [nonStandardChips, setNonStandardChips] = React.useState<NonStandardRequirementType[]>(nonStandardData);
  const [hasCategory, setHasCategory] = React.useState(false);
  const [hasNameOfSkill, setHasNameOfSkill] = React.useState(false);
  const { control, watch, setValue } = useFormContext();
  const [showYearsOfExperience, setShowYearsOfExperience] = React.useState(false);
  const [showDocumentRequired, setShowDocumentRequired] = React.useState(false);
  const [documentRequired, setDocumentRequired] = React.useState<boolean | null>(null);
  const category = watch('category');
  const nameOfSkill = watch('nameOfSkill');
  const yearsOfExperience = watch('yearsOfExperience');

  useEffect(() => {
    setHasCategory(category && category !== 'Select');
    setHasNameOfSkill(nameOfSkill ? true : false);
    setShowYearsOfExperience(category && (category === 'Experience' || category === 'Skills'));
    setShowDocumentRequired(category && category === 'Certifications');
    setDocumentRequired(null);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category, nameOfSkill]);

  const handleSkill = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSkill(event.target.value);
  };
  const checkIsAlreadyChosen = () => {
    return nonStandardChips.some(chip => {
      return chip.category === category && chip.value === nameOfSkill;
    });
  };

  /*useEffect(() => {
    props.setIsRequired(true);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nonStandardChips]);*/

  const formatChipLabel = (label1: string, label2: string, label3?: any) => {
    return label1 + ', ' + label2 + (label3 ? ', ' + label3 + 'y' : '');
  };

  const handleClick = () => {
    const isAlreadyChosen = checkIsAlreadyChosen();

    const updatedValue = [
      ...nonStandardChips,
      {
        id: nonStandardID,
        category: category,
        value: nameOfSkill,
        yearsOfExperience: yearsOfExperience ? yearsOfExperience : null,
        isRequired: props.isRequired,
        documentAttachmentRequired: documentRequired ? documentRequired : null,
      },
    ];
    if (!isAlreadyChosen) {
      setNonStandardChips(updatedValue);
      onSkillAddition('nonStandardRequirement', updatedValue);
      nonStandardID += 1;
    }
    setValue('category', nonStandardCategories[0]);
    setValue('nameOfSkill', '');
    setDocumentRequired(null);
    setValue('yearsOfExperience', yearsOfExperienceList[0]);
  };

  const handleDelete = (index: number) => {
    const updatedValue = nonStandardChips.filter((chip, nonStandardIndex) => nonStandardIndex !== index);
    setNonStandardChips(updatedValue);
    onSkillAddition('nonStandardRequirement', updatedValue);
  };

  const handleDocumentRequired = () => {
    setDocumentRequired(!documentRequired);
  };
  return (
    <>
      <Grid container direction="row" spacing={2} style={{ paddingTop: 12, paddingBottom: 12 }}>
        <Grid item xs={3}>
          <FormControl fullWidth variant="filled" disabled={isdisabled}>
            <InputLabel>{t('order.createUnit.nonStandardRequirement.category')}</InputLabel>
            <Controller
              name={'category'}
              control={control}
              onChange={data => data}
              defaultValue={nonStandardCategories[0]}
              render={({ ref, onChange, ...rest }) => (
                <SimpleDropdown
                  id={'createUnitNonStandardRequirementCategoryDropdown'}
                  onChange={newValue => {
                    onChange(newValue);
                  }}
                  defaultInputList={nonStandardCategories}
                  defaultInputValue={nonStandardCategories[0]}
                  inputName="category"
                  marginLeft={0}
                  minWidth={'160px !important'}
                  disabled={isdisabled}
                  {...rest}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <Controller
            name={'nameOfSkill'}
            control={control}
            onChange={data => data}
            defaultValue={null}
            render={({ ref, ...rest }) => (
              <SingleLineInput
                id={'createUnitNonStandardRequirementNameOfNonStandardSkillTxt'}
                inputDefaultValue={''}
                inputLabel={t('order.createUnit.nonStandardRequirement.nameOfNonStandardSkill')}
                inputName={'nameOfSkill'}
                inputValue={skill}
                inputPlaceholder={'Enter'}
                handleChange={handleSkill}
                maxLength={70}
                isdisabled={isdisabled}
                {...rest}
              />
            )}
          />
        </Grid>
        {showYearsOfExperience && (
          <Grid item xs={3}>
            <FormControl fullWidth variant="filled" disabled={isdisabled}>
              <InputLabel>{t('order.createUnit.nonStandardRequirement.yearsOfExperienceTitle')}</InputLabel>
              <Controller
                name={'yearsOfExperience'}
                control={control}
                onChange={data => data}
                defaultValue={yearsOfExperienceList[0]}
                render={({ ref, onChange, ...rest }) => (
                  <SimpleDropdown
                    id={'createUnitNonStandardRequirementYearsOfExperienceTxt'}
                    onChange={newValue => {
                      onChange(newValue);
                    }}
                    defaultInputList={yearsOfExperienceList}
                    defaultInputValue={yearsOfExperienceList[0]}
                    inputName="Years of Experience"
                    marginLeft={0}
                    minWidth={'160px !important'}
                    {...rest}
                  />
                )}
              />
            </FormControl>
          </Grid>
        )}
        {showDocumentRequired && (
          <Grid item>
            <Tooltip
              /* eslint-disable i18next/no-literal-string */
              title={'Document attached with submission'}
              placement="bottom"
              arrow
              classes={{
                tooltip: classes.tooltip,
                arrow: classes.arrow,
                tooltipPlacementBottom: classes.placementBottom,
              }}
            >
              <IconButton
                color="primary"
                id={'createUnitNonStandardRequirementDocumentCheck'}
                edge="end"
                aria-label="document"
                disableFocusRipple
                disableTouchRipple
                disableRipple
                className={`${classes.icon}`}
                onClick={handleDocumentRequired}
                size="small"
              >
                <DescriptionOutlinedIcon
                  style={{
                    fill: `${documentRequired ? '#FFFFFF' : '#B8BABC'}`,
                  }}
                  className={classes.iconSize}
                />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
        <ButtonGrid item>
          <IconButton
            id={'createUnitNonStandardRequirementAddBtn'}
            type="button"
            classes={{ root: classes.addButton, disabled: classes.addButtonDisabled }}
            color="primary"
            disableTouchRipple
            disableFocusRipple
            disabled={!hasCategory || !hasNameOfSkill}
            onClick={() => handleClick()}
            size="small"
          >
            <AddBoxIcon style={{ fontSize: 40, fill: !hasCategory || !hasNameOfSkill ? '#D6D7D9' : '#006FB9' }} />
          </IconButton>
        </ButtonGrid>
      </Grid>
      <div id={'createUnitNonStandardRequirementAddedContainer'}>
        {nonStandardChips &&
          nonStandardChips.map((nonStandardChip, index) => {
            return (
              <Chip
                key={index}
                disabled={isdisabled}
                variant="outlined"
                avatar={
                  <Avatar variant={nonStandardChip.isRequired ? 'primary' : 'green'}>
                    {nonStandardChip.isRequired ? 'R' : 'P'}
                  </Avatar>
                }
                label={
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                    <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      {formatChipLabel(
                        nonStandardChip.category,
                        nonStandardChip.value,
                        nonStandardChip.yearsOfExperience,
                      )}
                    </div>
                    <div>
                      {nonStandardChip.documentAttachmentRequired && (
                        <DescriptionOutlinedIcon
                          sx={theme => ({ fill: theme.palette.components.icons.secondary.backgroundColor })}
                          className={classes.chipIcon}
                        />
                      )}
                    </div>
                  </div>
                }
                className={classes.chip}
                onDelete={() => handleDelete(index)}
              />
            );
          })}
      </div>
    </>
  );
};
