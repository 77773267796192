/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable i18next/no-literal-string */
import { makeStyles } from 'tss-react/mui';

import PersonIcon from '@mui/icons-material/Person';
import { Avatar, Divider, Grid, Tooltip, Typography, Theme } from 'amn-ui-core';
import Attachment from 'app/assets/images/Notification/Inbox/Attachment2x.png';
import { formatPhoneNumber } from 'app/helpers/phoneHelpers';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import styledComponent from 'styled-components';
import { CapitalizeEveryFirstLetter } from 'utils/string/string';
import { getAttachmentFileExtension } from '../Common/AttachmentFileTypes';
import { nameInitial, TextWithEllipses } from '../Common/StringHelper';
import { getElapsedTime } from '../MultiChannel/Sms/Helpers/Helper';
import Pinned from 'app/assets/images/pin.svg';
import UnPinned from 'app/assets/images/unpin.svg';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { useSelector } from 'react-redux';
import { selectCoveredInbox } from 'store/redux-store/user-coverage/selectors';
import { selectUser } from 'oidc/user.selectors';

const useStyles = makeStyles<{
  unreadCount: boolean;
  candidateID: string | number;
  brandID: string | number;
  error: boolean;
}>()((theme: Theme, props) => ({
  root: {
    flexGrow: 1,
    cursor: 'pointer',
    padding: '4% 4% 0 4%',
    position: 'relative',
    backgroundColor: props?.error
      ? theme.palette.system.lightPink
      : props.unreadCount
      ? theme.palette.system.bluePrimary
      : theme.palette.framework.system.white,
    '&:hover': {
      background: props?.error
        ? theme.palette.system.lightPink
        : props.unreadCount
        ? theme.palette.system.blueSecondary
        : theme.palette.framework.system.whisper,
    },
  },
  rootContainer: {},
  avatar: {
    height: '40px',
    width: '40px',
    color: '#FFFFFF',
    fontSize: '18px',
    background: '#5FB057',
    cursor: props.candidateID === '' || !props.brandID ? 'not-allowed' : 'pointer',
    position: 'absolute',
    top: '18px',
  },
  header: {
    display: 'flex',
    cursor: 'pointer',
  },
  readMessages: {
    color: '#555555',
  },
  messageDivider: {
    background: '#ccc',
    width: '280px',
    marginLeft: '15%',
    height: '1px',
  },
  attachmentIcon: {
    width: '20px',
    height: '20px',
    position: 'absolute',
    right: '55px',
  },
  additionalAttachment: {
    color: '#333',
    marginLeft: '10px',
    fontWeight: 500,
    fontSize: '14px',
    marginTop: '3px',
  },
  pinIcon: {
    position: 'absolute',
    right: '20px',
  },
  headerHover: {
    '&:hover': {
      paddingTop: '-9px',
    },
    '& .MuiGrid-root-rootContainer': {
      '&:hover': {
        paddingTop: '-9px',
      },
    },
    '& .MuiGrid-item': {
      '&:hover': {
        paddingTop: '-9px',
      },
    },
  },
  errorIcon: {
    position: 'absolute',
    top: 12,
    right: 77,
  },
}));

const Header = styledComponent(Typography)`
  text-align: left;
  font: normal normal 15px/19px Roboto;
  letter-spacing: 0px;
  color: #0f6fb9;
  opacity: 1;
`;

const SubHeader = styledComponent(Typography)`
  text-align: left;
  font: normal normal normal 13px/18px Roboto;
  font-weight: 400;
  color: #000000;
  opacity: 1;
  margin-right: 5%;
  margin-top: 5px;
  padding-bottom: 2%;
`;

const MessageCounter = styledComponent(Typography)`
  text-align: center;
  font: normal normal normal 13px/19px Roboto;
  letter-spacing: 0px;
  border-radius: 50%;
  background: #0f6fb9;
  color: #ffffff;
  opacity: 1;
  margin: 0 0 0 25px;
  position: absolute;
  right: 85px;
  min-width: 20px;
`;

const ElapsedTime = styledComponent(Typography)`
  text-align: left;
  font: normal normal normal 14px/18px Roboto;
  letter-spacing: 0px;
  color: #555555;
  opacity: 1;
  position: absolute;
  right: 20px;
`;

const OutboundText = styledComponent.span`
  font-weight: 600;
`;

const MAX_MESSAGE_LENGTH = 70;
export const MessageCard = props => {
  const { classes } = useStyles(props, {
    props: props,
  });
  const history = useHistory();
  const {
    direction,
    subheader,
    closeInboxPanel,
    unreadCount,
    brandID,
    publishedOn,
    candidateName,
    onClick,
    candidateID,
    attachments = [],
    pinned,
    id,
    handlePin,
    pinning,
    error,
  } = props;
  const [hover, setHover] = useState<boolean>(false);
  const [pin, setPin] = useState<any>();
  const { activeInbox } = useSelector(selectCoveredInbox);
  const {userInfo} = useSelector(selectUser);
  const allowPinning = (userInfo.employeeId).toString() == activeInbox.coveredUserId

  const handleclick = () => {
    handlePin(id, pinned);
  };

  const handleRecipientNameOnclick = e => {
    e.stopPropagation();
    if (candidateID && brandID) {
      history.push(`/candidate/${candidateID}/${brandID}`);
      closeInboxPanel();
    }
  };
  const onHover = () => {
    setHover(true);
    if (pinned) {
      setPin(UnPinned);
    } else {
      setPin(Pinned);
    }
  };
  const onLeave = () => {
    setHover(false);
  };

  const handledCandidateName = CapitalizeEveryFirstLetter(candidateName.toLowerCase());

  const showAttachment = () => {
    return (
      <div style={{ display: 'inline-flex' }}>
        {attachments && attachments.length > 0 && getAttachmentFileExtension(attachments.find(Boolean) as string)}
        {attachments && attachments.length > 1 && (
          <span className={classes.additionalAttachment}>{`+${attachments.length - 1}`}</span>
        )}
      </div>
    );
  };
  return (
    <div className={classes.root} onMouseEnter={onHover} onMouseLeave={onLeave}>
      <div onClick={onClick} className={classes.rootContainer}>
        <div>
          <Avatar
            onClick={e => candidateID && brandID !== undefined && handleRecipientNameOnclick(e)}
            className={classes.avatar}
          >
            {candidateID ? nameInitial(candidateName) : <PersonIcon />}
          </Avatar>
        </div>
        <div className={classes.headerHover} style={{ textOverflow: 'ellipsis', paddingLeft: '50px' }}>
          <div>
            <div>
              <div className={classes.header}>
                {candidateID !== '' ? (
                  <Tooltip title={handledCandidateName && handledCandidateName.length > 20 ? handledCandidateName : ''}>
                    <Header
                      style={{ fontWeight: unreadCount > 0 ? 'bold' : 'normal', marginRight: '5px' }}
                      variant="h6"
                      onClick={onClick}
                    >
                      {handledCandidateName.length > 20
                        ? `${handledCandidateName.substring(0, 20)}... `
                        : handledCandidateName}
                    </Header>
                  </Tooltip>
                ) : (
                  <Header
                    style={{ fontWeight: unreadCount > 0 ? 'bold' : 'normal', marginRight: '5px' }}
                    variant="h6"
                    onClick={onClick}
                  >
                    {formatPhoneNumber(candidateName)}
                  </Header>
                )}

                {candidateID && (
                  <Header style={{ fontWeight: unreadCount > 0 ? 'bold' : 'normal' }} variant="h6">
                    {`(${candidateID})`}
                  </Header>
                )}
                {unreadCount > 0 && <MessageCounter>{unreadCount}</MessageCounter>}
                {error && <WarningAmberIcon color="error" className={classes.errorIcon} />}
                {attachments?.length > 0 && (
                  <img className={classes.attachmentIcon} src={Attachment} alt={`Attachment`} />
                )}
                <div>
                  { allowPinning && pinning && hover === true ? (
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                    <div
                      onClick={e => {
                        e.stopPropagation();
                        handleclick();
                      }}
                    >
                      <img className={classes.pinIcon} src={pin} alt="pin" />
                    </div>
                  ) : (
                    <ElapsedTime>{getElapsedTime(new Date(publishedOn))}</ElapsedTime>
                  )}
                </div>
              </div>
            </div>
          </div>
          <SubHeader variant="subtitle1">
            {direction === 2 || direction === 'Inbound' ? (
              subheader.length === 0 ? (
                attachments && attachments.length > 0 ? (
                  showAttachment()
                ) : undefined
              ) : (
                <span className={unreadCount > 0 ? undefined : classes.readMessages}>
                  {TextWithEllipses(subheader || '', MAX_MESSAGE_LENGTH)}
                </span>
              )
            ) : (
              <>
                <OutboundText>{`You: `}</OutboundText>
                {subheader.length === 0 ? (
                  attachments && attachments.length > 0 ? (
                    showAttachment()
                  ) : undefined
                ) : (
                  <span className={classes.readMessages}>{TextWithEllipses(subheader, MAX_MESSAGE_LENGTH)}</span>
                )}
              </>
            )}
          </SubHeader>
          {attachments && attachments.length > 0 && subheader.length > 0 && showAttachment()}
        </div>
      </div>
      <Divider className={classes.messageDivider} variant="middle" />
    </div>
  );
};
