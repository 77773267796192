/* eslint-disable i18next/no-literal-string */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { TextField, Box } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import SearchIcon from '@mui/icons-material/Search';
import { UnitClientContactGrid } from './UnitClientContactGrid';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import { UnitCardsTitle, UnitDetailsBody } from '../UnitDesign';
import { SimpleCheckbox } from '../Common/SimpleCheckbox';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FacilityContactsActions } from 'app/components/Facility/FacilityDetails/FacilityTabs/ContactTab/Store/FacilityContacts.redux';
import { selectFacilityDetails } from '@AMIEWEB/Order/Store/OrderCreation.selectors';
import {
  selectContactsSourceData,
  selectPrimaryData,
  selectSelectedData,
} from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/ContactTab/Store/FacilityContacts.selector';

export interface IClientContact {
  contactId: number;
  firstName: string;
  firstNameSortable: string;
  lastName: string;
  name: string;
  lastNameAttributes: any;
  title: string;
  email: string;
  goesBy: string;
  city: string;
  state: string;
  units: string;
  unitAttributes: any;
  unitsSortField: string;
  actions: string;
  actionAttributes: any;
  actionsSortField: string;
  actionsSort: number;
}

const useStyles = makeStyles()(theme => ({
  searchField: {
    height: '1%',
    width: '100%',
    borderBottom: '1px solid #CCCCCC',
  },
  wrapper: {
    backgroundColor: '#FFFFFF !important',
    border: '1px solid #CCCCCC',
    borderRadius: 5,
    minHeight: 300,
  },
  noBorder: {
    border: 'hidden',
  },
  gridAlignment: {
    marginTop: '-6px',
    marginBottom: '11px',
  },
  checkBoxSec: {
    marginBottom: '5px',
  },
}));
export const NewUnitClientContactWrapper = props => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const facilityDetails = useSelector(selectFacilityDetails);
  const contactsGridSourceData = useSelector(selectContactsSourceData);
  const selectedContacts = useSelector(selectSelectedData);
  const primaryData = useSelector(selectPrimaryData);
  const [showPanel, setShowPanel] = useState(false);

  useEffect(() => {
    if (facilityDetails.facilityId !== undefined) {
      dispatch(
        FacilityContactsActions.requestFacilityClientContactsListForUnitEdit({
          id: facilityDetails.facilityId,
          unitContacts: props?.unitContacts?.length > 0 ? props.unitContacts : [],
        }),
      );
    }
  }, []);

  useEffect(() => {
    props.onCheckedRows(selectedContacts);
  }, [selectedContacts]);

  useEffect(() => {
    props.onStarIconClick(primaryData, contactsGridSourceData);
  }, [primaryData]);

  useEffect(() => {
    if (contactsGridSourceData.find(item => item.selected)) {
      setShowPanel(true);
    }
  }, [contactsGridSourceData]);

  const handleSearch = async event => {
    event.target.value.length >= 1
      ? globalSearch(event.target.value.toLowerCase())
      : dispatch(FacilityContactsActions.setFilteredData(contactsGridSourceData));
  };

  const globalSearch = searchInput => {
    const filteredData = contactsGridSourceData.filter(value => {
      return (
        value.firstName.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.lastName.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.goesBy.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.title.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.actionsSortField.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.unitsSortField.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.email.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.city.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.state.toLowerCase().includes(searchInput.toLowerCase())
      );
    });
    dispatch(FacilityContactsActions.setFilteredData(filteredData));
  };

  const handleCheckBoxChangeX = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowPanel(event.target.checked);
  };

  return (
    <UnitDetailsBody variant="outlined">
      <LayoutGrid container direction="row" spacing={1} className={classes.checkBoxSec}>
        <LayoutGridItem item>
          <UnitCardsTitle>{t('Select Client Contacts')}</UnitCardsTitle>
        </LayoutGridItem>
        <SimpleCheckbox
          isChecked={showPanel}
          hasRadio={false}
          handleChecked={handleCheckBoxChangeX}
          checkboxLabel={`Yes`}
          checkboxName={'no-cbx'}
        />
      </LayoutGrid>
      {showPanel ? (
        <Box className={classes.wrapper}>
          <LayoutGrid container direction="column" spacing={1}>
            <LayoutGridItem item xs={12}>
              <TextField
                variant="outlined"
                color="primary"
                placeholder="Search"
                autoComplete="on"
                onChange={handleSearch}
                fullWidth
                classes={{ root: classes.searchField }}
                InputProps={{
                  startAdornment: <SearchIcon style={{ opacity: 0.6 }} />,
                  classes: { notchedOutline: classes.noBorder },
                }}
              />
            </LayoutGridItem>
            <LayoutGridItem item xs={12} className={classes.gridAlignment}>
              {contactsGridSourceData && <UnitClientContactGrid gridTitle={t('facility.units.unitContacts.title')} />}
            </LayoutGridItem>
          </LayoutGrid>
        </Box>
      ) : null}
    </UnitDetailsBody>
  );
};
