/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {
  Grid,
  Theme,
  ThemeProvider,
  StyledEngineProvider,
  useTheme,
  Chip,
  Avatar,
  Autocomplete,
  Checkbox,
  TextField,
  AutocompleteChangeReason,
} from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { reqPref } from 'app/enums/Unit';
import { Controller, useFormContext } from 'react-hook-form';
import { ReqPrefSection } from '../Common/ReqPrefSection';
import { EMRType } from 'app/models/Unit/CreateUnit';
import { UnitCardsTitle } from '../UnitDesign';
import { useSelector } from 'react-redux';
import { formatChipLabel } from '../helper';
import { SimpleCheckbox } from '../Common/SimpleCheckbox';
import { selectEmrOptionsList } from '@AMIEWEB/Order/Store/OrderCreation.selectors';

import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const useStyles = makeStyles()((theme: Theme) => ({
  topWrapper: {
    paddingTop: 20,
  },
  emrTitle: {
    marginRight: theme.spacing(3),
  },
  blue: {
    color: '#FFFFFF !important',
    backgroundColor: '#006FB9',
  },
  chip: {
    maxWidth: '50ch',
    margin: '0 5px 10px 0',
  },
  green: {
    color: '#FFFFFF !important',
    backgroundColor: theme.palette.components.avatar.green.backgroundColor,
  },
  textareastyle: {
    backgroundColor: '#fff',
    border: 'thin #e7e2e2 solid',
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&:focus': {
      backgroundColor: '#fff',
    },
    '&.Mui-focused': {
      backgroundColor: '#fff',
    },
  },
}));

interface IEmr {
  isdisabled?: boolean;
  hasRadio?: boolean;
}

export const NewEMR = (props: IEmr) => {
  const { isdisabled } = props;
  const { classes } = useStyles();
  const theme = useTheme();
  const { control, getValues, watch, setValue, reset } = useFormContext();
  const watchValue = watch('emr');

  const showPanel = watchValue !== undefined && watchValue !== null;
  const emrOptions = useSelector(selectEmrOptionsList);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const handleDelete = (emr, existingChps) => {
    const vals = existingChps.values.filter(t => t !== null && t !== emr);
    setValue('emr.values', vals);
  };

  return (
    <>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Grid container direction="row" className={classes.topWrapper}>
            <Grid item>
              <UnitCardsTitle className={classes.emrTitle}>{`EMR`}</UnitCardsTitle>
            </Grid>
            <Controller
              control={control}
              name={'emr'}
              render={({ ref, onChange, ...rest }) => {
                return (
                  <SimpleCheckbox
                    id={'createUniEMREnableCheck'}
                    isChecked={watchValue !== undefined && watchValue !== null ? true : false}
                    isdisabled={isdisabled}
                    hasRadio={false}
                    handleChecked={(event: React.ChangeEvent<HTMLInputElement>) => {
                      if (event.target.checked) {
                        setValue('emr', {
                          isRequired: true,
                          values: [],
                        } as EMRType);
                      } else {
                        const existingValues = getValues();
                        reset({
                          ...existingValues,
                          emr: null,
                        });
                      }
                    }}
                    checkboxLabel={`Yes`}
                    checkboxName={'no-cbx'}
                  />
                );
              }}
            />
          </Grid>
          {showPanel && (
            <Grid container item direction="column">
              <Controller
                control={control}
                name={'emr.isRequired'}
                render={({ ref, onChange, ...rest }) => {
                  return (
                    <ReqPrefSection
                      isRequired={rest.value ?? false}
                      handleReqPref={event => onChange(event.target.value === reqPref.req)}
                      isdisabled={isdisabled}
                      id={'createUnitEMRReqPrefRadio'}
                    />
                  );
                }}
              />
              <Grid item xs={3} style={{ paddingBottom: '13px' }}>
                <Controller
                  name={'emr.values'}
                  control={control}
                  render={({ ref, onChange, ...rest }) => {
                    return (
                      <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={theme}>
                          <Autocomplete
                            disablePortal
                            multiple
                            fullWidth
                            options={emrOptions as any[]}
                            disableCloseOnSelect
                            defaultValue={!!watchValue ? watchValue.values : []}
                            disabled={isdisabled}
                            value={!!watchValue ? watchValue.values : []}
                            id={'createUnitEMRSelectCheckbox'}
                            renderOption={(p, option, { selected }) => (
                              <li id={option.label} {...p}>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  color="primary"
                                  checked={!!watchValue ? watchValue.values?.some(x => x == option.label) : false}
                                  disabled={false}
                                />
                                {option.label}
                              </li>
                            )}
                            popupIcon={<ExpandMoreOutlinedIcon />}
                            renderTags={() => null}
                            renderInput={params => (
                              <TextField
                                {...params}
                                variant="filled"
                                label={`EMR`}
                                disabled={isdisabled}
                                InputProps={{
                                  ...params.InputProps,
                                  className: classes.textareastyle,
                                }}
                                type="text"
                              />
                            )}
                            onChange={(event, choice, reason: AutocompleteChangeReason, details) => {
                              if (!!watchValue && watchValue.values?.length > 0) {
                                const keys = watchValue.values ?? [];
                                if (!!keys && keys?.some(x => x === details.option.label)) {
                                  const v = keys.filter(x => x !== details.option.label);
                                  onChange(v);
                                  /*setValue('qualification.emr', {
                                  isRequired: watchValue.isRequired,
                                  values: v,
                                } as EMRType);*/
                                } else {
                                  const v = [...keys, details.option.label];
                                  onChange(v);
                                  /*setValue('qualification.emr', {
                                  isRequired: watchValue.isRequired,
                                  values: v,
                                } as EMRType);*/
                                }
                              } else {
                                const v = [details.option.label];
                                onChange(v);
                                /*setValue('qualification.emr', {
                                isRequired: watchValue.isRequired,
                                values: v,
                              } as EMRType);*/
                              }
                            }}
                            {...rest}
                          />
                        </ThemeProvider>
                      </StyledEngineProvider>
                    );
                  }}
                />
              </Grid>
            </Grid>
          )}
        </ThemeProvider>
      </StyledEngineProvider>
      {showPanel &&
        watchValue &&
        watchValue.values &&
        Array.isArray(watchValue.values) &&
        watchValue.values.map((emrChip, index) => {
          return (
            <Chip
              disabled={isdisabled}
              variant="outlined"
              key={index}
              avatar={
                <Avatar className={` ${watchValue.isRequired ? classes.blue : classes.green}`}>
                  {watchValue.isRequired ? 'R' : 'P'}
                </Avatar>
              }
              label={
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                  <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{formatChipLabel(emrChip)}</div>
                </div>
              }
              className={classes.chip}
              onDelete={() => handleDelete(emrChip, watchValue)}
            />
          );
        })}
    </>
  );
};
