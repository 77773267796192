import React from 'react';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import { useTranslation } from 'react-i18next';
import { Box, Skeleton, Typography } from 'amn-ui-core';
import { UnitCard } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/CustomComponents/UnitCard';
import { useUnitDetailStyles } from './UnitDetails';
import { StatusIndicator, isAuthorizedForAddEdit, useSelectedUnitDetails, useUnitStatusInActive } from './unitHelper';
import { missingField } from '@AMIEWEB/Notification/MultiChannel/Sms/CredentialingAnalyst/PlacementDisplay';
import { CustomTooltip } from '@AMIEWEB/Common';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { selectUser } from 'oidc/user.selectors';
import { usePromiseTracker } from 'react-promise-tracker';

const UnitReferences = () => {
  const { t } = useTranslation();
  const { classes } = useUnitDetailStyles();
  const selectedUnitDetail = useSelectedUnitDetails();
  const userData = useSelector(selectUser);
  const isAuthorized = isAuthorizedForAddEdit(userData);
  const isUnitInActive = useUnitStatusInActive();
  const { promiseInProgress: isFacilityWithFilterDetailsLoading } = usePromiseTracker({
    area: 'get-facility-details-with-filters',
    delay: 0,
  });
  return (
    <UnitCard
      title={t('facility.references.title')}
      showEditIcon={isAuthorized && !isUnitInActive}
      rightIcon={true}
      skeletonWidth={91}
      isDataLoading={isFacilityWithFilterDetailsLoading}
    >
      <LayoutGrid container direction="column" rowSpacing={2} sx={{ p: '12px' }}>
        {isFacilityWithFilterDetailsLoading && (
          <LayoutGridItem container style={{ display: 'flex' }}>
            <Skeleton variant="circular" width={28} height={28} />
          </LayoutGridItem>
        )}
        {selectedUnitDetail?.reference?.isRequired && !isFacilityWithFilterDetailsLoading && (
          <LayoutGridItem container style={{ display: 'flex' }}>
            <CustomTooltip
              tooltipContent={t('facility.units.unitDetails.additionalInformation.references.requiredTooltip')}
            >
              <Box>
                <StatusIndicator isRequired={true} hideToolTip />
              </Box>
            </CustomTooltip>
          </LayoutGridItem>
        )}

        <LayoutGridItem container gap={'16px'} style={{ display: 'flex' }}>
          <Box sx={{ width: '140px' }}>
            {isFacilityWithFilterDetailsLoading ? (
              <Skeleton variant="rectangular" width={131} height={13} />
            ) : (
              <Typography className={classes.textBold}>
                {t('facility.units.unitDetails.additionalInformation.references.numberOfReferencesToStartUnit')}
              </Typography>
            )}
          </Box>
          <Box>
            {isFacilityWithFilterDetailsLoading ? (
              <Skeleton variant="rectangular" width={37} height={13} />
            ) : (
              <Typography sx={{ fontSize: '14px', color: 'system.black' }}>
                {selectedUnitDetail?.reference?.numberOfReferenceRequired || missingField}
              </Typography>
            )}
          </Box>
        </LayoutGridItem>
        <LayoutGridItem container gap={'16px'} style={{ display: 'flex' }}>
          <Box sx={{ width: '140px' }}>
            {isFacilityWithFilterDetailsLoading ? (
              <Skeleton variant="rectangular" width={131} height={13} />
            ) : (
              <Typography className={classes.textBold}>
                {t('facility.units.unitDetails.additionalInformation.references.numberOfReferencesToRFOUnit')}
              </Typography>
            )}
          </Box>
          <Box>
            {isFacilityWithFilterDetailsLoading ? (
              <Skeleton variant="rectangular" width={37} height={13} />
            ) : (
              <Typography sx={{ fontSize: '14px', color: 'system.black' }}>
                {selectedUnitDetail?.reference?.numberOfReferenceToRFO || missingField}
              </Typography>
            )}
          </Box>
        </LayoutGridItem>
        <LayoutGridItem container gap={'16px'}>
          <Box sx={{ width: '140px' }}>
            {isFacilityWithFilterDetailsLoading ? (
              <Skeleton variant="rectangular" width={85} height={13} />
            ) : (
              <Typography className={classes.textBold}>
                {t('facility.units.unitDetails.additionalInformation.references.withinTheLast')}
              </Typography>
            )}
          </Box>
          <Box>
            {isFacilityWithFilterDetailsLoading ? (
              <Skeleton variant="rectangular" width={59} height={13} />
            ) : (
              <Typography sx={{ fontSize: '14px', color: 'system.black' }}>
                {selectedUnitDetail?.reference?.entireWorkHistory
                  ? t('facility.units.unitDetails.additionalInformation.references.entireTime')
                  : `${selectedUnitDetail?.reference?.withinNumberOfMonths ?? missingField} ${t(
                      'facility.units.unitDetails.additionalInformation.references.months',
                    )}`}
              </Typography>
            )}
          </Box>
        </LayoutGridItem>
        <LayoutGridItem container gap={'16px'}>
          <Box sx={{ width: '140px' }}>
            {isFacilityWithFilterDetailsLoading ? (
              <Skeleton variant="rectangular" width={120} height={13} />
            ) : (
              <Typography className={classes.textBold}>
                {t('facility.units.unitDetails.additionalInformation.references.supervisor')}
              </Typography>
            )}
          </Box>
          <Box>
            {isFacilityWithFilterDetailsLoading ? (
              <Skeleton variant="rectangular" width={34} height={13} />
            ) : (
              <Typography sx={{ fontSize: '14px', color: 'system.black' }}>
                {selectedUnitDetail?.reference?.referenceFromSupervisor
                  ? t('order.orderDetails.buttonConfirmation.Yes')
                  : t('order.orderDetails.buttonConfirmation.No')}
              </Typography>
            )}
          </Box>
        </LayoutGridItem>
        <LayoutGridItem container gap={'16px'}>
          <Box sx={{ width: '140px' }}>
            {isFacilityWithFilterDetailsLoading ? (
              <Skeleton variant="rectangular" width={75} height={13} />
            ) : (
              <Typography className={classes.textBold}>
                {t('facility.units.unitDetails.additionalInformation.references.lastUpdated')}
              </Typography>
            )}
          </Box>
          <Box>
            {isFacilityWithFilterDetailsLoading ? (
              <Skeleton variant="rectangular" width={92} height={13} />
            ) : (
              <Typography sx={{ fontSize: '14px', color: 'system.black' }}>
                {selectedUnitDetail?.reference?.lastUpdated
                  ? moment(selectedUnitDetail?.reference?.lastUpdated)?.format('MM/DD/YYYY hh:mm A')
                  : missingField}
              </Typography>
            )}
          </Box>
        </LayoutGridItem>
        <LayoutGridItem container gap={'16px'}>
          <Box sx={{ width: '140px' }}>
            {isFacilityWithFilterDetailsLoading ? (
              <Skeleton variant="rectangular" width={75} height={13} />
            ) : (
              <Typography className={classes.textBold}>
                {t('facility.units.unitDetails.additionalInformation.references.lastUpdatedBy')}
              </Typography>
            )}
          </Box>
          <Box>
            {isFacilityWithFilterDetailsLoading ? (
              <Skeleton variant="rectangular" width={92} height={13} />
            ) : (
              <Typography sx={{ fontSize: '14px', color: 'system.black' }}>
                {selectedUnitDetail?.reference?.lastUpdatedByFirstName ||
                selectedUnitDetail?.reference?.lastUpdatedByLastName
                  ? `${selectedUnitDetail?.reference?.lastUpdatedByFirstName} ${selectedUnitDetail?.reference?.lastUpdatedByLastName}`
                  : missingField}
              </Typography>
            )}
          </Box>
        </LayoutGridItem>
      </LayoutGrid>
    </UnitCard>
  );
};

export default UnitReferences;
