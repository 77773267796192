/* eslint-disable i18next/no-literal-string */
import React, { useEffect } from 'react';
import {
  IconButton,
  Paper,
  Grid,
  Theme,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Collapse,
  ClickAwayListener,
  Box,
} from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { keyframes } from 'tss-react';
import { useInjectReducer } from 'redux-injectors';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MenuIcon from '@mui/icons-material/Menu';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectNavigationStick } from './NavigationStick.selectors';
import {
  navigationStickSliceKey,
  navigationStickActions,
  navigationStickReducers,
  INavigation,
  NavigationOption,
  NavigationSubTypes,
  NavigationStick,
} from './NavigationStick.redux';
import { getStickIcons } from './iconHelper';
import { trackEvent } from 'app-insights/appInsightsTracking';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { colors } from 'styles/styleVariables';
import { PrivateComponent } from '../Common/PrivateComponent/PrivateComponent';
import { selectUser } from 'oidc/user.selectors';
import { DEFAULT_HOME_PAGE_LOCAL_STORAGE_KEY } from '../AppHome/helper';
import { isAccountManagement, isCredentialing, isRecruitment, isGrantedAllDashboards } from 'oidc/userRoles';
import { Authorized } from 'oidc/userHelper';
import { breadcrumbActions } from '../Common/BreadcrumbNavigation/BreadcrumbNavigation.redux';
import { globalSearchActions, initialSearchAttributes } from 'store/redux-store/global-search/slice';
import { useRecentHistoryNavigation } from './RecentHistoryItems';
import { isNullOrUndefined } from 'app/helpers/objectHelpers';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { useDecision } from '@optimizely/react-sdk';
import { OverflowTooltip } from '@AMIEWEB/Common';
import { HomePage } from '@AMIEWEB/AppHome/AppHome';
import {
  ff_placement_ui_candidatecontracts_sidemenu,
  ff_placement_ui_clientconfirmation_sidemenu,
  ff_placement_ui_templates_sidemenu,
  ff_pricing_ui_pricingworkdesk,
  ff_shared_ui_auto_task_management_sidebar,
} from 'app/constants/FeatureFlags/Sidenav/Keys';
import { ff_ui_showhomecoverage } from 'app/constants/FeatureFlags/EmployeeCoverage/keys';
import { userRoles } from 'oidc/userRoles';
import { openInNewTab } from 'app/helpers/openInNewTab';
import { gridStateActions } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.redux';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    height: '100vh',
    background: '#52429A',
    borderRight: '1px solid #F5F5F5',
  },
  drawerCollapse: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawer: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  collapseIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 49,
    color: '#F5F5F5',
  },
  headerIcon: {
    color: '#F5F5F5',
  },
  listItem: {
    display: 'flex',
    flexDirection: 'column',
  },
  listItemGutters: {
    paddingLeft: 8,
    paddingRight: 4,
  },
  borderSelection: {
    borderLeft: '5px solid',
    borderImage: 'linear-gradient(180deg, #009AD9 0%, #009AD9 0%, #52429A 100%) 1 100%',
  },
  invertItemColor: {
    filter: `invert(50%) brightness(294%)`,
  },
  listIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '30px',
  },
  option: {
    '&:hover': {
      backgroundColor: '#372C67',
    },
    display: 'flex',
    color: 'white',
  },
  optionSelected: {
    backgroundColor: '#F5F5F5',
  },
  menuInner: {
    width: '130px',
    zIndex: 100,
    paddingLeft: '10px',
    top: '0px',
    marginLeft: '-160px',
    opacity: '0',
  },
  menuInnerCollapsed: {
    '&.navigation-collapsed': {
      marginLeft: '0px',
      opacity: '1',
      animation: `${keyframes`
      0% {
        opacity: 1;
      }
      15% {
        opacity: 0;
      }
      100% {
        opacity: 0;
        margin-left: -160px;
      }
      `} 150ms linear 1 normal forwards`,
    },
  },
  menuInnerExpanded: {
    '&.navigation-expanded': {
      marginLeft: '-160px',
      opacity: '0',
      animation: `${keyframes`
      75% {
        opacity: 0;
      }
      100% {
        opacity: 1;
        margin-left: 0px;
      }
      `} 150ms linear 1 normal forwards`,
    },
  },
  menuList: {
    position: 'absolute',
    zIndex: theme.zIndex.drawer + 2,
    backgroundColor: '#52429A',
    boxShadow: '4px -1px 4px -1px rgba(0,0,0,0.24)',
  },
  menuListExpanded: {
    height: 'unset',
    paddingBottom: '0px',
    animation: `${keyframes`
    1% {
      height: 100vh;
      padding-bottom: 50px;
    }
    100% {
      height: 100vh;
      padding-bottom: 50px;
    }
    `} 150ms linear 1 normal forwards`,
  },
  menuListCollapsed: {
    height: '100vh',
    paddingBottom: '50px',
    animation: `${keyframes`
    0% {
      height: 100vh;
      padding-bottom: 50px;
    }
    98% {
      height: 100vh;
      padding-bottom: 50px;
    }
    99% {
      height: unset;
      padding-bottom: 0px;
    }
    100% {
      height: unset;
      padding-bottom: 0px;
    }
    `} 150ms linear 1 normal forwards`,
  },
  menuListInner: {
    width: '100%',
    padding: '0px 0px',
  },
  menuItem: {
    padding: '0px 5px',
    cursor: 'default',
  },
  menuItemClickable: {
    cursor: 'pointer',
  },
  menuItemSelected: {
    display: 'inline-block',
  },
  subMenuItemSelectedRoot: {
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.04)',
    },
  },
  subMenuItemRoot: {
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.1)',
    },
  },
  icon: {
    height: 'min-content',
    minHeight: '26px',
  },
  expandIcon: {
    width: '20px',
  },
  font: {
    [theme.breakpoints.up('xs')]: {
      fontSize: '12px !important',
    },
  },
  lvl2SubMenuItem: {
    color: '#AFA0F5',
  },
  lvl2SubMenuItemSelected: {
    color: '#7D6FBE',
  },
  inactiveIcon: {
    cursor: 'no-drop',
  },
  overflowStyle: {
    maxWidth: 110,
  },
  overflowtooltip: {
    padding: '7px 12px',
  },
  activeIcon: {
    cursor: 'pointer',
  },
  listWrapperExpanded: {
    width: 'auto',
    animation: `${keyframes`
    0% {
      width: auto;
    }
    100% {
      width: 181px;
    }
    `} 150ms linear 1 normal forwards`,
  },
  listWrapperCollapsed: {
    width: '181px',
    animation: `${keyframes`
    0% {
      width: 181px;
    }
    100% {
      width: auto;
    }
    `} 150ms linear 1 normal forwards`,
  },
}));

export const SideNavStick = () => {
  useInjectReducer({ key: navigationStickSliceKey, reducer: navigationStickReducers });
  const [homeCoverage] = useDecision(ff_ui_showhomecoverage);
  const [expandSubMenu, setExpandSubMenu] = React.useState<{ [menuKey: string]: boolean }>({});
  const [navigationExpandedByHover, setNavigationExpandedByHover] = React.useState<boolean>(false);

  const { classes, cx } = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { navigationMenu, selectedMenu, selectedSubMenu, navigationExpanded } = useSelector(selectNavigationStick);
  const appUser = useSelector(selectUser);
  useRecentHistoryNavigation();
  const [pricingWorkDeskDecision] = useDecision(ff_pricing_ui_pricingworkdesk);
   const brainReportURL = `${globalThis?.app?.env?.REACT_APP_REPORT_BRAIN_URL}`
  const r108ReportURL = `${globalThis?.app?.env?.REACT_APP_REPORT_R108_URL}`
  const setHomePage = (path, useSubMenu = false) => {
    // determine home page by user role
    const foundDefaultPage = navigationMenu[0].subMenu?.find(
      item => item.key === localStorage.getItem(DEFAULT_HOME_PAGE_LOCAL_STORAGE_KEY),
    );

    let isPricingWorkDeskDefault = false;
    if (foundDefaultPage?.key === 'pricingWorkDesk' && !pricingWorkDeskDecision?.enabled) {
      localStorage.removeItem(DEFAULT_HOME_PAGE_LOCAL_STORAGE_KEY);
      isPricingWorkDeskDefault = true;
    }
    if (
      appUser.userInfo?.multiroled &&
      foundDefaultPage &&
      Authorized(foundDefaultPage.roles || [], appUser.userInfo) &&
      path === '/' &&
      !isPricingWorkDeskDefault
    ) {
      if (useSubMenu || !selectedSubMenu) dispatch(navigationStickActions.setSelectedSubMenu(foundDefaultPage));
    } else if (path === '/') {
      // application root
      if (isGrantedAllDashboards(appUser.userInfo?.roles || []) || isRecruitment(appUser.userInfo?.roles || []))
        dispatch(
          navigationStickActions.setSelectedSubMenu(
            navigationMenu[0].subMenu?.find(item => item.key === 'recruiterWorkDesk'),
          ),
        );
      else if (isCredentialing(appUser.userInfo?.roles || []))
        dispatch(
          navigationStickActions.setSelectedSubMenu(
            navigationMenu[0].subMenu?.find(item => item.key === 'credentialingWorkDesk'),
          ),
        );
      else if (isAccountManagement(appUser.userInfo?.roles || []))
        dispatch(
          navigationStickActions.setSelectedSubMenu(
            navigationMenu[0].subMenu?.find(item => item.key === 'accountManagementWorkDesk'),
          ),
        );
    } else if (path.startsWith('/placementreminders') || path.startsWith('/expiringcredentials')) {
      dispatch(
        navigationStickActions.setSelectedSubMenu(
          navigationMenu[0].subMenu?.find(item => item.key === 'credentialingWorkDesk'),
        ),
      );
    }
  };

  // set defaults for menu on first render
  useEffect(() => {
    if (!selectedSubMenu) setHomePage(window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname, pricingWorkDeskDecision]);

  useEffect(() => {
    if (navigationMenu?.length > 0) {
      const homeValue = navigationMenu?.find(item => item?.key === NavigationStick.home);
      const isCoverageOptionExists = homeValue?.subMenu?.some(item => item?.key === NavigationStick.coverage);
      const defaultPage = localStorage.getItem(DEFAULT_HOME_PAGE_LOCAL_STORAGE_KEY);
      if (isCoverageOptionExists && defaultPage === HomePage.COVERAGE) setHomePage(window.location.pathname, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigationMenu]);

  useEffect(() => {
    if (homeCoverage?.enabled) {
      const NavigationOptions = [...navigationMenu];
      const homeValueIndex = NavigationOptions?.findIndex(item => item.key === 'home');
      const homeValue = NavigationOptions?.find(item => item.key === 'home');
      const subOptions = [...homeValue?.subMenu];
      const isCoverageOptionExists = subOptions?.some(item => item?.key === 'coverage');
      if (!isCoverageOptionExists) {
        const newNavigationPath: NavigationOption<NavigationSubTypes, string> = {
          key: 'coverage',
          name: 'home.coverageWorkDesk.coverageTab',
          path: '/',
          roles: [userRoles.all],
        };
        subOptions?.push(newNavigationPath);
        const updatedHomeValue = { ...homeValue, subMenu: subOptions };
        NavigationOptions[homeValueIndex] = { ...updatedHomeValue };
      }
      dispatch(navigationStickActions.setNavigationNewOptions(NavigationOptions));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [homeCoverage]);

  const navigate = item => {
    //to reset grids state
    if(!window.location.pathname.includes(item.path)){
      dispatch(
        gridStateActions.reset(),
      );
    }
    /**Reset action to remove existing page breadcrumbs */
    dispatch(breadcrumbActions.reset());
    dispatch(globalSearchActions.setDrawerData({ open: false, data: undefined }));
    if (item.path) history.push(item.path);
    else if (['r108'].includes(item?.key))
      openInNewTab(r108ReportURL);
    else if (['brain'].includes(item?.key))
      openInNewTab(brainReportURL);  
    else if (item.pushState) window.history.pushState(null, '', `${window.location.pathname}${item.pushState}`);
  };

  const handleCustomAction = (
    subMenuItem: INavigation | NavigationOption<NavigationSubTypes> | NavigationOption<string>,
  ) => {
    switch (subMenuItem.action.type) {
      case 'candidateDrawer':
        dispatch(globalSearchActions.setDrawerData({ open: false, data: undefined }));
        dispatch(globalActions.resetAllRecentHistoryDrawers());
        dispatch(globalActions.setDetailsPageDrawer({ open: false }));
        dispatch(globalActions.setSmsIconDrawer({ open: false }));
        dispatch(globalActions.setCandidateDrawerData(subMenuItem.action.data));
        break;
      case 'placementDrawer':
        history.push({
          pathname: `/placement/${subMenuItem.action.data?.placementId}`,
          state: { navigateBack: true },
        });
        break;
      case 'orderDrawer':
        dispatch(globalSearchActions.setDrawerData({ open: false, data: undefined }));
        dispatch(globalActions.resetAllRecentHistoryDrawers());
        dispatch(globalActions.setOrderDrawerData(subMenuItem.action.data));
        break;
      case 'facilityDrawer':
        dispatch(globalSearchActions.setDrawerData({ open: false, data: undefined }));
        dispatch(globalActions.resetAllRecentHistoryDrawers());
        dispatch(globalActions.setFacilityDrawerData(subMenuItem.action.data));
        break;
      default:
        break;
    }
  };

  const menuItemClicked = (menuItem: INavigation) => {
    if (!menuItem.disabled) {
      const a = { ...expandSubMenu, [menuItem.key]: true };
      setExpandSubMenu(a);
      setNavigationExpandedByHover(false);
      dispatch(navigationStickActions.expandNavigation(true));
      dispatch(globalSearchActions.setShowPreferenceFilterDropdown(true));
      if (!['report', 'recentHistory', 'settings'].includes(menuItem?.key))
        dispatch(navigationStickActions.setSelectedSubMenu(undefined));
      if (selectedMenu?.key !== menuItem.key && !['report', 'recentHistory', 'settings'].includes(menuItem?.key))
        dispatch(navigationStickActions.setSelectedMenu(menuItem));
      if (menuItem.key === 'home') {
        dispatch(globalSearchActions.setSearchOptions(initialSearchAttributes));
        setHomePage(menuItem.path, true);
        trackEvent({
          type: 'navigation',
          name: 'Home button clicked',
        });
      }
      navigate(menuItem);
    }
  };

  const subMenuItemClicked = (
    event,
    menuItem: INavigation,
    subMenuItem: NavigationOption<NavigationSubTypes> | NavigationOption<string>,
  ) => {
    if (
      !subMenuItem.disabled &&
      (!subMenuItem.enableOnlyForRoute ||
        (subMenuItem.enableOnlyForRoute && window.location.pathname.startsWith(subMenuItem.enableOnlyForRoute)))
    ) {
      if (subMenuItem.action) {
        handleCustomAction(subMenuItem);
        event.preventDefault();
        event.stopPropagation();
        return;
      }

      setNavigationExpandedByHover(false);
      if (selectedMenu?.key !== menuItem.key) dispatch(navigationStickActions.setSelectedMenu(menuItem));
      dispatch(navigationStickActions.setSelectedSubMenu(subMenuItem));
      if (menuItem.key === 'home') {
        dispatch(globalSearchActions.setSearchOptions(initialSearchAttributes));
        trackEvent({
          type: 'navigation',
          name: 'Home button clicked',
        });
      }
      navigate(subMenuItem);
    }
    event.preventDefault();
    event.stopPropagation();
  };

  const toggleMenu = () => {
    const open = !navigationExpanded;
    if (open) {
      dispatch(navigationStickActions.expandNavigation(true));
    } else {
      closeMenu();
    }
  };

  const closeMenu = () => {
    if (navigationExpanded) {
      setExpandSubMenu({});
      dispatch(navigationStickActions.expandNavigation(false));
    }
  };

  const expandCollapseSubMenu = (
    menuItem: INavigation | NavigationOption<NavigationSubTypes, string> | NavigationOption<string>,
    parentMenuItem?: INavigation | NavigationOption<NavigationSubTypes, string> | NavigationOption<string>,
  ) => {
    setExpandSubMenu({ ...expandSubMenu, [menuItem.key]: !expandSubMenu[menuItem.key] });
    if (parentMenuItem?.singleSubMenuExpandable && parentMenuItem?.subMenu) {
      // close all other sub menus in the parent sub menu list
      parentMenuItem.subMenu?.forEach(item => {
        if (item.key !== menuItem.key) {
          setExpandSubMenu(prev => ({
            ...prev,
            [item.key]: false,
          }));
        }
      });
    }
  };

  return (
    <ClickAwayListener onClickAway={closeMenu}>
      <Paper
        square
        classes={{ root: `${classes.root} ${navigationExpanded ? classes.drawer : classes.drawerCollapse}` }}
      >
        <div className={classes.collapseIcon}>
          <IconButton color="primary" disableFocusRipple disableRipple disableTouchRipple onClick={toggleMenu} size="small">
            {navigationExpanded ? (
              <ArrowBackIcon className={classes.headerIcon} />
            ) : (
              <MenuIcon className={classes.headerIcon} />
            )}
          </IconButton>
        </div>
        <Divider />

        <div
          style={{ position: 'relative', height: '100vh', overflow: 'hidden' }}
          className={cx({
            [classes.listWrapperExpanded]: navigationExpanded,
            [classes.listWrapperCollapsed]: !navigationExpanded,
          })}
        >
          <List
            id="global-nav-list"
            disablePadding
            className={cx(classes.menuList, {
              [classes.menuListExpanded]: navigationExpanded,
              [classes.menuListCollapsed]: !navigationExpanded,
            })}
            sx={{ overflowY: 'auto' }}
            onMouseEnter={() => {
              if (!navigationExpanded) {
                toggleMenu();
                setNavigationExpandedByHover(true);
              }
            }}
            onMouseLeave={() => {
              if (navigationExpandedByHover) {
                setNavigationExpandedByHover(false);
                closeMenu();
              }
            }}
          >
            {navigationMenu.map((menuItem, index) => (
              <PrivateComponent roles={menuItem.roles} key={index}>
                <NavMenuItem
                  menuItem={menuItem}
                  selectedMenu={selectedMenu}
                  selectedSubMenu={selectedSubMenu}
                  index={index}
                  navigationExpanded={navigationExpanded}
                  expandSubMenu={expandSubMenu}
                  menuItemClicked={menuItemClicked}
                  expandCollapseSubMenu={expandCollapseSubMenu}
                  subMenuItemClicked={subMenuItemClicked}
                />
              </PrivateComponent>
            ))}
          </List>
        </div>
      </Paper>
    </ClickAwayListener>
  );
};

const NavMenuItem = ({ ...props }) => {
  const { menuItem, selectedMenu, selectedSubMenu, index, navigationExpanded, expandSubMenu } = props;
  const { classes, cx } = useStyles();
  const { t } = useTranslation();

  const { isMenuItemSelected, isSubMenuSelected } = React.useMemo(
    () => ({
      isMenuItemSelected: selectedMenu?.key === menuItem.key,
      isSubMenuSelected:
        selectedSubMenu && menuItem.subMenu?.some(x => (x.subMenu ?? []).map(i => i.key).includes(selectedSubMenu.key)),
    }),
    [selectedMenu, selectedSubMenu, menuItem],
  );

  return (
    <ListItem
      classes={{
        root: cx(classes.listItem, {
          [classes.borderSelection]: isMenuItemSelected,
        }),
        gutters: classes.listItemGutters,
      }}
      key={menuItem.tag}
      className={cx({
        [classes.option]: !isMenuItemSelected && !isSubMenuSelected,
        [classes.optionSelected]: isMenuItemSelected || isSubMenuSelected,
      })}
    >
      <ListItemIcon
        classes={{
          root: `${classes.listIcon}`,
        }}
      >
        <Grid container wrap="nowrap" style={{ marginLeft: '0px' }}>
          <Grid
            item
            className={cx(classes.icon, {
              [classes.invertItemColor]: !isMenuItemSelected && !isSubMenuSelected,
              [classes.inactiveIcon]: menuItem.disabled,
              [classes.activeIcon]: !menuItem.disabled && menuItem.clickable !== false,
            })}
            onClick={() => {
              if (menuItem.clickable !== false) props.menuItemClicked(menuItem);
            }}
          >
            {getStickIcons(menuItem.icon)}
          </Grid>
          <Grid item>
            {
              <div
                id={`navigation-expandable-${index}`}
                className={cx(classes.menuInner, classes.menuInnerCollapsed, classes.menuInnerExpanded, {
                  [classes.menuItemSelected]: isMenuItemSelected,
                  'navigation-expanded': navigationExpanded === true,
                  'navigation-collapsed': navigationExpanded === false,
                })}
              >
                <List
                  disablePadding
                  className={classes.menuListInner}
                  style={{
                    color: isMenuItemSelected || isSubMenuSelected ? colors.amnPurple : 'white',
                  }}
                >
                  <ListItem
                    disabled={menuItem.disabled}
                    disableGutters
                    className={cx(classes.menuItem, {
                      [classes.menuItemClickable]: menuItem.clickable !== false,
                    })}
                  >
                    <ListItemText
                      primaryTypographyProps={{ className: classes.font }}
                      onClick={() => {
                        if (menuItem.clickable !== false) props.menuItemClicked(menuItem);
                      }}
                    >
                      {t(`navStick.${menuItem.tag}`)}
                    </ListItemText>
                    {menuItem.subMenu && (
                      <Box
                        onClick={() => {
                          props.expandCollapseSubMenu(menuItem);
                        }}
                      >
                        {expandSubMenu[menuItem.key] ? (
                          <ExpandLess
                            className={cx(classes.expandIcon, {
                              [classes.menuItemClickable]: !menuItem.disabled,
                            })}
                          />
                        ) : (
                          <ExpandMore
                            className={cx(classes.expandIcon, {
                              [classes.menuItemClickable]: !menuItem.disabled,
                            })}
                          />
                        )}
                      </Box>
                    )}
                  </ListItem>
                  {menuItem.subMenu && (
                    <SubMenu
                      menuItem={menuItem}
                      expandSubMenu={expandSubMenu}
                      onExpand={props.expandCollapseSubMenu}
                      selectedMenu={selectedMenu}
                      selectedSubMenu={selectedSubMenu}
                      subMenuItemClicked={props.subMenuItemClicked}
                      level={0}
                    />
                  )}
                </List>
              </div>
            }
          </Grid>
        </Grid>
      </ListItemIcon>
    </ListItem>
  );
};

interface ISubMenu {
  menuItem: INavigation | NavigationOption<NavigationSubTypes, string>;
  expandSubMenu: { [menuKey: string]: boolean };
  onExpand: (
    menuItem: INavigation | NavigationOption<NavigationSubTypes, string> | NavigationOption<string>,
    parentMenuItem?: INavigation | NavigationOption<NavigationSubTypes, string> | NavigationOption<string>,
  ) => void;
  selectedMenu: INavigation;
  selectedSubMenu: NavigationOption<NavigationSubTypes, {}> | NavigationOption<string, {}>;
  subMenuItemClicked: (event, menuItem, subMenuItem) => void;
  level: number;
}

const SubMenu = (props: ISubMenu) => {
  const { menuItem, expandSubMenu, onExpand, selectedMenu, selectedSubMenu, subMenuItemClicked, level } = props;
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  const [pricingWorkDeskDecision] = useDecision(ff_pricing_ui_pricingworkdesk);
  const [templateDecision] = useDecision(ff_placement_ui_templates_sidemenu);
  const [clientConfirmationTemplateDecision] = useDecision(ff_placement_ui_clientconfirmation_sidemenu);
  const [candidateContractsTemplateDecision] = useDecision(ff_placement_ui_candidatecontracts_sidemenu);
  const [autoTaskManagementDecision] = useDecision(ff_shared_ui_auto_task_management_sidebar);

  // TODO: @Rijash - Figure out an alternative for Optimizely flags, 18th July 2023

  const subMenuItems = React.useMemo(() => {
    return menuItem.subMenu
      .filter(item => {
        if (item.key === 'pricingWorkDesk' && !pricingWorkDeskDecision.enabled) return false;
        if (
          item.key === 'templateEditor' &&
          (!templateDecision.enabled ||
            (!clientConfirmationTemplateDecision.enabled && !candidateContractsTemplateDecision.enabled))
            )
            return false;
        if (item.key === 'autoTaskManagement' && !autoTaskManagementDecision.enabled) return false;
        return true;
      })
      .map(item => {
        if (item.key === 'templateEditor' && templateDecision.enabled) {
          const filteredSubMenu = item.subMenu.filter(subMenuItem => {
            if (subMenuItem.key === 'templateEditor.clientConfirmation' && !clientConfirmationTemplateDecision.enabled)
              return false;
            if (subMenuItem.key === 'templateEditor.candidateContracts' && !candidateContractsTemplateDecision.enabled)
              return false;
            return true;
          });
          return { ...item, subMenu: filteredSubMenu };
        }
        return item;
      });
  }, [
    menuItem,
    pricingWorkDeskDecision,
    templateDecision,
    clientConfirmationTemplateDecision,
    candidateContractsTemplateDecision,
  ]);

  return (
    <Collapse in={expandSubMenu[menuItem.key]} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {subMenuItems.map((subMenuItem: any, subsubIndex: number) => (
          <PrivateComponent roles={subMenuItem.roles} key={subsubIndex}>
            <ListItem
              disabled={
                subMenuItem.disabled ||
                (!!subMenuItem.enableOnlyForRoute &&
                  !window.location.pathname.startsWith(subMenuItem.enableOnlyForRoute))
              }
              disableGutters
              className={cx(classes.menuItem, {
                [classes.menuItemClickable]: subMenuItem.clickable !== false || subMenuItem.action,
                [classes.subMenuItemSelectedRoot]: menuItem.key === selectedMenu?.key,
                [classes.subMenuItemRoot]: menuItem.key !== selectedMenu?.key,
                [classes.lvl2SubMenuItem]: level >= 1,
                [classes.lvl2SubMenuItemSelected]: level >= 1 && !!selectedSubMenu,
              })}
              onClick={event => {
                if (subMenuItem.clickable !== false || !isNullOrUndefined(subMenuItem.action))
                  subMenuItemClicked(event, menuItem, subMenuItem);
              }}
              selected={subMenuItem.key === selectedSubMenu?.key}
            >
              <ListItemText
                primaryTypographyProps={{ className: classes.font }}
                title={subMenuItem.title}
                onClick={() => {
                  if (subMenuItem.subMenu) {
                    onExpand?.(subMenuItem, menuItem);
                  }
                }}
              >
                {subMenuItem.removeTextOverflow ? (
                  <OverflowTooltip
                    contentClass={classes.overflowStyle}
                    value={t(`${subMenuItem.name}`)}
                    placement="right"
                    classes={{ tooltip: classes.overflowtooltip }}
                  />
                ) : (
                  t(`${subMenuItem.name}`)
                )}
              </ListItemText>
              {subMenuItem.subMenu && (
                <Box
                  onClick={() => {
                    onExpand?.(subMenuItem, menuItem);
                  }}
                  className={classes.menuItemClickable}
                >
                  {expandSubMenu[subMenuItem.key] ? (
                    <ExpandLess
                      className={cx(classes.expandIcon, { [classes.menuItemClickable]: !subMenuItem.disabled })}
                    />
                  ) : (
                    <ExpandMore
                      className={cx(classes.expandIcon, { [classes.menuItemClickable]: !subMenuItem.disabled })}
                    />
                  )}
                </Box>
              )}
            </ListItem>
            {subMenuItem.subMenu && (
              <SubMenu
                menuItem={subMenuItem}
                expandSubMenu={expandSubMenu}
                onExpand={onExpand}
                selectedMenu={selectedMenu}
                selectedSubMenu={selectedSubMenu}
                subMenuItemClicked={subMenuItemClicked}
                level={level + 1}
              />
            )}
          </PrivateComponent>
        ))}
      </List>
    </Collapse>
  );
};
