import React, { useEffect } from 'react';
import { CustomTooltip } from 'app/components/Common/Tooltips';
import { useRef, useState } from 'react';
import { Box, Grid, Typography, Skeleton } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  rowSectionEllipsis: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '250px',
    width: '100% !important',
    fontSize: '14px',
    fontWeight: 'normal',
    color: theme.palette.framework.system.charcoal,
  },
  rowSubsectionContainer: {
    width: '100%',
    whiteSpace: 'nowrap',
    paddingBottom: '12px',
    WebkitFlexWrap: 'nowrap',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  rowSubsectionHeader: {
    fontWeight: '600',
    paddingRight: '24px',
    whiteSpace: 'nowrap',
  },
  rowSubsectionData: {
    fontSize: '14px',
    fontWeight: '400',
    width: '100%',
    paddingBottom: '12px',
  },
  rowContainer: {
    width: '100%',
    whiteSpace: 'nowrap',
    paddingBottom: '12px',
    WebkitFlexWrap: 'nowrap',
    alignItems: 'flex-end',
  },
  rowHeader: {
    fontWeight: '600',
    paddingRight: '24px',
    whiteSpace: 'nowrap',
  },
  skeletonRowHeader: {
    minWidth: '120px',
    fontWeight: '600',
    paddingRight: '24px',
    whiteSpace: 'nowrap',
  },
  clickable: {
    cursor: 'pointer !important',
    color: '#0586db !important',
    fontWeight: 450,
  },
  icon: {
    position: 'relative',
    display: 'inline-block',
    '& img': {
      transition: 'filter 0.3s ease',
      filter: 'grayscale(100%)',
    },
    '&$iconHovered img': {
      filter: 'none',
    },
  },
}));

export const RowSubSection: React.FC<{
  header: string | number | React.ReactNode;
  data: string | number | React.ReactNode;
  actionIcon?: React.ReactNode;
  actionIcomTooltip?: string;
  actionHandler?: () => void;
}> = props => {
  const { classes, cx } = useStyles();
  const [showTooltip, setShowTooltip] = useState(false);

  const handleActionClick = () => {
    setShowTooltip(false);
    props.actionHandler();
  };

  return (
    <Grid container direction="column">
      <Grid container direction="row" className={classes.rowSubsectionContainer}>
        <Grid
          item
          className={classes.rowSubsectionHeader}
          style={{
            fontSize: props.data ? '12px' : '14px',
          }}
        >
          {props.header}
        </Grid>
        {props.actionIcon && (
          <CustomTooltip open={showTooltip} placement="bottom" arrow={true} tooltipContent={props.actionIcomTooltip}>
            <Grid
              item
              className={cx(classes.clickable, { [classes.icon]: !showTooltip })}
              onClick={handleActionClick}
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
            >
              {props.actionIcon}
            </Grid>
          </CustomTooltip>
        )}
      </Grid>
      <Grid item className={classes.rowSubsectionData}>
        {props.data}
      </Grid>
    </Grid>
  );
};

export const RowSection: React.FC<{
  header: string | number | React.ReactNode;
  data: string | number | React.ReactNode;
  isNormalRow?: boolean;
  key?: number;
  fixedHeaderWidth: number;
  handleMeasureWidth: (width: number) => void;
  useSpecialPadding?: boolean;
}> = props => {
  const [showTooltip, setShowTooltip] = useState(false);
  const rowSectionRef = useRef(null);
  const rowHeadRef = useRef(null);
  const { classes } = useStyles();
  useEffect(() => {
    if (rowHeadRef.current) {
      // Measure and send this to a parent component
      const width = rowHeadRef.current.offsetWidth;
      props.handleMeasureWidth(width);
    }
  }, []);
  const handleMouseEnter = () => {
    setShowTooltip(rowSectionRef?.current?.scrollWidth > rowSectionRef?.current?.clientWidth);
  };

  const paddingBottomStyle = props.useSpecialPadding ? { paddingBottom: '6px' } : { paddingBottom: '12px' };

  return (
    <Grid container direction="row" key={props.key} className={classes.rowContainer} style={paddingBottomStyle}>
      <Grid
        item
        ref={rowHeadRef}
        className={classes.rowHeader}
        style={{
          fontSize: '12px',
          minWidth: `${props.fixedHeaderWidth}px`,
        }}
      >
        {props.header}
      </Grid>
      <Grid
        item
        style={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        <CustomTooltip open={showTooltip} placement="bottom" arrow={true} tooltipContent={props.data}>
          <div
            className={classes.rowSectionEllipsis}
            ref={rowSectionRef}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={() => setShowTooltip(false)}
          >
            {props.data}
          </div>
        </CustomTooltip>
      </Grid>
      {props.children}
    </Grid>
  );
};

/**
 *  Component that wraps text with ellipsis when character exceeds
 */
export const TruncatedText = ({ text, maxLength }: { text: string; maxLength: number }) => {
  return (
    <Box
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre-line',
      }}
    >
      <Typography sx={{ fontSize: '14px' }}>
        {text.length > maxLength ? `${text.slice(0, maxLength)}...` : text}
      </Typography>
    </Box>
  );
};

/**
 *  Component that overflows text with scroll when character exceeds
 */
export const ScrolledText = ({ text, maxHeight }: { text: string; maxHeight: number }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          height: '100%',
          zIndex: 1,
        }}
      />
      <Box
        sx={{
          maxHeight: maxHeight,
          overflowY: 'scroll',
          whiteSpace: 'pre-line',
        }}
      >
        <Typography sx={{ fontSize: '14px' }}>{text}</Typography>
      </Box>
    </Box>
  );
};

export const FacilityRolesSkeleton = () => {
  const { classes, cx } = useStyles();
  
  return (
    <Grid container direction="column">
      <Grid container direction="row" className={classes.rowContainer}>
        <Grid item className={classes.skeletonRowHeader} >
          <Skeleton width={50} height={25} />
        </Grid>
        <Grid item>
          <Skeleton width={150} height={25} />
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.rowContainer}>
        <Grid item className={classes.skeletonRowHeader}>
          <Skeleton width={80} height={25} />
        </Grid>
        <Grid item>
          <Skeleton width={160} height={25} />
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.rowContainer}>
        <Grid item className={classes.skeletonRowHeader}>
          <Skeleton width={60} height={25} />
        </Grid>
        <Grid item>
          <Skeleton width={180} height={25} />
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.rowContainer}>
        <Grid item className={classes.skeletonRowHeader}>
          <Skeleton width={60} height={25} />
        </Grid>
        <Grid item>
          <Skeleton width={120} height={25} />
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.rowContainer}>
        <Grid item className={classes.skeletonRowHeader}>
          <Skeleton width={90} height={25} />
        </Grid>
        <Grid item>
          <Skeleton width={170} height={25} />
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.rowContainer}>
        <Grid item className={classes.skeletonRowHeader}>
          <Skeleton width={75} height={25} />
        </Grid>
        <Grid item>
          <Skeleton width={190} height={25} />
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.rowContainer}>
        <Grid item className={classes.skeletonRowHeader}>
          <Skeleton width={65} height={25} />
        </Grid>
        <Grid item>
          <Skeleton width={120} height={25} />
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.rowContainer}>
        <Grid item className={classes.skeletonRowHeader}>
          <Skeleton width={90} height={25} />
        </Grid>
        <Grid item>
          <Skeleton width={170} height={25} />
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.rowContainer}>
        <Grid item className={classes.skeletonRowHeader}>
          <Skeleton width={75} height={25} />
        </Grid>
        <Grid item>
          <Skeleton width={190} height={25} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export const AtAGlanceSkeleton = () => {
  const { classes, cx } = useStyles();

  return (
    <Grid container direction="column">
      <Grid container direction="row" className={classes.rowContainer}>
        <Grid item className={classes.skeletonRowHeader} >
          <Skeleton width={80} height={25} />
        </Grid>
        <Grid item>
          <Skeleton width={150} height={25} />
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.rowContainer}>
        <Grid item className={classes.skeletonRowHeader}>
          <Skeleton width={80} height={25} />
        </Grid>
        <Grid item>
          <Skeleton width={160} height={25} />
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.rowContainer}>
        <Grid item className={classes.skeletonRowHeader}>
          <Skeleton width={60} height={25} />
        </Grid>
        <Grid item>
          <Skeleton width={180} height={25} />
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.rowContainer}>
        <Grid item className={classes.skeletonRowHeader}>
          <Skeleton width={65} height={25} />
        </Grid>
        <Grid item>
          <Skeleton width={120} height={25} />
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.rowContainer}>
        <Grid item className={classes.skeletonRowHeader}>
          <Skeleton width={75} height={25} />
        </Grid>
        <Grid item>
          <Skeleton width={170} height={25} />
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.rowContainer}>
        <Grid item className={classes.skeletonRowHeader} >
          <Skeleton width={50} height={25} />
        </Grid>
        <Grid item>
          <Skeleton width={90} height={25} />
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.rowContainer}>
        <Grid item className={classes.skeletonRowHeader}>
          <Skeleton width={90} height={25} />
        </Grid>
        <Grid item>
          <Skeleton width={160} height={25} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export const ReferencesSkeleton = () => {
  const { classes, cx } = useStyles();

  return (
    <Grid container direction="column">
      <Grid container direction="row" className={classes.rowContainer}>
        <Grid item className={classes.skeletonRowHeader} >
          <Skeleton width={80} height={25} />
        </Grid>
        <Grid item>
          <Skeleton width={90} height={25} />
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.rowContainer}>
        <Grid item className={classes.skeletonRowHeader}>
          <Skeleton width={80} height={25} />
        </Grid>
        <Grid item>
          <Skeleton width={160} height={25} />
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.rowContainer}>
        <Grid item className={classes.skeletonRowHeader}>
          <Skeleton width={60} height={25} />
        </Grid>
        <Grid item>
          <Skeleton width={180} height={25} />
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.rowContainer}>
        <Grid item className={classes.skeletonRowHeader}>
          <Skeleton width={65} height={25} />
        </Grid>
        <Grid item>
          <Skeleton width={120} height={25} />
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.rowContainer}>
        <Grid item className={classes.skeletonRowHeader}>
          <Skeleton width={75} height={25} />
        </Grid>
        <Grid item>
          <Skeleton width={170} height={25} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export const TechVendorSkeleton = () => {
  const { classes, cx } = useStyles();

  return (
    <Grid container direction="column">
      <Grid container direction="row" className={classes.rowContainer}>
        <Grid item className={classes.skeletonRowHeader} >
          <Skeleton width={50} height={25} />
        </Grid>
        <Grid item>
          <Skeleton width={90} height={25} />
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.rowContainer}>
        <Grid item className={classes.skeletonRowHeader}>
          <Skeleton width={90} height={25} />
        </Grid>
        <Grid item>
          <Skeleton width={160} height={25} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export const DrugScreenSkeleton = () => {
  const { classes, cx } = useStyles();

  return (
    <Grid container direction="column">
      <Grid container direction="row" className={classes.rowContainer}>
        <Grid item className={classes.skeletonRowHeader} >
          <Skeleton width={60} height={25} />
        </Grid>
        <Grid item>
          <Skeleton width={90} height={25} />
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.rowContainer}>
        <Grid item className={classes.skeletonRowHeader}>
          <Skeleton width={80} height={25} />
        </Grid>
        <Grid item>
          <Skeleton width={160} height={25} />
        </Grid>
      </Grid>
    </Grid>
  )
}