import { GetMomentOfDate } from 'utils/dates/moment';
import { Concatenate } from 'utils/string/string';
import { getAge } from 'app/components/Order/OrderDetails/helper';
import Call from '../../../assets/images/WorkDesk/Call.png';
import email from '../../../assets/images/WorkDesk/email.png';
import { missingField } from 'app/constants';
import states from 'app/assets/jsons/State.json';

export const orderFlattenData = items =>
  items.map((item, index) => {
    const dateEnteredMoment = GetMomentOfDate(item.dateEntered);
    const dateVerifiedMoment = GetMomentOfDate(item.dateVerified);

    const startDateMoment = GetMomentOfDate(item.startdate);
    const endDateMoment = GetMomentOfDate(item.endDate);

    const set = new Set();

    type Skillset = {
      disciplineAbbr?: string;
      specialtyAbbr?: string;
    };

    item?.skillsets?.forEach((skillset: Skillset) => {
      const tempArray = [];
      skillset.disciplineAbbr && tempArray.push(skillset.disciplineAbbr);
      skillset.specialtyAbbr && tempArray.push(skillset.specialtyAbbr);
      set.add(tempArray.join('-'));
    });

    const skillSets = set.size > 0 ? Array.from(set).join(', ') : missingField;
    set.clear();

    const shiftCont =
      item.shifts && item.shifts?.length
        ? item.shifts
            ?.map(item => {
              return item.description;
            })
            .join(', ')
        : missingField;

    const orderPriorities =
      item.orderPriorities && item.orderPriorities?.length
        ? item.orderPriorities
            ?.map(item => {
              return item.description;
            })
            .join(', ')
        : missingField;
    const accountManagerAttributes = [
      { key: Call, value: item.accountManager?.phoneNumber },
      { key: email, value: item.accountManager?.email },
    ];

    const facilityCoordinates =
      {
        latitude: item.geoPoint?.coordinates[1],
        longitude: item.geoPoint?.coordinates[0],
      } || missingField;
    const data = {
      id: `${item.orderId}-${index}`,
      indexCounter: index,
      orderId: item.orderId,
      previewDrawerViewerId: item.orderId,
      previewDrawerViewerType: 1,
      vmsReqNo: item.vmsReqNo || missingField,
      positionsAvailable: item.positionsAvailable || missingField,
      maxFilesSent: item.maxFilesSent || missingField,
      filesPending: item.filesPending || missingField,
      filesOut: item.filesOut || missingField,
      mspClient: item.mspClient || missingField,
      affiliate: item.affiliate || missingField,
      facility: item.facility || missingField,
      facilityId: item.facilityId || 0,
      unit: item.unit || missingField,
      skillsets: skillSets,
      shifts: shiftCont.length ? shiftCont : missingField,
      paysGww: item.paysGww == null ? missingField : item.paysGww ? 'Yes' : 'No',
      guaranteedHours: item.guaranteedHours ? item.guaranteedHours : missingField,
      expectedHours: item.expectedHours ? item.expectedHours : missingField,
      reason: item.reason || missingField,
      orderBillRate: item.orderBillRate ? item.orderBillRate : missingField,
      startdate: startDateMoment.isValid() ? startDateMoment.format('MM/DD/YYYY') : missingField,
      endDate: endDateMoment.isValid() ? endDateMoment.format('MM/DD/YYYY') : missingField,
      orderPriority: orderPriorities.length ? orderPriorities : missingField,
      weeklyGrossPay: item.weeklyGrossPay || missingField,
      accountManager: Concatenate([item.accountManager?.firstName, item.accountManager?.lastName], ' ') || missingField,
      accountManagerAttributes,
      city: item.city || missingField,
      stateName: item.stateName ? item.stateName : missingField,
      zipCode: item.zipCode || missingField,
      division: item.division || missingField,
      orderType: item.orderType || missingField,
      orderTypeId: item.orderTypeId,
      businessRelationship: item.businessRelationship || missingField,
      facilityStatus: item.facilityStatus || missingField,
      dateEntered: dateEnteredMoment ? getAge(item.dateEntered) : missingField,
      dateVerified: dateVerifiedMoment ? getAge(item.dateVerified) : missingField,
      covidVaccinationRequired: item.covidVaccinationRequired || missingField,
      staffingVendor: item.staffingVendor || missingField,
      techVendor: item.technologyVendor || missingField,
      region: item.region || missingField,
      facilityCoordinates,
    };
    return data;
  });

export const orderFilterRequest = { facilityStatusIds: [1, 3, 6], states: ['CA'] };
