/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { FormControl, Grid, InputLabel, ThemeProvider, StyledEngineProvider,useTheme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { reqPref } from 'app/enums/Unit';
import { ReqPrefSection } from '../Common/ReqPrefSection';
import { SimpleCheckbox } from '../Common/SimpleCheckbox';
import { SimpleDropdown } from '../Common/SimpleDropdown';
import { InstructionText, UnitCardsTitle, UnitDetailsBody } from '../UnitDesign';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';

interface ITravelExperience {
  //onUnitPropChange: (property: string, value: any) => void;
  disableEdit?: boolean;
  hasRadio?: boolean;
  //travelExperienceData?: any | null;
}

const useStyles = makeStyles()(theme => ({
  orLabel: {
    maxWidth: 38,
    textAlign: 'center',
  },
  reqPrefSection: {
    paddingTop: '12px !important',
    paddingBottom: '12px !important',
  },
}));

export const NewTravelExperience = (props: ITravelExperience) => {
  const { classes } = useStyles();
  const { disableEdit } = props;
  const { t } = useTranslation();
  const theme= useTheme();
  const { control, getValues, watch, setValue, reset } = useFormContext();

  const watchValue = watch('travelExperience');

  const dropDownData = {
    defaultText: 'Select',
    data: ['Select', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
  };

  const [yearsError, setYearsError] = React.useState({
    assignment: false,
    experience: false,
  });

  const showPanel = watchValue !== undefined && watchValue !== null;

  const handleCheckBoxChangeX = (event: React.ChangeEvent<HTMLInputElement>) => {
    const existingValues = getValues();
    if (event.target.checked) {
      const updatedValue = {
        isRequired: true,
        inSpecialty: false,
        general: false,
        numberOfAssignments: 0,
        yearsOfExperience: null,
      };
      reset({
        ...existingValues,
        travelExperience: updatedValue,
      });
    } else {
      reset({
        ...existingValues,
        travelExperience: null,
      });
    }
  };

  return (
    <div>
      {/* TODO: Remove this theme wrapper since it uses the global theme */}
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <UnitDetailsBody variant="outlined">
            <Grid container direction="row" spacing={1}>
              <Grid item xs={12}>
                <Grid container direction="row" spacing={1}>
                  <Grid item>
                    <UnitCardsTitle>{t('order.createUnit.travelExperience.title')}</UnitCardsTitle>
                  </Grid>
                  <SimpleCheckbox
                    id={'createUnitTravelExperienceEnableCheck'}
                    isChecked={watchValue !== undefined && watchValue !== null ? true : false}
                    isdisabled={disableEdit}
                    hasRadio={false}
                    handleChecked={handleCheckBoxChangeX}
                    checkboxLabel={`Yes`}
                    checkboxName={'no-cbx'}
                  />
                </Grid>
              </Grid>
              {showPanel && (
                <>
                  <Grid item xs={12} className={classes.reqPrefSection}>
                    <Controller
                      control={control}
                      name={'travelExperience.isRequired'}
                      render={({ ref, onChange, ...rest }) => {
                        return (
                          <ReqPrefSection
                            isRequired={rest.value ?? false}
                            handleReqPref={event => {
                              onChange(event.target.value === reqPref.req);
                            }}
                            isdisabled={disableEdit}
                            id={'createUnitTravelExperienceReqPrefRadio'}
                          />
                        );
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Grid alignItems="center" container direction="row" spacing={3}>
                      <Grid item xs={3}>
                        <FormControl fullWidth variant="filled">
                          <InputLabel id="no-of-assignment">
                            {t('order.createUnit.travelExperience.numberOfAssignments')}
                          </InputLabel>
                          <Controller
                            control={control}
                            name={'travelExperience.numberOfAssignments'}
                            render={({ ref, onChange, ...rest }) => (
                              <SimpleDropdown
                                id={'createUnitTravelExperienceNumberOfAssignmentsDropdown'}
                                inputName={'numberOfAssignments'}
                                defaultInputList={dropDownData.data}
                                defaultInputValue={dropDownData.data[0]}
                                value={rest.value ?? 'Select'}
                                marginLeft={0}
                                onChange={event => {
                                  onChange(event.target.value === 'Select' ? null : parseInt(event.target.value));
                                  setYearsError({ ...yearsError, assignment: true });
                                  event.target.value !== 'Select' &&
                                    setValue('travelExperience.yearsOfExperience', null);
                                }}
                                minWidth={'160px !important'}
                                disabled={disableEdit}
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={1} className={classes.orLabel}>
                        {t('order.createUnit.travelExperience.or')}
                      </Grid>
                      <Grid item xs={3}>
                        <FormControl fullWidth variant="filled" disabled={disableEdit}>
                          <InputLabel id="years-of-experience">
                            {t('order.createUnit.travelExperience.yearsOfExperience')}
                          </InputLabel>
                          <Controller
                            control={control}
                            name={'travelExperience.yearsOfExperience'}
                            render={({ ref, onChange, ...rest }) => (
                              <SimpleDropdown
                                id={'createUnitTravelExperienceYearsOfExperienceDropdown'}
                                inputName={'yearsOfExperience'}
                                defaultInputList={dropDownData.data}
                                defaultInputValue={dropDownData.data[0]}
                                value={rest.value ?? 'Select'}
                                marginLeft={0}
                                onChange={event => {
                                  onChange(event.target.value === 'Select' ? null : parseInt(event.target.value));
                                  setYearsError({ ...yearsError, experience: true });
                                  event.target.value !== 'Select' &&
                                    setValue('travelExperience.numberOfAssignments', null);
                                }}
                                minWidth={'160px !important'}
                                disabled={disableEdit}
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item>
                        <Controller
                          control={control}
                          name={'travelExperience.inSpecialty'}
                          render={({ ref, onChange, ...rest }) => {
                            return (
                              <SimpleCheckbox
                                id={'createUnitTravelExperienceInSpecialityCheckbox'}
                                isChecked={rest.value ?? false}
                                handleChecked={event => onChange(event.target.checked)}
                                checkboxLabel={t('order.createUnit.travelExperience.inSpeciality')}
                                checkboxName={'inSpecialty'}
                                isdisabled={disableEdit}
                              />
                            );
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Controller
                          control={control}
                          name={'travelExperience.general'}
                          render={({ ref, onChange, ...rest }) => {
                            return (
                              <SimpleCheckbox
                                id={'createUnitTravelExperienceInGeneralCheckbox'}
                                isChecked={rest.value ?? false}
                                handleChecked={event => onChange(event.target.checked)}
                                checkboxLabel={t('order.createUnit.travelExperience.general')}
                                checkboxName={'general'}
                                isdisabled={disableEdit}
                              />
                            );
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <InstructionText>
                      {yearsError.assignment &&
                        yearsError.experience &&
                        t('order.createUnit.travelExperience.instructionText')}
                    </InstructionText>
                  </Grid>
                </>
              )}
            </Grid>
          </UnitDetailsBody>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
};
