import React from 'react';
import { TextField, Autocomplete, InputAdornment, CircularProgress, Checkbox, Chip } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { Control, Controller, useFormContext } from 'react-hook-form';
import { DisableAppScroll, EnableAppScroll } from 'app/layout/Layout';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { useTranslation } from 'react-i18next';

export interface ITypeAheadOption {
  object: any;
  label: string;
}

function isTypeAheadOption(object: any): object is ITypeAheadOption {
  return object && typeof object === 'object' && 'label' in object && 'object' in object;
}

interface IProps {
  name: string;
  label: string;
  register: any;
  defaultValue?: any;
  control: Control<Record<string, any>>;
  options: ITypeAheadOption[];
  isError?: boolean;
  helperText?: string;
  disabled?: boolean;
  onChange?: (data?) => void;
  onFocus?: (data?) => void;
  onBlur?: (data?) => void;
  mandatory?: boolean;
  id?: string;
  filledNormal?: boolean;
  customScroll?: boolean;
  require?: boolean;
  isOptionsLoading?: boolean;
  showDropdownIcon?: boolean;
  customInputClass?: string;
  multiple?: boolean;
  adjustAxis?: boolean;
  isHelperText?: boolean;
  validateTypeAhead?: any;
  isNestedObject?: boolean; //for usefield array fields
  groupBy?: string;
  filterOptions?: (options: ITypeAheadOption[], { inputValue }: { inputValue: string }) => ITypeAheadOption[];
}

const useStyles: any = makeStyles<{ mandatory: boolean; disabled: boolean; multiple: boolean }>()(
  (theme, { mandatory, disabled, multiple }) => ({
    customScroll: {
      border: '1px solid #D1D1D1',
      boxShadow: 'none',
      borderRadius: 3,
      maxHeight: 300,
    },
    optionItem: {
      paddingLeft: '20px',
      paddingBlock: '10px',
      '&:hover': {
        backgroundColor: '#f5f5f5',
      },
    },
    optionDisabled: {
      paddingLeft: '20px',
      paddingBlock: '10px',
      color: '#CECECE',
      pointerEvents: 'none',
    },
    optionItemSelected: {
      paddingLeft: '20px',
      paddingBlock: '10px',
      backgroundColor: '#e5e5e5',
    },
    paper: {
      '& .MuiAutocomplete-listbox > li': {
        borderTop: `1px solid ${theme.palette.framework.system.silver}`,
      },
      '& .MuiAutocomplete-listbox > li:first-of-type': {
        borderTop: 'none',
        marginBottom: '0',
      },
      width: 241,
    },
  }),
);

export const ControlledTypeAhead: React.FC<IProps> = props => {
  const {
    name,
    label,
    control,
    options,
    disabled,
    isError,
    helperText,
    customScroll,
    mandatory,
    isOptionsLoading,
    showDropdownIcon,
    customInputClass,
    multiple,
    defaultValue = null,
    // filledNormal = true,
    id,
    require = false,
    isHelperText = false,
    validateTypeAhead,
    isNestedObject,
    groupBy,
    filterOptions,
  } = props;
  const { t } = useTranslation();
  const { classes } = useStyles({ mandatory, disabled, multiple });
  const { errors } = useFormContext();

  const getHelperText = (): string => {
    return isHelperText
      ? isNestedObject
        ? helperText
        : isError || (helperText && Boolean(errors[name]))
        ? helperText
        : errors[name]?.message || ''
      : helperText || errors[name]
      ? errors[name]?.type === 'required'
        ? 'Required'
        : errors[name]?.message || helperText
      : '';
  };

  const isGroupOptionSelected = (value, option): boolean => {
    if (Boolean(groupBy)) {
      return value.object.id === option.object.id;
    } else {
      return true;
    }
  };
  return (
    <Controller
      name={name}
      control={control}
      onChange={data => data}
      defaultValue={defaultValue}
      rules={{
        required: require,
        validate: validateTypeAhead,
      }}
      render={({ ref, onChange, value, ...rest }) => (
        <>
          <Autocomplete
            value={multiple ? value || [] : value}
            blurOnSelect={!multiple}
            clearOnBlur
            freeSolo
            clearIcon={null}
            forcePopupIcon={showDropdownIcon}
            popupIcon={showDropdownIcon ? <ExpandMoreOutlinedIcon /> : null}
            multiple={multiple}
            disableCloseOnSelect={multiple}
            disabled={disabled || isOptionsLoading}
            filterOptions={filterOptions}
            options={options}
            loading={isOptionsLoading}
            getOptionLabel={option => option.label}
            getOptionDisabled={option => option.disabled === true}
            groupBy={option => (Boolean(groupBy) ? option.groupBy : null)}
            id={id}
            renderTags={(items: ITypeAheadOption[], getTagProps) =>
              items.map((option: ITypeAheadOption, index: number) => (
                <Chip
                  key={index}
                  variant="outlined"
                  sx={{ backgroundColor: 'white' }}
                  label={option.label}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={params => (
              <TextField
                error={isError}
                className={mandatory ? classes.mandatory : undefined}
                onFocus={props.onFocus}
                onBlur={props.onBlur}
                helperText={getHelperText()}
                {...params}
                variant="filled"
                label={label}
                InputProps={{
                  ...params.InputProps,
                  className: customInputClass ?? params.InputProps.className,
                  endAdornment: isOptionsLoading ? (
                    <InputAdornment position="end">
                      <CircularProgress style={{ width: '20px', height: '20px', color: '#888' }} />
                    </InputAdornment>
                  ) : (
                    params.InputProps.endAdornment
                  ),
                  startAdornment:
                    multiple && value?.length > 4 ? (
                      <div
                        style={{
                          maxHeight: '100px',
                          overflowY: 'auto',
                        }}
                      >
                        {params.InputProps.startAdornment}
                      </div>
                    ) : (
                      params.InputProps.startAdornment
                    ),
                }}
              />
            )}
            renderOption={(params, option, { selected }) => {
              return multiple ? (
                <li
                  {...params}
                  onClick={e => {
                    params.onClick(e);
                    onChange(
                      value.find(item => item.object.ID === option.object.ID)
                        ? value.filter(item => item.object.ID !== option.object.ID)
                        : [...value, option],
                    );
                  }}
                  className={
                    value?.label === option.label
                      ? classes.optionItemSelected
                      : option.disabled
                      ? classes.optionDisabled
                      : classes.optionItem
                  }
                >
                  <Checkbox
                    style={{ marginRight: 8 }}
                    // onClick={(e) => {
                    //   //params.onClick(e);
                    //   onChange(
                    //     value.find(item => item.object.ID === option.object.ID)
                    //     ? value.filter(item => item.object.ID !== option.object.ID)
                    //     : [...value, option]);
                    // }}
                    checked={value?.filter(item => item.object.ID === option.object.ID)?.length > 0}
                  />
                  {option.label}
                </li>
              ) : (
                <li
                  {...params}
                  className={
                    value?.label === option.label && isGroupOptionSelected(value, option)
                      ? classes.optionItemSelected
                      : option.disabled
                      ? classes.optionDisabled
                      : classes.optionItem
                  }
                >
                  {option.label}
                </li>
              );
            }}
            onChange={(e, data) => {
              if (isTypeAheadOption(data) || multiple) {
                onChange(data);
                props?.onChange(data);
              }
            }}
            classes={{
              listbox: customScroll ? classes.customScroll : undefined,
              paper: Boolean(groupBy) ? classes.paper : undefined,
            }}
            {...rest}
            onOpen={() => options.length && DisableAppScroll()}
            onClose={EnableAppScroll}
          />
        </>
      )}
    />
  );
};
