import { Grid } from 'amn-ui-core';
import { XGrid } from '@AMIEWEB/Common/XGrid/XGrid';
import { GridApi, GridCallbackDetails, GridSelectionModel } from '@mui/x-data-grid-pro';
import React, { useState, useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { orderCertificationColumns } from './CertificationHelper';
import { selectOrderCertification } from '@AMIEWEB/Order/Store/Order.selectors';
import { IOrderCertification, orderDataActions } from '@AMIEWEB/Order/Store/Order.redux';

const useStyles = makeStyles<{ isDisabled: boolean }>()((theme, props) => ({
  root: {
    '& .MuiGrid-root': {
      margin: 0,
      width: 'auto',
    },
    '& .MuiGrid-root > .MuiGrid-item': {
      padding: 0,
    },
    '& .MuiDataGrid-root': {
      border: 'none',
      borderRadius: 0,
    },
    '& .MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
      border: 'none !important',
      outline: 'none !important',
      backgroundColor: 'transparent !important',
    },
  },
  cerificationGrid: {
    opacity: props.isDisabled ? 0.3 : 1,
  },
  overlay: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    whiteSpace: 'nowrap',
  },
}));

export const CertificationDropdownGrid = ({
  isDisabled = false,
  certificationList,
}: {
  isDisabled?: boolean;
  certificationList: any[];
}) => {
  const { classes } = useStyles({ isDisabled: isDisabled });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const gridApi = React.useRef<GridApi>();
  const [dataList, setDataList] = useState<any[]>([]);
  const [orderSelectionModel, setOrderSelectionModel] = useState<any[]>([]);
  const { control } = useFormContext();
  const orderCertifications = useSelector(selectOrderCertification);

  const certificationColumns = [...orderCertificationColumns(isDisabled, t)];

  useEffect(() => {
    const filteredParentCert = orderCertifications?.filter((x) => x?.isSubCert === false);
    const selectedCertificationIds = filteredParentCert?.map(x => {
      return x?.requirementTypeId;
    });
    setOrderSelectionModel(selectedCertificationIds);
  }, [orderCertifications]);

  const onSelectionModelChange = (selectionModel: GridSelectionModel, details: GridCallbackDetails<any>) => {
    setOrderSelectionModel([...selectionModel]);
    if (selectionModel && selectionModel?.length > 0 && certificationList?.length > 0) {
      const orderCertificationDetails: IOrderCertification[] = [...orderCertifications];
      selectionModel?.map(selectedOption => {
        const licenseDetailsIndex = orderCertifications?.findIndex(data => data?.requirementTypeId === selectedOption);
        if (licenseDetailsIndex === -1) {
          const selectedCertificationOptionDetails = dataList?.find(data => data?.id === selectedOption);
          const selectedLicenseDetail: IOrderCertification = {
            abbreviation: selectedCertificationOptionDetails?.abbreviation,
            documentAttachmentRequired: false,
            isError: false,
            isRequired: true,
            isSubCert: false,
            requirementTypeId: selectedCertificationOptionDetails?.id,
          };
          orderCertificationDetails.push(selectedLicenseDetail);
        }
      });
      orderCertificationDetails?.map((certificationDetails, index) => {
        const isRowSelected = selectionModel?.find(
          selected => selected === certificationDetails?.requirementTypeId,
        );
        if (!isRowSelected && certificationDetails?.isSubCert === false) {
          orderCertificationDetails.splice(index, 1);
        }
      });
      dispatch(orderDataActions.setOrderCertificationDetails(orderCertificationDetails));
    } else {
      dispatch(orderDataActions.setOrderCertificationDetails([]));
    }
  };

  useEffect(() => {
    const formattedCertificationList =
      certificationList?.length > 0
        ? certificationList?.map(e => ({
            ...e,
            id: e.requirementTypeID ?? e.requirementTypeId,
            certificationName: e.credentialName ? `${e.abbreviation} - ${e.credentialName}` : `${e.abbreviation}`,
          }))
        : [];
    setDataList(formattedCertificationList);
  }, [certificationList]);

  return (
    <div data-testid="certification-dropdown" className={classes.cerificationGrid}>
      <Controller
        control={control}
        name={'qualification.certifications'}
        render={({ ref, onChange, ...rest }) => (
          <Grid className={classes.root}>
            <XGrid
              apiRef={gridApi}
              hideFooterSelectedRowCount
              checkboxSelection
              disableSelectionOnClick
              gridTag={'Order-Certification'}
              height={300}
              rowHeight={60}
              columns={certificationColumns}
              onSelectionModelChange={onSelectionModelChange}
              selectionModel={orderSelectionModel}
              keepNonExistentRowsSelected={true}
              rows={dataList}
              toolbar={false}
              autoHeight={false}
              pagination={false}
              hideFooterPagination
              hideFooter={true}
              disablePaggination
              paginationProps={{
                hideResultsPerPageSelection: true,
                hidePageCount: true,
              }}
              disableVirtualization={true}
              components={{
                NoRowsOverlay: () => <div className={classes.overlay}>{t('No certifications match your search')}</div>,
              }}
            />
          </Grid>
        )}
      />
    </div>
  );
};
