/* eslint-disable @typescript-eslint/dot-notation */
import { Box, Button, CircularProgress, Grid, Skeleton, Typography } from 'amn-ui-core';
import React, { useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { ActionModal } from '../customComponents';
import { useTranslation } from 'react-i18next';
import { CustomTooltip, OverflowTooltip } from '@AMIEWEB/Common/Tooltips';
import { customSwitch } from 'utils/customSwitchCase/customSwitch';
import { useDispatch, useSelector } from 'react-redux';
import { usePromiseTracker } from 'react-promise-tracker';
import { StatusBubble } from '../customComponents/DocumentCard';
import { selectUser } from 'oidc/user.selectors';
import { CustomTooltipProps } from '@AMIEWEB/Common/Tooltips/CustomTooltip';
import styled from 'styled-components';
import {
  selectComparisonData,
  selectMiscellaneousPlacementData,
  selectPlacementCandidateRecords,
  selectPlacementContactsData,
  selectPlacementHeaderData,
} from 'store/redux-store/placement-details/selectors';
import { ConfirmationStatus, IConfirmationDoc } from 'store/redux-store/placement-client-confirmation-doc-editor/types';
import { ITemplate } from 'store/redux-store/confirmation-template-editor/types';
import {
  selectContacts,
  selectDocumentView,
} from 'store/redux-store/placement-client-confirmation-doc-editor/selector';
import { pConfirmationDocEditorActions } from 'store/redux-store/placement-client-confirmation-doc-editor/slice';
import { DocSource } from 'store/redux-store/placement-candidate-contract-doc-editor/types';
import { instanceOfConfirmationDoc } from 'store/redux-store/placement-client-confirmation-doc-editor/utils';
import { SharedService } from 'app/services/SharedServices/shared-service';
import {
  ConfirmationLetterSubjectPrefix,
  ConfirmationLetterTemplates,
  KeyContactsDescription,
} from 'store/redux-store/placement-documents/types';
import { getCandidateById, getEMRList } from 'app/services/CandidateServices/CandidateServices';
import { getCandidateCredentials } from 'app/services/CredentialingServices';
import { getOrderDetailsById } from 'app/services/OrderServices/OrderServices';
import { getCandidateSellingPointsByPid } from 'app/services/PlacementServices/PlacementServices';
import { getCredentialLookups } from 'app/services/CredentialingServices/CredentialingServices';
import { ApplyDataToTemplate, GetTemplatesByCategory } from 'app/services/NotificationServices/TemplateService';
import { selectPlacementSummary, selectSubmissionInfo } from 'store/redux-store/placement-summary/selectors';
import { notificationSelection } from 'store/redux-store/notification/notification.selector';
import { selectUserPreference } from 'oidc/UserDevicePreference/userPreference.selectors';
import { placementContactsFlattened } from '@AMIEWEB/Placement/PlacementDetails/PlacementDetailsHeader/utils';
import { EmailDefaultValues } from 'app/models/Email/IEmail';
import { getExpirationDate, getFluVaccinationStatus, getUniqueArray } from './helper';
import { ChannelType, FormName, SendType, UseSubType, UseType } from '@AMIEWEB/Notification/Constants';
import { RequirementType } from 'app/models/Credentialing/enums/RequirementType';
import { GetMomentOfDate } from 'utils/dates/moment';
import moment from 'moment';
import { Concatenate } from 'utils/string/string';
import { OrderTypeIds } from 'app/models/Placement/PlacementDetails';
import { ContactProps } from 'app/models/Notification/Notification';
import { LookupFields } from 'app/models/Credentialing/enums/Lookup';
import { getSubstitutions } from '@AMIEWEB/Placement/PlacementDetails/helper';
import { httpSuccess } from 'app/services/serviceHelpers';
import { signatureSubstitutionToken } from '@AMIEWEB/GlobalSearch/helper';
import { notificationDataActions } from 'store/redux-store/notification/notification.redux';
import { base64DocumentToURL } from 'app/helpers/fileHelper';
import { placementDocumentsActions } from 'store/redux-store/placement-documents/slice';
import { orderBy } from 'lodash';
import { useHistory } from 'react-router-dom';
import { GenericDialogButton } from '@AMIEWEB/Alerts/GenericDialog';

const useStyles = makeStyles()(theme => ({
  container: {
    padding: '0 12px 12px 0',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  titleRoot: {
    display: 'flex',
    flexDirection: 'row',
    gap: 6,
    alignItems: 'center',
  },
  titleSkeleton: {
    width: `calc(100% - ${theme.spacing(2)})`,
    /**
     * Code: marginBottom of 17 instead of 12px -
     * ensure section doesn't hop vertically when switching to loader
     */
    marginBottom: 17,
  },
  title: {
    color: theme.palette.system.darkGray,
    fontWeight: 450,
    fontSize: 16,
    whiteSpace: 'pre',
  },
  subTitle: {
    color: theme.palette.text.primary,
    fontSize: 14,
    maxWidth: 'inherit',
  },
  noTemplateSelected: {
    fontSize: '14px',
    fontWeight: 400,
    letterSpacing: '0px',
    color: theme.palette.system.lightGrey,
    opacity: 1,
    padding: '2px 0 6px 0',
  },
  btnContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'end',
  },
  secondaryBtn: {
    backgroundColor: theme.palette.system.buttonGray,
    ':hover': {
      backgroundColor: theme.palette.system.buttonDarkGray,
    },
  },
}));

const MarkAsSentButton = (props: { buttonProps: any }) => {
  const { promiseInProgress: isUpdatingDocStatus } = usePromiseTracker({
    area: 'placement-client-confirmation-update-document-status',
  });
  return <GenericDialogButton disabled={isUpdatingDocStatus} {...props.buttonProps} />;
};

export const EngineTemplateTitle: React.FC<{
  isTemplateSelected: boolean;
  templateTitle: string | null;
  isDirty: boolean;
  userIsReadOnly: boolean;
  onCancel: (callbackFn, ...args) => void;
  onSave: () => void;
  onCreate: () => void;
  templates: (IConfirmationDoc | ITemplate)[];
  selectedTemplate: IConfirmationDoc | ITemplate;
  forceModalCloseFlag?: boolean;
  [key: string]: any;
}> = ({
  isTemplateSelected,
  templateTitle,
  isDirty,
  userIsReadOnly,
  onCancel,
  onSave,
  onCreate,
  templates = [],
  selectedTemplate,
  forceModalCloseFlag,
  ...props
}) => {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const { renderPreviewScreen, document } = useSelector(selectDocumentView);
  const { atleastOneContactIsSelected, isContactsDirty } = useSelector(selectContacts);

  // @ts-ignore
  const [modalProps, setModalProps] = React.useState<IActionModalProps>({ open: false });
  // @ts-ignore
  const closeModal = React.useCallback(() => setModalProps({ open: false }), []);

  const dispatch = useDispatch();

  const { promiseInProgress: isPullingPdfDoc } = usePromiseTracker({
    area: 'placement-client-confirmation-templates-fetch-document',
  });
  const { promiseInProgress: isSavingingDraft } = usePromiseTracker({
    area: 'placement-client-confirmation-templates-save-draft',
  });
  const { promiseInProgress: isDeletingDraft } = usePromiseTracker({
    area: 'delete-placement-confirmation-draft-template',
  });
  const { promiseInProgress: isCreatingDoc } = usePromiseTracker({
    area: 'placement-client-confirmation-templates-create-document',
  });

  const placementDetails = useSelector(selectPlacementHeaderData);
  const {
    orderReq: {
      candidate: { experience: candidateReqItem, certifications, licenses },
    },
  } = useSelector(selectSubmissionInfo);
  const { candidate: candidateDetails } = useSelector(selectComparisonData);
  const { candidateSellingPointCounts } = useSelector(selectPlacementCandidateRecords);
  const notificationData = useSelector(notificationSelection);
  const { userInfo } = useSelector(selectUser);
  const placementContacts = useSelector(selectPlacementContactsData);
  const { disciplineData } = useSelector(selectMiscellaneousPlacementData);
  const placementSummary = useSelector(selectPlacementSummary);
  const flattenedPlacementDetails = placementContactsFlattened(placementContacts);
  const userPreference = useSelector(selectUserPreference);
  const user = useSelector(selectUser);

  const handleNotificationData = async () => {
    const sharedService = SharedService.createInstance();
    try {
      const [
        selectedConfirmationData,
        candidateData,
        candidateCreds,
        candidateEmrList,
        orderDetails,
        sellingPoints,
        lookupselection,
        templates,
      ] = await Promise.all([
        sharedService.getPlacementDocument(document.documentId),
        getCandidateById(placementDetails.candidate.id, placementDetails.brandId),
        getCandidateCredentials(placementDetails.candidate.id.toString()),
        getEMRList(placementDetails.candidate.id, placementDetails.brandId),
        getOrderDetailsById(`${placementDetails.orderId}`),
        getCandidateSellingPointsByPid(placementDetails.placementId),
        getCredentialLookups('all'),
        GetTemplatesByCategory('Confirmation Letter', 'email'),
      ]);

      const candidateEmr = candidateEmrList?.data
        .filter(item => item?.candidateId == placementDetails.candidate.id && item.brandId == placementDetails.brandId)
        ?.map(item => item?.description);
      const ssn = candidateData?.socialSecurityNumber?.replace(/\d/g, '#');
      const skillSets = candidateData?.verifiedSkills?.map(item => `${item?.disciplineAbbr}-${item?.specialtyAbbr}`);
      const fluData = candidateCreds.employeeHealth?.filter(
        e => e.requirementTypeID === RequirementType.FLUVACCINATION,
      );
      const firstReport = orderBy(fluData, ['dateCompleted'], ['desc'])[0];
      const type = lookupselection
        ?.filter(e => e.requirementTypeID === RequirementType.FLUVACCINATION)
        ?.find(item => item.lookUpField === LookupFields.TypeID)
        ?.lookups?.find(e => e.value === firstReport?.typeID);

      const expirationDate = getExpirationDate(firstReport?.dateCompleted);

      const CandidateFludata = {
        type: type?.description,
        dateCompleted: firstReport?.dateCompleted,
        expirationDate: expirationDate,
        tsceFormID: firstReport?.tsceFormID,
      };
      const activeLicences: Array<string> = [];
      licenses?.data?.map(item => {
        const expirationDate =
          GetMomentOfDate(item.expirationDate).format('MM/DD/YYYY') !== 'Invalid date'
            ? GetMomentOfDate(item.expirationDate).format('MM/DD/YYYY')
            : undefined;
        if (moment(expirationDate).isSameOrAfter(moment(), 'day')) {
          return activeLicences.push(
            `${item?.requirementType || ''} ${item.state} ${item?.level == 'Multi-state' ? 'Compact' : ''}${
              item?.licenseNumber ? '#' + item?.licenseNumber : ''
            }${expirationDate ? ' (exp ' + expirationDate + ')' : ''}`,
          );
        }
      });
      const licenseNumbers: Array<string> = [];
      candidateCreds?.licenses?.map(item => {
        if (!!item?.licenseNumber) {
          return licenseNumbers.push(`${item?.licenseNumber}`);
        }
      });
      const activeCertifications: Array<string> = [];
      candidateCreds?.certifications?.map(item => {
        const expirationDate =
          GetMomentOfDate(item.expirationDate).format('MM/DD/YYYY') !== 'Invalid date'
            ? GetMomentOfDate(item.expirationDate).format('MM/DD/YYYY')
            : undefined;
        if (moment(expirationDate).isSameOrAfter(moment(), 'day')) {
          return activeCertifications.push(
            `${item.requirementType || ''}${expirationDate ? ' (exp ' + expirationDate + ')' : ''}`,
          );
        }
      });

      const uniqueLicences = getUniqueArray(activeLicences);
      const uniqueCertifications = getUniqueArray(activeCertifications);
      const fluVaccinationStatus = getFluVaccinationStatus(CandidateFludata);
      const vmsReqNumber = orderDetails.vmsOrderNumber;
      const orderSkillSets = [];
      placementSummary.submissionInfo.orderReq.order.skillset.data.map(item => {
        item?.specialties.map(ele => {
          orderSkillSets.push(`${item?.disciplineAbbr || ''}-${ele?.specialtyAbbr || ''}`);
        });
      });
      const values = {
        facilityUnit: placementDetails?.placementUnit,
        accountManagerFirstName: flattenedPlacementDetails?.accountManager?.firstName,
        accountManagerLastName: flattenedPlacementDetails?.accountManager?.lastName,
        accountManagerEmailAddress: flattenedPlacementDetails?.accountManager?.email,
        accountManagerPhoneNumber: flattenedPlacementDetails?.accountManager?.phoneNumber,
        placementSellingPoints: `${sellingPoints?.placementSellingPoints?.replaceAll('\n', '<br/>') || ''}`,
        candidateFirstName: placementDetails.candidate?.firstName,
        candidateLastName: placementDetails.candidate?.lastName,
        candidateGoesBy: placementDetails.candidate?.goesByName,
        candidateDiscipline: candidateDetails?.skillsets,
        candidateDob:
          (candidateData?.dateOfBirth && GetMomentOfDate(candidateData?.dateOfBirth).format('MM/DD/YYYY')) || '',
        currentAMNTraveler: candidateSellingPointCounts?.currentAmnTraveler,
        candidateDisciplineText: disciplineData,
        candidatePhoneNumber: placementDetails.candidate?.phoneNumber?.[0]?.phoneNumber,
        candidateEmail: placementDetails.candidatePrimaryEmail,
        availableStartDate: placementDetails.availabilityDate,
        requestedTimeOff: candidateDetails?.requestedTimeOff,
        licenses: licenses?.data,
        certifications: certifications.data?.candidateCredCertListResponse,
        totalYearsofExperience: `${
          candidateData?.experience?.totalYearsOfExp ? candidateData?.experience?.totalYearsOfExp?.toString() + 'Y' : ''
        } ${
          candidateData?.experience?.totalMonthsOfExperience
            ? candidateData?.experience?.totalMonthsOfExperience?.toString() + 'M'
            : ''
        }`,
        COVIDVaccineStatus: candidateData.covidVaccinationstatus,
        experience: candidateReqItem,
        orderShift: orderDetails.shifts?.[0]?.description || '',
        orderGww: orderDetails.gww && orderDetails.gww == true ? 'Yes' : 'No',
        ssn: ssn,
        fluVaccinationStatus: fluVaccinationStatus,
        candidateEmr: candidateEmr,
        assignmentDuration: placementSummary.orderInformation.numberOfWeeks
          ? `${placementSummary.orderInformation.numberOfWeeks} weeks`
          : null,
        vmsReqNumber: vmsReqNumber,
        candidateSkillSets: skillSets,
        orderSkillSets: orderSkillSets,
        activeCertifications: uniqueCertifications.join(', '),
        activeLicences: uniqueLicences.join(', '),
        placementStartDate: placementDetails.placementStartDate,
        placementEndDate: placementDetails.placementEndDate,
        candidateId: `${placementDetails?.candidate?.id}`,
        placementId: `${placementDetails?.placementId}`,
        licenseNumber: placementDetails.candidateLicenseNumber,
      };
      const substitutions = getSubstitutions(values);
      let fileData;

      const sender = {
        name: Concatenate([userInfo?.firstName, userInfo?.lastName], ' '),
        email: userInfo?.email,
        userId: `${userInfo?.employeeId}`,
        NtUserName: userInfo?.ntUserName,
        senderId: '',
      };
      const title =
        placementDetails.orderTypeId === OrderTypeIds.FEMA
          ? ConfirmationLetterTemplates.FEMA
          : ConfirmationLetterTemplates.Standard;
      const template = templates.filter(data => data.title == title);
      const accountManager: ContactProps = {
        name: Concatenate(
          [
            flattenedPlacementDetails?.accountManager.firstName,
            flattenedPlacementDetails?.accountManager.middleName || '',
            flattenedPlacementDetails?.accountManager.lastName,
          ],
          ' ',
        ),
        email: flattenedPlacementDetails?.accountManager?.email,
      };

      const sendConfirmationToClientContacts = placementContacts?.facilityContacts?.filter(item => {
        return item.actions.some(action => action.description == KeyContactsDescription.SendConfirmationTo);
      });
      const keyClientContactsTos: Array<ContactProps> = sendConfirmationToClientContacts?.map(item => {
        const firstName = item.firstName || '';
        const lastName = item.lastName || '';
        return {
          name: Concatenate([firstName, lastName], ' '),
          email: item?.email != '' ? item?.email : null,
          contactId: item?.id,
        };
      });
      const tos = [...keyClientContactsTos];
      if (httpSuccess(selectedConfirmationData?.status)) {
        const { data: document } = selectedConfirmationData;
        if (document?.fileType || document?.filebytes) {
          const letter = base64DocumentToURL(document.filebytes, {
            fileType: 'application/pdf',
            fileName: document.fileName,
            download: false,
          });
          fileData = {
            blobUrl: letter.url,
            fileName: 'ConfirmationLetter',
            fileType: document?.fileType,
          };
        } else {
          dispatch(
            placementDocumentsActions.setMiscellaneousField({
              key: 'errorProps',
              data: {
                message: 'An error occurred while retrieving the confirmation letter to send. Please try again.',
                severity: 'error',
                error: true,
              },
            }),
          );
          return;
        }
      } else {
        dispatch(
          placementDocumentsActions.setMiscellaneousField({
            key: 'errorProps',
            data: {
              message: 'An error occurred while retrieving the confirmation letter to send. Please try again.',
              severity: 'error',
              error: true,
            },
          }),
        );
        return;
      }
      const payload = {
        body: template[0]?.body ? `${template[0]?.body}` : '',
        substitutions: substitutions.reduce((acc, item) => {
          if (item.name !== 'Signature') {
            acc[item.input.slice(2, -2)] = item.value || '';
          }
          return acc;
        }, {}),
        signatureSubstitutions: signatureSubstitutionToken(user, userPreference),
        brand: user.userInfo?.companyBrandName,
        id: null,
        skipSignatureSubstitution: true,
      };
      const body = await ApplyDataToTemplate(payload);
      const data = {
        useType: UseType.Placement,
        useSubType: UseSubType.ConfirmationLetter,
        channel: ChannelType.email,
        emailType: SendType.one_to_one,
        selectedTemplate:
          template.length > 0
            ? template[0]
            : {
                title: '',
                id: '',
                body: '',
                description: '',
                category: '',
                isPrivate: false,
                substitutions: [],
              },
        selectedPublicCategory: 'Confirmation Letter',
        publicTemplates: templates?.filter(template => template?.isPrivate !== true),
        sendType: SendType.one_to_one,
        body: body ? `${body}` : '',
        sender: sender,
        substitutions: substitutions,
        tos: tos,
        ccs: flattenedPlacementDetails?.accountManager?.email ? [accountManager] : [],
        bccs: [sender],
        confirmationLetter: true,
        associatedRecords: [
          { name: 'placementId', value: `${placementDetails?.placementId}` },
          { name: 'travelerId', value: `${placementDetails?.candidate?.id}` },
          { name: 'brandId', value: `${placementDetails?.brandId}` },
          { name: 'facilityId', value: `${placementDetails?.facility?.id}` },
          {
            name: 'candidate',
            value: Concatenate([placementDetails.candidate?.firstName, placementDetails.candidate?.lastName], ' '),
          },
          { name: 'documentId', value: `${document.documentId}` },
          { name: 'publisherId', value: `${userInfo.employeeId}` },
          { name: 'publisherName', value: `${userInfo.firstName || ''} ${userInfo.lastName || ''}` },
          { name: 'regionalDirectorId', value: `${placementContacts?.regionalDirector.id}` },
        ],
        correlationId: null,
        userRole: Concatenate(userInfo?.roles || [], ','),
        formName: FormName.Placement,
        manualLog: false,
        subject:
          placementDetails.orderTypeId === OrderTypeIds.FEMA
            ? `${ConfirmationLetterSubjectPrefix.FEMA} ${placementDetails.candidate?.firstName || ''} ${
                placementDetails.candidate.lastName || ''
              } for ${placementDetails?.placementUnit}`
            : `${ConfirmationLetterSubjectPrefix.Standard} ${placementDetails.candidate?.firstName || ''} ${
                placementDetails.candidate.lastName || ''
              }, ${placementDetails?.placementUnit}`,
        attachmentData: fileData,
      };
      if (!notificationData.email.open && notificationData.email.minimized) {
        dispatch(
          notificationDataActions.setEmailInteraction({
            open: true,
            minimized: false,
          }),
        );
        dispatch(notificationDataActions.setSnackBarData({ channel: 'email', manual: false, changeWarning: true }));
      } else {
        dispatch(
          notificationDataActions.setNotificationData({
            ...notificationData,
            email: {
              data: {
                ...EmailDefaultValues(),
                ...data,
              },
              open: true,
              minimized: false,
            },
            snackbarData: null,
          }),
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleCreate = React.useCallback(() => {
    if (props.isContactTokensSelected && !atleastOneContactIsSelected) {
      setModalProps({
        open: true,
        title: t('cancelModal.confirmation.selectContacts'),
        message: t('placement.profile.createConfirmation.warning'),
        primaryBtns: [
          {
            text: t('cancelModal.confirmation.selectContact'),
            onClick: () => {
              closeModal();
              props.setSideView('contacts');
            },
          },
        ],
        secondaryBtns: [
          {
            text: t('cancelModal.confirmation.cancel'),
            onClick: closeModal,
          },
        ],
      });
      return;
    }
    onCreate?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onCreate, props.isContactTokensSelected, atleastOneContactIsSelected]);

  const handleDeleteDraft = React.useCallback(() => {
    setModalProps({
      open: true,
      title: t('templateEditor.modal.title.discardDraft'),
      message: t('templateEditor.modal.message.discardDraft'),
      primaryBtns: [
        {
          text: t('cancelModal.confirmation.yes'),
          onClick: () => {
            dispatch(pConfirmationDocEditorActions.deleteDraftTemplate({ placementId: props.placementId }));
            closeModal();
          },
        },
      ],
      secondaryBtns: [
        {
          text: t('cancelModal.confirmation.no'),
          onClick: closeModal,
        },
      ],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.placementId]);

  const isUserDisabled = React.useMemo(() => userIsReadOnly, [userIsReadOnly]);

  const isCallingAPI = React.useMemo(
    () => isDeletingDraft || isCreatingDoc || isSavingingDraft,
    [isDeletingDraft, isCreatingDoc, isSavingingDraft],
  );

  const deleteBtn = React.useMemo(
    () => ({
      children: t('templateEditor.delete'),
      disabled: isUserDisabled || isCallingAPI ? true : renderPreviewScreen,
      onClick: isDeletingDraft ? undefined : handleDeleteDraft,
      startIcon: isDeletingDraft ? (
        <CircularProgress sx={{ color: theme => theme.palette.system.primary }} size={12} />
      ) : undefined,
      tooltipProps: {
        disabled: !isUserDisabled,
        tooltipContent: t('templateEditor.disableToolTip.accessRoleRestriction'),
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleDeleteDraft, isUserDisabled, renderPreviewScreen, isDeletingDraft, isCallingAPI],
  );

  const saveBtn = React.useMemo(
    () => ({
      children: t('templateEditor.save'),
      /**
       * Disabled if user is readonly, interacting with service,
       * Disabled if renderPreviewScreen or a template has been selected instead of existing draft doc
       *
       * */
      disabled:
        isUserDisabled || isCallingAPI
          ? true
          : renderPreviewScreen ||
            (instanceOfConfirmationDoc(selectedTemplate) ? !(isDirty || isContactsDirty) : false),
      onClick: isCallingAPI ? undefined : onSave,
      startIcon: isSavingingDraft ? (
        <CircularProgress sx={{ color: theme => theme.palette.system.primary }} size={12} />
      ) : undefined,
      tooltipProps: {
        disabled: !isUserDisabled,
        tooltipContent: t('templateEditor.disableToolTip.accessRoleRestriction'),
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isUserDisabled,
      isCallingAPI,
      renderPreviewScreen,
      selectedTemplate,
      isDirty,
      isContactsDirty,
      onSave,
      isSavingingDraft,
    ],
  );

  const createBtn = React.useMemo(() => {
    const disabled = isUserDisabled ? true : !isTemplateSelected;
    return {
      children: t('templateEditor.create'),
      disabled: disabled || isCallingAPI,
      onClick: isCallingAPI ? undefined : handleCreate,
      startIcon: isCreatingDoc ? (
        <CircularProgress sx={{ color: theme => theme.palette.system.primary }} size={12} />
      ) : undefined,
      tooltipProps: {
        disabled: !disabled,
        tooltipContent: isUserDisabled ? t('templateEditor.disableToolTip.accessRoleRestriction') : null,
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleCreate, isTemplateSelected, isUserDisabled, isCreatingDoc, isCallingAPI]);

  const handleDownload = React.useCallback(() => {
    dispatch(pConfirmationDocEditorActions.business_downloadSelectedConfirmationtDoc());
    if (selectedTemplate?.status === ConfirmationStatus.notSent)
      setModalProps({
        open: true,
        title: t('placement.profile.clientConfirmation.dialog.title.download'),
        message: t('placement.profile.clientConfirmation.dialog.content.sendToYourself'),
        primaryBtns: [
          {
            text: t('placement.profile.placementStatusUpdate.markAsSent'),
            renderer: (props: any) => <MarkAsSentButton buttonProps={props} />,
            onClick: () =>
              dispatch(
                pConfirmationDocEditorActions.business_updateConfirmationtDocStatusToSent({
                  placementId: props.placementId,
                  documentId: document.documentId,
                }),
              ),
          },
        ],
        secondaryBtns: [
          {
            text: t('placement.profile.submitPacket.dialog.buttons.notNow'),
            onClick: closeModal,
          },
        ],
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document?.documentId, props.placementId, selectedTemplate?.status]);

  const downloadBtn = React.useMemo(
    () => ({
      disabled: isCallingAPI,
      children: t('templateEditor.download'),
      onClick: handleDownload,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleDownload, isCallingAPI],
  );

  const cancelBtn = React.useMemo(
    () => ({
      children: t('templateEditor.cancel'),
      /** TODO: Enable/Disbale */
      disabled: !isTemplateSelected || renderPreviewScreen || isCallingAPI,
      classes: { containedSecondary: classes.secondaryBtn },
      color: 'secondary',
      onClick: () =>
        setModalProps({
          open: true,
          title: t('cancelModal.undoConfirmation'),
          message: t('templateEditor.modal.message.discardTemplateChanges'),
          primaryBtns: [
            {
              text: t('cancelModal.confirmation.yes'),
              onClick: () => {
                onCancel(closeModal, { resetContacts: true });
              },
            },
          ],
          secondaryBtns: [
            {
              text: t('cancelModal.confirmation.cancel'),
              onClick: closeModal,
            },
          ],
        }),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isTemplateSelected, onCancel, renderPreviewScreen, isCallingAPI],
  );

  const handleClose = React.useCallback(() => {
    dispatch(pConfirmationDocEditorActions.business_closeConfirmationEditor());
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete('view');
    searchParams.delete('default_selection');
    searchParams.delete('documentId');
    searchParams.delete('source');

    history.push({ ...history.location, search: searchParams.toString() });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const closeBtn = React.useMemo(
    () => ({
      disabled: isCallingAPI,
      children: t('templateEditor.close'),
      classes: { containedSecondary: classes.secondaryBtn },
      color: 'secondary',
      onClick: handleClose,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isCallingAPI, handleClose],
  );

  const sendBtn = React.useMemo(
    () => ({
      disabled: isCallingAPI,
      children: t('templateEditor.send'),
      classes: { containedSecondary: classes.secondaryBtn },
      color: 'secondary',
      onClick: handleNotificationData,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isCallingAPI, handleNotificationData],
  );

  const btnRender = React.useMemo(() => {
    const btns: {
      children: string;
      disabled: boolean;
      onClick: () => void;
      startIcon?: JSX.Element;
      classes?: any;
      color?: 'secondary';
      tooltipProps?: CustomTooltipProps;
    }[] =
      !!selectedTemplate && !instanceOfConfirmationDoc(selectedTemplate)
        ? [saveBtn, createBtn]
        : customSwitch({
            [ConfirmationStatus.draft]: [deleteBtn, saveBtn, createBtn],
            [ConfirmationStatus.notSent]: [sendBtn, downloadBtn],
            [ConfirmationStatus.sent]:
              selectedTemplate?.source === DocSource.web ? [sendBtn, downloadBtn] : [downloadBtn],
          })([])(selectedTemplate?.status);

    // @ts-ignore
    btns.unshift(isDirty || isContactsDirty ? cancelBtn : closeBtn);
    return btns;
  }, [
    cancelBtn,
    closeBtn,
    createBtn,
    deleteBtn,
    downloadBtn,
    isContactsDirty,
    isDirty,
    saveBtn,
    selectedTemplate,
    sendBtn,
  ]);

  const titleDisplay = React.useMemo(
    () =>
      !isTemplateSelected ? (
        <Typography className={classes.noTemplateSelected}>{t('templateEditor.noDocSelection')}</Typography>
      ) : (
        <div className={classes.titleRoot}>
          <StatusBubble
            status={
              renderPreviewScreen
                ? ConfirmationStatus.draft
                : !instanceOfConfirmationDoc(selectedTemplate)
                ? ConfirmationStatus.preDraft
                : selectedTemplate?.status
            }
            cardType="confirmation-doc"
          />
          <div className={classes.title}>{templateTitle}</div>
          {document?.documentId ? (
            <>
              <LeftBracket className={classes.subTitle} />
              {/* @ts-ignore */}
              <ResponsiveContainer numOfBtns={btnRender.length}>
                <OverflowTooltip value={document?.documentId} contentClass={classes.subTitle} />
              </ResponsiveContainer>
              <RightBracket className={classes.subTitle} />
            </>
          ) : null}
        </div>
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [btnRender.length, document?.documentId, isTemplateSelected, renderPreviewScreen, selectedTemplate, templateTitle],
  );

  useEffect(() => {
    closeModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceModalCloseFlag]);

  return isPullingPdfDoc ? (
    <Skeleton classes={{ root: classes.titleSkeleton }} />
  ) : (
    <Grid container direction="row" justifyContent="space-between" classes={{ container: classes.container }}>
      <Grid item classes={{ root: cx({ [classes.titleContainer]: isTemplateSelected }) }}>
        {titleDisplay}
      </Grid>
      <Grid item classes={{ root: classes.btnContainer }}>
        <Grid container direction="row" gap={2}>
          {btnRender.map(({ tooltipProps, ...btn }, index) =>
            !!tooltipProps ? (
              <CustomTooltip key={index.toString()} standardMargin {...tooltipProps}>
                <Button variant="contained" size="small" type="button" {...btn} />
              </CustomTooltip>
            ) : (
              <Button key={index.toString()} variant="contained" size="small" type="button" {...btn} />
            ),
          )}
        </Grid>
      </Grid>
      <ActionModal {...modalProps} />
    </Grid>
  );
};

const getIncrementMaxWidth = (numOfBtns, defaultWidth) =>
  customSwitch({
    4: `${defaultWidth}px`,
    3: `${defaultWidth + 95}px`,
    2: `${defaultWidth + 160}px`,
    1: '100%',
  })(`${defaultWidth}px`)(numOfBtns);

const ResponsiveContainer = styled(Box)`
  @media (max-width: 1520px) {
    max-width: ${props => getIncrementMaxWidth(props['numOfBtns'], 255)};
  }
  @media (max-width: 1500px) {
    max-width: ${props => getIncrementMaxWidth(props['numOfBtns'], 235)};
  }
  @media (max-width: 1480px) {
    max-width: ${props => getIncrementMaxWidth(props['numOfBtns'], 215)};
  }
  @media (max-width: 1460px) {
    max-width: ${props => getIncrementMaxWidth(props['numOfBtns'], 195)};
  }
  @media (max-width: 1440px) {
    max-width: ${props => getIncrementMaxWidth(props['numOfBtns'], 175)};
  }
  @media (max-width: 1420px) {
    max-width: ${props => getIncrementMaxWidth(props['numOfBtns'], 155)};
  }
  @media (max-width: 1400px) {
    max-width: ${props => getIncrementMaxWidth(props['numOfBtns'], 135)};
  }
  @media (max-width: 1380px) {
    max-width: ${props => getIncrementMaxWidth(props['numOfBtns'], 115)};
  }
  @media (max-width: 1360px) {
    max-width: ${props => getIncrementMaxWidth(props['numOfBtns'], 95)};
  }
  @media (max-width: 1340px) {
    max-width: ${props => getIncrementMaxWidth(props['numOfBtns'], 75)};
  }
  @media (max-width: 1320px) {
    max-width: ${props => getIncrementMaxWidth(props['numOfBtns'], 50)};
  }
  @media (max-width: 1300px) {
    max-width: ${props => getIncrementMaxWidth(props['numOfBtns'], 40)};
  }
  @media (max-width: 1280px) {
    max-width: 100%;
  }
`;

const LeftBracket = styled.span`
  margin-right: -6px;
  &:after {
    content: '(';
  }
`;

const RightBracket = styled.span`
  margin-left: -6px;
  &:after {
    content: ')';
  }
`;
