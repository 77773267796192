import { UnitCard } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/CustomComponents/UnitCard';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Grid, Typography } from 'amn-ui-core';
import { useDecision } from '@optimizely/react-sdk';
import { ff_facility_ui_view_new_unit_pages } from 'app/constants/FeatureFlags/Facility/Keys';
import { SkillSetType } from 'app/models/Unit/CreateUnit';
import { useSelectedUnitDetails, useIsUserAccountManager, StatusIndicator, useUnitStatusInActive } from './unitHelper';
import { missingField } from 'app/constants';

const ExperiencesOldDesign = () => {
  const { t } = useTranslation();
  const isAuthorized = useIsUserAccountManager();
  const selectedUnitDetail = useSelectedUnitDetails();
  const experience = selectedUnitDetail?.experience;
  const skillSets: SkillSetType[] = experience?.skillSets ?? [];
  const [isNewEditUnitDetails] = useDecision(ff_facility_ui_view_new_unit_pages);
  const isUnitInActive = useUnitStatusInActive();

  return (
    <UnitCard
      title={t('facility.units.experience.title', { expCount: experience?.skillSets?.length ?? 0 })}
      rightIcon={true}
      showEditIcon={isAuthorized && !isUnitInActive}
      showTitleBarBorder={!isNewEditUnitDetails}
      children={
        <>
          <Grid container p={2}>
            {experience ? (
              <>
                <Grid item container alignItems="center">
                  <Typography variant="body2" component="span" fontWeight="bold">
                    {t('facility.units.experience.newGrad')}
                  </Typography>
                  <Typography variant="body1" component="span" pl="5px">
                    {experience.isNewGrad ? t('detailsPage.confirmation.yes') : t('detailsPage.confirmation.no')}
                  </Typography>
                </Grid>

                {skillSets.map((item, index) => (
                  <SkillSetItem item={item} index={index} arrayLength={skillSets.length} />
                ))}
              </>
            ) : (
              <Box>{missingField}</Box>
            )}
          </Grid>
        </>
      }
    />
  );
};

const SkillSetItem = ({ item, index, arrayLength }: { item: SkillSetType; index: number; arrayLength: number }) => {
  const { t } = useTranslation();
  return (
    <Grid item container direction="column" pt="12px" key={index}>
      <StatusIndicator isRequired={item.isRequired} />

      <Grid item container rowSpacing={2} pt="6px">
        <Grid item xs={6}>
          <SkillSetFields title={t('home.amWorkDesk.unitFilter.discipline')} value={item.discipline ?? missingField} />
        </Grid>
        <Grid item xs={6}>
          <SkillSetFields title={t('home.amWorkDesk.unitFilter.specialty')} value={item.specialty ?? missingField} />
        </Grid>
        <Grid item xs={6}>
          <SkillSetFields
            title={t('order.createUnit.experience.subSpecialty')}
            value={item.subSpecialty ?? missingField}
          />
        </Grid>
        <Grid item xs={6}>
          <SkillSetFields
            title={t('facility.units.travelExp.yearsOfExp')}
            value={item.yearsOfExperience ?? missingField}
          />
        </Grid>
      </Grid>
      {arrayLength > index + 1 ? <Divider sx={{ pt: '12px' }} /> : <></>}
    </Grid>
  );
};

const SkillSetFields = ({ title, value }: { title: string; value: string | number }) => {
  return (
    <>
      <Typography component="div" fontWeight="bold" variant="body2">
        {title}
      </Typography>
      <Typography component="div" pt="6px">
        {value}
      </Typography>
    </>
  );
};

export default ExperiencesOldDesign;
