/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Grid, ThemeProvider, StyledEngineProvider,useTheme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { reqPref } from 'app/enums/Unit';
import { FloatType } from 'app/models/Unit/CreateUnit';
import { useTranslation } from 'react-i18next';
import { ReqPrefSection } from '../Common/ReqPrefSection';
import { SingleLineInput } from '../Common/SingleLineInput';
import { UnitCardsTitle, UnitDetailsBody } from '../UnitDesign';
import { SimpleCheckbox } from '../Common/SimpleCheckbox';
import { Controller, useFormContext } from 'react-hook-form';

interface Props {
  disableEdit?: boolean;
  hasRadio?: boolean;
}

const useStyles = makeStyles()(() => ({
  topPadding: {
    paddingTop: 12,
  },
}));

export const NewFloat = (props: Props) => {
  const { disableEdit } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { control, getValues, watch, setValue, reset } = useFormContext();
  const watchValue = watch('float');
  const showPanel = watchValue;
  const theme= useTheme();

  const handleCheckBoxChangeX = (event: React.ChangeEvent<HTMLInputElement>) => {
    //const existingValues = getValues();
    if (event.target.checked) {
      const updatedValue = {
        isRequired: true,
        floatToUnit: '',
        floatToLocation: '',
        floatToMiles: 0,
      } as FloatType;
      setValue('float', updatedValue);
      setValue('floatSelected', true);
      /*reset({
        ...existingValues,
        float: updatedValue,
      });*/
    } else {
      const existingValues = getValues();
      setValue('float', null);
      reset({
        ...existingValues,
        float: null,
      });
      setValue('floatSelected', false);
    }
  };

  const getNumericValue = (value: string) => {
    const numericValue = parseInt(value);
    return numericValue >= 0 ? numericValue : '';
  };

  return (
    <div>
      {/* TODO: Remove this theme wrapper since it uses the global theme */}
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <UnitDetailsBody variant="outlined">
            <Grid container direction="column" spacing={1}>
              <Grid item xs={12}>
                <Grid container direction="row" spacing={1}>
                  <Grid item>
                    <UnitCardsTitle>{t('order.createUnit.float.title')}</UnitCardsTitle>
                  </Grid>
                  <Controller
                    control={control}
                    name={'float'}
                    render={({ ref, onChange, ...rest }) => (
                      <SimpleCheckbox
                        id={'createUnitFloatEnableCheck'}
                        isChecked={showPanel}
                        isdisabled={disableEdit}
                        hasRadio={false}
                        handleChecked={handleCheckBoxChangeX}
                        checkboxLabel={`Yes`}
                        checkboxName={'no-cbx'}
                      />
                    )}
                  />
                </Grid>
                {showPanel && (
                  <>
                    <Controller
                      control={control}
                      name={'float.isRequired'}
                      render={({ ref, onChange, ...rest }) => (
                        <ReqPrefSection
                          isRequired={rest.value ?? false}
                          handleReqPref={(event: React.ChangeEvent<HTMLInputElement>) => {
                            onChange(event.target.value === reqPref.req);
                          }}
                          isdisabled={disableEdit}
                          id={'createUnitFloatReqPrefRadio'}
                        />
                      )}
                    />
                    <Grid className={classes.topPadding} container direction="row" spacing={3}>
                      <Grid item xs={4}>
                        <Controller
                          control={control}
                          name={'float.floatToUnit'}
                          render={({ ref, onChange, ...rest }) => (
                            <SingleLineInput
                              id={'createUnitFloatFloatToUnitTxt'}
                              inputDefaultValue={rest.value}
                              inputName={'floatToUnit'}
                              isdisabled={disableEdit}
                              inputLabel={t('order.createUnit.float.floatUnit')}
                              inputValue={rest.value}
                              handleChange={event => {
                                onChange(event.target.value);
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Controller
                          control={control}
                          name={'float.floatToLocation'}
                          render={({ ref, onChange, ...rest }) => (
                            <SingleLineInput
                              id={'createUnitFloatFloatToLocationTxt'}
                              inputDefaultValue={rest.value}
                              inputName={'floatToLocation'}
                              isdisabled={disableEdit}
                              inputLabel={t('order.createUnit.float.floatLocation')}
                              inputValue={rest.value}
                              handleChange={event => {
                                onChange(event.target.value);
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Controller
                          control={control}
                          name={'float.floatToMiles'}
                          render={({ ref, onChange, ...rest }) => (
                            <SingleLineInput
                              id={'createUnitFloatFloatToMilesTxt'}
                              inputDefaultValue={rest.value ? parseInt(rest.value) : 0}
                              inputName={'floatToMiles'}
                              isdisabled={disableEdit}
                              inputLabel={t('order.createUnit.float.floatToMiles')}
                              inputValue={rest.value ? parseInt(rest.value) : 0}
                              handleChange={event => {
                                onChange(getNumericValue(event.target.value));
                              }}
                              inputType={'number'}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </UnitDetailsBody>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
};
