import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { startTimedEvent, trackPageView, usePageLoadingTracker, usePageVisitTimeTracker } from 'app-insights/appInsightsTracking';
import { FacilityDetailsWrapper } from './FacilityDetailsWrapper';
import { FacilityType } from 'app/models/Facility/FacilityDetails';
import styledComponent from 'styled-components';
import { usePromiseTracker } from 'react-promise-tracker';
import CircularProgress from '@mui/material/CircularProgress';
import { selectNavigationData } from '../../Common/BreadcrumbNavigation/BreadcrumbNavigation.selector';
import { useDispatch, useSelector } from 'react-redux';
import { facilityActions } from '../FacilityStore/Facility.redux';
import { navigationStickActions } from 'app/components/GlobalNavigationMenu/NavigationStick.redux';
import { calculateIsCreditHold, calculateIsCreditPending, calculateIsMaxBookingLimit } from './helper';
import { navigationHelpers } from 'app/components/GlobalNavigationMenu/NavigationStick.selectors';
import { setFacilityDetailsForOrder } from '../../Order/OrderCreationForm/OrderCreationForm';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  StyledEngineProvider,
  ThemeProvider,
  useTheme,
} from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { ButtonSpace, MessageHeader } from 'app/components/Order/OrderDesign';
import { orderDataActions } from 'app/components/Order/Store/Order.redux';
import { selectUser } from 'oidc/user.selectors';
import {
  selectFacility,
  selectFacilityDetail,
  selectIsFacilityByIdRequestSuccess,
} from '../FacilityStore/Facility.selector';
import { CommonNoDataPage } from 'app/components/Common/NoDataPage/CommonNoDataPage';
import { selectEditUnitProcessStep } from 'app/components/Order/Store/Order.selectors';
import { IUnitEditProcessStep } from 'app/components/Order/Store/Order.redux';
import { DrawerFitted } from 'app/components/Common/Drawer/Drawer';
import { FacilityContactsDrawerViewer } from './SidePanel/FacilityContactsDrawerViewer';
import useDocumentTitle from 'utils/customHooks/useDocumentTitle';
import { navigationProfilesAction } from '@AMIEWEB/GlobalSearch/store/NavigationProfiles.redux';
import { createOrderOcbrActions } from 'store/redux-store/create-order-ocbr/slice';
import { setCreatedUnitId, setProceedToUnitCreation } from '@AMIEWEB/Order/Store/OrderCreation.redux';
import { selectProceedToUnitCreation } from '@AMIEWEB/Order/Store/OrderCreation.selectors';
import { ff_facility_ui_view_unit_landing_page } from 'app/constants/FeatureFlags/Facility/Keys';
import { useDecision } from '@optimizely/react-sdk';
import { AMNDivisionType } from 'app/models/enums/AMNDivisionType';
import { GenericDialog } from '@AMIEWEB/Alerts/GenericDialog';

const CustomCircularProgress = styledComponent(CircularProgress)`
  left: 50%;
  position: absolute;
  top: 50vh;
`;

const useStyles = makeStyles()(theme => ({
  button: {
    width: '100px',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#006FB9',
      color: '#ffffff',
    },
  },
  buttonWidth: {
    marginRight: theme.spacing(1),
  },
  buttonHeight: {
    lineHeight: 2,
  },
  modalButton: {
    width: 100,
  },
  dialog: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 550,
    },
  },
}));

export enum pageParams {
  default = '',
  newOrder = 'neworder',
  newUnit = 'newunit',
  editUnit = 'editunit',
}

export const FacilityDetails = () => {
  const theme = useTheme();
  const params = useParams<{ id: string }>();
  const dispatch = useDispatch();
  useDocumentTitle({ title: `FID ${params.id}` });
  usePageLoadingTracker(); // Must come after the document title is set
  const { t } = useTranslation();
  const { classes } = useStyles();
  const history = useHistory();
  const { promiseInProgress } = usePromiseTracker({ area: 'facility', delay: 0 });
  const [view, setView] = useState<FacilityType>(FacilityType.facilityDetails);
  const user = useSelector(selectUser);
  const facilityData = useSelector(selectFacility);
  const facilityDetail = useSelector(selectFacilityDetail);
  const [navigateToUnitDecision] = useDecision(ff_facility_ui_view_unit_landing_page, { autoUpdate: true });
  const navigationData = useSelector(selectNavigationData);
  const { getNavigationMenuByKey } = useSelector(navigationHelpers);
  const [isCreditHold, setIsCreditHold] = useState<boolean>(false);
  const [isCreditPending, setIsCreditPending] = useState<boolean>(false);
  const [isMaxBookingLimit, setIsMaxBookingLimit] = useState<boolean>(false);
  const [openNavConfirmation, setOpenNavConfirmation] = useState<boolean>(false);
  const [activateOrderCreationStep, setActivateOrderCreationStep] = useState<number>(1);
  const [createdNewUnitId, setCreatedNewUnitId] = useState<number>(-1);
  const [fromNavigation, setFromNavigation] = useState<boolean>(true);
  const facilityMenuOption = getNavigationMenuByKey('facility');
  const [unitNavigationTarget, setUnitNavigationTarget] = useState<string>('');
  const storeEditUnitProcessStep = useSelector(selectEditUnitProcessStep);
  const [unitstandardizConfirAlertOpen, setUnitstandardizConfirAlertOpen] = useState<boolean>(false);
  const [isDoNotShowCheckboxSelected, setIsDoNotShowCheckboxSelected] = React.useState(false);
  const [isOverrideEligible, setIsOverrideEligible] = useState<boolean>(false);
  const facilityDataForUnit =
    facilityDetail &&
    setFacilityDetailsForOrder(facilityDetail, user.userInfo?.employeeId ? user.userInfo.employeeId : 0);
  const proceedToUnitCreation = useSelector(selectProceedToUnitCreation);
  const facilityLoading = useSelector(selectIsFacilityByIdRequestSuccess);

  usePageVisitTimeTracker('Facility Details');

  const determineCreditHoldOverride = () => {
    const facilityStatus = facilityDetail?.facilityStatus?.toUpperCase();
    if (facilityStatus === 'DELETE' || facilityStatus === 'CLOSED' || facilityStatus === 'INACTIVE') {
      setIsOverrideEligible(false);
    } else if (isCreditHold || isCreditPending) {
      switch (facilityData?.facilityResponse?.amnDivisionTypeId) {
        case AMNDivisionType.hsg:
          setIsOverrideEligible(true);
          break;

        case AMNDivisionType.allied:
          if (facilityStatus === 'PROSPECT') {
            setIsOverrideEligible(true);
          } else {
            setIsOverrideEligible(false);
          }
          break;
        default:
          setIsOverrideEligible(false);
      }
    } else {
      setIsOverrideEligible(false);
    }
  };

  const initiateFacilityAPICalls = useCallback(() => {
    dispatch(facilityActions.getFacilityDetailsWithOpenOrderCountAction({ id: parseInt(params.id) }));
    dispatch(navigationStickActions.setSelectedMenuByKey('facility'));
  }, [params?.id]);

  const setupDataForFacilityDetail = () => {
    const { facilityResponse, openOrdersCount } = facilityData;
    setIsCreditHold(calculateIsCreditHold(facilityResponse?.creditStatus));
    setIsCreditPending(calculateIsCreditPending(facilityResponse?.creditStatus));
    setIsMaxBookingLimit(calculateIsMaxBookingLimit(facilityResponse?.bookingLimit, openOrdersCount));
  };

  useEffect(() => {
    if (params?.id) {
      initiateFacilityAPICalls();
      trackPageView({ name: `FID ${params.id}` });
    }
    return () => {
      dispatch(navigationProfilesAction.setNavigationFlag({ navigationFromGrid: false }));
    };
  }, [params?.id]);

  useEffect(() => {
    if (facilityDetail?.facilityId) {
      setupDataForFacilityDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityDetail?.facilityId]);

  useEffect(() => {
    determineCreditHoldOverride();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityDetail?.facilityStatus]);

  const navigateToUnitCreation = (value: boolean) => {
    if (value) {
      setView(FacilityType.createUnit);
      startTimedEvent('unitCreationStart');
    } else {
      setView(FacilityType.facilityDetails);
    }
  };

  const handleNavConfirmClose = (confirmation: boolean, activateStep?: number) => {
    if (confirmation) {
      dispatch(createOrderOcbrActions.reset());
      if (activateStep === 0) {
        setActivateOrderCreationStep(0);
        if (createdNewUnitId > 0) {
          setUnitNavigationTarget('SelectUnit');
        }
      } else {
        if (activateStep) {
          if (createdNewUnitId > 0) {
            setUnitNavigationTarget('UpdateOrderDetails');
          }
          setActivateOrderCreationStep(activateStep);
        }
      }
      //init creation order and navigate to order page
      if (createdNewUnitId > 0 && !!proceedToUnitCreation && proceedToUnitCreation === true) {
        dispatch(setCreatedUnitId(createdNewUnitId));
        history.push({
          pathname: `/facility/${params.id}/order/new`,
          state: 'navigationCycle',
        });
      }
    } else {
      dispatch(orderDataActions.setNewOrder(null));
    }
    setOpenNavConfirmation(false);
  };

  const createUnitNav = async (value: boolean) => {
    dispatch(orderDataActions.setOrderCreationProcessStep(null));
    setFromNavigation(true);
    navigateToUnitCreation(true);
    await dispatch(setProceedToUnitCreation(false));
    history.push({
      pathname: `/facility/${params.id}/newUnit`,
      state: 'navigationCycle',
    });
  };

  const handleClose = () => {
    setUnitstandardizConfirAlertOpen(false);
    dispatch(orderDataActions.setOrderCreationProcessStep(null));
    dispatch(orderDataActions.setUnitEditProcessStep(null));
    dispatch(orderDataActions.setNewUnit(null));
  };

  const handleCheck = (values: any) => {
    if (values == true) {
      setIsDoNotShowCheckboxSelected(true);
    }
  };

  const handleAlertStandardize = () => {
    setUnitstandardizConfirAlertOpen(false);
    dispatch(
      orderDataActions.setUnitEditProcessStep({
        facilityId: storeEditUnitProcessStep?.facilityId,
        facilityResponse: facilityDetail,
        facilityDataForUnit: facilityDataForUnit,
        selectedNewUnitId: storeEditUnitProcessStep?.selectedNewUnitId,
        selectedNewUnit: null,
        unitsData: null,
        readyToEdit: true,
        editMode: 'standardized',
        isRememebrCheckboxSelected: isDoNotShowCheckboxSelected,
      } as IUnitEditProcessStep),
    );
    history.push(
      `/facility/${params.id}/unit/${storeEditUnitProcessStep?.selectedNewUnitId}/edit?mode=standardized&rember=-1`,
    );
  };
  const handleAlertNotNow = () => {
    setUnitstandardizConfirAlertOpen(false);
    dispatch(
      orderDataActions.setUnitEditProcessStep({
        facilityId: storeEditUnitProcessStep?.facilityId,
        facilityResponse: facilityDetail,
        facilityDataForUnit: facilityDataForUnit,
        selectedNewUnitId: storeEditUnitProcessStep?.selectedNewUnitId,
        selectedNewUnit: null,
        unitsData: null,
        readyToEdit: true,
        editMode: 'notnow',
        isRememebrCheckboxSelected: isDoNotShowCheckboxSelected,
      } as IUnitEditProcessStep),
    );
    history.push(
      `/facility/${params.id}/unit/${storeEditUnitProcessStep?.selectedNewUnitId}/edit?mode=notnow&rember=${
        isDoNotShowCheckboxSelected === true ? 1 : -1
      }`,
    );
  };

  useEffect(() => {
    if (facilityData?.unitNavigationDetails?.unitId) {
      const { facilityId, unitId, mode, standardizedRequirements } = facilityData?.unitNavigationDetails;
      if (navigateToUnitDecision.enabled) {
        history.push(`/facility/${params.id}/unit/${unitId}`);
        dispatch(facilityActions.setUnitNavigationDetails(null));
      } else if (mode === `notnow`) {
        //open dialog
        if (standardizedRequirements === null || standardizedRequirements === undefined) {
          setUnitstandardizConfirAlertOpen(true);
          dispatch(
            orderDataActions.setUnitEditProcessStep({
              facilityId: facilityId,
              facilityResponse: facilityDetail,
              facilityDataForUnit: facilityDataForUnit,
              selectedNewUnitId: unitId,
              selectedNewUnit: null,
              unitsData: null,
              readyToEdit: false,
              editMode: 'notnow',
              isRememebrCheckboxSelected: false,
            } as IUnitEditProcessStep),
          );
        } else {
          setUnitstandardizConfirAlertOpen(false);
          dispatch(
            orderDataActions.setUnitEditProcessStep({
              facilityId: facilityId,
              facilityResponse: facilityDetail,
              facilityDataForUnit: facilityDataForUnit,
              selectedNewUnitId: unitId,
              selectedNewUnit: null,
              unitsData: null,
              readyToEdit: true,
              editMode: 'notnow',
              isRememebrCheckboxSelected: standardizedRequirements,
            } as IUnitEditProcessStep),
          );
          //setView(FacilityType.editUnit);
          history.push(
            `/facility/${params.id}/unit/${unitId}/edit?mode=notnow&rember=${
              isDoNotShowCheckboxSelected === true ? 1 : -1
            }`,
          );
        }
      } else {
        setUnitstandardizConfirAlertOpen(false);
        dispatch(
          orderDataActions.setUnitEditProcessStep({
            facilityId: facilityId,
            facilityResponse: facilityDetail,
            facilityDataForUnit: facilityDataForUnit,
            selectedNewUnitId: unitId,
            selectedNewUnit: null,
            unitsData: null,
            readyToEdit: true,
            editMode: 'standardized',
          } as IUnitEditProcessStep),
        );
        history.push(`/facility/${params.id}/unit/${unitId}/edit?mode=standardized&rember=-1`);
      }
    }
  }, [facilityData?.unitNavigationDetails]);

  const changeView = (activeView: FacilityType) => {
    if ((isCreditHold || isCreditPending) && activeView === 'CreateOrder' && !isOverrideEligible) {
      activeView = FacilityType.facilityDetails;
    }
    switch (activeView) {
      case 'FacilityDetails':
        return (
          <FacilityDetailsWrapper
            navigationData={navigationData}
            isCreditHold={isCreditHold}
            isCreditPending={isCreditPending}
            isMaxBookingLimit={isMaxBookingLimit}
            handleUnitCreation={createUnitNav}
            handleOrderCreation={handleNavConfirmClose}
            isOverrideEligible={isOverrideEligible}
            determineCreditHoldOverride={determineCreditHoldOverride}
          />
        );
    }
  };

  const showFacilityDetailPage = useMemo(() => {
    if (facilityData && !facilityData?.facilityResponse && !facilityLoading) {
      return <CommonNoDataPage message={`Unable to find information about selected facility`} />;
    } else {
        return changeView(view);
      }
  }, [facilityLoading]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        {showFacilityDetailPage}
        {unitstandardizConfirAlertOpen && (
          <GenericDialog
            open={unitstandardizConfirAlertOpen}
            disableEscapeKeyDown
            maxWidth="sm"
            fullWidth
            dialogTitleProps={{
              text: t(`Standardize Requirements?`),
            }}
            dialogActions={[
              {
                text: t('CANCEL'),
                variant: 'text',
                color: 'tertiary',
                onClick: handleClose,
              },
              {
                text: t('YES'),
                variant: 'contained',
                color: 'tertiary',
                onClick: handleAlertStandardize,
              },
              {
                text: t('NOT NOW'),
                variant: 'contained',
                color: 'primary',
                onClick: handleAlertNotNow,
              },
            ]}
          >
            <span>
              {t(`You are opening a unit that was created in AMIE Classic. Do you want to standardize requirements?`)}
            </span>
            <div style={{ paddingLeft: '23px', paddingTop: '10px' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    disableFocusRipple
                    disableRipple
                    disableTouchRipple
                    onChange={event => handleCheck(event.target.checked)}
                    name={`temp`}
                    color="primary"
                  />
                }
                label={`Do not ask me to standardize this unit again`}
              />
            </div>
          </GenericDialog>
        )}
        {openNavConfirmation && (
          <Dialog
            open={openNavConfirmation}
            onClose={() => handleNavConfirmClose(false)}
            fullWidth
            maxWidth={'sm'}
            aria-labelledby="submit-confirmation"
            aria-describedby="submit-confirmation-description"
            className={classes.dialog}
            disableEscapeKeyDown
          >
            <DialogTitle id="submit-confirmation-title">
              <MessageHeader>{t('order.facilityDetails.orderCreationNavConfirmationMessage')}</MessageHeader>
            </DialogTitle>
            <DialogActions>
              <ButtonSpace>
                <Button
                  onClick={() => handleNavConfirmClose(false)}
                  disableElevation
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                  color="inherit"
                  className={`${classes.button} ${classes.buttonWidth} ${classes.buttonHeight} ${classes.modalButton}`}
                  data-testid="test-dialogSubmitConfirmButton"
                >
                  {t('order.facilityDetails.orderCreationNavConfirmationOption.no')}
                </Button>
                <Button
                  onClick={() => handleNavConfirmClose(true, 5)}
                  disableElevation
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                  variant="contained"
                  color="primary"
                  className={`${classes.button} ${classes.buttonWidth} ${classes.buttonHeight} ${classes.modalButton}`}
                  data-testid="test-dialogSubmitCancelButton"
                >
                  {t('order.facilityDetails.orderCreationNavConfirmationOption.yes')}
                </Button>
              </ButtonSpace>
            </DialogActions>
          </Dialog>
        )}
        {facilityData.searchResults?.drawerData?.open && (
          <DrawerFitted
            onDrawerClose={() => {
              dispatch(facilityActions.setDrawerData({ open: false, data: undefined }));
            }}
            width={450}
            top={0}
            backgroundColor={'#FFFFFF'}
            open={facilityData.searchResults?.drawerData?.open || false}
          >
            {<FacilityContactsDrawerViewer />}
          </DrawerFitted>
        )}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
