import { Button, Grid } from 'amn-ui-core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  root: {
    backgroundColor: '#FDEDE9',
    width: '100%',
    padding: '12px 6px',
  },
  icon: {
    marginRight: 12,
    color: '#FF0000',
    fontSize: 24,
  },
  text: {
    fontSize: 14,
    alignSelf: 'center',
  },
  submitButton: {
    padding: '12px 9px',
    // width: '140px',
    backgroundColor: '#006FB9',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#006FB9',
    },
    marginRight: '24px',
    marginLeft: '24px',
    fontSize: '12px',
    height: '38px',
    '& .MuiInputBase-root.Mui-disabled': {
      pointerEvents: 'auto',
    },
  },
});

export interface IBannerMessageProps {
  message: string;
  refreshButton?: any;
  refreshAction?: any;
}

export const BannerMessage = ({ message, refreshButton, refreshAction }: IBannerMessageProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  return (
    <Grid
      container
      justifyContent="space-between"
      direction={'row'}
      alignContent="center"
      className={classes.root}
      wrap="nowrap"
    >
      <Grid item className={classes.text}>
      <ErrorOutlineIcon className={classes.icon} />
        {message}
      </Grid>
      {refreshButton && (
        <Button
          variant="contained"
          onClick={e => {
            refreshAction();
          }}
          className={classes.submitButton}
        >
          {t('placement.placementMods.refresh')}
        </Button>
      )}
    </Grid>
  );
};
