import { Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import Warning from 'app/assets/images/IconImages/warning.svg';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { BannerActions } from './BannerActions';
import { IBannerAction } from 'app/models/Global/Global';
import { useTranslation } from 'react-i18next';
import { isNullOrUndefined } from 'app/helpers/objectHelpers';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';


const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    padding: '12px 6px',
    display: 'flex',
    alignItems: 'center',
  },
  subRoot: {
    width: '100%',
  },
  rootWarning: {
    backgroundColor: '#FEF6EE',
    border: '1px solid #FFA22E',
    justifyContent: 'left',
    '& #custom-banner-text': {
      color: '#663B01',
    },
  },
  rootError: {
    backgroundColor: '#FDEDE9',
    '& #custom-banner-text': {
      color: '#611a16',
    },
  },
  rootSuccess: {
    backgroundColor: theme.palette.system.lighterGreen,
    '& #custom-banner-text': {
      color: theme.palette.system.darkerGreen,
    },
  },
  icon: {
    marginLeft: '6px',
    marginRight: '12px',
  },
  text: {
    fontSize: 14,
    alignSelf: 'center',
  },
  action: {
    marginLeft: '12px',
  },
}));

export const Banner = ({
  message,
  severity = 'warning',
  justify = 'flex-start',
  action,
}: {
  message: string;
  severity?: 'warning' | 'error' | 'success';
  justify?: 'flex-start' | 'center' | 'flex-end';
  action?: IBannerAction;
}) => {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();

  return (
    <Grid
      container
      justifyContent={justify}
      direction={'row'}
      alignContent="center"
      className={cx(classes.root, {
        [classes.rootWarning]: severity === 'warning',
        [classes.rootError]: severity === 'error',
        [classes.rootSuccess]: severity === 'success',
      })}
      wrap="nowrap"
    >
      <Grid item>
        <Grid container className={classes.subRoot} wrap="nowrap">
          <Grid item>
            {severity === 'warning' && <img src={Warning} alt="Warning" className={classes.icon} />}
            {severity === 'error' && <ErrorOutlineIcon style={{ color: 'red' }} className={classes.icon} />}
            {severity === 'success' && <CheckCircleOutlineIcon sx={{ color: '#5FB661' }} className={classes.icon} />}
          </Grid>
          <Grid id="custom-banner-text" item className={classes.text}>
            {t(message)}
          </Grid>
        </Grid>
      </Grid>
      {!isNullOrUndefined(action) && (
        <Grid item className={classes.action}>
          <BannerActions action={action!} />
        </Grid>
      )}
    </Grid>
  );
};
