import React from 'react';
import { Autocomplete, TextField } from 'amn-ui-core';
import { Controller, useFormContext } from 'react-hook-form';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const AutoCompleteRenderDropdowns = ({
  label,
  options,
  id,
  name,
}: {
  label: string;
  options: any;
  id: string;
  name: string;
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ onChange, ...props }) => (
        <Autocomplete
          id={id}
          fullWidth
          disablePortal
          options={options}
          getOptionLabel={option => option?.Description}
          onChange={(e, data) => onChange(data)}
          renderInput={params => <TextField {...params} label={label} variant="filled" />}
          popupIcon={false ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          {...props}
        />
      )}
    />
  );
};

export default AutoCompleteRenderDropdowns;
