import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Radio,
  RadioGroup,
  Skeleton,
  StyledEngineProvider,
  TextField,
  ThemeProvider,
  createTheme,
  debounce,
} from 'amn-ui-core';
import React, { Suspense, useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { CustomTooltip } from 'app/components/Common/Tooltips';
import { theme } from 'styles/global-styles';

const useStyles = makeStyles()(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    maxHeight: 250,
    overflow: 'auto',
    paddingTop: 0,
    paddingBottom: 0,
  },
  wrapper: {
    backgroundColor: `${theme.palette.components.box.container.backgroundColor} !important`,
    maxHeight: 300,
  },
  item: {
    borderBottom: `1px solid ${theme.palette.system.lightGrey2}`,
    borderColor: theme.palette.system.lightGrey2,
  },
  icon: {
    borderRadius: 3,
    padding: 2,
    border: `1px solid ${theme.palette.system.lightGrey}`,
    marginTop: 6,
  },

  iconSize: {
    fontSize: 'larger',
    padding: 4,
  },
  listItemContent: {
    display: '-webkit-box',
    maxWidth: '50ch',
    fontSize: 14,
    lineHeight: 1.4,
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  tooltip: {
    backgroundColor: theme.palette.components.tooltip.backgroundColor,
    color: theme.palette.components.tooltip.color,
    opacity: 1,
    textAlign: 'left',
    letterSpacing: '0px',
    boxShadow: '0px 3px 6px #00000029',
    border: `1px solid ${theme.palette.system.darkGray}`,
  },
  arrow: {
    color: theme.palette.components.tooltip.arrowColor,
    opacity: 1,
    '&::before': {
      boxShadow: '0px 3px 6px #00000029',
      border: `1px solid ${theme.palette.system.darkGray}`,
    },
  },

  textField: {
    fontSize: 12,
    marginLeft: 12,
    marginRight: 12,
    color: theme.palette.system.violet,
    '&::placeholder': {
      opacity: 1,
      fontSize: 12,
      color: theme.palette.system.lightGrey2,
    },
  },
}));

const smallTextFieldTheme = createTheme(theme, {
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          height: '37.625px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: { height: '37.625px' },
      },
    },
  },
});
export const CustomizedSubCertificationTypeAhead = ({
  options,
  selectedValues,
  setSelectedStates,
}: {
  options: any[];
  selectedValues: any[];
  setSelectedStates: (e) => void;
}) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [subCertList, setSubCertList] = useState<any[]>([]);

  const handleToggle = value => {
    const selectedCertificationOptions = [...selectedValues];
    const existingSubCert = selectedCertificationOptions?.find(item => item?.id === value.id);
    if (existingSubCert) {
      const filteredSubCerts = selectedCertificationOptions?.filter(item => item?.id !== value.id);
      setSelectedStates(filteredSubCerts);
    } else {
      const subCertData = {
        ...value,
        isSubCert: true,
        isRequired: true,
        documentAttachmentRequired: false,
      };
      selectedCertificationOptions.push(subCertData);
      setSelectedStates(selectedCertificationOptions);
    }
  };

  const handleDocumentRequired = value => {
    const updatedStateData = selectedValues?.map(item => {
      if (item?.id === value?.id) {
        item.documentAttachmentRequired = !item.documentAttachmentRequired;
      }
      return item;
    });
    setSelectedStates(updatedStateData);
  };

  const handleRequirementChange = (stateValue, value) => {
    const existingStateValue = selectedValues?.find(item => item?.id === stateValue?.id);
    let updatedStateOption = {};
    if (value === 'req') {
      updatedStateOption = {
        ...existingStateValue,
        isRequired: true,
      };
    } else {
      updatedStateOption = {
        ...existingStateValue,
        isRequired: false,
      };
    }
    const selectedStateOptions = [...selectedValues];
    const updatedStateOptionArray = selectedStateOptions?.map(item => {
      if (item?.id === existingStateValue?.id) {
        return updatedStateOption;
      }
      return item;
    });
    setSelectedStates(updatedStateOptionArray);
  };

  const handleSearch = event => {
    (() => {
      if (options?.length > 0) {
        const filteredStates = options?.filter(item =>
          item?.abbreviation?.toLowerCase()?.includes(event.target.value?.toLowerCase()),
        );
        setSubCertList(filteredStates);
      }
    })();
  };

  useEffect(() => {
    setSubCertList(options);
  }, [options]);

  const debouncedSearchHandler = debounce(handleSearch, 300);

  const OptionRender = React.useMemo(() => {
    return (
      <List className={classes.root} id={'stateCell'}>
        <Suspense fallback={<Skeleton variant="rectangular" />}>
          {(subCertList || [])?.map(stateValue => {
            const selectedState = selectedValues?.find(item => item?.id === stateValue?.id);
            return (
              <ListItem key={stateValue?.id} role={undefined} dense className={classes.item}>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    disableFocusRipple
                    disableRipple
                    disableTouchRipple
                    checked={!!selectedState}
                    tabIndex={-1}
                    inputProps={{ 'aria-labelledby': stateValue?.abbreviation }}
                    color="primary"
                    onChange={e => handleToggle(stateValue)}
                  />
                </ListItemIcon>
                <ListItemText
                  classes={{
                    primary: classes.listItemContent,
                  }}
                  id={stateValue?.id}
                  primary={stateValue?.abbreviation}
                />
                <ListItemSecondaryAction>
                  {!!selectedState && (
                    <Grid container>
                      <Grid item>
                        <RadioGroup
                          aria-label="Required"
                          name={'required'}
                          onChange={(e, value) => handleRequirementChange(stateValue, value)}
                          row
                        >
                          <FormControlLabel
                            value="req"
                            control={
                              <Radio
                                color="primary"
                                disableRipple
                                disableTouchRipple
                                disableFocusRipple
                                checked={selectedState?.isRequired}
                              />
                            }
                            label="Req"
                          />
                          <FormControlLabel
                            value="pref"
                            control={
                              <Radio
                                color="primary"
                                disableRipple
                                disableTouchRipple
                                disableFocusRipple
                                checked={!selectedState?.isRequired}
                              />
                            }
                            label="Pref"
                          />
                        </RadioGroup>
                      </Grid>
                      <Grid item>
                        <CustomTooltip
                          tooltipContent={'Document attached with submission'}
                          placement="bottom"
                          tooltip={classes.tooltip}
                          customArrow={classes.arrow}
                        >
                          <IconButton
                            color="primary"
                            edge="end"
                            aria-label="document"
                            disableFocusRipple
                            disableTouchRipple
                            disableRipple
                            className={`${classes.icon}`}
                            onClick={() => handleDocumentRequired(stateValue)}
                            size="medium"
                          >
                            <DescriptionOutlinedIcon
                              style={{
                                fill: selectedState?.documentAttachmentRequired
                                  ? theme.palette.components.icons.primary.color
                                  : theme.palette.system.mediumGrey,
                              }}
                              fontSize="medium"
                              className={classes.iconSize}
                            />
                          </IconButton>
                        </CustomTooltip>
                      </Grid>
                    </Grid>
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </Suspense>
      </List>
    );
  }, [selectedValues, subCertList]);

  return (
    <>
      <Box className={classes.wrapper}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={smallTextFieldTheme}>
            <TextField
              variant="outlined"
              color="primary"
              placeholder={t('appHeader.searchPlaceHolder')}
              id="txtCertification"
              autoComplete="on"
              onChange={debouncedSearchHandler}
              fullWidth
              style={{ margin: '0px 18px', width: '92%' }}
              size={'small'}
              InputProps={{
                classes: { input: classes.textField },
                startAdornment: <SearchIcon style={{ opacity: 0.6 }} />,
                'aria-label': 'filter-type-ahead',
              }}
            />
          </ThemeProvider>
        </StyledEngineProvider>
        {OptionRender}
      </Box>
    </>
  );
};
