import { Avatar, Box, IconButton, MenuItem, Popover, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCoveredInbox } from 'store/redux-store/user-coverage/selectors';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { formatPhoneNumber } from 'app/helpers/phoneHelpers';
import CheckIcon from '@mui/icons-material/Check';
import { coverageActions } from 'store/redux-store/user-coverage/async-actions';
import { useDecision } from '@optimizely/react-sdk';
import { ff_notifications_coverage_sms } from 'app/constants/FeatureFlags/EmployeeCoverage/keys';
import { coverageAvatarColors } from 'store/redux-store/user-coverage/coverageAvatarColors';
import { selectUser } from 'oidc/user.selectors';
import { ICoveredUserInbox } from 'app/models/UserCoverage/UserCoverage';
import { IUnreadSmsCount } from 'app/models/Notification/Notification';
import { selectUnreadCount } from 'store/redux-store/notification/notification.selector';
import { useSelectInboxStyles } from './MessageInbox.styles';

export interface ISelectInboxProps {
  onClickHandler?: () => any;
  onChangeHandler?: (e) => any;
  setPopOverOpen: (e) => any;
  popOverOpen: boolean;
  toolbarRef?: any;
  setPrevUser?: any;
  modalOpen: boolean;
  savePinnedConversations?: (isOpen: boolean) => any;
}

const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
};

const SelectInbox = (props: ISelectInboxProps) => {
  const { classes } = useSelectInboxStyles();
  const { setPopOverOpen, popOverOpen, savePinnedConversations } = props;
  const { coveredInboxes, activeInbox } = useSelector(selectCoveredInbox);
  const user = useSelector(selectUser);
  const unreadCountArr = useSelector(selectUnreadCount);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [checkCoverage] = useDecision(ff_notifications_coverage_sms);
  const [isHovered, setIsHovered] = React.useState(false);
  const [hoveredItem, setHoveredItem] = React.useState(null);
  const disabled = !checkCoverage || coveredInboxes?.length < 2;

  const open = Boolean(anchorEl);
  const ref = React.useRef<any>();
  const dispatch = useDispatch();
  const { width } = useWindowSize();

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (popOverOpen) {
      setAnchorEl(null);
      setPopOverOpen(false);
    } else {
      setAnchorEl(props?.toolbarRef?.current);
      setPopOverOpen(true);
    }
    setIsHovered(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setPopOverOpen(false);
  };

  const handleChange = (item: ICoveredUserInbox) => {
    props?.setPrevUser(activeInbox);
    dispatch(coverageActions.setActiveInbox(item));
    if (item?.coveredUserId === user?.userInfo?.employeeId?.toString()) {
      savePinnedConversations(false);
    }
    handleClose();
  };

  useEffect(() => {
    if (!props.modalOpen && anchorEl) {
      setAnchorEl(null);
      setPopOverOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.modalOpen]);

  const getUnreadCountForPhoneNumber = (phoneNumber: string, arr: IUnreadSmsCount[]) => {
    return arr?.find(ele => ele?.phoneNumber == phoneNumber)?.count || 0;
  };

  const DropdownAvatar = ({ firstName, lastName, avatarColor, isCoveredUser, avatarClass, isHeader, id = null }) => {
    return (
      <div
        onMouseLeave={() => (isHeader ? setIsHovered(false) : setHoveredItem(null))}
        style={{ position: 'relative' }}
        data-testid="dropdown-avatar"
      >
        <Avatar
          sx={{
            width: 30,
            height: 30,
            backgroundColor: avatarColor,
            borderRadius: '50%',
            '&:hover': {
              width: 'fit-content',
              borderRadius: '16px',
              zIndex: 1,
              padding: '0px 5px',
            },
          }}
          className={avatarClass}
          onMouseOver={() => (isHeader ? setIsHovered(true) : setHoveredItem(id))}
        >
          <span className={classes.avatarText} id={'avatarText'}>{`${
            isCoveredUser ? 'o/b/o ' : ''
          }${firstName} ${lastName}`}</span>
          <span className={classes.avatarInitial} id={'avatarInitial'}>{`${firstName?.charAt(0) || ''}${
            lastName?.charAt(0) || ''
          }`}</span>
        </Avatar>
      </div>
    );
  };

  return (
    <>
      <Box
        className={classes.wrapper}
        onClick={e => !disabled && handleClick(e)}
        aria-owns={open ? 'popover' : undefined}
      >
        <Box className={classes.innerWrapper}>
          <DropdownAvatar
            firstName={activeInbox?.firstName}
            lastName={activeInbox?.lastName}
            avatarColor={activeInbox?.avatarColor || coverageAvatarColors[0]}
            isCoveredUser={
              activeInbox?.coveredUserEmail != user.userInfo?.sharedProfile?.email &&
              activeInbox?.coveredUserEmail != user.userInfo.email
            }
            avatarClass={classes.avatar}
            isHeader={true}
          />
          {!isHovered && (
            <Typography fontSize={14} margin={'0 5px'} className={classes.phone}>
              {formatPhoneNumber(activeInbox.phoneNumber)}
            </Typography>
          )}
        </Box>
        <IconButton
          color="primary"
          size="small"
          disabled={!checkCoverage || coveredInboxes.length < 2}
          className={classes.button}
        >
          {!popOverOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
        </IconButton>
      </Box>
      <Popover
        id="popover"
        open={open}
        anchorEl={anchorEl}
        sx={{
          pointerEvents: 'none',
        }}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        className={classes.popper}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 200, left: width }}
      >
        <Box id="coverageInbox" width={380} ref={ref} sx={{ pointerEvents: 'auto' }}>
          {coveredInboxes?.map((ele, index) => {
            const selected = activeInbox?.coveredUserId === ele?.coveredUserId;
            return (
              <MenuItem
                key={index}
                disableGutters
                onClick={() => handleChange(ele)}
                selected={selected}
                className={classes.menuItem}
              >
                <div style={{ position: 'absolute' }}>
                  <DropdownAvatar
                    id={ele.coveredUserId}
                    firstName={ele?.firstName}
                    lastName={ele?.lastName}
                    avatarColor={ele?.avatarColor || coverageAvatarColors[index % 5]}
                    isCoveredUser={
                      ele?.coveredUserEmail !== user.userInfo.email &&
                      ele?.coveredUserEmail !== user.userInfo.sharedProfile?.email
                    }
                    avatarClass={classes.listAvatar}
                    isHeader={false}
                  />
                </div>
                <p
                  className={classes.listPhone}
                  style={{ filter: hoveredItem !== ele?.coveredUserId ? 'none' : 'opacity(30%)' }}
                >
                  {formatPhoneNumber(ele?.phoneNumber)}
                </p>
                {getUnreadCountForPhoneNumber(ele?.phoneNumber, unreadCountArr) > 0 && (
                  <div
                    className={classes.count}
                    style={{ filter: hoveredItem !== ele?.coveredUserId ? 'none' : 'opacity(30%)' }}
                  >
                    {getUnreadCountForPhoneNumber(ele?.phoneNumber, unreadCountArr)}
                  </div>
                )}
                {selected && <CheckIcon className={classes.checkIcon} />}
              </MenuItem>
            );
          })}
        </Box>
      </Popover>
    </>
  );
};

export default SelectInbox;
