import { DatePicker, InputAdornment, TextField } from 'amn-ui-core';
import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormInputProps } from './FormInputProps';
import { ErrorMessage } from '@hookform/error-message';
import { colors } from 'styles/styleVariables';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';

export const FormInputDatePickerV2 = ({
  name,
  label,
  require,
  inputValue,
  message,
  readOnly,
  handleChange,
  customClass,
}: FormInputProps) => {
  const { control, errors } = useFormContext();
  const [open, setOpen] = useState<boolean>(false);
  const date = new Date();

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: require }}
      defaultValue={null}
      render={({ onChange, ...rest }) => (
        <>
          <DatePicker
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            label={label}
            disabled={readOnly}
            renderInput={props => (
              <TextField
                {...props}
                onClick={() => setOpen(true)}
                fullWidth
                label={label}
                variant="filled"
                size="small"
                placeholder={label}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CalendarTodayOutlinedIcon
                        id="cal-Icon-Id"
                        sx={{
                          '&:hover': {
                            color: colors.amnLightBlue,
                          },
                          color: '#009AD9',
                          visibility: open ? 'visible' : 'hidden',
                          height: '20px',
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            )}
            {...rest}
            onChange={e => {
              if (handleChange) handleChange(e);
              onchange;
            }}
          />
          <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => (
              <span style={{ color: '#f44336', fontSize: '0.75rem', marginLeft: '10px' }}>{message}</span>
            )}
          />
        </>
      )}
    />
  );
};
