import { ITypeAheadOption } from '@AMIEWEB/Candidate/CandidateProfile/CandidateTabPanel/PreferencesTab/CustomComponents/ControlledTypeAhead';

export function getOPObjectbyLabel(label, orderPriorities) {
  return orderPriorities.find(item => item?.object?.name === label)?.object;
}

export const transformDataForOrderPriorities = orderPriorities => {
  const result = orderPriorities?.map(item => {
    const option: ITypeAheadOption = {
      object: { value: item.id, name: item.description },
      label: item.id +'. '+ item.description,
    };
    return option;
  });
  return result;
};