/* eslint-disable @typescript-eslint/lines-between-class-members */
import { FilterCompTypes } from 'app/ComponentLibrary/Filter/utils';
import { ICascadeStateCityZipDDLOption, ICity, IStateCityZip, IZip } from 'app/models/Candidate/IStateCityZip';
import { getCitiesAndZipsByStates, getSearchLookups } from 'app/services/SharedServices/SharedServices';
import { addDays, addMonths, endOfDay, endOfMonth, startOfDay, startOfMonth } from 'date-fns';
import { getAmnEndOfWeek, getAmnStartOfWeek } from 'utils/dates/dateExtraction';
import { candidateSearchFilterName } from './SearchUtils';
import { getSortedData } from 'app/helpers/arrayHelpers';
import { FilterType } from 'app/enums/Common';
import { dropDownFilterOptions, getFacilitySearchLookups } from '../helper';
import { totalCountNodes } from 'app/components/Common/TreeView/TreeViewHelpers';

export const getAllCitiesAndZips = (data: IStateCityZip[]) => {
  const zips: IZip[] = [];
  const cities: ICity[] = [];
  const citiesAdded = new Map<string, boolean>();
  data?.forEach(element => {
    zips.push(...element.zips);
    for (let i = 0; i < element.cities.length; i++) {
      const city = element.cities[i];
      if (citiesAdded.has(city.name)) continue;
      cities.push(city);
      citiesAdded.set(city.name, true);
    }
  });

  const city = getSortedData(cities || [], FilterType.string) as ICity[];

  const cityOpts = !city.length
    ? []
    : city.map(x => ({
        id: parseInt(x.id),
        name: x.name,
        value: x.name,
      }));

  const zipOpts = getSortedData(zips || [], FilterType.numeric).map(x => ({
    id: parseInt(x.id),
    name: x.name,
    value: x.name,
  }));
  return {
    cities: cityOpts,
    zips: zipOpts,
  };
};
export const getFilteredCities = (cities: ICascadeStateCityZipDDLOption[], key: string) => {
  const city = getSortedData(cities, FilterType.string) as ICascadeStateCityZipDDLOption[];
  const filteredCities: ICascadeStateCityZipDDLOption[] = [];
  for (let i = 0; i < 100 && i < city.length; i++) {
    filteredCities.push(city[i]);
  }
  const cityOpts = !city.length
    ? []
    : filteredCities.map(x => ({
        id: x.id,
        name: x.name,
        value: x.name,
      }));

  return cityOpts;
};
export const getFilteredZips = (zips: ICascadeStateCityZipDDLOption[], key: string) => {
  const zip = getSortedData(zips, FilterType.numeric) as ICascadeStateCityZipDDLOption[];
  const filteredZips: ICascadeStateCityZipDDLOption[] = [];
  for (let i = 0; i < 100 && i < zip.length; i++) {
    filteredZips.push(zip[i]);
  }
  const zipOpts = !zip.length
    ? []
    : filteredZips.map(x => ({
        id: x.id,
        name: x.name,
        value: x.name,
      }));
  return removeDuplicateZips(zipOpts);
};

export const getTagsOpts = tagsData => {
  return tagsData.map(item => {
    return {
      id: item.id,
      name: item.name,
      value: item.value,
    };
  });
};

export const upgradeBasedOnSearchOption = async (
  key: string,
  searchMode: string,
  gridStateFilters: any,
): Promise<ICascadeStateCityZipDDLOption[]> => {
  const selectedStates = (
    !(gridStateFilters?.filters?.states as any[])?.length
      ? []
      : gridStateFilters?.filters?.states?.filter(item => item?.isCompactOpt !== true)
  ) as any[];

  const selectedCities = (
    !(gridStateFilters?.filters?.city as any[])?.length ? [] : gridStateFilters?.filters?.city
  ) as any[];
  let filteredZips: IStateCityZip[];
  let filteredCities: IStateCityZip[];
  if (searchMode === 'z') {
    filteredZips = (await getCitiesAndZipsByStates({
      states: selectedStates.map(x => x.name),
      cities: selectedCities.map(x => x.name),
      searchMode: searchMode,
      searchValue: key,
    })) as IStateCityZip[];
    return getAllCitiesAndZips(filteredZips).zips;
  } else {
    filteredCities = (await getCitiesAndZipsByStates({
      states: selectedStates.map(x => x.name),
      cities: [],
      searchMode: searchMode,
      searchValue: key,
    })) as IStateCityZip[];
    return getAllCitiesAndZips(filteredCities).cities;
  }
};

export const vaccinationStatusOpts = [
  {
    id: 0,
    value: 'U',
    name: 'Unvaccinated',
    labelPrefix: 'Vaccination Status',
  },
  {
    id: 1,
    value: 'D',
    name: 'Declined Vaccination',
    labelPrefix: 'Vaccination Status',
  },
  {
    id: 2,
    value: 'V',
    name: 'Vaccinated',
    labelPrefix: 'Vaccination Status',
  },
];
export const SupervisorReasonOptions = [
  {
    id: 0,
    value: 'Not a good fit',
    name: 'Not a Good Fit',
  },
  {
    id: 1,
    value: 'Not enough experience',
    name: 'Not Enough Experience',
  },
];
export const SupervisorSkillsetStatusOptions = [
  {
    id: 0,
    value: 'Approved',
    name: 'Approved',
  },
  {
    id: 1,
    value: 'Declined',
    name: 'Declined',
  },
];
export const PlacementBeenOnAssignmentOptions = [
  {
    id: 0,
    value: '1 Year or Greater',
    name: '1 Year or Greater',
  },
  {
    id: 1,
    value: 'Less than 1 Year',
    name: 'Less than 1 Year',
  },
  {
    id: 2,
    value: '43 Days Ago or Greater',
    name: '43 Days Ago or Greater',
  },
  {
    id: 3,
    value: '42 Days Ago or Less',
    name: '42 Days Ago or Less',
  },
  {
    id: 4,
    value: 'Never',
    name: 'Never',
  },
];

export const virtualInterviewOpts = [
  { id: 0, value: true, name: 'Yes', labelPrefix: 'Virtual Interview' },
  { id: 1, value: false, name: 'No', labelPrefix: 'Virtual Interview' },
];

export const SupervisorOptions = [
  { id: 0, value: true, name: 'Yes' },
  { id: 1, value: false, name: 'No' },
];

export const getStatesArray = arg => {
  const array: string[] = [];
  arg?.forEach(element => {
    array.push(element.name);
  });
  return array;
};

export const removeDuplicateZips = zips => {
  const uniqueZips = new Set();
  return zips.filter(item => {
    if (uniqueZips.has(item.name)) {
      return false;
    } else {
      uniqueZips.add(item.name);
      return true;
    }
  });
};

export async function getFilteredOptions(options, keyword) {
  let result = [];
  if (keyword) {
    const filteredData = options.filter(
      opt =>
        opt.value.toLowerCase() === keyword.toLowerCase() ||
        opt.name.toLowerCase().includes(keyword.toLowerCase()) ||
        opt.id === 0,
    );
    if (filteredData?.length >= 1) {
      result = filteredData;
    }
  } else {
    result = options;
  }
  return result?.map(opt => ({ ...opt }));
}

export class CandidatesFilter {
  constructor(
    public filterSpecs: any,
    public t: any,
    public styleClasses: any,
    public gridStateFilters: any,
    public newSearchIndexEnabled: boolean,
  ) {}
  getCandidateFilterSpecs = () => {
    const date = new Date();
    return [
      {
        filterName: this.t('search.globalSearch.candidate.filterNames.recruiter'),
        name: candidateSearchFilterName.recruiter,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        applyOnClickAway: true,
        isMultiSelect: true,
        fetchOptions: async key => await getSearchLookups('recruiters', key),
        isSelectAll: true,
        applyOnEnter: true,
      },
      {
        filterName: this.t('search.globalSearch.candidate.filterNames.candidate'),
        name: candidateSearchFilterName.candidateAgggreggate,
        type: FilterCompTypes.CANDIDATESEARCH,
        applyOnClickAway: true,
        isMultiSelect: true,
      },
      {
        filterName: this.t('search.globalSearch.candidate.filterNames.brand'),
        name: candidateSearchFilterName.brand,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        applyOnClickAway: true,
        isMultiSelect: true,
        fetchOptions: async key => await getFilteredOptions(this.filterSpecs.brandOpts, key),
        isSelectAll: true,
        applyOnEnter: true,
      },
      {
        filterName: this.t('search.globalSearch.candidate.filterNames.candidStatus'),
        name: candidateSearchFilterName.candidateStatus,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        applyOnClickAway: true,
        isMultiSelect: true,
        fetchOptions: async key => await getFilteredOptions(this.filterSpecs.candidateStatusOpts, key),
        isSelectAll: true,
        applyOnEnter: true,
      },
      {
        filterName: this.t('search.globalSearch.candidate.filterNames.skillSet'),
        name: candidateSearchFilterName.candidateSkillSet,
        type: FilterCompTypes.CANDIDATESKILLSET,
        isMultiSelect: true,
        options: [],
        placeholder: 'Select',
        selectAllValue: 'All',
        version2: true,
        returnsObjectAsValue: true,
        size: 'large',
        customSelectedCount: value => totalCountNodes(value),
      },

      {
        filterName: this.t('search.globalSearch.candidate.filterNames.tags'),
        name: candidateSearchFilterName.candidateTags,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        applyOnClickAway: true,
        isMultiSelect: true,
        fetchOptions: async key => await getFilteredOptions(this.filterSpecs.tagsOpts, key),
        isSelectAll: true,
        applyOnEnter: true,
      },
      {
        filterName: this.t('search.globalSearch.candidate.filterNames.availableToStart'),
        name: candidateSearchFilterName.availableToStart,
        type: FilterCompTypes.DATERANGEV2,
        variant: 'outlined',
        secondaryLabel: this.t('search.globalSearch.candidate.filterNames.availableToStart'),
        useMaxWidth: true,
        maxWidth: 210,
        clickAway: false,
        applyOnClickAway: true,
        applyOnEnter: true,
        keepClickAwayVal: false,
        disablePortal: true,
        definedRanges: [
          {
            label: 'This Week',
            startDate: startOfDay(getAmnStartOfWeek()),
            endDate: endOfDay(getAmnEndOfWeek()),
            useLabelAsValue: false,
          },
          {
            label: 'This Month',
            startDate: startOfMonth(date),
            endDate: endOfMonth(date),
            useLabelAsValue: false,
          },
          {
            label: 'Next Month',
            startDate: startOfMonth(addMonths(date, 1)),
            endDate: endOfMonth(addMonths(date, 1)),
            useLabelAsValue: false,
          },
        ],
      },
      {
        filterName: this.t('search.globalSearch.candidate.filterNames.lastApplicationDate'),
        name: candidateSearchFilterName.lastApplicationDate,
        type: FilterCompTypes.DATERANGEV2,
        variant: 'outlined',
        secondaryLabel: this.t('search.globalSearch.candidate.filterNames.lastApplicationDate'),
        useMaxWidth: true,
        applyOnClickAway: true,
        maxWidth: 210,
        clickAway: false,
        disablePortal: true,
        definedRanges: [
          {
            label: 'Today',
            startDate: startOfDay(date),
            endDate: endOfDay(date),
            useLabelAsValue: false,
          },
          {
            label: 'This Week',
            startDate: startOfDay(getAmnStartOfWeek()),
            endDate: endOfDay(getAmnEndOfWeek()),
            useLabelAsValue: false,
          },
          {
            label: 'Last 30 Days',
            startDate: startOfDay(addDays(date, -30)),
            endDate: endOfDay(date),
            useLabelAsValue: false,
          },
          {
            label: 'Last 60 Days',
            startDate: startOfDay(addDays(date, -60)),
            endDate: endOfDay(date),
            useLabelAsValue: false,
          },
        ],
      },
      {
        filterName: this.t('search.globalSearch.candidate.filterNames.nonContacted'),
        name: candidateSearchFilterName.nonContacted,
        type: FilterCompTypes.DATERANGEV2,
        variant: 'outlined',
        secondaryLabel: this.t('search.globalSearch.candidate.filterNames.nonContacted'),
        useMaxWidth: true,
        single: true,
        maxWidth: 140,
        clickAway: false,
        applyOnClickAway: true,
        disablePortal: true,
        definedRanges: [
          {
            label: '1 Day',
            startDate: startOfDay(addDays(date, -1)),
            endDate: endOfDay(addDays(date, -1)),
            useLabelAsValue: false,
          },
          {
            label: '2 Days',
            startDate: startOfDay(addDays(date, -2)),
            endDate: endOfDay(addDays(date, -2)),
            useLabelAsValue: false,
          },
          {
            label: '7 Days',
            startDate: startOfDay(addDays(date, -7)),
            endDate: endOfDay(addDays(date, -7)),
            useLabelAsValue: false,
          },
          {
            label: '20 Days',
            startDate: startOfDay(addDays(date, -20)),
            endDate: endOfDay(addDays(date, -20)),
            useLabelAsValue: false,
          },
          {
            label: '30 Days',
            startDate: startOfDay(addDays(date, -30)),
            endDate: endOfDay(addDays(date, -30)),
            useLabelAsValue: false,
          },
          {
            label: '60 Days',
            startDate: startOfDay(addDays(date, -60)),
            endDate: endOfDay(addDays(date, -60)),
            useLabelAsValue: false,
          },
        ],
      },
      {
        filterName: this.t('search.globalSearch.candidate.filterNames.callBackDate'),
        name: candidateSearchFilterName.callBackDate,
        type: FilterCompTypes.DATERANGEV2,
        variant: 'outlined',
        secondaryLabel: this.t('search.globalSearch.candidate.filterNames.callBackDate'),
        useMaxWidth: true,
        maxWidth: 210,
        clickAway: false,
        applyOnClickAway: true,
        disablePortal: true,
        definedRanges: [
          {
            label: 'Today',
            startDate: startOfDay(date),
            endDate: endOfDay(date),
            useLabelAsValue: false,
          },
          {
            label: 'This Week',
            startDate: startOfDay(getAmnStartOfWeek()),
            endDate: endOfDay(getAmnEndOfWeek()),
            useLabelAsValue: false,
          },
          {
            label: 'Last 30 Days',
            startDate: startOfDay(addDays(date, -30)),
            endDate: endOfDay(date),
            useLabelAsValue: false,
          },
          {
            label: 'Last 60 Days',
            startDate: startOfDay(addDays(date, -60)),
            endDate: endOfDay(date),
            useLabelAsValue: false,
          },
        ],
      },
      {
        filterName: this.t('search.globalSearch.candidate.filterNames.lastPlacementEndDate'),
        name: candidateSearchFilterName.lastPlacementEndDate,
        type: FilterCompTypes.DATERANGEV2,
        variant: 'outlined',
        secondaryLabel: this.t('search.globalSearch.candidate.filterNames.lastPlacementEndDate'),
        useMaxWidth: true,
        maxWidth: 210,
        clickAway: false,
        applyOnClickAway: true,
        disablePortal: true,
        definedRanges: [
          {
            label: 'Today',
            startDate: startOfDay(date),
            endDate: endOfDay(date),
            useLabelAsValue: false,
          },
          {
            label: 'This Week',
            startDate: startOfDay(getAmnStartOfWeek()),
            endDate: endOfDay(getAmnEndOfWeek()),
            useLabelAsValue: false,
          },
          {
            label: 'Last 30 Days',
            startDate: startOfDay(addDays(date, -30)),
            endDate: endOfDay(date),
            useLabelAsValue: false,
          },
          {
            label: 'Last 60 Days',
            startDate: startOfDay(addDays(date, -60)),
            endDate: endOfDay(date),
            useLabelAsValue: false,
          },
        ],
      },
      {
        filterName: this.t('search.globalSearch.candidate.filterNames.state'),
        name: candidateSearchFilterName.state,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        isMultiSelect: true,
        isSelectAll: true,
        applyOnClickAway: true,
        fetchOptions: async key => await getFilteredOptions(this.filterSpecs.statesOpts, key),
        applyOnEnter: true,
      },
      {
        filterName: this.t('search.globalSearch.candidate.filterNames.city'),
        name: candidateSearchFilterName.city,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        fetchOptions: async (key: string) => {
          if (!key) return getFilteredCities(this.filterSpecs.cityOptions, key);
          const cityOptions = await upgradeBasedOnSearchOption(key, 'c', this.gridStateFilters);
          return cityOptions;
        },
        applyOnClickAway: true,
        isMultiSelect: true,
        applyOnEnter: true,
        isSelectAll: true,
      },
      {
        filterName: this.t('search.globalSearch.candidate.filterNames.zip'),
        name: candidateSearchFilterName.zip,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        fetchOptions: async (key: string) => {
          if (!key) return getFilteredZips(this.filterSpecs.zipOptions, key);
          const zipOptions = await upgradeBasedOnSearchOption(key, 'z', this.gridStateFilters);
          const uniqueZips = removeDuplicateZips(zipOptions); // DO WE NEED TO REMOVE DUPLICATES?
          return uniqueZips;
        },
        applyOnClickAway: true,
        isMultiSelect: false,
        applyOnEnter: true,
        hideClearAll: true,
      },
      {
        filterName: this.t('search.globalSearch.candidate.filterNames.radius'),
        name: candidateSearchFilterName.radius,
        type: FilterCompTypes.TEXTFIELDNUMBER,
        applyOnClickAway: true,
        placeholder: 'Radius',
        disabled: this.gridStateFilters?.filters?.zipCode ? false : true,
      },
      {
        filterName: this.t('search.globalSearch.candidate.filterNames.virtualInterview'),
        name: candidateSearchFilterName.virtualInterview,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        applyOnClickAway: true,
        options: virtualInterviewOpts,
        applyOnEnter: true,
        hideClearAll: true,
      },
      {
        filterName: this.t('search.globalSearch.candidate.filterNames.covidVaxStatus'),
        name: candidateSearchFilterName.covidVaxStatus,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        options: vaccinationStatusOpts,
        applyOnClickAway: true,
        isMultiSelect: true,
        isSelectAll: true,
        applyOnEnter: true,
      },
      {
        filterName: 'Licenses',
        name: candidateSearchFilterName.license,
        nestedlabelPrefix: 'Lic',
        type: FilterCompTypes.NESTEDFILTER,
        hasDefaultValue: true,
        applyOnClickAway: true,
        applyOnEnter: true,
        manipulateDefaultValue: (currentFilterName, setValue, nestedFieldValue, selectedCheckBox = 'Or') => {
          const nestedFieldArray =
            nestedFieldValue?.value && nestedFieldValue?.value?.length > 0 ? [...nestedFieldValue.value] : [];
          const nestedFieldArrayLength = nestedFieldArray?.length ?? 0;
          nestedFieldArray[nestedFieldArrayLength] = {
            compactStatus: {
              id: 0,
              value: false,
              name: 'Single State',
              position: 3,
              useLabelName: false,
            },
          };
          const newNestedFieldValue = {
            ...nestedFieldValue,
            type: 'NestedFilter',
            nestedlabelPrefix: 'Lic',
            value: nestedFieldArray,
            operation: selectedCheckBox,
          };
          setValue(currentFilterName, newNestedFieldValue);
        },
        filters: [
          {
            filterName: 'License Type',
            name: candidateSearchFilterName.licenseType,
            type: FilterCompTypes.TYPEAHEADDROPDOWN,
            options: this.filterSpecs.licensesOptions,
            isMultiSelect: false,
            position: 1,
            isRequired: true,
            filterOptions: (options, { inputValue }) => {
              return dropDownFilterOptions(options, inputValue);
            },
          },
          {
            filterName: 'State',
            name: candidateSearchFilterName.licenseState,
            type: FilterCompTypes.TYPEAHEADDROPDOWN,
            options: this.filterSpecs.statesOpts
              ? this.filterSpecs?.statesOpts?.filter(x => x.value !== 'All') ?? []
              : [],
            isMultiSelect: false,
            position: 2,
            filterOptions: (options, { inputValue }) => {
              return dropDownFilterOptions(options, inputValue);
            },
          },
          {
            filterName: 'Compact Status',
            name: candidateSearchFilterName.licenseCompactStatus,
            type: FilterCompTypes.TYPEAHEADDROPDOWN,
            options: [
              { name: 'Single State', value: false },
              { name: 'Multi State', value: true },
            ].map((x, id) => ({ id: id, value: x.value, name: x.name })),
            isMultiSelect: false,
            position: 3,
          },
          {
            filterName: 'Expiration Date',
            name: candidateSearchFilterName.licenseExp,
            type: FilterCompTypes.DATERANGEV2,
            labelPrefixV1: 'Ex',
            variant: 'outlined',
            secondaryLabel: 'Expiration Date',
            useMaxWidth: true,
            single: true,
            maxWidth: 140,
            clickAway: true,
            disablePortal: true,
            position: 4,
            definedRanges: [
              {
                label: '1 Day',
                startDate: startOfDay(addDays(date, -1)),
                endDate: endOfDay(addDays(date, -1)),
                useLabelAsValue: false,
              },
              {
                label: '2 Days',
                startDate: startOfDay(addDays(date, -2)),
                endDate: endOfDay(addDays(date, -2)),
                useLabelAsValue: false,
              },
              {
                label: '7 Days',
                startDate: startOfDay(addDays(date, -7)),
                endDate: endOfDay(addDays(date, -7)),
                useLabelAsValue: false,
              },
              {
                label: '20 Days',
                startDate: startOfDay(addDays(date, -20)),
                endDate: endOfDay(addDays(date, -20)),
                useLabelAsValue: false,
              },
              {
                label: '30 Days',
                startDate: startOfDay(addDays(date, -30)),
                endDate: endOfDay(addDays(date, -30)),
                useLabelAsValue: false,
              },
              {
                label: '60 Days',
                startDate: startOfDay(addDays(date, -60)),
                endDate: endOfDay(addDays(date, -60)),
                useLabelAsValue: false,
              },
            ],
          },
        ],
      },
      {
        filterName: 'Certifications',
        name: candidateSearchFilterName.certification,
        nestedlabelPrefix: 'Cert',
        type: FilterCompTypes.NESTEDFILTER,
        applyOnClickAway: true,
        applyOnEnter: true,
        filters: [
          {
            filterName: 'Certification',
            name: candidateSearchFilterName.certificationType,
            type: FilterCompTypes.TYPEAHEADDROPDOWN,
            options: this.filterSpecs.certificationOptions,
            isMultiSelect: false,
            position: 1,
            isRequired: true,
            filterOptions: (options, { inputValue }) => {
              return dropDownFilterOptions(options, inputValue);
            },
          },
          {
            filterName: 'Issued By',
            name: candidateSearchFilterName.certificationIssuedBy,
            type: FilterCompTypes.TYPEAHEADDROPDOWN,
            options: this.filterSpecs.certificationIssuers ? this.filterSpecs.certificationIssuers : [],
            isMultiSelect: false,
            position: 2,
          },
          {
            filterName: 'Expiration Date',
            name: candidateSearchFilterName.certificationExpDate,
            type: FilterCompTypes.DATERANGEV2,
            variant: 'outlined',
            secondaryLabel: 'Expiration Date',
            labelPrefixV1: 'Ex',
            useMaxWidth: true,
            single: true,
            maxWidth: 140,
            position: 3,
            clickAway: true,
            disablePortal: true,
            definedRanges: [
              {
                label: '1 Day',
                startDate: addDays(date, -1),
                endDate: addDays(date, -1),
                useLabelAsValue: false,
              },
              {
                label: '2 Days',
                startDate: addDays(date, -2),
                endDate: addDays(date, -2),
                useLabelAsValue: false,
              },
              {
                label: '7 Days',
                startDate: addDays(date, -7),
                endDate: addDays(date, -7),
                useLabelAsValue: false,
              },
              {
                label: '20 Days',
                startDate: addDays(date, -20),
                endDate: addDays(date, -20),
                useLabelAsValue: false,
              },
              {
                label: '30 Days',
                startDate: addDays(date, -30),
                endDate: addDays(date, -30),
                useLabelAsValue: false,
              },
              {
                label: '60 Days',
                startDate: addDays(date, -60),
                endDate: addDays(date, -60),
                useLabelAsValue: false,
              },
            ],
          },
        ],
      },
      {
        filterName: 'Work History',
        name: candidateSearchFilterName.workHistory,
        nestedlabelPrefix: 'Exp',
        type: FilterCompTypes.NESTEDFILTER,
        applyOnClickAway: true,
        applyOnEnter: true,
        filters: [
          {
            filterName: 'Years of Experience',
            name: candidateSearchFilterName.workYearOfExp,
            type: FilterCompTypes.TYPEAHEADDROPDOWN,
            options: [
              { id: 0, name: '0 to 2', value: { min: 0, max: 2 } },
              { id: 1, name: '3 to 5', value: { min: 3, max: 5 } },
              { id: 2, name: '6 to 10', value: { min: 6, max: 10 } },
              { id: 3, name: '10 to 12', value: { min: 10, max: 12 } },
              { id: 4, name: '12+', value: { min: 12, max: null } },
            ],
            isMultiSelect: false,
            position: 1,
            //isRequired: true
          },
          {
            filterName: 'State',
            name: candidateSearchFilterName.workHistoryState,
            type: FilterCompTypes.TYPEAHEADDROPDOWN,
            options: this.filterSpecs.statesOpts
              ? this.filterSpecs?.statesOpts?.filter(x => x.value !== 'All') ?? []
              : [],
            isMultiSelect: false,
            position: 2,
          },
          {
            filterName: 'Facility',
            name: candidateSearchFilterName.workHistoryFacility,
            type: FilterCompTypes.TYPEAHEADDROPDOWN,
            fetchOptions: getFacilitySearchLookups,
            isMultiSelect: false,
            position: 3,
            //isRequired: true
          },
          {
            filterName: 'Discipline',
            name: candidateSearchFilterName.workHistoryDiscipline,
            type: FilterCompTypes.TYPEAHEADDROPDOWN,
            options: this.filterSpecs?.disciplineOpts?.filter(x => x.value !== 'All') ?? [],
            isMultiSelect: false,
            position: 4,
          },
          {
            filterName: 'Unit Specialty',
            name: candidateSearchFilterName.workHistoryUnitSpecialty,
            type: FilterCompTypes.TYPEAHEADDROPDOWN,
            options: this.filterSpecs?.specialtyOpts?.filter(x => x.value !== 'All') ?? [],
            isMultiSelect: false,
            position: 5,
          },
          {
            filterName: 'Travel Experience',
            name: candidateSearchFilterName.workHistoryTravelExp,
            type: FilterCompTypes.TYPEAHEADDROPDOWN,
            options: ['AMN', 'Other', 'None'].map((x, id) => ({ id: id, value: x, name: x })),
            isMultiSelect: false,
            position: 6,
            //isRequired: true
          },
        ],
      },
      {
        filterName: 'Active Placements',
        name: candidateSearchFilterName.activePlacements,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        hiddenInput: false,
        applyOnClickAway: true,
        applyOnEnter: true,
        options: [
          {
            id: 0,
            name: 'Exclude',
            value: {
              include: false,
            },
          },
        ],
        forceCheckboxOptIcon: true,
        hideClearAll: true,
        searchBoxHiddenWithSingleOption: false,
      },
      ...(this.newSearchIndexEnabled
        ? [
            {
              filterName: this.t('search.globalSearch.candidate.filterNames.placementBeenOnAssignment'),
              name: candidateSearchFilterName.placementBeenOnAssignment,
              type: FilterCompTypes.TYPEAHEADDROPDOWN,
              options: PlacementBeenOnAssignmentOptions,
              applyOnClickAway: true,
              hideClearAll: true,
              applyOnEnter: true,
            },
            {
              filterName: this.t('search.globalSearch.candidate.filterNames.supervisor'),
              name: candidateSearchFilterName.supervisor,
              type: FilterCompTypes.TYPEAHEADDROPDOWN,
              options: SupervisorOptions,
              applyOnClickAway: true,
              hideClearAll: true,
              applyOnEnter: true,
            },
            {
              filterName: this.t('search.globalSearch.candidate.filterNames.supervisorSkillsetStatus'),
              name: candidateSearchFilterName.supervisorSkillsetStatus,
              type: FilterCompTypes.TYPEAHEADDROPDOWN,
              options: SupervisorSkillsetStatusOptions,
              applyOnClickAway: true,
              hideClearAll: true,
              applyOnEnter: true,
            },
            {
              filterName: this.t('search.globalSearch.candidate.filterNames.supervisorSkillsetReason'),
              name: candidateSearchFilterName.supervisorSkillsetRejectionReason,
              type: FilterCompTypes.TYPEAHEADDROPDOWN,
              options: SupervisorReasonOptions,
              applyOnClickAway: true,
              hideClearAll: true,
              applyOnEnter: true,
            },
          ]
        : []),
      {
        filterName: this.t('search.globalSearch.candidate.filterNames.recruitmentLeaders'),
        name: candidateSearchFilterName.recruitmentLeaderIds,
        type: FilterCompTypes.TYPEAHEADDROPDOWN,
        applyOnClickAway: true,
        isMultiSelect: true,
        fetchOptions: async key => await getSearchLookups('recruitmentleaders', key),
        isSelectAll: true,
        applyOnEnter: true,
      },
    ];
  };
}
