import { ContactItem } from '@AMIEWEB/Common';
import { InlineDropdown } from '@AMIEWEB/Common/EditPage/InlineDropdown';
import { IDisplayInternalContact, IUpdateInternalContactCommand } from '@AMIEWEB/Facility/FacilityStore/Facility.model';
import { facilityActions } from '@AMIEWEB/Facility/FacilityStore/Facility.redux';
import {
  selectEditFacilityInternalContacts,
  selectFacility,
  selectFacilityInternalContacts,
} from '@AMIEWEB/Facility/FacilityStore/Facility.selector';
import { getInitials } from '@AMIEWEB/Placement/PlacementDetails/PlacementDetailsHeader/utils';
import { Divider, List } from 'amn-ui-core';
import { missingField } from 'app/constants';
import { getSearchLookups } from 'app/services/SharedServices/SharedServices';
import { selectUser } from 'oidc/user.selectors';
import React from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { InternalContactSaveModal } from './InternalContactSaveModal';

const useStyles = makeStyles()(theme => ({
  root: {
    padding: '12px 24px 12px 24px',
    background: `${theme.palette.framework.system.white}`,
  },
  disabled: {
    WebkitTextFillColor: `${theme.palette.framework.system.charcoal} !important`,
  },
  smallTextfield: {
    paddingTop: '0 !important',
    '&.Mui-disabled': {
      '&:before': {
        borderBottom: `1px solid rgba(0, 0, 0, 0.42) !important`,
      },
      '&:hover, &:after': {
        borderBottom: `1px solid ${theme.palette.system.fadedGrey} !important`,
      },
      '&:hover:before': {
        borderBottom: 'none !important',
      },
    },
  },
  option: {
    '&.MuiAutocomplete-option[data-option-index="0"]': {
      fontStyle: 'italic',
    },
  },
}));

interface ISaveInternalContacts {
  id: number;
  key: string;
  name: string;
  value: string;
  employeeRole: string;
}

enum KeyContacts {
  AccountManager = 'accountManager',
  RegionalDirector = 'regionalDirector',
  HousingManager = 'housingAccountExecutive',
  BillingSpecialist = 'billingSpecialist',
  ClientAccountRepresentative = 'clientAcctgRep',
  TimeProcessingSpecialist = 'timeProcessingSpecialist',
  PayrollSpecialist = 'payrollSpecialist',
  PayrollRegion = 'payrollRegion',
  ClientContractAdmin = 'clientContractAdmin',
  ClientContractSpecialist = 'clientContractSpecialist',
  QSAnalyst = 'credentialingAnalyst',
  ClinicalLiaison = 'clinicalDirector',
  HousingAccountingSpecialist = 'credentialingSpecialist',
  AccountCoordinator = 'accountCoordinator',
  CustomerSupportSpecialist = 'customerSupportSpecialist',
}

/**
 * Component to render Internal contacts for view/edit
 */
export const InternalContacts = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const userDetails = useSelector(selectUser);
  const facilityData = useSelector(selectFacility);

  const {
    accountCoordinator,
    accountManager,
    billingSpecialist,
    clientAcctgRep,
    clientContractAdmin,
    clientContractSpecialist,
    clinicalDirector,
    credentialingAnalyst,
    credentialingSpecialist,
    customerSupportSpecialist,
    housingAccountExecutive,
    payrollRegion,
    payrollSpecialist,
    regionalDirector,
    timeProcessingSpecialist,
  } = useSelector(selectFacilityInternalContacts);

  const {
    accountManager: editAccountManager,
    accountCoordinator: editAccountCoordinator,
    clientContractSpecialist: editClientContractSpecialist,
    clinicalDirector: editClinicalDirector,
    credentialingAnalyst: editCredentialingAnalyst,
    credentialingSpecialist: editCredentialingSpecialist,
    customerSupportSpecialist: editCustomerSupportSpecialist,
    housingAccountExecutive: editHousingAccountExecutive,
    payrollRegion: editPayrollRegion,
    payrollSpecialist: editPayrollSpecialist,
    regionalDirector: editRegionalDirector,
    timeProcessingSpecialist: editTimeProcessingSpecialist,
  } = useSelector(selectEditFacilityInternalContacts);

  /**
   * Method to prevent lookup search call if last character is special character
   * @param searchKey
   */
  const ignoreLookupSearchForSpecialCharacter = (searchKey: string) => {
    const lastCharacter = searchKey.slice(-1);
    const specialCharacterPattern = /[!@#$%^&*()_+[\]{};:'"\\|,.<>/?]/;
    return specialCharacterPattern.test(lastCharacter);
  };

  /**
   * Method which trigger on searching in type ahead dropdown
   * @param field
   * @param key
   */
  const fetchOptions = async (field: string, key: string) => {
    if (ignoreLookupSearchForSpecialCharacter(key)) return;
    const options = await getSearchLookups(field, key);
    if (field != 'amieham')
      options.unshift({
        id: null,
        name: 'None',
      });
    return options;
  };

  /**
   * Method to trigger on change of the type ahead
   * @param key
   * @param value
   */
  const onChange = (key: string, value: { id: string; name: string; value: string }) => {
    value.name = value.name != 'None' ? value.name : '';
    dispatch(
      facilityActions.editInternalContact({
        key,
        data: {
          value,
          error: null,
          isDirty: true,
        },
      }),
    );
  };

  /**
   * Method to handle save on clicking check icon
   * @param data
   */
  const handleSaveContact = (data: ISaveInternalContacts) => {
    let key = '';
    let keyContact = 0;
    var userName = data.name.split(' ');

    const firstName = userName[0];
    const lastName = userName[1] ? userName[1] : '';
    const keyValue = data.key === undefined ? data.employeeRole : data.key;

    switch (keyValue) {
      case KeyContacts.AccountManager:
        key = 'Account Manager';
        keyContact = 1;
        break;
      case KeyContacts.RegionalDirector:
        key = 'Regional Director';
        keyContact = 2;
        break;
      case KeyContacts.HousingManager:
        key = 'Housing Account Executive';
        keyContact = 3;
        break;
      case KeyContacts.BillingSpecialist:
        key = 'Billing Specialist';
        keyContact = 4;
        break;
      case KeyContacts.ClientAccountRepresentative:
        key = 'Client Acctg. Rep';
        keyContact = 5;
        break;
      case KeyContacts.TimeProcessingSpecialist:
        key = 'Time Processing Specialist';
        keyContact = 6;
        break;
      case KeyContacts.PayrollSpecialist:
        key = 'Payroll Specialist';
        keyContact = 7;
        break;
      case KeyContacts.PayrollRegion:
        key = 'Payroll Region';
        keyContact = 8;
        break;
      case KeyContacts.ClientContractAdmin:
        key = 'Client Contract Admin';
        keyContact = 9;
        break;
      case KeyContacts.ClientContractSpecialist:
        key = 'Client Contract Specialist';
        keyContact = 10;
        break;
      case KeyContacts.QSAnalyst:
        key = 'Credentialing Analyst';
        keyContact = 11;
        break;
      case KeyContacts.ClinicalLiaison:
        key = 'Clinical Director';
        keyContact = 12;
        break;
      case KeyContacts.HousingAccountingSpecialist:
        key = 'Credentialing Specialist';
        keyContact = 13;
        break;
      case KeyContacts.AccountCoordinator:
        key = 'Account Coordinator';
        keyContact = 14;
        break;
      case KeyContacts.CustomerSupportSpecialist:
        key = 'Customer Support Specialist';
        keyContact = 15;
        break;
      default:
        break;
    }

    const updatedInternalContactDetail: IUpdateInternalContactCommand = {
      facilityId: facilityData.facilityResponse.facilityId,
      key: key,
      keyContact: keyContact,
      userID: data.id,
      userRoleName: data.name,
      isParentUpdateRequired: false,
      currentEmployeeId: userDetails.userInfo.employeeId,
      currentEmployeeName: userDetails.userInfo?.firstName + ' ' + userDetails.userInfo?.lastName,
    };

    const displayInternalContact: IDisplayInternalContact = {
      id: data.id,
      employeeRole: keyValue,
      designation: key,
      avatarText:
        keyValue === KeyContacts.PayrollRegion
          ? firstName
            ? getInitials('Payroll', 'Region')
            : missingField
          : firstName && lastName
          ? getInitials(firstName, lastName).toUpperCase()
          : missingField,
      title: firstName + ' ' + lastName,
      hideMailboxIcon: true,
      hideMessageIcon: true,
      hidePhoneIcon: true,
      disableAvatar: false,
      disabled: false,
    };

    if (keyValue === KeyContacts.AccountManager || keyValue === KeyContacts.RegionalDirector) {
      dispatch(
        facilityActions.setSaveModalState({
          open: true,
          data: { displayInternalContact, updatedInternalContactDetail },
        }),
      );
    } else {
      dispatch(
        facilityActions.requestUpdateInternalContact({
          updateInternalContact: updatedInternalContactDetail,
          setInternalContact: displayInternalContact,
        }),
      );
    }
  };

  /**
   * Method to reset facility role value on clicking cancel
   * @param facilityRole
   * @returns
   */
  const resetInternalContactValue = (facilityRole: string) => {
    switch (facilityRole) {
      case 'accountCoordinator':
        return accountCoordinator;
      case 'accountManager':
        return accountManager;
      case 'clientContractSpecialist':
        return clientContractSpecialist;
      case 'clinicalDirector':
        return clinicalDirector;
      case 'credentialingAnalyst':
        return credentialingAnalyst;
      case 'credentialingSpecialist':
        return credentialingSpecialist;
      case 'customerSupportSpecialist':
        return customerSupportSpecialist;
      case 'housingAccountExecutive':
        return housingAccountExecutive;
      case 'payrollRegion':
        return payrollRegion;
      case 'payrollSpecialist':
        return payrollSpecialist;
      case 'regionalDirector':
        return regionalDirector;
      case 'timeProcessingSpecialist':
        return timeProcessingSpecialist;
    }
  };

  /**
   * Method to trigger on clicking close icon
   * @param key
   */
  const onCancel = (key: string) => {
    const field = resetInternalContactValue(key);
    dispatch(
      facilityActions.editInternalContact({
        key,
        data: {
          value: field.id ? { name: field.title, value: field.id, id: field.id } : null,
          error: null,
          isDirty: false,
        },
      }),
    );
  };

  const _accountManager = React.useMemo(
    () => (
      <ContactEditor
        {...accountManager}
        {...editAccountManager}
        applyNoneOptionStyle={false}
        fetchOptions={key => fetchOptions('amieham', key)}
        onApply={handleSaveContact}
        onChange={newValue => onChange('accountManager', newValue)}
        onCancel={() => onCancel('accountManager')}
      />
    ),
    [accountManager, editAccountManager],
  );

  const _accountCoordinator = React.useMemo(
    () => (
      <ContactEditor
        {...accountCoordinator}
        {...editAccountCoordinator}
        fetchOptions={key => fetchOptions('amieac', key)}
        onApply={handleSaveContact}
        onChange={newValue => onChange('accountCoordinator', newValue)}
        onCancel={() => onCancel('accountCoordinator')}
      />
    ),
    [accountCoordinator, editAccountCoordinator],
  );

  const _clientContractSpecialist = React.useMemo(
    () => (
      <ContactEditor
        {...clientContractSpecialist}
        {...editClientContractSpecialist}
        fetchOptions={key => fetchOptions('amieccs', key)}
        onApply={handleSaveContact}
        onChange={newValue => onChange('clientContractSpecialist', newValue)}
        onCancel={() => onCancel('clientContractSpecialist')}
      />
    ),
    [clientContractSpecialist, editClientContractSpecialist],
  );

  const _clinicalDirector = React.useMemo(
    () => (
      <ContactEditor
        {...clinicalDirector}
        {...editClinicalDirector}
        fetchOptions={key => fetchOptions('amiecl', key)}
        onApply={handleSaveContact}
        onChange={newValue => onChange('clinicalDirector', newValue)}
        onCancel={() => onCancel('clinicalDirector')}
      />
    ),
    [clinicalDirector, editClinicalDirector],
  );

  const _credentialingAnalyst = React.useMemo(
    () => (
      <ContactEditor
        {...credentialingAnalyst}
        {...editCredentialingAnalyst}
        fetchOptions={key => fetchOptions('amieqsa', key)}
        onApply={handleSaveContact}
        onChange={newValue => onChange('credentialingAnalyst', newValue)}
        onCancel={() => onCancel('credentialingAnalyst')}
      />
    ),
    [credentialingAnalyst, editCredentialingAnalyst],
  );

  const _credentialingSpecialist = React.useMemo(
    () => (
      <ContactEditor
        {...credentialingSpecialist}
        {...editCredentialingSpecialist}
        fetchOptions={key => fetchOptions('amiehas', key)}
        onApply={handleSaveContact}
        onChange={newValue => onChange('credentialingSpecialist', newValue)}
        onCancel={() => onCancel('credentialingSpecialist')}
      />
    ),
    [credentialingSpecialist, editCredentialingSpecialist],
  );

  const _customerSupportSpecialist = React.useMemo(
    () => (
      <ContactEditor
        {...customerSupportSpecialist}
        {...editCustomerSupportSpecialist}
        fetchOptions={key => fetchOptions('amiecs', key)}
        onApply={handleSaveContact}
        onChange={newValue => onChange('customerSupportSpecialist', newValue)}
        onCancel={() => onCancel('customerSupportSpecialist')}
      />
    ),
    [customerSupportSpecialist, editCustomerSupportSpecialist],
  );

  const _housingAccountExecutive = React.useMemo(
    () => (
      <ContactEditor
        {...housingAccountExecutive}
        {...editHousingAccountExecutive}
        fetchOptions={key => fetchOptions('amiehm', key)}
        onApply={handleSaveContact}
        onChange={newValue => onChange('housingAccountExecutive', newValue)}
        onCancel={() => onCancel('housingAccountExecutive')}
      />
    ),
    [housingAccountExecutive, editHousingAccountExecutive],
  );

  const _payrollRegion = React.useMemo(
    () => (
      <ContactEditor
        {...payrollRegion}
        {...editPayrollRegion}
        fetchOptions={key => fetchOptions('payrollregion', key)}
        onApply={handleSaveContact}
        onChange={newValue => onChange('payrollRegion', newValue)}
        onCancel={() => onCancel('payrollRegion')}
      />
    ),
    [payrollRegion, editPayrollRegion],
  );

  const _payrollSpecialist = React.useMemo(
    () => (
      <ContactEditor
        {...payrollSpecialist}
        {...editPayrollSpecialist}
        fetchOptions={key => fetchOptions('amieps', key)}
        onApply={handleSaveContact}
        onChange={newValue => onChange('payrollSpecialist', newValue)}
        onCancel={() => onCancel('payrollSpecialist')}
      />
    ),
    [payrollSpecialist, editPayrollSpecialist],
  );

  const _regionalDirector = React.useMemo(
    () => (
      <ContactEditor
        {...regionalDirector}
        {...editRegionalDirector}
        fetchOptions={key => fetchOptions('amierd', key)}
        onApply={handleSaveContact}
        onChange={newValue => onChange('regionalDirector', newValue)}
        onCancel={() => onCancel('regionalDirector')}
      />
    ),
    [regionalDirector, editRegionalDirector],
  );

  const _timeProcessingSpecialist = React.useMemo(
    () => (
      <ContactEditor
        {...timeProcessingSpecialist}
        {...editTimeProcessingSpecialist}
        fetchOptions={key => fetchOptions('amietps', key)}
        onApply={handleSaveContact}
        onChange={newValue => onChange('timeProcessingSpecialist', newValue)}
        onCancel={() => onCancel('timeProcessingSpecialist')}
      />
    ),
    [timeProcessingSpecialist, editTimeProcessingSpecialist],
  );

  return (
    <>
      <List className={classes.root}>
        <ContactItem
          {...accountCoordinator}
          title={_accountCoordinator}
          disableMailIcon={editAccountCoordinator?.isDirty || false}
          disablePhoneIcon={editAccountCoordinator?.isDirty || false}
          disableMessageIcon={editAccountCoordinator?.isDirty || false}
          disableAvatar={editAccountCoordinator?.isDirty || !editAccountCoordinator?.value || false}
        />
        <Divider variant="fullWidth" component="li" />
        <ContactItem
          {...accountManager}
          title={_accountManager}
          disableMailIcon={editAccountManager?.isDirty || false}
          disablePhoneIcon={editAccountManager?.isDirty || false}
          disableMessageIcon={editAccountManager?.isDirty || false}
          disableAvatar={editAccountManager?.isDirty || !editAccountManager?.value || false}
        />
        <Divider variant="fullWidth" component="li" />
        <ContactItem {...billingSpecialist} />
        <Divider variant="fullWidth" component="li" />
        <ContactItem {...clientAcctgRep} />
        <Divider variant="fullWidth" component="li" />
        <ContactItem {...clientContractAdmin} />
        <Divider variant="fullWidth" component="li" />

        <ContactItem
          {...clientContractSpecialist}
          title={_clientContractSpecialist}
          disableMailIcon={editClientContractSpecialist?.isDirty || false}
          disablePhoneIcon={editClientContractSpecialist?.isDirty || false}
          disableMessageIcon={editClientContractSpecialist?.isDirty || false}
          disableAvatar={editClientContractSpecialist?.isDirty || !editClientContractSpecialist?.value || false}
        />
        <Divider variant="fullWidth" component="li" />
        <ContactItem
          {...clinicalDirector}
          title={_clinicalDirector}
          disableMailIcon={editClinicalDirector?.isDirty || false}
          disablePhoneIcon={editClinicalDirector?.isDirty || false}
          disableMessageIcon={editClinicalDirector?.isDirty || false}
          disableAvatar={editClinicalDirector?.isDirty || !editClinicalDirector?.value || false}
        />
        <Divider variant="fullWidth" component="li" />
        <ContactItem
          {...credentialingAnalyst}
          title={_credentialingAnalyst}
          disableMailIcon={editCredentialingAnalyst?.isDirty || false}
          disablePhoneIcon={editCredentialingAnalyst?.isDirty || false}
          disableMessageIcon={editCredentialingAnalyst?.isDirty || false}
          disableAvatar={editCredentialingAnalyst?.isDirty || !editCredentialingAnalyst?.value || false}
        />
        <Divider variant="fullWidth" component="li" />
        <ContactItem
          {...credentialingSpecialist}
          title={_credentialingSpecialist}
          disableMailIcon={editCredentialingSpecialist?.isDirty || false}
          disablePhoneIcon={editCredentialingSpecialist?.isDirty || false}
          disableMessageIcon={editCredentialingSpecialist?.isDirty || false}
          disableAvatar={editCredentialingSpecialist?.isDirty || !editCredentialingSpecialist?.value || false}
        />
        <Divider variant="fullWidth" component="li" />
        <ContactItem
          {...customerSupportSpecialist}
          title={_customerSupportSpecialist}
          disableMailIcon={editCustomerSupportSpecialist?.isDirty || false}
          disablePhoneIcon={editCustomerSupportSpecialist?.isDirty || false}
          disableMessageIcon={editCustomerSupportSpecialist?.isDirty || false}
          disableAvatar={editCustomerSupportSpecialist?.isDirty || !editCustomerSupportSpecialist?.value || false}
        />
        <Divider variant="fullWidth" component="li" />
        <ContactItem
          {...housingAccountExecutive}
          title={_housingAccountExecutive}
          disableMailIcon={editHousingAccountExecutive?.isDirty || false}
          disablePhoneIcon={editHousingAccountExecutive?.isDirty || false}
          disableMessageIcon={editHousingAccountExecutive?.isDirty || false}
          disableAvatar={editHousingAccountExecutive?.isDirty || !editHousingAccountExecutive?.value || false}
        />
        <Divider variant="fullWidth" component="li" />
        <ContactItem
          {...payrollRegion}
          title={_payrollRegion}
          disableMailIcon={editPayrollRegion?.isDirty || false}
          disablePhoneIcon={editPayrollRegion?.isDirty || false}
          disableMessageIcon={editPayrollRegion?.isDirty || false}
          disableAvatar={editPayrollRegion?.isDirty || !editPayrollRegion?.value || false}
        />
        <Divider variant="fullWidth" component="li" />

        <ContactItem
          {...payrollSpecialist}
          title={_payrollSpecialist}
          disableMailIcon={editPayrollSpecialist?.isDirty || false}
          disablePhoneIcon={editPayrollSpecialist?.isDirty || false}
          disableMessageIcon={editPayrollSpecialist?.isDirty || false}
          disableAvatar={editPayrollSpecialist?.isDirty || !editPayrollSpecialist?.value || false}
        />
        <Divider variant="fullWidth" component="li" />
        <ContactItem
          {...regionalDirector}
          title={_regionalDirector}
          disableMailIcon={editRegionalDirector?.isDirty || false}
          disablePhoneIcon={editRegionalDirector?.isDirty || false}
          disableMessageIcon={editRegionalDirector?.isDirty || false}
          disableAvatar={editRegionalDirector?.isDirty || !editRegionalDirector?.value || false}
        />
        <Divider variant="fullWidth" component="li" />

        <ContactItem
          {...timeProcessingSpecialist}
          title={_timeProcessingSpecialist}
          disableMailIcon={editTimeProcessingSpecialist?.isDirty || false}
          disablePhoneIcon={editTimeProcessingSpecialist?.isDirty || false}
          disableMessageIcon={editTimeProcessingSpecialist?.isDirty || false}
          disableAvatar={editTimeProcessingSpecialist?.isDirty || !editTimeProcessingSpecialist?.value || false}
        />
      </List>
      <InternalContactSaveModal />
    </>
  );
};

const ContactEditor = ({
  value,
  isDirty,
  helperText,
  designation,
  employeeRole,
  applyNoneOptionStyle,
  onChange,
  onCancel,
  onApply,
  ...props
}) => {
  const { promiseInProgress: isSaving } = usePromiseTracker({ area: `save-contact-${props.keyAttribute}` });
  const { classes } = useStyles();
  const _onApply = () => onApply({ ...value, key: props.keyAttribute, employeeRole: employeeRole });

  return (
    <InlineDropdown
      {...props}
      placeholder="Select"
      name={designation}
      value={value}
      onChange={onChange}
      onCancel={onCancel}
      onApply={_onApply}
      removeGutters
      isDirty={isDirty}
      isSaving={isSaving}
      helperText={helperText}
      noneOption={applyNoneOptionStyle ?? classes.option}
      inputClass={{ disabled: classes.disabled, sizeSmall: classes.smallTextfield }}
    />
  );
};
