import { IUserInfo } from 'oidc/user.redux';
import { CONSTANTS_NOTIFICATIONS } from '../Common/FormatData';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { taskDetailsActions } from '@AMIEWEB/Tasks/store/Tasks.redux';
import { IGetActivityFeedQuery } from 'app/models/ActivityFeed/IGetActivityFeed';
import { ICueNote, NotificationCategory, NotificationSender, TaskNotificationType } from 'app/models/Tasks/Tasks';
import { IUpdateActivityFeedReadStatusCommand } from 'app/models/ActivityFeed/IUpdateActivityFeedReadStatus';
import moment from 'moment-timezone';
import {
  DeliveryStatus,
  IDeliveryStatusGridData,
  IDeliveryStatusProps,
  ReciepientType,
} from '@AMIEWEB/Notification/EmailDeliverStatus/EmailDeliveryStatus';
import { missingField } from 'app/constants';
import { GetMomentOfDate } from 'utils/dates/moment';
import { isNullOrUndefined } from 'app/helpers/objectHelpers';
const PlacementID = 'PlacementId';

export const ORDER_PREFERENCE_SET = 'OrderPreferenceSet';
//Description: This function is used to handle the card selection and deselection.
export const handleCardSelection = ({
  id,
  index,
  setselectedCards,
  lastSelectedCard,
  setLastSelectedCard,
  notificationPopupData,
  event,
}) => {
  if (event.shiftKey && lastSelectedCard !== null) {
    const rangeStart = Math.min(lastSelectedCard, index);
    const rangeEnd = Math.max(lastSelectedCard, index);
    const selectedCardsIds = [];
    for (let i = rangeStart; i <= rangeEnd; i++) {
      selectedCardsIds.push(notificationPopupData[i].contentProps.id);
    }
    setselectedCards(selectedCardsIds);
  } else {
    setselectedCards((prev: string[]) => {
      if (prev.find((item: string) => item === id)) {
        return prev.filter((item: any) => item !== id);
      } else {
        return [...prev, id];
      }
    });
  }
  setLastSelectedCard(index);
  event.stopPropagation();
};

//Description: This function is used to check if the card is selected or not.
export const isCardSelected = (id: string, selectedCards: string[]) => {
  return selectedCards.find((item: string) => item === id);
};

export const getEmployeeId = (userInfo: IUserInfo) => {
  if (!isNullOrUndefined(userInfo?.sharedProfile)) {
    return userInfo?.sharedProfile?.employeeId?.toString()
  } else {
    return userInfo?.employeeId?.toString()
  }
}

export const checkIsUnread = (userInfo: IUserInfo, data = []) => {
  return data?.some(el => el.contactId === getEmployeeId(userInfo) && !el.readByRecipient);
};

export const getRenderMenuItems = (notificationData, isUnread, openGoToPopper) => {
  if (notificationData?.useType === NotificationCategory.Task) {
    return [
      { key: 1, name: CONSTANTS_NOTIFICATIONS.ACTION_PANEL.MARK_READ },
      { key: 2, name: CONSTANTS_NOTIFICATIONS.ACTION_PANEL.OPEN_TASK },
      {
        key: 3,
        name: CONSTANTS_NOTIFICATIONS.ACTION_PANEL.GO_TO,
        seconadryComponent: openGoToPopper ? KeyboardArrowUpIcon : KeyboardArrowDownIcon,
      },
    ];
  } else if (CONSTANTS_NOTIFICATIONS.PREFERENCES.USETYPE.includes(notificationData?.useType)) {
    return [
      { key: 1, name: CONSTANTS_NOTIFICATIONS.ACTION_PANEL.MARK_READ },
      { key: 2, name: CONSTANTS_NOTIFICATIONS.ACTION_PANEL.CREATE_PID },
      {
        key: 3,
        name: CONSTANTS_NOTIFICATIONS.ACTION_PANEL.GO_TO,
        seconadryComponent: openGoToPopper ? KeyboardArrowUpIcon : KeyboardArrowDownIcon,
      },
    ];
  } else if (
    parseInt(notificationData?.useSubType) === TaskNotificationType.CUENOTE ||
    parseInt(notificationData?.useSubType) === TaskNotificationType.CUENOTEREPLY
  ) {
    return [
      { key: 1, name: CONSTANTS_NOTIFICATIONS.ACTION_PANEL.MARK_READ },
      { key: 2, name: CONSTANTS_NOTIFICATIONS.ACTION_PANEL.VIEW_CUE },
      { key: 3, name: CONSTANTS_NOTIFICATIONS.ACTION_PANEL.NO_CUE },
      {
        key: 4,
        name: CONSTANTS_NOTIFICATIONS.ACTION_PANEL.GO_TO,
        seconadryComponent: openGoToPopper ? KeyboardArrowUpIcon : KeyboardArrowDownIcon,
      },
    ];
  } else if (notificationData?.channel === NotificationCategory.Email) {
    //TODO: sprint 99 Viper - Change actions for email
    return [
      { key: 1, name: CONSTANTS_NOTIFICATIONS.ACTION_PANEL.MARK_READ },
      { key: 2, name: CONSTANTS_NOTIFICATIONS.ACTION_PANEL.VIEW_STATUS },
    ];
  }

  return [];
};

//Mark read notification service call
export const markNotificationAsRead = (
  idArr,
  employeeId,
  dispatch,
  notificationCategory,
  pageNumber,
  isUnread,
  disableGetActivityFetch = false,
) => {
  if (idArr.length > 0) {
    try {
      isUnread &&
        dispatch(
          taskDetailsActions.businessUpdateNotificationReadStatusSaga({
            idArr,
            employeeId,
            currentPage: 1,
            notificationCategory,
            disableGetActivityFetch,
          }),
        );
    } catch (error) {
      console.error(error);
    }
  }
};

export const markBulkNotificationAsRead = ({ idArr, dispatch, notificationCategory }) => {
  dispatch(taskDetailsActions.businessHandleBulkMarkRead({ idArr, notificationCategory }));
};

export const getUnreadNotificationPayLoad = (notificationType, userInfo) => {
  const { useTypes, useSubTypes, emailUseTypes } = notificationType;
  const UnreadPayload: IGetActivityFeedQuery = {
    ReadStatus: false, //false for unread
    Activities: {
      ContactId: getEmployeeId(userInfo?.userInfo),
      UseSubTypes: useSubTypes,
      UseTypes: useTypes,
    },
    EmailDeliveries: {
      UserId: getEmployeeId(userInfo?.userInfo),
      DeliveryStatus: emailUseTypes,
    },
  };
  return UnreadPayload;
};

export const isNotificationUnread = (notificationData, userInfo) => {
  if (notificationData?.channel === NotificationCategory.Alert) {
    return checkIsUnread(userInfo, notificationData?.message?.tos);
  } else if (notificationData?.channel === NotificationCategory.Email) {
    return !!notificationData.message?.readStatus && !notificationData?.message?.readStatus?.isRead;
  }
};

export const formatNotificationResponseWithUnreadStatus = (data, userInfo) => ({
  ...data,
  results: (data?.results || []).map(item => {
    return { ...item, isNotificationUnread: isNotificationUnread(item, userInfo) };
  }),
});
export const findNotificationCategory = ({ requestPayload }) => {
  const responseCategory = requestPayload?.Activities?.UseTypes.includes(NotificationCategory.Task)
    ? NotificationCategory.Task
    : requestPayload?.Activities?.UseTypes.includes(CONSTANTS_NOTIFICATIONS.CUENOTE.USETYPE)
      ? NotificationCategory.Alert
      : CONSTANTS_NOTIFICATIONS.PREFERENCES.USETYPE.some(value => requestPayload?.Activities?.UseTypes.includes(value))
        ? NotificationCategory.Preferences
        : Array.isArray(requestPayload?.EmailDeliveries?.DeliveryStatus)
          ? NotificationCategory.Alert
          : '';
  return responseCategory;
};

export const getNotificationCount = (payloadValue, stateValue) => {
  return isNaN(payloadValue) ? 0 : payloadValue ?? stateValue;
};

export const getDisplayCount = (notificationType, count) => {
  switch (notificationType) {
    case NotificationCategory.Alert:
      return count.unreadAlertNotificationsCount || 0;
    case NotificationCategory.Preferences:
      return count.unreadOrderMatchNotificationsCount || 0;
    case NotificationCategory.Task:
      return count.unreadTaskNotificationsCount || 0;
  }
};

export const getNotificationType = ({ useType, useSubType, channelType }) => {
  if (useType === NotificationCategory.Task) {
    return NotificationCategory.Task;
  } else if (CONSTANTS_NOTIFICATIONS.PREFERENCES.USETYPE.includes(useType)) {
    return NotificationCategory.Preferences;
  } else if (
    parseInt(useSubType) === TaskNotificationType.CUENOTE ||
    parseInt(useSubType) === TaskNotificationType.CUENOTEREPLY
  ) {
    return NotificationCategory.Alert;
  } else if (channelType === NotificationCategory.Email) {
    return NotificationCategory.Alert;
  }
};

export const markNotificationAsReadPayload = (logId, employeeId) => {
  const payload: IUpdateActivityFeedReadStatusCommand = {};
  if (!!employeeId) {
    payload.UpdateActivitiesReadCommand = {
      //TODO: Need to confirm the userId here with all other notifications and remove userId from Notification Sender
      Sender: { ...NotificationSender, userId: '' },
      ContactId: employeeId.toString(),
      IsRead: true,
      Channel: 'Alert',
      ReadOn: moment.tz(new Date(), 'America/Los_Angeles').format(),
      NotificationLogIds: logId,
    };
  }
  return payload;
};
export const bulkMarkNotificationAsReadPayload = (logId, employeeId, filter) => {
  const payload: IUpdateActivityFeedReadStatusCommand = {};
  if (!!employeeId && logId.length > 0) {
    payload.UpdateActivitiesReadCommand = {
      //TODO: Need to confirm the userId here with all other notifications and remove userId from Notification Sender
      Sender: { ...NotificationSender, userId: '' },
      ContactId: employeeId.toString(),
      IsRead: true,
      Channel: 'Alert',
      ReadOn: moment.tz(new Date(), 'America/Los_Angeles').format(),
      NotificationLogIds: logId,
    };
  } else {
    payload.UpdateActivitiesReadCommand = {
      //TODO: Need to confirm the userId here with all other notifications and remove userId from Notification Sender
      Sender: { ...NotificationSender, userId: '' },
      ContactId: employeeId.toString(),
      IsRead: true,
      Channel: 'Alert',
      ReadOn: moment.tz(new Date(), 'America/Los_Angeles').format(),
      NotificationLogIds: [],
      useTypes: filter?.Activities?.UseTypes,
      useSubTypes: filter?.Activities?.UseSubTypes,
      EmailDeliveries: filter?.EmailDeliveries ?? null
    }
  }
  return payload;
};

export const getPlacementId = (associateRecords = []) => {
  const record = associateRecords.find(record => record?.name === PlacementID);
  return record ? parseInt(record.value) : 0;
};

export const sendAcknowledgeCueNote = ({ employeeId, dispatch, notifyData, t }) => {
  const cueData: ICueNote = {
    placementId: getPlacementId(notifyData?.contentProps?.associateRecords),
    senderId: employeeId || 0,
    statusId: 1,
    text: t('notification.cueNotification.acknowledge'),
    recipientId: 0,
  };
  dispatch(taskDetailsActions.saveCueNoteAction(cueData));
};

export const openEmailDeliveryModal = ({
  notifyData,
  setEmailDeliveryModal,
  activityFeedRefactoringFlag,
  setSelectedFeedData,
  emailDeliveryData,
  dispatch,
}) => {
  const tblData: IDeliveryStatusGridData[] = [];
  let idx = 0;
  const tos = (notifyData.message.tos as any[]).map<IDeliveryStatusGridData>(d => ({
    deliveryStatus: d.deliveryStatus ? d.deliveryStatus : DeliveryStatus.Pending,
    email: d.email,
    error: 0,
    id: `${idx++}`,
    name: d?.name?.length > 1 ? d.name : missingField,
    reciepientType: ReciepientType.Tos,
  }));
  tblData.push(...tos);
  const ccs = (notifyData.message.ccs as any[]).map<IDeliveryStatusGridData>(d => ({
    deliveryStatus: d.deliveryStatus ? d.deliveryStatus : DeliveryStatus.Pending,
    email: d.email,
    error: 0,
    id: `${idx++}`,
    name: d?.name?.length > 1 ? d.name : missingField,
    reciepientType: ReciepientType.Ccs,
  }));
  tblData.push(...ccs);
  const bccs = (notifyData.message.bccs as any[]).map<IDeliveryStatusGridData>(d => ({
    deliveryStatus: d.deliveryStatus ? d.deliveryStatus : DeliveryStatus.Pending,
    email: d.email,
    error: 0,
    id: `${idx++}`,
    name: d?.name?.length > 1 ? d.name : missingField,
    reciepientType: ReciepientType.Bccs,
  }));
  tblData.push(...bccs);
  const moment =
    GetMomentOfDate(notifyData.publishedOn).format('MM/DD/YYYY') +
    ' ' +
    'at' +
    ' ' +
    GetMomentOfDate(notifyData.publishedOn).format('LT');
  const deliveryModalProps: IDeliveryStatusProps = {
    id: notifyData.id,
    body: notifyData.message.body,
    open: true,
    subject: notifyData.message.subject,
    sentDate: [{ date: moment, logid: notifyData.id }],
    tableData: tblData,
    closeModal: () => {
      setEmailDeliveryModal(false);
      if (activityFeedRefactoringFlag) {
        setSelectedFeedData({
          currentPopper: null,
          openPopper: false,
        });
      }
    },
  };
  emailDeliveryData.current = deliveryModalProps;
  dispatch(taskDetailsActions.setEmailDeliveryAssociateRecords([...notifyData.associateRecords]));
  setEmailDeliveryModal(true);
};
