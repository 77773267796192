import React, { useEffect, useMemo } from 'react';
import { Chip, Grid, ThemeProvider, StyledEngineProvider,useTheme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { OrderPriorities } from 'app/components/Order/OrderCreationForm/OrderDetails/OrderPriorities';
import { Controller, useFormContext } from 'react-hook-form';
import { UnitCardsTitle, UnitDetailsBody } from '../UnitDesign';
import { useTranslation } from 'react-i18next';
import { getOrderPriorityValues, commonVIPriority, formatChipLabel } from '../helper';
import { useDispatch, useSelector } from 'react-redux';
import { selectOrderPriorities } from '@AMIEWEB/Order/Store/OrderCreation.selectors';
import { orderCreationActions } from '@AMIEWEB/Order/Store/OrderCreation.actions';

interface Props {}
const useStyles = makeStyles()((theme) => ({
  chip: {
    maxWidth: '50ch',
    margin: '0 5px 10px 0',
  },
}));

export const NewUnitOrderPriorities = (props: Props) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const theme= useTheme();
  const { control, watch, setValue } = useFormContext();
  const watchValue = watch('orderPriorities');
  const watchVirtualRatingValue = watch('virtualRating');
  const dispatch = useDispatch();
  const orderPriorities = useSelector(selectOrderPriorities);
  const getDisabledPriorities = () => {
    return Array.isArray(watchVirtualRatingValue) && watchVirtualRatingValue.length > 0
      ? [...watchVirtualRatingValue, commonVIPriority]
      : [];
  };
  const orderDisabledPriorityValue = getDisabledPriorities();

  const handleDelete = vr => {
    const selectedValues = watchValue.filter(priorities => priorities !== vr);
    setValue('orderPriorities', selectedValues);
  };

  useMemo(() => {
    if (watchVirtualRatingValue) {
      const uniqueItems = watchVirtualRatingValue.filter(item => !watchValue.includes(item));
      if (!!uniqueItems && uniqueItems.length > 0) {
        setValue('orderPriorities', [...watchValue, ...uniqueItems]);
      }
    }
  }, [watchVirtualRatingValue]);

  useEffect(() => {
    dispatch(orderCreationActions.getOrderPriorityTypes());
  }, []);

  return (
    <div>
      {/* TODO: Remove this theme wrapper since it uses the global theme */}
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <UnitDetailsBody variant="outlined">
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <UnitCardsTitle>{t('order.createUnit.orderPriorities')}</UnitCardsTitle>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name={'orderPriorities'}
                  render={({ ref, onChange, ...rest }) => {
                    return (
                      <OrderPriorities
                        defaultValue={watchValue && Array.isArray(watchValue) ? getOrderPriorityValues(watchValue) : []}
                        handleOrderPriorities={selectedValues => {
                          if (
                            orderDisabledPriorityValue &&
                            Array.isArray(orderDisabledPriorityValue) &&
                            orderDisabledPriorityValue.length > 0
                          ) {
                            onChange([...new Set([...selectedValues, ...orderDisabledPriorityValue])]);
                          } else {
                            onChange(selectedValues);
                          }
                        }}
                        orderPriorities={orderPriorities}
                        selectedValue={
                          watchValue && Array.isArray(watchValue)
                            ? orderPriorities.filter(p => watchValue.includes(p.label))
                            : []
                        }
                        disabledValue={getDisabledPriorities()}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item>
                <div id={'createUnitOrderPrioritiesSelectedItemContainer'}>
                  {(watchValue || watchVirtualRatingValue) &&
                    Array.isArray(watchValue) &&
                    watchValue.map(opChip => {
                      return (
                        <>
                          {orderDisabledPriorityValue &&
                          orderDisabledPriorityValue?.filter(t => t == opChip).length <= 0 ? (
                            <Chip
                              variant="outlined"
                              label={
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                  <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    {formatChipLabel(opChip)}
                                  </div>
                                </div>
                              }
                              className={classes.chip}
                              onDelete={() => handleDelete(opChip)}
                            />
                          ) : (
                            <Chip
                              variant="outlined"
                              label={
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                  <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    {formatChipLabel(opChip)}
                                  </div>
                                </div>
                              }
                              className={classes.chip}
                            />
                          )}
                        </>
                      );
                    })}
                </div>
              </Grid>
            </Grid>
          </UnitDetailsBody>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
};
