import React, { useEffect, useState } from 'react';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { selectOrderCertification, selectOrderSubCertification } from '@AMIEWEB/Order/Store/Order.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { IOrderCertification, orderDataActions } from '@AMIEWEB/Order/Store/Order.redux';
import { OrderCertificationTypeAheadDropdown } from './OrderCertificationTypeAhead';
import { selectIncompleteReqFields } from '@AMIEWEB/Order/Store/OrderCreation.selectors';
import { ReqOrderFieldNames } from '@AMIEWEB/Order/Store/types';

interface GridCellProps {
  [key: string]: any;
}

const useStyles = makeStyles()({
  selectField: {
    minWidth: 450,
    zIndex: 1000,
  },
});

const SubCertificationRenderer = React.memo(function SubCertificationRenderer(cellParams: GridCellProps) {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const { t } = useTranslation();
  const subCertificationList = useSelector(selectOrderSubCertification);
  const orderCertifications = useSelector(selectOrderCertification);
  const incompleteRequiredQualification = useSelector(selectIncompleteReqFields);
  const [selectedValues, setSelectedValues] = useState<any[]>([]);
  const [hideDropdown, setHideDropdown] = useState<boolean>(true);
  const [formattedSubCertificationList, setFormattedSubCertificationList] = useState<any[]>([]);

  const onApplyFilter = (subCerts: any[]) => {
    const orderCertificationDetails: IOrderCertification[] = [...orderCertifications];
    if (subCerts.length > 0) {
      subCerts?.map(item => {
        if (orderCertificationDetails && orderCertificationDetails?.length > 0) {
          const certificationDetailsIndex = orderCertificationDetails?.findIndex(
            data => data?.requirementTypeId === item?.id && item?.isSubCert === true,
          );

          const selectedCertificationDetails = orderCertificationDetails?.find(
            data => data?.requirementTypeId === item?.id && item?.isSubCert === true,
          );
          if (certificationDetailsIndex !== -1) {
            const selectedCertification: IOrderCertification = {
              ...selectedCertificationDetails,
              documentAttachmentRequired: item?.documentAttachmentRequired,
              isRequired: item?.isRequired,
            };
            orderCertificationDetails[certificationDetailsIndex] = { ...selectedCertification };
          } else {
            const newSelectedCertification: IOrderCertification = {
              abbreviation: item?.abbreviation,
              documentAttachmentRequired: item?.documentAttachmentRequired,
              isError: false,
              isRequired: item?.isRequired,
              isSubCert: item?.isSubCert,
              requirementTypeId: item?.id,
            };
            orderCertificationDetails.push(newSelectedCertification);
          }
        }
      });
      const filteredCerts = orderCertificationDetails?.filter(item => item?.isSubCert === false);
      const subCertsId = subCerts?.map(item => item?.id);
      const certIds = filteredCerts?.map(item => item?.requirementTypeId);
      const filteredIds = certIds?.concat(subCertsId);
      const filteredOrderCertification = orderCertificationDetails?.filter(data =>
        filteredIds?.includes(data?.requirementTypeId),
      );
      const updatedCertifications = filteredOrderCertification?.map(item => {
        if (item?.requirementTypeId === 122) {
          return { ...item, isError: false };
        } else {
          return { ...item };
        }
      });
      dispatch(orderDataActions.setOrderCertificationDetails(updatedCertifications));
    } else {
      if (orderCertificationDetails && orderCertificationDetails?.length > 0) {
        const updatedCertificationDetails = orderCertificationDetails?.filter(cert => cert?.isSubCert === false);
        const updateErrorCertificationDetails = updatedCertificationDetails?.map(cert => {
          if (cert?.requirementTypeId === 122) {
            const updatedData = {
              ...cert,
              isError: true,
            };
            return updatedData;
          } else {
            return cert;
          }
        });
        dispatch(orderDataActions.setOrderCertificationDetails(updateErrorCertificationDetails));
      }
    }
  };

  useEffect(() => {
    const isParentCertExist = orderCertifications?.some(item => item?.requirementTypeId === 122);
    if (isParentCertExist) {
      const isSubCertError = incompleteRequiredQualification?.some(item => item === ReqOrderFieldNames.CERTIFICATION);
      const orderCertificationDetails = orderCertifications?.map(cert => {
        if (cert?.requirementTypeId === 122) {
          const updatedData = {
            ...cert,
            isError: isSubCertError,
          };
          return updatedData;
        } else {
          return cert;
        }
      });
      dispatch(orderDataActions.setOrderCertificationDetails(orderCertificationDetails));
    }
  }, [incompleteRequiredQualification]);

  React.useEffect(() => {
    const existingCertificationData = orderCertifications?.find(data => data?.requirementTypeId === cellParams.id);
    if (cellParams?.id && existingCertificationData) {
      if (existingCertificationData?.requirementTypeId === 122) {
        setHideDropdown(false);
      } else {
        setHideDropdown(true);
      }
      const subCertList: any[] = [];
      orderCertifications?.map(data => {
        if (data?.isSubCert === true) {
          const selectedSubCertValue = {
            id: data?.requirementTypeId,
            requirementTypeID: data?.requirementTypeId,
            abbreviation: data?.abbreviation,
            isSubCert: true,
            isRequired: data?.isRequired,
            documentAttachmentRequired: data?.documentAttachmentRequired,
          };
          subCertList.push(selectedSubCertValue);
        }
      });
      setSelectedValues(subCertList);
    }
  }, [orderCertifications]);

  useEffect(() => {
    const formattedList = subCertificationList?.map(s => {
      return { id: s?.requirementTypeID, ...s };
    });
    setFormattedSubCertificationList(formattedList);
  }, [subCertificationList]);

  return (
    <>
      {!hideDropdown && (
        <OrderCertificationTypeAheadDropdown
          filterName={t('order.certification.subCertificationDropdown')}
          options={formattedSubCertificationList}
          isMultiSelect
          value={selectedValues}
          minWidth={'180px'}
          apply={onApplyFilter}
          customClass={classes.selectField}
          hasControlError={cellParams?.isError}
          controlErrorText={'required'}
          isDisabled={cellParams?.isDisabled}
        />
      )}
    </>
  );
});

export const SubCertificationRenderCell = (props: { param: GridCellParams; isDisabled: boolean }) => {
  const [hideField, setHideField] = useState<boolean>(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const [isError, setIsError] = useState<boolean>(false);
  const orderCertifications = useSelector(selectOrderCertification);

  React.useEffect(() => {
    if (
      props?.param?.id &&
      orderCertifications?.find(data => data?.requirementTypeId === props?.param?.id && data?.requirementTypeId === 122)
    ) {
      const isErrorExists = orderCertifications?.some(data => data?.isError === true);
      setIsError(isErrorExists);
      setHideField(false);
    } else {
      setSelectedValues([]);
      setHideField(true);
    }
  }, [orderCertifications]);

  return !hideField ? (
    <SubCertificationRenderer
      {...props?.param}
      isDisabled={props?.isDisabled}
      selectedValues={selectedValues}
      isError={isError}
    />
  ) : (
    <></>
  );
};
