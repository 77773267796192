import React from 'react';
import { Grid, ThemeProvider, StyledEngineProvider,useTheme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { CustomMultiLineTextField } from 'app/components/Order/OrderCreationForm/Common/CustomMultiLineTextField';
import { useTranslation } from 'react-i18next';
import { UnitCardsTitle, UnitDetailsBody } from './UnitDesign';
import { useDispatch, useSelector } from 'react-redux';
import { selectNewOrder } from '@AMIEWEB/Order/Store/OrderCreation.selectors';
import { requestOrderCreationUpdateOrderDetailsAction } from '@AMIEWEB/Order/Store/OrderCreation.actions';
import { Controller, useFormContext } from 'react-hook-form';

interface Props {
  showMandatoryStyle?: boolean;
}
const useStyles = makeStyles()((theme) => ({
  body: {
    paddingBottom: 12,
  },
  instructionalText: {
    paddingBottom: 16,
  },
}));

export const NewFirstDayInstructionNotes = (props: Props) => {
  const { showMandatoryStyle } = props;
 const theme= useTheme();
  const { classes } = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { control } = useFormContext();

  const newOrderObj = useSelector(selectNewOrder);

  //@Team: @Monin  Remove these functions if they are not used
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedValue = { ...newOrderObj, [event.target.name]: event.target.value };
    dispatch(requestOrderCreationUpdateOrderDetailsAction({ data: updatedValue }));
  };

  //@Team: @Monin  Remove these functions if they are not used
  const handleChangeForm = (key, value) => {
    const updatedValue = { ...newOrderObj, [key]: value };
    dispatch(requestOrderCreationUpdateOrderDetailsAction({ data: updatedValue }));
  };

  return (
    <div>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <UnitDetailsBody variant="outlined">
            <Grid container direction="column" spacing={1} className={classes.body}>
              <Grid item>
                <UnitCardsTitle>{t('order.createUnit.firstDayInstructionNotes.title')}</UnitCardsTitle>
              </Grid>
              <Grid item>
                <Controller
                  control={control}
                  name={'firstDayInstructions'}
                  render={({ ref, onChange, ...rest }) => (
                    <CustomMultiLineTextField
                      id={'createUnitFirstDayInstructionTxt'}
                      value={rest.value}
                      name="firstDayInstructions"
                      req={
                        showMandatoryStyle !== true &&
                        (rest.value === undefined || rest.value === null || rest.value === '')
                          ? true
                          : false
                      }
                      label={
                        showMandatoryStyle !== true &&
                        (rest.value === undefined || rest.value === null || rest.value === '')
                          ? `${t('order.createUnit.firstDayInstructionNotes.title')} *`
                          : t('order.createUnit.firstDayInstructionNotes.title')
                      }
                      onChange={newValue => {
                        onChange(newValue);
                      }}
                      placeholder={t('order.createUnit.firstDayInstructionNotes.placeHolder')}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </UnitDetailsBody>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
};
