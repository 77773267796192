import React from 'react';
import { Box, Chip, Divider, Grid, IconButton, MenuItem, MenuList, Paper } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { ICandidateInterview } from '../InterviewsTab';
import moment from 'moment';
import { CustomTooltip } from '../../../../../Common/Tooltips/CustomTooltip';
import InterviewIcon from 'app/assets/images/CandidateProfile/InterviewIcon.svg';
import { customSwitch } from 'utils/customSwitchCase/customSwitch';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ff_interviewViewHistory } from 'app/constants/FeatureFlags/Candidate/Keys';
import { useDecision } from '@optimizely/react-sdk';
import { useSelector } from 'react-redux';
import { disciplineDataSelector } from 'store/redux-store/lookup/lookup.selectors';

interface InterviewTabProps {
  addInterview?: boolean;
  data: ICandidateInterview;
  handleClick: (data: ICandidateInterview) => void;
}

const useStyles = makeStyles()(theme => ({
  container: {
    width: '100%',
    border: '1px solid #ececec',
    borderRadius: '4px',
    padding: '15px 10px 15px 25px;',
    minHeight: '265px',
  },
  divider: {
    margin: '5px 20px 10px 0px',
  },
  child: {
    overflow: 'hidden',
    fontSize: '14px',
  },
  subTitle: {
    fontWeight: 'bold',
  },
  fontAdjustment: {
    fontSize: '12px',
  },
  chip: {
    fontWeight: 500,
    fontSize: '14px',
  },
  icon: {
    height: '25px',
    width: '25px',
  },
  tooltipPlacementBottom: {
    margin: 0,
    marginTop: 22,
    padding: '8px 8px',
    fontSize: '14px',
    color: '#000',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  menuItem: {
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
}));

export const InterviewCard = (props: InterviewTabProps) => {
  const { data, handleClick } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [interviewViewHistory] = useDecision(ff_interviewViewHistory);
  const disciplineData = useSelector(disciplineDataSelector);

  const getSkillset = () => {
    const discipline = disciplineData.find(disc => disc.object.Abbreviation === data.disciplineAbbr)?.object;
    return discipline?.Description
      ? data.specialtyAbbr
        ? `${discipline.Description} - ${data.specialtyAbbr}`
        : discipline?.Description
      : '-';
  };

  const getChipData = customSwitch({
    Completed: {
      color: '#13A024',
      background: 'rgba(19, 160, 36, 0.1)',
      label: t('candidate.virtualInterviewTabLabels.passed'),
    },
    Rejected: {
      color: '#CC0000',
      background: 'rgba(204, 0, 0, 0.1)',
      label: t('candidate.virtualInterviewTabLabels.rejected'),
    },
    Scheduled: {
      color: '#ED812C',
      background: 'rgba(237, 129, 44, 0.1)',
      label: t('candidate.virtualInterviewTabLabels.review'),
    },
    '1st reminder sent': {
      color: '#189AD9',
      background: 'rgba(24, 154, 217, 0.1)',
      label: t('candidate.virtualInterviewTabLabels.sent'),
    },
    '2nd reminder sent': {
      color: '#189AD9',
      background: 'rgba(24, 154, 217, 0.1)',
      label: t('candidate.virtualInterviewTabLabels.sent'),
    },
    default: {
      color: '#888888',
      background: 'rgba(136, 136, 136, 0.1)',
      label: t('candidate.virtualInterviewTabLabels.notAvailable'),
    },
  })(undefined);

  const chipData = data.interviewStatus ? getChipData(data.interviewStatus) : getChipData('default');

  const TooltipContent = () => {
    return (
      <MenuList style={{ padding: 0 }}>
        <MenuItem className={classes.menuItem} onClick={() => handleClick(data)} data-testid="view-history-tooltip">
          <div style={{ fontSize: '12px' }}>{t('candidate.virtualInterviewTabLabels.viewHistory')}</div>
        </MenuItem>
      </MenuList>
    );
  };

  return (
    <Paper className={classes.container}>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <Grid container spacing={2} direction="row" xs={12} style={{ height: '60px' }}>
            <Grid item xs={1}>
              <img src={InterviewIcon} className={classes.icon} />
            </Grid>
            <Grid item xs={11}>
              <Grid container spacing={2} direction="row" justifyContent="space-between">
                <Grid item>
                  <Grid container spacing={0} direction="column">
                    <Grid item>
                      <Box className="page-title">
                        {data.interviewDate ? moment(new Date(data.interviewDate)).format('MM/DD/YYYY') : '-'}
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box className={classes.fontAdjustment}>{`Invite ${data.inviteId ? data.inviteId : '-'} ${
                        data.invitationDate ? moment(new Date(data.invitationDate)).format('MM/DD/YYYY') : ' '
                      }`}</Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Chip
                    className={classes.chip}
                    style={{
                      color: chipData?.color,
                      borderColor: chipData?.color,
                      background: chipData?.background,
                    }}
                    variant="outlined"
                    size="medium"
                    label={chipData?.label}
                  />
                  {interviewViewHistory.enabled && (
                    <IconButton color="primary" style={{ padding: '0', marginLeft: '10px' }} size="small">
                      <CustomTooltip
                        arrow
                        style={{ color: '#888888' }}
                        tooltipContent={<TooltipContent />}
                        tooltipPlacementBottom={classes.tooltipPlacementBottom}
                        interactive
                        placement="bottom"
                      >
                        <MoreVertIcon />
                      </CustomTooltip>
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider className={classes.divider}></Divider>
        <Grid item>
          <Grid container spacing={3} direction="row" justifyContent="space-between" xs={12}>
            <Grid item xs={5} className={classes.child}>
              <Grid container spacing={1} direction="column">
                <Grid item>
                  <Box>{t('candidate.virtualInterviewTabLabels.skillset')}</Box>
                </Grid>
                <Grid item>
                  <Box className={classes.subTitle}>{getSkillset()}</Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={5} className={classes.child}>
              <Grid container spacing={1} direction="column">
                <Grid item>
                  <Box>{t('candidate.virtualInterviewTabLabels.rating')}</Box>
                </Grid>
                <Grid item>
                  <Box className={classes.subTitle}>
                    {data.interviewRating !== ''
                      ? data.interviewRating.substring(0, data.interviewRating.length - 1)
                      : '-'}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={5} className={classes.child}>
              <Grid container spacing={1} direction="column">
                <Grid item>
                  <Box>{t('candidate.virtualInterviewTabLabels.lastUpdated')}</Box>
                </Grid>
                <Grid item>
                  <Box className={classes.subTitle}>
                    {data.changedDate ? moment(new Date(data.changedDate)).format('MM/DD/YYYY') : '-'}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={5} className={classes.child}>
              <Grid container spacing={1} direction="column">
                <Grid item>
                  <Box>{t('candidate.virtualInterviewTabLabels.rejectReason')}</Box>
                </Grid>
                <Grid item>
                  {data.interviewReason && data.interviewReason !== '' ? (
                    data.interviewReason.length < 30 ? (
                      <Box className={classes.subTitle}>{data.interviewReason}</Box>
                    ) : (
                      <CustomTooltip
                        contentStyle={{
                          textOverflow: 'wrap',
                          overflow: 'hidden',
                          color: 'black',
                          fontSize: '12px',
                          fontWeight: 'bold',
                        }}
                        tooltipContent={data.interviewReason}
                        value={`${data.interviewReason?.slice(0, 23)}...`}
                      />
                    )
                  ) : (
                    <Box className={classes.subTitle}>{`-`}</Box>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};
