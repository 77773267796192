import { Button, Grid, Theme, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React, { createContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { notificationSelection } from 'store/redux-store/notification/notification.selector';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTabbingOrder } from 'utils/customHooks/useTabbingOrder';
import { SearchCandidate } from './SearchCandidate';
import { IAssociateCandidatePayload } from 'app/models/Notification/Notification';
import { selectUser } from 'oidc/user.selectors';
import { PersonalInfoForm } from 'app/components/Candidate/CandidateProfile/CandidateTabPanel/PersonalInfoTab/Form/PersonalInfoForm';
import { ICandidateDetails, PlacementInfo } from 'app/models/Candidate/CandidateProfile';
import { appendSessionValue, SessionKey } from 'utils/customHooks/sessionStorage/sessionHelpers';
import {
  getCandidateAddress,
  getCandidateById,
  getSSNValueByTravelerId,
} from 'app/services/CandidateServices/CandidateServices';
import { Concatenate } from 'utils/string/string';
import { getActivePlacementsByCandidate } from 'app/services/PlacementServices/PlacementServices';
import { PagingData } from 'app/models/PagingData';
import { notificationDataActions } from 'store/redux-store/notification/notification.redux';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { candidateDetailActions } from 'app/components/Candidate/CandidateProfile/Profile/CandidateDetails.redux';
import { formatPhoneNumber } from 'app/helpers/phoneHelpers';
import { PersonalInfoWrapperTabsIndex } from 'app/components/Candidate/CandidateProfile/CandidateTabPanel/PersonalInfoTab/Form/PersonalInfoWrapper';
import { candidateDetailsSelection } from 'app/components/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import { isPositiveNumber } from 'app/helpers/componentHelpers';
import { associateConversationWithContactId } from 'app/services/NotificationServices/NotificationService';
import { selectCoveredInbox } from 'store/redux-store/user-coverage/selectors';
import { ff_notifications_coverage_sms } from 'app/constants/FeatureFlags/EmployeeCoverage/keys';
import { useDecision } from '@optimizely/react-sdk';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    background: '#E8F4FD',
    borderBottom: '1px solid #ccc',
    borderTop: '1px solid #ccc',
    padding: '2% 2% 2% 5%',
  },
  header: {
    margin: '1% 0',
    textAlign: 'left',
    font: 'normal normal normal 14px/19px Roboto',
    letterSpacing: '0px',
    color: '#333333',
    opacity: 1,
  },
  searchField: {
    background: '#fff',
    marginTop: '1%',
    width: '70%',
    padding: '0 !important',
  },
  addButton: {
    height: '48px',
    margin: '5px 5px 0px 10px',
    overflow: 'hidden',
    width: '90%',
  },
}));

export const AddonContext = createContext<{ setCurrentFocusId: (id: any) => void }>({ setCurrentFocusId: id => {} });

export default function ChooseCandidate() {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const notificationData = useSelector(notificationSelection);
  const candidateInfo = useSelector(candidateDetailsSelection);
  const dispatch = useDispatch();

  const { userInfo } = useSelector(selectUser);
  const coveredInbox = useSelector(selectCoveredInbox);
  const [editInfoOpen, setEditInfoOpen] = useState<boolean>(false);

  const formMethods = useForm({
    defaultValues: notificationData.sms.chatHistory?.associatedCandidate || {},
  });

  const { reset } = formMethods;

  const isLoggedUserInActiveInbox = coveredInbox?.activeInbox.coveredUserId === String(userInfo?.employeeId);
  const [smsCoverageFlag] = useDecision(ff_notifications_coverage_sms);
  const hasActiveInbox = smsCoverageFlag && !isLoggedUserInActiveInbox && !!coveredInbox?.activeInbox;
  const [recruiter, setRecruiter] = useState<any>(null);
  useEffect(() => {
    reset(notificationData.sms.chatHistory?.associatedCandidate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationData.sms.chatHistory]);

  useEffect(
    () => {
      if (notificationData.sms.chatHistory?.associatedCandidate) {
        reset({ associatedCandidate: notificationData.sms.chatHistory?.associatedCandidate });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [notificationData],
  );

  const { setCurrentFocusId } = useTabbingOrder({
    tabOrderList: [{ id: 'Candidate-Search', action: 'focus' }],
    onUndo: event => {
      event.preventDefault();
      event.stopPropagation();
    },
    onSave: event => {},
  });

  const handleAssociation = async () => {
    const travelerId = notificationData.sms.chatHistory?.associatedCandidate?.candidateId;
    const brandId = notificationData.sms.chatHistory?.associatedCandidate?.brandId;

    let candidateDetails: ICandidateDetails | {} = {};
    if (isPositiveNumber(travelerId) && isPositiveNumber(brandId)) {
      const ssnValue = await getSSNValueByTravelerId(travelerId);
      await getCandidateById(travelerId, brandId)
        .then(async candidateRes => {
          appendSessionValue(
            SessionKey.recentCandidates,
            {
              candidateId: candidateRes.travelerId,
              name: Concatenate([candidateRes.name.first, candidateRes.name.middle, candidateRes.name.last], ' '),
              brandId: candidateRes.brandId,
              brand: candidateRes.brand,
              recruiter: Concatenate([candidateRes.recruiter.first, candidateRes.recruiter.last], ' '),
              recruiterId: candidateRes.recruiter.recruiterId,
              applicationDate: candidateRes.applicationDate,
              disciplines: candidateRes.groupedSkillsSets?.reduce((storage, item) => {
                if (item.disciplineAbbr !== null) storage.push(item.disciplineAbbr);
                return storage;
              }, []),
            },
            true,
            5,
          );

          setRecruiter(candidateRes.recruiter);

          candidateRes.socialSecurityNumber = ssnValue?.ssn || '';
          candidateRes.phoneNumbers.push({
            phoneTypeDescription: '',
            phoneNumber: formatPhoneNumber(notificationData.sms.data?.tos.find(Boolean)?.phoneNumber),
            countryDialingCode: '1',
            phoneTypeId: '',
            extensionNotes: null,
          });
          dispatch(candidateDetailActions.setCandidateDetails(candidateRes));
          //address
          getCandidateAddress(travelerId, brandId)
            .then(data => dispatch(candidateDetailActions.setAddressData(data)))
            .catch(err => dispatch(candidateDetailActions.setAddressData(null)));

          setEditInfoOpen(true);
          // Placements: Active placements
          await getActivePlacementsByCandidate(travelerId)
            .then(activePlacementsRes => {
              const pagingData: PagingData<PlacementInfo> = activePlacementsRes;
              candidateRes.activePlacements = pagingData.items;
              candidateDetails = { ...candidateDetails, ...candidateRes };
              dispatch(candidateDetailActions.setActivePlacements(candidateRes.activePlacements));
            })
            .catch(err =>
              dispatch(
                globalActions.setSnackBar({
                  message: t('Failed to Fetch Candidate Active Placements'),
                  severity: 'error',
                }),
              ),
            );
        })
        .catch(err => {
          dispatch(
            globalActions.setSnackBar({
              message: `Failed to fetch candidate Information`,
              severity: 'error',
            }),
          );
        });
    }
  };

  const isRecruiter = userInfo => {
    return userInfo?.roles?.some(item => item.includes('Recruitment'));
  };

  const saveCandidateAssociation = async () => {
    const payload: IAssociateCandidatePayload = {
      userId: hasActiveInbox ? `${coveredInbox?.activeInbox.coveredUserId}` : `${userInfo?.employeeId}`,
      contactId: `${notificationData.sms.chatHistory?.associatedCandidate?.candidateId}`,
      contactPhone: notificationData.sms.data?.tos.find(Boolean)?.phoneNumber,
      brand: isRecruiter(userInfo) ? notificationData.sms.chatHistory?.associatedCandidate?.brand : null,
      contactName: notificationData.sms.chatHistory?.associatedCandidate?.name,
      brandId: isRecruiter(userInfo) ? `${notificationData.sms.chatHistory?.associatedCandidate?.brandId}` : null,
      associatedRecords: isRecruiter(userInfo)
        ? [
            {
              name: 'brandId',
              value: `${notificationData.sms.chatHistory?.associatedCandidate.brandId}`,
            },
            {
              name: 'brand',
              value: notificationData.sms.chatHistory?.associatedCandidate.brand,
            },
            {
              name: 'recruiterId',
              value: `${recruiter?.recruiterId}`,
            },
            {
              name: 'recruiter',
              value: Concatenate([recruiter?.first, recruiter?.last], ' '),
            },
          ]
        : [
            {
              name: 'recruiterId',
              value: `${recruiter?.recruiterId}`,
            },
            {
              name: 'recruiter',
              value: Concatenate([recruiter?.first, recruiter?.last], ' '),
            },
          ],
    };
    const associatedCandidate = notificationData.sms.chatHistory?.associatedCandidate;
    const recipientDetails = {
      name: associatedCandidate.name,
      contactId: `${associatedCandidate.candidateId}`,
      brand: isRecruiter(userInfo) ? associatedCandidate.brand : null,
      brandId: isRecruiter(userInfo) ? String(associatedCandidate.brandId) : null,
      phoneNumber: payload.contactPhone,
      email: associatedCandidate.email,
    };

    //Check coverage and add covering employeeId when an active coverage/ inbox is present
    payload.associatedRecords = hasActiveInbox
      ? [
          ...payload.associatedRecords,
          {
            name: 'coveringEmployeeId',
            value: `${userInfo?.employeeId}`,
          },
        ]
      : payload.associatedRecords;

    await associateConversationWithContactId(payload);

    dispatch(notificationDataActions.updateSmsCandidateInfo(candidateInfo));
    const smsData = _.cloneDeep(notificationData.sms.data);
    if (!!smsData.associatedRecords.find(x => x.name.toLowerCase() === 'brandid')) {
      smsData.associatedRecords.pop();
      smsData.associatedRecords.push({
        name: 'brandId',
        value: `${notificationData.sms.chatHistory?.associatedCandidate.brandId}`,
      });
    }
    dispatch(notificationDataActions.updateSmsData({ ...smsData, tos: [recipientDetails] }));

    const updateConversation =
      notificationData.sms.chatHistory && _.cloneDeep(notificationData.sms.chatHistory.chats!.results);

    updateConversation!.find(Boolean)!.brand = notificationData.sms.chatHistory?.associatedCandidate.brand;
    updateConversation!.find(Boolean)!.associatedRecords.push({
      name: 'brandId',
      value: notificationData.sms.chatHistory?.associatedCandidate.brandId,
    });
    dispatch(
      notificationDataActions.updateChatHistory({
        ...notificationData.sms.chatHistory,
        candidateDetails: candidateInfo,
        chats: {
          ...notificationData.sms.chatHistory?.chats,
          result: updateConversation,
        },
      }),
    );

    const result = _.cloneDeep(notificationData);
    const updatedInboxMessage = result.inbox
      ? result.inbox?.map(x =>
          x.name === result.sms.data.tos.find(Boolean).phoneNumber
            ? { ...x, name: recipientDetails.name, travelerId: recipientDetails.contactId, unreadLogs: 0 }
            : x,
        )
      : [];

    if (updatedInboxMessage.length > 0) {
      updatedInboxMessage.find(Boolean).log.associatedRecords.push({
        name: 'brandId',
        value: notificationData.sms.chatHistory?.associatedCandidate.brandId,
      });
      var updateTos = updatedInboxMessage.find(Boolean).log.message.tos.find(Boolean);
      updateTos.contactId = `${associatedCandidate.candidateId}`;
      updateTos.email = associatedCandidate.email;
      updateTos.name = associatedCandidate.name;
      updateTos.brand = associatedCandidate.brand;
    }

    dispatch(notificationDataActions.updateInbox(updatedInboxMessage));
  };

  return (
    <AddonContext.Provider value={{ setCurrentFocusId }}>
      <PersonalInfoForm
        open={editInfoOpen}
        handleClose={() => setEditInfoOpen(false)}
        defaultTabIndex={PersonalInfoWrapperTabsIndex.ContactInformation}
        onSubmit={saveCandidateAssociation}
        onSuccessfullSave={saveCandidateAssociation}
        fromAssociateCandidate={true}
      />
      <FormProvider {...formMethods}>
        <form>
          <div className={classes.root}>
            <Typography className={classes.header}>{t('notification.sms.addCandidate')}</Typography>
            <Grid container direction="row">
              <Grid item className={classes.searchField} xs={9}>
                <SearchCandidate />
              </Grid>
              <Grid item xs={3}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.addButton}
                  disabled={!notificationData.sms.chatHistory?.associatedCandidate}
                  onClick={handleAssociation}
                >
                  {t('notification.sms.add')}
                </Button>
              </Grid>
            </Grid>
          </div>
        </form>
      </FormProvider>
    </AddonContext.Provider>
  );
}
