import React from 'react';
import { missingField } from 'app/constants';
import { MoreTextBlock } from '../../CustomComponents/MoreTextBlock';

export const OrderInfoPreferredQualifications = ({ ...props }) => {
  return (
    <div id="placement-order-info-preferred-qualifications" data-testid="order-info-preferred-qualifications-item">
      <MoreTextBlock
        title={''}
        text={
          props.orderInformation?.preferredQualifications
            ? props.orderInformation?.preferredQualifications
            : `${missingField}`
        }
        charLimit={170}
        id={'placementDetailsTabsPreferredQualificationsDetails'}
      />
    </div>
  );
};
