import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { TextField } from 'amn-ui-core';
import { formInputs } from 'app/models/ClientContact/ClientContact';
import { emailCheck } from 'app/components/Candidate/CandidateProfile/CandidateTabPanel/PersonalInfoTab/Form/PersonalInfoHelper';
import { useSelector } from 'react-redux';
import { selectClientContactDetails } from '../store/ClientContact.selector';

export const CCEmail = formMethods => {
  const { t } = useTranslation();
  const { formMethods: controls, setIsInValidEmail, isInValidEmail, disabled } = formMethods;
  const selectedClientContactDetails = useSelector(selectClientContactDetails);

  const {
    control,
    setError,
    clearErrors,
    setValue,
    formState: { isDirty, errors },
  } = controls;

  useEffect(() => {
    if (selectedClientContactDetails?.contactId)
      setValue('email', selectedClientContactDetails?.email ? selectedClientContactDetails?.email : '', {
        shouldDirty: false,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClientContactDetails?.contactId]);

  useEffect(() => {
    if (isInValidEmail) {
      setError(formInputs.email, {
        type: 'invalidEmail',
        message: 'Invalid email address',
      });
    } else {
      clearErrors(formInputs.email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInValidEmail]);

  return (
    <Controller
      control={control}
      name={'email'}
      render={({ ref, onChange, value, ...rest }) => (
        <TextField
          variant="filled"
          color="primary"
          id="email"
          label={t('clientContactModal.labels.email')}
          inputProps={{ maxLength: 101 }}
          InputLabelProps={{ shrink: !!value }}
          disabled={disabled}
          autoComplete="off"
          value={value}
          {...rest}
          error={errors?.email ? true : false || (isDirty && errors?.email) ? true : false || isInValidEmail}
          onChange={e => {
            onChange(e);
          }}
          onBlur={e => {
            if (emailCheck(e?.target?.value)) {
              setIsInValidEmail(false);
              clearErrors(formInputs.email);
            } else {
              setIsInValidEmail(true);
              setError(formInputs.email, {
                type: 'invalidEmail',
                message: 'Invalid email address',
              });
            }
          }}
          helperText={
            (isInValidEmail || errors?.email || (isDirty && errors?.email)) &&
            t('notification.clientContact.emailInvalid')
          }
        />
      )}
    />
  );
};
