import { IOperationContainer } from '@AMIEWEB/Placement/PlacementDetails/PlacementTabPanel/PlacementSummaryTab/GroupedRequirements/types';
import { ISkillsetRequirement } from 'app/models/Placement/PlacementSummary';
import { Concatenate } from 'utils/string/string';
import { formatCertificates } from './sagaHelper';

interface ISkillResponse {
  isPrimary: boolean;
  disciplineId: number;
  discipline: string;
  disciplineAbbr: string;
  specialtyId: number;
  specialty: string;
  specialtyAbbr: string;
  subSpecialtyId: number;
  subSpecialty: string;
}
export class SkillRequirementMapper {
  #containers: IOperationContainer;

  #containerRegistry: { primary: any; [key: string]: any };

  /**
   * Code: To generate the containers from linear structure response.
   */
  constructor(operation, skillsets, primaryRequirements, lookupCert) {
    this.#containers = this.#deriveContainersLinearly(operation, skillsets, primaryRequirements, lookupCert);
  }

  getRequirementsData = () => ({
    containers: this.#containers,
    containerRegistry: this.#containerRegistry,
  });

  #deriveContainersLinearly = (operation, skillsets, primaryRequirements, lookupCert): IOperationContainer => {
    this.#registerSkill('primary', primaryRequirements);
    const rootNode = {
      operation,
      label: null,
      key: 'root',
      counts: skillsets.reduce(
        (result, { excludeFromFilter, requirementCounts }) => {
          if (!excludeFromFilter) {
            result.aggregate += requirementCounts.aggregate;
            result.passed += requirementCounts.passed;
            result.failed += requirementCounts.failed;
            result.risks += requirementCounts.risks;
          }
          return result;
        },
        { aggregate: 0, passed: 0, failed: 0, risks: 0 },
      ),
      isPrimary: true,
      nodes: skillsets.map(
        ({
          id,
          disciplineId,
          discipline,
          disciplineAbbr,
          specialtyId,
          specialty,
          specialtyAbbr,
          subSpecialtyId,
          subSpecialty,
          isPrimary,
          excludeFromFilter,
          requirementCounts = { aggregate: 0, passed: 0, failed: 0, risks: 0 },
          ...rest
        }) => {
          const label = Concatenate([disciplineAbbr, specialty, subSpecialty], ' - ');
          const containerRegistryKey = Concatenate([disciplineId, specialtyId, subSpecialtyId, id], '_');
          const key = `root_${containerRegistryKey}`;
          const item = {
            key,
            containerRegistryKey,
            label,
            isPrimary,
            excludeFromFilter,
            counts: requirementCounts,
          };
          // Format certifications directly in the rest object
          rest.certifications = formatCertificates({
            certifications: rest.certifications,
            lookupCert,
          });

          this.#registerSkill(containerRegistryKey, { ...(rest as unknown as ISkillsetRequirement) });
          return item;
        },
      ),
    };

    return rootNode;
  };

  #recursiveContainers = (operation, skillsets: (ISkillResponse & { [key: string]: any }[]) | undefined) => {};

  /** Code: To avoid duplicate skills in the containerRegistry */
  #registerSkill = (key: string, data: ISkillsetRequirement) => {
    if (!this.#containerRegistry) this.#containerRegistry = {} as any;

    if (key in this.#containerRegistry) return;

    this.#containerRegistry[key] = { ...data };
  };
}
