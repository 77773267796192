import { TextField, Theme, InputAdornment, Popper, FormControl, InputLabel } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { PopperPlacementType } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { addMonths, addYears, isAfter, isBefore, isSameDay, isSameMonth, isWithinInterval, max, min } from 'date-fns';
import * as React from 'react';
import Menu from './components/Menu';
import { defaultRanges } from './defaults';
import { DateRange, DefinedRange, EDateRangePickTabbingIDs, NavigationAction } from './types';
import { formatJavascriptDate, parseOptionalDate } from './utils';
import EditIcon from '@mui/icons-material/Edit';
import { colors } from 'styles/styleVariables';
import { useTranslation } from 'react-i18next';

type Marker = symbol;

const useStyles = makeStyles<{
  variant: string;
  size: string;
  clear: boolean;
  useMaxWidth: boolean;
  maxWidth: number;
  single: boolean;
  helperBackgroundColor: string;
}>()((theme: Theme, { variant, size, clear, useMaxWidth, maxWidth, single, helperBackgroundColor }) => ({
  label: {
    fontSize: '12px !important',
    color: 'rgba(0, 0, 0, 0.37)',
    '&.Mui-focused': {
      color: 'rgba(0, 0, 0, 0.37)',
    },
    '&[class*="MuiInputLabel-outlined"]': {
      marginTop: '1px',
    },
    '&.Mui-error': {
      color: '#D90000',
    },
  },
  labelSecondary: {
    fontSize: '12px !important',
    '&[class*="MuiInputLabel-outlined"]': {
      marginTop: '1px',
    },
    '&[class*="MuiInputLabel-outlined"][class*="MuiInputLabel-shrink"]': {
      marginTop: '2px',
      fontSize: '14px !important',
    },
    '&[class*="MuiInputLabel-outlined"][class*="Mui-focused"]': {
      marginTop: '2px',
      color: '#006FB9',
    },
  },
  labelOutlined: {
    transform: 'translate(14px, 13px) scale(1)',
  },
  inputIcon: {
    marginBottom: variant === 'outlined' || size === 'inline' ? '0px' : '16px',
    height: '20px',
    width: '20px',
    // color: '#888888',
    marginRight: '-10px',
  },
  iconColor: {
    '.Mui-focused &': {
      color: '#006FB9',
    },
  },
  iconError: {
    color: `#D90000 !important`,
  },
  input: {
    backgroundColor: clear === true ? 'transparent !important' : variant === 'outlined' ? '#FFF' : undefined,
    minWidth: single ? '110px' : '210px',
    maxWidth: useMaxWidth ? (maxWidth ? `${maxWidth}px` : single ? '110px' : '200px') : undefined,
    '& .MuiFormHelperText-root.Mui-error': {
      backgroundColor: helperBackgroundColor ? helperBackgroundColor : undefined,
      margin: 0,
      paddingLeft: 10,
      paddingTop: 3,
      color: '#D90000',
    },
    '& .MuiInputBase-root.Mui-error': {
      color: '#D90000',
    },
    '&:hover, &:focus': {
      backgroundColor: clear === true ? 'transparent !important' : undefined,
    },
  },
  fullWidth: {
    width: '100%',
  },
  error: {
    marginTop: '20px',
  },
  popper: {
    zIndex: 9999,
  },
  smallTextbox: {
    maxHeight: '25px',
    minHeight: '25px',
    width: '100%',
    minWidth: single ? '110px' : '200px',
    maxWidth: useMaxWidth ? (single ? '110px' : '200px') : undefined,
    padding: '0px',
    fontSize: '14px',
    '& > input': {
      paddingLeft: '6px',
      paddingTop: variant === 'standard' && size === 'inline' ? '8px' : undefined,
    },
    marginTop: variant === 'standard' ? '0px !important' : undefined,
    backgroundColor: clear === true ? 'transparent !important' : undefined,
  },
  smallLabel: {
    padding: '0px',
    transform: 'translate(7px, 7px) scale(1) !important',
    fontSize: '12px !important',
  },
  smallIcon: {
    marginRight: '0px',
    height: '15px',
    width: '15px',
  },
}));

export const MARKERS: { [key: string]: Marker } = {
  FIRST_MONTH: Symbol('firstMonth'),
  SECOND_MONTH: Symbol('secondMonth'),
};

const getValidatedMonths = (range: DateRange, minDate: Date, maxDate: Date) => {
  const { startDate, endDate } = range;
  if (startDate && endDate) {
    const newStart = max([new Date(startDate), minDate]);
    const newEnd = min([new Date(endDate), maxDate]);

    return [newStart, isSameMonth(newStart, newEnd) ? addMonths(newStart, 1) : newEnd];
  } else {
    return [startDate ? new Date(startDate) : undefined, endDate ? new Date(endDate) : undefined];
  }
};

interface DateRangePickerProps {
  open?: boolean;
  onOpen?: (open) => void;
  initialDateRange?: DateRange;
  definedRanges?: DefinedRange[];
  minDate?: Date | string;
  maxDate?: Date | string;
  onChange?: (dateRange: DateRange) => void;
  onApply?: (dateRange: DateRange) => void;
  onCancel?: () => void;
  onInlineEdit?: (dateValue) => void;
  onError?: (value: boolean) => void;
  onBlurText?: (dateValue) => void;
  /** String value to override in the textfield */
  value?: string | { startDate: Date | string; endDate: Date | string };
  quickSelect?: boolean;
  actionBar?: boolean;
  variant?: 'filled' | 'standard' | 'outlined';
  secondaryLabel?: string;
  helperBackgroundColor?: string;
  single?: boolean;
  disableEdit?: boolean;
  fullWidth?: boolean;
  className?: any;
  placeholder?: string;
  showPlaceholder?: boolean;
  size?: 'small' | 'inline';
  textFieldSize?: 'medium' | 'small';
  disablePortal?: boolean;
  useMaxWidth?: boolean;
  maxWidth?: number;
  clickAway?: boolean;
  applyOnClickAway?: boolean;
  keepClickAwayVal?: boolean;
  popper?: boolean;
  wrapperClass?: string;
  elevation?: number;
  applyButtonLabel?: string;
  error?: boolean;
  helperText?: string;
  helperTextDisabled?: boolean;
  isDisabled?: boolean;
  enableEdit?: boolean;
  readOnly?: boolean;
  clear?: boolean;
  required?: boolean;
  /*
  set to true if you plan to use this for both
  single date picker and range picker at the same time
  */
  dualValidation?: boolean;
  showIcon?: boolean;
  isDateIconReq?: boolean;
  trailingIconShow?: boolean;
  labelClass?: string;
  disableUnderline?: boolean;
  defaultFirstMonth?: Date;
  defaultSecondMonth?: Date;
  popperPlacement?: PopperPlacementType;
  fallbackPlacements?: PopperPlacementType[];
  shrink?: boolean;
  applyOnEnter?: boolean;
}

const extractTextValue = propValue => {
  return instanceOfMinimalDateRange(propValue)
    ? propValue.startDate
      ? `${formatJavascriptDate(propValue.startDate)} - ${formatJavascriptDate(propValue.endDate)}`
      : ''
    : typeof propValue === 'string' && propValue.includes('T')
    ? formatJavascriptDate(new Date(propValue))
    : propValue || '';
};

const DateRangePickerImpl: React.FunctionComponent<DateRangePickerProps> = props => {
  const today = new Date();
  const {
    open,
    onOpen,
    onChange,
    onApply,
    onCancel,
    onInlineEdit,
    onError,
    onBlurText,
    initialDateRange,
    minDate,
    maxDate,
    value,
    required,
    definedRanges = defaultRanges,
    quickSelect = true,
    actionBar = true,
    variant = 'filled',
    secondaryLabel,
    helperBackgroundColor = '#fff', //#F5F5F5 (for html background color)
    single = false,
    disableEdit = false,
    fullWidth,
    className,
    placeholder,
    showPlaceholder = false,
    size = 'standard',
    textFieldSize,
    disablePortal,
    useMaxWidth = true,
    maxWidth,
    clickAway = false,
    keepClickAwayVal = false,
    popper = true,
    wrapperClass,
    elevation = 5,
    applyButtonLabel = 'Apply',
    error,
    readOnly = false,
    applyOnClickAway = false,
    applyOnEnter = false,
    helperText,
    helperTextDisabled = false,
    isDisabled = false,
    dualValidation,
    enableEdit,
    isDateIconReq = false,
    clear,
    trailingIconShow = false,
    labelClass,
    disableUnderline = false,
    shrink = false,
    defaultFirstMonth,
    defaultSecondMonth,
    popperPlacement = 'bottom-start',
    fallbackPlacements = ['bottom-end', 'bottom', 'bottom-start', 'top-start', 'top', 'top-end', 'left', 'right'],
  } = props;
  const { t } = useTranslation();
  const { classes, cx } = useStyles({ variant, helperBackgroundColor, single, useMaxWidth, maxWidth, size, clear });
  const textFieldRef = React.useRef<HTMLInputElement | HTMLTextAreaElement>();
  const [_placeholder] = React.useState<string>(
    placeholder ? placeholder : single ? 'MM/DD/YYYY' : 'MM/DD/YYYY - MM/DD/YYYY',
  );
  const defaultHelperText = single ? t('invalidDate') : t('invalidDateRange');

  const [val, setVal] = React.useState<string | undefined>(undefined);
  const [focused, setFocused] = React.useState<boolean>(false);
  const [_open, __setOpen] = React.useState<boolean>(false);
  const _openRef = React.useRef(_open);
  const _setOpen = data => {
    _openRef.current = data;
    __setOpen(data);
  };
  const [_error, _setError] = React.useState<boolean>(false);
  const [_helperText, _setHelperText] = React.useState<string | undefined>(defaultHelperText);

  const minDateValid = parseOptionalDate(minDate, addYears(today, -10));
  const maxDateValid = parseOptionalDate(maxDate, addYears(today, 10));
  const [intialFirstMonth, initialSecondMonth] = getValidatedMonths(initialDateRange || {}, minDateValid, maxDateValid);

  const [dateRange, setDateRange] = React.useState<DateRange>({ ...initialDateRange, single });
  const [hoverDay, setHoverDay] = React.useState<Date>();
  const [firstMonth, setFirstMonth] = React.useState<Date>(defaultFirstMonth || intialFirstMonth || today);
  const [secondMonth, setSecondMonth] = React.useState<Date>(
    defaultSecondMonth || initialSecondMonth || addMonths(firstMonth, 1),
  );
  const [valid, setValid] = React.useState<boolean>(false);
  const [trailingIconVisible, setTrailingIconVisible] = React.useState<boolean>(trailingIconShow);
  const uniqueIdRef = React.useRef(Math.random());

  const { startDate, endDate } = dateRange;

  //Tabbing
  const [tabbingIndex, _setTabbingIndex] = React.useState<number>(1);
  const tabbingIndexRef = React.useRef(tabbingIndex);
  const setTabbingIndex = data => {
    tabbingIndexRef.current = data;
    _setTabbingIndex(data);
  };

  const pageKeyDownHandler = event => {
    if (event.keyCode === 9 && _openRef.current) {
      switch (tabbingIndexRef.current) {
        case 1:
          const applyButton = document.getElementById(`${EDateRangePickTabbingIDs.applyButton}-${uniqueIdRef.current}`);
          if (applyButton && Object.values(applyButton.classList).indexOf('Mui-disabled') === -1) {
            applyButton.focus();
            setTabbingIndex(2);
            break;
          }
        // eslint-disable-next-line no-fallthrough
        case 2:
          document.getElementById(`${EDateRangePickTabbingIDs.cancelButton}-${uniqueIdRef.current}`)?.focus();
          setTabbingIndex(3);
          break;
        case 3:
          document.getElementById(`${EDateRangePickTabbingIDs.textfield}-${uniqueIdRef.current}`)?.focus();
          setTabbingIndex(1);
          break;
        default:
          break;
      }
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const closeOnTab = event => {
    if (event.keyCode === 9 && _openRef.current) {
      _setOpen(false);
      document.getElementById(`${EDateRangePickTabbingIDs.textfield}-${uniqueIdRef.current}`)?.blur?.();
    }
  };

  React.useEffect(() => {
    if (actionBar) document.addEventListener?.('keydown', pageKeyDownHandler);
    else document.addEventListener?.('keydown', closeOnTab);
    return () => {
      if (actionBar) document.removeEventListener?.('keydown', pageKeyDownHandler);
      else document.removeEventListener?.('keydown', closeOnTab);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!trailingIconShow) {
      setTrailingIconVisible(focused || _open || (!open && _error));
    }
  }, [focused, _open, _error, open, trailingIconShow]);

  React.useEffect(() => {
    _setError(error ?? false);
    setValid(!(error ?? false));
  }, [error]);

  React.useEffect(() => {
    if (helperText) _setHelperText(helperText);
    else _setHelperText(defaultHelperText);
  }, [helperText]);

  React.useEffect(() => {
    return () => {
      window.globalThis.keyEvent = undefined;
      window.globalThis.globalSearchCandidateApply = undefined;
      onOpen?.(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setVal(extractTextValue(value));
  }, [value]);

  React.useEffect(() => {
    setFirstMonth(defaultFirstMonth || initialDateRange?.startDate || today);
    setSecondMonth(defaultSecondMonth || initialDateRange?.endDate || addMonths(firstMonth, 1));
  }, [defaultFirstMonth, defaultSecondMonth]);

  React.useEffect(() => {
    if (!_open && initialDateRange?.startDate && initialDateRange.endDate) {
      let format: string;
      if (!single) {
        format = `${formatJavascriptDate(initialDateRange.startDate)} - ${formatJavascriptDate(
          initialDateRange.endDate,
        )}`;
        setVal(format);
      } else {
        format = `${formatJavascriptDate(initialDateRange.startDate)}`;
        setFirstMonth(new Date(initialDateRange.startDate));
        setSecondMonth(new Date(initialDateRange.endDate));
        if (initialDateRange.startDate && initialDateRange.endDate) setVal(format);
      }
      setDateRange({
        startDate: new Date(initialDateRange.startDate),
        endDate: new Date(initialDateRange.endDate),
        label: initialDateRange.label || format,
        useLabelAsValue: initialDateRange.useLabelAsValue ?? false,
        single,
      });
      if (initialDateRange.useLabelAsValue) setVal(initialDateRange.label || format);
      if (showPlaceholder) setVal('');
    } else if (!_open && !initialDateRange) {
      setVal('');
      setDateRange({
        startDate: undefined,
        endDate: undefined,
        label: undefined,
        single,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDateRange, _open]);

  React.useEffect(() => {
    _setOpen(open || false);
    if (open) {
      setFocused(true);
      textFieldRef?.current?.focus?.();
    }
  }, [open]);

  React.useEffect(() => {
    onOpen?.(_open);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_open]);

  // handlers
  const setFirstMonthValidated = (date: Date) => {
    if (single || (!single && isBefore(date, secondMonth))) {
      setFirstMonth(date);
    }
  };

  const setSecondMonthValidated = (date: Date) => {
    if (isAfter(date, firstMonth)) {
      setSecondMonth(date);
    }
  };

  /**
   * Use for quick select
   * @param range set date range
   * @param setValue set the value that displays in the textfield
   */
  const setDateRangeValidated = (range: DefinedRange) => {
    const _range = { ...range };
    setValid(isValidInputRange(_range).isValid);
    let { startDate: newStart, endDate: newEnd } = _range;
    if (!dualValidation && (!newStart || !newEnd)) return;
    if (_range.useLabelAsValue) {
      setVal(_range.label);
      _setError(!isValidInputRange(_range).isValid);
      onError?.(!isValidInputRange(_range).isValid);
    } else
      setValForOnTextChange(
        `${formatJavascriptDate(_range.startDate)} - ${formatJavascriptDate(_range.endDate)}`,
        0,
        false,
      );
    if (dualValidation) {
      _range.startDate = newStart = max([newStart, minDateValid]);
      _range.startDate.setHours(0, 0, 0, 0);
      setFirstMonth(newStart);
      if (range.endDate) {
        _range.endDate = newEnd = min([newEnd, maxDateValid]);
        _range.endDate.setHours(23, 59, 59, 999);
        _range.single = single;
        setSecondMonth(isSameMonth(newStart, newEnd) ? addMonths(newStart, 1) : newEnd);
      } else {
        setSecondMonth(addMonths(newStart, 1));
      }
      setDateRange(_range);

      onChange?.(_range);
    } else {
      _range.startDate = newStart = max([newStart, minDateValid]);
      _range.startDate.setHours(0, 0, 0, 0);
      _range.endDate = newEnd = min([newEnd, maxDateValid]);
      _range.endDate.setHours(23, 59, 59, 999);
      _range.single = single;
      setDateRange(_range);
      setFirstMonth(newStart);
      setSecondMonth(isSameMonth(newStart, newEnd) ? addMonths(newStart, 1) : newEnd);
      onChange?.(_range);
    }
    // if action bar is hidden, call apply function after date range selection
    !actionBar && _onApply();
  };

  const onDayClick = (day: Date) => {
    if (startDate && !endDate && !isBefore(day, startDate)) {
      const newRange = { startDate, endDate: day, single };
      // onChange?.(newRange);
      setDateRange(newRange);
      setValForOnTextChange(`${formatJavascriptDate(startDate)} - ${formatJavascriptDate(day)}`, 0);
    } else if (single) {
      setDateRange({ startDate: day, endDate: day, single });
      setValForOnTextChange(`${formatJavascriptDate(day)}`, 0);
    } else {
      setDateRange({ startDate: day, endDate: undefined, single });
      if (dualValidation) {
        setValForOnTextChange(`${formatJavascriptDate(day)}`, 0);
      } else setValid(false);
    }
    setHoverDay(day);
  };

  const onMonthNavigate = (marker: Marker, action: NavigationAction) => {
    if (marker === MARKERS.FIRST_MONTH) {
      const firstNew = addMonths(firstMonth, action);
      if (single || (!single && isBefore(firstNew, secondMonth))) setFirstMonth(firstNew);
    } else {
      const secondNew = addMonths(secondMonth, action);
      if (isBefore(firstMonth, secondNew)) setSecondMonth(secondNew);
    }
  };

  const onDayHover = (date: Date) => {
    if (!single && startDate && !endDate) {
      if (!hoverDay || !isSameDay(date, hoverDay)) {
        setHoverDay(date);
      }
    }
  };

  /**
   * Format textfield input and set and values / errors / calendar selections
   * @param event HTML inpu
   */
  const onTextChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const input = event.target;
    const caret = setValForOnTextChange(event.target.value, event.target.selectionStart ?? 0);
    window.requestAnimationFrame(() => {
      input.selectionStart = caret;
      input.selectionEnd = caret;
    });
    onInlineEdit?.(event.target.value);
  };

  const setValForOnTextChange = (input: string, caret: number, doesSetDateRangeValidated: boolean = true): number => {
    let inputValue = input;
    // move cursor to proper position within textfied to account for slashes and spaces
    // works for new text and deleted text
    if (window.globalThis.keyEvent === 8) {
      // backspace
      if (
        caret === 2 ||
        caret === 3 ||
        caret === 5 ||
        caret === 6 ||
        caret === 12 ||
        caret === 13 ||
        caret === 15 ||
        caret === 16 ||
        caret === 18 ||
        caret === 19
      ) {
        if (caret === 12 || caret === 13) {
          inputValue = inputValue.slice(0, caret - 3) + inputValue.slice(caret - 2, inputValue.length);
          caret -= 3;
        } else {
          inputValue = inputValue.slice(0, caret - 1) + inputValue.slice(caret, inputValue.length);
          caret -= 1;
        }
      }
    } else if (caret === 3 || caret === 6 || caret === 16 || caret === 19) {
      caret += 1;
    } else if (caret === 10 || caret === 11) {
      caret += 3;
    }

    // set up array of integers and prepare to insert in formatted string
    const onlyNums: string = inputValue.replace(/[^0-9]/g, '');
    const splitVals = onlyNums.split('');
    // formatted array of all underscores with numbers injected
    const arr = Array(single ? 8 : 16)
      .fill('_')
      .map((item, i) => (i < splitVals.length ? splitVals[i] : item));

    if (onlyNums !== '') {
      const format = !single
        ? `${arr[0]}${arr[1]}/${arr[2]}${arr[3]}/${arr[4]}${arr[5]}${arr[6]}${arr[7]} - ${arr[8]}${arr[9]}/${arr[10]}${arr[11]}/${arr[12]}${arr[13]}${arr[14]}${arr[15]}`
        : `${arr[0]}${arr[1]}/${arr[2]}${arr[3]}/${arr[4]}${arr[5]}${arr[6]}${arr[7]}`;
      setVal(format);

      // move cursor to index of last numeric value
      const regex = new RegExp(/[0-9]{1,1}/g);
      let found = false;
      let lastIndex = 0;
      do {
        found = regex.test(format);
        if (regex.lastIndex > lastIndex) lastIndex = regex.lastIndex;
      } while (found);
      if (caret > lastIndex) caret = lastIndex;

      const validateDates = isValidInputRange(format);
      setValid(validateDates.isValid);
      if (validateDates.isValid) {
        // if valid, then set textfield to formatted value and select dates in calendar
        resetHelperText();
        if (doesSetDateRangeValidated) {
          setDateRangeValidated({
            startDate: validateDates.dates.startDate!,
            endDate: validateDates.dates.endDate!,
            label: format,
            useLabelAsValue: true,
            single,
          });
        }
        _setError(false);
        onError?.(false);
      } else {
        // invalid date, reset date range and show error if all numbers are entered
        setDateRange({ startDate: undefined, endDate: undefined, single });
        _setError((!single && onlyNums.length === 16) || (single && onlyNums.length === 8));
        onError?.((!single && onlyNums.length === 16) || (single && onlyNums.length === 8));
      }
    } else {
      setVal('');
      setDateRange({ startDate: undefined, endDate: undefined, single });
      onChange?.({ startDate: undefined, endDate: undefined });
      _setError(false);
      onError?.(false);
      setValid(true);
      resetHelperText();
    }

    return caret;
  };

  const resetHelperText = () => {
    if (helperText) {
      _setHelperText(helperText);
    } else {
      _setHelperText(defaultHelperText);
    }
  };

  /**
   * Capture key entered to be able to perform proper actions if it's backspace
   */
  const keyDownCapture = event => {
    window.globalThis.keyEvent = event.keyCode;
    switch (event.keyCode) {
      case 8: // backspace
        break;
      case 9: // tab
        _onCancel();
        break;
      case 37: // left arrow
      case 39: // right arrow
        break;
      case 48: // numbers
      case 49:
      case 50:
      case 51:
      case 52:
      case 53:
      case 54:
      case 55:
      case 56:
      case 57:
      case 96:
      case 97:
      case 98:
      case 99:
      case 100:
      case 101:
      case 102:
      case 103:
      case 104:
      case 105:
        // prevent key strokes beyond max length
        if (
          (single &&
            textFieldRef?.current?.value &&
            textFieldRef?.current?.value.replace(/[^0-9]/g, '').length === 8) ||
          (!single && textFieldRef?.current?.value && textFieldRef?.current?.value.replace(/[^0-9]/g, '').length === 16)
        ) {
          event.preventDefault();
          event.stopPropagation();
        }
        break;
      case 65:
        if (event.metaKey || event.ctrlKey) break;
      // eslint-disable-next-line no-fallthrough
      default:
        event.preventDefault();
        event.stopPropagation();
        break;
    }
  };

  const onFocus = () => {
    if (!isDisabled && !readOnly) {
      _setOpen(true);
      setFocused(true);
      if (isDateIconReq) toggleDateIcon();
    }
  };

  const onBlur = e => {
    // if (!dateRange.useLabelAsValue) setError(!!val && !isValidInputRange(val).isValid);
    setFocused(false);
    onBlurText?.(e.target.value);
  };

  const toggleDateIcon = () => {
    var x = document.getElementById('cal-Icon-Id');
    if (x) {
      x.style.visibility = 'visible';
    }
  };

  const _onApply = React.useCallback(() => {
    window.globalThis.globalSearchCandidateApply = true;
    onApply?.(dateRange);
    setFocused(false);
    _setOpen(false);
    setValid(true);
    setTimeout(() => {
      window.globalThis.globalSearchCandidateApply = false;
    }, 500);
  }, [dateRange, onApply]);

  const _onCancel = () => {
    if (!_open) return;
    if (window.globalThis.globalSearchCandidateApply) return;
    setFocused(false);
    _setOpen(false);
    if (!keepClickAwayVal) {
      if (!initialDateRange) setVal(extractTextValue(value));
      else if (initialDateRange.startDate && initialDateRange.endDate) {
        setValForOnTextChange(
          `${formatJavascriptDate(initialDateRange.startDate)} - ${formatJavascriptDate(initialDateRange.endDate)}`,
          0,
          false,
        );
        setDateRange({ ...initialDateRange, single });
      } else setVal('');
    }
    _setError(error ?? false);
    onError?.(error ?? false);
    setValid(true);
    onCancel?.();
  };

  // helpers
  const isValidInputRange = (input: string | DateRange | undefined): { isValid: boolean; dates: DateRange } => {
    const dateValue = date => (!date || date.toString() === 'Invalid Date' ? undefined : date);
    let startDate: Date | undefined = undefined;
    let endDate: Date | undefined = undefined;
    let valid: boolean = false;

    if (typeof input === 'string') {
      const dates = input?.split(' - ');
      if (dates.length >= 2) {
        startDate = new Date(dates[0]);
        endDate = new Date(dates[1]);
        endDate = endDate.toDateString() === 'Invalid Date' ? undefined : endDate;
      } else if (dates.length === 1) {
        startDate = new Date(dates[0]);
      }
    } else if (input !== undefined) {
      startDate = (input as DateRange).startDate;
      endDate = (input as DateRange).endDate;
    }
    if (single && startDate && dateValue(startDate) && startDate >= minDateValid && startDate <= maxDateValid) {
      endDate = startDate;
      valid = true;
    } else if (
      dualValidation &&
      !endDate &&
      startDate &&
      dateValue(startDate) &&
      startDate >= minDateValid &&
      startDate <= maxDateValid
    ) {
      valid = true;
    } else if (
      startDate &&
      endDate &&
      dateValue(startDate) &&
      dateValue(endDate) &&
      startDate <= endDate &&
      startDate >= minDateValid &&
      endDate >= minDateValid &&
      startDate <= maxDateValid &&
      endDate <= maxDateValid
    )
      valid = true;
    return { isValid: valid, dates: { startDate: dateValue(startDate), endDate: dateValue(endDate) } };
  };

  const inHoverRange = (day: Date) => {
    return (startDate &&
      !endDate &&
      hoverDay &&
      isAfter(hoverDay, startDate) &&
      isWithinInterval(day, {
        start: startDate,
        end: hoverDay,
      })) as boolean;
  };

  const helpers = {
    inHoverRange,
  };

  const handlers = {
    onDayClick,
    onDayHover,
    onMonthNavigate,
    _onApply,
    _onCancel,
  };

  const handleAppylOnClickAway = () => {
    if (!_open) return;
    setFocused(false);
    _setOpen(false);
    if (startDate && endDate) _onApply();
  };

  const handleKeyDown = React.useCallback(
    event => {
      /**
       * Code: click on Enter key invokes submission
       * if open && applyOnEnter
       */
      if (_open && applyOnEnter && event.key === 'Enter' && startDate && endDate) {
        _onApply();
      }
      /** Add future key down features below */
    },
    [_open, applyOnEnter, startDate, endDate, _onApply],
  );

  React.useEffect(() => {
    document.body.addEventListener('keydown', handleKeyDown);

    return () => {
      document.body.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <ClickAwayListener onClickAway={clickAway ? _onCancel : applyOnClickAway ? handleAppylOnClickAway : () => {}}>
      <div className={wrapperClass}>
        <FormControl variant={variant} fullWidth={fullWidth}>
          {secondaryLabel && (
            <InputLabel className={cx(classes.label, classes.labelOutlined)}>
              {!val && focused && _placeholder}
            </InputLabel>
          )}
          <TextField
            id={`${EDateRangePickTabbingIDs.textfield}-${uniqueIdRef.current}`}
            inputRef={textFieldRef}
            className={cx(classes.input, {
              [classes.fullWidth]: fullWidth,
              [classes.smallTextbox]: size === 'inline',
            })}
            variant={variant}
            required={required}
            label={
              variant === 'outlined' && secondaryLabel
                ? secondaryLabel
                : ((focused && val) || val) && variant !== 'filled'
                ? undefined
                : _placeholder
            }
            focused={focused}
            defaultValue={undefined}
            value={val}
            onFocus={onFocus}
            onBlur={onBlur}
            error={_error}
            disabled={isDisabled}
            onKeyDownCapture={keyDownCapture}
            onChange={!disableEdit ? onTextChange : () => {}}
            size={textFieldSize ?? 'small'}
            InputLabelProps={{
              shrink:
                variant === 'outlined' && secondaryLabel
                  ? focused || (val && val !== '')
                    ? true
                    : false
                  : variant === 'outlined'
                  ? false
                  : shrink
                  ? shrink
                  : !!val || focused,
              className: labelClass
                ? labelClass
                : cx(classes.label, {
                    [classes.smallLabel]: size === 'inline',
                    [classes.labelSecondary]: !!secondaryLabel,
                  }),
            }}
            InputProps={{
              disableUnderline: disableUnderline,
              autoComplete: 'off',
              endAdornment: (
                <InputAdornment position="start" onClick={onFocus}>
                  {enableEdit ? (
                    <EditIcon
                      className={cx(classes.inputIcon, {
                        [classes.iconColor]: focused,
                        [classes.iconError]: _error,
                        [classes.smallIcon]: size === 'inline',
                      })}
                    />
                  ) : (
                    trailingIconVisible && (
                      <CalendarTodayIcon
                        id="cal-Icon-Id"
                        className={cx(classes.inputIcon, {
                          [classes.iconColor]: focused,
                          [classes.iconError]: _error,
                          [classes.smallIcon]: size === 'inline',
                        })}
                        sx={{
                          '&:hover': {
                            color: !disableEdit && readOnly ? colors.amnDarkGrey : colors.amnLightBlue,
                            cursor: !_open ? 'pointer' : 'default',
                          },
                        }}
                      />
                    )
                  )}
                </InputAdornment>
              ),
              readOnly: readOnly,
              style: {
                pointerEvents: !disableEdit && readOnly ? 'none' : 'auto',
                background: (!disableEdit && readOnly) || isDisabled ? 'rgba(0,0,0, 0.04)' : '',
              },
              classes: {
                root: className
                  ? className
                  : cx(classes.input, {
                      [classes.smallTextbox]: size === 'inline',
                    }),
              },
            }}
            helperText={_error && !helperTextDisabled ? _helperText : undefined}
          />
        </FormControl>
        {popper && (
          <Popper
            disablePortal={disablePortal}
            open={_open}
            anchorEl={textFieldRef.current}
            placement={popperPlacement}
            modifiers={[
              {
                name: 'flip',
                options: {
                  fallbackPlacements: fallbackPlacements,
                },
              },
            ]}
            className={cx(classes.popper, {
              [classes.error]: _error,
            })}
          >
            <Menu
              dateRange={dateRange}
              minDate={minDateValid}
              maxDate={maxDateValid}
              ranges={definedRanges}
              firstMonth={firstMonth}
              secondMonth={secondMonth}
              quickSelect={quickSelect}
              setFirstMonth={setFirstMonthValidated}
              setSecondMonth={setSecondMonthValidated}
              setDateRange={setDateRangeValidated}
              helpers={helpers}
              handlers={handlers}
              actionBar={actionBar}
              single={single}
              valid={valid}
              elevation={elevation}
              applyButtonLabel={applyButtonLabel}
              uniqueId={uniqueIdRef.current}
            />
          </Popper>
        )}
        {!popper && (
          <Menu
            dateRange={dateRange}
            minDate={minDateValid}
            maxDate={maxDateValid}
            ranges={definedRanges}
            firstMonth={firstMonth}
            secondMonth={secondMonth}
            quickSelect={quickSelect}
            setFirstMonth={setFirstMonthValidated}
            setSecondMonth={setSecondMonthValidated}
            setDateRange={setDateRangeValidated}
            helpers={helpers}
            handlers={handlers}
            actionBar={actionBar}
            single={single}
            valid={valid}
            elevation={elevation}
            applyButtonLabel={applyButtonLabel}
            uniqueId={uniqueIdRef.current}
          />
        )}
      </div>
    </ClickAwayListener>
  );
};

export type { DateRange, DefinedRange } from './types';
export const DateRangePicker = DateRangePickerImpl;

export function instanceOfDateRangePicker(object: any): object is DefinedRange {
  return (
    object &&
    typeof object === 'object' &&
    'startDate' in object &&
    'endDate' in object &&
    'label' in object &&
    !object.single
  );
}

export function instanceOfDateRangePickerSingle(object: any): object is DefinedRange {
  return (
    object &&
    typeof object === 'object' &&
    'startDate' in object &&
    'endDate' in object &&
    'label' in object &&
    object.single
  );
}

export function instanceOfMinimalDateRange(
  object: any,
): object is { startDate: Date | string; endDate: Date | string } {
  return object && typeof object === 'object' && 'startDate' in object && 'endDate' in object;
}
