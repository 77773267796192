import { ChannelType } from './../../components/Notification/Constants';
import baseAxiosInstance from 'utils/BaseApi';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import { ISaveTemplate, IUpdateTemplate } from 'app/models/Notification/Notification';
const templateBaseUrl = globalThis?.app?.env?.REACT_APP_TEMPLATE_SERVICE_BASE_URL;

/**
 * Fetch available category for templates.
 * @constructor
 * @param {any} channel - Payload for getting all template category.
 */
export async function GetAvailableCategory(channel: string) {
  try {
    const response = await baseAxiosInstance.get(`${templateBaseUrl}/Templates/list/category/${channel}`);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'GetAvailableCategory',
        area: 'src/app/services/NotificationServices/TemplateService.ts',
      },
    });
    return null;
  }
}

/**
 * Apply substitution data to template.
 * @constructor
 * @param {any} data - Payload for applying substitution to templates.
 */
export async function ApplyDataToTemplate(data: any) {
  const response = await baseAxiosInstance.post(`${templateBaseUrl}/Templates/previewTemplate`, data);
  return response.data;
}

/**
 * Save new template to container.
 * @constructor
 * @param {any} data - Payload for saving new template to container.
 */
export async function SaveTemplate(data: any) {
  const response = await baseAxiosInstance.post(`${templateBaseUrl}/Templates/createTemplate`, data);
  return response.data;
}

/**
 * Delete personal template.
 * @constructor
 * @param {string} templateId - Payload for deleting template by category.
 * @param {string} category - Payload for deleting template by category.
 */
export async function deletePersonalTemplate(templateId: string,category: string) {
  try {
    const response = await baseAxiosInstance.delete(`${templateBaseUrl}/Templates/${templateId}/${category}`);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'deleteTemplateByCategory',
        area: 'src/store/redux-store/notification/notification.saga.ts',
      },
    });
    return null;
  }
}

/**
 * Get template by channel.
 * @constructor
 * @param {ChannelType} channel - Payload for fetching template by channel.
 */
export async function GetTemplateByChannel(channel: ChannelType) {
  try {
    const response = await baseAxiosInstance.get(`${templateBaseUrl}/Templates/channel/${channel}`);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'GetTemplateByChannel',
        area: 'src/app/services/NotificationServices/TemplateService.ts',
      },
    });
    return null;
  }
}

/**
 * Get template by category.
 * @constructor
 * @param {string} category - Payload for fetching template by category.
 * @param {string} channel - Payload for fetching template by category.
 */
export async function GetTemplatesByCategory(category: string, channel: string) {
  try {
    const response = await baseAxiosInstance.post(`${templateBaseUrl}/Templates/search/category`, {
      category: category,
      channel: channel,
    });
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'GetTemplatesByCategory',
        area: 'src/app/services/NotificationServices/TemplateService.ts',
      },
    });
    return null;
  }
}

/**
 * Get template by Id.
 * @constructor
 * @param {string} id - Payload for fetching template by Id.
 */
export async function GetTemplatesById(id: string) {
  return await baseAxiosInstance.get(`${templateBaseUrl}/templates/id/${id}`);
}

/**
 * Show Template after adding all substitutions.
 * @constructor
 * @param {string} templateId - Payload for previewing template after adding all substitutions.
 * @param {any} substitution - Payload for previewing template after adding all substitutions.
 * @param {any} tableData - Payload for previewing template after adding all substitutions.
 */
export async function getTemplatePreview(templateId: string, substitution: any, tableData: any) {
  try {
    const response = await baseAxiosInstance.post(`${templateBaseUrl}/previewTemplate`, {
      templateId: templateId,
      substitutions: substitution,
      tableData: tableData,
    });
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getTemplatePreview',
        area: 'src/app/services/NotificationServices/TemplateService.ts',
      },
    });
    return null;
  }
}

/**
 * Fetch template by classification.
 * @constructor
 * @param {string} classification - Payload for fetching template by classification.
 */
export async function getTemplateByClassification(classification: string) {
  try {
    const response = await baseAxiosInstance.post(`${templateBaseUrl}/search/classification`, {
      classification: classification,
    });
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getTemplateByClassification',
        area: 'src/app/services/NotificationServices/TemplateService.ts',
      },
    });
    return null;
  }
}

/**
 * Fetch template by keyword.
 * @constructor
 * @param {string} keyword - Payload for fetching template by keyword.
 */
export async function getTemplateByKeyword(keyword: string) {
  try {
    const response = await baseAxiosInstance.post(`${templateBaseUrl}/search/keyword`, {
      keyword: keyword,
    });
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getTemplateByKeyword',
        area: 'src/app/services/NotificationServices/TemplateService.ts',
      },
    });
    return null;
  }
}

/**
 * Fetch all available template.
 * @constructor
 * @param {string} keyword - Payload for fetching all template.
 */
export async function getAllTemplate() {
  try {
    const response = await baseAxiosInstance.post(`${templateBaseUrl}/search/all`);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getAllTemplate',
        area: 'src/app/services/NotificationServices/TemplateService.ts',
      },
    });
    return null;
  }
}

export async function getTemplateByName(name: string) {
  try {
    const response = await baseAxiosInstance.get(`${templateBaseUrl}/Templates/title/${name}`);
    return response?.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getTemplateByName',
        area: 'src/app/services/NotificationServices/TemplateService.ts',
      },
    });
    return null;
  }
}

export const getSignaturePreferences = async (brandId?: number) => {
  try {
    const response = await baseAxiosInstance.get(`${templateBaseUrl}/Signatures?brand=${brandId}`);
    return response?.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getSignaturePreferences',
        area: 'src/app/services/NotificationServices/TemplateService.ts',
      },
    });
    return null;
  }
};

export async function getBaseTemplate(userType: number) {
  try {
    const response = await baseAxiosInstance.get(`${templateBaseUrl}/Templates/base-template/${userType}`);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getBaseTemplate',
        area: 'src/app/services/NotificationServices/TemplateService.ts',
      },
    });
    return null;
  }
}

export async function getFacilityTemplatesByCategory(category: string, userType: number, userId: number) {
  try {
    const response = await baseAxiosInstance.get(`${templateBaseUrl}/Templates/${category}/${userType}/${userId}`);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getFacilityTemplatesByCategory',
        area: 'src/app/services/NotificationServices/TemplateService.ts',
      },
    });
    return null;
  }
}

export async function saveFacilityEmailTemplate(data: ISaveTemplate) {
  try {
    const response = await baseAxiosInstance.post(`${templateBaseUrl}/Templates/createTemplate`, data);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'saveFacilityEmailTemplate',
        area: 'src/app/services/NotificationServices/TemplateService.ts',
      },
    });
    return null;
  }
}

export async function updateFacilityEmailTemplate(data: IUpdateTemplate) {
  try {
    const response = await baseAxiosInstance.put(`${templateBaseUrl}/Templates`, data);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'updateFacilityEmailTemplate',
        area: 'src/app/services/NotificationServices/TemplateService.ts',
      },
    });
    return null;
  }
}
