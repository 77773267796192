import { getTaskEntityValue } from 'app/components/Notification/Tasks/Common/FormatData';
import { taskDetailsActions, taskDetailsReducer, taskDetailsSliceKey } from 'app/components/Tasks/store/Tasks.redux';
import { taskDetailsSaga } from 'app/components/Tasks/store/Tasks.saga';
import {
  SelectedContainer,
  selectPlacementBrandId,
  selectedUpdatedId,
  selectTaskUpdatedData,
  selectEntityId,
} from 'app/components/Tasks/store/Tasks.selectors';
import {
  NotificationBrand,
  NotificationSender,
  StatusUpdateModalVersion,
  Task,
  TaskEntity,
  TaskNotificationType,
} from 'app/models/Tasks/Tasks';
import { getCandidateById } from 'app/services/CandidateServices/CandidateServices';
import { getFacilityDetails } from 'app/services/FacilityServices/FacilityServices';
import { getOrderDetailsById } from 'app/services/OrderServices/OrderServices';
import { getPlacementHeader } from 'app/services/PlacementServices/PlacementServices';
import moment from 'moment-timezone';
import { selectUser } from 'oidc/user.selectors';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { Concatenate } from 'utils/string/string';
import { isPositiveNumber } from 'app/helpers/componentHelpers';

export const useEditTaskNotification = () => {
  useInjectSaga({ key: taskDetailsSliceKey, saga: taskDetailsSaga });
  useInjectReducer({ key: taskDetailsSliceKey, reducer: taskDetailsReducer });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const updatedId = useSelector(selectedUpdatedId);
  const updatedTaskData = useSelector(selectTaskUpdatedData);
  const currentContainer = useSelector(SelectedContainer);
  const globalEntityId = useSelector(selectEntityId);
  const userInfo = useSelector(selectUser);
  const brandId = useSelector(selectPlacementBrandId);

  const formatUserData = user => {
    const name = user?.object?.label?.split(' ');
    const formatedUser = {
      name: name[0] + ' ' + name[1],
      email: `${name[0]}.${name[1]}@amnhealthcare.com`,
      phoneNumber: '',
      contactId: user?.object?.empId?.toString(),
    };
    return formatedUser;
  };

  const getAssignedToList = () => {
    const assignedToArray: any[] = [];
    updatedTaskData?.assignedTo?.forEach(assigned => {
      if (updatedTaskData?.sentBy !== assigned?.object?.empId) {
        const userData = formatUserData(assigned);
        assignedToArray.push(userData);
      }
    });
    updatedTaskData?.cc?.forEach(ccUser => {
      if (updatedTaskData?.sentBy !== ccUser?.object?.empId) {
        const userData = formatUserData(ccUser);
        assignedToArray.push(userData);
      }
    });

    if (updatedTaskData.sentByUser.id !== updatedTaskData.sentBy) {
      const createdByUser = {
        name: updatedTaskData?.sentByUser?.firstName + ' ' + updatedTaskData?.sentByUser?.lastName,
        email: `${updatedTaskData?.sentByUser?.firstName}.${updatedTaskData?.sentByUser?.lastName}@amnhealthcare.com`,
        phoneNumber: '',
        contactId: updatedTaskData?.sentByUser?.id?.toString(),
      };
      assignedToArray.push(createdByUser);
    }
    const key = 'contactId';
    const filteredList = assignedToArray.filter(
      (contact, index, self) => self.findIndex(c => c[key] === contact[key]) === index,
    );
    return filteredList;
  };

  const getTaskUpdateNotifications = async () => {
    let entityId: string = '0';
    let primaryText: string = '';
    if (currentContainer === TaskEntity.PLACEMENTS) {
      if (globalEntityId?.placementId !== undefined) {
        await getPlacementHeader(globalEntityId?.placementId).then(response => {
          primaryText = Concatenate(
            [
              response?.candidate?.firstName,
              ' ',
              response?.candidate?.middleName || '',
              ' ',
              response?.candidate?.lastName,
            ],
            '',
          );
          entityId = response?.candidate?.id?.toString();
        });
      }
    } else if (currentContainer === TaskEntity.FACILITIES) {
      entityId = globalEntityId?.facilityId?.toString();
      if (globalEntityId?.facilityId !== undefined) {
        await getFacilityDetails(globalEntityId?.facilityId).then(response => {
          primaryText = response?.facilityName;
        });
      }
    } else if (currentContainer === TaskEntity.ORDERS) {
      entityId = globalEntityId?.facilityId?.toString();
      if (globalEntityId?.orderId !== null && globalEntityId?.orderId !== undefined) {
        await getOrderDetailsById(globalEntityId?.orderId).then(response => {
          primaryText = response?.facility?.name;
        });
      }
    } else if (currentContainer === TaskEntity.CANDIDATES) {
      entityId = globalEntityId?.candidateId?.toString();
      if (isPositiveNumber(globalEntityId?.candidateId)) {
        await getCandidateById(globalEntityId?.candidateId, brandId || 1).then(response => {
          primaryText = Concatenate(
            [response?.name?.first, ' ', response?.name?.middle || '', ' ', response?.name?.last],
            '',
          );
        });
      }
    } else {
      entityId = '0';
    }

    if (primaryText) {
      const taskNotificationPayload = {
        brand: NotificationBrand,
        useType: Task,
        useSubType: TaskNotificationType.TASKEDITED.toString(),
        sendType: 'Bulk',
        sender: NotificationSender,
        ttlValue: 2,
        ttlPeriod: 'w',
        associatedRecords: [
          {
            name: 'TaskId',
            value: updatedTaskData?.taskId?.toString(),
          },
          {
            name: 'DueDate',
            value: updatedTaskData?.dueDate,
          },
          {
            name: 'CurrentContainer',
            value: getTaskEntityValue(currentContainer, t),
          },
          {
            name: 'PrimaryContext',
            value: primaryText,
          },
          {
            name: 'PlacementId',
            value: globalEntityId ? globalEntityId?.placementId?.toString() : '',
          },
          {
            name: 'FacilityId',
            value: globalEntityId?.facilityId ? globalEntityId?.facilityId?.toString() : '',
          },
          {
            name: 'UnitId',
            value: updatedTaskData?.unitId ? updatedTaskData?.unitId?.toString() : '',
          },
          {
            name: 'CandidateId',
            value: globalEntityId?.candidateId ? globalEntityId?.candidateId.toString() : '',
          },
          {
            name: 'OrderId',
            value: globalEntityId?.orderId ? globalEntityId?.orderId.toString() : '',
          },
          {
            name: 'Description',
            value: userInfo?.userInfo?.firstName + ' ' + userInfo?.userInfo?.lastName + ' has updated Task Details.',
          },
          {
            name: 'TaskCategory',
            value: updatedTaskData?.taskCategoryText ? updatedTaskData?.taskCategoryText : '',
          },
          {
            name: 'EntityId',
            value: entityId,
          },
          {
            name: 'UserName',
            value: userInfo?.userInfo?.firstName + ' ' + userInfo?.userInfo?.lastName,
          },
          {
            name: 'TaskName',
            value: updatedTaskData?.task,
          },
          {
            name: 'SentDate',
            value: moment.tz(new Date(), 'America/Los_Angeles').format(),
          },
          {
            name: 'Version',
            value: StatusUpdateModalVersion.PARENT_TASK.toString(),
          },
        ],
        body: '',
        level: 1,
        templateId: '',
        tos: getAssignedToList(),
        ccs: [],
        groupName: '',
      };
      if (taskNotificationPayload?.tos?.length > 0) {
        if (globalEntityId?.companyId > 0) {
          taskNotificationPayload?.associatedRecords?.push({
            name: 'BrandId',
            value: globalEntityId?.companyId?.toString(),
          });
        }
        dispatch(taskDetailsActions.sendTaskCreatedNotification(taskNotificationPayload));
      }
    }
  };
  useEffect(() => {
    if (updatedId && updatedId > 0 && updatedTaskData) {
      getTaskUpdateNotifications();
      dispatch(taskDetailsActions.setTaskUpdatedId(undefined));
      dispatch(taskDetailsActions.setTaskUpdatedData(undefined));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedId, updatedTaskData]);
};
