import { UnitCard } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/CustomComponents/UnitCard';
import React from 'react';
import { LayoutGrid } from 'app/layout/LayoutGrid';
import { missingField } from 'app/constants';
import { useTranslation } from 'react-i18next';
import { isNullOrUndefined } from 'app/helpers/objectHelpers';
import { Grid, Typography } from 'amn-ui-core';
import { StatusIndicator, useSelectedUnitDetails, useIsUserAccountManager, useUnitStatusInActive } from './unitHelper';

const TravelExperience = () => {
  const { t } = useTranslation();
  const isAuthorized = useIsUserAccountManager();
  const selectedUnitDetail = useSelectedUnitDetails();
  const travelExperience = selectedUnitDetail?.travelExperience;
  const isAvailable =
    !isNullOrUndefined(travelExperience?.numberOfAssignments) ||
    !isNullOrUndefined(travelExperience?.yearsOfExperience);
  const isUnitInActive = useUnitStatusInActive();

  return (
    <>
      <UnitCard
        title={t('facility.units.travelExp.viewPageTitle')}
        rightIcon={true}
        showEditIcon={isAuthorized && !isUnitInActive}
      >
        <LayoutGrid container sx={{ p: '12px', m: 0 }} direction="column">
          {isAvailable ? (
            <>
              <StatusIndicator isRequired={travelExperience?.isRequired} />
              <Grid container rowSpacing={2} mt={'0px'}>
                <Grid xs={5} p={'6px'}>
                  <GridSection label={t('facility.units.travelExp.viewPageTitle')} value={'Yes'} />
                </Grid>
                <Grid xs={7} p={'6px'}>
                  <GridSection
                    label={
                      isNullOrUndefined(travelExperience?.yearsOfExperience)
                        ? t('facility.units.travelExp.noOfAssignments')
                        : t('facility.units.travelExp.yearsOfExp')
                    }
                    value={
                      isNullOrUndefined(travelExperience?.numberOfAssignments)
                        ? travelExperience?.yearsOfExperience
                        : travelExperience?.numberOfAssignments
                    }
                  />
                </Grid>
                <Grid xs={5} p={'6px'}>
                  <GridSection
                    label={t('facility.units.travelExp.inSpeciality')}
                    value={travelExperience?.inSpecialty ? 'Yes' : 'No'}
                  />
                </Grid>
                <Grid xs={7} p={'6px'}>
                  <GridSection
                    label={t('facility.units.travelExp.general')}
                    value={travelExperience?.general ? 'Yes' : 'No'}
                  />
                </Grid>
              </Grid>
            </>
          ) : (
            missingField
          )}
        </LayoutGrid>
      </UnitCard>
    </>
  );
};

export default TravelExperience;

const GridSection = ({ label, value }: { label: string; value: string | number }) => {
  return (
    <>
      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
        {label}
      </Typography>
      <Typography variant="body1" pt={'5px'}>
        {value}
      </Typography>
    </>
  );
};
