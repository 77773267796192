import { Collapse as AMNCollapse, Grid, Box, Container as AMNContainer, styled, useTheme } from 'amn-ui-core';
import React from 'react';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import styledComponent from 'styled-components';
import Skeleton from '@mui/material/Skeleton';

type ExpandableVariant = 'default' | 'error' | 'report' | 'inlineDense';

export interface IExpandableProps {
  titleComponent?: any;
  titleAddOns?: string | JSX.Element;
  expanded?: boolean;
  loading?: boolean;
  loadingRenderer?: () => JSX.Element;
  unmountOnExit?: boolean;
  disabled?: boolean;
  id?: string;
  preventForceClose?: boolean;
  hideOverflowTitle?: boolean;
  [key: string]: any;
  highlight?: boolean;
  variant?: ExpandableVariant;
  customPadding?: string;
}

interface TitleContainerProps {
  variant: ExpandableVariant;
  disabled?: boolean;
}

interface TitleProps {
  variant: ExpandableVariant;
  hideOverflowTitle?: boolean;
}

interface ContentContainerProps {
  variant: ExpandableVariant;
  customPadding?: string;
}

export const Expandable = ({
  titleComponent,
  titleAddOns = null,
  expanded = false,
  unmountOnExit = true,
  loading,
  loadingRenderer,
  disabled,
  id,
  preventForceClose,
  hideOverflowTitle = false,
  highlight = false,
  variant = 'default',
  customPadding,
  ...props
}: IExpandableProps) => {
  const theme = useTheme();
  const [_expanded, _setExpanded] = React.useState<boolean>(!disabled && expanded);

  const loadingRendererMemo = React.useMemo(() => {
    return loadingRenderer?.();
  }, [loadingRenderer]);

  React.useEffect(() => {
    if (preventForceClose) expanded && _setExpanded(expanded);
    else _setExpanded(expanded);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded]);

  const titleRender = React.useMemo(
    () => (
      <TitleContainer disabled={disabled} variant={variant}>
        {!hideOverflowTitle ? (
          <>
            <Title variant={variant}>{titleComponent}</Title>
            <div>
              {!disabled && (
                <Box>
                  {titleAddOns}
                  {_expanded ? <ExpandLessIcon variant={variant} /> : <ExpandMoreIcon variant={variant} />}
                </Box>
              )}
            </div>
          </>
        ) : (
          <div
            style={{
              minWidth: '100px',
              width: '-webkit-fill-available',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Title variant={variant} hideOverflowTitle={hideOverflowTitle}>
              {titleComponent}
            </Title>
            <div>
              {!disabled && (
                <Box>{_expanded ? <ExpandLessIcon variant={variant} /> : <ExpandMoreIcon variant={variant} />}</Box>
              )}
            </div>
          </div>
        )}
      </TitleContainer>
    ),
    [_expanded, disabled, hideOverflowTitle, titleAddOns, titleComponent, variant],
  );

  return (
    <Container
      id={id}
      disableGutters
      variant={variant}
      style={{ maxWidth: '100%', border: highlight ? `1.5px solid ${theme.palette.system.lightNavyBlue}` : null }}
    >
      <Grid container alignItems="center" style={{ border: '1px solid transparent' }}>
        <Grid
          item
          xs={12}
          onClick={
            !disabled
              ? () => {
                  _setExpanded(prev => !prev);
                }
              : () => {}
          }
        >
          {titleRender}
        </Grid>
        {(props.children || loading) && (
          <Grid item xs={12}>
            <Collapse
              in={_expanded}
              timeout="auto"
              unmountOnExit={unmountOnExit}
              style={{ borderTop: highlight ? `1.5px solid ${theme.palette.system.lightNavyBlue}` : null }}
            >
              <ContentContainer variant={variant} customPadding = {customPadding}>
                {loading ? loadingRendererMemo || <Loading /> : props.children}
              </ContentContainer>
            </Collapse>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export const Loading = () => {
  return (
    <div style={{ width: '100%' }}>
      <Skeleton />
      <Skeleton />
    </div>
  );
};

const borderColor = '#d9d9d9';

const Container = styled(AMNContainer)<TitleContainerProps>(({ theme, variant, disabled }) => ({
  border: !['inlineDense', 'report'].includes(variant) ? `1.5px solid ${borderColor}` : undefined,
  borderTopLeftRadius: '5px',
  borderTopRightRadius: '5px',
}));

const TitleContainer = styled('div')<TitleContainerProps>(({ theme, variant, disabled }) => ({
  display: 'flex',
  flexDirection: 'row',
  padding: variant === 'report' ? '6px 0px' : '10px 12px',
  backgroundColor:
    variant === 'error'
      ? theme.palette.error.main
      : variant === 'report'
      ? 'transparent'
      : variant === 'inlineDense'
      ? theme.palette.framework.system.white
      : theme.palette.framework.system.backgroundGrey,
  cursor: disabled ? 'auto' : 'pointer',
  height: '100%',
  alignItems: 'center',
  borderTopLeftRadius: variant === 'error' ? '5px' : '0px',
  borderTopRightRadius: variant === 'error' ? '5px' : '0px',
  borderBottom: variant === 'report' && `1px solid ${theme.palette.framework.system.midnightBlue}`,
}));

const Title = styled('div')<TitleProps>(({ theme, variant, hideOverflowTitle }) => ({
  flex: '1 0 auto',
  height: '100%',
  width: hideOverflowTitle ? '100px' : 'auto',
  color:
    variant === 'error'
      ? theme.palette.common.white
      : variant === 'report'
      ? theme.palette.framework.system.midnightBlue
      : theme.palette.text.primary,
  fontWeight: variant === 'report' && 'bold',
  textTransform: variant === 'report' ? 'uppercase' : 'none',
}));

const Collapse = styledComponent(AMNCollapse)`
  width: 100%;
  border-top: 1px solid ${borderColor};
`;

const ContentContainer = styledComponent('div')<ContentContainerProps>(({ variant, customPadding }) => ({
  padding: variant === 'inlineDense' ? '12px 12px 0' : customPadding ?? '12px 12px',
}));

const ExpandMoreIcon = styled(ExpandMore)<{ variant: ExpandableVariant }>(({ theme, variant }) => ({
  width: '28px',
  color: variant === 'error' ? theme.palette.common.white : theme.palette.components.iconButton.primarySmall.color,
}));

export const ExpandLessIcon = styled(ExpandLess)<{ variant: ExpandableVariant }>(({ theme, variant }) => ({
  width: '28px',
  color: variant === 'error' ? theme.palette.common.white : theme.palette.components.iconButton.primarySmall.color,
}));
