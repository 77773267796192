/* eslint-disable i18next/no-literal-string */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { FormControl, Grid, InputLabel, MenuItem, Select, ThemeProvider, StyledEngineProvider,useTheme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { UnitCardsTitle, UnitDetailsBody } from '../UnitDesign';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { SingleLineInput } from '../Common/SingleLineInput';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import bestBetsValue from 'app/assets/jsons/OrderCreation/BestBets.json';
import { orderCreationActions } from '@AMIEWEB/Order/Store/OrderCreation.actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectBestBets } from '@AMIEWEB/Order/Store/OrderCreation.selectors';

interface Props {
  disableEdit?: boolean;
}

const useStyles = makeStyles()((theme) => ({
  selectField: {
    height: 'auto',
    backgroundColor: '#F7F7F7',
  },
  dropdownStyle: {
    border: '1px solid #D1D1D1',
    boxShadow: 'none',
    borderRadius: 3,
    marginTop: '0.5rem',
    width: 100,
    overflowY: 'auto',
    height: '20%',
  },
  menu: {
    color: '#333333',
    fontSize: 12,
    '&:hover, &:focus': {
      backgroundColor: '#F7F7F7',
    },
  },
  textareastyle: {
    backgroundColor: '#fff',
    border: 'thin #e7e2e2 solid',
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&:focus': {
      backgroundColor: '#fff',
    },
    '&.Mui-focused': {
      backgroundColor: '#fff',
    },
  },
}));

export const NewUnitBestBets = (props: Props) => {
  const { disableEdit } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const theme = useTheme();

  const { control, setValue } = useFormContext();

  useEffect(() => {
    dispatch(orderCreationActions.getBestBetsOptions());
  }, []);
  
  const bestBets = useSelector(selectBestBets);
  const getBestBets = (bestBetsId: number) => {
    const bets = bestBetsValue.filter(x => x.ID === bestBetsId)[0];
    return bets.Description;
  };

  return (
    <div>
      {/* TODO: Remove this theme wrapper since it uses the global theme */}
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <UnitDetailsBody variant="outlined">
            <Grid container direction="column" spacing={1}>
              <Grid item xs={12}>
                <UnitCardsTitle>{t('order.createUnit.bestBetsUnitSize.title')}</UnitCardsTitle>
              </Grid>
              <Grid item xs={12}>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={6}>
                    <FormControl fullWidth variant="filled">
                      <InputLabel>{t('order.createUnit.bestBetsUnitSize.bestBets')}</InputLabel>
                      <Controller
                        control={control}
                        name={'bestBetsId'}
                        render={({ ref, onChange, ...rest }) => {
                          return (
                            <Select
                              id={'createUnitBestBetsSizeDropDown'}
                              name={'bestBetsId'}
                              className={classes.selectField}
                              //defaultValue={bestBetId}
                              value={rest.value ? parseInt(rest.value) : 0}
                              disabled={disableEdit}
                              onChange={event => {
                                onChange(event.target.value);
                                setValue('bestBets', getBestBets(parseInt(`${event.target.value}`)));
                              }}
                              inputProps={{
                                className: disableEdit ? '' : `${classes.textareastyle}`,
                              }}
                              MenuProps={{
                                classes: { paper: classes.dropdownStyle },
                                anchorOrigin: {
                                  vertical: 'bottom',
                                  horizontal: 'left',
                                },
                                transformOrigin: {
                                  vertical: 'top',
                                  horizontal: 'left',
                                },
                              }}
                              IconComponent={ExpandMoreOutlinedIcon}
                            >
                              {bestBets?.map((item) => {
                                return (
                                  <MenuItem key={item.value} value={item.value} className={classes.menu}>
                                    {item.name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          );
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name={'unitSize'}
                      render={({ ref, onChange, ...rest }) => (
                        <SingleLineInput
                          id={'createUnitUnitSizeTxt'}
                          inputDefaultValue={0}
                          isdisabled={disableEdit}
                          inputLabel={t('order.createUnit.bestBetsUnitSize.unitSize')}
                          inputName={'unitSize'}
                          inputValue={rest.value || ''}
                          handleChange={event => {
                            onChange(parseInt(event.target.value));
                          }}
                          inputType={'number'}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </UnitDetailsBody>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
};

