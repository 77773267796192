import React, { useRef } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Card, CardContent, Avatar, Typography, CircularProgress, Grid, MenuList } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PhoneCallOutline from 'app/assets/images/Notification/UserManagement/PhoneCallOutline.svg';
import FaxOutline from 'app/assets/images/Notification/UserManagement/FaxOutline.svg';
import FaxDisabledOutline from 'app/assets/images/Notification/UserManagement/FaxDisabledOutline.svg';
import PhonedisableOutline from 'app/assets/images/Notification/UserManagement/PhonedisableOutline.svg';
import noresults from 'app/assets/images/Notification/UserManagement/noresults.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getUserManagementActions } from './store/userManagement.redux';
import {
  selectAppliedFiltersFlag,
  selectEmpInfo,
  selectIsDataChanged,
  selectUserLists,
  selectUserManagementFilters,
  selectedCardId,
  setUserCounts,
} from './store/userManagement.selector';
import { CustomTooltip } from '@AMIEWEB/Common/Tooltips';
import { usePromiseTracker } from 'react-promise-tracker';

const useStyles = makeStyles()(() => ({
  pageContainer: {
    borderRadius: '4px',
    border: '1px solid #CCCCCC',
    marginLeft: '0.5px',
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
    height: 'calc(100vh - 320px)',
    '@media (max-width: 980px)': {
      height: 'calc(100vh - 360px)',
    },
  },
  scroll: {
    color: '#555555',
    fontSize: '1rem',
    overflowX: 'hidden',
    overflowY: 'auto',
    width: 'calc(100% + 2px)',
    boxSizing: 'content-box',
    height: 'calc(100vh - 320px)',
    '@media (max-width: 980px)': {
      height: 'calc(100vh - 360px)',
    },
  },
  card: {
    height: '150px',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    borderBottom: '1px solid #CCCCCC',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'nowrap',
    borderRadius: '0px',
    cursor: 'pointer',
    overflow: 'auto',
  },
  avatar: {
    width: '36px',
    height: '36px',
    backgroundColor: '#52429A',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0rem 0.5rem 0rem 0.5rem',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '0 !important',
  },
  employeeStatusIcon: {
    width: '58px',
    height: '28px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    position: 'absolute',
    top: '10px',
    right: '22px',
    zIndex: 1,
  },
  circular: {
    height: '84vh',
  },
  activeText: {
    marginLeft: '4px',
    fontWeight: 600,
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  employeeName: {
    color: '#0F6FB9',
    marginBottom: '4px',
    fontSize: '14px',
    display: 'inline',
    fontWeight: 600,
    marginTop: '3px',
  },
  employeeIds: {
    color: 'black',
    width: '191px',
    height: '14px',
    marginBottom: '4px',
    fontSize: '14px',
    opacity: '100%',
    display: 'inline',
    padding: '2px 4px',
    fontWeight: 600,
  },
  userInfoLabel: {
    fontSize: '12px',
    fontWeight: 600,
    color: '#333333',
    marginRight: '8px',
    minWidth: '5rem',
  },
  userInfoValue: {
    fontSize: '14px',
    fontWeight: 400,
    color: '#333333',
    whiteSpace: 'nowrap',
  },
  userInfoRow: {
    display: 'flex',
    alignItems: 'center',
  },
  phoneText: {
    color: '#0F6FB9',
    fontSize: '13px',
    marginRight: '25px',
    fontWeight: 600,
  },
  disableText: {
    color: '#C6C8CA',
    fontSize: '13px',
    marginRight: '25px',
    fontWeight: 600,
  },
  phoneContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '5px 0px 7px 0px',
  },
  phoneIcon: {
    width: '30px',
    height: '30px',
    marginRight: '8px',
    color: '#0F6FB9',
  },
  faxNumber: {
    color: '#0F6FB9',
    fontSize: '13px',
    fontWeight: 600,
    marginRight: '25px',
  },
  loader: {
    position: 'absolute',
    width: '100%',
    height: '84vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loaderText: {
    marginTop: '10px',
    fontStyle: 'italic',
    fontWeight: 500,
  },
  selectedCard: {
    backgroundColor: '#E8F4FDCC',
  },
}));

const LeftContainer = props => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const employeeData = useSelector(selectUserLists);
  const filterData = useSelector(selectUserManagementFilters);
  const filterFlag = useSelector(selectAppliedFiltersFlag);
  const userListCounts = useSelector(setUserCounts);
  const empInfo = useSelector(selectEmpInfo);
  const isDataChanged = useSelector(selectIsDataChanged);
  const cardId = useSelector(selectedCardId);
  const pageContainerRef = useRef<HTMLDivElement | null>(null);
  const pageSizeRef = useRef(25);
  const { promiseInProgress } = usePromiseTracker({ area: 'user-search-card', delay: 0 });

  var hasValues = empInfo && Object.keys(empInfo).length > 0;
  const missingField = '--';
  const payload = {
    firstName: filterData?.employeeNames?.value?.split('_')?.[0],
    lastName: filterData?.employeeNames?.value?.split('_')?.[1],
    userName: filterData?.usernames?.value,
    statusId: Number(filterData?.employeeStatus?.value) ?? null,
    employeeId: Number(filterData?.eid?.value) ?? null,
    employeeRoleId: Number(filterData?.employeeRole?.value) ?? null,
    companyId: Number(filterData?.companies?.value) ?? null,
    pageNumber: 1,
    pageSize: pageSizeRef.current,
  };

  const scrollToTop = () => {
    if (pageContainerRef.current) {
      pageContainerRef.current.scrollTop = 0;
    }
  };

  const handleScroll = () => {
    const numberOfObjects = employeeData?.length;
    const container = pageContainerRef.current;
    if (container) {
      const scrollPosition = container.scrollTop + container.clientHeight;
      const totalHeight = container.scrollHeight;

      if (scrollPosition + 1 >= totalHeight && numberOfObjects < userListCounts) {
        if (!filterFlag) {
          pageSizeRef.current += 25;
          payload.pageSize = pageSizeRef.current;
          dispatch(getUserManagementActions.getUserSearchLists(payload));
          props?.setPageSize(pageSizeRef.current);
        } else {
          pageSizeRef.current = 25;
          dispatch(getUserManagementActions.setSelectedFiltersFlag(false));
          props?.setPageSize(pageSizeRef.current);
          scrollToTop();
        }
      }
    }
  };

  const EmployeeCard = ({ employee, classes }) => {
    const truncatedEmail = employee?.email ? employee?.email.slice(0, 32) : '';

    return (
      <div className={classes.employeeCard}>
        {employee?.email?.length > 32 ? (
          <CustomTooltip
            contentStyle={{ display: 'inline-block' }}
            inlineBlock
            interactive
            tooltipContent={employee?.email}
          >
            <Typography variant="body2" className={classes.userInfoValue}>
              {truncatedEmail}...
            </Typography>
          </CustomTooltip>
        ) : (
          <Typography variant="body2" className={classes.userInfoValue}>
            {truncatedEmail || missingField}
          </Typography>
        )}
      </div>
    );
  };

  const EmployeeNameCard = ({ firstName, lastName, classes }) => {
    const fullName = `${firstName} ${lastName}`;
    const truncatedFullName = fullName.slice(0, 25);

    return (
      <div className={classes.employeeName}>
        {fullName.length > 25 ? (
          <CustomTooltip contentStyle={{ display: 'inline-block' }} inlineBlock interactive tooltipContent={fullName}>
            <Typography variant="body2" className={classes.employeeName}>
              {truncatedFullName}... |
            </Typography>
          </CustomTooltip>
        ) : (
          <Typography variant="body2" className={classes.employeeName}>
            {truncatedFullName || missingField} |
          </Typography>
        )}
      </div>
    );
  };

  const PhoneNumberDisplay = ({ phoneNumber, classes }) => {
    const IconComponent = phoneNumber ? PhoneCallOutline : PhonedisableOutline;

    return (
      <>
        <img src={IconComponent} alt="Phone" className={classes.phoneIcon} data-testid="phoneNumber" />
        <Typography
          variant="body2"
          className={phoneNumber ? classes.phoneText : classes.disableText}
          data-testid="phoneNumber-content"
        >
          {phoneNumber || missingField}
        </Typography>
      </>
    );
  };

  const FaxNumberDisplay = ({ faxNumber, classes }) => {
    const IconComponent = faxNumber ? FaxOutline : FaxDisabledOutline;

    return (
      <>
        <img src={IconComponent} alt="Phone" className={classes.phoneIcon} data-testid="faxNumber" />
        <Typography
          variant="body2"
          className={faxNumber ? classes.faxNumber : classes.disableText}
          data-testid="faxNumber-content"
        >
          {faxNumber || missingField}
        </Typography>
      </>
    );
  };


  const handleCardClick = employee => {
    dispatch(getUserManagementActions.setDialoge(isDataChanged && employee?.employeeId != cardId ? true : false));
    dispatch(getUserManagementActions.setEmpCardInfo(employee));

    if (!isDataChanged) {
      dispatch(getUserManagementActions.setSelectedCardId(employee?.employeeId));
      dispatch(getUserManagementActions.setEmpInfo(employee));
      const payload = { employeeId: employee?.employeeId };
      dispatch(getUserManagementActions.getEmpRoles(payload));
      dispatch(getUserManagementActions.employeeAssociations(payload));
    }
  };

  return (
    <Grid className={classes.pageContainer} data-testid="userManagementCardContainer">
      <MenuList style={{ margin: 0, padding: '0px' }}>
        {promiseInProgress && pageSizeRef.current === 25 ? (
          <div className={classes.loader}>
            <CircularProgress />
          </div>
        ) : (
          <div
            className={classes.scroll}
            ref={pageContainerRef}
            onScroll={handleScroll}
            style={{ margin: 0, padding: '0px' }}
            data-testid="employee-cards"
          >
            {employeeData?.length > 0
              ? employeeData?.map(employee => (
                <Card
                  key={employee.id}
                  className={`${classes.card} ${cardId === employee.employeeId && hasValues ? classes.selectedCard : ''
                    }`}
                  onClick={() => handleCardClick(employee)}
                >
                  <CardContent className={classes.cardContent}>
                    <div data-testid="avatar">
                      <Avatar className={classes.avatar}>{employee.firstName[0] + employee.lastName[0]}</Avatar>
                    </div>
                    <div className={classes.infoContainer} data-testid="employeeName">
                      <Typography variant="body2" style={{ marginBottom: '8px' }}>
                        <EmployeeNameCard
                          firstName={employee.firstName}
                          lastName={employee.lastName}
                          classes={classes}
                        />
                        <div className={classes.employeeIds} data-testid="employeeId">
                          {t('userManagement.eid')}
                          {` `}
                          {employee.employeeId}
                        </div>
                      </Typography>
                      <div className={classes.userInfoRow} data-testid="userName">
                        <Typography variant="body2" className={classes.userInfoLabel}>
                          {t('userManagement.userName')}
                          {` `}
                        </Typography>
                        <Typography variant="body2" className={classes.userInfoValue}>
                          {employee.userName}
                        </Typography>
                      </div>
                      <div className={classes.userInfoRow} data-testid="userBrand">
                        <Typography variant="body2" className={classes.userInfoLabel}>
                          {t('userManagement.brand')}
                          {` `}
                        </Typography>
                        <Typography variant="body2" className={classes.userInfoValue}>
                          {employee.companyName}
                        </Typography>
                      </div>
                      <div className={classes.userInfoRow} data-testid="employeeEmails">
                        <Typography variant="body2" className={classes.userInfoLabel}>
                          {t('userManagement.email')}
                          {` `}
                        </Typography>
                        <EmployeeCard employee={employee} classes={classes} />
                      </div>

                      <div className={classes.phoneContainer}>
                        <PhoneNumberDisplay phoneNumber={employee.phoneNumber} classes={classes} />
                        <FaxNumberDisplay faxNumber={employee.faxNumber} classes={classes} />
                      </div>
                    </div>
                    <div
                      className={classes.employeeStatusIcon}
                      style={{ backgroundColor: employee.descStatus === 'Active' ? '#5FB0574D' : '#CCCCCC80' }}
                      data-testid="EmployeeStatus"
                    >
                      <Typography
                        variant="body2"
                        className={classes.activeText}
                        style={{ color: employee.descStatus === 'Active' ? '#266E1F' : '#888888' }}
                      >
                        {employee.descStatus}
                      </Typography>
                    </div>
                  </CardContent>
                </Card>
              ))
              : !promiseInProgress && (
                <div className={classes.loader}>
                  <img src={noresults} alt="noresults" data-testid="noresults" />
                  <Typography className={classes.loaderText}>{t('No result found')}</Typography>
                </div>
              )}
          </div>
        )}
      </MenuList>
    </Grid>
  );
};

export default LeftContainer;
