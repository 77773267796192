import { Button, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import React, { useEffect, useState } from 'react';
import ManageFilter from 'app/components/Common/ManageFilter/ManageFilter';
import { useDispatch, useSelector } from 'react-redux';
import { selectFilters, selectGridState } from 'app/components/Common/Grid/GridStateManagement/GridState.selectors';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { userDevicePreferenceActions } from 'oidc/UserDevicePreference/userPreference.redux';
import { FilterPreferenceSelector } from './Actions/FilterPreferenceSelector';
import { useFormContext } from 'react-hook-form';
import { DuplicateFilterAlertModal } from './Actions/DuplicateFilterAlertModal';
import { SaveModal } from './Actions/SaveModal';
import { SaveAsModal } from './Actions/SaveAsModal';
import { defaultFilterName, SearchType } from 'app/models/GlobalSearch/GlobalSearch';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { ff_ui_facility_search_manage_facility } from 'app/constants/FeatureFlags/Candidate/Keys';
import { useDecision } from '@optimizely/react-sdk';
import _ from 'lodash';
import {
  getSessionValue,
  removeSessionValue,
  SessionKey,
  StorageType,
} from 'utils/customHooks/sessionStorage/sessionHelpers';
import { RootState } from 'types';
import { isNullOrUndefined } from 'app/helpers/objectHelpers';
import { useTranslation } from 'react-i18next';
import { gridStateActions } from 'app/components/Common/Grid/GridStateManagement/GridState.redux';

const useStyles = makeStyles()({
  button: {
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: '20px',
    '&:hover': {
      backgroundColor: '#003E68',
    },
  },
  icon: {
    height: '90%',
    paddingLeft: '5px',
    marginRight: '-5px',
  },
  link: {
    textDecoration: 'none',
  },
});

interface SearchFilterActionProps {
  category: SearchType;
  searchString: string;
  chips: any;
  onFilterSetChange: (filters: { [x: string]: any }) => void;
}

export const SearchFilterAction = ({ category, searchString, chips, onFilterSetChange }: SearchFilterActionProps) => {
  const { classes } = useStyles();
  /**New State Management - Start */
  const filterAttributes = useSelector(selectFilters);
  const filterPreferences = useSelector((state: RootState) => {
    return state?.userPreferenceData?.userGridPreferences?.find(item => item.id === `GlobalSearchGrid-${category}`);
  });
  const { t } = useTranslation();
  const [facilityLinkDecision] = useDecision(ff_ui_facility_search_manage_facility);
  const [filterOptions, setFilterOptions] = useState<any[]>([]);
  /**New State Management - End */
  /** Old state management */
  const [isSaveModalOpen, setSaveModalOpen] = useState(false);
  const [isAlertModalOpen, setAlertModalOpen] = useState(false);
  const [isSaveAsModalOpen, setSaveAsModalOpen] = useState(false);
  const [existingFilterSet, setExistingFilterSet] = useState('');
  const [enableSaveButton, setEnableSaveButton] = useState(false);
  const [enableSaveAsButton, setEnableSaveAsButton] = useState(false);
  const [disableSaveButton, setDisableSaveButton] = useState<boolean>(true);
  const [filtersApplied, setFiltersApplied] = useState<boolean>(true);
  const [errorText, setErrorText] = useState('');
  const [selectedFilterOption, setSelectedFilterOption] = useState<{ [x: string]: any } | undefined | null>();
  const [isManageFilters, setMangeFilters] = useState(false);
  const dispatch = useDispatch();
  const [newFilterName, setNewFilterName] = useState('');

  const facilityLink = globalThis?.app?.env?.REACT_APP_FACILITY_URL;

  const checkAppliedFilters = filterStates => {
    const isSkillsetFilterApplied =
      filterStates?.skillsetFilter?.allowableMismatch ||
      filterStates?.skillsetFilter?.isExactMatch ||
      filterStates?.skillsetFilter?.isVerifiedOnly ||
      filterStates?.skillsetFilter?.operation ||
      filterStates?.skillsetFilter?.skillsets?.length > 0;
    const isCandidateFilterApplied = filterStates?.candidateFilter?.selections?.length > 0;
    return Object.entries(filterStates || {})?.some(
      item =>
        (Array.isArray(item[1]) && item[1].length > 0) ||
        (!Array.isArray(item[1]) &&
          item[0] !== 'skillsetFilter' &&
          item[0] !== 'candidateFilter' &&
          !isNullOrUndefined(item[1])) ||
        (item[0] === 'skillsetFilter' && isSkillsetFilterApplied) ||
        (item[0] === 'candidateFilter' && isCandidateFilterApplied),
    );
  };

  const {
    formState: { isDirty },
    setValue,
  } = useFormContext();

  const gridState = useSelector(selectGridState);
  const [displayDropdown, setDisplayDropdown] = useState<boolean>(true);

  useEffect(() => {
    setDisplayDropdown(!searchString);
  }, [searchString]);

  useEffect(() => {
    // const option = filterPreferences?.value?.selectedFilter;
    const foundFilter = filterOptions?.find(item => item.name === filterPreferences?.value?.selectedFilter?.name);
    if (foundFilter) {
      setSelectedFilterOption(foundFilter);
    } else {
      setSelectedFilterOption(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterPreferences?.value?.selectedFilter, filterOptions]);

  useEffect(() => {
    if (searchString) return;
    const defaultFilter =
      selectedFilterOption?.name?.toLowerCase() === defaultFilterName || selectedFilterOption?.isDefault;
    const sessionFilters = getSessionValue(SessionKey[`GlobalSearchGrid-${category}`], StorageType.sessionStorage);
    const isFilterChanged = !isNullOrUndefined(selectedFilterOption)
      ? JSON.stringify(Object.fromEntries(selectedFilterOption?.value)) !== JSON.stringify(filterAttributes?.filters) &&
        isDirty
      : false;

    const appliedFilters = filterAttributes?.filters ? checkAppliedFilters(filterAttributes?.filters) : false;
    setFiltersApplied(appliedFilters);

    if (isDirty || (!!sessionFilters && isFilterChanged)) {
      setEnableSaveAsButton(isDirty || (!!sessionFilters && isFilterChanged));
      setEnableSaveButton(selectedFilterOption && !defaultFilter && (isDirty || (!!sessionFilters && isFilterChanged)));
    } else if (isNullOrUndefined(selectedFilterOption) && appliedFilters) {
      setEnableSaveAsButton(true);
      setEnableSaveButton(false);
    } else {
      setEnableSaveAsButton(false);
      setEnableSaveButton(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterAttributes?.filters, selectedFilterOption]);

  const isDuplicateFilter = () => {
    let isDuplicate = false;
    filterPreferences?.value?.filterAttributes?.filterSet?.every(item => {
      if (_.isEqual(JSON.stringify(Object.fromEntries(item.value)), JSON.stringify(filterAttributes?.filters))) {
        isDuplicate = true;
        setExistingFilterSet(item.name);
        return false;
      }
      return true;
    });
    return isDuplicate;
  };

  const addNewFilter = () => {
    const tempColumnSort = getSessionValue(SessionKey.tempColumnOrder, StorageType.sessionStorage);
    removeSessionValue(SessionKey.globalSearchReset, StorageType.sessionStorage);
    removeSessionValue(SessionKey[`GlobalSearchGrid-${category}`], StorageType.sessionStorage);
    if (
      newFilterName.length > 0 &&
      filterOptions?.filter(item => item.name.toLowerCase() === newFilterName.toLowerCase()).length > 0
    ) {
      setErrorText('Name Already In Use');
      return;
    }

    if (isDuplicateFilter()) {
      setSaveAsModalOpen(false);
      setAlertModalOpen(true);
    } else {
      const filtersTemp = JSON.parse(JSON.stringify(filterAttributes?.filters));
      const gridStateTemp = _.cloneDeep(gridState);

      const newFilterOption = {
        name: newFilterName.toLowerCase(),
        value: Object.entries(filtersTemp),
        ...tempColumnSort,
      };
      const newGridState = {
        ...gridStateTemp,
        filterAttributes: {
          ...gridStateTemp.filterAttributes,
          filterSet: [...(filterPreferences?.value?.filterAttributes?.filterSet ?? []), newFilterOption],
        },
        selectedFilter: {
          name: newFilterOption.name,
          value: newFilterOption.value,
          isDefault: newFilterOption.isDefault,
        },
      };
      dispatch(
        userDevicePreferenceActions.saveUserGridPreferenceAction({
          id: SessionKey[`GlobalSearchGrid-${category}`],
          value: newGridState,
        }),
      );
      dispatch(
        globalActions.setSnackBar({
          message: t('search.filter.addFilterSuccess'),
          severity: 'success',
        }),
      );
      setSaveAsModalOpen(false);
    }
  };

  const handleFilterNameChange = val => {
    const isNamePresent = filterPreferences?.value?.filterAttributes?.filterSet?.some(
      filter => filter.name.trim().toLowerCase() === val.trim().toLowerCase(),
    );
    setDisableSaveButton(val.length <= 1 || val.length >= 31 || isNamePresent);
    setNewFilterName(val);
    setErrorText(isNamePresent ? t(`global.filterPreferences.nameAlreadyExists`) : '');
  };

  const handleFilterOptionsChange = filterValue => {
    if (!!filterValue && selectedFilterOption?.name !== filterValue) {
      removeSessionValue(SessionKey.globalSearchReset, StorageType.sessionStorage);
      removeSessionValue(SessionKey[`GlobalSearchGrid-${category}`], StorageType.sessionStorage);
      const filterSet = filterPreferences?.value?.filterAttributes?.filterSet.find(item => item.name === filterValue);
      const selectedFilter = {
        name: filterSet.name,
        value: filterSet.value,
        isDefault: filterSet.isDefault,
      };
      setValue('license', null);
      setValue('certification', null);
      setValue('workHistory', null);
      dispatch(gridStateActions.setSelectedChips([]));
      dispatch(
        userDevicePreferenceActions.saveUserGridPreferenceAction({
          ...filterPreferences,
          value: {
            ...filterPreferences?.value,
            selectedFilter,
          },
        }),
      );
      setEnableSaveButton(false);
      setEnableSaveAsButton(false);
    }
  };

  const handleSaveExistingFilter = () => {
    const currentFilterString = JSON.stringify(filterAttributes?.filters);
    setSaveModalOpen(
      !filterPreferences?.value?.filterAttributes?.filterSet.some(item => {
        const duplicateExist = JSON.stringify(Object.fromEntries(item.value)) === currentFilterString;
        if (duplicateExist) {
          setAlertModalOpen(true);
          setExistingFilterSet(item.name);
          setSaveAsModalOpen(false);
        }
        return duplicateExist;
      }),
    );
  };

  const updateExistingFilter = () => {
    const filterPrefClone = _.cloneDeep(filterPreferences);
    removeSessionValue(SessionKey.globalSearchReset, StorageType.sessionStorage);
    removeSessionValue(SessionKey[`GlobalSearchGrid-${category}`], StorageType.sessionStorage);
    dispatch(
      userDevicePreferenceActions.saveUserGridPreferenceAction({
        id: SessionKey[`GlobalSearchGrid-${category}`],
        value: {
          ...(filterPrefClone?.value ?? {}),
          filterAttributes: {
            ...(filterPrefClone?.value?.filterAttributes ?? {}),
            filterSet: _.cloneDeep(filterPrefClone?.value?.filterAttributes?.filterSet)?.map(item => {
              if (item.name === filterPrefClone?.value?.selectedFilter?.name) {
                item.value = Object.entries(JSON.parse(JSON.stringify(filterAttributes?.filters)));
              }
              return item;
            }),
          },
        },
      }),
    );
    dispatch(
      globalActions.setSnackBar({
        message: t('search.filter.addFilterSuccess'),
        severity: 'success',
      }),
    );
    setSaveModalOpen(false);
    setEnableSaveButton(false);
    setEnableSaveAsButton(false);
  };

  useEffect(() => {
    setFilterOptions(
      _.cloneDeep(filterPreferences?.value?.filterAttributes?.filterSet || []).sort((a, b) =>
        a?.name?.toLowerCase().localeCompare(b?.name?.toLowerCase()),
      ),
    );
    if (
      filterOptions.length !== 0 &&
      filterOptions.length > (filterPreferences?.value?.filterAttributes?.filterSet?.length || 0) &&
      !filterPreferences?.value?.filterAttributes?.filterSet?.find(item => item.name === selectedFilterOption?.name)
    ) {
      // Filter option was removed and it was the one that was currently selected
      setSelectedFilterOption(undefined);
      dispatch(
        userDevicePreferenceActions.saveUserGridPreferenceAction({
          id: `GlobalSearchGrid-${category}`,
          value: {
            ...filterPreferences?.value,
            selectedFilter: null,
          },
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterPreferences?.value?.filterAttributes?.filterSet]);

  return (
    <LayoutGridItem>
      <LayoutGrid alignContent="flex-end">
        <LayoutGridItem>
          {facilityLinkDecision.enabled &&
            category === SearchType.facility &&
            (facilityLink !== '' ? (
              <a href={facilityLink} target="_blank" className={classes.link} rel="noreferrer">
                <Button variant="contained" disableElevation color="primary" className={classes.button}>
                  {`MANAGE FACILITY`}
                  <OpenInNewIcon className={classes.icon} />
                </Button>
              </a>
            ) : (
              <Button variant="contained" color="primary" disabled={true} className={classes.button}>
                {`MANAGE FACILITY`}
                <OpenInNewIcon className={classes.icon} />
              </Button>
            ))}
        </LayoutGridItem>
        <LayoutGridItem alignContent="center">
          <Typography variant="body1">My Views: </Typography>
        </LayoutGridItem>
        {displayDropdown && (
          <LayoutGridItem>
            <FilterPreferenceSelector
              filterOptions={filterOptions.map(item => item.name)}
              selectedFilterOption={selectedFilterOption?.name || 'SELECT FILTERS'}
              setMangeFilters={setMangeFilters}
              handleFilterOptionsChange={handleFilterOptionsChange}
              size="medium"
            />
          </LayoutGridItem>
        )}
        <LayoutGridItem>
          <Button
            disableElevation
            color="primary"
            size="medium"
            className={classes.button}
            variant="contained"
            onClick={() => handleSaveExistingFilter()}
            disabled={
              filterOptions.length === 0 ||
              selectedFilterOption?.name === 'SELECT FILTERS' ||
              !enableSaveButton ||
              !!searchString
            }
          >{`Save`}</Button>
        </LayoutGridItem>
        {/** Code: enableSaveAsButton OR Display Save As Button to when no filters have been saved yet */}
        <LayoutGridItem>
          <Button
            disableElevation
            color="primary"
            size="medium"
            onClick={() => setSaveAsModalOpen(true)}
            className={classes.button}
            variant="contained"
            disabled={!(enableSaveAsButton && filtersApplied) || !displayDropdown || !!searchString}
          >{`Save As`}</Button>
        </LayoutGridItem>
      </LayoutGrid>
      {/* DIALOGS */}
      <DuplicateFilterAlertModal {...{ isAlertModalOpen, setAlertModalOpen, setMangeFilters, existingFilterSet }} />
      <SaveModal {...{ isSaveModalOpen, setSaveModalOpen, updateExistingFilter }} />
      <SaveAsModal
        {...{
          isSaveAsModalOpen,
          setSaveAsModalOpen,
          disableSaveButton,
          handleFilterNameChange,
          addNewFilter,
          errorText,
          chips,
        }}
      />
      {isManageFilters && (
        <ManageFilter open={isManageFilters} onClose={() => setMangeFilters(false)} category={category} />
      )}
    </LayoutGridItem>
  );
};
