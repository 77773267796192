import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { configureAppStore } from 'store/configureStore';
import { GlobalStyle, theme } from 'styles/global-styles';
import { Routes } from 'app/Routes';
import 'sanitize.css/sanitize.css';
import { ThemeProvider, StyledEngineProvider } from 'amn-ui-core';
import { AppInsightsContextProvider } from 'app-insights/AppInsightsContext';
import { LogRocketLogging } from 'log-rocket/LogRocket';
import { ApplicationInsightsWrapper } from 'app/ApplicationInsightsWrapper';
import { GlobalDisplay } from './GlobalDisplay';
import { MaintenancePage } from '../../maintenance';
import { createInstance, OptimizelyFeature, OptimizelyProvider, setLogLevel } from '@optimizely/react-sdk';
import { MsalProvider } from '@azure/msal-react';
import { msalInstance } from 'oidc/userManager';
import { AxiosWrapper } from 'utils/AxiosWrapper';
import { RecentHistory } from './RecentHistory';
import CssBaseline from '@mui/material/CssBaseline';
import { VisibilityProvider } from '@AMIEWEB/VisblityChange/VisiblityContext';

export const { store } = configureAppStore();

export const ApplicationRoot: React.FC = () => {
  const optimizely = createInstance({
    sdkKey: globalThis?.app?.env?.REACT_APP_OPTIMIZELY_SDK_KEY,
    datafileOptions: {
      autoUpdate: true,
      updateInterval: 300000,
    },
  });

  setLogLevel('error');

  return (
    <>
      <MsalProvider instance={msalInstance}>
        <StyledEngineProvider injectFirst>
          <GlobalStyle />
          <CssBaseline>
            <ThemeProvider theme={theme}>
              <Provider store={store}>
                <React.StrictMode>
                  <AppInsightsContextProvider>
                    <div>
                      <AxiosWrapper>
                        <BrowserRouter>
                          <ApplicationInsightsWrapper>
                            <OptimizelyProvider optimizely={optimizely} userId={'all'}>
                              <OptimizelyFeature feature="maintenance" autoUpdate>
                                {isEnabled => (
                                  <GlobalDisplay>
                                    <VisibilityProvider>
                                      <LogRocketLogging>
                                        {isEnabled ? (
                                          <MaintenancePage />
                                        ) : (
                                          <RecentHistory>
                                            <Routes />
                                          </RecentHistory>
                                        )}
                                      </LogRocketLogging>
                                    </VisibilityProvider>
                                  </GlobalDisplay>
                                )}
                              </OptimizelyFeature>
                            </OptimizelyProvider>
                          </ApplicationInsightsWrapper>
                        </BrowserRouter>
                      </AxiosWrapper>
                    </div>
                  </AppInsightsContextProvider>
                </React.StrictMode>
              </Provider>
            </ThemeProvider>
          </CssBaseline>
        </StyledEngineProvider>
      </MsalProvider>
    </>
  );
};
