import { Box, CircularProgress, Grid, IconButton, Paper, Skeleton } from 'amn-ui-core';
import React, { createContext, useEffect } from 'react';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import { makeStyles } from 'tss-react/mui';
import { Editor } from '@tinymce/tinymce-react';
import { Editor as EditorType } from 'tinymce';
import { TemplateTitle as ConfirmationTitle } from './ClientConfirmation/TemplateTitle';
import { Sidebar } from './Sidebar';
import GeneratingTokens from '../../../assets/images/ClientConfirmation/GeneratingTokens.svg';
import GreyGeneratingTokens from '../../../assets/images/ClientConfirmation/GreyGeneratingTokens.svg';
import ContactEmail from '../../../assets/images/ClientConfirmation/ContactEmail.svg';
import GreyContactEmail from '../../../assets/images/ClientConfirmation/GreyContactEmail.svg';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import { TokensWrapper } from './TokensWrapper';
import { TemplatesWrapper as ConfirmationTemplates } from './ClientConfirmation/TemplatesWrapper';
import { TemplatesWrapper as ContractTemplates } from './CandidateContract/TemplatesWrapper';
import { CustomTooltip } from '@AMIEWEB/Common';
import { IAppliedMergeToken, ITemplate } from 'store/redux-store/confirmation-template-editor/types';
import { useTranslation } from 'react-i18next';
import { EngineTemplateTitle as ConfirmationEngineTitle } from './ClientConfirmation/EngineTemplateTitle';
import { EngineTemplateTitle as ContractEngineTitle } from './CandidateContract/EngineTemplateTitle';
import { EngineTemplates as ContractEngineTemplates } from './CandidateContract/EngineTemplates';
import { EngineTemplates as ConfirmationEngineTemplates } from './ClientConfirmation/EngineTemplates';
import { PdfAction, PreviewAction, PreviewScreen } from './customComponents';
import { customSwitch } from 'utils/customSwitchCase/customSwitch';
import { TemplateTitle as ContractTitle } from './CandidateContract/TemplateTitle';
import { IContractTemplate } from 'store/redux-store/contract-template-editor/types';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import { IContractDoc } from 'store/redux-store/placement-candidate-contract-doc-editor/types';
import { PublishTokensAction } from './customComponents/PublishTokensAction';
import { IEditorPreference } from 'oidc/UserDevicePreference/userPreference.redux';
import { PaneDivider, SplitPane, SplitPaneLeft, SplitPaneRight } from 'app/ComponentLibrary/SplitPanel';
import { usePaneResetter } from 'app/ComponentLibrary/SplitPanel/hooks';
import { ConfirmationDocumentType, ConfirmationStatus } from 'store/redux-store/placement-client-confirmation-doc-editor/types';
import { CopyAction } from './customComponents/CopyAction';

const useStyles = makeStyles<{ renderSideView: boolean; disablePointerEvents: boolean }>()(
  (theme, { renderSideView, disablePointerEvents }) => ({
    spinner: {
      left: '50%',
      position: 'absolute',
      top: '50vh',
    },
    container: {
      padding: `0px 0px 0px ${theme.spacing(2)}`,
    },
    paper: {
      padding: 12,
      border: `1px solid ${theme.palette.system.lightGrey}`,
    },
    innerPaperContent: {
      border: `1px solid ${theme.palette.system.borderColor1}`,
      backgroundColor: theme.palette.system.smokeWhite,
    },
    editorContainer: {
      transition: 'width 0.8s ease-in-out',
      width: renderSideView ? 'calc(100% - 330px)' : '100%',
    },
    sidebar: {
      width: 342,
      margin: '-12px -12px -12px 0',
    },
    titleDiv: {
      display: 'flex',
      alignItems: 'center',
    },
    templateSelectBox: {
      height: 760,
      background: `${theme.palette.system.lightGrey} 0% 0% no-repeat padding-box`,
      opacity: 1,
    },
    templateSelectDiv: {
      height: 'inherit',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    templateSelectText: {
      textAlign: 'center',
      marginBottom: '4rem',
      font: 'italic normal normal 14px/19px Roboto',
      color: theme.palette.system.darkGray,
      opacity: 1,
    },
    editor: {
      '& .tox-edit-area__iframe': {
        pointerEvents: disablePointerEvents ? 'none' : undefined,
      },
      '& .tox-tinymce': {
        border: 'none',
        borderRadius: 0,
      },
      '& .tox-editor-header': {
        boxShadow: 'none',
        backgroundColor: `${theme.palette.framework.system.whisper} !important`,
        borderBottom: `1px solid ${theme.palette.system.borderColor1} !important`,
        borderTop: `1px solid ${theme.palette.system.borderColor1} !important`,
      },
      '& .tox-toolbar, .tox-toolbar__primary': {
        backgroundColor: `${theme.palette.framework.system.whisper} !important`,
      },
    },
    preview: {
      'object: first-child': {
        height: '75vh',
        width: '100%',
      },
    },
  }),
);

const REGEX = /&lt;&lt;([A-Za-z0-9:.,# ]+)&gt;&gt;/g;

export const TemplateEditorContext = createContext<{
  documentType: 'candidate-contract' | 'client-confirmation' | null;
}>({
  documentType: null,
});

interface TemplateType extends ITemplate, IContractTemplate, IContractDoc {}
export type SidePanel = 'documents' | 'templates' | 'tokens' | 'contacts' | null;

export const TemplateEditorWrapper: React.FC<{
  templates: TemplateType[];
  selectedTemplate: TemplateType;
  onTemplateClick: (item: TemplateType) => void;
  handleDelete?: (item: number) => void;
  onPublish?: (template: TemplateType) => void;
  onSave?: (template: TemplateType) => void;
  pageTitle: React.ReactNode;
  userIsReadOnly: boolean;
  restrictToMergeEngine?: boolean;
  history?: any;
  document?: { data: string; version: number; fileName?: string; documentId?: string };
  renderPreview?: boolean;
  renderPdfView?: boolean;
  documentType: 'candidate-contract' | 'client-confirmation';
  sideViewProps: [SidePanel, (value: React.SetStateAction<SidePanel>) => void];
  editorPreference?: IEditorPreference;
  updateTemplatePreferences?: (pref) => void;
  onSplitPaneDragEnd?: ({ clientHeight, clientWidth }) => void;
  [key: string]: any;
}> = ({
  templates,
  selectedTemplate,
  onTemplateClick,
  handleDelete,
  onPublish,
  onSave,
  userIsReadOnly,
  pageTitle,
  prePageTitleAddOns = [],
  postPageTitleAddOns = [],
  restrictToMergeEngine = false,
  renderPreview = false,
  renderPdfView = false,
  history,
  document: _document,
  documentType,
  sideViewProps: [sideView = 'templates', setSideView],
  editorPreference,
  updateTemplatePreferences,
  onSplitPaneDragEnd,
  ...props
}) => {
  const [editorContent, setEditorContent] = React.useState<string | null>(null);
  const [templateTitle, setTemplateTitle] = React.useState(null);
  const [_isDirty, _setIsDirty] = React.useState<boolean>(false);
  const [isDraggingPaneDivider, setDraggingPaneDivider] = React.useState(false);
  const [paneWidthRatio, setPaneWidthRatio] = React.useState<number | null>(window.innerWidth);
  const { classes, cx } = useStyles({ renderSideView: !!sideView, disablePointerEvents: isDraggingPaneDivider });

  const { t } = useTranslation();

  const editorRef = React.useRef<EditorType>(null);
  const handleContentChange = newContent => {
    setEditorContent(newContent);
  };

  const { _tokens, isContactTokensSelected } = React.useMemo(() => {
    const appliedTokens = editorContent?.match?.(REGEX) ?? ([] as unknown as RegExpMatchArray);
    const tokensGroup = Object.fromEntries(
      Object.entries<{ label: string; tokens: IAppliedMergeToken[] }>(props.mergeTokenList)?.map(pair => {
        const updatedTokens = pair[1]?.tokens?.map(token => {
          const count = appliedTokens.reduce(
            (countOfOccurrence, item) =>
              item === `&lt;&lt;${token.mergeFieldText}&gt;&gt;` ? ++countOfOccurrence : countOfOccurrence,
            0,
          );
          return { ...token, count };
        });
        return [pair[0], { label: pair[1].label, tokens: updatedTokens }];
      }),
    );

    const isContactTokensSelected = tokensGroup?.clientContacts?.tokens?.some(x => x.count > 0);
    return {
      isContactTokensSelected,
      _tokens: (
        <TokensWrapper
          tokensGroup={tokensGroup}
          editorInstance={editorRef.current}
          isNewTemplate={selectedTemplate?.templateId === Number.MIN_VALUE}
          disableTokenInteractions={props.disableTokenInteractions}
        />
      ),
    };
  }, [editorContent, props.mergeTokenList, props.disableTokenInteractions, selectedTemplate?.templateId]);

  React.useEffect(() => {
    if (!!editorRef.current) {
      editorRef.current.setContent('', { set: true });
      /** resetContent needs to be called with timeout, since earlier setContent is has an internal async behavior */
      setTimeout(() => {
        editorRef.current.resetContent(selectedTemplate?.templateText ?? null);
      }, 100);
    }
    setTemplateTitle(selectedTemplate ? selectedTemplate.templateName : null);
    /** Reset view to template sideview on removing selection */
    if (!selectedTemplate && !props.determineSidePanelExternally) setSideView('templates');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTemplate]);

  const _onCancel = React.useCallback(
    (callbackFn, ...args) => {
      if (selectedTemplate) {
        editorRef.current.resetContent(selectedTemplate.templateText);
        setTemplateTitle(selectedTemplate ? selectedTemplate.templateName : null);
      }
      props.onCancelEvent?.(selectedTemplate, ...args);
      callbackFn?.();
    },
    [selectedTemplate, props.onCancelEvent],
  );

  const _onClose = React.useCallback(() => {
    onTemplateClick(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onTemplateClick]);

  const getTextContent = React.useCallback(
    () => {
      return editorRef.current.getContent();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedTemplate, templateTitle, editorRef.current],
  );

  const _onSave = React.useCallback(() => {
    onSave({
      ...selectedTemplate,
      templateName: templateTitle,
      templateText: editorRef.current.getContent(),
      isDraft: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTemplate, templateTitle, editorRef.current]);

  const _onPublish = React.useCallback(
    (templateName: string | null) => {
      onPublish({
        ...selectedTemplate,
        templateName,
        templateText: editorRef.current.getContent(),
        isDraft: false,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedTemplate, templateTitle, editorRef.current],
  );

  const _onCreate = React.useCallback(() => {
    props.onCreate(
      {
        ...selectedTemplate,
        templateText: editorRef.current.getContent(),
      },
      _isDirty,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_isDirty, selectedTemplate, props.onCreate]);

  const _onPreview = React.useCallback(() => {
    props.onPreview(editorRef.current?.getContent());
  }, []);

  const _onCopy = React.useCallback((documentId, hasDraft) => {
    props.onCopy(documentId, hasDraft);
  }, []);

  const _onEdit = React.useCallback(() => {
    props.onEdit();
  }, []);

  const _onSplitPaneDragEnd = React.useCallback(
    (...args) => {
      setDraggingPaneDivider(false);
      // @ts-ignore
      onSplitPaneDragEnd(...args);
    },
    [onSplitPaneDragEnd],
  );

  const _onSplitPaneDragStart = React.useCallback(() => setDraggingPaneDivider(true), []);

  React.useEffect(() => {
    /** To be called with timeout, since hasUndo func has an internal async behavior */
    setTimeout(
      () =>
        _setIsDirty(
          editorRef.current?.undoManager?.hasUndo?.() ||
            (!restrictToMergeEngine &&
              documentType === 'client-confirmation' &&
              templateTitle !== (selectedTemplate?.templateName ?? null)) ||
            props.forceDirtyState,
        ),
      100,
    );
  }, [
    editorContent,
    templateTitle,
    selectedTemplate?.templateName,
    selectedTemplate?.id,
    selectedTemplate?.documentId,
    props.forceDirtyState,
    restrictToMergeEngine,
    documentType,
  ]);

  const handleWindowResize = () => {
    const splitPaneWidth = document.getElementById('split-pane')?.clientWidth;
    const rightPaneWidth = document.getElementById('split-pane-right')?.clientWidth;

    if (!!splitPaneWidth && !!rightPaneWidth) {
      const adjustedPaneWidth = splitPaneWidth - rightPaneWidth + 10;
      const adjustedPaneWidthRatio = adjustedPaneWidth / splitPaneWidth;
      setPaneWidthRatio(adjustedPaneWidthRatio);
    }
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  React.useEffect(() => {
    if (props.determineSidePanelExternally) return;
    if (renderPreview || renderPdfView) {
      setSideView(renderPdfView ? 'templates' : null);
    } else {
      setSideView(props.forceSideView ?? 'templates');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.forceSideView, renderPdfView, renderPreview]);

  const handleBeforeUnload = React.useCallback(event => {
    event.preventDefault();
    event.returnValue = 'Changes you made may not be saved.';
  }, []);

  useEffect(() => {
    const promptListener = () => {
      if (_isDirty) {
        const confirmLeave = window.confirm('Changes you made may not be saved.');
        return confirmLeave;
      }
      return true;
    };

    const unblock = history.block(promptListener);
    return () => {
      unblock();
    };
  }, [_isDirty, history]);

  React.useEffect(() => {
    if (_isDirty) window.addEventListener('beforeunload', handleBeforeUnload);
    else window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [_isDirty, handleBeforeUnload]);

  const _templates = React.useMemo(
    () =>
      customSwitch({
        'client-confirmation': (
          <ConfirmationTemplates
            // @ts-ignore
            templates={templates}
            // @ts-ignore
            selectedTemplate={selectedTemplate}
            onTemplateClick={onTemplateClick}
            handleDelete={handleDelete}
            isDeletingDraft={props.isDeletingDraft}
            userIsReadOnly={userIsReadOnly}
            selectedTemplateIsDirty={_isDirty}
            onCancel={_onCancel}
            restrictToMergeEngine={restrictToMergeEngine}
            editorPreference={editorPreference}
            updatePreferences={updateTemplatePreferences}
          />
        ),
        'candidate-contract': restrictToMergeEngine ? (
          <ContractEngineTemplates
            // @ts-ignore
            templates={templates}
            // @ts-ignore
            selectedTemplate={selectedTemplate}
            onTemplateClick={onTemplateClick}
          />
        ) : (
          <ContractTemplates
            // @ts-ignore
            templates={templates}
            // @ts-ignore
            selectedTemplate={selectedTemplate}
            selectedTemplateIsDirty={_isDirty}
            onTemplateClick={onTemplateClick}
            onCancel={_onCancel}
          />
        ),
      })(null)(documentType),
    [
      templates,
      selectedTemplate,
      onTemplateClick,
      handleDelete,
      props.isDeletingDraft,
      userIsReadOnly,
      _isDirty,
      _onCancel,
      restrictToMergeEngine,
      editorPreference,
      updateTemplatePreferences,
      documentType,
    ],
  );

  const _documents = React.useMemo(
    () =>
      restrictToMergeEngine && documentType === 'client-confirmation' ? (
        <ConfirmationEngineTemplates
          onDocumentClick={onTemplateClick}
          selectedDoc={selectedTemplate}
          setSideView={setSideView}
        />
      ) : null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [documentType, onTemplateClick, restrictToMergeEngine, selectedTemplate],
  );

  const titleIcons = React.useCallback(
    titleOpt =>
      customSwitch({
        contacts: (
          <CustomTooltip
            disabled={!isContactTokensSelected}
            tooltipContent={t('templateEditor.sidebar.tooltip.contacts')}
          >
            <IconBtn
              isSelected={sideView === 'contacts'}
              onClick={() => setSideView('contacts')}
              disabled={!selectedTemplate || !isContactTokensSelected || renderPdfView}
            >
              <img
                src={!(selectedTemplate && isContactTokensSelected) || renderPdfView ? GreyContactEmail : ContactEmail}
                alt="Contacts Icon"
              />
            </IconBtn>
          </CustomTooltip>
        ),
        tokens: (
          <CustomTooltip
            disabled={sideView === 'tokens' || renderPdfView}
            tooltipContent={t('templateEditor.sidebar.tooltip.insertTokens')}
          >
            <IconBtn
              isSelected={sideView === 'tokens'}
              onClick={() => setSideView('tokens')}
              sideView={sideView}
              disabled={!selectedTemplate || renderPreview || renderPdfView}
            >
              <img
                src={!selectedTemplate || renderPreview || renderPdfView ? GreyGeneratingTokens : GeneratingTokens}
                alt="Generating Tokens Icon"
              />
            </IconBtn>
          </CustomTooltip>
        ),
        templates: (
          <CustomTooltip
            disabled={sideView === 'templates'}
            tooltipContent={
              documentType === 'candidate-contract'
                ? t('templateEditor.sidebar.tooltip.contracts')
                : t('templateEditor.sidebar.tooltip.templates')
            }
          >
            <IconBtn
              isSelected={sideView === 'templates'}
              onClick={() => setSideView('templates')}
              disabled={renderPreview}
            >
              {documentType === 'candidate-contract' && restrictToMergeEngine ? (
                <HistoryEduIcon color={renderPreview ? 'disabled' : 'primary'} />
              ) : (
                <LibraryBooksOutlinedIcon color={renderPreview ? 'disabled' : 'primary'} />
              )}
            </IconBtn>
          </CustomTooltip>
        ),
        documents: (
          <CustomTooltip
            disabled={sideView === 'documents'}
            tooltipContent={t('templateEditor.sidebar.tooltip.clientConfirmations')}
          >
            <IconBtn
              isSelected={sideView === 'documents'}
              onClick={() => setSideView('documents')}
              disabled={renderPreview}
            >
              <TaskOutlinedIcon color={renderPreview ? 'disabled' : 'primary'} />
            </IconBtn>
          </CustomTooltip>
        ),
      })(null)(titleOpt),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      documentType,
      isContactTokensSelected,
      renderPdfView,
      renderPreview,
      restrictToMergeEngine,
      selectedTemplate,
      sideView,
    ],
  );

  const outerTitleAction = React.useMemo(
    () =>
      restrictToMergeEngine
        ? customSwitch({
            'client-confirmation': (
              <ConfirmationEngineTitle
                templateTitle={templateTitle}
                isTemplateSelected={!!selectedTemplate}
                // @ts-ignore
                selectedTemplate={selectedTemplate}
                isDirty={_isDirty}
                userIsReadOnly={userIsReadOnly}
                onCancel={_onCancel}
                onSave={_onSave}
                // @ts-ignore
                templates={templates}
                switchToDraft={onTemplateClick}
                forceModalCloseFlag={props.forceModalCloseFlag}
                onCreate={_onCreate}
                setSideView={setSideView}
                isContactTokensSelected={isContactTokensSelected}
                contactsWarningMessage={props.contactsWarningMessage}
                placementId={props.placementId}
              />
            ),
            'candidate-contract': (
              <ContractEngineTitle
                templateTitle={templateTitle}
                isTemplateSelected={!!selectedTemplate}
                isDirty={_isDirty}
                userIsReadOnly={userIsReadOnly}
                onCancel={_onCancel}
                onSave={_onSave}
                onCreate={_onCreate}
                switchToDraft
                // @ts-ignore
                templates={templates}
                // @ts-ignore
                selectedTemplate={selectedTemplate}
                forceModalCloseFlag={props.forceModalCloseFlag}
                placementId={props.placementId}
              />
            ),
          })(null)(documentType)
        : null,

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      _isDirty,
      _onCancel,
      _onCreate,
      _onSave,
      documentType,
      isContactTokensSelected,
      onTemplateClick,
      props.contactsWarningMessage,
      props.forceModalCloseFlag,
      props.placementId,
      restrictToMergeEngine,
      selectedTemplate,
      templateTitle,
      templates,
      userIsReadOnly,
    ],
  );

  const documentId = selectedTemplate?.documentId;
  const isWebDoc = selectedTemplate?.source === 'Web' &&  (selectedTemplate?.status && selectedTemplate?.status !== 'Draft');
  const shouldDissable = !documentId || !selectedTemplate || !isWebDoc;
  const tooltopMessage = isWebDoc || (selectedTemplate?.status && selectedTemplate?.status === 'Draft') ?
      (selectedTemplate?.status && selectedTemplate?.status === 'Draft') ? t('templateEditor.copyTemplateDraftDissable') : t('templateEditor.copyTemplate')
      :  t('templateEditor.copyTemplateDissable');

  const innerTitleAction = React.useMemo(
    () =>
      restrictToMergeEngine ? (
        renderPdfView ?
        documentType === ConfirmationDocumentType
        && (selectedTemplate?.status === ConfirmationStatus.notSent
          || selectedTemplate?.status === ConfirmationStatus.sent) ?
        (
          <Grid container direction="row" justifyContent="space-between" sx={{ padding: '9px' }}>
            <Grid item sx={{ color: theme => theme.palette.system.darkGray, fontWeight: 450 }}>
              {t('templateEditor.pdfAction', { documentId })}
            </Grid>
            <Grid item sx={{ display: 'flex', gap: theme => theme.spacing(2) }}>
              <CopyAction
                previewMode={renderPreview}
                onCopy={_onCopy}
                previewIsloading={props.previewIsloading}
                hasDraft = {props.hasDraft}
                setSideView={setSideView}
                selectedDoc={selectedTemplate}
                tooltopMessage={!!documentId ? tooltopMessage : null}
                shouldDissable={shouldDissable}
                contactsWarningMessage={props.contactsWarningMessage}
                disablePreview={!selectedTemplate || props.disablePreview}
              />
            </Grid>
          </Grid>
        ) :
        (
          // @ts-ignore
            <PdfAction {..._document} />
        ) : (
          <Grid container direction="row" justifyContent="space-between" sx={{ padding: '9px' }}>
            <Grid item sx={{ color: theme => theme.palette.system.darkGray, fontWeight: 450 }}>
              {renderPreview ? t('templateEditor.preview') : t('templateEditor.edit')}
            </Grid>
            <Grid item sx={{ display: 'flex', gap: theme => theme.spacing(2) }}>
              {restrictToMergeEngine && documentType === ConfirmationDocumentType ? (
                <>
                <CopyAction
                  previewMode={renderPreview}
                  onCopy={null}
                  previewIsloading={false}
                  hasDraft = {props.hasDraft}
                  setSideView={null}
                  selectedDoc={selectedTemplate}
                  tooltopMessage={selectedTemplate ? tooltopMessage : null}
                  shouldDissable={shouldDissable}
                  contactsWarningMessage={props.contactsWarningMessage}
                  disablePreview={!selectedTemplate || props.disablePreview}
                />
                <PublishTokensAction
                  previewMode={renderPreview}
                  setSideView={setSideView}
                  placementId={props.placementId}
                  selectedTemplate={selectedTemplate}
                  isContactTokensSelected={isContactTokensSelected}
                  atleastOneContactIsSelected={props.atleastOneContactIsSelected}
                  contactsWarningMessage={props.contactsWarningMessage}
                  disablePreview={!selectedTemplate || props.disablePreview}
                  getCurrentTemplateText={getTextContent}
                  editor={editorRef}
                />
                </>
              ) : null}
              <PreviewAction
                onPreview={_onPreview}
                onEdit={_onEdit}
                previewMode={renderPreview}
                previewIsloading={props.previewIsloading}
                setSideView={setSideView}
                contactsWarningMessage={props.contactsWarningMessage}
                disablePreview={!selectedTemplate || props.disablePreview}
              />
            </Grid>
          </Grid>
        )
      ) : (
        customSwitch({
          'candidate-contract': (
            <ContractTitle
              templateTitle={templateTitle}
              isTemplateSelected={!!selectedTemplate}
              // @ts-ignore
              selectedTemplate={selectedTemplate}
              // @ts-ignore
              templates={templates}
              isDirty={_isDirty}
              onCancel={_onCancel}
              onClose={_onClose}
              onPublish={_onPublish}
              forceModalCloseFlag={props.forceModalCloseFlag}
              isPublishing={props.isPublishing}
              userIsReadOnly={userIsReadOnly}
            />
          ),
          'client-confirmation': (
            <ConfirmationTitle
              templateTitle={templateTitle}
              onTitleChange={newValue => setTemplateTitle(newValue)}
              isTemplateSelected={!!selectedTemplate}
              // @ts-ignore
              selectedTemplate={selectedTemplate}
              // @ts-ignore
              templates={templates}
              isNewTemplate={selectedTemplate?.templateId === Number.MIN_VALUE}
              isDirty={_isDirty}
              onCancel={_onCancel}
              onClose={_onClose}
              onSave={_onSave}
              onPublish={_onPublish}
              forceModalCloseFlag={props.forceModalCloseFlag}
              isSavingDraft={props.isSavingDraft}
              isPublishing={props.isPublishing}
              userIsReadOnly={userIsReadOnly}
            />
          ),
        })(null)(documentType)
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      _isDirty,
      _onCancel,
      _onClose,
      _onEdit,
      _onPreview,
      _onPublish,
      _onSave,
      _document,
      documentType,
      getTextContent,
      isContactTokensSelected,
      props.atleastOneContactIsSelected,
      props.contactsWarningMessage,
      props.disablePreview,
      props.forceModalCloseFlag,
      props.isPublishing,
      props.isSavingDraft,
      props.placementId,
      props.previewIsloading,
      renderPdfView,
      renderPreview,
      restrictToMergeEngine,
      selectedTemplate,
      templateTitle,
      templates,
      userIsReadOnly,
    ],
  );

  const leftPaneRef = React.useRef(null);
  const rightPaneRef = React.useRef(null);

  /** Code: Resize left pane on closing side panel */
  usePaneResetter({ pane: leftPaneRef.current, complementaryPaneExists: !!sideView });

  React.useEffect(() => {
    if(editorPreference?.editorWidth){
      setPaneWidthRatio(editorPreference?.editorWidth ?? window.innerWidth)
    }
  },[editorPreference?.editorWidth],);

  return props.isLoading ? (
    <CircularProgress className={classes.spinner} />
  ) : (
    <TemplateEditorContext.Provider value={{ documentType }}>
      <Grid container direction="column" classes={{ container: classes.container }}>
        <Grid item>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item classes={{ root: classes.titleDiv }}>
              {pageTitle}
            </Grid>
            <Grid item>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                {prePageTitleAddOns?.map((addon, index) => (
                  <React.Fragment key={index}>{addon}</React.Fragment>
                ))}
                {props.titleIcons?.map(x => titleIcons(x))}
                {postPageTitleAddOns?.map((addon, index) => (
                  <React.Fragment key={index}>{addon}</React.Fragment>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Paper classes={{ root: classes.paper }}>
            <SplitPane
              id={'split-pane'}
              Element={Grid}
              container
              direction="row"
              wrap="nowrap"
              onSplitPaneDragStart={_onSplitPaneDragStart}
              onSplitPaneDragEnd={_onSplitPaneDragEnd}
              forceClientWidth={paneWidthRatio}
              paneRefs={{ leftPaneRef, rightPaneRef }}
              leftPaneLimits={{ default: 0.8, minWidth: 0.5, maxWidth: 0.8 }}
            >
              <SplitPaneLeft id={'split-pane-left'} Element={Grid} item style={{ flex: 1 }} classes={{ root: classes.editorContainer }} ref={leftPaneRef}>
                {outerTitleAction}
                <Grid container direction="column" classes={{ root: classes.innerPaperContent }}>
                  <Grid item>{innerTitleAction}</Grid>
                  <Grid
                    item
                    className={cx({
                      [classes.editor]: !!selectedTemplate,
                      [classes.preview]: renderPreview || renderPdfView,
                    })}
                  >
                    {renderPreview || renderPdfView ? (
                      <PreviewScreen
                        document={_document?.data}
                        htmlVersion={renderPreview}
                        disablePointerEvents={isDraggingPaneDivider}
                      />
                    ) : null}
                    {/* Code: Use of visibility styling allows to retain editor state on switching b/w editor and preview screens */}
                    <Box sx={renderPreview || renderPdfView ? { display: 'none' } : undefined}>
                      {!!selectedTemplate && !renderPdfView ? (
                        <Editor
                          apiKey={globalThis?.app?.env?.REACT_APP_TINYMCE_API_KEY}
                          init={{
                            branding: false,
                            height: 760,
                            menubar: false,
                            statusbar: false,
                            plugins: ['advlist autolink lists link image charmap print preview anchor'],
                            toolbar:
                              'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | image',
                            content_style:
                              'body { background-color: rgb(255, 255, 255); border:none; padding: 1rem 1rem 0; margin: 0; min-height: 97.8vh } html { margin: 0px 100px; background-color: lightgrey; ::-webkit-scrollbar { opacity: 0; background: transparent; width: 14px; } ::-webkit-scrollbar-button { display: none; } ::-webkit-scrollbar-thumb { border-radius: 9999px; border: 3px solid rgba(0,0,0,0.0); background-color: #C7C7C7; background-clip: padding-box; } ::-webkit-scrollbar-track: { background: transparent; }  }',
                          }}
                          onEditorChange={handleContentChange}
                          value={editorContent}
                          onInit={(e, editor) => {
                            editorRef.current = editor;
                            editor.setContent(selectedTemplate.templateText, { initial: true, load: true, set: true });
                          }}
                        />
                      ) : (
                        <Box className={classes.templateSelectBox}>
                          <div className={classes.templateSelectDiv}>
                            <p className={classes.templateSelectText}>
                              {restrictToMergeEngine
                                ? documentType === 'client-confirmation'
                                  ? props.noDocExists
                                    ? t('templateEditor.selectContractTemplate')
                                    : t('templateEditor.selectConfirmationTemplateEngine')
                                  : t('templateEditor.selectContractTemplateEngine')
                                : documentType === 'client-confirmation'
                                ? t('templateEditor.selectConfirmationTemplate')
                                : t('templateEditor.selectContractTemplate')}
                            </p>
                          </div>
                        </Box>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </SplitPaneLeft>
              {sideView ? (
                <>
                  <PaneDivider Element={Grid} component="div" orientation="vertical" />
                  <SplitPaneRight id={'split-pane-right'} Element={Grid} item classes={{ root: classes.sidebar }} ref={rightPaneRef}>
                    <Sidebar
                      title={
                        sideView === 'templates'
                          ? restrictToMergeEngine && documentType === 'candidate-contract'
                            ? t('templateEditor.sidebar.contracts')
                            : t('templateEditor.sidebar.templates')
                          : sideView === 'tokens'
                          ? t('templateEditor.sidebar.tokens')
                          : sideView === 'contacts'
                          ? t('templateEditor.sidebar.contacts')
                          : sideView === 'documents'
                          ? t('templateEditor.sidebar.clientConfirmations')
                          : null
                      }
                      onClose={() => setSideView(null)}
                    >
                      {props.isLoadingSideView ? (
                        <Skeleton />
                      ) : sideView === 'templates' ? (
                        _templates
                      ) : sideView === 'tokens' ? (
                        _tokens
                      ) : sideView === 'documents' ? (
                        _documents
                      ) : sideView === 'contacts' ? (
                        props.contactsSideView
                      ) : null}
                    </Sidebar>
                  </SplitPaneRight>
                </>
              ) : null}
            </SplitPane>
          </Paper>
        </Grid>
      </Grid>
    </TemplateEditorContext.Provider>
  );
};

const useIconStyle = makeStyles()(theme => ({
  iconBtn: {
    '&:hover': {
      backgroundColor: 'rgba(0, 111, 185, 0.1)',
    },
  },
  selectedIcon: {
    width: 40,
    height: 45,
    padding: '10px 8px',
    display: 'flex',
    borderBottom: `4px solid ${theme.palette.system.navyBlue}`,
    backgroundColor: theme.palette.components.box.container.backgroundColor,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export const IconBtn = ({ isSelected, sideView = null, disabled = false, ...props }) => {
  const { classes } = useIconStyle();
  return isSelected ? (
    <Box className={classes.selectedIcon}>{props.children}</Box>
  ) : (
    <>
      <IconButton
        color="secondary"
        size="medium"
        disabled={disabled}
        className={classes.iconBtn}
        onClick={props.onClick}
      >
        {props.children}
      </IconButton>
    </>
  );
};