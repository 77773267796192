import { Avatar, Badge, Grid, Paper } from 'amn-ui-core';
import TaskIcon from 'app/assets/images/Header/Task.svg';
import { makeStyles } from 'tss-react/mui';
import icon from 'app/assets/images/AMN-icon.png';
import ReadOnlyIcon from 'app/assets/images/ReadOnly.svg';
import GraphicsLogo from 'app/assets/images/graphics_logo.svg';
import MessageInboxDisabled from 'app/assets/images/Header/MessageDisabled.svg';
import { GlobalSearch } from 'app/components/GlobalSearch/GlobalSearch';
import moment from 'moment';
import { selectUser } from 'oidc/user.selectors';
import { userInitials } from 'oidc/userHelper';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CustomTooltip } from '../Common/Tooltips';
import { MessageInbox } from '../Notification/MessageInbox/MessageInbox';
import NotificationWrapper from '../Notification/NotificationWrapper';
import { Toast } from '../Notification/Toast';
import HelpIcon from 'app/assets/images/Header/Help-Icon.svg';
import { ProfileDrawer } from '../Profile/Drawer/ProfileDrawer';
import { isProfileOpen } from 'app/ApplicationRoot/Global.selectors';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { CueNotesNotifications } from '../Notification/Tasks/CueNotesNotification/CueNotesNotification';
import { TaskNotifications } from '../Notification/Tasks/Common/TaskNotifications';
import { useReadOnly } from 'oidc/userRoles';
import { useEnableSmsCommunication } from 'oidc/CommunicationEnabler';
import { OrderMatchNotification } from '../Order/OrderMatch/OrderMatchNotification';
import { selectCoveredInbox } from 'store/redux-store/user-coverage/selectors';
import { EmailDeliveryNotifications } from '@AMIEWEB/Notification/Tasks/Common/EmailDeliveryNotifications';
import { NavLink } from 'react-router-dom';
import { useDecision } from '@optimizely/react-sdk';
import { ff_notifications_ui_activity_feed_split } from 'app/constants/FeatureFlags/Tasks/Notifications/Keys';

const useStyles = makeStyles()(() => ({
  root: {
    height: '50px',
    backgroundColor: '#ffffff',
    border: '0.5px solid  #cccccc',
    alignItems: 'center',
    position: 'relative',
  },
  icon: {
    height: '35px',
    width: '50px',
  },
  logoImage: {
    alignSelf: 'center',
    height: 24,
    width: 23,
  },
  headerItems: {
    paddingLeft: '20px',
    height: '100%',
    alignItems: 'center',
  },
  icons: {
    alignSelf: 'center',
    justifyContent: 'center',
    marginLeft: 10,
    marginRight: '15px',
  },
  centerItem: {
    alignSelf: 'center',
    justifyContent: 'center',
    padding: '0 15px',
  },
  centerItemDisabled: {
    alignSelf: 'center',
    justifyContent: 'center',
    margin: '14px 11px',
  },
  title: {
    color: '#003E68',
    fontWeight: 'bold',
    fontSize: 20,
  },
  avatar: {
    color: 'white',
    backgroundColor: '#52429A',
    height: 32,
    width: 32.7,
    textTransform: 'uppercase',
    fontSize: '14px',
    cursor: 'pointer',
    marginTop: '8px',
    fontWeight: 500,
  },
  avatarContainer: {
    position: 'relative',
  },
  barSelection: {
    width: '50px',
    height: '5px',
    position: 'absolute',
    left: '5px',
    bottom: '-1px',
    backgroundColor: '#52429A',
    transition: '0.3s ease',
  },
  readOnlyWrapper: {
    position: 'relative',
  },
  readOnly: {
    position: 'absolute',
    right: '-6px',
    bottom: '-6px',
  },
  readOnlyTooltip: {
    fontWeight: 500,
  },
}));

export const Header: React.FC = () => {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  const userInfo = useSelector(selectUser);
  const dispatch = useDispatch();
  const profileOpen = useSelector(isProfileOpen);
  const enableSmsCommunication = useEnableSmsCommunication();
  const [activityFeedRefactoring] = useDecision(ff_notifications_ui_activity_feed_split);

  const { readOnly } = useReadOnly();
  const login = () => {
    dispatch(globalActions.setProfileOpen(!profileOpen));
  };
  const { activeInbox } = useSelector(selectCoveredInbox);

  const UserAvatar = () => {
    return (
      <div className={classes.readOnlyWrapper}>
        <Avatar data-avatar onClick={() => login()} className={classes.avatar}>
          {userInitials(userInfo)}
        </Avatar>
        {readOnly && <img src={ReadOnlyIcon} className={classes.readOnly} alt="ReadOnly" />}
      </div>
    );
  };

  return (
    <Paper square={true} className={classes.root}>
      <Grid container direction="row" className={classes.headerItems}>
        <Grid xs={2} item>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              {moment().format('MM') === '12' ? (
                <img src={GraphicsLogo} className={classes.icon} alt="GraphicsLogo" />
              ) : (
                <img src={icon} alt="AMN" className={classes.logoImage} />
              )}
            </Grid>
            <Grid item>
              <div style={{ display: 'inline' }} className={classes.title}>
                {t('appHeader.title')}
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={10} item>
          <Grid container item spacing={0} justifyContent="flex-end" alignItems="center">
            <Grid item>
              <GlobalSearch />
            </Grid>
            <Grid item className={classes.icons}>
              <Grid container>
                {!activityFeedRefactoring?.enabled && (
                  <Grid item className={classes.centerItem}>
                    <NavLink data-testid="task-mgmt-link" to={`/tasks`}>
                      <CustomTooltip standardMargin tooltipContent={t('appHeader.taskTitle')}>
                        <Badge>
                          <img src={TaskIcon} alt="Task" />
                        </Badge>
                      </CustomTooltip>
                    </NavLink>
                  </Grid>
                )}
                <Grid item>
                  <TaskNotifications userInfo={userInfo} />
                  <CueNotesNotifications />
                  <OrderMatchNotification />
                  <EmailDeliveryNotifications />
                </Grid>
                <Grid item>
                  {enableSmsCommunication ? (
                    !activeInbox?.coveredUserId && !activeInbox?.phoneNumber ? (
                      <CustomTooltip standardMargin tooltipContent={t('appHeader.disabledTextMessaging')}>
                        <div className={classes.centerItemDisabled}>
                          <img src={MessageInboxDisabled} alt="Message Inbox" />
                        </div>
                      </CustomTooltip>
                    ) : (
                      <MessageInbox userInfo={userInfo?.userInfo?.email} />
                    )
                  ) : (
                    <img src={MessageInboxDisabled} alt="Message Inbox" className={classes.centerItemDisabled} />
                  )}
                </Grid>
                <Grid item className={classes.centerItem}>
                  {/* <NavLink data-testid="task-mgmt-link" to={`/tasks`}>
                    <CustomTooltip standardMargin tooltipContent={t('appHeader.taskTitle')}> */}
                  <Badge>
                    <img src={HelpIcon} alt="Help" />
                  </Badge>
                  {/* </CustomTooltip>
                  </NavLink> */}
                </Grid>
                <div className={cx(classes.avatarContainer)}>
                  <Grid item className={classes.centerItem}>
                    {!profileOpen && userInfo?.userInfo?.email ? (
                      <CustomTooltip
                        standardMargin
                        tooltipContent={
                          <div>
                            <span>{userInfo?.userInfo?.email || ''}</span>
                            {readOnly && (
                              <>
                                <span>{` | `}</span>
                                <span className={classes.readOnlyTooltip}>{t('appHeader.readOnly')}</span>
                              </>
                            )}
                          </div>
                        }
                        value={<UserAvatar />}
                      />
                    ) : (
                      <UserAvatar />
                    )}
                  </Grid>
                  <div className={cx({ [classes.barSelection]: profileOpen })}></div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ProfileDrawer
        open={profileOpen}
        closeDrawer={event => {
          if (!event.target.getAttribute('data-avatar')) dispatch(globalActions.setProfileOpen(false));
        }}
      />
      <NotificationWrapper />
      <Toast />
    </Paper>
  );
};
