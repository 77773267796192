import React from 'react';
import { Grid, Typography } from 'amn-ui-core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import Roles from './RightContainer/Roles';
import { selectEmpInfo, selectIsDataChanged, selectedcustomTabvalue } from './store/userManagement.selector';
import Associations from './RightContainer/Associations';
import { UserDetails } from './RightContainer/Details';
import { getUserManagementActions } from './store/userManagement.redux';
import { OverflowTooltip } from '@AMIEWEB/Common/Header/Tooltip/OverflowTooltip';
import { GenericTabs } from '@AMIEWEB/Common/Tabs/GenericTabs';

const useStyles = makeStyles()(theme => ({
  tabRoot: {
    height: '80%',
    width: '100%',
    display: 'flex',
    '& .MuiTab-root': {
      fontWeight: '500'
    },
  },
  emp: {
    width: '98%',
    display: 'flex',
    margin: '8px',
  },
  employeeIds: {
    color: 'black',
    fontSize: '14px',
  },
  employeeName: {
    marginBottom: '4px',
    fontSize: '15px',
    fontWeight: 600,
    display: 'inline',
  },
  employeeStatusIcon: {
    width: '58px',
    height: '28px',
    display: 'inline-block',
    textAlign: 'center',
    borderRadius: '4px',
    position: 'relative',
    marginRight: '5px',
  },
  activeText: {
    margin: '6px',
    fontWeight: 600,
  },
  userInfoLabel: {
    fontSize: '12px',
    color: '#333333',
  },
  userInfoValue: {
    fontSize: '14px',
    fontWeight: 600,
    color: '#333333',
    whiteSpace: 'nowrap',
  },
  userInfoRow: {
    display: 'flex',
    alignItems: 'center',
  },
  scroll: {
    color: '#555555',
    fontSize: '1rem',
    overflow: 'hidden',
    width: '100%',
    border: '1px solid #CCCCCC',
    borderRadius: '4px',
    height: 'calc(100vh - 320px)',
    '@media (max-width: 980px)': {
      height: 'calc(100vh - 360px)',
    },
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    paddingLeft: '4px',
  },
  labelContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: '1 1 auto',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  valueContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '30%',
  },
}));

const RightContainer = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const empInfo = useSelector(selectEmpInfo);
  const isDataChanged = useSelector(selectIsDataChanged);
  const customTabvalue = useSelector(selectedcustomTabvalue);
  const missingField = '--';

  var hasValues = empInfo && Object.keys(empInfo).length > 0;
  const EmployeeNameCard = ({ firstName, lastName, classes }) => {
    const fullName = `${firstName} ${lastName}`;
    return (
      <Typography variant="body2" className={classes.employeeName} display="inline">
        {fullName}
      </Typography>
    );
  };

  const EmployeeUserNameCard = ({ userName, classes }) => {
    return (
      <Typography variant="body1" className={classes.userInfoValue} display="inline">
        {userName}
      </Typography>
    );
  };

  const tabs = React.useMemo(
    () => [
      { label: t('Details'), component: <UserDetails /> },
      { label: t('Roles'), component: <Roles /> },
      { label: t('Associations'), component: <Associations /> },
    ],
    [t],
  );

  const customHandleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    if (isDataChanged) {
      dispatch(getUserManagementActions.setDialoge(true));
      dispatch(getUserManagementActions.setTabValue(newValue));
    } else dispatch(getUserManagementActions.setCustomTabValue(newValue));
  };

  return (
    <>
      <div className={classes.scroll} data-testid="right-container">
        <Grid item xs={12} key={empInfo?.companyId} spacing={2} className={classes.emp}>
          <Grid item xs={10}>
            <div className={classes.flexContainer} data-testid="empInformation">
              <div className={`${classes.labelContainer} ${classes.flexContainer}`}>
                {hasValues ? (
                  <>
                    <OverflowTooltip
                      contentStyle={{ maxWidth: 200, fontWeight: 'normal !important' }}
                      title={`${empInfo?.firstName} ${empInfo?.lastName}`}
                      value={
                        <EmployeeNameCard
                          firstName={empInfo?.firstName}
                          lastName={empInfo?.lastName}
                          classes={classes}
                        />
                      }
                      normallyRendered
                    />
                    <Typography className={classes.employeeIds} data-testid="employeeId" display="inline">
                      &nbsp;|&nbsp;{t('userManagement.eid')}
                      {` `}
                      {empInfo?.employeeId}
                    </Typography>{' '}
                  </>
                ) : (
                  <Typography className={classes.employeeName} data-testid="employeeId" display="inline">
                    {t('userManagement.noUserSelected')}
                  </Typography>
                )}
              </div>
              <div className={classes.valueContainer} data-testid="userName">
                <Typography className={classes.userInfoLabel} display="inline">
                  {t('userManagement.userName')}&nbsp;
                </Typography>
                <OverflowTooltip
                  title={empInfo?.userName}
                  contentStyle={{ maxWidth: 150 }}
                  value={<EmployeeUserNameCard userName={empInfo?.userName} classes={classes} />}
                  normallyRendered
                />
              </div>
              <div className={classes.valueContainer} data-testid="Brand">
                <Typography variant="body1" className={classes.userInfoLabel} display="inline">
                  {t('userManagement.brand')}&nbsp;
                </Typography>
                <Typography variant="body1" className={classes.userInfoValue} display="inline">
                  {empInfo?.companyName || missingField}
                </Typography>
              </div>
            </div>
          </Grid>
          <>
            {hasValues ? (
              <Grid item xs={2} container justifyContent="flex-end">
                <div
                  className={classes.employeeStatusIcon}
                  style={{ backgroundColor: empInfo?.descStatus === 'Active' ? '#5FB0574D' : '#CCCCCC80' }}
                  data-testid="EmployeeStatus"
                >
                  <Typography
                    variant="body2"
                    display="inline"
                    className={classes.activeText}
                    style={{ color: empInfo?.descStatus === 'Active' ? '#266E1F' : '#888888' }}
                  >
                    {empInfo?.descStatus}
                  </Typography>
                </div>
              </Grid>
            ) : (
              <></>
            )}
          </>
        </Grid>
        <Grid
          item
          justifyContent="flex-start"
          direction="column"
          spacing={2}
          data-testid="tab"
          className={classes.tabRoot}
        >
          <GenericTabs
            tabs={tabs}
            // modifyUrl
            value={customTabvalue}
            customHandleChange={customHandleChange}
            analytics={{ name: 'User Management page' }}
          />
        </Grid>
      </div>
    </>
  );
};

export default RightContainer;
