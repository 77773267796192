export enum OrderPrioritiesId {
  VoiceAdvantage = '45',
  BedSizeLessThan = '5',
  TeachingFacility = '13',
  TraumaCenter = '14',
  MagnetStatus = '17',
}
export enum VirtualInterviewRatingsId {
  A = '30',
  B = '31',
  C = '32',
  D = '33',
  E = '34',
  F = '35',
  G = '36',
  H = '37',
  I = '38',
  VoiceAdvantage = '45',
  VAAutoOffer = '46',
}
export const VoiceAdvantage = 'Voice Advantage (Interview)';

export const VirtualInterviewIdList = Object.values(VirtualInterviewRatingsId) as string[];
export const DisableOrderPriorityList = Object.values(OrderPrioritiesId) as string[];
export const TooltipOrderPriorityList = Object.values(OrderPrioritiesId).filter(
  a => a !== OrderPrioritiesId.VoiceAdvantage.toString(),
) as string[];

export const VirtualInterviewAssociatedList = VirtualInterviewIdList.slice(0, -2);

export enum QualificationType {
  Required = 1,
  Preferred,
  AdditionalRequiredQualifications,
  InternalNotes,
  ExternalJobDetails,
}

export const QualificationTypeString = [
  '',
  'requiredQualifications',
  'preferredQualifications',
  'additionalRequiredQualifications',
  'internalNotes',
  'jobDescription',
];

export const UPDATE_MESSAGE_TEMPLATE =
  'Order last updated in AMIE Web by [lastupdatedby] on [lastupdateddatetime], please make future edits in AMIE Web.';
export const STRING_VALIDATION_MESSAGE = 'please make future edits in AMIE Web.';

export const qTabs = [
  {
    index: 0,
    id: 'requiredQualifications',
    label: 'Required Qualifications',
  },
  {
    index: 1,
    id: 'additionalRequiredQualifications',
    label: 'Additional Required Qualifications',
  },
  {
    index: 2,
    id: 'preferredQualifications',
    label: 'Preferred Qualifications',
  },
  {
    index: 3,
    id: 'internalNotes',
    label: 'Internal Notes',
  },
  {
    index: 4,
    id: 'jobDescription',
    label: 'External Job Details',
  },
];

export interface IOrderQualificationFreeText {
  orderId?: string;
  isUpdatedFeeText?: Boolean;
  jobDescription: string;
  preferredQualifications: string;
  requiredQualifications?: string | null;
  internalNotes: string;
  additionalRequiredQualifications: string;
}
