import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { CustomTooltip } from '@AMIEWEB/Common/Tooltips';
import { useTranslation } from 'react-i18next';
import { missingField } from 'app/constants';
import { Button, Grid, IconButton, Typography, useTheme } from 'amn-ui-core';
import { GenericDialog } from '@AMIEWEB/Alerts/GenericDialog';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { AddDateTimeIcon } from '@AMIEWEB/Candidate/CandidateProfile/CandidateTabPanel/SummaryTab/Audit/Helpers/AddDateTimeButton';
import moment from 'moment';
import { selectIsFacilityIntructionsUpdateSuccess } from '@AMIEWEB/Facility/FacilityStore/Facility.selector';
import { useSelector } from 'react-redux';

interface EditableFacilityTextCardProps {
  title: string;
  leftIcon: ReactElement;
  showRightIcon?: Boolean;
  text: string;
  onSave: (text: string) => void;
  isAuthorized: boolean;
}

const useStyles = makeStyles()(theme => ({
  container: {
    border: `1px solid ${theme.palette.system.paleGray}`,
    background: theme.palette.components.grid.content.backgroundColor,
  },
  titleBar: {
    paddingTop: '8px',
    paddingBottom: '11px',
    paddingLeft: '24px',
    paddingRight: '24px',
    width: '100%',
    height: '42px',
    alignItems: 'flex-end',
    borderBottom: `1px solid ${theme.palette.system.paleGray}`,
    color: theme.palette.system.darkGray,
    fontSize: 16,
    fontWeight: 500,
  },
  titleBarColorEditing: {
    background: theme.palette.framework.system.whisper,
  },
  titleBarColor: {
    background: theme.palette.system.offWhite,
  },
  icon: {
    width: '28px',
    padding: '1px',
    color: theme.palette.system.neutralGray,
    '&:hover': {
      cursor: 'pointer',
      borderRadius: '50%',
      background: theme.palette.system.lightSteelBlue,
      color: theme.palette.system.navyBlue,
    },
  },
  textField: {
    display: 'flex',
    margin: '0px',
    width: '100%',
    padding: '24px',
  },
  saveButton: {
    backgroundColor: 'primary',
    height: '28px',
  },
  cancelButton: {
    height: '28px',
    backgroundColor: theme.palette.system.buttonGray,
    '&:hover': {
      backgroundColor: theme.palette.system.buttonDarkGray,
    },
  },
  input: {
    width: '97%',
    height: '100%',
    border: 'none',
    outline: 'none',
    resize: 'none',
    backgroundColor: 'white',
    fontFamily: 'inherit',
    fontSize: '14px',
    padding: 0,
    margin: 0,
    overflowY: 'hidden',
    '&:focus': {
      boxShadow: 'none',
    },
    '&:disabled': {
      cursor: 'default',
    },
  },
  leftIcon: {
    height: '20px !important',
  },
  title: {
    fontSize: '16px !important',
    paddingLeft: '8px',
    fontWeight: '500',
    alignItems: 'flex-end',
  },
  editTitle: {
    fontSize: '16px !important',
    fontWeight: '500',
    alignItems: 'flex-end',
    paddingBottom: '4px',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  addDateTimeBtn: {
    '& svg': {
      fill: `${theme.palette.system.neutralGray} !important`,

      '&:hover': {
        fill: `${theme.palette.system.main} !important`,
      },
    },
  },
  transparentButton: {
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

export const EditableFacilityTextCard = ({
  title,
  showRightIcon,
  text,
  leftIcon,
  onSave,
  isAuthorized,
}: EditableFacilityTextCardProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const textUpdateSuccessful = useSelector(selectIsFacilityIntructionsUpdateSuccess);
  const [isEditing, setIsEditing] = useState(false);
  const [displayedText, setDisplayedText] = useState<string>(text || missingField);
  const [shouldShowDialog, setShouldShowDialog] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const textareaRef = useRef(null);
  const theme = useTheme();

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'inherit';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [displayedText]);

  useEffect(() => {
    if (!isEditing) {
      const effectiveText = text && text.trim() ? text : missingField;
      setDisplayedText(effectiveText);
    }
  }, [text, isEditing]);

  useEffect(() => {
    if (isEditing && textUpdateSuccessful) {
      setIsEditing(false);
    }
  }, [textUpdateSuccessful]);

  const onEditClick = () => {
    if (displayedText === missingField) {
      setDisplayedText('');
    }
    setIsEditing(true);
    setIsDirty(false);
  };

  const onTextChange = changedText => {
    setDisplayedText(changedText);
    setIsDirty(text != changedText);
  };

  const onSaveButtonClick = () => {
    if (onSave) {
      onSave(displayedText.replace(/^\n+|\n+$/g, ''));
    }
  };

  const onCancelButtonClick = () => {
    if (isDirty) {
      showCancelModal();
    } else {
      setIsEditing(false);
    }
  };

  const handleCancelModalResponse = () => {
    setDisplayedText(text);
    setIsEditing(false);
    setShouldShowDialog(false);
  };

  const showCancelModal = () => {
    setShouldShowDialog(true);
  };
  const handleAddTimeStamp = event => {
    if (textareaRef && textareaRef.current) {
      const value = textareaRef.current.value;
      const [start, end] = [textareaRef.current.selectionStart, textareaRef.current.selectionEnd];
      const timeStamp = moment().format('MM/DD/YYYY hh:mm:ss A');
      textareaRef.current.value = null;
      const newValue = value.substring(0, start) + timeStamp + value.substring(end, value.length);
      textareaRef.current.value = newValue;
      onTextChange?.(newValue);
      event.preventDefault();
    }
  };

  return (
    <>
      <Grid container direction="column" className={classes.container}>
        <Grid
          container
          item
          direction="row"
          className={`${classes.titleBar} ${isEditing ? classes.titleBarColorEditing : classes.titleBarColor}`}
        >
          <Grid item xs={9}>
            {isEditing ? (
              <Typography className={classes.editTitle}>
                {t('facility.instructionsTab.editingTitlePrefix')} {title}
              </Typography>
            ) : (
              <Grid item className={classes.titleContainer}>
                {React.cloneElement(leftIcon, { className: classes.leftIcon })}
                <Typography className={classes.title}>{title}</Typography>
              </Grid>
            )}
          </Grid>
          <Grid item container xs={3} alignItems="center" display="flex" justifyContent="flex-end" spacing={2}>
            {isAuthorized && showRightIcon && !isEditing && (
              <Grid item xs={3} display="flex" justifyContent="flex-end">
                <CustomTooltip tooltipContent={t('facility.tooltip.edit')}>
                  <EditOutlinedIcon className={classes.icon} onClick={onEditClick} />
                </CustomTooltip>
              </Grid>
            )}
            {isEditing && (
              <>
                <Grid item>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={onCancelButtonClick}
                    className={classes.cancelButton}
                  >
                    {t('global.button.cancel')}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={onSaveButtonClick}
                    disabled={!isDirty}
                    className={classes.saveButton}
                  >
                    {t('global.button.save')}
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.textField}>
          <div style={{ position: 'relative', width: '100%' }}>
            <textarea
              style={{ color: theme.palette.components.typography.color }}
              ref={textareaRef}
              disabled={!isEditing}
              className={classes.input}
              value={displayedText}
              onChange={e => onTextChange(e.target.value)}
            />
            {isEditing && (
              <div style={{ position: 'absolute', top: '-8px', right: 0 }}>
                <IconButton
                  color="primary"
                  disabled={false}
                  className={`${classes.addDateTimeBtn} ${classes.transparentButton}`}
                  onClick={handleAddTimeStamp}
                >
                  <CustomTooltip tooltipContent="Add Date & Time">
                    <AddDateTimeIcon viewBox="0 0 30 30" />
                  </CustomTooltip>
                </IconButton>
              </div>
            )}
          </div>
        </Grid>
      </Grid>
      {shouldShowDialog && (
        <GenericDialog
          open={shouldShowDialog}
          dialogActions={[
            {
              text: t('global.button.cancel'),
              variant: 'text',
              color: 'tertiary',
              useDialogOnCloseEvent: true,
            },
            {
              text: t('global.Yes'),
              variant: 'contained',
              color: 'primary',
              onClick: () => handleCancelModalResponse(),
            },
          ]}
          dialogTitleProps={{ text: t('facility.instructionsTab.modalCancelTitle') }}
          draggable
          onClose={() => setShouldShowDialog(false)}
        >
          <Typography> {t('facility.instructionsTab.modalCancelBody')} </Typography>
        </GenericDialog>
      )}
    </>
  );
};
