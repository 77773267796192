import { ITechnologyVendorUsage } from '@AMIEWEB/Facility/FacilityStore/Facility.model';
import { IFacilityRoleAssignments } from './FacilityRoles';

export interface FacilityResponse {
  facilityId: number;
  facilityName: string;
  healthSystemName: string;
  facilityWebsite: string;
  phone: string;
  bestBets: string;
  bestBetsId: number;
  employeeContacts: {
    recruiter: {
      userId?: number;
      firstName?: string;
      lastName?: string;
      email?: string;
      phoneNumber?: string;
      im?: string;
    };
    accountManager: {
      userId?: number;
      firstName?: string;
      lastName?: string;
      email?: string;
      phoneNumber?: string;
      im?: string;
    };
    regionalDirector: {
      userId?: number;
      firstName?: string;
      lastName?: string;
      email?: string;
      phoneNumber?: string;
      im?: string;
    };
    credentialingAnalyst: {
      userId?: number;
      firstName?: string;
      lastName?: string;
      email?: string;
      phoneNumber?: string;
      im?: string;
    };
    clinicalManager: {
      userId?: number;
      firstName?: string;
      lastName?: string;
      email?: string;
      phoneNumber?: string;
      im?: string;
    };
  };
  facilityContacts: [] | null;
  location: {
    address1: string;
    address2: string;
    state: string;
    city: string;
    zipCode: string;
    region: string;
    regionId?: number;
  };
  facilityStatus: string;
  division: string;
  bedSize: number | null;
  facilityType: string | null;
  facilityDescription: string | null;
  setting: string | null;
  tier: string | null;
  serviceStatus: string | null;
  isTeaching: boolean;
  isMagnetStatus: boolean;
  isTraumaCenter: boolean;
  travelersOnAssignment: number;
  bookingLimit: number;
  specialProjects: string;
  creditStatus: string;
  creditNotes: string;
  creditLastUpdated: string | null;
  creditLastUpdatedBy: {
    userId: number;
    employeeId?: number;
    firstName: string;
    lastName: string;
  };
  technologyVendor: string;
  staffingVendor: string;
  businessRelationship: string;
  mspClient: string;
  affiliationName: string;
  filesOut?: number;
  currentOA?: number;
  historicalOA?: number;
  locationOA?: number;
  positionsAvailable?: number;
  parent: string;
  notes?: string;
  numberOfReferenceRequired: number;
  numberOfReferenceToRFO: number;
  lastUpdated: string | null;
  lastUpdatedBy: {
    userId: number;
    employeeId?: number;
    firstName: string;
    lastName: string;
  };
  employeeRoles: IFacilityRoleAssignments;
  payrollRegion: string;
  payrollRegionID: number;
  technologyVendorUsage: ITechnologyVendorUsage[];
  amnDivisionTypeId: number;
  submissionRequiredReference: boolean;
  supervisorAndAboveReference: boolean;
  withinLastMonthsReferenceChanged: number;
  entireWorkHistory: boolean;
  facilityReferenceChangedBy: {
    email: string;
    employeeId?: number;
    firstName: string;
    lastName: string;
  };
  facilityReferenceChangedDate: string | null;
}

export interface FacilityDetailsHeader {
  facilityId: number;
  facilityName: string;
  healthSystemName: string;
  facilityAddress: {
    addressLine1: string;
    addressLine2: string;
  };
  facilityStatus: string;
}

export interface Paggination {
  pageSize: number;
  pageNumber: number;
  sortedColumn: { column: string; direction: string };
  Total: number;
}
export interface IFacilityClientContactsList {
  contactId: number;
  firstName: string;
  lastName: string;
  name: string;
  title: string;
  email: string;
  goesBy: string;
  prefix: string;
  city: string;
  state: string;
  zip: string;
  doNotCallIn: string;
  status: string;
  amhTitleId: number;
  address1: string;
  address2: string;
  stateProvinceId: string;
  country: string;
  faxInstruction: string;
  notes: string;
  insertDate: Date;
  statusId: number;
  evaluationDeliveryTypeId: number;
  confirmationDeliveryTypeId: number;
  fileDeliveryTypeId: number;
  contactPreferenceTypeId: number;
  activeSsc: number;
  descStatus: string;
  doNotCallFacility: string;
  contactPreference: string;
  statusIdForFiltering: string;
  regionalDirectorId: number;
  phoneNumber: string;
  units: any;
  actions: any;
  actionsSort: number;
  isPrimary: boolean;
}

export interface OrderFacilityDetails {
  currentUserId: number;
  facilityId: number;
  facilityName: string;
  facilityAddress: {
    line1: string;
    line2: string;
  };
  location: {
    address1: string | null | undefined;
    address2: string | null | undefined;
    city: string | null | undefined;
    region: string | null | undefined;
    state: string | null | undefined;
    zipCode: string | null | undefined;
  } | null;
  division: string;
  employeeContacts: {
    recruiter: {
      userId: number;
      employeeId?: number;
      firstName: string;
      lastName: string;
    };
    hospitalAccountManager: {
      userId: number;
      employeeId?: number;
      firstName: string;
      lastName: string;
    };
    accountManager?: {
      userId: number;
      employeeId?: number;
      firstName: string;
      lastName: string;
    };
    regionalDirector: {
      userId: number;
      employeeId?: number;
      firstName: string;
      lastName: string;
    };
    credentialingAnalyst: {
      userId: number;
      employeeId?: number;
      firstName: string;
      lastName: string;
    };
    clinicalManager: {
      userId: number;
      employeeId?: number;
      firstName: string;
      lastName: string;
    };
  } | null;
  recruiter: {
    userId: number;
    employeeId?: number;
    firstName: string;
    lastName: string;
  };
  hospitalAccountManager: {
    userId: number;
    employeeId?: number;
    firstName: string;
    lastName: string;
  };
  regionalDirector: {
    userId: number;
    employeeId?: number;
    firstName: string;
    lastName: string;
  };
  credentialingAnalyst: {
    userId: number;
    employeeId?: number;
    firstName: string;
    lastName: string;
  };
  clinicalManager: {
    userId: number;
    employeeId?: number;
    firstName: string;
    lastName: string;
  };
  bestBets: string;
  bestBetsId: number;
  gww: boolean;
  bedSize: boolean;
  isTeaching: boolean;
  isMagnetStatus: boolean;
  isTraumaCenter: boolean;
  isCreditHold?: boolean | null;
  technologyVendor: string;
  healthSystem: string;
  region: string;
  firstDayInstructions: string;
  amnDivisionTypeId: number;
}

export interface QuickGlance {
  creditCheck: string;
  bookingLimit: string;
  currentOA: string;
  historicalOA: string;
  locationOA: string;
  openPositions: string;
  filesOut: string;
}

export enum GlanceDataStatus {
  yes = 'Y',
  no = 'N',
  neutral = 'Neutral',
}

export enum FacilityType {
  facilityDetails = 'FacilityDetails',
  createOrder = 'CreateOrder',
  createUnit = 'CreateUnit',
  editUnit = 'EditUnit',
}
