import { DrawerFitted } from '@AMIEWEB/Common/Drawer/Drawer';
import {
  Avatar,
  Box,
  Divider,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from 'amn-ui-core';
import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import CloseIcon from '@mui/icons-material/Close';
import { StatusDropDownValues, StatusDropdown, VERSION } from '@AMIEWEB/Common/StatusDropDown/StatusDropDown';
import { CustomTooltip } from '@AMIEWEB/Common/Tooltips';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import { formatPhoneNumber } from '@AMIEWEB/Facility/FacilityDetails/FacilityHeader/HeaderUtils';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { container } from 'styles/styleVariables';
import StarIcon from '@mui/icons-material/Star';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { useDispatch, useSelector } from 'react-redux';
import {
  isAuthorizedForAddEdit,
  useIsUserAccountManager,
  useSelectedUnitDetails,
} from '@AMIEWEB/Unit/UnitDetails/Containers/unitHelper';
import { missingField } from 'app/constants';
import { useHistory, useLocation } from 'react-router-dom';
import { Concatenate, isNonEmptyString } from 'utils/string/string';
import { useTranslation } from 'react-i18next';
import { selectUnit } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/UnitTab/Store/FacilityUnit.selector';
import { selectUser } from 'oidc/user.selectors';
import MailBoxIcon from 'app/assets/images/MailBox.svg';
import EmailDisabled from 'app/assets/images/CandidateHeader/email-Disabled.svg';
import PhoneIcon from 'app/assets/images/Phone.svg';
import PhoneDisabled from 'app/assets/images/CandidateHeader/PhoneDisabled.svg';
import { ChannelType, FormName, SendType, UseSubType, UseType } from '@AMIEWEB/Notification/Constants';
import { notificationSelection } from 'store/redux-store/notification/notification.selector';
import { IEmployee, IToken } from 'app/models/Notification/Notification';
import { notificationDataActions } from 'store/redux-store/notification/notification.redux';
import { colors } from 'styles/styleVariables';
import { useEnableVoiceCommunication, useEnableEmailCommunication } from 'oidc/CommunicationEnabler';
import { searchNotificationData } from '@AMIEWEB/GlobalSearch/helper';
import { EmailDefaultValues } from 'app/models/Email/IEmail';
import _ from 'lodash';

const useStyles = makeStyles()(theme => ({
  drawerRoot: {
    flexGrow: 1,
    width: 'inherit',
  },
  bodyCandidateProfileDrawer: {
    overflowY: 'auto',
    overflowX: 'hidden',
    maxHeight: '100vh',
  },
  divider: {
    margin: '0 0 12px 0',
    height: 2,
    'margin-bottom': '0',
  },
  title: {
    fontSize: '16px',
    fontWeight: '500',
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  facilityName: {
    fontSize: '14px !important',
    color: `${theme.palette.primary.main} !important`,
    fontWeight: '500 !important',
    cursor: 'pointer',
  },
  unitName: {
    fontSize: '22px !important',
    fontWeight: '600 !important',
  },
  circleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    width: 42,
    height: 42,
    border: `1px solid ${theme.palette.primary.main}`,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.system.paleBlue,
    },
  },
  circleContainerDisabled: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    width: 42,
    height: 42,
    border: `1px solid ${theme.palette.system.lightGrey} !important`,
  },
  icon: { color: theme.palette.primary.main, width: '24px' },
  iconDisabled: { color: theme.palette.system.lightGrey, width: '24px' },
  tabRoot: {
    [theme.breakpoints.up(380)]: {
      minWidth: '125px !important',
    },
  },
  tabContainer: {
    borderBottom: 'none !important',
    padding: '0px !important',
    width: '100%',
    borderRadius: `${container.borderRadius} ${container.borderRadius} 0px 0px !important`,
    '& .active-tab-profile': {
      width: 'auto',
    },
  },
  fullWidth: {
    width: '100% !important',
  },
  editIcon: {
    cursor: 'pointer !important',
    width: '28px',
    padding: '1px',
    color: theme.palette.system.navyBlue,
    '&:hover': {
      borderRadius: '50%',
      background: theme.palette.system.lightSteelBlue,
    },
  },
  userAvatar: {
    width: '40px',
    height: '40px',
    borderRadius: '80px',
    opacity: 1,
    color: 'white',
    backgroundColor: theme.palette.system.violet,
  },
  starIcon: {
    color: theme.palette.system.lightNavyBlue,
    width: '25px',
    paddingLeft: '5px',
    paddingBottom: '5px',
  },
  actionContainer: {
    right: 0,
  },
  tooltip: {
    display: 'grid',
  },
  tooltipBackground: {
    backgroundColor: theme.palette.framework.system.white,
    color: theme.palette.framework.system.doveGray,
    border: `1px solid  ${theme.palette.framework.system.doveGray}`,
    fontSize: '12px',
  },
  tooltipArrow: {
    color: theme.palette.framework.system.white,
    '&::before': {
      border: `1px solid  ${theme.palette.framework.system.doveGray}`,
    },
  },
  listItemText: {
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  secondaryTextWithAvatar: {
    color: colors.darkText,
    maxWidth: 250,
  },
  designation: {
    color: theme.palette.system.dimGrey,
    fontSize: 12,
  },
  listItem: {
    margin: '0px 12px 0px 12px !important',
  },
}));

const getInitials = (firstName: string, lastName: string): string =>
  isNonEmptyString(firstName) && isNonEmptyString(lastName)
    ? Concatenate([firstName?.substring(0, 1), lastName?.substring(0, 1)], '')
    : missingField;

export enum IUnitDetailsSidePanelTabs {
  CLIENT_CONTACTS,
}
export interface IDetailsDrawerComponent {
  closeProfileDrawer: () => void;
  openProfileDrawer: boolean;
  facilityName: string;
  handleDropDownStatus: (value: StatusDropDownValues) => void;
  status: StatusDropDownValues | string;
  openFacilityDrawer: () => void;
}

const UnitDetailSidePanel = ({
  closeProfileDrawer,
  openProfileDrawer,
  facilityName,
  handleDropDownStatus,
  status,
  openFacilityDrawer,
}: IDetailsDrawerComponent) => {
  const { classes } = useStyles();
  const [tabSelectedValue, setTabSelectedValue] = useState<number>(IUnitDetailsSidePanelTabs.CLIENT_CONTACTS);
  const selectedUnitDetail = useSelectedUnitDetails();
  const enableEmailCommunication = useEnableEmailCommunication();
  const selectedUnit = useSelector(selectUnit);
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isEdit = pathname.includes('edit');
  const isNewUnit = pathname.includes('newUnit');
  const enableVoiceCommunication = useEnableVoiceCommunication();
  const { t } = useTranslation();
  const unitName = selectedUnitDetail?.name ?? missingField;
  const userData = useSelector(selectUser);
  const isAuthorized = useIsUserAccountManager();
  const { userInfo } = useSelector(selectUser);
  const notificationData = useSelector(notificationSelection);

  const handleEditIconClick = () => {
    if (selectedUnitDetail?.facilityId && selectedUnitDetail?.unitId)
      history.push(`/facility/${selectedUnitDetail?.facilityId}/unit/${selectedUnitDetail?.unitId}/edit`);
  };

  const TabPanel = ({ children, value, index, classes, ...other }) => {
    return (
      <div
        className="tab"
        role="tabpanel"
        hidden={value !== index}
        id={`unitDetails-tabpanel-${index}`}
        aria-labelledby={`unitDetails-tab-${index}`}
        {...other}
      >
        {value === index && <Box className={`paper ${classes.tabContainer}`}>{children}</Box>}
      </div>
    );
  };

  const ContactItem = ({ avatarText, isPrimary, contact }) => {
    const handleCommunication = (props: { channelType: ChannelType; contactData: any }) => {
      const { contactData } = props;
      const associatedRecords = [
        {
          name: 'clientContactId',
          value: `${contactData?.clientContactId}`,
        },
        { name: 'unitId', value: selectedUnitDetail?.unitId?.toString() },
        { name: 'unitName', value: unitName?.toString() },
      ];
      const sender: IEmployee = {
        name: userInfo?.firstName + ' ' + userInfo?.lastName,
        email: userInfo?.email,
        senderId: `${userInfo?.employeeId}`,
        userId: `${userInfo?.employeeId}`,
        NtUserName: userInfo?.ntUserName,
        deviceName: '',
        lineNumber: '',
        phoneNumber: userInfo?.phoneNumber,
      };
      const tos = {
        contactId: parseInt(contactData?.clientContactId),
        designation: contactData?.title,
        email: contactData?.email,
        phoneNumber: contactData?.phoneNumber?.replace(/^\+\d+\s\(/, '('),
        name: `${contactData?.firstName} ${contactData?.lastName}`,
      };
      if (props.channelType === ChannelType.voice) {
        const VoiceData = {
          manualLog: false,
          channel: props.channelType,
          associatedRecords: associatedRecords,
          sender: sender,
          sendType: SendType.one_to_one,
          tos: [tos],
          useType: UseType.ClientContacts,
          userRole: Concatenate(userInfo?.roles || [], ','),
          isInternal: false,
          formName: FormName[FormName.Contact],
          UseSubType: UseSubType.ClientContactGrid,
        };
        if (!notificationData?.voice?.open && notificationData?.voice?.minimized) {
          dispatch(
            notificationDataActions.setVoiceInteraction({
              open: true,
              minimized: false,
              showDisposition: true,
            }),
          );
          if (notificationData?.voice?.data?.tos.find(Boolean).contactId !== contactData?.contactId) {
            dispatch(notificationDataActions.setSnackBarData({ channel: 'voice', manual: false, changeWarning: true }));
          }
        } else {
          dispatch(
            notificationDataActions.setVoiceData({
              data: VoiceData,
              open: true,
              minimized: false,
            }),
          );
        }
      } else {
        const notificationUser = searchNotificationData({ userInfo });
        const body = `<p><br><br><br>{{signature}}</p>`;
        const substitutions: Array<IToken> = [
          {
            name: 'Signature',
            input: '{{signature}}',
          },
        ];
        const emailData = {
          substitutions: substitutions,
          useType: UseType.ClientContacts,
          emailType: SendType.one_to_one,
          body: body,
          associatedRecords: associatedRecords,
          subject: '',
          tos: [tos],
          sender: sender,
          userRole: Concatenate(userInfo?.roles || [], ','),
          useSubType: UseSubType.ClientContactGrid,
          sendType: SendType.one_to_one,
          disableGroupEmailCheckbox: true,
        };
        if (!notificationData?.email?.open && notificationData?.email?.minimized) {
          dispatch(
            notificationDataActions.setEmailInteraction({
              open: true,
              minimized: false,
            }),
          );
          if (!_.isEqual(notificationData?.email?.data?.tos, [])) {
            dispatch(notificationDataActions.setSnackBarData({ channel: 'email', manual: false, changeWarning: true }));
          }
        } else {
          dispatch(
            notificationDataActions.setEmailData({
              data: {
                ...EmailDefaultValues(),
                ...notificationUser,
                ...emailData,
              },
              open: true,
              minimized: false,
            }),
          );
        }
      }
    };
    return (
      <ListItem alignItems="flex-start" disableGutters>
        <Grid container direction={'row'} m={2} className={classes.listItem}>
          <ListItemAvatar>
            <Avatar data-avatar className={classes.userAvatar}>
              {avatarText || 'UNK'}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            classes={{
              root: classes.listItemText,
              primary: classes.title,
              secondary: classes.secondaryTextWithAvatar,
            }}
            secondary={
              <React.Fragment>
                <Typography variant="body2" sx={theme => ({ color: theme.palette.system.dimGrey })}>
                  {contact?.title ?? missingField}
                </Typography>
                <Grid container>
                  <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: '500' }}>
                    {`${contact?.firstName} ${contact?.lastName}`}
                  </Typography>
                  {isPrimary && (
                    <CustomTooltip tooltipContent={t('facility.unitSidePanel.priority')}>
                      <StarIcon className={classes.starIcon} />
                    </CustomTooltip>
                  )}
                </Grid>
              </React.Fragment>
            }
          />
          <ListItemSecondaryAction classes={{ root: classes.actionContainer }}>
            {enableEmailCommunication && !!contact?.email && contact?.email !== '' ? (
              <Tooltip
                title={
                  <span className={classes.tooltip}>
                    <span>{contact.email}</span>{' '}
                  </span>
                }
                disableHoverListener={!contact?.email}
                arrow
                placement="bottom"
                classes={{ tooltip: classes.tooltipBackground, arrow: classes.tooltipArrow }}
              >
                <img
                  src={MailBoxIcon}
                  alt="MailBox"
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleCommunication({ channelType: ChannelType.email, contactData: { ...contact } })}
                />
              </Tooltip>
            ) : (
              <img src={EmailDisabled} alt="MailBox" />
            )}
            {enableVoiceCommunication && contact?.phoneNumber && contact?.phoneNumber.length > 0 ? (
              <Tooltip
                title={
                  <span className={classes.tooltip}>
                    <span>{contact?.phoneNumber}</span>{' '}
                  </span>
                }
                disableHoverListener={false}
                arrow
                placement="bottom"
                classes={{ tooltip: classes.tooltipBackground, arrow: classes.tooltipArrow }}
              >
                <img
                  src={PhoneIcon}
                  alt="Phone"
                  style={{ marginLeft: 6, marginRight: 10 }}
                  onClick={() => handleCommunication({ channelType: ChannelType.voice, contactData: { ...contact } })}
                />
              </Tooltip>
            ) : (
              <img src={PhoneDisabled} style={{ marginLeft: 6, marginRight: 10 }} />
            )}
          </ListItemSecondaryAction>
        </Grid>
      </ListItem>
    );
  };

  const handleProfileDrawerTabChange = newValue => {
    if (newValue !== tabSelectedValue) {
      setTabSelectedValue(newValue);
    }
  };

  return (
    <DrawerFitted
      open={openProfileDrawer}
      onDrawerClose={closeProfileDrawer}
      width={425}
      top={0}
      sx={theme => ({ backgroundColor: theme.palette.framework.system.whisper })}
    >
      <div className={classes.drawerRoot}>
        <Grid container xs={12} lg={12} sm={12} md={12} direction="row" p={2}>
          <Grid container xs={11} lg={11} sm={11} md={11}>
            <Typography className={classes.title}>
              {t('facility.unitSidePanel.uid', { uid: selectedUnitDetail?.unitId ?? missingField })}
            </Typography>
          </Grid>
          <Grid container xs={1} lg={1} sm={1} md={1}>
            <CloseIcon onClick={closeProfileDrawer} sx={theme => ({ color: theme.palette.system.buttonDarkGray })} />
          </Grid>
        </Grid>
        <Divider orientation="horizontal" classes={{ root: classes.divider }} />
        <div className={classes.bodyCandidateProfileDrawer}>
          <div style={{ backgroundColor: 'white' }}>
            <Grid container spacing={2} p={2}>
              <Grid item xs={9}>
                <Typography className={classes.unitName}>{unitName}</Typography>
              </Grid>
              <Grid item xs={3}>
                <CustomTooltip
                  tooltipContent={t('facility.tooltip.noPermissionToEdit')}
                  placement="bottom"
                  isStandardContent={true}
                  isInlineContent={true}
                  disabled={isAuthorized}
                >
                  <StatusDropdown
                    isNewStatusIndicator={false}
                    handleStatus={handleDropDownStatus}
                    defaultValue={isNewUnit ? 'Status N/A' : status}
                    isDisabled={isNewUnit || status === StatusDropDownValues.INACTIVE ? true : false}
                    isReadOnly={!isAuthorized}
                  />
                </CustomTooltip>
              </Grid>
              <Grid item xs={12}>
                <Typography onClick={openFacilityDrawer} className={classes.facilityName}>
                  {facilityName}
                </Typography>
              </Grid>
            </Grid>
            <Grid container p={2} direction="row" columnGap={2}>
              <Grid
                item
                className={selectedUnit?.phone ? `${classes.circleContainer}` : `${classes.circleContainerDisabled}`}
              >
                <CustomTooltip tooltipContent={formatPhoneNumber(selectedUnit?.phone)} disabled={!selectedUnit?.phone}>
                  <CallOutlinedIcon className={selectedUnit?.phone ? `${classes.icon}` : `${classes.iconDisabled}`} />
                </CustomTooltip>
              </Grid>
              <Grid item className={classes.circleContainerDisabled}>
                <EmailOutlinedIcon className={classes.iconDisabled} />
              </Grid>
            </Grid>
          </div>
          <Paper elevation={0} className={`paper ${classes.tabContainer} `}>
            <Tabs
              value={tabSelectedValue}
              classes={{ indicator: 'tab-indicator profileTab' }}
              onChange={handleProfileDrawerTabChange}
              aria-label="full width tabs"
            >
              <Tab
                className={
                  tabSelectedValue === IUnitDetailsSidePanelTabs.CLIENT_CONTACTS
                    ? 'active-tab-profile'
                    : 'default-tab profileTab'
                }
                label={'Client Contacts'}
                classes={{ root: classes.tabRoot }}
              />
            </Tabs>
          </Paper>
          <TabPanel componet={'div'} value={tabSelectedValue} index={0} classes={classes}>
            <Grid container m={2} className={classes.listItem} sx={{ paddingTop: '10px' }}>
              <Grid xs={10.6}>
                <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
                  {t('facility.unitSidePanel.clientContact', { count: selectedUnitDetail?.unitContacts?.length ?? 0 })}
                </Typography>
              </Grid>
              <Grid xs={1.4}>
                {isAuthorizedForAddEdit(userData) && !isEdit && !isNewUnit && (
                  <CustomTooltip tooltipContent={'Edit'}>
                    <CreateOutlinedIcon className={classes.editIcon} onClick={handleEditIconClick} />
                  </CustomTooltip>
                )}
              </Grid>
            </Grid>
            {selectedUnitDetail?.unitContacts?.map((contact, index) => (
              <>
                <ContactItem
                  avatarText={getInitials(contact?.firstName, contact?.lastName).toUpperCase()}
                  isPrimary={contact?.isPrimary}
                  key={index}
                  contact={contact}
                />
                <Divider />
              </>
            ))}
          </TabPanel>
        </div>
      </div>
    </DrawerFitted>
  );
};

export default UnitDetailSidePanel;
