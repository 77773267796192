import { IPlacementStatus, PlacementOrderType } from 'app/models/Placement/PlacementDetails';

export enum ICueToOptions {
  NO_CUE = 'No Cue',
}
export interface ISelectedCueNoteStatus extends IPlacementStatus {
  placementOrderType?: PlacementOrderType;
  placementOrderValue?: string;
  isRfo?: boolean;
  isTA?: boolean;
}
export interface ICueNoteTransformed {
  name: string;
  value: number;
  role: string;
}

export interface ICueNoteEmployees {
  employeeId: number;
  employeeName: string;
  firstName: string;
  lastName: string;
  roleDescription: string;
}

export interface IRequestCueNotePayload {
  placementId: number;
  orderId: number;
  candidateId: number;
  brandId: number;
  recruiterId: number;
}
