import { makeStyles } from 'tss-react/mui';
import { Theme } from 'amn-ui-core';
export const WorkExperienceModalStyles = makeStyles()((theme: Theme) => ({
  root: {
    border: `1px solid ${theme.palette.system.paleGray}`,
    borderRadius: '4px 4px 4px 4px',
  },

  container: {
    margin: theme.spacing(4),
  },
  editHover: {
    color: `${theme.palette.system.neutralGray} !important`,
    '&:hover': {
      color: `${theme.palette.framework.system.skyBlue} !important`,
    },
  },
  equipment: {
    maxWidth: theme.spacing(28),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  equipmentContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  equipmentLabel: {
    marginRight: theme.spacing(1),
  },
  heading: {
    border: `1px solid ${theme.palette.system.lightGrey}`,
    borderRadius: '4px 4px 0px 0px',
    background: '#EBF4FA',
    padding: '6px ',
  },
  tooltipBackground: {
    backgroundColor: '#fff',
    color: '#555555',
    border: '1px solid #555555',
    fontSize: '12px',
  },
  facilityName: {
    fontFamily: 'Roboto, sans-serif',
    fontWeight: '500',
    fontSize: '22px',
    marginTop: theme.spacing(2),
    maxWidth: '350px',
    wordWrap: 'break-word',
  },
  columnMargin: {
    marginTop: theme.spacing(6),
  },
  columnRow: {
    fontFamily: 'Roboto, sans-serif',
    marginTop: theme.spacing(2),
    fontSize: '14px',
  },
  MuiCircularProgressRoot: {
    left: '50%',
    position: 'absolute',
    top: '50%',
  },
  internalHeading: {
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 'bold',
    fontSize: '16px',
  },
  requiredField: {
    fontWeight: 'normal',
    fontSize: '16px',
  },
  standardValue: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '14px',
    marginLeft: theme.spacing(20),
  },
}));
export const EnhancedEditWorkExperieneStyles = makeStyles()((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(5),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  action: {
    display: 'flex',
    flexDirection: 'row',
  },
  actionBtn: {
    fontSize: 12,
    padding: 0,
    minWidth: 30,
  },
  actionNoBtn: {
    color: theme.palette.framework.system.tertiaryGrey,
  },

  alingmentField: {
    paddingRight: '25px !important',
  },
  currentlyWorking: {
    marginBottom: '-15px',
  },
  datePickerInput: {
    height: '55px',
  },

  contentContainer: {
    dialogContent: {
      paddingTop: 0,
      overflow: 'hidden',
      'overflow-y': 'auto',
    },
  },
  checkBoxContainer: {
    marginBottom: '-30px',
  },
  checkBoxInput: {
    paddingTop: '24px !important',
  },
  heading: {
    border: `1px solid ${theme.palette.system.lightGrey}`,
    borderRadius: '4px 4px 4px 4px',
    background: '#EBF4FA',
    display: 'flex',
    flexDirection: 'column',
    padding: '6px !important',
  },
  buttonStyle: {
    color: '#611A16',
    fontSize: '14px',
    fontWeight: 500,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  infoIcon: {
    color: '#34AADC',
    height: '20px',
    width: '20px',
  },
  infoIconAlignment: {
    paddingTop: '30px !important',
  },
  label: {
    fontSize: '14px !important',
    color: 'rgba(0, 0, 0, 0.37)',
    '&.Mui-focused': {
      color: 'rgba(0, 0, 0, 0.37)',
    },
    '&[class*="MuiInputLabel-outlined"]': {
      marginTop: '1px',
    },
  },
  textBox: {
    backgroundColor: theme.palette.common.white,
    marginTop: '1%',
    marginBottom: '8px',
    '& .MuiInputBase-root': {
      height: '135px',
      display: 'inline-flex',
      flexDirection: 'column',
      margin: '2px',
    },
    '& .MuiOutlinedInput-root': {
      margin: '2px',
      position: 'initial',
    },
    '& label': {
      '&.Mui-focused': {
        color: '#C0C0C0',
      },
    },
  },
  actionButton: {
    backgroundColor: '#00000010',
    boxShadow: '0px -1px 3px rgba(0, 0, 0, 0.29)',
    borderRadius: '0px 0px 4px 4px',
    opacity: 0.95,
    backdropFilter: 'blur(30px)',
    WebkitBackdropFilter: 'blur(30px)',
    height: theme.spacing(11),
    paddingBottom: theme.spacing(2),
  },
}));

export const WorkExperienceViewStyles = makeStyles()((theme: Theme) => ({
  dialogContainer: {
    minWidth: '1280px',
  },
  dialogContent: {
    paddingTop: 0,
    overflow: 'hidden',
    'overflow-y': 'auto',
  },
}));
