import { PayloadAction } from '@reduxjs/toolkit';
import { call, fork, getContext, put, select } from 'redux-saga/effects';
import { workExperienceActions } from './slice';
import {
  candidateDetailsSelection,
  referenceGridSelector,
  selectReferences,
} from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import { lookupLoaderSelector, lookupSelector } from '../lookup/lookup.selectors';
import { missingField } from 'app/constants';
import { selectUser } from 'oidc/user.selectors';
import { CandidateService } from 'app/services/CandidateServices/candidate-service';
import { trackPromise } from 'react-promise-tracker';
import { httpSuccess } from 'app/services/serviceHelpers';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { IPostReferenceWorkExpModalPayload, IWorkExperienceBatchDeletePayload } from './types';
import { candidateDetailActions } from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.redux';
import { JobTypes, getJobTypeValue, getWorkExpLastUpdatedText, isDisabledWorkExperience } from './helper';
import i18next from 'i18next';
import { selectSelectedWorkExperience } from './selector';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import { IReferenceRequest } from 'app/models/Candidate/Tags';
import statesData from 'app/assets/jsons/State.json';
import countriesData from 'app/assets/jsons/Country.json';

const TrackDeleteWorkExperienceBatch = (fn, ...args) => trackPromise(fn(...args), 'delete-work-experience-batch');

const TrackGetReferenceListByWorkExperience = (fn, ...args) =>
  trackPromise(fn(...args), 'get-reference-by-work-experience');

const TrackPostReferenceFromWorkExpModal = (fn, ...args) =>
  trackPromise(fn(...args), 'post-reference-work-experience-modal');

const TrackGetCandidateWorkExperience = (fn, ...args) => trackPromise(fn(...args), 'get-candidate-work-experience');

const TrackPostReferenceRequestFromWorkExp = (fn, ...args) =>
  trackPromise(fn(...args), 'post-reference-request-work-experience');

export function* onInit(action: PayloadAction<{ candidateId: number }>) {
  yield fork(deriveWorkExperienceData, action.payload.candidateId);
  yield fork(getCandidateReferences, action.payload.candidateId, 1);
  yield fork(getCandidateReferences, action.payload.candidateId, 0);
}

export function* deriveWorkExperienceData(candidateId: number) {
  try {
    const candidateService: CandidateService = yield getContext('candidateService');
    const isLoaded = yield select(lookupLoaderSelector);
    const response = yield call(TrackGetCandidateWorkExperience, candidateService.getWorkExperienceList, candidateId);

    if (response && httpSuccess(response?.status)) {
      let selMasterCopy = yield select(lookupSelector);
      const selReferences = yield select(selectReferences);

      if (!isLoaded) {
        selMasterCopy = {
          ...selMasterCopy,
          states: statesData.states,
          countries: countriesData.countries,
        };
      }

      const workExperienceData = response.data?.map(x => {
        const state = x.location.state;
        const country = x.location.country;
        const isTimeOffExperience = getJobTypeValue(x) === JobTypes.TimeOff ? true : false;
        const locationData = {
          address1: x.location.address1,
          address2: x.location.address2,
          city: !isTimeOffExperience ? x.location.city : null,
          state: selMasterCopy?.states?.find(x => x.ID == state)?.Description ?? x.location.stateProvinceID,
          stateProvinceID: selMasterCopy?.states?.find(x => x.ID == state)?.ID ?? x.location.stateProvinceID,
          country: x.location.country,
          countryId: selMasterCopy?.countries?.find(x => x.Description == country)?.ID,
          zip: x.location.zip,
        };

        const currentDate = new Date().getTime();
        const currentlyEmployed =
          x.endedOn && x.endedOn !== 'Invalid date' ? currentDate < new Date(x.endedOn).getTime() : true;

        const referenceData = selReferences?.items?.filter(item => item.workHistoryID === x.workHistoryId) || [];

        return {
          ...x,
          ...locationData,
          id: x.workHistoryId,
          position: x.position,
          unit: x.unit,
          numberOfBedsInFacility: x.numberOfBedsInFacility,
          travelCompany: x.travelCompany,
          reasonForLeaving: x.reasonForLeaving,
          staffingAgency: x.staffingAgency == 'Y' ? true : false,
          isTeachingHospital: x.isTeachingHospital == 'Y' ? true : false,
          timeStamp: x?.timeStamp,
          referenceData: referenceData,
          equipmentProcedures: x?.equipmentProcedures?.length > 0 ? x?.equipmentProcedures : missingField,
          currentlyEmployed: currentlyEmployed,
          isTimeOff: isTimeOffExperience,
          isDisabled: isDisabledWorkExperience(x) || referenceData?.length,
          lastUpdatedText: getWorkExpLastUpdatedText(x),
          jobType: getJobTypeValue(x),
        };
      });

      yield put(workExperienceActions.setWorkExperience(workExperienceData));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'deriveWorkExperienceData',
        area: 'src/store/redux-store/candidate-work-experience/business.saga.ts',
      },
    });
  }
}

/**
 * Method to delete work experience records as a batch (Bulk Delete)
 */
export function* deleteWorkExperienceBatch(
  action: PayloadAction<{ workHistoryIds: number[]; workExperienceList: any[] }>,
) {
  const candidateService: CandidateService = yield getContext('candidateService');

  const candidateDetails = yield select(candidateDetailsSelection);
  const user = yield select(selectUser);

  const reqBody: IWorkExperienceBatchDeletePayload = {
    workHistoryIds: action.payload?.workHistoryIds,
    candidateId: candidateDetails?.travelerId,
    companyId: candidateDetails?.brandId,
    currentEmployeeId: user.userInfo?.employeeId || 0,
    currentEmployeeName: user.userInfo?.firstName + ' ' + user.userInfo?.lastName,
  };

  const response = yield call(TrackDeleteWorkExperienceBatch, candidateService.deleteWorkExperienceBatch, reqBody);

  if (httpSuccess(response.status)) {
    yield call(deriveWorkExperienceData, candidateDetails?.travelerId);
    yield put(
      globalActions.setSnackBar({
        message: i18next.t('candidate.workExperience.snackbar.deleteWorkExperience'),
        severity: 'success',
      }),
    );
  } else {
    yield put(
      globalActions.setSnackBar({
        message: i18next.t('candidate.workExperience.snackbar.deleteFailWorkExperience'),
        severity: 'error',
      }),
    );
  }
}

export function* getReferenceListByWorkExperience(
  action: PayloadAction<{ candidateId: number; workHistoryId: number }>,
) {
  let result = [];
  try {
    const candidateService: CandidateService = yield getContext('candidateService');

    const response = yield call(
      TrackGetReferenceListByWorkExperience,
      candidateService.getWorkExperienceReferenceList,
      action.payload,
    );

    if (response && httpSuccess(response?.status)) {
      const selectedWorkExp = yield select(selectSelectedWorkExperience);
      yield put(
        workExperienceActions.setSelectedWorkExperience({
          ...selectedWorkExp,
          referenceData: response?.data?.items || [],
          isDisabled: true,
        }),
      );

      result = response.data;
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'getReferenceListByWorkExperience',
        area: 'src/store/redux-store/candidate-work-experience/business.saga.ts',
      },
    });
  }

  return result;
}

export function* postReferenceFromWorkExpModal(action: PayloadAction<any>) {
  try {
    const data: IPostReferenceWorkExpModalPayload = action.payload;
    const candidateService: CandidateService = yield getContext('candidateService');

    const response = yield call(
      TrackPostReferenceFromWorkExpModal,
      candidateService.postReferenceFromWorkExpModal,
      data,
    );

    if (response && httpSuccess(response?.status)) {
      yield call(setWorkExperienceReferenceList, action?.payload);

      yield put(
        globalActions.setSnackBar({
          message: `Reference has been ${!action?.payload?.travelerReferenceID ? 'added' : 'updated'} successfully.`,
          severity: 'success',
        }),
      );
    } else {
      yield put(
        globalActions.setSnackBar({
          message: `Failed to ${!action.payload?.travelerReferenceID ? 'add' : 'update'} reference. Please try again.`,
          severity: 'error',
        }),
      );
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'postReferenceFromWorkExpModal',
        area: 'src/store/redux-store/candidate-work-experience/business.saga.ts',
      },
    });
  }
}

function* setWorkExperienceReferenceList(payload: any) {
  const { travelerID, workHistoryID, travelerReferenceID } = payload;
  const workExperienceReferences = yield call(getReferenceListByWorkExperience, {
    payload: { candidateId: travelerID, workHistoryId: workHistoryID },
    type: workExperienceActions.getReferenceByWorkExperienceAction.type,
  });

  if (workExperienceReferences && workExperienceReferences.items) {
    const totalReferenceList = yield select(selectReferences);
    const { items: existingReferenceItems = [], totals: existingTotals = {} } = totalReferenceList;
    const referencesCount = travelerReferenceID
      ? existingTotals.referencesCount
      : (existingTotals.referencesCount || 0) + 1;

    const otherWEReferencesList = existingReferenceItems?.filter(item => item.workHistoryID !== workHistoryID);
    const referencesList = {
      items: [...otherWEReferencesList, ...workExperienceReferences.items],
      totals: { ...existingTotals, referencesCount: referencesCount },
    };

    yield put(candidateDetailActions.setReferences(referencesList));
    yield fork(deriveWorkExperienceData, travelerID);
  }
}

/**
 * Method to post reference request from Work Experience Grid
 */
export function* postReferenceRequestFromWorkExp(action: PayloadAction<IReferenceRequest>) {
  try {
    const candidateService: CandidateService = yield getContext('candidateService');

    const response = yield call(
      TrackPostReferenceRequestFromWorkExp,
      candidateService.postReferenceRequest,
      action.payload,
    );
    yield put(candidateDetailActions.setRequestReferenceSaveStatus(response));
    if (httpSuccess(response.status)) {
      const referenceGrid = yield select(referenceGridSelector);

      yield put(
        candidateDetailActions.setReferenceGrid([
          ...referenceGrid,
          { ...action.payload, referenceRequestStatusID: 6, id: referenceGrid?.length },
        ]),
      );
      yield put(
        globalActions.setSnackBar({
          message: 'Reference request has been added successfully',
          severity: 'success',
        }),
      );
    } else {
      yield put(
        globalActions.setSnackBar({
          message: `Failed to add reference request. Please try again.`,
          severity: 'error',
        }),
      );
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'postReferenceRequestFromWorkExp',
        area: 'src/store/redux-store/candidate-work-experience/business.saga.ts',
      },
    });
  }
}

function* getCandidateReferences(candidateId: number, filter: number) {
  try {
    const candidateService: CandidateService = yield getContext('candidateService');
    const response = yield call(
      TrackGetCandidateWorkExperience,
      candidateService.getCandidateReferences,
      candidateId,
      filter,
    );

    if (response && httpSuccess(response?.status)) {
      if (filter === 0) {
        yield put(candidateDetailActions.setReferences(response.data));
      } else {
        yield put(candidateDetailActions.setReferenceData(response.data));
      }
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'getCandidateReferences',
        area: '/src/store/redux-store/candidate-details/business.saga.ts',
      },
    });
  }
}
