import { ISelection } from 'app/ComponentLibrary/Filter/CustomSelect';
import { FilterCompTypes } from 'app/ComponentLibrary/Filter/utils';
import { getCitiesByKeyword } from 'app/services/SharedServices/SharedServices';
import {
  dropDownFilterOptions,
  getFacilitySearchLookups,
  getDescriptionSearchLookups,
  getFacilityCommonSearchLookups,
  getFilteredOptions,
} from '../helper';
import { facilitySearchFilterName } from './SearchUtils';
import _ from 'lodash';

export class FacilitiesFilter {
  constructor(public filterSpecs: any, public t: any, public styleClasses: any, public gridStateFilters: any) {}

  getFacilityFilterSpecs = () => [
    {
      filterName: this.t('search.globalSearch.facility.filterNames.facilityStatus'),
      name: facilitySearchFilterName.facilityStatus,
      type: FilterCompTypes.TYPEAHEADDROPDOWN,
      isMultiSelect: true,
      fetchOptions: async key => await getFilteredOptions(this.filterSpecs.facilityStatusesOpts, key),
      isSelectAll: true,
      applyOnClickAway: true,
      applyOnEnter: true,
    },
    {
      filterName: this.t('search.globalSearch.facility.filterNames.division'),
      name: facilitySearchFilterName.division,
      type: FilterCompTypes.TYPEAHEADDROPDOWN,
      isMultiSelect: true,
      fetchOptions: async key => await getFilteredOptions(this.filterSpecs.divisionOpts, key),
      isSelectAll: true,
      applyOnClickAway: true,
      applyOnEnter: true,
    },
    {
      filterName: this.t('search.globalSearch.facility.filterNames.state'),
      name: facilitySearchFilterName.state,
      type: FilterCompTypes.TYPEAHEADDROPDOWN,
      isMultiSelect: true,
      fetchOptions: async key => await getFilteredOptions(this.filterSpecs.statesOpts, key),
      isSelectAll: true,
      filterOptions: (options, { inputValue }) => {
        return dropDownFilterOptions(options, inputValue);
      },
      applyOnClickAway: true,
      applyOnEnter: true,
    },
    {
      filterName: this.t('search.globalSearch.facility.filterNames.city'),
      name: facilitySearchFilterName.city,
      type: FilterCompTypes.TYPEAHEADDROPDOWN,
      fetchOptions: async key => await getCitiesByKeyword(key),
      isMultiSelect: true,
      isSelectAll: true,
      applyOnClickAway: true,
      applyOnEnter: true,
    },
    {
      filterName: this.t('search.globalSearch.facility.filterNames.facility'),
      name: facilitySearchFilterName.facility,
      type: FilterCompTypes.TYPEAHEADDROPDOWN,
      fetchOptions: getFacilitySearchLookups,
      isMultiSelect: true,
      isSelectAll: true,
      applyOnClickAway: true,
      applyOnEnter: true,
    },
    {
      filterName: this.t('search.globalSearch.facility.filterNames.accountManager'),
      name: facilitySearchFilterName.accountManager,
      type: FilterCompTypes.TYPEAHEADDROPDOWN,
      isMultiSelect: true,
      fetchOptions: async key => await getFilteredOptions(this.filterSpecs.accountManagerOpts, key),
      isSelectAll: true,
      applyOnClickAway: true,
      applyOnEnter: true,
    },
    {
      filterName: this.t('search.globalSearch.facility.filterNames.positions'),
      name: facilitySearchFilterName.positions,
      type: FilterCompTypes.TYPEAHEADDROPDOWN,
      options: PositionOpts(this.t),
      hideClearAll: true,
    },
    {
      filterName: this.t('search.globalSearch.facility.filterNames.credentialingAnalyst'),
      name: facilitySearchFilterName.credentialingAnalyst,
      type: FilterCompTypes.TYPEAHEADDROPDOWN,
      isMultiSelect: true,
      isSelectAll: true,
      fetchOptions: async key => await getFacilityCommonSearchLookups('credentialinganalysts', key),
      applyOnClickAway: true,
      applyOnEnter: true,
    },
    {
      filterName: this.t('search.globalSearch.facility.filterNames.clinicalManager'),
      name: facilitySearchFilterName.clinicalManager,
      type: FilterCompTypes.TYPEAHEADDROPDOWN,
      isMultiSelect: true,
      isSelectAll: true,
      fetchOptions: async key => await getFacilityCommonSearchLookups('clinicalmanager', key),
      applyOnClickAway: true,
      applyOnEnter: true,
    },
    {
      filterName: this.t('search.globalSearch.facility.filterNames.contractAffiliateId'),
      name: facilitySearchFilterName.contractAffiliateId,
      type: FilterCompTypes.TYPEAHEADDROPDOWN,
      isMultiSelect: true,
      isSelectAll: true,
      fetchOptions: async key => await getFacilityCommonSearchLookups('contractaffiliateid', key),
      applyOnClickAway: true,
      applyOnEnter: true,
    },
    {
      filterName: this.t('search.globalSearch.facility.filterNames.contractAffiliateName'),
      name: facilitySearchFilterName.contractAffiliateName,
      type: FilterCompTypes.TYPEAHEADDROPDOWN,
      isMultiSelect: true,
      isSelectAll: true,
      fetchOptions: async key => await getFacilityCommonSearchLookups('contractaffiliates', key),
      applyOnClickAway: true,
      applyOnEnter: true,
    },
    {
      filterName: this.t('search.globalSearch.facility.filterNames.regionalDirector'),
      name: facilitySearchFilterName.regionalDirector,
      type: FilterCompTypes.TYPEAHEADDROPDOWN,
      isMultiSelect: true,
      isSelectAll: true,
      fetchOptions: async key => await getFacilityCommonSearchLookups('amierd', key),
      applyOnClickAway: true,
      applyOnEnter: true,
    },
    {
      filterName: this.t('search.globalSearch.facility.filterNames.clientContractAdmin'),
      name: facilitySearchFilterName.clientContractAdmin,
      type: FilterCompTypes.TYPEAHEADDROPDOWN,
      isMultiSelect: true,
      isSelectAll: true,
      fetchOptions: async key => await getFacilityCommonSearchLookups('clientcontractadmin', key),
      applyOnClickAway: true,
      applyOnEnter: true,
    },
    {
      filterName: this.t('search.globalSearch.facility.filterNames.clientContractSpecialist'),
      name: facilitySearchFilterName.clientContractSpecialist,
      type: FilterCompTypes.TYPEAHEADDROPDOWN,
      isMultiSelect: true,
      isSelectAll: true,
      fetchOptions: async key => await getFacilityCommonSearchLookups('clientcontractspecialist', key),
      applyOnClickAway: true,
      applyOnEnter: true,
    },
    {
      filterName: this.t('search.globalSearch.facility.filterNames.hospitalSetting'),
      name: facilitySearchFilterName.hospitalSetting,
      type: FilterCompTypes.TYPEAHEADDROPDOWN,
      isMultiSelect: true,
      isSelectAll: true,
      fetchOptions: async key => await getFilteredOptions(this.filterSpecs.hospitalSettingOpts, key),
      filterOptions: (options, { inputValue }) => {
        return dropDownFilterOptions(options, inputValue);
      },
      applyOnClickAway: true,
      applyOnEnter: true,
    },
    {
      filterName: this.t('search.globalSearch.facility.filterNames.requirementType'),
      name: facilitySearchFilterName.requirementType,
      type: FilterCompTypes.TYPEAHEADDROPDOWN,
      isMultiSelect: true,
      isSelectAll: true,
      fetchOptions: async key => await getFilteredOptions(this.filterSpecs.requirementTypeOpts, key),
      includeUnits: true,
      includeUnitsText: 'Include Units in Requirement Search',
      unitsFilterName: facilitySearchFilterName.includeRequirementTypeUnits,
      filterOptions: (options, { inputValue }) => {
        return dropDownFilterOptions(options, inputValue);
      },
      applyOnClickAway: true,
      applyOnEnter: true,
    },
    {
      filterName: this.t('search.globalSearch.facility.filterNames.facilityTypeAndDescription'),
      name: facilitySearchFilterName.facilityNested,
      nestedlabelPrefix: this.t('search.globalSearch.facility.filterNames.facilityType'),
      type: FilterCompTypes.NESTEDFILTER,
      showOperations: false,
      filters: [
        {
          filterName: this.t('search.globalSearch.facility.filterNames.facilityType'),
          name: facilitySearchFilterName.facilityType,
          type: FilterCompTypes.TYPEAHEADDROPDOWN,
          options: this.filterSpecs.facilityTypeOpts,
          isMultiSelect: false,
          position: 1,
          isRequired: true,
          getValue: (existingValue: any, index: number) => {
            const values = _.cloneDeep(existingValue);
            if (values[index][facilitySearchFilterName.description]) {
              values[index] = {
                [facilitySearchFilterName.facilityType]: values[index][facilitySearchFilterName.facilityType],
              };
            }
            return values;
          },
          filterOptions: (options, { inputValue }) => {
            return dropDownFilterOptions(options, inputValue);
          },
        },
        {
          filterName: this.t('search.globalSearch.facility.filterNames.description'),
          name: facilitySearchFilterName.description,
          type: FilterCompTypes.TYPEAHEADDROPDOWN,
          fetchOptionsFromNestedValue: async (value: any, index: number) =>
            await getDescriptionSearchLookups(
              value?.value?.at(index)?.[facilitySearchFilterName.facilityType]
                ? parseInt(value?.value?.at(index)?.[facilitySearchFilterName.facilityType].value)
                : 0,
            ),
          isMultiSelect: false,
          position: 2,
          filterOptions: (options, { inputValue }) => {
            return dropDownFilterOptions(options, inputValue);
          },
        },
      ],
    },
    {
      filterName: this.t('search.globalSearch.facility.filterNames.staffingVendor'),
      name: facilitySearchFilterName.staffingVendor,
      type: FilterCompTypes.TYPEAHEADDROPDOWN,
      isMultiSelect: true,
      isSelectAll: true,
      fetchOptions: async key => await getFilteredOptions(this.filterSpecs.staffingVendorOpts, key),
      filterOptions: (options, { inputValue }) => {
        return dropDownFilterOptions(options, inputValue);
      },
      applyOnClickAway: true,
      applyOnEnter: true,
    },
    {
      filterName: this.t('search.globalSearch.facility.filterNames.healthSystemID'),
      name: facilitySearchFilterName.healthSystemID,
      type: FilterCompTypes.TYPEAHEADDROPDOWN,
      isMultiSelect: true,
      isSelectAll: true,
      fetchOptions: async key => await getFacilityCommonSearchLookups('healthsystemid', key),
      applyOnClickAway: true,
      applyOnEnter: true,
    },
    {
      filterName: this.t('search.globalSearch.facility.filterNames.healthSystem'),
      name: facilitySearchFilterName.healthSystem,
      type: FilterCompTypes.TYPEAHEADDROPDOWN,
      isMultiSelect: true,
      isSelectAll: true,
      fetchOptions: async key => await getFacilityCommonSearchLookups('healthsystem', key),
      applyOnClickAway: true,
      applyOnEnter: true,
    },
    {
      filterName: this.t('search.globalSearch.facility.filterNames.techVendor'),
      name: facilitySearchFilterName.techVendor,
      type: FilterCompTypes.TYPEAHEADDROPDOWN,
      isMultiSelect: true,
      isSelectAll: true,
      fetchOptions: async key => await getFilteredOptions(this.filterSpecs.techVendorOpts, key),
      filterOptions: (options, { inputValue }) => {
        return dropDownFilterOptions(options, inputValue);
      },
      applyOnClickAway: true,
      applyOnEnter: true,
    },
    {
      filterName: this.t('search.globalSearch.facility.filterNames.includeRequirementTypeUnits'),
      name: facilitySearchFilterName.includeRequirementTypeUnits,
      type: FilterCompTypes.CUSTOMCHECKBOX,
      hidden: true,
    },
  ];
}

export const PositionOpts = t =>
  [
    { id: 0, value: { min: 0, max: 0 }, name: '0', labelPrefix: t('Positions') },
    { id: 1, value: { min: 1, max: 999 }, name: '>0', labelPrefix: t('Positions') },
    { id: 2, value: { min: 1, max: 10 }, name: '1-10', labelPrefix: t('Positions') },
    { id: 3, value: { min: 10, max: 50 }, name: '10-50', labelPrefix: t('Positions') },
    { id: 4, value: { min: 50, max: 99 }, name: '50-99', labelPrefix: t('Positions') },
    { id: 5, value: { min: 100, max: 999 }, name: '>99', labelPrefix: t('Positions') },
  ] as unknown as ISelection[];
