import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectEditableRequirementTypes,
  selectInterviewInfo,
  selectSubmissionInfo,
} from '../../../../../../../../store/redux-store/placement-summary/selectors';
import { selectPlacementOrderType } from 'store/redux-store/placement-details/selectors';
import { PlacementOrderType } from 'app/models/Placement/PlacementDetails';
import { Requirements } from 'app/models/Placement/PlacementRequirements';
import { placementSummaryActions } from '../../../../../../../../store/redux-store/placement-summary/slice';
import { requirementType } from 'app/models/Placement/PlacementSummary';
import { useEditableRequirementAvailableStartDate } from './EditableRequirements/AvailableStartDateRequirement';
import { useEditableRequirementPlacementDate } from './EditableRequirements/PlacementDateRequirement';
import { useEditableRequirementShiftOffered } from './EditableRequirements/ShiftOfferedRequirement';
import { useEditableRequirementFacilityStatus } from './EditableRequirements/FacilityStatusRequirement';
import { useEditableRequirementOrderDOB } from './EditableRequirements/OrderDobRequirement';
import { useEditableRequirementOrderSsn } from './EditableRequirements/OrderSsnRequirement';
import {
  ff_placement_ui_and_or_containers,
  ff_placement_ui_requirement_check,
} from 'app/constants/FeatureFlags/Placement/Keys';
import { useDecision } from '@optimizely/react-sdk';

/**
 * AMN REQUIREMENT COUNTS
 */
const useSubmissionAMNRequirementCounts = () => {
  const orderType = useSelector(selectPlacementOrderType);
  const {
    amnReq: {
      amn: {
        experience: strikeExperince,
        skillset: amnSkillset,
        status: amnStatus,
        audit: amnAudit,
        education: amnEducation,
        workHistory: amnWorkHistory,
        skillsCheckList: amnSkillsChecklist,
        application: amnApplication,
      },
    },
  } = useSelector(selectSubmissionInfo);

  const AMNRequirementCounts = React.useMemo(() => {
    /** AMN REQUIREMENT COUNT CALCULATIONS */
    const counts = new Requirements();
    // Skill Set
    counts.add(amnSkillset?.data?.verifiedByCmType);
    // Strike Skill Set
    counts.add(amnSkillset?.data?.strikeMatchType);
    // Status
    counts.add(amnStatus?.type);
    // Audit
    counts.add(amnAudit?.type);
    // Education
    counts.add(amnEducation?.type);

    // Experience
    counts.add(strikeExperince?.type);

    // Work History
    counts.add(amnWorkHistory?.type);
    // References
    //counts.add(references?.type);

    // Skills Checklists
    counts.add(amnSkillsChecklist?.type);

    // Application Suspension Type
    counts.add(amnApplication?.data?.suspensionType);
    // Application Right To Work Verification
    counts.add(amnApplication?.data?.rightToWorkVerificationType);

    return counts;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    amnSkillset,
    orderType,
    strikeExperince,
    amnStatus,
    amnAudit,
    amnEducation,
    amnWorkHistory,
    //references,
    amnSkillsChecklist,
    amnApplication,
  ]);

  return AMNRequirementCounts;
};

/**
 * ORDER REQUIREMENT COUNTS
 */
const useSubmissionOrderRequirementCounts = () => {
  const [requirementCheckFlag] = useDecision(ff_placement_ui_requirement_check);
  const [orderGeneralRequirement] = useDecision(ff_placement_ui_and_or_containers);
  const orderType = useSelector(selectPlacementOrderType);
  useEditableRequirementAvailableStartDate();
  useEditableRequirementPlacementDate();
  useEditableRequirementOrderDOB();
  useEditableRequirementOrderSsn();
  useEditableRequirementShiftOffered();
  useEditableRequirementFacilityStatus();
  const { availabilityDate, placementDate, orderDob, orderSsn } = useSelector(selectEditableRequirementTypes);

  const {
    orderReq: {
      order: {
        experience: orderExperience,
        status: orderStatus,
        licenses: orderLicenses,
        certifications: orderCertifications,
        milesRadius: orderMilesRadius,
        schedule: orderSchedule,
        covid: orderCovid,
        orderDob: orderDOBValue,
        orderSsn: orderSsnValue,
        workHistory: orderWorkHistory,
        references: orderReferences,
      },
      candidate: { certifications, references, licenses: candidateLicenses },
    },
  } = useSelector(selectSubmissionInfo);

  const { virtualInterview } = useSelector(selectInterviewInfo);

  const OrderRequirementCounts = React.useMemo(() => {
    /** ORDER REQUIREMENT COUNT CALCULATIONS */
    // Skill Set - NO ORDER REQUIREMENT
    // Status
    const counts = new Requirements();
    // Order Status
    counts.add(orderStatus?.type);
    // Order Dates
    counts.add(availabilityDate?.requirement);

    if (orderType === PlacementOrderType.StrikeOrderInHsg) {
      counts.add(orderSchedule?.data?.shiftOffered?.type);
      counts.add(placementDate?.requirement);
    }

    // References
    counts.add(references?.type);

    // Miles Radius
    counts.add(orderMilesRadius?.type);
    // COVID
    counts.add(orderCovid?.type);

    if (!orderGeneralRequirement?.enabled) {
      // Order Experience
      counts.add(orderExperience?.type);
      // Order Certifications
      (orderCertifications?.data || []).forEach(item => counts.add(item.type));

      //Order req - Candidate certifications
      certifications?.data.isCandidateCertsExpiring && counts.add(requirementType.risk);

      //Order req - Candidate Credential certifications
      certifications?.data.isCredentialCertsExpiring &&
        counts.add(requirementType.risk, certifications?.data.countOfCredentialsWithRisk);

      // Order Licenses
      (orderLicenses?.data?.orderLicensesDetails || []).forEach(item => counts.add(item.type));

      // Candidate licenses - risk requirements
      (candidateLicenses?.data || []).forEach(item => {
        if (item.type === requirementType.risk) counts.add(requirementType.risk);
      });
    }
    // DOB, SSN
    if (requirementCheckFlag.enabled) {
      if (orderDOBValue?.type) counts.add(orderDob?.requirement);
      if (orderSsnValue?.type) counts.add(orderSsn?.requirement);
      if (virtualInterview?.order?.data?.virtualRatingSubmissionRequirement) counts.add(virtualInterview?.order?.type);
      counts.add(orderWorkHistory?.type);
      counts.add(orderReferences?.type);
    }

    return counts;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    orderStatus,
    orderExperience,
    availabilityDate,
    orderType,
    orderLicenses,
    references,
    orderCertifications,
    certifications,
    orderMilesRadius,
    orderCovid,
    orderSchedule,
    placementDate,
    orderDob,
    orderSsn,
    orderDOBValue,
    orderSsnValue,
  ]);

  return OrderRequirementCounts;
};

export const useSubmissionRequirementCounts = () => {
  const dispatch = useDispatch();
  const amnRequirementCounts = useSubmissionAMNRequirementCounts();
  const orderRequirementCounts = useSubmissionOrderRequirementCounts();

  React.useEffect(() => {
    dispatch(
      placementSummaryActions.setRequirementCounts({
        submissionAMNReqCounts: amnRequirementCounts,
        submissionOrderReqCounts: orderRequirementCounts,
      }),
    );
  }, [amnRequirementCounts, dispatch, orderRequirementCounts]);

  return {
    amnCounts: amnRequirementCounts,
    orderCounts: orderRequirementCounts,
  };
};
