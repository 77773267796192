/* eslint-disable i18next/no-literal-string */
import React from 'react';
import { Expandable } from 'app/components/Common/Expandable/Expandable';
import { IconTitle } from './Titles/IconTitle';
import StarIcon from 'app/assets/images/IconImages/StarIcon.svg';
import { CandidateSellingPoints } from 'app/components/Placement/PlacementDetails/PlacementTabPanel/PlacementSummaryTab/CandidateSellingPoints';

export const SellingPoints = ({ ...props }) => {
  return (
    <Expandable
      id="placement-summary-selling-points"
      expanded={false}
      titleComponent={<IconTitle title="Selling Points" icon={<img src={StarIcon} alt="Star" />} />}
    >
      <CandidateSellingPoints />
    </Expandable>
  );
};
