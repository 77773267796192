import { getTaskEntityValue } from 'app/components/Notification/Tasks/Common/FormatData';
import { taskDetailsActions, taskDetailsReducer, taskDetailsSliceKey } from 'app/components/Tasks/store/Tasks.redux';
import { taskDetailsSaga } from 'app/components/Tasks/store/Tasks.saga';
import {
  SelectedContainer,
  selectCueNoteId,
  selectCueNoteCreated,
  selectCueNoteReplyParent,
} from 'app/components/Tasks/store/Tasks.selectors';
import { NotificationBrand, NotificationSender, TaskEntity, TaskNotificationType } from 'app/models/Tasks/Tasks';
import moment from 'moment-timezone';
import { selectUser } from 'oidc/user.selectors';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { Concatenate } from 'utils/string/string';
import { selectPlacementHeaderData } from 'store/redux-store/placement-details/selectors';
import { selectPlacementStatus } from 'store/redux-store/placement-status/selectors';
import { selectUnitDetailsId } from '@AMIEWEB/Order/Store/Order.selectors';
import { getEmployeeDetails } from '../EditTask/helper';

export const useReplyCueNotesNotifications = () => {
  useInjectSaga({ key: taskDetailsSliceKey, saga: taskDetailsSaga });
  useInjectReducer({ key: taskDetailsSliceKey, reducer: taskDetailsReducer });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const createdId = useSelector(selectCueNoteId);
  const createdCueData = useSelector(selectCueNoteCreated);
  const currentContainer = useSelector(SelectedContainer);
  const userInfo = useSelector(selectUser);
  const cueNoteParent = useSelector(selectCueNoteReplyParent);
  const placementHeaderData = useSelector(selectPlacementHeaderData);
  const { currentPlacementStatus } = useSelector(selectPlacementStatus);
  const unitId = useSelector(selectUnitDetailsId);
  const employeeDetails = getEmployeeDetails(userInfo?.userInfo);
  const lastRecipient = {
    firstName: cueNoteParent?.sentByFirstName,
    lastName: cueNoteParent?.sentByLastName,
    employeeId: cueNoteParent?.sentById,
  };
  const getReplyNotifications = async () => {
    let entityId: string = '0';
    let primaryText: string = '';
    if (currentContainer === TaskEntity.PLACEMENTS) {
      primaryText = Concatenate(
        [
          placementHeaderData?.candidate?.firstName,
          ' ',
          placementHeaderData?.candidate?.middleName || '',
          ' ',
          placementHeaderData?.candidate?.lastName,
        ],
        '',
      );
      entityId = placementHeaderData?.candidate?.id?.toString();
    } else {
      entityId = '0';
    }
    if (primaryText) {
      const taskNotificationPayload = {
        brand: NotificationBrand,
        useType: 'Cue Note',
        useSubType: TaskNotificationType.CUENOTEREPLY.toString(),
        sendType: 'Bulk',
        sender: NotificationSender,
        ttlValue: 2,
        ttlPeriod: 'w',
        associatedRecords: [
          {
            name: 'TaskId',
            value: createdId?.toString(),
          },
          {
            name: 'CurrentContainer',
            value: getTaskEntityValue(currentContainer, t),
          },
          {
            name: 'PrimaryContext',
            value: primaryText,
          },
          {
            name: 'PlacementId',
            value: createdCueData?.placementId ? createdCueData?.placementId?.toString() : '',
          },
          {
            name: 'UnitId',
            value: unitId ? unitId?.toString() : '',
          },
          {
            name: 'CandidateId',
            value: placementHeaderData?.candidate?.id ? placementHeaderData?.candidate?.id?.toString() : '',
          },
          {
            name: 'OrderId',
            value: placementHeaderData?.orderId ? placementHeaderData?.orderId?.toString() : '',
          },
          {
            name: 'FacilityId',
            value: placementHeaderData?.facility?.id ? placementHeaderData?.facility?.id?.toString() : '',
          },
          {
            name: 'BrandId',
            value: placementHeaderData?.brandId ? placementHeaderData?.brandId?.toString() : '',
          },
          {
            name: 'PlacementStatus',
            value: currentPlacementStatus?.activityStatus ? currentPlacementStatus?.activityStatus?.toString() : '',
          },
          {
            name: 'PlacementStatusId',
            value: currentPlacementStatus?.activityStatusId ? currentPlacementStatus?.activityStatusId?.toString() : '',
          },
          {
            name: 'Description',
            value: createdCueData?.text ? createdCueData?.text : '',
          },
          {
            name: 'EntityId',
            value: entityId,
          },
          {
            name: 'UserName',
            value: employeeDetails?.firstName + ' ' + employeeDetails?.lastName,
          },
          {
            name: 'SentDate',
            value: moment.tz(new Date(), 'America/Los_Angeles').format(),
          },
        ],
        body: '',
        level: 1,
        templateId: '',
        tos: [
          {
            name: lastRecipient?.firstName + ' ' + lastRecipient?.lastName,
            email: `${lastRecipient?.firstName + '.' + lastRecipient?.lastName}@amnhealthcare.com`,
            phoneNumber: '',
            contactId: lastRecipient?.employeeId?.toString(),
          },
        ],
        ccs: [],
        groupName: '',
      };
      if (lastRecipient?.employeeId && lastRecipient?.employeeId > 0) {
        dispatch(taskDetailsActions.sendCueNoteCreatedNotification(taskNotificationPayload));
        dispatch(taskDetailsActions.setCueNoteDataId(undefined));
      }
    }
  };
  useEffect(() => {
    if (createdId && cueNoteParent) {
      getReplyNotifications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdId]);
};
